import { List } from 'immutable'
import { calendarCellsQuery, staffAssignedHoursQuery } from '../Queries'

export default PreloadFlatShifts()

function PreloadFlatShifts() {
  let gqlClient
  let updateCalendarCache

  return { initialize, actions: { preloadFlatShifts } }

  function initialize(context) {
    ;({ gqlClient, updateCalendarCache } = context)
  }

  function preloadFlatShifts(preloadData, dimensions) {
    const roles = preloadData.get('roles')
    const facilityUserIdsByShiftId = preloadData.get('facilityUserIdsByShiftId')

    const { shiftIds, userIds } = _getUserIdsAndShiftIds(roles, facilityUserIdsByShiftId, dimensions)

    if (userIds.length === 0 || shiftIds.length === 0) {
      return
    }

    const startDate = preloadData.get('startDate')
    const endDate = preloadData.get('endDate')
    const unitId = preloadData.get('unitId')
    const calendarCellsParameters = { userIds, shiftIds, startDate, endDate }
    const staffHoursParameters = { unitId, userIds, startDate, endDate }

    const calendarCellsPromise = gqlClient.query(calendarCellsQuery, calendarCellsParameters)
    const staffHoursPromise = gqlClient.query(staffAssignedHoursQuery, staffHoursParameters)

    updateCalendarCache('query', calendarCellsParameters, calendarCellsPromise, {
      queryName: 'calendarCells'
    })

    updateCalendarCache('query', staffHoursParameters, staffHoursPromise, {
      queryName: 'staffAssignedHours'
    })
  }
}

function _getUserIdsAndShiftIds(roles, facilityUserIdsByShiftId, dimensions) {
  const {
    calendarHeight,
    staffRowHeight,
    shiftHeight,
    roleHeight
    // overscanRowCount = 0
  } = dimensions
  let height = 0
  // TODO: implement functionality for overscanRowCount if it will be used:

  const hideEmptyShifts = false
  return roles.reduce(
    (memo, role) => {
      const totalHeight = height + memo.userIds.length * staffRowHeight
      if (totalHeight >= calendarHeight) {
        return memo
      }
      height += roleHeight

      return role.get('shifts').reduce((memo, shift) => {
        const totalHeight = height + memo.userIds.length * staffRowHeight
        if (totalHeight >= calendarHeight) {
          return memo
        }

        const isHidden = shift.get('isHidden')
        if (isHidden) {
          return memo
        }

        const shiftId = shift.get('id')
        const shiftUserIds = facilityUserIdsByShiftId.get(shiftId) || List()
        const isEmpty = shiftUserIds.size === 0
        if (hideEmptyShifts && isEmpty) {
          return memo
        }

        height += shiftHeight
        memo.shiftIds.push(shiftId)

        memo.userIds = shiftUserIds.reduce(
          (userIds, userId) => (userIds.includes(userId) ? userIds : userIds.concat(userId)),
          memo.userIds
        )

        return memo
      }, memo)
    },
    { shiftIds: [], userIds: [] }
  )
}
