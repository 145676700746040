import { UpdateEntity } from '@humanics/he-react-common/lib/admin/components'

function UnitUpdate(props) {
  const getDefinitionForMonthlySchedulingStartsAt = () => {
    const { match } = props
    const { id } = match.params

    const unit = props['appState']
      .get('admin.Unit')
      .get('list')
      .filter((e) => e.id === id)

    const { scheduleType, schedulingStartsAt } = unit[0]
    let value = ''

    if (scheduleType === 'monthly') {
      value = schedulingStartsAt
    }

    return { value, disabled: true }
  }

  const inputDefinitionExtraFields = {
    name: { required: true },
    staffingPeriod: { disabled: true },
    scheduleType: { disabled: true },
    schedulingStartsAt: { disabled: true },
    monthlySchedulingStartsAt: getDefinitionForMonthlySchedulingStartsAt()
  }

  const beforeSubmit = (form) => {
    const { staffingPeriod, schedulingStartsAt, scheduleType, monthlySchedulingStartsAt, ...rest } = form
    return rest
  }

  const _props = { ...props, inputDefinitionExtraFields, deleteOperationId: undefined }

  return <UpdateEntity {..._props} beforeSubmit={beforeSubmit} />
}

export default UnitUpdate
