import { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import ReactTooltip from 'react-tooltip'

export default class StaffItemStatus extends Component {
  static propTypes = {
    facilityStaff: PropTypes.instanceOf(Map).isRequired
  }

  render() {
    const { facilityStaff, timeService } = this.props
    const isInvitationSent = facilityStaff.get('isInvitationSent')
    const isInvitationAccepted = facilityStaff.get('isInvitationAccepted')
    const hasPermissions = facilityStaff.get('hasPermissions')
    const staffProfile = facilityStaff.get('staffProfile') || Map()
    const endsAt = staffProfile.get('endsAt')

    const todayMoment = timeService.timeMoment(null)
    const endsAtFormatted = endsAt && timeService.timeMoment(endsAt).format('MMM DD, YYYY')
    const isExpired = endsAt && todayMoment.isAfter(endsAt)

    let status

    switch (true) {
      case isExpired:
        status = (
          <div className="regent-gray small text-center">
            Removed
            <br />
            {endsAtFormatted}
          </div>
        )

        break

      case isInvitationAccepted:
      case hasPermissions:
        status = endsAt ? (
          <i className="icon-access-ending p20 pv0" data-tip={`Access ending ${endsAtFormatted}`} />
        ) : (
          <i className="icon-has-access p20 pv0" data-tip="Has access" />
        )

        break

      case isInvitationSent && !isInvitationAccepted:
        status = <i className="icon-pending-access p20 pv0" data-tip="Pending access" />
        break

      default:
        status = <i className="icon-no-access p20 pv0" data-tip="Access is not available" />
    }

    return (
      <span className="_status">
        {status}
        <ReactTooltip place="bottom" type="dark" effect="solid" />
      </span>
    )
  }
}
