import { createRef, Component } from 'react'
import classNames from 'classnames'

export default class VDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPopupInViewport: true
    }

    this.containerRef = createRef()
  }

  componentDidMount() {
    this.setState((state) => {
      const containerRect = this.containerRef.current.getBoundingClientRect()
      const isPopupInViewport =
        containerRect &&
        containerRect.top >= 0 &&
        containerRect.left >= 0 &&
        containerRect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        containerRect.right <= (window.innerWidth || document.documentElement.clientWidth)
      return { isPopupInViewport }
    })
  }

  render() {
    const { phoneNumbers, hasPreferences, preferenceNames } = this.props
    const { isPopupInViewport } = this.state

    return (
      <div
        ref={this.containerRef}
        className={classNames({
          'profile-dropdown-options': isPopupInViewport,
          'profile-dropdown-options-up': !isPopupInViewport
        })}
      >
        <ul className="bg-brightgray text-left rounded6">
          {phoneNumbers.map((phone, index) => (
            <li key={index}>
              <a className="_phone-number" href={`tel:${phone}`}>
                <span className="river-bad semibold">{phone}</span>
              </a>
            </li>
          ))}
          <li>
            {hasPreferences ? (
              <a href="." className="_preferences">
                <span className="river-bad semibold">
                  Extra shift preferences:
                  <br />
                  {preferenceNames.join(', ')}
                </span>
              </a>
            ) : (
              <a href="." className="_preferences">
                <span className="river-bad semibold">No preferences set</span>
              </a>
            )}
          </li>
        </ul>
      </div>
    )
  }
}
