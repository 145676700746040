import { PureComponent } from 'react'
import classNames from 'classnames'
import { pick } from 'lodash'
import SelectUnit from './SelectUnit'
import ActiveDateRange from './ActiveDateRange'
import ProfileDropdown from './ProfileDropdown'
import DateRangeSelector from './DateRangeSelector'
import './Navigation.scss'

export default class Navigation extends PureComponent {
  state = { isNavigationShown: false }

  render() {
    const { unit, isNavigationHidden, isLoading, authentication, urlId } = this.props
    if (!unit) {
      return null
    }
    const homeUnitId = authentication.getIn(['facilityUser', 'homeUnitId'])
    const unitUrlId = urlId || authentication.getIn(['facility', 'unit', 'urlId'])
    const selectUnitProps = pick({ ...this.props, homeUnitId }, [
      'unit',
      'timeService',
      'homeUnitId',
      'eligibleUnits',
      'facilityName',
      'facilitiesCount'
    ])

    const dateRangeSelectorProps = pick(
      {
        ...this.props,
        isShown: this.state.isNavigationShown,
        hideDateRange: this.hideDateRange,
        unitUrlId
      },
      ['unit', 'usDate', 'isShown', 'timeService', 'hideDateRange', 'unitUrlId']
    )

    const navigationClassName = classNames('hx-navigation', {
      hide: isNavigationHidden
    })

    const isSchedulesLoaded = unit.get('isSchedulesLoaded')

    return (
      <div className={navigationClassName}>
        <div className="row align-middle bg-white">
          <SelectUnit {...selectUnitProps} />
          <div className="col-4 row align-center align-middle">
            {isLoading ? (
              <section className="loader">
                <div className="loading" />
              </section>
            ) : (
              <>
                <ActiveDateRange
                  className="col-12"
                  unit={this.props.unit}
                  activeDateRange={this.props.activeDateRange}
                  showDateRange={this.showDateRange}
                  hideDateRange={this.hideDateRange}
                />
              </>
            )}
          </div>
          <div className="col col-4 print-and-profile-container">
            {this.props.children}
            <ProfileDropdown />
          </div>
        </div>

        {isSchedulesLoaded && <DateRangeSelector {...dateRangeSelectorProps} />}
      </div>
    )
  }

  showDateRange = () => {
    this.setState({ isNavigationShown: true })
  }

  hideDateRange = () => {
    this.setState({ isNavigationShown: false })
  }
}
