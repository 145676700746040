import { fromJS, Map } from 'immutable'
import { keyBy } from 'lodash'

function updateNotes(calendar, notes) {
  const notesReducer = (memo, value) => memo.set(value.get('id'), value)

  return calendar.update('notes', (current) => fromJS(notes).reduce(notesReducer, current))
}

function updateStaffHoursMap(calendar, staffHours) {
  const hoursReducer = (result, value) => {
    const { userId, assignedHours } = value
    const currentHours = calendar.getIn(['staffHoursMap', userId]) || Map()
    const requestsCount = currentHours.get('requestsCount')

    if (requestsCount > 1) {
      return result.set(userId, currentHours.merge({ requestsCount: requestsCount - 1 }))
    } else {
      return result.set(userId, Map({ value: assignedHours }))
    }
  }

  return calendar.update('staffHoursMap', (current) => staffHours.reduce(hoursReducer, current))
}

function updateOtherStaffSection(calendar, data, paths) {
  const { otherStaffPath, cellsPaths } = paths
  if (otherStaffPath) {
    return calendar.updateIn(otherStaffPath, (otherStaffSection) => {
      const otherStaff = otherStaffSection.get('staff')
      const staffDataMap = keyBy(data, 'id')
      const staff = otherStaff.map(updateStaff(staffDataMap, cellsPaths))

      return otherStaffSection.merge({ staff, isLoaded: true, isLoading: false })
    })
  }
  return calendar
}

function updatePrimaryStaffSections(calendar, shiftIds, data, paths) {
  const byShiftId = data.reduce((memo, shiftData) => {
    memo[shiftData.id] = shiftData
    return memo
  }, {})
  const shiftsData = shiftIds.map((id) => byShiftId[id])

  const { shiftsPaths, cellsPaths, shiftDaysPaths } = paths

  return updateShifts(calendar, shiftsData, shiftsPaths, cellsPaths, shiftDaysPaths)
}

function updateShifts(calendar, shiftsData, shiftsPaths, cellsPaths, shiftDaysPaths) {
  return shiftsPaths.reduce((memo, shiftPath, index) => {
    const shiftData = shiftsData[index]
    return memo.updateIn(shiftPath, updateShift(shiftData, cellsPaths, shiftDaysPaths))
  }, calendar)
}

function updateShift(shiftData, cellsPaths, shiftDaysPaths) {
  return (shift) => {
    if (!shift) {
      return
    }
    const { views, shiftDays: shiftDaysData, viewPreference } = shiftData

    let staff
    if (views.staff?.staff) {
      const staffDataMap = keyBy(views.staff.staff, 'id')
      const shiftStaff = shift.get('staff')
      staff = shiftStaff.map(updateStaff(staffDataMap, cellsPaths))
    }

    const shiftDays = updateShiftDays(shift, shiftDaysData, shiftDaysPaths)

    return shift.merge({
      shiftDays,
      isLoaded: true,
      isLoading: false,
      viewPreference: viewPreference || 'staff',
      staff: staff || [],
      shiftViewRows: views.shift.rows
    })
  }
}

function updateShiftDays(shift, shiftDaysData, shiftDaysPaths) {
  if (!shiftDaysPaths) {
    return fromJS(shiftDaysData)
  }

  const shiftIndex = shift.get('shiftIndex')

  const paths = shiftDaysPaths.filter((shiftDayPath) => {
    const { shiftIndex: shiftDayShiftIndex } = shiftDayPath
    return shiftIndex === shiftDayShiftIndex
  })

  let shiftDays = shift.get('shiftDays')

  paths.forEach(({ shiftDayIndex }) => {
    const currentShiftDay = shiftDays.get(shiftDayIndex)
    const requestsCount = currentShiftDay.get('requestsCount')
    if (requestsCount > 1) {
      const shiftDay = currentShiftDay.merge({ requestsCount: requestsCount - 1 })
      shiftDays = shiftDays.set(shiftDayIndex, shiftDay)
    } else {
      const shiftDay = fromJS(shiftDaysData[shiftDayIndex])
      shiftDays = shiftDays.set(shiftDayIndex, shiftDay)
    }
  })

  return shiftDays
}

function updateStaff(staffDataMap, cellsPaths) {
  return (staff) => {
    const userId = staff.get('id')
    const staffData = staffDataMap[userId]

    if (!staffData) {
      return staff
    }

    const { isUnavailabilitySubmitted } = staffData
    const cells = getStaffCells(staff, staffData.cells, cellsPaths)

    return staff.merge({
      isShiftLoaded: true,
      cells: fromJS(cells),
      isUnavailabilitySubmitted
    })
  }
}

function getStaffCells(staff, cells, cellsPaths) {
  if (!cellsPaths) {
    return fromJS(cells)
  }

  const roleIndex = staff.get('roleIndex')
  const shiftIndex = staff.get('shiftIndex')
  const staffIndex = staff.get('staffIndex')

  const staffCellsPaths = cellsPaths.filter((cellPath) => {
    const { roleIndex: cellRoleIndex, shiftIndex: cellShiftIndex, staffIndex: cellStaffIndex } = cellPath

    const isSameRoleIndex = roleIndex === cellRoleIndex
    const isSameShiftIndex = shiftIndex === cellShiftIndex
    const isSameStaffIndex = staffIndex === cellStaffIndex

    return isSameRoleIndex && isSameShiftIndex && isSameStaffIndex
  })

  let staffCells = staff.get('cells')

  staffCellsPaths.forEach(({ cellIndex }) => {
    const cell = cells[cellIndex]
    staffCells = staffCells.mergeIn([cellIndex], { ...cell, isDummy: false })
  })

  return staffCells
}

export { updateNotes, updateStaffHoursMap, updateOtherStaffSection, updatePrimaryStaffSections }
