import { PureComponent, createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import Header from './Header'
import { pick } from 'lodash'
import StaffViewCellContextMenu from '../../../../../Calendar/StaffViewCellContextMenu'
import CellDetails from '../../../../../Calendar/CellDetails'
import CellTooltip from '../../../../../Calendar/CellTooltip'
import classNames from 'classnames'
import JobProcessingState from './JobProcessingState'
import ReactResizeDetector from 'react-resize-detector'
import {
  ActionController,
  CellTooltipController,
  DataController,
  EventController,
  FilterController,
  MultiselectController,
  PopupController,
  StateController,
  ViewController,
  ViewModelController
} from './Controllers'
import stores from 'stores'
import { List, Map } from 'immutable'
import VGrid from './Grid/VGrid'
import WarningModal from './SecondaryStaff/WarningModal'
import {
  getCellContextMenuProps,
  getCellDetailsProps,
  composeIndicatorsClasses,
  componseModeClasses
} from '../../../../../Calendar/layoutUtils'
import './Calendar.scss'
import './Cell.scss'
import './Grid/VRole/Role.scss'
import './Grid/EligibleStaff.scss'
import './Layout.print.scss'
import { DateRangeService } from 'services'

const { calendarStore, expertiseStore } = stores

export class Layout extends PureComponent {
  constructor(props) {
    super(props)

    this.viewController = new ViewController(this)
    this.dataController = new DataController(this)
    this.stateController = new StateController(this)
    this.multiselectController = new MultiselectController(this)
    this.popupController = new PopupController(this)
    this.viewModelController = new ViewModelController(this)
    this.filterController = new FilterController(this)
    this.eventController = new EventController(this)
    this.actionController = new ActionController(this)
    this.cellTooltipController = new CellTooltipController(this)

    const filters = this.filterController.buildFilters()

    this.state = {
      // isLoading: false,
      isMuted: false,
      isMouseLeave: false,
      ...this.actionController.defaultState,
      ...this.multiselectController.defaultState,
      ...this.cellTooltipController.defaultState,
      ...this.stateController.defaultState,
      ...this.viewController.defaultState,
      ...this.popupController.defaultState,
      cellsPathsInProcessing: List(),
      cellTooltipProps: {
        cell: Map(),
        cellPosition: {}
      },
      jobProcessing: {
        type: null,
        state: null,
        message: ''
      },
      filters,
      scrollTop: 0,
      offsetHeight: 0,
      offsetWidth: 0,
      overscanRowCount: 0,
      expertiseMismatchDetails: {},
      eventStaffDetails: {}
    }

    this.headerRef = createRef()
    this.crossHatchRef = props.crossHatchRef
    this.StaffViewCellContextMenuRef = createRef()
    this.gridRef = createRef()
  }

  updateScrollTop = (scrollTop) => {
    this.setState({ scrollTop })
  }

  toggleIsCollapsed = (role) => {
    const roleId = role.get('id')
    this.setState(({ filters }) => {
      const isCollapsed = filters.getIn(['collapsed', roleId]) === true
      const newFilters = filters.setIn(['collapsed', roleId], !isCollapsed)
      return {
        filters: newFilters
      }
    })
  }

  updateCalendarDimensions = () => {
    this.setState({
      offsetHeight: this.gridRef.current.offsetHeight,
      offsetWidth: this.gridRef.current.offsetWidth
    })
  }

  componentDidMount() {
    document.addEventListener('click', this.eventController.onClickOutsideTheGrid)

    const { unit } = this.props
    const isUnitReady = unit.get('isReady')
    this.loadExpertises()
    if (isUnitReady) {
      this.filterController.initializeFilters()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.popupController.onComponentUpdated(prevProps, prevState)

    const { unit } = this.props
    const { unit: prevUnit } = prevProps

    const unitId = unit.get('id')
    const prevUnitId = prevUnit.get('id')

    const isUnitChanged = unitId !== prevUnitId
    const isUnitReady = unit.get('isReady')

    if (isUnitReady && isUnitChanged) {
      this.filterController.initializeFilters()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.eventController.onClickOutsideTheGrid)
  }

  componentWillUpdate(nextProps, nextState) {
    this.stateController.onComponentWillUpdate(nextProps, nextState)

    const { preloadData } = nextProps
    const { preloadData: prevPreloadData } = this.props
    const hasPreloadData = preloadData && !prevPreloadData

    if (hasPreloadData) {
      const { offsetHeight, overscanRowCount } = this.state
      this.dataController.preloadShifts(preloadData, overscanRowCount, offsetHeight)
    }
  }

  render() {
    const { match, unit, activeDateRange, facility, timeService, appState, showMismatchModal, getCalendar } = this.props

    const { filters, eventStaffDetails } = this.state
    const { calendar } = this.stateController.calendar
    getCalendar?.(calendar)
    const { mode, unitUrlId: urlId, userId: staffId } = match.params
    const { offsetHeight, offsetWidth } = this.state

    const monthExtendedActiveDateRange = DateRangeService.getMonthExtendedDateRange(activeDateRange, timeService)
    const indicators = filters.get('indicators')
    const indicatorsClasses = composeIndicatorsClasses(indicators)
    const modeClasses = componseModeClasses(mode)
    const className = classNames('col-12 _calendarGridWrapper', {
      ...modeClasses,
      ...indicatorsClasses
    })

    if (this.gridRef.current && (!offsetHeight || !offsetWidth)) {
      this.updateCalendarDimensions()
    }

    const { state: jobProcessingState, message: jobProcessingMessage } = this.state.jobProcessing

    //TODO: reduce the amount of parameters we spread into components
    //      and stop the use of `pick` method, it obscures the source of params

    const { popupConfig, cellTooltipConfig } = this.state
    const popupProps = popupConfig.get('popupProps')
    const popupId = popupConfig.get('popupId')
    const unitManager = appState.getIn(['authentication', 'facilityUser'])
    const unitShiftsMap = appState.getIn(['generalData', 'shiftsById']) || Map()
    const expertises = appState.getIn(['expertises', 'expertiseItems'])

    const isStaffViewCellContextMenuVisible = popupId === 'ContextMenu'
    const StaffViewCellContextMenuProps = getCellContextMenuProps({
      ...this.props,
      ...this.state,
      ...this.actionController,
      ...this,
      calendar,
      popupProps,
      unitManager,
      isStaffViewCellContextMenuVisible
    })

    const isSecondaryStaffWarningVisible = popupId === 'SecondaryStaffWarning'
    const secondaryStaffWarningProps = { onClose: this.hidePopup }

    const isRequirementsMismatchVisible = this.popupController.isRequirementsMismatchVisible
    const requirementsMismatchProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        calendar,
        expertises,
        unitShiftsMap,
        eventStaffDetails,
        onCancel: this.hidePopup
      },
      [
        'actionController',
        'expertiseMismatchDetails',
        'expertises',
        'multiselectController',
        'onCancel',
        'timeService',
        'unitShiftsMap',
        'eventStaffDetails'
      ]
    )

    showMismatchModal?.(isRequirementsMismatchVisible, requirementsMismatchProps)

    const unitId = unit.get('id')
    const eventVariants = unit.get('eventVariants')
    const isCellDetailsVisible = popupId === 'CellDetails'
    const cellDetailsProps = getCellDetailsProps({
      ...this.props,
      ...this.state,
      ...this.actionController,
      calendar,
      popupProps,
      unitId,
      eventVariants,
      unitManager,
      units: this.props.eligibleUnits,
      getStaffEventDetails: this.getStaffEventDetails,
      onCancel: this.hidePopup,
      isCellDetailsVisible,
      shiftsMap: facility.get('shiftsMap')
    })

    const facilityShiftsMap = appState.getIn(['generalData', 'facility', 'shiftsMap']) || Map()
    const cellTooltipProps = {
      timeService,
      isCellDetailsVisible,
      isStaffViewCellContextMenuVisible,
      cellTooltipConfig,
      facilityShiftsMap,
      unitShiftsMap,
      isCellWithSelectionError: this.cellTooltipController.isCellWithSelectionError,
      notes: appState.getIn(['calendar', 'notes']) || List(),
      staffManagersMap: facility.get('staffManagersMap') || Map()
    }

    const jobProcessingProps = pick(
      {
        message: jobProcessingMessage,
        currentState: jobProcessingState
      },
      ['message', 'currentState']
    )

    const schedule = unit.get('schedule')
    const vGridProps = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        ...this.eventController,
        ...this.popupController,
        ...this.dataController,
        schedule,
        calendar,
        getViewModel: this.viewModelController.getViewModel,
        getRole: this.viewController.getRole,
        getShift: this.viewController.getShift,
        unitUrlId: urlId,
        staffId,
        showOpenShiftPopup: this.popupController.showOpenShiftPopup,
        hideOpenShiftPopup: this.popupController.hideOpenShiftPopup,
        updateCellTooltip: this.updateCellTooltip,
        displayNameAs: this.state.filters.getIn(['displayOptions', 'staff', 'displayNameAs']),
        updateFilter: this.filterController.updateFilter,
        facilityShiftsMap,
        notes: appState.getIn(['calendar', 'notes']) || Map(),
        monthExtendedActiveDateRange
      },
      [
        'filters',
        'timeService',
        'showPopup',
        'updateScrollTop',
        'toggleIsCollapsed',
        'selection',
        'loadOtherStaff',
        'activeDateRange',
        'onCellEvent',
        'offsetHeight',
        'unitUrlId',
        'staffId',
        'calendar',
        'getRole',
        'getShift',
        'schedule',
        'loadShift',
        'resetLoadConfig',
        'openShiftPopupProps',
        'loadCalendar',
        'getViewModel',
        'onMouseMoveOverGrid',
        'onMouseLeave',
        'cellsPathsInProcessing',
        'showOpenShiftPopup',
        'hideOpenShiftPopup',
        'updateCellTooltip',
        'overscanRowCount',
        'displayNameAs',
        'updateFilter',
        'facilityShiftsMap',
        'notes',
        'monthExtendedActiveDateRange'
      ]
    )

    return (
      <>
        <ReactResizeDetector handleWidth handleHeight onResize={this.updateCalendarDimensions} />
        <aside className="hx-main-navigation" ref={this.headerRef}>
          <div onMouseMove={this.updateCrossHatchPosition} onMouseLeave={this.hideCrosshatch}>
            <Header
              updateFilter={this.updateFilter}
              activeDateRange={monthExtendedActiveDateRange}
              timeService={timeService}
            />
          </div>
        </aside>
        <section
          ref={this.gridRef}
          className={className}
          id="hx-calendar"
          onMouseMove={this.onMouseMoveOverGrid}
          tabIndex={-1}
          onClick={this.clickOnGrid}
          onMouseLeave={this.onMouseLeave}
        >
          <div>
            <CellTooltip {...cellTooltipProps} />
            <JobProcessingState {...jobProcessingProps} />
            <VGrid {...vGridProps} />
            <StaffViewCellContextMenu ref={this.StaffViewCellContextMenuRef} {...StaffViewCellContextMenuProps} />
            {isSecondaryStaffWarningVisible && <WarningModal {...secondaryStaffWarningProps} />}
            <CellDetails {...cellDetailsProps} />
          </div>
        </section>
      </>
    )
  }

  updateMutedState = (isMuted) => {
    this.setState({ isMuted })
  }

  updateJobProcessingState = (jobProcessing) => {
    this.setState({ jobProcessing })
  }

  onMouseLeave = () => {
    this.hideCrosshatch()
  }

  onMouseMoveOverGrid = (event) => {
    this.updateCrossHatchPosition(event)
  }

  clickOnGrid = (e) => {
    // this.openShiftPopupRef.current.handleClick(e);
  }

  hideCrosshatch = () => {
    this.crossHatchRef.current.hide()
  }

  updateCrossHatchPosition = (e) => {
    this.crossHatchRef.current.updatePosition(e)
  }

  setExpertiseMismatchDetails = (context, eventShiftId, selection, note) => {
    const { errors = [] } = context
    const expertiseMismatchDetails = {
      errors,
      eventShiftId,
      selection,
      note
    }
    this.setState({ expertiseMismatchDetails })
  }

  loadExpertises() {
    return expertiseStore.loadExpertises()
  }

  showCellDetailsPopup = () => this.popupController.showCellDetailsPopup()

  hidePopup = () => this.popupController.hidePopup()

  getStaffEventDetails = (eventStaffDetails) => this.setState({ eventStaffDetails })

  resetSelection = () => this.multiselectController.cancelMultiselect()

  hideContextMenu = () => this.popupController.hidePopup()

  updateCellTooltip = ({ event, cell, staffPath, isMouseLeave = false, meta }) => {
    const current = this.headerRef
    const { calendar, timeService } = this.props
    const cellIndex = event.currentTarget.dataset.cellIndex

    this.cellTooltipController.showCellTooltip({
      event,
      cell,
      current,
      calendar,
      staffPath,
      timeService,
      isMouseLeave,
      cellIndex,
      meta
    })
  }
}

// export default withAppContext(Layout);
export default withRouter(
  withAppContext((props) => {
    const { appState, activeDateRange } = props
    const calendar = appState.get('calendar')
    const isSameDateRange = activeDateRange === calendar.get('dateRange')
    // hotfix: because we removed Manager/Layout
    return (
      <Layout
        {...props}
        facility={appState.getIn(['generalData', 'facility'])}
        eligibleUnits={appState.getIn(['generalData', 'eligibleUnits'])}
        facilityName={appState.getIn(['authentication', 'facility', 'name'])}
        facilitiesCount={appState.getIn(['authentication', 'facilities']).size}
        authentication={appState.get('authentication')}
        generalData={appState.get('generalData')}
        calendar={isSameDateRange ? calendar : calendarStore.defaultState}
        preloadData={appState.getIn(['generalData', 'preloadData'])}
      />
    )
  })
)
