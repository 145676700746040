import { Component } from 'react'
import classNames from 'classnames'
import { withAppContext } from 'AppContext'
import StringInputAutoComplete from 'components/Form/StringInputAutoComplete'
import form from '../formDefinitions'
import './SaveTemplateForm.scss'

class SaveTemplateForm extends Component {
  state = {
    templateName: '',
    isLoading: false,
    saveDisabled: false
  }

  componentDidMount = async () => {
    const { announcementsStore, appState } = this.props
    await announcementsStore.loadAnnouncementTemplates()
    const userTemplates = appState.getIn(['announcementTemplates', 'items'])
    const templateNames = userTemplates.reduce((templateNames, template) => templateNames.concat(template.name), [])

    this.setState({ templateNames })
  }

  onClickSubmit = async (e) => {
    e.preventDefault()
    this.setState({ saveDisabled: true }) // To prevent multiple saving
    const {
      announcementsStore,
      payload: formData,
      formatDetailsComponents,
      reloadTemplatesList,
      onClose,
      updateMasterState,
      appState
    } = this.props

    const activeComponent = {
      id: 'defaultForm',
      type: 'CreateAnnouncementForm',
      content: {}
    }

    const { hasDetailsPage, detailsComponents, ...payload } = formData
    payload.detailsComponents = hasDetailsPage ? formatDetailsComponents(detailsComponents) : null

    payload.name = this.state.templateName
    await announcementsStore.createAnnouncementTemplate(payload)
    const lastAnnouncement = appState.getIn(['announcementTemplates', 'lastUpdatedAnnouncementTemplate'])
    if (lastAnnouncement) {
      const { subject, subtitle, content, location, badgeUrl, name } = lastAnnouncement
      updateMasterState({
        subject,
        subtitle,
        content,
        location,
        badgeUrl,
        activeComponent,
        loadedTemplate: name,
        detailsComponents,
        isFormDirty: false
      })
    }

    await reloadTemplatesList()
    onClose() // Close Modal
  }

  // Handles User Input & Checks if Template Name already exists
  setFieldValue = (event) => {
    this.setState({
      templateName: event.target.value,
      nameExists: this.state.templateNames.includes(event.target.value)
    })
  }

  onKeyPress = (event) => {
    // Press Enter to click "Save" Button
    if (event.which === 13 /* Enter */) {
      event.preventDefault()
      if (!this.state.nameExists) {
        this.onClickSubmit(event)
      }
    }
  }

  render() {
    const { onClose, previewJSX, detailsJSX, hasDetailsPage } = this.props
    const { isLoading, templateName, nameExists, saveDisabled } = this.state
    const inputFieldProps = {
      placeholder: 'Enter template name',
      onChange: this.setFieldValue,
      warning: nameExists,
      definition: {
        ...form.saveTemplate,
        type: 'string',
        withLabel: true,
        value: templateName
      }
    }

    return (
      <div className="save-template-wrapper row">
        <div className="left-side">
          <div className={hasDetailsPage ? 'preview-announcements' : 'only-preview'}>{previewJSX}</div>
          {hasDetailsPage ? <div className="extended-preview-announcements">{detailsJSX}</div> : null}
        </div>
        <form className="right-side" onKeyPress={this.onKeyPress}>
          <div className="input-template-name-wrapper">
            <StringInputAutoComplete {...inputFieldProps} />
            {nameExists && (
              <div className="warning-text save-template-warning">A template with this name already exists.</div>
            )}
          </div>
          <footer className="submit-buttons">
            <button onClick={onClose} className="button secondary borderless p5 _cancel">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmit}
              disabled={nameExists || saveDisabled}
              type="submit"
              className={classNames('button primary float-right _submit', {
                loading: isLoading
              })}
            >
              Save
            </button>
          </footer>
        </form>
      </div>
    )
  }
}

export default withAppContext(SaveTemplateForm)
