import { Component } from 'react'
import { chunk } from 'lodash'
import classNames from 'classnames'
import { ShiftService } from 'services'

export default class ShiftHeader extends Component {
  render() {
    const { shift, days, timeService } = this.props

    const weeks = chunk(days, 7)
    const weeksCount = weeks.length

    const width = 100 / weeksCount
    const style = { width: `${width}%` }

    const shiftTypeId = shift.get('typeId')

    const shiftService = new ShiftService(shift, timeService)
    const time = shiftService.getShiftTime()

    return (
      <div className="hx-calendar-shift bg-mystic">
        <div className="row align-middle">
          <aside className="col-2">
            <span className="upper smaller bold river-bad">
              <i
                className={classNames({
                  'icon-moon': shiftTypeId === 'NIGHT',
                  'icon-sun': shiftTypeId === 'DAY'
                })}
              />
              {shift.get('name')}
            </span>
            <span className="smaller bold river-bad">{time}</span>
          </aside>
          <section className="col-10">
            <div className="row text-center">
              {weeks.map((days, weekIndex) => (
                <div key={weekIndex} style={style}>
                  <div className="row-7">
                    {days.map((day, dayIndex) => {
                      return (
                        <div key={dayIndex} className="col-1">
                          <span className="label bold rounded bg-mystic" />
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    )
  }
}
