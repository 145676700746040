import { navigate } from 'Navigation'
import RequestTimeOff from './RequestTimeOff/RequestTimeOff';
import TimeOffList from './TimeOffList/TimeOffList';
import { t } from 'i18n'
import { CalendarItemIcon } from 'Staff/Common'
import { useLocation } from 'react-router';
import { withAppContext } from 'AppContext';
import DataController from 'Staff/Calendar/Controllers';
import { RefObject, useRef } from 'react';

const timeOffTabs = [
    { id: 'timeOffList', label: `${t('staffCalendar.timeOff.timeOffList')}` },
    { id: 'requestTimeOff', label: `${t('staffCalendar.timeOff.requestTimeOff')}` }
]

type TimeOffDetailsProps = {
    usDate: string,
    timeService: any,
    staffTimeOffRequestsMap: any,
    setSelectedCellDate: any,
    selectedCellDate: any
}

function TimeOffDetails(props: TimeOffDetailsProps) {

    const thisRef = useRef({ props })
    const dataControllerRef: RefObject<DataController> = useRef(new DataController(thisRef.current))
    thisRef.current.props = props

    const { usDate: date, timeService, staffTimeOffRequestsMap, setSelectedCellDate, selectedCellDate } = props
    const location = useLocation()
    const pathname = location.pathname
    let componentId = 'timeOffList'

    const path = pathname.split('/')

    if (path[path.length - 1] === 'new') {
        componentId = 'requestTimeOff'
    }

    const timeOffIcon = <CalendarItemIcon calendarItemType='timeOff' size='s' />

    const currentDate = timeService.today;

    const isPastMonth = timeService.dateTimeToMoment(date).isBefore(currentDate, 'month');

    const handleTabClick = (id: string) => {
        if (id === 'requestTimeOff') {
            navigate.from.StaffCalendarPage.to.StaffCalendarPageNew({}, true)
        } else {
            navigate.from.StaffCalendarPage.to.StaffCalendarPage({}, true)
        }
    }

    return (
        <div className="h100 hx-box-shadow bg-white">
            <sh-tabs slot="tabs">
                <div className="row align-middle pl15">
                    {timeOffIcon}
                </div>
                {timeOffTabs.map(({ id, label }) => (
                    (id !== 'requestTimeOff' || !isPastMonth) && (
                        <sh-tab-item
                            label={label}
                            key={id}
                            active={componentId === id ? true : undefined}
                            onClick={() => handleTabClick(id)}
                        >
                        </sh-tab-item>
                    )
                ))}
            </sh-tabs>

            {componentId === 'requestTimeOff' && !isPastMonth ? (
                <RequestTimeOff selectedTimeOffDate={selectedCellDate} setSelectedCellDate={setSelectedCellDate} date={date} timeService={timeService} dataController={dataControllerRef.current} />
            ) : (
                <TimeOffList timeService={timeService} date={date} staffTimeOffRequests={staffTimeOffRequestsMap} />
            )}
        </div>)
}

export default withAppContext(TimeOffDetails)