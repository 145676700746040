const getStaffEventIndex = (cell, selectedEventTemplateId) => {
  let staffEventIndex = 0
  if (selectedEventTemplateId) {
    const [eventType, eventShiftId] = selectedEventTemplateId.split('|')
    staffEventIndex = cell
      .get('staffEvents')
      ?.findIndex((item) =>
        eventType === 'timeOff' ? item.get('isPTO') || item.get('isUnavailable') : eventShiftId === item.get('shiftId')
      )
  }
  return staffEventIndex
}

const hasFullDayOff = (cell) => {
  return cell
    .get('staffEvents')
    ?.some((e) => (e.get('isPTO') || e.get('isUnavailable')) && !e.get('isPartialShiftEvent'))
}

const hasPartialTimeOff = (cell) => {
  return cell
    .get('staffEvents')
    ?.some((e) => e.get('isPartialShiftEvent') && (e.get('isPTO') || e.get('isUnavailable')))
}

const hasTimeOff = (cell) => {
  return hasPTO(cell) || hasUnavailable(cell)
}

const hasPTO = (cell) => {
  return cell.get('staffEvents')?.some((e) => e.get('isPTO'))
}

const hasUnavailable = (cell) => {
  return cell.get('staffEvents')?.some((e) => e.get('isUnavailable'))
}

const hasShiftEvent = (cell) => {
  return cell.get('staffEvents')?.some((e) => e.get('isAssignment') || e.get('isMeeting') || e.get('isOnCall'))
}

const getEventStartTime = (startsAt, dateTime, timeService) => {
  const startsAtMoment = timeService.timeMoment(startsAt)
  const startOfDay = timeService.timeMoment(dateTime).startOf('day')

  const dlsOffset = timeService.calculateDLSChanging(startOfDay.toISOString(), startsAt)
  const offsetInMinutes = startsAtMoment.diff(startOfDay, 'minutes') + dlsOffset

  const hours = Math.abs(Math.trunc(offsetInMinutes / 60))
  const minutes = Math.abs(offsetInMinutes % 60)

  const sign = offsetInMinutes < 0 ? '-' : '+'
  const hh = hours < 10 ? `0${hours}` : hours
  const mm = minutes < 10 ? `0${minutes}` : minutes

  return `${sign}${hh}:${mm}`
}

export {
  getStaffEventIndex,
  hasFullDayOff,
  hasPartialTimeOff,
  hasTimeOff,
  hasPTO,
  hasUnavailable,
  hasShiftEvent,
  getEventStartTime
}
