import { Component } from 'react'
import { withAppContext } from 'AppContext'
import { FacilityAdmin, Staff, UnitManager } from 'auth/Roles'
import DateRangeService from 'services/DateRangeService'
import { t } from 'i18n'
import Avatar from '../components/Avatar'
import { Map } from 'immutable'

export class Activity extends Component {
  state = { isFullListOfRecipientsShown: false }

  componentDidMount() {
    document.addEventListener('click', this.onClickMore, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickMore)
  }

  onClickMore = (e) => {
    const { activity } = this.props
    const id = activity.get('id')
    const className = `_more-${id}`

    if (e.target.classList.contains(className)) {
      this.setState({ isFullListOfRecipientsShown: true })
    }
  }

  render() {
    const { activity, timeService } = this.props
    const id = activity.get('id')

    const createdAt = activity.get('createdAt')
    const isPostedActivityType = activity.get('activityType') === 'openShiftPosted'
    let description = activity.get('description')
    const initiatorFullname = activity.get('initiatorFullname')
    const [initiatorFirstName, initiatorLastName] = initiatorFullname.split(' ')
    const initiatorProfile = Map({
      avatarUrl: activity.get('initiatorAvatarUrl'),
      firstName: initiatorFirstName,
      lastName: initiatorLastName
    })
    const explanation = activity.get('explanation')
    const positions = [UnitManager, Staff, FacilityAdmin]
    const position = activity.get('initiatorPosition')
    const initiatorPosition = positions.includes(position) ? `(${getStringFromCapitalize(position)})` : ''
    const note = activity.get('note')
    const activityType = activity.get('activityType')
    description = description.replace(/<span class="date">([^Z]*Z)<\/span>/g, this._formatDate)

    if (isPostedActivityType) {
      const recipientCount = (description.match(/<span class="recipient">/g) || []).length
      let recipientIndex = 0

      description = description
        .replace(/<span class="recipient">[\s\S]*?<\/span>/g, (match) => {
          recipientIndex++
          return this._formatRecipient(match, recipientIndex - 1, recipientCount)
        })
        .replace(/, ,/g, '')
        .replace(/, <a /g, ' <a ')
        .replace(/, for/g, ' for')
    }

    const formattedHour = timeService.timeMoment(createdAt).format('HH:mm')
    const { startsAt, endsAt } = this._getDateRange()
    const hasDateRange = startsAt && endsAt

    let calendarRange = ' - '
    if (hasDateRange) {
      const startsAtMoment = timeService.timeMoment(startsAt)
      const endsAtMoment = timeService.timeMoment(endsAt)

      const startsAtFormatted = startsAtMoment.format('MMM D')
      const endsAtFormatted = endsAtMoment.subtract(1, 'd').format('MMM D')

      calendarRange = `${startsAtFormatted} - ${endsAtFormatted}`
    }

    return (
      <>
        <div
          className="activities-item"
          key={id}
          data-activity-type={activityType}
          data-schedule-start-date={startsAt}
          data-schedule-end-date={endsAt}
        >
          <sh-text size="body-2" class="activity-item-time" id="activity-item-time">
            {formattedHour}
          </sh-text>
          <div className="activities-item-content">
            <Avatar profile={initiatorProfile} />
            <div>
              <sh-text size="title-1" class="activity-item-name" id="activity-item-name">
                {initiatorFullname}
              </sh-text>
              <sh-text class="activity-item-position" id="activity-item-position">
                {initiatorPosition}
              </sh-text>
              <div
                data-testid="activity-item-detail"
                className="activity-item-detail"
                dangerouslySetInnerHTML={{
                  __html: description
                }}
              />
              {explanation && (
                <div className="activity-explanation">
                  <span className="explanation-title">{t('activities.activity.explanation')}</span> {explanation}
                </div>
              )}
              {note && (
                <div className="activity-note">
                  <div className="note-sidebar" />
                  <div className="note-content">
                    <span className="note-title">{t('activities.activity.note')}</span>
                    <span className="note-text">{note}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="activities-date-range">
            <i className="icon icon-calendar" />
            {calendarRange}
          </div>
        </div>
        <div className="separator" />
      </>
    )
  }

  _formatDate = (match, date, recipientCount) => {
    const { timeService } = this.props
    const formatted = timeService.timeMoment(date).format('MMM DD, YYYY')
    return `<span class="date">${formatted}</span>`
  }

  _formatRecipient = (match, recipientIndex, recipientCount) => {
    const { activity } = this.props
    const { isFullListOfRecipientsShown } = this.state

    if (isFullListOfRecipientsShown) {
      return match
    }

    const id = activity.get('id')
    let recipientHtml

    switch (true) {
      case recipientIndex < 2:
        recipientHtml = match
        break

      case recipientIndex === 2:
        recipientHtml = `<a class="cursor-pointer more _more-${id}">and ${recipientCount - 2} more</a>`
        break

      default:
        recipientHtml = ''
    }

    return recipientHtml
  }

  _getDateRange() {
    const { activity, unit, timeService } = this.props

    const description = activity.get('description')
    const scheduleStartsAt = activity.getIn(['parameters', 'scheduleStartsAt'])
    const scheduleEndsAt = activity.getIn(['parameters', 'scheduleEndsAt'])

    if (scheduleStartsAt && scheduleEndsAt) {
      return { startsAt: scheduleStartsAt, endsAt: scheduleEndsAt }
    }

    const isUnitLoaded = unit?.get('id')
    if (!isUnitLoaded) {
      return { startsAt: null, endsAt: null }
    }

    const createdAt = activity.get('createdAt')
    const compareDate = description.match(/<span class="date">([^/]*Z)<\/span>/, (match, date) => date) || [
      '',
      createdAt
    ]

    const usDate = timeService.timeMoment(compareDate[1]).format('MM-DD-YYYY')
    const dateRange = new DateRangeService(unit, timeService, usDate).getDateRange()

    const startsAt = dateRange.get('startsAt')
    const endsAt = dateRange.get('endsAt')

    return { startsAt, endsAt }
  }
}

export default withAppContext(Activity)

const getStringFromCapitalize = (string) => {
  if (!string) {
    return ''
  }
  string = string.replace(/([A-Z])/g, ' $1')
  return string.charAt(0).toUpperCase() + string.slice(1)
}
