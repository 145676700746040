import { PropsWithChildren } from 'react'
import GraphCard, { Dataset } from './GraphCard'
import { ICommonCardProps } from './CommonCard'
import getOptions from './getDonutGraphOptions'
import { sum } from 'lodash'

const options = getOptions()

export interface IDonutGraphCardProps extends ICommonCardProps {
  labels: string[]
  dataset: Dataset
  isValid?: boolean
}

export default function DonutGraphCard({
  dataset,
  labels,
  //common card props start here
  title,
  isValid = true,
  onClickActionButton,
  loadingSpinnerText,
  noContentLabel,
  actionButtonIcon,
  isLoading,
  titleIcon,
  isActionButtonDisabled,
  height
}: PropsWithChildren<IDonutGraphCardProps>) {
  const isDonutDataValid = dataset.data.length > 0 && sum(dataset.data) > 0

  return (
    <GraphCard
      type={'donut' as const}
      datasets={{ donut: [dataset] }}
      options={options}
      labels={{ donut: labels.map((l) => [l]) }}
      graphHeight={{ donut: 240 }}
      //common card props
      title={title}
      height={height}
      isValid={isValid && isDonutDataValid}
      onClickActionButton={onClickActionButton}
      loadingSpinnerText={loadingSpinnerText}
      noContentLabel={noContentLabel}
      actionButtonIcon={actionButtonIcon}
      isLoading={isLoading}
      titleIcon={titleIcon}
      isActionButtonDisabled={isActionButtonDisabled}
    />
  )
}
