import './SwitchInput.scss'
import classNames from 'classnames'

export default function SwitchInput({ switchClasses, value, setConfig, fieldName, field, disabled }) {
  return (
    <div
      className={classNames('align-right hx-switch', switchClasses, {
        disabled: disabled
      })}
    >
      <input
        checked={value}
        onChange={() => setConfig(!value)}
        className="hx-switch-checkbox"
        disabled={disabled}
        id={`hx-switch-new[${field}]`}
        type="checkbox"
      />
      <label
        className={classNames('hx-switch-label', {
          'bg-antiflash-white': !value,
          'bg-light-green': value
        })}
        htmlFor={`hx-switch-new[${field}]`}
      >
        <span
          className={classNames('hx-switch-button', {
            'bg-cadetblue': !value,
            'bg-ufo-green': value
          })}
        />
      </label>
      <label>{fieldName}</label>
    </div>
  )
}
