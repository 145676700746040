import { createRef, Component } from 'react'
import ErrorBoundary from './ErrorBoundary'
import Main from './Main'

export default class HumanicsApp extends Component {
  constructor(props) {
    super(props)
    this.mainRef = createRef()
  }

  render() {
    return (
      <ErrorBoundary>
        <Main {...this.props} ref={this.mainRef} />
      </ErrorBoundary>
    )
  }
}
