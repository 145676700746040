import { pick, times } from 'lodash'
import ScheduledStaff from './ScheduledStaff'
import StaffGroup from './StaffGroup'

export default class ScheduledStaffGroup extends StaffGroup {
  constructor(props) {
    super(props)
    const { isPosted } = props
    this.state = { isCollapsed: !isPosted, isCollapsedOnes: false }
  }

  render() {
    const { staff, requiredStaff } = this.props
    const diff = Math.max(requiredStaff - staff.size, 0)

    return (
      <section data-testid="hx-open-shift-scheduled-staff-group" className="hx-open-shift-scheduled-staff-group row">
        {staff.map(this.renderStaff)}
        {requiredStaff > 0 && times(diff, this.renderEmptyStaff)}
      </section>
    )
  }

  toggleStaffGroupSelector() {
    const { staff, openShiftController } = this.props
    let { isStaffSelected, selectedUserIds } = openShiftController
    const isAllStaffGroupSelected = staff.every((staff) => isStaffSelected(staff))
    const groupStaffIds = staff.map((s) => s.get('id'))

    selectedUserIds = isAllStaffGroupSelected
      ? selectedUserIds.filter((id) => !groupStaffIds.includes(id))
      : selectedUserIds.concat(groupStaffIds)

    openShiftController.updateSelectedUserIds(selectedUserIds)
  }

  renderEmptyStaff = (index) => (
    <div data-testid="hx-calendar-info-wrapper" className="hx-calendar-staff-info-wrapper empty" key={index}>
      <img className="rounded-16 image-32" alt="" />
      <div className="staff-meta">
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  )

  renderStaff = (staff) => {
    const staffProps = pick(
      {
        ...this.props,
        staff
      },
      ['eligibleUnits', 'timeService', 'staff', 'isPosted', 'openShiftController', 'showApproveButton', 'type']
    )

    return <ScheduledStaff key={staff.get('userId')} {...staffProps} />
  }

  toggleStaff = () => {
    const isCollapsed = !this.state.isCollapsed
    this.setState({ isCollapsed, isCollapsedOnes: true })
  }
}
