import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { withAppContext } from 'AppContext'
import './FileUpload.scss'
import imagePlaceholder from '../../../styles/web_v3/image_placeholder.png'

class FileUpload extends Component {
  state = {
    drag: false,
    fileList: []
  }

  dropRef = createRef()

  static defaultProps = {
    fileType: 'file',
    fileIconSrc: imagePlaceholder
  }

  static propTypes = {
    fileType: PropTypes.string,
    fileIconSrc: PropTypes.string,
    handleDrop: PropTypes.func.isRequired
  }

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true })
    }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({ drag: false })
    }
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let validFiles = this.validateFileInput(e.dataTransfer.files)
      if (validFiles) {
        this.props.handleDrop(e.dataTransfer.files)
      } else {
        alert(`Invalid File Type: please upload only ${this.props.fileType}(s).`)
      }
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }

  handleUpload = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.target.files && e.target.files.length > 0) {
      let validFiles = this.validateFileInput(e.target.files)
      if (validFiles) {
        this.props.handleDrop(e.target.files)
      } else {
        alert(`Invalid File Type: please upload only ${this.props.fileType}(s).`)
      }
    }
  }

  validateFileInput = (inputFiles) => {
    const validFileType = this.props.fileType.toLowerCase()
    const listOfFiles = Object.values(inputFiles)
    if (validFileType === 'file') {
      return true
    } else {
      return listOfFiles.every((inputFile) => inputFile.type.startsWith(validFileType))
    }
  }

  componentDidMount() {
    this.dragCounter = 0
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }

  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  render() {
    return (
      <div className="file-upload-component" ref={this.dropRef} style={{ ...this.props.style }}>
        <div className="img-icon-container">
          <img src={this.props.fileIconSrc} alt="File Icon" className="file-type-placeholder" />
        </div>
        <div className="file-upload-text-container">
          <span className="file-upload-text">
            Drop {this.props.fileType}(s)
            <br />
            Or
          </span>
        </div>
        <div className="browse-file-container">
          <input
            type="file"
            className="browse-file-upload"
            id="browse-file-upload"
            onChange={this.handleUpload}
            multiple
          />
          <label className="browse-file-btn" htmlFor="browse-file-upload">
            Browse
          </label>
        </div>
        {this.state.drag && <div className="drag-overlay">Release to drop</div>}
      </div>
    )
  }
}

export default withAppContext(FileUpload)
