import GridRow from './GridRow'
import './Grid.scss'
export interface ICellProps {
  timeService?: any
  date?: any
}

export default function Grid({
  date,
  timeService,
  initiatedShiftSwapRequests,
  receivedShiftSwapRequests,
  staffEvents,
  staffDaysMap,
  section,
  setSelectedCellDate 
}: any) {
  const calendarRows = 6

  return (
    <div className="grid-box hx-box-shadow">
      <table>
        {[...Array(calendarRows)].map((_, rowIndex) => (
          <GridRow
            key={rowIndex}
            rowIndex={rowIndex}
            date={date}
            timeService={timeService}
            staffEvents={staffEvents}
            initiatedShiftSwapRequests={initiatedShiftSwapRequests}
            receivedShiftSwapRequests={receivedShiftSwapRequests}
            staffDaysMap={staffDaysMap}
            section={section}
            setSelectedCellDate={setSelectedCellDate}
          />
        ))}
      </table>
    </div>
  )
}
