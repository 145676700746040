import { PureComponent } from 'react'
import Day from './Day'

export default class Week extends PureComponent {
  render() {
    const { days } = this.props
    return (
      <div className="hx-calendar-header-week br4">
        <div className="row-7 big bg-alabaster">
          {days.map((day, i) => (
            <Day key={i} day={day} />
          ))}
        </div>
      </div>
    )
  }
}
