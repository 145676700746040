import classNames from 'classnames'

export default function Header(properties) {
  const { title, onClick, iconClassName, isDisabled = true, iconWrapperClassName = '' } = properties

  const className = classNames('context__item', {
    'context__item--disabled': isDisabled
  })

  return (
    <div
      className={className}
      onClick={(e) => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <div className="context__item__data">
        {iconClassName && (
          <span className={iconWrapperClassName}>
            <i className={iconClassName} />
          </span>
        )}
        <span>{title}</span>
      </div>
    </div>
  )
}
