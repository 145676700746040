export default class StaffEvent {
  constructor(staffEvent) {
    this._staffEvent = staffEvent
  }

  set staffEvent(staffEvent) {
    this._staffEvent = staffEvent
  }

  get id() {
    if (!this.staffEvent) {
      return null
    }
    return StaffEvent.buildId(this.userId, this.staffEventStartsAt)
  }

  get staffEvent() {
    return this._staffEvent
  }

  get isExisted() {
    return !!this.staffEvent
  }

  get userId() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('userId')
  }

  get staffEventId() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('id')
  }

  get shiftId() {
    if (!this.staffEvent) {
      return null
    }
    if (this.isMeeting) {
      return null
    }
    return this.staffEvent.get('shiftId')
  }

  get unitRoleId() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('unitRoleId')
  }

  get unitId() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('unitId')
  }

  get staffEventType() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('type')
  }

  get staffEventStartsAt() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('startsAt')
  }

  get staffEventEndsAt() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('endsAt')
  }

  get staffEventDuration() {
    if (!this.staffEvent) {
      return 0
    }
    return this.staffEvent.get('duration')
  }

  get staffEventShiftId() {
    if (this.isShiftEvent) {
      return this.shiftId
    }
    return null
  }

  get staffEventUnitId() {
    if (this.isShiftEvent) {
      return this.unitId
    }
    return null
  }

  get staffEventNoteIds() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('noteIds')
  }

  get staffEventIdentityHash() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('identityHash')
  }

  get isPrimaryShiftAssignment() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('isPrimaryShiftAssignment')
  }

  get isAssignment() {
    if (!this.staffEvent) {
      return false
    }
    return this.staffEvent.get('type') === 'assignment'
  }

  get isOnCall() {
    if (!this.staffEvent) {
      return false
    }
    const eventType = this.staffEvent.get('type')
    return eventType === 'onCall'
  }

  get eventVariantId() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('unitEventVariantId')
  }

  get isMeeting() {
    if (!this.staffEvent) {
      return false
    }
    return this.staffEvent.get('type') === 'meeting'
  }

  get isAvailable() {
    if (!this.staffEvent) {
      return true
    }
    return !this.isShiftEvent && !this.isTimeOff
  }

  get isAvailableForScheduling() {
    return this.isAvailable
  }

  get isScheduled() {
    if (!this.staffEvent) {
      return null
    }
    return this.isShiftEvent
  }

  get isShiftEvent() {
    if (!this.staffEvent) {
      return null
    }
    return this.isAssignment || this.isMeeting || this.isOnCall
  }

  get isTimeOff() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('type') === 'timeOff'
  }

  get isUnavailable() {
    if (!this.staffEvent) {
      return null
    }
    return this.isTimeOff && this.staffEvent.getIn(['timeOffAttributes', 'paidMinutes']) === 0
  }

  get isPTO() {
    if (!this.staffEvent) {
      return null
    }
    return this.isTimeOff && this.staffEvent.getIn(['timeOffAttributes', 'paidMinutes']) > 0
  }

  get isCancelledType() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('status') === 'canceled'
  }

  get hasNotes() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEventNoteIds.size > 0
  }

  get updatedBy() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('updatedBy')
  }

  get updatedAt() {
    if (!this.staffEvent) {
      return null
    }
    return this.staffEvent.get('updatedAt')
  }

  static buildId(userId, startsAt) {
    return `${userId}|${startsAt}`
  }

  static parseId(id) {
    const [userId, startsAt] = id.split('|')
    return { userId, startsAt }
  }

  static builder(staffEventId) {
    return this.buildStaffEvent(staffEventId)
  }

  static buildStaffEvent(staffEventId) {
    return (staffEventsMap, timeService) => {
      if (staffEventId) {
        const { userId, startsAt } = this.parseId(staffEventId)
        const startDate = timeService.timeMomentUnsafe(startsAt).format('YYYY-MM-DD')
        const staffEvent = staffEventsMap.getIn([userId, startDate])
        if (staffEvent) {
          return new this(staffEvent)
        }
      }

      return new this(null)
    }
  }
}
