import { Component, createRef, Fragment } from 'react'
import { withAppContext } from 'AppContext'
// import { Pagination } from 'Manager/components';
import Subheader from '../Subheader'
import LogContent from './LogContent'
import stores, { addWarningBanner } from 'stores'
import { curry } from 'i18n'

const t = curry('announcements.banners.announcement.')

const itemsCountPerPage = 20

class AnnouncementsLogLayout extends Component {
  constructor(props) {
    super(props)
    this.announcementsScrollerRef = createRef()
    this.state = {
      finishedFetchingMoreAnnouncements: false,
      loadedAnnouncements: 0,
      failedAnnouncements: 0
    }
  }

  componentDidMount() {
    const { unit } = this.props
    const isUnitReady = unit.get('isReady')

    if (isUnitReady) {
      this.loadAnnouncements()
      this.handleSendFailure()
    }
    global.addEventListener('scroll', this.trackScrolling)
  }

  componentDidUpdate(prevProps, prevState) {
    const { unit } = this.props
    const { unit: prevUnit } = prevProps

    const unitId = unit.get('id')
    const prevUnitId = prevUnit.get('id')

    const isUnitChanged = unitId !== prevUnitId
    const isUnitReady = unit.get('isReady')

    if (isUnitReady) {
      if (isUnitChanged) {
        this.loadAnnouncements(0, false, itemsCountPerPage)
      }
      this.handleSendFailure()
    }
  }

  handleSendFailure() {
    const { appState } = this.props
    const failedAnnouncements = appState.getIn(['generalData', 'notifications', 'announcements'])
    if (typeof failedAnnouncements === 'number' && failedAnnouncements !== this.state.failedAnnouncements) {
      this.setState({ failedAnnouncements })
      if (failedAnnouncements > 0) {
        addWarningBanner({ message: t('send_error') })
      }
    }
  }

  componentWillUnmount() {
    global.removeEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = (e) => {
    const { finishedFetchingMoreAnnouncements } = this.state
    const { appState } = this.props

    const announcementsTotalCount = appState.getIn(['announcements', 'pager']).totalCount
    const currentAnnouncementsCount = appState.getIn(['announcements', 'items']).length
    const haventFetchedAllTheAnnouncements = announcementsTotalCount > currentAnnouncementsCount

    const announcementsScrollerBottom = this.announcementsScrollerRef.current.getBoundingClientRect().bottom
    const announcementCardHeight = 200 //px
    const announcementsBelowBeforeFetching = 3
    const heightToFetchMoreAnnnouncements =
      announcementsScrollerBottom - announcementCardHeight * announcementsBelowBeforeFetching
    const globalBottom = global.innerHeight
    const isLittleScrollingSpaceLeft = heightToFetchMoreAnnnouncements <= globalBottom

    const shouldLoadMoreAnnouncements = isLittleScrollingSpaceLeft && haventFetchedAllTheAnnouncements
    if (shouldLoadMoreAnnouncements && finishedFetchingMoreAnnouncements) {
      this.setState({ finishedFetchingMoreAnnouncements: false }, this.loadAnnouncements)
    }
  }

  loadAnnouncements = async (offset, append, limit = itemsCountPerPage) => {
    const { announcementsStore, unit } = this.props
    const { loadedAnnouncements } = this.state
    if (offset === undefined) {
      offset = loadedAnnouncements
    }
    if (append === undefined) {
      append = loadedAnnouncements > 0
    }
    const unitId = unit.get('id')
    const timeService = stores.timeService()
    const startDate = timeService.trueNow().subtract(5, 'months').startOf('month').toISOString()
    const endDate = timeService.trueNow().toISOString()
    await announcementsStore.loadAnnouncements(startDate, endDate, append, unitId, limit, offset, null)
    this.setState({
      finishedFetchingMoreAnnouncements: true,
      loadedAnnouncements: offset + limit
    })
  }

  resetLoadedAnnouncements = () => {
    this.setState(
      { finishedFetchingMoreAnnouncements: false },
      this.loadAnnouncements.bind(this, 0, false, this.state.loadedAnnouncements)
    )
  }

  groupByDate = (announcementsList) => {
    const timeService = stores.timeService()
    const timeNow = timeService.trueNow().toISOString()
    const timeMoment = timeService.timeMoment(timeNow)

    const startOfWeek = timeMoment.startOf('week').toISOString()

    const monthFormat = 'MMM YYYY'
    const startOfMonth1 = timeMoment.startOf('month').toISOString()
    const monthString1 = timeMoment.startOf('month').format(monthFormat)

    const startOfMonth2 = timeMoment.subtract(1, 'months').startOf('month').toISOString()
    const monthString2 = timeMoment.startOf('month').format(monthFormat)

    const startOfMonth3 = timeMoment.subtract(1, 'months').startOf('month').toISOString()
    const monthString3 = timeMoment.startOf('month').format(monthFormat)

    const startOfMonth4 = timeMoment.subtract(1, 'months').startOf('month').toISOString()
    const monthString4 = timeMoment.startOf('month').format(monthFormat)

    const startOfMonth5 = timeMoment.subtract(1, 'months').startOf('month').toISOString()
    const monthString5 = timeMoment.startOf('month').format(monthFormat)

    const startOfMonth6 = timeMoment.subtract(1, 'months').startOf('month').toISOString()
    const monthString6 = timeMoment.startOf('month').format(monthFormat)

    return announcementsList.reduce((announcementBuckets, announcement) => {
      const { updatedAt } = announcement
      const updatedMoment = timeService.timeMoment(updatedAt)
      let bucket = ''
      switch (true) {
        case updatedMoment.isAfter(startOfWeek):
          bucket = 'This Week'
          break
        case updatedMoment.isBetween(startOfMonth1, startOfWeek):
          bucket = monthString1
          break
        case updatedMoment.isBetween(startOfMonth2, startOfMonth1):
          bucket = monthString2
          break
        case updatedMoment.isBetween(startOfMonth3, startOfMonth2):
          bucket = monthString3
          break
        case updatedMoment.isBetween(startOfMonth4, startOfMonth3):
          bucket = monthString4
          break
        case updatedMoment.isBetween(startOfMonth5, startOfMonth4):
          bucket = monthString5
          break
        case updatedMoment.isBetween(startOfMonth6, startOfMonth5):
          bucket = monthString6
          break
        default:
          break
      }

      if (!announcementBuckets[bucket]) {
        announcementBuckets[bucket] = [announcement]
      } else {
        announcementBuckets[bucket].push(announcement)
      }

      return announcementBuckets
    }, {})
  }

  render() {
    const { appState, generalStore, unit } = this.props
    const refreshNotifications = () => generalStore.refreshNotifications(unit.get('id'))
    const announcements = appState.getIn(['announcements', 'items'])
    if (announcements.size === 0) {
      return null
    }
    const announcementBuckets = this.groupByDate(announcements)

    return (
      <>
        <Subheader headerText="Announcements" canCreate />
        <div className="announcements-content row align-center" ref={this.announcementsScrollerRef}>
          <div data-testid="announcements-log" className="announcements-log" ref={(e) => (this.contentRef = e)}>
            {Object.keys(announcementBuckets).map((keyName) => {
              return (
                <Fragment key={keyName}>
                  <b className="bucket-headers">{keyName}</b>
                  {announcementBuckets[keyName].map((item) => {
                    return (
                      <LogContent
                        key={item.id}
                        {...item}
                        loadAnnouncements={this.resetLoadedAnnouncements}
                        refreshNotifications={refreshNotifications}
                      />
                    )
                  })}
                </Fragment>
              )
            })}
          </div>
        </div>
      </>
    )
  }
}

export default withAppContext(AnnouncementsLogLayout)
