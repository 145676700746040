import './CalendarItemBar.scss'

export interface ICalendarItemBarProps {
  className?: string
  icon: string
  barColor: string
  barTitle: string
}

export default function CalendarItemBar({ className, icon, barColor, barTitle }: ICalendarItemBarProps) {
  let baseClassName = 'calendar-item-bar-label'
  if (className) {
    baseClassName += ` ${className}`
  }

  return (
    <div
      className="event-tag mt2 w100"
      style={{
        backgroundColor: barColor
      }}
    >
      <sh-icon icon={icon} size="xs" data-testid="sh-icon" />
      <div className={baseClassName}>{barTitle}</div>
    </div>
  )
}
