import { fromJS } from 'immutable'
import { facilityUsersQuery } from '../Queries'
import { GQLDataLoader } from 'utils'

export default OtherUnitsStaff()

function OtherUnitsStaff() {
  let gqlClient = null

  const actions = { loadOtherUnitsStaff }
  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  async function loadOtherUnitsStaff(userIds) {
    const parameters = { roleIds: ['staff'], userIds }
    const gqlDataLoaded = new GQLDataLoader(gqlClient)

    const staff = await gqlDataLoaded.loadAllItems('facilityUsers', facilityUsersQuery, parameters)

    return fromJS(staff)
  }
}
