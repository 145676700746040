import { List } from 'immutable'
import { chunk } from 'lodash'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import LoadStaffHours from './LoadStaffHours'
import LoadCalendarCells from './LoadCalendarCells'
import LoadCalendarSections from './LoadCalendarSections'

export default LoadFlatShifts()

function LoadFlatShifts() {
  let loadPrimaryStaffCells = null
  let loadOtherStaffCells = null
  let loadStaffHours = null
  let loadCalendarSections = null
  let loadNotes = null
  const actions = fluxStore({ loadFlatShifts })

  return { initialize, actions }

  function initialize(context) {
    LoadStaffHours.initialize(context)
    LoadCalendarCells.initialize(context)
    LoadCalendarSections.initialize(context)
    ;({ loadStaffHours } = LoadStaffHours.actions)
    ;({ loadCalendarSections } = LoadCalendarSections.actions)
    ;({ loadNotes, loadPrimaryStaffCells, loadOtherStaffCells } = LoadCalendarCells.actions)
  }

  async function loadFlatShifts(state, sections, dateRange, loadParameters, options) {
    const { cellsPaths } = loadParameters || {}
    const { asyncLoading = true, loadByChunks = false } = options || {}

    const calendar = state.get('calendar')
    const isPartitialLoading = !!cellsPaths

    const sectionsToLoad = getSectionsToLoad(calendar, sections, isPartitialLoading)
    const noSectionsToLoad = sectionsToLoad.length === 0

    if (noSectionsToLoad) {
      return state
    }

    const { sectionsPaths } = parseCalendarSections(sectionsToLoad)
    const shiftsById = state.getIn(['generalData', 'shiftsById'])

    if (loadByChunks) {
      const { shiftsInChunk } = options
      const chunks = chunk(sectionsToLoad, shiftsInChunk)
      chunks.forEach((sections) => fetchCalendarSections(sections, dateRange, loadParameters, asyncLoading, shiftsById))
    } else {
      fetchCalendarSections(sectionsToLoad, dateRange, loadParameters, asyncLoading, shiftsById)
    }

    if (isPartitialLoading) {
      return state
    }

    const setSectionIsLoading = setCalendarSectionsAreLoading(sectionsPaths)
    return state.update('calendar', setSectionIsLoading)
  }

  function fetchCalendarSections(sections, dateRange, loadParameters, asyncLoading, shiftsById) {
    let { userIds } = loadParameters || {}
    const { cellsPaths, shiftDaysPaths } = loadParameters || {}
    const { shiftsPaths, shiftsToLoad, otherStaffPath, otherStaff } = parseCalendarSections(sections)

    const hasOtherStaff = !!otherStaff
    const hasShiftsToLoad = shiftsToLoad.length > 0

    userIds = getUserIdsToLoad(userIds, sections)

    // console.log(`getting cells for ${sections.length} shifts & ${userIds.length} users'`)

    if (!hasShiftsToLoad && !hasOtherStaff) {
      return
    }

    const shiftIds = shiftsToLoad.map((shift) => shift.get('id'))
    const startDate = dateRange.get('startsAt')
    const endDate = dateRange.get('endsAt')
    const unitId = dateRange.get('unitId')
    const facilityUsersMap = dateRange.get('facilityUsersMap')

    const parameters = { unitId, shiftIds, userIds, startDate, endDate }
    const paths = { shiftsPaths, otherStaffPath, cellsPaths, shiftDaysPaths }

    if (!areValidCalendarSectionsParameters(parameters)) {
      return
    }
    if (asyncLoading) {
      hasOtherStaff && loadOtherStaffCells(parameters, paths)
      hasShiftsToLoad && loadPrimaryStaffCells(parameters, paths, shiftsById, facilityUsersMap)
      loadStaffHours(parameters)
      loadNotes(userIds, startDate, endDate)
    } else {
      loadCalendarSections(parameters, paths)
    }
  }

  function areValidCalendarSectionsParameters(parameters) {
    return parameters.startDate && parameters.endDate && parameters.unitId && parameters.userIds && parameters.shiftIds
  }

  function setCalendarSectionsAreLoading(sectionsPaths) {
    const setSectionIsLoading = (calendar, sectionPath) => calendar.setIn([...sectionPath, 'isLoading'], true)

    return (calendar) => sectionsPaths.reduce(setSectionIsLoading, calendar)
  }

  function getSectionsToLoad(calendar, sections, isPartitialLoading) {
    return isPartitialLoading
      ? sections
      : sections.filter((section) => {
          const roleIndex = section.get('roleIndex')
          const shiftIndex = section.get('shiftIndex')
          const path = ['roles', roleIndex, 'shifts', shiftIndex]
          const isSectionLoading = calendar.getIn([...path, 'isLoading']) === true

          return !isSectionLoading
        })
  }

  function parseCalendarSections(sections) {
    const otherStaffIndex = sections.findIndex((section) => section.get('id') === 'secondary-staff-shift')
    const hasOtherStaff = otherStaffIndex > -1
    const otherStaff = hasOtherStaff && sections[otherStaffIndex]

    const otherStaffRoleIndex = hasOtherStaff && otherStaff.get('roleIndex')
    const otherStaffShiftIndex = hasOtherStaff && otherStaff.get('shiftIndex')
    const otherStaffPath = hasOtherStaff && ['roles', otherStaffRoleIndex, 'shifts', otherStaffShiftIndex]

    const shiftsToLoad = sections.slice()
    hasOtherStaff && shiftsToLoad.splice(otherStaffIndex, 1)

    const shiftsPaths = shiftsToLoad.map((shift) => {
      const roleIndex = shift.get('roleIndex')
      const shiftIndex = shift.get('shiftIndex')
      return ['roles', roleIndex, 'shifts', shiftIndex]
    })

    const sectionsPaths = hasOtherStaff ? [...shiftsPaths, otherStaffPath] : shiftsPaths

    return { sectionsPaths, shiftsPaths, shiftsToLoad, otherStaffPath, otherStaff }
  }

  function getUserIdsToLoad(userIds, shiftsToLoad) {
    return (
      userIds ||
      shiftsToLoad
        .reduce((memo, shift) => {
          const shiftUsersIds = shift.get('staff').map((user) => user.get('id'))
          if (!shiftUsersIds) {
            return memo
          }
          return memo.concat(shiftUsersIds)
        }, List())
        .groupBy((id) => id)
        .keySeq()
        .toArray()
    )
  }
}
