import { PureComponent } from 'react'
import classNames from 'classnames'
import './DateRangeSelector.scss'
import DateRange from './DateRange'
import HeaderNavigation from 'Navigation/HeaderNavigation'
import Loader from './Loader'
import DateRangeService from 'services/DateRangeService'
import { pick } from 'lodash'
import { withRouter } from 'react-router-dom'
import { Map } from 'immutable'
import { withAppContext } from 'AppContext'
import { t } from 'i18n'

const scheduleChangeConfirmation = {
  message: t('calendar.workflow.change_schedule_with_altered_length.disclosure'),
  options: {
    accept: t('calendar.workflow.change_schedule_with_altered_length.confirm'),
    cancel: t('calendar.workflow.go_back'),
    title: t('calendar.workflow.change_schedule_with_altered_length.title'),
    type: 'warning'
  }
}

export class DateRangeSelector extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {}

    this.buildDateRanges = this.buildDateRanges.bind(this)
  }

  render() {
    const { unit, timeService, isShown, hideDateRange, unitUrlId } = this.props
    const { date: usDate } = this.props.match.params
    const isUnitReady = !!unit

    const { cursor, slide } = this.state

    if (cursor === undefined) {
      return null
    }

    const dateRanges = this.buildDateRanges(usDate, unit, timeService)

    const isPrevButtonShown = cursor > 1
    const scheduleIds = dateRanges.map((dateRange) => dateRange.get('scheduleId'))

    const emptySections = this.buildDummySections(cursor)

    const headerNavigationProps = {
      unitUrlId,
      isNavigationVisible: isShown,
      hideNavigation: hideDateRange,
      unitName: unit.get('name')
    }

    return (
      <div
        className={classNames('hx-schedule-selector', {
          hide: !isShown
        })}
        onClick={hideDateRange}
      >
        <HeaderNavigation {...headerNavigationProps} />
        <Loader isLoading={!isUnitReady} />
        <div
          className={classNames({
            'hx-schedule-list bg-alabaster rounded': true,
            hide: !isUnitReady
          })}
        >
          <nav>
            <button onClick={this.prev} className={classNames({ hide: !isPrevButtonShown })}>
              <i className="icon icon-left" />
            </button>
          </nav>
          <nav>
            <button onClick={this.next}>
              <i className="icon icon-right" />
            </button>
          </nav>
          <div className={slide}>
            <div>
              {emptySections}
              {dateRanges.map((range, key) => this.renderDateRange(range, scheduleIds, key))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  buildDummySections() {
    const { emptySectionsCount } = this.getCarouselParameters()
    const emptySections = []

    for (let index = 0; index < emptySectionsCount; index++) {
      emptySections.push(<section key={index} />)
    }

    return emptySections
  }

  getCarouselParameters() {
    const { cursor } = this.state
    const requiredDateRangesMap = [
      { firstIndex: 0, limit: 9, emptySectionsCount: 5 },
      { firstIndex: 0, limit: 9, emptySectionsCount: 5 },
      { firstIndex: 0, limit: 10, emptySectionsCount: 4 },
      { firstIndex: 0, limit: 11, emptySectionsCount: 3 },
      { firstIndex: 0, limit: 12, emptySectionsCount: 2 },
      { firstIndex: 0, limit: 13, emptySectionsCount: 1 },
      { firstIndex: 0, limit: 14, emptySectionsCount: 0 }
    ]

    const defaultParameters = { firstIndex: cursor - 6, limit: 14, emptySectionsCount: 0 }

    return requiredDateRangesMap[cursor] || defaultParameters
  }

  buildDateRanges(usDate, unit, timeService) {
    const { firstIndex, limit } = this.getCarouselParameters()

    return new DateRangeService(unit, timeService, usDate).getDateRanges(firstIndex, limit)
  }

  componentDidMount() {
    const { unit, timeService } = this.props
    const { date: usDate } = this.props.match.params
    const cursor = new DateRangeService(unit, timeService, usDate).getDateRange().get('index')
    this.setState({ cursor })
  }

  componentDidUpdate(prevProps, prevState) {
    const { unit, timeService, match } = this.props
    const { date: usDate } = match.params
    const { cursor } = this.state
    const { cursor: prevCursor } = prevState
    const isCursorInitialized = cursor !== undefined && prevCursor === undefined

    if (isCursorInitialized) {
      this.loadSchedulesMetadata(unit, timeService, usDate)
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { unit, timeService } = this.props
    const { date: usDate } = this.props.match.params
    const { unit: nextUnit } = nextProps
    const { date: nextUsDate } = nextProps.match.params
    let { cursor } = this.state
    const { cursor: nextCursor } = nextState
    const nextUnitId = nextUnit.get('id')
    const unitId = unit.get('id')

    const isDateChanged = usDate !== nextUsDate
    const isUnitIdChanged = unitId !== nextUnitId
    const isCursorChanged = cursor !== nextCursor

    if (isDateChanged || isUnitIdChanged || isCursorChanged) {
      const isCursorReady = !!cursor
      if (isCursorReady) {
        this.loadSchedulesMetadata(unit, timeService, usDate)
      }
    }

    if (isDateChanged || isUnitIdChanged) {
      const cursor = new DateRangeService(unit, timeService, nextUsDate).getDateRange(nextUsDate).get('index')
      this.setState({ cursor })
    }
  }

  loadSchedulesMetadata(unit, timeService, usDate) {
    const { generalStore, appState } = this.props

    let scheduleIds = this.buildDateRanges(usDate, unit, timeService)
      .filter((dateRange) => !!dateRange.get('scheduleId'))
      .map((dateRange) => dateRange.get('scheduleId'))

    const schedulesMetadataMap = appState.getIn(['generalData', 'unit', 'schedulesMetadataMap']) || Map()
    const stateScheduleIds = schedulesMetadataMap.keySeq().toArray()
    scheduleIds = scheduleIds.filter((scheduleId) => !stateScheduleIds.includes(scheduleId))

    if (scheduleIds.length > 0) {
      generalStore.loadSchedulesMetadata(scheduleIds)
    }
  }

  componentWillUnmount() {
    this.props.hideDateRange()
  }

  shouldAllowScheduleChange = async () => {
    const { activeDateRange, unit, Dialog } = this.props
    const unitStaffingPeriodInDays = unit.get('staffingPeriod')
    const activeDateRangeDurationInDays = activeDateRange.get('duration') * 7
    if (
      activeDateRange.get('state') === 'inactive' &&
      activeDateRangeDurationInDays !== unitStaffingPeriodInDays &&
      unit.get('scheduleType') !== 'monthly'
    ) {
      return Dialog.confirm(scheduleChangeConfirmation.message, scheduleChangeConfirmation.options)
    }
    return true
  }

  renderDateRange = (dateRange, scheduleIds, key) => {
    const dateRangeProps = pick({ ...this.props, dateRange }, ['dateRange', 'hideDateRange'])

    return (
      <DateRange
        key={key}
        {...dateRangeProps}
        scheduleIds={scheduleIds}
        shouldAllowScheduleChange={this.shouldAllowScheduleChange}
      />
    )
  }

  prev = (e) => {
    e.stopPropagation()

    const { slide } = this.state
    if (slide) {
      return
    }

    this.setState({ slide: `prev prev-${this.state.cursor + 1}` })

    setTimeout(() => {
      const cursor = Math.max(0, this.state.cursor - 4)
      this.setState({ cursor, slide: null })
    }, 1000)
  }

  next = (e) => {
    e.stopPropagation()

    const { slide } = this.state
    if (slide) {
      return
    }

    this.setState({ slide: 'next' })
    const offset = this.state.cursor === 0 ? 5 : 4

    setTimeout(() => {
      const cursor = this.state.cursor + offset
      this.setState({ cursor, slide: null })
    }, 1000)
  }
}

export default withAppContext(withRouter(DateRangeSelector))
