const form = {
  license: {
    definition: {
      label: 'License',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'license',
    formItemClasses: 'col-12'
  },
  licenseNumber: {
    definition: {
      label: 'License Number (if applicable)',
      required: false,
      type: 'string',
      inputType: 'StringInputField',
      withLabel: true
    },
    field: 'assessedCompetency',
    formItemClasses: 'col-6 pr30'
  },
  expirationDate: {
    definition: {
      label: 'Expiration Date',
      required: false,
      type: 'date',
      inputType: 'CustomDateInput',
      withLabel: true,
      flatpickrOptions: {
        static: true
      }
    },
    field: 'expirationDate',
    formItemClasses: 'col-6 pr30'
  }
}

export default form
