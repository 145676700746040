import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import { t } from 'i18n'
import classNames from 'classnames'
import './LoadTemplateButton.scss'
import stores from 'stores'

const { announcementsStore } = stores

const configLoad = {
  confirmation: {
    message: t('announcements.confirmations.load_template.disclosure'),
    options: {
      title: t('announcements.confirmations.load_template.title'),
      accept: t('announcements.confirmations.load_template.options.accept'),
      cancel: t('announcements.confirmations.load_template.options.cancel'),
      type: 'warning'
    }
  }
}

const configDelete = {
  confirmationDelete: {
    message: t('announcements.confirmations.delete_template.disclosure'),
    options: {
      title: t('announcements.confirmations.delete_template.title'),
      accept: t('announcements.confirmations.delete_template.options.accept'),
      cancel: t('announcements.confirmations.delete_template.options.cancel'),
      type: 'warning'
    }
  }
}

export class LoadTemplateButton extends PureComponent {
  state = {
    isSpinnerShown: false
  }

  render() {
    const { isSpinnerShown } = this.state
    const { Dialog, name: templateName, isFormDirty: isConfirmationRequired } = this.props
    const { confirmation } = configLoad
    const { confirmationDelete } = configDelete

    const onClickLoadTemplate = async (e) => {
      if (isConfirmationRequired) {
        const response = await Dialog.confirm(confirmation.message, confirmation.options)
        if (!response) {
          return
        }
      }
      this.loadAnnouncementTemplate()
    }

    const onClickDeleteTemplate = async (e) => {
      const response = await Dialog.confirm(confirmationDelete.message, confirmationDelete.options)
      if (response) {
        this.deleteAnnouncementTemplate()
      }
    }

    return (
      <>
        <button
          onClick={onClickLoadTemplate}
          className={classNames({
            'button secondary load-template': true,
            loading: isSpinnerShown
          })}
        >
          {templateName}
        </button>
        <i className="icon-close delete-template gray" onClick={onClickDeleteTemplate} />
      </>
    )
  }

  loadAnnouncementTemplate = async () => {
    const { name, subject, subtitle, content, location, badgeUrl, detailsComponents, updateMasterState } = this.props

    const activeComponent = {
      id: 'defaultForm',
      type: 'CreateAnnouncementForm',
      content: {}
    }

    this.setState({ isSpinnerShown: true })

    updateMasterState({
      subject,
      subtitle,
      content,
      location,
      badgeUrl,
      activeComponent,
      loadedTemplate: name,
      detailsComponents,
      isFormDirty: false
    })

    this.setState({ isSpinnerShown: false })
  }

  deleteAnnouncementTemplate = async () => {
    const { id, reloadTemplatesList, updateMasterState, loudspeakerBadgeUrl } = this.props
    const activeComponent = {
      id: 'defaultForm',
      type: 'CreateAnnouncementForm',
      content: {}
    }

    this.setState({ isSpinnerShown: true })
    announcementsStore.deleteAnnouncementTemplate(id)

    updateMasterState({
      subject: '',
      subtitle: '',
      content: '',
      location: '',
      badgeUrl: loudspeakerBadgeUrl,
      activeComponent,
      loadedTemplate: ''
    })

    await reloadTemplatesList()
    this.setState({ isSpinnerShown: false })
  }
}

export default withAppContext(LoadTemplateButton)
