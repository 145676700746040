import { PrivateRoute } from '../../auth'
import Header from './Header'
import { paths, navigate } from 'Navigation'
import ErrorBoundary from 'ErrorBoundary'
import typedErrorsHandlers from 'services/ErrorHandler/typedErrorsHandlers'
import ToDo from './ToDo'
import SkillsCheck from './SkillsCheck'
import LaborProfile from './LaborProfile'
import { Redirect } from 'react-router-dom'

function Dashboard({ dataController }) {
  return (
    <>
      <Header />
      <div>
        <ErrorBoundary onError={typedErrorsHandlers['ApplicationError']}>
          <PrivateRoute path={paths.Dashboard} component={redirectToToDo} exact />
          <PrivateRoute path={paths.ToDo} component={ToDo} componentId="todo" exact />
          <PrivateRoute
            path={paths.LaborProfile}
            component={LaborProfile}
            componentProps={{ dataController }}
            componentId="laborprofile"
            exact
          />
          <PrivateRoute path={paths.SkillsCheck} component={SkillsCheck} componentId="skillscheck" exact />
        </ErrorBoundary>
      </div>
    </>
  )
}

function redirectToToDo() {
  return <Redirect to={navigate.from.Dashboard.to.ToDo({}, false)} />
}

export default Dashboard
