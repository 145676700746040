import classNames from 'classnames'
import { Component } from 'react'
import CustomDropdownEnum from 'components/Form/CustomDropdownEnum'
import './DisplayOptions.scss'

export default class DisplayOptions extends Component {
  constructor(props) {
    super(props)
    const { options = {}, values = [] } = props
    this.state = { isActive: false, isHovered: false, options, values }
  }

  render() {
    const { selectOptions, values } = this.props
    const { isActive } = this.state

    const view = values.get('view')

    return (
      <aside className="hx-filters row align-middle">
        <button
          id="disp-pref-btn"
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <i className="icon-settings" />
        </button>

        <div className="display-options bg-white filters-content row" onMouseLeave={this.onMouseLeave}>
          <div className="col-12 p10 bb1 bold river-bad big text-center">Display Preferences</div>

          {this.renderViewSelector(view)}

          <ul className="p20">{selectOptions[view].map((definition) => this.renderOption(definition))}</ul>
        </div>
        <sh-tooltip label="Display preferences" target="disp-pref-btn" placement="bottom" variation="short" />
      </aside>
    )
  }

  renderViewSelector = (view) => {
    return (
      <div className="view-selector pt10">
        <sh-switch stretch>
          <sh-switch-item
            id="shift-display-options-staff"
            label="Staff"
            active={!(view === 'staff') && undefined}
            onClick={() => this.updateView('staff')}
          />
          <sh-switch-item
            id="shift-display-options-coverage"
            label="Coverage"
            active={!(view === 'coverage') && undefined}
            onClick={() => this.updateView('coverage')}
          />
        </sh-switch>
      </div>
    )
  }

  renderOption = (definition) => {
    const { values } = this.props

    const view = values.get('view')
    const {
      label,
      field,
      items: { enum: options }
    } = definition
    const value = values.getIn([view, field])
    const description = options.find((item) => item.get('key') === value).get('description')

    return (
      <li className="row align-middle" key={field}>
        <label className="col-5 text-right pr10 row h100 align-middle">{label}</label>
        <span className="col-7">
          <CustomDropdownEnum
            value={value}
            definition={definition}
            onChange={(e) => this.updateConfigOption(e.currentTarget.value, field)}
            propKey={'key'}
            propValue={'name'}
          />
        </span>
        <span className="col-5 offset-5 regent-gray smaller">{description}</span>
      </li>
    )
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => ({
      isActive: !prevState.isActive
    }))
  }

  updateView = (view) => {
    let { values, setConfig } = this.props
    setConfig(values.set('view', view))
  }

  updateConfigOption = (value, field) => {
    if (!value) {
      return
    }
    let { values, setConfig } = this.props
    const view = values.get('view')
    setConfig(values.setIn([view, field], value))
  }

  onMouseLeave = (event) => {
    const {
      target: { nodeName }
    } = event
    if (nodeName && nodeName === 'SELECT') {
      return
    }
    this.setState({ isActive: !this.state.isActive })
  }
}
