import { Fragment } from 'react'

const withSeparators = (ComponentList, separatorColor = '#F2F4F5') => {
  if (!(ComponentList instanceof Array) || ComponentList.length < 2) {
    return ComponentList
  }
  return ComponentList.map((Component, i) => (
    <Fragment key={i}>
      {Component}
      {i + 1 !== ComponentList.length && <hr className="crud-card-separator" style={{ background: separatorColor }} />}
    </Fragment>
  ))
}

export { withSeparators }
