import { useEffect, useState, memo } from 'react'
import { isEqual } from 'lodash'
import './KpiList.scss'
import DoughnutChart from '../DoughnutChart/DoughnutChart'

function KpiList({ kpiResult }) {
  const [kpiList, setKpiList] = useState([])
  useEffect(() => {
    setKpiList(kpiResult)
  }, [kpiResult])
  return (
    <>
      <div className="kpi-wrapper">
        {kpiList.map((kpi, index) => (
          <DoughnutChart
            chartId={'chart-id-' + index}
            percentage={kpi.value}
            heading={kpi.name}
            trend={kpi?.trend}
            key={kpi.name + '_' + kpi.value}
          />
        ))}
      </div>
    </>
  )
}

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}

export default memo(KpiList, areEqual)
