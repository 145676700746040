import { useState, useEffect } from 'react'
import { withAppContext } from 'AppContext'
import ExpiringListModal from './modals/ExpiringListModal/ExpiringListModal'
import { t } from 'i18n'

const defaultExpertiseMetadata = { license: [], skill: [], certification: [], equipment: [] }

function _isExpertiseExpiringOrExpired(timeService, expertise) {
  const expiresAt = expertise?.get('expiresAt')

  let isExpired,
    isExpiring = false

  if (expiresAt) {
    const today = timeService.timeMoment(null)
    const expiryDate = timeService.timeMoment(expiresAt)
    const ninetyDaysInSeconds = 90 * 24 * 60 * 60

    isExpired = expiryDate.diff(today, 'seconds') < 0
    isExpiring = !isExpired && expiryDate.diff(today, 'seconds') < ninetyDaysInSeconds
  }

  return { isExpired, isExpiring }
}

async function _loadExpertiseMetadata(timeService, expertises, staffStore, unitId) {
  const expertiseMetadata = { license: [], skill: [], certification: [], equipment: [] }

  if (!expertises?.size) {
    console.info('No expertises found')
    return expertiseMetadata
  }

  const today = timeService.timeMoment(null)
  const startDate = today.toISOString()
  const endDate = today.clone().add(1, 'day').toISOString()

  const staffList = await staffStore.loadUnitStaff(unitId, 1, { startDate, endDate }, 9999)
  const staffData = staffList?.getIn(['staff', 'staffList', 'edges'])

  for (const staff of staffData) {
    const staffExpertises = staff.getIn(['staffProfile', 'expertiseCredentials'])

    for (const expertise of staffExpertises) {
      const { isExpiring, isExpired } = _isExpertiseExpiringOrExpired(timeService, expertise)

      if (isExpiring || isExpired) {
        const expertiseType = expertises.get(expertise.get('expertiseId')).get(0).get('type')
        const profile = staff.get('profile')
        const facilityProfile = staff.get('facilityProfile')
        expertiseMetadata[expertiseType].push({
          isExpired,
          isExpiring,
          staffFirstName: facilityProfile.get('firstName'),
          staffLastName: facilityProfile.get('lastName'),
          avatarUrl: profile.get('avatarUrl'),
          skillName: expertises.get(expertise.get('expertiseId')).get(0).get('name'),
          expiryDate: expertise?.get('expiresAt')
        })
      }
    }
  }

  return expertiseMetadata
}

function ExpiringCard({ unit, staffStore, expertiseStore, timeService }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [expertiseMetadata, setExpertiseMetadata] = useState(defaultExpertiseMetadata)
  const [isLoading, setIsLoading] = useState(true)
  const unitId = unit.get('id')

  useEffect(() => {
    const loadData = async () => {
      if (!unitId) {
        return
      }

      setIsLoading(true)
      setExpertiseMetadata(defaultExpertiseMetadata)

      const expertisesList = await expertiseStore.loadExpertises()
      const expertises = expertisesList?.getIn(['expertises', 'expertiseItems'])
      const metadata = await _loadExpertiseMetadata(timeService, expertises, staffStore, unitId)

      setExpertiseMetadata(metadata)
      setIsLoading(false)
    }

    loadData()
  }, [timeService, expertiseStore, staffStore, unitId])

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible)
  }

  return (
    <div className="todo-common-card">
      <div className="hx-card-header">
        <div className="hx-card-header-title col-6">
          <div className="col-2 icon-box">
            <div className="icon-expiring"></div>
          </div>
          <div className="col-9 card-heading">Expiring</div>
        </div>
        {!isLoading && (
          <div className="col-6 hx-card-modal-icon">
            <div className="col-1 icon-box">
              <sh-icon icon="arrow-line-right-s" onClick={toggleModalVisibility} />
            </div>
          </div>
        )}
      </div>
      {isModalVisible && (
        <ExpiringListModal
          expertiseMetadata={expertiseMetadata}
          toggleModalVisibility={toggleModalVisibility}
          timeService={timeService}
        />
      )}

      <div className="hx-card-details">
        {isLoading ? (
          <div style={{ margin: 'auto', padding: '16px' }}>
            <sh-spinner></sh-spinner>
          </div>
        ) : (
          <>
            <div className="col-5 hx-card-detail">
              <div className="h60 hx-card-detail-first-box">
                <div className="h60 countFont" data-testid="skillMismatchLength">
                  {expertiseMetadata.skill.length}
                </div>
                {expertiseMetadata.skill.length > 0 && (
                  <div className="icon-box warning-icon">
                    <div className="icon-warning"></div>
                  </div>
                )}
              </div>
              <div className="h20 labelFont">{t('dashboard.staffSkills')}</div>
            </div>
            <div className="col-2 divider">
              <sh-divider vertical></sh-divider>
            </div>
            <div className="col-5 hx-card-detail">
              <div className="h60 hx-card-detail-first-box">
                <div className="countFont" data-testid="licenseMismatchLength">
                  {expertiseMetadata.license.length}
                </div>
                {expertiseMetadata.license.length > 0 && (
                  <div className="icon-box warning-icon">
                    <div className="icon-warning"></div>
                  </div>
                )}
              </div>
              <div className="h20 labelFont">{t('dashboard.staffLicenses')}</div>
            </div>
            <div className="col-2 divider">
              <sh-divider vertical></sh-divider>
            </div>
            <div className="col-5 hx-card-detail">
              <div className="h60 hx-card-detail-first-box">
                <div className="countFont" data-testid="certificationMismatchLength">
                  {expertiseMetadata.certification.length}
                </div>
                {expertiseMetadata.certification.length > 0 && (
                  <div className="icon-box warning-icon">
                    <div className="icon-warning"></div>
                  </div>
                )}
              </div>
              <div className="h20 labelFont">{t('dashboard.staffCredentials')}</div>
            </div>
            <div className="col-2 divider">
              <sh-divider vertical></sh-divider>
            </div>
            <div className="col-5 hx-card-detail">
              <div className="h60 hx-card-detail-first-box">
                <div className="countFont" data-testid="equipmentMismatchLength">
                  {expertiseMetadata.equipment.length}
                </div>
                {expertiseMetadata.equipment.length > 0 && (
                  <div className="icon-box warning-icon">
                    <div className="icon-warning"></div>
                  </div>
                )}
              </div>
              <div className="h20 labelFont">{t('dashboard.equipmentSkills')}</div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default withAppContext(ExpiringCard)
