import { isFunction } from 'utils'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { fromJS, Map, OrderedMap } from 'immutable'
import TimeOffRequests from './TimeOffRequests'
import Notes from './Notes'
import Staff from './Staff'
import StaffEvents from './StaffEvents'

const defaultState = Map({
  timeOffRequests: Map({
    timeOffRequestsMap: OrderedMap(),
    pageInfo: Map()
  }),
  notesMap: Map(),
  timeOffsMap: Map(),
  staff: Map({
    staffMap: Map()
  }),
  schedulesMap: OrderedMap()
})

export default function TimeOffStore() {
  const stateActions = fluxStore({ resetData })

  const actions = {
    ...stateActions,
    ...Staff.actions,
    ...StaffEvents.actions,
    ...TimeOffRequests.actions
  }

  return { initialize, ...actions }

  function initialize(state, context) {
    const { loadStaff } = Staff.actions
    const { loadDateRangeNotes } = Notes.actions

    const updaters = fluxStore({
      updateTimeOff,
      updateNotesMap,
      updateTimeOffs,
      appendUnitSchedules,
      updateTimeOffRequests
    })
    const extendedContext = { ...context, ...updaters, loadDateRangeNotes, loadStaff }

    Staff.initialize(extendedContext)
    Notes.initialize(extendedContext)
    StaffEvents.initialize(extendedContext)
    TimeOffRequests.initialize(extendedContext)

    return state.set('timeOff', defaultState)
  }

  function resetData(state) {
    return state.set('timeOff', defaultState)
  }

  function updateTimeOff(state, value) {
    if (isFunction(value)) {
      return state.updateIn(['timeOff'], value)
    }

    return state.setIn(['timeOff'], value)
  }

  function updateNotesMap(state, value) {
    if (isFunction(value)) {
      return state.updateIn(['timeOff', 'notesMap'], value)
    }

    return state.setIn(['timeOff', 'notesMap'], value)
  }

  function updateTimeOffs(state, value) {
    if (isFunction(value)) {
      return state.updateIn(['timeOff', 'timeOffsMap'], value)
    }

    return state.setIn(['timeOff', 'timeOffsMap'], value)
  }

  function updateTimeOffRequests(state, value) {
    if (isFunction(value)) {
      return state.updateIn(['timeOff', 'timeOffRequests'], value)
    }

    return state.setIn(['timeOff', 'timeOffRequests'], value)
  }

  function appendUnitSchedules(state, schedules) {
    const schedulesMap = state.getIn(['timeOff', 'schedulesMap'])

    const newSchedulesMap = schedules.reduce((memo, schedule) => memo.set(schedule.id, fromJS(schedule)), schedulesMap)
    return state.setIn(['timeOff', 'schedulesMap'], newSchedulesMap)
  }
}
