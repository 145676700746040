import { Redirect } from 'react-router-dom'
import { List } from 'immutable'
import { withAppContext } from 'AppContext'
import { navigate } from 'Navigation'
import { Admins } from 'auth/Roles'

// Current path is root.
// *.humanics.com
export function FirstFacilityRedirect(props) {
  const auth = props.appState.get('authentication')

  const urlId = auth.getIn(['facility', 'urlId']) || auth.getIn(['facilities', 0, 'urlId'])
  if (!urlId) {
    return <Redirect to={navigate.to.Forbidden({}, false)} />
  }

  const roleIds = auth.getIn(['facilityUser', 'roleIds']) || List()
  const isAdmin = roleIds.some((roleId) => Admins.some((role) => role === roleId))
  if (isAdmin) {
    return <Redirect to={navigate.to.AdminUnits({ urlId }, false)} />
  }

  return <Redirect to={navigate.to.FacilityHome({ urlId }, false)} />
}

export default withAppContext(FirstFacilityRedirect)
