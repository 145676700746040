import ShiftTypeAndRoleCreateUpdate from './ShiftTypeAndRoleCreateUpdate'
import RolesController from './RolesController'

class RoleCreateUpdate extends ShiftTypeAndRoleCreateUpdate {
  constructor(props) {
    super(props)

    this.controller = new RolesController(this)
    const data = this.controller.getRoles()
    this.state = { saving: false, visible: true, error: '', data }
  }

  componentDidUpdate(prevProps) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const prevStoreState = prevAppState.get(stateKey)
    const isStoreStateChanged = storeState !== prevStoreState

    if (isStoreStateChanged) {
      const data = this.controller.getRoles()
      this.setState({ data })
    }
  }
}
export default RoleCreateUpdate
