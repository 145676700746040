import { Component } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import UnitStaff from './UnitStaff'
import TransferringStaff from './TransferringStaff'
import { t } from 'i18n'
import './StaffListTable.scss'

export default class StaffListTable extends Component {
  static propTypes = {
    staffList: PropTypes.instanceOf(List).isRequired,
    activePage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    transferringStaff: PropTypes.instanceOf(Map).isRequired,
    staffListConfig: PropTypes.instanceOf(Map).isRequired
  }

  render() {
    const { staffListConfig, staffList } = this.props
    const emptySearchResultsCard = (
      <div className="bg-white hx-box-shadow rounded p20 mt10">{t('empty_search_results')}</div>
    )

    const areSearchResultsEmpty = staffListConfig.get('search') !== '' && staffList.size === 0

    let staffListTableContents = (
      <>
        <TransferringStaff {...this.props} />
        <UnitStaff {...this.props} />
      </>
    )

    if (areSearchResultsEmpty) {
      staffListTableContents = emptySearchResultsCard
    }
    return (
      <section className="hx-staff-list-table align-center pb30 staff-list-table">{staffListTableContents}</section>
    )
  }
}
