import './MonthYearHeader.scss'

const MonthYearHeader = ({ formattedMonthYear }: any) => {
  return (
    <sh-card card-height="64px">
      <div className="shift-swap-month-year-header">
        <sh-text size="super-header" color="secondary">
          {formattedMonthYear}
        </sh-text>
      </div>
    </sh-card>
  )
}

export default MonthYearHeader
