import { PureComponent } from 'react'
import { Avatar } from 'Manager/components'
import StaffPreferences from 'entityWrappers/StaffPreferences'
import StaffShiftDay from './StaffShiftDay'
import VDetails from './VDetails'
import { navigate } from 'Navigation'
import { formatPhoneNumber } from 'utils'
import { Map } from 'immutable'
import classNames from 'classnames'
import './Staff.scss'

export default function DayStaff({
  staff,
  style,
  selection,
  activeDateRange,
  scheduleState,
  onCellEvent,
  selectSingleCell,
  showPopup,
  onMouseMoveOverGridCell,
  onMouseLeave,
  cellsPathsInProcessing,
  updateCellTooltip,
  unitUrlId,
  displayNameAs,
  indicators,
  dayViewFilters,
  shiftTime,
  shift,
  dayIndex,
  facilityShiftsMap,
  unitShiftsMap,
  notes,
  staffManagersMap,
  updateEvent,
  timeService,
  staffMismatch
}) {
  const staffShiftDayProps = {
    indicators,
    dayViewFilters,
    shiftTime,
    showPopup,
    activeDateRange,
    staff,
    selection,
    onCellEvent,
    selectSingleCell,
    cellsPathsInProcessing,
    onMouseMoveOverGridCell,
    onMouseLeave,
    updateCellTooltip,
    shiftName: staff.get('shiftName'),
    staffPath: {
      roleIndex: staff.get('roleIndex'),
      shiftIndex: staff.get('shiftIndex'),
      staffIndex: staff.get('staffIndex')
    },
    shift,
    dayIndex,
    unitShiftsMap,
    facilityShiftsMap,
    staffManagersMap,
    updateEvent,
    notes,
    timeService,
    staffMismatch
  }
  const isShiftView = shift.get('viewPreference') === 'shift' ? true : false
  let profileComponent = (
    <VProfile staff={staff} unitUrlId={unitUrlId} scheduleState={scheduleState} displayNameAs={displayNameAs} />
  )

  if (isShiftView) {
    profileComponent = <aside className="_staffMeta _staffProfile col-2 bt1" />
  }
  return (
    <div
      data-test-id="hx-calendar-day-staff"
      className="_staff hx-calendar-staff row"
      data-staff-id={staff.get('id')}
      style={style}
    >
      {profileComponent}
      <StaffShiftDay {...staffShiftDayProps} />
    </div>
  )
}

class VProfile extends PureComponent {
  state = { isStaffDetailsShown: false }

  handleMouseEnter = () => {
    this.timer = setTimeout(
      () =>
        this.setState({
          isStaffDetailsShown: true
        }),
      500
    )
  }

  handleMouseLeave = () => {
    global.clearTimeout(this.timer)
    this.setState({
      isStaffDetailsShown: false
    })
  }

  navigateToFacilityUser = () => {
    const { staff, unitUrlId } = this.props
    const userId = staff.get('id')

    navigate.from.Home.to.StaffDetails({ userId, unitUrlId })
  }

  render() {
    const { staff, scheduleState, displayNameAs } = this.props
    const userId = staff.get('id')
    const fullName =
      !displayNameAs || displayNameAs === 'firstNameLastName' ? staff.get('fullName') : staff.get('fullNameRevers')
    const staffProfile = staff.get('staffProfile') || Map()
    const profile = staff.get('profile') || Map()
    const phoneNumber = staff.getIn(['profile', 'phoneNumber'])
    const phoneNumber1 = staffProfile.get('phoneNumber1')
    const phoneNumber2 = staffProfile.get('phoneNumber2')
    const phoneNumbers = [...new Set([phoneNumber, phoneNumber1, phoneNumber2])]
      .filter((phone) => phone)
      .map(formatPhoneNumber)

    const preferences = staff.get('preferences')
    const { preferenceNames } = new StaffPreferences(preferences)
    const detailsProps = {
      phoneNumbers,
      preferenceNames,
      hasPreferences: preferences && preferences.size > 0
    }
    const isOpen = scheduleState === 'open'
    const isUnavailabilitySubmitted = staff.get('isUnavailabilitySubmitted')
    const showCheckmark = isOpen && isUnavailabilitySubmitted

    // TODO add hrs logic from `src/js/Manager/Calendar/Grid/Role/Shift/Staff/Profile/Summary/Summary.js`
    // Should we do these calculations on backend as well?

    const avatarProps = {
      userId,
      profile,
      visible: staff.get('isShiftLoaded'),
      renderVisibleOnly: true
    }
    const staffHours = staff.get('weekHours')
    const isStaffHours = staffHours !== undefined
    const maximumNumberOfHoursPerWeek = staffProfile.get('maximumNumberOfHoursPerWeek')
    return (
      <>
        <aside
          className="_staffMeta _staffProfile col-2"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onDoubleClick={this.navigateToFacilityUser}
        >
          <div className="bg-alabaster hx-calendar-staff-info-wrapper bt1">
            <Avatar {...avatarProps} />
            <div className="staff-meta">
              {fullName}
              {showCheckmark && (
                <div className="small regent-gray float-right">
                  <i className="icon-checkmark ufo-green" />
                </div>
              )}
              <div className="small regent-gray">
                {`${staff.get('type')}  ·  `}
                {isStaffHours && this._hoursComponent(staffHours, maximumNumberOfHoursPerWeek)}
              </div>
            </div>
          </div>
        </aside>
        {this.state.isStaffDetailsShown && <VDetails {...detailsProps} />}
      </>
    )
  }

  _hoursComponent(hrs, weekHoursPlanned = 36) {
    const isMoreThenPlanned = hrs > weekHoursPlanned
    return (
      <span>
        <span className={classNames({ hint: isMoreThenPlanned })}>{hrs}</span>/ {weekHoursPlanned} hrs
      </span>
    )
  }
}
