import { fromJS, List, Map } from 'immutable'
import memoize from 'memoize-one'
import StateController from './StateController'
import { CalendarFilterService, DateRangeService } from 'services'
import { calculateSortWeightByEmploymentType } from 'services/StaffOrderService'
import { cloneDeep, isNil } from 'lodash'
import { Staff } from 'entityWrappers'

const MAX_DURATION_OF_SHIFT_IN_MINUTES = 2880

export default class ViewModelController extends StateController {
  getDayViewModel = (unitId, date, additinalFilters = {}) => {
    const filters = this.state.filters.merge(additinalFilters)
    const roles = this.calendar.getDayData(unitId, date)

    const { isStaffVisible, isRoleVisible, isShiftVisible } = this._getFilters(filters)

    return roles
      .filter((role) => isRoleVisible(role))
      .map((role) => {
        const shifts = role
          .get('shifts')
          .filter((shift) => isShiftVisible(shift, true))
          .map((shift) => {
            const staff = shift
              .get('staff')
              .filter((staff) => isStaffVisible(staff))
              .sort(this._sortShiftStaff)

            return shift.set('staff', staff)
          })
        return role.set('shifts', shifts)
      })
  }

  getViewModel = (additionalFilters = {}, forPrint = false, forShiftViewCellContextMenu = false) => {
    const { roles, staffHoursMap } = this.calendar
    let { filters } = this.state
    filters = filters.merge(additionalFilters)

    return this._buildModel(roles, staffHoursMap, filters, forPrint, forShiftViewCellContextMenu)
  }

  getViewModelTree = (additionalFilters, forPrint) => {
    const raw = this.getViewModel(additionalFilters, forPrint)
    return raw.reduce((memo, item) => {
      const kind = item.get('kind')

      const isStaff = kind === 'staff'
      const isShift = kind === 'shift'
      const isRole = kind === 'role'

      if (isRole) {
        item = item.set('shifts', List())
        memo = memo.push(item)
      }

      if (isShift) {
        item = item.set('staff', List())
        const lastRoleIndex = memo.size - 1
        const shifts = memo.getIn([lastRoleIndex, 'shifts'])
        memo = memo.setIn([lastRoleIndex, 'shifts'], shifts.push(item))
      }

      if (isStaff) {
        const lastRoleIndex = memo.size - 1
        const lastShiftIndex = memo.getIn([lastRoleIndex, 'shifts']).size - 1
        const staff = memo.getIn([lastRoleIndex, 'shifts', lastShiftIndex, 'staff'])
        memo = memo.setIn([lastRoleIndex, 'shifts', lastShiftIndex, 'staff'], staff.push(item))
      }

      return memo
    }, List())
  }

  _buildModel = memoize((roles, staffHoursMap, filters, forPrint, forShiftViewCellContextMenu) => {
    const raw = roles.reduce(
      this._getRolesReducer(filters, staffHoursMap, forPrint, forShiftViewCellContextMenu),
      List()
    )
    return raw.push(Map({ kind: 'footer' }))
  })

  _getRolesReducer = (filters, staffHoursMap, forPrint, forShiftViewCellContextMenu) => (result, role) => {
    const unitId = this.props.unit.get('id')
    const unitManagerViewPreferences = this.component.dataController.getUnitManagerViewPreferences()
    const unitManagerViewPreferenceForUnit = unitManagerViewPreferences['units']?.[unitId]?.['viewPreference']
    const isShiftView = unitManagerViewPreferenceForUnit === 'shift'

    if (!forShiftViewCellContextMenu && role.get('isOtherStaff') && isShiftView) return result

    const showMergedShift = filters.get('showMergedShift')
    const { isRoleVisible } = this._getFilters(filters)

    if (this.props.calendarType === 'autoScheduleCalendar' && role.get('id') === 'secondary-staff') {
      return result
    }
    // role is filtered out...
    if (!forShiftViewCellContextMenu && !isRoleVisible(role)) {
      return result
    }

    const roleId = role.get('id')
    const isCollapsed = filters.getIn(['collapsed', roleId]) === true
    result = result.push(role)

    const shifts = role.get('shifts')
    if (showMergedShift) {
      return this._getMergeShift(filters, staffHoursMap, isCollapsed, role, shifts, result, forShiftViewCellContextMenu)
    } else {
      return shifts.reduce(
        this._getShiftsReducer(filters, staffHoursMap, isCollapsed, forPrint, forShiftViewCellContextMenu),
        result
      )
    }
  }

  _getShiftsReducer =
    (filters, staffHoursMap, isCollapsed, forPrint, forShiftViewCellContextMenu) => (memoShift, shift) => {
      const { isShiftVisible } = this._getFilters(filters)
      const { mode } = this.props.match.params
      const isDayView = mode === 'day'
      const isShiftView = shift.get('viewPreference') === 'shift'

      if (!forShiftViewCellContextMenu && !isShiftVisible(shift, isDayView)) {
        return memoShift
      }
      if (isCollapsed) {
        return memoShift.push(shift)
      }

      if (isShiftView) {
        shift = this._setShiftViewRowsForMode(shift, mode)
      }
      const { shiftStaff, shiftMismatch } = this._getShiftStaff(shift, staffHoursMap, filters, mode, forPrint)
      if (shiftMismatch) {
        shift = shift.set('shiftMismatch', shiftMismatch)
      }

      return memoShift.push(shift).concat(shiftStaff)
    }

  _updateShiftMismatch = (shiftMismatch, mergeShift, shiftStaff) => {
    const mergeShiftStaff = mergeShift.get('staff', List())
    let updatedMergeShift = mergeShift.set('staff', mergeShiftStaff.concat(shiftStaff))
    if (shiftMismatch) {
      const currentShiftMismatch = updatedMergeShift.get('shiftMismatch')
      if (currentShiftMismatch) {
        const mergedShiftMismatch = currentShiftMismatch.map((num, idx) => {
          return num + shiftMismatch[idx]
        })
        updatedMergeShift = updatedMergeShift.set('shiftMismatch', mergedShiftMismatch)
      } else {
        updatedMergeShift = updatedMergeShift.set('shiftMismatch', shiftMismatch)
      }
    }
    return updatedMergeShift
  }

  _getMergeShift = (filters, staffHoursMap, isCollapsed, role, shifts, memo, inStaffView = false) => {
    const { isShiftVisible } = this._getFilters(filters)
    const roleId = role.get('id')
    const isOtherStaff = roleId === 'secondary-staff'
    const roleName = role.get('name')
    const hasVisibleShift = shifts.some((shift) => isShiftVisible(shift, true))
    const { mode } = this.props.match.params
    if (!hasVisibleShift) {
      return memo
    }

    let mergeShift = Map({
      id: !isOtherStaff ? `${roleId}-shift` : 'secondary-staff-shift',
      isClass: false,
      unitRoleId: roleId,
      isHidden: isOtherStaff,
      hidden: isOtherStaff,
      isLoaded: false,
      isOtherStaff,
      kind: 'shift',
      name: `${roleName} - Shifts`,
      shiftDay: List()
    })
    const staff = shifts.reduce((memoStaff, shift) => {
      if (!isShiftVisible(shift, true)) {
        return memoStaff
      }
      let mergedShiftDays = mergeShift.get('shiftDays')
      const shiftDays = shift.get('shiftDays')
      const shiftDay = shift.get('shiftDay')
      if (shiftDay) {
        const mergedShiftDay = mergeShift.get('shiftDay').concat(shiftDay)
        mergeShift = mergeShift.set('shiftDay', mergedShiftDay)
      }
      mergedShiftDays = this._getMergeShiftDays(mergedShiftDays, shiftDays)
      mergeShift = mergeShift.set('shiftDays', mergedShiftDays)

      const isShiftView = shift.get('viewPreference') === 'shift'
      if (isShiftView) {
        shift = this._setShiftViewRowsForMode(shift, mode)
      }

      const { shiftStaff, shiftMismatch } = this._getShiftStaff(shift, staffHoursMap, filters, mode, inStaffView)
      mergeShift = this._updateShiftMismatch(shiftMismatch, mergeShift, shiftStaff)
      return memoStaff.concat(shiftStaff)
    }, List())
    if (isCollapsed) {
      return memo.push(mergeShift)
    }
    return memo.push(mergeShift).concat(staff)
  }

  _setShiftViewRowsForMode(shift, mode) {
    const isDayView = mode === 'day'
    const isWeekView = mode === 'week'

    if (isDayView) {
      const shiftViewRowsForDayView = this._getShiftViewRowsForDayView(shift.get('shiftViewRows'))
      return shift.set('shiftViewRowsForDayView', shiftViewRowsForDayView)
    } else if (isWeekView) {
      const shiftViewRowsForWeekView = this._getShiftViewRowsForWeekView(shift.get('shiftViewRows'))
      return shift.set('shiftViewRowsForWeekView', shiftViewRowsForWeekView)
    }
    return shift
  }

  _getMergeShiftDays = (mergedShiftDays, shiftDays) => {
    if (mergedShiftDays) {
      return mergedShiftDays.map((shiftDay, idx) => {
        const existingShiftDay = shiftDays.get(idx)
        if (!existingShiftDay) {
          return shiftDay
        }
        return Map({
          staffMismatch: shiftDay.get('staffMismatch') + existingShiftDay.get('staffMismatch'),
          staffRequirementCustom: null,
          staffRequirement:
            shiftDay.get('staffRequirement') +
            (existingShiftDay.get('staffRequirementCustom') || existingShiftDay.get('staffRequirement')),
          startsAt: shiftDay.get('startsAt')
        })
      })
    } else {
      return List(
        shiftDays.map((shiftDay) =>
          Map({
            staffMismatch: shiftDay.get('staffMismatch'),
            staffRequirementCustom: null,
            staffRequirement: shiftDay.get('staffRequirementCustom') || shiftDay.get('staffRequirement'),
            startsAt: shiftDay.get('startsAt')
          })
        )
      )
    }
  }

  getSortValue = (sortBy, staff1, staff2) => {
    const { timeService } = this.props
    let sort
    const firstName1 = staff1.get('firstName')
    const lastName1 = staff1.get('lastName')
    const hireDate1 = staff1.getIn(['staffProfile', 'startsAt'])

    const firstName2 = staff2.get('firstName')
    const lastName2 = staff2.get('lastName')
    const hireDate2 = staff2.getIn(['staffProfile', 'startsAt'])
    switch (true) {
      case sortBy === 'firstName':
        sort = firstName1.toLowerCase() > firstName2.toLowerCase() ? 1 : -1
        break

      case sortBy === 'lastName':
        sort = lastName1.toLowerCase() > lastName2.toLowerCase() ? 1 : -1
        break

      case sortBy === 'hireDate':
        if (!hireDate1) {
          sort = -1
        } else if (!hireDate2) {
          sort = 1
        } else {
          sort = timeService.timeMoment(hireDate1).isBefore(hireDate2) ? 1 : -1
        }
        break

      default:
        sort = 0
    }
    return sort
  }

  _sortShiftStaff = (staff1, staff2) => {
    const { filters } = this.state

    const displayOptions = filters.get('displayOptions')
    const groupBy = displayOptions.getIn(['staff', 'groupBy'])
    const sortBy = displayOptions.getIn(['staff', 'sortBy'])
    const orderBy = displayOptions.getIn(['staff', 'orderBy'])
    const direction = orderBy === 'asc' ? 1 : -1
    const isGroupByEmployment = groupBy === 'employment'

    if (isGroupByEmployment) {
      const sortWeight1 = calculateSortWeightByEmploymentType(staff1)
      const sortWeight2 = calculateSortWeightByEmploymentType(staff2)

      if (sortWeight1 !== sortWeight2) {
        return sortWeight1 > sortWeight2 ? 1 : -1
      }
    }
    const sort = this.getSortValue(sortBy, staff1, staff2)

    return sort * direction
  }

  _getShiftWorkingHoursinHourCell = (hourCell, shiftId) => {
    return (hourCell.eventType === 'assignment' || hourCell.eventType === 'onCall') &&
      hourCell.eventShiftId === shiftId &&
      !isNil(hourCell.value)
      ? hourCell.value
      : 0
  }

  _getShiftStaff = (shift, staffHoursMap, filters, mode, inStaffView = false) => {
    const isShiftLoading = shift.get('isLoading')
    const isShiftOnCall = shift.get('isOnCall')
    const shiftName = shift.get('name')
    const shiftId = shift.get('id')
    const roleId = shift.get('unitRoleId')
    const shiftIndex = shift.get('shiftIndex')
    const roleIndex = shift.get('roleIndex')
    const viewPreference = shift.get('viewPreference')

    let updatedShiftMismatch = Array(24).fill(0)
    let updatedShiftStaff

    const isShiftView = viewPreference === 'shift'
    const isDayView = mode === 'day'
    const isWeekView = mode === 'week'

    const { shiftStaff, shiftMismatch } = this._getShiftStaffAndMismatchForStaffView(
      shift,
      filters,
      staffHoursMap,
      updatedShiftMismatch
    )
    updatedShiftMismatch = shiftMismatch

    if (isShiftOnCall || (isShiftView && !inStaffView)) {
      let shiftViewRows
      if (isDayView) {
        const shiftViewRowsForDayView = shift.get('shiftViewRowsForDayView') || List()
        shiftViewRows = this._createEventBarsAndHourCellsForShiftViewRows(shiftViewRowsForDayView)
      } else if (isWeekView) {
        shiftViewRows = shift.get('shiftViewRowsForWeekView') || List()
      } else {
        shiftViewRows = shift.get('shiftViewRows') || List()
      }
      const isOtherStaffLoaded = this.calendar.isOtherStaffLoaded()
      if (!isOtherStaffLoaded) {
        this.component.dataController.loadOtherStaff()
      }
      updatedShiftStaff = shiftViewRows.map((shiftViewRow, index) =>
        shiftViewRow.merge({
          kind: 'staff',
          id: `${shiftId}-${index}`,
          roleId,
          shiftId,
          roleIndex,
          shiftIndex,
          staffIndex: index,
          isShiftLoading,
          shiftName,
          isShiftView: true
        })
      )
    } else {
      updatedShiftStaff = shiftStaff
    }

    return { shiftStaff: updatedShiftStaff, shiftMismatch: updatedShiftMismatch }
  }

  _getShiftViewRowsForDayView(shiftViewRowsForFullView) {
    const { activeDateRange } = this.props
    const usDate = activeDateRange.get('usDate')
    const dayIndex = activeDateRange.get('days').findIndex((day) => day.usDate === usDate)
    const shiftViewRowsForDayView = shiftViewRowsForFullView.reduce((memoRows, shiftViewRow) => {
      let cell = shiftViewRow.get('cells').get(dayIndex)
      if (cell.get('staffEvents')) {
        memoRows.push(fromJS({ cells: [cell] }))
      }
      return memoRows
    }, [])

    let rowsWithMultiDayEventCells = shiftViewRowsForFullView.map((shiftViewRow) =>
      this._getCellsWithMultiDayEvent(shiftViewRow.get('cells'), dayIndex, usDate)
    )

    rowsWithMultiDayEventCells = rowsWithMultiDayEventCells.reduce((memoCells, newCellsList) => {
      if (newCellsList.length !== 0) {
        memoCells.push(fromJS({ cells: newCellsList }))
      }
      return memoCells
    }, [])

    return shiftViewRowsForDayView.concat(rowsWithMultiDayEventCells)
  }

  _getShiftViewRowsForWeekView(shiftViewRowsForFullView) {
    const countRequiredRowsForWeek = shiftViewRowsForFullView.forEach((shiftViewRow) => {
      const weekCells = this.getCurrentWeekCells(shiftViewRow.get('cells'))

      const filledCellExists = weekCells.find((cell) => cell.get('staff'))
      if (!filledCellExists) {
        return false // breaks out of forEach loop in the case of Immutable Lists
      }
    }) // forEach returns the number of iterations including the iteration that returned false
    return shiftViewRowsForFullView.slice(0, countRequiredRowsForWeek)
  }

  // might not return a list of "7" cells for the first and last weeks of a monthly schedule
  getCurrentWeekCells(cells) {
    const { activeDateRange, timeService } = this.props
    const firstDayIndexInWeek = DateRangeService.getFirstDayIndexForCurrentWeek(activeDateRange, timeService)
    const weekFirstCellIndex = Math.max(firstDayIndexInWeek, 0) // for monthly schedule
    const weekLastCellIndex = Math.min(firstDayIndexInWeek + 7, cells.size) // for monthly schedule
    return cells.slice(weekFirstCellIndex, weekLastCellIndex)
  }

  _getCellsWithMultiDayEvent(cells, dayIndex, usDate) {
    const { timeService } = this.props
    const numberOfDaysToLookBack = MAX_DURATION_OF_SHIFT_IN_MINUTES / 60 / 24

    const dayStartsAt = timeService.usDateToMoment(usDate).toISOString()
    const dayEndsAt = timeService.timeMoment(dayStartsAt).add(1, 'day').add(-1, 'minute')

    let multiDayEventCells = []
    for (let i = 1; i <= numberOfDaysToLookBack; ++i) {
      const prevDayCell = cells.get(dayIndex - i)
      const prevDayStaffEvents = prevDayCell.get('staffEvents')
      if (prevDayStaffEvents) {
        const staffEvents = prevDayStaffEvents.filter((staffEvent) => {
          const eventStartsAt = timeService.timeMoment(staffEvent.get('startsAt'))
          const eventEndsAt = timeService.timeMoment(staffEvent.get('endsAt'))
          return eventStartsAt.isSameOrBefore(dayEndsAt) && eventEndsAt.isSameOrAfter(dayStartsAt)
        })
        if (staffEvents.size !== 0) {
          multiDayEventCells.push(prevDayCell)
        }
      }
    }
    return multiDayEventCells
  }

  _createEventBarsAndHourCellsForShiftViewRows(shiftViewRows) {
    const { activeDateRange } = this.props
    const usDate = activeDateRange.get('usDate')
    const dayIndex = activeDateRange.get('days').findIndex((day) => day.usDate === usDate)
    return shiftViewRows.map((shiftViewRow, index) => {
      let hourCells = Array.from(Array(24).keys()).map((index) => ({
        value: 0,
        hour: index,
        dayIndex: dayIndex
      }))
      let eventBars = []
      let staffEvents = []
      const cell = shiftViewRow.get('cells').get(0)
      if (cell.get('staffEvents')) {
        staffEvents = cell
          .get('staffEvents')
          .toJS()
          .map((staffEvent) => ({
            ...staffEvent,
            type: 'assignment',
            userId: cell.get('staff'),
            shiftId: '64667f31b5f1b33d63bf8cde',
            notesIds: [],
            unitEventVariantId: 'assignment'
          }))
        const { updatedEventBars, updatedHourCells } = this._handleStaffEvents(
          staffEvents,
          dayIndex,
          usDate,
          hourCells,
          eventBars
        )
        hourCells = updatedHourCells
        eventBars = updatedEventBars
      }

      return shiftViewRow.set('hourCells', hourCells).set('eventBars', eventBars)
    })
  }

  _getShiftStaffAndMismatchForStaffView = (shift, filters, staffHoursMap) => {
    // TODO: Break down function for SRP
    const groupBy = filters.getIn(['displayOptions', 'staff', 'groupBy'])
    const shiftName = shift.get('name')
    const shiftId = shift.get('id')
    const roleId = shift.get('unitRoleId')
    let updatedShiftMismatch = Array(24).fill(0)
    const { isStaffVisible } = this._getFilters(filters)
    const isShiftLoading = shift.get('isLoading')
    const isOtherStaff = shiftId === 'secondary-staff-shift'

    let updatedShiftStaff = shift.get('staff').map(this._setHomeUnitId).sort(this._sortShiftStaff)

    if (isOtherStaff) {
      updatedShiftStaff = updatedShiftStaff.sort(this._sortShiftStaffByHomeUnit)
    }
    updatedShiftStaff = updatedShiftStaff
      .map(this._countEventHours)
      .map(this._addWeekHours(shift))
      .reduce((memoStaff, staff) => {
        if (!isStaffVisible(staff)) {
          return memoStaff
        }
        const dividerRow = this._getDividerRowForStaff(memoStaff, staff, shiftId, groupBy, isOtherStaff)
        if (dividerRow) {
          memoStaff = memoStaff.push(dividerRow)
        }
        const staffId = staff.get('id')
        const hrs = this._fixedHours(staffHoursMap.getIn([staffId, 'value']))

        return memoStaff.push(staff.merge({ roleId, shiftId, isShiftLoading, shiftName, hrs }))
      }, List())

    if (updatedShiftMismatch && updatedShiftStaff) {
      updatedShiftStaff.forEach((item) => {
        const hourCells = item.get('hourCells')
        if (hourCells) {
          updatedShiftMismatch = hourCells.map(
            (hourCell, index) => this._getShiftWorkingHoursinHourCell(hourCell, shiftId) + updatedShiftMismatch[index]
          )
        }
      })
    }
    return {
      shiftStaff: updatedShiftStaff,
      shiftMismatch: updatedShiftMismatch
    }
  }

  _sortShiftStaffByHomeUnit(staff1, staff2) {
    if (!staff1.get('homeUnitId') && !staff2.get('homeUnitId')) {
      return 0
    }
    if (!staff1.get('homeUnitId')) {
      return 1
    }
    if (!staff2.get('homeUnitId')) {
      return -1
    }
    if (staff1.get('homeUnitId') === staff2.get('homeUnitId')) {
      return 0
    }
    return staff1.get('homeUnitId') < staff2.get('homeUnitId') ? -1 : 1
  }

  _getDividerRowForStaff(memoStaff, staff, shiftId, groupBy, isOtherStaff) {
    const isNewUnitStaff = memoStaff.last()?.get('homeUnitId') !== staff.get('homeUnitId')
    if (isOtherStaff && isNewUnitStaff) {
      const unit = this.props.eligibleUnits.find((currUnit) => currUnit.get('id') === staff.get('homeUnitId'))
      const unitName = unit?.get('name')
      if (!unitName) {
        return Map({ id: 'incoming-staff', kind: 'unit', name: 'Incoming staff' })
      }
      return Map({ id: staff.get('homeUnitId'), kind: 'unit', name: unitName })
    } else if (groupBy === 'employment' && memoStaff.size && staff.get('type') !== memoStaff.last().get('type')) {
      const key = `st-${shiftId}-${memoStaff.last().get('type')}-${staff.get('type')}`
      return Map({ kind: 'staff-divider', key })
    }
    return null
  }

  _setHomeUnitId = (staff) => {
    const { activeDateRange, timeService } = this.props
    const staffWrapper = new Staff(staff)
    const homeUnitId = staffWrapper.getHomeUnitIdForDate(activeDateRange.get('startsAt'), timeService)
    return staff.set('homeUnitId', homeUnitId)
  }

  _getFilters = memoize((filters) => {
    const calendarFilterService = new CalendarFilterService(filters)
    const { isRoleVisible, isShiftVisible, isStaffVisible } = calendarFilterService

    return { isRoleVisible, isShiftVisible, isStaffVisible }
  })

  _fixedHours(hrs = 0) {
    if (Math.floor(hrs) === hrs) {
      return hrs
    }
    return Number(hrs).toFixed(2)
  }

  _addWeekHours(shift) {
    const {
      match: {
        params: { mode = 'full' }
      }
    } = this.props
    const staffAssignedWeekHours = shift.get('staffAssignedWeekHours') && shift.get('staffAssignedWeekHours').toJS()
    return (staff, staffIndex) => {
      if (mode === 'day') {
        const userId = staff.get('id')
        const staffAssignedWeekHoursByUserId = staffAssignedWeekHours?.filter((item) => item['userId'] === userId)
        const weekHoursObject = staffAssignedWeekHoursByUserId?.[0]
        const weekHours = weekHoursObject?.['assignedHours']
        staff = staff.set('weekHours', weekHours)
      }
      return staff
    }
  }

  _hourDiffAndstartsAtHours = (startsAt, dayDate, usDate) => {
    const { timeService } = this.props
    let hourDiff = 0
    let startsAtHours = timeService.timeMoment(startsAt).hours()
    if (dayDate !== usDate) {
      hourDiff = 24 - startsAtHours
      startsAtHours = 0
    }
    return { hourDiff, startsAtHours }
  }

  _formatEmptyHourCells = (hourCells) => {
    return hourCells.forEach((hourCell, i) => {
      const { value, hour, paidMinutes } = hourCell
      hourCells[i] = hourCell.value === 0 ? { value, hour, paidMinutes } : hourCell
    })
  }

  _calculateHourCellsHour = (hourCells, shiftHours, dayIndex, eventType, eventShiftId) => {
    for (const hour of shiftHours) {
      hourCells[hour] = {
        value: 1,
        hour,
        dayIndex,
        eventType,
        eventShiftId
      }
    }
  }

  _calculateHourCells = (hourCells, lastHour, startsAtHours, fullHours, dayIndex, eventType, eventShiftId) => {
    if (lastHour && startsAtHours + fullHours + 1 <= 23) {
      hourCells[startsAtHours + fullHours + 1] = {
        value: lastHour,
        hour: startsAtHours + fullHours,
        dayIndex,
        eventType,
        eventShiftId
      }
    }
  }
  _isStartAtAndEventDuration = (startsAt, eventDuration) => {
    return startsAt && eventDuration
  }

  _handleStaffEvents = (staffEvents, currentDayIndex, usDate, hourCells, eventBars) => {
    let tempHourCells = cloneDeep(hourCells)

    for (const staffEvent of staffEvents) {
      this._handleStaffEvent(staffEvent, currentDayIndex, usDate, tempHourCells, eventBars)
    }
    return { updatedEventBars: eventBars, updatedHourCells: tempHourCells }
  }

  _handleStaffEvent = (staffEvent, currentDayIndex, usDate, hourCells, eventBars) => {
    //TODO: revamp hour cell model to handle assignment and time off in the same hour cell
    if (!staffEvent) {
      return
    }
    const { activeDateRange, timeService } = this.props
    const { startsAt, type: eventType, duration, shiftId: eventShiftId } = staffEvent
    let eventDuration = duration
    const startsAtDate = timeService.timeMoment(startsAt).format('MM-DD-YYYY')
    const dayIndex = activeDateRange.get('days', []).findIndex((day) => day.usDate === startsAtDate)
    const startsOnCurrentDay = dayIndex === currentDayIndex
    const startsDayBefore = dayIndex === currentDayIndex - 1
    const isFullDayTimeOff = eventType === 'timeOff' && !staffEvent.timeOffAttributes.isPartial

    if (!startsOnCurrentDay && (!startsDayBefore || isFullDayTimeOff)) {
      // TODO: needs to handle event bar on currentDayIndex - 2
      return
    }

    const isFullDayUnpaidTimeOff = isFullDayTimeOff && !staffEvent.timeOffAttributes.paidMinutes
    if (isFullDayUnpaidTimeOff) {
      this._handleFullDayUnpaidTimeOff(staffEvent, dayIndex, hourCells, eventBars)
      return
    }

    const startsAtMinutes = timeService.timeMoment(startsAt).minutes()

    const { hourDiff, startsAtHours } = this._hourDiffAndstartsAtHours(startsAt, startsAtDate, usDate)
    const firstHour = 1 - 1 / (60 / startsAtMinutes)
    const eventDurationHours = eventDuration / 60

    hourCells[startsAtHours] = {
      value: firstHour,
      hour: startsAtHours,
      dayIndex,
      eventType,
      eventShiftId
    }

    const fullHours = Math.max(Math.trunc(eventDurationHours - firstHour - hourDiff), 0)

    const shiftHours = Array(fullHours)
      .fill(0)
      .map((_, idx) => startsAtHours + idx + 1)
      .filter((hour) => hour <= 23)
    this._calculateHourCellsHour(hourCells, shiftHours, dayIndex, eventType, eventShiftId)
    const lastHour = (eventDurationHours - firstHour) % 1

    this._calculateHourCells(hourCells, lastHour, startsAtHours, fullHours, dayIndex, eventType, eventShiftId)
    this._formatEmptyHourCells(hourCells)
    eventBars.push({
      ...staffEvent,
      startsDayBefore,
      startsAtHours,
      endsAtHours: startsAtHours + fullHours + 1,
      firstHour,
      lastHour,
      dayIndex
    })
  }

  _handleFullDayUnpaidTimeOff = (timeOffStaffEvent, dayIndex, hourCells, eventBars) => {
    hourCells.forEach((_hourCell, i) => {
      hourCells[i] = {
        value: 1,
        hour: i,
        dayIndex,
        eventType: 'timeOff'
      }
    })
    eventBars.push({
      ...timeOffStaffEvent,
      startsAtHours: 0,
      endsAtHours: 23,
      firstHour: 1,
      lastHour: 1,
      dayIndex
    })
  }

  _countEventHours = (staff) => {
    const { activeDateRange } = this.props
    const {
      match: {
        params: { date: usDate }
      }
    } = this.props

    const currentDayIndex = activeDateRange.get('days', []).findIndex((day) => day.usDate === usDate)
    let hourCells = Array.from(Array(24).keys()).map((index) => ({
      value: 0,
      hour: index,
      dayIndex: currentDayIndex
    }))
    let eventBars = []
    const staffEvents = staff.get('staffEvent') //TODO: change field name in staff to staffEvents
    if (staffEvents && staffEvents.length > 0) {
      const { updatedEventBars, updatedHourCells } = this._handleStaffEvents(
        staffEvents,
        currentDayIndex,
        usDate,
        hourCells,
        eventBars
      )
      hourCells = updatedHourCells
      eventBars = updatedEventBars
    }
    staff = staff.set('hourCells', hourCells)
    staff = staff.set('eventBars', eventBars)
    return staff
  }
}
