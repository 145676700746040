import VUnitTitle from './VUnitTitle'
import './Shift.scss'
import classNames from 'classnames'

export default function VUnit(props) {
  const { unit, style, calendarType } = props

  if (unit.get('hidden')) {
    return null
  }

  const additionalCSS = calendarType !== 'calendar' ? { height: '2.4rem' } : {}
  return (
    <div className="_shift" style={style}>
      <header className="hx-calendar-shift">
        <div
          className={classNames('row align-middle bg-mercury', { 'flex-nowrap': calendarType !== 'calendar' })}
          style={{ ...additionalCSS }}
        >
          <VUnitTitle unit={unit} />
        </div>
      </header>
    </div>
  )
}
