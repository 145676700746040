import { Component } from 'react'
import ReactPaginate from 'react-paginate'
import './Pagination.scss'

export default class Pagination extends Component {
  render() {
    const { activePage, itemsCountPerPage, pageRangeDisplayed, totalItemsCount, onChange, namespace } = {
      ...defaultProps,
      ...this.props
    }

    const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage)

    if (totalPages < 2) {
      return null
    }

    const onPageChange = (event) => {
      onChange(event.selected + 1)
    }

    const paginationProps = {
      forcePage: activePage - 1,
      className: 'pagination',
      activeClassName: 'active',
      pageCount: totalPages,
      pageRangeDisplayed,
      onPageChange,
      previousClassName: 'prev',
      nextClassName: 'next'
    }

    return (
      <div className={`pagination-container text-center ${namespace || ''}`}>
        <ReactPaginate {...paginationProps} />
      </div>
    )
  }
}

const defaultProps = {
  itemsCountPerPage: 25,
  pageRangeDisplayed: 5
}
