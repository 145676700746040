import { ShiftAssignmentsDetails, SwappingShiftDetails, TimeOffsDetails } from './SectionDetails'
import { Map } from 'immutable'
import DayDetails from './DayDetails'

type DetailsPaneProps = {
  usDate: string
  timeService: any
  staffEventsMap: Map<string, any>
  publicStaffMap: Map<string, Map<string, any>>
  unitsMap: any
  staffEventTypeFilters?: Set<string>
  section: string
  initiatedShiftSwapRequests: any
  receivedShiftSwapRequests: any
  shiftsMap: any
  userId: string
  staffTimeOffRequestsMap: any
  setSelectedCellDate: any
  selectedCellDate: any
}
export default function DetailsPane(props: DetailsPaneProps) {
  const {
    timeService,
    usDate,
    unitsMap,
    staffEventsMap,
    publicStaffMap,
    section,
    initiatedShiftSwapRequests,
    userId,
    receivedShiftSwapRequests,
    shiftsMap,
    staffTimeOffRequestsMap,
    setSelectedCellDate,
    selectedCellDate
  } = props
  const staffEventsForDay = staffEventsMap.get(timeService.usDateToMoment(usDate).toISOString())

  const dayDetailsProps = {
    timeService,
    usDate,
    unitsMap,
    staffEvents: staffEventsForDay,
    publicStaffMap,
    shiftsMap
  }

  const swappingShiftDetailsProps = {
    timeService,
    usDate,
    initiatedShiftSwapRequests,
    receivedShiftSwapRequests,
    publicStaffMap,
    unitsMap,
    shiftsMap,
    userId
  }

  const timeOffProps = {
    timeService,
    usDate,
    staffTimeOffRequestsMap,
    setSelectedCellDate,
    selectedCellDate
  }

  if (section === 'shifts-assigned') {
    return <ShiftAssignmentsDetails {...dayDetailsProps} />
  } else if (section === 'shift-swaps') {
    return <SwappingShiftDetails {...swappingShiftDetailsProps} />
  } else if (section === 'time-offs') {
    return <TimeOffsDetails {...timeOffProps} />
  }
  return <DayDetails {...dayDetailsProps} />
}
