import { Component, createRef } from 'react'
import Activity from './Activity'
import PropTypes from 'prop-types'
import { List } from 'immutable'

class ActivitiesGroup extends Component {
  static propTypes = {
    timeService: PropTypes.object.isRequired,
    activities: PropTypes.instanceOf(List).isRequired,
    unit: PropTypes.object.isRequired
  }

  static defaultProps = {
    currentActivityDate: null
  }

  contentRef = createRef()

  render() {
    const { activities, unit, isUnitLoaded, timeService } = this.props

    const dateMoment = timeService.timeMoment(activities.first().get('createdAtMoment'))
    const formattedDate = dateMoment.format('dddd, MMMM D')
    const ISOformatDate = dateMoment.toISOString()

    return (
      <>
        <div className="activities-dates" ref={this.contentRef} data-date={ISOformatDate}>
          <sh-text size="header-2">{formattedDate}</sh-text>
          <div className="share-icon" />
        </div>
        <div className="activities-day">
          {activities.map((activity, key) => (
            <Activity key={key} isUnitLoaded={isUnitLoaded} activity={activity} unit={unit} timeService={timeService} />
          ))}
        </div>
      </>
    )
  }
}

export default ActivitiesGroup
