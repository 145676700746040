import { PureComponent } from 'react'
import classNames from 'classnames'
import { startCase, toLower } from 'lodash'
import { withAppContext } from 'AppContext'
import './profileDropdown.scss'
import DropdownList from './DropdownList'
import { paths } from 'Navigation'
import Avatar from 'Manager/components/Avatar'
import LastLoginBanner from './LastLoginBanner'

export class ProfileDropdown extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  render() {
    const { authStore, appState } = this.props
    const auth = appState.get('authentication')
    const isLoggedIn = auth.get('isLoggedIn')
    const profile = auth.get('profile')

    const dropdownListProps = {
      authStore,
      appState,
      switchFacilityRedirectTo: paths.Home,
      className: 'dropdown-options'
    }

    const name = startCase(toLower(`${profile.get('firstName')} ${profile.get('lastName')}`))

    return (
      <div
        className={classNames('dropdown-menu float-right pl10', {
          hide: !isLoggedIn
        })}
      >
        <LastLoginBanner appState={appState} />
        <div onMouseEnter={this.showDropdown} onMouseLeave={this.hideDropdown}>
          <button id="avatar-btn" className="text-right" onClick={this.toggleDropdown} onBlur={this.hideDropdown}>
            <Avatar profile={profile} />
            <i className="icon-down slate-gray" />
          </button>
          <sh-tooltip title={name} label="Unit Manager" target="avatar-btn" placement="left" />
          {this.state.active && <DropdownList {...dropdownListProps} />}
        </div>
      </div>
    )
  }

  showDropdown = (e) => {
    this.setState(() => {
      return { active: true }
    })
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => {
      return { active: !prevState.active }
    })
  }

  hideDropdown = (e) => {
    setTimeout(() => this.setState({ active: false }), 200)
  }
}

export default withAppContext(ProfileDropdown)
