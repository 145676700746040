import {
  deleteStaffComplianceRuleMutation,
  createStaffComplianceRuleMutation,
  updateStaffComplianceRuleMutation
} from '../Mutations'

function StaffComplianceRules() {
  let gqlClient = null
  let updateComplianceRule = null

  const actions = {
    createStaffComplianceRule,
    deleteStaffComplianceRule,
    updateStaffComplianceRule
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, updateComplianceRule } = context)
  }

  async function createStaffComplianceRule(parameters) {
    const { position, employmentType } = parameters
    const { userId, ruleType, startDate, value } = parameters
    const staffComplianceRule = {
      position,
      employmentType,
      userIds: [userId],
      ruleType,
      startDate,
      value
    }

    const { createStaffComplianceRule: rule } = await gqlClient.mutate(createStaffComplianceRuleMutation, {
      staffComplianceRule
    })

    return updateComplianceRule(rule)
  }

  async function deleteStaffComplianceRule({ id, userId }) {
    await gqlClient.mutate(deleteStaffComplianceRuleMutation, { id })

    return updateComplianceRule({ id }, true)
  }

  async function updateStaffComplianceRule(parameters) {
    // TODO: ruleType, startDate
    const { id, position, employmentType, value } = parameters
    // TODO skip  userIds: [userId].
    // TODO: ruleType, startDate
    const staffComplianceRule = { position, employmentType, value }
    const props = { id, staffComplianceRule }

    const { updateStaffComplianceRule: rule } = await gqlClient.mutate(updateStaffComplianceRuleMutation, props)

    return updateComplianceRule(rule)
  }
}

export default StaffComplianceRules()
