import { Component } from 'react'
import SearchInput from 'components/Form/SearchInput'
import { t } from 'i18n'

export default class ActivitiesNavBar extends Component {
  render() {
    const { controller, filters } = this.props

    return (
      <div className="activities-nav-bar row common-toolbar">
        <div className="hx-activities-filter row align-middle col col-5">
          <span>Filter:</span>
          <form data-testid="activities-nav-bar-filter" className="pr20">
            <input
              type="checkbox"
              name="shiftsFilter"
              id="shift"
              value="notFilled"
              checked={filters.includes('shift')}
              onChange={controller.onFilterChange}
            />
            <label className="text" htmlFor="shift">
              {t('activities.filter.shifts')}
            </label>
            <input
              type="checkbox"
              name="timeOffFilter"
              id="paidTimeOff"
              value="notFilled"
              checked={filters.includes('paidTimeOff')}
              onChange={controller.onFilterChange}
            />
            <label className="text" htmlFor="paidTimeOff">
              {t('activities.filter.time_off')}
            </label>
            <input
              type="checkbox"
              name="openShiftFilter"
              id="openShift"
              value="notFilled"
              checked={filters.includes('openShift')}
              onChange={controller.onFilterChange}
            />
            <label className="text" htmlFor="openShift">
              {t('activities.filter.open_shift')}
            </label>
            <input
              type="checkbox"
              name="shiftSwapFilter"
              id="shiftSwap"
              value="notFilled"
              checked={filters.includes('shiftSwap')}
              onChange={controller.onFilterChange}
            />
            <label className="text" htmlFor="shiftSwap">
              {t('activities.filter.shift_swaps')}
            </label>
            <input
              type="checkbox"
              name="scheduleFilter"
              id="schedule"
              value="notFilled"
              checked={filters.includes('schedule')}
              onChange={controller.onFilterChange}
            />
            <label className="text" htmlFor="schedule">
              {t('activities.filter.schedule')}
            </label>
            <input
              type="checkbox"
              name="availabilityFilter"
              id="unavailability"
              value="notFilled"
              checked={filters.includes('unavailability')}
              onChange={controller.onFilterChange}
            />
            <label className="text" htmlFor="unavailability">
              {t('activities.filter.availability')}
            </label>
          </form>
          <span className="resetFilters pl20 bl1 cursor-pointer" onClick={controller.resetFilters}>
            {t('activities.filter.reset_all_filters')}
          </span>
        </div>

        <div className="col col-2 row align-middle align-center title">
          <h1>Activity</h1>
        </div>

        <div className="controls col-1 bl1">
          <SearchInput
            type="text"
            classNames="search"
            placeholder="Search"
            onChange={controller.searchPeople}
            onReset={controller.searchPeople}
            iconStyle={{ left: '-2px', top: '1em' }}
          />
        </div>
      </div>
    )
  }
}
