import { Component } from 'react'
import { Modal } from 'Common/components'
import CreateEditStaffForm from './CreateEditStaffForm'
import { navigate } from 'Navigation'
import { Redirect } from 'react-router'
import { Staff } from 'entityWrappers'
import { pick } from 'lodash'

export default class CreateEditStaffModal extends Component {
  hideModal = () => {
    const { isEdit } = this.props
    if (isEdit) {
      return navigate.from.StaffEdit.to.StaffDetails()
    }
    navigate.from.StaffNew.to.Staff()
  }

  canEditStaff = () => {
    const { unitManager, timeService, staff } = this.props

    const currentEligibleUnit = new Staff(staff).getCurrentEligibleUnit(timeService)

    const managerOtherUnitIds = unitManager.getIn(['unitManagerProfile', 'otherUnitIds'])
    const managerHomeUnitId = unitManager.getIn(['unitManagerProfile', 'homeUnitId'])
    const availableUnitIds = managerOtherUnitIds.push(managerHomeUnitId)

    const currentHomeUnitId = currentEligibleUnit?.get('homeUnitId')
    return availableUnitIds.includes(currentHomeUnitId)
  }

  render() {
    const { isEdit } = this.props
    if (isEdit && !this.canEditStaff()) {
      const path = navigate.from.StaffEdit.to.StaffDetails({}, false)
      return <Redirect to={path} />
    }

    const modalProps = pick(
      {
        ...this.props,
        width: 588,
        height: 522,
        visible: true,
        onClose: this.hideModal,
        title: isEdit ? 'edit Profile Information' : 'Add Staff'
      },
      ['title', 'width', 'height', 'visible', 'onClose', 'dismissOnOutsideClick', 'showClose']
    )

    const formProps = pick(
      {
        ...this.props,
        onClose: this.hideModal
      },
      [
        'match',
        'staff',
        'units',
        'isEdit',
        'Dialog',
        'onClose',
        'onSubmit',
        'homeUnitId',
        'timeService',
        'staffPositions',
        'managerFullName',
        'reloadStaff',
        'currentUnitId'
      ]
    )

    return (
      <div>
        <Modal {...modalProps}>
          <CreateEditStaffForm {...formProps} />
        </Modal>
      </div>
    )
  }
}
