import useShuiEventHook from '../Common/customHooks/shuiEventHook'

import { useCallback } from 'react'
import { customSlotReasons, getLabelForCustomSlotReason, getReasonFromLabel } from '../utils'

export interface IReasonDropdownProps {
  reason: string
  setReason: (reason: string) => void
}

const ReasonDropdown = ({ reason, setReason }: IReasonDropdownProps) => {
  const reasonLabel = getLabelForCustomSlotReason(reason)
  const handleReasonChangeEvent = useCallback(
    (event: any) => {
      const selectedReason = getReasonFromLabel(event?.target?.value)
      setReason(selectedReason)
    },
    [setReason]
  )

  const eventName = 'value-changed'
  const reasonDropdownRef = useShuiEventHook(eventName, handleReasonChangeEvent)

  return (
    <sh-dropdown ref={reasonDropdownRef} label="Reason" editable value={reasonLabel}>
      {customSlotReasons.map(({ label, key }) => (
        <sh-menu-item label={label} key={key}></sh-menu-item>
      ))}
    </sh-dropdown>
  )
}

export default ReasonDropdown
