import { useEffect, useState } from 'react'
import { withAppContext } from 'AppContext'

// @ts-ignore
import { addWarningBanner, addSuccessBanner } from 'stores'
import { recommendAppointmentSlots, bookAppointmentSlot } from './Queries'
import { getLabelForLocation, getLabelForStudyCodes, formatAsDate, formatAsTime, getDurationLabel } from './utils'

import RecommendedSlots from './RecommendedSlots'
import SlotPreferences from './SlotPreferences'
import Footer from 'Footer'
import { ActivityTracker } from './Common/activityTracker'
import { IRecommendedSlots } from './RecommendedSlots/RecommendedSlots'

const getRecommendedSlots = async (
  location: string,
  studyCodes: string[],
  date: string,
  time: string,
  insuranceAuthorization: boolean,
  gqlClient: any
) => {
  const patientPreference: any = {}

  if (location) {
    patientPreference.siteName = location
  }

  if (date) {
    patientPreference.date = date
  }

  if (time) {
    patientPreference.time = time
  }

  const {
    recommendAppointmentSlots: { recommendedSlots, studyCodes: responseStudyCodes }
  } = await gqlClient.mutate(recommendAppointmentSlots, {
    parameters: {
      appointmentDetails: {
        studyCodes,
        insuranceAuthorization
      },
      patientPreference
    }
  })

  return {
    data: recommendedSlots.map(({ siteName, dateTime, duration }: any) => ({
      location: siteName,
      startsAt: dateTime,
      duration
    })),
    studyCodes: responseStudyCodes
  }
}

const bookSlot = async (
  { location, startsAt, reason, reasonDetails }: any,
  studyCodes: string[] | undefined,
  gqlClient: any,
  isRecommendedSlot: boolean
) => {
  await gqlClient.mutate(bookAppointmentSlot, {
    parameters: {
      studyCodes,
      siteName: location,
      dateTime: startsAt,
      reason,
      reasonDetails,
      isRecommendedSlot
    }
  })
}

const PatientSlotRecommender = ({ gqlClient, timeService }: any) => {
  const [recommendedSlots, setRecommendedSlots] = useState<Partial<IRecommendedSlots>>({})
  const [lastSubmittedPreferences, setLastSubmittedPreferences] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [resetKey, setResetKey] = useState(0)

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1)
  }

  const handleSlotSearch = async (
    location: string,
    studyCodes: string[],
    date: string,
    time: string,
    insuranceAuthorization: boolean
  ) => {
    if (!studyCodes?.length) {
      addWarningBanner({ message: 'Please select atleast one Study code' })
      return
    }

    let newRecommendedSlots: any = null

    try {
      setIsLoading(true)
      newRecommendedSlots = await getRecommendedSlots(
        location,
        studyCodes,
        date,
        time,
        insuranceAuthorization,
        gqlClient
      )
      setLastSubmittedPreferences({ location, studyCodes, date, time, insuranceAuthorization })
    } catch (err: any) {
      setLastSubmittedPreferences({})

      if (err.graphQLErrors?.[0]?.httpStatusCode > 400 && err.graphQLErrors?.[0]?.httpStatusCode < 500) {
        addWarningBanner({
          message: 'Unexpected error fetching recommended slots. Try re-submitting preferences.'
        })
      }
    } finally {
      setRecommendedSlots(newRecommendedSlots)
      setIsLoading(false)
    }
  }

  const handleSlotBooking = async (slot: any, studyCodes: string[] | undefined, isCustom: boolean) => {
    const { location, startsAt, reason, reasonDetails, duration } = slot

    if (isCustom && (!location || !startsAt || !reason || (reason === 'other' && !reasonDetails))) {
      addWarningBanner({ message: 'Please fill in all the fields of the slot before booking' })
      return
    }

    try {
      setIsLoading(true)

      await bookSlot(slot, studyCodes, gqlClient, !isCustom)

      const locationLabel = getLabelForLocation(location)
      const studyCodeLabels = getLabelForStudyCodes(studyCodes)
      const date = formatAsDate(startsAt, timeService)
      const time = formatAsTime(startsAt, timeService)
      const endsAtMoment = timeService.timeMoment(startsAt)
      const endsAt = timeService.add(endsAtMoment, duration, 'minutes')
      const endsAtDate = formatAsDate(endsAt, timeService)
      const endsAtTime = formatAsTime(endsAt, timeService)
      const durationLabel = duration > 0 ? getDurationLabel(duration) : ''
      const dateLabel = endsAtDate === date ? date : `${date} - ${endsAtDate}`
      const timeLabel = duration > 0 ? `${time} - ${endsAtTime} (${durationLabel})` : `${time}`

      addSuccessBanner({
        message: `Slot booked successfully for \n Location: ${locationLabel}, Study codes: ${studyCodeLabels}, Date: ${dateLabel}, Time: ${timeLabel}`
      })

      ActivityTracker.EndTracking()

      setLastSubmittedPreferences({})
      handleReset()
    } catch (err: any) {
      if (
        err.graphQLErrors?.[0]?.httpStatusCode !== 409 &&
        err.graphQLErrors?.[0]?.httpStatusCode > 400 &&
        err.graphQLErrors?.[0]?.httpStatusCode < 500
      ) {
        addWarningBanner({ message: 'Unexpected error while booking slot. Try re-submitting details.' })
      }

      ActivityTracker.TrackingFailed(err)
    } finally {
      setIsLoading(false)
    }
  }

  const windowUnloadHandle = () => {
    ActivityTracker.TerminateTracking('page_reload')
  }

  useEffect(() => {
    window.addEventListener('unload', windowUnloadHandle)

    return () => {
      window.removeEventListener('unload', windowUnloadHandle)
      ActivityTracker.TerminateTracking()
    }
  }, [])

  return (
    <div className="h100">
      <div className="wrapper-container" style={{ height: '100%' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <sh-page>
            {isLoading && <sh-spinner overlay />}

            <SlotPreferences resetKey={resetKey} timeService={timeService} handleSlotSearch={handleSlotSearch} />

            <RecommendedSlots
              timeService={timeService}
              recommendedSlots={recommendedSlots}
              preferences={lastSubmittedPreferences}
              handleSlotBooking={handleSlotBooking}
            />
          </sh-page>
        </div>
      </div>
      <Footer timeService={timeService} />
    </div>
  )
}

export default withAppContext(PatientSlotRecommender)
