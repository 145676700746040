import { PureComponent } from 'react'
import classNames from 'classnames'

export default class Header extends PureComponent {
  render() {
    const { mismatches, roleName } = this.props
    return (
      <header className="row _group-header">
        <aside className="col-2 hx-group-summary-header">
          <span className="upper smaller bold">{roleName}</span>
          <div className="hx-staffing-totals rounded bg-white flex">{mismatches.map(this.renderShiftMismatch)}</div>
        </aside>
        <section className="col-10">
          <div className="hx-calendar-group-hours row">
            <div className="col-1">
              <i className="icon-sun" />
              <span>6</span>
            </div>
            <div className="col-1">
              <span>8</span>
            </div>
            <div className="col-1">
              <i className="icon-sun" />
              <span>10</span>
            </div>
            <div className="col-1">
              <span>12</span>
            </div>
            <div className="col-1">
              <span>14</span>
            </div>
            <div className="col-1">
              <span>16</span>
            </div>
            <div className="col-1">
              <i className="icon-moon" />
              <span>18</span>
            </div>
            <div className="col-1">
              <span>20</span>
            </div>
            <div className="col-1">
              <span>22</span>
            </div>
            <div className="col-1">
              <span>24</span>
            </div>
            <div className="col-1">
              <span>2</span>
            </div>
            <div className="col-1">
              <span>4</span>
            </div>
            <div className="col-1">
              <span>6</span>
            </div>
          </div>
        </section>
      </header>
    )
  }

  renderShiftMismatch = (mismatch, index) => {
    const isOverstaffed = mismatch < 0
    const isUnderstaffed = mismatch > 0

    return (
      <span
        key={index}
        className={classNames('bold', {
          'hx-overstaffed': isOverstaffed,
          'hx-understaffed': isUnderstaffed
        })}
      >
        {Math.abs(mismatch)}
      </span>
    )
  }
}
