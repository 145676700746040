import { Redirect } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import { navigate, paths } from 'Navigation'
import { List } from 'immutable'
import { Staff, SkillscheckAdministrator } from 'auth/Roles'

// Current path is FacilityHome
// *.humanics.com/facility-id
export function HomeUnitRedirect(props) {
  const auth = props.appState.get('authentication')

  const roleIds = auth.getIn(['facilityUser', 'roleIds']) || List()
  const isStaffOnly = roleIds.size === 1 && roleIds.every((roleId) => roleId === Staff)
  const isSkillscheckAdministratorOnly =
    roleIds.size === 1 && roleIds.every((roleId) => roleId === SkillscheckAdministrator)

  if (isStaffOnly) {
    const { todayUsDate } = props.timeService
    return <Redirect to={navigate.from.FacilityHome.to.StaffCalendarPage({ date: todayUsDate })} />
  }

  if (isSkillscheckAdministratorOnly) {
    return <Redirect to={paths.SkillscheckAdministratorPage} />
  }

  // TODO: this should be home unit NOT current unit!
  // TODO: we need `FacilityUser.homeUnitUrlId` field!
  // TODO: facilityUser.get('homeUnitUrlId') || facilityUser.getIn(['eligibleUnitIds', 0]);
  const unitUrlId = auth.getIn(['facility', 'unit', 'urlId'])
  if (!unitUrlId) {
    return <Redirect to={paths.NoUnits} />
  }

  const { to } = navigate.from.FacilityHome
  return <Redirect to={to.FacilityUnitHome({ unitUrlId }, false)} />
}

export default withAppContext(HomeUnitRedirect)
