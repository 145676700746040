import ShiftTypesController from './ShiftTypesController'
import ShiftTypeAndRoleCreateUpdate from '../RolesAdmin/ShiftTypeAndRoleCreateUpdate'

class ShiftTypeCreateUpdate extends ShiftTypeAndRoleCreateUpdate {
  constructor(props) {
    super(props)

    this.controller = new ShiftTypesController(this)
    const data = this.controller.getShiftTypes()
    this.state = { saving: false, visible: true, error: '', data }
  }

  componentDidUpdate(prevProps) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const prevStoreState = prevAppState.get(stateKey)
    const isStoreStateChanged = storeState !== prevStoreState

    if (isStoreStateChanged) {
      const data = this.controller.getShiftTypes()
      this.setState({ data })
    }
  }
}
export default ShiftTypeCreateUpdate
