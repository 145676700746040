const css = `
      @import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,600,600i,700,700i,900,900i);

      h1 {
          font-size: 1.38462em;
          line-height: 1.14286em;
        }

      h2 {
          font-size: 1.15385em;
          line-height: 1.16667em;
        }

      h3 {
          font-size: 1em;
          line-height: 1.2em;
        }

      body {
        font-family: Nunito Sans, sans-serif;
        color: #535b65;
        background-color: transparent;
      }

      html,
      body {
        font-size: 13px;
        line-height: 1.61538em;
      }

      blockquote {
          padding-left: 1rem;
          border-left: 4px solid rgba(0, 0, 0, 0.1);
          font-style: italic;
          color: rgba(94, 96, 105, 0.65);
          margin: 0;
      }

      ul, ul ul, ul ol, ol, ol ul, ol ol {
          margin: 0 0 0 1.61538em;
          padding: 0;
      }

      p, blockquote, hr, pre, ol, ul, dl, table, fieldset, figure, address, form {
          margin-bottom: 1em;
      }
`

export const detailsHtmlStyle = <style>{css}</style>
