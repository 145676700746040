export default class ShiftService {
  constructor(shift, timeService) {
    this.shift = shift
    this.timeService = timeService
  }

  getShiftPeriodForDate(dateTime) {
    const shiftStartTime = this.shift.get('startTime')
    const shiftLength = Number(this.shift.get('length'))
    const [hours, minutes] = shiftStartTime.split(':')

    // When format is '-06:30':
    const isNegative = shiftStartTime.indexOf('-') !== -1
    const sign = isNegative ? '-' : '+'

    const startOfDay = this.timeService.timeMoment(dateTime).startOf('day')

    const startsAtOffset = Number(hours) * 60 + Number(`${sign}${minutes}`)
    const endsAtOffset = startsAtOffset + shiftLength

    // Calculate offsets taking to account Daylight saving time:
    const trueStartsAtOffset = this.timeService.calculateDLSDuration(startOfDay, startsAtOffset)
    const trueEndsAtOffset = this.timeService.calculateDLSDuration(startOfDay, endsAtOffset)

    const startsAt = startOfDay.clone().add(trueStartsAtOffset, 'minute').toISOString()
    const endsAt = startOfDay.clone().add(trueEndsAtOffset, 'minute').toISOString()

    const length = trueEndsAtOffset - trueStartsAtOffset

    return { startsAt, endsAt, length }
  }

  getShiftParameters() {
    const unitId = this.shift.get('unitId')
    const shiftId = this.shift.get('id') || this.shift.get('shiftId')
    const duration = this.shift.get('length')
    const length = duration

    return { unitId, shiftId, duration, length }
  }

  getShiftTime() {
    if (this.shift.get('id') === 'secondary-staff-shift') {
      return ''
    }
    const shiftLength = this.shift.get('length')
    const startTime = this.shift.get('startTime')

    const startTimeMoment = this.timeService.timeMoment(startTime, 'HH:mm')
    const endTimeMoment = startTimeMoment.clone().add(shiftLength, 'minutes')
    const start = startTimeMoment.format('HH:mm')
    const end = endTimeMoment.format('HH:mm')

    return `${start} - ${end}`
  }
}

window.ShiftService = ShiftService
