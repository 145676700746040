import { Component } from 'react'
import classNames from 'classnames'
import { pick } from 'lodash'
import Staff from './Staff'
import { List } from 'immutable'
import { OpenShiftStaff } from 'entityWrappers'

export default class StaffGroup extends Component {
  constructor(props) {
    super(props)
    const { isPosted } = props
    this.state = { isCollapsed: !isPosted, isCollapsedOnes: false }
  }

  componentWillUpdate(nextProps) {
    const { openShiftController, staff = List(), timeService } = nextProps
    const { openShift } = openShiftController
    const { unitId, startsAt } = openShift
    const { isCollapsed, isCollapsedOnes } = this.state

    if (isCollapsedOnes) {
      return
    }

    const firstUser = staff.first()
    if (!firstUser) {
      return
    }

    const user = new OpenShiftStaff(openShift, firstUser)
    const homeUnitId = user.getHomeUnitIdForDate(startsAt, timeService)
    const isHomeUnit = homeUnitId === unitId

    if (isHomeUnit && isCollapsed) {
      this.setState({ isCollapsed: false })
    }
  }

  render() {
    const { staff = List(), type, openShiftController, timeService } = this.props
    const { isCollapsed } = this.state
    const { isStaffSelected, openShift } = openShiftController
    const isEligibleStaff = type === 'eligibleStaff'
    const isNotInvitedStaff = type === 'notInvitedStaff'
    const isAllStaffGroupSelected = staff.every((staff) => isStaffSelected(staff))

    if (staff.size === 0) {
      return null
    }

    const firstUser = staff.first()
    const openShiftStaff = new OpenShiftStaff(openShift, firstUser)

    const { startsAt: shiftStartsAt } = openShift
    const homeUnitId = openShiftStaff.getHomeUnitIdForDate(shiftStartsAt, timeService)
    const title = openShiftStaff.getHomeUnitNameForDate(shiftStartsAt, timeService)

    return (
      <section className="hx-open-shift-staff-group">
        {(isEligibleStaff || isNotInvitedStaff) && (
          <div className="row bg-catskillwhite hx-group-title" onClick={this.toggleStaff}>
            <div className="col col-6">
              <div className="large">
                <div className="hx-checkbox">
                  <input
                    id={homeUnitId}
                    type="checkbox"
                    checked={isAllStaffGroupSelected}
                    onChange={() => this.toggleStaffGroupSelector()}
                  />
                  <label className="regent-gray" htmlFor={homeUnitId} />
                </div>
              </div>
              <h5 className="upper black trout">{title}</h5>
            </div>
            <div className="col col-6 text-right">
              <i
                className={classNames('rock-blue', {
                  'icon-up': !isCollapsed,
                  'icon-down': isCollapsed
                })}
              />
            </div>
          </div>
        )}

        {!isCollapsed && staff && <div className="hx-staff-list">{staff.map(this.renderStaff)}</div>}
      </section>
    )
  }

  toggleStaffGroupSelector() {
    const { staff, openShiftController } = this.props
    let { isStaffSelected, selectedUserIds } = openShiftController
    const isAllStaffGroupSelected = staff.every((staff) => isStaffSelected(staff))
    const groupStaffIds = staff.map((s) => s.get('id'))

    selectedUserIds = isAllStaffGroupSelected
      ? selectedUserIds.filter((id) => !groupStaffIds.includes(id))
      : selectedUserIds.concat(groupStaffIds)

    openShiftController.updateSelectedUserIds(selectedUserIds)
  }

  renderStaff = (staff) => {
    const staffProps = pick(
      {
        ...this.props,
        staff
      },
      [
        'eligibleUnits',
        'timeService',
        'staff',
        'isPosted',
        'openShiftController',
        'showApproveButton',
        'type',
        'roles',
        'expertises'
      ]
    )

    return <Staff key={staff.get('userId')} {...staffProps} />
  }

  toggleStaff = () => {
    const isCollapsed = !this.state.isCollapsed
    this.setState({ isCollapsed, isCollapsedOnes: true })
  }
}
