import { PureComponent } from 'react'
import StickyList from './StickyList'
import { List } from 'immutable'
import VStaff from './VStaff/VStaff'
import VRole from './VRole/VRole'

const STAFF_HEIGHT = 49
const GROUP_HEIGHT = 31

export default class VGrid extends PureComponent {
  static defaultProps = { overscanRowCount: 0 }

  constructor(props, context) {
    super(props, context)

    const raw = this.props.calendar.get('raw')
    const listSize = raw ? raw.size : 0
    const { overscanRowCount } = props

    this.state = {
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false.valueOf,
      listHeight: 600,
      listRowHeight: 50,
      overscanRowCount,
      rowCount: listSize,
      scrollToIndex: undefined
    }
  }

  componentDidMount() {
    const { resetLoadConfig, loadCalendar, activeDateRange } = this.props
    const isDateRangeReady = activeDateRange.get('isReady') === true

    if (isDateRangeReady) {
      loadCalendar()
    }
    resetLoadConfig()

    const staffId = this.props.staffId
    this.props.updateFilter((filters) => filters.set('filterBySearch', staffId))
  }

  componentDidUpdate() {
    const { activeDateRange, loadCalendar, calendar, resetLoadConfig } = this.props
    const calendarDateRange = calendar.get('dateRange')
    const isDateRangeReady = activeDateRange.get('isReady') === true
    const isDateRangeChanged = calendarDateRange !== activeDateRange

    if (isDateRangeChanged && isDateRangeReady) {
      loadCalendar()
      resetLoadConfig()
    }
  }

  render() {
    const raw = this.props.getViewModel() || List()
    const listSize = raw ? raw.size : 0
    const { overscanRowCount, scrollToIndex } = this.state
    const props = {
      raw,
      defaultHeight: 500,
      containerStyle: { maxHeight: 'auto' },
      height: this.props.offsetHeight,
      overscanRowCount: overscanRowCount,
      filters: this.props.filters,
      headerHeight: GROUP_HEIGHT,
      onScroll: this.handleScroll,
      // noRowsRenderer: this.noRowsRenderer,
      rowCount: listSize,
      rowHeight: this.getRowHeightByIndex(raw),
      rowRenderer: this.rowRenderer(raw),
      scrollToIndex: scrollToIndex,
      getRowHeight: this.getRowHeight,
      renderShift: this.renderShift,
      renderRole: this.renderRole,
      getRole: this.props.getRole,
      getShift: this.props.getShift
    }

    return <StickyList {...props} />
  }

  handleScroll = ({ scrollTop }) => this.props.updateScrollTop(scrollTop)

  rowRenderer =
    (raw) =>
    ({ index, isScrolling, key, style }) => {
      const datum = this.getDatum(raw, index)
      const kind = datum.get('kind')

      const isStaff = kind === 'staff'

      if (!isScrolling && isStaff) {
        //do not load cells while scrolling.
        const shiftId = datum.get('shiftId')
        this.props.loadShift(shiftId)
      }

      const roleId = datum.get('id')
      const isOtherStaff = roleId === 'secondary-staff'
      if (isOtherStaff) {
        const { calendar, loadOtherStaff, filters } = this.props
        const isOtherStaffLoaded = calendar.get('isOtherStaffLoaded')
        const isCollapsed = filters.getIn(['collapsed', roleId]) === true

        if (!isOtherStaffLoaded && !isCollapsed) {
          loadOtherStaff()
        }
      }

      if (isStaff) {
        return this.renderStaff(datum, style)
      }

      return null
    }

  renderStaff(staff, style) {
    const {
      filters,
      onMouseMoveOverGrid,
      cellsPathsInProcessing,
      updateCellTooltip,
      unitUrlId,
      showPopup,
      selection,
      displayNameAs,
      facilityShiftsMap,
      notes,
      timeService,
      monthExtendedActiveDateRange
    } = this.props

    const roleIndex = staff.get('roleIndex')
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')

    const staffPath = { roleIndex, shiftIndex, staffIndex }

    const onCellEvent = (cellIndex, eventType, meta) => this.props.onCellEvent(staffPath, cellIndex, eventType, meta)

    const onMouseMoveOverGridCell = (event) => {
      const cellIndex = parseInt(event.target.getAttribute('data-cell-index'))
      return onMouseMoveOverGrid(event, roleIndex, shiftIndex, staffIndex, staff, cellIndex)
    }

    const selectedStaffPath = selection.get('staffPath')
    const {
      staffIndex: selectedStaffIndex,
      shiftIndex: selectedShiftIndex,
      roleIndex: selectedRoleIndex
    } = selectedStaffPath

    const isStaffSelected = selectedStaffIndex === staffIndex
    const isShiftSelected = selectedShiftIndex === shiftIndex
    const isRoleSelected = selectedRoleIndex === roleIndex

    const isRowSelected = isRoleSelected && isShiftSelected && isStaffSelected
    const actualSelection = isRowSelected && selection

    const staffId = staff.get('id')

    const indicators = filters.get('indicators')

    return (
      <VStaff
        key={staffId}
        staff={staff}
        style={style}
        onMouseMoveOverGridCell={onMouseMoveOverGridCell}
        cellsPathsInProcessing={cellsPathsInProcessing}
        updateCellTooltip={updateCellTooltip}
        unitUrlId={unitUrlId}
        displayNameAs={displayNameAs}
        showPopup={showPopup}
        indicators={indicators}
        selection={actualSelection}
        activeDateRange={monthExtendedActiveDateRange}
        onCellEvent={onCellEvent}
        facilityShiftsMap={facilityShiftsMap}
        notes={notes}
        timeService={timeService}
      />
    )
  }

  renderStaffDivider(datum, style) {
    return <hr className="staff-divider" key={datum.get('key')} style={style} />
  }

  renderShift = (shift, style) => {
    return null
  }

  renderRole = (role, style) => {
    const { monthExtendedActiveDateRange } = this.props
    const roleId = role.get('id')

    return <VRole key={roleId} style={style} activeDateRange={monthExtendedActiveDateRange} />
  }

  getRowHeightByIndex =
    (raw) =>
    ({ index }) => {
      const row = raw.get(index)
      return this.getRowHeight(row)
    }

  getRowHeight = (row) => {
    if (!row) {
      return 0
    }
    if (row.get('hidden')) {
      return 0
    }

    switch (row.get('kind')) {
      case 'staff':
        return STAFF_HEIGHT
      case 'role':
        if (row.get('roleIndex') === 0) {
          return GROUP_HEIGHT
        }
        return 0
      default:
        return 0
    }
  }

  getDatum = (raw, index) => {
    return raw.get(index % raw.size)
  }
}
