import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import { PureComponent } from 'react'

export default class CustomRecurShifts extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      shownShiftsSubmenu: false
    }
  }

  render() {
    const { shiftTypesValue, eventVariants, primaryShift, secondaryShifts, onCallShifts, onChange, form } = this.props
    const { shownShiftsSubmenu } = this.state

    const ShiftsSubmenuList = ({ onClickHandler, primaryShift, secondaryShifts, onCallShifts, eventVariants }) => {
      const onClickChange = (shiftId) => {
        this.setState({ shownShiftsSubmenu: false })
        return onClickHandler(shiftId)
      }
      const style = { left: '0px', top: '50px' }

      const hasAssignmentVariant = !!eventVariants.find((variant) => variant.get('id') === 'assignment')
      const hasMeetingVariant = !!eventVariants.find((variant) => variant.get('id') === 'meeting')
      const hasOnCallVariant = !!eventVariants.find((variant) => variant.get('id') === 'onCall')

      const hasPrimaryShift = hasAssignmentVariant && !!primaryShift
      const hasSecondaryShifts = hasAssignmentVariant && secondaryShifts && secondaryShifts.size > 0
      const hasOnCallShifts = hasOnCallVariant && onCallShifts && onCallShifts.size > 0
      const hasMeetingEvent = hasMeetingVariant && !!primaryShift

      /* TODO: find a way to get the proper value from the backend instead of
            changing it in the frontend */
      const renderShift = (shift, shiftIndex) => {
        const shiftId = shift.get('id')
        const title = shift.get('name')
        return (
          <div
            key={shiftIndex}
            className="context__item shift-item"
            role="presentation"
            onClick={(e) => onClickChange(shiftId)}
            onBlur={() => {
              this.setState({ shownShiftsSubmenu: false })
            }}
          >
            <div className="context__item__data">{title}</div>
          </div>
        )
      }

      return (
        <div data-testid="recur-submenu" className="submenu-recur" style={style}>
          {hasPrimaryShift && (
            <>
              <div className="context__item">
                <div className="context__item__data">
                  <span>
                    <i className="icon-dot rock-blue" />
                  </span>
                  <span>Primary Shift</span>
                </div>
              </div>
              {renderShift(primaryShift, 0)}
            </>
          )}

          {hasPrimaryShift && hasSecondaryShifts ? <hr /> : <span />}

          {(hasSecondaryShifts || hasMeetingEvent) && (
            <>
              <div className="context__item">
                <div className="context__item__data">
                  <span>
                    <i className="icon-circle rock-blue" />
                  </span>
                  <span>Secondary Shifts</span>
                </div>
              </div>
              {hasSecondaryShifts && secondaryShifts.map(renderShift)}
            </>
          )}

          {hasSecondaryShifts && hasOnCallShifts ? <hr /> : <span />}

          {hasOnCallShifts && (
            <>
              <div className="context__item">
                <div className="context__item__data">
                  <span>
                    <i className="icon-circle rock-blue" />
                  </span>
                  <span>On Call Shifts</span>
                </div>
              </div>
              {hasOnCallShifts && onCallShifts.map(renderShift)}
            </>
          )}
        </div>
      )
    }

    const shitTypesProps = {
      ...form.shiftTypes,
      value: shiftTypesValue
    }
    const shiftsSubmenuProps = {
      primaryShift,
      secondaryShifts,
      onCallShifts,
      eventVariants
    }
    shiftsSubmenuProps.onClickHandler = (shiftId) => {
      onChange(shiftId)
    }
    return (
      <div className="form-item row col-6">
        <div className="col-12">
          <div
            className="pr30 pb0 row"
            onMouseLeave={() => this.setState({ shownShiftsSubmenu: false })}
            onFocus={() => {
              this.setState({ shownShiftsSubmenu: true })
            }}
          >
            <StringInputField
              {...shitTypesProps}
              onChange={(e) => onChange(e, 'shiftTypes')}
              onFocus={() => {
                this.setState({ shownShiftsSubmenu: true })
              }}
            />
            {shownShiftsSubmenu && <ShiftsSubmenuList {...shiftsSubmenuProps} />}
          </div>
        </div>
      </div>
    )
  }
}
