import { Component } from 'react'
import { withAppContext } from 'AppContext'
import { navigate, paths } from 'Navigation'
import { Link, Redirect } from 'react-router-dom'

export class NoUnits extends Component {
  render() {
    const { appState } = this.props
    const email = appState.getIn(['authentication', 'profile', 'email'])
    const handleLogOut = (e) => {
      e.preventDefault()
      return navigate.to.Logout()
    }

    const unitManagerProfile = appState.getIn(['authentication', 'facilityUser', 'unitManagerProfile'])
    const homeUnitId = unitManagerProfile.get('homeUnitId')
    const otherUnitIds = unitManagerProfile.get('otherUnitIds')
    const eligibleUnitIds = otherUnitIds.push(homeUnitId)

    const hasEligibleUnits = eligibleUnitIds && eligibleUnitIds.size > 0
    if (hasEligibleUnits) {
      return <Redirect to={paths.Root} />
    }

    return (
      <main className="row">
        <div className="col col-4 push-center relative" style={{ marginTop: '2em' }}>
          <div className="bg-white">
            <div className="p2">
              <span>
                No unit is linked to {email}. &nbsp;Please try <Link to={paths.Logout}>logging in</Link>
                &nbsp;again with the correct email.
              </span>
            </div>
            <footer className="p2 pt0">
              <button onClick={handleLogOut} className="button secondary">
                Logout
              </button>
            </footer>
          </div>
        </div>
      </main>
    )
  }
}

export default withAppContext(NoUnits)
