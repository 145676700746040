import { Component } from 'react'
import { isEmpty } from 'lodash'
import Metadata from './Metadata'
import { navigate } from 'Navigation'
import { PreviewAnnouncements } from '../CreateAnnouncements'

export default class LogContent extends Component {
  constructor(props) {
    super(props)
    this.state = { isViewDetailsVisible: true }
  }

  onClick = () => {
    const { detailsComponents, status, id: announcementId } = this.props
    const hasDetailsPage = !isEmpty(detailsComponents)
    if (hasDetailsPage && status !== 'draft') {
      navigate.from.Announcements.to.AnnouncementsDetails({ announcementId })
    }
  }

  componentDidMount() {
    if (this.props.status === 'draft') {
      this.setState({ isViewDetailsVisible: false })
    }
  }

  render() {
    const {
      id: announcementId,
      createdAt,
      updatedAt,
      subject,
      subtitle,
      content,
      badgeUrl,
      location,
      status,
      startsAt,
      endsAt,
      detailsComponents,
      author,
      worksAt,
      shiftTypeIds: shiftTypes,
      loadAnnouncements,
      refreshNotifications
    } = this.props

    const hasDetailsPage = !isEmpty(detailsComponents)
    const previewAnnouncementsProps = {
      subject,
      content,
      subtitle,
      location,
      badgeUrl,
      hasDetailsPage,
      showSubtitle: !!subtitle,
      viewDetails: this.state.isViewDetailsVisible,
      onViewDetails: this.onClick
    }

    const metadataProps = {
      announcementId,
      status,
      author,
      hasDetailsPage,
      startsAt,
      endsAt,
      updatedAt,
      createdAt,
      worksAt,
      shiftTypes,
      loadAnnouncements,
      refreshNotifications
    }

    return (
      <>
        <div className="announcement-item row align-center">
          <PreviewAnnouncements {...previewAnnouncementsProps} />
          <Metadata {...metadataProps} />
        </div>
        <hr className={hasDetailsPage ? null : 'without-details'} />
      </>
    )
  }
}
