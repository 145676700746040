import momentWithTimeZone from 'moment-timezone'
import { t } from 'i18n'
import StaffName from '../../common/StaffName/StaffName'

function ShiftMismatchModal({
  shiftSkillMismatchCount,
  shiftSkillMismatchMetadata,
  toggleModalVisibility,
  handleCalenderNavigation,
  timeService
}) {
  const getTime = (date) => momentWithTimeZone.tz(date, timeService?.timeZone?.name).format('HH:mm')
  const getDate = (date) => momentWithTimeZone.tz(date, timeService?.timeZone?.name).format('MMMM DD, YYYY')

  return (
    <sh-modal
      class="z-201"
      visible
      sticky
      label={`${t('shiftSkillMismatch.header.title')} (${shiftSkillMismatchCount})`}
      modal-height="calc(100% - 10rem)"
      modal-width="70rem"
    >
      <sh-table class="no-margin-bottom" readonly>
        <sh-table-row slot="header">
          <sh-table-head columns="5" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.staffName')}
            </sh-text>
          </sh-table-head>
          <sh-table-head columns="3" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.shiftDate')}
            </sh-text>
          </sh-table-head>
          <sh-table-head columns="3" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.shiftName')}
            </sh-text>
          </sh-table-head>
          <sh-table-head columns="3" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.shiftHours')}
            </sh-text>
          </sh-table-head>
          <sh-table-head columns="3" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.role')}
            </sh-text>
          </sh-table-head>
          <sh-table-head columns="3" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.skillName')}
            </sh-text>
          </sh-table-head>
          <sh-table-head columns="3" unsortable>
            <sh-text size="title-1" color="secondary">
              {t('shiftSkillMismatch.table-header.skillType')}
            </sh-text>
          </sh-table-head>
        </sh-table-row>
        {shiftSkillMismatchMetadata.map((metaData, index) => (
          <sh-table-row key={index}>
            <sh-table-cell columns="5">
              <StaffName metaData={metaData} />
            </sh-table-cell>
            <sh-table-cell columns="3">{getDate(metaData['startsAt'])}</sh-table-cell>
            <sh-table-cell columns="3">{metaData['shiftName']}</sh-table-cell>
            <sh-table-cell columns="3">
              {getTime(metaData['startsAt']) + ' - ' + getTime(metaData['endsAt'])}
            </sh-table-cell>
            <sh-table-cell columns="3">{metaData['roleName']}</sh-table-cell>
            <sh-table-cell columns="3">{metaData['skillName']}</sh-table-cell>
            <sh-table-cell columns="3">{metaData['skillType']}</sh-table-cell>
          </sh-table-row>
        ))}
      </sh-table>
      <sh-button slot="footer" label="Close" color="secondary" onClick={toggleModalVisibility}></sh-button>
      <sh-button slot="footer" label="Go to Calender" size="m" onClick={handleCalenderNavigation}></sh-button>
    </sh-modal>
  )
}

export default ShiftMismatchModal
