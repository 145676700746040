import { Map } from 'immutable'
import { groupBy } from 'lodash'

function _getFullNameSearcheable(firstName = '', lastName = '') {
  return `${firstName.toLowerCase()} ${lastName.toLowerCase()} ${firstName.toLowerCase()}`
}

function _withAvatar(profile, instanceUri) {
  const avatarUrl = profile.get('avatarUrl')

  if (!avatarUrl || avatarUrl.startsWith('http')) {
    return profile
  }

  return profile.set('avatarUrl', `${instanceUri}/${avatarUrl}`)
}

function buildStaffWithCells(facilityUsers, cells, roleIndex, shiftIndex, shiftId, instanceUri, staffEvents) {
  return facilityUsers.map((facilityUser, staffIndex) => {
    const profile = facilityUser.get('profile')
    const staffProfile = facilityUser.get('staffProfile')
    const facilityProfile = facilityUser.get('facilityProfile')
    const facilityProfileWithAvatarUrl = facilityProfile.set('avatarUrl', profile.get('avatarUrl'))

    const userId = facilityUser.get('userId')
    const firstName = facilityProfile.get('firstName')
    const lastName = facilityProfile.get('lastName')
    const fullName = `${firstName} ${lastName}`
    const fullNameRevers = `${lastName}, ${firstName}`
    const fullNameAsSearchable = _getFullNameSearcheable(firstName, lastName)
    const preferences = staffProfile.get('preferences')
    const staffEventsByUserId = groupBy(staffEvents, 'userId')

    let staff = Map({
      roleIndex,
      shiftIndex,
      shiftId,
      staffIndex,
      fullName,
      fullNameRevers,
      firstName,
      lastName,
      fullNameAsSearchable,
      id: userId,
      kind: 'staff',
      staffProfile,
      homeUnitId: facilityUser.get('homeUnitId'),
      type: staffProfile.get('employmentTypeLabel'),
      profile: _withAvatar(facilityProfileWithAvatarUrl),
      isShiftLoaded: false,
      preferences,
      hrs: 0,
      staffEvent: staffEventsByUserId[userId]
    })

    if (cells) {
      staff = staff.set('cells', cells)
    }

    return staff
  })
}

const builders = { buildStaffWithCells }
export default builders
