import { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Menu from './Menu'
import { withAppContext } from 'AppContext'
import './Header.scss'
import { ProfileDropdownList } from 'Navigation/ProfileDropdown'
import Avatar from 'Manager/components/Avatar'
import LastLoginBanner from '../Navigation/ProfileDropdown/LastLoginBanner'
import navigate from '../Navigation/navigate'

class Header extends PureComponent {
  render() {
    const { authStore, appState, location } = this.props
    const auth = appState.get('authentication')
    const sitemap = appState.get('sitemap')
    const isLoggedIn = auth.get('isLoggedIn')
    const switchFacilityRedirectTo = navigate.replace({ unitUrlId: ':urlId' }, false, location)

    const dropdownListProps = {
      authStore,
      appState,
      switchFacilityRedirectTo
    }

    const profile = auth.get('profile')

    return (
      <header id="header" data-component="Header">
        <header className="hx-header bg-riverbad">
          <aside className="float-left">
            <Link className="bg-charcoal" to="/">
              <div className="image-32 hx-logo-orange" />
            </Link>
          </aside>

          <Menu sitemap={sitemap} />
          <LastLoginBanner appState={appState} />

          <aside className={classNames('_side-menu', { hide: !isLoggedIn })}>
            <button className="bg-charcoal hide">
              <i className="icon-bell" />
            </button>
            <div className="dropdown-menu">
              <button className="text-right">
                <Avatar profile={profile} />
                <i className="icon-down slate-gray" />
              </button>
              <ProfileDropdownList {...dropdownListProps} />
              <div />
            </div>
          </aside>
        </header>
      </header>
    )
  }
}

export default withAppContext(Header)
