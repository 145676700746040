import { PropsWithChildren } from 'react'
import GraphCard, { Dataset } from './GraphCard'
import { ICommonCardProps } from './CommonCard'
import { merge } from 'lodash'
import getHorizontalBarCommonOptions from './getBarGraphOptions'

const HorizontalBarCommonOptions = getHorizontalBarCommonOptions()

export interface IHorizontalBarGraphWithSingleDatasetCardProps extends ICommonCardProps {
  labels: string[]
  dataset: Dataset
  barsOnFocus: number
  extraOptions: any
  isValid?: boolean
}

export default function HorizontalBarGraphWithSingleDatasetCard({
  //graphing props start here
  labels,
  dataset,
  barsOnFocus,
  extraOptions,
  //common card props start here
  title,
  isValid = true,
  onClickActionButton,
  loadingSpinnerText,
  noContentLabel,
  actionButtonIcon,
  isLoading,
  titleIcon,
  isActionButtonDisabled
}: PropsWithChildren<IHorizontalBarGraphWithSingleDatasetCardProps>) {
  const isBarDataValid = dataset.data.length > 0

  const widthPerBar = 53
  const graphHeight = labels.length * widthPerBar + 20
  const cardHeight = barsOnFocus * widthPerBar + 67

  const mergedOptions = merge(HorizontalBarCommonOptions, extraOptions)

  return (
    <GraphCard
      type={'bar' as const}
      graphHeight={{ bar: graphHeight }}
      datasets={{ bar: [dataset] }}
      options={mergedOptions}
      labels={{ bar: labels.map((l) => [l]) }}
      //common card props
      title={title}
      height={cardHeight}
      isValid={isValid && isBarDataValid}
      onClickActionButton={onClickActionButton}
      loadingSpinnerText={loadingSpinnerText}
      noContentLabel={noContentLabel}
      actionButtonIcon={actionButtonIcon}
      isLoading={isLoading}
      titleIcon={titleIcon}
      isActionButtonDisabled={isActionButtonDisabled}
    />
  )
}
