import { ComponentController } from 'Common/components'
import { pick } from 'lodash'

class ExpertisesController extends ComponentController {
  get isExpertisesLoaded() {
    const { appState, store } = this.props
    const { stateKey } = store
    const { stateKey: stateGroupKey } = this.expertiseGroupStore
    const expertises = appState.getIn([stateKey, 'list'])
    const expertiseGroups = appState.getIn([stateGroupKey, 'list'])

    return !!expertises && !!expertiseGroups
  }

  get expertiseGroupStore() {
    return this.props.stores.get('expertiseGroup')
  }

  get expertiseGroups() {
    return this.props.appState.getIn(['admin.ExpertiseGroup', 'list'])
  }

  getExpertiseGroup(groupId) {
    return this.expertiseGroups.map((group) => pick(group, ['id', 'title'])).find((group) => group.id === groupId)
  }

  getExpertises() {
    const { appState, store } = this.props
    const isApiInitialized = store.isApiInitialized(appState)
    if (!isApiInitialized) {
      return {}
    }
    const { search, stateKey } = store.getAdminInfo()
    const { state: { query } = {} } = this.component
    const expertises = search ? store.filter(appState, search.field, query) : appState.getIn([stateKey, 'list'])

    const expertiseGroupIds = new Set()
    const groupedExpertises = expertises.reduce((memo, expertise) => {
      const { groupId } = expertise
      const parentGroup = this.getExpertiseGroup(groupId)
      if (!parentGroup) {
        return memo
      }
      expertiseGroupIds.add(groupId)
      const { title: groupName } = parentGroup
      const expertisesExtended = { ...expertise, groupName }
      return memo.concat(expertisesExtended)
    }, [])
    const emptyGroups =
      search && query
        ? []
        : this.expertiseGroups
            .filter((group) => !expertiseGroupIds.has(group.id))
            .map((group) => ({ groupId: group.id, groupName: group.title }))
    return {
      groupedExpertises,
      emptyGroups
    }
  }

  loadData(type) {
    const { store } = this.props
    store.loadDataAction({ type })
    this.expertiseGroupStore.loadDataAction({ type })
  }

  createExpertise(form, type) {
    const { store } = this.props
    const { groupId, name } = form
    return store.createExpertise(groupId, name, type)
  }

  deleteExpertise(id) {
    const { store } = this.props
    return store.deleteExpertise(id)
  }

  deleteExpertiseGroup(id) {
    return this.expertiseGroupStore.deleteExpertiseGroup(id)
  }

  createExpertiseGroup(form, type) {
    const { title } = form
    return this.expertiseGroupStore.createExpertiseGroup(title, type)
  }

  getExpertise(id) {
    const { appState, store } = this.props
    const { stateKey } = store
    const expertises = appState.getIn([stateKey, 'list'])
    return expertises.find((expertise) => expertise.id === id)
  }
}

export default ExpertisesController
