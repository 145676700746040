import { PureComponent } from 'react'
import classNames from 'classnames'
import './Note.scss'
import { ImmutableSelect } from 'Manager/components'

export default class Note extends PureComponent {
  render() {
    const { note } = this.props
    if (!note) {
      return null
    }

    const key = 0
    const { notesController } = this.props
    const { text, subject, isEmpty, lastUpdate, isEditMode, isCollapsed, isCollapsible, availableSubjects } =
      notesController.getNote(key)

    const title = isEmpty ? 'ADD EXPLANATION / NOTE' : 'EXPLANATION / NOTE'

    const subjectSelectProps = {
      propKey: 'subject',
      propText: 'subject',
      propValue: 'subject',
      value: subject || '',
      disabled: !isEditMode,
      options: availableSubjects,
      onChange: (e) => notesController.setNoteSubject(key, e.currentTarget.value)
    }

    return (
      <div
        data-testid="time-off-pending-request-explanation-note"
        className="row bg-porcelain m0 p10 pl20 pr20 pb0 bb1 bt1 hx-explanation-note"
      >
        <div className="col col-10">
          <h5 className="river-bad">{title}</h5>
        </div>
        <div className="col col-2">
          {isCollapsible && (
            <button
              onClick={(e) => notesController.toggleNote(key)}
              data-testid="time-off-note-collapsible"
              className="button float-right _collapse-button"
            >
              <i
                className={classNames('icon bold', {
                  'icon-plus': isCollapsed,
                  'icon-minus': !isCollapsed
                })}
              />
            </button>
          )}
          {!isEditMode && (
            <button
              onClick={(e) => notesController.enableEditModeForNote(key)}
              className="button float-right _edit-button"
            >
              <i className="icon bold icon-edit dimmed" />
            </button>
          )}
        </div>

        {!isCollapsed && (
          <div className="w100">
            <div className="col col-12 form-item">
              <ImmutableSelect {...subjectSelectProps}>
                <option value="" disabled>
                  Select an option...
                </option>
              </ImmutableSelect>
              {!isEditMode && <div className="updated-at">{lastUpdate}</div>}
            </div>
            <div className="col col-12 form-item">
              <textarea
                onChange={(e) => notesController.setNoteText(key, e.currentTarget.value)}
                placeholder="Note..."
                value={text}
                disabled={!isEditMode}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
