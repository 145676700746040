import { ComponentController } from 'Common/components'

export default class ComponentViewController extends ComponentController {
  get mode() {
    const { match } = this.props
    const modeInMatchParams = !!match?.params?.mode

    return modeInMatchParams ? match.params.mode : this.props.mode
  }

  get isDayView() {
    return this.mode === 'day'
  }

  get isWeekView() {
    return this.mode === 'week'
  }

  get isFullView() {
    return this.mode === 'full'
  }
}
