import StateController from './StateController'

export default class CellTooltipController extends StateController {
  get defaultState() {
    return null
  }

  scrollHandler = () => {
    this.setState({ openShiftPopupProps: null })
    this.hideCellTooltip()
  }

  get cellTooltipConfig() {
    return this.state.cellTooltipConfig
  }

  isCellWithSelectionError = (cellPath) => {
    const { selectionErrorPath } = this.selectedObjects
    if (!selectionErrorPath) {
      return false
    }
    return this.calendar.isSameCellPath(selectionErrorPath, cellPath)
  }

  updateCellTooltip = (cellTooltipConfig) => {
    const { staffPath, cellIndex } = cellTooltipConfig
    const cellPath = { ...staffPath, cellIndex }
    const cell = this.calendar.getStaffCell(cellPath)
    const shiftDay = this.calendar.getShiftDay({ ...cellPath, shiftDayIndex: cellIndex })

    cellTooltipConfig = {
      ...cellTooltipConfig,
      cell,
      shiftDay
    }

    global.clearTimeout(this.cellTooltipTimer)
    this.cellTooltipTimer = setTimeout(() => {
      this.setState({ cellTooltipConfig })
    }, 50)

    global.document.addEventListener('scroll', this.scrollHandler, true)
  }

  hideCellTooltip = () => {
    global.clearTimeout(this.cellTooltipTimer)
    global.document.removeEventListener('scroll', this.scrollHandler)

    this.setState({ cellTooltipConfig: this.defaultState })
  }
}
