import { USER_EVENTS } from 'Common/constants/UserEvents'
import LoggingService from 'services/LoggingService'
import { v4 as uuidv4 } from 'uuid'

export class ActivityTracker {
  private static _Started: boolean = false
  private static _StartTime: number = 0
  private static _ActivityId: string = ''

  /**
   * Calculate the duration in seconds since tracking started.
   * @private
   * @returns {number} Duration in seconds
   */
  private static _GetDuration(): number {
    const durationInMillis = new Date().getTime() - ActivityTracker._StartTime
    return Math.floor(durationInMillis / 1000)
  }

  /**
   * Get the activity details including activity ID and duration.
   * @private
   * @returns {{activityId: string , duration: number}} Activity details
   */
  private static _GetActivityDetails(): { activityId: string; duration: number } {
    return {
      activityId: ActivityTracker._ActivityId,
      duration: ActivityTracker._GetDuration()
    }
  }

  /**
   * Reset the tracking state.
   * @private
   */
  private static _Reset(): void {
    ActivityTracker._Started = false
    ActivityTracker._StartTime = 0
    ActivityTracker._ActivityId = ''
  }

  /**
   * Log the user event and reset tracking state.
   * @private
   * @param {USER_EVENTS} event - The user event to log
   * @param {Record<string, any>} extraProps - Extra properties to log with the event
   */
  private static _LogEvent(event: USER_EVENTS, extraProps: Record<string, any> = {}): void {
    const details = ActivityTracker._GetActivityDetails()
    LoggingService.logUserAction(event, { ...details, ...extraProps })
  }

  /**
   * Start tracking the activity if it has not started.
   */
  static StartTracking(): void {
    if (ActivityTracker._Started) {
      return
    }

    ActivityTracker._Started = true
    ActivityTracker._StartTime = new Date().getTime()
    ActivityTracker._ActivityId = uuidv4()
  }

  /**
   * End tracking the activity if it was started.
   * Also reset the tracking state.
   */
  static EndTracking(): void {
    if (!ActivityTracker._Started) {
      return
    }

    ActivityTracker._LogEvent(USER_EVENTS.TIME_TAKEN_BY_USER_TO_BOOK_SLOT_VIA_SLOT_RECOMMENDER)
    ActivityTracker._Reset()
  }

  /**
   * Terminate tracking the activity if it was started.
   * Also reset the tracking state.
   * @param {'page_reload' | 'navigated_to_different_page'} hint - The hint for the termination,
   * @default 'navigated_to_different_page'
   */
  static TerminateTracking(hint: 'page_reload' | 'navigated_to_different_page' = 'navigated_to_different_page'): void {
    if (!ActivityTracker._Started) {
      return
    }

    ActivityTracker._LogEvent(USER_EVENTS.USER_EXITED_PATIENT_SLOT_RECOMMENDER_WITHOUT_BOOKING, { hint })
    ActivityTracker._Reset()
  }

  /**
   * Log a booking failure event with the given reason.
   * @param {string} reason - The reason for the booking failure
   */
  static TrackingFailed(reason: string): void {
    if (!ActivityTracker._Started) {
      return
    }

    ActivityTracker._LogEvent(USER_EVENTS.SLOT_RECOMMENDER_BOOKING_FAILED, { reason })
    ActivityTracker._Reset()
  }
}
