import { debounce, throttle, some } from 'lodash'
import { addErrorMessage } from '@humanics/he-react-common/lib/stores/messages'
import { triggerError } from '@humanics/he-react-common/lib/stores/fluxStore'
import { readAuthorizationCode, readMyUserProfile, createTwoFactorAuthenticationCode, refreshSession } from './Queries'
import GQLClient from '@humanics/he-react-common/lib/stores/humanics/graphQL'
import { Sentry } from 'services'
import { handleError } from 'services/ErrorHandler'
import { MFA_REQUIRED_ERROR } from '../../../utils/GraphQLErrors'

const trackError = Sentry.trackRequestError

const MILLIS_IN_MIN = 60 * 1000

export default class GlobalAccountsService {
  constructor(uri) {
    this.uri = uri
  }

  async initialize() {
    const gqlUri = `${this.uri}/api/graphql/v1/master`

    this.gqlClient = GQLClient.buildClient(gqlUri, null, null, handleError, trackError)
  }

  autoLogin = async (instance) => {
    const { readMyUserProfile: userProfile } = await this.gqlClient.query(readMyUserProfile)
    await this._getNewSession(instance)
    if (instance.mfaRequired) {
      return { instance, userProfile }
    } else {
      const { facilities } = instance
      return { instance, facilities, userProfile }
    }
  }

  extendSession = throttle(
    async (instance) => {
      await this.gqlClient.query(refreshSession)
      const {
        readAuthorizationCode: { instances, authorizationCode, errors }
      } = await this.gqlClient.query(readAuthorizationCode)
      const instanceData = instances.find((i) => i.uri === instance.uri)
      const hasErrors = errors?.length
      if (hasErrors && !instanceData) {
        const error = { ...errors, message: 'Authorization error' }
        addErrorMessage(error)
        return Promise.reject(error)
      }
      return instance.extendSession({ authorizationCode, instanceData })
    },
    1 * MILLIS_IN_MIN,
    { leading: true, trailing: false }
  )

  getSessionValidator = (instance) => {
    return async (location = document.location) => {
      // cookie is not set on localhost; skip
      const localhosts = [
        'local.instance.pontevecchio.hcvpc.io',
        'instance.humanics.local',
        'localhost.develop.humanics.com'
      ]
      const isLocalhost = localhosts.includes(location.hostname)
      if (isLocalhost) {
        return Promise.resolve('localhost')
      }

      const expiresAtMatch = document.cookie.match(/authorizationExpiresAt=(\d+)/)
      if (expiresAtMatch) {
        const expiresAt = +expiresAtMatch[1]
        // more than 5min before expiration
        if (expiresAt - +new Date() > 5 * MILLIS_IN_MIN) {
          return Promise.resolve('valid')
        }
      }

      try {
        await this.extendSession(instance)
      } catch (e) {
        if (e.status === 401) {
          const error = { ...(e.obj || e), status: 401, silent: true }
          triggerError(error)
          throw error
        }

        throw e
      }
    }
  }

  _getInstanceAuthorization(instance) {
    return ({ authorizationCode, instanceData, errors }) => {
      const hasErrors = errors?.length
      if (hasErrors && !instanceData) {
        const error = { ...errors, message: 'Authorization error' }
        addErrorMessage(error)
        return Promise.reject(error)
      }
      const catchMFAError = (error) => {
        if (!error) {
          return
        }
        const { code } = error
        if (code === MFA_REQUIRED_ERROR) {
          instance.mfaRequired = true
          return this.gqlClient.mutate(createTwoFactorAuthenticationCode)
        }
        throw error
      }
      return instance.authorize({ authorizationCode, instanceData }).catch(catchMFAError)
    }
  }

  _getInstancePartialAuthorization(instance) {
    return ({ instanceData, errors }) => {
      const hasErrors = errors?.length
      if (hasErrors && !instanceData) {
        const error = { ...errors, message: 'Authorization error' }
        addErrorMessage(error)
        return Promise.reject(error)
      }

      instance.mfaRequired = true
    }
  }

  _getNewSession = debounce(
    async (instance) => {
      const {
        readAuthorizationCode: { instances, authorizationCode, errors }
      } = await this.gqlClient.query(readAuthorizationCode)
      const instanceData = instances.find((i) => i.uri === instance.uri)
      const authorizationData = { instanceData, authorizationCode, errors }
      let authorizeInstance
      if (instanceData !== undefined && some(instanceData.facilities, { isTwoFactorAuthenticationRequired: true })) {
        await this.gqlClient.mutate(createTwoFactorAuthenticationCode)
        authorizeInstance = this._getInstancePartialAuthorization(instance)
      } else {
        authorizeInstance = this._getInstanceAuthorization(instance)
      }
      await authorizeInstance(authorizationData)
    },
    5e3,
    { leading: true, trailing: false }
  )
}
