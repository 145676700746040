import { createRef, Component } from 'react'
import './CrudCardAccordion.scss'
import { withSeparators } from './utils'

class CrudCardAccordion extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.accordionContent = createRef()
  }

  render() {
    const { title, children, separatorColor } = this.props
    const { isOpen } = this.state

    const arrowDirection = isOpen ? 'up' : 'down'
    const status = isOpen ? 'active' : 'inactive'
    const paddingBottom = isOpen ? '10px' : '20px'

    const openAccordion = () => {
      this.setState((state) => ({ isOpen: !state.isOpen }))
    }

    const contentHeight = this.state.isOpen ? this.accordionContent.current.scrollHeight : 0

    return (
      <div className="card-accordion">
        <div
          className={`card-accordion-title ${status} row align-middle`}
          style={{ paddingBottom }}
          onClick={openAccordion}
        >
          <sh-text size="title-1">{title}</sh-text>
          <div>
            <i className={`icon icon-${arrowDirection}`} />
          </div>
        </div>
        <div className="card-accordion-content" ref={this.accordionContent} style={{ height: `${contentHeight}px` }}>
          {separatorColor ? withSeparators(children, separatorColor) : children}
        </div>
      </div>
    )
  }
}

export default CrudCardAccordion
