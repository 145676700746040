import { PureComponent } from 'react'
import './OpenShift.scss'

export default class OpenShift extends PureComponent {
  render() {
    const { shiftDay } = this.props
    const openShift = shiftDay.get('openShift')
    const shiftOpportunity = shiftDay.get('shiftOpportunity')

    if (!openShift && !shiftOpportunity) {
      return null
    }

    return (
      <div className="hx-calendar-staff-shift _openShift rounded bg-white">
        {shiftOpportunity && this._renderShiftOpportunity(shiftOpportunity)}
        {!shiftOpportunity && openShift && this._renderOpenShift(openShift)}
      </div>
    )
  }

  _renderOpenShift(openShift) {
    const { shiftDay } = this.props
    const shiftTime = this._getShiftTime(shiftDay)

    return (
      <>
        <i className="humanics-icons-Add-Circle" />
        <span className="event-time">{shiftTime}</span>
        <span className="description">Need to fill</span>
      </>
    )
  }

  _renderShiftOpportunity(shiftOpportunity) {
    const { shiftDay } = this.props
    const shiftTime = this._getShiftTime(shiftDay)
    const description = this._getShiftOpportunityStatus(shiftOpportunity)

    return (
      <>
        <i className="icon-pin" />
        <span className="event-time">{shiftTime}</span>
        <span className="description">{description}</span>
      </>
    )
  }

  _getShiftTime(shiftDay) {
    const { timeService } = this.props
    const startsAt = shiftDay.get('startsAt')
    const endsAt = shiftDay.get('endsAt')

    return timeService.formatTimeRange(startsAt, endsAt)
  }

  _getShiftOpportunityStatus(shiftOpportunity) {
    const acceptanceCriteria = shiftOpportunity.get('acceptanceCriteria') || 'manualApprove'
    const acceptedByUserIds = shiftOpportunity.get('acceptedByUserIds')
    const approvedUserIds = shiftOpportunity.get('approvedUserIds')
    const deniedByUserIds = shiftOpportunity.get('deniedByUserIds')

    const pendingToConfirmUsers = acceptedByUserIds.filter((userId) => {
      return !approvedUserIds.includes(userId) && !deniedByUserIds.includes(userId)
    })

    const isManagerApproval = acceptanceCriteria === 'manualApprove'

    if (pendingToConfirmUsers.size > 0 && isManagerApproval) {
      return 'Needs Approval'
    }

    return 'Request Posted'
  }
}
