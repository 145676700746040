const authenticationErrorCodesToIgnore = ['COM-3', 'COM-4']
const errorTypesToIgnore = {
  OperationError: () => false,
  ApplicationError: () => false,
  AccessDeniedError: () => false,
  InvalidOutputError: () => false,
  InvalidRequestError: () => false,
  LockedError: () => true,
  ConflictError: () => true,
  InvalidParametersError: () => true,
  ServiceUnavailableError: () => true,
  AuthenticationError: (code) => authenticationErrorCodesToIgnore.includes(code)
}

export default errorTypesToIgnore
