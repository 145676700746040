import { loader } from 'graphql.macro'

export const notesQuery = loader('./Notes.graphql')
export const staffEventQuery = loader('./StaffEvent.graphql')
export const staffEventsQuery = loader('./StaffEvents.graphql')
export const calendarDayDataQuery = loader('./CalendarDayData.graphql')
export const calendarCellsQuery = loader('./CalendarCells.graphql')
export const notesByDateRangeQuery = loader('./NotesByDateRange.graphql')
export const staffAssignedHoursQuery = loader('./StaffAssignedHours.graphql')
export const secondaryStaffCalendarCellsQuery = loader('./SecondaryStaffCalendarCells.graphql')
export const calendarSectionsQuery = loader('./CalendarSections.graphql')
export const primaryCalendarSectionsQuery = loader('./PrimaryCalendarSections.graphql')
export const secondaryCalendarSectionsQuery = loader('./SecondaryCalendarSections.graphql')
export const facilityUsersQuery = loader('./FacilityUsers.graphql')
export const readAlgoScheduleQuery = loader('./readAlgoSchedule.graphql')
