import { Component } from 'react'

export default class ComponentWithCommonFocus extends Component {
  state = { isFocused: true }

  componentDidMount() {
    setTimeout(() => this.ref?.focus(), 1)
  }

  render() {
    const { children, style } = this.props

    return (
      <div
        style={style}
        tabIndex="1"
        onBlur={this.onClickOutside}
        onClick={this.onClick}
        ref={(ref) => (this.ref = ref)}
      >
        {children}
      </div>
    )
  }

  onClickOutside = () => {
    const { onClickOutside } = this.props
    this.setState({ isFocused: false })

    setTimeout(() => {
      const { isFocused } = this.state

      if (!isFocused) {
        onClickOutside()
      }
    }, 150)
  }

  onClick = () => {
    this.setState({ isFocused: true })
  }
}
