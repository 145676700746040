import { PureComponent } from 'react'
import './ActiveDateRange.scss'
import { DateRangeState } from 'Manager/components'
import { withAppContext } from 'AppContext'

export class ActiveDateRange extends PureComponent {
  state = { isOpen: false }

  render() {
    const { unit, activeDateRange } = this.props
    const { isOpen } = this.state

    if (!unit || !unit.get('schedule')) {
      return (
        <div className="hx-calendar-datepicker">
          <div className="giant semibold">
            <div className="loading" />
          </div>
        </div>
      )
    }

    const state = activeDateRange.get('state')
    const title = activeDateRange.get('title')
    const endsAt = activeDateRange.get('endsAt')

    return (
      <>
        {!isOpen && (
          <div className="hx-calendar-datepicker p10 pv0 rounded align-middle">
            <span className="dateTitle bold pv0">{title}</span>
            <DateRangeState state={state} endDate={endsAt} />
          </div>
        )}
      </>
    )
  }
}

export default withAppContext(ActiveDateRange)
