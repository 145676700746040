import { Component } from 'react'
import { CreateEntity } from '@humanics/he-react-common/lib/admin/components'
import { withAppContext } from 'AppContext'

const createOperationId = 'createIdn'

export class IdnCreate extends Component {
  constructor(props) {
    super(props)

    const { id, name: title } = props.appState.getIn(['authentication', 'instance'])
    const instances = [{ id, title }]
    this.state = { instances }
  }

  render() {
    return <CreateEntity {...this.props} {...createProps(this.state)} onSubmit={this.onSubmit} />
  }

  onSubmit = (e, { name, location }) => {
    const { appState, authStore, history } = this.props
    const instance = appState.getIn(['authentication', 'instance'])
    const idn = { name, location }

    instance.gqlClient
      .createIdn({ idn })
      .then(() => {
        return authStore.authorize()
      })
      .then(() => {
        history.push('.')
      })
  }
}

export default withAppContext(IdnCreate)

function createProps({ instances }) {
  return {
    createOperationId,
    createDefinitionName: 'Idn',
    inputDefinitionExtraFields: {
      instance: {
        label: 'Instance',
        inputType: 'InputDropdown',
        type: 'string',
        required: true,
        enum: instances
      }
    }
  }
}
