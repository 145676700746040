import { withAppContext } from 'AppContext'
import './ToDo.scss'
import ExpiringCard from './ExpiringCard'
import OpenShiftCard from './OpenShiftCard'
import ShiftSwapCard from './ShiftSwapCard'
import TimeOffDetailsCard from './TimeOffDetailsCard'
import ShiftSkillMismatchDetailsCard from './ShiftSkillMismatchDetailsCard'
import SchedulesProgressCard from './SchedulesProgressCard'

function ToDo(props) {
  return (
    <div className="row">
      <div className="col-6">
        <div className="row">
          <div className="col-6">
            <OpenShiftCard {...props} />
          </div>
          <div className="col-6">
            <ShiftSwapCard {...props} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <TimeOffDetailsCard {...props} />
          </div>
          <div className="col-6">
            <ShiftSkillMismatchDetailsCard {...props} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ExpiringCard {...props} />
          </div>
        </div>
      </div>
      <div className="col-6">
        <SchedulesProgressCard {...props} />
      </div>
    </div>
  )
}

export default withAppContext(ToDo)
