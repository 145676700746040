import { Calendar } from 'entityWrappers'
import { ComponentViewController } from '../Components'
import { CalendarActionsService } from 'services'
import { getAppropriateStore } from '../layoutUtils'

export default class StateController extends ComponentViewController {
  get defaultState() {
    const { calendar, calendarType } = this.props
    const store = getAppropriateStore(calendarType)
    return { calendarWrapper: new Calendar(calendar, store) }
  }

  get calendar() {
    return this.state.calendarWrapper
  }

  get selectedObjects() {
    const { selection } = this.state
    return this.calendar.getSelectedObjects(selection)
  }

  get calendarActionsService() {
    const { timeService, calendarType } = this.props
    const store = getAppropriateStore(calendarType)
    return new CalendarActionsService(store, timeService)
  }

  set calendar(calendarWrapper) {
    this.setState({ calendarWrapper })
  }

  onComponentWillUpdate(nextProps, nextState) {
    const { calendar, calendarType } = this.props
    const { calendar: nextCalendar } = nextProps

    const isCalendarChanged = calendar !== nextCalendar
    const store = getAppropriateStore(calendarType)
    if (isCalendarChanged) {
      this.calendar = new Calendar(nextCalendar, store)
    }
  }

  isCalendarChanged(prevState) {
    const prevCalendarWrapper = this.getPreviousCalendar(prevState)
    return this.calendar.calendar !== prevCalendarWrapper.calendar
  }

  getPreviousCalendar(prevState) {
    return prevState.calendarWrapper
  }

  getPreviousSelectedObjects(prevState) {
    const { selection: prevSelection } = prevState
    return this.getPreviousCalendar(prevState).getSelectedObjects(prevSelection)
  }
}
