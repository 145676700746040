import { useEffect, useState } from 'react'
import { navigate } from 'Navigation'
import ShiftMismatchModal from './modals/ShiftSkillMismatchesModal/ShiftMismatchModal'

function _getScheduleStartAndEndDate(timeService, schedules) {
  let startDate
  let endDate

  for (const schedule of schedules) {
    const scheduleStartsAt = schedule?.get('startsAt')
    const scheduleEndsAt = schedule?.get('endsAt')
    const from = timeService.timeMoment(scheduleStartsAt)
    const to = timeService.timeMoment(scheduleEndsAt)
    const toMinuteBefore = to.clone().subtract(1, 'minutes')
    const today = timeService.today
    const isPast = timeService.isBefore(toMinuteBefore, today, 'day')
    const isTodayIncluded = timeService.isBetween(today, from, to, null, '[]')
    if (isPast || isTodayIncluded) {
      startDate = scheduleStartsAt
      endDate = scheduleEndsAt
    }
  }

  return { startDate, endDate }
}

function ShiftSkillMismatchDetailsCard({ expertiseStore, timeService, unit }) {
  const [shiftSkillMismatchCount, setShiftSkillMismatchCount] = useState(0)
  const [shiftSkillMismatchMetadata, setShiftSkillMismatchMetadata] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const unitId = unit.get('id')
  const schedules = unit.getIn(['schedules'])
  const isSchedulesReady = unit.get('isSchedulesLoaded')

  useEffect(() => {
    const data = async () => {
      if (!unitId) {
        return
      }

      setIsLoading(true)

      if (!isSchedulesReady) {
        return
      }

      if (!schedules?.size) {
        setShiftSkillMismatchCount(0)
        setIsLoading(false)
        return
      }

      const { startDate, endDate } = _getScheduleStartAndEndDate(timeService, schedules)

      const loadShiftSkillMismatchParams = { unitId, startDate, endDate }
      const shiftSkillMismatchData = await expertiseStore.loadShiftSkillMismatch(loadShiftSkillMismatchParams)
      const shiftSkillMismatch = shiftSkillMismatchData?.get('shiftSkillMismatch')
      const shiftSkillMismatchCount = shiftSkillMismatch?.get('shiftSkillMismatchCount') ?? 0
      const shiftSkillMismatchMetadata = shiftSkillMismatch?.get('shiftSkillMismatchMetadata') ?? []

      setShiftSkillMismatchCount(shiftSkillMismatchCount)
      setShiftSkillMismatchMetadata(shiftSkillMismatchMetadata)
      setIsLoading(false)
    }

    data()
  }, [expertiseStore, unitId, schedules, timeService, isSchedulesReady])

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible)
  }

  const handleCalenderNavigation = () => {
    setIsModalVisible(!isModalVisible)
    const today = timeService.today
    navigate.from.Dashboard.to.Calendar({ date: today, mode: 'full' })
  }

  return (
    <>
      <div className="todo-common-card">
        <div className="hx-card-header">
          <div className="col-2 icon-box">
            <div className="icon-shift-skill-mismatch"></div>
          </div>

          <div className="col-9 card-heading">Shift-Skill Mismatches</div>
          {shiftSkillMismatchCount > 0 && (
            <div className="col-1 icon-box">
              <sh-icon icon="arrow-line-right-s" onClick={toggleModalVisibility} />
              {isModalVisible && (
                <ShiftMismatchModal
                  shiftSkillMismatchCount={shiftSkillMismatchCount}
                  shiftSkillMismatchMetadata={shiftSkillMismatchMetadata}
                  toggleModalVisibility={toggleModalVisibility}
                  handleCalenderNavigation={handleCalenderNavigation}
                  timeService={timeService}
                />
              )}
            </div>
          )}
        </div>
        <div className="hx-card-details">
          {isLoading ? (
            <div style={{ margin: 'auto', padding: '16px' }}>
              <sh-spinner></sh-spinner>
            </div>
          ) : (
            <div className="col-5 hx-card-detail">
              <div className="h60 hx-card-detail-first-box">
                <div className="countFont">{shiftSkillMismatchCount}</div>
                {shiftSkillMismatchCount > 0 && (
                  <div className="icon-box warning-icon">
                    <div className="icon-warning"></div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ShiftSkillMismatchDetailsCard
