import { List, Map } from 'immutable'
import StateController from './StateController'
import { RequestError, StaffEvent, TimeOffCalendar } from 'entityWrappers'
import { DateRangeService, FacilityUserShiftsService, ShiftService } from 'services'

export default class ActionController extends StateController {
  denyTimeOffRequest = async (note) => {
    const { timeOffStore } = this.props
    const { selection } = this.state

    const timeOffRequestId = selection.get('timeOffRequestId')
    const staffPath = selection.get('staffPath')
    const timeOffRequestPath = { ...staffPath, timeOffRequestId }
    const timeOffRequest = this.timeOffCalendar.getStaffTimeOffRequest(timeOffRequestPath)

    this.setState({ isDenying: true })
    await timeOffStore.denyStaffTimeOffRequest(timeOffRequest, note)
    this.setState({ isDenying: false }, () => this.component.popupController.hidePopup())
  }

  approveTimeOffRequest = async (note) => {
    const { timeOffStore } = this.props
    const { selection } = this.state

    const timeOffRequestId = selection.get('timeOffRequestId')
    const staffPath = selection.get('staffPath')
    const timeOffRequestPath = { ...staffPath, timeOffRequestId }
    const timeOffRequest = this.timeOffCalendar.getStaffTimeOffRequest(timeOffRequestPath)
    let paidDates = timeOffRequest.get('paidDates')
    if (paidDates.size === 0) {
      const numberOfPaidDays = timeOffRequest.get('numberOfPaidDays')
      let dates = timeOffRequest.get('dates')
      dates = dates.map((date) => date.delete('__typename'))
      paidDates = dates.slice(0, numberOfPaidDays)
    }

    this.setState({ isApproving: true })
    const action = timeOffStore.approveStaffTimeOffRequest(timeOffRequest, paidDates, note)
    await this._executeTimeOffRequestActions([action])

    this.setState({ isApproving: false }, () => this.component.popupController.hidePopup())
  }

  deleteTimeOffs = () => {
    const { timeOffStore } = this.props
    const ids = this._getSelectedStaffEventIds()

    const action = timeOffStore.deleteStaffEvents(ids)

    return this._executeTimeActions([action])
  }

  changeTimeOff = async (eventVariant, note) => {
    if (!eventVariant) {
      return
    }

    const { selection } = this.state
    const { timeOffStore } = this.props

    const selectedCells = selection.get('cells')
    const staffPath = selection.get('staffPath')

    const unitEventVariantId = eventVariant.get('id')
    const staffEventIdsToDelete = this._getSelectedStaffEventIds()

    const staffEvents = this._buildTimeOffStaffEvents(staffPath, selectedCells, eventVariant)

    const updateStaffTimeOffs = async () => {
      await timeOffStore.deleteStaffEvents(staffEventIdsToDelete)
      await timeOffStore.createStaffEvents(staffEvents, unitEventVariantId, note)
    }
    const action = updateStaffTimeOffs()

    return this._executeTimeActions([action])
  }

  async _executeTimeActions(actions) {
    const { unit, timeOffStore } = this.props
    const { selection } = this.state

    let isFailedToFetch = false
    let isUnathorized = false
    try {
      for (const action of actions) {
        await action
      }
    } catch (error) {
      isUnathorized = RequestError.isUnathorized(error)
      isFailedToFetch = RequestError.isFailedToFetch(error)
    }

    if (!isFailedToFetch && !isUnathorized) {
      const unitId = unit.get('id')

      const staffPath = selection.get('staffPath')
      const { dateRangeIndex } = staffPath

      const dateRange = this.timeOffCalendar.getDateRange(dateRangeIndex)
      const userIds = TimeOffCalendar.getDateRangeUserIds(dateRange)

      return timeOffStore.loadDateRangeTimeOffs(unitId, dateRange, userIds)
    }
  }

  async _executeTimeOffRequestActions(actions) {
    const { unit, timeOffStore } = this.props
    const dateRanges = this._getDateRangesToReload()

    let isFailedToFetch = false
    let isUnathorized = false
    try {
      for (const action of actions) {
        await action
      }
    } catch (error) {
      isUnathorized = RequestError.isUnathorized(error)
      isFailedToFetch = RequestError.isFailedToFetch(error)
    }

    if (!isFailedToFetch && !isUnathorized) {
      const unitId = unit.get('id')

      const promises = dateRanges.map((dateRange) => {
        const userIds = TimeOffCalendar.getDateRangeUserIds(dateRange)
        return timeOffStore.loadDateRangeTimeOffs(unitId, dateRange, userIds)
      })

      return Promise.all(promises)
    }
  }

  _getDateRangesToReload() {
    const { appState, timeService, unit, activeDateRange } = this.props
    const { selection } = this.state
    const timeOff = appState.get('timeOff')

    const timeOffRequestId = selection.get('timeOffRequestId')
    const staffPath = selection.get('staffPath')
    const timeOffRequestPath = { ...staffPath, timeOffRequestId }
    const timeOffRequest = this.timeOffCalendar.getStaffTimeOffRequest(timeOffRequestPath)

    const timeOffRequestStartDate = timeOffRequest.get('dates').first()?.get('date')
    const timeOffRequestEndDate = timeOffRequest.get('dates').last()?.get('date')

    const timeOffRequestStartDateMoment = timeService.timeMoment(timeOffRequestStartDate)
    const timeOffRequestEndDateMoment = timeService.timeMoment(timeOffRequestEndDate)

    const timeOffRequestStartUsDate = timeOffRequestStartDateMoment.format('MM-DD-YYYY')
    const timeOffRequestEndUsDate = timeOffRequestEndDateMoment.format('MM-DD-YYYY')

    const schedules = timeOff.get('schedulesMap').valueSeq() || List([unit.get('schedule')])
    const unitConfig = unit.set('schedules', schedules)
    const usDate = activeDateRange.get('usDate')

    const dateRangeService = new DateRangeService(unitConfig, timeService, usDate)
    const startDateScheduleIndex = dateRangeService.getDateRangeIndex(timeOffRequestStartUsDate)
    const endDateScheduleIndex = dateRangeService.getDateRangeIndex(timeOffRequestEndUsDate)

    const isSameDateRange = startDateScheduleIndex === endDateScheduleIndex
    if (isSameDateRange) {
      const dateRange = this.timeOffCalendar.getDateRange(startDateScheduleIndex)
      return [dateRange]
    }

    return [startDateScheduleIndex, endDateScheduleIndex].map((dateRangeIndex) =>
      this.timeOffCalendar.getDateRange(dateRangeIndex)
    )
  }

  _getSelectedStaffEventIds() {
    const { selection } = this.state
    const selectedCells = selection.get('cells')
    const staffPath = selection.get('staffPath')

    return selectedCells.reduce((memo, identityHash, cellIndex) => {
      const cell = this.timeOffCalendar.getCell({ ...staffPath, cellIndex })
      const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
      const staffEvent = new StaffEvent(cellStaffEvent.get('timeOff'))
      const { staffEventId } = staffEvent
      return memo.concat(staffEventId)
    }, [])
  }

  _buildTimeOffStaffEvents(staffPath, cells, eventVariant) {
    const { timeService, unit } = this.props
    const shifts = unit.get('shifts')
    const staff = this.timeOffCalendar.getStaff(staffPath)

    const eligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits'])
    const userId = staff.get('userId')
    const facilityUserShiftsService = new FacilityUserShiftsService(timeService)

    const paidTimePercentage = eventVariant.getIn(['timeOffOptions', 'paidTimePercentage'])
    const isPTO = paidTimePercentage > 0

    return cells.reduce((memo, identityHash, cellIndex) => {
      const cell = this.timeOffCalendar.getCell({ ...staffPath, cellIndex })
      const dateTime = cell.get('dateTime')

      const primaryShift = facilityUserShiftsService.getPrimaryShift(eligibleUnits, dateTime, shifts)
      const shiftId = primaryShift.get('id')

      if (!memo[shiftId]) {
        memo[shiftId] = []
      }

      const shiftService = new ShiftService(primaryShift, timeService)
      const { unitId, duration: shiftDuration } = shiftService.getShiftParameters()
      const { startsAt: shiftStartsAt } = shiftService.getShiftPeriodForDate(dateTime)
      const dayDuration = 24 * 60
      const duration = isPTO ? shiftDuration : dayDuration
      const startsAt = isPTO ? shiftStartsAt : timeService.timeMoment(shiftStartsAt).startOf('day').toISOString()
      const staffEvent = { unitId, shiftId, userId, startsAt, duration }

      return memo.concat(staffEvent)
    }, [])
  }
}
