import { Component } from 'react'
import classNames from 'classnames'
import { startCase, capitalize } from 'lodash'
import { OpenShiftStaff } from 'entityWrappers'
import Avatar from 'Manager/components/Avatar'

export default class Staff extends Component {
  constructor(props) {
    super(props)
    this.state = { isCollapsed: true }
  }
  render() {
    const { staff, openShiftController, type, timeService } = this.props
    const { isCollapsed } = this.state
    const { openShift, isStaffSelected, isAddStaffToShiftProcessing } = openShiftController
    const openShiftStaff = new OpenShiftStaff(openShift, staff)
    const {
      fullName,
      userId,
      isAccepted,
      hoursThisWeek = 0,
      employmentType,
      staffExpertises,
      matchPercentage,
      staffPosition,
      maximumNumberOfHoursPerWeek,
      facilityProfile,
      avatarUrl
    } = openShiftStaff

    const facilityProfileWithAvatarUrl = facilityProfile.set('avatarUrl', avatarUrl)

    const isOvertimed = maximumNumberOfHoursPerWeek < hoursThisWeek
    const isAcceptedStaff = type === 'acceptedStaff'
    const isEligibleStaff = type === 'eligibleStaff'
    const isNotInvitedStaff = type === 'notInvitedStaff'
    const checkboxId = `staff${userId}`
    const checked = isStaffSelected(staff)
    const rowClassNames = classNames('row bg-white', { checked })
    const isApproved = this.isUserApprovedOnShift(openShift, userId, isAccepted)
    const { startsAt: shiftStartsAt } = openShift
    const homeUnitName = openShiftStaff.getHomeUnitNameForDate(shiftStartsAt, timeService)

    return (
      <>
        <div className={rowClassNames} onClick={this.toggleStaff}>
          <div>
            {(isEligibleStaff || isNotInvitedStaff) && (
              <div className="hx-checkbox">
                <input
                  id={checkboxId}
                  type="checkbox"
                  checked={checked}
                  onChange={(e) => openShiftController.toggleStaff(openShiftStaff)}
                />
                <label className="regent-gray" htmlFor={checkboxId} />
              </div>
            )}
          </div>
          <div>
            <Avatar
              userId={userId}
              profile={facilityProfileWithAvatarUrl}
              size={32}
              avatarClass="rounded-16"
              initialsClass="bg-periwinklegray rounded-16 hx-default-avatar hx-staff-initials"
            />
            <div className="staff-info">
              <div className="staff-name">{fullName}</div>
              <div className="staff-schedule small regent-gray">
                <div>{startCase(employmentType)}</div>
                <span>&#183;</span>
                <div>{this.renderShift(openShiftStaff, openShift)}</div>
                <span>&#183;</span>
                <div>
                  <span className={classNames({ scarlet: isOvertimed })}>{hoursThisWeek}</span>
                  <span>&nbsp;/&nbsp;</span>
                  <span>{maximumNumberOfHoursPerWeek}hrs</span>
                </div>
              </div>
            </div>
          </div>
          <div className="staff-matching">
            <div>{matchPercentage}% match</div>
            <div className="bg-periwinklegray progress-bar">
              <div
                className={classNames('matching', {
                  'bg-fern': matchPercentage >= 50,
                  'bg-warning': matchPercentage >= 20 && matchPercentage < 50,
                  'bg-redorange': matchPercentage < 20
                })}
                style={{ height: '100%', width: `${matchPercentage}%` }}
              />
            </div>
          </div>
          <div>
            {homeUnitName} / {staffPosition}
          </div>
          <div>
            {isApproved && isAccepted ? (
              <span className="hx-added-to-shift">
                <i className="icon-checkmark" />
                <span>Scheduled Staff</span>
              </span>
            ) : (
              <sh-button
                color="primary"
                size="auto"
                disabled={isAddStaffToShiftProcessing ? true : undefined}
                onClick={this.addStaffToShift(openShiftStaff)}
                label={isAcceptedStaff ? 'Approve' : 'Add to Shift'}
              />
            )}
          </div>
          <div className="text-right">
            <i
              className={classNames('rock-blue', {
                'icon-up': !isCollapsed,
                'icon-down': isCollapsed
              })}
            />
          </div>
        </div>
        {!isCollapsed && this.renderMatchingDetails(staffExpertises, openShift)}
      </>
    )
  }

  isUserApprovedOnShift(openShift, userId, isAccepted) {
    const { approvedFacilityUsers } = openShift
    const approvedUser = approvedFacilityUsers.findLast((afc) => afc.get('userId') === userId)
    return approvedUser && approvedUser.size > 0 && isAccepted
  }

  renderExpertiseBox(requirements, matched, label) {
    const matchKey = matched ? 'match' : 'mismatch'
    return (
      requirements?.get(matched)?.size > 0 && (
        <div className="matching-box">
          <div className="regent-gray">
            {capitalize(label)} {capitalize(matchKey)}
          </div>
          {requirements.get(matched).map((item) => (
            <div className="item" key={item.get('expertiseId')}>
              {item.get('name')}
              <span className={`matching-label ${item.get('key')}`}>
                {startCase(capitalize(item.get('staffLevel')))}
              </span>
            </div>
          ))}
        </div>
      )
    )
  }

  renderMatchingDetails(expertiseRequirements, openShift) {
    const { roles } = this.props
    const { unitRoleId } = openShift
    const role = roles.find((g) => g.get('id') === unitRoleId)
    const position = role?.get('name') || '-'

    const skillsRequirements = expertiseRequirements
      .filter((item) => item.get('type') === 'skill')
      .groupBy((item) => item.get('matched'))
    const equipmentRequirements = expertiseRequirements
      .filter((item) => item.get('type') === 'equipment')
      .groupBy((item) => item.get('matched'))
    const licenseRequirements = expertiseRequirements
      .filter((item) => item.get('type') === 'license')
      .groupBy((item) => item.get('matched'))
    const certificationRequirements = expertiseRequirements
      .filter((item) => item.get('type') === 'certification')
      .groupBy((item) => item.get('matched'))
    return (
      <div className="single-row">
        <div className="details">
          <div className="icon icon-person-border" />
          <div className="regent-gray">Current Primary Shift Role:</div>
          <div className="pl10">{position}</div>
        </div>
        <div className="match-box-group">
          <div className="match-boxes">
            {this.renderExpertiseBox(skillsRequirements, true, 'Skills')}
            {this.renderExpertiseBox(equipmentRequirements, true, 'Equipments')}
            {this.renderExpertiseBox(licenseRequirements, true, 'Licenses')}
            {this.renderExpertiseBox(certificationRequirements, true, 'Certification')}
          </div>
          <div className="match-boxes">
            {this.renderExpertiseBox(skillsRequirements, false, 'Skills')}
            {this.renderExpertiseBox(equipmentRequirements, false, 'Equipments')}
            {this.renderExpertiseBox(licenseRequirements, false, 'Licenses')}
            {this.renderExpertiseBox(certificationRequirements, false, 'Certification')}
          </div>
        </div>
      </div>
    )
  }

  renderShift(openShiftStaff) {
    const { eligibleUnits, timeService } = this.props
    const { openShift } = openShiftStaff
    const { startsAt: shiftStartsAt } = openShift

    const eligibleUnit = openShiftStaff.getEligibleUnitForDate(shiftStartsAt, timeService)
    if (!eligibleUnit) {
      return null
    }

    const homeUnitShiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')
    if (!homeUnitShiftTypeId) {
      return null
    }

    const homeUnitId = eligibleUnit.get('homeUnitId')
    const unit = eligibleUnits.find((unit) => unit.get('id') === homeUnitId)
    if (!unit) {
      return null
    }

    const shiftType = unit.get('shiftTypes').find((shiftType) => shiftType.get('id') === homeUnitShiftTypeId)
    if (!shiftType) {
      return null
    }

    return shiftType.get('title')
  }

  addStaffToShift = (openShiftStaff) => {
    return async () => {
      const { openShiftController } = this.props
      const { isAddStaffToShiftProcessing, isUpdating } = openShiftController

      if (isAddStaffToShiftProcessing || isUpdating) {
        return
      }

      openShiftController.isAddStaffToShiftProcessing = true
      await openShiftController.addStaffToShift(openShiftStaff)
      openShiftController.isAddStaffToShiftProcessing = false
    }
  }

  toggleStaff = () => {
    const isCollapsed = !this.state.isCollapsed
    this.setState({ isCollapsed, isCollapsedOnes: true })
  }
}
