import { keyBy, mapValues, uniq } from 'lodash'
import { openShiftStaffQuery, staffAssignedWeekHoursQuery } from './Queries'

export default async function extendShiftOpportunity(gqlClient, shiftOpportunity) {
  shiftOpportunity.isReady = true
  shiftOpportunity.availableUserIds = await getAvailableUserIds(gqlClient, shiftOpportunity)
  shiftOpportunity.facilityUserIds = getFacilityUserIds(shiftOpportunity)
  shiftOpportunity.staffAssignedWeekHoursMap = await getStaffAssignedWeekHoursMap(gqlClient, shiftOpportunity)

  return shiftOpportunity
}

async function getAvailableUserIds(gqlClient, shiftOpportunity) {
  const { shiftDayId } = shiftOpportunity

  const { staffEligibleForUnit: availableUsers } = await gqlClient.query(openShiftStaffQuery, {
    shiftDayId,
    limit: 999
  })

  return availableUsers.map((facilityUser) => facilityUser.userId)
}

function getFacilityUserIds(shiftOpportunity) {
  const { availableUserIds, eligibleUserIds } = shiftOpportunity

  return uniq(availableUserIds.concat(eligibleUserIds))
}

async function getStaffAssignedWeekHoursMap(gqlClient, shiftOpportunity) {
  const { shiftStartsAt } = shiftOpportunity

  const { staffAssignedWeekHours } = await gqlClient.query(staffAssignedWeekHoursQuery, {
    date: shiftStartsAt,
    userIds: shiftOpportunity.facilityUserIds
  })

  const hoursGroupped = keyBy(staffAssignedWeekHours, 'userId')

  return mapValues(hoursGroupped, (staffHours) => {
    return staffHours.assignedHours
  })
}
