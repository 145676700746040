import { PureComponent } from 'react'
import Row from '../../Row'
import Profile from './Profile'
import Event from './Event'
import './Staff.scss'

export default class Staff extends PureComponent {
  render() {
    const { staff, timeService, shiftDay } = this.props
    const event = staff.get('event')
    const staffPrimaryShiftId = staff.get('primaryShiftId')

    const rowProps = { shiftDay, event, timeService }
    const eventProps = { event, timeService, staffPrimaryShiftId }

    return (
      <div className="_staff hx-calendar-staff">
        <div className="row bt1 bg-concrete">
          <Profile staff={staff} />
          <Row {...rowProps}>
            <Event {...eventProps} />
          </Row>
        </div>
      </div>
    )
  }
}
