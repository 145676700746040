import { createContext } from 'react'

export const AppContext = createContext({})

export function withAppContext(Component, customProps) {
  return function ContextComponent(props) {
    return (
      <AppContext.Consumer>{(context) => <Component {...context} {...props} {...customProps} />}</AppContext.Consumer>
    )
  }
}
