import Banner from '@humanics/he-react-common/lib/components/Banner'
import OfflineBanner from '@humanics/he-react-common/lib/components/Banner/OfflineBanner'
import { withAppContext } from './AppContext'
import { removeBanner, setOfflineMode } from '@humanics/he-react-common/lib/stores/bannersStore'
import { myPermissionsQuery } from './stores/humanics/Queries'

const checkConnection = (gqlClient) => gqlClient.query(myPermissionsQuery, null, { displayError: false })
const OfflineBannerStatus = withAppContext(OfflineBanner, { setOfflineMode, checkConnection })
export { OfflineBannerStatus }

export default withAppContext(Banner, { removeBanner })
