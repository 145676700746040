import StateController from './StateController'
import { Map, OrderedMap } from 'immutable'

export default class MultiselectController extends StateController {
  get defaultState() {
    return {
      selection: Map({
        staffPath: {},
        cells: OrderedMap(),
        timeOffRequestId: null
      })
    }
  }

  get selectedCells() {
    return this.state.selection.get('cells')
  }

  get selectedStaffPath() {
    return this.state.selection.get('staffPath')
  }

  get selectedIndexes() {
    return this.selectedCells.keySeq().reduce((memo, index) => memo.concat(Number(index)), [])
  }

  resetSelection = () => {
    this.setState(this.defaultState)
  }

  _setSelection = (properties, callback) => {
    const selection = this.state.selection.merge(Map(properties))
    this.setState({ selection }, callback)
  }

  _selectCell(staffPath, cell) {
    const cellIndex = Number(cell.get('index'))
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const identityHash = cellStaffEvent.getIn(['timeOff', 'identityHash']) || 'empty'
    const cells = this.selectedCells.set(cellIndex, identityHash)
    this._setSelection({ staffPath, cells, timeOffRequestId: null })
  }

  selectTimeOffRequest = (staffPath, timeOffRequestId, callback) => {
    const cells = OrderedMap()
    this._setSelection({ staffPath, cells, timeOffRequestId }, callback)
  }

  selectSingleCell = (staffPath, cell, callback) => {
    const cellIndex = Number(cell.get('index'))
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const identityHash = cellStaffEvent.getIn(['timeOff', 'identityHash']) || 'empty'
    const cells = OrderedMap().set(cellIndex, identityHash)
    this._setSelection({ staffPath, cells, timeOffRequestId: null }, callback)
  }

  cellSelection = (selectedCells, eventInfo, staffPath, cell) => {
    const { isMetaKeyPressed, isCtrlKeyPressed } = eventInfo
    const isSingleCellSelected = selectedCells.size === 1
    if (isSingleCellSelected) {
      return this.resetSelection()
    }

    if (isMetaKeyPressed || isCtrlKeyPressed) {
      return this._unselectCell(cell)
    }

    return this.selectSingleCell(staffPath, cell)
  }

  handleClick = (staffPath, cell, eventInfo) => {
    const { isShiftKeyPressed, isMetaKeyPressed, isCtrlKeyPressed } = eventInfo

    const { selectedCells } = this
    const isSelected = selectedCells.size && selectedCells.size > 0

    // nothing selected
    if (!isSelected) {
      // add this cell as a first selected
      return this.selectSingleCell(staffPath, cell)
    }

    if (!this._isSameRow(staffPath)) {
      this.resetSelection()
      return this.selectSingleCell(staffPath, cell)
    }

    // cell from current row selected
    // current cell selected
    const cellIndex = Number(cell.get('index'))
    const isCellSelected = selectedCells.get(cellIndex)

    if (isCellSelected) {
      return this.cellSelection(selectedCells, eventInfo, staffPath, cell)
    }

    // TODO warn about different identity on hover
    const selectedIdentityHash = selectedCells.first()
    const identityHash = cell.getIn(['timeOff', 'identityHash']) || 'empty'
    const isSameIdentityHash = identityHash === selectedIdentityHash

    if (!isSameIdentityHash) {
      const isSingleCellSelected = selectedCells.size === 1
      const isMultiselectKeyPressed = isMetaKeyPressed || isShiftKeyPressed

      if (isSingleCellSelected || !isMultiselectKeyPressed) {
        return this.selectSingleCell(staffPath, cell)
      }

      return
    }

    if (isShiftKeyPressed) {
      const identityHash = cell.getIn(['timeOff', 'identityHash'])
      return this._selectIdenticalCellsToIndex(staffPath, cellIndex, identityHash)
    }

    if (isMetaKeyPressed || isCtrlKeyPressed) {
      return this._selectCell(staffPath, cell)
    }

    return this.selectSingleCell(staffPath, cell)
  }

  _selectIdenticalCellsToIndex(staffPath, cellIndex, identityHash) {
    const indexes = [...this.selectedIndexes, cellIndex]
    const cellsNumberBetweenIndexes = this._getCellsNumberBetweenIndexes(indexes)
    const identicalCells = this._getIdenticalCellsToIndex(staffPath, cellIndex, identityHash)

    const _isCellMismatch = cellsNumberBetweenIndexes !== identicalCells.size
    if (_isCellMismatch) {
      return
    }

    return this._setSelection({ cells: identicalCells, timeOffRequestId: null })
  }

  _getIdenticalCellsToIndex(staffPath, index, identityHash) {
    // TODO: probably this is wrong as we always check for cells betwen minimal and maximal indexes
    //       which prevents us from selections of cells between already selected cells sequences.
    //       Probably a correct solution will be to check for cells between last selected index and a new one,
    //       if we want to have a most common approach for multiselection:
    const indexes = [...this.selectedIndexes, index].sort((a, b) => a - b)
    const identicalCellsIndexes = this._getIdenticalCellsIndexes(staffPath)

    const [firstCellIndex, lastCellIndex] = [indexes[0], indexes[indexes.length - 1]]
    const matchedCellsIndexes = identicalCellsIndexes.slice(
      identicalCellsIndexes.indexOf(firstCellIndex),
      identicalCellsIndexes.indexOf(lastCellIndex) + 1
    )

    return matchedCellsIndexes.reduce((memo, index) => memo.set(index, identityHash), OrderedMap())
  }

  _getCellsNumberBetweenIndexes(indexes) {
    const indexesSorted = indexes.sort((a, b) => a - b)
    return indexesSorted[indexesSorted.length - 1] - indexesSorted[0] + 1
  }

  _getIdenticalCellsIndexes(staffPath) {
    const staffCells = this.timeOffCalendar.getStaffCells(staffPath)

    const selectedIdentityHash = this.selectedCells.last() || 'empty'

    const identicalCellsIndexes = staffCells
      .map((cell, index) => cell.set('index', index))
      .filter((cell) => (cell.getIn(['timeOff', 'identityHash']) || 'empty') === selectedIdentityHash)
      .map((cell) => cell.get('index'))

    return identicalCellsIndexes || []
  }

  _isSameRow = (staffPath) => {
    const { dateRangeIndex, roleIndex, shiftIndex, staffIndex } = staffPath

    const {
      staffIndex: selectedStaffIndex,
      shiftIndex: selectedShiftIndex,
      roleIndex: selectedRoleIndex,
      dateRangeIndex: selectedDateRangeIndex
    } = this.selectedStaffPath

    const isSameDateRange = selectedDateRangeIndex === dateRangeIndex
    const isSameRole = selectedRoleIndex === roleIndex
    const isSameShift = selectedShiftIndex === shiftIndex
    const isSameStaff = selectedStaffIndex === staffIndex

    return isSameDateRange && isSameRole && isSameShift && isSameStaff
  }

  _unselectCell = (cell) => {
    const cellIndex = cell.get('index')
    const cells = this.selectedCells.remove(cellIndex)
    this._setSelection({ cells, timeOffRequestId: null })
  }
}
