import classNames from 'classnames'
import moment from 'moment'

export default function DateRangeState({ state, endDate }) {
  const classes = classNames({
    'calendar-state white rounded upper bold label': true,
    'bg-graychateau': state === 'inactive',
    'bg-fern': state === 'active',
    'bg-tuliptree': state === 'balancing',
    'bg-cornflowerblue': state === 'published',
    'bg-echoblue': state === 'requests open'
  })

  const getState = (_) => {
    if (state === 'inactive' && moment(endDate) < moment()) {
      return 'archived'
    }

    return state
  }

  return <span className={`${classes} nowrap`}>{getState()}</span>
}
