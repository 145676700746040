import cn from 'classnames'

const customFields = {
  version: (dataRow) => (
    <td>
      <span className={cn('label outline', statusClass(dataRow))}>{dataRow.version}</span>
    </td>
  ),
  isRead: (dataRow) => (
    <td className="td-status">
      <span className={cn('label', { success: dataRow.isRead })}>{dataRow.isRead ? 'Read' : 'New'}</span>
    </td>
  )
}

function statusClass(dataRow) {
  const { status, version } = dataRow

  if (status.toLowerCase() === 'error') {
    return 'error'
  }
  if (!version || version === 'unhealty') {
    return 'error'
  }

  return 'success'
}

export default customFields
