import { t } from 'i18n'
import './WeekDays.scss'
import { CSSProperties } from 'react'

export default function WeekDays() {
  const daysOfWeek = [
    t('weekDays.Sun'),
    t('weekDays.Mon'),
    t('weekDays.Tue'),
    t('weekDays.Wed'),
    t('weekDays.Thu'),
    t('weekDays.Fri'),
    t('weekDays.Sat')
  ]

  const cellStyle: CSSProperties = {
    width: '15.75em',
    height: '2.5em',
    border: '0.063em solid #ccc',
    textAlign: 'center',
    lineHeight: '3.5em',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <div className="col-12 hx-box-shadow flex week-days">
      {daysOfWeek.map((day, index) => (
        <div key={index} style={cellStyle}>
          <sh-text size="header-2">{day}</sh-text>
        </div>
      ))}
    </div>
  )
}
