import { useEffect, useState } from 'react'
import { navigate } from 'Navigation'

async function loadShiftSwaps(shiftSwapsStore, unitId) {
  return shiftSwapsStore.loadShiftSwaps(unitId)
}

function getShiftSwapsRequiredApprovalcount(shiftSwaps) {
  return Object.values(shiftSwaps.toJS()).reduce((counter, shiftSwaps) => {
    counter =
      counter +
      shiftSwaps.shiftSwaps.reduce((requiredApprovalcount, shiftSwap) => {
        if (shiftSwap.acceptedDate !== null) {
          requiredApprovalcount = requiredApprovalcount + 1
        }
        return requiredApprovalcount
      }, 0)
    return counter
  }, 0)
}

function ShiftSwapCard({ unit, shiftSwapsStore }) {
  const [totalShiftSwaps, setTotalShiftSwaps] = useState(0)
  const [totalNeedApprovalShiftSwaps, setTotalNeedApprovalShiftSwaps] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const unitId = unit.get('id')

  useEffect(() => {
    if (!unitId) {
      return
    }

    setIsLoading(true)
    loadShiftSwaps(shiftSwapsStore, unitId).then((appState) => {
      let shiftSwapsCount = 0
      const shiftSwapsState = appState.get('shiftSwapsState')
      const shiftSwaps = shiftSwapsState.get('shiftSwaps')
      setTotalNeedApprovalShiftSwaps(getShiftSwapsRequiredApprovalcount(shiftSwaps))

      shiftSwaps.forEach((shift) => (shiftSwapsCount += shift.get('shiftSwaps').size))
      setTotalShiftSwaps(shiftSwapsCount)
      setIsLoading(false)
    })
  }, [unitId, shiftSwapsStore])

  return (
    <>
      <div className="todo-common-card">
        <div className="hx-card-header">
          <div className="col-2 icon-box">
            <div className="icon-shift-swap"></div>
          </div>
          <div className="col-9 card-heading">Shift Swap Requests</div>
          {!isLoading && (
            <div className="col-1 icon-box" onClick={() => navigate.from.Dashboard.to.ShiftSwaps({})}>
              <sh-icon icon="arrow-line-right-s"></sh-icon>
            </div>
          )}
        </div>
        <div className="hx-card-details">
          {isLoading ? (
            <div style={{ margin: 'auto', padding: '16px' }}>
              <sh-spinner></sh-spinner>
            </div>
          ) : (
            <>
              <div className="col-5 hx-card-detail">
                <div className="h60 countFont">{totalShiftSwaps}</div>
                <div className="h20 labelFont">Shift Swaps</div>
              </div>
              <div className="col-2 divider">
                <sh-divider vertical></sh-divider>
              </div>
              <div className="col-5 hx-card-detail">
                <div className="h60 hx-card-detail-first-box">
                  <div className="countFont">{totalNeedApprovalShiftSwaps}</div>

                  {totalNeedApprovalShiftSwaps > 0 && (
                    <div className="icon-box warning-icon">
                      <div className="icon-warning"></div>
                    </div>
                  )}
                </div>
                <div className="h20 labelFont">Approvals Required</div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ShiftSwapCard
