import { Component, createRef } from 'react'
import WarningHint from './WarningHint'
import { navigate } from 'Navigation'
import { withAppContext } from 'AppContext'
import moment from 'moment'
import { curry } from 'i18n'

const t = curry('openShifts.')

export class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { isMouseHover: false }
    this.buttonRef = createRef()
  }

  render() {
    const { openShiftController } = this.props
    const { isMouseHover } = this.state
    const {
      openShift,
      isLoading,
      isUpdating,
      isCanceling,
      isAnyStaffSelected,
      canPostOpenShift,
      canUpdateShiftOpportunity
    } = openShiftController
    const { isPosted, unitId, isCompleted, startsAt } = openShift
    const isWarningVisible = isMouseHover && !isAnyStaffSelected && !isPosted
    const isPostedDisabled = !canPostOpenShift && !canUpdateShiftOpportunity
    const canCancel = isPosted && !isCompleted
    const isCancelDisabled = isCanceling
    const dateRoute = moment(startsAt).format('MM-DD-YYYY')
    let title = ''

    if (!isLoading) {
      title = isPosted ? 'Open Shift Review' : 'Open Shift Detail'
    }

    return (
      <header className="hx-open-shift-header bg-white row align-middle">
        <div className="col col-4">
          <button className="float-left shuttle-gray" onClick={this.navigateToOpenShifts}>
            <i className="icon-left" />
            &nbsp; Back to Open Shifts
          </button>
        </div>
        <div className="col _title">
          <h1>{title}</h1>
        </div>
        <div className="col col-4 text-right">
          <WarningHint
            text="Please select eligible staff for this shift"
            parentRef={this.buttonRef}
            visible={isWarningVisible}
          />
          <div className="row align-right">
            <sh-button
              color="tertiary"
              size="auto"
              onClick={this.navigateToFullView(unitId, dateRoute)}
              label="View in Calendar"
            />
            {canCancel && (
              <sh-button
                id={!isCancelDisabled && '_view_cancel'}
                color="tertiary"
                size="auto"
                disabled={isCancelDisabled ? true : undefined}
                onClick={this.cancelShiftOpportunity}
                label="Remove Post"
              />
            )}
            <span
              ref={this.buttonRef}
              onMouseEnter={() => this.setState({ isMouseHover: true })}
              onMouseLeave={() => this.setState({ isMouseHover: false })}
            >
              <sh-button
                id="_post"
                onClick={this.onClick(openShift)}
                color="primary"
                size="auto"
                disabled={isPostedDisabled || isUpdating ? true : undefined}
                label="Post Open Shift"
              />
            </span>
          </div>
        </div>
      </header>
    )
  }

  cancelShiftOpportunity = async (event) => {
    event.preventDefault()
    const { Dialog, openShiftStaffMap, openShiftController, openShift } = this.props

    const approvedUserIds = openShift.get('approvedUserIds')
    const acceptedStaff = openShiftStaffMap
      .get('acceptedStaff')
      .filter((facilityUser) => !approvedUserIds.includes(facilityUser.get('id')))
      .map((facilityUser) => facilityUser.get('fullName'))
      .toArray()

    let dialogAnswer = false
    const { length: acceptedStaffLength } = acceptedStaff
    const hasAcceptedStaff = acceptedStaffLength > 0

    if (hasAcceptedStaff) {
      const hasManyStaff = acceptedStaffLength > 4
      const visibleAcceptedStaff = acceptedStaff.slice(0, 4)
      const etcText = hasManyStaff ? ', and others' : ''
      const message =
        `${visibleAcceptedStaff.join(', ')}${etcText} ${
          acceptedStaffLength > 1 ? 'are' : 'is'
        } pending approval to work this open shift.\n\n` +
        `${
          acceptedStaffLength > 1 ? 'Their' : 'His/her'
        } approval will remain pending until the shift is filled or the shift has ended.`

      dialogAnswer = await Dialog.confirm(message, {
        type: 'warning',
        title: 'Remove post?',
        accept: t('confirmShiftOpportunityCancel')
      })
    }

    if (dialogAnswer || !hasAcceptedStaff) {
      openShiftController.isCanceling = true
      await openShiftController.cancelOpenShift()
      openShiftController.isCanceling = false
      this.navigateToOpenShifts()
    }
  }

  async createShiftOpportunity() {
    const { openShiftController } = this.props
    const { canPostOpenShift } = openShiftController

    if (!canPostOpenShift) {
      return
    }

    openShiftController.isUpdating = true
    await openShiftController.createShiftOpportunity()
    openShiftController.isUpdating = false

    this.navigateToOpenShifts()
  }

  async updateShiftOpportunity(openShiftEntity) {
    const { openShiftController } = this.props
    const { canUpdateShiftOpportunity } = openShiftController

    if (!canUpdateShiftOpportunity) {
      return
    }

    openShiftController.isUpdating = true
    await openShiftController.updateShiftOpportunity()
    openShiftController.isUpdating = false
  }

  navigateToOpenShift = () => {
    const { openShift } = this.props
    const shiftDayId = openShift.get('shiftDayId')
    navigate.from.ShiftOpportunity.to.OpenShift({ shiftDayId })
  }

  navigateToOpenShifts = () => {
    navigate.from.OpenShifts.to.OpenShifts()
  }

  navigateToFullView = (unitId, date) => (dayView) => {
    navigate.from.OpenShift.to.CalendarFullView({ unitId, date })
  }

  onClick = (openShiftEntity) => {
    const { isPosted } = openShiftEntity

    return () => (isPosted ? this.updateShiftOpportunity() : this.createShiftOpportunity())
  }
}

export default withAppContext(Header)
