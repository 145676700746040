import { Component } from 'react'
import { withAppContext } from 'AppContext'
import { CreateAnnouncementsMaster } from './CreateAnnouncements'

class FormLoader extends Component {
  state = {
    isLoading: true
  }

  componentDidMount = async () => {
    const { announcementsStore, match } = this.props
    const { announcementId } = match.params
    await announcementsStore.getAnnouncement(announcementId)
    this.setState({ isLoading: false })
  }

  render() {
    const { appState, editExisting, viewExisting } = this.props
    const { isLoading } = this.state
    const loadedForm = appState.getIn(['announcements', 'activeAnnouncementItem'])
    let editable = false
    if (loadedForm) {
      editable = !!editExisting
    }

    return (
      <>{!isLoading && <CreateAnnouncementsMaster {...this.props} editable={editable} viewExisting={viewExisting} />}</>
    )
  }
}

export default withAppContext(FormLoader)
