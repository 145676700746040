import { NOTIFICATION_TYPES } from 'Staff/Calendar/utils';
import { NotificationItem } from 'Staff/Common/NotificationItem';
interface INotificationCard {
  shiftSwapRequestStatus: string,
  receiverName: string,
  onClose?: any
}

const NotificationCard = ({ shiftSwapRequestStatus, receiverName, onClose }: INotificationCard) => {
  let notificationContent;

  switch (shiftSwapRequestStatus) {
    case 'Success':
      notificationContent = (
        <NotificationItem
          labelKey="staffCalendar.shiftSwap.labels.shiftSwapRequested"
          message={`Your shift swap request has been shared with ${receiverName}.`}
          type={NOTIFICATION_TYPES.SUCCESS}
          onClose={onClose}
        />
      );
      break;
    case 'Failure':
      notificationContent = (
        <NotificationItem
          labelKey="staffCalendar.shiftSwap.labels.shiftSwapRequestFailed"
          message="Error raising shift swap request."
          type={NOTIFICATION_TYPES.FAILURE}
          onClose={onClose}
        />
      );
      break;
    case 'Warning':
      notificationContent = (
        <NotificationItem
          labelKey="staffCalendar.shiftSwap.labels.shiftSwapRequestWarning"
          message="Shift swap request already exists."
          type={NOTIFICATION_TYPES.WARNING}
          onClose={onClose}
        />
      );
      break;
    default:
      notificationContent = null;
  }

  return <div className="notification-container">{notificationContent}</div>;
}

export default NotificationCard
