import { useEffect, useRef } from 'react';
import { t } from 'i18n';

interface INotificationItem {
  labelKey: string,
  message: string,
  type: string,
  onClose?: () => void
}

export const NotificationItem = ({ labelKey, message, type, onClose }: INotificationItem) => {
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleNotificationClose = () => {
      if (onClose) {
        onClose();
      }
    };

    const notificationElement: any = notificationRef.current;
    if (notificationElement) {
      notificationElement.addEventListener('close', handleNotificationClose);
    }

    return () => {
      if (notificationElement) {
        notificationElement.removeEventListener('close', handleNotificationClose);
      }
    };
  }, [onClose]);

  return (
    <sh-wrapper direction="row" spacing="m">
      <sh-notification-item
        ref={notificationRef}
        visible
        label={t(labelKey)}
        type={type}
        duration="long"
      >
        {message}
      </sh-notification-item>
    </sh-wrapper>
  );
}