import { debounce } from 'lodash'
import { Map } from 'immutable'
import { findParentElement } from 'utils'
import StateController from './StateController'

export default class EventController extends StateController {
  updateScrollTop = (scrollTop) => {
    this.setState({ scrollTop })
  }

  handleScroll = () => {
    if (this.component.crossHatchRef.current) {
      this.component.crossHatchRef.current.hideHorizontal()
    }
    this.component.tooltipController.hideTooltip()
    this._handleScrollWithDelay()
  }

  _handleScrollWithDelay = debounce(() => {
    const { scrollLeft } = this.state
    const newScrollLeft = window.scrollX
    const isScrollLeftChanged = scrollLeft !== newScrollLeft

    if (isScrollLeftChanged) {
      this.setState({ scrollLeft: newScrollLeft }, () => {
        this.component.crossHatchController.updateCrossHatchStyle()
      })
    }
  }, 100)

  onResize = () => {
    setTimeout(() => this.component.crossHatchController.updateCrossHatchStyle(), 50)

    this.component.updateGridDimensions()
  }

  onClickOutsideTheGrid = (event) => {
    const targetElement = event.target
    if (!targetElement) {
      return
    }

    const isTimeOffRequestClicked = !!findParentElement(targetElement, 'classList', 'hx-time-off-request')
    const isTimeOffCellClicked = !!findParentElement(targetElement, 'classList', 'paidTimeOff')
    const isUnavailabilityCliecked = !!findParentElement(targetElement, 'classList', 'unavailability')
    const isPopupClicked = !!findParentElement(targetElement, 'classList', 'popup')

    if (!isTimeOffCellClicked && !isTimeOffRequestClicked && !isPopupClicked && !isUnavailabilityCliecked) {
      this.component.popupController.hidePopup()
    }
  }

  onMouseEnterTimeOffRequestDetails = () => {
    this._resetHideTimeOffRequestDetailsTimer()
  }

  onMouseLeaveTimeOffRequestDetails = () => {
    this._hideTimeOffRequestDetails()
  }

  onTimeOffRequestEvent = (staffPath, timeOffRequestId, eventType, meta) => {
    switch (eventType) {
      case 'onMouseEnter':
        return this._onTimeOffRequestMouseEnter(staffPath, timeOffRequestId, meta)
      case 'onMouseLeave':
        return this._onTimeOffRequestMouseLeave(staffPath, timeOffRequestId, meta)
      default:
        throw new Error('time off request was invoked with unknown eventType: ' + eventType)
    }
  }

  _onTimeOffRequestMouseEnter(staffPath, timeOffRequestId, meta) {
    this.component.tooltipController.hideTooltip()
    this._resetHideTimeOffRequestDetailsTimer()

    this.component.popupController.switchTo('TimeOffRequestDetails', () => {
      this.component.multiselectController.selectTimeOffRequest(staffPath, timeOffRequestId)
    })
  }

  _onTimeOffRequestMouseLeave(staffPath, timeOffRequestId, meta) {
    this._hideTimeOffRequestDetails()
  }

  _resetHideTimeOffRequestDetailsTimer = () => {
    if (this.hideTimeOffRequestDetailsTimer) {
      clearTimeout(this.hideTimeOffRequestDetailsTimer)
      this.hideTimeOffRequestDetailsTimer = null
    }
  }

  _hideTimeOffRequestDetails = () => {
    this._resetHideTimeOffRequestDetailsTimer()
    this.component.tooltipController.hideTooltip()
    this.hideTimeOffRequestDetailsTimer = setTimeout(() => {
      this.component.popupController.hidePopup()
    }, 100)
  }

  onCellEvent = (staffPath, cellIndex, eventType, meta) => {
    const cell = this.timeOffCalendar.getCell({ ...staffPath, cellIndex })

    switch (eventType) {
      case 'onClick':
        return this._onCellClick(staffPath, cell, meta)
      case 'onContextMenu':
        return this._onCellContextMenu(staffPath, cell, meta)
      case 'onMouseOver':
        return this._onCellMouseOver(staffPath, cell, meta)
      case 'onMouseLeave':
        return this._onCellMouseLeave(cell, meta)
      default:
        throw new Error('cell event was invoked with unknown eventType: ' + eventType)
    }
  }

  _onCellClick(staffPath, cell, meta) {
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const timeOff = cellStaffEvent.get('timeOff')
    if (timeOff) {
      const { eventInfo } = meta
      this.component.multiselectController.handleClick(staffPath, cell, eventInfo)
      this.component.popupController.switchTo('TimeOffDetails')
    } else {
      this.component.popupController.hidePopup()
    }
  }

  _onCellContextMenu(staffPath, cell, meta) {
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const timeOff = cellStaffEvent.get('timeOff')
    if (timeOff) {
      this.component.multiselectController.selectSingleCell(staffPath, cell)
      this.component.popupController.switchTo('ContextMenu')
    }
  }

  _onCellMouseOver(staffPath, cell, meta) {
    const { eventInfo } = meta
    const { targetElement } = eventInfo

    this.component.tooltipController.showTooltip(staffPath, cell, targetElement)
  }

  _onCellMouseLeave(cell, meta) {
    this.component.tooltipController.hideTooltip()
  }
}
