import { Component } from 'react'

import EquipmentFormController from './EquipmentFormController'
import CreateEditExpertiseModal from '../CreateEditExpertiseModal'

class CreateEditEquipmentModal extends Component {
  render() {
    const modalProps = {
      ...this.props,
      editPathKey: 'StaffEditEquipment',
      createPathKey: 'StaffCreateEquipment',
      expertiseType: 'equipment',
      FormController: EquipmentFormController,
      extenderLabel: 'Add equipment skill'
    }

    return <CreateEditExpertiseModal {...modalProps} />
  }
}

export default CreateEditEquipmentModal
