import { Component } from 'react'
import queryString from 'query-string'
import Form from '@humanics/he-react-common/lib/admin/components/Form'
import { Modal } from 'Common/components'
import classNames from 'classnames'
import { merge } from 'lodash'
import ExpertisesController from './ExpertiseController'

export default class CertificationCreate extends Component {
  constructor(props) {
    super(props)

    this.controller = new ExpertisesController(this)
    this.state = { saving: false, visible: true, error: '' }
  }

  onClose = () => {
    this.setState({ visible: false })
    this.props.history.push('.')
  }

  componentDidMount() {
    ;(async () => {
      const { store, type } = this.props
      await store.initializeApi()
      await this.controller.expertiseGroupStore.getApi()
      this.controller.loadData(type)
      this.setState({ apiInitialized: true })
    })()
  }

  componentDidUpdate(prevProps) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { stateKey: stateGroupKey } = this.controller.expertiseGroupStore
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const storeGroupState = appState.get(stateGroupKey)
    const prevStoreState = prevAppState.get(stateKey)
    const prevStoreGroupState = prevAppState.get(stateGroupKey)
    const isStoreStateChanged = storeState !== prevStoreState || storeGroupState !== prevStoreGroupState

    if (isStoreStateChanged) {
      const data = this.controller.getExpertises()
      this.setState({ data })
    }
  }

  onFormSubmit = async (event, form) => {
    const {
      match,
      location: { search: url },
      type,
      navigateHome
    } = this.props
    const { groupId } = queryString.parse(url)
    const { urlId } = match.params
    if (groupId) {
      await this.controller.createExpertise(form, type)
    } else {
      await this.controller.createExpertiseGroup(form, type)
    }
    this.controller.loadData(type)
    return navigateHome({ urlId })
  }

  render() {
    const { isExpertisesLoaded } = this.controller
    if (!isExpertisesLoaded) {
      return null
    }
    const {
      store,
      stores,
      history,
      appState,
      location: { search: url },
      entityExpertise,
      entityExpertiseGroup,
      titleExpertise,
      titleExpertiseGroup
    } = this.props
    const { error, visible, loading } = this.state
    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }
    const { groupId } = queryString.parse(url)
    let inputDefinition = groupId ? store.getInputDefinition('Expertise') : store.getInputDefinition('ExpertiseGroup')
    const entityType = groupId ? entityExpertise : entityExpertiseGroup
    const title = groupId ? `Create ${titleExpertise}` : `Create ${titleExpertiseGroup}`
    let defaultValues = {}
    if (groupId) {
      const { properties } = inputDefinition
      const inputDefinitionExtraFields = {
        groupId: {
          enum: this.controller.expertiseGroups,
          required: true
        },
        name: {
          required: true
        }
      }
      inputDefinition = { properties: merge({}, properties, inputDefinitionExtraFields) }
      defaultValues = { ...defaultValues, groupId }
    }
    return (
      <Modal visible={visible} onClose={this.onClose} title={title}>
        <div>
          <section className="modal-form">
            <div
              className={classNames('message error', {
                hide: !error
              })}
            >
              <i className="icon-error" />
              <span>{error}</span>
              <span
                className="close"
                onClick={(e) => {
                  this.setState({ error: '' })
                }}
              />
            </div>
            <div>
              {loading ? (
                <div>Loading...</div>
              ) : (
                <Form
                  onSubmit={this.onFormSubmit}
                  entityType={entityType}
                  inputDefinition={inputDefinition}
                  stores={stores}
                  defaultValues={defaultValues}
                  history={history}
                  appState={appState}
                />
              )}
            </div>
          </section>
        </div>
      </Modal>
    )
  }
}
