import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { USER_EVENTS } from '../Common/constants/UserEvents'

interface ICustomProperties {
  [key: string]: any
}

class LoggingService {
  private appInsights!: ApplicationInsights

  constructor() {
    const instrumentationKey: string = (window as any).APP_INSIGHTS_INSTRUMENTATION_KEY

    if (
      !instrumentationKey ||
      instrumentationKey === 'undefined' ||
      instrumentationKey === '%REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY%'
    ) {
      console.warn('Connection string is missing or empty')
      return
    }

    this.appInsights = new ApplicationInsights({ config: { instrumentationKey } })
    this.appInsights.loadAppInsights()
    this.appInsights.trackPageView()
  }

  logUserAction(name: USER_EVENTS, properties?: ICustomProperties) {
    if (this.appInsights) {
      this.appInsights.trackEvent({ name, properties })
    }

    // Uncomment if you want logs in local
    // else {
    //   console.info('LOG EVENT:', 'Name:', name, '& Properties:', properties)
    // }
  }
}

const loggingService = new LoggingService()
export default loggingService
