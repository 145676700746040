import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import { t } from 'i18n'
import classNames from 'classnames'

export class UpdateTemplateButton extends PureComponent {
  state = {
    isSpinnerShown: false
  }

  render() {
    const config = {
      title: t('Update Template'),
      confirmation: {
        message: t('announcements.confirmations.update_template.disclosure'),
        options: {
          title: t('Would you like to update template "' + this.props.loadedTemplate + '"?'),
          accept: t('announcements.confirmations.update_template.options.accept'),
          cancel: t('announcements.confirmations.update_template.options.cancel'),
          type: 'warning'
        }
      },
      isConfirmationRequired: true
    }

    const { isSpinnerShown } = this.state
    const { Dialog } = this.props

    const { confirmation, isConfirmationRequired } = config

    const onClick = async (e) => {
      if (isConfirmationRequired) {
        const response = await Dialog.confirm(confirmation.message, confirmation.options)
        if (!response) {
          //"Cancel"
          return
        }
      }

      // "Yes, Update Template"
      this.updateAnnouncementTemplate()
    }

    return (
      <>
        <button
          className={classNames({
            button: true,
            loading: isSpinnerShown
          })}
          onClick={onClick}
        >
          <i className="icon-plus pt10" />
          Update Template
        </button>
      </>
    )
  }

  updateAnnouncementTemplate = async () => {
    const {
      appState,
      loadedTemplate,
      formatDetailsComponents,
      reloadTemplatesList,
      announcementsStore,
      payload: formData
    } = this.props

    const userTemplates = appState.getIn(['announcementTemplates', 'items'])
    const currentTemplate = userTemplates.find((template) => template.name === loadedTemplate)
    this.setState({ isSpinnerShown: true })
    const { hasDetailsPage, detailsComponents, ...payload } = formData
    payload.detailsComponents = hasDetailsPage ? formatDetailsComponents(detailsComponents) : null

    announcementsStore.updateAnnouncementTemplate(currentTemplate.id, payload)
    await reloadTemplatesList()
    this.setState({ isSpinnerShown: false })
  }
}

export default withAppContext(UpdateTemplateButton)
