import { createRef, Component } from 'react'
import { isEmpty } from 'lodash'
import { t } from 'i18n'
import { withAppContext } from 'AppContext'
import { navigate } from 'Navigation'
import { addSuccessBanner } from 'stores'
import WarningHint from 'Manager/OpenShifts/OpenShift/Header/WarningHint.js'

const AVAILABLE_ACTIONS = {
  draft: ['edit', 'duplicate', 'delete'],
  scheduled: ['edit', 'duplicate'],
  sent: ['duplicate']
}

const DATETIME_FORMATS = {
  startsEnds: 'MMM DD, HH:mm',
  createdUpdated: 'dddd, MMM DD, YYYY, HH:mm'
}

const configDelete = {
  confirmation: {
    message: t('announcements.confirmations.delete_announcement.disclosure'),
    options: {
      title: t('announcements.confirmations.delete_announcement.title'),
      accept: t('announcements.confirmations.delete_announcement.options.accept'),
      cancel: t('announcements.confirmations.delete_announcement.options.cancel'),
      type: 'warning'
    }
  },
  isConfirmationRequired: true
}

class Metadata extends Component {
  constructor(props) {
    super(props)
    this.state = { isMouseHover: false }
    this.iconsPaneRef = createRef()
  }
  // Reformats the Date UI
  formatDate = (dateTimeString, dateTimeFormat) => {
    if (!dateTimeString) {
      return '--'
    }
    const { timeService } = this.props
    return timeService.timeMoment(dateTimeString).format(DATETIME_FORMATS[dateTimeFormat])
  }

  // Formats the recipients field text
  formatRecipients = (worksAt, shiftTypes) => {
    if (!worksAt && isEmpty(shiftTypes)) {
      return '--'
    }
    switch (true) {
      case !!worksAt: {
        const { timeService } = this.props
        const worksAtFormatted = timeService.timeMoment(worksAt).format(DATETIME_FORMATS['startsEnds'])
        return `Working at ${worksAtFormatted}`
      }
      case shiftTypes.length === 2:
        return 'All Staff'
      case shiftTypes.length === 1 && shiftTypes[0] === 'DAY':
        return 'Day Shift Staff'
      case shiftTypes.length === 1 && shiftTypes[0] === 'NIGHT':
        return 'NOC Shift Staff'
      default:
        return '--'
    }
  }

  onRetry = (e) => {
    const { announcementsStore } = this.props
    announcementsStore.setAnnouncementsRetryOptions(true)
    this.onEdit()
  }

  onEdit = async (e) => {
    const { announcementId, announcementsStore, status } = this.props

    if (status === 'draft') {
      return navigate.from.Announcements.to.AnnouncementsEdit({ announcementId })
    }

    // "Recall and then go to edit"
    const canceledAnnouncement = await announcementsStore.updateAnnouncement(announcementId, {
      status: 'draft'
    })
    if (!('error' in canceledAnnouncement)) {
      addSuccessBanner({ message: t('announcements.banners.announcement.cancel_success') })
    }

    navigate.from.Announcements.to.AnnouncementsEdit({ announcementId })
  }

  onDuplicate = async (e) => {
    const { announcementId, announcementsStore } = this.props
    await announcementsStore.getAnnouncement(announcementId)
    navigate.from.Announcements.to.AnnouncementsCreate({})
  }

  onDelete = async () => {
    const { announcementId, announcementsStore, Dialog, loadAnnouncements, refreshNotifications } = this.props
    const {
      isConfirmationRequired,
      confirmation: { message, options }
    } = configDelete

    if (isConfirmationRequired) {
      const response = await Dialog.confirm(message, options)
      if (!response) {
        // "Cancel"
        return
      }
    }

    // "Yes, Delete"
    const deletedAnnouncement = await announcementsStore.deleteAnnouncement(announcementId)
    if (!('error' in deletedAnnouncement)) {
      addSuccessBanner({ message: t('announcements.banners.announcement.delete_success') })
      refreshNotifications()
    }

    if (!loadAnnouncements) {
      return navigate.from.FacilityUnitHome.to.Announcements({})
    }

    loadAnnouncements()
  }

  announcementFailedToSend(startTime, status) {
    const { timeService } = this.props
    const now = timeService.trueNow()
    const dateToSendAnnouncement = timeService.timeMoment(startTime)
    const announcementStartTimeAlreadyPast = startTime && dateToSendAnnouncement.isBefore(now)
    return announcementStartTimeAlreadyPast && status === 'scheduled'
  }

  render() {
    const { status, startsAt, endsAt, createdAt, updatedAt, author, worksAt, shiftTypes } = this.props

    const { firstName, lastName } = author
    const updatedAtFormatted = this.formatDate(updatedAt, 'createdUpdated')
    const startsAtFormatted = this.formatDate(startsAt, 'startsEnds')
    const endsAtFormatted = this.formatDate(endsAt, 'startsEnds')
    const recipients = this.formatRecipients(worksAt, shiftTypes)
    const customTooltipStyle = {
      color: 'red',
      top: '30px',
      right: this.state.tooltipRight,
      minWidth: '40px',
      textAlign: 'center'
    }

    const ACTION_HANDLERS = {
      edit: this.onEdit,
      duplicate: this.onDuplicate,
      delete: this.onDelete
    }

    const announcementFailedToSend = this.announcementFailedToSend(startsAt, status)

    return (
      <div className="metadata-container">
        <div className="metadata-details">
          {announcementFailedToSend && (
            <button className="retry-announcement" onClick={this.onRetry}>
              Retry
            </button>
          )}
          <div className="announcement-header row">
            <div data-testid="announcement-status" className="announcement-status row">
              {announcementFailedToSend ? (
                <>
                  <i className={'danger-icon'} />
                  <div> Send failure </div>
                </>
              ) : (
                <>
                  <i className={`${status}-icon`} />
                  <div> {status} </div>
                </>
              )}
            </div>
            <div ref={this.iconsPaneRef} className="icons-pane row">
              <WarningHint
                customStyle={customTooltipStyle}
                text={this.state.isMouseHover}
                parentRef={this.iconsPaneRef}
                visible={this.state.isMouseHover}
              />
              {AVAILABLE_ACTIONS[status].map((action) => (
                <Icon
                  onMouseEnter={(e) => {
                    // align the floating tooltip card with the right side of the icon (HMN-4169)
                    const iconsContainer = e.target.parentNode.getBoundingClientRect()
                    const icon = e.target.getBoundingClientRect()
                    const tooltipRight = iconsContainer.right - icon.right + 'px'

                    this.setState({ isMouseHover: action, tooltipRight })
                  }}
                  onMouseLeave={() => this.setState({ isMouseHover: false })}
                  key={action}
                  action={action}
                  onClick={ACTION_HANDLERS[action]}
                />
              ))}
              {announcementFailedToSend && status === 'scheduled' && (
                <Icon
                  onMouseEnter={(e) => {
                    // align the floating tooltip card with the right side of the icon (HMN-4169)
                    const iconsContainer = e.target.parentNode.getBoundingClientRect()
                    const icon = e.target.getBoundingClientRect()
                    const tooltipRight = iconsContainer.right - icon.right + 'px'

                    this.setState({ isMouseHover: 'delete', tooltipRight })
                  }}
                  onMouseLeave={() => this.setState({ isMouseHover: false })}
                  key={'delete'}
                  action={'delete'}
                  onClick={ACTION_HANDLERS['delete']}
                />
              )}
            </div>
          </div>
          <div className="announcement-start-date">
            Start: <b>{startsAtFormatted && !announcementFailedToSend ? startsAtFormatted : '--'}</b>
          </div>
          <div className="announcement-end-date">
            End: <b>{endsAtFormatted && !announcementFailedToSend ? endsAtFormatted : '--'}</b>
          </div>
          <div className="announcement-recipient">
            {!announcementFailedToSend && (
              <>
                Recipients: <b>{recipients}</b>
              </>
            )}
          </div>
          <div className="announcement-last-edited">
            {createdAt === updatedAt ? 'Created' : 'Edited'} <b>{updatedAtFormatted}</b>
          </div>
          <div className="announcement-last-edited-by">
            by <b>{`${firstName[0]}. ${lastName}`}</b>
          </div>
        </div>
      </div>
    )
  }
}

const Icon = ({ action, onClick, onMouseEnter, onMouseLeave }) => (
  <i onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} className={action + '-icon'} onClick={onClick} />
)

export default withAppContext(Metadata)
