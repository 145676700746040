import { navigate } from 'Navigation'
import { createRef, PureComponent } from 'react'

export class ReadmeOSS extends PureComponent {
  onClose(e) {
    e.preventDefault()
    navigate.clearHash()
  }

  constructor(props) {
    super(props)
    this.modalRef = createRef()
  }

  componentDidMount() {
    const modalElement = this.modalRef.current
    if (modalElement) {
      modalElement.addEventListener('modal-closed', this.onClose)
    }
  }

  render() {
    return (
      <sh-modal
        visible={true}
        ref={this.modalRef}
        label={'Readme.oss for CrewPlace Enterprise'}
        modal-height="calc(100% - 48px)"
        modal-width="calc(100% - 48px)"
        style={{ zIndex: 100001 }}
      >
        <div style={{ height: 'calc(100% - 8px)', width: '100%' }}>
          <iframe src="/readme.oss.html" title="Readme OSS" height="100%" width="100%"></iframe>
        </div>
      </sh-modal>
    )
  }
}

export default ReadmeOSS
