import { ComponentController } from 'Common/components'
import stores from 'stores'

const { staffShiftSwapsStore, staffCalendarStore }: any = stores
export default class DataController extends ComponentController {
  async acceptShiftSwapRequest(id: string, userId: string, shiftDayId: string, monthStartsAt: string) {
    const staffEventId = await staffCalendarStore.getStaffEventIdForShiftDay(shiftDayId)
    await staffShiftSwapsStore.acceptShiftSwapRequest(id, userId, staffEventId, monthStartsAt)
  }

  async declineShiftSwapRequest(id: string, userId: string, monthStartsAt: string) {
    const parameters = { id: id }
    await staffShiftSwapsStore.declineShiftSwapRequest(parameters, userId, monthStartsAt)
  }

  async cancelShiftSwapRequest(id: string, userId: string, monthStartsAt: string) {
    const parameters = { id: id }
    await staffShiftSwapsStore.cancelShiftSwapRequest(parameters, userId, monthStartsAt)
  }

  async createShiftSwapRequest(staffEventId: string, shiftDayIdsToSwapFor: string[], userId: string, monthStartsAt: string, loggedInUserId: string) {
    await staffShiftSwapsStore.createShiftSwapRequest(staffEventId, shiftDayIdsToSwapFor, userId, monthStartsAt, loggedInUserId)
  }

  async loadShiftDaysAvailableToSwapFor(staffEventId: string) {
    await staffShiftSwapsStore.loadShiftDaysAvailableToSwapFor(staffEventId)
  }

  async loadStaffAvailableForSwap(staffEventId: string, shiftDayIdsForSwap: string[]) {
    await staffShiftSwapsStore.loadStaffAvailableForSwap(staffEventId, shiftDayIdsForSwap)
  }
}
