import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { fromJS, List, Map } from 'immutable'
import { keyBy } from '../../utils'
import { flatten } from 'lodash'
import { shiftSwapRequestsQuery, staffAssignedWeekHoursQuery } from './Queries'
import { confirmShiftSwapRequestMutation, denyShiftSwapRequestMutation } from './Mutations'

function ShiftSwapsStore() {
  let instanceUri = null
  let gqlClient = null // eslint-disable-line
  let timeService = null

  const actions = fluxStore({
    loadShiftSwaps,
    setShiftSwapDialog,
    confirmShiftSwapRequest,
    denyShiftSwapRequest,
    resetModalData
  })

  const defaultState = {
    modalData: null,
    shiftSwapsState: fromJS({
      shiftSwaps: [],
      groups: []
    })
  }

  return {
    initialize,
    ...actions,
    getShiftSwaps
  }

  function initialize(state, context) {
    ;({ instanceUri, facilityTime: timeService, gqlClient } = context)
    return state.merge(defaultState)
  }

  function resetModalData(state) {
    return state.set('modalData', null)
  }

  async function loadShiftSwaps(state, unitId) {
    const map = (mapper) => (list) => List(list.map(mapper))

    let shiftSwaps = await getShiftSwaps(unitId)

    const allRoleIds = shiftSwaps.map((shift) => shift.unitRoleId)
    const roleIds = [...new Set(allRoleIds)]

    shiftSwaps = map((shiftSwap) => mapShiftSwap(timeService, instanceUri, shiftSwap))(shiftSwaps)

    shiftSwaps = mapWeeks(shiftSwaps)
    shiftSwaps = await modifyScheduledHours(shiftSwaps)

    return state
      .setIn(['shiftSwapsState', 'shiftSwaps'], shiftSwaps)
      .setIn(['shiftSwapsState', 'roleIds'], fromJS(roleIds))
  }

  async function getShiftSwaps(unitId) {
    const firstStartsAt = timeService.timeMoment(null).toISOString()
    const lastEndsAt = timeService.timeMoment(firstStartsAt).add(10, 'years').toISOString()

    const parameters = {
      unitId,
      firstStartsAt,
      lastEndsAt,
      limit: 999
    }

    const { shiftSwapRequests } = await gqlClient.query(shiftSwapRequestsQuery, parameters)
    return shiftSwapRequests
  }

  function mapWeeks(shiftSwaps) {
    return shiftSwaps
      .groupBy((shiftSwap) => shiftSwap.get('weekYear'))
      .sortBy((value, key) => key)
      .map((shiftSwaps, weekYear) => {
        const from = timeService.timeMoment(weekYear, 'YYYY-w').startOf('week')
        const to = timeService.timeMoment(weekYear, 'YYYY-w').endOf('week')
        const weekRange = `${from.format('MMM D')} - ${to.format('MMM D')}`
        const nextWeek = timeService.timeMoment(null).add(1, 'week').format('YYYY-w')
        const isThisWeek = weekYear === timeService.timeMoment(null).format('YYYY-w')
        const isNextWeek = weekYear === nextWeek

        return Map({ weekRange, isThisWeek, isNextWeek, shiftSwaps })
      })
  }

  async function modifyScheduledHours(shiftSwapsGroups) {
    for (const [weekYear, shiftSwapsGroup] of shiftSwapsGroups) {
      let shiftSwaps = shiftSwapsGroup.get('shiftSwaps')
      const from = timeService.timeMoment(weekYear, 'YYYY-w').startOf('week')
      const userIds = shiftSwaps
        .map((shiftSwap) => [
          shiftSwap.get('initiatorStaffProfile').userId,
          shiftSwap.get('recipientStaffProfile').userId
        ])
        .toJS()

      const date = from.toISOString()

      const staffAssignedHours = await getStaffAssignedHours(date, flatten(userIds))

      shiftSwaps = shiftSwaps.reduce((memo, shiftSwap) => {
        shiftSwap = shiftSwap
          .update('initiatorStaffProfile', (profile) => {
            profile.scheduledHours = staffAssignedHours.get(profile.userId)
            return profile
          })
          .update('recipientStaffProfile', (profile) => {
            profile.scheduledHours = staffAssignedHours.get(profile.userId)
            return profile
          })
        return memo.push(shiftSwap)
      }, List())
    }

    return shiftSwapsGroups
  }

  function mapShiftSwap(time, instanceUri, shiftSwap) {
    const avatar = (avatarUrl) => {
      if (!avatarUrl) {
        return ''
      }

      return avatarUrl.startsWith('http') ? avatarUrl : `${instanceUri}/${avatarUrl}`
    }
    const {
      initiatorStaffProfile: initiator,
      recipientStaffProfile: recipient,
      shiftDay,
      date,
      acceptedShiftDay,
      shiftDaysToSwapFor
    } = shiftSwap
    let recipientWeekDays = [],
      recipientDays = []
    const { weekDay: initiatorWeekDay, day: initiatorDay } = getDateInfo(shiftDay)
    if (acceptedShiftDay) {
      const { weekDay, day } = getDateInfo(acceptedShiftDay)
      recipientWeekDays.push(weekDay)
      recipientDays.push(day)
    } else {
      for (const shiftDayToSwapFor of shiftDaysToSwapFor) {
        const { weekDay, day } = getDateInfo(shiftDayToSwapFor)
        recipientWeekDays.push(weekDay)
        recipientDays.push(day)
      }
    }
    const soonestShiftDateMoment = getSoonestShiftDate(date, shiftDaysToSwapFor)
    return Map({
      ...shiftSwap,
      ...getDateInfo(shiftDay),
      ...{ recipientWeekDays, recipientDays },
      ...{ initiatorWeekDay, initiatorDay },
      acceptedDateMoment: timeService.timeMoment(shiftSwap.acceptedDate),
      soonestShiftDateMoment,
      initiatorAvatarUrl: avatar(initiator.userProfileAvatarUrl),
      recipientAvatarUrl: avatar(recipient.userProfileAvatarUrl),
      kind: 'shift-swap'
    })
  }

  function sortDatesArray(datesArray) {
    return datesArray.sort((a, b) => a.diff(b))
  }
  //Move this array "sort" operation to a separate statement.
  function getSoonestShiftDate(date, shiftDaysToSwapFor) {
    let resultDatesArray = [timeService.timeMoment(date)]
    for (const shiftDayToSwapFor of shiftDaysToSwapFor) {
      const { startsAt } = shiftDayToSwapFor
      resultDatesArray.push(timeService.timeMoment(startsAt))
    }
    const sortedArrayResult = sortDatesArray(resultDatesArray)

    return sortedArrayResult[0]
  }

  function getDateInfo({ startsAt, endsAt }) {
    const start = timeService.timeMoment(startsAt)
    const end = timeService.timeMoment(endsAt)

    return {
      usDate: start.format('MM-DD-YYYY'),
      weekYear: start.format('YYYY-w'),
      date: start.format('ddd D'),
      weekDay: start.format('ddd'),
      day: start.format('D'),
      time: `${start.format('H:mm')} - ${end.format('H:mm')}`
    }
  }

  function setShiftSwapDialog(state, data) {
    return state.set('modalData', data)
  }

  async function confirmShiftSwapRequest(state, { id, text }) {
    let note

    if (text !== '') {
      note = {
        title: 'confirmShiftSwapRequest',
        subject: '',
        text
      }
    }

    const params = { id, parameters: { note } }

    await gqlClient.mutate(confirmShiftSwapRequestMutation, params)

    const shiftSwaps = state.getIn(['shiftSwapsState', 'shiftSwaps']).map((group, weekYear) => {
      return group.update('shiftSwaps', (shifts) => shifts.filter((s) => s.get('id') !== id))
    })

    return state.set('modalData', null).setIn(['shiftSwapsState', 'shiftSwaps'], shiftSwaps)
  }

  async function denyShiftSwapRequest(state, { id, text }) {
    let note

    if (text !== '') {
      note = {
        title: 'denyShiftSwapRequest',
        subject: '',
        text
      }
    }

    const params = { id, parameters: { note } }

    await gqlClient.mutate(denyShiftSwapRequestMutation, params)

    const shiftSwaps = state
      .getIn(['shiftSwapsState', 'shiftSwaps'])
      .map((group, weekYear) => {
        return group.update('shiftSwaps', (shifts) => shifts.filter((s) => s.get('id') !== id))
      })
      .filter((group) => group.get('shiftSwaps').size > 0)

    return state.set('modalData', null).setIn(['shiftSwapsState', 'shiftSwaps'], shiftSwaps)
  }

  async function getStaffAssignedHours(date, userIds) {
    const { staffAssignedWeekHours } = await gqlClient.query(staffAssignedWeekHoursQuery, {
      date,
      userIds
    })
    const staffAssignedHours = fromJS(staffAssignedWeekHours)

    return keyBy(staffAssignedHours, 'userId').map((o) => o.get('assignedHours'))
  }
}

export default ShiftSwapsStore
