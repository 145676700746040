import { keyBy } from 'utils'
import { List } from 'immutable'

import extendOpenShiftWithFacilityUsers from './extendOpenShiftWithFacilityUsers'

export default class OpenShiftsService {
  constructor(timeService) {
    this.timeService = timeService
  }

  extendOpenShifts(shiftsById, openShifts, extensionParameters, groups) {
    return openShifts
      .map((openShift) => this.extendOpenShift(shiftsById, openShift, extensionParameters, groups))
      .sortBy((openShift) => openShift.get('shiftStartsAt'))
  }

  extendOpenShift(shiftsById, openShift, extensionParameters, groups) {
    openShift = this.extendOpenShiftWithBasicData(shiftsById, openShift)
    openShift = this.extendOpenShiftWithDateTimeDetails(openShift)
    openShift = this.extendOpenShiftWithStaffDetails(openShift, extensionParameters)
    if (groups) {
      openShift = this.extendOpenShiftsWithGroups(openShift, groups)
    }

    return openShift
  }

  extendOpenShiftWithBasicData(shiftsById, openShift) {
    const shiftId = openShift.get('shiftId')
    const generalShift = shiftsById.get(shiftId)
    const expertiseRequirements = generalShift ? generalShift.first().get('expertiseRequirements') : []
    const isPosted = !!openShift.get('id')
    const isBonusPay = openShift.get('incentiveBonus')
    const isCrisisPay = openShift.get('crisisPay')
    const groupId = openShift.get('groupId')
    const groupShortName = openShift.get('groupShortName')
    const note = openShift.getIn(['note', 'text'])

    return openShift.merge({
      isPosted,
      isBonusPay,
      isCrisisPay,
      note,
      groupId,
      groupShortName,
      expertiseRequirements
    })
  }

  extendOpenShiftsWithGroups(openShift, groups) {
    const group = groups.filter((g) => openShift.get('groupId') === g.get('id'))
    if (group) {
      return openShift.merge({ groupName: group.getIn([0, 'name']) })
    }

    return openShift
  }

  extendOpenShiftWithDateTimeDetails(openShift) {
    const now = this.timeService.timeMoment(null)
    const today = now.clone().startOf('day').toISOString()
    const currentWeekStartsAt = now.clone().startOf('week').toISOString()

    const shiftStartsAt = openShift.get('shiftStartsAt')
    const shiftEndsAt = openShift.get('shiftEndsAt')
    const createdAt = openShift.get('createdAt')
    const startsAtMoment = this.timeService.timeMoment(shiftStartsAt)
    const endsAtMoment = this.timeService.timeMoment(shiftEndsAt)
    const weekStartsAt = startsAtMoment.clone().startOf('week')
    const weekEndsAt = startsAtMoment.clone().endOf('week')
    const isToday = startsAtMoment.diff(today, 'days') === 0
    const isTomorrow = startsAtMoment.diff(today, 'days') === 1
    const humanizedTime = this.humanizeMoment(startsAtMoment)
    const weekFromNow = weekStartsAt.diff(currentWeekStartsAt, 'weeks')
    const duration = endsAtMoment.diff(shiftStartsAt, 'minutes')
    const date = startsAtMoment.format('ddd, MMM D')
    const startsAtDate = startsAtMoment.format('MMM D, ddd')
    const dayIndexInWeek = startsAtMoment.format('d')
    const time = shiftStartsAt && shiftEndsAt ? `${startsAtMoment.format('H:mm')} - ${endsAtMoment.format('H:mm')}` : ''
    const weekDay = startsAtMoment.format('ddd')
    const day = startsAtMoment.format('D')
    const month = startsAtMoment.format('MMM')
    const weekDayNameDayMonth = `${weekDay} ${day} ${month}`
    const createdAtMoment = createdAt ? this.timeService.timeMoment(createdAt) : null
    const createdAtFormatted = createdAtMoment ? createdAtMoment.format('MMM D') : ''
    const createdAtTime = createdAtMoment ? createdAtMoment.format('h:mm') : ''
    const createdAtDateTime = createdAtMoment ? createdAtMoment.format('MMM D, h:mm') : ''
    const daysSinceCreation = createdAtMoment ? createdAtMoment.diff(today, 'days') : -1
    const updatedAt = openShift.get('updatedAt')
    const updatedAtMoment = updatedAt ? this.timeService.timeMoment(updatedAt) : null
    const updatedAtFormatted = updatedAtMoment ? updatedAtMoment.format('MMM D') : null
    const postedDaysAgo = createdAt ? now.diff(createdAt, 'days') : 0
    const isPastOpenShift = now.isAfter(shiftStartsAt)
    const weekRange = `${weekStartsAt.format('MMM D')} - ${weekEndsAt.format('MMM D')}`

    return openShift.merge({
      day,
      date,
      startsAtDate,
      time,
      weekDay,
      weekDayNameDayMonth,
      duration,
      daysSinceCreation,
      weekRange,
      humanizedTime,
      weekFromNow,
      isToday,
      isTomorrow,
      postedDaysAgo,
      dayIndexInWeek,
      isPastOpenShift,
      createdAtFormatted,
      createdAtTime,
      createdAtDateTime,
      updatedAtFormatted
    })
  }

  humanizeMoment(moment) {
    let humanized = null

    if (!moment?.isValid()) {
      return
    }

    const split_afternoon = 12
    const currentHour = parseFloat(moment.format('HH'))

    if (currentHour >= split_afternoon) {
      humanized = 'Night'
    } else {
      humanized = 'Day'
    }

    return humanized
  }

  extendOpenShiftWithStaffDetails(openShift, extensionParameters) {
    if (!extensionParameters) {
      return openShift
    }

    const { facilityUsersMap, ...rest } = extensionParameters

    openShift = extendOpenShiftWithFacilityUsers(openShift, extensionParameters)

    return openShift.merge({ ...rest, withStaffDetails: true })
  }

  getStaffAssignedWeekHoursMap(eligibleFacilityUsers) {
    const facilityUsersByUserId = keyBy(eligibleFacilityUsers, 'userId')

    return facilityUsersByUserId.map((facilityUser) => facilityUser.getIn(['staffProfile', 'weekHours']))
  }

  groupOpenShiftsByWeeks(openShifts) {
    return openShifts.groupBy((openShift) => openShift.get('weekFromNow'))
  }

  getShiftFilter = (filterByShift) => {
    return filterByShift.size > 1 || filterByShift.size === 0 ? SHIFT_FILTERS.all : SHIFT_FILTERS[filterByShift.get(0)]
  }

  filterOpenShifts(openShifts, filter) {
    const filterByShift = filter.get(FILTERS.shift)
    const filterByStatus = filter.get(FILTERS.status)
    const filterByRole = filter.get(FILTERS.roles)
    const filterByDate = filter.get(FILTERS.dateRange)
    if (filterByShift) {
      const shiftFilter = this.getShiftFilter(filterByShift)
      openShifts = openShifts.filter(shiftFilter)
    }

    if (filterByStatus) {
      openShifts = this.filterByStatus(openShifts, filterByStatus)
    }

    if (filterByRole) {
      openShifts = this.filterByRoles(openShifts, filterByRole)
    }

    if (filterByDate) {
      const { dates } = filterByDate
      if (dates.length === 1) {
        const startsFromMoment = this.timeService.timeMoment(dates[0])
        return openShifts.filter((openShift) => {
          const createdAt = openShift.get('createdAt')
          return this.timeService.isSameOrAfter(startsFromMoment, createdAt)
        })
      } else if (dates.length === 2) {
        const startsFromMoment = this.timeService.timeMoment(dates[0])
        const startsToMoment = this.timeService.timeMoment(dates[1])
        return openShifts.filter((openShift) => {
          const createdAt = openShift.get('createdAt')
          const createdAtMoment = createdAt ? this.timeService.timeMoment(createdAt) : null
          if (!createdAtMoment) {
            return false
          }
          return this.timeService.isBetween(createdAtMoment, startsFromMoment, startsToMoment)
        })
      }
    }
    return openShifts
  }

  filterByRoles(openShifts, filter) {
    return openShifts.filter((openShift) => filter.includes(openShift.get('unitRoleId')))
  }

  filterByStatus(openShifts, filter) {
    const filters = filter.toArray()
    let openShiftsByStatus = List([])
    for (let i = 0; i < filters.length; i++) {
      const statusFilter = STATUS_FILTERS[filters[i]]
      openShiftsByStatus = openShiftsByStatus.concat(openShifts.filter(statusFilter))
    }
    return openShiftsByStatus
  }

  sortOpenShifts(openShiftsByWeeks, sortOrder, sortBy) {
    return openShiftsByWeeks
      .sortBy((value, key) => key)
      .map((weekOpenShifts) => {
        const openShiftsOrder = weekOpenShifts.sortBy((openShift) => openShift.get('shiftStartsAt'))
        return openShiftsOrder.groupBy((openShift) => openShift.get('weekDayNameDayMonth'))
      })
  }

  getOpenShiftsSorter(sortBy) {
    if (sortBy === 'status') {
      return this.openShiftsSorterByStatus
    }

    return this.openShiftSorterByShiftStartsAt
  }

  openShiftSorterByShiftStartsAt = (openShift) => {
    const shiftStartsAt = openShift.get('shiftStartsAt')
    const shiftStartsAtMoment = this.timeService.timeMoment(shiftStartsAt)
    const shiftStartDate = shiftStartsAtMoment.format('YYYY-MM-DD')
    const shiftName = openShift.get('shiftName').toLowerCase()

    return `${shiftStartDate} ${shiftName}`
  }

  openShiftsSorterByStatus = (openShift) => {
    const staffMismatch = openShift.get('staffMismatch')
    const isFilled = staffMismatch <= 0
    const isPosted = !!openShift.get('id')
    const shiftStartsAt = openShift.get('shiftStartsAt')
    const acceptedByUserIds = openShift.get('acceptedByUserIds')
    const approvedUserIds = openShift.get('approvedUserIds')
    const deniedByUserIds = openShift.get('deniedByUserIds')
    const isNeedsApproval = acceptedByUserIds?.some((userId) => {
      return !approvedUserIds?.includes(userId) && !deniedByUserIds?.includes(userId)
    })

    let sortIndex = 3

    if (isFilled) {
      sortIndex = 0
    } else if (isNeedsApproval) {
      sortIndex = 1
    } else if (isPosted) {
      sortIndex = 2
    }

    return `${sortIndex} ${shiftStartsAt}`
  }

  getAcceptanceRate(openShifts) {
    const now = this.timeService.timeMoment(null)
    const monthAgo = now.clone().subtract(30, 'days')

    const shiftOpportunities = openShifts
      .filter((openShift) => !!openShift.get('isPosted'))
      .filter((openShift) => {
        const createdAt = openShift.get('createdAt')
        const createdAtMoment = this.timeService.timeMoment(createdAt)
        return createdAtMoment.diff(monthAgo, 'days') >= 0
      })

    if (shiftOpportunities.size === 0) {
      return 100
    }

    const filledCount = shiftOpportunities.filter((openShift) => !!openShift.get('isCompleted')).size
    return ((filledCount * 100) / shiftOpportunities.size).toFixed(0)
  }

  static getTotalOpenings(openShifts) {
    const reducer = (sum, item) => sum + parseInt(item.get('staffMismatch'), 10)
    const openings = (collection) => collection.reduce(reducer, 0)

    return openings(openShifts)
  }

  static getTotalShiftOpportunities(openShifts) {
    return openShifts.filter((openShift) => !!openShift.get('isPosted') && !openShift.get('isCompleted')).size
  }

  static getTotalOpenShifts(openShifts) {
    return openShifts.filter((openShift) => !openShift.get('isCompleted')).size
  }

  static getTotalNeedApprovalOpenShifts(openShifts) {
    return openShifts.filter((openShift) => !openShift.get('isCompleted')).filter(STATUS_FILTERS['needsApproval']).size
  }
}

const FILTERS = {
  shift: 'shift',
  status: 'status',
  roles: 'roles',
  dateRange: 'dateRange'
}

const SHIFT_FILTERS = {
  day: (openShift) => openShift.get('humanizedTime') === 'Day',
  night: (openShift) => openShift.get('humanizedTime') === 'Night',
  all: (openShift) => openShift
}

const STATUS_FILTERS = {
  filled: (openShift) => openShift.get('isCompleted'),
  nobodyInvited: (openShift) => !openShift.get('isPosted'),
  requestPosted: (openShift) =>
    !!openShift.get('isPosted') &&
    openShift.get('staffMismatch') > 0 &&
    openShift.get('pendingToConfirmUserIds').size === 0,
  needsApproval: (openShift) =>
    openShift.get('pendingToConfirmUserIds')?.size !== 0 && openShift.get('staffMismatch') > 0
}
