import { PureComponent } from 'react'
import CustomSelect from './CustomSelect'

export default class SelectFilter extends PureComponent {
  render() {
    const { name, value, setConfig, options, minWidth } = this.props

    const props = {
      name,
      value,
      options,
      updateConfig: setConfig,
      minWidth
    }

    return <CustomSelect {...props} />
  }
}
