const form = {
  subject: {
    label: 'SUBJECT',
    required: true,
    multiLine: false,
    name: 'subject',
    maxLength: 24
  },

  subtitle: {
    required: false,
    multiLine: false,
    name: 'subtitle',
    maxLength: 24
  },

  content: {
    label: 'CONTENT',
    required: true,
    multiLine: true,
    numberOfLines: 4,
    name: 'content',
    maxLength: 93
  },

  location: {
    required: false,
    multiLine: false,
    name: 'location',
    maxLength: 24
  },

  linkTitle: {
    label: 'LINK TITLE',
    required: true,
    multiLine: false,
    name: 'linkTitle',
    maxLength: 24
  },

  webAddress: {
    label: 'WEB ADDRESS\n(URL)',
    required: true,
    multiLine: true,
    name: 'webAddress'
  },

  saveTemplate: {
    label: 'Save As New Template',
    required: false,
    multiline: false,
    name: 'templateName'
  }
}

export default form
