import { fromJS, List, Map } from 'immutable'
import { isFunction } from 'utils'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import Staff from './Staff'
import StaffList from './StaffList'
import StaffData from './StaffData'
import StaffComplianceRules from './StaffComplianceRules'
import StaffRecurringSchedules from './StaffRecurringSchedules'

export default function StaffStore() {
  const defaultState = Map({
    staff: Map({
      shifts: List(),
      groups: List(),
      units: List(),
      staffPositions: List(),
      transferringStaff: Map({
        incomingStaff: List(),
        outcomingStaff: List(),
        futureStaff: List()
      }),
      staffList: Map({
        edges: List(),
        pageInfo: Map({
          offset: 0,
          limit: 25,
          count: 0,
          totalCount: 0
        })
      }),
      facilityUser: Map()
    })
  })

  const actions = {
    ...StaffData.actions,
    ...Staff.actions,
    ...StaffList.actions,
    ...StaffComplianceRules.actions,
    ...StaffRecurringSchedules.actions
  }

  return {
    initialize,
    ...actions
  }

  function initialize(state, context) {
    const updaters = fluxStore({
      setStaff,
      updateStaff,
      setStaffData,
      setStaffList,
      updateStaffData,
      updateStaffInList,
      updateComplianceRule,
      updateRecurringSchedule
    })
    const extendedContext = { ...context, ...updaters }

    StaffList.initialize(extendedContext)
    StaffData.initialize(extendedContext)
    Staff.initialize(extendedContext)
    StaffComplianceRules.initialize(extendedContext)
    StaffRecurringSchedules.initialize(extendedContext)

    return state.merge(defaultState)
  }

  function updateStaffInList(state, userId, value) {
    const staffList = state.getIn(['staff', 'staffList', 'edges'])
    const index = staffList.findIndex((user) => user.get('userId') === userId)

    if (isFunction(value)) {
      return state.updateIn(['staff', 'staffList', 'edges', index], value)
    }

    return state.mergeIn(['staff', 'staffList', 'edges', index], fromJS(value))
  }

  function setStaffList(state, staffList) {
    return state.setIn(['staff', 'staffList'], staffList)
  }

  function setStaff(state, staff) {
    return state.setIn(['staff', 'facilityUser'], staff)
  }

  function updateStaff(state, value) {
    if (isFunction(value)) {
      return state.updateIn(['staff', 'facilityUser'], value)
    }
    return state.mergeIn(['staff', 'facilityUser'], fromJS(value))
  }

  function setStaffData(state, data) {
    return state.mergeIn(['staff'], data)
  }

  function updateStaffData(state, callback) {
    return state.updateIn(['staff'], callback)
  }

  function updateComplianceRule(state, rule, shouldDelete = false) {
    const ruleId = rule.id
    const path = ['staff', 'facilityUser', 'staffProfile', 'staffComplianceRules']
    const staffRules = state.getIn(path) || List()
    const index = staffRules.findIndex((rule) => rule.get('id') === ruleId)
    const hasRule = index !== -1

    if (shouldDelete && hasRule) {
      const staffComplianceRules = staffRules.removeIn([index])
      return state.setIn(path, staffComplianceRules)
    }

    const shouldUpdate = hasRule && !shouldDelete
    if (shouldUpdate) {
      const staffComplianceRules = staffRules.set(index, fromJS(rule))
      return state.setIn(path, staffComplianceRules)
    }

    const shouldCreate = !hasRule && !shouldDelete
    if (shouldCreate) {
      const staffComplianceRules = staffRules.push(fromJS(rule))
      return state.setIn(path, staffComplianceRules)
    }

    return state
  }

  function updateRecurringSchedule(state, schedule, shouldDelete = false) {
    const scheduleId = schedule.id
    const path = ['staff', 'facilityUser', 'staffProfile', 'staffRecurringSchedules']
    const staffSchedules = state.getIn(path) || List()
    const index = staffSchedules.findIndex((schedule) => schedule.get('id') === scheduleId)
    const hasSchedule = index !== -1

    if (shouldDelete && hasSchedule) {
      const staffRecurringSchedules = staffSchedules.removeIn([index])
      return state.setIn(path, staffRecurringSchedules)
    }

    const shouldUpdate = hasSchedule && !shouldDelete
    if (shouldUpdate) {
      const staffRecurringSchedules = staffSchedules.set(index, fromJS(schedule))
      return state.setIn(path, staffRecurringSchedules)
    }

    const shouldCreate = !hasSchedule && !shouldDelete
    if (shouldCreate) {
      const staffRecurringSchedules = staffSchedules.push(fromJS(schedule))
      return state.setIn(path, staffRecurringSchedules)
    }

    return state
  }
}
