import { PureComponent } from 'react'
import { pick } from 'lodash'
import Profile from './Profile'
import Cells from './Cells'
import './Staff.scss'
import classNames from 'classnames'

export default class Staff extends PureComponent {
  render() {
    const { staff, style } = this.props

    const isLastRoleStaff = staff.get('isLastRoleStaff')

    const cellsProps = pick(
      {
        ...this.props
      },
      ['staff', 'showPopup', 'selection', 'onCellEvent', 'timeService', 'onTimeOffRequestEvent']
    )

    const profileProps = { facilityUser: staff }

    return (
      <div className="_staff hx-calendar-staff" style={style}>
        <div
          className={classNames('row bt1 bg-concrete', {
            _lastRoleStaff: isLastRoleStaff
          })}
        >
          <Profile {...profileProps} />
          <Cells {...cellsProps} />
        </div>
      </div>
    )
  }
}
