import fluxStore, { getState } from '@humanics/he-react-common/lib/stores/fluxStore'
import { fromJS } from 'immutable'
import FacilityData from './FacilityData'
import GeneralData from './GeneralData'
import ActiveDateRange from './ActiveDateRange'
import SchedulesMetadata from './SchedulesMetadata'
import Notifications from './Notifications/Notifications'

function GeneralStore() {
  const defaultState = fromJS({
    unit: {
      id: '',
      urlId: '',
      schedules: [],
      shifts: [],
      groups: [],
      roles: [],
      shiftTypes: [],
      isReady: false,
      schedulesMetadataMap: {}
    },
    eligibleUnits: [],
    activeDateRange: {
      days: [],
      facilityUserIds: [],
      facilityUsers: [],
      facilityUsersMap: {},
      staffUnavailabilityRequests: []
    },
    facility: {
      isDataLoaded: false,
      shiftsMap: {},
      groupsMap: {}
    },
    notifications: {
      calendar: 0,
      'open-shifts': 0,
      'shift-swaps': 0,
      'time-off': 0,
      staff: 0,
      activities: 0,
      announcements: 0
    }
  })

  function initialize(state, context) {
    const stateActions = fluxStore({ updateGeneralData, resetGeneralData })
    const initialData = { ...context, ...stateActions, getGeneralData }

    GeneralData.initialize(initialData)
    FacilityData.initialize(initialData)
    ActiveDateRange.initialize(initialData)
    SchedulesMetadata.initialize(initialData)
    Notifications.initialize(initialData)

    return state.set('generalData', defaultState)
  }

  return {
    initialize,
    ...GeneralData.actions,
    ...ActiveDateRange.actions,
    ...FacilityData.actions,
    ...SchedulesMetadata.actions,
    ...Notifications.actions
  }

  function updateGeneralData(state, value) {
    return state.update('generalData', value)
  }

  function getGeneralData() {
    return getState().get('generalData')
  }

  function resetGeneralData(state) {
    return state.set('generalData', defaultState)
  }
}

export default GeneralStore
