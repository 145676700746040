import { Map } from 'immutable'
import makeSummary from './OpenShifts/makeSummary'
import buildOpenShiftStaffMap from './OpenShift/buildOpenShiftStaffMap'

export default function updateOpenShift(updateOpenShiftsStateWith, openShift, timeService, isSelected = false) {
  const promises = []
  promises.push(
    updateOpenShiftsStateWith((openShiftsState) => {
      const openShiftsMap = updateOpenShiftsMap(openShiftsState.get('openShiftsMap'), openShift)

      const openShifts = updateOpenShifts(openShiftsState.get('openShifts'), openShift)

      const summary = makeSummary(openShifts)
      const newOpenShiftsState = { openShiftsMap, openShifts, summary }

      return openShiftsState.merge(newOpenShiftsState)
    })
  )

  if (isSelected) {
    promises.push(
      updateOpenShiftsStateWith((openShiftsState) => {
        const filter = openShiftsState.get('openShiftStaffFilter')
        const config = openShiftsState.get('openShiftStaffConfig')

        const openShiftStaffMap = buildOpenShiftStaffMap(openShift, filter, config, timeService)

        const isPosted = !!openShift.get('id')
        const prevOpenShiftStaffConfig = openShiftsState.get('openShiftStaffConfig')
        const selectedUserIds = isPosted
          ? openShift.get('eligibleUserIds')
          : prevOpenShiftStaffConfig.get('selectedUserIds')
        const openShiftStaffConfig = prevOpenShiftStaffConfig.merge({
          selectedUserIds
        })

        const prevOpenShiftParameters = openShiftsState.get('openShiftParameters')
        const openShiftParameters = isPosted
          ? Map({
              isBonusPay: openShift.get('isBonusPay'),
              isCrisisPay: openShift.get('isCrisisPay'),
              isImportant: openShift.get('isImportant'),
              acceptanceCriteria: openShift.get('acceptanceCriteria'),
              note: openShift.get('note')
            })
          : prevOpenShiftParameters.merge({
              isImportant: openShift.get('isImportant')
            })

        return openShiftsState.merge({
          openShift,
          openShiftStaffMap,
          openShiftStaffConfig,
          openShiftParameters
        })
      })
    )
  }

  return Promise.all(promises)
}

function updateOpenShiftsMap(openShiftsMap, openShift) {
  const weekFromNow = openShift.get('weekFromNow')
  const weekOpenShifts = openShiftsMap.get(weekFromNow)

  if (!weekOpenShifts) {
    return openShiftsMap
  }

  const shiftDayId = openShift.get('shiftDayId')
  const staffMismatch = openShift.get('staffMismatch')
  const isPosted = openShift.get('isPosted')

  const index = weekOpenShifts.findIndex((openShiftItem) => openShiftItem.get('shiftDayId') === shiftDayId)

  if (!isPosted && staffMismatch <= 0) {
    return openShiftsMap.removeIn([weekFromNow, index])
  }

  return openShiftsMap.setIn([weekFromNow, index], openShift)
}

function updateOpenShifts(openShifts, openShift) {
  const shiftDayId = openShift.get('shiftDayId')
  const staffMismatch = openShift.get('staffMismatch')
  const isPosted = openShift.get('isPosted')

  const index = openShifts.findIndex((openShiftItem) => openShiftItem.get('shiftDayId') === shiftDayId)

  if (!isPosted && staffMismatch <= 0) {
    return openShifts.remove(index)
  }

  return openShifts.set(index, openShift)
}
