import { Component } from 'react'
import { cloneDeep } from 'lodash'
import { withAppContext } from 'AppContext'
import './TemplatesHeader.scss'
import LoadTemplateButton from './LoadTemplateButton'
import UpdateTemplateButton from './UpdateTemplateButton'
import SaveTemplateModal from './SaveTemplateModal'

class TemplatesHeader extends Component {
  state = {
    isSpinnerShown: false,
    visible: false
  }

  componentDidMount = () => {
    this.loadTemplatesList()
  }

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible
    })
  }

  loadTemplatesList = () => {
    const { announcementsStore } = this.props
    announcementsStore.loadAnnouncementTemplates()
  }

  render() {
    const { isSpinnerShown, visible } = this.state
    const { appState, loadedTemplate, isFormDirty, canSave } = this.props
    const userTemplates = cloneDeep(appState.getIn(['announcementTemplates', 'items'])).reverse()

    const saveTemplateModalProps = {
      toggleModal: this.toggleModal,
      reloadTemplatesList: this.loadTemplatesList,
      visible
    }

    const loadTemplateButtonProps = {
      isFormDirty,
      reloadTemplatesList: this.loadTemplatesList
    }

    const updateTemplateButtonProps = {
      reloadTemplatesList: this.loadTemplatesList
    }

    return (
      <div className="templates-header-container row">
        <div className="row col-10">
          <div className="templates-list-container">
            {userTemplates.length
              ? userTemplates.map((template, index) => (
                  <div key={index} className="load-template-button">
                    <LoadTemplateButton {...template} {...this.props} {...loadTemplateButtonProps} />
                  </div>
                ))
              : null}
          </div>
        </div>

        <div className="col-2 row align-middle align-right">
          {!!loadedTemplate && isFormDirty && <UpdateTemplateButton {...updateTemplateButtonProps} {...this.props} />}

          <sh-button
            onClick={this.toggleModal}
            id="save-new-tmplt-btn"
            disabled={!canSave || isSpinnerShown ? true : undefined}
            label="+ Save As New Template"
            color="tertiary"
            size="auto"
          />
          <sh-tooltip label="Save as new template" target="save-new-tmplt-btn" placement="bottom" variation="short" />

          <div className="save-template-modal">
            <SaveTemplateModal {...saveTemplateModalProps} {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

export default withAppContext(TemplatesHeader)
