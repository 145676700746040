import classNames from 'classnames'
import { map } from 'lodash'

export default function Tabs(props) {
  const { tabs, onClick, activeTabId } = props

  return (
    <ul className="add-shift-nav">
      {map(tabs, (title, tabId) => (
        <li key={tabId} className={classNames({ active: tabId === activeTabId })}>
          <button onClick={(e) => onClick(tabId)}>{title}</button>
        </li>
      ))}
    </ul>
  )
}
