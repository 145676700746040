import { Component } from 'react'
import './formActionBar.scss'

class FormActionBar extends Component {
  render() {
    const { onCancel, onSubmit, submitLabel, cancelLabel, isSubmitLoading, isSubmitDisabled } = this.props
    return (
      <div className="form-action-bar row align-right">
        <div className="form-action-submit">
          <sh-button
            color="secondary"
            onClick={onCancel}
            id="_cancel"
            class="float-right"
            label={cancelLabel || 'Cancel'}
          />
        </div>
        <div className="form-action-cancel">
          {!isSubmitLoading ? (
            <sh-button
              color="primary"
              onClick={onSubmit}
              disabled={isSubmitDisabled ? true : undefined}
              id="_submit"
              class="float-right"
              label={submitLabel || 'Submit'}
            />
          ) : (
            <sh-spinner size="s" />
          )}
        </div>
      </div>
    )
  }
}

export default FormActionBar
