import { pick } from 'lodash'
import { PureComponent } from 'react'
import { PageContent, PageFooter, PageHeader } from '../Page'
import NotesTable from './NotesTable'

export default class PrintedNotes extends PureComponent {
  render() {
    const { filters } = this.props

    const headerProps = pick(
      {
        ...this.props,
        title: 'Notes'
      },
      ['unit', 'title', 'printedDateRange']
    )

    const footerProps = pick(
      {
        ...this.props,
        isLegendVisible: false,
        filterRoleIds: filters.get('roleIds'),
        filterShiftTypes: filters.get('shiftTypes')
      },
      ['unit', 'timeService', 'filterRoleIds', 'authentication', 'isLegendVisible', 'filterShiftTypes']
    )

    const notesTableProps = pick(this.props, [
      'calendar',
      'date',
      'view',
      'timeService',
      'additionalFilters',
      'getViewModel'
    ])

    return (
      <table className="print-table">
        <PageHeader {...headerProps} />
        <PageContent>
          <NotesTable {...notesTableProps} />
        </PageContent>
        <PageFooter {...footerProps} />
      </table>
    )
  }
}
