import { matchPath } from 'react-router-dom'
import { paths } from 'Navigation'

const redirectToGASPaths = [
  paths.Login,
  paths.Registration,
  paths.MagicLink,
  paths.NoPassword,
  paths.ChangePassword,
  paths.Logout
]

const hashRedirects = ['#/registration/', '#/createSessionByLoginToken/', '#/changePassword/']

function getGasUri(location) {
  return global.GAS_URI
}

function getInstanceUri(location) {
  switch (location.hostname) {
    // case: 'local.develop.humanics.com':
    case 'localhost.develop.humanics.com':
      return 'https://dev.develop.humanics.com'
    case 'local.instance.pontevecchio.hcvpc.io':
      return 'https://instance.dev.pontevecchio.hcvpc.io'
    default:
      // use current host as default instance URI.
      return location.origin
  }
}

function getFacilityUrlId(pathname) {
  const match = pathname.match(/^\/(admin\/)?([^/]+)/)
  if (match) {
    return match[2]
  }
  return null
}

function getGASRedirect(location) {
  const hashRedirect = hashRedirects.find((prefix) => location.hash.startsWith(prefix))
  const locationUrl = new URL(location)

  if (hashRedirect) {
    locationUrl.href = encodeURI(locationUrl.href.replace(/#\/(.*)\//, '$1/'))
  }

  const path = redirectToGASPaths.find((path) => matchPath(locationUrl.pathname, path))
  const shouldRedirect = !!path

  if (shouldRedirect) {
    console.info('Should redirect')
    return {
      accountsURI: encodeURIComponent(getGasUri(location)),
      referrerLocation: encodeURIComponent(locationUrl.href)
    }
  }

  return null
}

export { getGasUri, hashRedirects, getFacilityUrlId, getInstanceUri, getGASRedirect, redirectToGASPaths }
