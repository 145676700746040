import { createRef, PureComponent } from 'react'
import { pick } from 'lodash'
import { List, Map } from 'immutable'
import { withAppContext } from 'AppContext'
import Grid from './Grid'
import TimeOffDetails from './TimeOffDetails'
import TimeOffRequestDetails from './TimeOffRequestDetails'
import CellTooltip from './CellTooltip'
import CellContextMenu from './CellContextMenu/CellContextMenu'
import {
  ActionController,
  CrossHatchController,
  DataController,
  DataModelController,
  EventController,
  MultiselectController,
  PopupController,
  StateController,
  TooltipController,
  ViewModelController
} from './Controllers'
import CrossHatch from 'Manager/components/CrossHatch'
import ReactResizeDetector from 'react-resize-detector'
import Empty from '../Empty'
import Footer from 'Footer'
import './PendingRequests.scss'

class PendingRequests extends PureComponent {
  constructor(props) {
    super(props)

    this.dataController = new DataController(this)
    this.stateController = new StateController(this)
    this.eventController = new EventController(this)
    this.popupController = new PopupController(this)
    this.actionController = new ActionController(this)
    this.tooltipController = new TooltipController(this)
    this.crossHatchController = new CrossHatchController(this)
    this.multiselectController = new MultiselectController(this)
    this.viewModelController = new ViewModelController(this)
    this.dataModelController = new DataModelController(this)

    this.crossHatchRef = createRef()

    this.state = {
      ...this.stateController.defaultState,
      ...this.multiselectController.defaultState,
      ...this.dataModelController.defaultState,
      ...this.tooltipController.defaultState,
      ...this.popupController.defaultState,
      ...this.multiselectController.defaultState,
      overscanRowCount: 0,
      isLoading: true,
      clientHeight: 0,
      offsetHeight: 0,
      scrollTop: 0,
      scrollLeft: 0
    }

    this.pendingRequestsRef = createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.eventController.handleScroll, true)
    this.crossHatchController.updateCrossHatchStyle()
    this.sectionElement = document.querySelector('#root > section')

    const { unit } = this.props
    const isUnitReady = unit.get('isReady')

    if (isUnitReady) {
      this.dataController.loadInitialData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { unit, appState } = this.props
    const { unit: prevUnit, appState: prevAppState } = prevProps

    const isUnitReady = unit.get('isReady')
    const isUnitJustLoaded = isUnitReady && !prevUnit.get('isReady')
    const isUnitIdChanged = unit.get('id') !== prevUnit.get('id')
    if (isUnitIdChanged) {
      this.dataController.resetData()
    }

    if (isUnitJustLoaded || (isUnitIdChanged && isUnitReady)) {
      this.dataController.loadInitialData()
    }

    const isTimeOffChanged = appState.get('timeOff') !== prevAppState.get('timeOff')
    if (isTimeOffChanged) {
      this.dataModelController.rebuildDataModel()
    }

    const { timeOffCalendar } = this.state
    const { timeOffCalendar: prevTimeOffCalendar } = prevState
    const isTimeOffCalendarChanged = timeOffCalendar !== prevTimeOffCalendar
    if (isTimeOffCalendarChanged) {
      this.dataController.onTimeOffCalendarChanged(prevTimeOffCalendar)
    }

    const timeOffNotifications = appState.getIn(['generalData', 'notifications', 'time-off'])
    const prevTimeOffNotifications = prevAppState.getIn(['generalData', 'notifications', 'time-off'])
    const timeOffNotificationsChanged = timeOffNotifications !== prevTimeOffNotifications
    if (timeOffNotificationsChanged) {
      this.dataController.reloadTimeOffRequests()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventController.handleScroll, true)
    this.dataController.resetData()
  }

  updateGridDimensions() {
    this.setState({
      clientHeight: this.pendingRequestsRef.current.clientHeight,
      offsetHeight: this.pendingRequestsRef.current.offsetHeight,
      offsetWidth: this.pendingRequestsRef.current.offsetWidth
    })
  }

  render() {
    const { timeService, unit, appState } = this.props
    const { isLoading, timeOffCalendar } = this.state
    const { dateRanges, allTimeOffRequestsLoaded } = timeOffCalendar
    const noTimeOffs = allTimeOffRequestsLoaded && dateRanges.size === 0

    if (isLoading || noTimeOffs) {
      return (
        <div className="hx-pending-requests-container empty">
          <Empty isLoading={isLoading} />;
          <Footer />
        </div>
      )
    }

    const facility = appState.getIn(['generalData', 'facility'])
    const { styleHorizontal, popupConfig, selection } = this.state

    const notesMap = appState.getIn(['timeOff', 'notesMap'])

    const popupProps = popupConfig.get('popupProps')
    const popupId = popupConfig.get('popupId')

    const eventVariants = unit?.get('eventVariants')
    const timeOffEventVaraints = eventVariants
      ? eventVariants.filter((variant) => variant.get('type') === 'timeOff')
      : List()

    //Blocked TimeOffDetails so that new errors are not revealed before release
    //TODO: Unblock component safely
    const isTimeOffDetailsVisible = false //String(popupId) === 'TimeOffDetails';
    const timeOffDetailsProps = pick(
      {
        ...this.props,
        ...this.state,
        ...this,
        popupProps,
        notesMap,
        timeOffCalendar,
        timeOffEventVaraints,
        isTimeOffDetailsVisible,
        onCancel: this.popupController.hidePopup,
        changeTimeOff: this.actionController.changeTimeOff,
        deleteTimeOffs: this.actionController.deleteTimeOffs
      },
      [
        'notesMap',
        'popupProps',
        'isTimeOffDetailsVisible',
        'timeOffEventVaraints',
        'onCancel',
        'selection',
        'timeOffCalendar',
        'timeService',
        'scrollTop',
        'popupConfig',
        'changeTimeOff',
        'deleteTimeOffs'
      ]
    )

    const isTimeOffRequestDetailsVisible = String(popupId) === 'TimeOffRequestDetails'
    const timeOffRequestDetailsProps = pick(
      {
        ...this.props,
        ...this.state,
        ...this,
        popupProps,
        selection,
        timeOffCalendar,
        isTimeOffRequestDetailsVisible,
        onMouseEnterTimeOffRequestDetails: this.eventController.onMouseEnterTimeOffRequestDetails,
        onMouseLeaveTimeOffRequestDetails: this.eventController.onMouseLeaveTimeOffRequestDetails,
        onCancel: this.popupController.hidePopup,
        approveTimeOffRequest: this.actionController.approveTimeOffRequest,
        denyTimeOffRequest: this.actionController.denyTimeOffRequest
      },
      [
        'onCancel',
        'selection',
        'scrollTop',
        'isDenying',
        'popupProps',
        'isApproving',
        'timeService',
        'timeOffCalendar',
        'denyTimeOffRequest',
        'approveTimeOffRequest',
        'isTimeOffRequestDetailsVisible',
        'onMouseEnterTimeOffRequestDetails',
        'onMouseLeaveTimeOffRequestDetails'
      ]
    )

    const cellTooltipProps = {
      timeService,
      notesMap,
      config: this.state.tooltipConfig,
      staffManagersMap: facility.get('staffManagersMap') || Map(),
      countStaffPTOs: this.viewModelController.countStaffPTOs
    }

    //Blocked CellContextMenu so that new errors are not revealed before release
    //TODO: Unblock component safely
    const isCellContextMenuVisible = false //String(popupId) === 'ContextMenu';
    const cellContextMenuProps = pick(
      {
        ...this.props,
        ...this.state,
        popupProps,
        isCellContextMenuVisible,
        changeTimeOff: this.actionController.changeTimeOff,
        hideContextMenu: this.popupController.hidePopup
      },
      [
        'unit',
        'selection',
        'scrollTop',
        'popupProps',
        'timeService',
        'changeTimeOff',
        'hideContextMenu',
        'timeOffCalendar',
        'isCellContextMenuVisible'
      ]
    )

    const gridProps = pick(
      {
        ...this.props,
        ...this.state,
        ...this,
        dateRanges,
        allTimeOffRequestsLoaded,
        updateScrollTop: this.eventController.updateScrollTop,
        showPopup: this.popupController.showPopup,
        resetLoadConfig: this.dataController.resetLoadConfig,
        loadDataOnScroll: this.dataController.loadDataOnScroll,
        getRowHeightByIndex: this.viewModelController.getRowHeightByIndex,
        getViewModel: this.viewModelController.getViewModel,
        onCellEvent: this.eventController.onCellEvent,
        onTimeOffRequestEvent: this.eventController.onTimeOffRequestEvent,
        showPendingTimeOffModal: this.popupController.showPendingTimeOffModal,
        hidePendingTimeOffModal: this.popupController.hidePendingTimeOffModal,
        loadDateRangeTimeOffs: this.dataController.loadDateRangeTimeOffs,
        countDateRangeTimeOffs: this.viewModelController.countDateRangeTimeOffs
      },
      [
        'updateScrollTop',
        'unit',
        'timeService',
        'onCellEvent',
        'showPopup',
        'selection',
        'shiftType',
        'staffRole',
        'dateRanges',
        'offsetHeight',
        'getViewModel',
        'resetLoadConfig',
        'loadDataOnScroll',
        'overscanRowCount',
        'getRowHeightByIndex',
        'loadDateRangeTimeOffs',
        'countDateRangeTimeOffs',
        'showPendingTimeOffModal',
        'multiselectController',
        'hidePendingTimeOffModal',
        'allTimeOffRequestsLoaded',
        'onTimeOffRequestEvent'
      ]
    )

    return (
      <ReactResizeDetector handleWidth handleHeight onResize={this.eventController.onResize}>
        <div
          ref={this.pendingRequestsRef}
          id="hx-pending-timeoff-anchor"
          className="hx-pending-requests-container relative _modeFull"
          onClick={this.eventController.onClickOutsideTheGrid}
          onMouseMove={this.crossHatchController.updateCrossHatchPosition}
          onMouseLeave={this.crossHatchController.hideCrosshatch}
        >
          <Grid {...gridProps} />
          <TimeOffDetails {...timeOffDetailsProps} />
          <TimeOffRequestDetails {...timeOffRequestDetailsProps} />
          <CellContextMenu {...cellContextMenuProps} />
          <CellTooltip {...cellTooltipProps} />
          <CrossHatch paddingTop={0} styleHorizontal={styleHorizontal} ref={this.crossHatchRef} />
        </div>
      </ReactResizeDetector>
    )
  }
}

export default withAppContext(PendingRequests)
