import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import CustomTimeDropdown from 'components/Form/CustomTimeDropdown'
import CustomDropdownEnum from 'components/Form/CustomDropdownEnum'
import CustomRadioToggle from 'components/Form/CustomRadioToggle'
import './AdvancedConfigurationCard.scss'
import form from './formDefinitions'
import FormController from './FormController'
import { curry } from 'i18n'
import { addSuccessBanner, addWarningBanner } from 'stores'
import { cloneDeep } from 'lodash'

const t = curry('announcements.banners.announcement.')
const item = (option, labelIsIcon = false, iconClass = '') => ({
  option,
  labelIsIcon,
  iconClass,
  id: option
})

const sendDate = [item('Now'), item('Later')]
const timeDuration = [item('2 Days'), item('3 Days'), item('1 Week')]
const typeOfShift = [item('All'), item('Day', true, 'icon-sun'), item('Night', true, 'icon-moon')]
const recipientsTypeNow = [item('Working Now'), item('All Staff')]
const recipientsTypeThen = [item('Working Then'), item('All Staff')]

const Inputs = ({ items, field, value, setFieldValue }) =>
  items.map(({ option, ...item }) => (
    <CustomRadioToggle
      value={option}
      label={option}
      field={field}
      isChecked={option === value}
      key={option}
      onChange={(e) => setFieldValue(option, field)}
      wrapperClassName="regent-gray semibold"
      wrapperStyle={{ width: `calc(99%/${items.length})` }}
      labelClassName="regent-gray"
      {...item}
    />
  ))

export class AdvancedConfigurationCardForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      form: cloneDeep(form), // the values were being stored in the form from './formDefinitions';
      isLoading: false,
      startDateSelected: 'Now',
      durationSelected: '2 Days',
      shiftSelected: 'All',
      recipientsNowSelected: 'Working Now',
      recipientsThenSelected: 'Working Then',
      // isValidTime: true,
      dayScheduledChanged: false
    }
    this.controller = new FormController(this)
  }

  componentDidMount() {
    this.controller.updateForm(this.state.form, true)
  }

  componentWillUnmount() {
    const form = this.controller._resetForm(this.state.form)
    this.controller.setState({ form })
  }

  componentDidUpdate() {
    // This is intentional
  }

  onSubmit = async (e) => {
    const { recipientsNowSelected, recipientsThenSelected } = this.state

    const {
      payload: formData,
      unitIds,
      formatDetailsComponents,
      announcementsStore,
      onClose,
      announcementId,
      refreshNotifications
    } = this.props

    const { hasDetailsPage, detailsComponents, ...payload } = formData
    if (hasDetailsPage) {
      payload.detailsComponents = formatDetailsComponents(detailsComponents)
    } else {
      payload.detailsComponents = null
      payload.detailsHtml = null
    }

    const data = this.controller.serializeForm()

    payload.status = 'scheduled'
    payload.startsAt = data.startsAt
    payload.endsAt = data.endsAt
    payload.unitIds = unitIds

    // if recipient is "Working Now" or "Working Then", shiftTypes is not applicable
    if (recipientsNowSelected === 'All Staff' || recipientsThenSelected === 'All Staff') {
      payload.shiftTypeIds = []
    } else {
      payload.worksAt = data.worksAt
    }

    try {
      this.setState({ isLoading: true })

      const shouldBeSentImmediately = this.state.startDateSelected === 'Now'
      let announcementAction
      if (announcementId) {
        announcementAction = await announcementsStore.updateAnnouncement(
          announcementId,
          payload,
          shouldBeSentImmediately
        )
        if (shouldBeSentImmediately) {
          refreshNotifications()
        }
      } else {
        announcementAction = await announcementsStore.createAnnouncement(payload, shouldBeSentImmediately)
      }

      if ('error' in announcementAction) {
        addWarningBanner({ message: t('send_error') })
      } else if (shouldBeSentImmediately) {
        addSuccessBanner({ message: t('send_success') })
      } else if (this.state.startDateSelected === 'Later') {
        addSuccessBanner({ message: t('schedule_success') })
      }
      this.setState({ isLoading: false })
      onClose(true)
    } catch (error) {
      this.setState({ isLoading: false })
    }
  }

  setFieldValue = (optionSelected, stateToChange) => this.setState({ [stateToChange]: optionSelected })

  // checkTimeValidity = () => {
  //   const { form } = this.state;
  //   const { todayUsDate } = stores.timeService();
  //   const todayHour = todayUsDate.format('HH');
  //   const todayMinute = todayUsDate.format('mm');

  render() {
    const { onClose, timeService } = this.props
    const {
      startDateSelected,
      durationSelected,
      shiftSelected,
      isLoading,
      recipientsNowSelected,
      recipientsThenSelected
    } = this.state

    const form = this.controller.getFormProperties()

    // const isValidTime = this.checkTimeValidity();
    const hideShiftParam = false
    const TimeDropdown = ({ field }) => (
      <CustomTimeDropdown
        timeService={timeService}
        {...form[field]}
        onChange={(e) => this.controller.setFieldValue(e, field)}
        propKey={'id'}
        propValue={'name'}
      />
    )

    return (
      <div className="column">
        <div className="form-field column">
          <div data-testid="advanced-config-form-title" className="form-title">
            {' '}
            DELIVERY OPTIONS
          </div>
          <div className="separator-schedule-form" />
          <div className="form-options">
            <div className="row">
              <label className="advanced-config-label">SEND</label>
              <div data-testid="switch-field-send" className="switch-field">
                <Inputs
                  items={sendDate}
                  field="startDateSelected"
                  value={startDateSelected}
                  setFieldValue={this.setFieldValue}
                />
              </div>
            </div>
            {startDateSelected === 'Later' && (
              <div>
                <div className="row">
                  <label className="advanced-config-label">DATE</label>
                  <div data-testid="switch-field-date-later" className="switch-field">
                    <CustomDropdownEnum
                      timeService={timeService}
                      {...form.dayScheduled}
                      onChange={(e) => this.controller.setFieldValue(e, 'dayScheduled')}
                      propKey={'id'}
                      propValue={'name'}
                    />
                  </div>
                </div>

                <div className="row time-row">
                  <label className="advanced-config-label">TIME</label>
                  <div data-testid="switch-field-time-later" className="switch-field">
                    <div className="time-dropdown">
                      <div className="time">
                        <TimeDropdown field="hourScheduled" />
                      </div>

                      <div className="time-connector"> :</div>
                      <div className="time">
                        <TimeDropdown field="minuteScheduled" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="separator-schedule-form" />
            <div className="row">
              <label className="advanced-config-label">DURATION</label>
              <div data-testid="switch-field-duration" className="switch-field">
                <Inputs
                  items={timeDuration}
                  field="durationSelected"
                  value={durationSelected}
                  setFieldValue={this.setFieldValue}
                />
              </div>
            </div>
            <div className="row">
              <label className="advanced-config-label">RECIPIENTS</label>
              {startDateSelected === 'Now' && (
                <div data-testid="switch-field-recipients-now" className="switch-field">
                  <Inputs
                    items={recipientsTypeNow}
                    field="recipientsNowSelected"
                    value={recipientsNowSelected}
                    setFieldValue={this.setFieldValue}
                  />
                </div>
              )}
              {startDateSelected === 'Later' && (
                <div data-testid="switch-field-recipients-later" className="switch-field">
                  <Inputs
                    items={recipientsTypeThen}
                    field="recipientsThenSelected"
                    value={recipientsThenSelected}
                    setFieldValue={this.setFieldValue}
                  />
                </div>
              )}
            </div>

            {hideShiftParam &&
              ((recipientsNowSelected === 'All Staff' && startDateSelected === 'Now') ||
                (recipientsThenSelected === 'All Staff' && startDateSelected === 'Later')) && (
                <div className="row">
                  <label className="advanced-config-label">SHIFT</label>
                  <div data-testid="switch-field-shift" className="switch-field">
                    <Inputs
                      items={typeOfShift}
                      field="shiftSelected"
                      value={shiftSelected}
                      setFieldValue={this.setFieldValue}
                    />
                  </div>
                </div>
              )}
          </div>

          <footer className="form-buttons">
            <div className="acc-form-buttons">
              <sh-button onClick={onClose} color="secondary" label="Cancel" size="m" />
              <sh-button
                class="_submit"
                onClick={this.onSubmit}
                color="primary"
                disabled={isLoading ? true : undefined}
                label={startDateSelected === 'Later' ? 'Send Later' : 'Send Now'}
                size="m"
              />
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default withAppContext(AdvancedConfigurationCardForm)
