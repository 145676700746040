import { PureComponent } from 'react'
import { RolesSelector, ShiftTypesSelector } from 'Manager/components'
import ActiveDateRange from '../ActiveDateRange'
import { debounce } from 'lodash'
export default class AutoScheduleCalendarToolbar extends PureComponent {
  state = { isNavigationShown: false, search: '', isAutoScheduleShown: false }

  render() {
    const { filters, updateFilter, unit } = this.props

    const roles = unit.get('roles')
    const unitShiftTypes = unit.get('shiftTypes')

    const rolesSelectorProps = {
      roles,
      values: filters.get('roleIds'),
      setRole: (value) => updateFilter((filters) => filters.set('roleIds', value))
    }

    const shiftTypesSelectorProps = {
      shiftTypes: unitShiftTypes,
      values: filters.get('shiftTypes'),
      setShiftType: (value) => updateFilter((filters) => filters.set('shiftTypes', value))
    }

    return (
      <div className="row align-middle calendar-toolbar">
        <div className="col-4 row">
          <div className="space-around-align br1">
            <span className="river-bad">
              <ShiftTypesSelector {...shiftTypesSelectorProps} />
            </span>

            <span className="river-bad">
              <RolesSelector {...rolesSelectorProps} />
            </span>
          </div>
          <div className="col flex-end-align">
            <div className="hx-radio-group"></div>
          </div>
        </div>

        <div className="col-4 row align-center">
          <ActiveDateRange />
        </div>
        <div className="col-2 _navigation"></div>
        <div className="col-1 hx-staff-filter-input bl1"></div>
      </div>
    )
  }

  updateSearch = debounce((value) => {
    this.props.updateFilter((filters) => filters.set('filterBySearch', value))
  }, 300)

  onAutoSchedulerButtonClick = () => {
    this.setState({ isAutoScheduleShown: true })
  }

  onAutoSchedulerCancelButtonClick = () => {
    this.setState({ isAutoScheduleShown: false })
  }

  setIndicatorsConfig = (indicators) => {
    this.props.setConfig({ indicators })
  }

  setShiftTypesConfig = (event) => {
    this.props.setConfig({ shiftType: event.target.value })
  }

  setRolesFilterConfig = (event) => {
    this.props.setConfig({ roleId: event.target.value })
  }

  showDateRange = () => {
    this.setState({ isNavigationShown: true })
  }

  hideDateRange = () => {
    this.setState({ isNavigationShown: false })
  }
}
