import { Component } from 'react'
// import Modal from '../Templates/Modal';
import { Modal } from 'Common/components'
import AdvancedConfigurationCardForm from './AdvancedConfigurationCardForm'
// import { navigate } from 'Navigation';

export default class AdvancedConfigurationCardModal extends Component {
  render() {
    const { visible, toggleAddAdvancedConfigurationState, ...formProps } = this.props

    return (
      <div>
        <Modal visible={visible} width={511}>
          <AdvancedConfigurationCardForm {...formProps} onClose={toggleAddAdvancedConfigurationState} />
        </Modal>
      </div>
    )
  }
}
