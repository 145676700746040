import { ChangeEvent, Component } from 'react'
// @ts-ignore
import { Grid, Admin, Header, Search } from '@humanics/he-react-common/lib/admin/components'
// @ts-ignore
import { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { Link } from 'react-router-dom'
import UnitResourceController from './UnitResourceController'
import { withAppContext } from 'AppContext'
import { isFacilityEnabledFeature } from 'utils'
import { t } from 'i18n'

// TODO: Revisit when merging with unit resource - START
// const fields = [
//   Field('name', 'Name', { format: 'plain' }),
//   Field('unitName', 'Unit', { format: 'plain' }),
//   Field('workingDays', 'Working Days', { format: 'plain' }),
//   Field('defaultSettings', 'Default settings', { format: 'plain' })
// ]
function FieldName(props: any) {
  const { appState, dataRow } = props
  const name = dataRow['name']
  const doesFacilitySupportRemoteServices = isFacilityEnabledFeature(appState, 'remote_workflow_wescan', ['write'])
  const isEnabledRemoteService = doesFacilitySupportRemoteServices && dataRow?.remoteDetails?.isEnabledRemoteService

  if (!isEnabledRemoteService) {
    return <td>{name}</td>
  }

  return (
    <td>
      <div className="flex">
        <span>{name}</span>
        &nbsp;&nbsp;
        <sh-icon icon="remote-service-ongoing" title={t('equipments.supports_remote_message')}></sh-icon>
      </div>
    </td>
  )
}

const FieldNameWithContext = withAppContext(FieldName)

const fields = [
  Field('name', 'Name', {
    render: (dataRow: any) => <FieldNameWithContext dataRow={dataRow} />
  }),
  Field('unitName', 'Unit'),
  Field('groupName', 'Role'),
  Field('startTime', 'Start Time'),
  Field('duration', 'Duration'),
  Field('displayAbbreviation', 'Abbreviation')
]
// TODO: Revisit when merging with unit resource - END

export interface UnitResourceProps {
  location: { pathname: string }
  type: string
  itemName: string
  title: string
  timeService: any
  store: any
  match: any
  stores: any
  history: any
  appState: any
  subRoutes: any
}

interface UnitResourceState {
  fields: {
    format: string
    name: string
    text: string
  }[]
  data: { unitResourceData?: UnitResource[] | undefined }
  query: string
  apiInitialized: boolean
}

export interface DefaultDetail {
  startTime: string
  endTime: string
  targetCover: { min: number; max: number }
  allowableGap: number
}

export interface OperationalDetail {
  isWorking: boolean
  operationalDetail: DefaultDetail | null
}

export interface OperationalDetails {
  mon: OperationalDetail
  tue: OperationalDetail
  wed: OperationalDetail
  thu: OperationalDetail
  fri: OperationalDetail
  sat: OperationalDetail
  sun: OperationalDetail
}

export interface UnitResource {
  id: string
  name: string
  unitId: string
  unitResourceType: string
  unitName: string
  workingDays: string
  defaultSettings: string
  operationalDetails: OperationalDetails
  defaultDetails: DefaultDetail
}

class UnitResourceAdmin extends Component<UnitResourceProps, UnitResourceState> {
  controller: UnitResourceController

  constructor(props: UnitResourceProps) {
    super(props)
    this.controller = new UnitResourceController(this)
    this.state = { fields, data: {}, query: '', apiInitialized: false }
  }

  componentDidMount() {
    ;(async () => {
      const { store, type } = this.props
      await store.initializeApi()
      await this.controller.unitResourceStore.getApi()
      this.controller.loadData(type)
      this.setState({ apiInitialized: true })
    })()
  }

  async componentDidUpdate(prevProps: UnitResourceProps, prevState: UnitResourceState) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const prevStoreState = prevAppState.get(stateKey)
    const isStoreStateChanged = storeState !== prevStoreState

    const { query: prevQuery } = prevState
    const { query } = this.state
    const isQueryUpdated = prevQuery !== query

    if (isStoreStateChanged || isQueryUpdated) {
      const data = await this.controller.getUnitResources()
      this.setState({ data })
    }
  }

  render() {
    const { store, match, stores, history, location, appState, itemName, title, subRoutes } = this.props
    const { query, apiInitialized, data } = this.state
    const { unitResourceData } = data

    if (!apiInitialized) {
      return null
    }
    const isStoreApiInitialized = store.isApiInitialized(appState)
    if (!isStoreApiInitialized) {
      return null
    }

    if (!unitResourceData) {
      return null
    }

    const linkTo = location.pathname.split('/').includes('new')
      ? location.pathname
      : location.pathname.replace(/\/$/, '') + '/new'

    const actions = (
      <Link to={linkTo} className="button secondary">
        Create {itemName}
      </Link>
    )

    const { search, stateKey } = store.getAdminInfo()

    return (
      <Admin>
        <Header title={title} actions={actions} />
        <Grid
          history={history}
          data={unitResourceData}
          fields={fields}
          allowUpdate={true}
          stores={stores}
          currentPath={match.url}
          store={store}
          Link={Link}
          dataType={stateKey}
        >
          {!search ? null : (
            <Search
              keyword={query}
              onReset={(e: ChangeEvent<HTMLInputElement>) => e?.preventDefault()}
              placeholder={search?.placeholder}
              onSearch={(searchItem: string) => this.setState({ query: searchItem })}
              itemsFound={unitResourceData}
              entityName={title}
            />
          )}
        </Grid>
        {subRoutes}
      </Admin>
    )
  }
}

export default UnitResourceAdmin
