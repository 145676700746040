import { Map, List } from 'immutable'
import MultiSelectFilter from '../MultiSelectFilter'

const getEmploymentTypesDefinition = (options) => {
  return {
    options,
    minWidth: 100,
    deselectAllAllowed: true
  }
}

export const employmentTypes = List([
  Map({ id: 'any', name: 'Any' }),
  Map({ id: 'adex', name: 'Adex' }),
  Map({ id: 'agency', name: 'Agency' }),
  Map({ id: 'perDiem', name: 'Per Diem' }),
  Map({ id: 'fullTime', name: 'Full Time' }),
  Map({ id: 'partTime', name: 'Part Time' }),
  Map({ id: 'inHouseRegistry', name: 'In House Registry' })
])

export default function EmploymentTypesSelector(props) {
  const { values, setEmploymentType: setConfig, ...rest } = props
  const options = employmentTypes.reduce((memo, employmentType) => {
    const id = employmentType.get('id')
    const name = employmentType.get('name')
    return memo.set(id, name)
  }, Map())

  const multiSelectFilterProps = {
    ...getEmploymentTypesDefinition(options),
    setConfig,
    values,
    name: 'Employment Type',
    field: 'employmentType',
    deselectAllAllowed: false,
    ...rest
  }

  return <MultiSelectFilter {...multiSelectFilterProps} />
}
