import { t } from 'i18n'
import ShiftSwapDetails from '../ShiftSwapDetails/ShiftSwapDetails'
import './ShiftSwapDetailsCard.scss'
import { fromJS } from 'immutable'

export interface IShiftSwapDetailsCard {
  timeService: any
  shiftSwapRequest: any
  unitsMap: any
  shiftsMap: Map<string, Map<string, any>>
  initiated: any
}

function _getUserProfile(profileData: any) {
  const userProfileFirstName = profileData?.get('userProfileFirstName')
  const userProfileLastName = profileData?.get('userProfileLastName')
  const userProfileAvatarUrl = profileData?.get('userProfileAvatarUrl')

  return fromJS({
    firstName: userProfileFirstName,
    lastName: userProfileLastName,
    avatarUrl: userProfileAvatarUrl
  })
}

export default function ShiftSwapDetailsCard({
  timeService,
  shiftSwapRequest,
  unitsMap,
  shiftsMap,
  initiated
}: IShiftSwapDetailsCard) {
  function _getDate(date: string) {
    const moment = timeService.dateTimeToMoment(date)
    return `${moment.format('ddd')}, ${moment.format('D')} ${moment.format('MMMM')} ${moment.format('YYYY')}`
  }

  function _getShiftTime(starTime: string, duration: number) {
    const startTimeMoment = timeService.timeMoment(starTime, 'HH:mm')
    const endTimeMoment = startTimeMoment.clone().add(duration, 'minutes')
    const start = startTimeMoment.format('hh:mm A')
    const end = endTimeMoment.format('hh:mm A')
    return `${start} - ${end}`
  }

  const [firstShiftDayToSwapFor] = shiftSwapRequest.get('shiftDaysToSwapFor')
  const recipientUnitName = unitsMap.get(firstShiftDayToSwapFor.get('unitId'))?.get('name')
  const recipientShiftName = shiftsMap.get(firstShiftDayToSwapFor.get('shiftId'))?.get('name')
  const recipientDate = _getDate(firstShiftDayToSwapFor.get('startsAt'))
  const recipientShiftTime = _getShiftTime(
    firstShiftDayToSwapFor.get('startTime'),
    firstShiftDayToSwapFor.get('length')
  )

  const initatorDate = _getDate(shiftSwapRequest.get('date'))
  const initatorUnitName = shiftSwapRequest.get('unitName')
  const initatorShiftName = shiftSwapRequest.get('shiftName')
  const initatorShiftTime = _getShiftTime(
    shiftSwapRequest.get('shiftDay').get('startTime'),
    shiftSwapRequest.get('shiftDay').get('length')
  )

  const inititatorPosition = shiftSwapRequest.getIn(['initiatorStaffProfile', 'position'])
  const recipientPosition = shiftSwapRequest.getIn(['recipientStaffProfile', 'position'])

  const inititatorProfile = _getUserProfile(shiftSwapRequest.get('initiatorStaffProfile'))
  const recipientProfile = _getUserProfile(shiftSwapRequest.get('recipientStaffProfile'))

  const noteText = shiftSwapRequest?.getIn(['note', 'text'])

  const requestState = shiftSwapRequest.get('state')
  const isRequestApprovedOrDenied = requestState === 'confirmed' || requestState === 'denied'

  return (
    <div>
      <div className="shiftSwapDetailsCard" data-testid="shift-swap-details-card-component">
        <div className="requestFromDetailsCard">
          <div className="textWrapper" data-testid="from-text-component">
            <sh-text size="body-2">{`${t('staffCalendar.shiftSwap.swapFrom')}`}</sh-text>
          </div>
          <ShiftSwapDetails
            date={initatorDate}
            shiftTime={initatorShiftTime}
            unitName={initatorUnitName}
            shiftName={initatorShiftName}
            profile={inititatorProfile}
            position={inititatorPosition}
            isInitiator={initiated}
          />
        </div>
        <div className="requestToDetailsCard">
          <div className="textWrapper" data-testid="to-text-component">
            <sh-text size="body-2">{`${t('staffCalendar.shiftSwap.swapTo')}`}</sh-text>
          </div>
          <ShiftSwapDetails
            date={recipientDate}
            shiftTime={recipientShiftTime}
            unitName={recipientUnitName}
            shiftName={recipientShiftName}
            profile={recipientProfile}
            position={recipientPosition}
            isInitiator={!initiated}
          />
        </div>
      </div>
      {isRequestApprovedOrDenied && noteText && (
        <div className="unit-manager-comment-box">
          <sh-text size="title-1" color="secondary">
            {t('staffCalendar.shiftSwap.unitManagersComment')}
          </sh-text>
          <sh-text size="body-2" color="secondary">
            {noteText}
          </sh-text>
        </div>
      )}
    </div>
  )
}
