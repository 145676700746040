import { Component } from 'react'
import { List } from 'immutable'
import { generatePath, Redirect, Route } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import { navigate, paths } from 'Navigation'
import { Admins, UnitManager, Staff, SkillscheckAdministrator } from 'auth/Roles'

export class PrivateRoute extends Component {
  static defaultProps = {
    requireFacility: true
  }

  getMFARedirectPath = (auth, state) => {
    if (auth.get('mfaRequired')) {
      return <Redirect to={{ pathname: paths.Mfa, state }} />
    }
    return <Redirect to={{ pathname: paths.Login, state }} />
  }

  getRoleIds = (auth) => {
    return auth.getIn(['facilityUser', 'roleIds']) || List()
  }

  getNewPath = (unitUrlId, props, correctFacilityUrlId) => {
    return unitUrlId
      ? paths.Home
      : generatePath(props.match.path, {
          ...props.match.params,
          urlId: correctFacilityUrlId
        })
  }

  getCorrectFacility = (auth, facilities) => {
    return auth.get('facility') || facilities.get(0)
  }

  isUserUnauthorized = (isStaff, isManager, isAdmin, isInstanceAdministrator, isSkillscheckAdmin) => {
    return !isStaff && !isManager && !isAdmin && !isInstanceAdministrator && !isSkillscheckAdmin
  }

  redierectToAdminFacilities = (hasIdns, isAdminFacilityPath) => {
    return hasIdns && !isAdminFacilityPath
  }

  isComponentFeatureAndFacilityFeatures = (componentFeature, facilityFeatures) => {
    return componentFeature && facilityFeatures
  }

  isAdmin = (isInstanceAdministrator, roleIds) => {
    return isInstanceAdministrator || roleIds.some((roleId) => Admins.some((role) => role === roleId))
  }

  isStaff(roleIds) {
    return roleIds.size === 1 && roleIds.every((roleId) => roleId === Staff)
  }

  handleRedirect = (
    urlId,
    unitUrlId,
    auth,
    facilities,
    props,
    componentProps,
    Component,
    isSkillscheckAdmin,
    isStaff
  ) => {
    const { timeService, componentId } = this.props
    const correctFacility = this.getCorrectFacility(auth, facilities)
    const correctFacilityUrlId = correctFacility.get('urlId')
    if (correctFacilityUrlId !== urlId) {
      const newPath = this.getNewPath(unitUrlId, props, correctFacilityUrlId)
      return <Redirect to={newPath} />
    } else {
      const componentFeature = auth.getIn(['components', componentId, 'feature'])
      const facilityFeatures = correctFacility.getIn(['configuration', 'features'])
      if (this.isComponentFeatureAndFacilityFeatures(componentFeature, facilityFeatures)) {
        const featureState = facilityFeatures.find((feature) => feature.get('name') === componentFeature).get('state')

        const isComponentPermitted = ['read', 'write'].includes(featureState)
        if (!isComponentPermitted) {
          return <Redirect to={paths.Root} />
        }
        if (isSkillscheckAdmin) {
          return <Redirect to={paths.SkillscheckAdministratorPage} />
        }
        if (isStaff) {
          const { todayUsDate } = timeService
          return <Redirect to={navigate.from.FacilityHome.to.StaffCalendarPage({ date: todayUsDate })} />
        }
      }
    }
    return <Component {...props} {...componentProps} timeService={timeService} />
  }

  render() {
    const {
      component: Component,
      componentProps = {},
      roles = [],
      appState,
      requireFacility,
      timeService,
      ...rest
    } = this.props

    const auth = appState.get('authentication')
    const userRoles = auth.getIn(['facilityUser', 'roleIds'])
    const isAuthorized = roles.length === 0 || !userRoles || roles.some((role) => userRoles.includes(role))

    return (
      <Route
        {...rest}
        render={(props) => {
          const state = { from: props.location }
          const { pathname } = props.location
          const isForbiddenPath = navigate.isPath.Forbidden(pathname)
          const isAdminFacilityPath = navigate.isPath.AdminFacilities(pathname)
          const isAdminIdnsPath = navigate.isPath.AdminIdns(pathname)

          if (!auth.get('isLoggedIn')) {
            return this.getMFARedirectPath(auth, state)
          }

          const facilities = auth.get('facilities')
          const idns = auth.get('idns')
          const isInstanceAdministrator = auth.get('isInstanceAdministrator')
          const hasFacilities = facilities.size > 0
          const hasIdns = idns.size > 0

          const roleIds = this.getRoleIds(auth)
          const isManager = roleIds.some((roleId) => roleId === UnitManager)
          const isAdmin = this.isAdmin(isInstanceAdministrator, roleIds)
          const isStaff = this.isStaff(roleIds)
          const isSkillscheckAdmin = roleIds.some((roleId) => roleId === SkillscheckAdministrator)

          if (this.isUserUnauthorized(isStaff, isManager, isAdmin, isInstanceAdministrator, isSkillscheckAdmin)) {
            return <Redirect to={paths.Forbidden} />
          }

          if (requireFacility && !hasFacilities && !isForbiddenPath && !isInstanceAdministrator) {
            return <Redirect to={paths.Forbidden} />
          } else if (isForbiddenPath && hasFacilities) {
            return <Redirect to={paths.Root} />
          } else if (!isAuthorized) {
            return <Redirect to={paths.Root} />
          } else if (requireFacility && !hasFacilities && isInstanceAdministrator) {
            if (!hasIdns && !isAdminIdnsPath) {
              return <Redirect to={paths.AdminIdns} />
            }
            if (this.redierectToAdminFacilities(hasIdns, isAdminFacilityPath)) {
              return <Redirect to={paths.AdminFacilities} />
            }
          }

          const { urlId, unitUrlId } = props.match.params
          if (urlId) {
            return this.handleRedirect(
              urlId,
              unitUrlId,
              auth,
              facilities,
              props,
              componentProps,
              Component,
              isSkillscheckAdmin,
              isStaff
            )
          }

          return <Component {...props} {...componentProps} timeService={timeService} />
        }}
      />
    )
  }
}

export default withAppContext(PrivateRoute)
