import MonthNavigator from './MonthNavigator'
import { ControlledFilter } from 'Staff/Common'
import { useParams } from 'react-router'
import { useEffect, useContext } from 'react'
import { Set } from 'immutable'
import { defaultFilterOptionsBySection, filterOptions } from '../utils'
import { ItemTypeFilterContext } from '../CalendarContext'
import { t } from 'i18n'

export default function GridHeader(props: any) {
  const { timeService, date } = props
  const { section } = useParams<{ section: string }>()
  const { selectedCalendarFilters, addFilter, removeFilter, setFilters } = useContext(ItemTypeFilterContext)

  useEffect(() => {
    if (section && defaultFilterOptionsBySection[section]) {
      setFilters(Set(defaultFilterOptionsBySection[section]))
    }
  }, [section, setFilters])

  return (
    <div className="row hx-box-shadow col-12">
      <div className="col-3" data-testid="filter">
        <ControlledFilter
          filterMap={filterOptions}
          selectedFilters={selectedCalendarFilters}
          addFilter={addFilter}
          removeFilter={removeFilter}
          setFilters={setFilters}
          label={t('staffCalendar.itemTypeFilter.label')}
        />
      </div>
      <div className="row col-9 align-middle align-center" data-testid="month-navigator">
        <MonthNavigator timeService={timeService} date={date} />
      </div>
    </div>
  )
}
