import { List, Map } from 'immutable'

const ACCEPTANCE_CRITERIA = {
  firstToRespond: 'First to Respond',
  manualApprove: 'Manager Approval'
}

export default class OpenShiftEntity {
  constructor(openShift) {
    this.openShift = openShift
  }

  get id() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('id')
  }

  get withStaffDetails() {
    if (!this.openShift) {
      return false
    }
    return this.openShift.get('withStaffDetails')
  }

  get isFilled() {
    if (!this.openShift) {
      return false
    }
    return this.openShift.get('isCompleted')
  }

  get pendingToConfirmUserIds() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('pendingToConfirmUserIds')
  }

  get isPosted() {
    return !!this.id
  }

  get weekDay() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('weekDay')
  }

  get day() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('day')
  }

  get shiftName() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('shiftName')
  }

  get time() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('time')
  }

  get staffMismatch() {
    if (!this.openShift) {
      return 0
    }
    return this.openShift.get('staffMismatch')
  }

  get approvedFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('approvedFacilityUsers')
  }

  get availableFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('availableFacilityUsers')
  }

  get isCompleted() {
    if (!this.openShift) {
      return false
    }
    return !!this.openShift.get('isCompleted')
  }

  get isPastOpenShift() {
    if (!this.openShift) {
      return false
    }
    return this.openShift.get('isPastOpenShift')
  }

  get isManagerApproval() {
    if (!this.openShift) {
      return false
    }
    return this.acceptanceCriteria === 'manualApprove'
  }

  get note() {
    return ''
  }

  get isBonusPay() {
    if (!this.openShift) {
      return false
    }
    return this.incentiveBonus && this.incentiveBonus.length > 0
  }

  get acceptanceCriteria() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('acceptanceCriteria') || 'manualApprove'
  }

  get eligibleUserIds() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('eligibleUserIds')
  }

  // Not yet implemented:
  get isAlertEligibleStaff() {
    return false
  }

  get isCrisisPay() {
    if (!this.openShift) {
      return false
    }
    return this.openShift.get('crisisPay')
  }

  get shiftDayId() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('shiftDayId')
  }

  get startsAt() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('shiftStartsAt')
  }

  get unitId() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('unitId')
  }

  get unitName() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('unitName')
  }

  get unitRoleId() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('unitRoleId')
  }

  get acceptanceCriteriaText() {
    if (!this.openShift) {
      return ''
    }
    return ACCEPTANCE_CRITERIA[this.acceptanceCriteria]
  }

  get postedAt() {
    if (!this.openShift) {
      return ''
    }
    if (!this.isPosted) {
      return 'Not posted'
    }
    if (this.postedDaysAgo < 1) {
      return 'Posted today'
    }
    if (this.postedDaysAgo === 1) {
      return 'Posted 1 day ago'
    }

    return `Posted ${this.postedDaysAgo} days ago`
  }

  get postedDaysAgo() {
    if (!this.openShift || !this.isPosted) {
      return 0
    }
    return this.openShift.get('postedDaysAgo')
  }

  get updatedAt() {
    if (!this.openShift) {
      return null
    }
    if (this.isPosted) {
      return this.openShift.get('updatedAt')
    }
    return null
  }

  get updatedAtFormatted() {
    if (!this.openShift || !this.createdAt) {
      return ''
    }
    return this.openShift.get('updatedAtFormatted')
  }

  get createdAt() {
    if (!this.openShift) {
      return null
    }
    if (this.isPosted) {
      return this.openShift.get('createdAt')
    }
    return null
  }

  get createdAtFormatted() {
    if (!this.openShift || !this.createdAt) {
      return ''
    }
    return this.openShift.get('createdAtFormatted')
  }

  get createdAtDateTime() {
    if (!this.openShift || !this.createdAt) {
      return ''
    }
    return this.openShift.get('createdAtDateTime')
  }

  get startsAtDate() {
    if (!this.openShift) {
      return ''
    }
    return this.openShift.get('startsAtDate')
  }

  get shiftId() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('shiftId')
  }

  get endsAt() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('shiftEndsAt')
  }

  get duration() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('duration')
  }

  get availableUserIds() {
    if (!this.openShift) {
      return List()
    }
    if (this.isPosted) {
      return this.openShift.get('availableUserIds')
    }

    return this.eligibleUserIds
  }

  get isFirstToRespond() {
    if (!this.openShift) {
      return false
    }
    return this.acceptanceCriteria === 'firstToRespond'
  }

  get facilityUserIds() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('facilityUserIds')
  }

  get approvedUserIds() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('approvedUserIds')
  }

  get acceptedByUserIds() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('acceptedByUserIds')
  }

  get deniedByUserIds() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('deniedByUserIds')
  }

  get facilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('facilityUsers')
  }

  get eligibleFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('eligibleFacilityUsers')
  }

  get notRespondedFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('notRespondedFacilityUsers')
  }

  get declinedFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('declinedFacilityUsers')
  }

  get acceptedFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('acceptedFacilityUsers')
  }

  get notInvitedFacilityUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('notInvitedFacilityUsers')
  }

  get pendingToConfirmUsers() {
    if (!this.openShift) {
      return List()
    }
    return this.openShift.get('pendingToConfirmUsers')
  }

  get incentiveBonus() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('incentiveBonus')
  }

  get staffAssignedWeekHoursMap() {
    if (!this.openShift) {
      return Map()
    }
    return this.openShift.get('staffAssignedWeekHoursMap')
  }

  get isReady() {
    return this.withStaffDetails
  }

  get requireManagerApproval() {
    if (!this.openShift) {
      return false
    }
    return this.openShift.get('requireManagerApproval')
  }

  get dayIndexInWeek() {
    if (!this.openShift) {
      return null
    }
    return this.openShift.get('dayIndexInWeek')
  }

  get expertiseRequirements() {
    if (!this.openShift) {
      return []
    }
    return this.openShift.get('expertiseRequirements')
  }

  static buildKey(shiftId, shiftDayId) {
    return `${shiftId}|${shiftDayId}`
  }
}
