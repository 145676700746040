import { fromJS } from 'immutable'
import { NotesController } from '../Notes'
import { hasPTO, hasUnavailable } from '../cellDetailsUtils'

const emptyNote = { text: '', subject: '', isEditMode: true }

export default class TimeOffNotesController extends NotesController {
  get defaultState() {
    return {
      ...super.defaultState,
      StaffPaidTimeOffNotes: fromJS([emptyNote]),
      StaffUnavailabilityNotes: fromJS([emptyNote])
    }
  }

  get StaffPaidTimeOffNotes() {
    return this.state.StaffPaidTimeOffNotes
  }

  get StaffUnavailabilityNotes() {
    return this.state.StaffUnavailabilityNotes
  }

  get note() {
    const { lastNote } = this

    if (!lastNote) {
      return null
    }

    const subject = lastNote.get('subject')
    const text = lastNote.get('text')

    return { subject, text }
  }

  set StaffPaidTimeOffNotes(notes) {
    this.setState({ StaffPaidTimeOffNotes: notes })
  }

  set StaffUnavailabilityNotes(notes) {
    this.setState({ StaffUnavailabilityNotes: notes })
  }

  buildNotesState(notes) {
    const { cell } = this.props
    const notesState = fromJS(notes ? notes : [emptyNote])

    if (hasPTO(cell)) {
      return { StaffPaidTimeOffNotes: notesState }
    }

    if (hasUnavailable(cell)) {
      return { StaffUnavailabilityNotes: notesState }
    }

    return {}
  }
}
