import { Component } from 'react'
import CreateEntity from '@humanics/he-react-common/lib/admin/components/CreateEntity'
import { withAppContext } from 'AppContext'

export class UnitCreate extends Component {
  render() {
    const { timeService } = this.props
    const today = timeService.timeMoment(null)
    const availableDay = today.add(7 - today.day(), 'day').format('MM-DD-YYYY')
    const usDateToday = timeService.usDateToMoment(availableDay).toISOString()
    const startOfMonth = today.startOf('month').format('MM-DD-YYYY')
    const usFirstDateOfMonth = timeService.usDateToMoment(startOfMonth).toISOString()

    const createProps = {
      inputDefinitionExtraFields: {
        schedulingStartsAt: {
          label: 'Scheduling start date',
          inputType: 'InputDateFlatPickr',
          format: 'date-time',
          dateFormatHint: '(yyyy-mm-dd, Sundays only)',
          required: true,
          isDateDisabledMethod: function (date) {
            return date.getDay() !== 0
          },
          value: usDateToday
        },
        monthlySchedulingStartsAt: {
          label: 'Scheduling start date',
          inputType: 'InputDateFlatPickr',
          format: 'date-time',
          dateFormatHint: '(yyyy-mm-dd, first date of the month only)',
          required: true,
          isDateDisabledMethod: function (date) {
            return date.getDate() !== 1
          },
          value: usFirstDateOfMonth
        }
      }
    }

    const getSchedulingStartsAtDate = (form) => {
      const scheduleType = form.scheduleType
      const isMonthlySchedule = scheduleType === 'monthly'

      if (isMonthlySchedule) {
        return form.monthlySchedulingStartsAt
      }

      return form.schedulingStartsAt
    }

    const onSubmit = (e, form) => {
      const { store, history } = this.props
      const createOperationId = 'createUnit'
      const schedulingStartsAt = getSchedulingStartsAtDate(form)

      const unit = {
        ...form,
        schedulingStartsAt
      }

      delete unit['monthlySchedulingStartsAt']

      return store.create({ createOperationId, form: unit }).then(() => history.push('.'))
    }

    return <CreateEntity {...this.props} {...createProps} onSubmit={onSubmit} />
  }
}

export default withAppContext(UnitCreate)
