import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import Flatpickr from 'react-flatpickr'

export default class DateRange extends PureComponent {
  static propTypes = {
    flatpickrOptions: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any
  }

  render() {
    const { flatpickrOptions = {}, disabled, label, value, onChange } = this.props

    const options = {
      altInput: true,
      altFormat: 'Y-m-d',
      dateFormat: 'm-d-Y',
      allowInput: true,
      ...flatpickrOptions
    }
    const { allowInput } = options

    const flatpickrProps = {
      'data-allow-input': allowInput,
      className: 'date-range-picker',
      onChange,
      placeholder: label,
      value,
      options
    }

    return (
      <div className="range-input form-item">
        <label>
          <span className="form-label">{label}</span>
          <span className="description semibold">YYYY-MM-DD</span>
        </label>
        <Flatpickr {...flatpickrProps} disabled={disabled} />
        <i className="icon icon-calendar regent-gray" />
      </div>
    )
  }
}
