import { useEffect, useState } from 'react'
import './Footer.scss'
import { t } from 'i18n'

const Footer = ({ requestShiftSwapData, handleSubmitButtonClick, handleClearButtonClick }: any) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    const swappingStaff = requestShiftSwapData.swappingStaff
    const yourShift = requestShiftSwapData.yourShift

    if (
      swappingStaff.get('staffProfile') &&
      swappingStaff.get('shiftDaysToSwapFor')?.size &&
      yourShift.get('selectedShift')
    ) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [requestShiftSwapData]);

  return (
    <div className="request-shift-swap-footer bg-white mt10">
      <div className="btn-clear-request">
        <sh-button
          label={t('staffCalendar.shiftSwap.labels.resetShiftSwap')}
          color="secondary"
          size="m"
          onClick={handleClearButtonClick}
        />
      </div>
      <div className="btn-submit-request">
        <sh-button
          label={t('staffCalendar.shiftSwap.labels.createShiftSwap')}
          color="primary"
          size="m"
          disabled={isSubmitButtonDisabled ? true : undefined}
          onClick={handleSubmitButtonClick}
        />
      </div>
    </div>
  )
}

export default Footer
