import { cloneDeep } from 'lodash'
import ExpertiseFormController from '../ExpertiseFormController'

import form from './certificationFormDefinitions'
import { buildIdNameMapping, isAnyRequiredFieldEmpty, getFieldValueMapping } from '../../utils'

export default class CertificationFormController extends ExpertiseFormController {
  get defaultState() {
    return {
      form,
      isLoading: false,
      isValidForm: true
    }
  }

  initForm() {
    const { form } = cloneDeep(this.state)

    this.#initDropdownItems(form)
    this.#initFieldAccessibilities(form)
    this.#initFieldValues(form)

    if (this.isFormToEdit()) {
      this.formValuesBeforeEdit = getFieldValueMapping(form)
    }

    const isValidForm = this.isValidForm(form)
    this.setState({ form, isValidForm })
  }

  isValidForm(form) {
    return !(isAnyRequiredFieldEmpty(form) || (this.isFormToEdit() && this.areFormValuesUnchanged(form)))
  }

  setFieldValue = (value, field) => {
    const { form } = this.state
    form[field].value = value

    const isValidForm = this.isValidForm(form)
    this.setState({ form, isValidForm })
  }

  serializeForm = () => {
    const { form } = this.state

    return [
      {
        expertiseId: form.certification.value,
        issuedBy: form.organization.value,
        metadata: { number: form.certificationNumber.value },
        expiresAt: form.expirationDate.value
      }
    ]
  }

  #initDropdownItems(form) {
    const { expertises } = this.props
    const certificationsIdNameMapping = buildIdNameMapping(expertises, 'id', 'fullName', {
      type: 'certification'
    })
    form.certification.definition.enum = certificationsIdNameMapping
  }

  #initFieldAccessibilities(form) {
    if (this.isFormToEdit()) {
      form.certification.definition.disabled = true
    }
  }

  #initFieldValues(form) {
    const { timeService, expertiseId, staffExpertiseCredentials } = this.props

    form.certification.value = ''
    form.organization.value = ''
    form.certificationNumber.value = ''
    form.expirationDate.value = timeService.today.add(1, 'years').toISOString()
    if (this.isFormToEdit()) {
      const staffCertificationCredential = staffExpertiseCredentials.find(
        (staffExpertiseCredential) => staffExpertiseCredential.get('expertiseId') === expertiseId
      )

      form.certification.value = expertiseId
      form.organization.value = staffCertificationCredential.get('issuedBy') || ''
      form.certificationNumber.value = staffCertificationCredential.get('metadata')?.get('number') || ''
      form.expirationDate.value = staffCertificationCredential.get('expiresAt') || ''
    }
  }
}
