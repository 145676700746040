import { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { t } from 'i18n'

export default class TimeJump extends Component {
  static propTypes = {
    timeService: PropTypes.object.isRequired,
    onChangeTimeFilter: PropTypes.func.isRequired,
    currentActivityDate: PropTypes.string
  }

  static defaultProps = {
    currentActivityDate: null
  }

  buttonActions = Map({
    today: () => '',
    day: (date) => date.subtract(1, 'days').toISOString(),
    week: (date) => date.subtract(1, 'weeks').toISOString(),
    month: (date) => date.subtract(1, 'months').toISOString()
  })

  goToTimeKey = (when) => {
    const { currentActivityDate, timeService, onChangeTimeFilter } = this.props
    const lastSeen = currentActivityDate ? timeService.timeMoment(currentActivityDate) : timeService.today

    onChangeTimeFilter(this.buttonActions.get(when)(lastSeen))
  }

  render() {
    const { currentActivityDate, timeService } = this.props
    const shouldRenderTodayButton = currentActivityDate && !timeService.today.isSame(currentActivityDate, 'day')

    const timeJumpProps = {
      t,
      onClick: this.goToTimeKey
    }

    return (
      <div className="timeJump hide">
        <div className="timeJumpControls">
          {shouldRenderTodayButton && <JumpButton icon="icon icon-Chevron---Up" timeKey="today" {...timeJumpProps} />}
          <span>{t('activities.time_jump.title')}</span>
          <JumpButton icon="icon icon-Chevron---Down" timeKey="day" {...timeJumpProps} />
          <JumpButton icon="new-icon-week" timeKey="week" {...timeJumpProps} />
          <JumpButton icon="new-icon-month" timeKey="month" {...timeJumpProps} />
        </div>
      </div>
    )
  }
}

function JumpButton({ timeKey, t, icon, onClick, onMouseOver, onMouseOut, ...props }) {
  return (
    <button className="button" onClick={() => onClick(timeKey)} {...props}>
      <i className={icon} /> {t(`activities.time_jump.${timeKey}`)}
    </button>
  )
}
