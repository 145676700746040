import { useEffect, useState } from 'react'
import { withAppContext } from 'AppContext'

function ShiftOvertime(props) {
  const { unit, activeDateRange, calendarStore } = props
  const [shiftOvertimeCount, setShiftOvertimeCount] = useState(0)
  const [shiftOvertimeHours, setShiftOvertimeHours] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const unitId = unit?.get('id')
  const isReady = unit?.get('isReady')
  const isSchedulesReady = unit.get('isSchedulesLoaded')
  const schedule = unit?.get('schedule')
  const startDate = schedule?.get('startsAt')
  const endDate = schedule?.get('endsAt')

  useEffect(() => {
    const data = async () => {
      if (!unitId || !isReady || !activeDateRange?.get('isReady')) {
        return
      }

      setIsLoading(true)

      if (!isSchedulesReady) {
        return
      }

      let totalShiftOvertimeCount = 0
      let totalShiftOvertimeHours = 0

      try {
        const staffOvertimeData = await calendarStore.loadStaffOvertimeHoursByShift({ unitId, startDate, endDate })
        const shiftOvertimeMapData = staffOvertimeData?.getIn(['calendar', 'shiftOvertimeMap'])
        shiftOvertimeMapData?.forEach((overtimeData) => {
          totalShiftOvertimeCount += overtimeData.get('staffOvertime').size
          totalShiftOvertimeHours += overtimeData.get('totalShiftOvertime')
        })
        setShiftOvertimeCount(totalShiftOvertimeCount)
        setShiftOvertimeHours(totalShiftOvertimeHours)
        setIsLoading(false)
      } catch (error) {
        console.error('Failed at loadStaffOvertimeHoursByShift:', error)
      }
    }

    data()
  }, [activeDateRange, calendarStore, endDate, isReady, isSchedulesReady, startDate, unitId])

  return (
    <>
      <div className="laborprofile-common-card">
        <div className="hx-card-header">
          <div className="col-11 card-heading" style={{ paddingLeft: '1rem' }}>
            Shift Overtime
          </div>
          {/* TODO: Uncomment where redirect icon is to be implemented */}
          {/* {!isLoading && (
            <div className="col-1 icon-box" onClick={() => navigate.from.Dashboard.to.OpenShifts({})}>
              <sh-icon icon="arrow-line-right-s"></sh-icon>
            </div>
          )} */}
        </div>
        <div className="hx-card-details">
          {isLoading ? (
            <div style={{ margin: 'auto', padding: '16px' }}>
              <sh-spinner></sh-spinner>
            </div>
          ) : (
            <>
              <div className="col-5 hx-card-detail">
                <div className="h60 countFont">{shiftOvertimeCount}</div>
                <div className="h20 labelFont">Shift Overtimes</div>
              </div>
            </>
          )}
        </div>
        <br />

        <div className="hx-card-header">
          <div className="col-11 card-heading" style={{ paddingLeft: '1rem' }}>
            Overtime Hours
          </div>
        </div>
        <div className="hx-card-details">
          {isLoading ? (
            <div style={{ margin: 'auto', padding: '16px' }}>
              <sh-spinner></sh-spinner>
            </div>
          ) : (
            <>
              <div className="col-5 hx-card-detail">
                <div className="h60 countFont">{shiftOvertimeHours}</div>
                <div className="h20 labelFont">Hours</div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default withAppContext(ShiftOvertime)
