import { PureComponent } from 'react'
import { navigate } from 'Navigation'
import { List } from 'immutable'
import CustomSelect from 'Manager/components/SelectFilter/CustomSelect'

const spanMenu = { full: 'Full', day: 'Day', week: 'Week' }
const viewMenu = {
  staff: 'Staff',
  shift: 'Shift',
  equipment: 'Equipment',
  subspeciality: 'Subspecialty'
}

export default class SelectMode extends PureComponent {
  render() {
    const { mode } = this.props
    const unitViewPreference = this.getUnitViewPreference()
    return (
      <>
        <CustomSelect
          name="View"
          hideName={true}
          options={viewMenu}
          value={unitViewPreference}
          updateConfig={this.setViewPreference}
        />
        <CustomSelect name="Span" options={spanMenu} value={mode} updateConfig={this.setMode} />
      </>
    )
  }

  setViewPreference = async (viewPreference) => {
    const { onChangeViewPreference, viewPreferences, unitId, onChangeResourceType } = this.props
    if (viewPreference === 'equipment' || viewPreference === 'subspeciality') {
      await onChangeResourceType(List([viewPreference]))
      return
    }
    viewPreferences['units'][unitId] = { viewPreference, roles: {} }
    await onChangeResourceType(List(['shift']))
    onChangeViewPreference(viewPreferences, undefined, true)
  }

  getUnitViewPreference = () => {
    const { viewPreferences, unitId, filteredResourceTypes } = this.props
    if (filteredResourceTypes?.includes('subspeciality')) {
      return 'subspeciality'
    }
    if (filteredResourceTypes?.includes('equipment')) {
      return 'equipment'
    }

    return viewPreferences['units']?.[unitId]?.['viewPreference']
  }

  setMode = (mode) => {
    const date = this.getDateToNavigateTo(mode)
    navigate.from.Calendar.to.Calendar({ date, mode })
  }

  getDateToNavigateTo(newMode) {
    const { activeDateRange } = this.props
    const currentMode = activeDateRange.get('mode')
    const todayUsDate = activeDateRange.get('todayUsDate')

    const isCurrentModeWeek = currentMode === 'week'
    const isCurrentModeDay = currentMode === 'day'

    const isNewModeWeek = newMode === 'week'
    const isNewModeDay = newMode === 'day'

    const isNavigationFromDayToWeekMode = isCurrentModeDay && isNewModeWeek
    const isNavigationFromWeekToDayMode = isCurrentModeWeek && isNewModeDay

    if (isNavigationFromDayToWeekMode || isNavigationFromWeekToDayMode) {
      const isTodayInUsDateWeek = activeDateRange.get('isTodayInUsDateWeek')
      const usDateWeekSunday = activeDateRange.get('usDateWeekSunday')

      return isTodayInUsDateWeek ? todayUsDate : usDateWeekSunday
    }

    const isTodayIncluded = activeDateRange.get('isTodayIncluded')
    const firstDayUsDate = activeDateRange.get('firstDayUsDate')

    return isTodayIncluded ? todayUsDate : firstDayUsDate
  }
}
