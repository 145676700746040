import Header from '../Header'
import StaffItem from '../StaffItem'

export default function OutcomingStaff(props) {
  const { outcomingStaff, unitId, displayNameAs, timeService } = props

  if (outcomingStaff.size === 0) {
    return null
  }

  return (
    <>
      <h2 className="hx-transferring-staff-header slate-gray">&nbsp;&nbsp;Outgoing Staff</h2>
      <Header />
      <ul className="bg-white">
        {outcomingStaff.map((user, index) => (
          <StaffItem
            unitId={unitId}
            displayNameAs={displayNameAs}
            timeService={timeService}
            facilityStaff={user}
            key={index}
            namespace="row hx-calendar-staff bb1"
          />
        ))}
      </ul>
    </>
  )
}
