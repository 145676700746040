import { PureComponent, createRef } from 'react'
import { Map } from 'immutable'
import classNames from 'classnames'
import './Cell.scss'

export default class CellHour extends PureComponent {
  ref = createRef()

  componentDidUpdate(prevProps) {
    const { showPopup, cell, isMultipleCellsSelected, isCellSelected } = this.props
    const {
      cell: prevCell,
      isMultipleCellsSelected: prevIsMultipleCellsSelected,
      isCellSelected: prevIsCellSelected
    } = prevProps

    const isLastSelectedCell = cell?.get('isSelected')
    const prevIsLastSelectedCell = prevCell?.get('isSelected')
    const isCellSelectionChanged =
      isLastSelectedCell !== prevIsLastSelectedCell || isCellSelected !== prevIsCellSelected
    const isCellsSelectionChanged = isMultipleCellsSelected !== prevIsMultipleCellsSelected
    const isSelectionChanged = isCellsSelectionChanged || isCellSelectionChanged

    if (isSelectionChanged && isLastSelectedCell && isCellSelected) {
      showPopup(this.ref.current)
    }
  }

  render() {
    const { cell = Map(), hourCell, cellIndex = 0, isFirstHour, isLastHour, isUnderstaffed } = this.props

    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const identityHash = cellStaffEvent.get('identityHash') || 'empty'

    const className = classNames('_cell _full fullview-cell-icons rock-blue', {
      'left-border': isFirstHour,
      'right-border': isLastHour,
      isUnderstaffed: isUnderstaffed
    })
    const { dayIndex } = hourCell
    return (
      <div
        tabIndex={0}
        data-is-cell={true}
        data-cell-index={cellIndex}
        data-day-cell-index={dayIndex}
        data-cell-identity-hash={identityHash}
        className={className}
        width={'100%'}
        ref={this.ref}
      >
        {/*{isProcessing && <i className="loading spindle" />}*/}
      </div>
    )
  }
}
