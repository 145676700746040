import { Children, cloneElement, Component } from 'react'
import './Row.scss'

export default class Row extends Component {
  render() {
    const { timeService, shiftDay, event } = this.props

    const startTime = shiftDay.get('startTime')
    const startsAt = shiftDay.get('startsAt')
    const endsAt = shiftDay.get('endsAt')
    const shiftDayLength = shiftDay.get('length')

    const staffEventDuration = event?.get('duration')
    const type = event?.get('type')

    const [hours, minutes] = startTime.split(':')
    let eventLength = shiftDayLength
    let eventStart = parseInt(hours, 10) * 60 + parseInt(minutes)

    let momentStarts, momentEnds

    const isShiftEvent = type === 'assignment' || type === 'meeting' || type === 'onCall'

    if (isShiftEvent) {
      eventLength = staffEventDuration
      const staffEventStartsAt = event?.get('startsAt')
      const staffEventEndsAt = event?.get('endsAt')

      momentStarts = timeService.timeMoment(staffEventStartsAt)
      momentEnds = timeService.timeMoment(staffEventEndsAt)
      eventStart = momentStarts.diff(momentStarts.clone().startOf('day'), 'minutes', true)
    } else {
      momentStarts = timeService.timeMoment(startsAt)
      momentEnds = timeService.timeMoment(endsAt)
    }

    const shiftTime = `${momentStarts.format('HH:mm')} - ${momentEnds.format('HH:mm')}`

    const startOffsetHours = 6
    const widthHours = eventLength / 60
    const offsetHours = eventStart / 60 - startOffsetHours
    const style = {
      width: `${(widthHours * 100) / 24}%`,
      marginLeft: `${(offsetHours * 100) / 24}%`
    }

    const childrenWithExtraProp = Children.map(this.props.children, (child) => {
      return cloneElement(child, { shiftTime })
    })

    return (
      <section className="col-10">
        <div className="hx-calendar-staff-days bg-alabaster">
          <div className="relative w100">
            <Rulers />

            <div className="relative item-wrapper">
              <div style={style} className="hx-calendar-staff-shift-wrapper">
                {childrenWithExtraProp}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

function Rulers() {
  return (
    <div className="rulers">
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
      <div>
        <span></span>
      </div>
    </div>
  )
}
