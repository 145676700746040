import { PureComponent, Fragment } from 'react'
import { pick } from 'lodash'
import { AutoSizer, List } from 'react-virtualized'
import DateRange, { Role, Shift, Staff } from './DateRange'
import Footer from 'Footer'
import { Map, fromJS } from 'immutable'
import { DateRangeService } from 'services'

export default class Grid extends PureComponent {
  componentDidMount() {
    this.props.resetLoadConfig()
  }

  render() {
    const { getViewModel, overscanRowCount, unit } = this.props
    const raw = getViewModel()
    const listSize = raw.size

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <div className="container hx-calendar-grid">
            <List
              className="hx-vgrid"
              ref={(instance) => (this.listRef = instance)}
              width={width}
              onScroll={this._handleScroll}
              defaultHeight={500}
              rowRenderer={this.rowRenderer(raw)}
              onRowsRendered={this._onRowsRendered(listSize)}
              height={this.props.offsetHeight}
              overscanRowCount={overscanRowCount}
              rowCount={listSize}
              unit={unit}
              rowHeight={this.props.getRowHeightByIndex(raw)}
            />
          </div>
        )}
      </AutoSizer>
    )
  }

  _handleScroll = ({ scrollTop }) => this.props.updateScrollTop(scrollTop)

  _onRowsRendered =
    (rowsCount) =>
    ({ stopIndex: lastRenderedRowIndex }) => {
      this.props.loadDataOnScroll(rowsCount, lastRenderedRowIndex)
    }

  rowRenderer =
    (raw) =>
    ({ index, isScrolling, key, style }) => {
      const { allTimeOffRequestsLoaded } = this.props
      let datum = this.getDatum(raw, index)
      const kind = datum.get('kind')

      const isStaff = kind === 'staff'
      const isShift = kind === 'shift'
      const isRole = kind === 'role'
      const isFooter = kind === 'footer'
      const isDateRange = kind === 'date-range'

      if (isStaff) {
        const isLastRoleStaff = raw.getIn([index + 1, 'kind']) === 'date-range'
        datum = datum.set('isLastRoleStaff', isLastRoleStaff)
      }

      if (!isFooter && !isScrolling) {
        const dateRangeIndex = isDateRange ? datum.get('index') : datum.get('dateRangeIndex')
        this.props.loadDateRangeTimeOffs(dateRangeIndex)
      }

      return (
        <Fragment key={key}>
          {isFooter && allTimeOffRequestsLoaded && this.renderFooter(style)}
          {isStaff && this.renderStaff(datum, key, style)}
          {isShift && this.renderShift(datum, key, style)}
          {isRole && this.renderRole(datum, key, style)}
          {isDateRange && this.renderDateRange(datum, key, style)}
        </Fragment>
      )
    }

  renderDateRange = (dateRange, key, style) => {
    const { unit, timeService } = this.props
    const isMonththlySchedule = unit.get('scheduleType') === 'monthly'

    dateRange = isMonththlySchedule ? DateRangeService.getMonthExtendedDateRange(dateRange, timeService) : dateRange
    const dateRangeProps = pick(
      {
        ...this.props,
        ...this.state,
        dateRange: DateRangeService.getMonthExtendedDateRange(dateRange, timeService)
      },
      ['dateRange', 'timeService', 'countDateRangeTimeOffs']
    )
    this.lastRenderedDateRange = dateRangeProps.dateRange
    return <DateRange {...dateRangeProps} style={style} key={key} />
  }

  renderStaff(staff, key, style) {
    const { selection } = this.props
    const selectedStaffPath = selection.get('staffPath')

    const dateRangeIndex = staff.get('dateRangeIndex')
    const roleIndex = staff.get('roleIndex')
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')

    const staffPath = { dateRangeIndex, roleIndex, shiftIndex, staffIndex }
    const onCellEvent = (cellIndex, eventType, meta) => this.props.onCellEvent(staffPath, cellIndex, eventType, meta)

    const onTimeOffRequestEvent = (timeOffRequestId, eventType, meta) =>
      this.props.onTimeOffRequestEvent(staffPath, timeOffRequestId, eventType, meta)

    const {
      dateRangeIndex: selectedDateRangeIndex,
      staffIndex: selectedStaffIndex,
      shiftIndex: selectedShiftIndex,
      roleIndex: selectedRoleIndex
    } = selectedStaffPath

    const isDateRangeSelected = selectedDateRangeIndex === dateRangeIndex
    const isStaffSelected = selectedStaffIndex === staffIndex
    const isShiftSelected = selectedShiftIndex === shiftIndex
    const isRoleSelected = selectedRoleIndex === roleIndex

    const isRowSelected = isDateRangeSelected && isRoleSelected && isShiftSelected && isStaffSelected
    const actualSelection = isRowSelected ? selection : Map({})

    const rangeAdjustedCells = this.getDateRangeAdjustedCells(staff.get('cells'), this.lastRenderedDateRange)
    const cells = fromJS(rangeAdjustedCells)

    const staffProps = pick(
      {
        ...this.props,
        staff: staff.set('cells', cells),
        onCellEvent,
        onTimeOffRequestEvent,
        selection: actualSelection
      },
      ['staff', 'showPopup', 'selection', 'onCellEvent', 'timeService', 'onTimeOffRequestEvent']
    )

    return <Staff {...staffProps} key={key} style={style} />
  }

  getDateRangeAdjustedCells = (cells, dateRange) => {
    let cellIndexToAddNext = 0
    return (
      dateRange?.get('days').map((day, i) => {
        const cellDate = cells.get(cellIndexToAddNext)?.get('dateTime')
        const headerDate = day.dateTime
        if (cellDate !== headerDate) {
          return Map({ dateTime: headerDate, isDisabled: true, index: i })
        }
        return cells.get(cellIndexToAddNext++).set('index', i)
      }) || cells
    )
  }

  renderShift = (shift, key, style) => {
    const shiftProps = pick({ ...this.props, shift }, ['shift', 'timeService'])

    return <Shift {...shiftProps} key={key} style={style} />
  }

  renderRole = (role, key, style) => {
    const roleProps = pick({ ...this.props, role }, ['role', 'timeService'])

    return <Role {...roleProps} key={key} style={style} />
  }

  renderFooter = (style) => {
    return (
      <div style={style} className="footer-wrapper">
        <Footer />
      </div>
    )
  }

  getDatum = (raw, index) => {
    return raw.get(index % raw.size)
  }
}
