import Staff from './Staff'
import { OpenShiftStaffRequirementsService } from '../services'

export default class OpenShiftStaff extends Staff {
  constructor(openShiftEntity, facilityUser) {
    super(facilityUser)
    this.openShiftEntity = openShiftEntity
  }

  get openShift() {
    return this.openShiftEntity
  }

  get isAccepted() {
    const { acceptedByUserIds } = this.openShiftEntity
    return acceptedByUserIds?.includes(this.userId)
  }

  get isOvertimed() {
    return this.hoursThisWeek > this.maximumNumberOfHoursPerWeek
  }

  get hoursThisWeek() {
    const { staffAssignedWeekHoursMap } = this.openShiftEntity

    if (!staffAssignedWeekHoursMap) {
      return null
    }

    return staffAssignedWeekHoursMap.get(this.userId)
  }

  get isEligible() {
    const { eligibleUserIds } = this.openShiftEntity
    return eligibleUserIds.includes(this.userId)
  }

  expertiseRequirements(expertises, timeService) {
    const { expertiseRequirements } = this.openShiftEntity
    const staffRequirementsService = new OpenShiftStaffRequirementsService(timeService)
    return staffRequirementsService.getStaffMatchingRequirements(
      expertises,
      expertiseRequirements,
      this.expertiseCredentials
    )
  }
}
