import { Component } from 'react'
import classNames from 'classnames'
import { Staff } from 'entityWrappers'
import { curry } from 'i18n'
import { omit } from 'lodash'
import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import CustomPhoneNumberInput from '@humanics/he-react-common/lib/components/Form/CustomPhoneNumberInput'
import CustomDateInput from '@humanics/he-react-common/lib/components/Form/CustomDateInput'
import CustomNumericInput from '@humanics/he-react-common/lib/components/Form/CustomNumericInput'
import CustomDropDown from '@humanics/he-react-common/lib/components/Form/CustomDropdown'
import CustomDropdownEnum from 'components/Form/CustomDropdownEnum'
import form from './formDefinitions'
import FormController from './FormController'
import './CreateEditStaff.scss'
import { EMAIL_ERROR_CODES, PHONE_NUMBER_ERROR_CODES } from '../../../utils/GraphQLErrors'
import FormActionBar from '../StaffDetails/CreateEditExpertiseModals/FormActionBar'
import stores from 'stores'
const { generalStore } = stores

const t = curry('staff.')

export default class CreateEditStaffForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      form,
      isLoading: false,
      showEndsAt: false
    }

    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.controller = new FormController(this)
  }

  componentWillUnmount() {
    const form = this.controller._resetForm(this.state.form)
    this.controller.setState({ form })
  }

  componentDidMount() {
    this.controller.updateForm()
  }

  componentDidUpdate(prevProps) {
    const { staff, staffPositions, units } = this.props
    const { staff: prevStaff, staffPositions: prevStaffPositions, units: prevUnits } = prevProps
    const isStaffChanged = staff !== prevStaff
    const isStaffPositionsChanged = staffPositions.size !== prevStaffPositions.size
    const isUnitsChanged = units.size !== prevUnits.size

    if (isStaffChanged || isStaffPositionsChanged || isUnitsChanged) {
      this.controller.updateForm()
    }
  }

  onClickSubmit = async (e) => {
    e.preventDefault()

    const { onSubmit, onClose, isEdit, Dialog, reloadStaff, currentUnitId } = this.props

    let data = this.controller.serializeForm()

    data.maximumNumberOfHoursPerWeek = parseFloat(data.maximumNumberOfHoursPerWeek)

    if (isEdit) {
      data = omit(data, ['email', 'phoneNumber'])
    }
    const hasError = !this.controller.validateForm(true)
    if (hasError) {
      return
    }

    const hasToBeTerminated = isEdit && !!data.endsAt
    if (hasToBeTerminated) {
      const isTerminationConfirmed = await Dialog.confirm(t('terminateStaffConfirmation.text'), {
        type: 'warning',
        title: t('terminateStaffConfirmation.title'),
        accept: t('terminateStaffConfirmation.accept')
      })

      if (!isTerminationConfirmed) {
        return
      }
    }

    try {
      this.setState({ isLoading: true })
      await onSubmit(data)
      if (!isEdit) {
        await reloadStaff()
      } //to show newly added staff in staff page
      await generalStore.reloadFacilityUsersInActiveDateRange() //to update in calendar page
      await generalStore.refreshNotifications(currentUnitId) //to update notification badge count
      this.setState({ isLoading: false })
      onClose()
    } catch (error) {
      const { form } = this.state
      let emailError, phoneError
      /*
      Error codes (except for "COR-53", which is the one working) were retrieved from:
      https://gitlab.com/humanics/he-core/blob/develop/config/errors.yaml
      However, they don't seem to react properly.
      the error on this endpoint response comes in the form of err.err.graphQLErrors[...errors]
      */
      if (error?.graphQLErrors) {
        emailError = error.graphQLErrors.some((err) => EMAIL_ERROR_CODES.includes(err.code))
        phoneError = error.graphQLErrors.some((err) => PHONE_NUMBER_ERROR_CODES.includes(err.code))
      }

      if (emailError) {
        form.email.inlineError = t('auth.email_already_used')
      }
      if (phoneError) {
        form.phoneNumber.inlineError = t('auth.phone_already_used')
      }

      this.setState({ isLoading: false, form })
    }
  }

  render() {
    const { onClose, timeService, units, staffPositions, isEdit } = this.props
    const { showEndsAt } = this.state
    const isStaffDataLoaded = units.size > 0 && staffPositions.size > 0

    if (!isStaffDataLoaded) {
      return null
    }

    const { isLoading } = this.state
    const form = this.controller.getFormProperties()
    const isFormValid = this.controller.isFormValid()

    return (
      <form className={'form mb0 create-edit-staff-form'}>
        {isEdit && this.renderStaffTransferring()}
        <div className="p2 pb0 row">
          <StringInputField
            {...form.firstName}
            onChange={(e) => this.controller.setFieldValue(e, 'firstName')}
            onBlur={() => this.controller.validateField('firstName')}
          />

          <StringInputField
            {...form.lastName}
            onChange={(e) => this.controller.setFieldValue(e, 'lastName')}
            onBlur={() => this.controller.validateField('lastName')}
          />

          <StringInputField
            {...form.email}
            onChange={(e) => this.controller.setFieldValue(e, 'email')}
            onBlur={() => this.controller.validateField('email')}
          />

          <CustomPhoneNumberInput
            {...form.phoneNumber}
            onChange={(e) => this.controller.setFieldValue(e, 'phoneNumber')}
            onBlur={() => this.controller.validateField('phoneNumber')}
          />

          <CustomPhoneNumberInput
            {...form.phoneNumber1}
            onChange={(e) => this.controller.setFieldValue(e, 'phoneNumber1')}
            onBlur={() => this.controller.validateField('phoneNumber1')}
          />

          <CustomPhoneNumberInput
            {...form.phoneNumber2}
            onChange={(e) => this.controller.setFieldValue(e, 'phoneNumber2')}
            onBlur={() => this.controller.validateField('phoneNumber2')}
          />

          <CustomDropdownEnum
            {...form.homeUnitId}
            onChange={(e) => this.controller.setFieldValue(e, 'homeUnitId')}
            propKey={'id'}
            propValue={'name'}
          />
          <CustomDropdownEnum
            {...form.position}
            onChange={(e) => this.controller.setFieldValue(e, 'position')}
            propKey={'id'}
            propValue={'name'}
          />

          <CustomDropDown
            {...form.employmentType}
            onChange={(e) => this.controller.setFieldValue(e, 'employmentType')}
            propKey={'key'}
            propValue={'name'}
          />

          <CustomNumericInput
            {...form.maximumNumberOfHoursPerWeek}
            withArrows={true}
            onChange={(e) => this.controller.setFieldValue(e, 'maximumNumberOfHoursPerWeek')}
          />

          <CustomDropDown
            {...form.availabilityType}
            onChange={(e) => this.controller.setFieldValue(e, 'availabilityType')}
            propKey={'key'}
            propValue={'name'}
          />

          <CustomDateInput
            {...form.startsAt}
            timeService={timeService}
            onChange={(e) => this.controller.setFieldValue(e, 'startsAt')}
          />

          {isEdit && (
            <>
              <CustomDateInput
                {...form.endsAt}
                formItemClasses={classNames(form.endsAt.formItemClasses, {
                  hide: !(form.endsAt.value || showEndsAt)
                })}
                timeService={timeService}
                onChange={(e) => this.controller.setFieldValue(e, 'endsAt')}
              />

              <div
                className={classNames({
                  'form-item col-6 pr30': true,
                  hide: form.endsAt.value || showEndsAt
                })}
              >
                <label>
                  <span className="form-label">&nbsp;</span>
                </label>
                <input
                  className="form-control input center-align"
                  placeholder="Add End Date"
                  type="text"
                  onClick={() => this.setState({ showEndsAt: true })}
                />
              </div>
            </>
          )}

          <StringInputField
            {...form.managerFullName}
            onChange={(e) => this.controller.setFieldValue(e, 'managerFullName')}
            onBlur={() => this.controller.validateField('managerFullName')}
          />
        </div>
        <footer className="_footer">
          <FormActionBar
            submitLabel="Save"
            isSubmitLoading={isLoading}
            isSubmitDisabled={!isFormValid}
            onSubmit={this.onClickSubmit}
            onCancel={onClose}
          />
        </footer>
      </form>
    )
  }

  renderStaffTransferring() {
    const { staff, timeService } = this.props
    const facilityUser = new Staff(staff)
    const { transferringUnit } = facilityUser

    if (!transferringUnit) {
      return null
    }

    const transferringUnitName = transferringUnit.get('homeUnitName')
    const startsAt = transferringUnit.get('startsAt')
    const startsAtMoment = timeService.timeMoment(startsAt).format('MMM DD, YYYY')
    return (
      <div className="hx-transfer-container">
        <div className="hx-staff-profile-transferring-edit-form">
          <span className="hx-status">{`${t('transfering')} `}</span>
          {`${t('transferingTo', { unit: transferringUnitName })}`}
          <span className="staff-transfer-dot slate-gray">{startsAtMoment}</span>
        </div>
      </div>
    )
  }
}
