import { Component } from 'react'
import { Modal } from 'Common/components'
import classNames from 'classnames'
import Form from '@humanics/he-react-common/lib/admin/components/Form'

class ShiftTypeAndRoleCreateUpdate extends Component {
  onClose = () => {
    this.setState({ visible: false })
    this.props.history.push('.')
  }

  componentDidMount() {
    const { isUnitsLoaded } = this.controller

    if (!isUnitsLoaded) {
      this.controller.loadUnits()
    }
  }

  getLabelType = (labelType) => {
    return labelType === 'update' ? 'Update' : 'Create'
  }

  getEntityType = (entityType) => {
    return entityType === 'Role' ? 'Role' : 'ShiftType'
  }

  getEntityName = (entityType) => {
    return entityType === 'Role' ? 'Role' : 'Shift Type'
  }

  renderModal(onFormSubmit, inputDefinition, role, timeService, labelType = '', entityType = 'Role') {
    const { stores, history, appState } = this.props
    const actionLabel = this.getLabelType(labelType)
    const entityTypeLabel = this.getEntityType(entityType)
    const entityName = this.getEntityName(entityType)
    return (
      <Modal visible={this.state.visible} onClose={this.onClose} title={`${actionLabel} ${entityName}`}>
        <div>
          <section className="modal-form">
            <div
              className={classNames('message error', {
                hide: !this.state.error
              })}
            >
              <i className="icon-error" />
              <span>{this.state.error}</span>
              <span
                className="close"
                onClick={(e) => {
                  this.setState({ error: '' })
                }}
              />
            </div>
            <div>
              {this.state.loading ? (
                <div>Loading...</div>
              ) : (
                <Form
                  okLabel={actionLabel}
                  onSubmit={onFormSubmit}
                  entityType={entityTypeLabel}
                  inputDefinition={inputDefinition}
                  stores={stores}
                  defaultValues={role}
                  history={history}
                  appState={appState}
                  timeService={timeService}
                />
              )}
            </div>
          </section>
        </div>
      </Modal>
    )
  }
}
export default ShiftTypeAndRoleCreateUpdate
