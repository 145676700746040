import { mapValues, keyBy, chunk } from 'lodash'
import { staffAssignedWeekHoursQuery } from '../Queries'

export default async function getStaffAssignedWeekHoursMap(gqlClient, date, facilityUserIds) {
  const userIdsChunks = chunk(facilityUserIds, 100)
  const promises = userIdsChunks.map((facilityUserIds) => {
    const parameters = { date, userIds: facilityUserIds }
    return gqlClient.query(staffAssignedWeekHoursQuery, parameters)
  })

  const results = await Promise.all(promises)
  const staffAssignedWeekHours = results.reduce((memo, result) => memo.concat(result.staffAssignedWeekHours), [])

  const hoursGroupped = keyBy(staffAssignedWeekHours, 'userId')

  return mapValues(hoursGroupped, (staffHours) => staffHours.assignedHours)
}
