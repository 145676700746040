import { useEffect, useState } from 'react'
import { navigate } from 'Navigation'

function _getFirstPageParameters(timeService, dateRangeDurationInYears) {
  const today = timeService.timeMoment(null).startOf('day')
  const startDate = today.toISOString()
  const endDate = today.clone().add(dateRangeDurationInYears, 'years').toISOString()

  return { startDate, endDate, page: 1 }
}

function TimeOffDetailsCard({ timeService, unit, timeOffStore }) {
  const [totalTimeOffRequests, setTotalTimeOffRequests] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const unitId = unit?.get('id')

  useEffect(() => {
    if (!unitId) {
      return
    }

    setIsLoading(true)
    const parameters = _getFirstPageParameters(timeService, 5)
    const { startDate, endDate, page } = parameters
    timeOffStore.resetData()
    timeOffStore.loadTimeOffRequestsWithSchedules(unitId, startDate, endDate, page).then((appState) => {
      const timeOffState = appState.get('timeOff')
      const timeOffRequests = timeOffState?.get('timeOffRequests')
      const timeOffMap = timeOffRequests?.get('timeOffRequestsMap')

      setTotalTimeOffRequests(timeOffMap?.size ?? 0)
      setIsLoading(false)
    })
  }, [unitId, timeService, timeOffStore])

  return (
    <>
      <div data-testid="todo-common-card-time-off" className="todo-common-card">
        <div className="hx-card-header">
          <div className="col-2 icon-box">
            <div className="icon-timeoff"></div>
          </div>
          <div className="col-9 card-heading">Time Off Requests</div>
          {!isLoading && (
            <div className="col-1 icon-box" onClick={() => navigate.from.Dashboard.to.TimeOffPendingRequests({})}>
              <sh-icon icon="arrow-line-right-s"></sh-icon>
            </div>
          )}
        </div>
        <div className="hx-card-details">
          {isLoading ? (
            <div style={{ margin: 'auto', padding: '16px' }}>
              <sh-spinner></sh-spinner>
            </div>
          ) : (
            <div className="col-5 hx-card-detail">
              <div className="h60 hx-card-detail-first-box">
                <div className="countFont">{totalTimeOffRequests}</div>
                {totalTimeOffRequests > 0 && (
                  <div className="icon-box warning-icon">
                    <div className="icon-warning"></div>
                  </div>
                )}
              </div>
              <div className="h20 labelFont">Approvals Required</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default TimeOffDetailsCard
