import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { navigate, paths } from 'Navigation'
import RoleCreateUpdate from './RoleCreateUpdate'

export default class RoleUpdate extends RoleCreateUpdate {
  render() {
    const { isUnitsLoaded } = this.controller
    if (!isUnitsLoaded) {
      return null
    }

    const { store, appState, match } = this.props
    const url = this.props.location.search

    const { unitId } = queryString.parse(url)
    if (!unitId) {
      return <Redirect to={paths.AdminRoles} />
    }

    const { urlId, id } = match.params

    const timeService = appState.getIn(['context', 'facilityTime'])

    const onFormSubmit = async (event, form) => {
      try {
        await this.controller.updateUnitRole(unitId, id, form)
        await this.controller.loadUnits()
        return navigate.to.AdminRoles({ urlId })
      } catch {
        return navigate.to.AdminRoles({ urlId })
      }
    }
    let { properties } = store.getInputDefinition('OverrideUnitRoleInput')

    const role = this.controller.getUnitRole(unitId, id)
    if (!role) {
      return null
    }

    const { isManagement: isManagementRole } = role
    const unitHasManagementRole = this.controller.unitHasManagementRole(unitId)

    if (unitHasManagementRole && !isManagementRole) {
      const { isManagement, ...rest } = properties
      properties = rest
    } else if (isManagementRole) {
      properties = { ...properties, isManagement: { ...properties.isManagement, disabled: true } }
    }

    const inputDefinition = { properties }
    return this.renderModal(onFormSubmit, inputDefinition, role, timeService, 'update', 'Role')
  }

  onSubmit = (e, form) => {
    // This is intentional
  }
}
