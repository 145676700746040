export const enum USER_EVENTS {
  // Calendar
  USER_ENTERED_SCHEDULE = 'E001',
  USER_EXITED_SCHEDULE = 'E002',
  USER_CHANGED_SCHEDULE = 'E003',
  USER_CHANGED_UNIT = 'E004',
  USER_ENTERED_BALANCING_SCHEDULE = 'E005',
  USER_EXITED_BALANCING_SCHEDULE = 'E006',

  // Load Balancing / Slot Recommender
  TIME_TAKEN_BY_USER_TO_BOOK_SLOT_VIA_SLOT_RECOMMENDER = 'SR001',
  USER_EXITED_PATIENT_SLOT_RECOMMENDER_WITHOUT_BOOKING = 'SR002',
  SLOT_RECOMMENDER_BOOKING_FAILED = 'SR003'
}
