import { Component } from 'react'
import moment from 'moment'
import './lastLoginBanner.scss'
import { readMyLastLogin } from '../../stores/humanics/GAS/Queries'

class LastLoginBanner extends Component {
  constructor() {
    super()
    this.state = { lastLoginAt: undefined }
  }

  async componentDidMount() {
    const gasGQLClient = this.props.appState.getIn(['authentication', 'gasGQLClient'])
    try {
      const {
        readMyLastLogin: { dateTime }
      } = await gasGQLClient.query(readMyLastLogin)
      this.setState({ lastLoginAt: dateTime })
    } catch (e) {
      this.setState({ lastLoginAt: null })
    }
  }

  render() {
    return (
      <div className="row align-middle align-right last-login-banner">
        <div className="col-3 last-login-label">
          <sh-text size="title-2">Last login</sh-text>
        </div>
        <div className="col-8 last-login-value">{this.lastLoginValueRender(this.state.lastLoginAt)}</div>
      </div>
    )
  }

  lastLoginValueRender = (lastLoginAt) => {
    if (!lastLoginAt) {
      return <sh-spinner size="s" />
    }

    if (this.isNullDate(lastLoginAt)) {
      return <sh-text size="body-2">No prior login found</sh-text>
    }

    const loginDate = moment(lastLoginAt).format("DD MMM 'YY")
    const loginTime = moment(lastLoginAt).format('h:mm a')
    return (
      <>
        <sh-text size="body-2">{loginTime}</sh-text>
        <sh-text size="body-2">{loginDate}</sh-text>
      </>
    )
  }

  isNullDate = (lastLoginAt) => {
    const unixEpoch = new Date(0).toISOString()
    return !lastLoginAt || lastLoginAt === unixEpoch
  }
}

export default LastLoginBanner
