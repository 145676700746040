import { Component, createRef } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import './CreateEditDetailText.scss'

const MAX_LENGTH = 1000

export default class CreateEditDetailText extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: this.emptyEditorState()
    }
    this.setDomEditorRef = createRef()
  }

  componentDidMount(ref) {
    this.setDomEditorRef.current.focusEditor()
  }

  onEditorStateChange = (editorState) => {
    const { activeComponent: component, updateDetailsComponent } = this.props
    const contentToSave = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    component.content = { editorState, contentToSave }

    updateDetailsComponent(component)
    this.setState({ editorState })
  }

  _getSelectedTextLength = () => {
    const { editorState } = this.state
    const currentSelection = editorState.getSelection()

    if (currentSelection.isCollapsed()) {
      return 0
    }

    let textLength = 0
    const currentContent = editorState.getCurrentContent()
    const startKey = currentSelection.getStartKey()
    const endKey = currentSelection.getEndKey()
    const startBlock = currentContent.getBlockForKey(startKey)
    const startBlockTextLength = startBlock.getLength()
    const startSelectedTextOffset = currentSelection.getStartOffset()
    const startSelectedTextLength = startBlockTextLength - startSelectedTextOffset
    const endSelectedTextOffset = currentSelection.getEndOffset()
    const keyAfterEnd = currentContent.getKeyAfter(endKey)
    if (startKey === endKey) {
      textLength += endSelectedTextOffset - startSelectedTextOffset
    } else {
      let currentKey = startKey

      while (currentKey && currentKey !== keyAfterEnd) {
        if (currentKey === startKey) {
          textLength += startSelectedTextLength + 1
        } else if (currentKey === endKey) {
          textLength += endSelectedTextOffset
        } else {
          textLength += currentContent.getBlockForKey(currentKey).getLength() + 1
        }

        currentKey = currentContent.getKeyAfter(currentKey)
      }
    }

    return textLength
  }

  emptyEditorState = () =>
    EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.activeComponent.content.contentToSave)))

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newEditorState = nextProps.activeComponent.content.editorState
    this.setState({ editorState: newEditorState || this.emptyEditorState() })
  }

  handleBeforeInput = () => {
    const { editorState } = this.state
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = this._getSelectedTextLength()

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return 'handled'
    }
  }

  handlePastedText = (pastedText) => {
    const { editorState } = this.state
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = currentContent.getPlainText('').length
    const selectedTextLength = this._getSelectedTextLength()

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      return 'handled'
    }
  }

  renderCounter = () => {
    const { editorState } = this.state
    const contentState = editorState.getCurrentContent()
    let currentCount = contentState.getPlainText().length
    return MAX_LENGTH - currentCount
  }

  render() {
    const { editorState } = this.state

    return (
      <form className="Form" onSubmit={this.handleSubmit}>
        <div className="detail-text-component flex col-12 align-center">
          <div className="editor-box">
            <div>
              <Editor
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                placeholder={'Enter text here...'}
                handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class row"
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'emoji'],
                  inline: { options: ['bold', 'italic', 'underline'] },
                  blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote']
                  },
                  list: { options: ['unordered', 'ordered'] }
                }}
                ref={this.setDomEditorRef}
              />
            </div>

            <div className="detail-text-counter gray">{this.renderCounter()}</div>
          </div>
        </div>
      </form>
    )
  }
}
