import { PureComponent } from 'react'
import { paths } from 'Navigation'
import { Route } from 'react-router'
import { withAppContext } from 'AppContext'
import PendingRequests from './PendingRequests'
// import YearView from './YearView';
import Header from './Header'
import './TimeOffLayout.scss'

class TimeOffLayout extends PureComponent {
  state = { shiftType: 'all', staffRole: 'all' }

  onChangeShift = (shiftType) => this.setState({ shiftType })
  onChangeStaff = (staffRole) => this.setState({ staffRole })

  render() {
    const { match, unit } = this.props
    const { staffRole, shiftType } = this.state
    const { componentId: view } = match.params
    const roles = unit.get('roles')

    const headerProps = {
      view,
      roles,
      staffRole,
      shiftType,
      onChangeShift: this.onChangeShift,
      onChangeStaff: this.onChangeStaff
    }

    const filters = { staffRole, shiftType }

    return (
      <section className="h100">
        <div data-testid="time-offs-layout" className="wrapper-container time-offs-container">
          <Header {...headerProps} />
          <Route
            path={paths.TimeOffPendingRequests}
            render={(props) => renderComponentWithProps(PendingRequests, { ...props, filters })}
          />
          {/*<Route
            path={paths.TimeOffYearView}
            render={props => renderComponentWithProps(YearView, { ...props, filters })}
          />*/}
        </div>
      </section>
    )
  }
}

function renderComponentWithProps(Component, componentProps, props) {
  const allProps = { ...props, ...componentProps }
  return <Component {...allProps} />
}

export default withAppContext(TimeOffLayout)
