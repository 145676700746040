import { calculateKpiQuery } from '../Queries'

function Kpis() {
  let gqlClient = null
  const actions = { calculateKpi }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  async function calculateKpi(scheduleId, unitId) {
    const kpiResult = await gqlClient.query(calculateKpiQuery, { scheduleId, unitId })
    return kpiResult?.calculateKpi
  }
}

export default Kpis()
