import classNames from 'classnames'
import { PureComponent } from 'react'
import { curry } from 'i18n'
import './DateSelectFilter.scss'
import DateRange from './DateRange'
import PropTypes from 'prop-types'

const t = curry('components.')

export default class DateSelectFilter extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    isActive: PropTypes.bool,
    onChange: PropTypes.func,
    values: PropTypes.object
  }

  static defaultProps = {
    values: {}
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { name, isActive, onClick, values } = this.props
    const { dates } = values
    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    let textValue = this.getTextValue(values)

    const dateRangeProps = {
      onChange: this.updateConfig,
      value: dates,
      flatpickrOptions: {
        mode: 'range',
        dateFormat: 'Y-m-d'
      },
      label: 'Creation date period'
    }

    return (
      <aside className="hx-filters">
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={onClick}
        >
          <div className="option-value">
            <sh-text>{name}: </sh-text>
            <div className="cmsf selected-value">
              <sh-text size="title-1">
                {textValue}
                <span>
                  <i className={arrowClassName} />
                </span>
              </sh-text>
            </div>
          </div>
        </button>
        <div className="custom-multiselect-filter p20 bg-white filters-content" onMouseDown={(e) => e.preventDefault()}>
          <DateRange {...dateRangeProps} />
        </div>
      </aside>
    )
  }

  getTextValue(selectedDates) {
    const { dateStr } = selectedDates
    return dateStr ? dateStr : t('select.all')
  }

  updateConfig = (dates, dateStr) => {
    const { setConfig } = this.props
    return setConfig({ dates, dateStr })
  }
}
