const StatusBadge = ({ label, color }: any) => {
  let containerStyle = {}
  let tagStyle = {}

  if (color.rgb) {
    containerStyle = { backgroundColor: color.rgb }
    tagStyle = { backgroundColor: `rgba(255, 255, 255, ${color.opacity})` }
  } else if (color.rgba) {
    tagStyle = { backgroundColor: color.rgba }
  }
  return (
    <div style={containerStyle} className="status-badge">
      <sh-tag label={label} style={tagStyle}></sh-tag>
    </div>
  )
}

export default StatusBadge
