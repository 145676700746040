import { useEffect } from 'react'
import './TimeOffCategory.scss'
import { t } from 'i18n'
import { TimeOffDefinitionsService } from 'services'

const paidTimeOffCategories = TimeOffDefinitionsService.buildPaidTimeOffOptions()
const requestTimeOffCategories = TimeOffDefinitionsService.buildUnavailabilityOptions()

export type TimeOffCategoryProps = {
  selectedCategory: string
  selectedSubCategory: string
  onCategoryChange: (category: string, subCategory: string) => void
}

export default function TimeOffCategory({ selectedCategory, selectedSubCategory, onCategoryChange }: TimeOffCategoryProps) {
  const subCategories = selectedCategory === t('staffCalendar.timeOff.category.paidTimeOff') ? paidTimeOffCategories : requestTimeOffCategories

  const handleMenuItemClick = (category: string) => {
    const categoryLabel = category === 'paidTimeOff' ? t('staffCalendar.timeOff.category.paidTimeOff') : t('staffCalendar.timeOff.category.requestTimeOff') 
    onCategoryChange(categoryLabel, '')
  }

  const handleSubCategoryClick = (subCategory: string) => {
    onCategoryChange(selectedCategory, subCategory)
  }

  useEffect(() => {
    onCategoryChange(selectedCategory, selectedSubCategory)
  }, [selectedCategory, selectedSubCategory])

  return (
    <div className="time-off-category">
      <sh-card label={t('staffCalendar.timeOff.chooseTimeOfCategory')}>
        <div className="row-nowrap g10">
          <div className="col-8">
            <sh-dropdown 
              label={t('staffCalendar.timeOff.chooseTimeOfCategory')} 
              no-clear 
              value={selectedCategory}
              data-testid="category-dropdown"
            >
              <sh-menu-item
                label={t('staffCalendar.timeOff.category.paidTimeOff')}
                onClick={() => handleMenuItemClick('paidTimeOff')}
                data-testid="paid-time-off-item"
              ></sh-menu-item>
              <sh-menu-item
                label={t('staffCalendar.timeOff.category.requestTimeOff')}
                onClick={() => handleMenuItemClick('requestTimeOff')}
                data-testid="request-time-off-item"
              ></sh-menu-item>
            </sh-dropdown>
          </div>
          <sh-dropdown
            value={selectedSubCategory}
            label={t('staffCalendar.timeOff.chooseTypeOfTimeOff')}
            disabled={!selectedCategory ? true : undefined}
            data-testid="sub-category-dropdown"
          >
            {subCategories?.map((subCategory, index) => (
              <sh-menu-item
                key={index}
                label={subCategory}
                onClick={() => handleSubCategoryClick(subCategory)}
                data-testid={`sub-category-item-${index}`}
              ></sh-menu-item>
            ))}
          </sh-dropdown>
        </div>
      </sh-card>
    </div>
  )
}