import GridCell from './GridCell'

const calendarColumns = 7
const columnIndexes: any = []
for (let i = 0; i < calendarColumns; i++) {
  columnIndexes.push(i)
}
export default function GridRow({
  rowIndex,
  date,
  timeService,
  shifts,
  staffEvents,
  initiatedShiftSwapRequests,
  receivedShiftSwapRequests,
  staffDaysMap,
  section,
  setSelectedCellDate 
}: any) {
  return (
    <tr key={rowIndex}>
      {columnIndexes.map((colIndex: any) => (
        <GridCell
          key={colIndex}
          colIndex={colIndex}
          rowIndex={rowIndex}
          date={date}
          timeService={timeService}
          shifts={shifts}
          staffEvents={staffEvents}
          initiatedShiftSwapRequests={initiatedShiftSwapRequests}
          receivedShiftSwapRequests={receivedShiftSwapRequests}
          staffDaysMap={staffDaysMap}
          section={section}
          setSelectedCellDate={setSelectedCellDate}
        />
      ))}
    </tr>
  )
}
