import { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Admin, Header } from '@humanics/he-react-common/lib/admin/components'
import ModalWithConfirmation from '@humanics/he-react-common/lib/admin/components/ModalWithConfirmation'
import ShiftTypesController from './ShiftTypesController'
import { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [
  Field('title', 'Title', { format: 'plain' }),
  Field('startTime', 'Start Time', { format: 'plain' }),
  Field('duration', 'Duration minutes')
]

export default class ShiftTypesAdmin extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.controller = new ShiftTypesController(this)
    const data = this.controller.getShiftTypes()
    this.state = { fields, data }
  }

  componentDidMount() {
    const { store } = this.props
    store.initializeApi()
    store.loadDataAction()
  }

  componentDidUpdate(prevProps) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const prevStoreState = prevAppState.get(stateKey)
    const isStoreStateChanged = storeState !== prevStoreState

    if (isStoreStateChanged) {
      const data = this.controller.getShiftTypes()
      this.setState({ data })
    }
  }

  render() {
    const { store, stores, appState, location, history, subRoutes, match } = this.props
    const { isConfirmationModalVisible, data } = this.state

    const isApiInitialized = store.isApiInitialized(appState)

    if (!isApiInitialized) {
      return null
    }

    const { stateKey } = store

    // Rework grid to accept parameters.
    const groupAction = {
      linkTo: location.pathname.replace(/\/$/, '') + '/new',
      actionTitle: 'Create Shift Type'
    }

    const isReorderable = true
    const groupItems = {
      groupBy: 'unitId',
      groupNameField: 'unitName'
    }

    const fields = [
      ...this.state.fields,
      {
        render: (dataRow, index, isCurrentRowDraggable, ref) => {
          const { id: shiftTypeId, unitId } = dataRow
          return (
            <td ref={ref}>
              <span className="float-right pr10">
                <button
                  onClick={(e) => this.onDeleteShiftTypeInitiated(e, shiftTypeId, unitId)}
                  className="button danger primary"
                  disabled={false}
                >
                  Delete
                </button>
              </span>
            </td>
          )
        }
      }
    ]

    const confirmationModalProps = {
      modalTitle: 'Delete Shift Type',
      confirmationMessage: 'Do you really want to delete this shift type?',
      isCancelVisible: true,
      isConfirmVisible: true,
      onCancelConfirmation: () => this.setState({ isConfirmationModalVisible: false, deleteParameters: null }),
      onConfirm: this.onDeleteShiftType,
      isModalVisible: isConfirmationModalVisible,
      isConfirmationVisible: isConfirmationModalVisible
    }

    return (
      <>
        <ModalWithConfirmation {...confirmationModalProps} />
        <Admin>
          <Header title="Shift Types" actions={null} />
          <Grid
            orderField="position"
            history={history}
            Link={Link}
            updateItemPosition={this.updateItemPosition}
            renderDataRow={this.renderDataRow}
            data={data}
            store={store}
            dataType={stateKey}
            fields={fields}
            allowUpdate={true}
            groupItems={groupItems}
            stores={stores}
            groupAction={groupAction}
            isReorderable={isReorderable}
            currentPath={match.url}
          />
          {subRoutes}
        </Admin>
      </>
    )
  }

  onDeleteShiftTypeInitiated = (event, shiftTypeId, unitId) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ isConfirmationModalVisible: true, deleteParameters: { shiftTypeId, unitId } })
  }

  updateItemPosition = (itemId, newPosition, groupValue) => {
    this.controller.moveShiftTypeToPosition(itemId, newPosition, groupValue)
  }

  onDeleteShiftType = async () => {
    const { shiftTypeId, unitId } = this.state.deleteParameters

    await this.controller.deleteUnitShiftType(unitId, shiftTypeId)
    await this.controller.loadUnits()

    this.setState({ isConfirmationModalVisible: false, deleteParameters: null })
  }
}
