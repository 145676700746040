import { PureComponent } from 'react'
import { StaffEvent } from 'entityWrappers'
import './Event.scss'

export default class Staff extends PureComponent {
  render() {
    const { event, staffPrimaryShiftId } = this.props

    const staffEvent = new StaffEvent(event)
    const { isAssignment, isPTO, isUnavailable, shiftId: staffEventShiftId } = staffEvent

    const notes = event.get('notes')
    const lastNote = notes?.last()
    const description = lastNote?.get('subject')
    const descriptionIconClassName = this._getDescriptionIconClassName(description)

    const eventTime = this._getEventTime(staffEvent)
    const isPrimaryShiftEvent = staffPrimaryShiftId === staffEventShiftId

    return (
      <div className="hx-calendar-staff-shift rounded bg-white">
        {isAssignment && isPrimaryShiftEvent && <i className="icon-dot" />}
        {isAssignment && !isPrimaryShiftEvent && <i className="icon-circle" />}
        {isPTO && <i className="icon-indicator-money-sign" />}
        {isUnavailable && <i className="icon-close" />}
        {eventTime && <span className="event-time">{eventTime}</span>}
        {descriptionIconClassName && <i className={descriptionIconClassName} />}
        {description && <span className="description">{description}</span>}
      </div>
    )
  }

  _getEventTime(staffEvent) {
    const { isAssignment, staffEventStartsAt, staffEventEndsAt } = staffEvent
    if (!isAssignment) {
      return null
    }

    const { timeService } = this.props

    return timeService.formatTimeRange(staffEventStartsAt, staffEventEndsAt)
  }

  _getDescriptionIconClassName(description) {
    switch (description) {
      case 'Call Off':
        return 'icon-indicator-call-back'
      case 'On Call':
        return 'icon-indicator-call'
      case 'Shift Swap':
        return 'icon-indicator-swap'
      case 'Class':
        return ''
      default:
        return null
    }
  }
}
