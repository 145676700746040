import { Redirect } from 'react-router-dom'
import { List } from 'immutable'
import { withAppContext } from 'AppContext'
import { navigate } from 'Navigation'
import { UnitManager } from 'auth/Roles'
import stores from 'stores'

// Current path is
// *humanics.com/facility-id/unit-url-id
function DefaultRedirect({ appState }) {
  const auth = appState.get('authentication')
  const roleIds = auth.getIn(['facilityUser', 'roleIds']) || List()
  const isManager = roleIds.some((roleId) => roleId === UnitManager)

  // TODO: <PrivateRoute roles={[]} /> might redirect to `/forbidden` instead of `/`
  if (!isManager) {
    return <Redirect to={navigate.to.Forbidden({}, false)} />
  }

  // TODO: the user has access to this unit?

  // Top-Navigation: HMN-1955 at this point
  // always redirect to calendar...
  const date = stores.timeService().todayUsDate
  const { to } = navigate.from.FacilityUnitHome

  return <Redirect to={to.Dashboard({ date }, false)} />
}

export default withAppContext(DefaultRedirect)
