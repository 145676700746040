import {
  remoteEquipmentProceduresQuery,
  remoteEquipmentServiceTypesQuery,
  remoteStaffAvailabilitiesQuery,
  remoteStaffRequestsQuery
} from './Queries'
import { cancelRemoteStaffRequestMutation, createRemoteStaffRequestMutation } from './Mutations'

import { IRemoteWorkflowResponse } from './models/remoteWorkflowResponse'

import { IRemoteEquipmentServiceTypeData } from './models/remoteEquipmentServiceTypeData'
import { IRemoteEquipmentProcedureData } from './models/remoteEquipmentProcedureData'
import { IRemoteStaffAvailabilityData } from './models/remoteStaffAvailabilityData'
import { IRemoteStaffRequest } from './models/remoteStaffRequest'

import { IRemoteStaffAvailabilityInput } from './models/remoteStaffAvailabilityInput'
import { ICreateRemoteStaffRequestInput } from './models/createRemoteStaffRequestInput'

export type IRemoteWorkflowStore = ReturnType<typeof RemoteWorkflowStore>

export default function RemoteWorkflowStore() {
  let gqlClient: any = null

  return {
    initialize,
    getRemoteServicesTypesForEquipment,
    getProceduresAndSkillsForServiceType,
    getRemoteStaffAvailabilities,
    createRemoteStaffRequest,
    getRemoteStaffRequests,
    cancelRemoteStaffRequest
  }

  function initialize(state: any, context: any) {
    gqlClient = context.gqlClient
    return state
  }

  async function getRemoteServicesTypesForEquipment(
    equipmentId: string
  ): Promise<IRemoteWorkflowResponse<IRemoteEquipmentServiceTypeData>[]> {
    const { remoteEquipmentServiceTypes } = await gqlClient.query(remoteEquipmentServiceTypesQuery, { equipmentId })

    return remoteEquipmentServiceTypes
  }

  async function getProceduresAndSkillsForServiceType(
    equipmentId: string,
    weScanServiceType: string
  ): Promise<IRemoteWorkflowResponse<IRemoteEquipmentProcedureData>[]> {
    const { remoteEquipmentProcedures } = await gqlClient.query(remoteEquipmentProceduresQuery, {
      equipmentId,
      weScanServiceType
    })

    return remoteEquipmentProcedures
  }

  async function getRemoteStaffAvailabilities(
    equipmentId: string,
    data: IRemoteStaffAvailabilityInput
  ): Promise<IRemoteWorkflowResponse<IRemoteStaffAvailabilityData>[]> {
    const { remoteStaffAvailabilities } = await gqlClient.query(remoteStaffAvailabilitiesQuery, {
      equipmentId,
      ...data
    })

    return remoteStaffAvailabilities
  }

  async function createRemoteStaffRequest(shiftDayId: string, data: ICreateRemoteStaffRequestInput): Promise<void> {
    await gqlClient.mutate(createRemoteStaffRequestMutation, { shiftDayId, parameters: data })
  }

  async function getRemoteStaffRequests(shiftDayId: string): Promise<IRemoteStaffRequest[]> {
    const { remoteStaffRequests } = await gqlClient.query(remoteStaffRequestsQuery, { shiftDayIds: [shiftDayId] })
    return remoteStaffRequests
  }

  async function cancelRemoteStaffRequest(id: string): Promise<void> {
    await gqlClient.mutate(cancelRemoteStaffRequestMutation, { id })
  }
}
