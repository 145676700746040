import { Component } from 'react'
import { navigate } from 'Navigation'
import { CrudCardTable, CrudCard } from 'Manager/components'
import constants from './CreateEditRecurringScheduleModal/constants'
import { curry } from 'i18n'
import { List } from 'immutable'
import stores from 'stores'

const { staffStore } = stores
const { weekDaysShortArray } = constants
const t = curry('staff.recurringScheduleDeleteConfirmation.')

export default class StaffSchedule extends Component {
  render() {
    const { staffRecurringSchedules = List(), timeService } = this.props

    const createRecurringScheduleLink = navigate.from.StaffDetails.to.StaffCreateRecurringSchedule(
      {
        staffRecurringScheduleId: null
      },
      false
    )

    const tableProps = this.tableTransform(staffRecurringSchedules, timeService)

    return (
      <div data-testid="staff-recurring-schedule" className="recurring-schedule">
        <CrudCard
          title="Recurring Schedule"
          createItemPath={createRecurringScheduleLink}
          createItemTooltip="Create recurring schedule"
          createItemIcon="plus"
        >
          <CrudCardTable {...tableProps} addSeparator={true} emptyTableMessage="No recurring schedule found" />
        </CrudCard>
      </div>
    )
  }

  tableTransform(staffRecurringSchedules) {
    const columns = [
      { dataId: 'unit', width: 2 },
      { dataId: 'shift', width: 2 },
      { dataId: 'range', width: 1 },
      { dataId: 'startDate', width: 2 },
      { dataId: 'endDate', width: 2 },
      { dataId: 'repeats', width: 2 },
      { dataId: 'icons', width: 1 }
    ]

    const rows = [
      {
        unit: 'Unit',
        shift: 'Shift',
        range: 'Time',
        startDate: 'Start Date',
        endDate: 'End date',
        repeats: 'Repeats',
        icons: ''
      }
    ]

    staffRecurringSchedules.forEach((schedule, index) => {
      rows.push(this._getRecurringSchedule(schedule))
    })

    return { rows, columns }
  }

  _getRecurringSchedule = (schedule) => {
    const { units, shifts, timeService } = this.props
    const unitId = schedule.get('unitId')
    const shiftId = schedule.get('shiftId')
    const unitArray = units?.toJS().filter((item) => item.id === unitId)
    const unitName = (unitArray?.length && unitArray[0] && unitArray[0].name) || ''
    const shiftArray = shifts?.toJS().filter((item) => item.id === shiftId)
    const shiftName = shiftArray?.length && shiftArray[0] && shiftArray[0].name

    const shiftStartTime = schedule.get('shiftStartTime')
    const shiftDuration = schedule.get('shiftDuration')
    const shiftEndTime = timeService.timeMoment(shiftStartTime, 'HH:mm').add(shiftDuration, 'minutes').format('HH:mm')

    const range = shiftStartTime + ' - ' + shiftEndTime

    const startsAt = schedule.get('startsAt')
    const endsAt = schedule.get('endsAt')
    const recurringType = schedule.get('recurringType')
    const recurEvery = schedule.get('recurEvery')

    const subType = schedule.get('subType')
    const weeklyDayIndexes = schedule.get('weeklyDayIndexes')
    const monthlyOnTheDayWeek = schedule.get('monthlyOnTheDayWeek')
    const monthlyOnThe = schedule.get('monthlyOnThe')
    let recurType = ''
    let listData = ''
    switch (recurringType) {
      case 'weekly': {
        recurType = 'week'
        if (recurEvery > 1) {
          recurType = `${recurEvery} weeks`
        }
        const mappedArray = weeklyDayIndexes.map((item) => weekDaysShortArray[item]).join(', ')
        listData = `on ${mappedArray}`
        break
      }
      case 'monthly':
        recurType = 'month'
        if (recurEvery > 1) {
          recurType = `${recurEvery} months`
        }
        listData = this._getListDataForMonthlyRecurType(monthlyOnThe, monthlyOnTheDayWeek, subType)
        break
      default:
    }

    const repeatsTitleString = repeatsTitle(recurType, listData)

    const onEditSchedule = async (_e) => {
      navigate.from.StaffDetails.to.StaffEditRecurringSchedule({
        staffRecurringScheduleId: schedule?.get?.('id')
      })
    }

    const onDeleteSchedule = async (_e) => {
      const { Dialog } = this.props
      const isDeleteRecurringScheduleConfirmed = await Dialog.confirm(null, {
        type: 'warning',
        title: t('title'),
        accept: t('accept')
      })

      if (!isDeleteRecurringScheduleConfirmed) {
        return
      }

      const scheduleId = schedule?.get?.('id')
      const parameters = {
        id: scheduleId
      }
      parameters.id = scheduleId
      await staffStore.deleteStaffRecurringSchedule(parameters)
    }

    const scheduleIcons = (
      <>
        <Icon
          key="edit"
          action="edit"
          tooltip={{ label: 'Edit recurring schedule', placement: 'left' }}
          onClick={(_e) => {
            onEditSchedule()
          }}
        />
        <Icon
          key="delete"
          action="delete"
          tooltip={{ label: 'Delete recurring schedule', placement: 'right' }}
          onClick={(_e) => {
            onDeleteSchedule()
          }}
        />
      </>
    )

    const endDateVal = endsAt !== null ? timeService.timeMoment(endsAt).format('MMM D, YYYY') : '–'

    return {
      unit: unitName,
      shift: shiftName,
      range,
      startDate: timeService.timeMoment(startsAt || null).format('MMM D, YYYY'),
      endDate: endDateVal,
      repeats: repeatsTitleString,
      icons: scheduleIcons
    }
  }

  _getListDataForMonthlyRecurType = (monthlyOnThe, monthlyOnTheDayWeek, subType) => {
    let numberText = ''
    let listData = ''
    switch (monthlyOnThe) {
      case 1:
        numberText = '1st'
        break
      case 2:
        numberText = '2nd'
        break
      case 3:
        numberText = '3rd'
        break
      case 21:
        numberText = '21st'
        break
      case 22:
        numberText = '22nd'
        break
      case 23:
        numberText = '23rd'
        break
      case 31:
        numberText = '31st'
        break
      default:
        numberText = `${monthlyOnThe}th`
    }
    switch (subType) {
      case 'day':
        listData = `on the ${numberText} ${monthlyOnTheDayWeek}`
        break
      case 'date':
        listData = `on the ${numberText} day`
        break
      default:
    }
    return listData
  }
}

const repeatsTitle = (recurType, listData) => {
  return `Every ${recurType} ${listData}`
}

const Icon = ({ action, tooltip, onClick }) => (
  <>
    <i id={`${action}-item-btn`} className={`${iconClassNameByAction[action]} cursor-pointer p5`} onClick={onClick} />
    <sh-tooltip
      label={tooltip.label || action}
      target={`${action}-item-btn`}
      placement={tooltip.placement}
      variation="short"
    />
  </>
)

const iconClassNameByAction = {
  delete: 'icon delete-icon',
  edit: 'icon icon-edit'
}
