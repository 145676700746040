import { generatePath, Link } from 'react-router-dom'
import classNames from 'classnames'

export default function SwitchFacilityList({
  facilityNamesByUrlId,
  redirectToPathname,
  currentFacilityUrlId,
  authStore
}) {
  return facilityNamesByUrlId.entrySeq().map(([facilityUrlId, name]) => {
    const pathname = generatePath(redirectToPathname, { urlId: facilityUrlId })
    return (
      <li
        key={facilityUrlId}
        className={classNames('hx-facility-link', {
          active: facilityUrlId === currentFacilityUrlId
        })}
      >
        <Link
          to={pathname}
          className="bold smaller upper"
          onClick={(e) => {
            authStore.selectFacility(facilityUrlId).then(({ error }) => {
              // if (error) return; // TODO show message
            })
            e.preventDefault()
          }}
        >
          {name}
        </Link>
      </li>
    )
  })
}
