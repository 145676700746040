import { startCase } from 'lodash'

export default function Summary({ facilityUser }) {
  const position = facilityUser.getIn(['staffProfile', 'position'])
  let employmentType = facilityUser.getIn(['staffProfile', 'employmentType'])
  employmentType = truncateEmploymentType(employmentType)

  return (
    <div className="small regent-gray">
      {position}
      &nbsp;&nbsp;
      {'·'}
      &nbsp;&nbsp;
      {employmentType}
      &nbsp;&nbsp;
      {'·'}
    </div>
  )
}

function truncateEmploymentType(str) {
  str = startCase(str)
  const array = str.split(' ')

  if (array.length > 1) {
    // abbreviate
    return str.match(/\b([A-Z])/g).join('')
  }

  if (str.length > 6) {
    const substr = str.substring(0, 3)
    return `${substr}...`
  }

  return str
}
