import { ComponentController } from 'Common/components'
import { moveArrayItem } from 'utils'
import { pick } from 'lodash'

export default class ShiftTypesController extends ComponentController {
  get isUnitsLoaded() {
    const { appState, store } = this.props
    const { stateKey } = store
    const units = appState.getIn([stateKey, 'list'])

    return !!units && units.length > 0
  }

  getShiftTypes() {
    const { appState, store } = this.props
    const { stateKey } = store
    const units = appState.getIn([stateKey, 'list'])

    return units
      .reduce((memo, unit) => {
        const { id: unitId, name: unitName } = unit
        const shiftTypeExtended = unit.shiftTypes.map((shiftType) => ({
          ...shiftType,
          unitId,
          unitName
        }))
        return memo.concat(shiftTypeExtended)
      }, [])
      .map((shiftType, index) => ({ ...shiftType, position: (index + 1) * 10 }))
  }

  async moveShiftTypeToPosition(shiftTypeId, newPosition, unitId) {
    const { data: shiftTypes } = this.state

    const prevItemIndex = shiftTypes.findIndex(
      (shiftType) => shiftType.unitId === unitId && shiftTypeId === shiftType.id
    )
    const shiftTypesWithoutItem = shiftTypes.filter((shiftType, index) => index !== prevItemIndex)

    const nextItemIndex = shiftTypesWithoutItem.findIndex((shiftType) => shiftType.position >= newPosition)
    const newItemIndex = nextItemIndex !== -1 ? nextItemIndex : shiftTypesWithoutItem.length

    const newShiftTypes = moveArrayItem(prevItemIndex, newItemIndex, shiftTypes)
    newShiftTypes[newItemIndex].position = newPosition

    const newUnitShiftTypes = newShiftTypes.filter((shiftType) => shiftType.unitId === unitId)
    this.setState({ data: newShiftTypes })

    await this._updateUnitShiftTypes(unitId, newUnitShiftTypes)
    return this.loadUnits()
  }

  loadUnits() {
    const { store } = this.props
    return store.loadDataAction()
  }

  createUnitShiftType(unitId, { id, title, startTime, duration }) {
    const unitShiftTypes = this._getUnitShiftTypes(unitId)
    const newShiftType = { id, title, startTime, duration }
    const newShiftTypes = unitShiftTypes.concat(newShiftType)

    return this._updateUnitShiftTypes(unitId, newShiftTypes)
  }

  updateUnitShiftType(unitId, shiftTypeId, form) {
    const unitShiftTypes = this._getUnitShiftTypes(unitId)
    const newShiftTypes = unitShiftTypes.map((shiftType) => {
      const isUpdatedShiftType = shiftTypeId === shiftType.id
      if (!isUpdatedShiftType) {
        return shiftType
      }

      return { ...shiftType, ...form }
    })

    return this._updateUnitShiftTypes(unitId, newShiftTypes)
  }

  deleteUnitShiftType(unitId, shiftTypeId) {
    const unitShiftTypes = this._getUnitShiftTypes(unitId)
    const newShiftTypes = unitShiftTypes.filter((shiftType) => shiftTypeId !== shiftType.id)
    const hasType = newShiftTypes.length !== unitShiftTypes.length

    if (!hasType) {
      return Promise.resolve()
    }

    return this._updateUnitShiftTypes(unitId, newShiftTypes)
  }

  _updateUnitShiftTypes(unitId, shiftTypes) {
    const { store } = this.props
    const newShiftTypes = shiftTypes.map((type) => pick(type, ['id', 'title', 'startTime', 'duration']))
    return store.overrideUnitShiftTypes(unitId, newShiftTypes)
  }

  getUnitShiftType(unitId, shiftTypeId) {
    return this._getUnitShiftTypes(unitId).find((shiftType) => shiftType.id === shiftTypeId)
  }

  _getUnitShiftTypes(unitId, additionalFields = []) {
    const { data } = this.state

    return data
      .filter((shiftType) => shiftType.unitId === unitId)
      .map((shiftType) => pick(shiftType, ['id', 'title', 'startTime', 'duration', ...additionalFields]))
  }
}
