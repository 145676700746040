export { allCountries }
export { getStates }

async function allCountries(appState, api) {
  const countries = await getAllCountries(api.indexCountries)

  return Array.prototype.concat.apply([], countries)
}

function getAllCountries(indexCountries) {
  const mapper = (country) => ({
    ...country,
    fullName: `${country.name}`
  })

  return indexCountries().then((countries) => countries.map(mapper))
}

async function getStates(appState, api, countryCode) {
  if (typeof countryCode === 'object' || !countryCode) {
    return []
  }
  const states = await getStatesOfCountry(api.indexStates, countryCode)

  return Array.prototype.concat.apply([], states)
}

async function getStatesOfCountry(indexStates, countryCode) {
  const mapper = (stateObj) => ({
    ...stateObj,
    fullName: `${stateObj.name}`
  })

  return indexStates({ countryCode }).then((states) => states.map(mapper))
}
