import { GQLDataLoader } from 'utils'
import { staffEligibleForUnitQuery } from '../Queries'

function Staff() {
  let gqlClient = null
  let instanceUri = null

  const actions = { loadStaff }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, instanceUri } = context)
  }

  async function loadStaff(unitId, startDate, endDate) {
    const gqlDataLoaded = new GQLDataLoader(gqlClient)
    const parameters = { unitId, startDate, endDate }

    const staff = await gqlDataLoaded.loadAllItems('staff', staffEligibleForUnitQuery, parameters)
    return extendStaff(staff)
  }

  function extendStaff(staffList) {
    return staffList.map((staff) => {
      const { facilityProfile, profile } = staff
      facilityProfile.avatarUrl = profile.avatarUrl

      const { firstName, lastName } = facilityProfile

      const fullName = `${firstName} ${lastName}`
      const fullNameRevers = `${lastName}, ${firstName}`
      const fullNameAsSearchable = getFullNameSearcheable(firstName, lastName)

      return {
        ...staff,
        fullName,
        fullNameRevers,
        fullNameAsSearchable,
        profile: withAvatar(facilityProfile)
      }
    })
  }

  function getFullNameSearcheable(firstName = '', lastName = '') {
    return `${firstName.toLowerCase()} ${lastName.toLowerCase()} ${firstName.toLowerCase()}`
  }

  function withAvatar(profile) {
    const { avatarUrl } = profile

    if (!avatarUrl || avatarUrl.startsWith('http')) {
      return profile
    }
    profile.avatarUrl = `${instanceUri}/${avatarUrl}`

    return profile.avatarUrl
  }
}

export default Staff()
