import { List, Map } from 'immutable'
import CalendarSelection from './CalendarSelection'
import { CalendarFilterService } from 'services'
import { pick, isEqual } from 'lodash'
import { keyBy } from 'utils'

export default class Calendar {
  constructor(calendar, calendarStore) {
    this.calendar = calendar
    this.calendarStore = calendarStore
    this.hideEmptyShifts = false
  }

  get otherStaff() {
    return this.calendar.get('otherStaff')
  }

  get shiftIdsByFacilityUserId() {
    return this.calendar.get('shiftIdsByFacilityUserId')
  }

  get otherStaffMap() {
    if (this.otherStaff) {
      return keyBy(this.otherStaff, 'userId')
    }
    return Map()
  }

  get shiftsMap() {
    if (!this._shiftsMap) {
      this._shiftsMap = this._buildShiftsMap()
    }
    return this._shiftsMap
  }

  get staffHoursMap() {
    return this.calendar.get('staffHoursMap')
  }

  get notesMap() {
    return this.calendar.get('notes')
  }

  get days() {
    return this.calendar.get('days')
  }

  get roles() {
    return this.calendar.get('roles') || List()
  }

  get shifts() {
    return this.calendar
      .get('roles')
      .reduce((shiftsMemo, role) => role.get('shifts').reduce((memo, shift) => memo.push(shift), shiftsMemo), List())
  }

  getDayData(unitId, date) {
    return this.calendar.getIn(['daysData', unitId, date]) || List()
  }

  getSelectedObjects(selection) {
    return new CalendarSelection(selection, this)
  }

  getStaffCell = ({ roleIndex, shiftIndex, staffIndex, cellIndex }) => {
    const cellPathParams = { roleIndex, shiftIndex, staffIndex, cellIndex }
    const cellPath = this.calendarStore.getPath(cellPathParams)
    return this.calendar.getIn(cellPath)
  }

  getStaff = ({ roleIndex, shiftIndex, staffIndex }) => {
    const staffPathParams = { roleIndex, shiftIndex, staffIndex }
    const staffPath = this.calendarStore.getPath(staffPathParams)
    return this.calendar.getIn(staffPath)
  }

  getShift = ({ roleIndex, shiftIndex }) => {
    const shiftPathParams = { roleIndex, shiftIndex }
    const shiftPath = this.calendarStore.getPath(shiftPathParams)
    return this.calendar.getIn(shiftPath)
  }

  getShiftDay = ({ roleIndex, shiftIndex, shiftDayIndex }) => {
    const shiftDayPathParams = { roleIndex, shiftIndex, shiftDayIndex }
    const shiftDayPath = this.calendarStore.getPath(shiftDayPathParams)
    return this.calendar.getIn(shiftDayPath)
  }

  getRole = ({ roleIndex }) => {
    const rolePath = this.calendarStore.getPath({ roleIndex })
    return this.calendar.getIn(rolePath)
  }

  isShiftLoaded({ roleIndex, shiftIndex }) {
    const shift = this.getShift({ roleIndex, shiftIndex })
    return shift.get('isLoaded')
  }

  getVisibleRoles(filters) {
    const calendarFilterService = new CalendarFilterService(filters)
    const { isRoleVisible } = calendarFilterService

    return this.roles.filter(isRoleVisible)
  }

  getVisibleShifts(filters, isDayView) {
    const calendarFilterService = new CalendarFilterService(filters)
    const { isShiftVisible } = calendarFilterService

    return this.getVisibleRoles(filters)
      .reduce((memo, role) => {
        return memo.concat(role.get('shifts'))
      }, List())
      .filter((shift) => isShiftVisible(shift, isDayView))
  }

  getVisibleStaff(filters) {
    const calendarFilterService = new CalendarFilterService(filters)
    const { isStaffVisible } = calendarFilterService

    return this.getVisibleShifts(filters).reduce((staffMemo, shift) => {
      const shiftVisibleStaff = shift.get('staff').filter(isStaffVisible)
      return staffMemo.concat(shiftVisibleStaff)
    }, List())
  }

  isShiftsLoaded(filters) {
    return this.getVisibleShifts(filters).every((shift) => shift.get('isLoaded'))
  }

  isNotesLoaded(filters) {
    return this.getVisibleShifts(filters).reduce((memo, shift) => {
      if (!memo) {
        return false
      }
      if (!shift.get('isLoaded')) {
        return false
      }

      return shift.get('staff').reduce((memo, staff) => {
        if (!memo) {
          return false
        }

        return staff.get('cells').reduce((memo, cell) => {
          if (!memo) {
            return false
          }

          const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
          const noteIds = cellStaffEvent.get('noteIds')
          return !noteIds || noteIds.every((noteId) => this.notesMap.get(noteId))
        }, memo)
      }, memo)
    }, true)
  }

  isOtherStaffLoaded() {
    return this.calendar.get('isOtherStaffLoaded')
  }

  isCalendarLoaded(filters) {
    if (!this._isCalendarLoaded) {
      const isShiftsLoaded = this.isShiftsLoaded(filters)
      const visibleStaff = this.getVisibleStaff(filters)
      const isStaffHoursMapLoaded = this.staffHoursMap.size >= visibleStaff.size
      const isNotesLoaded = this.isNotesLoaded(filters)
      this._isCalendarLoaded = isShiftsLoaded && isStaffHoursMapLoaded && isNotesLoaded
    }

    return this._isCalendarLoaded
  }

  isCalendarDayLoaded(unitId, date) {
    const isOtherStaffLoaded = this.isOtherStaffLoaded()
    const isDayDataLoaded = !!this.calendar.getIn(['daysData', unitId, date])
    return isOtherStaffLoaded && isDayDataLoaded
  }

  findShiftById(shiftId) {
    return this.shiftsMap.get(shiftId)
  }

  getCellDetails(staffPath, cellIndex) {
    const { roleIndex, shiftIndex } = staffPath

    const shiftPath = { roleIndex, shiftIndex }
    const shiftDayIndex = cellIndex
    const shiftDayPath = { roleIndex, shiftIndex, shiftDayIndex }
    const cellPath = { ...staffPath, cellIndex }

    const shift = this.getShift(shiftPath)
    const staff = this.getStaff(staffPath)
    const shiftDay = this.getShiftDay(shiftDayPath)
    const cell = this.getStaffCell(cellPath)
    const day = this.calendar.getIn(['days', cellIndex])

    return { shift, staff, shiftDay, cell, shiftPath, cellPath, day }
  }

  getCellKeys(staffPath, cellIndexes) {
    const { roleIndex, shiftIndex, staffIndex } = staffPath

    return cellIndexes.map((cellIndex) => `${roleIndex}|${shiftIndex}|${staffIndex}|${cellIndex}`)
  }

  _buildShiftsMap() {
    return this.calendar.get('roles').reduce((rolesShiftsMemo, role) => {
      return role.get('shifts').reduce((shiftsMemo, shift) => {
        const shiftId = shift.get('id')
        return shiftsMemo.set(shiftId, shift)
      }, rolesShiftsMemo)
    }, Map())
  }

  isSameCellPath(cellPath1, cellPath2) {
    const attrs = ['roleIndex', 'shiftIndex', 'staffIndex', 'cellIndex']
    cellPath1 = pick(cellPath1, attrs)
    cellPath2 = pick(cellPath2, attrs)
    return isEqual(cellPath1, cellPath2)
  }
}
