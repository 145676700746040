import { PureComponent, createRef } from 'react'
import { AutoSizer, List } from 'react-virtualized'
import ViewController from './ViewController'
import StickyHeader from './StickyHeader'

export default class StickyList extends PureComponent {
  constructor(props) {
    super(props)
    this.listRef = createRef()
    this.viewController = new ViewController(this)
    this.state = { ...this.viewController.defaultState }
  }

  render() {
    const { raw, filters, renderRole, renderShift, getRowHeight, viewMode = 'full', staffHoursMap } = this.props
    const showMergedShift = filters.get('showMergedShift')
    const role = this.viewController.getFirstVisibleRole(raw)

    let shift
    const isDayView = viewMode === 'day'
    const roleIndex = role?.get('roleIndex')
    let shiftRoleIndex
    if (showMergedShift) {
      if (role && !isDayView) {
        shift = this.viewController._getMergeShift(filters, staffHoursMap, role)
      }
    } else {
      shift = this.viewController.getFirstVisibleShift(raw, role) // TODO shift should be set 1 row earlier
      shiftRoleIndex = shift?.get('roleIndex')
      shift = roleIndex === shiftRoleIndex && shift
    }

    const stickyHeaderProps = {
      role,
      shift,
      filters,
      renderRole,
      renderShift,
      getRowHeight
    }

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <div className="hx-grid w100 relative">
            {<StickyHeader {...stickyHeaderProps} width={width} />}
            <List
              {...this.props}
              width={width}
              style={{ backgroundColor: '#fff', overflowY: 'auto', scrollbarWidth: 'none' }}
              onRowsRendered={this.onRowsRendered(raw)}
              ref={this.listRef}
            />
          </div>
        )}
      </AutoSizer>
    )
  }

  recomputeRowHeightsForList = (rowIndex) => {
    this.listRef.current.recomputeRowHeights(rowIndex)
  }

  onRowsRendered =
    (raw) =>
    ({ startIndex }) => {
      this.viewController.onRowsRendered({ startIndex })
    }
}
