import { staffEventQuery } from '../Queries'
import {
  cancelStaffEventsMutation,
  updateStaffEventsMutation,
  createStaffEventsMutation,
  deleteStaffEventsMutation
} from '../Mutations'

function StaffEvents() {
  let gqlClient = null
  const actions = {
    readStaffEvent,
    createStaffEvents,
    updateStaffEvents,
    deleteStaffEvents,
    cancelStaffEvents
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  async function readStaffEvent(id) {
    const { staffEvent } = await gqlClient.query(staffEventQuery, { id })
    return staffEvent
  }

  function createStaffEvents(staffEvents, unitEventVariantId, note, options, omitExpertiseVerification) {
    const parameters = {
      parameters: {
        staffEvents,
        unitEventVariantId,
        omitExpertiseVerification: !!omitExpertiseVerification || unitEventVariantId !== 'assignment'
      }
    }

    if (note) {
      const { subject, text } = note

      parameters.parameters.note = { subject, text }
    }

    return gqlClient.mutate(createStaffEventsMutation, parameters, options)
  }

  function updateStaffEvents(staffEvents, note, options) {
    const parameters = { parameters: { staffEvents } }

    if (note) {
      const { subject, text } = note
      parameters.parameters.note = { subject, text }
    }

    return gqlClient.mutate(updateStaffEventsMutation, parameters, options)
  }

  function deleteStaffEvents(ids, options) {
    const parameters = { parameters: { ids } }

    return gqlClient.mutate(deleteStaffEventsMutation, parameters, options)
  }

  function cancelStaffEvents(ids, note, options) {
    const parameters = { parameters: { ids } }

    if (note) {
      const { subject, text } = note

      parameters.parameters.note = { subject, text }
    }
    return gqlClient.mutate(cancelStaffEventsMutation, parameters, options)
  }
}

export default StaffEvents()
