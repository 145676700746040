import { withAppContext } from 'AppContext'
import './LaborProfile.scss'
import ShiftOvertime from './ShiftOvertime'
import TopOvertimeShifts from './TopOvertimeShifts.tsx'
import TopStaffOvertimes from './TopStaffOvertimes/TopStaffOvertimes.tsx'
import StaffingInfo from './StaffingInfo'
import EmploymentTypeDistribution from './EmploymentTypeDistribution'

function LaborProfile(props) {
  const { unit, activeDateRange } = props
  return (
    <>
      <div className="row">
        <div className="col-4">
          <ShiftOvertime {...props} />
        </div>
        <div className="col-4 p10">
          <TopOvertimeShifts {...props} fetchShiftOvertimeList={props.dataController?.fetchShiftOvertimeList} />
        </div>
        <div className="col-4 p10">
          <TopStaffOvertimes
            loadCardData={props.dataController?.loadTopStaffOvertimesCardData}
            unit={unit}
            activeDateRange={activeDateRange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 p10">
          <StaffingInfo
            loadCardData={props.dataController?.loadStaffingInfoCardData}
            unit={unit}
            activeDateRange={activeDateRange}
          />
        </div>
        <div className="col-6 p10">
          <EmploymentTypeDistribution loadCardData={props.dataController?.loadCardData} unit={unit} />
        </div>
      </div>
    </>
  )
}

export default withAppContext(LaborProfile)
