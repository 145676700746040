import { times, chunk } from 'lodash'

export default class GQLDataLoader {
  constructor(gqlClient) {
    this.gqlClient = gqlClient
  }

  async loadByChunks(operationName, query, parameters, parameterNameToSplit, itemsInChunk = 100) {
    const { [parameterNameToSplit]: parameterToSplit } = parameters
    const parameterChunks = chunk(parameterToSplit, itemsInChunk)

    const promises = parameterChunks.map((values) => {
      const params = { ...parameters, [parameterNameToSplit]: values }
      return this.gqlClient.query(query, params)
    })

    const results = await Promise.all(promises)

    return results.reduce((memo, result) => memo.concat(result[operationName]), [])
  }

  async loadAllItems(operationName, query, parameters, options) {
    const limit = 999
    let pageParameters = { limit, page: 1 }
    const operationParameters = { operationName, query, parameters, options }
    let { items, pagesCount } = await this._loadDataFirstPage(pageParameters, operationParameters)

    // First page is already loaded, load all other pages:
    const promises = times(pagesCount - 1, async (time) => {
      pageParameters = { limit, page: time + 2 }

      const response = await this._loadItemsPage(pageParameters, operationParameters)
      const { edges: itemsPage } = response

      items = items.concat(itemsPage)
    })

    await Promise.all(promises)

    return items
  }

  _loadDataFirstPage = async (pageParameters, operationParameters) => {
    const { limit } = pageParameters
    const itemsResponse = await this._loadItemsPage(pageParameters, operationParameters)
    const { edges: items, pageInfo } = itemsResponse
    const { totalCount } = pageInfo
    const pagesCount = Math.ceil(totalCount / limit)

    return { items, pagesCount }
  }

  _loadItemsPage = async (pageParameters, operationParameters) => {
    const { limit, page } = pageParameters
    const { operationName, query, parameters, options = {} } = operationParameters

    const offset = (page - 1) * limit
    const operations = options.operations || {}
    const operationOptions = options?.operations?.[operationName] || {}
    const optionsExtended = {
      ...options,
      operations: {
        ...operations,
        [operationName]: {
          ...operationOptions,
          includePagination: true
        }
      }
    }
    const parametersExtended = { ...parameters, offset, limit }

    const response = await this.gqlClient.query(query, parametersExtended, optionsExtended)
    return response[operationName]
  }
}
