import './Header.scss'
import { t } from 'i18n'
import StatusBadge from 'Staff/Common/StatusBadge'
import { getInitiatorTagDetailsByState } from 'Staff/Calendar/DetailsPane/SectionDetails/SwappingShiftDetails/utils'

export interface IHeader {
  timeService: any
  shiftSwapRequest: any
  usDate: any
}

export default function Header({ timeService, usDate, shiftSwapRequest }: IHeader) {
  const renderStatusTag = (state: string) => {

    const acceptedDate = shiftSwapRequest.get('acceptedDate')

    const statusDetails = getInitiatorTagDetailsByState(state, acceptedDate)
    if (statusDetails) {
      return statusDetails.map(({ label, color }, index) => <StatusBadge key={index} label={label} color={color} />)
    }
    return null
  }

  const shiftSwapTitleWithDate =
    `${t('staffCalendar.shiftSwap.titles.you')}` + ' • ' + timeService.getDateMonthForUsDate(usDate)

  return (
    <div>
      <div className="myShiftHeaderBox">
        <div className="myShiftHeader">
          <div className="myShiftIconBox" data-testid="title-icon-component">
            <sh-icon icon="in-progress" size="s" color="white"></sh-icon>
          </div>
          <div className="myShiftTitle" id="my-shift-title">
            {shiftSwapTitleWithDate}
          </div>
          <sh-tooltip
            label={shiftSwapTitleWithDate}
            target="my-shift-title"
            placement="top"
            variation="short"
          ></sh-tooltip>
        </div>
        <div className="myShiftHeaderTags">{renderStatusTag(shiftSwapRequest.get('state'))}</div>
      </div>
    </div>
  )
}
