import { PureComponent } from 'react'
import ConstraintsList from './ConstraintsList/ConstraintsList'
import './AutoScheduleModal.scss'
import { createRef } from 'react'
import ConstraintsController from './ConstraintsController'
import AlgoScheduleController from './AlgoScheduleController'
import { addSuccessBanner } from 'stores'
import { curry } from 'i18n'
import CalendarView from './CalendarView/CalendarView'
import { ScheduleStatus, ConstraintType } from './constants'
const t = curry('calendar.autoSchedule.')

export default class AutoScheduleModal extends PureComponent {
  constructor(props) {
    super(props)
    this.modalRef = createRef()
    this.constraintsController = new ConstraintsController(this)
    this.algoScheduleController = new AlgoScheduleController(this)
    const { defaultState } = this.constraintsController
    this.state = {
      ...defaultState,
      constraints: [],
      isError: false
    }
  }

  async componentDidMount() {
    const { algoScheduleStore, unit } = this.props
    const unitId = unit.get('id')
    const scheduleId = unit.get('schedule').get('id')
    const modalElement = this.modalRef.current
    if (modalElement) {
      modalElement.addEventListener('modal-closed', this.onModalClose)
    }
    const { status, algoScheduleId, message } = await algoScheduleStore.readAutoSchedule({
      unitId,
      scheduleId,
      withCalendarResults: false,
      userIds: [],
      shiftIds: []
    })
    this.algoScheduleId = algoScheduleId
    if (!status) {
      await this.constraintsController.getConstraints()
    } else {
      if (status === ScheduleStatus.COMPLETED) {
        if (message?.includes(t('noOptimalSchedule'))) {
          this.setState({ isLoading: false, isCalendarLoaded: true, isError: true })
          return
        }
        this.setState({ isLoading: false, isCalendarLoaded: true, isError: false })
      } else {
        this.setState({
          isLoading: true,
          spinnerAttributes: { label: '' },
          spinnerLabel: t('generatingSchedule')
        })
      }
    }
  }

  componentWillUnmount() {
    this.updateAlgoSchedule = null
    this.generateSchedule = null
    this.updateConstraintSettings = null
    const modalElement = this.modalRef.current
    modalElement.removeEventListener('modal-closed', this.onModalClose)
    clearTimeout(AutoScheduleModal.timeOutId)
  }

  onModalClose = (e) => {
    e.preventDefault()
    const { cancelClick } = this.props
    cancelClick()
  }

  generateSchedule = async (constraints) => {
    const userSelectedConstraints = constraints.filter(
      (constraint) => constraint.isActive && constraint.type === ConstraintType.SOFT
    )
    const task_id = await this.algoScheduleController.generateSchedule(userSelectedConstraints)
    if (task_id) {
      this.startPolling(ScheduleStatus.PENDING)
    }
  }

  updateAlgoSchedule = async (id, status) => {
    const acceptStatus = status === ScheduleStatus.ACCEPTED
    const spinnerAttributes = {
      label: t('applyingSchedule'),
      overlay: acceptStatus ? true : false
    }
    this.setState({
      isLoading: true,
      spinnerAttributes: acceptStatus ? spinnerAttributes : {},
      spinnerLabel: ''
    })
    const updatedId = await this.algoScheduleController.updateAlgoSchedule(id, status)
    if (status === ScheduleStatus.REJECTED) {
      await this.constraintsController.getConstraints()
    } else {
      if (updatedId) {
        // ! Re-loading the page
        setTimeout(() => {
          window.location.reload()
        })
      }
      const { cancelClick } = this.props
      cancelClick()
      this.setState({ isLoading: false, isCalendarLoaded: false, isError: false })
    }
  }
  updateConstraintSettings = async (constraint) => {
    await this.constraintsController.updateConstraint(constraint)
  }

  startPolling = async (status, message) => {
    const { algoScheduleStore } = this.props
    const { unit } = this.props
    const unitId = unit.get('id')
    const scheduleId = unit.get('schedule').get('id')
    if (status === ScheduleStatus.COMPLETED) {
      clearTimeout(AutoScheduleModal.timeOutId)
      if (message?.includes(t('noOptimalSchedule'))) {
        this.setState({ isLoading: false, isCalendarLoaded: true, isError: true })
        return
      }
      addSuccessBanner({ message: t('autoScheduleCreated') })
      this.setState({ isLoading: false, isCalendarLoaded: true, isError: false })
    } else {
      const { status, algoScheduleId, message } = await algoScheduleStore.readAutoSchedule({
        unitId,
        scheduleId,
        withCalendarResults: false,
        userIds: [],
        shiftIds: []
      })
      this.algoScheduleId = algoScheduleId
      AutoScheduleModal.timeOutId = setTimeout(this.startPolling, 5000, status, message)
    }
  }

  render() {
    const locationArray = window.location.href.split('/')
    const calendarIndex = locationArray.findIndex((e) => e === 'calendar')

    const match = {
      params: {
        mode: 'full',
        date: locationArray[calendarIndex + 1],
        unitUrlId: locationArray[calendarIndex - 1]
      }
    }
    const constraintsListProps = {
      ...this.state,
      generateSchedule: this.generateSchedule,
      updateConstraintSettings: this.updateConstraintSettings
    }

    const calendarViewProps = {
      ...this.props,
      ...this.state,
      algoScheduleId: this.algoScheduleId,
      updateScheduleClick: this.updateAlgoSchedule,
      match
    }

    const stepperValue = !this.state.isCalendarLoaded ? 1 : 2

    return (
      <sh-modal
        id="sticky-modal"
        ref={this.modalRef}
        visible
        modal-height="calc(100% - 36px)"
        modal-width="calc(100% - 36px)"
        class="z1000"
      >
        <sh-text slot="header" size="header-1" class="flex align-middle w10">
          {t('autoScheduleModalTitle')}
        </sh-text>
        <sh-wrapper slot="header" class="flex align-center">
          <sh-stepper class="w30" color="primary" value={stepperValue}>
            <sh-stepper-item label={t('stepper.parameterSettings')} condensed></sh-stepper-item>
            <sh-stepper-item label={t('stepper.scheduleSimulation')} condensed></sh-stepper-item>
          </sh-stepper>
        </sh-wrapper>

        <div className="auto-schedule-modal-body">
          {this.state.isLoading ? (
            <div className="autoschedule-modal">
              <sh-spinner {...this.state.spinnerAttributes}></sh-spinner>
              <div className="text-center mt10">{this.state.spinnerLabel}</div>
            </div>
          ) : this.state.isCalendarLoaded ? (
            <CalendarView {...calendarViewProps} />
          ) : this.state.constraints.length === 0 ? (
            <div className="flex align-center h100">
              <sh-empty-state icon="studies" label={t('noConstraintsFound')} class="w30"></sh-empty-state>
            </div>
          ) : (
            <>
              <div class="flex align-middle p10 parameter-rule">
                <sh-icon icon="information" size="xs" class="mr10 ml10" color="#6E7B9F"></sh-icon>
                {t('rankingImportanceText')}
              </div>
              <ConstraintsList {...constraintsListProps} />
            </>
          )}
        </div>
      </sh-modal>
    )
  }
}
