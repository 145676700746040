import KpiList from '../../../../components/Form/AutoScheduleModal/KpiList/KpiList'
import { useState } from 'react'
import './DisplayKpis.scss'

const DisplayKpis = ({ calendarStore, unit }) => {
  const unitId = unit.get('id')
  const scheduleId = unit.get('schedule').get('id')
  const [kpiList, setKpiList] = useState([])

  const calculateKpi = async () => {
    const kpiResult = await calendarStore.calculateKpi(scheduleId, unitId)
    setKpiList(kpiResult)
  }
  return (
    <div className="col">
      <>
        <button id="display-kpi-btn" onClick={calculateKpi} className="display-kpis-button kpi-button opacity8 pl0 pr0">
          <sh-icon icon="kpi" size="m"></sh-icon>
        </button>
        <sh-tooltip label="KPIs" target="display-kpi-btn" placement="bottom" variation="short" />
      </>
      <sh-popover target="display-kpi-btn" position="bottom-left" style={{ width: 'auto' }}>
        <KpiList kpiResult={kpiList} />
      </sh-popover>
    </div>
  )
}

export default DisplayKpis
