import { PureComponent } from 'react'
import Week from './Week'
import { chunk } from 'lodash'
import Summary from '../Summary'
import '../ModeFull/ModeFull.scss'
import classNames from 'classnames'

export default class ModeWeek extends PureComponent {
  render() {
    const {
      roleId,
      roleViewPreference,
      roleName,
      isCollapsed,
      roleMismatch,
      activeDateRange,
      toggleIsCollapsed,
      isShiftDaysLoaded,
      onChangeViewPreference,
      unitId,
      mode,
      calendarType
    } = this.props

    let days = activeDateRange.get('days').filter((day) => day.isActiveWeek)
    const weeks = chunk(days, 7)
    const weeksCount = weeks.length
    const width = `${100 / weeksCount}%`
    const state = activeDateRange.get('state')
    const showMismatches = state !== 'inactive'

    const summaryProps = {
      isCollapsed,
      roleName,
      isMismatchCounterVisible: isShiftDaysLoaded && showMismatches && roleMismatch,
      toggleIsCollapsed,
      activeDateRange,
      roleId,
      onChangeViewPreference,
      unitId,
      roleViewPreference,
      mode,
      calendarType
    }

    // if (roleMismatch) {
    //   summaryProps.understaffCount = roleMismatch.get('understaffCount');
    //   summaryProps.overstaffCount = roleMismatch.get('overstaffCount');
    // }
    const isOtherStaff = roleId === 'secondary-staff'

    return (
      <header
        className={classNames('row', {
          'bg-cadetblue': !isOtherStaff,
          'bg-nimbuscloud': isOtherStaff,
          'flex-nowrap': calendarType !== 'calendar'
        })}
      >
        <Summary {...summaryProps} />
        <section className={'_week col-10'}>
          <div className="hx-calendar-group-days row bl1">
            {weeks.map((days, index) => (
              <Week key={index} width={width} days={days} mode={mode} />
            ))}
          </div>
        </section>
      </header>
    )
  }
}
