import { Map } from 'immutable'
import ConcurrentStaff from './ConcurrentStaff'
import { getStaffEventLongTitle, getStaffEventDisplayAttributes } from 'Staff/Calendar/utils'

import { SpillProofDiv } from 'Common/components'

export interface IStaffEventDetailsProps {
  staffEvent: Map<string, any>
  timeService: any
  publicStaffMap: Map<string, Map<string, any>>
  unitsMap: Map<string, Map<string, any>>
  shiftsMap: Map<string, Map<string, any>>
}

export default function StaffEventDetails({
  staffEvent,
  unitsMap,
  timeService,
  publicStaffMap,
  shiftsMap
}: IStaffEventDetailsProps) {
  const { icon, color } = getStaffEventDisplayAttributes(staffEvent)
  const title = getStaffEventLongTitle(staffEvent, unitsMap)
  const subtitleComponents = getStaffEventSubtitleComponents(staffEvent, timeService)

  return (
    <div className="row align-middle pt10 pb10">
      <div className="row col-12">
        <div className="col-1">
          <span className="icon-highlight-wrapper" style={{ backgroundColor: color }}>
            <sh-icon icon={icon} size="s" />
          </span>
        </div>
        <div className="col-11 pl5">
          <SpillProofDiv id={`staff-event-${staffEvent.get('id')}-title`} tooltipLabel={title}>
            <sh-text size="header-1">{title}</sh-text>
          </SpillProofDiv>
          <sh-text>{subtitleComponents?.join(' • ')}</sh-text>
        </div>
      </div>
      <ConcurrentStaff
        staffEvent={staffEvent}
        publicStaffMap={publicStaffMap}
        shiftsMap={shiftsMap}
        timeService={timeService}
      />
    </div>
  )
}

function getStaffEventSubtitleComponents(staffEvent: Map<string, any>, timeService: any) {
  if (staffEvent.get('type') === 'timeOff' || staffEvent.get('type') === 'block') {
    return getTimeOffSubtitleComponents(staffEvent, timeService)
  }
  if (staffEvent.get('type') === 'assignment' || staffEvent.get('type') === 'onCall') {
    return getAssignmentSubtitleComponents(staffEvent, timeService)
  }
}

function getTimeOffSubtitleComponents(staffEvent: Map<string, any>, timeService: any) {
  let subtitleComponents: Array<string> = []
  const eventTimeRange = timeService.formatTimeRange(staffEvent.get('startsAt'), staffEvent.get('endsAt'), 'hh:mm A')
  if (eventTimeRange) {
    subtitleComponents.push(eventTimeRange)
  }
  if (staffEvent.get('timeOffAttributes')?.get('paidMinutes') > 0) {
    subtitleComponents.push('Paid time off')
  } else {
    subtitleComponents.push('Request off')
  }
  const firstNote = staffEvent.get('notes')?.get(0)
  if (firstNote) {
    subtitleComponents.push(firstNote.get('subject'))
  }
  return subtitleComponents
}

function getAssignmentSubtitleComponents(staffEvent: Map<string, any>, timeService: any) {
  let subtitleComponents: Array<string> = []
  const eventTimeRange = timeService.formatTimeRange(staffEvent.get('startsAt'), staffEvent.get('endsAt'), 'hh:mm A')
  if (eventTimeRange) {
    subtitleComponents.push(eventTimeRange)
  }
  return subtitleComponents
}
