import * as Sentry from '@sentry/browser'

function buildConfig(isSentryEnabled) {
  return {
    setUser,
    setScope,
    resetScope,
    setFacility,
    setFacilityTimeZone
  }

  function setUser(user) {
    if (!isSentryEnabled) {
      return
    }

    const { userId } = user

    Sentry.configureScope((scope) => {
      scope.setUser({ id: userId })
    })
  }

  function setFacility(facility) {
    if (!isSentryEnabled) {
      return
    }

    const facilityId = facility.get('id')
    Sentry.configureScope((scope) => {
      scope.setTag('facilityId', facilityId)
    })
  }

  function setScope(requestScope) {
    if (!isSentryEnabled) {
      return
    }

    Sentry.configureScope((scope) => {
      scope.setTag('scope', requestScope)
    })
  }

  function setFacilityTimeZone(timeZone) {
    if (!isSentryEnabled) {
      return
    }

    Sentry.configureScope((scope) => {
      scope.setTag('facilityTimeZone', timeZone)
    })
  }

  function resetScope() {
    if (!isSentryEnabled) {
      return
    }

    Sentry.configureScope((scope) => {
      scope.setUser({ id: null })
      scope.setTag('scope', null)
      scope.setTag('facilityId', null)
      scope.setTag('facilityTimeZone', null)
    })
  }
}

export default buildConfig
