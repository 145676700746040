import { useState } from 'react'
import './RequestShiftSwap.scss'
import YourShift from './YourShift/YourShift'
import SwappingShift from './SwappingShift/SwappingShift'
import SwappingStaff from './SwappingStaff/SwappingStaff'
import EmptyStateCard from './Common/EmptyStateCard'
import Footer from './Common/Footer'
import { List, Map } from 'immutable'
import { t } from 'i18n'
import NotificationCard from './Common/NotificationCard'

export interface IRequestShiftSwap {
  dataController: any
  timeService: any
  staffCalendar: any
  staffShiftSwaps: any
  date: any
  loggedInUserId: string
}

let defaultLabel = t('staffCalendar.shiftSwap.emptyStateLabels.chooseYourShiftDate')

const defaultState: any = {
  yourShift: Map({
    date: '',
    selectedShift: Map(),
    staffEventsForSelectedDate: List([])
  }),
  swappingShift: Map({
    date: ''
  }),
  swappingStaff: Map()
}

const RequestShiftSwap = ({ loggedInUserId, dataController, date, staffCalendar, staffShiftSwaps, timeService }: IRequestShiftSwap) => {
  const [requestShiftSwapData, setRequestShiftSwapData] = useState(defaultState)
  const [receiverName, setReceiverName] = useState('')
  const [shiftSwapRequestStatus, setShiftSwapRequestStatus] = useState('')
  const [showShiftSwapRequestNotification, setShiftSwapRequestNotification] = useState(false)

  const [emptyStateLabel, setEmptyStateLabel] = useState(defaultLabel)

  const handleEmptyStateLabel = (label: string) => {
    setEmptyStateLabel(label)
  }

  const handleYourShiftData = (data: any) => {
    setRequestShiftSwapData((prevState: any) => ({
      yourShift: prevState.yourShift.merge(data),
      swappingShift: Map({
        date: ''
      }),
      swappingStaff: Map()
    }))
  }

  const handleSwappingShiftData = (swappingShiftData: any) => {
    setRequestShiftSwapData((prevState: any) => ({
      ...prevState,
      swappingShift: prevState.swappingShift.merge(swappingShiftData)
    }))
  }

  const handleStaffSelected = (staffSelectedData: any) => {
    setRequestShiftSwapData((prevState: any) => ({
      ...prevState,
      swappingStaff: prevState.swappingStaff.merge(staffSelectedData)
    }))
  }

  const handleSubmitButtonClick = async () => {
    try {
      const staffProfile: any = requestShiftSwapData.swappingStaff.get('staffProfile')
      const staffEventId = requestShiftSwapData.yourShift.getIn(['selectedShift', 'id'])
      const shiftDayIdsToSwapFor = requestShiftSwapData.swappingStaff
        .get('shiftDaysToSwapFor').map((shiftDay: any) => shiftDay.get('id'))
      const userId = staffProfile.get('userId')
      const monthStartsAt = timeService.dateTimeToMoment(date).startOf('month').toISOString()
      await dataController.createShiftSwapRequest(
        staffEventId,
        shiftDayIdsToSwapFor,
        userId,
        monthStartsAt,
        loggedInUserId
      )
      setReceiverName(staffProfile.get('userProfileLastName') + ', ' + staffProfile.get('userProfileFirstName'))
      handleClearButtonClick()
      setShiftSwapRequestStatus('Success')
      setShiftSwapRequestNotification(true)
    } catch (_e: any) {
      if (!_e.toString().includes('Request for this day already created.')) {
        setShiftSwapRequestStatus('Failure')
        setShiftSwapRequestNotification(true)
      }
    }
  }

  const handleClearButtonClick = () => {
    setRequestShiftSwapData(defaultState)
    setEmptyStateLabel(defaultLabel)
    setShiftSwapRequestStatus('')
  }

  const staffEventsMap = staffCalendar.getIn(['staffEventsForMonth', 'staffEventsMap'])

  const shiftDaysAvailableToSwapFor = staffShiftSwaps.getIn([
    'shiftDaysAvailableToSwapFor',
    'shiftDaysAvailableToSwapForMap'
  ])
  
  const staffAvailableForSwap = staffShiftSwaps.getIn([
    'staffAvailableForSwap',
    'staffAvailableForSwapList'
  ])

  const selectedShift = requestShiftSwapData.yourShift.get('selectedShift')

  const showSwappingStaff= !(emptyStateLabel === t('staffCalendar.shiftSwap.emptyStateLabels.noSwappingStaff') || emptyStateLabel === t('staffCalendar.shiftSwap.emptyStateLabels.shiftSwapDateUnavailable'))


  const handleShiftSwapRequestNotificationClose = () => {
    setShiftSwapRequestNotification(false)
  }

  return (
    <>
      <div className="request-shift-swap-container">
        <div className="notification-card">
         {showShiftSwapRequestNotification && <NotificationCard shiftSwapRequestStatus={shiftSwapRequestStatus} receiverName={receiverName} onClose={handleShiftSwapRequestNotificationClose} />}
        </div>
        <YourShift
          timeService={timeService}
          staffEventsMap={staffEventsMap}
          dataController={dataController}
          handleEmptyStateLabel={handleEmptyStateLabel}
          requestShiftSwapData={requestShiftSwapData}
          handleYourShiftData={handleYourShiftData}
        />
        <SwappingShift
          shiftDaysAvailableToSwapFor={shiftDaysAvailableToSwapFor}
          timeService={timeService}
          dataController={dataController}
          handleEmptyStateLabel={handleEmptyStateLabel}
          requestShiftSwapData={requestShiftSwapData}
          handleSwappingShiftData={handleSwappingShiftData}
        />
        <SwappingStaff
          staffAvailableForSwap={staffAvailableForSwap}
          requestShiftSwapData={requestShiftSwapData}
          handleEmptyStateLabel={handleEmptyStateLabel}
          handleStaffSelected={handleStaffSelected}
          showSwappingStaff={showSwappingStaff}
        />
        <EmptyStateCard emptyStateLabel={emptyStateLabel} selectedShift={selectedShift} />
      </div>
      <Footer
        requestShiftSwapData={requestShiftSwapData}
        handleSubmitButtonClick={handleSubmitButtonClick}
        handleClearButtonClick={handleClearButtonClick}
      />
    </>
  )
}

export default RequestShiftSwap
