import { ComponentController } from 'Common/components'
import { moveArrayItem } from 'utils'
import { pick } from 'lodash'

class RolesController extends ComponentController {
  get isUnitsLoaded() {
    const { appState, store } = this.props
    const { stateKey } = store
    const units = appState.getIn([stateKey, 'list'])

    return !!units && units.length > 0
  }

  getRoles() {
    const { appState, store } = this.props
    const { stateKey } = store
    const units = appState.getIn([stateKey, 'list'])
    const emptyUnits = units.filter((u) => {
      return u.roles.length === 0
    })

    const roles = units
      .reduce((memo, unit) => {
        const { id: unitId, name: unitName } = unit
        const rolesExtended = unit.roles.map((role) => ({ ...role, unitId, unitName }))
        return memo.concat(rolesExtended)
      }, [])
      .map((role, index) => ({ ...role, position: (index + 1) * 10 }))

    return { emptyUnits, roles }
  }

  unitHasManagementRole(unitId) {
    const unitRoles = this._getUnitRoles(unitId)
    return unitRoles.some((role) => role.isManagement)
  }

  loadUnits() {
    const { store } = this.props
    return store.loadDataAction()
  }

  async moveRoleToPosition(roleId, newPosition, unitId) {
    const { data: roles } = this.state

    const prevItemIndex = roles.findIndex((shiftType) => shiftType.unitId === unitId && roleId === shiftType.id)
    const rolesWithoutItem = roles.filter((shiftType, index) => index !== prevItemIndex)

    const nextItemIndex = rolesWithoutItem.findIndex((shiftType) => shiftType.position >= newPosition)
    const newItemIndex = nextItemIndex !== -1 ? nextItemIndex : rolesWithoutItem.length

    const newRoles = moveArrayItem(prevItemIndex, newItemIndex, roles)
    newRoles[newItemIndex].position = newPosition

    const newUnitRoles = newRoles.filter((role) => role.unitId === unitId)
    this.setState({ data: newRoles })

    await this._updateUnitRoles(unitId, newUnitRoles)
    return this.loadUnits()
  }

  createUnitRole(unitId, { isManagement, name }) {
    const unitRoles = this._getUnitRoles(unitId)
    const newRole = { isManagement, name }
    const newRoles = unitRoles.concat(newRole)

    return this._updateUnitRoles(unitId, newRoles)
  }

  updateUnitRole(unitId, roleId, form) {
    const unitRoles = this._getUnitRoles(unitId)
    const newRoles = unitRoles.map((role) => {
      const isUpdatedRole = roleId === role.id
      if (!isUpdatedRole) {
        return role
      }

      return { ...role, ...form }
    })

    return this._updateUnitRoles(unitId, newRoles)
  }

  deleteUnitRole(unitId, roleId) {
    const unitRoles = this._getUnitRoles(unitId)
    const newRoles = unitRoles.filter((role) => roleId !== role.id)
    const hasRole = newRoles.length !== unitRoles.length

    if (!hasRole) {
      return Promise.resolve()
    }

    return this._updateUnitRoles(unitId, newRoles)
  }

  getUnitRole(unitId, roleId) {
    return this._getUnitRoles(unitId).find((role) => role.id === roleId)
  }

  _getUnitRoles(unitId, additionalFields = []) {
    const { data } = this.state

    return data.roles
      .filter((role) => role.unitId === unitId)
      .map((role) => pick(role, ['id', 'name', 'isManagement', ...additionalFields]))
  }

  _updateUnitRoles(unitId, roles) {
    const { store } = this.props
    const newRoles = roles.map((type) => pick(type, ['id', 'name', 'isManagement']))

    return store.overrideUnitRoles(unitId, newRoles)
  }
}

export default RolesController
