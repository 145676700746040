import { pick } from 'lodash'
import { cancelledSubjects } from './NoteSubjects'

export default class CalendarActionsService {
  constructor(calendarStore, timeService) {
    this.timeService = timeService
    this.calendarStore = calendarStore
  }

  updateStaffEventsWithNotes = async (staffEvents, newNote, notesToUpdate, options) => {
    await this.updateNotes(notesToUpdate, options)
    await this.updateStaffEvents(staffEvents, newNote, options)
  }

  createStaffEvents(staffEvents, unitEventVariantId, note, options, omitExpertiseVerification) {
    staffEvents = this._clearStaffEvents(staffEvents, 'create')

    const action = this.calendarStore.createStaffEvents(
      staffEvents,
      unitEventVariantId,
      note,
      options,
      omitExpertiseVerification
    )
    return this._makeAction(action)
  }

  async changeShiftForStaffEvents(staffEventsToDelete, newStaffEvents, unitEventVariantId, note, options) {
    await this.deleteStaffEvents(staffEventsToDelete, options)
    return this.createStaffEvents(newStaffEvents, unitEventVariantId, note, options)
  }

  async updateStaffEvents(staffEvents, note, options) {
    if (note && cancelledSubjects.includes(note.subject)) {
      await this._cancelStaffEvents(staffEvents, options)
    }

    staffEvents = this._clearStaffEvents(staffEvents, 'update')
    const action = this.calendarStore.updateStaffEvents(staffEvents, note, options)
    return this._makeAction(action)
  }

  async updateStaffTimeOffs(staffEventsToDelete, newStaffEvents, unitEventVariantId, note, options) {
    await this.deleteStaffEvents(staffEventsToDelete, options)
    await this.createStaffEvents(newStaffEvents, unitEventVariantId, note, options)
  }

  deleteStaffEvents(staffEvents, options) {
    const staffEventIds = staffEvents.map(({ staffEventId }) => staffEventId)
    const action = this.calendarStore.deleteStaffEvents(staffEventIds, options)
    return this._makeAction(action)
  }

  updateNotes(notes, options) {
    if (notes.length === 0) {
      return Promise.resolve()
    }

    const promises = notes.map((note) => {
      const { ids, subject, text } = note

      return this.calendarStore.updateNotes(ids, { subject, text }, options)
    })

    return this._makeAction(Promise.all(promises))
  }

  _cancelStaffEvents(staffEvents, options) {
    const staffEventIds = staffEvents.map(({ staffEventId }) => staffEventId)
    const action = this.calendarStore.cancelStaffEvents(staffEventIds, options)
    return this._makeAction(action)
  }

  _makeAction(action) {
    this.calendarStore.resetCalendarDay()
    return action
  }

  _clearStaffEvents(staffEvents, operation) {
    const isUpdate = operation === 'update'

    const fields = isUpdate
      ? ['staffEventId', 'startsAt', 'duration']
      : ['startsAt', 'userId', 'unitId', 'shiftId', 'duration', 'isPartialTimeOff']

    return staffEvents.map((event) => pick(event, fields))
  }
}
