import { serializeForm, UpdateEntity } from '@humanics/he-react-common/lib/admin/components'
import definitionsMap from '@humanics/he-react-common/lib/admin/stores/entityDefinitionsMap'
import createUpdateStore from './utils'

const readOnly = ({ hasPermissions }) => !!hasPermissions

const inputDefinitionExtraFields = {
  homeUnitId: {
    type: 'string',
    label: 'Manager Home Unit',
    inputType: 'HomeUnitSelect',
    relatesWith: 'roleIds',
    value: ({ unitManagerProfile }) => unitManagerProfile.homeUnitId
  },
  otherUnitIds: {
    label: 'Manager Other Units',
    inputType: 'EligibleUnitsSelect',
    relatesWith: 'roleIds',
    dependsOn: 'homeUnitId',
    value: ({ unitManagerProfile }) => unitManagerProfile.otherUnitIds
  },
  gasFirstName: {
    label: 'First Name',
    readOnly: true,
    value: ({ hasPermissions, profile, facilityProfile }) =>
      hasPermissions ? profile.firstName : facilityProfile.firstName
  },
  gasLastName: {
    label: 'Last Name',
    readOnly: true,
    value: ({ hasPermissions, profile, facilityProfile }) =>
      hasPermissions ? profile.lastName : facilityProfile.lastName
  },
  firstName: {
    label: 'Legal First Name',
    value: ({ facilityProfile }) => facilityProfile.firstName
  },
  lastName: {
    label: 'Legal Last Name',
    value: ({ facilityProfile }) => facilityProfile.lastName
  },
  email: { readOnly },
  phoneNumber: { readOnly },
  position: {
    type: 'string',
    label: 'Position',
    inputType: 'InputDropdownStaffPositions',
    required: true,
    value: ({ staffProfile }) => (staffProfile.position === 'N/A' ? '' : staffProfile.position)
  },
  employmentType: {
    type: 'string',
    inputType: 'InputDropdown',
    label: 'Employment Type',
    required: true,
    enum: [
      { id: 'fullTime', title: 'Full Time' },
      { id: 'partTime', title: 'Part Time' },
      { id: 'perDiem', title: 'Per Diem' },
      { id: 'inHouseRegistry', title: 'In House Registry' },
      { id: 'adex', title: 'Adex' },
      { id: 'agency', title: 'Agency' },
      { id: 'any', title: 'Any' }
    ]
  },
  availabilityType: {
    type: 'string',
    inputType: 'InputDropdown',
    label: 'Availability type',
    required: true,
    enum: [
      { id: 'anyTime', title: 'Any time' },
      { id: 'dayTime', title: 'Day time' },
      { id: 'nightTime', title: 'Night time' }
    ]
  },
  maximumNumberOfHoursPerWeek: {
    inputType: 'InputNumber',
    label: 'Maximum Number of Hours Per Week',
    min: 0,
    required: true,
    step: 0.25
  }
}

const updateProps = {
  readOperationId: 'readFacilityUser',
  updateOperationId: 'updateFacilityUserFacilityProfile',
  inputDefinitionExtraFields: inputDefinitionExtraFields
}

class FacilityUsersUpdate extends UpdateEntity {
  componentDidMount() {
    const { store, match, readOperationId } = this.props
    store.read({ readOperationId }, match.params.id).then((res) => {
      res.facilityProfile = {
        ...res.profile,
        ...res.facilityProfile,
        ...res.phoneNumber
      }
      delete res.profile.phoneNumber

      if (res.facilityProfile.phoneNumber && !res.facilityProfile.phoneNumber.includes('+')) {
        res.facilityProfile.phoneNumber = `+${res.facilityProfile.phoneNumber}`
      }

      return this.setState({ defaultValues: res, loading: false, apiInitialized: true })
    })
  }

  defaultOnSubmit = (e, form) => {
    const { store, history, match, updateOperationId } = this.props
    const { roleIds } = this.state.defaultValues
    const { id } = match.params

    const isStaff = roleIds.includes('staff')
    const isUnitManager = roleIds.includes('unitManager')

    const { header } = store.getAdminInfo()
    const { propsMappings } = definitionsMap[header.label] || {}

    if (propsMappings) {
      form = serializeForm(form, propsMappings)
    }
    const { unitManagerProfile, staffProfile, profile: facilityProfile } = form

    staffProfile.maximumNumberOfHoursPerWeek = parseFloat(staffProfile.maximumNumberOfHoursPerWeek)

    const updateStaffProfileOperationId = 'updateStaffProfile'
    const updateUnitManagerProfileOperationId = 'updateUnitManagerProfile'

    const shouldUpdateStaffProfile = Object.keys(staffProfile).reduce(
      (memo, field) => !!(memo || (staffProfile[field] && staffProfile[field].length > 0)),
      false
    )
    const shouldUpdateUnitManagerProfile = Object.keys(unitManagerProfile).reduce(
      (memo, field) => !!(memo || (unitManagerProfile[field] && unitManagerProfile[field].length > 0)),
      false
    )

    return store
      .update({ form: { facilityProfile, userId: id }, updateOperationId }, id)
      .then(() => {
        return createUpdateStore({
          isStaff,
          shouldUpdateStaffProfile,
          staffProfile,
          id,
          updateStaffProfileOperationId,
          isUnitManager,
          shouldUpdateUnitManagerProfile,
          unitManagerProfile,
          updateUnitManagerProfileOperationId,
          store
        })
      })
      .then(() => history.push('.'))
  }
}

const facilityUserUpdate = (props) => <FacilityUsersUpdate {...props} {...updateProps} />
export default facilityUserUpdate
