const form = {
  // dayScheduled2: {
  //   definition: {
  //     label: 'Day2',
  //     required: false,
  //     type: 'string',
  //     withLabel: false
  //   },
  //   field: 'dayScheduled2',
  //   formItemClasses: 'col-4 pr30'
  //   // entityType: 'FacilityUserInput',
  //   // path: facilityStaff => facilityStaff.getIn(['staffProfile', 'position']) || ''
  // },

  dayScheduled: {
    definition: {
      label: 'Day',
      required: false,
      type: 'number',
      withLabel: false,
      disabled: false
    },
    formItemClasses: 'col-4 pr30',
    field: 'dayScheduled',
    entityType: 'FacilityUserInput',
    path: (staff) => {
      const { staffProfile } = { ...staff }
      const { dayScheduled } = { ...staffProfile }
      return dayScheduled || ''
    }
  },

  hourScheduled: {
    definition: {
      label: 'Hour',
      required: false,
      type: 'number',
      withLabel: false,
      disabled: false
    },
    formItemClasses: 'col-4 pr30',
    field: 'hourScheduled',
    entityType: 'FacilityUserInput',
    path: (staff) => {
      const { staffProfile } = { ...staff }
      const { hourScheduled } = { ...staffProfile }
      return hourScheduled || ''
    }
  },

  minuteScheduled: {
    definition: {
      label: 'Minute',
      required: false,
      type: 'string',
      withLabel: false,
      disabled: false
    },
    formItemClasses: 'col-4 pr30',
    field: 'minuteScheduled',
    entityType: 'FacilityUserInput',
    path: (staff) => {
      const { staffProfile } = { ...staff }
      const { minuteScheduled } = { ...staffProfile }
      return minuteScheduled || ''
    }
  }

  // timeOfDayScheduled: {
  //   definition: {
  //     label: 'Time Of Day',
  //     required: false,
  //     type: 'string',
  //     withLabel: false,
  //     disabled: false,
  //     defaultValue: ''
  //   },
  //   formItemClasses: 'col-4 pr30',
  //   field: 'timeOfDayScheduled',
  //   entityType: 'FacilityUserInput',
  //   path: staff => {
  //     const { staffProfile } = { ...staff };
  //     const { timeOfDayScheduled } = { ...staffProfile };
  //     return timeOfDayScheduled || '';
  //   }
  // }
}

export default form
