import { Link, matchPath, withRouter } from 'react-router-dom'
import classNames from 'classnames'

export default withRouter(Menu)

function Menu({ sitemap, location }) {
  const { url } = location
  const isMatch = (path) => path && matchPath(url, { path })
  const isItemMatch = (item) => isMatch(item.get('url'))
  return (
    <ul className="inline big medium">
      {sitemap.map(isActive).map((props, index) => (
        <MenuItem key={index} {...props} />
      ))}
    </ul>
  )

  function isActive(menu) {
    let active = isItemMatch(menu) || menu.get('items').find(isItemMatch)
    return { menu, active }
  }
}

export function MenuItem({ menu, active }) {
  const items = menu.get('items')
  const isDropDown = items.size > 0
  const cnLink = classNames({ active, 'dropdown-menu': isDropDown })

  return (
    <li className={classNames({ hide: menu.get('hide') })}>
      <div>
        <Link to={menu.get('url') || '#'} className={cnLink}>
          {menu.get('title')}
          {isDropDown && <i className="icon-down slate-gray" />}
        </Link>
        <MenuItems items={items} />
      </div>
    </li>
  )
}

export function MenuItems({ items }) {
  if (!items.size) {
    return null
  }
  return <ul className="bg-brightgray text-left">{items.map(Item)}</ul>
}

function Item(item, index) {
  return (
    <li key={index} className={classNames({ hide: item.get('hide') })}>
      <Link to={item.get('url')} className="gull-gray bold smaller upper _item">
        {item.get('title')}
      </Link>
    </li>
  )
}
