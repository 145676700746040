import { Component } from 'react'
import './PreviewAnnouncements.scss'

export default class PreviewAnnouncements extends Component {
  render() {
    const component = {
      id: 'defaultForm',
      type: 'CreateAnnouncementForm',
      content: {}
    }

    const {
      activeComponent,
      badgeUrl,
      showSubtitle,
      showLocation,
      hasDetailsPage,
      onDetailsComponentClicked,
      isFormEditable,
      viewDetails,
      onViewDetails
    } = this.props

    return (
      <div className="card">
        {activeComponent && activeComponent.type === 'CreateAnnouncementForm' && isFormEditable && (
          <div className="rectangle" />
        )}
        <div
          style={activeComponent ? { height: '225px', cursor: 'default' } : { height: '257px' }}
          className={
            activeComponent && activeComponent.type === 'CreateAnnouncementForm'
              ? 'notifications'
              : 'notifications-unclicked'
          }
          onClick={() => {
            if (viewDetails) {
              onViewDetails()
            } else if (isFormEditable) {
              onDetailsComponentClicked(component)
            }
          }}
        >
          <div className="simple-card">
            {/* Content (Words) of simple card */}
            <div className="notifications-word" style={badgeUrl ? { width: '80%' } : { width: '100%' }}>
              <Subject subject={this.props.subject} />
              {showSubtitle && <Subtitle subtitle={this.props.subtitle} />}
              <Content content={this.props.content} />
              {showLocation && <Location location={this.props.location} />}
            </div>

            {/* Badge of simple card */}
            {badgeUrl && (
              <div className="notifications-badge">
                <img className="displayed-card-badge" src={badgeUrl} alt="" />
              </div>
            )}
          </div>

          {viewDetails && hasDetailsPage && !activeComponent && (
            <div className="extended-simple-card">
              <div className="separator-extended-simple-card" />
              <div className="view-more-details">View More Details </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

function Subject({ subject }) {
  return <div className="preview-subject">{subject || <p className="dimmed">(Subject)</p>}</div>
}

function Content({ content }) {
  return <div className="preview-content">{content || <p className="dimmed">(Content)</p>}</div>
}

function Location({ location }) {
  return <div className="preview-location">{location || <p className="dimmed">(Location)</p>}</div>
}

function Subtitle({ subtitle }) {
  return <div className="preview-subtitle">{subtitle || <p className="dimmed">(Subtitle)</p>}</div>
}
