import stores from 'stores'

const { shiftSwapsStore } = stores

const DEFAULT_STATE = {
  isLoading: true,
  shiftFilter: 'all',
  roleFilter: 'all',
  sortType: 'soonestShift',
  searchValue: ''
}

export default function ShiftSwapsController(component) {
  const store = () => shiftSwapsStore
  const history = () => component.props.history
  const appState = () => component.props.appState

  return {
    getState: () => component.state,
    getDefaultState: () => DEFAULT_STATE,
    readShiftSwaps,
    getFilteredShiftSwaps,
    onShiftFilterChange,
    onSearchChange,
    onRoleFilterChange,
    onSortFilterChange
  }

  function readShiftSwaps(unitId) {
    return store()
      .loadShiftSwaps(unitId)
      .catch(() => history().push('/'))
  }

  function onShiftFilterChange(shiftFilter) {
    component.setState({ shiftFilter })
  }

  function onRoleFilterChange(roleFilter) {
    component.setState({ roleFilter })
  }

  function onSortFilterChange(sortType) {
    component.setState({ sortType })
  }

  function onSearchChange(e) {
    const { value: searchValue } = e.target
    component.setState({ searchValue })
    return getFilteredShiftSwaps()
  }

  function searchFilter(shiftSwap) {
    const { searchValue } = component.state
    const matchSearchValue = (data) => data.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    let { userProfileFirstName: initiatorFirstName, userProfileLastName: initiatorLastName } =
      shiftSwap.get('initiatorStaffProfile')
    let { userProfileFirstName: recipientFirstName, userProfileLastName: recipientLastName } =
      shiftSwap.get('recipientStaffProfile')

    return (
      matchSearchValue(initiatorFirstName) ||
      matchSearchValue(initiatorLastName) ||
      matchSearchValue(recipientFirstName) ||
      matchSearchValue(recipientLastName)
    )
  }

  function getFilteredShiftSwaps() {
    const { shiftFilter, roleFilter, sortType } = component.state
    const shiftSwaps = appState().getIn(['shiftSwapsState', 'shiftSwaps'])
    const shiftFilterMethod = getShiftTypeIdFilter(shiftFilter)
    const roleFilterMethod = GROUPFILTERS[roleFilter] || GROUPFILTERS['other'](roleFilter)

    const sortMethod = SORT[sortType]

    return shiftSwaps.map((group) => {
      return group.update('shiftSwaps', (shifts) =>
        shifts.filter(shiftFilterMethod).filter(roleFilterMethod).filter(searchFilter).sort(sortMethod)
      )
    })
  }
}

const SORT = {
  lastAccepted: (a, b) => b.get('acceptedDateMoment').diff(a.get('acceptedDateMoment')),
  soonestShift: (a, b) => a.get('soonestShiftDateMoment').diff(b.get('soonestShiftDateMoment'))
}

const getShiftTypeIdFilter = (shiftTypeId) => (shiftSwap) => {
  return shiftTypeId === 'all' || shiftSwap.get('shiftTypeId') === shiftTypeId
}

const GROUPFILTERS = {
  all: (shiftSwap) => shiftSwap,
  other: (roleFilter) => (shiftSwap) => shiftSwap.get('unitRoleId') === roleFilter
}
