import { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Admin, Header } from '@humanics/he-react-common/lib/admin/components'
import ModalWithConfirmation from '@humanics/he-react-common/lib/admin/components/ModalWithConfirmation'
import RolesController from './RolesController'
import { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [Field('name', 'Name', { format: 'plain' }), Field('isManagement', 'Is Management', { format: 'bool' })]

class RolesAdmin extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.controller = new RolesController(this)
    const data = this.controller.getRoles()
    this.state = { fields, data }
  }

  componentDidMount() {
    const { store } = this.props
    store.initializeApi()
    store.loadDataAction()
  }

  componentDidUpdate(prevProps) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const prevStoreState = prevAppState.get(stateKey)
    const isStoreStateChanged = storeState !== prevStoreState

    if (isStoreStateChanged) {
      const data = this.controller.getRoles()
      this.setState({ data })
    }
  }

  render() {
    const { store, stores, appState, location, history, subRoutes, match } = this.props
    const { isConfirmationModalVisible, data } = this.state
    const { emptyUnits, roles } = data

    const emptyGroups = emptyUnits.map((unit) => ({ unitId: unit.id, unitName: unit.name }))

    const isApiInitialized = store.isApiInitialized(appState)

    if (!isApiInitialized) {
      return null
    }

    const { stateKey } = store

    const groupAction = {
      linkTo: location.pathname.replace(/\/$/, '') + '/new',
      actionTitle: 'Create Role'
    }

    const isReorderable = true
    const groupItems = {
      groupBy: 'unitId',
      groupNameField: 'unitName',
      emptyGroups
    }

    const fields = [
      ...this.state.fields,
      {
        render: (dataRow, index, isCurrentRowDraggable) => {
          const { id: roleId, unitId } = dataRow
          return (
            <td>
              <span className="float-right pr10">
                <button
                  onClick={(e) => this.onDeleteRoleInitiated(e, roleId, unitId)}
                  className="button danger primary"
                  disabled={false}
                >
                  Delete
                </button>
              </span>
            </td>
          )
        }
      }
    ]

    const confirmationModalProps = {
      modalTitle: 'Delete Role',
      confirmationMessage: 'Do you really want to delete this role?',
      isCancelVisible: true,
      isConfirmVisible: true,
      onCancelConfirmation: () => this.setState({ isConfirmationModalVisible: false, deleteParameters: null }),
      onConfirm: this.onDeleteRole,
      isModalVisible: isConfirmationModalVisible,
      isConfirmationVisible: isConfirmationModalVisible
    }

    return (
      <>
        <ModalWithConfirmation {...confirmationModalProps} />
        <Admin>
          <Header title="Roles" actions={null} />
          <Grid
            orderField="position"
            history={history}
            Link={Link}
            renderDataRow={this.renderDataRow}
            updateItemPosition={this.updateItemPosition}
            data={roles}
            store={store}
            dataType={stateKey}
            fields={fields}
            allowUpdate={true}
            groupItems={groupItems}
            stores={stores}
            groupAction={groupAction}
            isReorderable={isReorderable}
            currentPath={match.url}
          />
          {subRoutes}
        </Admin>
      </>
    )
  }

  onDeleteRoleInitiated = (event, roleId, unitId) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ isConfirmationModalVisible: true, deleteParameters: { roleId, unitId } })
  }

  onDeleteRole = async () => {
    const { roleId, unitId } = this.state.deleteParameters

    try {
      await this.controller.deleteUnitRole(unitId, roleId)
    } catch {
      // its intentional
    }
    await this.controller.loadUnits()

    this.setState({ isConfirmationModalVisible: false, deleteParameters: null })
  }

  updateItemPosition = (itemId, newPosition, groupValue) => {
    this.controller.moveRoleToPosition(itemId, newPosition, groupValue)
  }
}

export default RolesAdmin
