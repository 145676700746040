import { CreateEntity, serializeForm } from '@humanics/he-react-common/lib/admin/components'
import definitionsMap from '@humanics/he-react-common/lib/admin/stores/entityDefinitionsMap'
import createUpdateStore from './utils'
import { t } from 'i18n'
import { addWarningBanner } from 'stores'

function FacilityUsersCreate(props) {
  const { timeService } = props
  const today = timeService.timeMoment(null).format('MM-DD-YYYY')
  const usDateToday = timeService.usDateToMoment(today).toISOString()

  const isValidEmail = (email) => {
    const rgex =
      /^(([^<>() [\]\\.,;:\s@"]+(\.[^<>() [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/

    return rgex.test(email)
  }

  const onSubmit = async (e, form) => {
    const { store, history } = props
    const { header } = await store.getAdminInfo()
    const { propsMappings } = definitionsMap[header.label] || {}

    if (propsMappings) {
      form = serializeForm(form, propsMappings)
    }
    const isStaff = form.roleIds.includes('staff')
    const isUnitManager = form.roleIds.includes('unitManager')
    const { unitManagerProfile, staffProfile, ...facilityUser } = form

    staffProfile.maximumNumberOfHoursPerWeek = parseFloat(staffProfile.maximumNumberOfHoursPerWeek)

    const updateStaffProfileOperationId = 'updateStaffProfile'
    const updateUnitManagerProfileOperationId = 'updateUnitManagerProfile'

    const shouldUpdateStaffProfile = Object.keys(staffProfile).reduce((memo, field) => {
      return !!(memo || (staffProfile[field] && staffProfile[field].length > 0))
    }, false)
    const shouldUpdateUnitManagerProfile = Object.keys(unitManagerProfile).reduce((memo, field) => {
      return !!(memo || (unitManagerProfile[field] && unitManagerProfile[field].length > 0))
    }, false)

    const email = facilityUser?.profile?.email

    if (email?.length > 0 && !isValidEmail(email)) {
      addWarningBanner({ message: t('errors.invalid_email', { email }) })
      history.push('.')
      return
    }

    return store
      .create({ form: facilityUser, createOperationId })
      .then((result) => {
        const id = result?.id
        if (result && result.status >= 400) {
          /* using throw for catch current object - not throw new Error  */
          throw { response: result }
        }
        return createUpdateStore({
          isStaff,
          shouldUpdateStaffProfile,
          staffProfile,
          id,
          updateStaffProfileOperationId,
          isUnitManager,
          shouldUpdateUnitManagerProfile,
          unitManagerProfile,
          updateUnitManagerProfileOperationId,
          store
        })
      })
      .then(() => history.push('.'))
  }

  const createProps = {
    createOperationId,
    createDefinitionName: 'FacilityUser',
    inputDefinitionExtraFields: inputDefinitionExtraFields(usDateToday)
  }
  const { appState } = props

  const isInstanceAdministrator = appState.getIn(['authentication', 'isInstanceAdministrator'])

  const isFacilityAdministrator =
    appState
      .get('admin.Role')
      .get('list')
      .find((e) => e.id === 'facilityAdministrator') || false

  const listOfValues = [
    {
      id: 'staff',
      title: 'Staff'
    },
    {
      id: 'unitManager',
      title: 'Unit Manager'
    },
    {
      id: 'skillscheckAdministrator',
      title: 'Skillscheck Administrator'
    }
  ]
  let allProps = { ...props }
  if (!isInstanceAdministrator && isFacilityAdministrator) {
    allProps = { ...allProps, listOfValues }
  }
  return <CreateEntity {...allProps} {...createProps} onSubmit={onSubmit} />
}

const createOperationId = 'createFacilityUser'

const inputDefinitionExtraFields = (today) => ({
  avatarUrl: {
    type: 'string',
    label: 'Avatar Url',
    required: false
  },
  homeUnitId: {
    type: 'string',
    label: 'Manager Home Unit',
    inputType: 'HomeUnitSelect',
    relatesWith: 'roleIds'
  },
  otherUnitIds: {
    label: 'Manager Other Units',
    inputType: 'EligibleUnitsSelect',
    relatesWith: 'roleIds',
    dependsOn: 'homeUnitId'
  },
  position: {
    type: 'string',
    label: 'Position',
    inputType: 'InputDropdownStaffPositions',
    dependsVisibilityOn: ['roleIds'],
    visibleIfEquals: [[['staff'], []]],
    forceRequired: true,
    withLabel: true,
    required: true
  },
  employmentType: {
    type: 'string',
    inputType: 'InputDropdown',
    label: 'Employment Type',
    forceRequired: true,
    withLabel: true,
    required: true,
    dependsVisibilityOn: ['roleIds'],
    visibleIfEquals: [[['staff'], []]],
    enum: [
      { id: 'fullTime', title: 'Full Time' },
      { id: 'partTime', title: 'Part Time' },
      { id: 'perDiem', title: 'Per Diem' },
      { id: 'inHouseRegistry', title: 'In House Registry' },
      { id: 'adex', title: 'Adex' },
      { id: 'agency', title: 'Agency' },
      { id: 'any', title: 'Any' }
    ]
  },
  availabilityType: {
    type: 'string',
    inputType: 'InputDropdown',
    label: 'Availability type',
    dependsVisibilityOn: ['roleIds'],
    visibleIfEquals: [[['staff'], []]],
    required: true,
    forceRequired: true,
    withLabel: true,
    enum: [
      { id: 'anyTime', title: 'Any time' },
      { id: 'dayTime', title: 'Day time' },
      { id: 'nightTime', title: 'Night time' }
    ]
  },
  maximumNumberOfHoursPerWeek: {
    inputType: 'InputNumber',
    label: 'Maximum Number of Hours Per Week',
    dependsVisibilityOn: ['roleIds'],
    visibleIfEquals: [[['staff'], []]],
    min: 0,
    required: true,
    step: 0.25
  },
  startsAt: {
    label: 'Starts At',
    inputType: 'InputDateFlatPickr',
    required: true,
    withLabel: true,
    value: today
  },
  endsAt: {
    label: 'Ends At',
    inputType: 'InputDateFlatPickr',
    withLabel: true
  },
  phoneNumber: {
    required: false
  }
})

export default FacilityUsersCreate
