import { Component } from 'react'
import { chunk } from 'lodash'
import Cell from './Cell'
import classNames from 'classnames'
import { Map } from 'immutable'

export default class StaffShiftDays extends Component {
  onMouseOver = (event) => this.onCellEvent(event, 'onMouseOver')

  onMouseLeave = (event) => this.onCellEvent(event, 'onMouseLeave')

  onClick = (event) => this.onCellEvent(event, 'onClick')

  onDoubleClick = (event) => {
    return this.onCellEvent(event, 'onDoubleClick')
  }

  onContextMenu = (event) => this.onCellEvent(event, 'onContextMenu')

  getParentDataCellIndex = (element) => {
    while (element && !element.hasAttribute('data-cell-index')) {
      element = element.parentElement
    }
    return element
  }
  onCellEvent = (event, eventType) => {
    // instead of `e.target.dataset` because IE
    const { target } = event
    const dataCellElement = !target.hasAttribute('data-cell-index') ? this.getParentDataCellIndex(target) : target
    if (!dataCellElement?.hasAttribute('data-cell-index') || !this.props.onCellEvent) {
      return
    }
    event.preventDefault()

    const cellIndex = parseInt(dataCellElement.getAttribute('data-cell-index'))
    const identityHash = dataCellElement.getAttribute('data-cell-identity-hash')

    const { shiftKey, metaKey, ctrlKey } = event
    const eventMeta = {
      eventTarget: dataCellElement,
      isShiftKeyPressed: shiftKey,
      isMetaKeyPressed: metaKey,
      isCtrlKeyPressed: ctrlKey
    }
    const meta = { eventMeta, identityHash }
    this.props.onCellEvent(cellIndex, eventType, meta)
  }

  render() {
    const { staff, updateCellTooltip, staffPath, calendarType, mode, style, activeDateRange } = this.props
    const cells = staff.get('cells')
    const isAvatarCell = cells?.some?.((cell) => cell.get('isAvatarCell') === true)
    let days = activeDateRange.get('days')
    if (mode === 'week') {
      days = activeDateRange.get('days').filter((day) => day.isActiveWeek)
    }
    const isShiftLoading = staff.get('isShiftLoading')

    const weeks = chunk(days, 7)
    const weeksCount = weeks.length
    const width = `${100 / weeksCount}%`

    const onContextMenuHandler = !isAvatarCell
      ? this.onContextMenu
      : () => {
          // This is intentional.
        }
    const additionalEventHandler =
      calendarType === 'calendar'
        ? {
            onDoubleClick: this.onDoubleClick,
            onContextMenu: onContextMenuHandler
          }
        : {}

    return (
      <section
        className={classNames('row bl4 bt1 col-10', { loading: isShiftLoading })}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        {...additionalEventHandler}
      >
        {!isShiftLoading &&
          weeks.map((days, weekIndex) => (
            <div key={weekIndex} className="br4 _weekCells bg-white row-7" style={{ width }}>
              {days.map((day) => this.renderCell(cells, day, updateCellTooltip, staffPath, style))}
            </div>
          ))}
      </section>
    )
  }

  renderCell(cells, day, updateCellTooltip, staffPath, style) {
    const {
      showPopup,
      staff,
      cellsPathsInProcessing,
      shiftTime,
      indicators,
      activeDateRange,
      otherStaffMap,
      facilityShiftsMap,
      isPrintView,
      notes,
      mode,
      timeService
    } = this.props
    const leftOffset = timeService.timeMoment(activeDateRange.get('startsAt')).day()
    let cellIndex = day.index - leftOffset

    let cell = new Map()
    if (cellIndex >= 0 && cellIndex < cells?.size) {
      cell = cells?.get(cellIndex)
    }

    const roleIndex = staff.get('roleIndex')
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')

    const cellKey = `${roleIndex}|${shiftIndex}|${staffIndex}|${cellIndex}`
    const isProcessing = cellsPathsInProcessing?.contains?.(cellKey)

    if (isProcessing) {
      cell = cell.set('isProcessing', isProcessing)
    }

    const { selection } = this.props
    const isCellSelected = selection?.getIn?.(['cells', cellIndex])
    if (isCellSelected) {
      const cells = selection.get('cells')

      const isPreviousCellSelected = cells.get(cellIndex - 1)
      const isNextCellSelected = cells.get(cellIndex + 1)
      const lastSelectedCellIndex = cells.findLastKey(() => true)
      const isLastSelectedCell = lastSelectedCellIndex === cellIndex

      cell = cell.set('isSelected', true)

      if (isPreviousCellSelected) {
        cell = cell.set('isPreviousSelected', true)
      }
      if (isNextCellSelected) {
        cell = cell.set('isNextSelected', true)
      }
      if (isLastSelectedCell) {
        cell = cell.set('isLastSelectedCell', true)
      }
    }

    const isMultipleCellsSelected = selection?.get?.('cells').size > 1

    const cellProps = {
      cell,
      day,
      cellIndex,
      showPopup,
      indicators,
      isMultipleCellsSelected,
      updateCellTooltip: updateCellTooltip,
      staffPath,
      shiftTime,
      facilityUsersMap: activeDateRange.get('facilityUsersMap'),
      otherStaffMap,
      facilityShiftsMap,
      isPrintView,
      notes,
      rowStyle: style,
      mode,
      activeDateRange,
      timeService
    }

    return <Cell key={cellIndex} {...cellProps} />
  }
}
