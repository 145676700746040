import { PureComponent } from 'react'
import classNames from 'classnames'
import { SpillProofDiv } from 'Common/components'
import { ImmutableSelect } from 'Manager/components'
import { fromJS } from 'immutable'

export default class Summary extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { selectedOption: this.props.roleViewPreference }
    this.viewOptions =
      this.props.calendarType === 'calendar'
        ? fromJS([
            {
              id: 'shift',
              name: 'Shift View'
            },
            {
              id: 'staff',
              name: 'Staff View'
            }
          ])
        : fromJS([
            {
              id: 'staff',
              name: 'Staff View'
            }
          ])
  }

  componentDidUpdate(prevProps) {
    if (prevProps.roleViewPreference !== this.props.roleViewPreference) {
      this.setState({ selectedOption: this.props.roleViewPreference })
    }
  }

  getViewPreferenceObject = () => {
    return {
      units: {
        [this.props.unitId]: {
          roles: {
            [this.props.roleId]: {
              viewPreference: this.state.selectedOption
            }
          }
        }
      }
    }
  }

  onChangeHandler = () => {
    if (this.props.calendarType === 'calendar') {
      return (e) => {
        this.setState({ selectedOption: e.target.value }, async () => {
          const newViewPreferenceSettings = this.getViewPreferenceObject()
          await this.props.onChangeViewPreference(newViewPreferenceSettings)
        })
      }
    }
    return () => {}
  }

  render() {
    const {
      roleId,
      roleName,
      isCollapsed,
      overstaffCount,
      understaffCount,
      toggleIsCollapsed,
      isMismatchCounterVisible
    } = this.props

    const isOverstaffed = overstaffCount < 0
    const isUnderstaffed = understaffCount > 0
    const hideViewChangeDropdown = true

    const renderOverstaffed = () => {
      return (
        <span
          className={classNames('_overstaffed label bg-pizazz white bold rounded ', {
            'non-rounded-right': isUnderstaffed
          })}
        >
          {-overstaffCount}
        </span>
      )
    }

    const renderUnderstaffed = () => {
      return (
        <span
          className={classNames('_understaffed label error bold rounded', {
            'non-rounded-left': isOverstaffed
          })}
        >
          {understaffCount}
        </span>
      )
    }

    const isOverstaffCounterVisible = isMismatchCounterVisible && isOverstaffed
    const isUnderstaffCounterVisible = isMismatchCounterVisible && isUnderstaffed

    return (
      <aside className="col-2">
        <button className={`_expander white row col-${hideViewChangeDropdown ? 12 : 8}`} onClick={toggleIsCollapsed}>
          <span className="col-1">
            <sh-icon size="s" icon={isCollapsed ? 'plus' : 'minus'} />
          </span>
          <span className="pl5 col-11">
            <sh-text size="header-2" className="">
              <section className="upper smaller bold col-12">
                <SpillProofDiv id={roleId} tooltipPosition="right" hasCustomTooltip={true}>
                  {roleName}
                </SpillProofDiv>
              </section>
              {isOverstaffCounterVisible && renderOverstaffed()}
              {isUnderstaffCounterVisible && renderUnderstaffed()}
            </sh-text>
          </span>
        </button>
        {!hideViewChangeDropdown && (
          <span className="col-4 pr5">
            <ImmutableSelect
              options={this.viewOptions}
              value={this.state.selectedOption}
              onChange={this.onChangeHandler()}
              className="nowrap-style dropdown-height"
            />
          </span>
        )}
      </aside>
    )
  }
}
