import { fromJS } from 'immutable'
import extendShiftOpportunity from './extendShiftOpportunity'
import { createShiftOpportunitiesMutation } from './Mutations'

export default function OpenShiftStore() {
  let gqlClient = null

  return { initialize, createShiftOpportunity }

  function initialize(state, context) {
    ;({ gqlClient } = context)
    return state
  }

  async function createShiftOpportunity(shiftDayId, openShift) {
    const { eligibleUserIds, isBonusPay, isImportant, isAlertEligibleStaff, ...rest } = openShift
    const eligibilityType = openShift.eligibilityType || (eligibleUserIds ? 'invite' : 'eligible')
    const shiftOpportunities = [{ shiftDayId, eligibleUserIds }]
    const incentiveBonus = isBonusPay ? 'yes' : ''
    const parameters = {
      ...rest,
      incentiveBonus,
      eligibilityType,
      isImportant,
      parameters: { shiftOpportunities }
    }

    const { createShiftOpportunities: newOpportunities } = await gqlClient.mutate(
      createShiftOpportunitiesMutation,
      parameters
    )

    const shiftOpportunity = await extendShiftOpportunity(gqlClient, newOpportunities[0])

    return fromJS(shiftOpportunity)
  }
}
