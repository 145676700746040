import queryString from 'query-string'
import { navigate, paths } from 'Navigation'
import EquipmentCreateUpdate from './EquipmentLegacyCreateUpdate'
import { merge } from 'lodash'
import { Redirect } from 'react-router-dom'

export default class EquipmentLegacyUpdate extends EquipmentCreateUpdate {
  onFormSubmit = async (event, form) => {
    const { match } = this.props
    const { urlId, id } = match.params
    if (id) {
      const skills = this.controller.getSkills(id)
      await this.controller.updateSkills(form, id, skills)
    }
    this.controller.loadData()
    return navigate.to.AdminEquipmentsLegacy({ urlId })
  }

  render() {
    const { isEquipmentsLoaded } = this.controller
    if (!isEquipmentsLoaded) {
      return null
    }
    const {
      match,
      store,
      stores,
      history,
      appState,
      location: { search: url }
    } = this.props
    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }

    const isStoreApiInitialized = store.isApiInitialized(appState)
    if (!isStoreApiInitialized) {
      return null
    }

    const { groupId } = queryString.parse(url)
    const { id } = match.params
    let inputDefinition = store.getInputDefinition('ExpertiseGroup')
    const entityType = groupId ? 'Equipment' : 'EquipmentGroup'
    const title = groupId ? 'Update Equipment' : 'Update Category'
    let defaultValues = {}
    if (id) {
      const equipment = this.controller.getEquipment(id)
      const skills = this.controller.getSkills(id)
      const { properties } = inputDefinition
      const inputDefinitionExtraFields = {
        parentId: {
          formItemClasses: 'header',
          enum: this.controller.equipmentGroups,
          disabled: true
        },
        title: {
          formItemClasses: 'top-sidebar',
          disabled: true
        },
        description: {
          formItemClasses: 'bottom-sidebar',
          disabled: true
        },
        imageUrl: {
          formItemClasses: 'image',
          disabled: true
        },
        skills: {
          type: 'array',
          formItemClasses: 'footer'
        }
      }
      inputDefinition = { properties: merge({}, properties, inputDefinitionExtraFields) }
      defaultValues = {
        ...defaultValues,
        parentId: groupId,
        ...equipment,
        skills: skills.map((item) => item.name)
      }
    } else {
      return <Redirect to={paths.AdminEquipmentsLegacy} />
    }
    return this.renderModal(
      entityType,
      inputDefinition,
      defaultValues,
      stores,
      history,
      appState,
      title,
      groupId,
      'Update'
    )
  }
}
