import { formatAsDate, formatAsTime, getDurationLabel, getLabelForLocation } from '../utils'
import './RecommendedSlotData.scss'

export interface IRecommendedSlotDataProps {
  active: boolean
  slot: any
  handleClick: () => void
  timeService: any
  idx: number
}

const RecommendedSlotData = ({ active, slot, handleClick, timeService, idx }: IRecommendedSlotDataProps) => {
  const { startsAt, location, duration } = slot
  const date = formatAsDate(startsAt, timeService)
  const time = formatAsTime(startsAt, timeService)
  const endsAtMoment = timeService.timeMoment(startsAt)
  const endsAt = timeService.add(endsAtMoment, duration, 'minutes')
  const locationLabel = getLabelForLocation(location)
  const endsAtDate = formatAsDate(endsAt, timeService)
  const endsAtTime = formatAsTime(endsAt, timeService)
  const durationLabel = getDurationLabel(duration)
  const dateLabel = endsAtDate === date ? date : `${date} - ${endsAtDate}`

  return (
    <sh-list-item
      clickable
      label={`Recommendation: ${idx + 1}`}
      active={active ? true : undefined}
      onClick={handleClick}
    >
      <div className="slot-data-container row">
        <div className="slot-data-left-container">
          <div className="slot-row">
            <sh-icon size="xs" icon="location-pin" class="slot-icon"></sh-icon>
            <div>
              <b>{`${locationLabel}`}</b>
            </div>
          </div>
        </div>

        <sh-divider spacing="s" vertical></sh-divider>

        <div className="slot-data-right-container">
          <div className="slot-row">
            <sh-icon size="xs" icon="calendar" class="slot-icon"></sh-icon>
            <div>
              <b>{dateLabel}</b>
            </div>
          </div>
          <div className="slot-row">
            <sh-icon size="xs" icon="time" class="slot-icon"></sh-icon>
            <div>
              <b>
                {`${time} - ${endsAtTime}`} <i>{`(${durationLabel})`}</i>
              </b>
            </div>
          </div>
        </div>
      </div>
    </sh-list-item>
  )
}

export default RecommendedSlotData
