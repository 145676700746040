import './AddComponentButton.scss'

export default function AddComponentButton({ insertDetailsComponent }) {
  return (
    <div>
      <button onClick={insertDetailsComponent}>
        <p className="icon-plus add-component-plus-sign" />
      </button>
    </div>
  )
}
//
