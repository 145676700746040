import { RefObject, useRef, useEffect, useState } from 'react'
import DataController from './Controllers/DataController'
import GridHeader from './GridHeader'
import Grid from './Grid'
import { withAppContext } from 'AppContext'
import WeekDays from './WeekDays'
import './Layout.scss'
import { ItemTypeFilterContextProvider } from './CalendarContext'
import { Redirect, useParams } from 'react-router'
import { navigate } from 'Navigation'
import DetailsPane from './DetailsPane'

function Layout(props: any) {
  const thisRef = useRef({ props })
  const dataControllerRef: RefObject<DataController> = useRef(new DataController(thisRef.current))
  thisRef.current.props = props

  const userId = props.appState.getIn(['authentication', 'facilityUser', 'id'])
  const { timeService, match, location, appState } = props
  const { date } = match.params
  const monthStartsAt = timeService.usDateToMoment(date).startOf('month').toISOString()

  const [selectedCellDate, setSelectedCellDate] = useState<any>(null);

  useEffect(() => {
    const loadData = async () => {
      await dataControllerRef.current?.loadMyShiftSwapRequestsForMonth(monthStartsAt, userId)
      await dataControllerRef.current?.loadMyStaffEventsForMonth(monthStartsAt)
      await dataControllerRef.current?.loadMyStaffDaysForMonth(monthStartsAt)
      await dataControllerRef.current?.loadMyStaffTimeOffRequestsForMonth(monthStartsAt)
    }
    loadData()
  }, [monthStartsAt, timeService, userId])

  const { section } = useParams<{ section: string }>()

  if (!section) {
    return <Redirect to={navigate.from.StaffCalendarPage.to.StaffCalendarPage({ section: 'shifts-assigned' })} />
  }

  const publicStaffMap = appState.getIn(['staffGeneralData', 'publicStaffMap'])
  const staffEventsMap = appState.getIn(['staffCalendar', 'staffEventsForMonth', 'staffEventsMap'])
  const staffDaysMap = appState.getIn(['staffCalendar', 'staffDaysForMonth', 'staffDaysMap'])
  const staffTimeOffRequestsMap = appState.getIn(['staffTimeOffRequests', 'staffTimeOffRequestsForMonth', 'myStaffTimeOffRequests'])

  const initiatedShiftSwapRequestsMap = props.appState.getIn([
    'staffShiftSwaps',
    'shiftSwapRequestsForMonth',
    'initiatedShiftSwapRequestsMap'
  ])

  const receivedShiftSwapRequestsMap = props.appState.getIn([
    'staffShiftSwaps',
    'shiftSwapRequestsForMonth',
    'receivedShiftSwapRequestsMap'
  ])
  const staffGeneralData = appState.getIn(['staffGeneralData'])

  return (
    <ItemTypeFilterContextProvider>
      <div className="row-nowrap g10">
        <div className="col-8">
          <div className="row col-12 header align-middle between">
            <GridHeader timeService={timeService} date={date} />
          </div>
          <div className="row col-12">
            <WeekDays />
          </div>
          <div>
            <Grid
              timeService={timeService}
              staffEvents={staffEventsMap}
              initiatedShiftSwapRequests={initiatedShiftSwapRequestsMap}
              receivedShiftSwapRequests={receivedShiftSwapRequestsMap}
              section={section}
              date={date}
              staffDaysMap={staffDaysMap}
              setSelectedCellDate={setSelectedCellDate}
            />
          </div>
        </div>
        <div className="col-4">
          <DetailsPane
            staffEventsMap={staffEventsMap}
            timeService={timeService}
            staffTimeOffRequestsMap={staffTimeOffRequestsMap}
            usDate={date}
            unitsMap={staffGeneralData.get('unitsMap')}
            shiftsMap={staffGeneralData.get('shiftsMap')}
            publicStaffMap={publicStaffMap}
            section={section}
            initiatedShiftSwapRequests={initiatedShiftSwapRequestsMap}
            receivedShiftSwapRequests={receivedShiftSwapRequestsMap}
            userId={userId}
            setSelectedCellDate={setSelectedCellDate}
            selectedCellDate={selectedCellDate}
          />
        </div>
      </div>
    </ItemTypeFilterContextProvider>
  )
}

export default withAppContext(Layout)
