import './ImageCheckboxInput.scss'

export default function ImageCheckboxInput({ badgeImage, onBadgeClick, avatarBadge, badgeUrl }) {
  const badgeName = avatarBadge === badgeUrl && badgeImage === badgeUrl ? 'Me' : null
  const badgePreviewImage = badgeUrl === badgeImage ? '' : badgeImage
  const badgeStyle = badgeImage === badgeUrl ? { borderColor: '#4a90e2' } : null

  return (
    <div className="each-badge">
      <img
        style={badgeStyle}
        className="noborder"
        data-src={badgePreviewImage}
        src={badgeImage}
        alt=""
        onClick={onBadgeClick}
      />
      <div className="badge-name"> {badgeName} </div>
    </div>
  )
}
