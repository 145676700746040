import { withAppContext } from 'AppContext'
import Footer from 'Footer/Footer'
import { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { t } from 'i18n'
import { navigate } from '../../Navigation'

const wait = async (ms = 200) => new Promise((resolve) => setTimeout(resolve, ms))

function EngagementCenter({ timeService, appState, unit, Dialog, skipUnitValidation = false }) {
  const facility = appState.getIn(['authentication', 'facility'])
  const facilityId = facility.get('scope')
  const unitId = unit.get('id')

  const [isLoading, setIsLoading] = useState(true)
  const [src, setSrc] = useState('')
  const [routeNavigationMessage, setRouteNavigationMessage] = useState(null)

  const handleBlockedNavigation = ({ pathname }) => {
    showWarningModal(pathname)
    return false
  }

  const showWarningModal = async (navigateToPath) => {
    const dialogConfirmed = await Dialog.confirm(routeNavigationMessage, {
      accept: t('skillscheck.confirmations.exit.options.accept'),
      cancel: t('skillscheck.confirmations.exit.options.cancel'),
      title: t('skillscheck.confirmations.exit.options.title'),
      type: 'warning'
    })

    if (!dialogConfirmed) {
      return
    }

    setRouteNavigationMessage(null)
    navigate(navigateToPath)
  }

  const handleMessage = (event) => {
    // Check the origin of the message for added security
    const origin = window.SKILLSCHECK_BASE_URL || window.location.origin
    if (event.origin !== origin) {
      return
    }

    setRouteNavigationMessage(event.data.isEdited ? event.data.message : null)
  }

  global.addEventListener('message', handleMessage)

  useEffect(() => {
    setIsLoading(true)

    if (!facilityId || (!skipUnitValidation && !unitId)) {
      return
    }

    ;(async () => {
      await wait()
      const baseURL = window.SKILLSCHECK_BASE_URL || ''
      setSrc(`${baseURL}/skillscheck/user/dashboard?facilityId=${facilityId}&unitId=${unitId}`)
      setIsLoading(false)
    })()

    return () => {
      setIsLoading(true)
      global.removeEventListener('message', handleMessage)
    }
  }, [facilityId, unitId])

  return (
    <div className="h100">
      <div className="wrapper-container" style={{ height: '100%' }}>
        {isLoading ? (
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <sh-spinner></sh-spinner>
          </div>
        ) : (
          <div style={{ height: '100%', width: '100%' }}>
            <Prompt when={!!routeNavigationMessage} message={handleBlockedNavigation} />
            <iframe height="100%" width="100%" src={src} title="Skills Check" />
          </div>
        )}
      </div>
      <Footer timeService={timeService} />
    </div>
  )
}

export default withAppContext(EngagementCenter)
