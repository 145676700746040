import ReasonDropdown from './ReasonDropdown'
import { LocationDropdown, DatePicker, TimePicker } from '../Common'
import ReasonDetails from './ReasonDetails'
import { useCallback } from 'react'

export interface ICustomSlotProps {
  customSlot: any
  active: boolean
  handleClick: () => void
  handleChange: (customSlot: any) => void
  timeService: any
}

const CustomSlot = ({ customSlot, active, handleClick, handleChange, timeService }: ICustomSlotProps) => {
  const { location, date, time, reason, reasonDetails } = customSlot || {}
  const isReasonNotListed = reason === 'other'

  const handleLocationChange = useCallback(
    (newLocation: string) => {
      handleChange({ location: newLocation })
    },
    [handleChange]
  )

  const handleDateChange = useCallback(
    (newDate: string) => {
      handleChange({ date: newDate })
    },
    [handleChange]
  )

  const handleTimeChange = useCallback(
    (newTime: string) => {
      handleChange({ time: newTime })
    },
    [handleChange]
  )

  const handleReasonChange = useCallback(
    (newReason: string) => {
      handleChange({ reason: newReason, reasonDetails: undefined })
    },
    [handleChange]
  )

  const handleReasonDetailsChange = useCallback(
    (newReasonDetails: string) => {
      handleChange({ reasonDetails: newReasonDetails })
    },
    [handleChange]
  )

  return (
    <sh-list-item
      clickable
      key={'custom'}
      label={`Custom slot`}
      onClick={() => {
        handleClick()
      }}
      active={active ? true : undefined}
    >
      <div className="row g10 mt5">
        <div className="col-2">
          <LocationDropdown location={location} setLocation={handleLocationChange} />
        </div>
        <div className="col-2">
          <DatePicker date={date} setDate={handleDateChange} timeService={timeService} />
        </div>
        <div className="col-2">
          <TimePicker time={time} setTime={handleTimeChange} />
        </div>
        <div className="col-2">
          <ReasonDropdown reason={reason} setReason={handleReasonChange} />
        </div>
        <div className="col-3">
          {isReasonNotListed && (
            <ReasonDetails reasonDetails={reasonDetails} setReasonDetails={handleReasonDetailsChange} />
          )}
        </div>
      </div>
    </sh-list-item>
  )
}

export default CustomSlot
