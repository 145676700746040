import { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import { withAppContext } from 'AppContext'
import { completeTwoFactorAuthentication } from './stores/humanics/GAS/Queries'
import { navigate } from './Navigation'

const MfaSchema = yup.object().shape({
  code: yup.number().required().positive().integer()
})

class Mfa extends Component {
  onSubmit = async (values, { setSubmitting }) => {
    const { appState } = this.props
    const instance = appState.getIn(['authentication', 'instance'])
    const gasGQLClient = appState.getIn(['authentication', 'gasGQLClient'])
    const {
      completeTwoFactorAuthentication: { authorizationCode }
    } = await gasGQLClient.mutate(completeTwoFactorAuthentication, {
      parameters: values.code ? { code: values.code.trim() } : values
    })
    await instance.authorizeMFA({ authorizationCode })
    setSubmitting(false)
    // Don't ask history doesn't support await
    document.location.href = document.location.origin
  }

  render() {
    const { appState } = this.props
    const isLoggedIn = appState.getIn(['authentication', 'isLoggedIn'])
    const instance = appState.getIn(['authentication', 'instance'])
    const gasGQLClient = appState.getIn(['authentication', 'gasGQLClient'])
    if (gasGQLClient === undefined || instance === undefined) {
      return navigate.to.Login()
    }
    if (isLoggedIn) {
      return navigate.to.Root()
    }
    return (
      <main className="hx-mfa">
        <div className="hx-mfa-container hx-single-column-layout">
          <div className="logo" />
          <Formik
            validationSchema={MfaSchema}
            onSubmit={this.onSubmit}
            initialValues={{
              code: ''
            }}
          >
            {({ errors, touched, isSubmitting, isValid }) => (
              <Form className="hx-form">
                <header>
                  <h3>Enter MFA Code</h3>
                </header>
                <section>
                  <label data-error={touched.code && errors.code}>
                    <span className="hx-form-label">Code</span>
                    <Field name="code" type="code" />
                  </label>
                </section>
                <footer className="_footer">
                  <button
                    type="submit"
                    className={classNames('button primary', { loading: isSubmitting })}
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </button>
                </footer>
              </Form>
            )}
          </Formik>
        </div>
      </main>
    )
  }
}

export default withAppContext(Mfa)
