import { List, Map } from 'immutable'
import { ComponentController } from 'Common/components'

export default class NotesController extends ComponentController {
  get defaultState() {
    return { notesMap: Map() }
  }

  buildNotesMap() {
    const notes = this.props.calendar.get('notes')

    if (!notes || notes.size === 0) {
      return this.setState(this.defaultState)
    }

    const { timeService } = this.props
    const notesDetails = this._buildNotesDetails()

    const notesMap = notes
      .valueSeq()
      .filter((note) => notesDetails.get(note.get('id')) !== undefined)
      .sortBy((note) => note.get('date'))
      .groupBy((note) => {
        const noteDate = note.get('date')
        return timeService.timeMoment(noteDate).format('YYYY-MM-DD')
      })
      .map((dayNotes) =>
        dayNotes
          .map((note) => note.merge(notesDetails.get(note.get('id'))))
          .sort((note1, note2) => {
            const note1SortIndex = note1.get('sortIndex')
            const note2SortIndex = note2.get('sortIndex')

            return note1SortIndex < note2SortIndex ? -1 : 1
          })
      )
    this.setState({ notesMap })
  }

  _buildNotesDetails() {
    const { additionalFilters, getViewModel, view } = this.props

    const isDayView = view === 'day'
    const printModel = getViewModel(additionalFilters)
    const extendNotesMap = isDayView ? this._extendNotesMapWithStaffEvent : this._extendNotesMapWithStaffCells

    return printModel.reduce((memo, role) => {
      return role.get('shifts').reduce((memo, shift, shiftIndex) => {
        return shift.get('staff').reduce((memo, staff, staffIndex) => {
          const extensionData = { role, shift, staff }
          return extendNotesMap(memo, extensionData)
        }, memo)
      }, memo)
    }, Map())
  }

  _extendNotesMapWithStaffCells = (notesMap, extensionData) => {
    const { role, shift, staff } = extensionData

    return staff.get('cells').reduce(
      (memo1, cell, cellIndex) =>
        (cell.get('staffEvents')?.get(0)?.get('noteIds') || List()).reduce((memo2, noteId, noteIndex) => {
          const event = cell.get('staffEvents')?.get(0)
          const data = { role, shift, staff, cellIndex, noteIndex, event }
          return memo2.set(noteId, this._getNoteDetails(data))
        }, memo1),
      notesMap
    )
  }

  _extendNotesMapWithStaffEvent = (notesMap, extensionData) => {
    const { role, shift, staff } = extensionData
    const event = staff.get('event')
    const cellIndex = 0

    const noteIds = event.get('noteIds')
    return noteIds.reduce((memo, noteId, noteIndex) => {
      const data = { role, shift, staff, cellIndex, noteIndex, event }
      return memo.set(noteId, this._getNoteDetails(data))
    }, notesMap)
  }

  _getNoteDetails({ role, shift, staff, cellIndex, noteIndex, event }) {
    const staffEventId = event.get('id')
    const shiftId = shift.get('id')
    const isOtherStaff = shiftId === 'secondary-staff-shift'

    const shiftName = isOtherStaff ? 'Other Staff' : shift.get('name')
    const shiftTime = isOtherStaff ? '' : event?.get('shiftTime')

    const roleIndex = role.get('roleIndex')
    const shiftIndex = shift.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')
    const sortIndex = `${roleIndex}${shiftIndex}${staffIndex}${cellIndex}${noteIndex}`

    const firstName = staff.getIn(['profile', 'firstName'])
    const lastName = staff.getIn(['profile', 'lastName'])
    const staffFullName = `${firstName} ${lastName}`

    return Map({
      roleIndex,
      shiftIndex,
      staffIndex,
      cellIndex,
      noteIndex,
      sortIndex,
      shiftName,
      shiftTime,
      staffEventId,
      staffFullName
    })
  }
}
