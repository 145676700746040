import { Component } from 'react'
import { Modal } from 'Common/components'
import { navigate } from 'Navigation'
import { Map } from 'immutable'
import { pick } from 'lodash'
import { withAppContext } from 'AppContext'
import { Staff } from 'entityWrappers'
import { Redirect } from 'react-router'
import CustomDropdownEnum from 'components/Form/CustomDropdownEnum'
import './AcceptStaffTransferModal.scss'

const roleDefinition = {
  definition: {
    type: 'string',
    withLabel: true,
    label: 'ROLE'
  },
  field: 'groupId',
  formItemClasses: 'pr30'
}

const shiftDefinition = {
  definition: {
    type: 'string',
    withLabel: true,
    label: 'SHIFT'
  },
  field: 'shifType',
  formItemClasses: 'pr30'
}

class AcceptStaffTransferModal extends Component {
  constructor(props) {
    super(props)

    const { staffStore } = props
    staffStore.resetStaff()
    this.state = { eligibleUnit: Map(), isProcessing: false }
  }

  hideModal = () => {
    navigate.from.AcceptStaffTransfer.to.Staff()
  }

  componentDidMount() {
    const { match, staffStore } = this.props
    const { userId } = match.params
    staffStore.loadStaff(userId)
  }

  componentDidUpdate(prevProps) {
    const staff = this.props.appState.getIn(['staff', 'facilityUser'])
    const prevStaff = prevProps.appState.getIn(['staff', 'facilityUser'])
    const isStaffJustLoaded = staff.size > 0 && prevStaff.size === 0

    if (isStaffJustLoaded) {
      const { transferringUnit: eligibleUnit } = new Staff(staff)
      this.setState({ eligibleUnit })
    }
  }

  render() {
    const { appState } = this.props
    const { eligibleUnit, isProcessing } = this.state
    if (!eligibleUnit) {
      const path = navigate.from.AcceptStaffTransfer.to.Staff({}, false)
      return <Redirect to={path} />
    }

    const unit = appState.getIn(['generalData', 'unit'])
    const shifts = unit.get('shifts')
    const isUnitReady = unit.get('isReady')
    const staff = appState.getIn(['staff', 'facilityUser'])
    const isStaffLoaded = staff.size > 0

    if (!isUnitReady || !isStaffLoaded) {
      return null
    }

    const facilityProfile = staff.get('facilityProfile')

    const firstName = facilityProfile.get('firstName')
    const lastName = facilityProfile.get('lastName')
    const fullName = `${firstName} ${lastName}`
    const modalProps = pick(
      {
        ...this.props,
        width: 600,
        visible: true,
        onClose: this.hideModal,
        title: `Add assignment for ${fullName}`
      },
      ['title', 'width', 'visible', 'onClose', 'dismissOnOutsideClick', 'showClose']
    )

    const shiftsMap = shifts
      .groupBy((shift) => shift.get('unitRoleId'))
      .map((roleShifs) => roleShifs.groupBy((shift) => shift.get('typeId')))

    const homeUnitRoleId = eligibleUnit.get('homeUnitRoleId')
    const homeUnitShiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')
    const isConfirmDisabled = !homeUnitRoleId || !homeUnitShiftTypeId || isProcessing

    const roleShifts = shiftsMap.get(homeUnitRoleId)
    const shiftTypes = unit.get('shiftTypes').filter((shiftType) => {
      const shiftTypeId = shiftType.get('id')
      const shifts = roleShifts?.get(shiftTypeId)

      return shifts && shifts.size > 0
    })

    roleDefinition.definition.items = {
      enum: unit.get('roles')
    }
    shiftDefinition.definition.items = {
      enum: shiftTypes
    }

    return (
      <Modal {...modalProps} modalBoxClasses={'hx-modal-staff-list hx-accept-staff-transfer-modal'}>
        <div className="hx-staff-transfer-data-container row relative">
          <div className="hx-staff-info-data col-8 pl30">
            <i className="icon-indicator-dot" />
            <div className="row">
              <div className="col-6">
                <CustomDropdownEnum
                  {...roleDefinition}
                  propKey={'id'}
                  propValue={'name'}
                  onChange={(e) => this.setRoleId(e.currentTarget.value)}
                  value={homeUnitRoleId}
                />
              </div>
              <div className="col-6">
                <CustomDropdownEnum
                  {...shiftDefinition}
                  propKey={'id'}
                  propValue={'title'}
                  onChange={(e) => this.setShiftType(e.currentTarget.value)}
                  value={homeUnitShiftTypeId}
                />
              </div>
            </div>
          </div>
          <div className="col-4 call-to-action">
            <div className="row">
              <div className="col-6">
                <button className="button outline" onClick={this.hideModal}>
                  Cancel
                </button>
              </div>
              <div className="col-6">
                <button disabled={isConfirmDisabled} className="button primary" onClick={this.onAcceptStaffTransfer}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  onAcceptStaffTransfer = async () => {
    const { match, staffStore, reloadStaff } = this.props
    const { userId } = match.params
    const { eligibleUnit } = this.state

    const startsAt = eligibleUnit.get('startsAt')
    const homeUnitId = eligibleUnit.get('homeUnitId')
    const homeUnitRoleId = eligibleUnit.get('homeUnitRoleId')
    const homeUnitShiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')

    const eligibleUnitItem = {
      startsAt,
      homeUnitId,
      homeUnitRoleId,
      homeUnitShiftTypeId
    }

    this.setState({ isProcessing: true })

    await staffStore.acceptStaffTransfer(userId, eligibleUnitItem)
    await reloadStaff()

    this.setState({ isProcessing: false })
    this.hideModal()
  }

  setRoleId = (roleId) => {
    const eligibleUnit = this.state.eligibleUnit.set('homeUnitRoleId', roleId)
    this.setState({ eligibleUnit })
  }

  setShiftType = (shiftTypeId) => {
    const eligibleUnit = this.state.eligibleUnit.set('homeUnitShiftTypeId', shiftTypeId)
    this.setState({ eligibleUnit })
  }
}

export default withAppContext(AcceptStaffTransferModal)
