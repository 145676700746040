import { staffDataQuery } from '../Queries'

function StaffData() {
  let gqlClient = null
  let setStaffData = null

  const actions = { loadStaffData }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, setStaffData } = context)
  }

  async function loadStaffData() {
    const response = await gqlClient.query(staffDataQuery, { limit: 999 })
    const { staffPositions, groups, shifts, units } = response

    return setStaffData({ staffPositions, groups, shifts, units, isStaffDataLoaded: true })
  }
}

export default StaffData()
