import { Component } from 'react'
import { Modal } from 'Common/components'
import CreateEditExpertiseForm from './CreateEditExpertiseForm'
import { navigate } from 'Navigation'

export default class CreateEditExpertiseModal extends Component {
  render() {
    const {
      expertises,
      expertiseGroups,
      staffExpertiseCredentials,
      timeService,
      match,
      onSubmit,
      expertiseType,
      FormController,
      extenderLabel
    } = this.props

    const { expertiseId, userId } = match.params
    const modalTitle = expertiseId === 'new' ? `Add ${expertiseType}` : `Edit ${expertiseType}`

    const modalProps = {
      visible: true,
      onClose: this.hideModal,
      width: 588,
      title: modalTitle
    }

    const formProps = {
      onClose: this.hideModal,
      onSubmit,
      expertises,
      expertiseGroups,
      staffExpertiseCredentials,
      timeService,
      expertiseId,
      userId,
      extenderLabel,
      FormController
    }

    return (
      <Modal {...modalProps} modalBoxClasses="hx-modal-staff-list">
        <CreateEditExpertiseForm {...formProps} />
      </Modal>
    )
  }

  hideModal = () => {
    const { createPathKey, editPathKey, match } = this.props
    const { expertiseId } = match.params

    if (expertiseId !== 'new') {
      return navigate.from[editPathKey].to.StaffDetails()
    }

    return navigate.from[createPathKey].to.StaffDetails()
  }
}
