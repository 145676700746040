import { Redirect } from 'react-router'
import { PrivateRoute } from 'auth'
import { navigate, paths, TopNavigation } from 'Navigation'
import ManagerLayout from './ManagerLayout'
import { CalendarLayout } from './Calendar'
import { ShiftSwapsLayout } from './ShiftSwaps'
import { OpenShiftsLayout } from './OpenShifts'
import Dashboard from './Dashboard'
import { Activities } from './Activities'
import Staff from './Staff'
import { TimeOffLayout } from './TimeOff'
import { AnnouncementsLayout } from './Announcements'
import stores from 'stores'
import { isUsDate } from 'utils'
import ErrorBoundary from 'ErrorBoundary'
import typedErrorsHandlers from 'services/ErrorHandler/typedErrorsHandlers'
import EngagementCenter from './EngagementCenter/EngagementCenter'
import PatientSlotRecommender from './PatientSlotRecommender'

const CALENDAR = 'calendar'

const ManagerRoutes = (props) => {
  const { componentId, date } = props.match.params
  if (componentId === CALENDAR && !isUsDate(date)) {
    const { todayUsDate } = stores.timeService()
    const { to } = navigate.from.CommonManager
    const url = to.CalendarFullView({ date: todayUsDate }, false)
    return <Redirect to={url} />
  }

  return (
    <ManagerLayout {...props}>
      <TopNavigation />
      <ErrorBoundary onError={typedErrorsHandlers['ApplicationError']}>
        <PrivateRoute
          path={paths.Calendar}
          component={CalendarLayout}
          componentProps={{ calendarType: 'calendar' }}
          componentId="calendar"
        />
        <PrivateRoute path={paths.OpenShifts} component={OpenShiftsLayout} componentId="open-shifts" />
        <PrivateRoute path={paths.ShiftSwaps} component={ShiftSwapsLayout} componentId="shift-swaps" />
        <PrivateRoute path={paths.Dashboard} component={Dashboard} componentId="dashboard" />
        <PrivateRoute path={paths.TimeOff} component={TimeOffLayout} componentId="time-off" />
        <PrivateRoute path={paths.Activities} component={Activities} componentId="activities" />
        <PrivateRoute path={paths.Staff} component={Staff} componentId="staff" />
        <PrivateRoute path={paths.Announcements} component={AnnouncementsLayout} componentId="announcements" />
        <PrivateRoute path={paths.EngagementCenter} component={EngagementCenter} componentId="engagement-center" />
        <PrivateRoute
          path={paths.PatientSlotRecommender}
          component={PatientSlotRecommender}
          componentId="patient-slot-recommender"
        />
      </ErrorBoundary>
    </ManagerLayout>
  )
}

export default ManagerRoutes
