import { PureComponent, createRef } from 'react'
import ReactResizeDetector from 'react-resize-detector'
import updateStyle from './updateStyle'
import './PopUp.scss'
import { findParentElement } from 'utils'
import classNames from 'classnames'

export default class PopUp extends PureComponent {
  state = { style: { visibility: 'hidden' } }

  constructor(props) {
    super(props)
    this.popup = createRef()
    this.arrow = createRef()
    this.updateStyle = this.updateStyle.bind(this)
  }

  componentDidMount() {
    this.componentDidUpdate()
    this.setState({ style: {} })
  }

  componentDidUpdate() {
    this.updateStyle()
  }

  updateStyle = () => {
    const { viewPort, position, align, targetElement, scrollTop, page, container } = this.props
    const { popup, arrow } = this
    updateStyle(popup.current, arrow.current, viewPort, position, targetElement, scrollTop, align, page, container)
  }

  render() {
    const { dataAttributes, onMouseEnter, onMouseLeave, className } = this.props
    return (
      <div
        className={classNames('popup', { [className]: !!className })}
        ref={this.popup}
        style={this.state.style}
        onBlur={this.handleBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...dataAttributes}
      >
        <ReactResizeDetector handleWidth handleHeight onResize={this.updateStyle} />
        <div className="triangle" ref={this.arrow} />
        {this.props.children}
      </div>
    )
  }

  handleBlur = (event) => {
    const { onBlur } = this.props
    if (!onBlur) {
      return
    }

    const targetElement = event.relatedTarget || document.activeElement

    const isTargetElementInsidePopup = targetElement && !!findParentElement(targetElement, 'classList', 'popup')
    if (!isTargetElementInsidePopup) {
      onBlur()
    }
  }
}
