import { useState, useEffect } from 'react'
import { OrderedSet } from 'immutable'
import TimeOffCategory from './TimeOffCategory'
import RequestTimeOffDetailsCard from './RequestTimeOffDetailsCard'
import './RequestTimeOff.scss'
import { StaffTimeOffRequestDateInput, StaffTimeOffRequestNoteInput } from 'Staff/Calendar/utils'
import NotificationCard from './NotificationCard'
import Footer from './Footer'

export type RequestTimeOffProps = {
  date: string
  timeService?: any
  dataController: any,
  selectedTimeOffDate: any,
  setSelectedCellDate: any
}

export default function RequestTimeOff({ date, timeService, dataController, selectedTimeOffDate, setSelectedCellDate }: RequestTimeOffProps) {
  const [selectedDates, setSelectedDates] = useState<OrderedSet<StaffTimeOffRequestDateInput>>(OrderedSet())
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const [timeOffRequestStatus, setTimeOffRequestStatus] = useState('')
  const [isAnyPartialDay, setIsAnyPartialDay] = useState(false)
  const [isAnyPartialDayDurationSelected, setIsAnyPartialDayDurationSelected] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  useEffect(() => {
    if (selectedTimeOffDate) {
      const { timeOffDate } = selectedTimeOffDate
      const formattedDate = timeService.usDateToMoment(timeOffDate).toISOString();
      const isDateAlreadySelected = selectedDates.some((selectedDate) => selectedDate?.date === formattedDate)

      if (!isDateAlreadySelected) {
        setSelectedDates((prevSelectedDates) =>
          prevSelectedDates.add({ date: formattedDate, duration: 1440, isPartial: false, startTime: null })
        )
      }
    }
  }, [selectedTimeOffDate])

  const handleTimeOffDataChange = (requestTimeOff: StaffTimeOffRequestDateInput) => {
    const selectedDatesArray = selectedDates.toArray().sort((firstDate, secondDate) => firstDate.date.localeCompare(secondDate.date))

    const index = selectedDatesArray.findIndex((item) => item.date === requestTimeOff.date)
    if (index !== -1) {
      const updatedDatesArray = [...selectedDatesArray]
      updatedDatesArray[index] = requestTimeOff
      setSelectedDates(OrderedSet(updatedDatesArray))
    } else {
      setSelectedDates((prevSelectedDates) => prevSelectedDates.add(requestTimeOff))
    }
  }

  const handleDelete = (dateToDelete: string) => {
    setSelectedDates((prevSelectedDates) => OrderedSet(prevSelectedDates.filter((item) => item?.date !== dateToDelete)))
  }

  const handleClearButtonClick = () => {
    setSelectedDates(OrderedSet())
    setSelectedSubCategory('')
    setSelectedCategory('')
    setIsAnyPartialDay(false)
    setSelectedCellDate(null)
    setIsAnyPartialDayDurationSelected(false)
  }

  const handleTimeOffCategory = (category: string, subCategory: string) => {
    setSelectedCategory(category)
    setSelectedSubCategory(subCategory)
  }

  const handlePartialDayChange = (isPartial: boolean) => {
    setIsAnyPartialDay(isPartial)
  }

  const handlePartialDayDurationChange = (isDurationSelected: boolean) => {
    setIsAnyPartialDayDurationSelected(isDurationSelected)
  }

  const handleSubmission = async () => {
    const note: StaffTimeOffRequestNoteInput = {
      subject: selectedSubCategory
    }

    const monthStartsAt = timeService.dateTimeToMoment(date).startOf('month').toISOString()

    try {
      await dataController.createMyStaffTimeOffRequest(selectedDates, note, monthStartsAt)
      setTimeOffRequestStatus('Success')
      handleClearButtonClick()
      setShowNotification(true)
    } catch (error) {
      setTimeOffRequestStatus('Failure')
      setShowNotification(true)
    }
  }

  const handleNotificationClose = () => {
    setShowNotification(false)
  }

  const isSubcategoryEmpty = !selectedSubCategory
  const isSelectedCardsEmpty = selectedDates.size === 0

  return (
    <div className="pt05">
      <div className="time-off-notification-card">
        {showNotification && <NotificationCard timeOffRequestStatus={timeOffRequestStatus} onClose={handleNotificationClose} />}
      </div>
      <div>
        <TimeOffCategory
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          onCategoryChange={handleTimeOffCategory}
        />
      </div>
      <div className="request-time-off-section vertical-auto-scroll">
        {selectedDates.toArray().map((requestTimeOff, index) => (
          <RequestTimeOffDetailsCard
            key={requestTimeOff.date}
            date={requestTimeOff.date}
            timeService={timeService}
            onDelete={() => handleDelete(requestTimeOff.date)}
            onTimeOffDataChange={handleTimeOffDataChange}
            onPartialDayChange={handlePartialDayChange}
            onPartialDayDurationChange={handlePartialDayDurationChange}
            timeOffDetails={requestTimeOff}
          />
        ))}
      </div>
      <Footer
        isSubcategoryEmpty={isSubcategoryEmpty}
        isSelectedCardsEmpty={isSelectedCardsEmpty}
        isAnyPartialDay={isAnyPartialDay}
        isAnyPartialDayDurationSelected={isAnyPartialDayDurationSelected}
        handleSubmitButtonClick={handleSubmission}
        handleClearButtonClick={handleClearButtonClick}
      />
    </div>
  )
}