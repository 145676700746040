import { Component } from 'react'
import './ElementSelectionMenu.scss'
import TextIcon from '../../../styles/web_v3/text.svg'
import ImageIcon from '../../../styles/web_v3/image.svg'
import LinkIcon from '../../../styles/web_v3/link.svg'

export default class ElementSelectionMenu extends Component {
  render() {
    const { onDetailsComponentSelected } = this.props

    return (
      <div className="selection-menu-container">
        <ElementItem text="Text" name="text" icon={TextIcon} onClick={onDetailsComponentSelected} />
        <ElementItem text="Image" name="image" icon={ImageIcon} onClick={onDetailsComponentSelected} />
        <ElementItem text="Link" name="link" icon={LinkIcon} onClick={onDetailsComponentSelected} />
      </div>
    )
  }
}

const ElementItem = ({ name, text, icon, onClick }) => (
  <div name={name} className="element-item-container" onClick={onClick}>
    <div name={name} className="element-icon-container" onClick={onClick}>
      <img name={name} src={icon} alt={`${text} Icon`} onClick={onClick} />
    </div>
    <div name={name} className="element-type-container" onClick={onClick}>
      {text}
    </div>
  </div>
)
