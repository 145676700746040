import { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import Footer from 'Footer/Footer'
import { AnnouncementsLog } from './Log'
import { CreateAnnouncementsMaster } from './CreateAnnouncements'
import FormLoader from './FormLoader'
import { paths } from 'Navigation'
import './Layout.scss'

class Announcements extends Component {
  render() {
    return (
      <div className="h100">
        <div className="wrapper-container">
          <section ref={(e) => (this.sectionRef = e)}>
            <Switch>
              <Route exact path={paths.Announcements} component={AnnouncementsLog} />
              <Route
                exact
                path={paths.AnnouncementsCreate}
                render={(props) => <CreateAnnouncementsMaster {...props} editable={true} />}
              />
              <Route
                exact
                path={paths.AnnouncementsDetails}
                render={(props) => <FormLoader {...props} viewExisting={true} />}
              />
              <Route
                exact
                path={paths.AnnouncementsEdit}
                render={(props) => <FormLoader {...props} editExisting={true} />}
              />
            </Switch>
          </section>
        </div>
        <Footer className="announcements-footer" timeService={this.props.timeService} />
      </div>
    )
  }
}

export default withAppContext(Announcements)
