import { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import OpenShift from './OpenShift'
import OpenShifts from './OpenShifts'
import { paths } from 'Navigation'
import stores from 'stores'
import Footer from 'Footer'
import { fromJS } from 'immutable'
import { isEqual } from 'lodash'

const { openShiftsStore, expertiseStore } = stores
const filtersConfig = fromJS({
  status: ['needsApproval', 'requestPosted', 'filled', 'nobodyInvited'],
  shift: ['day', 'night'],
  roles: []
})

class Layout extends Component {
  state = { isLoading: false, config: filtersConfig }

  async componentDidMount() {
    document.body.style.setProperty('overflow-y', 'scroll', 'important')
    const { activeDateRange, appState } = this.props
    const isActiveDateRangeReady = activeDateRange.get('isReady')

    if (isActiveDateRangeReady) {
      this.setState({ isLoading: true })
      const config = this.mergeConfigWithRoles(appState.get('openShiftsState'), 'rawRoleKeys')
      this.setState({ config })
      openShiftsStore.setFilter(config)
      await this.loadOpenShifts()
      this.setState({ isLoading: false })
    }
  }

  async componentDidUpdate(prevProps) {
    const { activeDateRange, appState } = this.props
    const prevRawRoles = prevProps.appState.getIn(['openShiftsState', 'openShiftsMap', 'rawRoles']) || {}
    const rawRoles = appState.getIn(['openShiftsState', 'openShiftsMap', 'rawRoles']) || {}
    const isReady = activeDateRange.get('isReady')
    const isActiveDateRangeChanged = activeDateRange !== prevProps.activeDateRange
    const isComingBackToList = this.props.match.isExact && !prevProps.match.isExact
    const isRolesChanged = !isEqual(prevRawRoles, rawRoles)

    if (isRolesChanged) {
      this.onChangeFilters(fromJS({ roles: Object.keys(rawRoles) }))
    }

    if (isReady && (isComingBackToList || isActiveDateRangeChanged)) {
      this.setState({ isLoading: true })
      await this.loadOpenShifts()
      await this.loadExpertises()
      this.setState({ isLoading: false })
    }
  }

  onChangeFilters = (filters) => {
    let { config } = this.state
    config = config.merge(filters)
    openShiftsStore.setFilter(config)
    this.setState({ config })
  }

  loadOpenShifts() {
    const { activeDateRange, appState, timeService } = this.props
    const today = timeService.timeMoment(null).format('MM-DD-YYYY')
    const startDate = timeService.usDateToMoment(today).toISOString()
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')
    const unit = appState.getIn(['generalData', 'unit'])

    return openShiftsStore.loadOpenShifts({
      unit,
      startDate,
      facilityUsersMap
    })
  }

  loadExpertises() {
    return expertiseStore.loadExpertises()
  }

  componentWillUnmount() {
    document.body.style.setProperty('overflow-y', 'scroll', 'important')
  }

  render() {
    return (
      <div className="h100">
        <div className="wrapper-container">
          <div className="hx-main-navigation" />
          <section>
            <Switch>
              <Route path={paths.ShiftOpportunity} render={this.renderOpenShift} />
              <Route path={paths.OpenShift} render={this.renderOpenShift} />
              <Route path={paths.OpenShifts} render={this.renderOpenShifts} />
            </Switch>
          </section>
        </div>
        <Footer timeService={this.props.timeService} />
      </div>
    )
  }

  renderOpenShifts = () => {
    const { activeDateRange, timeService } = this.props
    const { isGeneralDataLoading, appState } = this.props
    const { isLoading: isOpenShiftsLoading } = this.state
    const isLoading = isOpenShiftsLoading || isGeneralDataLoading

    if (isLoading) {
      return null
    }

    const openShiftsState = appState.get('openShiftsState')
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')
    const config = this.mergeConfigWithRoles(openShiftsState)
    const openShiftsProps = {
      isLoading,
      openShiftsState,
      facilityUsersMap,
      timeService,
      config,
      onChangeFilters: this.onChangeFilters
    }

    return <OpenShifts {...openShiftsProps} />
  }

  mergeConfigWithRoles(openShiftsState, key = 'roleKeys') {
    const filtersConfig = this.state.config
    const roles = openShiftsState.getIn(['openShiftsMap', key])
    return filtersConfig.merge({ roles })
  }

  renderOpenShift = (props) => {
    const { shiftDayId, id } = props.match.params
    const { appState, unit, openShiftsStore, isGeneralDataLoading, timeService, activeDateRange, shiftStartsAt } =
      this.props
    const unitId = unit.get('id')

    const openShift = appState.getIn(['openShiftsState', 'openShift'])
    const openShiftStaffMap = appState.getIn(['openShiftsState', 'openShiftStaffMap'])
    const openShiftParameters = appState.getIn(['openShiftsState', 'openShiftParameters'])
    const staffFilter = appState.getIn(['openShiftsState', 'openShiftStaffFilter'])
    const staffConfig = appState.getIn(['openShiftsState', 'openShiftStaffConfig'])
    const eligibleUnits = appState.getIn(['generalData', 'eligibleUnits'])
    const expertises = appState.get('expertises')

    if (!unitId) {
      return null
    }

    const openShiftProps = {
      id,
      unit,
      openShift,
      openShiftsStore,
      eligibleUnits,
      shiftDayId,
      staffFilter,
      staffConfig,
      openShiftStaffMap,
      openShiftParameters,
      isGeneralDataLoading,
      timeService,
      activeDateRange,
      shiftStartsAt,
      expertises
    }

    return <OpenShift {...openShiftProps} />
  }
}

export default withAppContext(Layout)
