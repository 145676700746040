import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [
  Field('userIds', 'implemented for direct staff', {
    format: 'userIdsBool'
  }),
  Field('employmentType', 'employment type'),
  Field('position', 'position'),
  Field('ruleType', 'rule'),
  Field('value', 'value')
]

function StaffComplianceStore() {
  return AdminStore({
    fields,
    entityName: 'StaffComplianceRule',
    api: {
      index: 'indexStaffComplianceRules'
    },
    header: { title: 'Compliance Rules', createLabel: 'Create Rule' }
  })
}

export default StaffComplianceStore
