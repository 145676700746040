import { PureComponent } from 'react'
import classNames from 'classnames'
import Notes from '../Notes'
import NotesController from './TimeOffNotesController'
import TimeOffController from './TimeOffController'
import { CustomTimeRangeInput } from 'components/Form/CustomTimeRangeInput'
import { getStaffEventIndex, hasTimeOff } from '../cellDetailsUtils'

export default class TimeOffForm extends PureComponent {
  constructor(props) {
    super(props)

    this.timeOffController = new TimeOffController(this)
    this.notesController = new NotesController(this)

    const timeOffState = this.timeOffController.getDefaultState()
    const { defaultState: notesState } = this.notesController

    this.state = { ...timeOffState, ...notesState }
  }

  componentDidMount() {
    this.notesController.loadNotes()
    this.timeOffController.loadCellStaffEvents(this.props)
  }

  componentDidUpdate(prevProps) {
    const { cell } = this.props
    const { cell: prevCell } = prevProps

    const isCellChanged = cell !== prevCell
    if (isCellChanged) {
      this.notesController.loadNotes()
      this.timeOffController.updateTimeOff()
      this.timeOffController.loadCellStaffEvents()
    }
  }

  render() {
    const { documentType, isNotesLoading } = this.state
    const { onCancel, isTimeOffFormShown, isProcessing, cell } = this.props
    const { isNoteChanged } = this.notesController
    const { isTimeOffChanged } = this.timeOffController
    const isLoading = isNotesLoading

    const isStartTimeOrEndTimeChanged =
      this.state.initialStartsAt !== this.state.startsAt || this.state.initialEndsAt !== this.state.endsAt
    const isDataChanged = isTimeOffChanged || isNoteChanged || isStartTimeOrEndTimeChanged
    const isTypeSelected = documentType !== 'empty'

    const isUpdateDisabled = isLoading || isProcessing || !isDataChanged
    const isCreateDisabled = isLoading || isProcessing || !isTypeSelected
    const isDeleteDisabled = isLoading || isProcessing

    const className = classNames('add-timeoff pt16 bt1', {
      hide: !isTimeOffFormShown
    })

    return (
      <div className={className}>
        {!isLoading && this.renderForm()}
        {isLoading && this.renderSpinner()}

        <footer className="p20">
          <button className="button compact secondary borderless ph0" onClick={onCancel}>
            Cancel
          </button>

          {hasTimeOff(cell) && (
            <>
              <button
                className="button float-right compact bg-skyblue _update-button"
                onClick={this.update}
                disabled={isUpdateDisabled}
              >
                Update
              </button>

              <span className="float-right">&nbsp;</span>

              <button
                className="button float-right compact primary _delete-button"
                onClick={this.delete}
                disabled={isDeleteDisabled}
              >
                Remove
              </button>
            </>
          )}

          {!hasTimeOff(cell) && (
            <button
              className="button float-right compact bg-skyblue _create-button"
              onClick={this.create}
              disabled={isCreateDisabled}
            >
              Add
            </button>
          )}
        </footer>
      </div>
    )
  }

  renderSpinner() {
    return (
      <div className="loader">
        <i className="loading spindle" />
      </div>
    )
  }

  renderForm() {
    const { notesController, timeOffController } = this
    const { documentType, startsAt, endsAt } = this.state
    const { notes } = notesController
    const { day, timeService, timeRangeInputStep } = this.props

    const ptoClassName = classNames('button button-tab-left compact secondary', {
      'filled bg-dodgerblue _active': documentType === 'StaffPaidTimeOff'
    })

    const roClassName = classNames('button button-tab-right compact secondary', {
      'filled _active': documentType === 'StaffUnavailability'
    })

    const limits = timeOffController.getTimeOffPeriodLimits()
    const timeRangeProps = {
      ...limits,
      startsAt,
      endsAt,
      step: timeRangeInputStep,
      date: day.get('dateTime'),
      timeService,
      fromLabel: 'FROM',
      toLabel: 'TO',
      durationLabel: 'DURATION',
      onChangeStartsAt: (startsAt) => {
        this.setState({ startsAt })
      },
      onChangeEndsAt: (endsAt) => {
        this.setState({ endsAt })
      },
      readOnly: true
    }

    const notesProps = { notes, notesController }

    return (
      <>
        <div className="text-center smaller regent-gray">
          <div className="updated-at" />
        </div>
        <div className="row p20 pb0 pt0 bb1">
          <div className="col col-4 form-item text-right pr10 row align-middle">
            <label>TYPE</label>
          </div>
          <div className="col col-8 form-item">
            <button onClick={(e) => this.setDocumentType('StaffPaidTimeOff')} className={ptoClassName}>
              PTO
            </button>
            <button onClick={(e) => this.setDocumentType('StaffUnavailability')} className={roClassName}>
              RO
            </button>
          </div>
        </div>
        {documentType !== 'empty' && (
          <div className="row p20 pb0">
            <div className="form-item col-12 hx-time-off">
              <CustomTimeRangeInput {...timeRangeProps} />
            </div>
          </div>
        )}
        <Notes {...notesProps} />
      </>
    )
  }

  setDocumentType = (documentType) => {
    this.setState({ documentType })
    this.timeOffController.loadCellStaffEvents(this.props)
  }

  create = async () => {
    const { addTimeOffs, onCancel } = this.props
    const { eventVariant, startsAt, duration } = this.timeOffController
    const { note } = this.notesController

    addTimeOffs(eventVariant, note, startsAt, duration, true)

    onCancel()
  }

  update = async () => {
    const { changeTimeOffs, /*updateTimeOffNotes,*/ onCancel } = this.props
    const { eventVariant /*, isTimeOffChanged */, startsAt, duration } = this.timeOffController
    const { note /*, isNoteChanged*/ } = this.notesController

    changeTimeOffs(eventVariant, note, startsAt, duration, true)
    // if (isTimeOffChanged) {
    //   changeTimeOffs(documentType, note);
    // } else if (isNoteChanged) {
    //   updateTimeOffNotes(note);
    // }

    onCancel()
  }

  delete = async () => {
    const { deleteSelectedStaffEvents, onCancel, cell } = this.props
    const { selectedEventTemplateId } = this.state
    const staffEventIndex = getStaffEventIndex(cell, selectedEventTemplateId)
    const staffEventId = cell.get('staffEvents')?.get(staffEventIndex).get('id')

    deleteSelectedStaffEvents(staffEventId)

    onCancel()
  }
}

TimeOffForm.defaultProps = { timeRangeInputStep: 15 }
