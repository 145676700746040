import { Map } from 'immutable'
import classNames from 'classnames'
import { PureComponent } from 'react'
import CustomDropDown from '@humanics/he-react-common/lib/components/Form/CustomDropdown'
import './DropdownSelector.scss'

export default class DropdownSelector extends PureComponent {
  constructor(props) {
    super(props)
    const { options = {}, values = Map() } = props
    this.state = { isActive: false, isHovered: false, options, values }
  }

  render() {
    const { name, selectOptions, values } = this.props
    const { isActive } = this.state

    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    return (
      <aside className="hx-filters" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <div className="option-value">
            <sh-text size="title-1">{name}</sh-text>
            <sh-text size="title-1">
              <i className={arrowClassName} />
            </sh-text>
          </div>
        </button>
        <div className="custom-dropdown-selector p20 bg-white filters-content">
          <ul>
            {selectOptions.map((definition) => {
              const { label, field } = definition
              const value = values.get(field)

              return (
                <li className="row align-middle" key={field}>
                  <label className="col-4 text-right pr10 row h100 align-middle">{label}</label>
                  <span className="col-8">
                    <CustomDropDown
                      value={value}
                      definition={definition}
                      onChange={(e) => this.updateConfig(e.currentTarget.value, field)}
                      propKey={'key'}
                      propValue={'name'}
                      onMouseEnter={this.onSelectMouseEnter}
                      onMouseLeave={this.onSelectMouseLeave}
                    />
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    )
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => ({
      isActive: !prevState.isActive
    }))
  }

  updateConfig = (value, field) => {
    const { values, setConfig, required } = this.props

    if (required && !value) {
      return
    }

    const newValue = values.set(field, value)
    setConfig(newValue)
  }

  hideDropdown = (e) => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    const { isSelectHovered } = this.state

    if (isSelectHovered) {
      return
    }

    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 300)
  }

  onSelectMouseEnter = () => {
    this.setState({ isSelectHovered: true })
  }

  onSelectMouseLeave = () => {
    this.setState({ isSelectHovered: false })
  }
}
