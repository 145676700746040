import { createRef, PureComponent } from 'react'
import classNames from 'classnames'

export default class Note extends PureComponent {
  constructor(props) {
    super(props)
    this.ref = createRef()
  }

  componentDidMount() {
    if (this.ref.current) {
      const { index } = this.props
      const width = this.ref.current.offsetWidth
      const left = this.ref.current.offsetLeft
      const right = left + width

      this.props.applyChildDimensions(index, { left, right })
    }
  }

  render() {
    const { note, withDivider } = this.props

    const shiftName = note.get('shiftName')
    const shiftTime = note.get('shiftTime')
    const staffFullName = note.get('staffFullName')
    const subject = note.get('subject')
    const text = note.get('text')
    const updatedAt = this._getNoteUpdatedAt(note)
    const updatedBy = this._getNoteUpdatedBy(note)
    const lastUpdate = updatedAt && updatedBy ? `${updatedAt} by ${updatedBy}` : ''
    const iconClassName = this._getSubjectIconClassName(subject)
    const isIconVisible = iconClassName && iconClassName.length > 0
    const className = classNames('day-note', { 'with-divider': withDivider })

    return (
      <article className={className} ref={this.ref}>
        <div className="day-note-shift-name upper">{shiftName}</div>
        <div className="day-note-shift-time">{shiftTime}</div>
        <div className="day-note-staff-name">{staffFullName}</div>
        <div className="day-note-subject">
          {isIconVisible && <i className={iconClassName} />}
          {subject}
        </div>
        <div className="day-note-explanation">{text}</div>
        <div className="day-note-last-update">{lastUpdate}</div>
      </article>
    )
  }

  _getNoteUpdatedAt(note) {
    const updatedAt = note.get('updatedAt')

    if (!updatedAt) {
      return ''
    }

    const { timeService } = this.props

    const today = timeService.timeMoment(null)
    const isCurrentYear = timeService.isSame(today, updatedAt, 'year')
    const dateFormat = isCurrentYear ? 'MMM D' : 'YYYY MMM D'

    return timeService.timeMoment(updatedAt).format(dateFormat)
  }

  _getNoteUpdatedBy(note) {
    const author = note.get('author')

    if (!author) {
      return ''
    }

    const [firstName, lastName] = author.split(' ')

    return `${firstName[0]}. ${lastName}`
  }

  _getSubjectIconClassName(subject) {
    switch (subject) {
      case 'Call Off':
        return 'icon-indicator-call-back'
      case 'On Call':
        return 'icon-indicator-call'
      case 'Shift Swap':
        return 'icon-indicator-swap'
      case 'Class':
        return ''
      default:
        return null
    }
  }
}
