import { Component } from 'react'
import { DefaultAdmin } from '@humanics/he-react-common/lib/admin/components'
import { Modal } from 'Common/components'
import customFields from './customFields'
import { Link, withRouter } from 'react-router-dom'

class Services extends Component {
  constructor(props) {
    super(props)
    this.state = {
      service: null
    }
  }

  render() {
    const { stores, ...props } = this.props
    const { service } = this.state
    const visible = !!service
    const serviceJSON = JSON.stringify(service || {}, null, 4)
    const onRowClick = (e, id, dataRow) => {
      e.preventDefault()
      this.setState({ service: dataRow })
    }

    return (
      <div>
        <Modal visible={visible} onClose={(e) => this.setState({ service: null })} title={service?.name || ''}>
          <textarea rows="20" defaultValue={serviceJSON} />
        </Modal>
        <DefaultAdmin
          {...props}
          store={stores.get('service')}
          customFields={customFields}
          onRowClick={onRowClick}
          Link={Link}
          withRouter={withRouter}
        />
      </div>
    )
  }
}

export default Services
