import { Map } from 'immutable'

export default class CalendarSelection {
  constructor(selection, calendar) {
    this.selection = selection
    this.calendar = calendar
  }

  get selectedCells() {
    return this.selection.get('cells')
  }

  get staffPath() {
    return this.selection.get('staffPath')
  }

  get shift() {
    if (!this._shift) {
      this._shift = this.calendar.getShift(this.shiftPath)
    }

    return this._shift
  }

  get staff() {
    if (!this._staff) {
      this._staff = this.calendar.getStaff(this.staffPath)
    }

    return this._staff
  }

  get shiftPath() {
    if (!this._shiftPath) {
      const { roleIndex, shiftIndex } = this.staffPath
      this._shiftPath = { roleIndex, shiftIndex }
    }

    return this._shiftPath
  }

  get cells() {
    if (!this._cells) {
      this._cells = this.selectedCells.map((identityHash, cellIndex) =>
        this.calendar.getStaffCell({ ...this.staffPath, cellIndex })
      )
    }

    return this._cells
  }

  get cellsPaths() {
    if (!this._cellsPaths) {
      this._cellsPaths = this.selectedCells.reduce(
        (memo, identityHash, cellIndex) => memo.concat({ ...this.staffPath, cellIndex }),
        []
      )
    }

    return this._cellsPaths
  }

  get shiftDays() {
    if (!this._shiftDays) {
      this._shiftDays = this.selectedCells.map((identityHash, shiftDayIndex) =>
        this.calendar.getShiftDay({ ...this.staffPath, shiftDayIndex })
      )
    }

    return this._shiftDays
  }

  get shiftDaysPaths() {
    if (!this._shiftDaysPaths) {
      const { roleIndex, shiftIndex } = this.staffPath
      const shiftPath = { roleIndex, shiftIndex }

      this._shiftDaysPaths = this.selectedCells.reduce(
        (memo, identityHash, shiftDayIndex) => memo.concat({ ...shiftPath, shiftDayIndex }),
        []
      )
    }

    return this._shiftDaysPaths
  }

  get staffEventShiftDays() {
    if (!this._staffEventShiftDays) {
      this._staffEventShiftDays = this.staffEventShiftDaysPaths.map((staffEventShiftDayPath) => {
        if (!staffEventShiftDayPath) {
          return null
        }
        const shiftDay = this.calendar.getShiftDay(staffEventShiftDayPath)
        return shiftDay.merge({ path: staffEventShiftDayPath })
      })
    }

    return this._staffEventShiftDays
  }

  get staffEventShiftDaysPaths() {
    if (!this._staffEventShiftDaysPaths) {
      this._staffEventShiftDaysPaths = this.cells
        .map((cell, shiftDayIndex) => {
          const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
          const staffEventShiftId = cellStaffEvent.get('shiftId')
          const eventShift = this.calendar.findShiftById(staffEventShiftId)
          if (!eventShift) {
            return null
          }

          const roleIndex = eventShift.get('roleIndex')
          const shiftIndex = eventShift.get('shiftIndex')

          return { roleIndex, shiftIndex, shiftDayIndex }
        })
        .filter((staffEventShiftDayPath) => !!staffEventShiftDayPath)
    }

    return this._staffEventShiftDaysPaths
  }

  get staffEventShiftId() {
    const cellStaffEvent = this.cells.first().get('staffEvents')?.get(0) || Map()
    return cellStaffEvent.get('shiftId')
  }

  get cellIndexes() {
    if (!this._cellIndexes) {
      this._cellIndexes = this.selectedCells.reduce((memo, identityHash, cellIndex) => memo.concat(cellIndex), [])
    }

    return this._cellIndexes
  }

  get days() {
    if (!this._days) {
      const { days } = this.calendar
      this._days = this.selectedCells.map((identityHash, dayIndex) => days.get(dayIndex))
    }

    return this._days
  }

  get identityHash() {
    return CalendarSelection.buildSelectionIdentityHash(this)
  }

  get hasSelectionError() {
    return !!this.selectionErrorPath
  }

  get selectionErrorPath() {
    return this.selection.get('cellErrorPath')
  }

  static buildSelectionIdentityHash(selection) {
    const { cellsPaths } = selection
    return cellsPaths.reduce((memo, cellPath) => {
      const { roleIndex, shiftIndex, staffIndex, cellIndex } = cellPath
      if (memo.length === 0) {
        return `roleIndex:${roleIndex},shiftIndex:${shiftIndex},staffIndex:${staffIndex}|cellIndexes:[${cellIndex}]`
      }

      return `${memo.split(']')[0]},${cellIndex}]`
    }, '')
  }
}
