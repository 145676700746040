import { PureComponent } from 'react'
import Week from './Week'
import { times, chunk } from 'lodash'
import './Role.scss'

export default class Role extends PureComponent {
  render() {
    const { role, style } = this.props
    const roleName = role.get('name')
    const days = role.get('days')

    const weeks = chunk(days, 7)
    const weeksCount = weeks.length
    const width = `${100 / weeksCount}%`

    return (
      <div className="hx-group" style={style}>
        <div className="hx-calendar-group bg-cadetblue">
          <header className="row">
            <aside className="col-2">
              <button className="_expander white">
                <span className="upper smaller bold">{roleName}</span>
              </button>
            </aside>
            <section className="_full col-10">
              <div className="hx-calendar-group-days row">
                {times(weeksCount, (i) => (
                  <Week key={i} width={width} />
                ))}
              </div>
            </section>
          </header>
        </div>
      </div>
    )
  }
}
