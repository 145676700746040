import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import { t } from 'i18n'

const config = {
  confirmation: {
    message: t('announcements.confirmations.delete_details.disclosure'),
    options: {
      title: t('announcements.confirmations.delete_details.title'),
      accept: t('announcements.confirmations.delete_details.options.accept'),
      cancel: t('announcements.confirmations.delete_details.options.cancel'),
      type: 'warning'
    }
  }
}

export class DeleteDetailsPageButton extends PureComponent {
  state = {
    toLogsPage: false
  }

  render() {
    const { Dialog } = this.props
    const { onDeleteDetailsPage } = this.props
    const { onMouseEnter, onMouseLeave } = this.props
    const { confirmation } = config

    const onClick = async (e) => {
      const response = await Dialog.confirm(confirmation.message, confirmation.options)
      if (response) {
        onDeleteDetailsPage()
      }
    }

    return (
      <>
        <button
          className="delete-details-page-button bg-gray icon-minus"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </>
    )
  }
}

export default withAppContext(DeleteDetailsPageButton)
