import { Component, Children, cloneElement } from 'react'
import { curry } from 'i18n'
import { ControlledMultiSelectFilter } from 'Manager/components/MultiSelectFilter'
import './OpenShiftsHeader.scss'
import { Map } from 'immutable'
import DateSelectFilter from '../components/DateSelectFilter'

const t = curry('openShifts.header.')

export default function OpenShiftsHeader({ onChangeFilters, config, roles }) {
  const onChange = (name) => (values) => onChangeFilters(Map({ [name]: values }))
  return (
    <header className="hx-open-shifts-header row">
      <div className="hx-open-shifts-header-module col-5">
        <span className="hx-status-filter">
          <Filter onChange={onChange} config={config}>
            <StatusFilter />
          </Filter>
        </span>
        <Filter onChange={onChange} config={config}>
          <ShiftFilter />
        </Filter>
        <Filter onChange={onChange} config={config} roles={roles}>
          <RolesFilter />
        </Filter>
        <Filter onChange={onChange} config={config} keepOpen>
          <DateFilter />
        </Filter>
      </div>
      <div className="hx-open-shifts-header-module title center col-2">{t('title')}</div>
    </header>
  )
}

class Filter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false
    }
  }

  onMouseLeave = (e) => {
    const { keepOpen } = this.props
    if (this.state.isActive && !keepOpen) {
      this.toggle()
    }
  }

  toggle = (e) => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render() {
    const { children, ...rest } = this.props
    const childProps = {
      ...rest,
      isActive: this.state.isActive,
      onClick: this.toggle
    }
    return (
      <span onMouseLeave={this.onMouseLeave}>
        {Children.map(children, (child) => cloneElement(child, { ...childProps }))}
      </span>
    )
  }
}

function StatusFilter({ isActive, onChange, config, onClick }) {
  return (
    <ControlledMultiSelectFilter
      options={Map({
        needsApproval: 'NEEDS APPROVAL',
        requestPosted: 'REQUEST POSTED',
        filled: 'FILLED',
        nobodyInvited: 'NOBODY INVITED YET'
      })}
      deselectAllAllowed={false}
      values={config.get('status')}
      setConfig={onChange('status')}
      name="Status"
      field="status"
      isActive={isActive}
      onClick={onClick}
    />
  )
}

function ShiftFilter({ isActive, onChange, config, onClick }) {
  return (
    <ControlledMultiSelectFilter
      options={Map({ day: 'Day', night: 'Night' })}
      deselectAllAllowed={false}
      values={config.get('shift')}
      setConfig={onChange('shift')}
      name="Shift"
      field="shift"
      isActive={isActive}
      onClick={onClick}
    />
  )
}

function RolesFilter({ isActive, onChange, config, roles, onClick }) {
  return (
    <ControlledMultiSelectFilter
      options={Map(roles)}
      deselectAllAllowed={false}
      values={config.get('roles')}
      setConfig={onChange('roles')}
      name="Role"
      field="role"
      isActive={isActive}
      onClick={onClick}
    />
  )
}
function DateFilter({ isActive, onChange, config, onClick }) {
  return (
    <DateSelectFilter
      deselectAllAllowed={false}
      values={config.get('dateRange')}
      setConfig={onChange('dateRange')}
      name="Creation date"
      field="dateRange"
      isActive={isActive}
      onClick={onClick}
    />
  )
}
