export const enum ScheduleStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export const enum ConstraintType {
  SOFT = 'soft',
  HARD = 'hard',
  DISABLED = 'disabled',
  ALL = 'all'
}

export const enum ConstraintWeight {
  HARD = 5,
  SOFT = 1,
  DISABLED = 0
}

export const enum ConstraintTableHeaders {
  PARAMETERS = 'Parameters',
  WEIGHTAGE = 'Weightage'
}
