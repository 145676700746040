import { Component } from 'react'

export default class WarningHint extends Component {
  render() {
    const { parentRef, visible, customStyle = {} } = this.props
    if (!parentRef?.current || !visible) {
      return null
    }

    const height = parentRef.current.offsetHeight
    const position = {
      top: height + 25
    }

    return (
      <div className="align-center align-middle p5 _warning bg-midgray" style={{ ...position, ...customStyle }}>
        <div className="col-12 white">{this.props.text}</div>
      </div>
    )
  }
}
