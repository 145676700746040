import { Map, OrderedMap } from 'immutable'
import StateController from './StateController'
import { getAppropriateStore } from '../layoutUtils'

const defaultState = Map({
  staffPath: {},
  cells: OrderedMap()
})

let store
export default class MultiselectController extends StateController {
  get defaultState() {
    return { selection: defaultState }
  }

  get selection() {
    return this.state.selection
  }

  get cells() {
    return this.selection.get('cells')
  }

  get staffPath() {
    return this.selection.get('staffPath')
  }

  get cellErrorPath() {
    return this.state.selection.get('cellErrorPath')
  }

  get selectedIndexes() {
    const { cells: selectedCells } = this
    return selectedCells.keySeq().reduce((memo, index) => memo.concat(Number(index)), [])
  }

  get cellsPathsInProcessing() {
    return this.selection.get('cellsPathsInProcessing')
  }

  cancelMultiselect() {
    this._resetSelection()
  }

  selectSingleCell(staffPath, cellIndex, details, callback) {
    const cells = defaultState.get('cells').set(cellIndex, details)
    this._setSelection({ staffPath, cells }, callback, true)
  }

  selectMultipleCells(staffPath, cells) {
    this._setSelection({ staffPath, cells }, null, true)
  }

  _setSelection(selection, callback, resetCellError) {
    selection = resetCellError ? { ...selection, cellErrorPath: null } : selection
    const newSelection = this.selection.merge(Map(selection))
    this.setState({ selection: newSelection }, callback)
  }

  _selectCell(cellIndex, details) {
    const cells = this.cells.set(cellIndex, details)
    this._setSelection({ cells }, null, true)
  }

  _unselectCell(cellIndex) {
    const cells = this.cells.remove(cellIndex)
    this._setSelection({ cells }, null, true)
  }

  _resetSelection() {
    this.setState({ selection: defaultState })
  }

  _isSameRow(staffPath, selectedStaffPath) {
    const { staffIndex, shiftIndex, roleIndex } = staffPath
    const {
      staffIndex: selectedStaffIndex,
      shiftIndex: selectedShiftIndex,
      roleIndex: selectedRoleIndex
    } = selectedStaffPath

    const isSameStaff = staffIndex === selectedStaffIndex
    const isSameShift = shiftIndex === selectedShiftIndex
    const isSameRole = roleIndex === selectedRoleIndex
    return isSameRole && isSameShift && isSameStaff
  }

  cellSelection = (selectedCells, cellIndex, staffPath, identityHash, eventMeta) => {
    const { isMetaKeyPressed, isCtrlKeyPressed } = eventMeta
    const isSingleCellSelected = selectedCells.size === 1
    if (isSingleCellSelected) {
      return this._resetSelection()
    }

    if (isMetaKeyPressed || isCtrlKeyPressed) {
      return this._unselectCell(cellIndex)
    }

    return this.selectSingleCell(staffPath, cellIndex, { identityHash })
  }

  handleClick = (staffPath, cellIndex, meta) => {
    const { eventMeta, identityHash = 'empty' } = meta
    const { isShiftKeyPressed, isMetaKeyPressed, isCtrlKeyPressed } = eventMeta
    const { cells: selectedCells, staffPath: selectedStaffPath } = this

    const isSelected = selectedCells.size && selectedCells.size > 0
    const hasCellError = this._hasCellError()

    if (hasCellError) {
      this._clearCellError()
    }
    // nothing selected
    if (!isSelected) {
      // add this cell as a first selected
      return this.selectSingleCell(staffPath, cellIndex, { identityHash })
    }

    if (!this._isSameRow(staffPath, selectedStaffPath)) {
      this._resetSelection()
      return this.selectSingleCell(staffPath, cellIndex, { identityHash })
    }

    // cell from current row selected
    // current cell selected
    const isCellSelected = selectedCells.get(cellIndex)
    if (isCellSelected) {
      return this.cellSelection(selectedCells, cellIndex, staffPath, identityHash, eventMeta)
    }

    // TODO warn about different identity on hover
    const selectedIdentityHash = selectedCells.first()
    const isSameIdentityHash = identityHash === selectedIdentityHash?.identityHash

    if (!isSameIdentityHash) {
      const isSingleCellSelected = this.cells.size === 1
      const isMultiselectKeyPressed = isMetaKeyPressed || isShiftKeyPressed

      if (isSingleCellSelected || !isMultiselectKeyPressed) {
        return this.selectSingleCell(staffPath, cellIndex, { identityHash })
      }

      if (isMultiselectKeyPressed) {
        this._setSelectionError({ staffPath, cellIndex })
      }

      return
    }

    if (isShiftKeyPressed) {
      return this._selectIdenticalCellsToIndex(staffPath, cellIndex, identityHash, eventMeta)
    }

    if (isMetaKeyPressed || isCtrlKeyPressed) {
      return this._selectCell(cellIndex, { identityHash })
    }

    return this.selectSingleCell(staffPath, cellIndex, { identityHash })
  }

  _clearCellError() {
    const cellPath = this.cellErrorPath
    const store = getAppropriateStore(this.props.calendarType)
    if (cellPath && store) {
      const cell = this.calendar.getStaffCell(cellPath)
      const cleanCell = cell.merge({ isError: false })
      const storeName = `${this.props.calendarType}Store`
      this.calendar[storeName].updateCalendarCell(cellPath, cleanCell)
      this._setSelection({ cellErrorPath: null })
    }
  }

  _setSelectionError = ({ staffPath, cellIndex }) => {
    const { selection } = this.state
    const { calendarStore } = this.calendar
    const cellPath = { ...staffPath, cellIndex }
    const cellSize = (selection.get('cells') || Map()).size
    if (cellSize > 0) {
      this._setErrorCell(cellPath, calendarStore)
    }
  }

  _setErrorCell = (cellPath, calendarStore) => {
    const cell = this.calendar.getStaffCell(cellPath)
    const cellWithError = cell.merge({ isError: true })
    calendarStore.updateCalendarCell(cellPath, cellWithError)
    this._setSelection({ cellErrorPath: cellPath })
  }

  _getIdenticalCellsIndexes(staffPath) {
    const { calendar, calendarType } = this.props
    store = getAppropriateStore(calendarType)
    const staffPathInCalendar = store.getPath(staffPath)
    const staffCells = calendar.getIn([...staffPathInCalendar, 'cells'])

    const cellIndex = this.cells.findKey(() => true)
    const firstCell = this.calendar.getStaffCell({ ...staffPath, cellIndex })
    const firstCellStaffEvent = firstCell.get('staffEvents')?.get(0) || Map()
    const selectedIdentityHash = firstCellStaffEvent.get('identityHash') || 'empty'

    const identicalCells = staffCells
      .map((cell, index) => cell.set('index', index))
      .filter((cell) => (cell.get('staffEvents')?.get(0)?.get('identityHash') || 'empty') === selectedIdentityHash)
      .map((cell) => cell.get('index'))

    return identicalCells || []
  }

  _getIdenticalCellsToIndex(staffPath, index, identityHash) {
    // TODO: probably this is wrong as we always check for cells betwen minimal and maximal indexes
    //       which prevents us from selections of cells between already selected cells sequences.
    //       Probably a correct solution will be to check for cells between last selected index and a new one,
    //       if we want to have a most common approach for multiselection:
    const indexes = this._sortIndexes([...this.selectedIndexes, index])
    const identicalCellsIndexes = this._getIdenticalCellsIndexes(staffPath)

    const [firstCellIndex, lastCellIndex] = [indexes[0], indexes[indexes.length - 1]]
    const matchedCellsIndexes = identicalCellsIndexes.slice(
      identicalCellsIndexes.indexOf(firstCellIndex),
      identicalCellsIndexes.indexOf(lastCellIndex) + 1
    )

    return matchedCellsIndexes.reduce((memo, index) => memo.set(index, { identityHash }), OrderedMap())
  }

  _sortIndexes(indexes) {
    return indexes.sort((a, b) => a - b)
  }

  _getCellsNumberBetweenIndexes(indexes) {
    const indexesSorted = this._sortIndexes(indexes)
    return indexesSorted[indexesSorted.length - 1] - indexesSorted[0] + 1
  }

  _isCellMismatch(cellsNumberBetweenIndexes, identicalCells) {
    return cellsNumberBetweenIndexes !== identicalCells.size
  }

  _selectIdenticalCellsToIndex(staffPath, cellIndex, identityHash, eventMeta) {
    const indexes = [...this.selectedIndexes, cellIndex]
    const cellsBetweenIndexes = this._getCellsNumberBetweenIndexes(indexes)
    const identicalCells = this._getIdenticalCellsToIndex(staffPath, cellIndex, identityHash)

    if (this._isCellMismatch(cellsBetweenIndexes, identicalCells)) {
      return

      // TODO: probably this is wrong to show error for cell with passed cellIndex,
      //       because cell with passed cellIndex can have  same identity hash,
      //       but some cells between already selected cells and cell with passed index
      //       can have other identity hash and to be inconsistant for selection:
      //return this._setSelectionError({ staffPath, cellIndex, eventMeta });
    }

    return this._setSelection({ cells: identicalCells }, null, true)
  }

  _hasCellError() {
    return !!this.cellErrorPath
  }
}
