import { Map } from 'immutable'
import { TimeOffFilterService } from 'services'

export default class TimeOffCalendar {
  constructor(timeOffCalendarModel) {
    this.model = timeOffCalendarModel
  }

  get dateRangesMap() {
    return this.model.get('dateRanges')
  }

  get dateRanges() {
    return this.model.get('dateRanges').valueSeq()
  }

  get isLoading() {
    return this.isTimeOffRequestsLoading
  }

  get isTimeOffRequestsLoading() {
    return this.model.get('isTimeOffRequestsLoading')
  }

  get allTimeOffRequestsLoaded() {
    return this.model.get('allTimeOffRequestsLoaded')
  }

  getVisibleRowsCount(filters) {
    const reduceVisibleRows = this.getVisibleRowsReducer(filters)

    return reduceVisibleRows((memo, row) => {
      const isStaff = row.get('kind') === 'staff'
      if (!isStaff) {
        return memo
      }
      return memo + 1
    }, 0)
  }

  getVisibleRowsReducer = (filters) => (reducer, initialValue) => {
    const filterService = new TimeOffFilterService(filters)

    return this.dateRanges.filter(filterService.isDateRangeVisible).reduce((memo, dateRange) => {
      const dateRangeMemo = reducer(memo, dateRange)
      return dateRange
        .get('roles')
        .filter(filterService.isRoleVisible)
        .reduce((memo, role) => {
          const rolesMemo = reducer(memo, role)
          return role
            .get('shifts')
            .filter(filterService.isShiftVisible)
            .reduce((memo, shift) => {
              const shiftMemo = reducer(memo, shift)
              return shift.get('staff').reduce((memo, staff) => reducer(memo, staff), shiftMemo)
            }, rolesMemo)
        }, dateRangeMemo)
    }, initialValue)
  }

  getDateRange(index) {
    return this.model.getIn(['dateRanges', index])
  }

  getStaffCells({ dateRangeIndex, roleIndex, shiftIndex, staffIndex }) {
    const staffPath = this._getPath({ dateRangeIndex, roleIndex, shiftIndex, staffIndex })
    return this.model.getIn([...staffPath, 'cells'])
  }

  getCell({ dateRangeIndex, roleIndex, shiftIndex, staffIndex, cellIndex }) {
    const cellPathParams = { dateRangeIndex, roleIndex, shiftIndex, staffIndex, cellIndex }
    const cellPath = this._getPath(cellPathParams)
    return this.model.getIn(cellPath)
  }

  getStaff({ dateRangeIndex, roleIndex, shiftIndex, staffIndex }) {
    const staffPathParams = { dateRangeIndex, roleIndex, shiftIndex, staffIndex }
    const staffPath = this._getPath(staffPathParams)
    return this.model.getIn(staffPath)
  }

  getStaffTimeOffRequests({ dateRangeIndex, roleIndex, shiftIndex, staffIndex }) {
    const staffPath = this._getPath({ dateRangeIndex, roleIndex, shiftIndex, staffIndex })
    return this.model.getIn([...staffPath, 'timeOffRequests'])
  }

  getStaffTimeOffRequest({ dateRangeIndex, roleIndex, shiftIndex, staffIndex, timeOffRequestId }) {
    const timeOffRequests = this.getStaffTimeOffRequests({
      dateRangeIndex,
      roleIndex,
      shiftIndex,
      staffIndex
    })

    return timeOffRequests?.find((timeOffRequest) => timeOffRequest.get('id') === timeOffRequestId)
  }

  _getPath(params, includeTimeOffScope = false) {
    const keys = Object.keys(params)
    keys.forEach((key) => {
      if (typeof params[key] === 'undefined') {
        throw new Error(`${key} is undefined`)
      }
    })

    const { dateRangeIndex, roleIndex, shiftIndex, staffIndex, cellIndex } = params
    const dateRangePath = dateRangeIndex >= 0 ? ['dateRanges', dateRangeIndex] : []
    const rolePath = roleIndex >= 0 ? ['roles', roleIndex] : []
    const shiftPath = shiftIndex >= 0 ? ['shifts', shiftIndex] : []
    const staffPath = staffIndex >= 0 ? ['staff', staffIndex] : []
    const cellPath = cellIndex >= 0 ? ['cells', cellIndex] : []
    const relativePath = [...dateRangePath, ...rolePath, ...shiftPath, ...staffPath, ...cellPath]

    return includeTimeOffScope ? ['timeOff', ...relativePath] : relativePath
  }

  static getDateRangeUserIds(dateRange) {
    return dateRange
      .get('roles')
      .reduce(
        (memo, role) =>
          role
            .get('shifts')
            .reduce(
              (memo, shift) => shift.get('staff').reduce((memo, staff) => memo.concat(staff.get('userId')), memo),
              memo
            ),
        []
      )
  }

  static countDateRangeTimeOffs(dateRange) {
    return dateRange
      .get('roles')
      .reduce(
        (count, role) =>
          role
            .get('shifts')
            .reduce(
              (count, shift) =>
                shift.get('staff').reduce((count, staff) => count + staff.get('timeOffRequests').size, count),
              count
            ),
        0
      )
  }

  static countStaffPTOs(staff) {
    return staff.get('cells').reduce((count, cell) => {
      const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
      const timeOff = cellStaffEvent.get('timeOff')
      const isPTO = timeOff && timeOff.getIn(['timeOffAttributes', 'paidMinutes']) > 0
      return isPTO ? count + 1 : count
    }, 0)
  }
}
