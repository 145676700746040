import { navigate } from 'Navigation'
import './Footer.scss'
import { t } from 'i18n'

export interface IFooter {
  dataController: any
  userId: String
  timeService: any
  usDate: any
  shiftSwapRequest: any
  urlId: any
}

export default function Footer({ dataController, userId, timeService, usDate, shiftSwapRequest, urlId }: IFooter) {
  const handleWithdrawButtonClick = () => {
    async function updateData() {
      const monthStartsAt = timeService.dateTimeToMoment(usDate).startOf('month').toISOString()
      const id = shiftSwapRequest.get('id')
      await dataController.cancelShiftSwapRequest(id, userId, monthStartsAt)
    }
    updateData()
  }

  const handleRequestOtherStaffButtonClick = () => {
    return navigate.from.StaffCalendarPage.to.StaffCalendarPageNew()
  }

  const shouldRenderWithdrawButton = () => {
    const state = shiftSwapRequest.get('state')
    return state === 'submitted' || state === 'accepted'
  }

  const shouldRenderRequestOtherStaffButton = () => {
    const state = shiftSwapRequest.get('state')
    return state === 'denied' || state === 'declined' || state === 'canceled'
  }

  return (
    <div>
      <div className="myShiftFooterBox">
        <div className="myShiftFooterButtons">
          {shouldRenderRequestOtherStaffButton() && (
            <sh-button
              label={`${t('staffCalendar.shiftSwap.actionLabels.requestOtherStaff')}`}
              role="button"
              color="secondary"
              size="l"
              onClick={handleRequestOtherStaffButtonClick}
            ></sh-button>
          )}
          {shouldRenderWithdrawButton() && (
            <sh-button
              label={`${t('staffCalendar.shiftSwap.actionLabels.withdrawRequest')}`}
              role="button"
              color="primary"
              size="m"
              onClick={handleWithdrawButtonClick}
            ></sh-button>
          )}
        </div>
      </div>
    </div>
  )
}
