import { PropsWithChildren, useEffect, useState } from 'react'
import './Slider.scss'
import { ConstraintType, ConstraintWeight } from '../constants'

export interface ISliderProps {
  disabled: boolean
  type: string
  weight: number
  updateWeight?: (weight: number) => void
}

export interface ISlideProps {
  setSelectedRange: (weight: number) => void
  index: number
  selectedRange: number
  type: string
  disabled: boolean
  updateWeight?: (weight: number) => void
}

function Slider({ disabled, type, weight, updateWeight }: PropsWithChildren<ISliderProps>) {
  useEffect(() => {
    setSelectedRange(weight)
  }, [type, disabled, weight])
  const range = ConstraintWeight.HARD
  const [selectedRange, setSelectedRange] = useState(weight)

  return (
    <>
      <div className="flex constraint-weight-container">
        <div className="constraint-weight">
          {Array(range)
            .fill(0)
            .map((_, index) => (
              <Slide
                key={`slider${index}`}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                index={index}
                type={type}
                disabled={disabled}
                updateWeight={updateWeight}
              />
            ))}
        </div>
        <span>{selectedRange}</span>
      </div>
    </>
  )
}

function Slide({
  setSelectedRange,
  index,
  selectedRange,
  type,
  disabled,
  updateWeight
}: PropsWithChildren<ISlideProps>) {
  const backgroundColor = type === ConstraintType.SOFT ? '#3DC3B0' : '#dddddd'
  const additionalStyle = index < selectedRange ? { backgroundColor: backgroundColor } : { backgroundColor: '#dddddd' }

  const clickHandler = disabled
    ? () => {}
    : () => {
        setSelectedRange(index + 1)
        if (updateWeight) {
          updateWeight(index + 1)
        }
      }
  return <div style={{ ...additionalStyle }} key={index} onClick={clickHandler} className="weight"></div>
}

export default Slider
