import facilityStore from './facilityStore'
import idnStore from './idnStore'
import countryStore from './countryStore'
import stateStore from './stateStore'
import skillStore from './skillStore'
import certificationStore from './certificationStore'
// import minimumStaffingRequirementStore from './minimumStaffingRequirementsStore';
// import jobStore from './jobStore';
import unitStore from './unitStore'
import shiftStore from './shiftStore'
// import userNotificationStore from './userNotificationStore';
import employmentStore from './employmentStore'
import serviceStore from './serviceStore'
import facilityUsersStore from './facilityUsersStore'
import staffPositionsStore from './staffPositionsStore'
import staffComplianceStore from './staffComplianceStore'
import staffPreferencesStore from './staffPreferencesStore'
import roleStore from './roleStore'
import StoresFactory from '@humanics/he-react-common/lib/admin/stores/storesFactory'
// import singleStaffPreferencesStore from './singleStaffPreferencesStore';
import expertiseGroupStore from './expertiseGroupStore'
import expertiseStore from './expertiseStore'
import unitResourceStore from './unitResourceStore'
// TODO it should not initialize admin stores for manager part
// TODO it should not initialize unneeded stores

const STORES = {
  roleStore,
  facilityStore,
  idnStore,
  countryStore,
  stateStore,
  skillStore,
  employmentStore,
  certificationStore,
  // minimumStaffingRequirementStore,
  // jobStore,
  unitStore,
  shiftStore,
  // censusStore,
  // userNotificationStore,
  serviceStore,
  facilityUsersStore,
  staffPositionsStore,
  staffComplianceStore,
  staffPreferencesStore,
  // singleStaffPreferencesStore,
  expertiseGroupStore,
  expertiseStore,
  unitResourceStore
}

const storesFactory = () => StoresFactory(STORES)

export default storesFactory
