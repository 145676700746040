import { Component } from 'react'
import { createPortal } from 'react-dom'
import './Tooltip.scss'

const setBetterFitting = ({ missingSpaceBottom, missingSpaceTop }) => {
  return {
    fitsTop: missingSpaceTop < 10,
    fitsBottom: missingSpaceBottom < missingSpaceTop
  }
}

// TODO: check for usage, seems this component is deprecated:
export default class ToolTip extends Component {
  constructor(props) {
    super(props)
    this.state = { x: 0, y: 0, type: 'none' }
    this.anchor = document.getElementById('root')
  }

  componentDidUpdate(prevProps) {
    const { rectangle, visible } = this.props
    const { rectangle: prevRectangle } = prevProps

    if (
      rectangle &&
      visible &&
      (!prevRectangle || prevRectangle.x !== rectangle.x || prevRectangle.y !== rectangle.y)
    ) {
      this.setup(rectangle)
    }
  }

  getPosition = ({ rectangle, nodeRectangle, fitsRight, fitsLeft, fitsTop, fitsBottom, right, left, top, bottom }) => {
    let x = 0
    let y = 0
    let type = ''
    if (fitsRight) {
      x = right
      y = top + (rectangle.height - nodeRectangle.height) / 2
      if (y < 0) {
        y = top
      }
      type = 'right'
    } else if (fitsBottom) {
      y = bottom
      x = left + (rectangle.width - nodeRectangle.width) / 2
      if (x < 0) {
        x = left / 2
      }
      type = 'bottom'
    } else if (fitsLeft) {
      x = left - nodeRectangle.width
      y = top + (rectangle.height - nodeRectangle.height) / 2
      if (y < 0) {
        y = top
      }
      type = 'left'
    } else if (fitsTop) {
      y = top - nodeRectangle.height
      x = left + (rectangle.width - nodeRectangle.width) / 2
      if (x < 0) {
        x = left / 2
      }
      type = 'top'
    }
    return {
      x,
      y,
      type
    }
  }

  setup = (rectangle) => {
    const { childrenId } = this.props
    const node = document.getElementById(childrenId)
    const nodeRectangle = node?.getBoundingClientRect()
    if (!nodeRectangle) {
      return
    }
    const documentWidth = global.document.documentElement.clientWidth
    const documentHeight = global.document.documentElement.clientHeight
    const topNavHeight = 50
    const right = rectangle.x + rectangle.width
    const left = rectangle.x
    const top = rectangle.y
    const bottom = rectangle.y + rectangle.height
    let fitsRight =
      right + nodeRectangle.width <= global.scrollX + documentWidth &&
      top >= rectangle.height / 2 + topNavHeight &&
      documentHeight >= bottom + nodeRectangle.height
    let fitsLeft =
      left + nodeRectangle.width >= 0 &&
      top >= rectangle.height / 2 + topNavHeight &&
      documentHeight >= bottom + nodeRectangle.height
    let fitsTop = top + nodeRectangle.height - documentHeight >= 0
    let fitsBottom = bottom + nodeRectangle.height <= global.scrollY + documentHeight
    if (!fitsRight && !fitsLeft && !fitsBottom && !fitsTop) {
      const missingSpaceBottom = Math.abs(documentHeight - bottom + nodeRectangle.height)
      const missingSpaceTop = Math.abs(documentHeight - top + nodeRectangle.height)
      const { fitsBottom: shouldBeBottom, fitsTop: shouldBeTop } = setBetterFitting({
        missingSpaceBottom,
        missingSpaceTop
      })
      fitsBottom = shouldBeBottom
      fitsTop = shouldBeTop
    }
    const { x, y, type } = this.getPosition({
      rectangle,
      nodeRectangle,
      fitsRight,
      fitsLeft,
      fitsTop,
      fitsBottom,
      right,
      left,
      top,
      bottom
    })
    this.setState({ type, x, y, height: nodeRectangle.height, width: nodeRectangle.width })
  }

  render() {
    const {
      state: { type, x, y, height, width },
      props: { visible }
    } = this
    const hide = visible === true ? '' : 'hide'
    const style = { left: x + global.scrollX + 'px', top: y + global.scrollY + 'px' }
    const arrowStyle = {}
    switch (type) {
      case 'right':
      case 'left':
        arrowStyle.top = `${height / 2 - 10}px`
        break
      case 'bottom':
        arrowStyle.top = '-20px'
        arrowStyle.left = `${width / 2 - 10}px`
        break
      case 'top':
        arrowStyle.bottom = `${-1 * height - 20}px`
        arrowStyle.left = `${width / 2 - 10}px`
        break
      default:
        break
    }

    return (
      <>
        {createPortal(
          <div
            id="hx-tooltip"
            className={`${hide} ${type}`}
            style={style}
            onMouseOver={this.onMouseOver}
            onMouseLeave={this.onMouseLeave}
          >
            <div className="tooltip-arrow" style={arrowStyle}></div>
            <div className="tooltip-inner">{this.props.children}</div>
          </div>,
          this.anchor
        )}
      </>
    )
  }

  onMouseOver = (event) => {
    const { onMouseOver } = this.props
    onMouseOver?.(event)
  }

  onMouseLeave = (event) => {
    const { onMouseLeave } = this.props
    onMouseLeave?.(event)
  }
}
