import { indexAlgoScheduleStateQuery, readAlgoScheduleQuery, readScheduleSnapshotQuery } from './Queries'
import { generateScheduleMutation, updateAlgoScheduleMutation, resetAlgoScheduleMutation } from './Mutation'

// ! Introduced new store which do schedule regarding operations
export function AlgoScheduleStore() {
  let gqlClient = null
  const actions = {
    generateSchedule,
    readAutoSchedule,
    updateAlgoSchedule,
    indexAlgoScheduleState,
    readScheduleSnapshot,
    resetAlgoSchedule
  }

  return { initialize, ...actions }

  function initialize(state, context) {
    ;({ gqlClient } = context)
    return state
  }

  async function generateSchedule(generateScheduleInput) {
    const params = { parameters: generateScheduleInput }
    let task_id
    try {
      task_id = await gqlClient.mutate(generateScheduleMutation, params)
    } catch (e) {
      console.error(e.message)
    }
    return task_id
  }

  async function updateAlgoSchedule(scheduleId, status) {
    const params = { id: scheduleId, schedule: { status: status } }
    let resp
    try {
      resp = await gqlClient.mutate(updateAlgoScheduleMutation, params)
    } catch (e) {
      return { updateAlgoSchedule: { id: null } }
    }
    return resp
  }

  async function readAutoSchedule(readScheduleInput) {
    const params = { ...readScheduleInput }
    let response
    // TODO: do the destructuring of data
    try {
      response = await gqlClient.query(readAlgoScheduleQuery, params)
    } catch (e) {
      return { status: null, algoScheduleId: null }
    }
    return {
      status: response.algoSchedule.status,
      algoScheduleId: response.algoSchedule.id,
      message: response.algoSchedule.message
    }
  }

  async function indexAlgoScheduleState(indexScheduleInput) {
    const params = { ...indexScheduleInput }
    let response
    // TODO: do the destructuring of data
    try {
      response = await gqlClient.query(indexAlgoScheduleStateQuery, params)
    } catch (e) {
      return { algoScheduleState: [{ status: null, algoScheduleId: null }] }
    }
    return response
  }

  async function readScheduleSnapshot(readScheduleSnapshotInput) {
    const params = { ...readScheduleSnapshotInput }
    let response
    try {
      const { scheduleSnapshot } = await gqlClient.query(readScheduleSnapshotQuery, params)
      response = scheduleSnapshot
    } catch (e) {
      return { isAlgoOutputApplied: false }
    }
    return response
  }

  async function resetAlgoSchedule(resetAlgoScheduleInput) {
    let response
    try {
      response = await gqlClient.mutate(resetAlgoScheduleMutation, resetAlgoScheduleInput)
    } catch (e) {
      return { resetAlgoStatus: { isScheduleReset: false } }
    }
    return response.resetAlgoSchedule
  }
}
