import { createRef, useEffect, useState } from 'react'
import { isEmpty, isEqual } from 'lodash'
import { formatConstraintName } from '../utils.js'
import ConstraintDetails from '../ConstraintDetails/ConstraintDetails'
import Constraint from './Constraint/Constraint'
import './ConstraintsList.scss'
import { curry } from 'i18n'
import { ConstraintType, ConstraintTableHeaders } from '../constants'
const t = curry('calendar.autoSchedule.constraintDetails.')

function ConstraintsList({
  isConstraintsLoading,
  constraints: allConstraint,
  generateSchedule,
  updateConstraintSettings
}) {
  const [constraints, setConstraints] = useState([allConstraint])
  const [searchField, setSearchField] = useState('')
  const [filterField, setFilterField] = useState(ConstraintType.ALL)
  const searchRef = createRef()
  const [selectedConstraint, setSelectedConstraint] = useState({})
  // const [selectedConstraintType, setSelectedConstraintType] = useState({})
  const [updatedConstraintSettings, setUpdatedConstraintSettings] = useState({})
  const [showDetailsPage, setShowDetailsPage] = useState(true)
  const dropDownRef = createRef()
  const notificationRef = createRef()
  const isRankingEnabled = window.REACT_APP_RANKING_ENABLED_FOR_ALGO_SCHEDULE === 'true'
  // allConstraint.sort((a, b) => b.type.localeCompare(a.type))
  useEffect(() => {
    let updatedConstraints = allConstraint
    if (filterField !== ConstraintType.ALL) {
      updatedConstraints = _getFilteredConstraints()
    }
    setConstraints(
      updatedConstraints.map((constraint) => {
        constraint.isActive = !!constraint.isUserPreference
        return constraint
      })
    )
    searchRef.current?.addEventListener('keyup', onChangeSearchField)
    searchRef.current?.addEventListener('clearsearch', onChangeSearchField)
    dropDownRef.current?.addEventListener('value-changed', dropDownHandler)
  }, [allConstraint])

  const onChangeSearchField = (e) => {
    setSearchField(e.target.value)
  }

  useEffect(() => {
    let updatedConstraints = allConstraint
    if (searchField === '') {
      updatedConstraints = _getFilteredConstraints()
    } else {
      updatedConstraints = constraints.filter((constraint) =>
        formatConstraintName(constraint.name).toLowerCase().includes(searchField.toLowerCase())
      )
    }
    setConstraints(updatedConstraints)
  }, [searchField])

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index.toString())
  }

  const handleDragOver = (e, index) => {
    e.preventDefault()
  }

  const handleDrop = (e, dropIndex) => {
    e.preventDefault()
    const dragIndex = Number(e.dataTransfer.getData('text/plain'))
    const newConstraintsList = [...constraints]
    const [draggedItem] = newConstraintsList.splice(dragIndex, 1)
    newConstraintsList.splice(dropIndex, 0, draggedItem)
    setConstraints(newConstraintsList)
  }

  const swapConstraints = (index, method) => {
    let swapElementIndex
    const newConstraintsList = [...constraints]
    if (method === 'up') {
      if (index - 1 < 0) {
        return
      }
      swapElementIndex = index - 1
    } else {
      if (index + 1 >= newConstraintsList.length) {
        return
      }
      swapElementIndex = index + 1
    }
    ;[newConstraintsList[index], newConstraintsList[swapElementIndex]] = [
      newConstraintsList[swapElementIndex],
      newConstraintsList[index]
    ]
    setConstraints(newConstraintsList)
  }

  const onSelectConstraint = (e, index) => {
    let newConstraintsList = [...constraints]
    if (!e.target.active) {
      newConstraintsList[index].isActive = false
    } else {
      newConstraintsList[index].isActive = true
    }
    setConstraints(newConstraintsList)
  }
  function showConstraintDetails(constraint) {
    setSelectedConstraint({ ...constraint })
    setUpdatedConstraintSettings({ ...constraint })
    setShowDetailsPage(true)
  }

  useEffect(() => {
    const updatedConstraints = _getFilteredConstraints()
    setConstraints(updatedConstraints)
  }, [filterField])

  const _getFilteredConstraints = () => {
    let updatedConstraints = allConstraint
    if (filterField === ConstraintType.DISABLED) {
      updatedConstraints = allConstraint.filter((constraint) => !constraint.enabled)
    } else if (filterField === ConstraintType.HARD || filterField === ConstraintType.SOFT) {
      updatedConstraints = allConstraint.filter((constraint) => constraint.enabled && constraint.type === filterField)
    } else {
      updatedConstraints.map((constraint) => {
        constraint.isActive = !!constraint.isUserPreference
        return constraint
      })
    }
    return updatedConstraints
  }

  const dropDownHandler = (e) => {
    if (e.target.value === t('allConstraints.name')) {
      setFilterField(ConstraintType.ALL)
    } else if (e.target.value === t('hardConstraint.name')) {
      setFilterField(ConstraintType.HARD)
    } else if (e.target.value === t('softConstraint.name')) {
      setFilterField(ConstraintType.SOFT)
    } else {
      setFilterField(ConstraintType.DISABLED)
    }
  }

  const getDraggableAttributes = (constraintType, index, isConstraintEnabled) => {
    let draggableAttributes = { draggable: false }
    if (isRankingEnabled && isConstraintEnabled && constraintType === ConstraintType.SOFT) {
      draggableAttributes = {
        draggable: true,
        onDragStart: (e) => handleDragStart(e, index),
        onDragOver: (e) => handleDragOver(e, index),
        onDrop: (e) => handleDrop(e, index)
      }
    }
    return draggableAttributes
  }

  const sortAllConstraints = (e, field) => {
    let newConstraintsList = [...constraints]
    if (e.target.sortDescending) {
      newConstraintsList.sort((firstConstraint, secondConstraint) =>
        firstConstraint[field].localeCompare(secondConstraint[field])
      )
    } else {
      newConstraintsList.sort((firstConstraint, secondConstraint) =>
        secondConstraint[field].localeCompare(firstConstraint[field])
      )
    }
    setConstraints(newConstraintsList)
  }

  const selectAllConstraints = (e) => {
    let newConstraintsList = [...constraints]
    for (let constraint of newConstraintsList) {
      if (e.target.active && constraint.enabled) {
        constraint.isActive = true
      } else {
        constraint.isActive = false
      }
    }
    setConstraints(newConstraintsList)
  }

  const discardChangesHandler = () => {
    setUpdatedConstraintSettings({ ...selectedConstraint })
  }

  const saveSettingsHandler = async () => {
    await updateConstraintSettings({ ...selectedConstraint, ...updatedConstraintSettings })
    showNotification()
    setShowDetailsPage(false)
  }

  const showNotification = () => {
    notificationRef.current.visible = notificationRef.current ? !notificationRef.current.visible : false
  }

  const renderParameters = (constraint, index) => {
    let draggableAttributes = getDraggableAttributes(constraint.type, index, constraint.enabled)
    return (
      <Constraint
        constraint={constraint}
        index={index}
        showConstraintDetails={showConstraintDetails}
        draggableAttributes={draggableAttributes}
        onSelectConstraint={onSelectConstraint}
        swapConstraints={swapConstraints}
      />
    )
  }

  const selectedConstraintSetting = {
    type: selectedConstraint.type,
    enabled: selectedConstraint.enabled,
    weight: selectedConstraint.weight
  }
  const updatedConstraintSetting = {
    type: updatedConstraintSettings.type,
    enabled: updatedConstraintSettings.enabled,
    weight: updatedConstraintSettings.weight
  }
  const additionalCSS =
    isEmpty(selectedConstraint) || !showDetailsPage || isEqual(selectedConstraintSetting, updatedConstraintSetting)
      ? { disabled: true }
      : {}

  return (
    <>
      <div className="row align-middle constraint-body-wrapper">
        {isConstraintsLoading ? (
          <sh-spinner label="Loading Constraints...."></sh-spinner>
        ) : (
          <>
            <div className="h100 w100">
              <div id="constraintsList" className="row constraints-list-body">
                <div className="w40 h100 constraint-section-background">
                  <div className="row col-12 search-wrapper">
                    <div className="flex col-4">
                      <sh-dropdown ref={dropDownRef} no-clear label="Parameters List">
                        <sh-menu-item label={t('allConstraints.name')} active></sh-menu-item>
                        <sh-menu-item label={t('hardConstraint.name')}></sh-menu-item>
                        <sh-menu-item label={t('softConstraint.name')}></sh-menu-item>
                        <sh-menu-item label={t('disabledConstraint.name')}></sh-menu-item>
                      </sh-dropdown>
                    </div>
                    <div className="flex col-7 pr20">
                      <sh-search
                        label="Search scheduling parameters"
                        ref={searchRef}
                        keyup={onChangeSearchField}
                      ></sh-search>
                    </div>
                  </div>
                  {constraints.length === 0 ? (
                    <div className="flex align-center">
                      <sh-empty-state
                        icon="studies"
                        label={`"${searchField}" Not Found`}
                        class="w50 search-empty-state"
                      ></sh-empty-state>
                    </div>
                  ) : (
                    <div className="constraint-table-wrapper">
                      <sh-table multiselect>
                        <sh-table-row slot="header">
                          <sh-table-head checkbox>
                            <sh-checkbox
                              label=""
                              onClick={(e) => {
                                selectAllConstraints(e)
                              }}
                            ></sh-checkbox>
                          </sh-table-head>
                          <sh-table-head
                            columns="12"
                            onClick={(e) => {
                              sortAllConstraints(e, 'name')
                            }}
                          >
                            <span className='constraint-table-heading'>{ConstraintTableHeaders.PARAMETERS}</span>
                          </sh-table-head>
                          <sh-table-head columns="12" unsortable>
                          <span className='constraint-table-heading'> {ConstraintTableHeaders.WEIGHTAGE}</span>
                          </sh-table-head>
                        </sh-table-row>

                        {constraints.map((constraint, index) => {
                          return renderParameters(constraint, index)
                        })}
                      </sh-table>
                    </div>
                  )}
                </div>
                <div className="w60">
                  <ConstraintDetails
                    selectedConstraint={selectedConstraint}
                    setUpdatedConstraintSettings={setUpdatedConstraintSettings}
                    updatedConstraintSettings={updatedConstraintSettings}
                    showDetailsPage={showDetailsPage}
                  />
                  <sh-notifications position="top-right" class="constraint-update-notification">
                    <sh-notification-item
                      duration="2000"
                      type="success"
                      label="Saved Parameters Settings"
                      ref={notificationRef}
                      sticky
                    >
                      Setting for {formatConstraintName(selectedConstraint.name)} has been saved.
                    </sh-notification-item>
                  </sh-notifications>
                </div>
              </div>
              <sh-wrapper slot="footer" class="flex align-right absolute autoscheduler-footer">
                <sh-button
                  condensed
                  color="tertiary"
                  label="Discard Changes"
                  size="m"
                  class="constraint-list-footer-button"
                  {...additionalCSS}
                  onClick={() => {
                    discardChangesHandler()
                  }}
                ></sh-button>

                <sh-button
                  condensed
                  color="secondary"
                  label="Save Settings"
                  size="m"
                  class="constraint-list-footer-button ml10"
                  {...additionalCSS}
                  onClick={() => {
                    saveSettingsHandler()
                  }}
                ></sh-button>

                <sh-button
                  condensed
                  color="Primary"
                  label="Simulate Schedule"
                  size="m"
                  class="constraint-list-footer-button ml10"
                  onClick={() => generateSchedule(constraints)}
                ></sh-button>
              </sh-wrapper>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ConstraintsList
