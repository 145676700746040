import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [Field('id', 'ID'), Field('title', 'Skill')]

function SkillStore() {
  return AdminStore({
    fields,
    entityName: 'Skill',
    api: {
      index: 'indexSkills'
    },
    header: { title: 'Skills' }
  })
}

export default SkillStore
