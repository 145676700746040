import { t } from 'i18n'
import { navigate } from 'Navigation'
import { createRef, PureComponent } from 'react'
import logo from '../../styles/web_v3/logo-b.png'

export class TermsOfService extends PureComponent {
  onClose(e) {
    e.preventDefault()
    navigate.clearHash()
  }

  constructor(props) {
    super(props)
    this.modalRef = createRef()
  }

  componentDidMount() {
    const modalElement = this.modalRef.current
    if (modalElement) {
      modalElement.addEventListener('modal-closed', this.onClose)
    }
  }

  render() {
    return (
      <sh-modal
        visible={true}
        ref={this.modalRef}
        label={'CrewPlace Enterprise Terms Of Service'}
        modal-height="calc(100% - 160px)"
        modal-width="800px"
        style={{ zIndex: 100001 }}
      >
        <div className="hm-signup-confirmation bg-white p20">
          <div className="hm-web-view bg-accent-6 web-view safari-fix">
            <header className="bg-white">
              <img alt="logo" src={logo} />
            </header>

            <br />
            <div>
              <sh-text>
                Updated: Wednesday, February 14<sup style={{ marginLeft: 0 }}>th</sup> 2024
              </sh-text>

              <br />
              <sh-text>
                Welcome to the CrewPlace Enterprise digital platform (the “Platform”). The Platform is operated by
                Siemens Healthcare AG or its affiliates. (“Siemens Healthineers”) and is accessible through the
                CrewPlace Enterprise digital platform, accessible through the CrewPlace Enterprise mobile application or
                website. The Platform is an online service through which scheduling staff members (“Administrator(s)”)
                at hospitals and other care facilities (“Facility(ies)”) can schedule nurses, technologists, and other
                healthcare providers (“Care Provider(s)”). The following terms and conditions (“Terms”) govern your use
                of the Platform, as an Administrator or a Care Provider, including your use of the Platform to provide
                or receive scheduling services, including mobile notifications, private messages, public messages, team
                schedules, personal schedules, and status updates (“Services”). Siemens Healthineers is not affiliated
                with any Facility, Administrator or Care Provider. Nothing in these Terms shall be construed as making
                Siemens Healthineers partner, joint venturer, agent, legal representative, employer, contractor, or
                employee of any other party. Siemens Healthineers does not take part in, nor is Siemens Healthineers in
                any way responsible for, any interactions or negotiations between Administrators, Facilities or Care
                Providers, except to the extent necessary to maintain the Platform through an agreement between Siemens
                Healthineers and the Facility for which you are using the Platform. Any interaction between you and any
                other individual or entity through the Platform or arising out of your use of the Platform, including
                any interactions between Facilities, Administrators and Care Providers, is solely between you and that
                other individual or entity. Siemens Healthineers expressly disclaims any responsibility for any
                interactions between Facilities, Administrators and Care Providers. Care Providers and Administrators
                acknowledge that they are not eligible to participate in or receive any pension, health, or other
                benefits plan of Siemens Healthineers, and that Siemens Healthineers is in no way obligated to acquire
                any workers’ compensation insurance or other insurance associated with their services.
                <strong>
                  By accessing, viewing, or using the content, material, or Services available on or through the
                  Platform, you indicate, on behalf of yourself and any company or business entity on whose behalf you
                  are using the Platform, that you have read and understand these Terms, that you agree to them and
                  intend to be legally bound by them.&nbsp;
                </strong>
                If you do not agree to these Terms, or if you are under 18 years of age, or if you are not a legal
                resident of the United States, you are not granted permission to use the Platform and must exit
                immediately. Any personal information that you provide to Siemens Healthineers via the Platform is
                subject to the Privacy Notice for the CrewPlace Enterprise App, which is incorporated into these Terms
                by reference, as if set forth fully herein.
              </sh-text>

              <br />
              <sh-text size="super-header">1.&nbsp;&nbsp;Registration</sh-text>
              <sh-text>
                Depending on your intended uses of the Platform, you may be asked to designate your account as either an
                Administrator Account (i.e., for Facilities) or a Care Provider Account (i.e., for Care Providers). Your
                obligations and rights under these Terms may vary depending on whether you registered as an
                Administrator or a Care Provider. By registering an account on the Platform, you represent, on behalf of
                yourself and any Facility, company or business entity on whose behalf you are using the Platform, that
                you are at least 18 years of age or older and that you agree to bound by and comply with these Terms.
                You are fully responsible for your account, including use of the account by any third party, and
                maintaining the confidentiality of your password. Unauthorized access to the Platform by non-registered
                users is prohibited. As part of the registration process, you may be asked to select a user name and
                password, and may be required to provide Siemens Healthineers with certain information about yourself
                including some types of personally identifying information, such as your name, gender, professional
                qualifications, CME credits, social security number and/or certification number for the purpose of
                verifying nurse credentials, email, address, and phone number (if you change your phone number you must
                update your account information within 48 hours). You agree that the information that you provide to
                Siemens Healthineers on registration and at all other times will be true, accurate, current and
                complete. You also agree that you will ensure that this information is kept accurate and up to date at
                all times. Please note that your cell phone carrier’s normal rates for text and data will apply to your
                use of the Platform.
              </sh-text>

              <br />
              <sh-text size="super-header">2.&nbsp;&nbsp;Rules of Engagement</sh-text>
              <sh-text>
                When an Administrator creates the first business user account associated with the Facility, the
                Administrator can authorize Care Providers and other Administrators to access and use the scheduling and
                other tools on the Platform. If any Administrator or Care Provider that is associated with a Facility
                leaves the Facility organization or is no longer authorized by the Facility to access and use the
                Platform, an Administrator for the Facility is solely responsible for disabling or removing such other
                Administrator’s or Care Provider's account from the Facility’s account. A Facility or Administrator may
                disable or terminate an Administrator or Care Provider’s account at any time by disabling such accounts
                through the Platform. Each Care Provider must be: (a) a citizen of, or legally authorized to work in,
                the United States, with no disqualifying prior criminal record, including prior convictions for offenses
                involving dishonesty or violence; (b) fully experienced, skilled, and qualified to perform the Services;
                (c) able to perform all of the Services under this Agreement without creating a conflict with, or breach
                of, any contract, relationship, or understanding to which he or she is a party; and (d) comply with all
                federal, state, and local statutes, regulations, rules, ordinances, and orders of any governmental body
                or agency, including those relating to the delivery of the Services to the Facilities. While Siemens
                Healthineers reserves the right to review your qualifications, Siemens Healthineers is not obligated to
                do so, and may choose not to do so, in Siemens Healthineers’ sole discretion. Siemens Healthineers may
                approve or reject your registration, for any reason or no reason, in its sole discretion and you agree
                that Siemens Healthineers has no obligation to you to allow you to use the Platform and that you are not
                a third-party beneficiary of any agreement between Siemens Healthineers and the applicable Facility. By
                registering with the Platform, if Siemens Healthineers is providing credentialing services, you hereby
                authorize Siemens Healthineers to verify any representations and warranties you make either pursuant to
                these Terms or within any materials submitted during the registration process, including conducting
                background checks, contacting any provided references or affiliations and reviewing public records. You
                acknowledge that while Siemens Healthineers reserves the right to verify these representations and
                warranties, Siemens Healthineers is not obligated to do so, and may choose not to do so, in Siemens
                Healthineers’ sole discretion.
              </sh-text>

              <br />
              <sh-text size="super-header">3.&nbsp;&nbsp;License to use the App</sh-text>
              <sh-text>
                Subject to these Terms, Siemens Healthineers hereby grants you a single, limited, terminable, revocable,
                royalty-free, non-exclusive, nontransferable, nonsublicenseable license to access and use the Platform,
                solely for informational purposes. You shall be responsible, at your sole expense or the expense of the
                Facility for which you are using the Platform, for procuring, operating, and maintaining the mobile
                device, hardware, systems software, telecommunications applications and providers, and other items
                necessary or appropriate to enable you to exercise your rights and licenses hereunder. Without limiting
                any other provisions of these Terms, any software, applications or other materials, including the
                Platform, made available to you is the copyrighted work of Siemens Healthineers or its licensors.
                Copying or distributing the Platform or the Platform Content (as defined below) is expressly prohibited.
              </sh-text>

              <br />
              <sh-text size="super-header">4.&nbsp;&nbsp;Informational Purposes Only</sh-text>
              <sh-text>
                The Platform is for informational purposes only. Siemens Healthineers will be relying on the accuracy
                and completeness of the materials and information Siemens Healthineers receives from you and third
                parties. You must review and verify the accuracy of any and all scheduling requests, confirmations and
                other materials and information in connection with the Platform. Occasionally there may be information
                on the Platform that contains typographical errors, inaccuracies, or omissions. Siemens Healthineers
                reserves the right to correct any errors, inaccuracies, or omissions and to change or update information
                or cancel scheduling requests if any information on the Platform is inaccurate at any time without prior
                notice. If there is a dispute between you and any Facility, Administrator or Care Provider, you hereby
                release Siemens Healthineers from any claims, demands, or damages of any kind and of any nature arising
                out of or relating to any such dispute.
              </sh-text>

              <br />
              <sh-text size="super-header">5.&nbsp;&nbsp;Unauthorized Activities</sh-text>
              <sh-text>
                You agree that you will not use the Platform or the Services for: (a) any illegal or unauthorized
                purposes that violate any local, national, or international laws (including to import, export,
                copyright, and trademark laws); (b) accessing or collecting any personally identifiable information,
                including any names, email addresses or other such information for any purpose, including any commercial
                purposes; (c) threatening, harassing or bullying other users, including soliciting login information;
                (d) impersonating another person or wrongfully identifying as a nurse or other healthcare provider of a
                Facility or other healthcare institution; (e) data mining, scraping, robots, or for releasing spiders,
                crawlers or any other data gathering or extraction tools; (f) modifying, copying, distributing,
                displaying, performing, reproducing, publishing, resale, licensing, creating derivative works from,
                transferring, selling any of the Platform, the Platform Content, or the User Content (defined below),
                unless otherwise authorized by these Terms or in a separate written agreement with Siemens Healthineers;
                (g) attempting to gain unauthorized access to Siemens Healthineers’ computer systems or engaging in any
                activity that interferes with the performance of, or impairs the functionality of the Platform or any
                services provided through the Platform; (h) promoting any effort to compete with Siemens Healthineers,
                including using the Platform in a way that causes a reduction or loss of business for Siemens
                Healthineers; (i) posting harmful or malicious content; (j) removing, circumventing, disabling, damaging
                or otherwise interfering in any way with any security-related features of the Platform aimed at
                preventing or restricting the unauthorized use of the Platform or any of the Platform Content; or (k)
                adversely affecting the safety, operations and confidentiality of the Platform and digital Services. You
                agree to use the Platform only for the purpose and manner for which they were specifically designed; any
                other use of the Platform or the Platform Content without prior written permission of Siemens
                Healthineers is strictly prohibited. You acknowledge and agree that the unauthorized use of the Platform
                or the Platform Content could cause irreparable harm to Siemens Healthineers and that, in the event of
                such unauthorized use, Siemens Healthineers shall be entitled to an injunction in addition to any other
                remedies available at law or in equity. When deciding on a e.g. suspension based on a misuse by you,
                Siemens Healthineers will take into account all relevant circumstances leading to such misuse, including
                breach of applicable laws, frequency and severity of the relevant misuse and whether the misuse happened
                intentionally. Upon receipt of notice about any misuse, Siemens Healthineers will review your content
                manually by human individuals and will remove any content that does not comply with this section. No
                automated systems will be used for the review. Complaints against decisions by Siemens Healthineers to
                remove your content may be filed free of charge by electronic means under
                complaints_DSA@siemens-Healthineers.com within 6 months following the decision of Siemens Healthineers.
                Siemens Healthineers will review and decide about any such complaint.
              </sh-text>

              <br />
              <sh-text size="super-header">6.&nbsp;&nbsp;Intellectual Property</sh-text>
              <sh-text>
                As between you and Siemens Healthineers, Siemens Healthineers owns or licenses all data, content,
                graphics, forms, artwork, images, photographs, functional components, and any software concepts and
                documentation and other materials on, in, or made available through the Platform (the “Platform
                Content”), as well as the selection, coordination, arrangement, and organization and enhancement of the
                Platform Content. All Platform Content is protected pursuant to copyright, trademark, patent, and other
                applicable laws. You agree not to remove or alter any copyright notice or any other proprietary notice
                on the Platform or used in connection with any Platform Content. As between you and Siemens
                Healthineers, all names, trademarks, service marks, certification marks, symbols, slogans, or logos
                appearing on the Platform are proprietary to Siemens Healthineers or its licensors. Use or misuse of
                these trademarks is expressly prohibited and may violate federal and state trademark law. Unless
                expressly written otherwise by Siemens Healthineers, you do not have any rights of any kind in or to the
                Platform Content, other than the right to use the Platform Content in accordance with these Terms.
                Certain features of the Platform may allow you to contribute comments, feedback, information, content,
                text, files, graphics, postings, and other materials and information for access, use, viewing and
                commentary by other users to the Platform, including Administrators and Facilities (“User Content”). By
                contributing User Content, you represent that you have the full legal right to provide the User Content
                and that use of the User Content by Siemens Healthineers and all other persons and entities will not:
                (a) impersonate any person or entity or falsely state or otherwise imply an affiliate with a person or
                entity or is generally false, deceptive, misleading, deceitful, misinformative, or constitutes a “bait
                and switch”; (b) infringe any intellectual property rights of any person or entity or any rights of
                publicity, personality, or privacy of any person or entity, including as a result of your failure to
                obtain consent to post personally identifying or otherwise private information about a person; (c)
                violate any law, statute, ordinance, regulation, or agreement; (d) be defamatory, libelous or trade
                libelous, unlawfully threatening, or unlawfully harassing; (e) be obscene, child pornographic, or
                indecent; (f) violate any community or Internet standard; (g) constitute misappropriation of any trade
                secret or know-how; or (h) constitute disclosure of any confidential or personal information of a third
                party. You retain all right, title and interest in and to the User Content that you submit and all
                intellectual property rights embodied therein. Upon your submission of User Content or other material or
                information to Siemens Healthineers, you grant Siemens Healthineers a worldwide, perpetual, irrevocable,
                transferable, license to access, use, distribute, reproduce, display, modify, create derivative works
                based upon, and sublicense the User Content, all without any compensation to you whatsoever. If you
                believe that any content or postings on the Platform violate your intellectual property or other rights,
                please follow our Complaint Procedures in Section 13 of these Terms.
              </sh-text>

              <br />
              <sh-text size="super-header">7.&nbsp;&nbsp;Third Party Websites and Content</sh-text>
              <sh-text>
                The Platform may contain links to other websites or feature services of third parties, including any
                Facility, for the convenience of users: (a) in locating information, products, or services that may be
                of interest; (b) with using the Platform; or (c) with performing or receiving the Services, and
                complying with any requirements associated with such transactions. These third party websites and
                services may be available on the Platform via a link, redirect, connect, "frame,” plug-ins, integrated
                application, or other technology, and may be recognized automatically by your browser. In order to
                utilize some aspects of the Platform, you may have to use, accept, install, and review information,
                data, executables, programs, software, and other items from Siemens Healthineers’ third party service
                providers, suppliers, and licensors. To the extent applicable, these Terms shall apply, but please be
                aware that Siemens Healthineers has no control over these third parties, and that these Terms may not
                apply to such links and services. Siemens Healthineers does not recommend and expressly disclaims any
                responsibility for the content, the accuracy of the information, or quality of products or services
                provided by or advertised on third party websites or the transactions you conduct or enter into with
                third parties. Your use of any third party websites or services is at your own risk, and subject to the
                terms and conditions of such third parties. Siemens Healthineers encourages you to read the privacy
                policies and terms of use linked or referenced in the websites you enter. Additional Provider Terms may
                be made available to you upon your access to or use of particular feature, function, or other element of
                the Platform.
              </sh-text>

              <br />
              <sh-text size="super-header">8.&nbsp;&nbsp;Confidentiality</sh-text>
              <sh-text>
                You hereby acknowledge that certain confidential business materials and other non-public information,
                including financial information, scheduling information, business relationships, and other trade secrets
                of Siemens Healthineers or any Facility (“Confidential Information”) may be disclosed to you during your
                use of the Platform or during your use of or performance of the Services. You will not, at any time,
                during or after the term of this Agreement, directly or indirectly, copy, use, disclose, release, or
                publish any such Confidential Information, except as expressly required or authorized by Siemens
                Healthineers or the Facility, as applicable. You will exercise reasonable precautions in maintaining the
                confidentiality of Confidential Information, and will not disclose such Confidential Information to
                third parties without express written permission of the owner. Because the life of Confidential
                Information may extend indefinitely, this provision shall continue in perpetuity.
              </sh-text>

              <br />
              <sh-text size="super-header">9.&nbsp;&nbsp;Disclaimer</sh-text>
              <sh-text>
                Siemens Healthineers, its subsidiaries, affiliates, and licensors, are not responsible for and do not
                guarantee the accuracy or completeness of any Platform Content, User Content, products, data, services
                (whether performed by Siemens Healthineers or any third party), links, advertisements, or other items
                contained within or made available through the Platform. For avoidance of doubt, Siemens Healthineers
                disclaims all responsibility for the services provided by Care Providers or Administrators, and Siemens
                Healthineers disclaims all responsibility for the failure of any Care Provider or Administrator to
                perform any services. Siemens Healthineers reserves the right to immediately remove any Platform Content
                or User Content for any reason, or for no reason. Siemens Healthineers cannot and does not review all
                communications or products made available on or through the Platform, but, although not obligated to,
                may review, verify, make changes to, or remove any Platform Content, User Content, or the products or
                services made available in connection with the Platform, at any time, with or without notice, in its
                sole discretion, including at the request of the Facility. You agree that you must evaluate and bear all
                risks associated with the use of the Platform, Platform Content, User Content, or Services and that you
                may not rely on any of the foregoing. THE PLATFORM, PLATFORM CONTENT, USER CONTENT, SERVICES, PRODUCTS,
                INFORMATION AND OTHER MATERIALS ON, IN, AND MADE AVAILABLE THROUGH THE PLATFORM ARE MADE AVAILABLE “AS
                IS” AND “WITH ALL FAULTS.” USE OF THE PLATFORM IS ENTIRELY AT YOUR OWN RISK. SIEMENS HEALTHINEERS AND
                ITS LICENSORS MAKE NO REPRESENTATIONS OR WARRANTIES, AND DISCLAIM ALL REPRESENTATIONS AND WARRANTIES,
                WITH RESPECT TO THE PLATFORM, PLATFORM CONTENT, THE USER CONTENT, SERVICES, PRODUCTS, INFORMATION AND
                OTHER MATERIALS ON, IN, AND MADE AVAILABLE THROUGH THE PLATFORM, EXPRESS OR IMPLIED, WRITTEN OR ORAL,
                ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE, INCLUDING THE
                IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, SYSTEMS INTEGRATION,
                NONINTERFERENCE, QUALITY, TITLE, AND NON-INFRINGEMENT. THE ENTIRE RISK AS TO SATISFACTORY QUALITY,
                PERFORMANCE, ACCURACY, COMPLETENESS, AND EFFORT RELATED TO THE PLATFORM IS WITH YOU.
              </sh-text>

              <br />
              <sh-text size="super-header">10.&nbsp;&nbsp;LIMITATION OF LIABILITY</sh-text>
              <sh-text>
                IN NO EVENT WILL SIEMENS HEALTHINEERS BE LIABLE FOR DAMAGES OTHER THAN ACTUAL AND DIRECT DAMAGES PROVEN
                IN A COURT OF LAW. IN NO EVENT SHALL SIEMENS HEALTHINEERS’ LIABILITY EXCEED THE PRICE YOU (EITHER
                DIRECTLY OR INDIRECTLY THROUGH A FACILITY) PAID FOR THE SERVICE THAT IS THE SUBJECT OF THE CLAIM.
                WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND ACKNOWLEDGE THAT SIEMENS HEALTHINEERS SHALL NOT BE
                LIABLE TO YOU FOR: A) ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY LOSSES, REGARDLESS
                OF LEGAL THEORY, WHICH MAY BE INCURRED BY YOU ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, THE
                PLATFORM OR THE SERVICES, INCLUDING ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY
                LOSS OF GOODWILL OR BUSINESS REPUTATION, OR ANY LOSS OF DATA SUFFERED BY YOU; OR B) ANY LOSS OR DAMAGE
                WHICH MAY BE INCURRED BY YOU AS A RESULT OF: (1) ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS,
                ACCURACY, OR EXISTENCE OF ANY INFORMATION OR MATERIALS MADE AVAILABLE THROUGH THE PLATFORM, INCLUDING
                ANY SCHEDULING; (2) ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY CARE PROVIDER, ADMINISTRATOR OR
                FACILITY; (3) ANY CHANGES THAT SIEMENS HEALTHINEERS MAY MAKE TO THE PLATFORM OR SERVICES, OR FOR ANY
                PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
                (4) THE DELETION OF, CORRUPTION OF, UNAUTHORIZED ACCESS TO, OR FAILURE TO STORE, ANY CONTENT AND OTHER
                COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE PLATFORM OR THE SERVICES;
                (5) THE USE OF ANY PRODUCTS OR SERVICES OBTAINED ON OR THROUGH THE PLATFORM; OR (6) ANY OTHER MATTER
                RELATING TO THE PLATFORM, THE SERVICES, THE PLATFORM CONTENT, OR THE USER CONTENT. THE LIMITATIONS ON
                SIEMENS HEALTHINEERS’ LIABILITY TO YOU IN THIS SECTION 10 SHALL APPLY WHETHER OR NOT SIEMENS
                HEALTHINEERS HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING
                AND EVEN IF THE REMEDIES OTHERWISE AVAILABLE FAIL OF THEIR ESSENTIAL PURPOSE. IF YOU ARE DISSATISFIED
                WITH THE PLATFORM, THE SERVICES, OR THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
                THE PLATFORM AND SERVICES.
              </sh-text>

              <br />
              <sh-text size="super-header">11.&nbsp;&nbsp;Indemnification</sh-text>
              <sh-text>
                You agree to defend, indemnify, and hold harmless Siemens Healthineers, its officers, directors,
                members, employees, agents, parents, subsidiaries, affiliates, licensors and suppliers, from and against
                all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not
                limited to attorneys’ fees) arising from: (a) your use of and access to the Platform and Services; (b)
                your violation of any of these Terms, including the Privacy Policy; (c) your violation of any third
                party rights, including intellectual property or privacy rights; and (d) the use by of the Platform by
                any person using your Internet account or account login. This defense and indemnification obligation
                will survive these Terms and your use of the Platform and Services. Siemens Healthineers reserves the
                right, at Siemens Healthineers’ own expense, to assume the exclusive defense and control of any matter
                otherwise subject to indemnification by you hereunder, and you shall cooperate in all reasonable
                respects in such defense. You may not settle any claim contemplated in this Section 11 without the prior
                written consent of Siemens Healthineers.
              </sh-text>

              <br />
              <sh-text size="super-header">12.&nbsp;&nbsp;Internet Security</sh-text>
              <sh-text>
                Siemens Healthineers uses reasonable efforts to ensure that the Platform is generally available.
                However, there will be occasions when access to the Platform will be interrupted or unavailable. Siemens
                Healthineers will use reasonable efforts to minimize such disruption where it is within Siemens
                Healthineers’ reasonable control. You agree that Siemens Healthineers will not be liable to you for any
                modification, suspension, or discontinuance of the Platform. You understand that the technical
                processing and transmission of any content may be transferred unencrypted and involve: (a) transmissions
                over various networks; and (b) changes to conform and adapt to technical requirements of connecting
                networks or devices. Please be advised that Siemens Healthineers does not guarantee that any information
                sent from via the Platform will be secure during transmission, nor can Siemens Healthineers guarantee
                the confidentiality of any communication or material transmitted to Siemens Healthineers via the
                Platform or the internet in general, including, for example, personal information such as your name or
                address.
              </sh-text>

              <br />
              <sh-text size="super-header">13.&nbsp;&nbsp;Complaint Procedures</sh-text>
              <sh-text>
                If you believe that any content or postings on the Platform violates your rights or intellectual
                property, please contact your Facility for further assistance in addressing the matter.
              </sh-text>

              <br />
              <sh-text size="super-header">14.&nbsp;&nbsp;Changes to these Terms; Termination</sh-text>
              <sh-text>
                Siemens Healthineers reserves the right at any time to modify, alter, or update these Terms. Siemens
                Healthineers will notify you, by email or with a posting to the Platform, of any new or revised Terms,
                including information regarding the location of the new or revised terms and conditions. Your use of the
                Platform following any changes means that you agree to follow and be bound by the terms as changed. Any
                change to these Terms shall be effective as to any visitor who has visited the Platform before the
                change was made. It is the obligation of users of the Platform before the change to learn of changes to
                the Terms since their last visit. Siemens Healthineers may suspend or terminate your account and/or your
                ability to use the Platform for any failure to comply with these Terms, for providing Siemens
                Healthineers with untrue or inaccurate information about yourself, for infringement of Siemens
                Healthineers’ proprietary rights, or for any other reason whatsoever or for no reason.
              </sh-text>

              <br />
              <sh-text size="super-header">15.&nbsp;&nbsp;Assignment</sh-text>
              <sh-text>
                You may not transfer or assign any of your rights or obligations hereunder to any other party in any
                manner (by assignment, operation of law, or otherwise) unless you have obtained prior written consent
                from Siemens Healthineers. If you attempt to transfer or assign any of your rights or obligations
                hereunder without Siemens Healthineers’ prior written consent, the transfer or assignment will be
                ineffective, null, and void (and you will be in material breach of these Terms).
              </sh-text>

              <br />
              <sh-text size="super-header">16.&nbsp;&nbsp;Dispute Resolution</sh-text>
              <sh-text>
                Any dispute arising out of these Terms shall be resolved exclusively through final and binding
                arbitration conducted by the American Arbitration Association (the “AAA”). Arbitration uses a neutral
                arbitrator instead of a judge or jury, and court review of an arbitration award is very limited.
                However, an arbitrator can award the same damages and relief on an individual basis that a court can
                award to an individual; and an arbitrator must also follow these Terms, as a court would. All issues are
                for the arbitrator to decide, except that issues relating to arbitrability and the scope or
                enforceability of this agreement to arbitrate shall be for a court of competent jurisdiction to decide.
                Arbitration shall take place in Philadelphia, Pennsylvania, unless Siemens Healthineers elects
                otherwise. The arbitrator will decide the substance of all claims in accordance with the laws of the
                Commonwealth of Pennsylvania. The arbitrator shall not be bound by rulings in prior arbitrations
                involving different Siemens Healthineers users, but is bound by rulings in prior arbitrations involving
                the same Siemens Healthineers user to the extent required by applicable law. The arbitrator’s award
                shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any
                court having jurisdiction thereof. Payment of all filing, administration, and arbitrator fees will be
                governed by the AAA’s rules, unless otherwise stated in this agreement to arbitrate. If the value of the
                relief sought is $10,000 or less, at your request, Siemens Healthineers will pay all filing,
                administration, and arbitrator fees associated with the arbitration (not including attorney fees). Any
                request for payment of arbitration fees by Siemens Healthineers should be submitted by mail to the AAA
                along with your demand for arbitration and Siemens Healthineers will make arrangements to pay all
                necessary fees directly to the AAA. If the value of the relief sought is more than $10,000 and you are
                able to demonstrate that the costs of accessing arbitration will be prohibitive as compared to the costs
                of accessing a court for purposes of pursuing litigation on an individual basis, Siemens Healthineers
                will pay as much of the filing, administration, and arbitrator fees as the arbitrator deems necessary to
                prevent the costs of accessing arbitration from being prohibitive. In the event the arbitrator
                determines the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse Siemens
                Healthineers for all fees associated with the arbitration paid by Siemens Healthineers on your behalf
                that you otherwise would be obligated to pay under the AAA’s rules. If an arbitrator or court decides
                that any part of this agreement to arbitrate is invalid or unenforceable, the other parts of this
                agreement to arbitrate shall still apply. You can choose to reject this agreement to arbitrate by
                mailing Siemens Healthineers a written opt-out notice (“Opt-Out Notice”). The Opt-Out Notice must be
                postmarked no later than thirty days after the date you accept these Terms for the first time. You must
                complete the Opt-Out Notice form by providing the information called for in the form, including your
                name, address (including street number and address, city, state, and zip code), phone number, and the
                email address used to log in to the Siemens Healthineers account to which the opt-out applies. You must
                sign the Opt-Out Notice for it to be effective. This procedure is the only way you can opt out of the
                agreement to arbitrate. If you opt out of the agreement to arbitrate, all other parts of these Terms
                will continue to apply. Opting out of this agreement to arbitrate has no effect on any previous, other,
                or future arbitration agreements that you may have with Siemens Healthineers.
              </sh-text>

              <br />
              <sh-text size="super-header">17.&nbsp;&nbsp;Miscellaneous</sh-text>
              <sh-text>
                Without limiting anything else, Siemens Healthineers makes no representation that the Platform, Platform
                Materials, User Content, Services, products, information, and other materials available on, in, or
                through the Platform are appropriate or available for use in other locations, and access to them from
                territories where they are illegal is prohibited. Those who choose to access the Platform from other
                locations do so on their own volition and are responsible for compliance with applicable laws. These
                Terms constitute the complete and exclusive statement of the agreement between the parties with respect
                to the subject matter of these Terms, and these Terms supersede any and all prior oral or written
                communications, proposals, representations, and agreements. The parties do not intend, nor shall any
                clause be interpreted, to create under these Terms any obligations of any party in favor of, or benefits
                to, or rights in, any third party. If any provision of these Terms is held to be invalid, illegal or
                unenforceable for any reason, such invalidity, illegality or unenforceability shall not affect any other
                provisions of these Terms, and such provision will be deemed modified to the limited extent required to
                permit its enforcement in a manner most closely representing the intention of the parties as expressed
                herein. The waiver or failure of Siemens Healthineers to exercise in any respect any right provided
                hereunder shall not be deemed a waiver of such right in the future or a waiver of any of other rights
                established under these Terms. Headings used in these Terms are for reference only and shall not affect
                the interpretation of these Terms. No person or entity not party to this agreement will be deemed to be
                a third party beneficiary of these Terms or any provision hereof. When used herein, the words “includes”
                and “including” and their syntactical variations shall be deemed followed by the words “without
                limitation.”
              </sh-text>
            </div>

            <br />
            <br />
            <footer>
              <img alt="logo" src={logo} style={{ marginBottom: '8px' }} />
              <div className="copyright">
                © {new Date().getFullYear()} {t('copyright_text')}
              </div>
            </footer>
          </div>
        </div>
      </sh-modal>
    )
  }
}

export default TermsOfService
