import { fromJS, Map } from 'immutable'
import { staffTimeOffRequestsQuery, StaffTimeOffRequestsWithSchedulesQuery } from '../Queries'
import { approveStaffTimeOffRequestMutation, denyStaffTimeOffRequestMutation } from '../Mutations'

function TimeOffRequests() {
  let gqlClient = null
  let timeService = null
  let loadStaff = null
  let updateTimeOff = null
  let updateTimeOffRequests = null

  const actions = {
    loadTimeOffRequests,
    denyStaffTimeOffRequest,
    approveStaffTimeOffRequest,
    loadTimeOffRequestsWithSchedules
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ facilityTime: timeService, gqlClient, loadStaff, updateTimeOff, updateTimeOffRequests } = context)
  }

  async function approveStaffTimeOffRequest(timeOffRequest, paidDates, { text, subject }) {
    const timeOffRequestId = timeOffRequest.get('id')

    const timeOffRequestParams = { id: timeOffRequestId, paidDates }

    if (subject || text) {
      timeOffRequestParams.responseNote = { subject, text }
    }

    const parameters = { timeOffRequest: timeOffRequestParams }
    await gqlClient.mutate(approveStaffTimeOffRequestMutation, parameters)

    return updateTimeOffRequests((timeOffRequestsData) => {
      const timeOffRequestsMap = timeOffRequestsData.get('timeOffRequestsMap')
      const pageInfo = timeOffRequestsData.get('pageInfo')

      const newTimeOffRequestsMap = timeOffRequestsMap.setIn([timeOffRequestId, 'status'], 'approved')
      const newPageInfo = removeTimeOffRequestFromPageInfo(timeOffRequest, pageInfo)

      return timeOffRequestsData.set('pageInfo', newPageInfo).set('timeOffRequestsMap', newTimeOffRequestsMap)
    })
  }

  async function denyStaffTimeOffRequest(timeOffRequest, { text, subject }) {
    const timeOffRequestId = timeOffRequest.get('id')
    const timeOffRequestParams = { id: timeOffRequestId }

    if (subject || text) {
      timeOffRequestParams.responseNote = { subject, text }
    }
    const parameters = { timeOffRequest: timeOffRequestParams }
    const { denyStaffTimeOffRequest: deniedTimeOff } = await gqlClient.mutate(
      denyStaffTimeOffRequestMutation,
      parameters
    )

    return updateTimeOffRequests((timeOffRequestsData) => {
      const timeOffRequestsMap = timeOffRequestsData.get('timeOffRequestsMap')
      const pageInfo = timeOffRequestsData.get('pageInfo')

      const newTimeOffRequestsMap = timeOffRequestsMap.set(timeOffRequestId, fromJS(deniedTimeOff))
      const newPageInfo = removeTimeOffRequestFromPageInfo(timeOffRequest, pageInfo)

      return timeOffRequestsData.set('pageInfo', newPageInfo).set('timeOffRequestsMap', newTimeOffRequestsMap)
    })
  }

  function removeTimeOffRequestFromPageInfo(timeOffRequest, pageInfo) {
    const timeOffRequestStartDate = timeOffRequest.get('dates').first()?.get('date')
    const timeOffRequestEndDate = timeOffRequest.get('dates').last()?.get('date')

    const startDate = pageInfo.get('startDate')
    const endDate = pageInfo.get('endDate')
    const isTimeOffRequestInDateRange =
      timeService.timeMoment(timeOffRequestStartDate).isSameOrBefore(endDate) &&
      timeService.timeMoment(timeOffRequestEndDate).isSameOrAfter(startDate)

    if (isTimeOffRequestInDateRange) {
      const totalCount = pageInfo.get('totalCount') - 1
      const offset = pageInfo.get('offset') - 1
      return pageInfo.merge({ totalCount, offset })
    }

    return pageInfo
  }

  async function loadTimeOffRequests(unitId, startDate, endDate, page) {
    const limit = 25 // we should use 17 here if we want load related data
    // (which minimal entity takes one day) in one request
    // with limit 999 (17 schedules with 8 weeks will take 952 days)
    const offset = (page - 1) * limit
    const statuses = ['pending']
    const parameters = { unitId, statuses, startDate, endDate, limit, offset }
    const options = {
      operations: { staffTimeOffRequests: { includePagination: true } }
    }

    const { staffTimeOffRequests } = await gqlClient.query(staffTimeOffRequestsQuery, parameters, options)

    return updateTimeOffRequests((timeOffRequestsData) =>
      _updateTimeOffRequestsData(timeOffRequestsData, unitId, startDate, endDate, staffTimeOffRequests)
    )
  }

  async function loadTimeOffRequestsWithSchedules(unitId, startDate, endDate, page, limit = 25) {
    const offset = (page - 1) * limit
    const statuses = ['pending']
    const parameters = { unitId, statuses, startDate, endDate, limit, offset, schedulesLimit: 999 }
    const options = {
      operations: { staffTimeOffRequests: { includePagination: true } }
    }

    updateTimeOffRequests((timeOffRequestsData) =>
      timeOffRequestsData.set('pageInfo', Map({ isLoading: true, isLoaded: false }))
    )

    const loadTimeOffRequestsPromise = gqlClient.query(StaffTimeOffRequestsWithSchedulesQuery, parameters, options)
    const loadStaffPromise = loadStaff(unitId, startDate, endDate)

    const [{ staffTimeOffRequests, schedules }, staff] = await Promise.all([
      loadTimeOffRequestsPromise,
      loadStaffPromise
    ])

    return updateTimeOff((timeOff) => {
      return timeOff
        .set('isSchedulesMapLoaded', true)
        .setIn(['staff', 'isStaffMapLoaded'], true)
        .updateIn(['staff', 'staffMap'], (staffMap) => _updateStaffMap(staffMap, staff))
        .updateIn(['schedulesMap'], (schedulesMap) => _updateSchedulesMap(schedulesMap, schedules))
        .updateIn(['timeOffRequests'], (timeOffRequestsData) =>
          _updateTimeOffRequestsData(timeOffRequestsData, unitId, startDate, endDate, staffTimeOffRequests)
        )
    })
  }

  function _updateStaffMap(staffMap, staff) {
    return staff.reduce((map, staff) => map.set(staff.userId, fromJS(staff)), staffMap)
  }

  function _updateSchedulesMap(schedulesMap, schedules) {
    return schedules.reverse().reduce((memo, schedule) => memo.set(schedule.id, fromJS(schedule)), schedulesMap)
  }

  function _updateTimeOffRequestsData(timeOffRequestsData, unitId, startDate, endDate, staffTimeOffRequests) {
    const { pageInfo, edges: timeOffRequests } = staffTimeOffRequests
    const timeOffRequestsMap = timeOffRequestsData.get('timeOffRequestsMap')

    const newTimeOffRequestsMap = timeOffRequests.reduce(
      (memo, request) => memo.set(request.id, fromJS(request)),
      timeOffRequestsMap
    )

    const newPageInfo = Map({
      unitId,
      startDate,
      endDate,
      ...pageInfo,
      isLoading: false,
      isLoaded: true
    })

    return timeOffRequestsData.set('pageInfo', newPageInfo).set('timeOffRequestsMap', newTimeOffRequestsMap)
  }
}

export default TimeOffRequests()
