import { PureComponent } from 'react'
import DateRangeService from 'services/DateRangeService'
import './SelectScheduleDuration.scss'

export default class SelectScheduleDuration extends PureComponent {
  state = { active: false }

  render() {
    const { activeDateRange } = this.props

    if (!activeDateRange.get('isReady')) {
      return null
    }

    const dateRangesWithDuration = this.getDateRangesWithDuration()

    return (
      <div className="hx-schedule-duration">
        <sh-button
          color="tertiary"
          id="edit-sched-len-btn"
          onClick={this.toggleDropdown}
          onBlur={this.hideDropdown}
          label="Edit Schedule Length"
          size="auto"
        />
        <sh-tooltip
          class="below-1400"
          label="Edit schedule length"
          target="edit-sched-len-btn"
          placement="bottom"
          variation="short"
        />

        <sh-popover label="Edit Schedule Length" target="edit-sched-len-btn" position="bottom">
          {dateRangesWithDuration.map(this.renderDateRangeWithDuration)}
        </sh-popover>
      </div>
    )
  }

  renderDateRangeWithDuration = (dateRangeWithDuration, key) => {
    const { activeDateRange } = this.props
    const activeDateRangeDuration = activeDateRange.get('duration')
    const dateRangeDuration = dateRangeWithDuration.get('duration')
    const isSelected = dateRangeDuration === activeDateRangeDuration
    const title = dateRangeWithDuration.get('title')
    const durationText = dateRangeWithDuration.get('durationText')

    return (
      <sh-menu-item
        key={key}
        active={!isSelected && undefined}
        label={title}
        // className={classNames({ disabled: isSelected })}
        onMouseDown={() => this.selectDuration(dateRangeDuration)}
      >
        {/* <span>{isSelected && <i className="icon-checkmark rock-blue" />}</span> */}
        <sh-text slot="functions" color="secondary">
          {durationText}
        </sh-text>
      </sh-menu-item>
    )
  }

  getDateRangesWithDuration = () => {
    const { activeDateRange, timeService } = this.props
    const durationsInWeeks = [2, 4, 6, 8]

    return durationsInWeeks.map((durationInWeeks) =>
      DateRangeService.buildDateRangeWithDuration(activeDateRange, timeService, durationInWeeks)
    )
  }

  toggleDropdown = () => {
    if (!this.props.isLoading) {
      this.setState((prevState, props) => ({
        active: !prevState.active
      }))
    }
  }

  hideDropdown = () => {
    this.setState({ active: false })
  }

  selectDuration = async (sizeInWeeks) => {
    const { generalStore, activeDateRange, timeService } = this.props
    const newActiveDateRange = DateRangeService.buildDateRangeWithDuration(activeDateRange, timeService, sizeInWeeks)

    this.props.setLoadingStatus(true)
    await generalStore.setActiveDateRange(newActiveDateRange)
    this.props.setLoadingStatus(false)
    this.hideDropdown()
  }
}
