import Layout from '../../../../Manager/Calendar/Layout'
import KpiList from '../KpiList/KpiList'
import { curry } from 'i18n'
import './CalendarView.scss'
import { ScheduleStatus } from '../constants'
const t = curry('calendar.autoSchedule.')

export default function CalendarView({ match, updateScheduleClick, algoScheduleId, isError, ...props }) {
  return (
    <>
      <div className="flex calendar-view-body">
        {!isError ? (
          <>
            <div className="_mainContainer autoschedule-view-container calendar-view-wrapper">
              <Layout match={match} {...props} calendarType="autoScheduleCalendar" />
            </div>
            <sh-divider spacing="s" vertical></sh-divider>
            <KpiList kpiResult={props.KpiResult} />
          </>
        ) : (
          <>
            <div className="_mainContainer autoschedule-view-container w100">
              <sh-empty-state icon="error" label={t('algoGenerationError')}></sh-empty-state>
            </div>
          </>
        )}
      </div>

      <sh-wrapper slot="footer" class="flex align-right absolute autoscheduler-footer">
        <sh-button
          id="reset-button"
          color="secondary"
          label="Reset Parameters"
          class="calendar-view-footer-button"
          onClick={() => updateScheduleClick(algoScheduleId, ScheduleStatus.REJECTED)}
          size="m"
        ></sh-button>
        {!isError && (
          <sh-button
            id="apply-schedule-button"
            label="Apply Schedule"
            class="calendar-view-footer-button ml10"
            size="m"
            onClick={() => updateScheduleClick(algoScheduleId, ScheduleStatus.ACCEPTED)}
          ></sh-button>
        )}
      </sh-wrapper>
    </>
  )
}
