import GraphQLError from '@humanics/he-react-common/lib/entityWrappers/GraphQLError'
import RequestError from '@humanics/he-react-common/lib/entityWrappers/RequestError'
import errorTypesToIgnore from './errorTypesToIgnore'

export default class IgnoreEventValidator {
  constructor(event, hint) {
    this.event = event
    this.hint = hint
  }

  isEventInIgnoreList() {
    const { event, hint } = this
    const ignoreList = [this._ignoreExpectedError]
    return ignoreList.some((shouldIgnoreError) => shouldIgnoreError(event, hint))
  }

  _ignoreExpectedError = (event) => {
    const { extra = {} } = event
    const { originalError: error = {} } = extra

    const isGraphQLError = GraphQLError.isGraphQLError(error)
    if (isGraphQLError) {
      return IgnoreEventValidator.shouldIgnoreGraphQLError(error)
    }

    const isFetchError = RequestError.isFetchError(error)
    if (isFetchError) {
      return IgnoreEventValidator.shouldIgnoreFetchError(error)
    }

    return false
  }

  static shouldIgnoreFetchError = (error) => {
    const typedError = RequestError.parseFetchError(error)
    return IgnoreEventValidator.shouldIgnoreTypedError(typedError)
  }

  static shouldIgnoreGraphQLError = (error) => {
    const typedErrors = GraphQLError.getErrors(error)
    return IgnoreEventValidator.shouldIgnoreTypedErrors(typedErrors)
  }

  static shouldIgnoreTypedErrors = (errors) =>
    errors.every((error) => IgnoreEventValidator.shouldIgnoreTypedError(error))

  static shouldIgnoreTypedError = (error) => {
    const { type, code } = error
    const shouldIgnoreError = errorTypesToIgnore[type]
    return shouldIgnoreError?.(code)
  }
}
