import { Component } from 'react'
import { chunk } from 'lodash'
import Cell from './Cell'
import classNames from 'classnames'
import { Map } from 'immutable'

export default class StaffShiftDays extends Component {
  onMouseLeave = (event) => this.onCellEvent(event, 'onMouseLeave')
  onMouseOver = (event) => this.onCellEvent(event, 'onMouseOver')
  onContextMenu = (event) => this.onCellEvent(event, 'onContextMenu')
  onClick = (event) => this.onCellEvent(event, 'onClick')

  onDoubleClick = (event) => {
    return this.onCellEvent(event, 'onDoubleClick')
  }

  onCellEvent = (event, eventType) => {
    // instead of `e.target.dataset` because IE
    if (!event.target.parentElement.hasAttribute('data-cell-index')) {
      return
    }
    event.preventDefault()
    const identityHash = event.target.parentElement.getAttribute('data-cell-identity-hash')
    const cellIndex = parseInt(event.target.parentElement.getAttribute('data-cell-index'))
    const { target, shiftKey, metaKey, ctrlKey } = event
    const eventMeta = {
      eventTarget: target.parentElement,
      isShiftKeyPressed: shiftKey,
      isMetaKeyPressed: metaKey,
      isCtrlKeyPressed: ctrlKey
    }
    const meta = { eventMeta, identityHash }
    this.props.onCellEvent(cellIndex, eventType, meta)
  }

  renderCell(isShiftLoading, cells, weekIndex, dayIndex, day, updateCellTooltip, staffPath, style) {
    const {
      showPopup,
      staff,
      cellsPathsInProcessing,
      shiftTime,
      indicators,
      facilityShiftsMap,
      notes,
      timeService,
      activeDateRange
    } = this.props
    const leftOffset = timeService.timeMoment(activeDateRange.get('startsAt')).day()
    const unitId = activeDateRange.get('unitId')
    let cellIndex = weekIndex * 7 + dayIndex - leftOffset
    if (cellIndex < 0 || cellIndex >= cells?.size) {
      cellIndex = undefined
    }

    // eslint-disable-next-line eqeqeq
    let cell = (cellIndex != undefined && cells?.get?.(cellIndex)) || new Map() // cellIndex && ... won't work since cellIndex can be zero
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')
    const roleIndex = staff.get('roleIndex')
    const cellKey = `${roleIndex}|${shiftIndex}|${staffIndex}|${cellIndex}`
    const isProcessing = cellsPathsInProcessing?.contains?.(cellKey)
    if (isProcessing) {
      cell = cell.set('isProcessing', isProcessing)
    }
    const { selection } = this.props
    const isCellSelected = selection?.getIn?.(['cells', cellIndex])
    if (isCellSelected) {
      const cells = selection.get('cells')
      const isPreviousCellSelected = cells.get(cellIndex - 1)
      const isNextCellSelected = cells.get(cellIndex + 1)
      const lastSelectedCellIndex = cells.findLastKey(() => true)
      const isLastSelectedCell = lastSelectedCellIndex === cellIndex
      cell = cell.set('isSelected', true)
      if (isPreviousCellSelected) {
        cell = cell.set('isPreviousSelected', true)
      }
      if (isNextCellSelected) {
        cell = cell.set('isNextSelected', true)
      }
      if (isLastSelectedCell) {
        cell = cell.set('isLastSelectedCell', true)
      }
    }
    const isMultipleCellsSelected = selection && selection.get('cells').size > 1
    const cellProps = {
      cell,
      day,
      indicators,
      isMultipleCellsSelected,
      cellIndex,
      updateCellTooltip: updateCellTooltip,
      staffPath,
      showPopup,
      shiftTime,
      facilityShiftsMap,
      notes,
      rowStyle: style,
      unitId
    }

    return <Cell key={dayIndex} {...cellProps} />
  }

  render() {
    const { activeDateRange, staff, updateCellTooltip, staffPath, style } = this.props
    const cells = staff.get('cells')
    const days = activeDateRange.get('days')
    const isShiftLoading = staff.get('isShiftLoading')

    const weeks = chunk(days, 7)
    const weeksCount = weeks.length
    const width = `${100 / weeksCount}%`

    return (
      <section
        className={classNames('row bl4 bt1 col-10', { loading: isShiftLoading })}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        onDoubleClick={this.onDoubleClick}
        onContextMenu={this.onContextMenu}
      >
        {!isShiftLoading &&
          weeks.map((days, weekIndex) => (
            <div key={weekIndex} className="br4 _weekCells bg-white row-7" style={{ width }}>
              {days.map((day, dayIndex) =>
                this.renderCell(isShiftLoading, cells, weekIndex, dayIndex, day, updateCellTooltip, staffPath, style)
              )}
            </div>
          ))}
      </section>
    )
  }
}
