import { Component } from 'react'

export default class Invitation extends Component {
  render() {
    const { facilityUser, facilityUsersStore, authUserId } = this.props
    const { isInvitationSent, isInvitationAccepted, hasPermissions, userId } = facilityUser
    const props = { facilityUsersStore, userId }

    if (isInvitationAccepted || hasPermissions) {
      const isCurrentUser = authUserId === userId
      return isCurrentUser ? null : <RevokePermissions {...props} />
    }

    if (isInvitationSent && !isInvitationAccepted) {
      return <RevokeInvitations {...props} />
    }

    return <InvitationMethod {...props} />
  }
}

function RevokeInvitations({ facilityUsersStore, userId }) {
  const onRevoke = (e) => {
    e.stopPropagation()

    if (global.confirm('Are you sure you wish to revoke invitation?')) {
      facilityUsersStore.revokeFacilityUserInvitations({ userId })
    }
  }

  return (
    <span className="col-10">
      <span className="button primary tiny danger" onClick={onRevoke}>
        Revoke Invitations
      </span>
    </span>
  )
}

function RevokePermissions({ facilityUsersStore, userId }) {
  const onRevoke = (e) => {
    e.stopPropagation()

    if (global.confirm('Are you sure you wish to revoke user permissions?')) {
      facilityUsersStore.revokePermissions({ userId })
    }
  }

  return (
    <span className="col-10">
      <span className="button primary tiny danger w100" onClick={onRevoke}>
        Revoke Permissions
      </span>
    </span>
  )
}

function InvitationMethod({ facilityUsersStore, ...parameters }) {
  const onInviteClick = (e) => {
    e.stopPropagation()
    return facilityUsersStore.inviteFacilityUser({ parameters })
  }

  return (
    <span className="col-10">
      <span className="button primary tiny w100 text-center" onClick={onInviteClick}>
        Invite
      </span>
    </span>
  )
}
