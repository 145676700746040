import { useEffect, useState } from 'react'
import { List } from 'immutable'
import ScheduleMetadata from './ScheduleMetadata'

export default function SchedulesProgressCard({ timeService, unit, generalStore }) {
  const [isLoading, setIsLoading] = useState(true)
  const schedules = unit.get('schedules')
  const isSchedulesReady = unit.get('isSchedulesLoaded')
  const unitId = unit.get('id')

  useEffect(() => {
    if (!unitId) {
      return
    }

    setIsLoading(true)

    if (!isSchedulesReady) {
      return
    }

    const scheduleIds = schedules?.map((schedule) => schedule.get('id')).toJS()

    if (!scheduleIds?.length) {
      setIsLoading(false)
      return
    }

    generalStore.loadSchedulesMetadata(scheduleIds).then((data) => {
      setIsLoading(false)
    })
  }, [schedules, generalStore, isSchedulesReady, unitId])

  const scheduleMetadatas = !isLoading
    ? unit
        .get('schedulesMetadataMap')
        ?.toList()
        .filter(
          (scheduleMetadata) =>
            scheduleMetadata.get('state') === 'balancing' || scheduleMetadata.get('state') === 'open'
        )
        .sortBy((scheduleMetadata) => scheduleMetadata.get('startsAt')) || List()
    : List()

  const evenIndexedScheduleMetadataComponents = []
  const oddIndexedScheduleMetadataComponents = []
  scheduleMetadatas.forEach((scheduleMetadata, i) => {
    let containerClassName = 'ml10 mr10'
    if (i !== 0 && i !== 1) {
      containerClassName += ' mt10'
    }
    const scheduleMetadataComponent = (
      <div className={containerClassName} key={scheduleMetadata.get('id')}>
        <ScheduleMetadata scheduleMetadata={scheduleMetadata} timeService={timeService} />
      </div>
    )

    if (i % 2 === 0) {
      evenIndexedScheduleMetadataComponents.push(scheduleMetadataComponent)
    } else {
      oddIndexedScheduleMetadataComponents.push(scheduleMetadataComponent)
    }
  })

  return (
    <div className="todo-common-card">
      <div className="hx-card-header">
        <div className="icon-box p10">
          <div className="icon-calendar"></div>
        </div>
        <div className="card-heading">Schedules in Progress</div>
      </div>
      <div className="row" style={{ marginTop: '10px', height: '100%' }}>
        {isLoading ? (
          <div style={{ margin: 'auto', padding: '16px' }}>
            <sh-spinner></sh-spinner>
          </div>
        ) : !evenIndexedScheduleMetadataComponents?.length && !oddIndexedScheduleMetadataComponents?.length ? (
          <sh-empty-state
            style={{ marginTop: '-32px' }}
            icon="patient-scheduler"
            label="No schedules in progress"
          ></sh-empty-state>
        ) : (
          <>
            <div className="col-6">{evenIndexedScheduleMetadataComponents}</div>
            <div className="col-6">{oddIndexedScheduleMetadataComponents}</div>
          </>
        )}
      </div>
    </div>
  )
}
