export default function Item({
  title,
  onClick,
  onMouseEnter,
  className = '',
  hasSubmenu = false,
  iconClassName = false
}) {
  className = `context__item ${className}`

  return (
    <div className={className} role="presentation" onClick={onClick}>
      <div className="context__item__data" onMouseEnter={onMouseEnter}>
        {iconClassName && <i className={iconClassName} />}
        {title}
        {hasSubmenu && (
          <span className="context__submenu-arrow">
            <i className="icon-right rock-blue" />
          </span>
        )}
      </div>
    </div>
  )
}
