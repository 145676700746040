import { fromJS } from 'immutable'

const employmentTypeMap = {
  fullTime: 'Full Time',
  partTime: 'Part Time',
  perDiem: 'Per Diem'
}

export default function extendFacilityUser(facilityUser, data) {
  const { instanceUri } = data

  facilityUser = updateFacilityUserAvatarUrl(instanceUri, facilityUser)

  const { preferences } = facilityUser.staffProfile
  const extendedPreferences = extendPreferences(preferences || [])
  const { employmentType } = facilityUser.staffProfile

  facilityUser.staffProfile.extendedPreferences = extendedPreferences
  facilityUser.staffProfile.employmentTypeLabel = employmentTypeMap[employmentType] || ''

  return fromJS(facilityUser)
}

function updateFacilityUserAvatarUrl(instanceUri, facilityUser) {
  const url = facilityUser.profile.avatarUrl
  const isHostIncluded = url?.startsWith('http')

  if (!isHostIncluded && url && url.length > 0) {
    const avatarUrl = `${instanceUri}/${url}`
    facilityUser.profile.avatarUrl = avatarUrl
  }

  return facilityUser
}

const FAVORITE_DAY_OF_WEEK = [
  'willingToWorkSundays',
  'willingToWorkMondays',
  'willingToWorkTuesdays',
  'willingToWorkWednesdays',
  'willingToWorkThursdays',
  'willingToWorkFridays',
  'willingToWorkSaturdays'
]

function extendPreferences(preferences) {
  const initialPreferences = {
    isDayShiftPreferred: false,
    isNightShiftPreferred: false,
    isOvertimePreferred: false,
    preferedDayIndexes: []
  }

  return preferences.reduce((preferences, preference) => {
    const { name, value } = preference

    switch (name) {
      case 'willingToWorkDays':
        preferences.isDayShiftPreferred = value
        break

      case 'willingToWorkNights':
        preferences.isNightShiftPreferred = value
        break

      case 'willingToWorkOvertime':
        preferences.isOvertimePreferred = value
        break

      default:
        if (FAVORITE_DAY_OF_WEEK.includes(name) && value) {
          const dayIndex = FAVORITE_DAY_OF_WEEK.indexOf(name)
          preferences.preferedDayIndexes.push(dayIndex)
        }
        break
    }

    return preferences
  }, initialPreferences)
}
