import { Component } from 'react'
import './LinkPreviewComponent.scss'
import EditButtons from './EditButtons/EditButtons'
import NavigationBar from './NavigationBar'

export default class LinkPreviewComponent extends Component {
  state = {
    isHovering: false
  }
  onHover = () => {
    this.setState({ isHovering: !this.state.isHovering })
  }

  getWebAddress() {
    return this.props.webAddress
  }

  render() {
    const { component, activeComponent, hasDetailsPage, isFormEditable, linkTitle } = this.props

    const {
      insertDetailsComponentAbove,
      insertDetailsComponentBelow,
      deleteDetailsComponent,
      onDetailsComponentClicked
    } = this.props

    const extendedPreviewLinkStyle = {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px'
    }

    const linkBoxStyle = {
      width: '396px',
      height: '38px',
      borderRadius: '5px',
      backgroundColor: '#4a90e2',
      margin: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      fontSize: 0
    }

    const linkTextStyle = {
      fontSize: '16px',
      fontWeight: '8px',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1',
      letterSpacing: '-0.1px',
      textAlign: 'center',
      color: '#ffffff',
      margin: '0',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      marginTop: '0px',
      textDecoration: 'none'
    }

    return (
      // adds highlight grey background behind selected component
      <div
        className={
          component.id === activeComponent.id && isFormEditable
            ? 'extended-preview-link'
            : 'extended-preview-link-unclicked'
        }
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        {/* Blue navigation bar on the left */}
        {component.id === activeComponent.id && isFormEditable && <NavigationBar />}

        {/* Link div */}
        <div onClick={onDetailsComponentClicked} style={extendedPreviewLinkStyle}>
          <a
            className="link-box"
            style={linkBoxStyle}
            href={this.getWebAddress()}
            target="_blank"
            rel="noreferrer noopener"
          >
            Link Button
          </a>
          <a
            className="link-text"
            style={linkTextStyle}
            href={this.getWebAddress()}
            target="_blank"
            rel="noreferrer noopener"
          >
            {linkTitle}
          </a>
        </div>

        {/* Buttons */}
        {this.state.isHovering && hasDetailsPage && isFormEditable && (
          <div>
            <EditButtons
              insertDetailsComponentAbove={insertDetailsComponentAbove}
              insertDetailsComponentBelow={insertDetailsComponentBelow}
              deleteDetailsComponent={deleteDetailsComponent}
            />
          </div>
        )}
      </div>
    )
  }
}
