import { Map } from 'immutable'
import { OpenShiftsService } from 'services'

export default function makeSummary(openShifts) {
  const totalOpenings = OpenShiftsService.getTotalOpenings(openShifts)
  const filled = openShifts.count((openShift) => openShift.get('isCompleted'))
  const notFilled = openShifts.size - filled

  return Map({
    totalOpenings,
    filled,
    notFilled,
    all: filled + notFilled
  })
}
