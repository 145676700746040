import useShuiEventHook from './customHooks/shuiEventHook'

import { useCallback } from 'react'
import { formatAsDate } from '../utils'
import { ActivityTracker } from './activityTracker'

export interface IDatePickerProps {
  date: string
  setDate: (date: string) => void
  timeService: any
  trackActivity?: boolean
}

const DatePicker = ({ date, setDate, timeService, trackActivity = false }: IDatePickerProps) => {
  const dateValue = formatAsDate(date, timeService)
  const handleDateChange = useCallback(
    (e: any) => {
      const updatedDate = e?.target?.value

      if (!updatedDate) {
        setDate('')
        return
      }

      const [month, day, year] = updatedDate.split('/')
      const newDate = `${year}-${month}-${day}`
      setDate(newDate)
    },
    [setDate]
  )

  const handleClick = () => {
    if (trackActivity) {
      ActivityTracker.StartTracking()
    }
  }

  const eventName = 'value-changed'
  const datePickerRef = useShuiEventHook(eventName, handleDateChange)

  return (
    <sh-datepicker
      label="Date"
      ref={datePickerRef}
      past-date-disable
      value={dateValue}
      onClick={handleClick}
    ></sh-datepicker>
  )
}

export default DatePicker
