import { Component } from 'react'

export default class TextInput extends Component {
  renderCounter(maxLength, currentCount) {
    return maxLength - currentCount
  }

  render() {
    const { value, name, label, required, multiLine, maxLength, onChange } = this.props
    const counter = !!maxLength && <div className="counter gray"> {this.renderCounter(maxLength, value.length)} </div>

    if (multiLine) {
      return (
        <div className="row">
          <label className="form-inline">
            {label.split('\n').map((i, key) => {
              return <div key={key}>{i}</div>
            })}
          </label>
          <div className="row inputBoxAndCount">
            <textarea value={value} className="form-inline" onChange={onChange} name={name} maxLength={maxLength} />

            {counter}
          </div>
        </div>
      )
    } else if (!required) {
      return (
        <div className="row inputBoxAndCount">
          <input
            value={value}
            className="form-inline"
            type="text"
            onChange={onChange}
            name={name}
            maxLength={maxLength}
          />

          {counter}
        </div>
      )
    } else {
      return (
        <div className="row">
          <label className="form-inline">{label}</label>
          <div className="row inputBoxAndCount">
            <input
              className="form-inline subject"
              type="text"
              value={value}
              onChange={onChange}
              name={name}
              maxLength={maxLength}
            />

            {counter}
          </div>
        </div>
      )
    }
  }
}
