import { useEffect, useState } from 'react'
import './Footer.scss'
import { t } from 'i18n'

export type FooterProps = {
  isSubcategoryEmpty: boolean,
  isSelectedCardsEmpty: boolean,
  isAnyPartialDay: boolean,
  isAnyPartialDayDurationSelected: boolean,
  handleSubmitButtonClick: () => void
  handleClearButtonClick: () => void
}

const Footer = ({ handleClearButtonClick, handleSubmitButtonClick, isSubcategoryEmpty, isSelectedCardsEmpty, isAnyPartialDay, isAnyPartialDayDurationSelected }: FooterProps) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    if (isSubcategoryEmpty || isSelectedCardsEmpty || (isAnyPartialDay && !isAnyPartialDayDurationSelected)) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  }, [isSubcategoryEmpty, isSelectedCardsEmpty, isAnyPartialDay, isAnyPartialDayDurationSelected]);

  return (
    <div className="request-time-off-footer bg-white mt10">
      <div>
        <sh-button
          label={`${t('staffCalendar.timeOff.actionButtons.clearTimeOff')}`}
          color="secondary"
          size="m"
          onClick={handleClearButtonClick}
        />
      </div>
      <div className="btn-submit-time-off-request">
        <sh-button
          label={`${t('staffCalendar.timeOff.actionButtons.requestTimeOff')}`}
          color="primary"
          size="m"
          disabled={isSubmitButtonDisabled ? true : undefined}
          onClick={handleSubmitButtonClick}
        />
      </div>
    </div>
  )
}

export default Footer