import { FacilityUserShiftsService } from 'services'
import {
  buildPTOCell,
  buildStaffEventCell,
  buildUnavailabilityCell,
  cellWithEmptyEvent,
  updateStaffEventCell
} from './DummyCell'

export default class DummyCellsBuilder {
  constructor(timeService) {
    this.timeService = timeService
  }

  buildDummyCells(action, { cells, days, shift, staff }, data) {
    let cellNumber = 0
    let eligibleUnits
    if (data.addStaffToOnCallShift) {
      eligibleUnits = data.staff.getIn(['staffProfile', 'eligibleUnits'])
    } else {
      eligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits'])
    }

    return cells.map((cell, index) => {
      const day = days.get(index)

      return this._buildDummyCell(cellNumber++, cell, shift, day, action, {
        ...data,
        eligibleUnits
      })
    })
  }

  _buildDummyCell = (cellNumber, originalCell, selectedShift, day, action, data) => {
    const isDeleteAction = action === 'delete'
    if (isDeleteAction) {
      return this._buildDeletedDummyCell(originalCell)
    }

    const isCreateAction = action === 'create'
    if (isCreateAction) {
      return this._buildNewDummyCell(cellNumber, originalCell, selectedShift, day, data)
    }

    const isChangeAction = action === 'change'
    if (isChangeAction) {
      return this._buildChangedDummyCell(cellNumber, originalCell, selectedShift, day, data)
    }

    const isUpdateAction = action === 'update'
    if (isUpdateAction) {
      return this._buildUpdatedDummyCell(cellNumber, originalCell, data)
    }
  }

  _buildDeletedDummyCell(originalCell) {
    return originalCell.merge(cellWithEmptyEvent)
  }

  _buildNewDummyCell(cellNumber, originalCell, selectedShift, day, data) {
    const { isTimeOff } = data

    if (isTimeOff) {
      return this._buildNewTimeOffDummyCell(originalCell, data)
    } else {
      return this._buildNewStaffEventDummyCell(cellNumber, originalCell, selectedShift, day, data)
    }
  }

  _buildChangedDummyCell(cellNumber, originalCell, selectedShift, day, data) {
    const { isTimeOff, isStaffPaidTimeOff, staffEvents, note } = data

    if (isTimeOff) {
      const staffEvent = staffEvents[0]
      const { shiftId } = staffEvent

      if (isStaffPaidTimeOff) {
        return originalCell.merge(buildPTOCell(note, shiftId))
      } else {
        return originalCell.merge(buildUnavailabilityCell(note, shiftId))
      }
    } else {
      return this._buildNewStaffEventDummyCell(cellNumber, originalCell, selectedShift, day, data)
    }
  }

  _buildNewTimeOffDummyCell(originalCell, data) {
    const { isStaffPaidTimeOff, isRequestOff, staffEvents, note } = data
    const staffEvent = staffEvents[0]
    const { shiftId } = staffEvent

    if (isStaffPaidTimeOff) {
      return originalCell.merge(buildPTOCell(note, shiftId))
    }

    if (isRequestOff) {
      return originalCell.merge(buildUnavailabilityCell(note, shiftId))
    }
  }

  _buildNewStaffEventDummyCell(cellNumber, originalCell, selectedShift, day, data) {
    const { timeService } = this
    const { staffEventShiftId, staffEvents, note, eligibleUnits, shifts, isMeeting, isOnCall, isAssignment } = data
    const staffEvent = staffEvents[cellNumber]
    const dateTime = day.get('dateTime')

    const facilityUserShiftsService = new FacilityUserShiftsService(timeService)
    const primaryShiftId = facilityUserShiftsService.getPrimaryShiftId(eligibleUnits, dateTime, shifts)

    const isEventFromPrimaryShift = staffEventShiftId === primaryShiftId
    const isPrimaryShift = isEventFromPrimaryShift

    return originalCell.merge(
      buildStaffEventCell({
        selectedShift,
        isPrimaryShift,
        originalCell,
        timeService,
        staffEvent,
        staffEventShiftId,
        note,
        isMeeting,
        isOnCall,
        isAssignment
      })
    )
  }

  _buildUpdatedDummyCell(cellNumber, originalCell, data) {
    const { timeService } = this
    const { staffEvents, newNote, updatedNotes } = data
    const staffEvent = staffEvents[cellNumber]

    return originalCell.merge(updateStaffEventCell(originalCell, timeService, staffEvent, newNote, updatedNotes))
  }
}
