import { Component } from 'react'
import OpenShiftsHeader from './OpenShiftsHeader'
import OpenShiftCards from './OpenShiftCards'
import './OpenShiftsHeader.scss'
import { withAppContext } from 'AppContext'

class OpenShifts extends Component {
  render() {
    const { unit, openShiftsState, openShiftsStore, facilityUsersMap, timeService, onChangeFilters, config } =
      this.props

    const openShiftsMap = openShiftsState.get('openShiftsMap')
    const sortOrder = openShiftsState.get('sortOrder')
    const sortBy = openShiftsState.get('sortBy')
    const unitRoles = unit.get('roles')

    const todayOpenShifts = openShiftsMap.get('todayOpenShifts')
    const tomorrowOpenShifts = openShiftsMap.get('tomorrowOpenShifts')
    const restOpenShifts = openShiftsMap.get('restOpenShifts')
    const rawRoles = openShiftsMap.get('rawRoles')
    const acceptanceRate = openShiftsMap.get('acceptanceRate')
    const totalOpenShifts = openShiftsMap.get('totalOpenShifts')
    const totalTodayOpenShifts = openShiftsMap.get('totalTodayOpenShifts')
    const totalShiftOpportunities = openShiftsMap.get('totalShiftOpportunities')
    const isLoading = !todayOpenShifts || !tomorrowOpenShifts || !restOpenShifts || !rawRoles

    if (isLoading) {
      return null
    }

    const openShiftsProps = {
      unitRoles,
      sortBy,
      sortOrder,
      openShiftsMap,
      openShiftsStore,
      facilityUsersMap,
      todayOpenShifts,
      acceptanceRate,
      totalOpenShifts,
      totalTodayOpenShifts,
      totalShiftOpportunities,
      tomorrowOpenShifts,
      restOpenShifts,
      timeService
    }

    return (
      <div className="w100">
        <OpenShiftsHeader
          roles={rawRoles}
          onChangeFilters={onChangeFilters}
          config={config}
          timeService={timeService}
        />
        <OpenShiftCards {...openShiftsProps} />
      </div>
    )
  }
}

export default withAppContext(OpenShifts)
