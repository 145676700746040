import { navigate, paths } from 'Navigation'
import { CalendarItemIcon, VerticalNavBar } from './Common'
import { matchPath, useLocation } from 'react-router'

export function StaffSidebar({ timeService, urlId }: any) {
  const { pathname } = useLocation()
  let { date, section }: any = matchPath(pathname, paths.StaffCalendarPage)?.params || {}
  if (!date) {
    date = timeService.todayUsDate
  }
  const navItems = [
    {
      id: 'shiftsAssigned',
      label: 'Shift Assignments',
      iconComponent: <CalendarItemIcon calendarItemType="assignment" size="s" />,
      path: navigate.to.StaffCalendarPage({ section: 'shifts-assigned', urlId, date }, false),
      active: section === 'shifts-assigned'
    },
    {
      id: 'shiftSwaps',
      label: 'Shift Swaps',
      iconComponent: <CalendarItemIcon calendarItemType="shiftSwap" size="s" />,
      path: navigate.to.StaffCalendarPage({ section: 'shift-swaps', urlId, date }, false),
      active: section === 'shift-swaps'
    },
    {
      id: 'timeOffs',
      label: 'Time Offs',
      iconComponent: <CalendarItemIcon calendarItemType="timeOff" size="s" />,
      path: navigate.to.StaffCalendarPage({ section: 'time-offs', urlId, date }, false),
      active: section === 'time-offs'
    }
  ]
  return <VerticalNavBar navItems={navItems} />
}
