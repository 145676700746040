import { PureComponent } from 'react'
import CreateSchedules from './CreateSchedules'
import UpdateScheduleState from './UpdateScheduleState'
import { pick } from 'lodash'

export default class ScheduleWorkflow extends PureComponent {
  render() {
    const createSchedulesProps = pick(this.props, ['activeDateRange', 'generalStore', 'timeService', 'unit'])

    const updateScheduleStateProps = pick(this.props, ['activeDateRange', 'generalStore', 'sendSchedulePdf'])

    return (
      <aside className="_scheduleState float-right p10 pv0">
        <CreateSchedules {...createSchedulesProps} />
        <UpdateScheduleState {...updateScheduleStateProps} />
      </aside>
    )
  }
}
