import { Component } from 'react'
import classNames from 'classnames'
import Invitation from './Invitation'
import { navigate } from 'Navigation'
import { SpillProofDiv } from 'Common/components'
import './FacilityUserRow.scss'

export default class FacilityUserRow extends Component {
  render() {
    const { facilityUser, timeService, facilityUsersStore, authUserId } = this.props
    const { userId, profile, updatedAt, homeUnitName, hasPermissions } = facilityUser
    const { firstName, lastName, email } = profile
    const fullName = `${firstName} ${lastName}`
    const updatedAtFormatted = timeService.timeMoment(updatedAt).format('MMM D YYYY, h:mm')
    const invitationParams = { facilityUsersStore, facilityUser, authUserId }

    return (
      <div className="hx-facility-users-row row cursor-pointer" onClick={this.openUpdateModal(userId)}>
        <SpillProofDiv id={`${userId}-name`} tooltipPosition="top-right" className="col-2 p10">
          {fullName}
        </SpillProofDiv>
        <SpillProofDiv id={`${userId}-email`} tooltipPosition="top-right" className="col-2 p10 pl0">
          {email}
        </SpillProofDiv>
        <SpillProofDiv id={`${userId}-homeUnit`} tooltipPosition="top-right" className="col-2 p10 pl0">
          {homeUnitName}
        </SpillProofDiv>
        <SpillProofDiv id={`${userId}-updatedAt`} tooltipPosition="top-right" className="col-2 p10 pl0">
          {updatedAtFormatted}
        </SpillProofDiv>
        <div className="col-1 p10 pl0">{this.renderHasPermissions(hasPermissions)}</div>
        <div className="col-1 p10 pl0">{this.renderStatus(facilityUser)}</div>
        <div className="col-2 p10 pl0">
          <div className="row w70">
            <Invitation {...invitationParams} />
          </div>
        </div>
      </div>
    )
  }

  renderHasPermissions(hasPermissions) {
    const hasPermissionsText = hasPermissions ? 'Yes' : 'No'
    const className = classNames('label white rounded _hasPermissions', {
      'bg-ufo-green': hasPermissions,
      warning: !hasPermissions
    })

    return <span className={className}>{hasPermissionsText}</span>
  }

  renderStatus({ isInvitationSent, isInvitationAccepted }) {
    if (isInvitationSent && !isInvitationAccepted) {
      return <span className="label warning white rounded _invitationStatus">Invited</span>
    }

    if (isInvitationAccepted) {
      return <span className="label bg-ufo-green white rounded _invitationStatus">Accepted</span>
    }

    return null
  }

  openUpdateModal = (userId) => (e) => navigate.from.AdminStaff.to.AdminStaffUpdate({ id: userId })
}
