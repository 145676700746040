import { PureComponent, createRef } from 'react'
import { Map } from 'immutable'
import { sideGlyphMap } from 'Manager/components/CellIconMap'
import './Cell.scss'
import { isNil } from 'lodash'
import { getClassName, getLeftColumn, getMiddleColumn } from '../../../../../../../Calendar/Grid/VStaff/utils'

export default class Cell extends PureComponent {
  ref = createRef()

  componentDidUpdate(prevProps) {
    const { showPopup, cell, isMultipleCellsSelected } = this.props
    const { cell: prevCell, isMultipleCellsSelected: prevIsMultipleCellsSelected } = prevProps

    const isLastSelectedCell = cell.get('isLastSelectedCell')
    const prevIsLastSelectedCell = prevCell?.get?.('isLastSelectedCell')
    const isCellSelectionChanged = isLastSelectedCell !== prevIsLastSelectedCell
    const isCellsSelectionChanged = isMultipleCellsSelected !== prevIsMultipleCellsSelected
    const isSelectionChanged = isCellsSelectionChanged || isCellSelectionChanged

    if (isSelectionChanged && isLastSelectedCell) {
      showPopup(this.ref.current)
    }
  }

  render() {
    const { cell = Map(), cellIndex, day = {}, indicators, facilityShiftsMap, notes, rowStyle, unitId } = this.props
    const identityHash = cell.get('staffEvents')?.get(0)?.get('identityHash') || 'empty'
    const content = getContent(cell, facilityShiftsMap, indicators, notes, unitId)

    const isCellDisabled = isNil(cellIndex)
    const className = getClassName(cell, day, isCellDisabled)

    const isProcessing = cell.get('isProcessing')

    return (
      <div
        data-cell-index={cellIndex}
        data-cell-identity-hash={identityHash}
        className={`staff-details ${className}`}
        ref={this.ref}
        tabIndex={0}
        style={{ height: `${rowStyle?.height}px` }}
      >
        {isProcessing && <i className="loading spindle" />}
        {content}
      </div>
    )
  }
}

function getContent(cell, facilityShiftsMap, indicators = Map({}), notes = Map(), unitId) {
  if (!cell?.size || cell.get('isProcessing')) {
    return null
  }

  const middleColumn = getMiddleColumn(cell, facilityShiftsMap, false, notes, unitId)
  const rightColumn = getRightColumn(cell, indicators)
  const leftColumn = getLeftColumn(cell, indicators, false)

  return (
    <>
      <div style={{ display: 'flex' }}>
        {leftColumn}
        {middleColumn}
        {rightColumn}
      </div>
    </>
  )
}

function getRightColumn(cell, indicators) {
  const rightIcons = getRightIcons(cell, indicators)
  return <span>{rightIcons}</span>
}

function getRightIcons(cell, indicators) {
  const isInfo = indicators.get('info') && cell.get('isExpertiseMismatchEvent')
  const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()

  let rightIcons = ''

  if (cell.get('isRequestedDayOff') && indicators.get('unavailable')) {
    rightIcons = 'timeoff'
  }

  if (isInfo) {
    rightIcons = 'info'
  }

  if (cellStaffEvent.get('hasNotes') && indicators.get('noteOrExplanation')) {
    if (rightIcons) {
      rightIcons += '+'
    }
    rightIcons += 'note'
  }

  return sideGlyphMap[rightIcons || 'gap']
}
