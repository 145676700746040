import { Map } from 'immutable'
import StateController from './StateController'

export default class TooltipController extends StateController {
  get defaultState() {
    return { tooltipConfig: Map() }
  }

  showTooltip(staffPath, cell, targetElement) {
    this.setState({ tooltipConfig: Map({ staffPath, cell, targetElement }) })
  }

  hideTooltip() {
    this.setState(this.defaultState)
  }
}
