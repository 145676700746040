import { Component } from 'react'
import InconsistencyWarningTooltip from './InconsistencyWarningTooltip'
import StaffEventInfoTooltip from './StaffEventInfoTooltip'
import './CellTooltip.scss'

export default class CellTooltip extends Component {
  render() {
    const {
      isCellDetailsVisible,
      isCellContextMenuVisible,
      cellTooltipConfig,
      isCellWithSelectionError,
      notes,
      unitShiftsMap,
      facilityShiftsMap,
      staffManagersMap,
      staffHoursMap,
      facilityUsersMap,
      otherStaffMap
    } = this.props

    if (isCellContextMenuVisible || isCellDetailsVisible || !cellTooltipConfig) {
      return null
    }

    const { staffPath, cellIndex, meta, cell, shiftDay } = cellTooltipConfig

    if (!meta) {
      return null
    }

    const {
      eventMeta: { eventTarget }
    } = meta
    const cellPath = { ...staffPath, cellIndex }
    const cellPosition = eventTarget?.getBoundingClientRect()
    const hasSelectionError = isCellWithSelectionError(cellPath)

    const inconsistencyWarningTooltipProps = {
      cellPosition,
      hasSelectionError
    }

    const staffEventInfoTooltipProps = {
      cellPosition,
      cell,
      shiftDay,
      staffPath,
      cellIndex,
      notes,
      unitShiftsMap,
      facilityShiftsMap,
      staffManagersMap,
      hasSelectionError,
      staffHoursMap,
      facilityUsersMap,
      otherStaffMap,
      meta
    }

    return (
      <>
        <InconsistencyWarningTooltip {...inconsistencyWarningTooltipProps} />
        <StaffEventInfoTooltip {...staffEventInfoTooltipProps} />
      </>
    )
  }

  componentDidMount() {
    window.addEventListener('scroll', this.hide)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.hide)
  }
}
