import { Component } from 'react'

import CertificationFormController from './CertificationFormController'
import CreateEditExpertiseModal from '../CreateEditExpertiseModal'

class CreateEditCertificationModal extends Component {
  render() {
    const modalProps = {
      ...this.props,
      editPathKey: 'StaffEditCertification',
      createPathKey: 'StaffCreateCertification',
      expertiseType: 'certification',
      FormController: CertificationFormController
    }

    return <CreateEditExpertiseModal {...modalProps} />
  }
}

export default CreateEditCertificationModal
