import { ShiftService } from 'services'

export default class EventTemplate {
  constructor(unitId, eventVariantId, startsAt, endsAt, shiftId) {
    this._unitId = unitId
    this._eventVariantId = eventVariantId
    this._shiftId = shiftId
    this._startsAt = startsAt
    this._endsAt = endsAt
  }

  get id() {
    return EventTemplate.buildId(this.eventVariantId, this.shiftId)
  }

  get unitId() {
    return this._unitId
  }

  get eventVariantId() {
    return this._eventVariantId
  }

  get startsAt() {
    return this._startsAt
  }

  get endsAt() {
    return this._endsAt
  }

  get shiftId() {
    return this._shiftId || null
  }

  get isMeetingEvent() {
    return this.eventVariantId === 'meeting'
  }

  static buildId(eventVariantId, shiftId) {
    return `${eventVariantId}|${shiftId}`
  }

  static parseId(id) {
    const [eventVariantId, shiftId] = id.split('|')
    return { eventVariantId, shiftId }
  }

  static buildEventTemplate(eventVariantId, date, shift, timeService) {
    const shiftService = new ShiftService(shift, timeService)
    const { startsAt, endsAt } = shiftService.getShiftPeriodForDate(date)
    const { unitId, shiftId } = shiftService.getShiftParameters()

    return new EventTemplate(unitId, eventVariantId, startsAt, endsAt, shiftId)
  }

  static buildMeetingEventTemplate(date, primaryShift, timeService) {
    const eventVariantId = 'meeting'
    const shiftService = new ShiftService(primaryShift, timeService)
    const { startsAt, endsAt } = shiftService.getShiftPeriodForDate(date)
    const { unitId } = shiftService.getShiftParameters()

    return new EventTemplate(unitId, eventVariantId, startsAt, endsAt)
  }

  static duplicate(eventTemplate, { startsAt, endsAt }) {
    const { unitId, eventVariantId, shiftId } = eventTemplate

    startsAt = startsAt || eventTemplate.startsAt
    endsAt = endsAt || eventTemplate.endsAt

    return new EventTemplate(unitId, eventVariantId, startsAt, endsAt, shiftId)
  }
}
