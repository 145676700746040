import { createRef, Component } from 'react'
import classNames from 'classnames'
import stores from 'stores'
const { shiftSwapsStore, generalStore } = stores

export default class ShiftSwapsDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isClosedComment: true,
      isClosedExplanation: true,
      isHiddenComment: false,
      isHiddenExplanation: true,
      position: {},
      type: '',
      id: null,
      textComment: '',
      textExplanation: ''
    }

    this.ref = createRef()

    this.handleToggleComment = this.onToggleComment.bind(this)
    this.handleToggleExplanation = this.onToggleExplanation.bind(this)
    this.handleSubmit = this.onSubmit.bind(this)
    this.onWindowScrollHandler = this.onWindowScroll.bind(this)
    this.handleCancel = this.onCancel.bind(this)
  }

  componentWillUnmount() {
    global.document.removeEventListener('scroll', this.onWindowScrollHandler)
  }

  componentDidMount() {
    global.document.addEventListener('scroll', this.onWindowScrollHandler, true)
  }

  componentDidUpdate() {
    const { appState } = this.props
    const modalData = appState.get('modalData')

    if (!modalData || (modalData.type === this.state.type && modalData.id === this.state.id)) {
      return
    }

    let { position, type, id } = modalData
    const { width, height } = this.ref.current.getBoundingClientRect()

    position = {
      top: this.calculateTop(position.top, popupOffset.top, height),
      left: position.left - width - popupOffset.left
    }

    this.setState({
      position,
      type,
      id,
      textComment: '',
      textExplanation: ''
    })
  }

  calculateTop(positionTop, offsetTop, height) {
    if (positionTop - offsetTop + height > window.innerHeight) {
      return window.innerHeight - height
    }
    return positionTop - offsetTop
  }

  onWindowScroll() {
    shiftSwapsStore.resetModalData()
  }

  onToggleComment(event) {
    this.setState({
      isClosedComment: event.target.checked,
      type: ''
    })
  }

  onToggleExplanation(event) {
    this.setState({
      isClosedExplanation: event.target.checked,
      type: ''
    })
  }

  async onSubmit() {
    const { id, type, textComment: text, loading } = this.state
    const { appState } = this.props

    const params = { id, text }
    const unitId = appState.getIn(['generalData', 'unit', 'id'])

    if (loading) {
      return
    } // still hasn't finished the last submit

    this.setState({ loading: true })
    try {
      if (type === 'approve') {
        await shiftSwapsStore.confirmShiftSwapRequest(params)
      }
      if (type === 'deny' || type === 'remove') {
        await shiftSwapsStore.denyShiftSwapRequest(params)
      }
      generalStore.refreshNotifications(unitId)
    } catch (error) {
      /* just to make sure that the loading state doesn't hang in case of request error */
    }
    this.setState({ loading: false })
  }

  onCancel() {
    shiftSwapsStore.setShiftSwapDialog(null)
  }

  render() {
    const { appState } = this.props
    const modalData = appState.get('modalData')
    const {
      isClosedComment,
      isClosedExplanation,
      isHiddenComment,
      isHiddenExplanation,
      loading,
      position,
      textComment,
      textExplanation
    } = this.state

    if (!modalData) {
      return null
    }

    const { type } = modalData
    let submitTitle = ''
    let dialogTitle = ''

    switch (type) {
      case 'approve':
        dialogTitle = 'Approve Swap'
        submitTitle = 'Confirm'
        break
      case 'deny':
        dialogTitle = 'Deny Swap'
        submitTitle = 'Deny'
        break
      case 'remove':
        dialogTitle = 'Remove Swap'
        submitTitle = 'Remove'
        break
      default:
    }

    return (
      <div className="hx-open-shifts-dialog-container" ref={this.ref} style={position}>
        <i className="icon-Chevron---Right arrowDown white" />

        <div className="hx-open-shifts-dialog">
          <header className="bb1">
            <div className="river-bad bold">{dialogTitle}</div>
          </header>
          <div>
            <ul>
              <li className={classNames('bb1', { hide: isHiddenComment })}>
                <input
                  id="hx-open-shifts-dialog-comment"
                  type="checkbox"
                  className="hide"
                  checked={isClosedComment}
                  onChange={this.handleToggleComment}
                />
                <label htmlFor="hx-open-shifts-dialog-comment" className="row river-bad align-middle p20 pv0">
                  <span>Explanation</span>
                  <i className="river-bad icon-right" />
                  <i className="river-bad icon-down" />
                </label>
                <div className="p15 pt0">
                  <textarea
                    className="semibold river-bad rounded"
                    placeholder="Comment will be visible to both staff members"
                    width="100%"
                    onChange={(e) => this.setState({ textComment: e.target.value })}
                    value={textComment}
                  />
                </div>
              </li>
              <li className={classNames('bb1', { hide: isHiddenExplanation })}>
                <input
                  id="hx-open-shifts-dialog-explanation"
                  type="checkbox"
                  className="hide"
                  checked={isClosedExplanation}
                  onChange={this.handleToggleExplanation}
                />
                <label htmlFor="hx-open-shifts-dialog-explanation" className="row river-bad align-middle p20 pv0">
                  <span>Add Explanation / Note</span>
                  <i className="river-bad icon-minus" />
                  <i className="river-bad icon-plus" />
                </label>
                <div className="p15 pt0">
                  <textarea
                    className="semibold river-bad rounded"
                    placeholder="Comment will be visible to both staff members"
                    width="100%"
                    onChange={(e) => this.setState({ textExplanation: e.target.value })}
                    value={textExplanation}
                  />
                </div>
              </li>
            </ul>
          </div>
          <footer className="row align-middle p15">
            <sh-button color="tertiary" onClick={this.handleCancel} label="Cancel" />
            <sh-button
              color={type === 'deny' || type === 'remove' ? 'secondary' : 'primary'}
              onClick={this.handleSubmit}
              label={submitTitle}
              disabled={loading ? true : undefined}
            />
          </footer>
        </div>
      </div>
    )
  }
}

const popupOffset = {
  top: 5,
  left: 5
}
