import { Map } from 'immutable'

export default class DummyStaffHoursBuilder {
  constructor(staffHoursMap) {
    this.staffHoursMap = staffHoursMap
  }

  buildDummyStaffHours(action, { staff, cells }, data) {
    const { staffHoursMap } = this
    const userId = staff.get('id')
    const staffHours = parseFloat(staffHoursMap.getIn([userId, 'value']))
    const requestsCount = parseFloat(staffHoursMap.getIn([userId, 'requestsCount'])) || 0

    const isDeleteAction = action === 'delete'
    const isCreateAction = action === 'create'
    const isChangeAction = action === 'change'
    const isUpdateAction = action === 'update'

    let hoursDelta = 0

    if (isDeleteAction) {
      hoursDelta = this._deleteStaffHoursDelta(cells)
    }
    if (isCreateAction) {
      hoursDelta = this._addStaffHoursDelta(data)
    }
    if (isChangeAction) {
      hoursDelta = this._changeStaffHoursDelta(cells, data)
    }
    if (isUpdateAction) {
      hoursDelta = this._updateStaffHoursDelta(cells, data)
    }

    return {
      hoursDelta,
      requestsCount: requestsCount + 1,
      staffHours: staffHours + hoursDelta
    }
  }

  _deleteStaffHoursDelta(cells) {
    const hoursToDelete = this._hoursToDelete(cells)
    return -hoursToDelete
  }

  _addStaffHoursDelta(data) {
    const { isRequestOff, isStaffPaidTimeOff } = data

    if (isRequestOff || isStaffPaidTimeOff) {
      return 0
    }

    const { staffEvents } = data
    return this._hoursToAdd(staffEvents)
  }

  _changeStaffHoursDelta(cells, data) {
    const { isRequestOff, isStaffPaidTimeOff } = data

    const { staffEvents } = data
    const hoursToDelete = this._hoursToDelete(cells)
    const hoursToAdd = isRequestOff || isStaffPaidTimeOff ? 0 : this._hoursToAdd(staffEvents)

    return -hoursToDelete + hoursToAdd
  }

  _updateStaffHoursDelta(cells, data) {
    const { staffEvents } = data

    const hoursToDelete = this._hoursToDelete(cells)
    const hoursToAdd = this._hoursToAdd(staffEvents)

    return -hoursToDelete + hoursToAdd
  }

  _hoursToDelete = (cells) => {
    return cells.reduce((memo, cell) => {
      const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
      const isAssignment = cellStaffEvent.get('isAssignment')
      const isOnCall = cellStaffEvent.get('isOnCall')
      if (!isAssignment && !isOnCall) {
        return memo
      }

      const duration = parseInt(cellStaffEvent.get('duration')) / 60
      return memo + duration
    }, 0)
  }

  _hoursToAdd = (staffEvents) => {
    return staffEvents.reduce((memo, staffEvent) => memo + staffEvent.duration / 60, 0)
  }
}
