const noYet = ['update', 'review', 'deny']
const noYetActions = noYet.reduce((memo, action) => {
  memo[action] = (state, openShift) => {
    alert(`no yet implemented ${action}`)
    return state
  }
  return memo
}, {})

export default noYetActions
