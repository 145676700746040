import { buildIdNameMapping, isAnyRequiredFieldEmpty, getFieldValueMapping } from '../../utils'
import form from './skillFormDefinitions'
import { capitalize, startCase, cloneDeep } from 'lodash'
import ExpertiseFormController from '../ExpertiseFormController'

export default class SkillFormController extends ExpertiseFormController {
  get defaultState() {
    return {
      form,
      isLoading: false,
      isValidForm: true
    }
  }

  initForm() {
    const { form } = cloneDeep(this.state)

    this.#initDropdownItems(form)
    this.#initFieldAccessibilities(form)
    this.#initFieldValues(form)

    if (this.isFormToEdit()) {
      this.formValuesBeforeEdit = getFieldValueMapping(form)
    }

    const isValidForm = this.isValidForm(form)

    this.setState({ form, isValidForm })
  }

  isValidForm(form) {
    if (isAnyRequiredFieldEmpty(form)) {
      return false
    }
    return !(this.isFormToEdit() && this.areFormValuesUnchanged(form))
  }

  setFieldValue = (value, field) => {
    const { form } = cloneDeep(this.state)

    switch (field) {
      case 'skillGroup':
        this.#showExpertisesOfGroup(value, form)
        break
      case 'skillType':
        this.#showCompetenciesOfExpertise(value, form)
        break
      case 'reviewedDate':
        form.expirationDate.minDate = value
        break
      case 'expirationDate':
        form.reviewedDate.maxDate = value
        break
      default:
    }

    form[field].value = value
    const isValidForm = this.isValidForm(form)
    this.setState({ form, isValidForm })
  }

  serializeForm = () => {
    const { form } = this.state

    return [
      {
        expertiseId: form.skillType.value,
        issuedAt: form.reviewedDate.value,
        expiresAt: form.expirationDate.value,
        scaleIndex: parseInt(form.assessedCompetency.value)
      }
    ]
  }

  #initDropdownItems(form) {
    const { expertises, expertiseGroups, expertiseId } = this.props
    const skillGroupsIdNameMapping = buildIdNameMapping(expertiseGroups, 'id', 'title', {
      type: 'skill'
    })
    form.skillGroup.definition.enum = skillGroupsIdNameMapping

    if (this.isFormToEdit()) {
      form.skillType.definition.enum = [
        {
          key: expertiseId,
          name: expertises.get(expertiseId).get(0).get('name')
        }
      ]
      form.assessedCompetency.definition.enum = this.#getCompetenciesOfExpertise(expertiseId)
    }
  }

  #initFieldAccessibilities(form) {
    if (this.isFormToEdit()) {
      form.skillGroup.definition.disabled = true
      form.skillType.definition.disabled = true
      form.assessedCompetency.definition.disabled = false
    }
  }

  #initFieldValues(form) {
    const { timeService, expertiseId, expertises, staffExpertiseCredentials } = this.props

    form.skillGroup.value = ''
    form.skillType.value = ''
    form.assessedCompetency.value = ''
    form.reviewedDate.value = timeService.today.toISOString()
    form.expirationDate.value = timeService.today.add(1, 'years').toISOString()
    if (this.isFormToEdit()) {
      const skillToEdit = expertises.get(expertiseId).get(0)
      const staffSkillCredential = staffExpertiseCredentials.find(
        (staffExpertiseCredential) => staffExpertiseCredential.get('expertiseId') === expertiseId
      )

      form.skillGroup.value = skillToEdit.get('groupId')
      form.skillType.value = expertiseId
      form.assessedCompetency.value = staffSkillCredential.get('scaleIndex').toString()
      form.reviewedDate.value = staffSkillCredential.get('issuedAt')
      form.expirationDate.value = staffSkillCredential.get('expiresAt')
    }

    form.expirationDate.minDate = form.reviewedDate.value
    form.reviewedDate.maxDate = form.expirationDate.value
  }

  #isReviewedAfterExpiration = (form) => {
    const { timeService } = this.props
    const reviewedMoment = timeService.dateTimeToMoment(form.reviewedDate.value)
    const expirationMoment = timeService.dateTimeToMoment(form.expirationDate.value)
    return reviewedMoment.isAfter(expirationMoment)
  }

  #showExpertisesOfGroup = (groupId, form) => {
    form.skillType.definition.enum = this.#getExpertisesOfGroup(groupId)
  }

  #showCompetenciesOfExpertise = (expertiseId, form) => {
    form.assessedCompetency.definition.enum = this.#getCompetenciesOfExpertise(expertiseId)
  }

  #getCompetenciesOfExpertise = (expertiseId) => {
    const { expertises } = this.props
    const expertise = expertises.get(expertiseId).get(0)
    const scaleItems = expertise.get('scaleItems')

    return scaleItems
      .map((scaleItem, index) => ({
        key: index.toString(),
        name: capitalize(startCase(scaleItem))
      }))
      .toJS()
  }

  #getExpertisesOfGroup = (groupId) => {
    const { expertises } = this.props
    return buildIdNameMapping(expertises, 'id', 'name', { type: 'skill', groupId })
  }
}
