import { Map } from 'immutable'
import { actionRequestCountersQuery } from '../Queries'

function Notifications() {
  let gqlClient = null
  let updateGeneralData = null
  const actions = { refreshNotifications }

  return { initialize, actions }

  function initialize(context) {
    ;({ updateGeneralData, gqlClient } = context)
  }

  async function refreshNotifications(unitId) {
    const parameters = { unitId }
    const { actionRequestCounters } = await gqlClient.query(actionRequestCountersQuery, parameters, {
      displayError: false // this query runs periodically, it's not a user-executed action (shouldn't be displayed if fails)
    })
    const notifications = mapNotifications(actionRequestCounters)

    return updateGeneralData((state) => state.set('notifications', notifications))
  }

  function mapNotifications(counters) {
    const map = {
      shiftOpportunities: 'open-shifts',
      shiftSwapRequests: 'shift-swaps',
      staffTimeOffRequests: 'time-off',
      staffTransfers: 'staff',
      failedAnnouncements: 'announcements'
    }
    return counters.reduce((memo, { key, value }) => memo.set(map[key], value), Map({}))
  }
}

export default Notifications()
