import useShuiEventHook from '../Common/customHooks/shuiEventHook'

import { MutableRefObject, useCallback, useEffect } from 'react'
import { getStudyCodesFromLabel, studyCodes } from '../utils'
import { ActivityTracker } from '../Common/activityTracker'
import { KEYBOARD_KEYS } from 'Common/constants/KeyboardKeys'

export interface IStudyCodeDropdownProps {
  setSelectedStudyCodes: (studyCodes: string[]) => void
  resetKey: number
}

const StudyCodeDropdown = ({ setSelectedStudyCodes, resetKey }: IStudyCodeDropdownProps) => {
  const handleKeyUpEventForDropdown = (event: KeyboardEvent) => {
    if (event.code === KEYBOARD_KEYS.Space || event.code === KEYBOARD_KEYS.Enter) {
      handleDropdownClick()
    }
  }

  const handleDropdownClick = () => {
    ActivityTracker.StartTracking()
  }

  const handleStudyCodeChangeEvent = useCallback(
    (event: any) => {
      const userSelectedStudyCodeLabels = event?.target?.value
      const selectedStudyCodes = getStudyCodesFromLabel(userSelectedStudyCodeLabels)
      setSelectedStudyCodes(selectedStudyCodes)
    },
    [setSelectedStudyCodes]
  )

  const eventName = 'value-changed'
  const dropdownRef = useShuiEventHook(eventName, handleStudyCodeChangeEvent) as MutableRefObject<HTMLInputElement>

  useEffect(() => {
    const dropdownElement = dropdownRef.current

    if (dropdownElement) {
      dropdownElement.value = ''
    }
  }, [setSelectedStudyCodes, resetKey, dropdownRef])

  return (
    <sh-dropdown
      ref={dropdownRef}
      label="Study code"
      editable
      multi-select
      onClick={handleDropdownClick}
      onKeyUp={handleKeyUpEventForDropdown}
    >
      {studyCodes.map(({ label, key }) => (
        <sh-menu-item label={label} key={key}></sh-menu-item>
      ))}
    </sh-dropdown>
  )
}

export default StudyCodeDropdown
