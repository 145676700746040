import { pick } from 'lodash'
import { PureComponent, Fragment } from 'react'
import { PageContent, PageFooter, PageHeader } from '../Page'
import VStaff from '../../Grid/VStaff/VStaff'
import VRole from '../../Grid/VRole/VRole'
import VShift from '../../Grid/VShift/VShift'
import Months from '../../Header/ModeFull/Months'
import './PrintedGrid.scss'
import { ShiftService } from 'services'

export default class PrintedGrid extends PureComponent {
  constructor(props, context) {
    super(props, context)
    props.resetLoadConfig()
  }

  async componentDidMount() {
    const { loadOtherStaff, isOtherStaffLoaded, additionalFilters } = this.props
    if (!additionalFilters.hideSecondaryStaff && !isOtherStaffLoaded) {
      await loadOtherStaff()
    }
  }

  componentDidUpdate(prevProps) {
    const { activeDateRange, calendar } = this.props
    const isReady = activeDateRange.get('isReady') === true
    const calendarDateRange = calendar.get('dateRange')
    const hasChanged = calendarDateRange !== activeDateRange

    if (hasChanged && isReady) {
      this.props.resetLoadConfig()
    }
  }

  render() {
    const { additionalFilters } = this.props
    const printModel = this.props.getViewModelTree(additionalFilters, true)
    return <Fragment>{printModel.map((role, index) => this.renderRole(role, index))}</Fragment>
  }

  renderStaff(staff, index, shiftTime) {
    const { activeDateRange, filters, facilityShiftsMap, notes, timeService, monthExtendedActiveDateRange } = this.props
    const scheduleState = activeDateRange.get('scheduleState')
    const displayNameAs = filters.getIn(['displayOptions', 'staff', 'displayNameAs'])
    const indicators = filters.get('indicators')
    const shiftId = staff.get('shiftId')
    this.props.loadShift(shiftId, true)

    return (
      <VStaff
        key={index}
        staff={staff}
        shiftTime={shiftTime}
        displayNameAs={displayNameAs}
        scheduleState={scheduleState}
        activeDateRange={monthExtendedActiveDateRange}
        indicators={indicators}
        facilityShiftsMap={facilityShiftsMap}
        isPrintView={true}
        notes={notes}
        timeService={timeService}
      />
    )
  }

  renderShift(shift, index) {
    const { activeDateRange, timeService, mode } = this.props
    const staff = shift.get('staff')

    const vShiftProps = {
      shift: shift,
      activeDateRange: activeDateRange,
      showScheduledNumbers: true,
      timeService: timeService,
      mode: mode
    }

    if (!this.props.filters.get('showMergedShift')) {
      const shiftService = new ShiftService(shift, timeService)
      const shiftTime = shiftService.getShiftTime()
      vShiftProps.shiftRange = shiftTime
    }

    return (
      <Fragment key={index}>
        <VShift {...vShiftProps} />
        {staff.map((item, index) => this.renderStaff(item, index))}
      </Fragment>
    )
  }

  renderRole(role, index) {
    const { filters, activeDateRange, additionalFilters, monthExtendedActiveDateRange } = this.props
    const shifts = role.get('shifts')

    const title = 'Full Schedule'

    const headerProps = pick(
      {
        ...this.props,
        title
      },
      ['unit', 'printedDateRange', 'title']
    )
    const footerProps = pick(
      {
        ...this.props,
        isLegendVisible: true,
        filterRoleIds: filters.get('roleIds'),
        filterShiftTypes: filters.get('shiftTypes')
      },
      ['unit', 'timeService', 'filterRoleIds', 'authentication', 'filterShiftTypes', 'isLegendVisible']
    )

    return (
      <Fragment key={index}>
        <table className="print-table printed-grid">
          <PageHeader {...headerProps}>
            <div className="row hx-months-container">
              <div className="col-2" />
              <div className="col-10">
                <Months activeDateRange={activeDateRange} />
              </div>
            </div>
            <VRole
              role={role}
              activeDateRange={monthExtendedActiveDateRange}
              filters={filters}
              additionalFilters={additionalFilters}
            />
          </PageHeader>
          <PageContent>{shifts.map((shift, index) => this.renderShift(shift, index))}</PageContent>
          <PageFooter {...footerProps} />
        </table>
        <div className="pageBreak" />
      </Fragment>
    )
  }
}
