import history from 'browserHistory'
import { generatePath, matchPath } from 'react-router-dom'
import * as PATHS from './paths'

const PATHS_KEYS = Object.keys(PATHS)

function navigate(path = '/', params = {}, followLink = true) {
  const url = generatePath(path, params)

  if (followLink) {
    history.push(url)
  }

  return url
}

function navigateFromTo(fromPath, toPath, params, followLink) {
  const { pathname } = history.location
  const pathData = matchPath(pathname, fromPath)

  if (!pathData) {
    return
  }

  const { params: fromParams = {} } = pathData
  return navigate(toPath, { ...fromParams, ...params }, followLink)
}

navigate.to = PATHS_KEYS.reduce(
  (memo, key) => {
    const path = PATHS[key]
    memo[key] = (...params) => navigate(path, ...params)
    return memo
  },
  (...params) => navigate(...params)
)

navigate.from = PATHS_KEYS.reduce(
  /**
   * @param {any} memoFrom
   */
  (memoFrom, keyFrom) => {
    const pathFrom = PATHS[keyFrom]
    const to = PATHS_KEYS.reduce((memoTo, keyTo) => {
      const pathTo = PATHS[keyTo]
      memoTo[keyTo] = (...params) => navigateFromTo(pathFrom, pathTo, ...params)
      return memoTo
    }, {})
    memoFrom[keyFrom] = { to }
    return memoFrom
  },
  {}
)

navigate.isPath = PATHS_KEYS.reduce((memo, key) => {
  const path = PATHS[key]
  memo[key] = (pathname, options = {}) => matchPath(pathname, { path, ...options }) !== null
  return memo
}, {})

navigate.clearHash = () => {
  history.location.hash = null
  history.push(history.location)
}

const replace = (
  { urlId = undefined, unitUrlId = undefined, componentId = undefined, date = undefined },
  followLink = true,
  location
) => {
  const match = /^(?:\/([^/]*))(?:\/([^/]*))(?:\/([^/]*))(?:\/([^/]*))?(?:\/(.*))?/i.exec(
    location?.pathname || history.location.pathname
  )
  if (!match) {
    return null
  }
  const url = [`/${urlId || match[1]}`, unitUrlId || match[2], componentId || match[3], date || match[4], match[5]]
    .filter((x) => x)
    .join('/')

  if (followLink) {
    history.push(url)
  }
  return url
}

Object.assign(replace, {
  facility: (urlId, followLink) => replace({ urlId }, followLink),
  unit: (unitUrlId, followLink) => replace({ unitUrlId }, followLink),
  component: (componentId, followLink) => replace({ componentId }, followLink),
  date: (date, followLink) => replace({ date }, followLink)
})

navigate.replace = replace

export default navigate
