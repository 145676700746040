import { PureComponent } from 'react'
import ActiveDateRange from 'Navigation/ActiveDateRange'

export default class Toolbar extends PureComponent {
  render() {
    return (
      <div className="toolbar header row align-middle">
        <div className="col-4">
          <ActiveDateRange className="datepicker" />
        </div>
      </div>
    )
  }
}
