import HeaderDay from './Header/HeaderDay'
import VShiftTitle from './VShiftTitle'
import './Shift.scss'

export default function DayShift(props) {
  const { shift, style, shiftRange } = props
  const isLoaded = shift.get('isLoaded')

  if (shift.get('hidden')) {
    return null
  }

  return (
    <div className="_shift" style={style}>
      <header className="hx-calendar-shift">
        <div className="row align-middle bg-mystic">
          <VShiftTitle shift={shift} shiftRange={shiftRange} />
          <HeaderDay {...props} isShiftDaysLoaded={isLoaded} />
        </div>
      </header>
    </div>
  )
}
