import { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import IncomingStaff from './IncomingStaff'
import OutcomingStaff from './OutcomingStaff'

export default class TransferringStaff extends Component {
  static propTypes = {
    transferringStaff: PropTypes.instanceOf(Map).isRequired
  }

  render() {
    const { unit, timeService, displayNameAs, transferringStaff } = this.props

    const incomingStaff = transferringStaff.get('incomingStaff')
    const outcomingStaff = transferringStaff.get('outcomingStaff')
    const futureStaff = transferringStaff.get('futureStaff')

    const hasOutcomingStaff = outcomingStaff.size > 0
    const hasIncomingStaff = incomingStaff.size > 0
    const hasFutureStaff = futureStaff.size > 0

    const hasStaffTransferring = hasOutcomingStaff || hasIncomingStaff || hasFutureStaff
    if (!hasStaffTransferring) {
      return null
    }

    const unitId = unit.get('id')
    const incomingStaffProps = { incomingStaff, futureStaff, unitId, displayNameAs, timeService }
    const outcomingStaffProps = { outcomingStaff, unitId, displayNameAs, timeService }

    return (
      <>
        <h3 className="hx-transferring-staff-header slate-gray">Transferring Staff</h3>
        <IncomingStaff {...incomingStaffProps} />
        <OutcomingStaff {...outcomingStaffProps} />
      </>
    )
  }
}
