import { Redirect, Switch } from 'react-router'
import FacilityUsersAdmin from './FacilityUsersAdmin'
import { withAppContext } from 'AppContext'
import { PrivateRoute, Roles } from 'auth'
import { paths } from 'Navigation'
import mapRoutes from './mapRoutes'

function AdminRoutes({ adminStores: stores, appState, timeService }) {
  return (
    <div className="content-container">
      <Switch>
        {mapRoutes(stores, appState, timeService)}

        <PrivateRoute
          path={paths.AdminStaff}
          componentProps={{ appState, stores }}
          component={FacilityUsersAdmin}
          timeService={timeService}
          requireFacility={true}
          roles={[Roles.FacilityAdmin]}
        />

        <Redirect path="*" exact to="/" />
      </Switch>
    </div>
  )
}

export default withAppContext(AdminRoutes)
