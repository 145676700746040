import { PureComponent } from 'react'
import classNames from 'classnames'
import { matchPath, NavLink } from 'react-router-dom'
import { Announcements as announcementsPath } from '../paths'

export default class TopNavigationItem extends PureComponent {
  render() {
    const { title, isActive, totalNotifications = 0, icon, path } = this.props

    const topNavigationItemClassName = classNames({
      'hx-top-navigation-item row align-middle': true,
      active: isActive
    })

    let notifications = totalNotifications > 99 ? '99+' : totalNotifications
    if (matchPath(path, announcementsPath)) {
      notifications = totalNotifications ? '!' : ''
    }

    return (
      <div data-testid="hx-top-navigation-item" className={topNavigationItemClassName}>
        <div
          className={classNames({
            label: true,
            hide: totalNotifications < 1
          })}
        >
          {notifications}
        </div>
        <NavLink to={path} className="row align-middle">
          <i className={icon} />
          <div className="relative">
            <div data-testid="top-navigation-title" className="absolute text-center w100">
              {title}
            </div>
            <div className="invisible" style={{ fontWeight: 800 }}>
              {title}
            </div>
          </div>
        </NavLink>
      </div>
    )
  }
}
