import { useEffect, useState } from 'react'
import CommonCard from '../../CommonCards/CommonCard'
import { Map } from 'immutable'
import StaffOvertime, { IStaffOvertime } from './StaffOvertime'

export interface ITopStaffOvertimesProps {
  readonly loadCardData: Function
  readonly unit: Map<string, any>
  readonly activeDateRange: Map<string, any>
}

export interface ITopStaffOvertimesCardData {
  sortedStaffOvertimes: Array<IStaffOvertime>
}

export default function TopStaffOvertimes({ loadCardData, unit, activeDateRange }: ITopStaffOvertimesProps) {
  const [cardData, setCardData] = useState<ITopStaffOvertimesCardData>({
    sortedStaffOvertimes: []
  })
  const [isLoading, setIsLoading] = useState(true)
  const [noContentLabel, setNoContentLabel] = useState('No staffs found with overtime hours for the given schedules')
  const unitId = unit.get('id')
  const areLoadParamsReady = unit.get('isReady') && activeDateRange.get('isReady')

  useEffect(() => {
    const loadData = async () => {
      try {
        const cardNewData = await loadCardData(unitId, activeDateRange)
        setCardData(cardNewData)
      } catch (_err) {
        setNoContentLabel('Error loading card data')
      }
      setIsLoading(false)
    }
    if (areLoadParamsReady) {
      setIsLoading(true)
      loadData()
    }
  }, [loadCardData, unitId, activeDateRange, areLoadParamsReady])

  const widthPerRow: number = 53
  const cardHeight = 5 * widthPerRow + 67

  const { sortedStaffOvertimes } = cardData

  //TODO: move away from using index for key
  return (
    <CommonCard
      title={'Top Overtime Employees'}
      noContentLabel={noContentLabel}
      isLoading={isLoading}
      height={cardHeight}
    >
      {sortedStaffOvertimes.length > 0 &&
        sortedStaffOvertimes.map((so: IStaffOvertime, i: any) => (
          <div key={so.staffFirstName + i}>
            <StaffOvertime staff={so} index={i} />
            {i !== sortedStaffOvertimes.length - 1 && <sh-divider />}
          </div>
        ))}
    </CommonCard>
  )
}
