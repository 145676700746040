import { ComponentController } from 'Common/components'
import { List } from 'immutable'

export default class TimeOffController extends ComponentController {
  getDefaultState() {
    let documentType = 'empty'
    let selectedEventTemplateId = ''
    const isPTO = this.isPTO()
    if (this.cellHasTimeOff()) {
      documentType = isPTO ? 'StaffPaidTimeOff' : 'StaffUnavailability'
      selectedEventTemplateId = 'timeOff' //this state variable never changes, used by notesController to distinguish between shift and timeOff forms
    }
    const { startsAt, endsAt } = this._getDefaultStartsAtEndsAt()
    return {
      documentType,
      isProcessing: false,
      isStaffEventLoading: false,
      startsAt,
      endsAt,
      selectedEventTemplateId
    }
  }

  _getDefaultStartsAtEndsAt() {
    const { day } = this.props
    const startsAt = day.get('dateTime')
    const { maxEndsAt } = this._getEndsAtLimitsByStartsAt(startsAt)
    return { startsAt, endsAt: maxEndsAt }
  }

  get eventVariant() {
    const { documentType } = this.state
    const { timeOffEventVaraints } = this.props
    const ptoVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'paidTimeOff')
    const roVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'requestOff')
    return documentType === 'StaffPaidTimeOff' ? ptoVariant : roVariant
  }

  get startsAt() {
    return this.state.startsAt
  }

  get duration() {
    const { startsAt, endsAt } = this.state
    return this.props.timeService.timeMoment(endsAt).diff(startsAt, 'minutes')
  }

  cellHasTimeOff = () => {
    return this.isPTO() || this.isUnavailable()
  }

  isPTO = () => {
    const { cell } = this.props
    return cell.get('staffEvents')?.some((e) => e.get('isPTO'))
  }

  isUnavailable = () => {
    const { cell } = this.props
    return cell.get('staffEvents')?.some((e) => e.get('isUnavailable'))
  }

  get isTimeOffChanged() {
    const { documentType } = this.state
    const isPTO = this.isPTO()
    const isUnavailable = this.isUnavailable()

    if (isPTO) {
      return documentType !== 'StaffPaidTimeOff'
    }
    if (isUnavailable) {
      return documentType !== 'StaffUnavailability'
    }
    return false
  }

  updateTimeOff() {
    const isPTO = this.isPTO()

    let documentType = 'empty'
    if (this.cellHasTimeOff()) {
      documentType = isPTO ? 'StaffPaidTimeOff' : 'StaffUnavailability'
    }

    return this.setState({ documentType })
  }

  getTimeOffPeriodLimits() {
    const { day, timeService, timeRangeInputStep } = this.props
    const { startsAt, endsAt } = this.state

    const minStartsAtByDay = day.get('dateTime')
    const minStartsAt = minStartsAtByDay

    const maxStartsAtByDay = timeService
      .timeMoment(day.get('dateTime'))
      .add(24, 'hours')
      .subtract(timeRangeInputStep, 'minutes')
      .toISOString()
    const maxStartsAtByEndsAt =
      endsAt && timeService.timeMoment(endsAt).subtract(timeRangeInputStep, 'minutes').toISOString()
    const maxStartsAt =
      maxStartsAtByEndsAt && timeService.timeMoment(maxStartsAtByEndsAt).isAfter(maxStartsAtByDay)
        ? maxStartsAtByDay
        : maxStartsAtByEndsAt

    const { minEndsAt, maxEndsAt } = this._getEndsAtLimitsByStartsAt(startsAt)
    return { minStartsAt, maxStartsAt, minEndsAt, maxEndsAt }
  }

  _getEndsAtLimitsByStartsAt(startsAt) {
    const { timeService, timeRangeInputStep } = this.props
    const minEndsAt = timeService.timeMoment(startsAt).subtract(timeRangeInputStep, 'minutes').toISOString()
    const maxEndsAt =
      startsAt &&
      timeService.timeMoment(startsAt).add(24, 'hours').subtract(timeRangeInputStep, 'minutes').toISOString()
    return { minEndsAt, maxEndsAt }
  }

  async loadCellStaffEvents() {
    const { calendarStore, cell } = this.props
    const cellStaffEvents = cell.get('staffEvents') || List()
    for (const cellStaffEvent of cellStaffEvents) {
      const staffEventId = cellStaffEvent.get('id')
      const isPTO = cellStaffEvent.get('isPTO')
      const isUnavailable = cellStaffEvent.get('isUnavailable')
      const isTimeOff = isPTO || isUnavailable
      if (staffEventId && isTimeOff) {
        const staffEvent = await calendarStore.readStaffEvent(staffEventId)
        this._setTimeOffStartsAtEndsAt(staffEvent)
        break
      }
    }
  }

  _setTimeOffStartsAtEndsAt(staffEvent) {
    if (this._doesStaffEventMatchDocumentType(staffEvent)) {
      this.setState({
        startsAt: staffEvent.startsAt,
        endsAt: staffEvent.endsAt,
        initialStartsAt: staffEvent.startsAt,
        initialEndsAt: staffEvent.endsAt
      })
    } else {
      this.setState(this._getDefaultStartsAtEndsAt())
    }
  }

  _doesStaffEventMatchDocumentType(staffEvent) {
    const { documentType } = this.state
    const { unitEventVariantId } = staffEvent
    return (
      (unitEventVariantId === 'paidTimeOff' && documentType === 'StaffPaidTimeOff') ||
      (unitEventVariantId === 'requestOff' && documentType === 'StaffUnavailability')
    )
  }
}
