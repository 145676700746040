import { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Pagination } from 'Manager/components'
import ActionButton from './ActionButton'
import StaffItem from '../StaffItem'
import Header from '../Header'

export default class UnitStaff extends Component {
  static propTypes = {
    staffList: PropTypes.instanceOf(List).isRequired,
    activePage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired
  }

  render() {
    const {
      staffStore,
      staffList,
      activePage,
      totalCount,
      limit,
      onChangePage,
      timeService,
      displayNameAs,
      authUserId
    } = this.props

    if (staffList.size === 0) {
      return null
    }

    return (
      <>
        <h3 className="hx-transferring-staff-header slate-gray">Staff List</h3>
        <Header />
        <ul className="bg-white">
          {staffList.map((user, index) => (
            <StaffItem
              authUserId={authUserId}
              displayNameAs={displayNameAs}
              timeService={timeService}
              facilityStaff={user}
              key={index}
              namespace={classNames('row hx-calendar-staff', {
                bb1: index < totalCount - 1
              })}
            >
              <ActionButton
                facilityStaff={user}
                inviteUser={staffStore.inviteFacilityUser}
                resendInvitation={staffStore.resendInvitation}
                revokePermissions={staffStore.revokePermissions}
                authUserId={authUserId}
              />
            </StaffItem>
          ))}
        </ul>

        <Pagination
          activePage={activePage}
          totalItemsCount={totalCount}
          itemsCountPerPage={limit}
          onChange={onChangePage}
          namespace="bg-white p30"
        />
      </>
    )
  }
}
