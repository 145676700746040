import './GridCell.scss'
import DateCard from './Date/DateCard'
import { Link, generatePath } from 'react-router-dom'
import { navigate } from 'Navigation'
import { Map } from 'immutable'
import { CSSProperties } from 'react'
import { useLocation } from 'react-router'

function calculateDayOfMonth(
  dayIndex: number,
  cellsBeforeMonthStart: any,
  daysInMonth: number,
  daysOfMonth: any,
  cellsAfterMonthEnd: any,
  currentDate: any,
  prevMonthMoment: any,
  nextMonthMoment: any
) {
  if (dayIndex < cellsBeforeMonthStart.length) {
    const dayOfMonth = cellsBeforeMonthStart[dayIndex]
    return prevMonthMoment.date(dayOfMonth)
  } else if (dayIndex < cellsBeforeMonthStart.length + daysInMonth) {
    const dayOfMonth = daysOfMonth[dayIndex - cellsBeforeMonthStart.length]
    return currentDate.date(dayOfMonth)
  } else {
    const dayOfMonth = cellsAfterMonthEnd[dayIndex - cellsBeforeMonthStart.length - daysInMonth]
    return nextMonthMoment.date(dayOfMonth)
  }
}

export default function GridCell({
  colIndex,
  rowIndex,
  date,
  timeService,
  shifts,
  staffEvents,
  initiatedShiftSwapRequests,
  receivedShiftSwapRequests,
  staffDaysMap,
  section,
  setSelectedCellDate
}: any) {
  const currentDate = timeService.usDateToMoment(date)
  const firstDayOfMonth = currentDate.startOf('month')
  const startingDayOfWeek = firstDayOfMonth.day()

  const daysInMonth = currentDate.daysInMonth()
  const daysOfMonth = Array(currentDate.daysInMonth())
    .fill(0)
    .map((_, index) => index + 1)
  const prevMonthUsDate = timeService.getMonth(date, -1, 'MM-DD-YYYY')
  const prevMonthMoment = timeService.usDateToMoment(prevMonthUsDate)
  const nextMonthUsDate = timeService.getMonth(date, 1, 'MM-DD-YYYY')
  const nextMonthMoment = timeService.usDateToMoment(nextMonthUsDate)
  const cellsBeforeMonthStart = Array(startingDayOfWeek)
    .fill(0)
    .map((_, index) => prevMonthMoment.daysInMonth() - startingDayOfWeek + index + 1)

  const totalDays = startingDayOfWeek + daysInMonth
  const remainingEmptyCells = 42 - totalDays
  const cellsAfterMonthEnd = Array(remainingEmptyCells)
    .fill(0)
    .map((_, index) => index + 1)

  const dayIndex = rowIndex * 7 + colIndex
  const dateOfMonth = calculateDayOfMonth(
    dayIndex,
    cellsBeforeMonthStart,
    daysInMonth,
    daysOfMonth,
    cellsAfterMonthEnd,
    currentDate,
    prevMonthMoment,
    nextMonthMoment
  )

  const isCurrentMonth = dateOfMonth.month() === currentDate.month()

  const { todayUsDate } = timeService

  const formattedDate = timeService.timeMoment(dateOfMonth).startOf('day').toISOString()
  const isPastDate = timeService.dateTimeToMoment(formattedDate, 'MM-DD-YYYY').isSameOrBefore(todayUsDate, 'day')
  const selectedCellDate = dateOfMonth.format('MM-DD-YYYY')
  const isSelectedDay = selectedCellDate === date

  const renderDateCards = () => {
    const dateCards = []
    const staffEventsForDate = staffEvents.get(formattedDate)
    const staffDay = staffDaysMap.get(formattedDate) || Map()

    dateCards.push(
      <DateCard
        key={formattedDate}
        date={formattedDate}
        shifts={shifts}
        initiatedShiftSwapRequests={initiatedShiftSwapRequests.get(formattedDate)}
        receivedShiftSwapRequests={receivedShiftSwapRequests.get(formattedDate)}
        staffEvents={staffEventsForDate}
        isCurrentMonth={isCurrentMonth}
        timeService={timeService}
        dateOfMonth={dateOfMonth}
        isSelectedDay={isSelectedDay}
        staffDay={staffDay}
      />
    )
    return dateCards
  }

  const isTimeOffsSection = section === 'time-offs'
  const location = useLocation()
  const pathname = location.pathname
  
  const isRequestTimeOffTab = isTimeOffsSection && pathname.includes('new')
  const isTimeOffAndPastDate = isPastDate && isTimeOffsSection

  const cellStyle: CSSProperties = {
    width: '10.25em',
    height: '9.5em',
    border: '1px solid #ccc',
    borderRadius: '1.125em',
    textAlign: 'center',
    lineHeight: '7.5em',
    padding: '2px',
    pointerEvents: isTimeOffAndPastDate ? 'none' : 'auto',
    opacity: isTimeOffAndPastDate ? 0.5 : 1
  }

  const dayDetailsHeaderDate = dateOfMonth.format('MM-DD-YYYY')
  const dateParam: any = { date: ':date' }

  const switchDateRedirectTo: any = navigate.replace(dateParam, false)
  const dateUrl: any = generatePath(switchDateRedirectTo, { date: dayDetailsHeaderDate })

  const updateSelectedTimeOffDate = () => {
    if(!isTimeOffAndPastDate && isRequestTimeOffTab)
      {
        setSelectedCellDate({ timeOffDate : dayDetailsHeaderDate })
      }
  }

  return (
    <td key={colIndex} style={cellStyle} onClick={updateSelectedTimeOffDate}>
      <Link to={dateUrl}>
        <div className="date">{renderDateCards()} </div>
      </Link>
    </td>
  )
}