import WeekDay from './WeekDay'
import './ModeWeek.scss'
import Months from './Months'

export default function ModeWeek({ activeDateRange }) {
  const days = activeDateRange.get('days').filter((day) => day.isActiveWeek)

  if (!days.length) {
    return null
  }

  return (
    <>
      <div className="hx-calendar-header-week row-7 text-center bl1">
        <Months activeDateRange={activeDateRange} />
        {days.map((day, i) => (
          <WeekDay key={i} day={day} activeDateRange={activeDateRange} />
        ))}
      </div>
    </>
  )
}
