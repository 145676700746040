import { fromJS } from 'immutable'
import { notesByDateRangeQuery } from '../Queries'

function Notes() {
  let gqlClient = null
  let updateNotesMap = null

  const actions = { loadDateRangeNotes }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, updateNotesMap } = context)
  }

  async function loadDateRangeNotes(unitId, startDate, endDate, shiftIds, userIds) {
    const parameters = { unitId, startDate, endDate, userIds }
    const { notesByDateRange: notes } = await gqlClient.query(notesByDateRangeQuery, parameters)

    return updateNotesMap((notesMap) => notes.reduce((memo, note) => memo.set(note.id, fromJS(note)), notesMap))
  }
}

export default Notes()
