import { cloneDeep } from 'lodash'
import ExpertiseFormController from '../ExpertiseFormController'

import form from './LicenseFormDefinitions'
import { buildIdNameMapping, isAnyRequiredFieldEmpty, getFieldValueMapping } from '../../utils'

export default class LicenseFormController extends ExpertiseFormController {
  get defaultState() {
    return {
      form,
      isLoading: false,
      isValidForm: true
    }
  }

  initForm() {
    const { form: formCopy } = cloneDeep(this.state)

    this.#initDropdownItems(formCopy)
    this.#initFieldAccessibilities(formCopy)
    this.#initFieldValues(formCopy)

    if (this.isFormToEdit()) {
      this.formValuesBeforeEdit = getFieldValueMapping(formCopy)
    }

    const isValidForm = this.isValidForm(formCopy)
    this.setState({ form: formCopy, isValidForm })
  }

  isValidForm(form) {
    if (isAnyRequiredFieldEmpty(form)) {
      return false
    }
    return !(this.isFormToEdit() && this.areFormValuesUnchanged(form))
  }

  setFieldValue = (value, field) => {
    const { form } = this.state
    form[field].value = value

    const isValidForm = this.isValidForm(form)
    this.setState({ form, isValidForm })
  }

  serializeForm = () => {
    const { form } = this.state

    return [
      {
        expertiseId: form.license.value,
        metadata: { number: form.licenseNumber.value },
        expiresAt: form.expirationDate.value
      }
    ]
  }

  #initDropdownItems(form) {
    const { expertises } = this.props
    const licensesIdNameMapping = buildIdNameMapping(expertises, 'id', 'fullName', {
      type: 'license'
    })
    form.license.definition.enum = licensesIdNameMapping
  }

  #initFieldAccessibilities(form) {
    if (this.isFormToEdit()) {
      form.license.definition.disabled = true
    }
  }

  #initFieldValues(form) {
    const { timeService, expertiseId, staffExpertiseCredentials } = this.props

    form.license.value = ''
    form.licenseNumber.value = ''
    form.expirationDate.value = timeService.today.add(1, 'years').toISOString()
    if (this.isFormToEdit()) {
      const staffLicenseCredential = staffExpertiseCredentials.find(
        (staffExpertiseCredential) => staffExpertiseCredential.get('expertiseId') === expertiseId
      )

      form.license.value = expertiseId
      form.licenseNumber.value = staffLicenseCredential.get('metadata')?.get('number') || ''
      form.expirationDate.value = staffLicenseCredential.get('expiresAt') || ''
    }
  }
}
