import { createRef, PureComponent } from 'react'
import classNames from 'classnames'
import { t } from 'i18n'

const documentWidth = global.document.body.offsetWidth
const calendarStaffListWidthPercentage = 16.66667 //% for col-2
const staffListWidth = (documentWidth / 100) * calendarStaffListWidthPercentage
const DEFAULT_PADDING_TOP = 0

export default class InconsistencyWarningTooltip extends PureComponent {
  constructor(props) {
    super(props)
    this.cellTooltipRef = createRef()
  }
  render() {
    const { cellPosition, hasSelectionError } = this.props

    if (!hasSelectionError) {
      return null
    }

    const tooltipPosition = this.position(cellPosition)
    const cellSelectionPosition = this.cellSelectionPosition(cellPosition)
    const { left } = cellPosition
    const tooltipWidth = 209
    const warningTooltipOverlapsStaffList = staffListWidth > left - tooltipWidth + 54
    const tooltipClassNames = classNames('align-center align-middle p5 hx-cell-tooltip _warning', {
      'caret-left-side': warningTooltipOverlapsStaffList
    })

    return (
      <>
        <div ref={this.cellTooltipRef} className={tooltipClassNames} style={tooltipPosition}>
          <div className="col-2 p5">
            <i className="tooltip-error-icon"></i>
          </div>

          <div className="col-10 regent-gray">{t('calendar.hovercard_tooltip')}</div>
        </div>
        <div className="_cellSelection _cell" style={cellSelectionPosition} />
      </>
    )
  }

  position(cellPosition) {
    const tooltipReference = this.cellTooltipRef.current
    let tooltipHeight = 46
    let tooltipWidth = 209
    if (tooltipReference !== null) {
      ;({ height: tooltipHeight, width: tooltipWidth } = tooltipReference.getBoundingClientRect())

      tooltipHeight = Math.max(46, tooltipHeight)
      tooltipWidth = Math.max(209, tooltipWidth)
    }

    const { left, top } = cellPosition

    const warningTooltipOverlapsStaffList = staffListWidth > left - tooltipWidth + 54
    const newStyle = {
      position: 'fixed',
      left: `${left - tooltipWidth + 54}px`,
      top: `${top - tooltipHeight}px`
    }
    if (warningTooltipOverlapsStaffList) {
      newStyle.left = `${left - 13}px` //13==(the distance between the caret and the tooltip edge)-(half the cell width)+(half the caret width)
    }
    return newStyle
  }

  cellSelectionPosition(cellPosition) {
    const { paddingTop = DEFAULT_PADDING_TOP } = this.props
    const { left, top, width, height } = cellPosition
    const leftPosition = left
    const topPosition = top - paddingTop
    const transform = `translate3d(${leftPosition}px, ${topPosition}px, 0)`

    return { width, height, transform }
  }
}
