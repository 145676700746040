import { fromJS } from 'immutable'
import { OpenShiftsService } from 'services'
import { uniq } from 'lodash'
import updateOpenShift from '../../updateOpenShift'
import OpenShiftConfig from '../OpenShiftConfig'
import getStaffAssignedWeekHoursMap from '../getStaffAssignedWeekHoursMap'
import { openShiftQuery, shiftOpportunityQuery, staffEventsQuery } from '../../Queries'

function LoadOpenShift() {
  let gqlClient, timeService, updateOpenShiftsStateWith
  const actions = { loadOpenShift, loadShiftOpportunity, resetOpenShift }

  return { actions, initialize }

  function initialize(context) {
    ;({ gqlClient, facilityTime: timeService, updateOpenShiftsStateWith } = context)
  }

  function resetOpenShift() {
    const { defaultState } = OpenShiftConfig

    return updateOpenShiftsStateWith((openShiftsState) => openShiftsState.merge(defaultState))
  }

  async function loadOpenShift(shiftDayId, facilityUsersMap, shiftsById) {
    const [{ openShift }, { staffEvents }] = await Promise.all([
      gqlClient.query(openShiftQuery, { shiftDayId }),
      gqlClient.query(staffEventsQuery, {
        shiftDayId,
        types: ['assignment'],
        limit: 99999
      })
    ])

    openShift.approvedUserIds = uniq(staffEvents.map((staffEvent) => staffEvent.userId))

    const openShiftsService = new OpenShiftsService(timeService)

    const { eligibleFacilityUsers, shiftStartsAt: date } = openShift
    const facilityUserIds = eligibleFacilityUsers.map(({ userId }) => userId)
    const allOpenShiftUserIds = facilityUserIds.concat(openShift.approvedUserIds)

    const staffAssignedWeekHoursMap = await getStaffAssignedWeekHoursMap(gqlClient, date, allOpenShiftUserIds)

    const extensionParameters = { facilityUsersMap, staffAssignedWeekHoursMap }
    const openShiftExtended = openShiftsService.extendOpenShift(shiftsById, fromJS(openShift), extensionParameters)

    return updateOpenShift(updateOpenShiftsStateWith, openShiftExtended, timeService, true)
  }

  async function loadShiftOpportunity(id, facilityUsersMap, shiftsById) {
    const { shiftOpportunity } = await gqlClient.query(shiftOpportunityQuery, { id })

    const { shiftDayId, availableStaff } = shiftOpportunity
    const staffEventsParamters = {
      shiftDayId,
      hasOwnedBy: false,
      types: ['assignment'],
      limit: 99999
    }
    const { staffEvents } = await gqlClient.query(staffEventsQuery, staffEventsParamters)

    shiftOpportunity.approvedUserIds = uniq(staffEvents.map((staffEvent) => staffEvent.userId))

    const { eligibleUserIds, shiftStartsAt: date } = shiftOpportunity
    const availableUserIds = availableStaff.map(({ userId }) => userId)
    const facilityUserIds = uniq(availableUserIds.concat(eligibleUserIds).concat(shiftOpportunity.approvedUserIds))

    const staffAssignedWeekHoursMap = await getStaffAssignedWeekHoursMap(gqlClient, date, facilityUserIds)

    const openShiftsService = new OpenShiftsService(timeService)
    const extensionParameters = {
      availableUserIds,
      facilityUserIds,
      facilityUsersMap,
      staffAssignedWeekHoursMap
    }
    const shiftOpportunityExtended = openShiftsService.extendOpenShift(
      shiftsById,
      fromJS(shiftOpportunity),
      extensionParameters
    )

    return updateOpenShift(updateOpenShiftsStateWith, shiftOpportunityExtended, timeService, true)
  }
}

export default LoadOpenShift()
