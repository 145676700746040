import { Component } from 'react'
import Header from '../Header'
import StaffItem from '../StaffItem'
import { navigate } from 'Navigation'

export default class IncomingStaff extends Component {
  navigateToAcceptStaffTransferModal = (event, staff) => {
    event.stopPropagation()
    event.preventDefault()

    const userId = staff.get('userId')
    navigate.from.Staff.to.AcceptStaffTransfer({ userId })
  }

  render() {
    const { incomingStaff, futureStaff } = this.props

    const hasIncomingStaff = incomingStaff.size > 0
    const hasFutureStaff = futureStaff.size > 0

    if (!hasIncomingStaff && !hasFutureStaff) {
      return null
    }

    return (
      <>
        <h2 className="hx-transferring-staff-header slate-gray">&nbsp;&nbsp;Incoming Staff</h2>
        <Header />
        <ul className="bg-white">
          {incomingStaff.map((user, index) => this.renderStaffItem(user, index))}
          {futureStaff.map((user, index) => this.renderStaffItem(user, index, false))}
        </ul>
      </>
    )
  }

  renderStaffItem(user, index, isAddAssignmentButtonVisible = true) {
    const { unitId, displayNameAs, timeService } = this.props
    return (
      <StaffItem
        unitId={unitId}
        displayNameAs={displayNameAs}
        timeService={timeService}
        facilityStaff={user}
        key={index}
        namespace="row hx-calendar-staff bb1"
      >
        {isAddAssignmentButtonVisible && (
          <button
            onClick={(event) => this.navigateToAcceptStaffTransferModal(event, user)}
            className="button primary hx-transferring-button"
          >
            Add Assignment
          </button>
        )}
      </StaffItem>
    )
  }
}
