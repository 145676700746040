const DEFAULT_STATE = {
  filter: [],
  userSearched: '',
  tipText: '',
  activePage: 1,
  endDate: '',
  isLoading: false
}

export default function ActivitiesController(component) {
  return {
    getState: () => component.state,
    getDefaultState: () => DEFAULT_STATE,
    onFilterChange,
    resetFilters,
    // filterPeople,
    searchPeople
  }

  function onFilterChange(e) {
    const field = e.target.id
    const isSelected = e.target.checked
    let filter = component.state.filter
    const newFilter = isSelected ? selectFilter(filter, field) : unselectFilter(filter, field)

    component.setState({ filter: newFilter })
  }

  function searchPeople(userSearched) {
    return component.setState({ userSearched: userSearched.target.value })
  }

  function selectFilter(list, field) {
    let newArray = list.slice()
    newArray.push(field)
    return newArray
  }

  function resetFilters() {
    component.setState({ filter: [] })
  }

  function unselectFilter(list, field) {
    return list.filter((element) => element !== field)
  }

  // function filterPeople(activity) {
  //   return true;
  //
  //   const { userSearched } = component.state;
  //   const find = path =>
  //     activity
  //       .getIn(path)
  //       .toLowerCase()
  //       .indexOf(userSearched.toLowerCase()) >= 0;
  //   return (
  //     !userSearched || find(['initiatorFullname']) || find(['description'])
  //   );
  // }
}
