// @ts-ignore
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { Map, fromJS } from 'immutable'
import { isFunction } from 'utils'
import { groupBy } from 'lodash'

import { myShiftSwapRequestsQuery, staffAvailableForSwapQuery, shiftDaysAvailableToSwapForQuery } from './Queries'
import {
  acceptShiftSwapRequestMutation,
  declineShiftSwapRequestMutation,
  cancelShiftSwapRequestMutation,
  createShiftSwapRequestMutation
} from './Mutations'

const defaultState = Map({
  shiftSwapRequestsForMonth: Map({
    isLoading: false,
    initiatedShiftSwapRequestsMap: Map({}),
    receivedShiftSwapRequestsMap: Map({}),
    monthStartsAt: null
  }),
  staffAvailableForSwap: Map({
    isLoading: false,
    staffAvailableForSwapList: Map({})
  }),
  shiftDaysAvailableToSwapFor: Map({
    isLoading: false,
    shiftDaysAvailableToSwapForMap: Map({})
  })
})

export default function staffShiftSwapsStore() {
  let gqlClient: any = null
  let timeService: any = null
  let updateStaffShiftSwapsState: any = null

  const actions = {
    loadMyShiftSwapRequestsForMonth,
    acceptShiftSwapRequest,
    declineShiftSwapRequest,
    cancelShiftSwapRequest,
    createShiftSwapRequest,
    loadShiftDaysAvailableToSwapFor,
    loadStaffAvailableForSwap
  }

  return {
    initialize,
    ...actions
  }

  function initialize(state: any, context: any) {
    function _updateStaffShiftSwapsState(state: any, key: string, value: any) {
      if (isFunction(value)) {
        return state.updateIn(['staffShiftSwaps', key], value)
      }
      return state.setIn(['staffShiftSwaps', key], value)
    }
    const updaters = fluxStore({
      _updateStaffShiftSwapsState
    })

    const extendedContext = { ...context, ...updaters }

    ;({
      gqlClient,
      facilityTime: timeService,
      _updateStaffShiftSwapsState: updateStaffShiftSwapsState
    } = extendedContext)
    return state.set('staffShiftSwaps', defaultState)
  }

  function _getDateKey(startsAt: string): string {
    return timeService.timeMoment(startsAt).startOf('day').toISOString()
  }

  function _filterShiftSwapByUser(myShiftSwapRequests: any, userId: string) {
    let initiatedShiftSwapRequestsMap = myShiftSwapRequests?.filter(
      (initiatedShiftSwapRequests: any) => initiatedShiftSwapRequests['createdBy'] === userId
    )
    initiatedShiftSwapRequestsMap = fromJS(
      groupBy(initiatedShiftSwapRequestsMap, (initiatedShiftSwapRequests: any) =>
        _getDateKey(initiatedShiftSwapRequests.date)
      )
    )

    let receivedShiftSwapRequestsMap = myShiftSwapRequests?.filter(
      (receivedShiftSwapRequests: any) => receivedShiftSwapRequests['createdBy'] !== userId
    )
    receivedShiftSwapRequestsMap = fromJS(
      groupBy(receivedShiftSwapRequestsMap, (receivedShiftSwapRequests: any) =>
        _getDateKey(receivedShiftSwapRequests['shiftDaysToSwapFor'][0]['startsAt'])
      )
    )
    return { initiatedShiftSwapRequestsMap, receivedShiftSwapRequestsMap }
  }

  function _calculateStartAndEndDate(monthStartsAt: string) {
    const currentDate = timeService.timeMoment(monthStartsAt)
    const startDateMoment = currentDate.startOf('week')
    const endDateMoment = startDateMoment.clone().add(42, 'days')

    return { startDate: startDateMoment.toISOString(), endDate: endDateMoment.toISOString() }
  }

  async function loadMyShiftSwapRequestsForMonth(monthStartsAt: string, userId: string) {
    const { startDate: sundayBeforeMonthStartsAt, endDate: sevenWeeksAfterStart } =
      _calculateStartAndEndDate(monthStartsAt)
    const parameters: any = {
      startDate: sundayBeforeMonthStartsAt,
      endDate: sevenWeeksAfterStart
    }
    updateStaffShiftSwapsState('shiftSwapRequestsForMonth', (shiftSwapRequestsForMonthsData: any) => {
      return shiftSwapRequestsForMonthsData.set('isLoading', true)
    })

    const { myShiftSwapRequests } = await gqlClient.query(myShiftSwapRequestsQuery, parameters)
    const { initiatedShiftSwapRequestsMap, receivedShiftSwapRequestsMap } = _filterShiftSwapByUser(
      myShiftSwapRequests,
      userId
    )
    return updateStaffShiftSwapsState(
      'shiftSwapRequestsForMonth',
      Map({
        isLoading: false,
        initiatedShiftSwapRequestsMap: initiatedShiftSwapRequestsMap,
        receivedShiftSwapRequestsMap: receivedShiftSwapRequestsMap,
        monthStartsAt
      })
    )
  }

  async function loadShiftDaysAvailableToSwapFor(staffEventId: string) {
    updateStaffShiftSwapsState('shiftDaysAvailableToSwapFor', (shiftDaysAvailableToSwapForMap: any) => {
      return shiftDaysAvailableToSwapForMap.set('isLoading', true)
    })

    const { shiftDaysAvailableToSwapFor } = await gqlClient.query(shiftDaysAvailableToSwapForQuery, { staffEventId })
    let shiftDaysAvailableToSwapForMap = fromJS(
      groupBy(shiftDaysAvailableToSwapFor, (shiftDaysAvailableToSwapFor: any) =>
        _getDateKey(shiftDaysAvailableToSwapFor.startsAt)
      )
    )

    return updateStaffShiftSwapsState(
      'shiftDaysAvailableToSwapFor',
      Map({
        isLoading: false,
        shiftDaysAvailableToSwapForMap
      })
    )
  }

  async function loadStaffAvailableForSwap(staffEventId: string, shiftDayIdsForSwap: string[]) {
    updateStaffShiftSwapsState('staffAvailableForSwap', (staffAvailableForSwapList: any) => {
      return staffAvailableForSwapList.set('isLoading', true)
    })

    const { staffAvailableForSwap } = await gqlClient.query(staffAvailableForSwapQuery, {
      staffEventId,
      shiftDayIdsForSwap
    })

    return updateStaffShiftSwapsState(
      'staffAvailableForSwap',
      Map({
        isLoading: false,
        staffAvailableForSwapList: fromJS(staffAvailableForSwap)
      })
    )
  }

  async function acceptShiftSwapRequest(id: string, userId: string, staffEventId: string, monthStartsAt: string) {
    const parameters = { staffEventId: staffEventId }
    const params = { id, parameters }
    await gqlClient.mutate(acceptShiftSwapRequestMutation, params)
    await loadMyShiftSwapRequestsForMonth(monthStartsAt, userId)
  }

  async function declineShiftSwapRequest(parameters: any, userId: string, monthStartsAt: string) {
    await gqlClient.mutate(declineShiftSwapRequestMutation, { parameters })
    await loadMyShiftSwapRequestsForMonth(monthStartsAt, userId)
  }

  async function cancelShiftSwapRequest(parameters: any, userId: string, monthStartsAt: string) {
    await gqlClient.mutate(cancelShiftSwapRequestMutation, { parameters })
    await loadMyShiftSwapRequestsForMonth(monthStartsAt, userId)
  }

  async function createShiftSwapRequest(
    staffEventId: string,
    shiftDayIdsToSwapFor: string[],
    userId: string,
    monthStartsAt: string,
    loggedInUserId: string
  ) {
    const shiftSwapRequest = { staffEventId, shiftDayIdsToSwapFor, userId }
    await gqlClient.mutate(createShiftSwapRequestMutation, { shiftSwapRequest })
    await loadMyShiftSwapRequestsForMonth(monthStartsAt, loggedInUserId)
  }
}
