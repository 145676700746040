import { Component } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import StaffListFilter from './StaffListFilter'
import StaffListTable from './StaffListTable'
import CreateEditStaffModal from '../CreateEditStaffModal'
import { paths } from 'Navigation'
import { DataController } from './Controllers'
import AcceptStaffTransferModal from './AcceptStaffTransferModal'
import stores from 'stores'

const { staffStore } = stores

export class StaffList extends Component {
  static defaultProps = {}
  static propTypes = {
    appState: PropTypes.object,
    timeService: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.dataController = new DataController(this)
    this.state = { ...this.dataController.defaultState }
  }

  componentDidMount() {
    this.dataController.resetStaffList()
    this.dataController.loadStaffList()
    this.dataController.loadTransferringStaff()
  }

  componentDidUpdate(prevProps, prevState) {
    const { unit, appState } = this.props
    const { unit: prevUnit, appState: prevAppState } = prevProps

    const isUnitIdChanged = unit.get('id') !== prevUnit.get('id')
    const { staffListConfig: prevStaffListConfig } = prevState

    if (isUnitIdChanged) {
      this.dataController.resetStaffList()
    }

    const isStaffListFilterChanged = this.dataController.isStaffListFilterChanged(prevStaffListConfig)
    if (isUnitIdChanged || isStaffListFilterChanged) {
      this.dataController.loadStaffList()
    }

    const isStaffTranferFilterChanged = this.dataController.isStaffTranferFilterChanged(prevStaffListConfig)
    if (isUnitIdChanged || isStaffTranferFilterChanged) {
      this.dataController.loadTransferringStaff()
    }

    const staffPositions = appState.getIn(['staff', 'staffPositions'])
    const prevStaffPositions = prevAppState.getIn(['staff', 'staffPositions'])
    const isStaffPositionsListChanged = staffPositions !== prevStaffPositions
    if (isStaffPositionsListChanged) {
      const staffPositionsIds = staffPositions.map((position) => position.get('id'))
      this.dataController.setStaffListConfig({ positions: staffPositionsIds }, false)
    }
  }

  render() {
    const { appState, timeService, isEnabledFeature, unit } = this.props
    const { units, staffList, usersPerPage, staffPositions, usersTotalCount, staffListConfig, transferringStaff } =
      this.dataController

    const activePage = staffListConfig.get('activePage')
    const authentication = appState.get('authentication')
    const homeUnitId = authentication.getIn(['facilityUser', 'homeUnitId'])
    const authUserId = authentication.getIn(['facilityUser', 'userId'])
    const authProfile = authentication.get('profile')
    const managerFirstName = authProfile.get('firstName')
    const managerLastName = authProfile.get('lastName')
    const managerFullName = `${managerFirstName} ${managerLastName}`

    const filterProps = {
      isEnabledFeature,
      onChangeSearch: this.dataController.onChangeSearch,
      onChangePositions: this.dataController.onChangePositions,
      onChangeStatuses: this.dataController.onChangeStatuses,
      onChangeExpirationDateType: this.dataController.onChangeExpirationDateType,
      onChangeSort: this.dataController.onChangeSort,
      staffPositions,
      staffListConfig
    }

    const staffListTableProps = {
      timeService,
      staffList,
      unit,
      staffStore,
      transferringStaff,
      limit: usersPerPage,
      activePage: activePage,
      totalCount: usersTotalCount,
      onChangePage: this.dataController.onChangePage,
      displayNameAs: this.state.staffListConfig.getIn(['sort', 'displayNameAs']),
      authUserId,
      staffListConfig
    }

    const createEditStaffModalProps = {
      units,
      currentUnitId: unit.get('id'),
      homeUnitId,
      timeService,
      staffPositions,
      managerFullName,
      staff: null,
      title: 'New Staff',
      onSubmit: staffStore.createFacilityUser,
      reloadStaff: this.dataController.reloadStaff
    }

    const acceptStaffTransferModalProps = {
      reloadStaff: this.dataController.reloadStaff
    }

    return (
      <section className="bg-antiflashwhite">
        <StaffListFilter {...filterProps} />
        <StaffListTable {...staffListTableProps} />
        <Route
          exact
          path={paths.StaffNew}
          render={(props) => <CreateEditStaffModal {...props} {...createEditStaffModalProps} />}
        />
        <Route
          exact
          path={paths.AcceptStaffTransfer}
          render={(props) =>
            withProps(AcceptStaffTransferModal, {
              ...props,
              ...acceptStaffTransferModalProps
            })
          }
        />
      </section>
    )
  }
}

function withProps(Component, componentProps, props) {
  const allProps = { ...props, ...componentProps }
  return <Component {...allProps} />
}

export default withAppContext(StaffList)
