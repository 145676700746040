import Stores from './Stores'
import authStore from './authentication'

const { initialize, timeService, ...stores } = Stores()
export const authenticationStore = authStore(initialize)
export { GlobalAccountsService, Instance } from './humanics'
const defaultStores = { timeService, ...stores }
export default defaultStores
export { stores }
export * from '@humanics/he-react-common/lib/stores/bannersStore'
