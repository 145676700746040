import { Map } from 'immutable'
import Avatar from '../../../../components/Avatar/Avatar'

export default function StaffName({ metaData }) {
  return (
    <div style={{ display: 'flex' }}>
      <Avatar
        profile={Map({
          firstName: metaData.staffFirstName,
          lastName: metaData.staffLastName,
          avatarUrl: metaData.avatarUrl
        })}
      />
      <div style={{ marginLeft: '0.5rem' }}> {metaData['staffFirstName'] + ' ' + metaData['staffLastName']}</div>
    </div>
  )
}
