import { PureComponent } from 'react'
import { fromJS } from 'immutable'
import { Avatar } from 'Manager/components'

const mapName = {
  partTime: 'Part Time',
  fullTime: 'Full Time',
  perDiem: 'Per Diem'
}

class InitiatorRecipientProfile extends PureComponent {
  state = { visible: true }

  render() {
    const {
      avatarUrl,
      userProfileFirstName,
      userProfileLastName,
      userFacilityProfileFirstName,
      userFacilityProfileLastName,
      employmentType,
      scheduledHours
    } = this.props

    const firstName = userFacilityProfileFirstName || userProfileFirstName
    const lastName = userFacilityProfileLastName || userProfileLastName
    const fullName = `${firstName} ${lastName}`

    const profile = fromJS({
      firstName,
      lastName,
      avatarUrl
    })

    return (
      <div style={{ position: 'relative' }}>
        <Avatar profile={profile} />
        <div>
          <span className="full-name">{fullName}</span>
          <span>
            {mapName[employmentType] || employmentType}
            &nbsp; &middot; &nbsp;
            <span>{Math.round(scheduledHours)} hrs</span>
          </span>
        </div>
      </div>
    )
  }
}

export default InitiatorRecipientProfile
