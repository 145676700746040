import { buildIdNameMapping, isAnyRequiredFieldEmpty, getFieldValueMapping, doesDuplicateExist } from '../../utils'
import form from './equipmentFormDefinitions'
import formExtension from './equipmentFormExtensionDefinitions'
import { capitalize, startCase, cloneDeep, isEqual } from 'lodash'
import ExpertiseFormController from '../ExpertiseFormController'

export default class EquipmentFormController extends ExpertiseFormController {
  get defaultState() {
    return {
      form,
      formExtensions: [],
      isLoading: false,
      isValidForm: true,
      skillsEnum: []
    }
  }

  initForm() {
    const newState = cloneDeep(this.state)
    const { form: formCopy } = newState

    this.#initDropdownItems(formCopy)
    this.#initFieldAccessibilities(formCopy)
    this.#initFieldValues(formCopy)

    if (this.isFormToEdit()) {
      const { expertiseId } = this.props
      newState.skillsEnum = this.#getSkillsOfEquipment(expertiseId)
      const staffEquipmentCredentials = this.#getStaffCredentialsForEquipment(expertiseId)
      staffEquipmentCredentials.forEach((credential) => this.addFormExtension(credential, newState))
      this.formExtensionValuesBeforeEdit = newState.formExtensions.map((extension) => getFieldValueMapping(extension))
    }

    newState.isValidForm = this.isValidForm(newState.form, newState.formExtensions)
    this.setState(newState)
  }

  allowFormExtension = () => {
    if (this.isFormToEdit()) {
      return false
    }
    const { formExtensions, skillsEnum } = this.state
    return formExtensions.length < skillsEnum.length
  }

  addFormExtension = (data, state) => {
    const formExtensionCopy = this.initFormExtension(data)
    if (!state) {
      this.setState((prevState) => ({
        formExtensions: [...prevState.formExtensions, formExtensionCopy],
        isValidForm: false
      }))
    } else {
      state.formExtensions = [...state.formExtensions, formExtensionCopy]
      state.isValidForm = false
    }
  }

  areFormValuesUnchanged = (formCopy, i) => {
    const currentFormValues = getFieldValueMapping(formCopy)
    return isEqual(currentFormValues, this.formExtensionValuesBeforeEdit[i])
  }

  initFormExtension(data) {
    const formExtensionCopy = cloneDeep(formExtension)
    this.#initDropdownItems(formExtensionCopy, data)
    this.#initFieldValues(formExtensionCopy, data)
    data && this.#initFieldAccessibilities(formExtensionCopy)
    return formExtensionCopy
  }

  isValidForm(formCopy, formExtensions) {
    if (isAnyRequiredFieldEmpty(formCopy)) {
      return false
    }
    if (formExtensions.length === 0) {
      return false
    }

    const skillsAdded = this.#getSkillsAdded(formExtensions)
    if (doesDuplicateExist(skillsAdded)) {
      return false
    }

    for (const formExtensionCopy of formExtensions) {
      if (isAnyRequiredFieldEmpty(formExtensionCopy)) {
        return false
      }
    }

    if (this.isFormToEdit()) {
      let formValuesUnchanged = true
      for (let i = 0; i < formExtensions.length; ++i) {
        if (!this.areFormValuesUnchanged(formExtensions[i], i)) {
          formValuesUnchanged = false
          break
        }
      }
      return !formValuesUnchanged
    }

    return true
  }

  setFieldValue = (value, field, index) => {
    const state = cloneDeep(this.state)
    let formCopy = state.form

    if (index !== undefined) {
      formCopy = state.formExtensions[index]
    }

    switch (field) {
      case 'equipmentGroup':
        this.#clearExtensions(state)
        this.#showEquipmentOfGroup(value, formCopy)
        formCopy.equipment.value = '_'
        break
      case 'equipment':
        this.#clearExtensions(state)
        state.skillsEnum = this.#getSkillsOfEquipment(value)
        break
      case 'equipmentSkill':
        this.#showCompetenciesOfExpertise(value, formCopy)
        formCopy.assessedCompetency.value = '_'
        break
      case 'reviewedDate':
        formCopy.expirationDate.minDate = value
        break
      case 'expirationDate':
        formCopy.reviewedDate.maxDate = value
        break
      default:
    }

    formCopy[field].value = value
    state.isValidForm = this.isValidForm(state.form, state.formExtensions)
    this.setState(state)
  }

  handleFormExtensionClosing = (index) => {
    if (this.isFormToEdit()) {
      return
    }
    this.setState((prevState) => {
      prevState.formExtensions.splice(index, 1)
      return {
        formExtensions: prevState.formExtensions,
        isValidForm: this.isValidForm(prevState.form, prevState.formExtensions)
      }
    })
  }

  serializeForm = () => {
    const { formExtensions } = this.state

    return formExtensions.map((formExtensionCopy) => ({
      expertiseId: formExtensionCopy.equipmentSkill.value,
      issuedAt: formExtensionCopy.reviewedDate.value,
      expiresAt: formExtensionCopy.expirationDate.value,
      scaleIndex: parseInt(formExtensionCopy.assessedCompetency.value)
    }))
  }

  #initDropdownItems(formCopy, data) {
    const { expertiseGroups } = this.props
    if (!this.#isFormExtension(formCopy)) {
      const equipmentGroupsIdNameMapping = buildIdNameMapping(expertiseGroups, 'id', 'title', {
        type: 'equipment',
        parentId: null
      })
      formCopy.equipmentGroup.definition.enum = equipmentGroupsIdNameMapping
    } else {
      formCopy.equipmentSkill.definition.enum = this.state.skillsEnum
    }

    if (this.isFormToEdit()) {
      if (!this.#isFormExtension(formCopy)) {
        const { expertiseGroups, expertiseId } = this.props
        const equipment = expertiseGroups.get(expertiseId).get(0)
        formCopy.equipment.definition.enum = [{ key: expertiseId, name: equipment.get('title') }]
      } else {
        if (!data) {
          return
        }
        const { expertises } = this.props
        const skillId = data.get('expertiseId')
        const skill = expertises.get(skillId).get(0)
        formCopy.equipmentSkill.definition.enum = [{ key: skillId, name: skill.get('name') }]
        formCopy.assessedCompetency.definition.enum = this.#getCompetenciesOfExpertise(skillId)
      }
    }
  }

  #initFieldAccessibilities(formCopy) {
    if (!this.isFormToEdit()) {
      return
    }

    if (this.#isFormExtension(formCopy)) {
      formCopy.equipmentSkill.definition.disabled = true
      return
    }

    formCopy.equipmentGroup.definition.disabled = true
    formCopy.equipment.definition.disabled = true
  }

  #initFieldValues(formCopy, data) {
    if (!this.isFormToEdit()) {
      return
    }
    if (this.#isFormExtension(formCopy)) {
      if (data) {
        formCopy.equipmentSkill.value = data.get('expertiseId')
        formCopy.assessedCompetency.value = data.get('scaleIndex')?.toString()
        formCopy.reviewedDate.value = data.get('issuedAt')
        formCopy.expirationDate.value = data.get('expiresAt')
      } else {
        const { timeService } = this.props
        formCopy.reviewedDate.value = timeService.today.toISOString()
        formCopy.expirationDate.value = timeService.today.add(1, 'years').toISOString()
      }
      formCopy.expirationDate.minDate = formCopy.reviewedDate.value
      formCopy.reviewedDate.maxDate = formCopy.expirationDate.value
    } else {
      const { expertiseId } = this.props
      const equipmentGroup = this.#getGroupForEquipment(expertiseId)
      formCopy.equipmentGroup.value = equipmentGroup.get('id')
      formCopy.equipment.value = expertiseId
    }
  }

  #isFormExtension = (formCopy) => {
    return formCopy.equipmentSkill !== undefined
  }

  #showEquipmentOfGroup = (groupId, formCopy) => {
    formCopy.equipment.definition.enum = this.#getEquipmentOfGroup(groupId)
  }

  #getEquipmentOfGroup = (groupId) => {
    const { expertiseGroups } = this.props
    return buildIdNameMapping(expertiseGroups, 'id', 'title', {
      type: 'equipment',
      parentId: groupId
    })
  }

  #showCompetenciesOfExpertise = (expertiseId, formCopy) => {
    formCopy.assessedCompetency.definition.enum = this.#getCompetenciesOfExpertise(expertiseId)
  }

  #getCompetenciesOfExpertise = (expertiseId) => {
    if (!expertiseId) {
      return []
    }

    const { expertises } = this.props
    const expertise = expertises.get(expertiseId).get(0)
    const scaleItems = expertise.get('scaleItems')

    return scaleItems
      .map((scaleItem, index) => ({
        key: index.toString(),
        name: capitalize(startCase(scaleItem))
      }))
      .toJS()
  }

  #getSkillsOfEquipment = (equipmentId) => {
    const { expertises } = this.props
    return buildIdNameMapping(expertises, 'id', 'name', {
      type: 'equipment',
      groupId: equipmentId
    })
  }

  #getStaffCredentialsForEquipment = (equipmentId) => {
    const { staffExpertiseCredentials, expertises } = this.props
    return staffExpertiseCredentials.filter((credential) => {
      const expertiseId = credential.get('expertiseId')
      return expertises.get(expertiseId).get(0).get('groupId') === equipmentId
    })
  }

  #getGroupForEquipment = (equipmentId) => {
    const { expertiseGroups } = this.props
    const equipmentGroupId = expertiseGroups.get(equipmentId).get(0).get('parentId')
    return expertiseGroups.get(equipmentGroupId).get(0)
  }

  #clearExtensions = (state) => {
    state.skillsEnum = []
    state.skillsAdded = []
    state.formExtensions = []
  }

  #getSkillsAdded = (formExtensions) => {
    return formExtensions.map((formExtension) => formExtension.equipmentSkill.value).filter((skill) => !!skill)
  }
}
