import { t } from 'i18n'
import moment from 'moment'
import './Footer.scss'

export default function Footer({ timeService, style = {} }) {
  const year = timeService?.timeMoment ? timeService.timeMoment(null).format('YYYY') : moment().format('YYYY') //For case when user is not logged in
  const { RELEASE: release } = window

  return (
    <footer className="hx-footer" data-component="Footer" style={style}>
      <div>
        {t('footer.version')} <span className="bold _release">{release}</span> - ©{' '}
        <span className="_year">{year}</span> <span className="bold">{t('footer.company')}</span>
        {t('footer.rights')}
      </div>
    </footer>
  )
}
