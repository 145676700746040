function StaffAssignedHoursByDateRangeOperation(parameters) {
  const { unitId, userIds, startDate, endDate } = parameters
  const operation = 'staffAssignedHoursByDateRange'
  const parametersPrefix = operation
  return {
    name: operation,
    parameters: {
      [`${parametersPrefix}_unitId`]: unitId,
      [`${parametersPrefix}_startDate`]: startDate,
      [`${parametersPrefix}_endDate`]: endDate,
      [`${parametersPrefix}_userIds`]: userIds,
      [`${parametersPrefix}_limit`]: 999
    }
  }
}

function SecondaryStaffCalendarCellsOperation(parameters) {
  const { userIds, unitId, startDate, endDate } = parameters
  const operation = 'secondaryStaffCalendarCells'
  const parametersPrefix = operation

  return {
    name: operation,
    parameters: {
      [`${parametersPrefix}_userIds`]: userIds,
      [`${parametersPrefix}_unitId`]: unitId,
      [`${parametersPrefix}_startDate`]: startDate,
      [`${parametersPrefix}_endDate`]: endDate
    }
  }
}

function CalendarCellsOperation(parameters) {
  const { userIds, shiftIds, startDate, endDate } = parameters
  const operation = 'calendarCells'
  const parametersPrefix = operation

  return {
    name: operation,
    parameters: {
      [`${parametersPrefix}_userIds`]: userIds,
      [`${parametersPrefix}_shiftIds`]: shiftIds,
      [`${parametersPrefix}_startDate`]: startDate,
      [`${parametersPrefix}_endDate`]: endDate
    }
  }
}

function NotesByDateRangeOperation(parameters) {
  const { userIds, startDate, endDate } = parameters
  const operation = 'notesByDateRange'
  const parametersPrefix = operation

  return {
    name: operation,
    parameters: {
      [`${parametersPrefix}_userIds`]: userIds,
      [`${parametersPrefix}_startDate`]: startDate,
      [`${parametersPrefix}_endDate`]: endDate
    }
  }
}

export {
  CalendarCellsOperation,
  NotesByDateRangeOperation,
  SecondaryStaffCalendarCellsOperation,
  StaffAssignedHoursByDateRangeOperation
}
