import { PropsWithChildren } from 'react'
import './MonthNavigator.scss'
import { t } from 'i18n'
import { Link, generatePath } from 'react-router-dom'
import { navigate } from 'Navigation'

export interface IMonthNavigatorProps {
  timeService?: any
  date: any
}

export default function MonthNavigator({ timeService, date }: PropsWithChildren<IMonthNavigatorProps>) {
  const monthYear = timeService.getMonthYearForUsDate(date)

  const prevMonthUsDate = timeService.getMonth(date, -1, 'MM-DD-YYYY')
  const nextMonthUsDate = timeService.getMonth(date, 1, 'MM-DD-YYYY')
  const todayUsDate = timeService.todayUsDate
  const isTodayMoment = timeService.usDateToMoment(date)
  const isToday = timeService.isToday(isTodayMoment)

  const dateParam: any = { date: ':date' }

  const switchDateRedirectTo: any = navigate.replace(dateParam, false)

  const prevMonthUrl = generatePath(switchDateRedirectTo, { date: prevMonthUsDate })
  const nextMonthUrl = generatePath(switchDateRedirectTo, { date: nextMonthUsDate })
  const currentDateUrl = generatePath(switchDateRedirectTo, { date: todayUsDate })

  return (
    <div className="row col-12 align-middle align-center">
      <div className="row col-9 align-middle align-center">
        <div className="col-2 row align-center">
          <Link to={prevMonthUrl}>
            <sh-icon slot="functions" button size="l" icon="arrow-line-left-s"></sh-icon>
          </Link>
        </div>
        <div className="col-5 row align-center">
          <div>
            <sh-text size="header-2">{monthYear}</sh-text>
          </div>
        </div>
        <div className="col-2 row align-center">
          <Link to={nextMonthUrl}>
            <sh-icon slot="functions" button size="l" icon="arrow-line-right-s"></sh-icon>
          </Link>
        </div>
      </div>
      <div className="col-3 row align-middle align-right">
        {!isToday && (
          <div className="today">
            <Link to={currentDateUrl}>
              <sh-text href="#" size="body-1" color="primary">
                {`${t('staff.calendar.today')}`}
              </sh-text>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
