import { List } from 'immutable'
import { cancelledSubjects } from 'services/NoteSubjects'

const cellWithEmptyEvent = {
  staff: null,
  isPTO: false,
  isDummy: true,
  shiftTime: null,
  duration: null,
  noteIds: List(),
  hasNotes: false,
  staffEventId: null,
  isCancelled: false,
  isAssignment: false,
  isOnCall: false,
  isUnavailable: false,
  staffEventShiftId: null,
  isMeeting: false,
  isBlockedByAssignment: false,
  isPrimaryShift: false,
  identityHash: 'dummyIdentityHash',
  isHomeUnit: true
}

function buildPTOCell(note, staffEventShiftId) {
  return {
    ...cellWithEmptyEvent,
    isPTO: true,
    hasNotes: !!note,
    staffEventShiftId
  }
}

function buildUnavailabilityCell(note, staffEventShiftId) {
  return {
    ...cellWithEmptyEvent,
    isUnavailable: true,
    hasNotes: !!note,
    staffEventShiftId
  }
}

function buildStaffEventCell({
  selectedShift,
  isPrimaryShift,
  originalCell,
  timeService,
  staffEvent,
  staffEventShiftId,
  note,
  isMeeting,
  isOnCall,
  isAssignment
}) {
  const { duration, startsAt, shiftId: eventShiftId, unitId: eventUnitId } = staffEvent
  const startsAtMoment = timeService.timeMoment(startsAt)
  const endsAtMoment = startsAtMoment.clone().add(duration, 'minutes')

  const staffEventStartsAt = startsAtMoment.format('HH:mm')
  const staffEventEndsAt = endsAtMoment.format('HH:mm')

  const shiftTime = `${staffEventStartsAt} - ${staffEventEndsAt}`

  const selectedShiftId = selectedShift.get('id')
  const isBlockedByAssignment = selectedShiftId !== eventShiftId

  const selectedUnitId = selectedShift.get('unitId')
  const isBlockedByAssignmentFromOtherUnit = eventUnitId !== selectedUnitId
  const hasNotes = !!note

  return {
    ...cellWithEmptyEvent,
    isAssignment,
    isOnCall,
    isPrimaryShift,
    shiftTime,
    duration,
    hasNotes,
    staffEventShiftId,
    isMeeting,
    isBlockedByAssignment,
    isBlockedByAssignmentFromOtherUnit
  }
}

function updateStaffEventCell(originalCell, timeService, staffEvent, newNote, updatedNotes) {
  const { startsAt, duration } = staffEvent
  const startsAtMoment = timeService.timeMoment(startsAt)
  const endsAtMoment = startsAtMoment.clone().add(duration, 'minutes')

  const staffEventStartsAt = startsAtMoment.format('HH:mm')
  const staffEventEndsAt = endsAtMoment.format('HH:mm')

  const shiftTime = `${staffEventStartsAt} - ${staffEventEndsAt}`
  const identityHash = 'dummyIdentityHash'
  const hasNotes = originalCell.hasNotes || !!newNote
  const isNewNoteCancelType = newNote && cancelledSubjects.includes(newNote.subject)
  const isCancelled = originalCell.isCancelled || isNewNoteCancelType

  return {
    ...originalCell,
    hasNotes,
    duration,
    shiftTime,
    isCancelled,
    identityHash,
    isDummy: true
  }
}

export { cellWithEmptyEvent, buildPTOCell, buildStaffEventCell, updateStaffEventCell, buildUnavailabilityCell }
