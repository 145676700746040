function Option({ opt, index, propKey, propValue, propText }) {
  return (
    <option key={opt.get(propKey) || index} value={opt.get(propValue)}>
      {opt.get(propText) === 'Class' ? 'Event' : opt.get(propText)}
    </option>
  )
}

export default function ImmutableSelect({
  options,
  propKey = 'id',
  propValue = 'id',
  propText = 'name',
  children,
  placeholder = false,
  ...rest
}) {
  const { value, disabled } = rest
  const style = !value && disabled ? { color: 'transparent' } : {}

  return (
    <select {...rest} style={style} value={value}>
      {children}
      {placeholder && (
        <option value="default" disabled>
          Please select
        </option>
      )}
      {options?.map((opt, index) => (
        <Option
          key={opt?.get(propKey) || index}
          opt={opt}
          index={index}
          propKey={propKey}
          propValue={propValue}
          propText={propText}
        />
      ))}
    </select>
  )
}
