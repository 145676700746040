import { Component } from 'react'
import './Header.scss'

export default class Header extends Component {
  render() {
    const { unit, title, printedDateRange } = this.props
    const unitName = unit.get('name')
    const dateRange = printedDateRange.get('title')

    return (
      <thead>
        <tr>
          <td>
            <div className="top-colontitle">
              <div className="bold">{unitName}</div>
              <div>{title}</div>
              <div>{dateRange}</div>
            </div>
            {this.props.children}
          </td>
        </tr>
      </thead>
    )
  }
}
