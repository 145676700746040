import classNames from 'classnames'
import moment from 'moment'

export default function WeekDay({ day, activeDateRange }) {
  const { isToday, dayOfMonth, dateTime } = day
  const outOfSchedule = moment(dateTime).isBetween(
    moment(activeDateRange?.get('startsAt')),
    moment(activeDateRange?.get('endsAt')),
    null,
    '()'
  )

  const className = classNames('small bold', {
    bb4: isToday,
    bb1: !isToday,
    'prev-day': !outOfSchedule
  })

  return (
    <div className={'col-1 upper bold trout'}>
      <div className={className}>
        <span className={isToday ? 'roundedBackground' : ''}>
          <b>{dayOfMonth}</b>
        </span>
      </div>
    </div>
  )
}
