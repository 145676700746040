import { PureComponent } from 'react'
import './StickyHeader.scss'

export default class StickyHeader extends PureComponent {
  render() {
    const { role, shift, filters, width, renderRole, renderShift, getRowHeight } = this.props

    const roleId = role?.get('id')
    const collapsed = filters.get('collapsed')
    const isCollapsed = collapsed.get(roleId) === true

    const roleRowHeight = getRowHeight(role)
    const shiftRowHeight = isCollapsed ? 0 : getRowHeight(shift)

    const height = roleRowHeight + shiftRowHeight

    return (
      <div className="hx-grid-header" style={{ height, width }}>
        {role && renderRole(role, { height: roleRowHeight })}
        {shift && renderShift(shift, { height: shiftRowHeight })}
      </div>
    )
  }
}
