import { PureComponent } from 'react'
import { ImmutableSelect } from 'Manager/components'
import { pick } from 'lodash'
import StaffEventController from './StaffEventController'
import Notes from '../Notes'
import NotesController from './StaffEventNotesController'
import StaffEventTemplateSelector from './StaffEventTemplateSelector'
import classNames from 'classnames'
import { hasShiftEvent } from '../cellDetailsUtils'

export default class ShiftForm extends PureComponent {
  constructor(props) {
    super(props)

    this.notesController = new NotesController(this)
    this.staffEventController = new StaffEventController(this)

    const { defaultState: staffEventState } = this.staffEventController
    const { defaultState: notesState } = this.notesController

    this.state = { ...staffEventState, ...notesState }
  }

  componentDidMount() {
    this.notesController.loadNotes()
    this.staffEventController.loadCellStaffEvents()
  }

  componentDidUpdate(prevProps, prevState) {
    const { cell } = this.props
    const { cell: prevCell } = prevProps

    const isCellChanged = cell !== prevCell
    if (isCellChanged) {
      this.notesController.loadNotes()
      this.staffEventController.loadCellStaffEvents()
    }
  }

  render() {
    // add support of cellSubIndex
    const { selectedParameters, eligibleUnits } = this.staffEventController
    if (!eligibleUnits || eligibleUnits.size === 0) {
      return null
    }
    const { cell, isProcessing, onCancel, isShiftFormShown } = this.props
    const { isNotesLoading, isStaffEventLoading } = this.state
    const isLoading = isNotesLoading || isStaffEventLoading
    const { unitId, eventTemplate } = selectedParameters

    const hasEvent = hasShiftEvent(cell)

    const isFormFilled = !!unitId && !!eventTemplate
    const isInputDisabled = isLoading || isProcessing
    const isActionDisabled = isLoading || isProcessing || !isFormFilled

    const className = classNames('add-shift bt1', { hide: !isShiftFormShown })

    return (
      <div className={className}>
        {isLoading && this.renderSpinner()}
        {!isLoading && this.renderForm()}

        <footer className="p20">
          <button className="button compact secondary borderless ph0" onClick={onCancel} disabled={isInputDisabled}>
            Cancel
          </button>

          {hasEvent && (
            <>
              <button
                disabled={isActionDisabled}
                className="button float-right compact bg-skyblue _update-button"
                onClick={this.update}
              >
                Update
              </button>

              <span className="float-right">&nbsp;</span>

              <button
                className="button float-right compact primary _delete-button"
                onClick={this.delete}
                disabled={isInputDisabled}
              >
                Remove
              </button>
            </>
          )}

          {!hasEvent && (
            <button
              disabled={isActionDisabled}
              className="button float-right compact bg-skyblue _create-button"
              onClick={this.create}
            >
              Add
            </button>
          )}
        </footer>
      </div>
    )
  }

  renderSpinner() {
    return (
      <section className="p15">
        <div className="loader">
          <i className="loading spindle" />
        </div>
      </section>
    )
  }

  renderForm() {
    const { eligibleUnits, eventTemplates, selectedParameters, selectedEventTemplateId } = this.staffEventController
    const { unitId, eventTemplate } = selectedParameters
    const { cell } = this.props

    const unitSelectProps = {
      disabled: !unitId,
      options: eligibleUnits,
      value: unitId,
      onChange: this.onUnitChange
    }

    const eventTemplateSelectorProps = pick(
      {
        ...this.props,
        eventTemplates,
        eventTemplate,
        setEventTemplate: this.staffEventController.setEventTemplate,
        selectedEventTemplateId,
        loadNotes: () => this.notesController.loadNotes()
      },
      [
        'timeService',
        'day',
        'eventTemplates',
        'eventTemplate',
        'shiftsMap',
        'setEventTemplate',
        'selectedEventTemplateId',
        'loadNotes'
      ]
    )

    const { notes } = this.notesController
    const notesProps = { notes, notesController: this.notesController }

    const hasEvent = hasShiftEvent(cell)
    return (
      <>
        <section className="p15">
          <div className="row pt20">
            <div className="form-item col col-4 text-right pr10">
              <label>UNIT</label>
            </div>
            <div className="form-item col col-7">
              <ImmutableSelect {...unitSelectProps} disabled />
            </div>
          </div>
          <StaffEventTemplateSelector {...eventTemplateSelectorProps} hasEvent={hasEvent} />
        </section>

        <Notes {...notesProps} />
      </>
    )
  }

  onUnitChange = (e) => {
    const unitId = e.currentTarget.value
    this.staffEventController.unitId = unitId
  }

  create = async () => {
    const { createStaffEventsForSelection, onCancel, getStaffEventDetails } = this.props
    const eventParameters = this.staffEventController.getEventParameters()
    getStaffEventDetails?.(eventParameters)
    const { note } = this.notesController
    createStaffEventsForSelection(eventParameters, note)

    onCancel()
  }

  update = async () => {
    const { onCancel } = this.props
    const { staffEvent } = this.state
    const selectedEventParameters = this.staffEventController.getEventParameters()
    const selectedShiftId = selectedEventParameters.get('shiftId')

    const isShiftChanged = staffEvent.some((item) => item.shiftId === selectedShiftId)
    !isShiftChanged ? this.changeEvent() : this.updateEvent()

    onCancel()
  }

  updateEvent = () => {
    const { staffEvent } = this.state
    const { updateEventForSelection } = this.props

    const eventParamaters = this.staffEventController.getEventParameters()
    const selectedShiftId = eventParamaters.get('shiftId')
    const selectedStaffEvent = staffEvent.find((item) => item.shiftId === selectedShiftId)
    const { type } = selectedStaffEvent || {}

    const { noteToAdd, notesToUpdate } = this.notesController

    return updateEventForSelection(eventParamaters, { type }, noteToAdd, notesToUpdate)
  }

  changeEvent = async () => {
    const { changeStaffEventForSelection } = this.props
    const eventParamaters = this.staffEventController.getEventParameters()
    const { note } = this.notesController

    return changeStaffEventForSelection(eventParamaters, note)
  }

  delete = async () => {
    const { deleteSelectedStaffEvents, onCancel, cell } = this.props
    const { selectedEventTemplateId } = this.staffEventController
    const cellStaffEvent = (cell.get('staffEvents') || [])
      ?.toJS()
      .filter((e) => selectedEventTemplateId?.includes(e.shiftId))
    const staffEventId = cellStaffEvent[0]?.id

    deleteSelectedStaffEvents(staffEventId)
    onCancel()
  }
}
