import { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './StringInputAutoComplete.scss'

export default class StringInputAutoComplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  }

  static defaultProps = {
    suggestions: []
  }

  constructor(props) {
    super(props)

    this.state = {
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: this.props.defaultValue
    }
  }

  onChange = (e) => {
    const { suggestions } = this.props
    const userInput = e.currentTarget.value

    const filteredSuggestions = suggestions
      ? suggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1)
      : []

    this.setState({
      activeSuggestion: -1,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    })

    this.props.onChange(e)
  }

  onClick = (e) => {
    this.setState({
      activeSuggestion: -1,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    })
  }

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: -1,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      })
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === -1) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 })
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion === filteredSuggestions.length) {
        return
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 })
    }
  }

  setInputType = (definition) => {
    const map = {
      boolean: 'checkbox',
      string: 'text',
      number: 'number',
      password: 'password'
    }
    return definition.format || map[definition.type] || 'text'
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput },
      setInputType
    } = this

    const { definition, placeholder, expectedFormat, field, defaultSuggestion, warning } = this.props

    let suggestionsListComponent

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active'
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              )
            })}
          </ul>
        )
      } else if (defaultSuggestion) {
        suggestionsListComponent = <div className="no-suggestions">{defaultSuggestion}</div>
      }
    }

    return (
      <div className="autocomplete-container">
        <label
          className={classNames({
            hide: !definition.label
          })}
        >
          <span className="form-label">{definition.label || field}</span>
          &nbsp;
          {expectedFormat && <span className="expectedFormat">{expectedFormat}</span>}
          &nbsp;
          {definition.required && <span className="req">*</span>}
        </label>
        <input
          className={classNames('save-template-input', { 'warning-box': warning })}
          type={setInputType(definition)}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder={userInput ? userInput : placeholder}
        />
        {suggestionsListComponent}
      </div>
    )
  }
}
