import { navigate } from 'Navigation'

export default function Subheader({ headerText, canCreate, canGoBack, goBackText, history }) {
  const handleGoBack = () => history.goBack()
  return (
    <div className="hx-announcements row calendar-toolbar">
      <div className="col-4" />

      <div className="col-4 row align-middle align-center ">
        <div data-testid="announcement-header-title" className="title">
          <h1>{headerText}</h1>
        </div>
      </div>

      <div className="col-4 row align-right">
        {canCreate && (
          <sh-button
            color="primary"
            onClick={() => navigate.from.Announcements.to.AnnouncementsCreate({})}
            label="Create New"
            size="m"
          />
        )}
      </div>

      {canGoBack && (
        <div className="go-back-link" onClick={handleGoBack}>
          <span className="back-arrow"></span>
          <span>{goBackText}</span>
        </div>
      )}
    </div>
  )
}
