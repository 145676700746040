import { PureComponent } from 'react'
import classNames from 'classnames'
import Header from './Header'
import { DateRangeState } from 'Manager/components'
import './DateRange.scss'

export default class DateRange extends PureComponent {
  render() {
    const { dateRange, countDateRangeTimeOffs, style } = this.props

    const title = dateRange.get('title')
    const isActive = dateRange.get('isActive')
    const state = dateRange.get('state')
    const days = dateRange.get('days')
    const endsAt = dateRange.get('endsAt')
    const dateRangeIndex = dateRange.get('index')
    const timeOffRequestsCount = countDateRangeTimeOffs(dateRangeIndex)

    return (
      <div className="hx-date-range pt30" style={style}>
        <div className="row align-middle">
          <h4 className="bold river-bad relative">
            {title}
            {timeOffRequestsCount > 0 && <div className="bg-danger _count-timeOffRequests">{timeOffRequestsCount}</div>}
          </h4>
          <div className="pl30 pb16">
            <DateRangeState state={state} endDate={endsAt} />
          </div>
        </div>
        <div
          className={classNames({
            'hx-time-off-schedule round': true,
            current: isActive
          })}
        >
          <section className="hx-calendar-header row bg-white align-right">
            <header className="col-10 bg-alabaster">
              <Header days={days} />
            </header>
          </section>
        </div>
      </div>
    )
  }
}
