import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [Field('userProfileFullname', 'name'), Field('createdAt', 'created', { format: 'date-time' })]

function StaffPreferencesStore() {
  return AdminStore({
    fields,
    entityName: 'StaffPreferences',
    api: {
      index: 'indexStaffPreferences'
    },
    header: {
      title: 'Preferences',
      createLabel: 'Create Shift Preference'
    }
  })
}

export default StaffPreferencesStore
