import { PureComponent } from 'react'
import { List } from 'immutable'
import { pick } from 'lodash'
import SelectScheduleDuration from './SelectScheduleDuration'
import DateRangeService from 'services/DateRangeService'
import { withAppContext } from 'AppContext'
import { t } from 'i18n'
import './CreateSchedules.scss'

const openScheduleOptions = {
  title: t('open'),
  confirmation: {
    message: t('calendar.workflow.open_schedule.disclosure'),
    options: {
      accept: t('calendar.workflow.open_schedule.confirm'),
      cancel: t('calendar.workflow.go_back'),
      title: t('calendar.workflow.open_schedule.title'),
      type: 'warning'
    }
  }
}

export class CreateSchedules extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      shouldCreateSchedulesWhenSchedulesLoaded: false
    }
  }

  componentDidUpdate(prevProps) {
    const { unit } = this.props
    const { unit: prevUnit } = prevProps
    const { shouldCreateSchedulesWhenSchedulesLoaded } = this.state

    const isSchedulesLoaded = unit.get('isSchedulesLoaded')
    const prevIsSchedulesLoaded = prevUnit.get('isSchedulesLoaded')

    const isSchedulesJustLoaded = isSchedulesLoaded && !prevIsSchedulesLoaded

    if (isSchedulesJustLoaded && shouldCreateSchedulesWhenSchedulesLoaded) {
      this.createSchedules()
      this.setState({ shouldCreateSchedulesWhenSchedulesLoaded: false })
    }
  }

  render() {
    const { activeDateRange, unit, timeService } = this.props
    const { isLoading } = this.state

    let hasPreviousInactiveDateRanges = false
    const isScheduleCreated = activeDateRange.get('scheduleId')
    const startsAt = activeDateRange.get('startsAt') || null
    const startsAtMoment = timeService.timeMoment(startsAt)

    const schedules = unit.get('schedules') || List()

    // TODO: to check if this is expected behavior:
    //       With this logic we still able to create multiple schedules in one request.
    //       This can happen when unit doesn't have any schedules yet,
    //       and we navigate to n-number of schedule forward and click `Open` button:
    if (schedules.size > 0) {
      const lastSchedule = unit.get('schedules').last()
      const lastScheduleEndsAt = lastSchedule.get('endsAt')
      hasPreviousInactiveDateRanges = startsAtMoment.isAfter(lastScheduleEndsAt)
    }

    if (isScheduleCreated) {
      return null
    }

    const createSchedules = async () => {
      const { Dialog } = this.props
      if (isLoading) {
        return
      }
      if (hasPreviousInactiveDateRanges) {
        Dialog.alert(message, { type: 'warning', title: t('just_oops'), accept: t('ok') })
        return
      }
      const dialogConfirmed = await Dialog.confirm(
        openScheduleOptions.confirmation.message,
        openScheduleOptions.confirmation.options
      )
      if (!dialogConfirmed) {
        return
      }

      const isSchedulesLoaded = unit.get('isSchedulesLoaded')
      if (!isSchedulesLoaded) {
        this.setState({
          shouldCreateSchedulesWhenSchedulesLoaded: true,
          isLoading: true
        })

        return
      }

      this.createSchedules()
    }

    const selectScheduleDurationProps = pick(
      {
        ...this.props,
        isLoading: isLoading,
        setLoadingStatus: this.setLoadingStatus
      },
      ['activeDateRange', 'generalStore', 'timeService', 'isLoading', 'setLoadingStatus']
    )

    const isMonthlySchedule = unit.get('scheduleType') === 'monthly'

    return (
      <div className="hx-open-availability-requests-period">
        {/* Hide Edit Schedule length if it's a monthly schedule*/}
        {!isMonthlySchedule && <SelectScheduleDuration {...selectScheduleDurationProps} />}
        <div style={{ display: 'inline-block' }}>
          {this.state.isLoading && <sh-spinner overlay label="Schedule is being updated"></sh-spinner>}
          <sh-button
            onClick={createSchedules}
            color="primary"
            disabled={isLoading ? true : undefined}
            label={openScheduleOptions.title}
          />
        </div>
      </div>
    )
  }

  async createSchedules() {
    const { activeDateRange, unit, timeService, generalStore } = this.props
    this.setState({ isLoading: true })

    const index = DateRangeService.getActiveDateRangeIndex(activeDateRange, unit, timeService)
    const duration = activeDateRange.get('duration')
    const unitId = activeDateRange.get('unitId')
    const scheduleSizesInWeeks = DateRangeService.getDurationsForNewSchedules(unit, timeService, index, duration)

    try {
      await generalStore.createSchedules(unitId, scheduleSizesInWeeks)
    } catch (e) {
      /* This is intentional */
    }

    this.setState({ isLoading: false })
  }

  setLoadingStatus = (isLoading) => this.setState({ isLoading })
}

const message =
  'There are inactive schedules before the current schedule. Please open those first before you open this one.'
export default withAppContext(CreateSchedules)
