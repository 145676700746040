import { staffOvertimeHoursByShiftQuery } from '../Queries'
import { fromJS } from 'immutable'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'

export default LoadStaffOvertimeHoursByShift()

function LoadStaffOvertimeHoursByShift() {
  let gqlClient = null
  const actions = fluxStore({ loadStaffOvertimeHoursByShift })

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  async function loadStaffOvertimeHoursByShift(state, parameters) {
    const { unitId, startDate, endDate } = parameters
    const params = { unitId, startDate, endDate }
    const { staffOvertimeByShift } = await gqlClient.query(staffOvertimeHoursByShiftQuery, params)

    return state.setIn(['calendar', 'shiftOvertimeMap'], fromJS(staffOvertimeByShift))
  }
}
