import { Map } from 'immutable'
import { startCase } from 'lodash'
import StateController from './StateController'
import { PrintTemplate } from 'entityWrappers'
import { navigate } from 'Navigation'
import { t } from 'i18n'

export default class PrintController extends StateController {
  getPrintingFilters = (view) => {
    const printFilters = {
      full: {
        collapsed: Map(),
        hideEmptyRoles: true,
        hideSecondaryStaff: false
      },
      day: {
        collapsed: Map(),
        hideEmptyShifts: false,
        hideEmptyRoles: true,
        hideEmptyShiftsWithoutOpenShifts: true
      }
    }
    return view ? printFilters[view] : printFilters
  }

  get defaultState() {
    return {
      printStatus: {
        view: 'full',
        date: null,
        printNotes: false,
        isPrinting: false,
        isGeneratingPdf: false,
        isGeneratingPdfError: false,
        toSendPdf: false
      }
    }
  }

  get printStatus() {
    return this.state.printStatus
  }

  updatePrintStatus(status = {}, callback) {
    const printStatus = {
      ...this.defaultState.printStatus,
      ...status
    }

    this.setState({ printStatus }, callback)
  }

  resetDefaultState = () => {
    this.updatePrintStatus()
  }

  resetSpecificStateParams = () => {
    const { printNotes, view, date } = this.printStatus
    this.updatePrintStatus({ printNotes, view, date })
  }

  print = ({ printNotes = false, view = 'full', date }) => {
    this.updatePrintStatus({ printNotes, isPrinting: true, view, date })
  }

  generateSchedulePdf = ({ printNotes = false, view = 'full', date }) => {
    this.updatePrintStatus({ printNotes, isGeneratingPdf: true, view, date })
  }

  sendSchedulePdf = ({ printNotes = true, view = 'full', date }) => {
    this.updatePrintStatus({ printNotes, isGeneratingPdf: true, toSendPdf: true, view, date })
  }

  async onComponentUpdated(_prevProps, prevState) {
    const { isPrinting, isGeneratingPdf } = this.printStatus
    if (!(isPrinting || isGeneratingPdf)) {
      return
    }

    const { isPrinting: prevIsPrinting, isGeneratingPdf: prevIsGenerating } = prevState.printStatus

    const isPrintingJustActivated = isPrinting && !prevIsPrinting
    const isGeneratingPdfJustActivated = isGeneratingPdf && !prevIsGenerating
    const isActionJustActivated = isPrintingJustActivated || isGeneratingPdfJustActivated

    const isCalendarChanged = this.isCalendarChanged(prevState)
    if (!isActionJustActivated && !isCalendarChanged) {
      return
    }

    const { isDataLoaded, isDataJustLoaded } = this._isDataLoaded(prevState)
    const shouldGeneratePdf = isDataJustLoaded || (isDataLoaded && isActionJustActivated)
    if (!shouldGeneratePdf) {
      return
    }

    if (isPrinting) {
      this._showPrintPreview()
      return
    }

    try {
      await this._generatePdf()
    } catch {
      const message = this.printStatus.toSendPdf
        ? t('calendar.print.sendPdfError')
        : t('calendar.print.generatePdfError')
      console.error('Print pdf failed:', message)
    }
  }

  _isDataLoaded(prevState) {
    const { view, date } = this.printStatus

    const { filters } = this.state
    const { filters: prevFilters } = prevState

    const isFullView = view === 'full'
    if (isFullView) {
      const additionalFilters = this.getPrintingFilters(view)
      const printFilters = filters.merge(additionalFilters)
      const isCalendarLoaded = this.calendar.isCalendarLoaded(printFilters)
      const isOtherStaffLoaded = this.calendar.isOtherStaffLoaded()

      const prevCalendarWrapper = this.getPreviousCalendar(prevState)
      const prevPrintFilters = prevFilters.merge(additionalFilters)
      const isPrevCalendarLoaded = prevCalendarWrapper.isCalendarLoaded(prevPrintFilters)
      const isPrevOtherStaffLoaded = prevCalendarWrapper.isOtherStaffLoaded()
      const isCalendarJustLoaded = isCalendarLoaded && !isPrevCalendarLoaded
      const isOtherStaffJustLoaded = isOtherStaffLoaded && !isPrevOtherStaffLoaded

      let isDataLoaded = isCalendarLoaded
      let isDataJustLoaded = isCalendarJustLoaded

      if (!additionalFilters.hideSecondaryStaff) {
        isDataLoaded = isCalendarLoaded && isOtherStaffLoaded
        isDataJustLoaded =
          (isCalendarJustLoaded && isOtherStaffLoaded) ||
          (isOtherStaffJustLoaded && isCalendarLoaded) ||
          (isCalendarJustLoaded && isOtherStaffJustLoaded)
      }
      return { isDataLoaded, isDataJustLoaded }
    }

    const isDayView = view === 'day'
    if (isDayView) {
      const { unit } = this.props
      const unitId = unit.get('id')
      const isCalendarDayLoaded = this.calendar.isCalendarDayLoaded(unitId, date)

      const prevCalendarWrapper = this.getPreviousCalendar(prevState)
      const wasCalendarDayLoaded = prevCalendarWrapper.isCalendarDayLoaded(unitId, date)
      const isCalendarDayJustLoaded = isCalendarDayLoaded && !wasCalendarDayLoaded
      return { isDataLoaded: isCalendarDayLoaded, isDataJustLoaded: isCalendarDayJustLoaded }
    }

    return { isDataLoaded: false, isDataJustLoaded: false }
  }

  onCalendarPdfGenerateError = () => {
    this.updatePrintStatus({ isGeneratingPdfError: true })
  }

  _showPrintPreview = async () => {
    window.print()
    this.resetDefaultState()
  }

  _generateHtml = async () => {
    const printView = document.getElementById('hx-print-view')
    const stylesCollection = document.getElementsByTagName('style')

    const content = printView.outerHTML

    const styles = [].slice.call(stylesCollection)
    const stylesLinks = document.querySelectorAll('[rel=stylesheet]')

    const css = styles.reduce((memo, style) => `${memo} ${style.outerHTML}`, '')

    const template = new PrintTemplate(content, css, stylesLinks)
    return template.getHtml()
  }

  _generatePdf = async () => {
    const dateRange = this.props.calendar.get('dateRange')
    if (!dateRange.get('isReady')) {
      return
    }
    const html = await this._generateHtml()
    const facility = this.props.appState.getIn(['authentication', 'facility'])
    const scope = facility.get('scope')
    if (this.printStatus.toSendPdf) {
      this.props.calendarStore.createCalendarPdf(
        { scheduleHtml: html, sendEmail: true, filename: this._getFileName() },
        scope
      )
      this.resetDefaultState()
      return
    }

    setTimeout(async () => {
      // If print modal was closed before generation is finished:
      if (!this.printStatus.isGeneratingPdf) {
        return
      }
      try {
        const blob = await this.props.calendarStore.createCalendarPdf({ scheduleHtml: html }, scope)
        this._showFile(blob)
        this.resetSpecificStateParams()
        navigate.from.Calendar.to.Calendar()
      } catch (error) {
        console.error(error)
        this.onCalendarPdfGenerateError()
      }
    }, 50)
  }

  _showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const fileName = this._getFileName()

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator?.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, fileName)
      return
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')

    link.href = data
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // For Firefox it is necessary to delay revoking the ObjectURL
    setTimeout(() => {
      document.body.removeChild(link)
      window.URL.revokeObjectURL(data)
    }, 10)
  }

  _getFileName() {
    const { activeDateRange, unit } = this.props
    const { filters } = this.state
    const shiftTypeIds = filters.get('shiftTypes')
    const roleIds = filters.get('roleIds')

    const roles = unit.get('roles')
    const shiftTypes = unit.get('shiftTypes')

    const allRolesSelected = roleIds.size === roles.size
    const allShiftTypesSelected = shiftTypeIds.size === shiftTypes.size

    const unitName = unit.get('name')
    const dateRangeTitle = activeDateRange.get('title')
    const shiftFilter = allShiftTypesSelected
      ? ''
      : shiftTypeIds.reduce((name, shiftTypeId) => {
          const shiftType = shiftTypes.find((shiftType) => shiftType.get('id') === shiftTypeId)
          const shiftTypeTitle = shiftType.get('title')
          return `${name} ${startCase(shiftTypeTitle)}`
        }, '')
    const roleFilter = allRolesSelected
      ? ''
      : roleIds.reduce((name, roleId) => {
          const role = roles.find((role) => role.get('id') === roleId)
          return `${name} ${role.get('id')}`
        }, '')

    return `Schedule - ${unitName} (${dateRangeTitle})${roleFilter}${shiftFilter}.pdf`
  }
}
