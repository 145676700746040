import { Component } from 'react'
import CustomDropDown from '@humanics/he-react-common/lib/components/Form/CustomDropdown'
import CustomRadioToggle from 'components/Form/CustomRadioToggle'

export default class CustomSelectionInput extends Component {
  render() {
    const { form, onChangeSelection } = this.props

    const customDropDownDaysProps = {
      ...form.dayOnSelection,
      value: form.dayOnSelection.value,
      onChange: (e) => onChangeSelection(e, form.dayOnSelection.field),
      disabled: form.weekOnSelection.value,
      propKey: 'key',
      propValue: 'name'
    }
    const customDropDownWeekOnProps = {
      ...form.weekOnSelection,
      value: form.weekOnSelection.value,
      onChange: (e) => onChangeSelection(e, form.weekOnSelection.field),
      disabled: form.dayOnSelection.value,
      propKey: 'key',
      propValue: 'name'
    }
    const customDropDownDaysWeekProps = {
      ...form.weekDaySelection,
      value: form.weekDaySelection.value,
      onChange: (e) => onChangeSelection(e, form.weekDaySelection.field),
      propKey: 'index',
      propValue: 'label'
    }

    const customRadioToggleDayProps = {
      ...form.daySelection,
      isChecked: form.daySelection.value,
      onChange: (e) => onChangeSelection(e, form.daySelection.field),
      wrapperClassName: 'regent-gray semibold pt5 pr5',
      wrapperStyle: {},
      labelClassName: 'regent-gray'
    }

    const customRadioToggleDayOnProps = {
      ...form.weekSelection,
      isChecked: form.weekSelection.value,
      onChange: (e) => onChangeSelection(e, form.weekSelection.field),
      wrapperClassName: 'regent-gray semibold pt5 pr5',
      wrapperStyle: {},
      labelClassName: 'regent-gray'
    }
    const requiredOnTheFirst = form.daySelection.value
    const requiredOnTheSecond = form.weekSelection.value
    return (
      <div className="pb0 col-7">
        <div className="pb0 row">
          <div className="col-4">
            <label className="pr10 pb10 row">
              <span className="form-label">On the</span>
              {requiredOnTheFirst && <span className="pl5 req">*</span>}
            </label>
            <div className="pb0 row">
              <CustomRadioToggle {...customRadioToggleDayProps} />
              <CustomDropDown {...customDropDownDaysProps} />
              <p className="text-reccur-shift">day</p>
            </div>
          </div>
          <div className="col-8">
            <label className="pr10 pb10 row">
              <span className="form-label">On the</span>
              {requiredOnTheSecond && <span className="pl5 req">*</span>}
            </label>
            <div className="pb0 row">
              <CustomRadioToggle {...customRadioToggleDayOnProps} />
              <CustomDropDown {...customDropDownWeekOnProps} />
              <CustomDropDown {...customDropDownDaysWeekProps} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
