export default function Header() {
  return (
    <header className="hx-staff-list-header-normal bg-white row align-center align-middle bb1">
      <div className="col col-4 pl30">
        <label className="gulf-blue">Name</label>
      </div>

      <div className="col col-3">
        <label className="gulf-blue">Email</label>
      </div>

      <div className="col col-4">
        <label className="gulf-blue">Phone</label>
      </div>

      <div className="col col-1 text-center">
        <label className="gulf-blue">Status</label>
      </div>
    </header>
  )
}
