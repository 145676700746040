import { PureComponent } from 'react'
import classNames from 'classnames'
import CustomNumericInput from '@humanics/he-react-common/lib/components/Form/CustomNumericInput'
import './ShiftDayRequirement.scss'
import stores from 'stores'
import { fromJS, List } from 'immutable'
import { getStaffStrategyProperties } from 'utils'
import { pick, isEqual, isNil } from 'lodash'
import { navigate } from 'Navigation'

const { calendarStore } = stores

export class ShiftDayRequirement extends PureComponent {
  state = {
    targetCover: 0,
    initTargetCover: 0,
    staffScheduled: 0,
    staffMismatch: 0,
    newOpening: 0,
    isLoading: false,
    filters: fromJS([]),
    updatingTimeout: 0
  }

  componentDidMount() {
    this.initRequirements()
  }

  componentDidUpdate(prevProps, prevState) {
    let { openShiftPopupProps } = this.props
    let { openShiftPopupProps: prevOpenShiftPopupProps } = prevProps

    const attrs = ['roleIndex', 'shiftIndex', 'dayIndex']
    const shiftDay = pick(openShiftPopupProps, attrs)
    const prevShiftDay = pick(prevOpenShiftPopupProps, attrs)

    if (!isEqual(shiftDay, prevShiftDay)) {
      this.initRequirements()
    }
  }

  initRequirements() {
    const { calendar, filters, openShiftPopupProps } = this.props

    const { roleIndex, shiftIndex, dayIndex } = openShiftPopupProps
    const shiftPath = ['roles', roleIndex, 'shifts', shiftIndex]
    const shiftDay = calendar.getIn([...shiftPath, 'shiftDays', dayIndex])
    const staffRequirementCustom = shiftDay?.get('staffRequirementCustom')
    let staffRequirement = shiftDay?.get('staffRequirement')

    if (!isNil(staffRequirementCustom)) {
      staffRequirement = staffRequirementCustom
    }
    staffRequirement = staffRequirement || 0

    const staffMismatch = shiftDay?.get('staffMismatch') || 0
    const staffScheduled = Math.abs(staffRequirement - staffMismatch)

    const targetCover = staffScheduled + staffMismatch

    this.setState({
      initTargetCover: targetCover,
      newOpening: 0,
      targetCover,
      staffScheduled,
      filters,
      updatingTimeout: 0
    })
  }

  onChange = (targetCover) => {
    const { isLoading, initTargetCover } = this.state

    if (isLoading) {
      return
    }

    if (this.state.updatingTimeout) {
      clearTimeout(this.state.updatingTimeout)
    }

    const newOpening = targetCover - this.state.initTargetCover
    this.setState({ targetCover, newOpening })

    if (targetCover !== initTargetCover) {
      this.updateShiftDayRequirement(targetCover)
    }
  }

  updateShiftDayRequirement = (targetCover) => {
    const { reloadCalendarForShift, calendar, unitId, openShiftPopupProps } = this.props

    const { roleIndex, shiftIndex, dayIndex } = openShiftPopupProps
    const shift = calendar.getIn(['roles', roleIndex, 'shifts', shiftIndex])
    const date = calendar.getIn(['days', dayIndex, 'dateTime'])

    if (!shift || !date || targetCover === '') {
      return
    }

    const shiftId = shift.get('id')
    const userIds = shift
      .get('staff')
      .map((s) => s.get('id'))
      .toArray()
    const params = { unitId, shiftId, date, number: targetCover, dayIndex }

    this.setState({
      updatingTimeout: setTimeout(async () => {
        this.setState({ isLoading: true })
        await calendarStore.createShiftDayRequirement(params)
        await reloadCalendarForShift({ shift, userIds })
        this.setState({ isLoading: false, initTargetCover: targetCover })
      }, 500)
    })
  }

  render() {
    const { targetCover, staffScheduled, filters, isLoading } = this.state
    const strategy = filters.getIn(['displayOptions', 'coverage', 'viewCoverageAs'])
    const { staffDelta, className, isBalanced, isUnderstaffed } = getStaffStrategyProperties(
      strategy,
      staffScheduled,
      targetCover
    )
    const inputProps = {
      type: 'math',
      order: '-i+',
      definition: {
        min: 0,
        disabled: isLoading
      },
      value: targetCover,
      onChange: this.onChange,
      formItemClasses: 'col-6'
    }

    return (
      <section className="form form-checkboxes hx-shift-day-requirement">
        <div className="p20 pb0">
          <div className="row align-middle pb20">
            <div className="col-6 text-right">
              <label>
                <span className="form-label">Scheduled Staff</span>
              </label>
            </div>
            <div className="col-6 text-center">
              <div className={classNames('hx-calendar', strategy === 'scheduledStaff' && className)}>
                {staffScheduled}
              </div>
            </div>
          </div>
          <div className="row align-middle pb10">
            <div className="col-6 text-right">
              <label>
                <span className="form-label">Target Cover</span>
              </label>
            </div>
            <CustomNumericInput {...inputProps} />
          </div>
          {!isBalanced && (
            <div className="row align-middle p10 ph0">
              <hr className="col-12" />
              <div className="col-6 text-right">
                <label>
                  <span className="form-label">{isUnderstaffed ? 'Understaffed' : 'Overstaffed'}</span>
                </label>
              </div>
              <div className="col-6 text-center">
                <div className={classNames('hx-calendar', strategy === 'staffDelta' && className)}>{staffDelta}</div>
              </div>
            </div>
          )}
        </div>

        {this.renderFooter()}
      </section>
    )
  }

  renderFooter = () => {
    const { calendar, openShiftPopupProps } = this.props

    const { targetCover, staffScheduled, filters } = this.state
    const { roleIndex, shiftIndex, dayIndex } = openShiftPopupProps

    const strategy = filters.getIn(['displayOptions', 'coverage', 'viewCoverageAs'])
    const { isUnderstaffed } = getStaffStrategyProperties(strategy, staffScheduled, targetCover)
    const shiftPath = ['roles', roleIndex, 'shifts', shiftIndex]
    const shiftDay = calendar.getIn([...shiftPath, 'shiftDays', dayIndex])
    let staffEventUserIds = List()

    const shiftStaff = calendar.getIn([...shiftPath, 'staff'])

    shiftStaff?.forEach((staff) => {
      const staffId = staff.get('id')
      const staffEventId = staff.getIn(['cells', dayIndex, 'staffEventId'])
      if (staffEventId) {
        staffEventUserIds = staffEventUserIds.push(staffId)
      }
    })

    const shiftDayId = shiftDay?.get('id')
    const id = shiftDay?.getIn(['opportunity', 'id'])
    const acceptedByUserIds = shiftDay?.getIn(['opportunity', 'acceptedByUserIds']) || List()

    const isPendingApproval = acceptedByUserIds.filter((userId) => !staffEventUserIds.includes(userId)).size > 0
    const isPosted = !!id

    let statusTitle
    let buttonTitle

    switch (true) {
      case isUnderstaffed && !isPosted:
        buttonTitle = 'Manage Need'
        break

      case isUnderstaffed && isPosted && !isPendingApproval:
        statusTitle = 'Open Shift Posted'
        buttonTitle = 'Manage Need'
        break

      case isUnderstaffed && isPosted && isPendingApproval:
        statusTitle = 'Needs Approval'
        buttonTitle = 'Manage Need'
        break

      default:
        buttonTitle = 'Review'
    }

    const onlyApprovalPending = isUnderstaffed && isPosted && isPendingApproval

    const status = (
      <div
        className={classNames({
          'pr10 smaller': true,
          hide: !statusTitle,
          sun: isUnderstaffed && isPosted && !isPendingApproval,
          cornflowerblue: isUnderstaffed && isPosted && isPendingApproval
        })}
      >
        {statusTitle}
      </div>
    )

    return (
      <footer className="row align-right align-middle bt1 p10">
        {status}

        <sh-button
          color={onlyApprovalPending ? 'primary' : 'tertiary'}
          onClick={() => this.navigateToOpenShift({ id, shiftDayId })}
          label={buttonTitle}
          size="auto"
        />
      </footer>
    )
  }

  navigateToOpenShift = ({ id, shiftDayId }) => {
    const isPosted = !!id

    if (isPosted) {
      navigate.from.Calendar.to.ShiftOpportunity({ id })
    } else {
      navigate.from.Calendar.to.OpenShift({ shiftDayId })
    }
  }
}

export default ShiftDayRequirement
