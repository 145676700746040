import { PureComponent } from 'react'
import './Week.scss'

const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export default class Week extends PureComponent {
  render() {
    const { width, days } = this.props

    const mapper = (day, index) => {
      const { dayOfMonth } = day
      const weekDay = weekDays[index]

      const className = "col-1 _headerCell white 'tiny upper"

      return (
        <div key={index} className={className}>
          <div className="bold">{dayOfMonth}</div>
          <div className="upper">{weekDay}</div>
        </div>
      )
    }

    return (
      <div
        className="bl1"
        style={{
          display: 'inline-block',
          width
        }}
      >
        <div className="row-7">{days.map(mapper)}</div>
      </div>
    )
  }
}
