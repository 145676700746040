import StudyCodeDropdown from './StudyCodeDropdown'

import { useEffect, useState } from 'react'
import { LocationDropdown, DatePicker, TimePicker } from '../Common'
import { KEYBOARD_KEYS } from 'Common/constants/KeyboardKeys'
import { ActivityTracker } from '../Common/activityTracker'

export interface ISlotPreferencesProps {
  handleSlotSearch: (
    location: string,
    selectedStudyCodes: string[],
    date: string,
    time: string,
    insuranceAuthorization: boolean
  ) => void
  timeService: any
  resetKey: number
}

const SlotPreferences = ({ handleSlotSearch, timeService, resetKey }: ISlotPreferencesProps) => {
  const [location, setLocation] = useState('')
  const [selectedStudyCodes, setSelectedStudyCodes] = useState<string[]>([])
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [insuranceAuthorization, setInsuranceAuthorization] = useState(false)

  useEffect(() => {
    setLocation('')
    setSelectedStudyCodes([])
    setDate('')
    setTime('')
    setInsuranceAuthorization(false)
  }, [resetKey])

  const handleKeyUpEventForInsuranceAuthorization = (event: KeyboardEvent) => {
    if (event.code === KEYBOARD_KEYS.Space) {
      handleInsuranceAuthorizationClick()
    }
  }

  const handleInsuranceAuthorizationClick = () => {
    ActivityTracker.StartTracking()
    setInsuranceAuthorization((prev) => !prev)
  }

  return (
    <sh-pane slot="left" label="Slot search" style={{ zIndex: '201' }}>
      <sh-text size="header-2">Study information</sh-text>

      <sh-checkbox
        label="Insurance authorization"
        active={insuranceAuthorization ? true : undefined}
        onClick={handleInsuranceAuthorizationClick}
        onKeyUp={handleKeyUpEventForInsuranceAuthorization}
      ></sh-checkbox>
      <StudyCodeDropdown resetKey={resetKey} setSelectedStudyCodes={setSelectedStudyCodes} />

      <sh-divider></sh-divider>

      <sh-text size="header-2">Patient preferences</sh-text>

      <LocationDropdown location={location} setLocation={setLocation} trackActivity={true} />
      <DatePicker date={date} setDate={setDate} timeService={timeService} trackActivity={true} />
      <TimePicker time={time} setTime={setTime} trackActivity={true} />

      <sh-button
        label="Search"
        type="primary"
        slot="footer"
        onClick={() => {
          handleSlotSearch(location, selectedStudyCodes, date, time, insuranceAuthorization)
        }}
      ></sh-button>
    </sh-pane>
  )
}
export default SlotPreferences
