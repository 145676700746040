const { capitalize, startCase } = require('lodash')

const formatConstraintName = (constraintName) => {
  if (typeof constraintName !== 'string') {
    return constraintName
  }
  return capitalize(startCase(constraintName))
}

export { formatConstraintName }
