import { PureComponent } from 'react'
import { Staff } from 'entityWrappers'

export default class Details extends PureComponent {
  render() {
    const { facilityUser } = this.props
    const staff = new Staff(facilityUser)
    const { phoneNumbers, hasPreferences, staffPreferences } = staff
    const { preferenceNames } = staffPreferences

    return (
      <div className="profile-dropdown-options-up">
        <ul className="bg-brightgray text-left rounded6">
          {phoneNumbers.map((phone, index) => (
            <li key={index}>
              <a className="_phone-number" href={`tel:${phone}`}>
                <span className="river-bad semibold">{phone}</span>
              </a>
            </li>
          ))}
          <li>
            {hasPreferences ? (
              <a href="." className="_preferences">
                <span className="river-bad semibold">
                  Extra shift preferences:
                  <br />
                  {preferenceNames.join(', ')}
                </span>
              </a>
            ) : (
              <a href="." className="_preferences">
                <span className="river-bad semibold">No preferences set</span>
              </a>
            )}
          </li>
        </ul>
      </div>
    )
  }
}
