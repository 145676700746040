import { List } from 'immutable'

export default class StaffPreferences {
  static preferenceNamesMap = {
    willingToWorkDays: 'Days',
    willingToWorkNights: 'Nights',
    willingToWorkHolidays: 'Holidays',
    willingToWorkWeekends: 'Weekends',
    willingToWorkOvertime: 'Overtime',
    willingToWorkFloat: 'Float',
    willingToWorkCrisisPay: 'CrisisPay',
    willingToWorkBonusPay: 'BonusPay',
    willingToWorkExtremelyShortShifts: 'ExtremelyShortShifts',
    willingToWorkPartialShifts: 'PartialShifts',
    willingToWorkExtraShifts: 'ExtraShifts',
    willingToWorkOutsideHomeUnit: 'OutsideHomeUnit',
    willingToWorkHardToFillShifts: 'HardToFillShifts',
    willingToWorkMondays: 'Mondays',
    willingToWorkTuesdays: 'Tuesdays',
    willingToWorkWednesdays: 'Wednesdays',
    willingToWorkThursdays: 'Thursdays',
    willingToWorkFridays: 'Fridays',
    willingToWorkSaturdays: 'Saturdays',
    willingToWorkSundays: 'Sundays'
  }

  constructor(preferences) {
    this.preferences = preferences || List()
  }

  get preferenceNames() {
    if (this._preferenceNames) {
      return this._preferenceNames
    }

    this._preferenceNames = this.selectedPreferences.map((preference) => StaffPreferences.getPreferenceName(preference))

    return this._preferenceNames
  }

  get selectedPreferences() {
    if (this._selectedPreferences) {
      return this._selectedPreferences
    }

    this._selectedPreferences = this.preferences.filter((preference) =>
      StaffPreferences.isPreferenceSelected(preference)
    )

    return this._selectedPreferences
  }

  static isPreferenceSelected(preference) {
    return preference.get('value')
  }

  static getPreferenceName(preference) {
    return StaffPreferences.preferenceNamesMap[preference.get('name')]
  }
}
