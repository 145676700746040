import { Map, fromJS } from 'immutable'
import { t } from 'i18n'
import { useEffect } from 'react'

interface ISwappingStaff {
  staffAvailableForSwap: any,
  handleEmptyStateLabel: any,
  showSwappingStaff: any,
  requestShiftSwapData: any,
  handleStaffSelected: any
}

const getProfileDetails = (staff: any) => {
  return Map({
    firstName: staff.get('userProfileFirstName'),
    lastName: staff.get('userProfileLastName'),
    avatarUrl: staff.get('userProfileAvatarUrl'),
    position: staff.get('position')
  })
}

const SwappingStaff = ({
  staffAvailableForSwap,
  handleEmptyStateLabel,
  showSwappingStaff,
  requestShiftSwapData,
  handleStaffSelected
}: ISwappingStaff) => {

  const staffPermittedForSwap = staffAvailableForSwap?.filter((staff: any) =>
    staff.get('staffProfile').get('hasPermissions')
  )

  useEffect(() => {
    if (requestShiftSwapData.swappingShift.get('date') && staffPermittedForSwap.size === 0 && showSwappingStaff) {
      handleEmptyStateLabel(t('staffCalendar.shiftSwap.emptyStateLabels.noSwappingStaff'))
    }
  }, [requestShiftSwapData, staffPermittedForSwap, showSwappingStaff, handleEmptyStateLabel])

  const handleSwappingStaffSelection = (staff: any) => {
    handleStaffSelected(fromJS(staff))
  }

  return (
    <div>
      {showSwappingStaff &&
        requestShiftSwapData.yourShift.get('selectedShift').size > 0 &&
        requestShiftSwapData.swappingShift.get('date') &&
        staffPermittedForSwap.size > 0 && (
          <div className="swapping-staff-list p10 mt10 bg-whitesmoke">
            <div className="strong pb10">{t('staffCalendar.shiftSwap.labels.swappingStaff')}:</div>
            <sh-radio-group>
              {staffPermittedForSwap.map((staff: any) => {
                const staffProfile = staff.get('staffProfile')
                const staffId = staffProfile.get('id')
                const profile = getProfileDetails(staffProfile)
                const fullName = `${profile.get('lastName')} ${profile.get('firstName')}`
                const avatarUrl = profile.get('avatarUrl')
                return (
                  <div key={staffId} className="swapping-staff pt10">
                    <sh-radio-button label="" onClick={() => handleSwappingStaffSelection(staff)} />
                    <div className="pl10">
                      <sh-user-identifier
                        slot="user"
                        name={fullName}
                        info={profile.get('position')}
                        image={avatarUrl && `url(${avatarUrl})`}
                      ></sh-user-identifier>
                    </div>
                  </div>
                )
              })}
            </sh-radio-group>
          </div>
        )}
    </div>
  )
}

export default SwappingStaff
