import { fromJS } from 'immutable'
import { keyBy } from 'lodash'
import { schedulesMetadataQuery } from '../Queries'

function SchedulesMetadata() {
  let gqlClient = null
  let updateGeneralData = null
  const actions = { loadSchedulesMetadata }

  return { initialize, actions }

  function initialize(context) {
    ;({ updateGeneralData, gqlClient } = context)
  }

  async function loadSchedulesMetadata(scheduleIds) {
    const parameters = { ids: scheduleIds }
    const { schedulesMetadata } = await gqlClient.query(schedulesMetadataQuery, parameters)
    const metadataMap = keyBy(schedulesMetadata, 'id')

    return updateGeneralData((state) =>
      state.updateIn(['unit', 'schedulesMetadataMap'], (schedulesMetadataMap) =>
        schedulesMetadataMap.merge(fromJS(metadataMap))
      )
    )
  }
}

export default SchedulesMetadata()
