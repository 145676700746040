import { Header } from './Header'
import VShiftTitle from './VShiftTitle'
import './Shift.scss'
import classNames from 'classnames'

export default function VShift(props) {
  const { shift, style, shiftRange, calendarType, mode } = props
  const isLoaded = shift.get('isLoaded')
  const isOnCallShift = shift.get('isOnCall')

  if (shift.get('hidden')) {
    return null
  }

  const additionalCSS = calendarType !== 'calendar' ? { height: '2.4rem' } : {}
  return (
    <div className="_shift" style={style}>
      <header className={`hx-calendar-shift ${isOnCallShift ? 'on-call-avatar-row' : ''}`}>
        <div
          className={classNames('row align-middle bg-mystic', { 'flex-nowrap': calendarType !== 'calendar' })}
          style={{ ...additionalCSS }}
        >
          <VShiftTitle shift={shift} shiftRange={shiftRange} calendarType={calendarType} />
          <Header {...props} isShiftDaysLoaded={isLoaded} calendarType={calendarType} mode={mode} />
        </div>
      </header>
    </div>
  )
}
