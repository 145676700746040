import { Component } from 'react'
import './ImagePreviewComponent.scss'
import EditButtons from './EditButtons/EditButtons'
import ImageIcon from '../../../../styles/web_v3/image.svg'

export default class ImagePreviewComponent extends Component {
  state = {
    isHovering: false,
    isActive: false
  }

  render() {
    const { hasDetailsPage, isFormEditable, component, activeComponent } = this.props

    const {
      insertDetailsComponentAbove,
      insertDetailsComponentBelow,
      deleteDetailsComponent,
      onDetailsComponentClicked
    } = this.props

    const extendedPreviewImageStyle = {
      width: '100%',
      opacity: '1',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      background: 'white',
      boxSizing: 'inherit'
    }

    const uploadedImageStyle = {
      width: '100%',
      marginTop: '0',
      background: 'rgba(103, 122, 145, 0.13)',
      boxSizing: 'inherit'
    }

    const imagePreviewDivStyle = {
      width: '100%',
      paddingTop: '40.652%',
      overflow: 'hidden',
      position: 'relative',
      opacity: '1',
      display: 'flex',
      justifyContent: 'center',
      boxSizing: 'inherit'
    }

    const imagePreviewDivImgStyle = {
      position: 'absolute',
      top: '0',
      bottom: '0',
      margin: 'auto',
      boxSizing: 'inherit',
      maxWidth: '100%'
    }

    return (
      // adds highlight grey background behind selected component
      <div
        className={
          component.id === activeComponent.id && isFormEditable
            ? 'extended-preview-image'
            : 'extended-preview-image-unclicked'
        }
        style={extendedPreviewImageStyle}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        {/* Image div */}
        <div id="uploaded-image" style={uploadedImageStyle}>
          <div
            className="image-preview-div"
            style={imagePreviewDivStyle}
            onClick={hasDetailsPage ? onDetailsComponentClicked : null}
          >
            <img
              style={imagePreviewDivImgStyle}
              src={this.props.imageSrc || ImageIcon}
              alt=""
              onClick={hasDetailsPage ? onDetailsComponentClicked : null}
            />
          </div>

          {/* Buttons */}
          {this.state.isHovering && hasDetailsPage && isFormEditable && (
            <div>
              <EditButtons
                insertDetailsComponentAbove={insertDetailsComponentAbove}
                insertDetailsComponentBelow={insertDetailsComponentBelow}
                deleteDetailsComponent={deleteDetailsComponent}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
