import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import CustomSlot from './CustomSlot'
import PreferenceBar from './PreferenceBar'
import RecommendedSlotData from './RecommendedSlotData'

export type IRecommendedSlots = {
  data: { location: string; startsAt: string; duration: number }[]
  studyCodes: string[]
}

export interface IRecommendedSlotsProps {
  recommendedSlots: Partial<IRecommendedSlots>
  preferences: any
  handleSlotBooking: (slot: any, studyCodes: string[] | undefined, isCustom: boolean) => void
  timeService: any
}

const RecommendedSlots = ({
  recommendedSlots,
  preferences,
  handleSlotBooking,
  timeService
}: IRecommendedSlotsProps) => {
  const [selectedSlotKey, setSelectedSlotKey] = useState<string | null>(null)
  const [customSlotDetails, setCustomSlotDetails] = useState<any>({})

  useEffect(() => {
    setCustomSlotDetails({})
    setSelectedSlotKey(null)
  }, [preferences])

  const { data, studyCodes } = recommendedSlots || {}

  const handleChange = useCallback(
    (updatedFields: any) => {
      setCustomSlotDetails((prev: any) => {
        return { ...prev, ...updatedFields }
      })
    },
    [setCustomSlotDetails]
  )

  let content = null

  if (isEmpty(preferences)) {
    content = <sh-empty-state icon="time" label="Submit preferences for slot recommendations"></sh-empty-state>
  } else {
    content = (
      <>
        <PreferenceBar preferences={{ ...preferences, studyCodes }} timeService={timeService} />
        <sh-list>
          {data?.map((slot: any, idx: number) => (
            <RecommendedSlotData
              key={slot.location + slot.startsAt}
              slot={slot}
              idx={idx}
              active={selectedSlotKey === idx.toString()}
              handleClick={() => {
                setSelectedSlotKey(idx.toString())
              }}
              timeService={timeService}
            />
          ))}
        </sh-list>
        <sh-divider />
        <CustomSlot
          active={selectedSlotKey === 'custom'}
          customSlot={customSlotDetails}
          handleClick={() => {
            setSelectedSlotKey('custom')
          }}
          handleChange={(e) => handleChange(e)}
          timeService={timeService}
        />
      </>
    )
  }

  const getCustomSlotDetails = () => {
    const { location, date, time, reason, reasonDetails } = customSlotDetails

    let startsAt = null

    if (date && time) {
      startsAt = timeService.timeMoment(`${date} ${time}`).toISOString()
    }

    const firstRecommendedSlot = recommendedSlots?.data?.[0]
    const duration = firstRecommendedSlot?.duration ?? 0

    return {
      location,
      reason,
      reasonDetails,
      startsAt,
      duration
    }
  }

  const handleSlotBookButtonClick = () => {
    if (!selectedSlotKey) {
      return
    }

    const selectedSlot =
      selectedSlotKey === 'custom' ? getCustomSlotDetails() : recommendedSlots.data![parseInt(selectedSlotKey)]
    handleSlotBooking(selectedSlot, studyCodes, selectedSlotKey === 'custom')
  }

  const label = isEmpty(preferences) ? 'Slot recommendations' : `Slot recommendations (${data?.length})`

  return (
    <sh-card label={label}>
      {content}
      {!isEmpty(preferences) && !selectedSlotKey && (
        <sh-text slot="footer" size="header-2" color="secondary" style={{ width: 'auto', marginRight: 'auto' }}>
          Click on a slot to select it for booking
        </sh-text>
      )}
      <sh-button
        slot="footer"
        label="Book"
        disabled={selectedSlotKey ? undefined : true}
        onClick={handleSlotBookButtonClick}
      ></sh-button>
    </sh-card>
  )
}
export default RecommendedSlots
