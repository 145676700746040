import { createExpertiseMutation, deleteExpertiseMutation } from './Mutations'
import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [Field('name', 'Name'), Field('type', 'Type'), Field('description', 'Description')]

function ExpertiseStore() {
  const store = AdminStore({
    fields,
    entityName: 'Expertise',
    api: {
      index: 'indexExpertises'
    },
    header: { title: 'Expertises' },
    search: { field: 'name', placeholder: 'Search for expertise' }
  })

  function createExpertise(groupId, name, type) {
    const gqlClient = store.getGQLClient()
    const parameters = { expertise: { groupId, name, type } }

    return gqlClient.query(createExpertiseMutation, parameters)
  }

  function createExpertises(groupId, items, type) {
    const gqlClient = store.getGQLClient()
    const expertises = items || []
    expertises.forEach((name) => {
      const parameters = { expertise: { groupId, name, type } }
      return gqlClient.query(createExpertiseMutation, parameters)
    })
  }

  function deleteExpertise(id) {
    const gqlClient = store.getGQLClient()
    const parameters = { id }

    return gqlClient.mutate(deleteExpertiseMutation, parameters)
  }

  return {
    ...store,
    createExpertise,
    createExpertises,
    deleteExpertise
  }
}

export default ExpertiseStore
