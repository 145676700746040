import { Component } from 'react'
import { pick } from 'lodash'
import FacilityUserRow from './FacilityUserRow'

export default class FacilityUserData extends Component {
  render() {
    const { facilityUser } = this.props

    return <section className="hx-facility-user-data bb1">{this.renderFacilityUserRow(facilityUser)}</section>
  }

  renderFacilityUserRow(facilityUser) {
    const facilityUserRowProps = pick(
      {
        ...this.props,
        ...this.state,
        facilityUser
      },
      ['authUserId', 'timeService', 'facilityUser', 'facilityUsersStore']
    )

    return <FacilityUserRow {...facilityUserRowProps} />
  }
}
