import { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { navigate } from 'Navigation'
import { SpillProofDiv } from 'Common/components'
import './SelectUnit.scss'

const SELECT_UNIT_OFFSET_TOP = 70

class SelectUnit extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { active: false, isHovered: false, selectedUnitName: '...' }
  }

  render() {
    const { unitUrlId, unit, eligibleUnits } = this.props
    const unitName = unit.get('name') || this.state.selectedUnitName
    const { active: isActive } = this.state
    const isHomeUrlVisible = false //HMN-3870 hide home unit button but keep functionality for future.
    // const isHomeUrlVisible = !!unitUrlId || eligibleUnits.size > 0;
    const isSelectorDisabled = eligibleUnits.size <= 1
    const homeLinkClassName = classNames('col row align-middle', {
      invisible: !isHomeUrlVisible
    })

    const renderUnit = (unit, key) => (
      <div key={key} className={classNames({ disabled: unit.get('urlId') === unitUrlId })}>
        <Link className="unit-list-item" onClick={this.changeUnit} to={navigate.replace.unit(unit.get('urlId'), false)}>
          <span>{unit.get('name')}</span>
        </Link>
      </div>
    )

    const { clientHeight } = document.documentElement
    const style = { maxHeight: clientHeight - SELECT_UNIT_OFFSET_TOP }

    return (
      <div
        className="calendar-select-unit-container row align-middle"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {isHomeUrlVisible && <div className={homeLinkClassName}>{this._renderHomeLink()}</div>}
        <button
          className={classNames({
            active: isActive
          })}
          onClick={this.toggleDropdown}
          onBlur={this.hideDropdown}
          disabled={isSelectorDisabled}
        >
          <div className="unit-name row align-middle">
            <SpillProofDiv id={'unit-selector-text'} className="col-10" tooltipPosition="right">
              {unitName}
            </SpillProofDiv>
            {!isSelectorDisabled && <i className="icon-Chevron---Down" />}
          </div>
        </button>

        {this.state.active && (
          <div className="select-unit-options" style={style}>
            {eligibleUnits.map(renderUnit)}
          </div>
        )}
      </div>
    )
  }

  changeUnit = () => {
    if (navigate.isPath.StaffDetails) {
      setTimeout(() => {
        navigate.from.StaffDetails.to.Staff() // navigation will hide dropdown as side-effect
      }, 20)
    } else {
      this.hideDropdown()
    }
  }

  _renderHomeLink() {
    const { homeUnitId, timeService, eligibleUnits } = this.props
    const homeUnit = eligibleUnits.find((u) => u.get('id') === homeUnitId)
    const unitUrlId = homeUnit ? homeUnit.get('urlId') : eligibleUnits.getIn([0, 'urlId'])
    const homeUrl = navigate.from.Home.to.CalendarFullView(
      {
        unitUrlId,
        date: timeService.todayUsDate
      },
      false
    )

    return (
      <Link to={homeUrl} className="">
        <i className="icon-left cadet-blue" />
        <i className="icon-home large" />
      </Link>
    )
  }

  selectUnit = (unitName) => (e) => {
    this.setState({ selectedUnitName: unitName })
    this.hideDropdown()
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => ({
      active: !prevState.active
    }))
  }

  hideDropdown = () => {
    setTimeout(() => this.setState({ active: false }), 200)
  }

  onMouseEnter = () => this.setState({ isHovered: true })

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 500)
  }
}

export default withRouter(SelectUnit)
