import { Component } from 'react'
import ShiftSwapsFilter from './ShiftSwapsFilter'
import ShiftSwapsTable from './ShiftSwapsTable'
import ShiftSwapsDialog from './ShiftSwapsDialog'
import PropTypes from 'prop-types'
import stores from 'stores'

const { shiftSwapsStore } = stores

export default class ShiftSwaps extends Component {
  static propTypes = {
    Controller: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.controller = this.props.Controller(this)
    this.state = this.controller.getDefaultState()
  }

  componentDidMount() {
    const { unit } = this.props
    const isUnitReady = unit.get('isReady')
    if (isUnitReady) {
      this.readShiftSwaps(unit)
    }
    window.addEventListener('visibilitychange', this.handleVisibilityChange)
  }

  handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible') {
      const { unit } = this.props
      this.readShiftSwaps(unit)
    }
  }

  componentDidUpdate(prevProps) {
    const { unit, appState } = this.props
    const { unit: prevUnit, appState: prevAppState } = prevProps

    const isUnitReady = unit.get('isReady')
    const isUnitChanged = unit.get('id') !== prevUnit.get('id')

    const shiftSwapNotifications = appState.getIn(['generalData', 'notifications', 'shift-swaps'])
    const prevShiftSwapNotifications = prevAppState.getIn(['generalData', 'notifications', 'shift-swaps'])
    const shiftSwapNotificationsChanged = shiftSwapNotifications !== prevShiftSwapNotifications

    if (isUnitChanged) {
      shiftSwapsStore.setShiftSwapDialog(null)
    }
    if ((isUnitChanged && isUnitReady) || shiftSwapNotificationsChanged) {
      this.readShiftSwaps(unit)
    }
  }

  readShiftSwaps(unit) {
    const unitId = unit.get('id')
    this.setState({ isLoading: true }, async () => {
      const state = await this.controller.readShiftSwaps(unitId)
      const shiftSwaps = state.getIn(['shiftSwapsState', 'shiftSwaps'])
      if (shiftSwaps.size === 0) {
        setTimeout(() => this.setState({ isLoading: false }), 100)
      }
    })
  }

  render() {
    const { paddingTop, appState } = this.props
    const { isLoading } = this.state
    const shiftSwaps = this.controller.getFilteredShiftSwaps()

    return (
      <section className="row" style={{ paddingTop }}>
        <div className="col col-12">
          <header className="hx-shift-swaps-header">
            <ShiftSwapsFilter controller={this.controller} appState={appState} />
            <ShiftSwapsTable shiftSwaps={shiftSwaps} searchValue={this.state.searchValue} isLoading={isLoading} />
          </header>
        </div>
        <ShiftSwapsDialog appState={appState} />
      </section>
    )
  }
  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange)
  }
}
