import { t } from 'i18n'
import './TimeOffList.scss'
import { PropsWithChildren } from 'react'
import { List } from 'immutable'
import TimeOffDetails from './TimeOffDetails/TimeOffDetails'

export interface ITimeOffListProps {
    timeService?: any,
    date: any,
    staffTimeOffRequests: any
}

export default function TimeOffList({ timeService, date, staffTimeOffRequests }: PropsWithChildren<ITimeOffListProps>) {
    const monthYear = timeService.getMonthYearForUsDate(date)

    const currentDate = timeService.today;

    const isFutureMonth = timeService.dateTimeToMoment(date).isAfter(currentDate, 'month');
    const isPastMonth = timeService.dateTimeToMoment(date).isBefore(currentDate, 'month');
    const isCurrentMonth = timeService.dateTimeToMoment(date).isSame(currentDate, 'month');

    const accordionHeight = isFutureMonth || isPastMonth ? 'h100' : 'h50'

    const pastTimeOffs = staffTimeOffRequests ? staffTimeOffRequests?.filter((timeOff: any) => {
        const dates = timeOff.get('dates')
        const firstDate = dates.get(0);
        const timeOffDate = timeService.dateTimeToMoment(firstDate.get('date'));

        return timeOffDate.isBefore(currentDate);
    }) : List();

    const sortedPastTimeOffs = pastTimeOffs.sortBy((timeOff: any) => {
        const dates = timeOff.get('dates');
        const firstDate = dates.get(0);
        const timeOffDate = timeService.dateTimeToMoment(firstDate.get('date'));

        return timeOffDate.valueOf();
    }).reverse()

    const futureTimeOffs = staffTimeOffRequests ? staffTimeOffRequests?.filter((timeOff: any) => {
        const dates = timeOff.get('dates')
        const firstDate = dates.get(0);
        const timeOffDate = timeService.dateTimeToMoment(firstDate.get('date'));
        return timeOffDate.isSameOrAfter(currentDate);
    }) : List();

    const sortedFutureTimeOffs = futureTimeOffs.sortBy((timeOff: any) => {
        const dates = timeOff.get('dates');
        const firstDate = dates.get(0);
        const timeOffDate = timeService.dateTimeToMoment(firstDate.get('date'));

        return timeOffDate.valueOf();
    })

    return (
        <div>
            <div className='p1 bg-white text-color'>
                <sh-text size='header-2'>
                    {monthYear}
                </sh-text>
            </div>
            <div className='flex-column accordion-wrapper'>
                {(isFutureMonth || isCurrentMonth) && <div className={`${accordionHeight} vertical-auto-scroll pt05`}>
                    <sh-accordion expanded label={`${t('staffCalendar.timeOff.upcoming')}`}>
                        {sortedFutureTimeOffs.size !== 0 && <TimeOffDetails timeService={timeService} timeOffsData={sortedFutureTimeOffs} />}
                    </sh-accordion>
                </div>
                }
                {(isPastMonth || isCurrentMonth) && <div className={`${accordionHeight} vertical-auto-scroll pt05 history`}>
                    <sh-accordion expanded label={`${t('staffCalendar.timeOff.history')}`}>
                        {sortedPastTimeOffs.size !== 0 && <TimeOffDetails timeService={timeService} timeOffsData={sortedPastTimeOffs} />}
                    </sh-accordion>
                </div>
                }
            </div>
        </div>
    )
}