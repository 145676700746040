import Footer from 'Footer'
import Dashboard from './Dashboard'
import DataController from './Controllers/DataController'
import { withAppContext } from 'AppContext'
import { useRef } from 'react'

function DashboardLayout(props) {
  const thisRef = useRef({ props })
  const dataControllerRef = useRef(new DataController(thisRef.current))
  thisRef.current.props = props
  return (
    <div className="h100">
      <div className="wrapper-container">
        <section>
          <Dashboard {...props} dataController={dataControllerRef.current} />
        </section>
      </div>
      <Footer timeService={props.timeService} />
    </div>
  )
}

export default withAppContext(DashboardLayout)
