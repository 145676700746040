// @ts-ignore
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { Map, fromJS } from 'immutable'
import { isFunction } from '../../utils'

import { myStaffTimeOffRequestsQuery } from './Queries'
import { createMyStaffTimeOffRequestMutation } from './Mutations'
import { StaffTimeOffRequestDateInput } from 'Staff/Calendar/utils'

const defaultState = Map({
  staffTimeOffRequestsForMonth: Map({
    isLoading: false,
    timeOffRequestsMap: Map({})
  })
})

export default function staffTimeOffRequestsStore() {
  let gqlClient: any = null
  let timeService: any = null
  let updateStaffTimeOffRequests: any = null

  const actions = {
    loadMyStaffTimeOffRequestsForMonth,
    createMyStaffTimeOffRequest
  }

  return {
    initialize,
    ...actions
  }

  function initialize(state: any, context: any) {
    function _updateStaffTimeOffRequests(state: any, key: string, value: any) {
      if (isFunction(value)) {
        return state.updateIn(['staffTimeOffRequests', key], value)
      }
      return state.setIn(['staffTimeOffRequests', key], value)
    }
    const updaters = fluxStore({
      _updateStaffTimeOffRequests
    })

    const extendedContext = { ...context, ...updaters }

      ; ({ gqlClient, facilityTime: timeService, _updateStaffTimeOffRequests: updateStaffTimeOffRequests } = extendedContext)
    return state.set('staffTimeOffRequests', defaultState)
  }

  async function loadMyStaffTimeOffRequestsForMonth(monthStartsAt: string) {
    const monthEndsAt = timeService.timeMoment(monthStartsAt).add(1, 'month').subtract(1, 'day').toISOString()
    const parameters: any = {
      startDate: monthStartsAt,
      endDate: monthEndsAt
    }
    updateStaffTimeOffRequests('staffTimeOffRequestsForMonth', (staffTimeOffRequestsForMonthsData: any) => {
      return staffTimeOffRequestsForMonthsData.set('isLoading', true)
    })

    const { myStaffTimeOffRequests } = await gqlClient.query(myStaffTimeOffRequestsQuery, parameters)

    return updateStaffTimeOffRequests(
      'staffTimeOffRequestsForMonth',
      fromJS({
        isLoading: false,
        myStaffTimeOffRequests
      })
    )
  }

  async function createMyStaffTimeOffRequest(
    dates: StaffTimeOffRequestDateInput[],
    note: StaffTimeOffRequestDateInput,
    monthStartsAt: string
  ) {
    const timeOffRequest = { dates, note }
    await gqlClient.mutate(createMyStaffTimeOffRequestMutation, { timeOffRequest })
    await loadMyStaffTimeOffRequestsForMonth(monthStartsAt)
  }
}
