import { Component } from 'react'
// import Modal from './Modal';
import { Modal } from 'Common/components'
import SaveTemplateForm from './SaveTemplateForm'

export default class SaveTemplateModal extends Component {
  render() {
    const { visible, toggleModal: onClose, ...formProps } = this.props

    const modalProps = {
      visible,
      onClose,
      width: 869
    }

    const saveFormProps = { onClose, ...formProps }

    // TODO: Remove "X" from modal
    return (
      <div>
        <Modal {...modalProps}>
          <SaveTemplateForm {...saveFormProps} />
        </Modal>
      </div>
    )
  }
}
