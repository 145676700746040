import './ControlledFilter.scss'
import { Set } from 'immutable'

type FilterProps = {
  filterMap: { [key: string]: string }
  selectedFilters: Set<string>
  addFilter: (_newFilter: string) => void
  removeFilter: (_filterToRemove: string) => void
  setFilters: (_newFilters: Set<string>) => void
  label: string
}

function ControlledFilter({ filterMap, selectedFilters, addFilter, removeFilter, setFilters, label }: FilterProps) {
  const toggleFilter = (newFilter: string) => {
    if (newFilter === 'all') {
      const allSelected = selectedFilters.size === Object.keys(filterMap).length
      const updatedFilters: Set<string> = allSelected ? Set() : Set(Object.keys(filterMap))
      setFilters(updatedFilters)
    } else if (selectedFilters.has(newFilter)) {
      removeFilter(newFilter)
    } else {
      addFilter(newFilter)
    }
  }
  const getFilterDropdownValue = () => {
    let filterValue = ''
    if (selectedFilters.size > 1) {
      filterValue = 'Multiple'
    } else if (selectedFilters.size === 1) {
      filterValue = filterMap[selectedFilters.toArray()[0]]
    }
    return filterValue
  }

  const allSelected = selectedFilters.size === Object.keys(filterMap).length
  return (
    <div className="drop-down">
      <sh-dropdown label={label} value={getFilterDropdownValue()} no-clear>
        <sh-list-item
          selectable
          active={allSelected ? true : undefined}
          onClick={() => toggleFilter('all')}
          key="all"
          data-testid="all"
        >
          All
        </sh-list-item>
        {Object.entries(filterMap).map(([key, label]) => {
          return (
            key && (
              <sh-list-item
                selectable
                divider={'none'}
                active={selectedFilters.has(key) ? true : undefined}
                onClick={() => toggleFilter(key)}
                key={key}
                data-testid={key}
              >
                {label}
              </sh-list-item>
            )
          )
        })}
      </sh-dropdown>
    </div>
  )
}

export default ControlledFilter
