import { Component } from 'react'
import CustomDateInput from '@humanics/he-react-common/lib/components/Form/CustomDateInput'
import { CustomRecurTimeInput, CustomRecurEvery, CustomRecurShifts } from './components'
import form from './formDefinitionsSchedules'
import stores, { addWarningBanner } from 'stores'
import { Staff } from 'entityWrappers'
import FormActionBar from '../CreateEditExpertiseModals/FormActionBar'
import constants from './constants'
import { DataController } from '../Controllers'
import StaffEventController from '../../../Calendar/CellDetails/ShiftForm/StaffEventController'
import { FacilityUserShiftsService } from '../../../../services'
import RecurringScheduleFormController from './RecurringScheduleFormController'

const { staffStore } = stores
const { timeStep } = constants

export default class CreateUpdateRecurringScheduleForm extends Component {
  constructor(props) {
    super(props)
    this.dataController = new DataController(this)
    this.staffEventController = new StaffEventController(this)
    this.formController = new RecurringScheduleFormController(this)
    this.state = {
      form,
      isLoading: false,
      isValidForm: false,
      shownShiftsSubmenu: false
    }

    this.onClickSubmit = this.onClickSubmit.bind(this)
  }

  componentDidMount() {
    this.formController.initForm()
  }

  componentDidUpdate(prevProps) {
    const { staffRecurringSchedule } = this.props
    const { staffRecurringSchedule: prevStaffRecurringSchedule } = prevProps

    if (staffRecurringSchedule && !prevStaffRecurringSchedule) {
      this.formController.initForm()
    }
  }

  onClickSubmit = async (e) => {
    e.preventDefault()
    const { staffRecurringSchedule, staff, onClose, timeService, onSubmit } = this.props
    const facilityUser = new Staff(staff)
    const { userId, staffProfile } = facilityUser
    const { startsAt: staffStartDateToAssignRecurSchedule } = staffProfile
    const {
      shiftId,
      shiftStartTime,
      shiftDuration,
      startsAt,
      endsAt,
      recurringType,
      weeklyDayIndexes,
      recurEvery,
      subType,
      monthlyOnThe,
      monthlyOnTheDayWeek
    } = this.formController.serializeForm()

    const staffRecurringScheduleId = staffRecurringSchedule ? staffRecurringSchedule.get('id') : null

    let isSameData = false
    if (staffRecurringSchedule) {
      let isSameDate
      const startsAtRecord = staffRecurringSchedule.get('startsAt')
      const startsAtMoment = timeService.timeMoment(startsAt)
      const startsAtMomentRecord = timeService.timeMoment(startsAtRecord)

      isSameDate = startsAtMoment.isSame(startsAtMomentRecord, 'day')
      if (
        isSameDate &&
        recurringType === staffRecurringSchedule.get('recurringType') &&
        shiftDuration === staffRecurringSchedule.get('shiftDuration') &&
        recurEvery === staffRecurringSchedule.get('recurEvery') &&
        subType === staffRecurringSchedule.get('subType') &&
        monthlyOnThe === staffRecurringSchedule.get('monthlyOnThe') &&
        monthlyOnTheDayWeek === staffRecurringSchedule.get('monthlyOnTheDayWeek')
      ) {
        isSameData = true
      }
    }

    let parameters = {
      userId,
      shiftId,
      shiftStartTime,
      shiftDuration,
      startsAt,
      endsAt,
      recurringType,
      weeklyDayIndexes,
      recurEvery,
      subType,
      monthlyOnThe
    }

    if (monthlyOnTheDayWeek) {
      parameters.monthlyOnTheDayWeek = monthlyOnTheDayWeek
    }
    if (monthlyOnThe) {
      parameters.monthlyOnThe = monthlyOnThe
    }
    this.setState({ isLoading: true })

    const hasRecurringSchedule = !!staffRecurringScheduleId

    const shouldCreate = !staffRecurringSchedule
    if (timeService.timeMoment(startsAt).isBefore(staffStartDateToAssignRecurSchedule)) {
      addWarningBanner({
        message: 'Cannot assign recurring schedules to the date prior to staff profile creation!'
      })
      this.setState({ isLoading: false })
      onClose()
      return
    }

    if (shouldCreate) {
      await staffStore.createStaffRecurringSchedule(parameters)
      await onSubmit({ userId })
      this.setState({ isLoading: false })
      onClose()
      return
    }

    const shouldUpdate = !isSameData && hasRecurringSchedule
    if (shouldUpdate) {
      parameters.id = staffRecurringScheduleId
      await staffStore.updateStaffRecurringSchedule(parameters)

      this.setState({ isLoading: false })
      onClose()
      return
    }

    this.setState({ isLoading: false })
    onClose()
  }

  onChangeStartsAt = (utcValue) => {
    if (utcValue === this.props.startsAt) {
      return
    }
    this.props.onChangeStartsAt(utcValue)
  }

  onChangeEndsAt = (utcValue) => {
    if (utcValue === this.props.endsAt) {
      return
    }
    this.props.onChangeEndsAt(utcValue)
  }

  render() {
    const { onClose, timeService, units, staff, shifts, unit, usDate, staffRecurringSchedule } = this.props
    if (units.isEmpty()) {
      onClose()
      return null
    }
    const { isLoading, form, isValidForm } = this.state

    const buttonLabel = staffRecurringSchedule ? 'Update' : 'Save'
    const customRecurEveryProps = {
      form,
      setFieldValue: this.formController.setFieldValue
    }

    const customDropDownStartShiftTimeProps = {
      date: timeService.timeMoment(usDate),
      label: form.shiftStartTime.definition.label,
      disabled: form.shiftStartTime.definition.disabled,
      required: true,
      onChange: (e) => this.formController.setFieldValue(e, 'shiftStartTime'),
      step: timeStep,
      timeService,
      value: form.shiftStartTime.value || timeService.timeMoment(usDate).add(6, 'hours'),
      valueShort:
        (form?.shiftStartTime?.value && timeService.timeMoment(form.shiftStartTime.value).format('HH:mm')) ||
        timeService.timeMoment(timeService.timeMoment(usDate)).add(6, 'hours').format('HH:mm'),
      minValue: form.shiftStartTime.minValue,
      maxValue: timeService
        .timeMoment(form.shiftEndTime.value || null)
        .subtract(timeStep, 'minutes')
        .toISOString(),
      onChangeEndsAt: this.onChangeEndsAt,
      onChangeStartsAt: this.onChangeStartsAt
    }

    const customDropDownEndShiftTimeProps = {
      date: timeService.timeMoment(usDate),
      label: form.shiftEndTime.definition.label,
      disabled: form.shiftEndTime.definition.disabled,
      required: true,
      onChange: (e) => this.formController.setFieldValue(e, 'shiftEndTime'),
      step: timeStep,
      timeService,
      value: form.shiftEndTime.value || timeService.timeMoment(usDate).add(18, 'hours'),
      valueShort:
        (form?.shiftEndTime?.value && timeService.timeMoment(form.shiftEndTime.value).format('HH:mm')) ||
        timeService.timeMoment(timeService.timeMoment(usDate)).add(18, 'hours').format('HH:mm'),
      maxValue: form.shiftEndTime.maxValue,
      minValue: timeService
        .timeMoment(form.shiftStartTime.value || null)
        .add(timeStep, 'minutes')
        .toISOString(),
      onChangeEndsAt: this.onChangeEndsAt,
      onChangeStartsAt: this.onChangeStartsAt
    }

    const customDropDownScheduleStartTimeProps = {
      ...form.scheduleStartDate,
      value: form.scheduleStartDate.value,
      maxDate: this.formController.isFieldEmpty('scheduleEndDate')
        ? ''
        : timeService.timeMoment(form.scheduleEndDate.value).subtract(1, 'weeks').day(0).toISOString(),
      timeService,
      onChange: (e) => this.formController.setFieldValue(e, 'scheduleStartDate'),
      showMonths: 1,
      weeksSelect: 1,
      isWeekSelect: true
    }

    const customDropDownScheduleEndTimeProps = {
      ...form.scheduleEndDate,
      value: form.scheduleEndDate.value,
      minDate: this.formController.isFieldEmpty('scheduleStartDate')
        ? ''
        : timeService.timeMoment(form.scheduleStartDate.value).add(1, 'weeks').day(0).toISOString(),
      timeService,
      onChange: (e) => this.formController.setFieldValue(e, 'scheduleEndDate'),
      showMonths: 1,
      weeksSelect: 1,
      isWeekSelect: true
    }

    const eventVariants = unit.get('eventVariants')
    const startsAt = timeService.timeMoment(usDate).toISOString()

    const userShiftsService = new FacilityUserShiftsService(timeService)
    const availableUnitIds = units?.toJS().map((item) => item.id)
    const { primaryShift, secondaryShifts, onCallShifts } = userShiftsService.getShiftsForDate(
      startsAt,
      staff,
      shifts,
      availableUnitIds
    )
    const shiftTypesValueArray = shifts?.toJS().filter((item) => item.id === form?.shiftTypes?.value)
    const shiftTypesObject = shiftTypesValueArray?.length > 0 && shiftTypesValueArray[0]

    const shiftTypesValue = shiftTypesObject?.name
    const customDropDownShiftTypesProps = {
      form,
      onChange: (e) => this.formController.setFieldValue(e, 'shiftTypes'),
      eventVariants,
      primaryShift,
      secondaryShifts,
      onCallShifts,
      shiftTypesValue
    }

    return (
      <form className="form mb0 hx-recurring-schedule">
        <div className="pt25 pr25 pl25 pb0 row">
          <CustomRecurShifts {...customDropDownShiftTypesProps} />
          <CustomRecurTimeInput {...customDropDownStartShiftTimeProps} />
          <CustomRecurTimeInput {...customDropDownEndShiftTimeProps} />
        </div>
        <div className="pr25 pl25 pb0 row">
          <CustomDateInput {...customDropDownScheduleStartTimeProps} />
          <CustomDateInput {...customDropDownScheduleEndTimeProps} />
        </div>
        <div className="row col-12 hx-seprator"></div>
        <CustomRecurEvery {...customRecurEveryProps} />
        <footer className="_footer">
          <FormActionBar
            submitLabel={buttonLabel}
            isSubmitDisabled={!isValidForm}
            isSubmitLoading={isLoading}
            onSubmit={this.onClickSubmit}
            onCancel={onClose}
          />
        </footer>
      </form>
    )
  }
}
