import stores from 'stores'
import { ComponentController } from 'Common/components'
import { fromJS } from 'immutable'

const { staffStore, activitiesStore } = stores

export default class StateController extends ComponentController {
  get defaultState() {
    return { isLoading: true }
  }

  loadStaff = async (staffUserId = null, isStaffOnly = false) => {
    const userId = this.props.match?.params?.userId
    const targetUserId = staffUserId ? staffUserId : userId
    await staffStore.loadStaff(targetUserId, isStaffOnly)
    this.setState({ isLoading: false })
  }

  loadStaffExpertiseForest = (staff, expertises, expertiseGroups) => {
    const staffExpertiseCredentials = staff.get('staffProfile')?.get('expertiseCredentials')
    if (!staffExpertiseCredentials) {
      return
    }
    return buildStaffExpertiseForest(staffExpertiseCredentials, expertises, expertiseGroups)
  }

  loadStaffActivities = async (unitId, startDate, endDate) => {
    const { userId } = this.props.match.params
    await activitiesStore.loadActivities(unitId, undefined, 100, endDate, undefined, userId, startDate, undefined)
  }
}

function buildStaffExpertiseForest(staffExpertiseCredentials, expertises, expertiseGroups) {
  const staffExpertiseForest = {}

  staffExpertiseCredentials.forEach((staffExpertiseCredential) => {
    const expertise = buildExpertiseLeaf(staffExpertiseCredential, expertises)

    let expertiseTreeFromForest = getExpertiseTreeForLeaf(expertise, staffExpertiseForest)
    if (!expertiseTreeFromForest) {
      expertiseTreeFromForest = buildExpertiseTreeForLeaf(expertise, expertiseGroups)
      addExpertiseTreeToForest(expertiseTreeFromForest, staffExpertiseForest)
    }

    addExpertiseLeafToTree(expertise, expertiseTreeFromForest)
  })

  Object.entries(staffExpertiseForest).forEach(([treeId, expertiseTree]) => {
    if (!expertiseTree.parentId) {
      return
    }
    let expertiseGroveFromForest = getExpertiseGroveForTree(expertiseTree, staffExpertiseForest)
    if (!expertiseGroveFromForest) {
      expertiseGroveFromForest = buildExpertiseGroveForTree(expertiseTree, expertiseGroups)
      addExpertiseGroveToForest(expertiseGroveFromForest, staffExpertiseForest)
    }
    addExpertiseTreeToGrove(fromJS(expertiseTree), expertiseGroveFromForest)
    staffExpertiseForest[treeId] = undefined
  })

  return Object.fromEntries(Object.entries(staffExpertiseForest).filter(([key, value]) => value !== undefined))
}

//expertise leaf = expertise + staff credential
function buildExpertiseLeaf(staffExpertiseCredential, expertises) {
  const expertiseId = staffExpertiseCredential.get('expertiseId')
  let expertise = expertises.get(expertiseId).get(0)
  return expertise.merge({ staffExpertiseCredential })
}

//expertise tree = expertise group + array of staff expertise leaves
function buildExpertiseTreeForLeaf(expertiseLeaf, expertiseGroups) {
  const expertiseGroupId = expertiseLeaf.get('groupId')
  const expertiseGroup = expertiseGroups.get(expertiseGroupId).get(0).toJS()
  expertiseGroup.expertises = []
  return expertiseGroup
}

//expertise grove = ExpertiseGroup{..., expertises: [ExpertiseGroups]}
function buildExpertiseGroveForTree(expertiseTree, expertiseGroups) {
  const expertiseGroupId = expertiseTree.parentId
  const expertiseGroup = expertiseGroups.get(expertiseGroupId).get(0).toJS()
  expertiseGroup.expertises = []
  return expertiseGroup
}

function getExpertiseTreeForLeaf(expertiseLeaf, staffExpertiseForest) {
  const expertiseGroupId = expertiseLeaf.get('groupId')
  return staffExpertiseForest[expertiseGroupId]
}

function getExpertiseGroveForTree(expertiseTree, staffExpertiseForest) {
  const parentId = expertiseTree.parentId
  return staffExpertiseForest[parentId]
}

function addExpertiseLeafToTree(expertiseLeaf, expertiseTree) {
  expertiseTree.expertises.push(expertiseLeaf)
}

function addExpertiseTreeToGrove(expertiseTree, expertiseGrove) {
  expertiseGrove.expertises.push(expertiseTree)
}

function addExpertiseTreeToForest(expertiseTree, staffExpertiseForest) {
  staffExpertiseForest[expertiseTree.id] = expertiseTree
}

function addExpertiseGroveToForest(expertiseGrove, staffExpertiseForest) {
  staffExpertiseForest[expertiseGrove.id] = expertiseGrove
}
