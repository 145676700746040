import CustomDropDown from '@humanics/he-react-common/lib/components/Form/CustomDropdown'
import NumericInput from '@humanics/he-react-common/lib/components/Form/CustomNumericInput'
import { Component } from 'react'
import constants from '../constants'
import CustomSelectionInput from './CustomSelectionInput'

export default class CustomRecurEvery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      weekDaysShort: constants.weekDaysShort
    }
  }

  selectDate = (e, weekIndex, dayIndex) => {
    e.stopPropagation()
    const { setFieldValue } = this.props
    const { weekDaysShort } = this.state
    const { value } = weekDaysShort[weekIndex][dayIndex]
    weekDaysShort[weekIndex][dayIndex].value = !value
    setFieldValue(weekDaysShort, 'weekSchedule')
    this.setState({ weekDaysShort })
  }

  render() {
    const { form, setFieldValue } = this.props
    const typeForReccur = +form?.ruleType?.value
    const customDropDownProps = {
      ...form.ruleType,
      onChange: (e) => setFieldValue(e, 'ruleType'),
      value: form.ruleType.value,
      propKey: 'key',
      propValue: 'name'
    }
    const inputProps = {
      ...form.ruleEvery,
      onChange: (e) => setFieldValue(e, 'ruleEvery'),
      value: form.ruleEvery.value,
      formItemClasses: 'col-4 pr5',
      order: 'i+-',
      type: 'arrows'
    }

    const weekScheduleProps = {
      ...form.weekSchedule,
      onChange: (e) => setFieldValue(e, 'weekSchedule'),
      value: form.weekSchedule.value,
      namespace: 'form-item col-12'
    }

    const customDropDownDayProps = {
      form,
      onChangeSelection: setFieldValue,
      propKey: 'key',
      propValue: 'name'
    }

    const weekScheduleDisabled = form.weekSchedule.definition.disabled

    const requiredEver = form.ruleEvery.definition.required || form.ruleType.definition.required
    return (
      <div className="row pt25 pb25">
        <div className="col-4">
          <label className="pl30 pb10 row">
            <span className="form-label">Recur every</span>
            {requiredEver && <span className="pl5 req">*</span>}
          </label>
          <div className="pl25 pb0 row">
            <NumericInput {...inputProps} />
            <CustomDropDown {...customDropDownProps} />
          </div>
        </div>
        {typeForReccur === 1 && (
          <div className={weekScheduleProps.formItemClasses}>
            <label className="pb0 row">
              <span className="form-label">Recur on</span>
              <span className="pl5 req">*</span>
            </label>
            <div className={`pb0 row datePicker ${weekScheduleProps.namespace}`}>
              {weekScheduleProps?.value?.map((week, weekIndex) => (
                <Week
                  week={week}
                  weekIndex={weekIndex}
                  selectDate={this.selectDate}
                  key={weekIndex}
                  weekScheduleDisabled={weekScheduleDisabled}
                />
              ))}
            </div>
          </div>
        )}
        {typeForReccur === 2 && <CustomSelectionInput {...customDropDownDayProps} />}
      </div>
    )
  }
}

const Week = ({ week, weekIndex, selectDate, weekScheduleDisabled = false }) => (
  <div className="row">
    <div className="col-12 row-7">
      {week.map((day, dayIndex) => (
        <div className="col-1 text-center" key={dayIndex}>
          {weekScheduleDisabled && (
            <>
              <input checked={day.value} type="checkbox" readOnly={true} />
              <div>{day.label}</div>
            </>
          )}
          {!weekScheduleDisabled && (
            <>
              <input checked={day.value} type="checkbox" readOnly={true} />
              <div
                onClick={(e) => selectDate(e, weekIndex, dayIndex)}
                data-testid="recur-input-label"
                className="_inputLabel"
              >
                {day.label}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  </div>
)
