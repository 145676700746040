import stores from 'stores'
import StateController from './StateController'

const { staffStore } = stores

export default class ActionController extends StateController {
  updateStaff = async (parameters) => {
    await staffStore.updateFacilityUser(parameters)
    return this.loadStaff(parameters.userId, parameters.isStaffOnly)
  }

  updateStaffExpertises = async (parameters) => {
    await staffStore.updateStaffProfileExpertise(parameters)
    return this.loadStaff()
  }

  cancellStaffTransfer = async (userId) => {
    await staffStore.cancellStaffTransfer(userId)
    this.loadStaff()
  }
}
