import { Component } from 'react'
import { capitalize } from 'lodash'
import './DoughnutChart.scss'
import { Doughnut } from 'react-chartjs-2'

const trendIcon = {
  Incremented: { icon: 'trend-up', color: 'green' },
  Decremented: { icon: 'trend-down', color: 'red' }
}
const options = {
  responsive: true,
  cutout: 75,
  radius: 55,
  animation: false,
  plugins: {
    datalabels: false,
    legend: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  }
}
const plugins = [
  {
    beforeDraw: function (chart) {
      let width = chart.width,
        height = chart.height - 8,
        ctx = chart.ctx
      ctx.restore()
      ctx.font = 'bold 12px Helvetica Neue, Helvetica, Arial, sans-serif'
      ctx.textBaseline = 'top'
      let text = `${chart.data.datasets[0].data[0]}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2
      ctx.fillText(text, textX, textY)
      ctx.save()
    }
  }
]
export default class DoughnutChart extends Component {
  formatHeading = (text) => {
    if (typeof text !== 'string') {
      return text
    }
    return capitalize(text.split('_').join(' '))
  }
  generateChartData = () => {
    const { percentage } = this.props
    const dataPercentage = percentage !== undefined && typeof percentage === 'string' ? Number(percentage) : 0
    return {
      datasets: [
        {
          data: [dataPercentage, dataPercentage > 100 ? 0 : 100 - dataPercentage],
          backgroundColor: ['#3dc3b0', '#e1e1e1'],
          borderWidth: 0
        }
      ]
    }
  }
  render() {
    return (
      <>
        <span className="text-center">
          <sh-text size="header-1" class="chart-header-alignment">
            {this.formatHeading(this.props.heading)}
          </sh-text>
          <Doughnut key={this.props.chartId} data={this.generateChartData()} options={options} plugins={plugins} />
          <sh-icon
            class="trend-icon"
            button
            toggle
            icon={trendIcon[this.props.trend]?.icon}
            color={trendIcon[this.props.trend]?.color}
            size="s"
            slot="footer"
          ></sh-icon>
        </span>
      </>
    )
  }
}
