import InitiatorRecipientProfile from './InitiatorRecipientProfile'
import stores from 'stores'

export default function ShiftSwapRow({ shiftSwap, onClickButton }) {
  const formatDate = (date) => stores.timeService().timeMoment(date).format('MMM DD, YYYY HH:mm')
  const {
    shiftName,
    time,
    acceptedDate,
    initiatorStaffProfile: initiator,
    initiatorWeekDay,
    initiatorDay,
    recipientWeekDays,
    recipientDays,
    initiatorAvatarUrl,
    recipientStaffProfile: recipient,
    recipientAvatarUrl,
    updatedAt,
    id
  } = shiftSwap

  const isAccepted = acceptedDate !== null

  return (
    <tr>
      <td>
        {shiftName} &middot; <span>{time}</span>
      </td>

      {/*TODO tony - remove this class, move style to component scope instead*/}
      <td className="initiator-recipient">
        <InitiatorRecipientProfile {...initiator} avatarUrl={initiatorAvatarUrl} />
      </td>

      <td>
        <div className="swap-dates row align-middle">
          <div className="calendar col-2">
            {initiatorWeekDay}
            <div className="date">{initiatorDay}</div>
          </div>

          <i className="icon-swap hx-swap-icon col-2 row align-center" />

          <div className="col-8 row">
            {recipientDays.map((recipientDay, i) => (
              <div className="calendar col-3" key={i}>
                {recipientWeekDays[i]}
                <div className="date">{recipientDay}</div>
              </div>
            ))}
          </div>
        </div>
      </td>

      {/*TODO tony - remove this class, move style to component scope instead*/}
      <td className="initiator-recipient">
        <InitiatorRecipientProfile {...recipient} avatarUrl={recipientAvatarUrl} />
      </td>

      <td>
        <div className="row">
          {isAccepted ? (
            <span className="responder-date">{formatDate(updatedAt)}</span>
          ) : (
            <span className="label warning">Not Yet Accepted</span>
          )}
        </div>
      </td>

      <td>
        <div className="button-group actions row">
          <div className="col">
            <sh-button
              color={isAccepted ? 'secondary' : 'tertiary'}
              onClick={(e) => onClickButton(e, isAccepted ? 'deny' : 'remove', id)}
              label={isAccepted ? 'Deny' : 'Remove'}
            />
          </div>

          <div className="col">
            {isAccepted && (
              <sh-button color="primary" onClick={(e) => onClickButton(e, 'approve', id)} label="Approve" />
            )}
          </div>
        </div>
      </td>
    </tr>
  )
}
