export default function Empty({ isLoading = false }) {
  return (
    <>
      <div className="row">
        <div className="col col-12 hx-no-requests">
          {isLoading ? (
            'Loading...'
          ) : (
            <>
              <i className="icon-checkmark success" />
              &nbsp;There are no pending Time Offs
            </>
          )}
        </div>
      </div>
    </>
  )
}
