export default class DataController {
  constructor(component) {
    this.component = component
  }

  get adminStores() {
    return this.component.props.adminStores
  }

  get appState() {
    return this.component.props.appState
  }

  get facilityUsersStore() {
    return this.adminStores.get('facilityUsers')
  }

  get unitStore() {
    return this.adminStores.get('unit')
  }

  get shiftStore() {
    return this.adminStores.get('shift')
  }

  get adminInfo() {
    return this.facilityUsersStore.getAdminInfo()
  }

  get shifts() {
    return this.appState.getIn(['admin.Shift', 'list'])
  }

  get facilityUsers() {
    const { search } = this.adminInfo

    return search
      ? this.facilityUsersStore.filter(this.appState, search.field, this.component.state.query)
      : this.appState.getIn(['admin.FacilityUserInput', 'list'])
  }

  loadData() {
    this.facilityUsersStore.loadFacilityUsers()
    this.unitStore.loadUnits()
    this.shiftStore.loadShifts()
  }

  reloadFacilityUsers = () => {
    return this.facilityUsersStore.loadFacilityUsers({
      includeAssignments: true
    })
  }
}
