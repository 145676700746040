export default function getOptions() {
  return {
    cutout: 60,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 20,
        left: 10
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          boxHeight: 16
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: -4,
        formatter: function (label: number, context: any) {
          if (label === 0) {
            return ''
          }
          const donutSum = context.dataset.data.reduce((acc: number, ele: number) => acc + ele, 0)
          const datasetLength = context.dataset.data.length

          if (context.dataIndex === datasetLength - 1) {
            const percentageBeforeLastIndex: number = getPercentageBeforeLastIndex(context.dataset.data, donutSum)
            return 100 - percentageBeforeLastIndex + '%'
          }

          return Math.round(getPercentage(label, donutSum)) + '%'
        }
      }
    }
  }
}

function getPercentageBeforeLastIndex(dataset: number[], donutSum: number) {
  let totalPercentage = 0
  dataset.forEach((data: number, idx: number) => {
    if (idx !== dataset.length - 1) {
      totalPercentage += getPercentage(data, donutSum)
    }
  })
  return totalPercentage
}

function getPercentage(data: number, donutSum: number) {
  return Math.round((data * 100) / donutSum)
}
