import { DefaultAdmin } from '@humanics/he-react-common/lib/admin/components'
import { Link, withRouter } from 'react-router-dom'
function Units(props) {
  const zeroValueFieldHandler = (dataRow, field) => {
    if (dataRow[field] === 0) {
      return (
        <td>
          <span></span>
        </td>
      )
    }
    return (
      <td>
        <span>{dataRow[field]}</span>
      </td>
    )
  }
  const customFieldRenders = {
    hppdTarget: (dataRow) => zeroValueFieldHandler(dataRow, 'hppdTarget'),
    beds: (dataRow) => zeroValueFieldHandler(dataRow, 'beds')
  }
  return <DefaultAdmin {...props} customFields={customFieldRenders} Link={Link} withRouter={withRouter} />
}
export default Units
