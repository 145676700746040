export const PHONE_NUMBER_REGEX = /^(\+)?(\d{0,3})(\d{3})(\d{3})(\d{4})$/

export function formatPhoneNumber(phoneNumber) {
  const cleanedPhoneNumber = phoneNumber.replace(/-/g, '')
  const match = cleanedPhoneNumber.match(PHONE_NUMBER_REGEX)

  if (!match) {
    return phoneNumber
  }

  const domesticNumber = `(${match[3]}) ${match[4]}-${match[5]}`
  const countryCode = match[2] ? (match[1] || '+') + match[2] : ''
  return countryCode ? `${countryCode} ${domesticNumber}` : domesticNumber
}
