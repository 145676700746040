import { navigate } from 'Navigation'
import { Map } from 'immutable'
import { curry, t } from 'i18n'
import { keyBy } from 'utils'

const tRules = curry('calendar.rules.open_shifts.')

//          | will fulfill opportunity? |	someone accepted and waiting to be approved
//------------------------------------------------------------------------------------
// HMN-4028 | Yes                       | Yes
// HMN-4027	| Yes	                      | No
// HMN-4026 | No                        | Yes
// HMN-4025	| No                        | No

const getRule = (willFulfillOpportunity, hasSomeoneElseAccepted, rules) => {
  const { fillOpenShiftAmberRule, fillOpenShiftGreenRule, addToShiftAmberRule, addToShiftRule } = rules
  if (willFulfillOpportunity) {
    return hasSomeoneElseAccepted ? fillOpenShiftAmberRule : fillOpenShiftGreenRule
  } else {
    return hasSomeoneElseAccepted ? addToShiftAmberRule : addToShiftRule
  }
}

export async function canCreateShiftAssignment(component, staffPath, cellIndex, calendarStore) {
  const {
    props: { Dialog, activeDateRange },
    calendar
  } = component

  const facilityUsersMap = activeDateRange.get('facilityUsersMap')
  const { staff, shiftDay } = calendar.getCellDetails(staffPath, cellIndex)

  let opportunity = shiftDay?.get('opportunity')
  const shiftOpportunityExists = opportunity !== null
  // An open shift opportunity exists for that role
  if (!shiftOpportunityExists) {
    return true
  }

  const staffId = staff.get('id')
  const willFulfillOpportunity = shiftDay?.get('staffMismatch') === 1
  const acceptedByUserIds = opportunity?.get('acceptedByUserIds')
  const approvedUserIds = opportunity?.get('approvedUserIds')
  const pendingForApprovalUserIds = acceptedByUserIds?.filter(
    (userId) => !approvedUserIds.includes(userId) && userId !== staffId
  )

  const hasSomeoneElseAccepted = pendingForApprovalUserIds?.size > 0
  const rule = getRule(willFulfillOpportunity, hasSomeoneElseAccepted, {
    fillOpenShiftAmberRule,
    fillOpenShiftGreenRule,
    addToShiftAmberRule,
    addToShiftRule
  })

  const result = await rule()
  return result === null || result === true

  // https://hesiemens.atlassian.net/browse/HMN-4025
  async function addToShiftRule() {
    // Adding the staff member does not fulfill the opportunity
    // The staff member for whom the shift assignment is being added may or may not be invited AND
    // There is nobody else that has accepted and is waiting to be approved
    const name = staff.get('fullName')
    const answer = await Dialog.select(tRules('add_to_shift.message', { name }), {
      title: tRules('add_to_shift.title'),
      type: 'warning',
      left: t('review'),
      accept: t('yes')
    })

    return answer === 'left' ? navigateToOpportunity() : answer
  }

  // https://hesiemens.atlassian.net/browse/HMN-4025
  async function addToShiftAmberRule() {
    // Adding the staff member does not fulfill the opportunity (# of openings left >0) AND
    // The staff member for whom the shift assignment is being added may or may not be invited AND
    // There is at least one other staff that has accepted and is waiting to be approved
    const name = staff.get('fullName')
    const names = await staffNames(pendingForApprovalUserIds)
    const answer = await Dialog.select(tRules('add_to_shift_amber.message', { name, names }), {
      title: tRules('add_to_shift_amber.title'),
      type: 'warning',
      left: t('review'),
      accept: t('yes')
    })

    return answer === 'left' ? navigateToOpportunity() : answer
  }

  // https://hesiemens.atlassian.net/browse/HMN-4027
  function fillOpenShiftGreenRule() {
    // Adding the staff member fulfills the opportunity (# of openings left =0) AND
    // There is nobody else that has accepted and is waiting to be approved
    const name = staff.get('fullName')
    return Dialog.select(tRules('fill_open_shift_green.message', { name }), {
      title: tRules('fill_open_shift_green.title'),
      type: 'success',
      accept: t('yes')
    })
  }

  // https://hesiemens.atlassian.net/browse/HMN-4028
  async function fillOpenShiftAmberRule() {
    // Adding the staff member fulfills the opportunity (# of openings left =0) AND
    // There is at least one other staff that has accepted and is waiting for to be approved
    const name = staff.get('fullName')
    const names = await staffNames(pendingForApprovalUserIds)
    const answer = await Dialog.select(tRules('fill_open_shift_amber.message', { name, names }), {
      title: tRules('fill_open_shift_amber.title'),
      type: 'warning',
      left: t('review'),
      accept: t('yes')
    })

    return answer === 'left' ? navigateToOpportunity() : answer
  }

  function navigateToOpportunity() {
    const id = opportunity.get('id')
    navigate.from.Calendar.to.ShiftOpportunity({ id })
    return false
  }

  async function staffNames(userIds) {
    const staffIdsFromOtherUnits = userIds
      .filter((userId) => !facilityUsersMap.get(userId))
      .reduce((memo, userId) => memo.concat(userId), [])

    let otherUnitsStaffMap = Map()
    if (staffIdsFromOtherUnits.length > 0) {
      const staffFromOtherUnits = await calendarStore.loadOtherUnitsStaff(staffIdsFromOtherUnits)
      otherUnitsStaffMap = keyBy(staffFromOtherUnits, 'userId')
    }

    let names = userIds
      .map((userId) => facilityUsersMap.get(userId) || otherUnitsStaffMap.get(userId))
      .map((staffItem) => {
        const profile = staffItem.get('facilityProfile')
        return `${profile.get('firstName')} ${profile.get('lastName')}`
      })

    if (names.size > 2) {
      names = names.update(names.size - 1, (name) => `and ${name}`)
    }

    return names.toArray().join(', ')
  }
}
