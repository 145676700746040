import './ModeDay.scss'
import { navigate } from 'Navigation'
import { Link } from 'react-router-dom'

export default function ModeDay({ activeDateRange, timeService, showSpinner }) {
  const days = activeDateRange.get('days')
  const day = days.filter((day) => day.isActive)[0]
  if (!day) {
    return null
  }

  const { weekDayMonthAndDay: currentDay, dateTime } = day
  const nextDayMoment = timeService.timeMoment(dateTime).add(1, 'day')
  // const nextDay = nextDayMoment.format('ddd, MMM DD');
  const nextUsDate = nextDayMoment.format('MM-DD-YYYY')
  const prevUsDate = timeService.timeMoment(dateTime).add(-1, 'day').format('MM-DD-YYYY')
  const hours = 24
  const hoursArray = Array.from(Array(hours).keys())
  const width = `${100 / hours}%`
  const prevDayUrl = navigate.from.Calendar.to.Calendar({ date: prevUsDate, mode: 'day' }, false)
  const nextDayUrl = navigate.from.Calendar.to.Calendar({ date: nextUsDate, mode: 'day' }, false)

  return (
    <div className="hx-calendar-header-day day-view bl1 bb1">
      <div className="row bb1 border-periwinklegray">
        <div className="col-9 br2 border-periwinklegray small">
          <div className="row text-left trout upper black _current-day align-middle" onClick={showSpinner}>
            {currentDay}
            <div className="row col pl10">
              <Link to={prevDayUrl}>
                <sh-icon icon="arrow-face-left-l" size="xs" button />
              </Link>
              <Link to={nextDayUrl}>
                <sh-icon icon="arrow-face-right-l" size="xs" button />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hours-list-container">
        {hoursArray.map((hour, index) => (
          <div
            key={index}
            width={width}
            className="hour-container text-center trout upper black br2 border-periwinklegray"
          >
            {hour}
          </div>
        ))}
      </div>
    </div>
  )
}
