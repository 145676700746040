import { Component, createRef } from 'react'
import { pick, isEqual } from 'lodash'
import StaffGroup from './StaffGroup'
import { List, Map } from 'immutable'
import classNames from 'classnames'
import StaffFilter from './StaffFilter'
import ScheduledStaffGroup from './StaffGroup/ScheduledStaffGroup'
import CustomNumericInput from '@humanics/he-react-common/lib/components/Form/CustomNumericInput'
import { OpenShiftStaff } from '../../../../../entityWrappers'
import { isFacilityEnabledFeature } from 'utils'
import { withAppContext } from 'AppContext'
import ViewRemoteWeScanStaffRequests from './RemoteStaffRequest/weScan/ViewRemoteWeScanStaffRequests'
import CreateRemoteWeScanStaffRequest from './RemoteStaffRequest/weScan/modals/CreateRemoteWeScanStaffRequest'
import { t } from 'i18n'

class StaffList extends Component {
  constructor(props) {
    super(props)
    const { isGroupCollapsed = false, requiredStaff = 0 } = props

    this.state = {
      requiredStaff,
      updatingTimeout: 0,
      isGroupCollapsed,
      isHidden: true,
      staffGroups: List(),
      equipmentSupportsRemoteWeScan: false,
      showCreateRemoteWeScanStaffRequestModal: false,
      numberOfRemoteWeScanStaffRequests: 0
    }

    this.viewRemoteWeScanStaffRequestsRef = createRef()
  }

  isType(type) {
    return this.props.type === type
  }

  get isRemoteWeScanStaffRequestsType() {
    return this.isType('remoteWeScanStaffRequests')
  }

  get isScheduledStaffType() {
    return this.isType('approvedStaff')
  }

  get isEligibleStaffType() {
    return this.isType('eligibleStaff')
  }

  get isNotInvitedStaffType() {
    return this.isType('notInvitedStaff')
  }

  componentDidUpdate(prevProps, prevState) {
    const { requiredStaff: prevRequiredStaff } = prevProps
    const { requiredStaff } = this.props

    const { staffGroups: prevStaffGroups, isHidden } = prevState
    const staffGroups = this.getStaffGroups()

    const isStaffRequirementsChanged = requiredStaff !== prevRequiredStaff
    const isStaffGroupsChanged = !isEqual(prevStaffGroups.toJS(), staffGroups.toJS())
    const size = this.getStaffGroupsSize(staffGroups)

    if (isStaffRequirementsChanged) {
      this.setState({ requiredStaff })
    }

    if (isStaffGroupsChanged) {
      this.setState({ staffGroups })
    }

    if (
      isHidden &&
      (size > 0 ||
        this.isScheduledStaffType ||
        (this.isRemoteWeScanStaffRequestsType && this.state.equipmentSupportsRemoteWeScan))
    ) {
      this.setState({ isHidden: false })
    }
  }

  onRemoteWeScanStaffRequestsChange = (remoteStaffRequests) => {
    this.setState({ numberOfRemoteWeScanStaffRequests: remoteStaffRequests?.length || 0 })
  }

  onNewRemoteWeScanStaffRequestCreated = () => {
    this.viewRemoteWeScanStaffRequestsRef?.current?.reloadData()
  }

  componentDidMount() {
    if (!this.isRemoteWeScanStaffRequestsType) {
      return
    }

    const { appState = Map() } = this.props

    const facilitySupportsRemoteWeScan = isFacilityEnabledFeature(appState, 'remote_workflow_wescan', ['write'])

    if (!facilitySupportsRemoteWeScan) {
      return
    }

    const shiftId = this.props.openShift.get('shiftId')
    const { unit } = this.props
    const shiftsListForUnit = unit.get('shifts')
    const shift = shiftsListForUnit?.find((shiftData) => shiftData.get('id') === shiftId)

    this.setState({
      equipmentSupportsRemoteWeScan: shift?.getIn(['remoteDetails', 'isEnabledRemoteService']) || false
    })
  }

  render() {
    let { openShiftController, type, requiredStaff } = this.props
    const { isGroupCollapsed, staffGroups, isHidden, equipmentSupportsRemoteWeScan } = this.state

    if (isHidden) {
      return null
    }

    const { openShift, selectedUserIds } = openShiftController
    const { isPosted } = openShift

    const isRemoteWeScanStaffRequestsType = this.isRemoteWeScanStaffRequestsType
    const isEligibleStaffType = this.isEligibleStaffType
    const isNotInvitedStaffType = this.isNotInvitedStaffType
    const isScheduledStaffType = this.isScheduledStaffType

    const label = isRemoteWeScanStaffRequestsType
      ? getTitle(type, { size: this.state.numberOfRemoteWeScanStaffRequests })
      : getTitle(type, selectedUserIds)

    const iconClass = getIconClass(type)
    const size = this.getStaffGroupsSize(staffGroups)
    const selectedStaffSize = this.getStaffGroupsSize([selectedUserIds])

    const staffGroupCommonProps = pick({ ...this.props, isPosted }, [
      'roles',
      'eligibleUnits',
      'timeService',
      'isPosted',
      'openShiftController',
      'expertises'
    ])

    return (
      <div className="hx-open-shift-request-details-container" key={type} data-section-key={type}>
        <div
          className={`row align-middle pb10 ${classNames({
            pt10: !isScheduledStaffType && !isRemoteWeScanStaffRequestsType
          })}`}
        >
          <h3 className="trout semibold cursor-pointer row align-middle" onClick={this.toggleGroup}>
            {!isScheduledStaffType && !isRemoteWeScanStaffRequestsType && (
              <>
                <span>{selectedStaffSize > 0 ? selectedStaffSize : size}</span>&nbsp;
              </>
            )}
            <span>{label}</span>&nbsp;
            <i
              className={classNames({
                [iconClass]: !isGroupCollapsed,
                'icon-right nano regent-gray': isGroupCollapsed
              })}
            />
          </h3>
          {isScheduledStaffType && !isGroupCollapsed && this.renderTargetCover(size)}
          {isRemoteWeScanStaffRequestsType &&
            equipmentSupportsRemoteWeScan &&
            !isGroupCollapsed &&
            this.renderRemoteWeScanRequestsHeader()}
        </div>

        {isRemoteWeScanStaffRequestsType && !isGroupCollapsed && (
          <ViewRemoteWeScanStaffRequests
            ref={this.viewRemoteWeScanStaffRequestsRef}
            openShift={this.props.openShift}
            timeService={this.props.timeService}
            onDataUpdate={this.onRemoteWeScanStaffRequestsChange}
          />
        )}

        {!isRemoteWeScanStaffRequestsType && (
          <div
            className={classNames({
              'hx-open-shift-staff': !isScheduledStaffType,
              hide: isGroupCollapsed
            })}
          >
            {(isEligibleStaffType || isNotInvitedStaffType) && <StaffFilter {...this.props} />}
            {isScheduledStaffType &&
              staffGroups.map((staffGroup, index) => (
                <ScheduledStaffGroup
                  {...staffGroupCommonProps}
                  staff={staffGroup}
                  type={type}
                  key={`${type}-${index}`}
                  requiredStaff={requiredStaff}
                />
              ))}
            {!isScheduledStaffType && (
              <div className="hx-open-shift-staff-table bg-white">
                <Header type={type} />
                {staffGroups.map((staffGroup, index) => (
                  <StaffGroup {...staffGroupCommonProps} staff={staffGroup} type={type} key={`${type}-${index}`} />
                ))}
              </div>
            )}
          </div>
        )}

        {this.state.showCreateRemoteWeScanStaffRequestModal && (
          <CreateRemoteWeScanStaffRequest
            onClose={() => this.setModalVisibility(false)}
            onCreated={this.onNewRemoteWeScanStaffRequestCreated}
            openShift={this.props.openShift}
          />
        )}
      </div>
    )
  }

  getStaffGroups = () => {
    if (this.isRemoteWeScanStaffRequestsType) {
      return List()
    }

    let { openShiftController, openShiftStaffMap, type, expertises, timeService } = this.props
    const { openShift } = openShiftController
    const expertiseItems = expertises.get('expertiseItems')
    const { unitId } = openShift
    let staff = (openShiftStaffMap.get(type) || List())
      .map((item) => {
        const openShiftStaff = new OpenShiftStaff(openShift, item)
        const { staffExpertises, matchPercentage } = openShiftStaff.expertiseRequirements(expertiseItems, timeService)
        return item.merge({
          staffExpertises,
          matchPercentage
        })
      })
      .sortBy((item) => List([-item.get('matchPercentage', 0), item.get('fullName')]))

    const isEligibleStaff = type === 'eligibleStaff'
    const isNotInvitedStaff = type === 'notInvitedStaff'
    const isNeedsApprovalStaff = type === 'acceptedStaff'

    if (isNeedsApprovalStaff) {
      const approvedStaffUserIds = openShiftStaffMap.get('approvedStaff').map((facilityUser) => facilityUser.get('id'))
      staff = staff.filter((facilityUser) => !approvedStaffUserIds.includes(facilityUser.get('id')))
    }

    let staffGroups = List([])

    if (isEligibleStaff || isNotInvitedStaff) {
      const staffGroupsMap = staff.groupBy((item) => item.get('homeUnitId'))

      staffGroups = staffGroups
        .push(staffGroupsMap.get(unitId))
        .concat(staffGroupsMap.remove(unitId).valueSeq())
        .filter((group) => !!group)
    } else {
      staffGroups = staffGroups.push(staff)
    }

    return staffGroups
  }

  getStaffGroupsSize = (staffGroups) => staffGroups.reduce((size, staffGroup) => (size += staffGroup.size), 0)

  toggleGroup = () => this.setState({ isGroupCollapsed: !this.state.isGroupCollapsed })

  setModalVisibility = (newVisibilityState) => {
    this.setState({ showCreateRemoteWeScanStaffRequestModal: newVisibilityState })
  }

  renderRemoteWeScanRequestsHeader = () => {
    const { openShift, timeService } = this.props
    const shiftStartsAt = openShift.get('shiftStartsAt')
    const isShiftInPast = timeService.isPastDay(shiftStartsAt)

    return (
      <>
        <span className="push-right" id="requestWeScanStaffButton">
          <sh-button
            size="xl"
            onClick={() => this.setModalVisibility(true)}
            label={t('equipments.request_wescan_staff')}
            disabled={isShiftInPast ? true : undefined}
          ></sh-button>
        </span>
        {isShiftInPast && (
          <sh-tooltip
            label={t('equipments.date_already_past')}
            target="requestWeScanStaffButton"
            placement="top"
            variation="short"
          ></sh-tooltip>
        )}
      </>
    )
  }

  renderTargetCover = (size) => {
    const { openShift, isLoading } = this.props
    const { requiredStaff } = this.state

    if (!openShift) {
      return null
    }

    const inputProps = {
      type: 'math',
      order: '-i+',
      definition: {
        min: 0,
        disabled: isLoading
      },
      value: requiredStaff,
      onChange: this.onChangeTargetCover,
      formItemClasses: ''
    }
    return (
      <>
        <span
          className={classNames({
            _size: true,
            understaffed: size < requiredStaff,
            overstaffed: size > requiredStaff
          })}
        >
          {size}
        </span>
        <span className="row align-middle _targetCover">
          <label>
            <span className="form-label">Target Cover</span>
          </label>
          <CustomNumericInput {...inputProps} />
        </span>
      </>
    )
  }

  onChangeTargetCover = (requiredStaff) => {
    const { openShiftController, isLoading } = this.props
    const { requiredStaff: initRequiredStaff, updatingTimeout } = this.state
    if (isLoading) {
      return
    }
    if (updatingTimeout) {
      clearTimeout(this.state.updatingTimeout)
    }
    this.setState({ requiredStaff })
    if (initRequiredStaff !== requiredStaff) {
      this.setState({
        updatingTimeout: setTimeout(async () => {
          this.setState({ isLoading: true })
          await openShiftController.createShiftDayRequirement(requiredStaff)
          this.setState({ isLoading: false, initRequiredStaff: requiredStaff })
        }, 500)
      })
    }
  }
}

function Header({ type }) {
  const isEligibleStaff = type === 'eligibleStaff'

  return (
    <header className="row">
      <div />
      <div className="upper black gulf-blue">{isEligibleStaff ? 'eligible staff' : 'staff'}</div>
      <div className="upper black gulf-blue">matching</div>
      <div className="upper black gulf-blue">unit / position </div>
    </header>
  )
}

const getTitle = (type, selectedUserIds) => {
  switch (type) {
    case 'approvedStaff':
      return 'Scheduled Staff'
    case 'acceptedStaff':
      return 'Needs Approval'
    case 'notRespondedStaff':
      return 'Invited To Shift'
    case 'notInvitedStaff':
      return 'Invite Additional Staff'
    case 'eligibleStaff':
      return selectedUserIds.size === 0 ? 'Eligible Staff' : 'Selected Staff'
    case 'remoteWeScanStaffRequests': {
      const headerTitle = t('equipments.wescan_requests_header')
      return selectedUserIds.size ? `${headerTitle} (${selectedUserIds.size})` : headerTitle
    }
    default:
      return ''
  }
}

const getIconClass = (type) => {
  switch (type) {
    case 'acceptedStaff':
      return 'icon-has-access'
    case 'notRespondedStaff':
      return 'icon-pending-access'
    case 'notInvitedStaff':
      return 'icon-no-access'
    default:
      return ''
  }
}

export default withAppContext(StaffList)
