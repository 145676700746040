import { List, Map } from 'immutable'
import { ComponentController } from 'Common/components'

export default class DataController extends ComponentController {
  get defaultStatuses() {
    return List(['connected', 'invited', 'disconnected', 'terminated'])
  }

  get defaultState() {
    const { staffPositions } = this
    const staffPositionsIds = staffPositions.map((positions) => positions.get('id'))

    return {
      staffListConfig: Map({
        sort: Map({
          displayNameAs: 'firstNameLastName',
          sortBy: 'firstName',
          orderBy: 'asc'
        }),
        search: '',
        activePage: 1,
        positions: List(staffPositionsIds),
        statuses: this.defaultStatuses,
        expirationDateType: 'current'
      })
    }
  }

  get staffListConfig() {
    return this.state.staffListConfig
  }

  get staffList() {
    return this.props.appState.getIn(['staff', 'staffList', 'edges'])
  }

  get transferringStaff() {
    return this.props.appState.getIn(['staff', 'transferringStaff'])
  }

  get staffPositions() {
    return this.props.appState.getIn(['staff', 'staffPositions'])
  }

  get shifts() {
    return this.props.appState.getIn(['staff', 'shifts'])
  }

  get units() {
    return this.props.appState.getIn(['generalData', 'eligibleUnits'])
  }

  get usersTotalCount() {
    return this.props.appState.getIn(['staff', 'staffList', 'pageInfo', 'totalCount'])
  }

  get usersPerPage() {
    return this.props.appState.getIn(['staff', 'staffList', 'pageInfo', 'limit'])
  }

  isStaffListFilterChanged(prevConfig) {
    return this.staffListConfig.some((value, key) => prevConfig.get(key) !== value)
  }

  isStaffTranferFilterChanged(prevConfig) {
    const staffTransferFilterKeys = ['sort', 'search', 'positions', 'statuses']
    return this.staffListConfig
      .filter((value, key) => staffTransferFilterKeys.includes(key))
      .some((value, key) => prevConfig.get(key) !== value)
  }

  setStaffListConfig(properties, resetPage = true) {
    const object = resetPage ? { ...properties, activePage: 1 } : properties
    const staffListConfig = this.staffListConfig.merge(object)

    return this.setState({ staffListConfig })
  }

  onChangeSort = (sort) => this.setStaffListConfig({ sort })

  onChangeExpirationDateType = (expirationDateType) => this.setStaffListConfig({ expirationDateType })

  onChangeSearch = (search) => this.setStaffListConfig({ search })

  onChangePositions = (positions) => this.setStaffListConfig({ positions })

  onChangeStatuses = (statuses) => this.setStaffListConfig({ statuses })

  onChangePage = (activePage) => this.setStaffListConfig({ activePage }, false)

  get commonFilters() {
    const { staffListConfig, defaultStatuses } = this

    const positionIds = staffListConfig.get('positions')
    const positions = this.staffPositions
      .filter((position) => positionIds.includes(position.get('id')))
      .map((position) => position.get('name'))
      .reduce((memo, position) => memo.concat(position), [])

    const isAllStatusesSelected = defaultStatuses.size === staffListConfig.get('statuses').size
    const statuses = isAllStatusesSelected
      ? undefined
      : staffListConfig.get('statuses').reduce((memo, status) => memo.concat(status), [])

    const sortBy = staffListConfig.getIn(['sort', 'sortBy'])
    const orderBy = staffListConfig.getIn(['sort', 'orderBy'])
    const isSortDescending = !!orderBy && orderBy === 'desc'

    const search = staffListConfig.get('search')

    return { positions, statuses, search, isSortDescending, sortBy }
  }

  get staffListFilters() {
    const { positions, statuses, search, sortBy, isSortDescending } = this.commonFilters
    const expirationDateType = this.staffListConfig.get('expirationDateType')
    const { startDate, endDate, endsAtBefore } = this._getStaffDateRange(expirationDateType)

    return {
      positions,
      statuses,
      search,
      sortBy,
      isSortDescending,
      startDate,
      endDate,
      endsAtBefore
    }
  }

  get staffTransferFilters() {
    const { positions, statuses, search, isSortDescending, sortBy } = this.commonFilters
    return { positions, statuses, search, isSortDescending, sortBy }
  }

  resetStaffList() {
    this.props.staffStore.resetStaffList()
  }

  async loadTransferringStaff() {
    const { unit, staffStore } = this.props
    const isUnitReady = unit.get('isReady')

    if (isUnitReady) {
      const { staffTransferFilters } = this
      const { positions } = staffTransferFilters

      const isPositionsUndefined = !positions || positions.length === 0

      if (isPositionsUndefined) {
        return staffStore.resetStaffList()
      }

      const unitId = unit.get('id')

      this.setState({ isTransferringStaffLoading: true })
      await staffStore.loadTransferringStaff(unitId, staffTransferFilters)
      this.setState({ isTransferringStaffLoading: false })
    }
  }

  reloadStaff = () => {
    return Promise.all([this.loadStaffList(), this.loadTransferringStaff()])
  }

  async loadStaffList() {
    const { unit, staffStore } = this.props
    const isUnitReady = unit.get('isReady')

    if (isUnitReady) {
      const { staffListFilters } = this
      const { positions } = staffListFilters

      const isPositionsUndefined = !positions || positions.length === 0

      if (isPositionsUndefined) {
        return staffStore.resetStaffList()
      }

      const unitId = unit.get('id')
      const page = this.staffListConfig.get('activePage')

      this.setState({ isStaffListLoading: true })
      await staffStore.loadUnitStaff(unitId, page, staffListFilters)
      this.setState({ isStaffListLoading: false })
    }
  }

  _getStaffDateRange(expirationDateType) {
    const { timeService } = this.props
    const today = timeService.timeMoment(null)

    if (expirationDateType === 'current') {
      const startDate = today.toISOString()
      const endDate = today.clone().add(1, 'day').toISOString()

      return { startDate, endDate, endsAtBefore: null }
    }

    const startDate = today.clone().add(-100, 'year').toISOString()

    if (expirationDateType === 'past') {
      const endDate = today.toISOString()
      const endsAtBefore = today.toISOString()

      return { startDate, endDate, endsAtBefore }
    }

    const endDate = today.clone().add(100, 'year').toISOString()

    return { startDate, endDate, endsAtBefore: null }
  }
}
