import { PureComponent } from 'react'
import Week from '../../../../../../../../../Calendar/Grid/VRole/Header/ModeFull/Week.js'
import { chunk } from 'lodash'
import './ModeFull.scss'

export default class ModeFull extends PureComponent {
  render() {
    const days = this.props.activeDateRange.get('days')
    const weeks = chunk(days, 7)
    const weeksCount = weeks.length
    const width = `${100 / weeksCount}%`

    return (
      <header className="row bg-cadetblue">
        <section className="_full col-12">
          <div className="hx-calendar-group-days row">
            {weeks.map((days, index) => (
              <Week key={index} width={width} days={days} />
            ))}
          </div>
        </section>
      </header>
    )
  }
}
