import { getCommonFieldsDefinition } from '../../utils'

const form = {
  skillGroup: {
    definition: {
      label: 'Skill Group',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'skillGroup',
    formItemClasses: 'col-12'
  },
  skillType: {
    definition: {
      label: 'Skill Type',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'skillType',
    formItemClasses: 'col-6 pr30'
  },
  ...getCommonFieldsDefinition()
}

export default form
