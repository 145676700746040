import { PureComponent } from 'react'
import SelectFilter from 'Manager/components/SelectFilter'
import DropdownSelector from 'Manager/components/DropdownSelector'
import MultiSelectFilter from 'Manager/components/MultiSelectFilter'
import SearchInput from 'components/Form/SearchInput'
import { Map } from 'immutable'
import { Link } from 'react-router-dom'
import { navigate } from 'Navigation'
import { keyBy } from 'utils'

export default class StaffListFilter extends PureComponent {
  render() {
    const { staffListConfig, staffPositions, isEnabledFeature } = this.props
    const sort = staffListConfig.get('sort')
    const positions = staffListConfig.get('positions')
    const statuses = staffListConfig.get('statuses')
    const expirationDateType = staffListConfig.get('expirationDateType')

    const viewProps = {
      ...getViewDefinition(),
      value: expirationDateType,
      setConfig: (option) => this.props.onChangeExpirationDateType(option),
      name: 'View'
    }

    const positionsProps = {
      ...getPositionDefinition(staffPositions),
      values: positions,
      setConfig: this.props.onChangePositions,
      name: 'Position',
      field: 'positions'
    }

    const statusesProps = {
      ...getStatusesDefinition(),
      values: statuses,
      setConfig: this.props.onChangeStatuses,
      name: 'Status',
      field: 'statuses',
      required: true,
      deselectAllAllowed: false
    }

    const sortProps = {
      ...getSortDefinition(),
      values: sort,
      setConfig: this.props.onChangeSort,
      name: 'Sort',
      field: 'sort',
      required: true,
      deselectAllAllowed: false
    }

    const newStaffUrl = navigate.from.Staff.to.StaffNew({}, false)

    return (
      <div className="hx-staff-list-filter bg-white bb1 row calendar-toolbar p10 pv0">
        <div className="col col-5">
          <span className="river-bad">
            <SelectFilter {...viewProps} />
          </span>
          <span className="river-bad">
            <MultiSelectFilter {...positionsProps} />
          </span>
          <span className="river-bad">
            <MultiSelectFilter {...statusesProps} />
          </span>
          {isEnabledFeature('staff-display-settings') && (
            <span className="river-bad pl10 bl1">
              <DropdownSelector {...sortProps} />
            </span>
          )}
        </div>

        <div className="col col-2 row align-middle align-center">
          <div className="title">
            <h1>Staff</h1>
          </div>
        </div>

        <div className="col col-4 row align-middle align-right">
          <div className="p10 pv0">
            <Link to={newStaffUrl}>
              <sh-button color="tertiary" label="+ Add Staff" size="auto" />
            </Link>
          </div>
        </div>

        <div className="col-1 hx-staff-filter-input bl1">
          <SearchInput
            classNames="bg-alabaster"
            placeholder="Search"
            onChange={(e) => this.props.onChangeSearch(e.target.value)}
            onReset={(e) => this.props.onChangeSearch(e.target.value)}
            iconStyle={{ left: '-2px', top: '1em' }}
          />
        </div>
      </div>
    )
  }
}

const getViewDefinition = () => {
  return {
    minWidth: 100,
    name: 'Employment type',
    options: {
      all: 'All',
      current: 'Current',
      past: 'Past'
    }
  }
}

const getPositionDefinition = (staffPositions) => {
  const staffPositionsMap = keyBy(staffPositions, 'id').map((position) => position.get('name'))

  return {
    minWidth: 100,
    name: 'Role',
    options: staffPositionsMap,
    deselectAllAllowed: true
  }
}

const getStatusesDefinition = () => {
  return {
    minWidth: 100,
    name: 'Status',
    options: statusesOptions,
    deselectAllAllowed: true,
    icons: statusesIcons
  }
}

const getSortDefinition = () => {
  return {
    minWidth: 100,
    name: 'Sort',
    selectOptions: dropdownSelectors
  }
}

const statusesOptions = Map({
  connected: 'Has Access',
  invited: 'Pending access',
  disconnected: 'No access',
  terminated: 'Access ending'
})

const statusesIcons = {
  connected: 'icon-has-access',
  invited: 'icon-pending-access',
  disconnected: 'icon-no-access',
  terminated: 'icon-access-ending'
}

const dropdownSelectors = [
  {
    label: 'Display Name As',
    field: 'displayNameAs',
    items: {
      enum: [
        { key: 'firstNameLastName', name: 'Jane Smith' },
        { key: 'lastNameFirstName', name: 'Smith, Jane' }
      ]
    }
  },
  {
    label: 'Sort By',
    field: 'sortBy',
    items: {
      enum: [
        { key: 'firstName', name: 'First Name' },
        { key: 'lastName', name: 'Last Name' }
      ]
    }
  },
  {
    label: 'Order By',
    field: 'orderBy',
    items: {
      enum: [
        { key: 'asc', name: 'A to Z' },
        { key: 'desc', name: 'Z to A' }
      ]
    }
  }
]
