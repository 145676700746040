import './OpenShiftsStatusBar.scss'

export default function OpenShiftsStatusBar({
  acceptanceRate,
  totalOpenShifts,
  totalTodayOpenShifts,
  totalShiftOpportunities
}) {
  return (
    <div className="hx-open-shifts-status-bar">
      <div className="hx-open-shifts-status-item">
        <div className="status-icon">
          <div className="icon icon-user-clock" />
        </div>
        <div className="status-number semibold">{totalOpenShifts}</div>
        <div data-testid="open-shifts-status-description" className="status-description">
          Open Shifts
        </div>
      </div>
      <div className="hx-open-shifts-status-item">
        <div className="status-icon">
          <div className="icon icon-user-add" />
        </div>
        <div className="status-number semibold">{totalShiftOpportunities}</div>
        <div className="status-description">Shift Invites</div>
      </div>
      <div className="hx-open-shifts-status-item">
        <div className="status-icon">
          <div className="icon icon-user-check" />
        </div>
        <div className="status-number semibold">{acceptanceRate}%</div>
        <div className="status-description">Acceptance Rates</div>
      </div>
      <div className="hx-open-shifts-status-item">
        <div className="status-icon semibold">
          <div className="icon icon-history" />
        </div>
        <div className="status-number semibold">{totalTodayOpenShifts}</div>
        <div className="status-description">
          Shifts Created<span className="info regent-gray">Last 24h</span>
        </div>
      </div>
    </div>
  )
}
