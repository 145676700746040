import useShuiEventHook from '../Common/customHooks/shuiEventHook'

import { useCallback } from 'react'

export interface IReasonDetailsProps {
  reasonDetails: string
  setReasonDetails: (reasonDetails: string) => void
}

const ReasonDetails = ({ reasonDetails, setReasonDetails }: IReasonDetailsProps) => {
  const handleReasonDetailsEvent = useCallback(
    (e: any) => {
      const newReasonDetails = e?.target?.value
      setReasonDetails(newReasonDetails)
    },
    [setReasonDetails]
  )

  const eventName = 'value-changed'
  const reasonDetailsRef = useShuiEventHook(eventName, handleReasonDetailsEvent)

  return <sh-input-text ref={reasonDetailsRef} label="Details" value={reasonDetails} />
}

export default ReasonDetails
