import classNames from 'classnames'

export default function Day({ day }) {
  const { isWeekend, isToday, isPrevDay, dayOfMonth } = day
  return (
    <div
      className={classNames('col-1 br1 _headerCell', {
        'bg-alabaster': isWeekend,
        'bg-white': !isWeekend,
        'current-day': isToday,
        'prev-day': isPrevDay
      })}
    >
      {dayOfMonth}
    </div>
  )
}
