import { t } from 'i18n'

import { TimeService } from 'services'
import RemoteWeScanStaffRequestController from './RemoteWeScanStaffRequestController'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'

interface IViewRemoteWeScanStaffRowProps {
  remoteStaffRequest: IRemoteStaffRequest
  timeService: TimeService

  remoteWeScanStaffRequestController: RemoteWeScanStaffRequestController
  handleCancelRequestButtonClick: (remoteStaffRequest: IRemoteStaffRequest) => void
}

export default function RemoteWeScanStaffRequestRow(props: IViewRemoteWeScanStaffRowProps) {
  const { remoteStaffRequest, remoteWeScanStaffRequestController, handleCancelRequestButtonClick } = props

  const weScanRequestDetails = remoteStaffRequest.requestDetails.wescan!
  const requestedBy = `${weScanRequestDetails.requestor.firstName} ${weScanRequestDetails.requestor.lastName}`
  const state = remoteStaffRequest.state
  const canRequestBeCancelled = remoteWeScanStaffRequestController.canRemoteWeScanRequestBeCancelled(remoteStaffRequest)
  const isPastCurrentTime = remoteWeScanStaffRequestController.isRequestPastCurrentTime(remoteStaffRequest)
  const appointmentSlots = remoteWeScanStaffRequestController.getAppointmentSlots(weScanRequestDetails.appointments)

  return (
    <div className="row bg-white">
      <div>{weScanRequestDetails.serviceType}</div>
      <div>{weScanRequestDetails.procedures?.join(', ')}</div>
      <div>{state}</div>
      <div title={appointmentSlots}>{appointmentSlots}</div>
      <div>{requestedBy}</div>
      <div>
        <div className="actions">
          {/* TODO: This will be implemented later */}
          {/* <div>
            <sh-icon icon="show" size="m" title="View Details"></sh-icon>
          </div> */}
          {canRequestBeCancelled && (
            <>
              <div id="cancelIconContainer">
                <sh-icon
                  icon="cancel"
                  size="m"
                  disabled={isPastCurrentTime ? true : undefined}
                  onClick={() => handleCancelRequestButtonClick(remoteStaffRequest)}
                ></sh-icon>
              </div>
              <sh-tooltip
                label={
                  isPastCurrentTime
                    ? t('equipments.request_cannot_be_cancelled_message')
                    : t('equipments.cancel_wescan_request_button_text')
                }
                target="cancelIconContainer"
                placement="top"
                variation="short"
              ></sh-tooltip>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
