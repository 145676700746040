import fluxStore, { initialState, onError } from '@humanics/he-react-common/lib/stores/fluxStore'
import { Sentry } from 'services'
import { fromJS, List, Map } from 'immutable'
import { Roles } from 'auth'
import components from './components.json'
import features from './features.json'
import { updateMyUnitManagerProfileMutation } from './Mutations'

const EMPTY_STATE = Map({
  facilities: List(),
  isLoggedIn: false,
  profile: Map({}),
  sitemap: List([])
})

initialState('authentication', EMPTY_STATE)

const hasFacilities = (instance) => {
  return instance.facilities && (instance.facilities.length > 0 || instance.facilities.size > 0)
}

function subscribeForUnauthorizedError(actions) {
  // This handle should process requests when auth.isLoggedIn equals true:
  onError((error) => {
    const isUnauthorized = error.status === 401
    if (isUnauthorized) {
      actions.logOut(error)
    }
  })
}

function updateRoles(facility, facilityUser) {
  const isAdmin = facility.get('isAdmin')
  let result = fromJS(facilityUser)

  if (isAdmin) {
    result = result.update('roleIds', (roles) => roles.push(Roles.InstanceAdministrator))
  }

  return result
}

export default function authenticationStore({
  hideMenuItems,
  resetSitemap,
  updateFacilityUrlId,
  initialize: initializeStores
}) {
  return function (gas, instance, urlId) {
    const actions = fluxStore({
      logOut,
      selectFacility,
      updateStateWithFacilityData,
      updateUnitManagerProfileSettings
    })

    return {
      ...actions,
      authorize: () => optimisticAutoLogin(),
      extendSession: () => extendSession(instance)
    }

    async function optimisticAutoLogin() {
      if (document.location.hash === '#reloadToken') {
        return autoLogin(instance)
      }
      try {
        await instance.autoLogin(urlId)

        const { facilities, facility, facilityUser, unit } = instance
        const { profile: userProfile } = facilityUser
        const auth = buildAuthState({ userProfile, facilities, instance })

        initialState('authentication', auth, true)
        subscribeForUnauthorizedError(actions)

        return actions.updateStateWithFacilityData(facility, facilityUser, unit)
      } catch (e) {
        return autoLogin(instance)
      }
    }

    async function autoLogin(instance) {
      try {
        const result = await gas.autoLogin(instance)
        const auth = buildAuthState(result)

        initialState('authentication', auth, true)
        subscribeForUnauthorizedError(actions)

        await actions.selectFacility(urlId)
      } catch (e) {
        // it was not logged in? don't worry
      }
    }

    async function extendSession(instance) {
      try {
        await gas.extendSession(instance)
      } catch (e) {
        // it was not logged in? don't worry
      }
    }

    function logOut(state, { code, message }) {
      Sentry.resetScope()
      state = state.set('authentication', EMPTY_STATE.set('signOutReason', Map({ code, message })))
      return resetSitemap(state)
    }

    function buildAuthState({ userProfile, instance, facilities }) {
      return Map({
        instance,
        gasGQLClient: gas.gqlClient,
        idns: fromJS(instance.idns),
        isInstanceAdministrator: instance.isInstanceAdministrator,
        isLoggedIn: !instance.mfaRequired,
        mfaRequired: instance.mfaRequired,
        profile: Map(userProfile),
        facilities: fromJS(facilities),
        facility: null,
        components: fromJS(components)
          .toOrderedMap()
          .sortBy((c) => c.get('order')),
        features: fromJS(features),
        sitemap: List([])
      })
    }

    async function selectFacility(state, facilityUrlId) {
      if (!hasFacilities(instance)) {
        return updateStateWithoutFacilityData(state)
      }
      if (facilityUrlId) {
        const currentFacilityUrlId = state.getIn(['authentication', 'facility', 'urlId'])
        const isSameFacility = facilityUrlId === currentFacilityUrlId
        if (isSameFacility) {
          return state
        }
      }

      try {
        await instance.selectFacility(facilityUrlId)
        await instance.selectDefaultUnit()

        const { facility, facilityUser, unit } = instance
        return updateStateWithFacilityData(state, facility, facilityUser, unit)
      } catch (e) {
        return state
      }
    }

    function updateStateWithFacilityData(state, facility, facilityUser, unit) {
      const withAdmin = (facilityUser) => {
        return updateRoles(facility, facilityUser)
      }

      const context = {
        gqlClient: instance.gqlClient,
        facilityTime: facility.get('facilityTime').call(),
        instanceUri: instance.uri,
        apiUri: instance.uri,
        gasApiUri: gas.uri,
        authorized: { facilityScope: facility.get('scope') }
      }

      window.gqlClient = context.gqlClient

      return Promise.resolve(
        state
          .updateIn(['authentication', 'profile'], (current) => current.merge(facilityUser.profile))
          .setIn(['authentication', 'facilityUser'], withAdmin(facilityUser))
          .setIn(['authentication', 'facility'], facility.set('unit', fromJS(unit)))
      )
        .then((state) => updateFacilityUrlId(state, facility.get('urlId')))
        .then((state) => hideMenuItems(state))
        .then((state) => state.set('context', Map(context)))
        .then((state) => initializeStores(state, context))
    }

    function updateStateWithoutFacilityData(state) {
      const context = {
        gqlClient: instance.gqlClient,
        instanceUri: instance.uri,
        apiUri: instance.uri,
        gasApiUri: gas.uri
      }

      window.gqlClient = context.gqlClient

      return Promise.resolve(state)
        .then((state) => hideMenuItems(state, instance.isInstanceAdministrator))
        .then((state) => state.set('context', Map(context)))
        .then((state) => initializeStores(state, context))
    }

    async function updateUnitManagerProfileSettings(state, settings) {
      const {
        updateMyUnitManagerProfile: {
          unitManagerProfile: { settings: newSettings }
        }
      } = await instance.gqlClient.mutate(updateMyUnitManagerProfileMutation, {
        unitManagerProfile: { settings }
      })
      return state.setIn(['authentication', 'facilityUser', 'unitManagerProfile', 'settings'], fromJS(newSettings))
    }
  }
}
