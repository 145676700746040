import { PropsWithChildren } from 'react'
import GraphCard, { Dataset } from './GraphCard'
import { ICommonCardProps } from './CommonCard'
import { merge, sum } from 'lodash'
import { multilineWordedStringSplit } from '../utils' //TODO: have this as chartjs plugin
import getBarCommonOptions from './getBarGraphOptions'
import getDonutCommonOptions from './getDonutGraphOptions'

const donutCommonOptions = getDonutCommonOptions()
const barCommonOptions = getBarCommonOptions()
export interface IDonutBarGraphCardProps extends ICommonCardProps {
  donutLabels: string[]
  barLabels: string[]
  donutDataset: Dataset
  barDatasets: Dataset[]
  extraOptions?: object
  isValid?: boolean //will display nonContentLabel from props if set to false
}

//Donut Graph Options
const donutExtendedOptions: any = {
  cutout: 50,
  plugins: {
    legend: {
      labels: {
        boxWidth: 16
      }
    }
  }
}

const barExtendedOptions: any = {
  scales: {
    y: {
      ticks: {
        mirror: false,
        labelOffset: 0
      },
      grid: {
        display: true
      },
      border: {
        display: true
      }
    }
  },
  categoryPercentage: 0.8,
  plugins: {
    title: {
      display: true,
      align: 'start'
    }
  }
}

export default function DonutBarGraphCard({
  donutDataset,
  barDatasets,
  donutLabels,
  barLabels,
  extraOptions,
  isValid = true,
  //common card props start here
  title,
  onClickActionButton,
  loadingSpinnerText,
  noContentLabel,
  actionButtonIcon,
  isLoading,
  titleIcon,
  isActionButtonDisabled,
  height
}: PropsWithChildren<IDonutBarGraphCardProps>) {
  const widthPerBar = 60
  const barGraphHeight = barLabels.length * widthPerBar + 30
  const barOptions = merge(barCommonOptions, barExtendedOptions)
  const donutOptions = merge(donutCommonOptions, donutExtendedOptions)
  const isBarDataValid = barDatasets.reduce((isValid, nextDataset) => isValid && nextDataset.data.length > 0, true)
  const isDonutDataValid = donutDataset.data.length > 0 && sum(donutDataset.data) > 0

  return (
    <GraphCard
      type={'donutBar' as const}
      datasets={{ donut: [donutDataset], bar: barDatasets }}
      options={merge({ donut: donutOptions, bar: barOptions }, extraOptions)}
      labels={{ donut: donutLabels.map((l) => [l]), bar: barLabels.map((l) => multilineWordedStringSplit(l, 13)) }}
      graphHeight={{ donut: 240, bar: barGraphHeight }}
      isValid={isValid && isBarDataValid && isDonutDataValid}
      //common card props
      title={title}
      height={height}
      onClickActionButton={onClickActionButton}
      loadingSpinnerText={loadingSpinnerText}
      noContentLabel={noContentLabel}
      actionButtonIcon={actionButtonIcon}
      isLoading={isLoading}
      titleIcon={titleIcon}
      isActionButtonDisabled={isActionButtonDisabled}
    />
  )
}
