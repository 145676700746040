import { Component } from 'react'

import SkillFormController from './SkillFormController'
import CreateEditExpertiseModal from '../CreateEditExpertiseModal'

class CreateEditSkillModal extends Component {
  render() {
    const modalProps = {
      ...this.props,
      editPathKey: 'StaffEditSkill',
      createPathKey: 'StaffCreateSkill',
      expertiseType: 'skill',
      FormController: SkillFormController
    }

    return <CreateEditExpertiseModal {...modalProps} />
  }
}

export default CreateEditSkillModal
