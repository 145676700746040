import { Component } from 'react'
import WeekShiftSwaps from './WeekShiftSwaps'
import { t } from 'i18n'

export default class ShiftSwapsTable extends Component {
  render() {
    const { shiftSwaps, isLoading, searchValue } = this.props
    const emptySearchResultsCard = (
      <div className="bg-white row hx-box-shadow rounded p20 mt10">{t('empty_search_results')}</div>
    )

    const shiftSwapsSize = shiftSwaps.valueSeq().reduce((sum, weekInfo) => sum + weekInfo.get('shiftSwaps').size, 0)
    const areSearchResultsEmpty = searchValue !== '' && shiftSwapsSize === 0

    const mapper = (weekInfo, key) => <WeekShiftSwaps weekInfo={weekInfo} key={key} />

    return (
      <table className="hx-shift-swaps">
        <thead>
          <tr className="hx-table-header">
            <th>role</th>
            <th>initiator</th>
            <th>swap dates</th>
            <th>responder</th>
            <th>responder accepted</th>
            <th />
          </tr>
        </thead>

        {areSearchResultsEmpty ? (
          <tr>
            <td colspan={6}>{emptySearchResultsCard}</td>
          </tr>
        ) : (
          shiftSwaps.valueSeq().map(mapper)
        )}
        <Empty shiftSwaps={shiftSwaps} isLoading={isLoading} />
      </table>
    )
  }
}

function Empty({ shiftSwaps, isLoading }) {
  if (shiftSwaps.size > 0) {
    return null
  }

  return (
    <tbody>
      <tr className="week">
        <td colSpan="6">
          {isLoading ? (
            'Loading...'
          ) : (
            <>
              <i className="icon-checkmark success" />
              &nbsp;There are no pending Shift Swaps
            </>
          )}
        </td>
      </tr>
    </tbody>
  )
}
