import { PureComponent } from 'react'
import './Week.scss'

const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export default class Week extends PureComponent {
  render() {
    const { width } = this.props

    const mapper = (day, index) => (
      <div key={index} className="col-1 upper _headerCell white tiny">
        {day}
      </div>
    )

    return (
      <div
        className="bl1"
        style={{
          display: 'inline-block',
          width
        }}
      >
        <div className="row-7">{days.map(mapper)}</div>
      </div>
    )
  }
}
