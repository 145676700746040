import { Component } from 'react'
import { withAppContext } from 'AppContext'
import ShiftSwaps from './ShiftSwaps'
import ShiftSwapsController from './ShiftSwapsController'
import Footer from 'Footer'
import stores from 'stores'

class Layout extends Component {
  render() {
    return (
      <div className="h100">
        <div className="wrapper-container">
          {/* <div className="hx-main-navigation" ref={this.fixed}> */}
          {/* <TopNavigation {...topNavigationProps} /> */}
          {/* <Navigation {...navigationProps} /> */}
          {/* </div> */}
          <ShiftSwaps {...this.props} Controller={ShiftSwapsController} />
        </div>
        <Footer timeService={stores.timeService()} />
      </div>
    )
  }
}

export default withAppContext(Layout)
