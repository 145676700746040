import { Route } from 'react-router'

const HashSwitch = ({ hashMap }) => {
  return (
    <Route
      path="/"
      render={({ location }) => {
        const Component = hashMap[location.hash]
        if (!Component) {
          return null
        }
        return <Component />
      }}
    />
  )
}

export default HashSwitch
