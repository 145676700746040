import { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { withAppContext } from 'AppContext'
import { List } from 'immutable'
import { ImageGraceful } from '../ImageGraceful'
import imagePlaceholder from 'styles/web_v3/image_placeholder.png'
import './CrudCardItem.scss'

class CrudCardItem extends Component {
  getRoleIds = (auth) => {
    return auth?.getIn(['facilityUser', 'roleIds']).toJS() || List()
  }
  render() {
    const {
      title,
      info,
      editItemPath,
      deleteItemHandler,
      imgUrl,
      imgAltText,
      icon,
      disabled,
      id,
      editItemTooltip,
      deleteItemTooltip
    } = this.props
    const auth = this.props.appState?.get('authentication')
    const roleIds = this.getRoleIds(auth)
    const isStaffOnly = roleIds.length === 1 && roleIds[0] === 'staff'
    return (
      <div className={classNames('card-item row align-middle', { disabled })}>
        <div className="content row">
          <div className="col">
            {imgUrl && (
              <div className="img-container rounded">
                <ImageGraceful className="rounded" src={imgUrl} alt={imgAltText} fallbackSrc={imagePlaceholder} />
              </div>
            )}
            {icon && (
              <div className="expiry-indicator">
                <sh-icon icon={icon} color={disabled ? '#AFB3BC' : undefined} />
              </div>
            )}
          </div>
          <div className="col">
            <sh-text size="title-1">{title}</sh-text>
            <sh-text size="body-2">{info}</sh-text>
          </div>
        </div>
        <div className="actions">
          {!isStaffOnly && editItemPath && (
            <Link to={editItemPath}>
              <i id="edit-item-btn" className={'icon icon-edit'} />
              <sh-tooltip label={editItemTooltip || 'Edit'} target="edit-item-btn" placement="left" variation="short" />
            </Link>
          )}
          {!isStaffOnly && deleteItemHandler && (
            <>
              <i id="delete-item-btn" className={'icon delete-icon'} onClick={(e) => deleteItemHandler(id, title)} />
              <sh-tooltip
                label={deleteItemTooltip || 'Delete'}
                target="delete-item-btn"
                placement="right"
                variation="short"
              />
            </>
          )}
        </div>
      </div>
    )
  }
}

export default withAppContext(CrudCardItem)
