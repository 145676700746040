import { ComponentController } from 'Common/components'
import { cloneDeep } from 'lodash'
import { List, Map } from 'immutable'
import stores from 'stores'

export default class FormController extends ComponentController {
  getFormProperties() {
    const { form } = this.state
    const { isEdit } = this.props

    const { today } = stores.timeService()

    const tomorrow = today.clone().add(1, 'day')
    const dayAfter = today.clone().add(2, 'day')

    const todayJSON = {
      name: today.format('ddd, MMM DD') + ' (Today)',
      id: Number(today)
    }
    const tomorrowJSON = {
      name: tomorrow.format('ddd, MMM DD') + ' (Tomorrow)',
      id: Number(tomorrow)
    }

    const dayAfterJSON = {
      name: dayAfter.format('ddd, MMM DD') + ' (Day After)',
      id: Number(dayAfter)
    }

    const todayMap = Map(todayJSON)
    const tomorrowMap = Map(tomorrowJSON)
    const dayAfterMap = Map(dayAfterJSON)

    const optionsDayScheduledArray = [todayMap, tomorrowMap, dayAfterMap]
    const optionsDayScheduled = List(optionsDayScheduledArray)

    form.dayScheduled.definition.items = { enum: optionsDayScheduled }
    form.dayScheduled.definition.disabled = isEdit

    return form
  }

  setFieldValue = (e, field) => {
    let { form } = this.state
    form[field].value = e.target ? e.target.value : ''
    this.updateForm(form)
    // this.setState({ form: cloneDeep(form) });
  }

  serializeForm = () => {
    const { timeService } = this.props
    const {
      form,
      startDateSelected,
      durationSelected
      // shiftSelected
      // recipientsNowSelected,
      // recipientsThenSelected
    } = this.state
    let startsAtMoment = timeService.trueNow() //today, current time

    if (startDateSelected === 'Later') {
      const {
        dayScheduled: { value: date },
        hourScheduled: { value: hour },
        minuteScheduled: { value: minute }
      } = form
      const moment = timeService.timeMoment(date)
      startsAtMoment = moment.set({ hour, minute })
    }

    const duration = parseInt(durationSelected.match(/\d/)[0], 10)
    const unit = duration === 1 ? 'week' : 'day'
    const endsAtMoment = startsAtMoment.clone()
    const endsAt = endsAtMoment.add(duration, unit).toISOString()
    // const shiftTypeIds = shiftSelected === 'All' ? ['DAY', 'NIGHT'] : [shiftSelected.toUpperCase()];
    const shiftTypeIds = []

    return {
      startsAt: startsAtMoment.toISOString(),
      endsAt,
      shiftTypeIds,
      worksAt: startsAtMoment.toISOString()
    }
  }

  async updateForm(form, isFirstLoad) {
    const { timeService, startsAt, endsAt } = this.props
    const hasStartAndEndDate = startsAt && endsAt
    let lastAnnouncementOptions = {}

    // restore announcement options
    if (hasStartAndEndDate && isFirstLoad) {
      // will modify directly `form` within `loadPreviousAnnouncementOptions`
      lastAnnouncementOptions = this.loadPreviousAnnouncementOptions(form)
    }

    const EnumItems = (field) => ({
      enum: Object.keys(field).map((key) => ({ key, name: field[key] }))
    })

    let hourScheduled = [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23'
    ]

    let minuteScheduled = ['00', '30']

    const { today } = stores.timeService()
    const hourNow = timeService.trueNow().format('HH')
    const minuteNow = timeService.trueNow().format('mm')

    form.dayScheduled.value = form.dayScheduled.value || Number(today)

    // filter values in definition.items.enum list
    const scheduledDayIsToday = Number(form.dayScheduled.value) === Number(today)
    const isFirstHalfHour = minuteNow < 30
    if (scheduledDayIsToday && isFirstHalfHour) {
      hourScheduled = hourScheduled.splice(parseInt(hourNow), 24)
    } else if (scheduledDayIsToday) {
      hourScheduled = hourScheduled.splice(parseInt(hourNow) + 1, 24)
    }
    // end of filter

    // initialising values
    const defaultHoursValue = isFirstHalfHour
      ? timeService.trueNow().format('HH')
      : timeService.trueNow().clone().add(1, 'hour').format('HH')

    form.hourScheduled.value = form.hourScheduled.value || defaultHoursValue

    const isFirstHourSelected = form.hourScheduled.value === hourScheduled[0]

    if (scheduledDayIsToday && isFirstHalfHour && isFirstHourSelected) {
      minuteScheduled = ['30']
    }

    form.minuteScheduled.value = form.minuteScheduled.value || minuteScheduled[0]
    // end of initialising values

    form.hourScheduled.definition.items = EnumItems(hourScheduled)
    form.minuteScheduled.definition.items = EnumItems(minuteScheduled)

    this.setState({ form: cloneDeep(form), ...lastAnnouncementOptions })
  }

  loadPreviousAnnouncementOptions(form) {
    // options to override with prior ones
    let { durationSelected, shiftSelected, recipientsThenSelected } = this.state

    const { timeService, startsAt, endsAt } = this.props
    const now = timeService.trueNow()

    // override SEND selector
    let startDateSelected = 'Later'

    const activeAnnouncementItem = this.props.appState.getIn(['announcements', 'activeAnnouncementItem']).announcement
    const shiftTypesSelected = activeAnnouncementItem.employmentTypes

    const startMoment = timeService.timeMoment(startsAt)
    const endMoment = timeService.timeMoment(endsAt)

    // Set the DURATION selector (above RECIPIENTS selector)
    const durationInDays = endMoment.diff(startMoment, 'days')
    switch (durationInDays) {
      case 2:
        durationSelected = '2 Days'
        break
      case 3:
        durationSelected = '3 Days'
        break
      case 7:
        durationSelected = '1 Week'
        break
      default:
        break
    }

    // Select the RECIPIENTS selector
    if (shiftTypesSelected.includes('day') || shiftTypesSelected.includes('night')) {
      recipientsThenSelected = 'All Staff'
    } else if (!shiftTypesSelected.length) {
      recipientsThenSelected = 'Working Then'
    }

    // set the SHIFTS selector ( when 'All Staff' is selected)
    if (shiftTypesSelected.includes('night') && shiftTypesSelected.includes('day')) {
      shiftSelected = 'All'
    } else if (shiftTypesSelected.includes('night')) {
      shiftSelected = 'Night'
    } else if (shiftTypesSelected.includes('day')) {
      shiftSelected = 'Day'
    }

    // use the `startsAt` date if it hasn't already past.
    if (startMoment.isSameOrAfter(now)) {
      form.dayScheduled.value = timeService.timeMoment(startsAt).startOf('day') // only the day matters
      form.hourScheduled.value = startMoment.format('HH')
      form.minuteScheduled.value = startMoment.format('mm')
    }

    return {
      durationSelected,
      recipientsThenSelected,
      shiftSelected,
      startDateSelected
    }
  }

  _resetForm(form) {
    Object.keys(form).forEach((field) => {
      if (form[field].path) {
        delete form[field].value
      }
    })

    return form
  }
}
