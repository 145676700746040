import classNames from 'classnames'
import PropTypes from 'prop-types'

const defaultActivePage = 'calendar'

function HeaderNavigation({
  hideNavigation,
  isNavigationVisible,
  unitUrlId,
  unitName,
  activePage = defaultActivePage
}) {
  // const { to } = navigate.from.Home;
  // const activitiesRoute = to.Activities({ unitUrlId }, false);
  // const timeOffRoute = to.TimeOff({ unitUrlId, module: 'pending-requests' }, false);
  // const calendarRoute = to.CalendarDefault({}, false);
  // const staffRoute = to.Staff({ unitUrlId }, false);
  // const announcementsRoute = to.Announcements({ unitId }, false);

  const isPageWithDateRangeSelector = ['calendar', 'open-shifts', 'shift-swaps'].includes(activePage)

  return (
    <div
      className={classNames('hx-schedule-selector staff-list', {
        hide: !isNavigationVisible
      })}
      onClick={hideNavigation}
      onMouseLeave={!isPageWithDateRangeSelector ? hideNavigation : undefined}
    >
      <header className="row align-middle align-center">
        <div className="hx-calendar-datepicker p10 pv0 rounded align-middle align-center">
          <span className="selectDocument bold pv0">Select your document</span>
        </div>
      </header>
      <section className="loader hide">
        <div className="loading" />
      </section>
    </div>
  )
}

HeaderNavigation.propTypes = {
  hideNavigation: PropTypes.func.isRequired,
  isNavigationVisible: PropTypes.bool.isRequired,
  unitName: PropTypes.string
}

export default HeaderNavigation
