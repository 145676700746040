import './Header.scss'
import StatusBadge from 'Staff/Common/StatusBadge'
import { getRequesterTagDetailsByState } from 'Staff/Calendar/DetailsPane/SectionDetails/SwappingShiftDetails/utils'

export interface IHeader {
  timeService: any
  shiftSwapRequest: any
}

export default function Header({ timeService, shiftSwapRequest }: IHeader) {
  // TO-DO use publicStaffMap to get profile data and replace Avatar url with <sh-user-identifier>
  const userProfileFirstName = shiftSwapRequest.getIn(['initiatorStaffProfile', 'userProfileFirstName'])

  const [firstShiftDayToSwapFor] = shiftSwapRequest.get('shiftDaysToSwapFor')

  const recipientDate = firstShiftDayToSwapFor.get('startsAt')

  const recipientDateFormatted = timeService.timeMoment(recipientDate).format('MM-DD-YYYY')

  const renderStatusTag = (state: string) => {

    const acceptedDate = shiftSwapRequest.get('acceptedDate')

    const statusDetails = getRequesterTagDetailsByState(state, acceptedDate)
    if (statusDetails) {
      return statusDetails.map(({ label, color }, index) => <StatusBadge key={index} label={label} color={color} />)
    }
    return null
  }

  const shiftSwapTitleWithDate =
    userProfileFirstName + ' • ' + timeService.getDateMonthForUsDate(recipientDateFormatted)

  return (
    <div>
      <div className="pendingHeaderWrapper">
        <div className="pendingHeaderBox">
          <div className="pendingHeader">
            <div className="iconBox" data-testid="title-icon-component">
              <sh-icon icon="in-progress" size="s" color="white"></sh-icon>
            </div>
            <div className="pendingTitle" id="pending-title">
              {shiftSwapTitleWithDate}
            </div>
            <sh-tooltip
              label={shiftSwapTitleWithDate}
              target="pending-title"
              placement="top"
              variation="short"
            ></sh-tooltip>
          </div>
          <div className="pendingHeaderTags">{renderStatusTag(shiftSwapRequest.get('state'))}</div>
        </div>
      </div>
    </div>
  )
}
