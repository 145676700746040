import { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Grid, Admin, Header, Search } from '@humanics/he-react-common/lib/admin/components'
import ModalWithConfirmation from '@humanics/he-react-common/lib/admin/components/ModalWithConfirmation'
import { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import ExpertisesController from './ExpertiseController'

const fields = [Field('name', 'Name', { format: 'plain' })]

class ExpertisesAdmin extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.controller = new ExpertisesController(this)
    const data = this.controller.getExpertises()
    this.state = { fields, data, query: '', apiInitialized: false }
  }

  componentDidMount() {
    ;(async () => {
      const { store, type } = this.props
      await store.initializeApi()
      await this.controller.expertiseGroupStore.getApi()
      this.controller.loadData(type)
      this.setState({ apiInitialized: true })
    })()
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { stateKey: stateGroupKey } = this.controller.expertiseGroupStore
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const storeGroupState = appState.get(stateGroupKey)
    const prevStoreState = prevAppState.get(stateKey)
    const prevStoreGroupState = prevAppState.get(stateGroupKey)
    const isStoreStateChanged = storeState !== prevStoreState || storeGroupState !== prevStoreGroupState

    const { query: prevQuery } = prevState
    const { query } = this.state
    const isQueryUpdated = prevQuery !== query

    if (isStoreStateChanged || isQueryUpdated) {
      const data = this.controller.getExpertises()
      this.setState({ data })
    }
  }

  render() {
    const {
      appState,
      store,
      stores,
      location,
      history,
      title,
      itemName,
      titleExpertise,
      titleExpertiseGroup,
      subRoutes,
      match
    } = this.props
    const { isConfirmationModalVisible, isGroupConfirmationModalVisible, data, apiInitialized, query } = this.state
    if (!apiInitialized) {
      return null
    }

    const isStoreApiInitialized = store.isApiInitialized(appState)
    if (!isStoreApiInitialized) {
      return null
    }

    const groupAction = {
      linkTo: location.pathname.replace(/\/$/, '') + '/new',
      actionTitle: `Create ${itemName}`
    }
    const { groupedExpertises, emptyGroups } = data

    const groupItems = {
      groupBy: 'groupId',
      groupNameField: 'groupName',
      emptyGroups
    }

    const fields = [
      ...this.state.fields,
      {
        render: (dataRow) => {
          const { id } = dataRow
          return (
            <td>
              <span className="float-right pr10">
                <i
                  className="icon icon-unfilled-trash cursor-pointer"
                  onClick={(e) => this.onDeleteExpertiseInitiated(e, id)}
                />
              </span>
            </td>
          )
        }
      }
    ]

    const confirmationModalProps = {
      modalTitle: `Delete ${titleExpertise}`,
      confirmationMessage: `Do you really want to delete this ${titleExpertise}?`,
      isCancelVisible: true,
      isConfirmVisible: true,
      onCancelConfirmation: () => this.setState({ isConfirmationModalVisible: false, deleteParameters: null }),
      onConfirm: this.onDeleteExpertise,
      isModalVisible: isConfirmationModalVisible,
      isConfirmationVisible: isConfirmationModalVisible
    }
    const groupConfirmationModalProps = {
      modalTitle: `Delete ${titleExpertiseGroup}`,
      confirmationMessage: `Do you really want to delete this ${titleExpertiseGroup}?`,
      isCancelVisible: true,
      isConfirmVisible: true,
      onCancelConfirmation: () =>
        this.setState({ isGroupConfirmationModalVisible: false, deleteGroupParameters: null }),
      onConfirm: this.onDeleteExpertiseGroup,
      isModalVisible: isGroupConfirmationModalVisible,
      isConfirmationVisible: isGroupConfirmationModalVisible
    }
    const linkTo = location.pathname.replace(/\/$/, '') + '/new'
    const actions = (
      <Link to={linkTo} className="button secondary">
        Create {itemName} Group
      </Link>
    )

    const { search, stateKey } = store.getAdminInfo()
    return (
      <>
        <ModalWithConfirmation {...confirmationModalProps} />
        <ModalWithConfirmation {...groupConfirmationModalProps} />
        <Admin>
          <Header title={title} actions={actions} />
          <Grid
            orderField="position"
            history={history}
            Link={Link}
            data={groupedExpertises}
            store={store}
            dataType={stateKey}
            fields={fields}
            allowUpdate={false}
            groupItems={groupItems}
            stores={stores}
            groupAction={groupAction}
            groupDelete={this.onDeleteExpertiseGroupInitiated}
            currentPath={match.url}
          >
            {!search ? null : (
              <Search
                keyword={query}
                onReset={(e) => e?.preventDefault()}
                placeholder={search?.placeholder}
                onSearch={(query) => this.setState({ query })}
                itemsFound={groupedExpertises}
                entityName={title}
              />
            )}
          </Grid>
          {subRoutes}
        </Admin>
      </>
    )
  }

  onDeleteExpertiseInitiated = (event, id) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ isConfirmationModalVisible: true, deleteParameters: { id } })
  }

  onDeleteExpertise = async () => {
    const { type } = this.props
    const { id } = this.state.deleteParameters
    try {
      await this.controller.deleteExpertise(id)
    } catch (e) {
      //
    }
    this.controller.loadData(type)
    this.setState({ isConfirmationModalVisible: false, deleteGroupParameters: null })
  }

  onDeleteExpertiseGroupInitiated = (event, groupId) => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ isGroupConfirmationModalVisible: true, deleteGroupParameters: { groupId } })
  }

  onDeleteExpertiseGroup = async () => {
    const { type } = this.props
    const { groupId } = this.state.deleteGroupParameters
    try {
      await this.controller.deleteExpertiseGroup(groupId)
    } catch (e) {
      //
    }
    this.controller.loadData(type)
    this.setState({ isGroupConfirmationModalVisible: false, deleteGroupParameters: null })
  }
}

export default ExpertisesAdmin
