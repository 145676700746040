import { List } from 'immutable'
import { OpenShiftEntity } from 'entityWrappers'
import OpenShiftActionService from 'services/OpenShiftActionService'
import { ComponentController } from 'Common/components'
import { employmentTypes } from 'Manager/components/EmploymentTypesSelector'
import stores from 'stores'
const { openShiftsStore } = stores

export default class OpenShiftController extends ComponentController {
  constructor(component) {
    super(component)
    this._openShift = new OpenShiftEntity(null)
  }

  get defaultShiftOpportunityAcceptanceCriteria() {
    return this.props.unit.get('defaultShiftOpportunityAcceptanceCriteria')
  }

  get defaultState() {
    return {
      isLoading: false,
      isUpdating: false,
      isCanceling: false,
      isAddStaffToShiftProcessing: false
    }
  }

  get isReady() {
    return this.openShift.withStaffDetails
  }

  get selectedUserIds() {
    return this.props.staffConfig.get('selectedUserIds')
  }

  get hiddenUserIds() {
    return this.props.staffConfig.get('hiddenUserIds')
  }

  get isAnyStaffSelected() {
    return this.selectedUserIds.size > 0
  }

  set openShift(openShift) {
    this._openShift = new OpenShiftEntity(openShift)
  }

  get openShift() {
    return this._openShift
  }

  get isLoading() {
    return this.state.isLoading || this.props.isGeneralDataLoading
  }

  get isUpdating() {
    return this.state.isUpdating
  }

  get isCanceling() {
    return this.state.isCanceling
  }

  get isAddStaffToShiftProcessing() {
    return this.state.isAddStaffToShiftProcessing
  }

  get staffFilter() {
    return this.props.staffFilter
  }

  get search() {
    return this.staffFilter.get('search')
  }

  get isStaffSelected() {
    return (staff) => {
      const userId = staff.get('userId')
      return this.selectedUserIds.includes(userId)
    }
  }

  get isStaffListChanged() {
    const { eligibleUserIds: originalOpenShiftStaffIds } = this.openShift
    const selectedUserIds = this.selectedUserIds

    let isChanged = false

    if (!originalOpenShiftStaffIds || !selectedUserIds || selectedUserIds.size === 0) {
      return false
    }

    if (originalOpenShiftStaffIds.size !== selectedUserIds.size) {
      return true
    }

    isChanged = originalOpenShiftStaffIds.reduce((isChanged, userId) => {
      return isChanged || !selectedUserIds.includes(userId)
    }, isChanged)

    isChanged = selectedUserIds.reduce((isChanged, userId) => {
      return isChanged || !originalOpenShiftStaffIds.includes(userId)
    }, isChanged)

    return isChanged
  }

  get isAllSelected() {
    if (!this.isAnyStaffSelected) {
      return false
    }

    const { openShiftStaffMap } = this.props

    const notRespondedStaff = openShiftStaffMap.get('notRespondedStaff')
    const isAllNotRespondedStaffSelected = notRespondedStaff.every((staff) => staff.get('isSelected'))
    if (!isAllNotRespondedStaffSelected) {
      return false
    }

    const declinedStaff = openShiftStaffMap.get('declinedStaff')
    const isAllDeclinedStaffSelected = declinedStaff.every((staff) => staff.get('isSelected'))
    if (!isAllDeclinedStaffSelected) {
      return false
    }

    const acceptedStaff = openShiftStaffMap.get('acceptedStaff')
    const isAllAcceptedStaffSelected = acceptedStaff.every((staff) => staff.get('isSelected'))
    if (!isAllAcceptedStaffSelected) {
      return false
    }

    const notInvitedStaff = openShiftStaffMap.get('notInvitedStaff')
    const isAllNotInvitedStaffSelected = notInvitedStaff.every((staff) => staff.get('isSelected'))
    if (!isAllNotInvitedStaffSelected) {
      return false
    }

    const eligibleStaff = openShiftStaffMap.get('eligibleStaff')
    return eligibleStaff.every((staff) => staff.get('isSelected'))
  }

  get openShiftParameters() {
    const { openShiftParameters } = this.props
    const note = openShiftParameters.get('note')
    const isBonusPay = openShiftParameters.get('isBonusPay')
    const isImportant = openShiftParameters.get('isImportant')
    const acceptanceCriteria = openShiftParameters.get('acceptanceCriteria')
    const isAlertEligibleStaff = openShiftParameters.get('isAlertEligibleStaff')
    const isCrisisPay = openShiftParameters.get('isCrisisPay')
    const eligibleUserIds = this.selectedUserIds.toJS()

    return {
      note,
      isCrisisPay,
      isBonusPay,
      isImportant,
      eligibleUserIds,
      acceptanceCriteria,
      isAlertEligibleStaff
    }
  }

  get canPostOpenShift() {
    const { isPosted, isPastOpenShift } = this.openShift
    return !isPosted && !isPastOpenShift && this.isAnyStaffSelected && !this.isAddStaffToShiftProcessing
  }

  get canUpdateShiftOpportunity() {
    const { isPosted } = this.openShift

    return isPosted && this.isStaffListChanged && !this.isAddStaffToShiftProcessing && !this.isUpdating
  }

  set isAddStaffToShiftProcessing(value) {
    this.setState({ isAddStaffToShiftProcessing: value })
  }

  set isLoading(value) {
    this.setState({ isLoading: value })
  }

  set isUpdating(value) {
    this.setState({ isUpdating: value })
  }

  set isCanceling(value) {
    this.setState({ isCanceling: value })
  }

  updateSelectedUserIds(value) {
    openShiftsStore.setStaffConfig({ selectedUserIds: value })
  }

  onSearchChange(value) {
    openShiftsStore.setStaffFilter('search', value)
  }

  initializeFilters = () => {
    const { unit, eligibleUnits } = this.props
    const employmentTypesIds = employmentTypes.map((employmentType) => employmentType.get('id'))
    const shiftTypeIds = unit.get('shiftTypes').map((shiftType) => shiftType.get('id'))
    const eligibleUnitIds = eligibleUnits.map((unit) => unit.get('id'))
    const unitRoleIds = eligibleUnits
      .filter((unit) => eligibleUnitIds.includes(unit.get('id')))
      .map((item) => item.get('roles'))
      .flatten(1)
      .map((item) => item.get('id'))
    openShiftsStore.setStaffFilter('employmentTypes', employmentTypesIds, false)
    openShiftsStore.setStaffFilter('shiftTypes', shiftTypeIds, false)
    openShiftsStore.setStaffFilter('units', eligibleUnitIds, false)
    openShiftsStore.setStaffFilter('shiftRoles', unitRoleIds, false)
  }

  updateFilter = (field, value) => openShiftsStore.setStaffFilter(field, value)

  toggleAll = () => {
    const newIsSelected = !this.isAllSelected
    const { facilityUserIds } = this.openShift
    const selectedUserIds = newIsSelected ? facilityUserIds : List()

    openShiftsStore.setStaffConfig({ selectedUserIds })
  }

  toggleStaff(openShiftStaff) {
    const { userId } = openShiftStaff
    const isSelected = this.selectedUserIds.contains(userId)
    const shouldUnselect = isSelected
    const shouldSelect = !isSelected
    if (shouldUnselect) {
      const selectedUserIds = this.selectedUserIds.filter((id) => id !== userId)
      openShiftsStore.setStaffConfig({ selectedUserIds })
    }
    if (shouldSelect) {
      const selectedUserIds = this.selectedUserIds.push(userId)
      openShiftsStore.setStaffConfig({ selectedUserIds })
    }
  }

  hideStaff(openShiftStaff) {
    const { userId } = openShiftStaff
    const hiddenUserIds = this.hiddenUserIds.push(userId)
    let selectedUserIds = this.selectedUserIds
    const indexOfSelectedUser = selectedUserIds.indexOf(userId)
    if (indexOfSelectedUser !== -1) {
      selectedUserIds = selectedUserIds.delete(indexOfSelectedUser)
    }
    openShiftsStore.setStaffConfig({
      hiddenUserIds,
      selectedUserIds
    })
  }

  onRadioChange = (field) => (e) =>
    openShiftsStore.updateOpenShiftParameters({
      [field]: e.target.value
    })
  onInputChange = (field) => (e) =>
    openShiftsStore.updateOpenShiftParameters({
      [field]: e.target.value
    })
  onCheckboxChange = (field) => (e) =>
    openShiftsStore.updateOpenShiftParameters({
      [field]: e.target.checked
    })

  setIsImportant = (value) => {
    const { isPosted, shiftDayId } = this.openShift
    if (!isPosted) {
      openShiftsStore.updateOpenShiftParameters({
        isImportant: value
      })
      const { activeDateRange, unit } = this.props
      const facilityUsersMap = activeDateRange.get('facilityUsersMap')
      const openShiftActionService = new OpenShiftActionService(this.openShift, openShiftsStore, facilityUsersMap, unit)
      return openShiftActionService.updateShiftDayMetadata({
        shiftDayId,
        isImportant: value
      })
    }
  }

  async createShiftOpportunity() {
    const { activeDateRange, unit } = this.props
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')

    const openShiftActionService = new OpenShiftActionService(this.openShift, openShiftsStore, facilityUsersMap, unit)

    this.normalizeOpenShiftParameters()
    return openShiftActionService.createShiftOpportunity(this.openShiftParameters)
  }

  async updateShiftOpportunity() {
    const { activeDateRange, unit } = this.props
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')
    const openShiftActionService = new OpenShiftActionService(this.openShift, openShiftsStore, facilityUsersMap, unit)

    this.normalizeOpenShiftParameters()
    return openShiftActionService.updateShiftOpportunity(this.openShiftParameters)
  }

  normalizeOpenShiftParameters() {
    let { note } = this.openShiftParameters
    note = note || ''
    openShiftsStore.updateOpenShiftParameters({ note: note.trim() })
  }

  createShiftDayRequirement(number) {
    const { activeDateRange, timeService, unit } = this.props
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')
    const openShiftActionService = new OpenShiftActionService(this.openShift, openShiftsStore, facilityUsersMap, unit)
    const unitId = activeDateRange.get('unitId')
    const shiftId = this.openShift.openShift.get('shiftId')
    const shiftStartsAt = this.openShift.openShift.get('shiftStartsAt')
    const date = timeService.timeMoment(shiftStartsAt).startOf('day').toISOString()
    const params = { unitId, shiftId, date, number }
    return openShiftActionService.createShiftDayRequirement(params)
  }

  cancelOpenShift() {
    const { activeDateRange, unit } = this.props
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')
    const openShiftActionService = new OpenShiftActionService(this.openShift, openShiftsStore, facilityUsersMap, unit)

    return openShiftActionService.deleteShiftOpportunity()
  }

  addStaffToShift(openShiftStaff) {
    const { activeDateRange, unit } = this.props
    const facilityUsersMap = activeDateRange.get('facilityUsersMap')
    const openShiftActionService = new OpenShiftActionService(this.openShift, openShiftsStore, facilityUsersMap, unit)
    return openShiftActionService.addStaffToShift(openShiftStaff)
  }
}
