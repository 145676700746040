import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { getStates } from 'Admin/services/LocalityService'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'

const fields = [Field('code', 'CODE'), Field('name', 'NAME')]

function StateStore() {
  const actions = fluxStore({ loadDataAction })
  const store = AdminStore({
    fields,
    instanceAdmin: true,
    entityName: 'State',
    api: {
      index: 'indexStates'
    },
    header: { title: 'State' },
    search: { field: 'name', placeholder: 'Search for State' }
  })
  return {
    ...store,
    ...actions
  }

  async function loadDataAction(state, countryCode) {
    if (typeof countryCode === 'object' || !countryCode) {
      return state
    }
    const api = await store.getApi()
    let states
    try {
      states = await getStates(state, api, countryCode)
    } catch (_err) {
      states = [] //TODO: Improve error handling by displaying banner as well
    }
    const { stateKey } = store.getAdminInfo()
    return state.setIn([stateKey, 'list'], states)
  }
}

export default StateStore
