import { Component } from 'react'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { List } from 'immutable'

export class CustomDropdownEnum extends Component {
  constructor(props) {
    super(props)
    const { definition = {} } = props
    const itemsEnum = (definition.items || {}).enum || definition.enum || List([])
    this.state = { itemsEnum }
  }

  componentWillReceiveProps(nextProps) {
    const { definition: nextDefinition } = nextProps
    const { itemsEnum } = this.state
    const nextItemsEnum = nextDefinition.enum || (nextDefinition.items && nextDefinition.items.enum) || List([])
    const isChangedOptionsList = !isEqual(itemsEnum, nextItemsEnum)

    if (isChangedOptionsList) {
      this.setState({ itemsEnum: nextItemsEnum })
    }
  }

  render() {
    const { value, field, error, propKey, propValue, definition, formItemClasses, inlineError } = this.props

    const { itemsEnum: options } = this.state

    const { required, label, default: defaultValue, disabled, customPlaceHolder, withLabel } = definition

    const isErrorVisible = !!error

    return (
      <div className={classNames('form-item', formItemClasses)} key={field}>
        {withLabel && (
          <label>
            <span className="form-label">{label}</span>
            &nbsp;
            {required && <span className="req">*</span>}
            {isErrorVisible && <span className="error">{error}</span>}
          </label>
        )}
        {!withLabel && isErrorVisible && <span className="error">{error}</span>}
        <select
          className={classNames('w100', { inlineError })}
          name={label}
          value={value || defaultValue}
          onChange={this.onChange(field)}
          disabled={options.size === 0 || disabled}
        >
          <option value="">{customPlaceHolder || `--- Select ${label} ---`}</option>
          {options.map((option) => (
            <Option data={option} propValue={propValue} propKey={propKey} key={option.get(propKey)} />
          ))}
        </select>
        {inlineError && <small className={classNames({ inlineError })}>{inlineError}</small>}
      </div>
    )
  }

  onChange = (field) => {
    return (e) => {
      const { onChange } = this.props
      const selectedValue = e.target.value

      onChange(e, field)
      this.setFormData(selectedValue)
    }
  }

  setFormData(selectedValue) {
    const { setFormData, model, propKey } = this.props

    if (model && selectedValue) {
      const { completeListOfItems: items } = this.state
      const item = items.find((item) => item[propKey] === selectedValue)
      setFormData({ [model]: item })
    }
  }
}

export default CustomDropdownEnum

function Option({ data, propValue, propKey }) {
  const text = data.get(propValue)
  const key = data.get(propKey)
  return <option value={key}>{text}</option>
}
