import { List } from 'immutable'

export default class OpenShiftStaffRequirementsService {
  constructor(timeService) {
    this.timeService = timeService
  }

  get defaultExpertiseDetails() {
    return {
      name: '',
      type: '',
      matched: false,
      staffLevel: undefined
    }
  }

  getMatchPercentage(staffExpertises) {
    const expertiseCount = staffExpertises.size
    const matchedExpertiseCount = staffExpertises.filter((item) => item.get('matched')).size
    return expertiseCount === 0 ? 100 : ((matchedExpertiseCount / expertiseCount) * 100).toFixed(0)
  }

  isMatchRequirements(expertiseRequirements, expertiseCredentials, expertises) {
    if (!expertises) {
      return expertiseRequirements.map((item) => item.merge(this.defaultExpertiseDetails))
    }
    const expertiseCount = expertiseRequirements.size
    if (expertiseCount === 0) {
      return true
    }
    const groupedCredentials = expertiseCredentials.groupBy((item) => item.get('expertiseId'))
    const matchedExpertiseCount = expertiseRequirements
      .filter((item) => expertises.keySeq().includes(item.get('expertiseId')))
      .filter((item) => {
        const expertiseId = item.get('expertiseId')
        const expertise = expertises.get(expertiseId).get(0)
        const type = expertise.get('type')
        const userExpertise = groupedCredentials.get(expertiseId, List()).get(0)
        if (!userExpertise) {
          return false
        }
        if (['skill', 'equipment'].includes(type)) {
          const eligibleScaleIndexes = item.get('eligibleScaleIndexes')
          const scaleIndex = userExpertise.get('scaleIndex')
          const match = (eligibleScaleIndexes.size === 0 && !!scaleIndex) || eligibleScaleIndexes.includes(scaleIndex)
          const now = this.timeService.timeMoment(null)
          const expiresAt = userExpertise.get('expiresAt')
          return match && (!expiresAt || this.timeService.isSameOrBefore(now, expiresAt))
        }
        if (['license', 'certification'].includes(type)) {
          const now = this.timeService.timeMoment(null)
          const expiresAt = userExpertise.get('expiresAt')
          return !expiresAt || this.timeService.isSameOrBefore(now, expiresAt)
        }
        return false
      }).size
    return matchedExpertiseCount >= 1
  }

  getName = (type, expertise) => {
    return type === 'equipment' ? expertise.get('fullName') : expertise.get('name')
  }

  isMatched = (eligibleScaleIndexes, scaleIndex) => {
    return (eligibleScaleIndexes.size === 0 && !!scaleIndex) || eligibleScaleIndexes.includes(scaleIndex)
  }

  getItemDetails = (expertise, itemDetails, confirmed, scaleIndex, eligibleScaleIndexes, type) => {
    const scaleItems = expertise.get('scaleItems')
    const name = this.getName(type, expertise)
    const matched = this.isMatched(eligibleScaleIndexes, scaleIndex)
    return {
      ...itemDetails,
      name,
      matched: matched && confirmed,
      key:
        scaleItems.size <= scaleIndex
          ? 'mismatch'
          : matched && confirmed
            ? 'match'
            : confirmed
              ? 'skill-mismatch'
              : 'mismatch',
      staffLevel:
        scaleItems.size <= scaleIndex
          ? 'None'
          : matched && confirmed
            ? scaleItems.get(scaleIndex)
            : confirmed
              ? scaleItems.get(scaleIndex)
              : 'Expired'
    }
  }

  getStaffMatchingRequirements(expertises, expertiseRequirements, expertiseCredentials) {
    if (!expertises || expertises.size === 0) {
      return {
        staffExpertises: expertiseRequirements.map((item) => item.merge(this.defaultExpertiseDetails)),
        matchPercentage: 0
      }
    }
    const groupedCredentials = expertiseCredentials.groupBy((item) => item.get('expertiseId'))

    const staffExpertises = expertiseRequirements
      .filter((item) => expertises.keySeq().includes(item.get('expertiseId')))
      .map((item) => {
        const expertiseId = item.get('expertiseId')
        const expertise = expertises.get(expertiseId).get(0)
        const type = expertise.get('type')
        const userExpertise = groupedCredentials.get(expertiseId, List()).get(0)
        const name = type === 'equipment' ? expertise.get('fullName') : expertise.get('name')
        if (!userExpertise) {
          return item.merge({
            name,
            type,
            key: 'mismatch',
            matched: false,
            staffLevel: 'None'
          })
        }
        const now = this.timeService.timeMoment(null)
        const expiresAt = userExpertise.get('expiresAt')
        const confirmed = !expiresAt || this.timeService.isSameOrBefore(now, expiresAt)
        const key = confirmed ? 'match' : 'mismatch'
        let itemDetails = {
          name,
          type,
          matched: confirmed,
          key,
          staffLevel: confirmed ? 'Confirmed' : 'Expired'
        }
        if (['skill', 'equipment'].includes(type)) {
          const eligibleScaleIndexes = item.get('eligibleScaleIndexes')
          // const scaleItems = expertise.get('scaleItems');
          const scaleIndex = userExpertise.get('scaleIndex')
          // const name = type === 'equipment' ? expertise.get('fullName') : expertise.get('name');
          // const matched =
          //   (eligibleScaleIndexes.size === 0 && !!scaleIndex) ||
          //   eligibleScaleIndexes.includes(scaleIndex);
          itemDetails = this.getItemDetails(expertise, itemDetails, confirmed, scaleIndex, eligibleScaleIndexes, type)
          // {
          //   ...itemDetails,
          //   name,
          //   matched: matched && confirmed,
          //   key:
          //     scaleItems.size <= scaleIndex
          //       ? 'mismatch'
          //       : matched && confirmed
          //       ? 'match'
          //       : confirmed
          //       ? 'skill-mismatch'
          //       : 'mismatch',
          //   staffLevel:
          //     scaleItems.size <= scaleIndex
          //       ? 'None'
          //       : matched && confirmed
          //       ? scaleItems.get(scaleIndex)
          //       : confirmed
          //       ? scaleItems.get(scaleIndex)
          //       : 'Expired'
          // };
        }
        return item.merge(itemDetails)
      })
    const matchPercentage = this.getMatchPercentage(staffExpertises)
    return {
      staffExpertises,
      matchPercentage
    }
  }
}
