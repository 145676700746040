type DropdownData = { key: string; label: string }

let locations: DropdownData[] = []
let studyCodes: DropdownData[] = []
let customSlotReasons: DropdownData[] = []

try {
  locations = JSON.parse(window.SLOT_RECOMMENDER_LOCATIONS || '[]')
} catch {
  locations = []
}

try {
  studyCodes = JSON.parse(window.SLOT_RECOMMENDER_STUDY_CODES || '[]')
} catch {
  studyCodes = []
}

try {
  customSlotReasons = JSON.parse(window.SLOT_RECOMMENDER_REASONS || '[]')
} catch {
  customSlotReasons = []
}

const formatAsDate = (date: string, timeService: any) => {
  if (!date) {
    return null
  }

  return timeService.timeMoment(date).format('MM/DD/YYYY')
}

const formatAsTime = (time: string, timeService: any) => {
  if (!time) {
    return null
  }

  return timeService.timeMoment(time).format('hh:mm A')
}

const format24HourTimeTo12HourTime = (time: string, timeService: any) => {
  if (!time) {
    return null
  }

  return timeService.timeMoment(time, 'HH:mm').format('hh:mm A')
}

const getLabelForLocation = (locationKey: string) => {
  const location = locations.find((l) => l.key === locationKey)
  return location ? location.label : locationKey
}

const _getLabelForStudyCode = (studyCodeKey: string) => {
  const studyCode = studyCodes.find((s) => s.key === studyCodeKey)
  return studyCode ? studyCode.label : studyCodeKey
}

const getLabelForStudyCodes = (studyCodeKeys?: string[]) => {
  return studyCodeKeys?.length
    ? studyCodeKeys.map((studyCodeKey) => _getLabelForStudyCode(studyCodeKey)).join(', ')
    : null
}

const _getStudyCodeFromLabel = (studyCodeLabel: string) => {
  const studyCode = studyCodes.find((s) => s.label === studyCodeLabel)
  return studyCode?.key ?? ''
}

const getStudyCodesFromLabel = (studyCodesLabel: string) => {
  return studyCodesLabel
    ? studyCodesLabel
        .split(',')
        .map((label) => _getStudyCodeFromLabel(label))
        .filter(Boolean)
    : []
}

const getLocationFromLabel = (locationCodeLabel: string) => {
  const location = locations.find((s) => s.label === locationCodeLabel)
  return location?.key ?? ''
}

const getLabelForCustomSlotReason = (reasonKey: string) => {
  const customSlotReason = customSlotReasons.find((r) => r.key === reasonKey)
  return customSlotReason ? customSlotReason.label : reasonKey
}

const getReasonFromLabel = (reasonLabel: string) => {
  const customSlotReason = customSlotReasons.find((r) => r.label === reasonLabel)
  return customSlotReason?.key ?? ''
}

const getDurationLabel = (duration: number) => {
  let durationLabel = `${duration} mins`

  if (duration === 60) {
    durationLabel = '1 hr'
  } else if (duration > 60) {
    const hrs = Math.floor(duration / 60)
    const mins = duration % 60
    durationLabel = mins === 0 ? `${hrs} hrs` : `${hrs} hr ${mins} mins`
  }

  return durationLabel
}

export {
  locations,
  studyCodes,
  customSlotReasons,
  formatAsDate,
  formatAsTime,
  format24HourTimeTo12HourTime,
  getLabelForLocation,
  getLabelForStudyCodes,
  getLabelForCustomSlotReason,
  getStudyCodesFromLabel,
  getLocationFromLabel,
  getReasonFromLabel,
  getDurationLabel
}
