import { isEmpty } from 'lodash'
import './ConstraintDetails.scss'
import { formatConstraintName } from '../utils.js'
import { curry } from 'i18n'
import { ConstraintType, ConstraintWeight } from '../constants'
import Slider from '../Slider/Silder'
import classNames from 'classnames'
const t = curry('calendar.autoSchedule.constraintDetails.')

const ConstraintDetails = ({
  selectedConstraint,
  updatedConstraintSettings,
  setUpdatedConstraintSettings,
  showDetailsPage
}) => {
  const isWeightageEnabled = window.REACT_APP_WEIGHTAGE_ENABLED_FOR_ALGO_SCHEDULE === 'true'
  const isConstraintEnabled = updatedConstraintSettings.enabled
  let softAdditionalAttributes =
    updatedConstraintSettings.type === ConstraintType.SOFT && isConstraintEnabled
      ? { active: true, class: 'optional-parameter-switch-item' }
      : {}
  let hardAdditionalAttributes =
    updatedConstraintSettings.type === ConstraintType.HARD && isConstraintEnabled
      ? { active: true, class: 'mandatory-parameter-switch-item' }
      : {}
  const disabledAdditionalAttributes = !isConstraintEnabled
    ? {
        active: true
      }
    : {}

  const canChangeSettings = updatedConstraintSettings.canChangeType || false
  if (!canChangeSettings) {
    softAdditionalAttributes =
      selectedConstraint.type === ConstraintType.SOFT ? { ...softAdditionalAttributes } : { disabled: true }
    hardAdditionalAttributes =
      selectedConstraint.type === ConstraintType.HARD ? { ...hardAdditionalAttributes } : { disabled: true }
  }

  const onToggleButton = (updatedSettings) => {
    setUpdatedConstraintSettings({ ...updatedSettings })
  }

  const updateWeight = (weight) => {
    setUpdatedConstraintSettings({ ...updatedConstraintSettings, weight: weight })
  }

  return (
    <div className="pl20 h100 p15" key={`constraint-details-${updatedConstraintSettings.id}`}>
      {isEmpty(updatedConstraintSettings) || !showDetailsPage ? (
        <div className="flex align-center align-middle h100">
          <span className="flex align-center align-middle flex-column">
            <sh-icon icon="multiselect" class="multi-select-icon"></sh-icon>
            <sh-text class="mt10">{t('constraintEmptyState')}</sh-text>
          </span>
        </div>
      ) : (
        <>
          <>
            {!canChangeSettings && (
              <div className="flex align-middle p1 constraint-modification-warning">
                <sh-icon icon="warning" size="xs"></sh-icon>
                <sh-text size="body-1" class="pl10">
                  {t('constraintModificationWarning')}
                </sh-text>
              </div>
            )}
            <div className="p10">
              <sh-text size="title-1" class='constraint-details-font'>{formatConstraintName(updatedConstraintSettings.name)}</sh-text>
              <div className="flex align-middle p1 constraint-description-wrapper">
                <sh-icon icon="information" size="xs"></sh-icon>
                <sh-text size="body-1" class="pl10" >
                  {updatedConstraintSettings.description}
                </sh-text>
              </div>
            </div>
            <sh-divider></sh-divider>
            <div>
              <div className="p10">
                <sh-text size="title-1" class='constraint-details-font'>{t('parameterSettings')}</sh-text>
                <div className="flex align-middle parameter-settings">
                  <span>
                    <sh-text size="body-1" class='constraint-details-font'>{t('parameterType')}:</sh-text>
                  </span>
                  <div className="col-5 ml10 pl25">
                    <sh-switch stretch condensed class="settings-switch">
                      <sh-switch-item
                        label={t('hardConstraint.label')}
                        icon="lock"
                        onClick={() =>
                          onToggleButton({
                            ...selectedConstraint,
                            enabled: true,
                            type: ConstraintType.HARD,
                            weight: ConstraintWeight.HARD
                          })
                        }
                        {...hardAdditionalAttributes}
                      ></sh-switch-item>
                      <sh-switch-item
                        label={t('softConstraint.label')}
                        onClick={() =>
                          onToggleButton({
                            ...selectedConstraint,
                            enabled: true,
                            type: ConstraintType.SOFT,
                            weight: ConstraintWeight.SOFT
                          })
                        }
                        {...softAdditionalAttributes}
                      ></sh-switch-item>
                      <sh-switch-item
                        label={t('disabledConstraint.label')}
                        {...disabledAdditionalAttributes}
                        onClick={() =>
                          onToggleButton({ ...selectedConstraint, enabled: false, weight: ConstraintWeight.DISABLED })
                        }
                      ></sh-switch-item>
                    </sh-switch>
                  </div>
                </div>
                {isWeightageEnabled && (
                  <div
                    className={classNames('w100 flex weight-setting-wrapper', {
                      disabledWeight:
                        updatedConstraintSettings.type !== ConstraintType.SOFT || !updatedConstraintSettings.enabled
                    })}
                  >
                    <span className='constraint-details-font'>{t('parameterWeight')}:</span>
                    <div className="slider-wrapper">
                      <Slider
                        disabled={false}
                        weight={updatedConstraintSettings.weight}
                        type={updatedConstraintSettings.type}
                        updateWeight={updateWeight}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </>
      )}
    </div>
  )
}

export default ConstraintDetails
