import { calculateTotalTopHeight } from 'utils'

const px = (number) => `${number}px`
// https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
const supportPageOffset = global.pageXOffset
const isCSS1Compat = (global.document.compatMode || '') === 'CSS1Compat'
const scrollY = () =>
  supportPageOffset ? global.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop

updateStyle.documentWidth = () => global.document.body.offsetWidth

const getValue = (value) => {
  return value || 0
}

const calculateContainerRight = (containerRight) => {
  return containerRight || updateStyle.documentWidth()
}

export default function updateStyle(
  popup,
  arrow,
  viewPort,
  position = 'right',
  targetElement,
  scrollTop,
  container = {}
) {
  if (!popup || !arrow || !viewPort) {
    return
  }

  const getContainerDimensions = () => {
    let { left: containerLeft, right: containerRight } = container
    containerLeft = getValue(containerLeft)
    containerRight = calculateContainerRight(containerRight)

    return { containerLeft, containerRight }
  }

  let { left, top, right, height } = viewPort
  scrollTop = getValue(scrollTop)
  top -= scrollTop
  const { offsetLeft: targetLeft, offsetWidth: targetWidth } = targetElement
  const positions = {
    right: setRightPosition,
    left: setLeftPosition,
    top: setTopPosition,
    bottom: setBottomPosition
  }

  resetArrow()
  positions[position].call()

  function resetArrow() {
    ;['left', 'top', 'right', 'bottom'].forEach((name) => (arrow.style[name] = ''))
  }

  function setRightPosition() {
    const isWindowOverflow = right + popup.offsetWidth + 10 > updateStyle.documentWidth()
    const isSelectionOverlap = right > targetLeft + targetWidth

    if (isWindowOverflow || isSelectionOverlap) {
      return setLeftPosition()
    }

    popup.style.left = px(right + 10)
    popup.style.top = px(top - 10)
    arrow.style.left = px(-5)
    arrow.style.top = px(15)
  }

  function setLeftPosition() {
    const isWindowOverflow = left - popup.offsetWidth - 10 < 0
    const isPopupBelowWindow = calculateTotalTopHeight() + popup.offsetHeight + top > window.innerHeight
    const isSelectionOverlap = left < targetLeft

    if (isWindowOverflow || isSelectionOverlap || isPopupBelowWindow) {
      return setTopPosition()
    }

    popup.style.left = px(left - popup.offsetWidth - 15)
    popup.style.top = px(top - 10)
    arrow.style.right = px(-8)
    arrow.style.top = px(9)
  }

  function setTopPosition() {
    if (top - popup.offsetHeight - 5 < scrollY()) {
      return setBottomPosition()
    }

    popup.style.top = px(top - popup.offsetHeight - 5)
    arrow.style.bottom = px(-5)
    alignTargetLeft()
  }

  function setBottomPosition() {
    popup.style.top = px(top + height + 5)
    arrow.style.top = px(-5)
    alignTargetLeft()
  }

  function alignTargetLeft() {
    const { containerRight, containerLeft } = getContainerDimensions()

    const { offsetWidth: popupWidth } = popup
    const arrowWidth = 16
    const popupCenter = left + targetWidth / 2
    let popupLeft = popupCenter - popupWidth / 2

    const documentWidth = containerRight
    const documentWithPopupWidth = popupLeft + popupWidth + 3
    const lackOfSpaceLeft = containerLeft - (popupLeft - 3)
    const lackOfSpaceRight = documentWithPopupWidth - documentWidth
    const isPopupInsideDocument = lackOfSpaceLeft <= 0 && lackOfSpaceRight <= 0
    const defaultLeft = (popupWidth - arrowWidth) / 2

    if (isPopupInsideDocument) {
      arrow.style.left = px(defaultLeft)
    } else if (lackOfSpaceLeft > 0) {
      popupLeft += lackOfSpaceLeft
      arrow.style.left = px(defaultLeft - lackOfSpaceLeft)
    } else {
      popupLeft -= lackOfSpaceRight
      arrow.style.left = px(defaultLeft + lackOfSpaceRight)
    }

    popup.style.left = px(popupLeft)
  }
}
