import stores from 'stores'
import { ComponentController } from 'Common/components'
import { t } from 'i18n'

import { TimeService } from 'services'
import { ICreateRemoteStaffRequestInput } from 'stores/remoteWorkflowStore/models/createRemoteStaffRequestInput'
import { IRemoteStaffAvailabilityInput } from 'stores/remoteWorkflowStore/models/remoteStaffAvailabilityInput'
import { IRemoteWorkflowStore } from 'stores/remoteWorkflowStore/remoteWorkflowStore'
import { IRemoteStaffRequest, IWeScanAppointment } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import { IWeScanRemoteStaffAvailability } from 'stores/remoteWorkflowStore/models/remoteStaffAvailabilityData'
import { RemoteRequestState } from 'stores/remoteWorkflowStore/enums/RemoteRequestState'
import { ICreateRemoteWeScanStaffFormData } from 'stores/remoteWorkflowStore/models/wescan/createRemoteWeScanStaffFormData'
import { IWeScanRemoteEquipmentProcedureData } from 'stores/remoteWorkflowStore/models/remoteEquipmentProcedureData'
import { WeScanServiceType } from 'stores/remoteWorkflowStore/enums/WeScanServiceType'

// @ts-ignore
const remoteWorkflowStore: IRemoteWorkflowStore = stores.remoteWorkflowStore
const SLOT_DURATION_IN_MINUTES = 60

export default class RemoteWeScanStaffRequestController extends ComponentController {
  get timeService(): TimeService {
    return this.props.timeService
  }

  get defaultState() {
    return {
      isLoading: false,
      isUpdating: false,
      serviceTypeError: false
    }
  }

  /**
   * If now is 02:36 AM, the nearest hour is 03:00 AM
   */
  isLessThanNearestHour(shiftDayStart: string, startTime: number) {
    let nearestCurrentDateTime = this.timeService.trueNow().set({ second: 0, millisecond: 0 })

    const currentDayStart = nearestCurrentDateTime.clone().startOf('day')
    const minutesFromStartOfDay = nearestCurrentDateTime.diff(currentDayStart, 'minutes')
    const minutesRemainder = minutesFromStartOfDay % SLOT_DURATION_IN_MINUTES

    if (minutesRemainder !== 0) {
      nearestCurrentDateTime = nearestCurrentDateTime.subtract(minutesRemainder, 'minutes')
    }

    const minimumAllowedStartsAt = nearestCurrentDateTime.add(SLOT_DURATION_IN_MINUTES, 'minutes')
    const dateMoment = this.timeService.timeMoment(shiftDayStart).startOf('day')
    const startTimeMoment = dateMoment.clone().add(startTime || 0, 'minutes')

    const isValid = startTimeMoment.isSameOrAfter(minimumAllowedStartsAt)

    return { minimumAllowedStartsAt: minimumAllowedStartsAt.format('MMM DD, hh:mm A'), isValid }
  }

  getCancellationMessage(firstWeScanAppointmentStartsAt: string) {
    const isPast = this.timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)
    const lessThan30Days = this.timeService.getDaysDifference(firstWeScanAppointmentStartsAt) < 30

    let message = t('equipments.cancel_wescan_request_message')

    if (isPast) {
      message = t('equipments.request_cannot_be_cancelled_message')
    } else if (lessThan30Days) {
      message = t('equipments.request_cancellation_before_30_days_message')
    }

    return message
  }

  async getRemoteServicesTypesForEquipment(equipmentId: string) {
    this.setState({ isLoading: true, serviceTypeError: false })

    let serviceTypes: WeScanServiceType[] = []

    try {
      const remoteWorkflowServiceTypes = await remoteWorkflowStore.getRemoteServicesTypesForEquipment(equipmentId)
      serviceTypes = remoteWorkflowServiceTypes[0]?.data?.wescan || []
    } catch (e) {
      console.error('Error getting remote services types for equipment:', equipmentId, e)
      this.setState({ serviceTypeError: true })
    } finally {
      this.setState({ isLoading: false })
    }

    return serviceTypes
  }

  async getProceduresAndSkillsForServiceType(equipmentId: string, weScanServiceType: string) {
    this.setState({ isLoading: true })

    let proceduresAndSkills: IWeScanRemoteEquipmentProcedureData | undefined = void 0

    try {
      const result = await remoteWorkflowStore.getProceduresAndSkillsForServiceType(equipmentId, weScanServiceType)
      proceduresAndSkills = result[0]?.data?.wescan
    } catch (e) {
      console.error('Error getting procedures and skills for service type:', weScanServiceType, e)
    } finally {
      this.setState({ isLoading: false })
    }

    return proceduresAndSkills!
  }

  async getRemoteStaffAvailabilities(
    equipmentId: string,
    timeService: TimeService,
    shiftDayStart: string,
    form: ICreateRemoteWeScanStaffFormData
  ) {
    const { serviceType, procedures, skillLevel, startTime, endTime } = form

    if (!serviceType) {
      return []
    }

    const dateMoment = timeService.timeMoment(shiftDayStart).startOf('day')
    const dateString = dateMoment.format('YYYY-MM-DD')
    const startTimeMoment = dateMoment.clone().add(startTime || 0, 'minutes')
    const endTimeMoment = dateMoment.clone().add(endTime || 0, 'minutes')
    const startTimeString = startTimeMoment.format('HH:mm')
    const endTimeString = endTimeMoment.format('HH:mm')

    const data: IRemoteStaffAvailabilityInput = {
      weScanServiceType: serviceType,
      weScanProcedures: [procedures],
      date: dateString,
      startTime: startTimeString,
      endTime: endTimeString
    }

    if (skillLevel) {
      data.weScanSkillLevel = skillLevel
    }

    this.setState({ isLoading: true })

    let staffAvailabilities: IWeScanRemoteStaffAvailability[] = []

    try {
      const result = await remoteWorkflowStore.getRemoteStaffAvailabilities(equipmentId, data)
      staffAvailabilities = result[0]?.data?.wescan || []
    } catch (e) {
      console.error('Error getting remote staff availabilities for equipment:', equipmentId, e)
    } finally {
      this.setState({ isLoading: false })
    }

    return staffAvailabilities
  }

  _getSelectedSlots(selectedAvailabilityIndexes: number[], availabilities: IWeScanRemoteStaffAvailability[]) {
    return selectedAvailabilityIndexes.map((index: number) => {
      const { startsAt } = availabilities[index]

      return {
        startsAt
      }
    })
  }

  async createRemoteStaffRequest(
    shiftDayId: string,
    formData: ICreateRemoteWeScanStaffFormData,
    availabilities: IWeScanRemoteStaffAvailability[]
  ) {
    const { serviceType, procedures, skillLevel, radiologist, techAide } = formData

    if (!serviceType || !procedures || !formData.availabilities?.length) {
      return
    }

    const requestBody: ICreateRemoteStaffRequestInput = {
      wescan: {
        requestorPhone: formData.requestor.phone,
        serviceType,
        procedures: [procedures],
        skillLevel: Number(skillLevel),
        radiologist,
        techAide,
        appointments: this._getSelectedSlots(formData.availabilities, availabilities)
      }
    }

    await remoteWorkflowStore.createRemoteStaffRequest(shiftDayId, requestBody)
  }

  async getRemoteStaffRequests(shiftDayId: string) {
    return remoteWorkflowStore.getRemoteStaffRequests(shiftDayId)
  }

  async cancelRemoteStaffRequest(request: IRemoteStaffRequest) {
    return remoteWorkflowStore.cancelRemoteStaffRequest(request.id)
  }

  canRemoteWeScanRequestBeCancelled(request: IRemoteStaffRequest) {
    const state = request.state
    return state !== RemoteRequestState.Cancelled && state !== RemoteRequestState.Closed
  }

  isRequestPastCurrentTime(request: IRemoteStaffRequest) {
    const firstWeScanAppointmentStartsAt = request.requestDetails.wescan!.appointments[0].startsAt
    return this.timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)
  }

  getAppointmentSlots(appointments: IWeScanAppointment[]) {
    return appointments
      .map((appointment: IWeScanAppointment) => {
        const startsAtFacilityTime = this.timeService.timeMoment(appointment.startsAt)
        const endsAtFacilityTime = this.timeService.timeMoment(appointment.endsAt)

        const formattedStartsAt = startsAtFacilityTime.format('hh:mm A')
        const formattedEndsAt = endsAtFacilityTime.format('hh:mm A')

        return `${formattedStartsAt} - ${formattedEndsAt}`
      })
      .join(', ')
  }
}
