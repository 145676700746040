import StaffShiftDays from './StaffShiftDays'

export default function VStaff({
  staff,
  style,
  selection,
  activeDateRange,
  onCellEvent,
  showPopup,
  onMouseMoveOverGridCell,
  onMouseLeave,
  cellsPathsInProcessing,
  updateCellTooltip,
  indicators,
  shiftTime,
  facilityShiftsMap,
  notes,
  timeService
}) {
  return (
    <div
      data-test-id="hx-calendar-v-staff-details"
      className="_staff hx-calendar-staff row"
      data-staff-id={staff.get('id')}
      style={style}
    >
      <StaffShiftDays
        staff={staff}
        indicators={indicators}
        onCellEvent={onCellEvent}
        updateCellTooltip={updateCellTooltip}
        shiftName={staff.get('shiftName')}
        showPopup={showPopup}
        shiftTime={shiftTime}
        selection={selection}
        staffPath={{
          roleIndex: staff.get('roleIndex'),
          shiftIndex: staff.get('shiftIndex'),
          staffIndex: staff.get('staffIndex')
        }}
        cellsPathsInProcessing={cellsPathsInProcessing}
        onMouseMoveOverGridCell={onMouseMoveOverGridCell}
        onMouseLeave={onMouseLeave}
        activeDateRange={activeDateRange}
        facilityShiftsMap={facilityShiftsMap}
        notes={notes}
        style={style}
        timeService={timeService}
      />
    </div>
  )
}
