const form = {
  equipmentGroup: {
    definition: {
      label: 'Select Category',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'equipmentGroup',
    formItemClasses: 'col-12'
  },
  equipment: {
    definition: {
      label: 'Select Equipment',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'equipment',
    formItemClasses: 'col-12'
  }
}

export default form
