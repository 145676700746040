import Avatar from '../../../components/Avatar/Avatar'
import { Map } from 'immutable'
import './StaffOvertime.scss'

export interface IStaffOvertimesProps {
  readonly staff: IStaffOvertime
  readonly index: number
}

export interface IStaffOvertime {
  staffFirstName: string
  staffLastName: string
  avatarUrl: string
  actualWorkingHours: number
  expectedWorkingHours: number
}

export default function StaffOvertime({ staff, index }: IStaffOvertimesProps) {
  return (
    <div className="row align-center align-middle">
      <div className="col-1 row align-center staff-index">{index + 1}</div>
      <div className="col-2 row align-center staff-avatar">
        <Avatar
          size={44}
          profile={Map({
            firstName: staff.staffFirstName,
            lastName: staff.staffLastName,
            avatarUrl: staff.avatarUrl
          })}
        />
      </div>
      <div className="col-9">
        <div className="row">
          <div className="col-5 staff-name">{`${staff.staffFirstName} ${staff.staffLastName}`}</div>
        </div>
        <div className="row">
          <div className="col-5 staff-work-hours">
            {staff.actualWorkingHours} / {staff.expectedWorkingHours} hours
          </div>
        </div>
      </div>
    </div>
  )
}
