import { Map } from 'immutable'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { LoadOpenShifts, OpenShiftsConfig } from './OpenShifts'
import { LoadOpenShift, OpenShiftActions, OpenShiftConfig } from './OpenShift'
import noYetImplemented from './notImplemented'

function OpenShiftsStore() {
  const actions = {
    ...LoadOpenShifts.actions,
    ...LoadOpenShift.actions,
    ...OpenShiftsConfig.actions,
    ...OpenShiftConfig.actions,
    ...OpenShiftActions.actions
  }
  const stateActions = fluxStore({ ...noYetImplemented })

  return {
    initialize,
    ...actions,
    ...stateActions
  }

  function initialize(state, context) {
    const { updateOpenShiftsStateWith, updateOpenShiftsStateWithState } = fluxStore({
      updateOpenShiftsStateWith: _updateOpenShiftsStateWith,
      updateOpenShiftsStateWithState: _updateOpenShiftsStateWithState
    })

    LoadOpenShifts.initialize({ ...context, updateOpenShiftsStateWith })
    OpenShiftsConfig.initialize({ ...context, updateOpenShiftsStateWith })
    LoadOpenShift.initialize({ ...context, updateOpenShiftsStateWith })
    OpenShiftConfig.initialize({ ...context, updateOpenShiftsStateWithState })
    OpenShiftActions.initialize({ ...context, updateOpenShiftsStateWith })

    const { defaultState: openShiftDefaultState } = OpenShiftConfig
    const { defaultState: openShiftsDefaultState } = OpenShiftsConfig
    const defaultState = {
      openShiftsState: Map({
        ...openShiftDefaultState,
        ...openShiftsDefaultState
      })
    }

    return state.merge(defaultState)
  }

  function _updateOpenShiftsStateWith(state, callback) {
    return state.update('openShiftsState', callback)
  }

  function _updateOpenShiftsStateWithState(state, callback) {
    return state.update('openShiftsState', (openShiftsState) => callback(openShiftsState, state))
  }
}

export default OpenShiftsStore
