import { PureComponent, createRef } from 'react'
import classNames from 'classnames'

export default class TimeOffRequestPending extends PureComponent {
  constructor(props) {
    super(props)
    this.ref = createRef()
  }

  componentDidUpdate(prevProps) {
    const { timeOffRequest, showPopup } = this.props
    const { timeOffRequest: prevTimeOffRequest } = prevProps

    const isSelected = timeOffRequest.get('isSelected')
    const prevIsSelected = prevTimeOffRequest.get('isSelected')
    const isJustSelected = isSelected && !prevIsSelected

    if (isJustSelected) {
      showPopup(this.ref.current)
    }
  }

  getTitle = () => {
    const { timeService, timeOffRequest } = this.props
    const createdAt = timeOffRequest.get('createdAt')
    const createdAtMoment = timeService.timeMoment(createdAt)
    const isInOneDateRange = timeOffRequest.get('isInOneDateRange')
    const dates = timeOffRequest.get('datesInDateRange')

    let title

    switch (true) {
      case dates.size === 1:
        title = (
          <span
            className={classNames({
              'small text-center': true,
              'dodger-blue': !isInOneDateRange,
              'regent-gray': isInOneDateRange
            })}
          >
            <span className="tiny">{createdAtMoment.format('M')}</span>
            <br />
            <span className="tiny">{createdAtMoment.format('D')}</span>
          </span>
        )

        break

      case dates.size < 4:
        title = (
          <span
            className={classNames({
              small: true,
              'dodger-blue': !isInOneDateRange,
              'regent-gray': isInOneDateRange
            })}
          >
            {createdAtMoment.format('M/D')}
          </span>
        )

        break

      default:
        title = (
          <span className="small">
            <span className="regent-gray">Requested on {createdAtMoment.format('M/D')}</span>
            &nbsp;
            {!isInOneDateRange && <span className="dodger-blue semibold">Extended request</span>}
          </span>
        )
    }

    return title
  }

  render() {
    const { style, timeOffRequest } = this.props
    const isSelected = timeOffRequest.get('isSelected')

    const title = this.getTitle()
    const timeOffRequestId = timeOffRequest.get('id')
    const className = classNames('hx-time-off-request bg-white pending', { selected: isSelected })

    return (
      <div
        ref={this.ref}
        data-time-off-request-id={timeOffRequestId}
        className={className}
        style={style}
        onMouseLeave={this.props.onMouseLeaveTimeOffRequest}
        onMouseEnter={this.props.onMouseEnterTimeOffRequest}
      >
        {title}
      </div>
    )
  }

  // onMouseEnter = event => {
  //   const bounding = this.ref.current.getBoundingClientRect();
  //   const { timeOffRequest, showPendingTimeOffModal, shiftId } = this.props;
  //   const { width, height, x, y } = event.currentTarget.getBoundingClientRect();
  //   const pendingTimeOff = {
  //     timeOffRequest,
  //     bounding,
  //     rectangle: { width, height, x, y },
  //     shiftId
  //   };
  //   // showPendingTimeOffModal(pendingTimeOff);
  //   this.setState({ isSelected: true });
  // };

  // onMouseLeave = () => {
  //   setTimeout(() => {
  //     const { isHoveringOverPendingTimeOffModal, hidePendingTimeOffModal } = this.props;

  //     // if (!isHoveringOverPendingTimeOffModal) hidePendingTimeOffModal();
  //   }, 10);
  // };
}
