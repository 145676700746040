import { fromJS, List, Map } from 'immutable'
import { cloneDeep } from 'lodash'
import { FacilityUserShiftsService } from 'services'
import { Staff, StaffEvent } from 'entityWrappers'
import { EventTemplate } from 'entityWrappers/Calendar'
import { ComponentController } from 'Common/components'

export default class StaffEventController extends ComponentController {
  set unitId(unitId) {
    const state = this.buildParametersForUnit(unitId)
    this.component.setState(state)
  }

  get defaultState() {
    return {
      isProcessing: false,
      documentType: 'StaffEvent',
      isStaffEventLoading: true
    }
  }

  get selectedParameters() {
    const { unitId, eventTemplate } = this.state
    return { unitId, eventTemplate }
  }

  get eligibleUnits() {
    const { units } = this.props

    const nonEmptyEligibleUnitIds = this.eventTemplatesByUnitIds
      .filter((shiftList) => shiftList.size)
      .keySeq()
      .toList()
    return units.filter((unit) => nonEmptyEligibleUnitIds.includes(unit.get('id')))
  }

  get selectedEventTemplateId() {
    return this.state.selectedEventTemplateId
  }

  get eventTemplates() {
    const { unitId } = this.component.state
    const { eventTemplatesByUnitIds } = this

    if (!eventTemplatesByUnitIds) {
      return null
    }

    return eventTemplatesByUnitIds.get(unitId) || List()
  }

  get eventTemplatesByUnitIds() {
    const { staff, day, days, shiftsMap, timeService, unitManager } = this.props

    if (!shiftsMap || shiftsMap.size === 0) {
      return null
    }

    const user = new Staff(staff)
    const dates = days.map((day) => day.get('dateTime'))
    const { eligibleUnits } = user

    const managerOtherUnitIds = unitManager.getIn(['unitManagerProfile', 'otherUnitIds'])
    const managerHomeUnitId = unitManager.getIn(['unitManagerProfile', 'homeUnitId'])
    const availableUnitIds = managerOtherUnitIds.push(managerHomeUnitId)

    const userShiftsService = new FacilityUserShiftsService(timeService)
    const staffShiftsByUnitIds = userShiftsService.getStaffShiftsByUnitIds(
      dates,
      eligibleUnits,
      shiftsMap.toList(),
      availableUnitIds
    )

    const date = day.get('dateTime')

    const eligibleUnit = user.getEligibleUnitForDate(date, timeService)
    const homeUnitId = eligibleUnit?.get('homeUnitId')

    const primaryShift = this._getStaffPrimaryShift()
    const meetingEventTemplate =
      primaryShift && EventTemplate.buildMeetingEventTemplate(date, primaryShift, timeService)

    return staffShiftsByUnitIds.map((shifts, unitId) => {
      const assignmentTemplates = shifts.map((shift) => {
        const eventVariantId = shift.get('isOnCall') ? 'onCall' : 'assignment'
        return EventTemplate.buildEventTemplate(eventVariantId, date, shift, timeService)
      })
      const isHomeUnit = unitId === homeUnitId
      const hasMeetingTemplate = isHomeUnit && meetingEventTemplate
      return hasMeetingTemplate ? List([...assignmentTemplates, meetingEventTemplate]) : List([...assignmentTemplates])
    })
  }

  setEventTemplate = (eventTemplateId, { startsAt, endsAt } = {}) => {
    if (!eventTemplateId) {
      const { selectedEventTemplateId } = this.state
      let eventTemplate = this.getEventTemplate()

      eventTemplate = EventTemplate.duplicate(eventTemplate, { startsAt, endsAt })
      let updatedEventTemplate
      if (selectedEventTemplateId) {
        const selectedEventTemplateIndex = this.state.eventTemplate.findIndex(
          (item) => item.id === selectedEventTemplateId
        )
        updatedEventTemplate = cloneDeep(this.state.eventTemplate)
        updatedEventTemplate[selectedEventTemplateIndex] = eventTemplate
      } else {
        updatedEventTemplate = [eventTemplate]
      }

      this.setState((prevState) => {
        return {
          ...prevState,
          eventTemplate: updatedEventTemplate
        }
      })
    } else {
      let eventTemplate = this.state.eventTemplate
      const isFound = this.state.eventTemplate.find((item) => item.id === eventTemplateId)
      if (!isFound) {
        const defaultEventTemplate = this.eventTemplates.filter((item) => item.id === eventTemplateId)
        eventTemplate = defaultEventTemplate.toJS()
      }
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedEventTemplateId: eventTemplateId,
          eventTemplate
        }
      })
    }
  }

  _getStaffPrimaryShift() {
    const { day, staff, shiftsMap, timeService } = this.props

    if (!shiftsMap || shiftsMap.size === 0) {
      return null
    }

    const dateTime = day.get('dateTime')
    const eligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits'])

    const userShiftsService = new FacilityUserShiftsService(timeService)
    return userShiftsService.getPrimaryShift(eligibleUnits, dateTime, shiftsMap.valueSeq())
  }

  async loadCellStaffEvents() {
    const { calendarStore, cell } = this.props
    const cellStaffEvents = cell.get('staffEvents') || Map()
    if (cellStaffEvents.size) {
      for (const cellStaffEvent of cellStaffEvents) {
        const staffEventId = cellStaffEvent.get('id')
        if (staffEventId) {
          const staffEvent = await calendarStore.readStaffEvent(staffEventId)
          this.buildParametersForCell(staffEvent)
        }
      }
    } else {
      this.buildParametersForCell(null)
    }
  }

  getEventTemplate() {
    const { eventTemplate, selectedEventTemplateId } = this.state
    if (selectedEventTemplateId) {
      return eventTemplate.find((item) => item.id === selectedEventTemplateId)
    }
    return eventTemplate[0]
  }

  getEventParameters() {
    const { timeService } = this.props
    const eventTemplate = this.getEventTemplate()

    const { unitId, startsAt, endsAt, shiftId, eventVariantId } = eventTemplate

    const startTime = this._getEventStartTime(startsAt)

    const dlsOffset = timeService.calculateDLSChanging(startsAt, endsAt)
    const duration = timeService.timeMoment(endsAt).diff(startsAt, 'minutes') + dlsOffset
    const length = duration

    return Map({ shiftId, startTime, length, unitId, duration, eventVariantId })
  }

  _getEventStartTime(startsAt) {
    const { day, timeService } = this.props
    const dateTime = day.get('dateTime')

    const startsAtMoment = timeService.timeMoment(startsAt)
    const startOfDay = timeService.timeMoment(dateTime).startOf('day')

    const dlsOffset = timeService.calculateDLSChanging(startOfDay.toISOString(), startsAt)
    const offsetInMinutes = startsAtMoment.diff(startOfDay, 'minutes') + dlsOffset

    const hours = Math.abs(Math.trunc(offsetInMinutes / 60))
    const minutes = Math.abs(offsetInMinutes % 60)

    const sign = offsetInMinutes < 0 ? '-' : '+'
    const hh = hours < 10 ? `0${hours}` : hours
    const mm = minutes < 10 ? `0${minutes}` : minutes

    return `${sign}${hh}:${mm}`
  }

  buildParametersForCell(staffEvent) {
    const parameters = this.cellParameters(staffEvent)
    this.component.setState((prevState) => {
      return {
        ...prevState,
        isStaffEventLoading: false,
        eventTemplate: prevState.eventTemplate
          ? [...prevState.eventTemplate, parameters.eventTemplate]
          : [parameters.eventTemplate],
        staffEvent: prevState.staffEvent ? [...prevState.staffEvent, staffEvent] : [staffEvent],
        unitId: parameters.unitId
      }
    })
  }

  cellParameters(staffEvent) {
    const { day, shiftId, shiftsMap, unitId } = this.props
    const staffEventType = staffEvent?.type
    const isShiftEvent = staffEventType === 'assignment' || staffEventType === 'onCall'
    const isMeetingEvent = staffEventType === 'meeting'

    if (isShiftEvent || isMeetingEvent) {
      if (!staffEvent) {
        return {}
      }
      const {
        eventVariantId,
        staffEventShiftId: shiftId,
        staffEventStartsAt: startsAt,
        staffEventEndsAt: endsAt,
        staffEventUnitId: unitId
      } = new StaffEvent(fromJS(staffEvent))

      const eventTemplate = new EventTemplate(unitId, eventVariantId, startsAt, endsAt, shiftId)
      return { unitId, eventTemplate }
    }

    const { timeService } = this.props
    const shift = shiftsMap.get(shiftId) || shiftsMap.find((shift) => shift.get('unitId') === unitId)
    const dayStartsAt = day.get('dateTime')
    const eventVariantId = 'assignment'
    const eventTemplate = EventTemplate.buildEventTemplate(eventVariantId, dayStartsAt, shift, timeService)

    return { unitId, eventTemplate }
  }

  buildParametersForUnit(unitId) {
    const { eventTemplate: selectedEventTemplate, staffEvent } = this.state
    const cellParameters = this.cellParameters(staffEvent)
    const eventTemplate = this._determineTemplate(unitId, selectedEventTemplate, cellParameters)

    return { unitId, eventTemplate }
  }

  _determineTemplate(unitId, selectedEventTemplate, cellParameters) {
    const { unitId: eventTemplateUnitId } = selectedEventTemplate
    const isSelectedEventTemplateInSelectedUnit = eventTemplateUnitId === unitId
    if (isSelectedEventTemplateInSelectedUnit) {
      return selectedEventTemplate
    }

    const { eventTemplate: cellEventTemplate } = cellParameters
    const { unitId: cellEventTemplateUnitId } = cellEventTemplate
    const isCellEventInSelectedUnit = cellEventTemplateUnitId === unitId
    if (isCellEventInSelectedUnit) {
      return cellEventTemplate
    }

    const primaryShift = this._getStaffPrimaryShift()
    const primaryShiftUnitId = primaryShift?.get('unitId')

    const isPrimaryShiftInSelectedUnit = primaryShiftUnitId === unitId
    const { timeService, day } = this.props
    const dayStartsAt = day.get('dateTime')
    const eventVariantId = 'assignment'

    if (isPrimaryShiftInSelectedUnit) {
      return EventTemplate.buildEventTemplate(eventVariantId, dayStartsAt, primaryShift, timeService)
    }

    const unitEventTemplates = this.eventTemplatesByUnitIds.get(unitId)
    return unitEventTemplates.size > 0 ? unitEventTemplates.first() : undefined
  }
}
