import { List } from 'immutable'

export default function extendOpenShiftWithFacilityUsers(openShift, extensionParameters) {
  const { facilityUsersMap } = extensionParameters
  if (!facilityUsersMap) {
    return openShift
  }

  const eligibleUserIds = getEligibleUserIds(openShift)
  const facilityUserIds = getFacilityUserIds(openShift, extensionParameters)
  const acceptedByUserIds = getAcceptedByUserIds(openShift)
  const deniedByUserIds = getDeniedByUserIds(openShift)
  const approvedUserIds = getApprovedUserIds(openShift)
  const notRespondedUserIds = getNotRespondedUserIds({
    approvedUserIds,
    eligibleUserIds,
    acceptedByUserIds,
    deniedByUserIds
  })
  const availableUserIds =
    extensionParameters.availableUserIds ||
    getAvailableUserIds({
      eligibleUserIds,
      deniedByUserIds,
      approvedUserIds
    })
  const declinedUserIds = deniedByUserIds
  const notInvitedUserIds = getNotInvitedUserIds({
    eligibleUserIds,
    availableUserIds
  })
  const pendingToConfirmUserIds = getPendingToConfirmUserIds({
    approvedUserIds,
    deniedByUserIds,
    acceptedByUserIds
  })

  const facilityUsers = facilityUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const notRespondedFacilityUsers = notRespondedUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const availableFacilityUsers = availableUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const declinedFacilityUsers = declinedUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const acceptedFacilityUsers = acceptedByUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const approvedFacilityUsers = approvedUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const notInvitedFacilityUsers = notInvitedUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const eligibleFacilityUsers = eligibleUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const pendingToConfirmUsers = pendingToConfirmUserIds
    .filter((userId) => !!facilityUsersMap.get(userId))
    .map((userId) => facilityUsersMap.get(userId))

  const isPosted = !!openShift.get('id')
  const acceptanceCriteria = openShift.get('acceptanceCriteria')
  const isManagerApproval = acceptanceCriteria === 'manualApprove'
  const requireManagerApproval =
    isPosted && isManagerApproval && acceptedByUserIds.some((userId) => !approvedUserIds.includes(userId))

  return openShift.merge({
    eligibleUserIds,
    facilityUserIds,
    acceptedByUserIds,
    deniedByUserIds,
    approvedUserIds,
    notRespondedUserIds,
    availableUserIds,
    declinedUserIds,
    notInvitedUserIds,
    pendingToConfirmUserIds,

    facilityUsers,
    notRespondedFacilityUsers,
    availableFacilityUsers,
    declinedFacilityUsers,
    acceptedFacilityUsers,
    approvedFacilityUsers,
    notInvitedFacilityUsers,
    eligibleFacilityUsers,
    pendingToConfirmUsers,

    requireManagerApproval
  })
}

function getNotInvitedUserIds({ eligibleUserIds, availableUserIds }) {
  return availableUserIds.filter((userId) => !eligibleUserIds.includes(userId))
}

function getAvailableUserIds({ eligibleUserIds, deniedByUserIds, approvedUserIds, availableUserIds }) {
  return eligibleUserIds.filter((userId) => {
    return !deniedByUserIds.includes(userId) && !approvedUserIds.includes(userId)
  })
}

function getApprovedUserIds(openShift) {
  return openShift.get('approvedUserIds') || List()
}

function getAcceptedByUserIds(openShift) {
  const acceptedByUserIds = openShift.get('acceptedByUserIds') || List()
  const approvedUserIds = openShift.get('approvedUserIds') || List()

  return acceptedByUserIds.concat(approvedUserIds).toSet().toList()
}

function getDeniedByUserIds(openShift) {
  return openShift.get('deniedByUserIds') || List()
}

function getNotRespondedUserIds({ eligibleUserIds, acceptedByUserIds, deniedByUserIds, approvedUserIds }) {
  return eligibleUserIds.filter((userId) => {
    return !approvedUserIds.includes(userId) && !acceptedByUserIds.includes(userId) && !deniedByUserIds.includes(userId)
  })
}

function getPendingToConfirmUserIds({ approvedUserIds, deniedByUserIds, acceptedByUserIds }) {
  return acceptedByUserIds.filter((userId) => !approvedUserIds.includes(userId) && !deniedByUserIds.includes(userId))
}

function getFacilityUserIds(openShift, extensionParameters) {
  const { facilityUserIds } = extensionParameters
  return facilityUserIds || getEligibleUserIds(openShift)
}

function getEligibleUserIds(openShift) {
  const isPosted = !!openShift.get('id')
  if (isPosted) {
    return openShift.get('eligibleUserIds') || List()
  }

  const eligibleFacilityUsers = openShift.get('eligibleFacilityUsers') || List()

  return eligibleFacilityUsers.map((facilityUser) => facilityUser.get('userId'))
}
