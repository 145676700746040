export default class ComponentController {
  constructor(component) {
    this.component = component
  }

  get state() {
    return this.component.state
  }

  get props() {
    return this.component.props
  }

  setState(state, callback = () => true) {
    return this.component.setState?.(state, callback)
  }
}
