import { Component } from 'react'
import { FileUpload } from '../../../components'
import { addWarningBanner } from 'stores'
import './CreateEditDetailImage.scss'
import img1 from '../../temp/1.jpg'
import img2 from '../../temp/2.jpg'
import img3 from '../../temp/3.jpg'
import img4 from '../../temp/4.jpg'
import img5 from '../../temp/5.jpg'
import img6 from '../../temp/6.jpg'
import img7 from '../../temp/7.jpg'
import img8 from '../../temp/8.jpg'
import { curry } from 'i18n'

const t = curry('announcements.banners.announcement.')

class CreateEditDetailImage extends Component {
  state = {
    imageList: []
  }

  async componentDidMount() {
    const imageList = loadPresetImages()
    this.setState({ imageList })
  }

  handleFile = async (imageFiles) => {
    const { activeComponent: component, instanceUri, facilityId } = this.props

    const _updateDetailsComponent = (imageSrc) =>
      this.props.updateDetailsComponent({ ...component, content: { imageSrc } })
    const _handleImageUploadError = () => addWarningBanner({ message: t('image_upload_error') })

    if (typeof imageFiles === 'string') {
      return _updateDetailsComponent(imageFiles)
    }

    //upload image to file storage and get URL
    const uploadImageUrl = `${instanceUri}/api/now/announcements/media`
    let bodyFormData = new FormData()
    bodyFormData.append('file', imageFiles[0])
    const options = {
      body: bodyFormData,
      method: 'POST',
      credentials: 'include',
      headers: {
        Scope: facilityId
      }
    }

    try {
      const response = await fetch(uploadImageUrl, options)
      if (!response.ok) {
        throw new Error()
      }
      const responseBody = await response.json()
      if (!responseBody.url) {
        throw new Error()
      }
      _updateDetailsComponent(responseBody.url) //update announcement details with image URL
    } catch (_err) {
      _handleImageUploadError()
    }
  }

  render() {
    const { imageList } = this.state

    return (
      <div className="add-img-container">
        <div className="img-list-container">
          {imageList
            ? imageList.map((image) => (
                <PresetImageItem key={image.id} src={image.src} title={image.title} handleFile={this.handleFile} />
              ))
            : 'No images found.'}
        </div>
        <div className="separator-container">
          <hr /> Or <hr />
        </div>
        <div className="file-upload-container">
          <FileUpload fileType="image" handleDrop={this.handleFile} />
        </div>
      </div>
    )
  }
}

const PresetImageItem = ({ title, src, handleFile }) => {
  return (
    <div className="image-item-container">
      <div className="image-item-title">{title}</div>
      <div className="img-container">
        <img
          src={src}
          alt=""
          className="image-item-img"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            handleFile(e.target.src)
          }}
        />
      </div>
    </div>
  )
}

const loadPresetImages = () => {
  // TO CHANGE
  return [
    { id: 1, src: img1, title: 'Hello' },
    { id: 2, src: img2, title: 'Thank you' },
    { id: 3, src: img3, title: 'Microphone' },
    { id: 4, src: img4, title: 'Post Its' },
    { id: 5, src: img5, title: 'Birthday Cake' },
    { id: 6, src: img6, title: 'Happy Birthday' },
    { id: 7, src: img7, title: 'Social Event' },
    { id: 8, src: img8, title: 'Celebration' }
  ]
}

export default CreateEditDetailImage
