import { t } from 'i18n'
import './ShiftSwapDetails.scss'

export default function ShiftSwapDetails({
  date,
  shiftTime,
  unitName,
  shiftName,
  profile,
  position,
  isInitiator
}: any) {
  const avatarUrl = profile.get('avatarUrl')
  const firstName = profile.get('firstName')
  const lastName = profile.get('lastName')
  const fullName = firstName + ' ' + lastName
  return (
    <div className="shiftSwapDetails" style={{ marginLeft: '0.5em' }} data-testid="shift-swap-deatils-component">
      {!isInitiator ? (
        <div className="shift-swap-request-avatar">
          <sh-user-identifier
            slot="user"
            name={fullName}
            info={position}
            image={avatarUrl && `url(${avatarUrl})`}
          ></sh-user-identifier>
        </div>
      ) : (
        <div className="current-user-text">
          <sh-text size="title-1">{`${t('staffCalendar.shiftSwap.titles.you')}`}</sh-text>
        </div>
      )}
      <sh-text size="title-1">{date}</sh-text>
      <sh-text size="body-2">{shiftTime}</sh-text>
      <sh-text size="title-2">{unitName}</sh-text>
      <sh-text size="body-2">{shiftName}</sh-text>
    </div>
  )
}
