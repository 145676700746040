export function buildIdNameMapping(map, idKey, nameKey, filter) {
  return map
    .valueSeq()
    .filter((objList) => {
      const obj = objList.get(0)
      for (let [key, value] of Object.entries(filter)) {
        if (obj.get(key) !== value) {
          return false
        }
      }
      return true
    })
    .map((objList) => ({
      key: objList.get(0).get(idKey),
      name: objList.get(0).get(nameKey)
    }))
    .toJS()
}

export function deepRemoveProperty(obj, prop) {
  if (!obj || typeof obj !== 'object') {
    return obj
  }
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (key === prop) {
      return
    }
    newObj[key] = deepRemoveProperty(obj[key], prop)
  })
  return newObj
}

export function isAnyRequiredFieldEmpty(form, ignoreList = []) {
  for (const field in form) {
    if (!form[field].definition.required || ignoreList.includes(field)) {
      continue
    }
    if (form[field].value === undefined || form[field].value === '' || form[field].value === '_') {
      return true
    }
  }
  return false
}

export function getFieldValueMapping(form) {
  const fieldValueMap = {}
  for (const field of Object.values(form)) {
    fieldValueMap[field.field] = field.value
  }
  return fieldValueMap
}

export function doesDuplicateExist(arr) {
  return new Set(arr).size !== arr.length
}

export function getCommonFieldsDefinition() {
  return {
    assessedCompetency: {
      definition: {
        label: 'Assessed Competency',
        required: true,
        type: 'string',
        inputType: 'CustomDropDown',
        withLabel: true
      },
      field: 'assessedCompetency',
      formItemClasses: 'col-6 pr30'
    },
    reviewedDate: {
      definition: {
        label: 'Reviewed Date',
        required: false,
        type: 'date',
        inputType: 'CustomDateInput',
        withLabel: true,
        flatpickrOptions: {
          static: true
        }
      },
      field: 'reviewedDate',
      formItemClasses: 'col-6 pr30'
    },
    expirationDate: {
      definition: {
        label: 'Expiration Date',
        required: false,
        type: 'date',
        inputType: 'CustomDateInput',
        withLabel: true,
        flatpickrOptions: {
          static: true
        }
      },
      field: 'expirationDate',
      formItemClasses: 'col-6 pr30'
    }
  }
}
