import { PureComponent } from 'react'
import { pick } from 'lodash'
import { PageContent, PageFooter, PageHeader } from '../Page'
import Role from './Role'
import DayHeader from './DayHeader'

export default class PrintedDayView extends PureComponent {
  async componentDidMount() {
    const { printStatus, loadCalendarDay, loadOtherStaff, isOtherStaffLoaded } = this.props
    const { date } = printStatus

    if (!isOtherStaffLoaded) {
      await loadOtherStaff()
    }
    await loadCalendarDay(date)
  }

  render() {
    const { filters, additionalFilters, printStatus, unit } = this.props
    const { date } = printStatus
    const unitId = unit.get('id')
    const printModel = this.props.getDayViewModel(unitId, date, additionalFilters)

    const title = 'Day Schedule'
    const headerProps = pick(
      {
        ...this.props,
        title
      },
      ['unit', 'printedDateRange', 'title']
    )
    const footerProps = pick(
      {
        ...this.props,
        isLegendVisible: true,
        filterRoleIds: filters.get('roleIds'),
        filterShiftTypes: filters.get('shiftTypes')
      },
      ['unit', 'timeService', 'filterRoleIds', 'authentication', 'filterShiftTypes', 'isLegendVisible']
    )

    const dayHeaderProps = pick({ ...this.props, date }, ['date', 'timeService'])

    return (
      <div className="printed-day-view">
        <table className="print-table">
          <PageHeader {...headerProps}>
            <DayHeader {...dayHeaderProps} />
          </PageHeader>
          <PageContent>{printModel.map(this.renderRole)}</PageContent>
          <PageFooter {...footerProps} />
        </table>
      </div>
    )
  }

  renderRole = (role, index) => {
    const { timeService } = this.props
    const props = { role, timeService }

    return <Role {...props} key={index} />
  }
}
