import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import { t } from 'i18n'
const config = {
  open: {
    title: t('close'),
    isConfirmationRequired: true,
    confirmation: {
      message: t('calendar.workflow.close_schedule.disclosure'),
      options: {
        accept: t('calendar.workflow.close_schedule.confirm'),
        cancel: t('calendar.workflow.go_back'),
        title: t('calendar.workflow.close_schedule.title'),
        type: 'warning'
      }
    }
  },
  balancing: {
    title: t('publish'),
    confirmation: {
      message: t('calendar.workflow.publish_schedule.disclosure'),
      options: {
        title: t('calendar.workflow.publish_schedule.title'),
        accept: t('calendar.workflow.publish_schedule.confirm'),
        cancel: t('calendar.workflow.go_back'),
        type: 'warning'
      }
    },
    isConfirmationRequired: true
  },
  published: { title: null }
}

export class UpdateScheduleState extends PureComponent {
  state = { isSpinnerShown: false }

  render() {
    const { isSpinnerShown } = this.state
    const { activeDateRange, Dialog } = this.props
    const scheduleState = activeDateRange.get('scheduleState')
    const isShown = scheduleState === 'balancing' || scheduleState === 'open'

    if (!isShown) {
      return null
    }

    const { title, confirmation, isConfirmationRequired } = config[scheduleState]

    const onClick = async (e) => {
      if (isConfirmationRequired) {
        const response = await Dialog.confirm(confirmation.message, confirmation.options)
        if (!response) {
          return
        }
      }

      this.updateScheduleState()
    }

    return (
      <>
        <sh-button
          id="publish-btn"
          onClick={onClick}
          color="primary"
          label={title}
          disabled={isSpinnerShown ? true : undefined}
        />
        {this.state.isSpinnerShown && <sh-spinner overlay label="Schedule is being updated"></sh-spinner>}
        {title === 'Publish' && (
          <sh-tooltip class="below-1200" label={title} target="publish-btn" placement="bottom" variation="short" />
        )}
      </>
    )
  }

  updateScheduleState = async () => {
    const { activeDateRange, generalStore, sendSchedulePdf } = this.props
    const scheduleId = activeDateRange.get('scheduleId')
    const scheduleState = activeDateRange.get('scheduleState')
    let newScheduleState

    if (scheduleState === 'open') {
      newScheduleState = 'balancing'
    }

    if (scheduleState === 'balancing') {
      newScheduleState = 'published'
    }
    this.setState({ isSpinnerShown: true })
    try {
      await generalStore.updateScheduleState(scheduleId, newScheduleState)
      if (newScheduleState === 'published') {
        await sendSchedulePdf({})
      } //exception handled in print controller
    } catch (e) {
      /* already handled as a banner */
    }
    this.setState({ isSpinnerShown: false })
  }
}

export default withAppContext(UpdateScheduleState)
