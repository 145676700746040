import { Component } from 'react'

export default class CustomRadioToggle extends Component {
  render() {
    const { value, label, field, onChange, isChecked, isDisabled } = this.props
    const {
      id,
      inputClassName = '',
      labelClassName = '',
      wrapperClassName = '',
      wrapperStyle,
      labelIsIcon,
      iconClass
    } = this.props

    return (
      <div className={`hx-radio-toggle ${wrapperClassName}`} style={wrapperStyle}>
        <input
          id={id}
          type="radio"
          name={field}
          value={value}
          checked={isChecked}
          disabled={isDisabled}
          className={inputClassName}
          onChange={(e) => onChange(field, value)}
        />
        <label htmlFor={id} className={`trout semibold ${labelClassName}`}>
          {!labelIsIcon ? label : <i className={iconClass} />}
        </label>
      </div>
    )
  }
}
