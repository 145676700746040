import EngagementCenter from 'Manager/EngagementCenter/EngagementCenter'
import { Link } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import { paths } from 'Navigation'
import { t } from 'i18n'
import constants from 'utils/constants'
import './SkillscheckAdministratorLayout.scss'
import { CustomerSupport } from 'Common/components'
import { useContext } from 'react'

// @ts-ignore
import { CustomerSupportContext } from '@humanics/he-react-common/lib/components/CustomerSupport'

const SupportHelp = () => {
  const supportContext: { handleClick: () => Promise<void> } = useContext(CustomerSupportContext)

  return (
    <div className="cursor-pointer" onClick={supportContext.handleClick}>
      <sh-list-item>{t('help_text')}</sh-list-item>
    </div>
  )
}

function SkillscheckAdministratorLayout(props: any) {
  const { digitalServicesActLink } = constants
  const user = props.appState.getIn(['authentication', 'facilityUser', 'profile'])

  const firstName = user.get('firstName')
  const lastName = user.get('lastName')
  const email = user.get('email')
  const avatarUrl = user.get('avatarUrl')

  return (
    <>
      <sh-access-bar label={t('product_name')} slot="access">
        <sh-user-identifier
          slot="user"
          name={firstName + ' ' + lastName}
          info={email}
          id="username"
          image={avatarUrl && `url(${avatarUrl})`}
        ></sh-user-identifier>
      </sh-access-bar>

      <sh-popover target="username" position="bottom-left">
        <Link to={paths.PrivacyPolicy}>
          <sh-list-item>{t('privacy_policy_text')}</sh-list-item>
        </Link>
        <Link to={paths.TermsOfService}>
          <sh-list-item>{t('terms_of_service_text')}</sh-list-item>
        </Link>
        <Link to={paths.ReadmeOSS}>
          <sh-list-item>{t('readme_oss_text')}</sh-list-item>
        </Link>
        <a href={digitalServicesActLink} target="_blank" rel="noreferrer">
          <sh-list-item>{t('digital_service_act_text')}</sh-list-item>
        </a>
        <CustomerSupport>
          <SupportHelp />
        </CustomerSupport>

        <Link to={paths.Logout}>
          <sh-list-item icon="sign-out" divider="none" label={t('auth.logout')} />
        </Link>
      </sh-popover>
      <div className="skillscheck">
        <EngagementCenter skipUnitValidation={true} />
      </div>
    </>
  )
}

export default withAppContext(SkillscheckAdministratorLayout)
