import { PureComponent, createRef } from 'react'
import classNames from 'classnames'
import { Map } from 'immutable'
import { middleGlyphMap, sideGlyphMap } from 'Manager/components/CellIconMap'

export default class Cell extends PureComponent {
  ref = createRef()

  componentDidUpdate(prevProps) {
    const { showPopup, cell, isMultipleCellsSelected } = this.props
    const { cell: prevCell, isMultipleCellsSelected: prevIsMultipleCellsSelected } = prevProps

    const isLastSelectedCell = cell.get('isLastSelectedCell')
    const prevIsLastSelectedCell = prevCell?.get?.('isLastSelectedCell')
    const isCellSelectionChanged = isLastSelectedCell !== prevIsLastSelectedCell
    const isCellsSelectionChanged = isMultipleCellsSelected !== prevIsMultipleCellsSelected
    const isSelectionChanged = isCellsSelectionChanged || isCellSelectionChanged

    if (isSelectionChanged && isLastSelectedCell) {
      showPopup(this.ref.current)
    }
  }

  render() {
    const { cell } = this.props

    const isWeekend = cell.get('isWeekend')
    const isDisabled = cell.get('isDisabled')
    const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
    const timeOff = cellStaffEvent.get('timeOff')
    const cellIndex = cell.get('index')

    const isRequestOff = timeOff && timeOff.getIn(['timeOffAttributes', 'paidMinutes']) === 0
    const isPTO = timeOff && timeOff.getIn(['timeOffAttributes', 'paidMinutes']) > 0

    const isSelected = cell.get('isSelected')
    const isPrevSelected = cell.get('isPreviousSelected')
    const isNextSelected = cell.get('isNextSelected')

    const isFirstSelected = isSelected && !isPrevSelected && isNextSelected
    const isMiddleSelected = isSelected && isPrevSelected && isNextSelected
    const isLastSelected = isSelected && isPrevSelected && !isNextSelected

    const content = this._getContent(cell)

    return (
      <div
        ref={this.ref}
        data-cell-index={cellIndex}
        className={classNames('text-center', {
          'col-1 _cell br1 fullview-cell-icons rock-blue': true,
          'bg-alabaster': isWeekend,
          'bg-white': !isWeekend,
          'bg-mystic': !!timeOff || isDisabled,
          'bg-selected-shift': isSelected,
          'first-selected': isFirstSelected,
          'middle-selected': isMiddleSelected,
          'last-selected': isLastSelected,
          unavailability: isRequestOff,
          paidTimeOff: isPTO
        })}
        onClick={(event) => this._handleCellEvent(event, 'onClick')}
        onContextMenu={(event) => this._handleCellEvent(event, 'onContextMenu')}
        onMouseOver={(event) => this._handleCellEvent(event, 'onMouseOver')}
        onMouseLeave={(event) => this._handleCellEvent(event, 'onMouseLeave')}
      >
        {content}
      </div>
    )
  }

  _getContent(cell) {
    const isEmptyCell = cell.size === 0
    if (isEmptyCell) {
      return null
    }

    const isProcessing = cell.get('isProcessing')
    if (isProcessing) {
      return null
    }

    const leftIcons = this._getLeftIcons()
    const middleIcon = this._getMiddleIcon(cell)
    const rightIcons = this._getRightIcons(cell)

    return (
      <>
        <span>{leftIcons}</span>
        <span>{middleIcon}</span>
        <span>{rightIcons}</span>
      </>
    )
  }

  _getLeftIcons(cell) {
    const isLocked =
      cell && !cell.get('isRequestedToWork') && (cell.get('isMandatoryToWork') || cell.get('isTimeOffNotAllowed'))

    if (isLocked) {
      return sideGlyphMap['reset']
    }

    const isRequestedToWork = cell?.get?.('isRequestedToWork')
    if (isRequestedToWork) {
      return sideGlyphMap['checkmark']
    }
    return sideGlyphMap['gap']
  }

  _getMiddleIcon(cell) {
    const cellStaffEvent = cell?.get('staffEvents')?.get(0) || Map()
    const timeOff = cellStaffEvent.get('timeOff')
    const isRequestOff = timeOff?.getIn?.(['timeOffAttributes', 'paidMinutes']) === 0
    const isPTO = timeOff?.getIn?.(['timeOffAttributes', 'paidMinutes']) > 0

    if (isPTO) {
      return middleGlyphMap['money-sign']
    }
    if (isRequestOff) {
      return middleGlyphMap['unavailability']
    }
    return middleGlyphMap['gap']
  }

  _getRightIcons(cell) {
    const cellStaffEvent = cell?.get('staffEvents')?.get(0) || Map()
    const timeOff = cellStaffEvent.get('timeOff')
    const noteIds = timeOff?.get?.('noteIds')
    const hasNotes = noteIds?.size > 0

    if (hasNotes) {
      return sideGlyphMap['note']
    }
    return sideGlyphMap['gap']
  }

  _handleCellEvent = (event, eventType) => {
    if (!event.currentTarget.hasAttribute('data-cell-index')) {
      return
    }
    event.preventDefault()
    const cellIndex = parseInt(event.currentTarget.getAttribute('data-cell-index'))

    const {
      shiftKey: isShiftKeyPressed,
      metaKey: isMetaKeyPressed,
      ctrlKey: isCtrlKeyPressed,
      currentTarget: targetElement
    } = event

    const eventInfo = {
      targetElement,
      isShiftKeyPressed,
      isMetaKeyPressed,
      isCtrlKeyPressed
    }
    const meta = { eventInfo }
    this.props.onCellEvent(cellIndex, eventType, meta)
  }
}
