import './RequestTimeOffDetailsCard.scss'
import { t } from 'i18n'
import { useState, useEffect } from 'react'
import { getDurationMinutes, StaffTimeOffRequestDateInput, getFormattedTime } from 'Staff/Calendar/utils'

export type RequestTimeOffDetailsCardProps = {
  date: string
  timeService?: any
  onDelete: () => void
  onTimeOffDataChange: (timeOffData: StaffTimeOffRequestDateInput) => void
  onPartialDayChange: (isPartial: boolean) => void
  onPartialDayDurationChange: (isDurationSelected: boolean) => void
  timeOffDetails: StaffTimeOffRequestDateInput
}

export default function RequestTimeOffDetailsCard({
  date,
  timeService,
  onDelete,
  onTimeOffDataChange,
  onPartialDayChange,
  onPartialDayDurationChange,
  timeOffDetails,
}: RequestTimeOffDetailsCardProps) {
  const [isPartialDay, setIsPartialDay] = useState(false)
  const [startTime, setStartTime] = useState<string | null>(null)
  const [endTime, setEndTime] = useState('')
  const [durationHrs, setDurationHrs] = useState('')
  const formattedDate = timeService.dateTimeToMoment(date).format('DD/MM/YYYY')

  const handleStartTimeChange = (timeChangeEvent: any) => {
    const { val } = timeChangeEvent.target;
    const updatedVal = getFormattedTime(val);
  
    if (val !== '::') {
      setStartTime(updatedVal);
    }
  };
  
  const handleEndTimeChange = (timeChangeEvent: any) => {
    const { val } = timeChangeEvent.target;
    const updatedVal = getFormattedTime(val);
  
    if (val !== '::') {
      setEndTime(updatedVal);
    }
  };

  useEffect(() => {
    if (startTime && endTime) {
      const durationInMinutes = isPartialDay ? getDurationMinutes(startTime, endTime, timeService) : 1440
      const hours = Math.floor(durationInMinutes / 60)
      const minutes = durationInMinutes % 60
      setDurationHrs(`(${hours}:${minutes} hrs)`)

      const startTime24HourFormat = startTime ? timeService.amTo24(startTime).trim() : null
      
      onTimeOffDataChange({
        ...timeOffDetails,
        isPartial: isPartialDay,
        startTime: startTime24HourFormat,
        duration: durationInMinutes,
      })
      onPartialDayDurationChange(true)
    } else {
      onPartialDayDurationChange(false)
    }
  }, [startTime, endTime])

  const handleDelete = () => {
    onDelete()
  }

  useEffect(() => {
    onPartialDayChange(isPartialDay)
  }, [isPartialDay])

  return (
    <div className="pt5">
      <div className="request-time-off-details-card">
        <sh-card>
          <div className="row align-middle g10">
            <div className="col-3">
              <sh-input-text value={formattedDate} condensed show-tooltip></sh-input-text>
            </div>
            <div className="col-4">
              <sh-switch stretch condensed>
                <sh-switch-item
                  label={t('staffCalendar.timeOff.fullDay')}
                  active
                  onClick={() => setIsPartialDay(false)}
                ></sh-switch-item>
                <sh-switch-item
                  label={t('staffCalendar.timeOff.partialDay')}
                  onClick={() => setIsPartialDay(true)}
                ></sh-switch-item>
              </sh-switch>
            </div>
            <div className="request-time-off-form-delete-button">
              <sh-icon icon="delete" button size="s" onClick={handleDelete}></sh-icon>
            </div>
          </div>
          {isPartialDay && (
            <div className="row align-middle g10 pt16">
              <div className="col-3">
                <sh-timepicker
                  label={t('staffCalendar.timeOff.startTime')}
                  mini
                  condensed
                  clock-format="12-hour"
                  onMouseMove={handleStartTimeChange}
                ></sh-timepicker>
              </div>
              <div className="col-3">
                <sh-timepicker
                  label={t('staffCalendar.timeOff.endTime')}
                  mini
                  condensed
                  clock-format="12-hour"
                  onMouseMove={handleEndTimeChange}
                ></sh-timepicker>
              </div>
              <div>
                <sh-text>{durationHrs}</sh-text>
              </div>
            </div>
          )}
        </sh-card>
      </div>
    </div>
  )
}