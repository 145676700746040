import memoize from 'memoize-one'
import TopNavigationItem from './TopNavigationItem'
import { navigate } from 'Navigation'
import stores from 'stores'

const UsDate = /\d{2}-\d{2}-\d{4}/
const isUsDate = (date) => UsDate.test(date)

export default memoize((components, facilityFeatures, activeComponentId, usDate, notifications) => {
  const date = isUsDate(usDate) ? usDate : stores.timeService().todayUsDate
  const activeComponentPath = components.getIn([activeComponentId, 'path'])
  const { to: navigateTo } = navigate.from[activeComponentPath || 'FacilityUnitHome']

  const permittedComponents = components.filter((component) => {
    const componentFeature = facilityFeatures.find((feature) => feature.get('name') === component.get('feature'))
    return componentFeature && ['read', 'write'].includes(componentFeature.get('state'))
  })

  return permittedComponents.entrySeq().map(([componentId, component]) => {
    const path = component.get('toPath') || component.get('path')

    return (
      <TopNavigationItem
        key={componentId}
        totalNotifications={notifications.get(componentId) || 0}
        isActive={activeComponentId === componentId}
        path={path ? navigateTo[path]({ mode: 'full', date }, false) : null}
        title={component.get('title')}
        icon={component.get('icon')}
      />
    )
  })
})
