import classNames from 'classnames'
import { Link } from 'react-router-dom'

export interface INavItemProps {
  id: string
  label: string
  iconComponent: any
  active?: boolean
  path: any
}
export interface IVerticalNavBarProps {
  navItems: INavItemProps[]
}
export default function VerticalNavBar({ navItems }: IVerticalNavBarProps) {
  return (
    <div className="bg-white-smoke hx-box-shadow flex-column pt20 h100">
      {navItems.map((props) => (
        <div key={props.id}>
          <NavItem {...props} />
        </div>
      ))}
    </div>
  )
}

function NavItem({ label, iconComponent, active, path }: INavItemProps) {
  return (
    <Link to={path}>
      <div className={classNames('row align-center pt5 pb5 m5 ml10 mr10 text-center nav-item', { active })}>
        <div>{iconComponent}</div>
        <sh-text size="body-2" color="primary">
          {label}
        </sh-text>
      </div>
    </Link>
  )
}
