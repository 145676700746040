import { CSSProperties } from 'react'
import memoize from 'memoize-one'

const PAID_TIME_OFF_OPTIONS = [
  'Vacation',
  'Holiday',
  'Compensate off',
  'Public Holiday',
  'Sick Leave',
  'Casual Leave',
  'Earned Leave',
  'Maternity Leave',
  'Weekly Off',
  'Night Off',
  'Bereavement'
]
const UNAVAILABILITY_OPTIONS = [
  'FMLA',
  'Holiday',
  'Jury Duty',
  'LOA',
  'MLOA',
  'Suspension',
  'Vacation',
  'Bereavement',
  'Loss of Pay (LOP)'
]

interface ITimeOffDefinition {
  name: string
  isPTO: boolean
  isRO: boolean
  displayAbbreviation?: string
  displayColor?: CSSProperties['color']
  defaultAttributes?: {
    isPartial?: boolean
  }
}

enum TimeOffTypes {
  RO = 'RO',
  PTO = 'PTO'
}

declare global {
  interface Window {
    TIME_OFF_DEFINITIONS: string
  }
}

export default class TimeOffDefinitionsService {
  static getTimeOffDefinitions = memoize((): ITimeOffDefinition[] | undefined => {
    try {
      return JSON.parse(window.TIME_OFF_DEFINITIONS)
    } catch {
      return undefined
    }
  })

  static getDefaultTimeOffAttributes(timeOffOption: ITimeOffDefinition['name']) {
    const timeOffDefinitions: ITimeOffDefinition[] | undefined = TimeOffDefinitionsService.getTimeOffDefinitions()
    return timeOffDefinitions?.find(({ name }) => name === timeOffOption)?.defaultAttributes || {}
  }

  static buildPaidTimeOffOptions() {
    return TimeOffDefinitionsService.buildTimeOffOptions(TimeOffTypes.PTO, PAID_TIME_OFF_OPTIONS)
  }

  static buildUnavailabilityOptions() {
    return TimeOffDefinitionsService.buildTimeOffOptions(TimeOffTypes.RO, UNAVAILABILITY_OPTIONS)
  }

  static buildTimeOffAbbreviationsMap = memoize(() => {
    const timeOffDefinitions: ITimeOffDefinition[] | undefined = TimeOffDefinitionsService.getTimeOffDefinitions()
    return (
      timeOffDefinitions?.reduce(
        (abbreviationsMap: { [key: string]: string | undefined }, { name, displayAbbreviation }) => {
          abbreviationsMap[name] = displayAbbreviation
          return abbreviationsMap
        },
        {}
      ) || {}
    )
  })

  static buildTimeOffColorsMap = memoize(() => {
    const timeOffDefinitions: ITimeOffDefinition[] | undefined = TimeOffDefinitionsService.getTimeOffDefinitions()
    return (
      timeOffDefinitions?.reduce(
        (colorsMap: { [key: string]: CSSProperties['color'] | undefined }, { name, displayColor }) => {
          colorsMap[name] = displayColor
          return colorsMap
        },
        {}
      ) || {}
    )
  })

  static buildTimeOffOptions(timeOffType: TimeOffTypes, timeOffOptionsFallback?: string[]) {
    let optionsForRO = false
    let optionsForPTO = false

    if (timeOffType === TimeOffTypes.RO) {
      optionsForRO = true
    } else if (timeOffType === TimeOffTypes.PTO) {
      optionsForPTO = true
    }
    const timeOffDefinitions: ITimeOffDefinition[] | undefined = TimeOffDefinitionsService.getTimeOffDefinitions()
    return (
      timeOffDefinitions
        ?.filter(({ isRO, isPTO }) => (optionsForRO && isRO) || (optionsForPTO && isPTO))
        .map(({ name }) => name) || timeOffOptionsFallback
    )
  }
}
