import classNames from 'classnames'
// import { Link } from 'react-router-dom';
// import { navigate } from 'Navigation';

export default function Day({ day }) {
  const { /*usDate,*/ isWeekend, isToday, isPrevDay, dayOfMonth } = day
  // const url = navigate.from.Calendar.to.Calendar(
  //   { date: usDate, mode: 'day' },
  //   false
  // );

  return (
    <div
      className={classNames('col-1 br1 _headerCell', {
        'bg-alabaster': isWeekend,
        'bg-white': !isWeekend,
        'current-day': isToday,
        'prev-day': isPrevDay
      })}
    >
      {/* HMN-2271
      <Link to={url}>{dayOfMonth}</Link>
      */}
      <span>{dayOfMonth}</span>
    </div>
  )
}
