import StaffEvents from './StaffEvents'
import StaffOnTimeOff from './StaffOnTimeOff'
import './DateCard.scss'
import classNames from 'classnames'
import { useContext } from 'react'
import { ItemTypeFilterContext } from 'Staff/Calendar/CalendarContext'

function DateCard({
  shifts,
  staffEvents,
  initiatedShiftSwapRequests,
  receivedShiftSwapRequests,
  isCurrentMonth,
  timeService,
  dateOfMonth,
  isSelectedDay,
  staffDay
}: any) {
  const { selectedCalendarFilters } = useContext(ItemTypeFilterContext)
  const showShiftSwapRequests = selectedCalendarFilters.has('shiftSwap')
  const showStaffOnTimeOffCount = selectedCalendarFilters.has('staffOnTimeOff')
  const containsInitiatedShiftSwapRequests = initiatedShiftSwapRequests && initiatedShiftSwapRequests.size > 0
  const containsReceivedShiftSwapRequests = receivedShiftSwapRequests && receivedShiftSwapRequests.size > 0
  const containsShiftSwapRequests = containsInitiatedShiftSwapRequests || containsReceivedShiftSwapRequests

  const allShiftSwapRequests = [...(initiatedShiftSwapRequests || []), ...(receivedShiftSwapRequests || [])]
  const hasSubmittedOrAcceptedRequest = allShiftSwapRequests.some((request) =>
    ['submitted', 'accepted'].includes(request.get('state'))
  )

  const day = dateOfMonth.date()
  const isToday = timeService.isToday(dateOfMonth)
  const isSameAsSelected = isToday && isSelectedDay
  const approvedTimeOffRequestsCounter = staffDay?.getIn(['approvedTimeOffRequestsCounter'])

  return (
    <div className="date-card">
      <div className="date-section row">
        <div className="col-9">
          {showShiftSwapRequests && containsShiftSwapRequests && hasSubmittedOrAcceptedRequest && (
            <div className="event-tag">
              <sh-icon icon="in-progress" size="xs" color="white"></sh-icon>
              <div className="shift-swap-label">Shift Swap</div>
            </div>
          )}
        </div>

        <div className="col-3">
          <div
            className={classNames('calendar-cell', {
              'today-date': isToday,
              'float-right': true,
              'is-selected': isSelectedDay,
              'not-current-month-date': !isCurrentMonth
            })}
          >
            <div
              className={classNames('cell-label', {
                'today-date': isToday,
                'not-current-month-date': !isCurrentMonth,
                'same-as-selected-label': isSameAsSelected
              })}
            >
              {day}
            </div>
          </div>
        </div>
      </div>
      <div className="staff-events-container">
        <StaffEvents staffEvents={staffEvents} shifts={shifts} />
        {showStaffOnTimeOffCount && (
          <StaffOnTimeOff approvedTimeOffRequestsCounter={approvedTimeOffRequestsCounter}></StaffOnTimeOff>
        )}
      </div>
    </div>
  )
}

export default DateCard
