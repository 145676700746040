import { t } from 'i18n'
import { List, Map } from 'immutable'
interface ISwappingShift {
  shiftDaysAvailableToSwapFor: any,
  timeService: any,
  dataController: any,
  handleEmptyStateLabel: any,
  requestShiftSwapData: any,
  handleSwappingShiftData: any
}

const SwappingShift = ({
  shiftDaysAvailableToSwapFor,
  timeService,
  dataController,
  handleEmptyStateLabel,
  requestShiftSwapData,
  handleSwappingShiftData
}: ISwappingShift) => {
  const yourShiftDate = requestShiftSwapData.yourShift.get('date')
  const date = timeService.formatDate(yourShiftDate, 'MM/DD/YYYY', 'MM-DD-YYYY')
  const minAvailableShiftDaysToSwapFor = timeService.usDateToMoment(date).startOf('week').format('YYYY-MM-DD')
  const maxAvailableShiftDaysToSwapFor = timeService.usDateToMoment(date).endOf('week').format('YYYY-MM-DD')

  const selectedShift = requestShiftSwapData.yourShift.get('selectedShift')

  const handleSwappingShiftDate = async (dateEvent: any) => {
    const dateValue = dateEvent.target.value
    if (dateValue) {
      const formattedDate = timeService.formatDate(dateValue, 'MM/DD/YYYY', 'MM-DD-YYYY')
      const staffEventId = selectedShift.get('id')
      const selectedDate = timeService.usDateToMoment(formattedDate)
      handleSwappingShiftData(Map({ date: selectedDate.format('MM/DD/YYYY')}))

      const shiftDays = shiftDaysAvailableToSwapFor.get(selectedDate.toISOString())
      const shiftDayIdsToSwapFor = shiftDays?.map((shiftDay: any) => shiftDay.get('id')) || List([])
      if (!shiftDayIdsToSwapFor.size) {
        handleEmptyStateLabel(t('staffCalendar.shiftSwap.emptyStateLabels.shiftSwapDateUnavailable'))
        return
      }
      await dataController.loadStaffAvailableForSwap(staffEventId, shiftDayIdsToSwapFor)
      handleEmptyStateLabel('')
    }
  }

  return (
    <div>
      {selectedShift.size > 0 && (
        <div className="swapping-shift p10 bg-whitesmoke">
          <div className="strong pb10">{t('staffCalendar.shiftSwap.labels.swappingShift')}:</div>
          <div className="row">
            <div className="col-4">
              <sh-datepicker
                onClick={handleSwappingShiftDate}
                label={t('staffCalendar.shiftSwap.labels.selectDate')}
                format="MMDDYYYY"
                past-date-disable
                min={minAvailableShiftDaysToSwapFor}
                max={maxAvailableShiftDaysToSwapFor}
                no-clear
              ></sh-datepicker>
            </div>
            <div className="col-8 pl10">
              <sh-dropdown
                label={t('staffCalendar.shiftSwap.labels.swappingShift')}
                value={selectedShift.get('shiftName')}
                readonly
              ></sh-dropdown>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SwappingShift
