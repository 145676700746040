// const readCalendarDayDataParams = (unitId, startDate, endDate, shiftIds) => {
//   const parameters = {
//     unitId,
//     shiftDaysStartDate: startDate,
//     shiftDaysEndDate: endDate,
//     notesStartDate: startDate,
//     notesEndDate: endDate,

//     openShiftUnitId: unitId,
//     openShiftsStartDate: startDate,
//     openShiftsLimit: 999,

//     shiftOpportunitiesStartDate: startDate,
//     shiftOpportunitiesEndDate: endDate,
//     shiftOpportunitiesLimit: shiftIds.length,
//     shiftOpportunitiesShiftIds: shiftIds,

//     staffEventsLimit: 999,
//     staffEventsStartDate: startDate,
//     staffEventsEndDate: endDate
//   };
//   return parameters;
// };

const staffEventFiltered = (staffEvents, timeService, startDate) => {
  const startPlusOneDayDate = timeService.timeMoment(startDate).add(1, 'day').add(-1, 'minute')
  return staffEvents.filter((item) => {
    const startsAt = timeService.timeMoment(timeService.timeMoment(item['startsAt']).format('YYYY-MM-DD'))
    const endsAt = timeService.timeMoment(timeService.timeMoment(item['endsAt']).format('YYYY-MM-DD'))
    return startsAt.isBetween(startDate, startPlusOneDayDate) || endsAt.isBetween(startDate, startPlusOneDayDate)
  })
}

export {
  //  readCalendarDayDataParams,
  staffEventFiltered
}
