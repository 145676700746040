import { Component } from 'react'
import EquipmentLegacyController from './EquipmentsLegacyController'
import { Modal } from 'Common/components'
import classNames from 'classnames'
import Form from '@humanics/he-react-common/lib/admin/components/Form'

class EquipmentLegacyCreateUpdate extends Component {
  constructor(props) {
    super(props)

    this.controller = new EquipmentLegacyController(this)
    this.state = {
      saving: false,
      visible: true,
      error: ''
    }
  }

  onClose = () => {
    this.setState({ visible: false })
    this.props.history.push('.')
  }

  componentDidMount() {
    ;(async () => {
      const { store } = this.props
      await store.initializeApi()
      await this.controller.expertiseSkillStore.getApi()
      this.controller.loadData()
      this.setState({ apiInitialized: true })
    })()
  }

  componentDidUpdate(prevProps) {
    const { appState, store } = this.props
    const { stateKey } = store
    const { stateKey: stateGroupKey } = this.controller.expertiseSkillStore
    const { appState: prevAppState } = prevProps

    const storeState = appState.get(stateKey)
    const storeGroupState = appState.get(stateGroupKey)
    const prevStoreState = prevAppState.get(stateKey)
    const prevStoreGroupState = prevAppState.get(stateGroupKey)
    const isStoreStateChanged = storeState !== prevStoreState || storeGroupState !== prevStoreGroupState

    if (isStoreStateChanged) {
      const data = this.controller.getEquipments()
      this.setState({ data })
    }
  }

  renderModal({ entityType, inputDefinition, defaultValues, stores, history, appState, title, groupId, okLabel }) {
    return (
      <Modal visible={this.state.visible} onClose={this.onClose} title={title}>
        <div>
          <section className="modal-form">
            <div
              className={classNames('message error', {
                hide: !this.state.error
              })}
            >
              <i className="icon-error" />
              <span>{this.state.error}</span>
              <span
                className="close"
                onClick={(e) => {
                  this.setState({ error: '' })
                }}
              />
            </div>
            <div>
              {this.state.loading ? (
                <div>Loading...</div>
              ) : (
                <Form
                  onSubmit={this.onFormSubmit}
                  customClasses={classNames('form mb0', { 'equipment-form': !!groupId })}
                  entityType={entityType}
                  inputDefinition={inputDefinition}
                  defaultValues={defaultValues}
                  stores={stores}
                  history={history}
                  appState={appState}
                  okLabel={okLabel}
                  disabled
                />
              )}
            </div>
          </section>
        </div>
      </Modal>
    )
  }
}
export default EquipmentLegacyCreateUpdate
