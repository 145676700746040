import { PureComponent } from 'react'
import StickyList from './StickyList'
import { List } from 'immutable'
import VStaff from './VStaff/VStaff'
import VRole from './VRole/VRole'
import VShift from './VShift/VShift'
import VUnit from './VUnit/VUnit'
import Footer from 'Footer'
import { CalendarFilterService, ShiftService } from 'services'

const FOOTER_HEIGHT = 39
const STAFF_HEIGHT = 49
const SHIFT_HEIGHT = 28
const UNIT_HEIGHT = 28
const STAFF_DIVIDER_HEIGHT = 2
const GROUP_HEIGHT = 31

export default class VGrid extends PureComponent {
  static defaultProps = { overscanRowCount: 0 }

  constructor(props, context) {
    super(props, context)

    const raw = this.props.calendar.get('raw')
    const listSize = raw ? raw.size : 0
    const { overscanRowCount } = props

    this.state = {
      listHeight: 600,
      listRowHeight: 50,
      overscanRowCount,
      rowCount: listSize,
      scrollToIndex: undefined,
      showScrollingPlaceholder: false,
      useDynamicRowHeight: false
    }
  }

  componentDidMount() {
    const { resetLoadConfig, loadCalendar, activeDateRange, resetOtherStaffFilter } = this.props
    const isDateRangeReady = activeDateRange.get('isReady') === true
    resetOtherStaffFilter()
    if (isDateRangeReady) {
      loadCalendar()
    }
    resetLoadConfig()
  }

  componentDidUpdate(prevProps) {
    const { activeDateRange, loadCalendar, calendar, resetLoadConfig } = this.props
    const calendarDateRange = calendar.get('dateRange')
    const isDateRangeChanged = calendarDateRange !== activeDateRange
    const isDateRangeReady = activeDateRange.get('isReady') === true

    if (isDateRangeChanged && isDateRangeReady) {
      loadCalendar()
      resetLoadConfig()
    }
  }

  render() {
    const { staffHoursMap, timeService, activeDateRange, date, calendarType } = this.props
    const raw = this.props.getViewModel() || List()

    const listSize = raw ? raw.size : 0
    const { overscanRowCount, scrollToIndex } = this.state
    const props = {
      raw,
      filters: this.props.filters,
      headerHeight: GROUP_HEIGHT + SHIFT_HEIGHT,
      onScroll: this.handleScroll,
      defaultHeight: 500,
      containerStyle: { maxHeight: 'auto' },
      height: this.props.offsetHeight,
      overscanRowCount: overscanRowCount,
      // noRowsRenderer: this.noRowsRenderer,
      rowCount: listSize,
      rowHeight: this.getRowHeightByIndex(raw),
      rowRenderer: this.rowRenderer(raw),
      scrollToIndex: scrollToIndex,
      getRowHeight: this.getRowHeight,
      renderShift: this.renderShift,
      renderRole: this.renderRole,
      renderUnit: this.renderUnit,
      getRole: this.props.getRole,
      getShift: this.props.getShift,
      staffHoursMap,
      timeService,
      activeDateRange,
      usDate: date,
      calendarType
    }

    return <StickyList {...props} />
  }

  handleScroll = ({ scrollTop }) => this.props.updateScrollTop(scrollTop)

  updateShift = (isRole, datum, isShift, isStaff) => {
    const { filters } = this.props
    const showMergedShift = filters.get('showMergedShift')
    if (showMergedShift && isRole) {
      const shifts = datum.get('shifts')
      const calendarFilterService = new CalendarFilterService(filters)
      shifts.forEach((shift) => {
        if (calendarFilterService.isShiftVisible(shift)) {
          this.props.loadShift(shift.get('id'))
        }
      })
    } else if (!showMergedShift && (isShift || isStaff)) {
      const shiftId = datum.get(isShift ? 'id' : 'shiftId')
      this.props.loadShift(shiftId)
    }
  }

  rowRenderer =
    (raw) =>
    ({ index, isScrolling, key, style }) => {
      const datum = this.getDatum(raw, index)
      const kind = datum.get('kind')

      const isStaff = kind === 'staff'
      const isStaffDivider = kind === 'staff-divider'
      const isShift = kind === 'shift'
      const isRole = kind === 'role'
      const isFooter = kind === 'footer'
      const isUnit = kind === 'unit'

      const roleId = datum.get('id')
      if (!isScrolling) {
        //do not load cells while scrolling.
        this.updateShift(isRole, datum, isShift, isStaff)
      }

      const isOtherStaff = roleId === 'secondary-staff'
      if (isOtherStaff) {
        const { calendar, loadOtherStaff, filters } = this.props
        const isOtherStaffLoaded = calendar.get('isOtherStaffLoaded')
        const isCollapsed = filters.getIn(['collapsed', roleId]) === true

        if (!isOtherStaffLoaded && !isCollapsed) {
          loadOtherStaff()
        }
      }

      if (isStaff) {
        return this.renderStaff(datum, style)
      }
      if (isStaffDivider) {
        return this.renderStaffDivider(datum, style)
      }
      if (isUnit) {
        return this.renderUnit(datum, style)
      }
      if (isShift) {
        return this.renderShift(datum, style)
      }
      if (isRole) {
        return this.renderRole(datum, style)
      }
      if (isFooter) {
        const { top, ...rest } = style
        return <Footer key="footer" style={{ ...rest, bottom: 0 }} />
      }
    }

  renderStaff(staff, style) {
    const {
      filters,
      showPopup,
      selection,
      onMouseMoveOverGrid,
      cellsPathsInProcessing,
      updateCellTooltip,
      unitUrlId,
      displayNameAs,
      mode,
      date,
      otherStaffMap,
      facilityShiftsMap,
      notes,
      calendarType,
      timeService,
      monthExtendedActiveDateRange
    } = this.props

    const roleIndex = staff.get('roleIndex')
    const shiftIndex = staff.get('shiftIndex')
    const staffIndex = staff.get('staffIndex')
    const scheduleState = monthExtendedActiveDateRange.get('scheduleState')
    const staffPath = { roleIndex, shiftIndex, staffIndex }

    const onCellEvent = (cellIndex, eventType, meta) => this.props.onCellEvent(staffPath, cellIndex, eventType, meta)

    const onMouseMoveOverGridCell = (event) => {
      const cellIndex = parseInt(event.target.getAttribute('data-cell-index'))
      return onMouseMoveOverGrid(event, roleIndex, shiftIndex, staffIndex, staff, cellIndex)
    }

    const selectedStaffPath = selection.get('staffPath')
    const {
      staffIndex: selectedStaffIndex,
      shiftIndex: selectedShiftIndex,
      roleIndex: selectedRoleIndex
    } = selectedStaffPath

    const isStaffSelected = selectedStaffIndex === staffIndex
    const isShiftSelected = selectedShiftIndex === shiftIndex
    const isRoleSelected = selectedRoleIndex === roleIndex

    const isRowSelected = isRoleSelected && isShiftSelected && isStaffSelected
    const actualSelection = isRowSelected && selection

    const staffId = staff.get('id')
    const indicators = filters.get('indicators')

    return (
      <VStaff
        key={staffId}
        staff={staff}
        style={style}
        showPopup={showPopup}
        indicators={indicators}
        selection={actualSelection}
        scheduleState={scheduleState}
        activeDateRange={monthExtendedActiveDateRange}
        onCellEvent={onCellEvent}
        onMouseMoveOverGridCell={onMouseMoveOverGridCell}
        cellsPathsInProcessing={cellsPathsInProcessing}
        updateCellTooltip={updateCellTooltip}
        unitUrlId={unitUrlId}
        displayNameAs={displayNameAs}
        mode={mode}
        date={date}
        otherStaffMap={otherStaffMap}
        facilityShiftsMap={facilityShiftsMap}
        notes={notes}
        calendarType={calendarType}
        timeService={timeService}
      />
    )
  }

  renderUnit(unit, style) {
    return <VUnit key={unit.get('id')} unit={unit} style={style} />
  }

  renderStaffDivider(datum, style) {
    return <hr className="staff-divider" key={datum.get('key')} style={style} />
  }

  renderShift = (shift, style) => {
    const {
      monthExtendedActiveDateRange,
      hideOpenShiftPopup,
      filters,
      timeService,
      openShiftPopupProps,
      calendarType,
      mode
    } = this.props
    const showMergedShift = filters.get('showMergedShift')
    const roleIndex = shift.get('roleIndex')
    const shiftIndex = shift.get('shiftIndex')
    const shiftId = shift.get('id')
    const isOtherStaff = shift.get('isOtherStaff')

    const shiftService = new ShiftService(shift, timeService)
    const shiftRange = showMergedShift || isOtherStaff ? '' : shiftService.getShiftTime()

    const showOpenShiftPopup = (event, dayIndex) =>
      this.props.showOpenShiftPopup(event, roleIndex, shiftIndex, dayIndex)

    return (
      <VShift
        key={shiftId}
        shift={shift}
        style={style}
        activeDateRange={monthExtendedActiveDateRange}
        showOpenShiftPopup={showOpenShiftPopup}
        hideOpenShiftPopup={hideOpenShiftPopup}
        filters={filters}
        shiftRange={shiftRange}
        openShiftPopupProps={openShiftPopupProps}
        calendarType={calendarType}
        timeService={timeService}
        mode={mode}
      />
    )
  }

  renderRole = (role, style) => {
    const { filters, onChangeViewPreference, unitId, calendarType, monthExtendedActiveDateRange } = this.props
    const roleId = role.get('id')

    return (
      <VRole
        key={roleId}
        role={role}
        style={style}
        activeDateRange={monthExtendedActiveDateRange}
        filters={filters}
        toggleIsCollapsed={() => this.props.toggleIsCollapsed(role)}
        onChangeViewPreference={onChangeViewPreference}
        unitId={unitId}
        calendarType={calendarType}
      />
    )
  }

  // noRowsRenderer = () => {
  //   return <div>No rows</div>;
  // };

  getRowHeightByIndex =
    (raw) =>
    ({ index }) => {
      const row = raw.get(index)
      return this.getRowHeight(row)
    }

  getRowHeight = (row) => {
    if (!row) {
      return 0
    }
    if (row.get('hidden')) {
      return 0
    }

    switch (row.get('kind')) {
      case 'footer':
        return FOOTER_HEIGHT
      case 'staff':
        return STAFF_HEIGHT
      case 'shift':
        return SHIFT_HEIGHT
      case 'unit':
        return UNIT_HEIGHT
      case 'staff-divider':
        return STAFF_DIVIDER_HEIGHT
      case 'role':
        return GROUP_HEIGHT
      default:
        return 0
    }
  }

  getDatum = (raw, index) => {
    return raw.get(index % raw.size)
  }
}
