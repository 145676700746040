import { fromJS } from 'immutable'
import { Sentry } from 'services'
import Facility from '../Facility'
import GQLClient from '@humanics/he-react-common/lib/stores/humanics/graphQL'
import { myFacilityUserQuery, myPermissionsQuery, unitWithUrlQuery } from '../Queries'
import { handleError } from 'services/ErrorHandler'

const trackError = Sentry.trackRequestError

export default class Instance {
  constructor(uri, getSessionValidator) {
    this.uri = uri
    this.revalidateSession = getSessionValidator(this)
    this._initializeGraphQLClient()
  }

  async autoLogin(urlId) {
    const { myPermissions } = await this.gqlClient.query(myPermissionsQuery)
    this._extendAuthorizedInstance(myPermissions)

    await this.selectFacility(urlId)
    await this.selectDefaultUnit()
  }

  authorize = async ({ instanceData, authorizationCode }) => {
    const url = `${this.uri}/api/permissions/authorize`
    const params = {
      body: JSON.stringify({ authorizationCode }),
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(url, params)
    const jsonPromise = response.json()

    if (!response.ok) {
      let error = await jsonPromise
      error.instanceUri = this.uri
      throw error
    }

    const facilities = await jsonPromise
    this._extendAuthorizedInstance({ ...instanceData, facilities })
  }

  extendSession = async ({ instanceData, authorizationCode }) => {
    const url = `${this.uri}/api/permissions/authorize`
    const params = {
      body: JSON.stringify({ authorizationCode }),
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(url, params)
    const jsonPromise = response.json()

    if (!response.ok) {
      let error = await jsonPromise
      error.instanceUri = this.uri
      throw error
    }
  }

  authorizeMFA = async ({ authorizationCode }) => {
    const url = `${this.uri}/api/permissions/authorize`
    const params = {
      body: JSON.stringify({ authorizationCode }),
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(url, params)
    const jsonPromise = response.json()

    if (!response.ok) {
      let error = await jsonPromise
      error.instanceUri = this.uri
      throw error
    }

    const facilities = await jsonPromise
    this._extendAuthorizedInstance({ facilities })
  }

  _getDefaultFacility = (urlId) => {
    const facilities = fromJS(this.facilities)
    if (!facilities || facilities.size === 0) {
      return null
    }

    let facility
    if (urlId) {
      facility = facilities.find((f) => f.get('urlId') === urlId)
    }
    if (!facility) {
      facility = facilities.first()
    }

    return facility
  }

  selectFacility = async (urlId) => {
    const facility = this._getDefaultFacility(urlId)
    if (!facility) {
      return Promise.reject(new Error('noFacility'))
    }

    Sentry.setFacility(facility)

    const scope = facility.get('scope')
    Sentry.setScope(scope)
    this._initializeGraphQLClient(scope)

    const { myFacilityUser: facilityUser } = await this.gqlClient.query(myFacilityUserQuery)

    Sentry.setUser(facilityUser)

    this.facilityUser = facilityUser
    this.facility = facility

    return facility
  }

  async selectDefaultUnit() {
    if (!this.facilityUser) {
      return null
    }

    const { roleIds, unitManagerProfile } = this.facilityUser
    const isStaffOnly = roleIds.length === 1 && roleIds[0] === 'staff'

    if (isStaffOnly) {
      return null
    }

    const { homeUnitId, otherUnitIds } = unitManagerProfile
    const unitId = homeUnitId || otherUnitIds[0]

    if (!unitId) {
      return null
    }

    const { unit } = await this.gqlClient.query(unitWithUrlQuery, { id: unitId })
    this.unit = unit

    return unit
  }

  _initializeGraphQLClient = (scope) => {
    const { uri, revalidateSession } = this
    const gqlUri = `${uri}/api/graphql/v2/master`

    this.gqlClient = GQLClient.buildClient(gqlUri, scope, revalidateSession, handleError, trackError)
  }

  _extendAuthorizedInstance({ id, name, idns, facilities, isInstanceAdministrator }) {
    // const { id, name, idns, facilities, isInstanceAdministrator } = instanceData;

    this.id = id
    this.idns = idns
    this.name = name
    // this.instanceData = instanceData;
    this.isInstanceAdministrator = isInstanceAdministrator
    this.mfaRequired = false
    this.facilities = facilities.map((facility) => Facility(facility, this.uri, isInstanceAdministrator))
  }
}
