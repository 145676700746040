import { PureComponent } from 'react'
import classNames from 'classnames'
import './JobProcessingState.scss'

export default class JobProcessingState extends PureComponent {
  render() {
    const { message, currentState } = this.props
    if (!message || !currentState) {
      return null
    }

    return <div className={classNames(currentState, 'hx-job-processing-state')}>{message}</div>
  }
}
