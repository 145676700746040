import { PureComponent } from 'react'
import SelectFilter from 'Manager/components/SelectFilter'
import './Header.scss'

export default class Header extends PureComponent {
  render() {
    const { /* onChangeShift,*/ onChangeStaff, /* shiftType,*/ staffRole, roles /*, view */ } = this.props

    // const shiftProps = {
    //   ...getShiftDefinition(),
    //   value: shiftType,
    //   setConfig: option => onChangeShift(option)
    // };

    const staffProps = {
      ...getStaffDefinition(roles),
      value: staffRole,
      setConfig: (option) => onChangeStaff(option)
    }

    return (
      <div className="hx-time-off-filter calendar-toolbar bg-alabaster bb1 bt1 row">
        <div className="col col-5">
          {/* <span className="river-bad">
            <SelectFilter {...shiftProps} />
          </span> */}
          <span className="river-bad">
            <SelectFilter {...staffProps} />
          </span>
        </div>
        <div className="col col-2 row align-middle align-center title">
          <h1>Time Off</h1>
        </div>
      </div>
    )
  }
}

const getStaffDefinition = (roles) => {
  const staffRoles = {}

  roles.forEach((role) => {
    const id = role.get('id')
    const name = role.get('name')
    staffRoles[id] = name
  })

  return {
    minWidth: 100,
    name: 'Staff',
    options: {
      all: 'All',
      ...staffRoles
    }
  }
}

// const getShiftDefinition = () => {
//   return {
//     minWidth: 100,
//     name: 'Shift',
//     options: {
//       all: 'All',
//       day: 'Day',
//       night: 'Night'
//     }
//   };
// };
