import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { navigate, paths } from 'Navigation'
import ShiftTypeCreateUpdate from './ShiftTypeCreateUpdate'

export default class ShiftTypeCreate extends ShiftTypeCreateUpdate {
  render() {
    const { isUnitsLoaded } = this.controller
    if (!isUnitsLoaded) {
      return null
    }

    const { store, appState, match } = this.props
    const url = this.props.location.search

    const { unitId } = queryString.parse(url)
    if (!unitId) {
      return <Redirect to={paths.AdminShiftTypes} />
    }

    const timeService = appState.getIn(['context', 'facilityTime'])

    const onFormSubmit = async (event, form) => {
      const { urlId } = match.params
      try {
        await this.controller.createUnitShiftType(unitId, form)
        await this.controller.loadUnits()
        return navigate.to.AdminShiftTypes({ urlId })
      } catch {
        return navigate.to.AdminShiftTypes({ urlId })
      }
    }

    let { required, properties } = store.getInputDefinition('OverrideUnitShiftTypeInput')
    required.forEach((element) => {
      properties[element] = { ...properties[element], required: 'true' }
    })
    const inputDefinitionExtraFields = {
      title: {
        label: 'Title',
        type: 'string',
        inputType: 'string',
        required: true,
        propKey: 'name',
        propText: 'name'
      }
    }
    const inputDefinition = { properties: { ...properties, ...inputDefinitionExtraFields } }
    return this.renderModal(onFormSubmit, inputDefinition, { duration: 60 }, timeService, 'create', 'ShiftType')
  }

  onSubmit = (e, form) => {
    // This is intentional
  }
}
