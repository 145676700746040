// @ts-ignore
import Modal from '@humanics/he-react-common/lib/components/Modal_new'
import { t } from 'i18n'
import { useState } from 'react'
import classNames from 'classnames'

interface IWarningModalProps {
  title: string
  message: string
  hideYesButton?: boolean
  onProceed: () => Promise<void>
  onAbort: () => void
}

export default function WarningModal(props: IWarningModalProps) {
  const { title, message, hideYesButton = false, onProceed, onAbort } = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleYesButtonClick = async () => {
    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)

    await onProceed().finally(() => {
      setIsSubmitting(false)
    })
  }

  return (
    <div className="hx-warning-modal">
      <Modal type="warning">
        <Modal.Title>{title}</Modal.Title>
        <>
          <Modal.Content>{message}</Modal.Content>
          <Modal.Buttons>
            {!hideYesButton && (
              <Modal.OKButton
                label={t('yes')}
                disabled={isSubmitting}
                onClick={handleYesButtonClick}
                className={classNames('button primary', {
                  loading: isSubmitting
                })}
              />
            )}
            {!isSubmitting && <Modal.CancelButton label={t('go_back')} onClick={onAbort} />}
          </Modal.Buttons>
        </>
      </Modal>
    </div>
  )
}
