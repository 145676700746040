import { FC, useEffect, useState } from 'react'
import HorizontalBarGraphWithSingleDatasetCard from '../CommonCards/HorizontalBarGraphWithSingleDatasetCard'

interface ITopOvertimeShiftsProps {
  unit: any
  activeDateRange: any
  appState: any
  fetchShiftOvertimeList: any
}

const newOptions = {
  plugins: {
    datalabels: {
      labels: {
        title: {
          font: {
            weight: 'bold'
          }
        }
      },
      formatter: (value: any) => {
        return value + ' hrs'
      }
    }
  }
}

const TopOvertimeShifts: FC<ITopOvertimeShiftsProps> = (props) => {
  const { unit, activeDateRange, appState, fetchShiftOvertimeList } = props
  const [isLoading, setIsLoading] = useState(true)
  const [shiftNames, setShiftNames] = useState<string[]>([])
  const [shiftOvertimeCounts, setShiftOvertimeCounts] = useState<number[]>([])
  const [noContentLabel, setNoContentLabel] = useState('No shifts found with overtime hours for the given schedule')
  const unitId = unit?.get('id')
  const isUnitReady = unit?.get('isReady')
  const areSchedulesLoaded = unit?.get('isSchedulesLoaded')

  useEffect(() => {
    const fetchData = async () => {
      if (!unitId || !isUnitReady || !activeDateRange?.get('isReady')) {
        return
      }

      setIsLoading(true)

      if (!areSchedulesLoaded) {
        return
      }

      try {
        const shiftOvertimeMap = appState.getIn(['calendar', 'shiftOvertimeMap'])
        const [newShiftNames, newShiftOvertimeCounts] = fetchShiftOvertimeList(unit, shiftOvertimeMap)

        setShiftNames(newShiftNames)
        setShiftOvertimeCounts(newShiftOvertimeCounts)

        setIsLoading(false)
      } catch (error) {
        console.error('Failed to load staff overtime hours by shift:', error)
        setNoContentLabel('Error loading card data')
      }
    }

    fetchData()
  }, [activeDateRange, isUnitReady, areSchedulesLoaded, unitId, unit, appState, fetchShiftOvertimeList])

  return (
    <>
      <HorizontalBarGraphWithSingleDatasetCard
        noContentLabel={noContentLabel}
        isLoading={isLoading}
        title="Top Overtime Shifts"
        labels={shiftNames}
        dataset={{
          data: shiftOvertimeCounts,
          backgroundColor: ['#1E3F66', '#2E5984', '#528AAE', '#73A5C6', '#BCD2E8']
        }}
        barsOnFocus={5}
        loadingSpinnerText="Loading..."
        extraOptions={newOptions}
      />
    </>
  )
}

export default TopOvertimeShifts
