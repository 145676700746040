import { PureComponent, createRef } from 'react'
import { times, pick } from 'lodash'
import Cell from './Cell'
import TimeOffRequest from './TimeOffRequest'
import './Cells.scss'

export default class Cells extends PureComponent {
  constructor(props) {
    super(props)
    this.ref = createRef()
  }

  render() {
    const { staff } = this.props

    const isTimeOffsLoaded = staff.get('isTimeOffsLoaded')
    const timeOffRequests = staff.get('timeOffRequests')
    const cells = staff.get('cells')

    const weeksCount = cells.size / 7
    const width = 100 / weeksCount
    const style = { width: `${width}%` }
    const cellsRowStyle = isTimeOffsLoaded && this.ref.current ? {} : { opacity: '0.2' }

    return (
      <section className="col-10 relative bg-white _cells bl4">
        <div className="relative" ref={this.ref}>
          <div className="row relative" style={cellsRowStyle}>
            {times(weeksCount, (weekIndex) => {
              return (
                <div style={style} key={weekIndex} className="_weekWrapper br4">
                  <div className="_weekCells row-7">
                    {times(7, (dayIndex) => this._renderCell(dayIndex, weekIndex))}
                  </div>
                </div>
              )
            })}
          </div>

          {timeOffRequests.map(this._renderTimeOffRequest)}
        </div>
      </section>
    )
  }

  _renderCell = (dayIndex, weekIndex) => {
    const { staff, selection, showPopup, onCellEvent } = this.props

    const cells = staff.get('cells')
    const cellIndex = weekIndex * 7 + dayIndex
    let cell = cells.get(cellIndex)
    const isCellSelected = selection?.getIn(['cells', cellIndex])
    if (isCellSelected) {
      const cells = selection?.get('cells')

      const isPreviousCellSelected = cells.get(cellIndex - 1)
      const isNextCellSelected = cells.get(cellIndex + 1)
      const lastSelectedCellIndex = cells.findLastKey(() => true)
      const isLastSelectedCell = lastSelectedCellIndex === cellIndex

      cell = cell.set('isSelected', true)

      if (isPreviousCellSelected) {
        cell = cell.set('isPreviousSelected', true)
      }
      if (isNextCellSelected) {
        cell = cell.set('isNextSelected', true)
      }
      if (isLastSelectedCell) {
        cell = cell.set('isLastSelectedCell', true)
      }
    }
    const isMultipleCellsSelected = selection && selection?.get('cells')?.size > 1

    const cellProps = { cell, isMultipleCellsSelected, showPopup, onCellEvent }

    return <Cell {...cellProps} key={cellIndex} />
  }

  _renderTimeOffRequest = (timeOffRequest, index) => {
    const { staff, selection } = this.props
    const staffFullName = staff.get('fullName')
    const isTimeOffsLoaded = staff.get('isTimeOffsLoaded')
    const onMouseLeaveTimeOffRequest = (event) => this._handleTimeOffRequestEvent(event, 'onMouseLeave')
    const onMouseEnterTimeOffRequest = (event) => this._handleTimeOffRequestEvent(event, 'onMouseEnter')
    const selectedTimeOffRequestId = selection?.get('timeOffRequestId')
    const timeOffRequestId = timeOffRequest.get('id')
    const isTimeOffRequstSelected = selectedTimeOffRequestId === timeOffRequestId
    const rowWidth = this.ref.current ? this.ref.current.offsetWidth : 0

    if (isTimeOffRequstSelected) {
      timeOffRequest = timeOffRequest.set('isSelected', true)
    }

    const timeOffRequestParams = pick(
      {
        ...this.props,
        rowWidth,
        staffFullName,
        timeOffRequest,
        isTimeOffsLoaded,
        onMouseLeaveTimeOffRequest,
        onMouseEnterTimeOffRequest
      },
      [
        'days',
        'rowWidth',
        'showPopup',
        'timeService',
        'timeOffRequest',
        'staffFullName',
        'isTimeOffsLoaded',
        'onMouseEnterTimeOffRequest',
        'onMouseLeaveTimeOffRequest'
      ]
    )

    return <TimeOffRequest {...timeOffRequestParams} key={index} />
  }

  _handleTimeOffRequestEvent = (event, eventType) => {
    if (!event.currentTarget.hasAttribute('data-time-off-request-id')) {
      return
    }

    const availableEventTypes = ['onMouseEnter', 'onMouseLeave']
    if (!availableEventTypes.includes(eventType)) {
      return
    }
    event.preventDefault()

    const timeOffRequestId = event.currentTarget.getAttribute('data-time-off-request-id')
    const { currentTarget: targetElement } = event
    const eventInfo = { targetElement }
    const meta = { eventInfo }

    this.props.onTimeOffRequestEvent(timeOffRequestId, eventType, meta)
  }
}
