export function multilineWordedStringSplit(str: string, maxCharsPerLine: number) {
  const words = str.split(' ')
  const lines: string[] = ['']
  words.forEach((word) => {
    const lineWithWord = lines.at(-1) + ' ' + word
    if (lineWithWord.length > maxCharsPerLine) {
      lines.push(word)
    } else {
      lines[lines.length - 1] = lineWithWord
    }
  })
  return lines
}
