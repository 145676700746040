import StateController from './StateController'

export default class ViewController extends StateController {
  getRole = (roleIndex) => {
    return this.calendar.getRole({ roleIndex })
  }

  getShift = ({ roleIndex, shiftIndex }) => {
    return this.calendar.getShift({ roleIndex, shiftIndex })
  }
}
