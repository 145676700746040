import { Component } from 'react'
import TableRow from './CrudCardTableRow'
import { withSeparators } from '../utils'
import './CrudCardTable.scss'

class Table extends Component {
  render() {
    const { columns, rows, formatRowItem, formatHeaderItem, emptyTableMessage, addSeparator, separatorColor } =
      this.props
    const tableContents = rows.map((row, index) => (
      <TableRow
        key={row.id || index}
        id={row.id || index}
        data={row}
        slots={columns}
        isHeader={index === 0}
        formatRowItem={formatRowItem}
        formatHeaderItem={formatHeaderItem}
      />
    ))

    return (
      <div className="crud-card-table">
        {addSeparator ? withSeparators(tableContents, separatorColor) : tableContents}
        {(!rows || rows.length <= 1) && (
          <div className="crud-card-table-row">
            <sh-text size="body-1" color="primary">
              {emptyTableMessage}
            </sh-text>
          </div>
        )}
      </div>
    )
  }
}

export default Table
