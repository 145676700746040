import { notesQuery } from '../Queries'
import { updateNotesByIdsMutation } from '../Mutations'

function Notes() {
  let gqlClient = null
  const actions = { readNotes, updateNotes }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  function updateNotes(ids, note, options) {
    if (note.text === undefined) {
      note.text = ''
    }

    return gqlClient.mutate(updateNotesByIdsMutation, { ids, note }, options)
  }

  async function readNotes(ids) {
    const { notesByIds: notes } = await gqlClient.query(notesQuery, { ids })

    return notes
  }
}

export default Notes()
