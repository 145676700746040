import { List, Map } from 'immutable'
import { TimeOffCalendar } from 'entityWrappers'
import StateController from './StateController'

export default class DataController extends StateController {
  constructor(component) {
    super(component)
    this.loadConfig = Map({
      loadedDateRangeIndexes: List(),
      dateRangeIndexes: List(),
      timeout: null
    })
  }

  _setLoadConfig(config) {
    this.loadConfig = this.loadConfig.merge(Map(config))
  }

  resetLoadConfig = (dateRangeIndexes = List()) => {
    const timeout = this.loadConfig.get('timeout')
    if (timeout) {
      clearTimeout(timeout)
    }

    const loadedDateRangeIndexes = this.loadConfig.get('loadedDateRangeIndexes').concat(dateRangeIndexes)

    this._setLoadConfig({
      timeout: null,
      dateRangeIndexes: List(),
      loadedDateRangeIndexes
    })
  }

  loadDataOnScroll = (rowsCount, lastRenderedRowIndex) => {
    const { allTimeOffRequestsLoaded, isTimeOffRequestsLoading } = this.timeOffCalendar
    if (allTimeOffRequestsLoaded || isTimeOffRequestsLoading) {
      return
    }

    // Load based on last rendered row index in react virtualized List:
    const rowsLeftToStartLoading = rowsCount / 4
    const rowsRendered = lastRenderedRowIndex + 1
    const rowsLeftToRender = rowsCount - rowsRendered
    const shouldLoadData = rowsLeftToRender <= rowsLeftToStartLoading

    if (shouldLoadData) {
      this.loadTimeOffRequests()
    }
  }

  onTimeOffCalendarChanged(prevTimeOffCalendar) {
    const { isTimeOffRequestsLoading: prevIsTimeOffRequestsLoading } = prevTimeOffCalendar
    const { isTimeOffRequestsLoading, allTimeOffRequestsLoaded } = this.timeOffCalendar
    const isDataJustLoaded = !isTimeOffRequestsLoading && prevIsTimeOffRequestsLoading

    if (!allTimeOffRequestsLoaded && isDataJustLoaded) {
      const { offsetHeight } = this.state
      const minimalVisibleRowsHeight = offsetHeight + offsetHeight / 2
      const visibleRowsHeight = this._calculateVisibleRowsHeight()

      const shouldLoadData = visibleRowsHeight < minimalVisibleRowsHeight
      if (shouldLoadData) {
        this.loadTimeOffRequests()
      }
    }
  }

  _calculateVisibleRowsHeight() {
    const { filters } = this.props
    const reduceVisibleRows = this.timeOffCalendar.getVisibleRowsReducer(filters)

    return reduceVisibleRows((memo, row) => {
      const rowHeight = this.component.viewModelController._getRowHeight(row)
      return rowHeight + memo
    }, 0)
  }

  resetData() {
    const { timeOffStore } = this.props

    this.setState({ isLoading: true })
    return timeOffStore.resetData()
  }

  async loadInitialData() {
    this.setState({ isLoading: true })
    await this.loadTimeOffRequests()
    this.setState({ isLoading: false })
  }

  _loadDateRangesTimeOffs = () => {
    const { unit, timeOffStore } = this.props
    const unitId = unit.get('id')
    const dateRangeIndexes = this.loadConfig.get('dateRangeIndexes')

    const dateRangesParameters = dateRangeIndexes.reduce((memo, dateRangeIndex) => {
      const dateRange = this.timeOffCalendar.getDateRange(dateRangeIndex)
      const userIds = TimeOffCalendar.getDateRangeUserIds(dateRange)

      return memo.concat({ dateRange, userIds })
    }, [])

    if (dateRangesParameters.length === 0) {
      return
    }

    this.resetLoadConfig(dateRangeIndexes)
    return timeOffStore.loadDateRangesTimeOffs(unitId, dateRangesParameters)
  }

  loadDateRangeTimeOffs = (dateRangeIndex) => {
    const dateRange = this.timeOffCalendar.getDateRange(dateRangeIndex)

    const isLoading = dateRange.get('isTimeOffsLoading')
    const isLoaded = dateRange.get('isTimeOffsLoaded')
    if (isLoading || isLoaded) {
      return
    }

    const timeout = this.loadConfig.get('timeout')
    const dateRangeIndexes = this.loadConfig.get('dateRangeIndexes')
    const loadedDateRangeIndexes = this.loadConfig.get('loadedDateRangeIndexes')

    if (dateRangeIndexes.includes(dateRangeIndex) || loadedDateRangeIndexes.includes(dateRangeIndex)) {
      return
    }
    if (timeout) {
      clearTimeout(timeout)
    }

    const newTimeout = setTimeout(this._loadDateRangesTimeOffs, 50)
    const newDateRangeIndexes = dateRangeIndexes.push(dateRangeIndex)
    this._setLoadConfig({ timeout: newTimeout, dateRangeIndexes: newDateRangeIndexes })
  }

  loadTimeOffRequests() {
    const { timeOffStore } = this.props
    const parameters = this._getNextTimeOffRequestsParameters()
    const { unitId, startDate, endDate, page, shouldLoadSchedules } = parameters

    if (shouldLoadSchedules) {
      return timeOffStore.loadTimeOffRequestsWithSchedules(unitId, startDate, endDate, page)
    }

    return timeOffStore.loadTimeOffRequests(unitId, startDate, endDate, page)
  }

  reloadTimeOffRequests() {
    const { timeOffStore, timeService, appState } = this.props
    const { unitId, endDate } = appState.getIn(['timeOff', 'timeOffRequests', 'pageInfo']).toJS()
    const today = timeService.timeMoment(null).startOf('day')
    const startDate = today.toISOString()
    return timeOffStore.loadTimeOffRequests(unitId, startDate, endDate)
  }

  _getNextTimeOffRequestsParameters() {
    const dateRangDurationInYears = 5

    const { appState, unit } = this.props
    const timeOffRequestsMap = appState.getIn(['timeOff', 'timeOffRequests', 'timeOffRequestsMap'])
    const isTimeOffRequestsLoaded = timeOffRequestsMap.size > 0

    if (!isTimeOffRequestsLoaded) {
      return this._getFirstPageParameters(dateRangDurationInYears)
    }

    const unitId = unit.get('id')
    const pageInfo = appState.getIn(['timeOff', 'timeOffRequests', 'pageInfo'])
    const timeOffRequestsUnitId = pageInfo.get('unitId')

    const isUnitChanged = timeOffRequestsUnitId !== unitId
    if (isUnitChanged) {
      return this._getFirstPageParameters(dateRangDurationInYears)
    }

    const limit = pageInfo.get('limit')
    const offset = pageInfo.get('offset')

    const loadedCount = offset + limit
    const totalCount = pageInfo.get('totalCount')

    const isDateRangeCompleted = loadedCount >= totalCount

    if (!isDateRangeCompleted) {
      return this._getNextPageParameters(pageInfo)
    }

    return this._getNextDateRangeParameters(pageInfo, dateRangDurationInYears)
  }

  _getFirstPageParameters(dateRangDurationInYears) {
    const { timeService, unit } = this.props
    const unitId = unit.get('id')
    const today = timeService.timeMoment(null).startOf('day')
    const startDate = today.toISOString()
    const endDate = today.clone().add(dateRangDurationInYears, 'years').toISOString()

    return { unitId, startDate, endDate, page: 1, shouldLoadSchedules: true }
  }

  _getNextPageParameters(pageInfo) {
    const { unit } = this.props

    const unitId = unit.get('id')

    const startDate = pageInfo.get('startDate')
    const endDate = pageInfo.get('endDate')

    const limit = pageInfo.get('limit')
    const offset = pageInfo.get('offset')
    const page = (offset + limit) / limit + 1

    return { unitId, startDate, endDate, page }
  }

  _getNextDateRangeParameters(pageInfo, dateRangDurationInYears) {
    const { timeService, unit } = this.props

    const unitId = unit.get('id')
    const endDate = pageInfo.get('endDate')

    const nextStartDate = endDate
    const nextEndDate = timeService.timeMoment(nextStartDate).add(dateRangDurationInYears, 'years').toISOString()

    return {
      unitId,
      page: 1,
      endDate: nextEndDate,
      startDate: nextStartDate,
      shouldLoadSchedules: true
    }
  }
}
