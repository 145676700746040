import { Component } from 'react'
import PrintedGrid from './PrintedGrid'
import PrintedNotes from './PrintedNotes'
import PrintedDayView from './PrintedDayView'
import './PrintView.scss'

export default class PrintView extends Component {
  _getShiftIds() {
    let shiftsMap = []
    const roleSize = this.props.calendar.get('roles').size
    if (roleSize !== 0) {
      this.props.calendar.get('roles').forEach((role) => {
        const shiftSize = role.get('shifts').size
        if (shiftSize !== 0) {
          role.get('shifts').forEach((shift) => {
            shiftsMap = [...shiftsMap, shift.get('id')]
          })
        }
      })
    }
    return shiftsMap
  }

  render() {
    const { printStatus, printingFilters } = this.props
    const { isGeneratingPdf, isPrinting, printNotes, view, date } = printStatus
    const shouldRender = isGeneratingPdf || isPrinting

    if (!shouldRender) {
      return null
    }

    const isFullView = view === 'full'
    const isDayView = view === 'day'

    const isNotesVisible = printNotes
    const additionalFilters = printingFilters[view]

    const getViewModel = () => {
      const { getDayViewModel, getViewModelTree, unit } = this.props
      const unitId = unit.get('id')

      return isDayView ? getDayViewModel(unitId, date, additionalFilters) : getViewModelTree(additionalFilters, true)
    }

    const shiftIds = this._getShiftIds()
    shiftIds.forEach((shiftId) => {
      this.props.loadShift(shiftId, true)
    })

    return (
      <div className="hx-print-view" id="hx-print-view" ref="hx-print-view">
        {isFullView && <PrintedGrid {...this.props} additionalFilters={additionalFilters} />}
        {isDayView && <PrintedDayView {...this.props} additionalFilters={additionalFilters} />}
        {isNotesVisible && (
          <PrintedNotes
            {...this.props}
            view={view}
            date={date}
            additionalFilters={additionalFilters}
            getViewModel={getViewModel}
          />
        )}
      </div>
    )
  }
}
