import { PureComponent } from 'react'
import './DayHeader.scss'

export default class DayHeader extends PureComponent {
  render() {
    const { date, timeService } = this.props
    const dateMoment = timeService.timeMoment(date)
    const today = timeService.today
    const isCurrentYear = timeService.isSame(today, dateMoment, 'year')
    const dateFormat = isCurrentYear ? 'ddd, MMM D' : 'ddd, YYYY MMM D'
    const nextDateMoment = dateMoment.clone().add(1, 'day')

    const dateFormatted = dateMoment.format(dateFormat)
    const nextDateFormatted = nextDateMoment.format(dateFormat)

    return (
      <div className="row day-header">
        <aside className="col-2"></aside>
        <div className="col-10">
          <div className="row">
            <div className="col">
              <span>{dateFormatted}</span>
            </div>
            <div className="col">
              <span>{nextDateFormatted}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
