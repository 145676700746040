import { PureComponent, createRef } from 'react'
import ActivitiesNavBar from './ActivitiesNavBar'
import ActivitiesGroup from './ActivitiesGroup'
import TimeJump from './TimeJump'
import { withAppContext } from 'AppContext'
import ActivitiesController from './ActivitiesController'
import { Pagination } from 'Manager/components'
import { findLast, isEqual, keys, reduce } from 'lodash'
import Footer from 'Footer'
import { t } from 'i18n'

const itemsCountPerPage = 25

export class Activities extends PureComponent {
  controller = ActivitiesController(this)

  state = {
    ...this.controller.getDefaultState(),
    activePage: 1,
    scrollEvents: {},
    currentActivityDate: null,
    heightOffset: 0
  }

  contentRef = createRef()

  async componentDidMount() {
    const { appState } = this.props
    const isUnitReady = appState.getIn(['generalData', 'unit', 'isReady'])

    if (isUnitReady) {
      const { endDate, activePage } = this.state
      this.loadActivities(activePage, endDate)
    }

    if (this.contentRef.current) {
      this.contentRef.current.addEventListener('scroll', this.scrollCallback)
    }
    const header = global.document.querySelector('.hx-activities-header')
    const { height = 0 } = header ? header.getBoundingClientRect() : {}
    this.setState({ heightOffset: height })
  }

  componentWillUnmount() {
    this.contentRef.current.removeEventListener('scroll', this.scrollCallback)
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { userSearched, endDate, filter } = this.state
    const { userSearched: prevUserSearched, filter: prevFilter } = prevState

    const isSearchChanged = userSearched !== prevUserSearched
    const isFilterChanged = filter !== prevFilter

    if (isSearchChanged || isFilterChanged) {
      this.loadActivities(1, endDate)
      return
    }

    const isUnitReady = this.props.appState.getIn(['generalData', 'unit', 'isReady'])
    const prevIsUnitReady = prevProps.appState.getIn(['generalData', 'unit', 'isReady'])
    const isUnitJustLoaded = isUnitReady && !prevIsUnitReady
    if (isUnitJustLoaded) {
      this.loadActivities(1, '')
      this.setState({
        ...this.controller.getDefaultState(),
        activePage: 1,
        scrollEvents: {},
        currentActivityDate: null,
        heightOffset: 0
      })
      return
    }

    const activitiesDates = this.contentRef.current.getElementsByClassName('activities-dates')
    const reducer = (scrollEvents, element) => {
      const { top } = element.getBoundingClientRect()
      const { date } = element.dataset
      const { scrollTop } = this.contentRef.current
      scrollEvents[top + scrollTop - this.state.heightOffset] = () => this.updateCurrentActivityDate(date)
      return scrollEvents
    }
    const scrollEvents = reduce(activitiesDates, reducer, {})

    if (!isEqual(keys(prevState.scrollEvents), keys(scrollEvents))) {
      this.setState({ scrollEvents })
      this.updateCurrentActivityDate()
    }
  }

  updateCurrentActivityDate = (currentActivityDate) => {
    if (!currentActivityDate) {
      const { appState } = this.props
      const activities = appState.getIn(['activities', 'items'])
      const timeService = appState.getIn(['context', 'facilityTime'])
      const firstActivityDate = activities.keySeq().first() || null
      const momentDate = timeService.timeMoment(firstActivityDate, 'YYYY-MM-DD')

      return this.setState({ currentActivityDate: momentDate.toISOString() })
    }

    if (this.state.currentActivityDate !== currentActivityDate) {
      this.setState({ currentActivityDate: currentActivityDate })
    }
  }

  loadActivities = (page, endDate) => {
    const { activitiesStore, appState } = this.props
    const { filter, userSearched } = this.state
    const unitId = appState.getIn(['generalData', 'unit', 'id'])
    activitiesStore
      .loadActivities(unitId, page, itemsCountPerPage, endDate, userSearched, null, null, filter)
      .then(this.resetScrollPosition)
    this.setState({ activePage: page, endDate })
  }

  onChangePage = (activePage) => {
    const { endDate } = this.state
    this.loadActivities(activePage, endDate)
  }

  onChangeTimeFilter = (endDate) => {
    this.loadActivities(1, endDate)
  }

  scrollCallback = () => {
    const scrollTop = this.contentRef.current.scrollTop
    const { scrollEvents } = this.state
    const key = findLast(keys(scrollEvents), (key) => key <= scrollTop)

    if (key) {
      scrollEvents[key]()
    } else {
      this.updateCurrentActivityDate()
    }
  }

  resetScrollPosition = () => {
    if (this.contentRef.current) {
      this.contentRef.current.scrollTop = 0
    }
    this.updateCurrentActivityDate()
  }

  render() {
    const { appState } = this.props
    const { currentActivityDate, activePage } = this.state
    const unit = appState.getIn(['generalData', 'unit'])
    const activities = appState.getIn(['activities', 'items'])
    const pagerData = appState.getIn(['activities', 'pager'])
    const timeService = appState.getIn(['context', 'facilityTime'])

    const paginationProps = {
      activePage,
      totalItemsCount: pagerData.totalCount || 0,
      itemsCountPerPage,
      onChange: this.onChangePage,
      namespace: 'bg-white p30'
    }
    const timeJumpProps = {
      onChangeTimeFilter: this.onChangeTimeFilter,
      timeService,
      currentActivityDate
    }
    const isUnitReady = unit.get('isReady')

    let activitiesContent = activities
      .valueSeq()
      .map((groupedActivities, key) => (
        <ActivitiesGroup
          key={key}
          isUnitLoaded={isUnitReady}
          activities={groupedActivities}
          unit={unit}
          timeService={timeService}
        />
      ))

    if (this.state.userSearched !== '' && activities.size === 0) {
      activitiesContent = <div className="bg-white hx-box-shadow rounded p20 mt10">{t('empty_search_results')}</div>
    }

    return (
      <div className="h100 hx-activities">
        <div className="wrapper-container">
          <header className="hx-activities-header">
            <ActivitiesNavBar controller={this.controller} filters={this.state.filter} />
          </header>
          <section ref={this.contentRef}>
            <div className="activities-content">{activitiesContent}</div>
            <TimeJump {...timeJumpProps} />
            <Pagination {...paginationProps} />
          </section>
        </div>
        <Footer />
      </div>
    )
  }
}

export default withAppContext(Activities)
