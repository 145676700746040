import { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import './SearchInput.scss'

const WAIT_INTERVAL = 1000
const ENTER_KEY = 13

export default class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = { isDirty: false }
    this.inputRef = createRef()
  }

  static propTypes = {
    onChange: PropTypes.func,
    onReset: PropTypes.func,
    value: PropTypes.string,
    id: PropTypes.string,
    classNames: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    iconStyle: PropTypes.object
  }

  static defaultProps = {
    onChange: () => {
      // This is intentional
    },
    onReset: () => {
      // This is intentional
    },
    classNames: '',
    value: '',
    id: '',
    placeholder: 'Search',
    disabled: false,
    iconStyle: { left: '10px', fontSize: '15px' }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.usDate !== this.props.usDate || nextProps.urlId !== this.props.urlId) {
      this.onReset()
    }

    return true
  }

  onChange = debounce(() => {
    const { value } = this.inputRef
    const { isDirty } = this.state
    if (value && !isDirty) {
      this.setState({
        isDirty: true
      })
    } else if (isDirty && !value) {
      this.setState({
        isDirty: false
      })
    }

    this.triggerChange()
  }, WAIT_INTERVAL)

  onKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange()
    }
  }

  triggerChange = () => {
    const { value } = this.inputRef
    this.props.onChange({ target: { value } })
  }

  onReset = () => {
    this.inputRef.value = ''
    this.setState({ isDirty: false })
    if (this.props.onReset) {
      this.props.onReset({ target: { value: '' } })
    }
  }

  render() {
    const { iconStyle } = this.props
    return (
      <div className="hx-search-wrapper">
        <i style={iconStyle} className="icon-zoom" />
        <input
          placeholder={this.props.placeholder}
          type="search"
          onChange={this.onChange}
          className={this.props.classNames}
          id={this.props.id}
          ref={(el) => (this.inputRef = el)}
          disabled={this.props.disabled}
          onKeyDown={this.onKeyDown}
        />
        {this.state.isDirty && <i onClick={this.onReset} className="hx-dirty-search" />}
      </div>
    )
  }
}
