import { t } from 'i18n'
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import RemoteWeScanStaffRequestController from './RemoteWeScanStaffRequestController'
import CancelRemoteWeScanStaffRequest from './modals/CancelRemoteWeScanStaffRequest'
import RemoteWeScanStaffRequestsList from './RemoteWeScanStaffRequestsList'
import './ViewRemoteWeScanStaffRequests.scss'

import { TimeService } from 'services'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'

interface IViewRemoteWeScanStaffRequestsProps {
  openShift: any
  timeService: TimeService
  onDataUpdate?: (requests: IRemoteStaffRequest[]) => void
}

const ViewRemoteWeScanStaffRequests = forwardRef(
  (props: IViewRemoteWeScanStaffRequestsProps, ref: Ref<{ reloadData: () => void }>) => {
    const { openShift, timeService, onDataUpdate } = props
    const shiftDayId = openShift?.get('shiftDayId')
    const thisRef = useRef({ props })

    const remoteWeScanStaffRequestControllerRef = useRef(new RemoteWeScanStaffRequestController(thisRef.current))
    const remoteWeScanStaffRequestController = remoteWeScanStaffRequestControllerRef.current

    const [remoteStaffRequestsList, setRemoteStaffRequestsList] = useState<IRemoteStaffRequest[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [showCancellationConfirmationModal, setShowCancellationConfirmationModal] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState<IRemoteStaffRequest | null>(null)

    const handleCancellationAbort = () => {
      setShowCancellationConfirmationModal(false)
    }

    const handleCancelRequestSuccess = () => {
      setShowCancellationConfirmationModal(false)
      loadData()
      setSelectedRequest(null)
    }

    const handleCancelRequestButtonClick = (request: IRemoteStaffRequest) => {
      setSelectedRequest(request)
      setShowCancellationConfirmationModal(true)
    }

    useImperativeHandle(ref, () => ({
      reloadData() {
        loadData()
      }
    }))

    const loadData = useCallback(() => {
      setIsLoading(true)
      setHasError(false)

      remoteWeScanStaffRequestController
        .getRemoteStaffRequests(shiftDayId)
        .then((remoteStaffRequests: IRemoteStaffRequest[]) => {
          setRemoteStaffRequestsList(remoteStaffRequests)
          setHasError(false)

          if (onDataUpdate) {
            onDataUpdate(remoteStaffRequests)
          }
        })
        .catch(() => {
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, [remoteWeScanStaffRequestController, shiftDayId, onDataUpdate])

    useEffect(() => {
      if (!shiftDayId) {
        return
      }

      loadData()
    }, [shiftDayId, loadData])

    if (isLoading) {
      return (
        <div className="hx-remote-staff-requests-table bg-white">
          <div className="p30 mb30">
            <sh-spinner></sh-spinner>
          </div>
        </div>
      )
    }

    if (hasError) {
      return (
        <div className="hx-remote-staff-requests-table bg-white">
          <div className="p10 mb10">
            <sh-empty-state
              icon="error"
              label={t('equipments.view_remote_wescan_requests.error_loading_data')}
            ></sh-empty-state>
          </div>
        </div>
      )
    }

    if (!remoteStaffRequestsList?.length) {
      return (
        <div className="hx-remote-staff-requests-table bg-white">
          <div className="p10 mb10">
            <sh-empty-state
              icon="remote-service-ongoing"
              label={t('equipments.view_remote_wescan_requests.no_requests_scheduled')}
            ></sh-empty-state>
          </div>
        </div>
      )
    }

    return (
      <>
        {showCancellationConfirmationModal && (
          <CancelRemoteWeScanStaffRequest
            remoteStaffRequest={selectedRequest!}
            onCancelAbort={handleCancellationAbort}
            onCancelSuccess={handleCancelRequestSuccess}
            timeService={timeService}
          />
        )}
        <div className="hx-remote-staff-requests-table bg-white">
          <header className="row">
            <div className="upper black gulf-blue">
              {t('equipments.view_remote_wescan_requests.header_columns.service_type_text')}
            </div>
            <div className="upper black gulf-blue">
              {t('equipments.view_remote_wescan_requests.header_columns.procedures_text')}
            </div>
            <div className="upper black gulf-blue">
              {t('equipments.view_remote_wescan_requests.header_columns.state_text')}
            </div>
            <div className="upper black gulf-blue">
              {t('equipments.view_remote_wescan_requests.header_columns.appointments_text')}
            </div>
            <div className="upper black gulf-blue">
              {t('equipments.view_remote_wescan_requests.header_columns.requested_by_text')}
            </div>
            <div className="upper black gulf-blue">
              {t('equipments.view_remote_wescan_requests.header_columns.actions_text')}
            </div>
          </header>
          <section className="hx-remote-staff-list">
            <RemoteWeScanStaffRequestsList
              remoteStaffRequests={remoteStaffRequestsList}
              remoteWeScanStaffRequestController={remoteWeScanStaffRequestController}
              timeService={timeService}
              handleCancelRequestButtonClick={handleCancelRequestButtonClick}
            />
          </section>
        </div>
      </>
    )
  }
)

export default ViewRemoteWeScanStaffRequests
