import { indexConstraintsQuery } from './Queries'
import { updateConstraintMutation } from './Mutation'

export function ConstraintStore() {
  let gqlClient = null
  const actions = {
    readConstraints,
    updateConstraint
  }

  return { initialize, ...actions }

  function initialize(state, context) {
    ;({ gqlClient } = context)
    return state
  }

  async function readConstraints(unitId) {
    const { constraints } = await gqlClient.query(indexConstraintsQuery, { unitId })
    return constraints
  }

  async function updateConstraint(constraint) {
    const updateConstraintInput = {
      name: constraint.name,
      type: constraint.type,
      unitId: constraint.unitId,
      description: constraint.description,
      enabled: constraint.enabled,
      weight: constraint.weight
    }
    const { constraints } = await gqlClient.query(updateConstraintMutation, {
      id: constraint.id,
      constraint: updateConstraintInput
    })
    return constraints
  }
}
