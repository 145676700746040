import { useEffect, useState } from 'react'
import DonutBarGraphCard from '../CommonCards/DonutBarGraphCard'
import { Map } from 'immutable'

export interface IStaffingInfoProps {
  readonly loadCardData: Function
  readonly unit: Map<string, any>
  readonly activeDateRange: Map<string, any>
}

export interface ICardData {
  donutGraphData: { understaffedShiftCount: number; staffedShiftCount: number; overstaffedShiftCount: number }
  barGraphRequiredStaffData: number[]
  barGraphAssignedStaffData: number[]
  barGraphLabels: string[]
}

enum StaffingTypeColors {
  understaffed = '#3DC3B0',
  staffed = '#8F8F8F',
  overstaffed = '#006ADD'
}

export default function StaffingInfo({ loadCardData, unit, activeDateRange }: IStaffingInfoProps) {
  const [cardData, setCardData] = useState<ICardData>({
    donutGraphData: { understaffedShiftCount: 0, staffedShiftCount: 0, overstaffedShiftCount: 0 },
    barGraphRequiredStaffData: [],
    barGraphAssignedStaffData: [],
    barGraphLabels: []
  })
  const [isLoading, setIsLoading] = useState(true)
  const unitId = unit.get('id')
  const areLoadParamsReady = unit.get('isReady') && activeDateRange.get('isReady')
  useEffect(() => {
    const loadData = async () => {
      const cardNewData = await loadCardData(unitId, activeDateRange)
      setCardData(cardNewData)
      setIsLoading(false)
    }
    if (areLoadParamsReady) {
      setIsLoading(true)
      loadData()
    }
  }, [loadCardData, unitId, activeDateRange, areLoadParamsReady])

  const { donutGraphData, barGraphRequiredStaffData, barGraphAssignedStaffData, barGraphLabels } = cardData

  const barGraphColors = barGraphAssignedStaffData.map((d, i) => {
    if (d > barGraphRequiredStaffData[i]) {
      return StaffingTypeColors.overstaffed
    } else if (d < barGraphRequiredStaffData[i]) {
      return StaffingTypeColors.understaffed
    } else {
      return StaffingTypeColors.staffed
    }
  })

  return (
    <DonutBarGraphCard
      title={'Staffing Targets'}
      isLoading={isLoading}
      noContentLabel={'No shifts found in this unit with open schedules'}
      height={320}
      donutLabels={['Understaffed', 'On target', 'Overstaffed']}
      donutDataset={{
        data: [
          donutGraphData.understaffedShiftCount,
          donutGraphData.staffedShiftCount,
          donutGraphData.overstaffedShiftCount
        ],
        backgroundColor: [StaffingTypeColors.understaffed, StaffingTypeColors.staffed, StaffingTypeColors.overstaffed]
      }}
      barDatasets={[
        {
          label: 'Target',
          data: barGraphRequiredStaffData
        },
        {
          label: 'Assigned',
          data: barGraphAssignedStaffData,
          backgroundColor: barGraphColors
        }
      ]}
      barLabels={barGraphLabels}
      extraOptions={{
        bar: {
          plugins: {
            title: {
              text: 'Staffing by shift'
            },
            tooltip: {
              callbacks: {
                title: () => null
              }
            }
          }
        }
      }}
    />
  )
}
