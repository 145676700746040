import { updateStaffHoursMap } from './UpdateCalendarData'
import { staffAssignedHoursQuery } from '../Queries'
import { GQLDataLoader } from 'utils'

export default LoadStaffHours()

function LoadStaffHours() {
  let gqlClient = null
  let updateCalendar = null

  return { initialize, actions: { loadStaffHours } }

  function initialize(context) {
    ;({ updateCalendar, gqlClient } = context)
  }

  async function loadStaffHours(parameters) {
    const { unitId, userIds, startDate, endDate } = parameters
    const params = { unitId, userIds, startDate, endDate }
    const loader = new GQLDataLoader(gqlClient)
    const staffHours = await loader.loadByChunks(
      'staffAssignedHoursByDateRange',
      staffAssignedHoursQuery,
      params,
      'userIds'
    )

    return updateCalendar((calendar) => updateStaffHoursMap(calendar, staffHours))
  }
}
