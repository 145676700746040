import { ComponentController } from 'Common/components'
import { curry } from 'i18n'
const t = curry('calendar.autoSchedule.')

export default class AlgoScheduleController extends ComponentController {
  async generateSchedule(userSelectedConstraints) {
    const { algoScheduleStore } = this.props
    let task_id
    this.setState({
      isLoading: true,
      spinnerAttributes: { label: '' },
      spinnerLabel: t('closeModalWhileGeneratingSchedule')
    })
    const generateScheduleInput = this._getGenerateScheduleInput(userSelectedConstraints)
    try {
      task_id = await algoScheduleStore.generateSchedule(generateScheduleInput)
    } catch (e) {
      // Do nothing
    }
    return task_id
  }

  async updateAlgoSchedule(id, status) {
    const { algoScheduleStore } = this.props
    const { updateAlgoSchedule } = await algoScheduleStore.updateAlgoSchedule(id, status)
    return updateAlgoSchedule.id
  }

  _getGenerateScheduleInput(userSelectedConstraints) {
    const { unit } = this.props
    const schedule = unit.get('schedule')
    return {
      scheduleId: schedule.get('id'),
      unitId: schedule.get('unitId'),
      endsAt: schedule.get('endsAt'),
      startsAt: schedule.get('startsAt'),
      constraints: this._updateConstraints(userSelectedConstraints)
    }
  }

  _updateConstraints(userSelectedConstraints) {
    return userSelectedConstraints.map((constraint, index) => {
      delete constraint.type
      delete constraint.active
      const updatedConstraint = {
        constraintId: constraint.id
      }
      if (window.REACT_APP_RANKING_ENABLED_FOR_ALGO_SCHEDULE === 'true') {
        updatedConstraint.priority = index + 1
      }
      return updatedConstraint
    })
  }
}
