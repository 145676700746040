import { List, Map } from 'immutable'
import { TimeOffCalendar } from 'entityWrappers'
import StateController from './StateController'
import { DateRangeService, FacilityUserShiftsService } from 'services'

export default class DataModelController extends StateController {
  get defaultState() {
    return { timeOffCalendar: this.buildTimeOffCalendar() }
  }

  buildTimeOffCalendar = () => {
    const dataModel = this._getDataModel()
    return new TimeOffCalendar(dataModel)
  }

  rebuildDataModel() {
    this.setState({ timeOffCalendar: this.buildTimeOffCalendar() })
  }

  _getDataModel = () => {
    const { appState, unit, timeService, activeDateRange } = this.props
    const timeOff = appState.get('timeOff')
    const schedules = timeOff.get('schedulesMap').valueSeq() || List([unit.get('schedule')])
    const timeOffRequests = timeOff.getIn(['timeOffRequests', 'timeOffRequestsMap']).valueSeq()
    const pageInfo = timeOff.getIn(['timeOffRequests', 'pageInfo'])

    const isDataLoaded = this._isDataLoaded()
    if (!isDataLoaded) {
      return Map({
        dateRanges: Map(),
        isTimeOffsLoading: true,
        isTimeOffRequestsLoading: true,
        allTimeOffRequestsLoaded: false
      })
    }

    const lastLoadedCount = pageInfo.get('count')

    const unitConfig = unit.set('schedules', schedules)
    const usDate = activeDateRange.get('usDate')
    const dateRangeService = new DateRangeService(unitConfig, timeService, usDate)

    const dateRanges = timeOffRequests.reduce((dateRangesMemo, timeOffRequest) => {
      const dateRanges = this._buildDateRangesForTimeOffRequest(dateRangeService, timeOffRequest, dateRangesMemo)

      return dateRanges.reduce((memo, dateRange) => {
        const index = dateRange.get('index')
        return memo.set(index, dateRange)
      }, dateRangesMemo)
    }, Map())

    const dateRangesSorted = this._sortDateRanges(dateRanges)
    const isTimeOffRequestsLoading = pageInfo.get('isLoading')

    return Map({
      isTimeOffRequestsLoading,
      dateRanges: dateRangesSorted,
      allTimeOffRequestsLoaded: lastLoadedCount === 0
    })
  }

  _sortDateRanges(dateRanges) {
    return dateRanges
      .sortBy((dateRange) => dateRange.get('index'))
      .map((dateRange, dateRangeIndex) => {
        const rolesSorted = dateRange
          .get('roles')
          .sortBy((role) => role.get('id'))
          .map((role, roleIndex) => {
            const shiftsSorted = role
              .get('shifts')
              .sortBy((shift) => shift.get('id'))
              .map((shift, shiftIndex) => {
                const staffSorted = shift
                  .get('staff')
                  .sortBy((staff) => staff.get('id'))
                  .map((staff, staffIndex) =>
                    staff.merge({
                      dateRangeIndex,
                      roleIndex,
                      shiftIndex,
                      staffIndex
                    })
                  )
                return shift.merge({
                  staff: staffSorted,
                  dateRangeIndex,
                  roleIndex,
                  shiftIndex
                })
              })

            return role.merge({ shifts: shiftsSorted, dateRangeIndex, roleIndex })
          })

        return dateRange.merge({ roles: rolesSorted })
      })
  }

  _buildDateRangesForTimeOffRequest(dateRangeService, timeOffRequest, dateRangesMemo) {
    const { timeService } = this.props
    const timeOffRequestStartDate = timeOffRequest.get('dates').first()?.get('date')
    const timeOffRequestEndDate = timeOffRequest.get('dates').last()?.get('date')

    const timeOffRequestStartDateMoment = timeService.timeMoment(timeOffRequestStartDate)
    const timeOffRequestEndDateMoment = timeService.timeMoment(timeOffRequestEndDate)

    const timeOffRequestStartUsDate = timeOffRequestStartDateMoment.format('MM-DD-YYYY')
    const timeOffRequestEndUsDate = timeOffRequestEndDateMoment.format('MM-DD-YYYY')

    const startDateScheduleIndex = dateRangeService.getDateRangeIndex(timeOffRequestStartUsDate)
    const endDateScheduleIndex = dateRangeService.getDateRangeIndex(timeOffRequestEndUsDate)

    const startDateRange =
      dateRangesMemo.get(startDateScheduleIndex) ||
      dateRangeService.buildDateRangeForScheduleIndex(startDateScheduleIndex, timeOffRequestStartUsDate)
    const isInOneDateRange = startDateScheduleIndex === endDateScheduleIndex
    const dataForStartDate = { isInOneDateRange, dateRange: startDateRange }
    const timeOffRequestForStartDateRange = this._extendTimeOffRequest(timeOffRequest, dataForStartDate)
    const startDateRangeExtended = this._extendDateRange(startDateRange, timeOffRequestForStartDateRange)

    if (isInOneDateRange) {
      return [startDateRangeExtended]
    }

    const endDateRange =
      dateRangesMemo.get(endDateScheduleIndex) ||
      dateRangeService.buildDateRangeForScheduleIndex(endDateScheduleIndex, timeOffRequestEndUsDate)
    const dataForEndDate = { isInOneDateRange, dateRange: endDateRange }
    const timeOffRequestForEndDateRange = this._extendTimeOffRequest(timeOffRequest, dataForEndDate)
    const endDateRangeExtended = this._extendDateRange(endDateRange, timeOffRequestForEndDateRange)

    return [startDateRangeExtended, endDateRangeExtended]
  }

  _extendTimeOffRequest(timeOffRequest, { isInOneDateRange, dateRange }) {
    const { timeService } = this.props
    const dateRangeStartsAt = dateRange.get('startsAt')
    const dateRangeEndsAt = dateRange.get('endsAt')
    const days = dateRange.get('days')
    const dateRangeDaysCount = days.length

    const datesInDateRange = isInOneDateRange
      ? timeOffRequest.get('dates')
      : timeOffRequest
          .get('dates')
          .filter((dateObject) =>
            timeService.timeMoment(dateObject.get('date')).isBetween(dateRangeStartsAt, dateRangeEndsAt, 'days', '[)')
          )

    return timeOffRequest.merge({
      dateRangeEndsAt,
      isInOneDateRange,
      datesInDateRange,
      dateRangeStartsAt,
      dateRangeDaysCount
    })
  }

  _extendDateRange(dateRange, timeOffRequest) {
    const { timeService, unit, appState } = this.props
    const staffMap = appState.getIn(['timeOff', 'staff', 'staffMap'])
    const timeOffsMap = appState.getIn(['timeOff', 'timeOffsMap'])
    const shifts = unit.get('shifts')
    const roles = unit.get('roles')

    const unitId = unit.get('id')
    const userId = timeOffRequest.get('userId')
    const staff = staffMap.get(userId)

    if (!staff) {
      const roles = dateRange.get('roles')
      return roles ? dateRange : dateRange.set('roles', List())
    }

    const eligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits'])

    const facilityUserShiftsService = new FacilityUserShiftsService(timeService)
    const primaryShiftId = facilityUserShiftsService.getClosestPrimaryShiftId(unitId, eligibleUnits, shifts)
    const shift = shifts.find((shift) => shift.get('id') === primaryShiftId)
    const roleId = shift.get('unitRoleId')

    const dateRangeRoles = dateRange.get('roles') || List()
    const roleIndex = dateRangeRoles?.findIndex((role) => role.get('id') === roleId)
    const hasRole = roleIndex > -1

    const role = hasRole ? dateRange.getIn(['roles', roleIndex]) : roles.find((role) => role.get('id') === roleId)

    const dateRangeIndex = dateRange.get('index')
    const dateRangeTimeOffs = timeOffsMap.get(dateRangeIndex)
    const isTimeOffsLoading = dateRangeTimeOffs?.get('isLoading')
    const isTimeOffsLoaded = !!dateRangeTimeOffs && !dateRangeTimeOffs.get('isLoading')

    const data = { shift, staff, timeOffRequest, dateRangeTimeOffs }
    const days = dateRange.get('days')
    const dateRangeData = { days, isTimeOffsLoaded }
    const roleExtended = this._extendRole(role, dateRangeData, data)

    const newRoles = hasRole ? dateRangeRoles.set(roleIndex, roleExtended) : dateRangeRoles.push(roleExtended)

    return dateRange.merge({
      isTimeOffsLoaded,
      isTimeOffsLoading,
      roles: newRoles,
      kind: 'date-range'
    })
  }

  _extendRole(role, dateRangeData, data) {
    let { shift, ...rest } = data
    const { days } = dateRangeData
    const roleShifts = role.get('shifts') || List()
    const shiftId = shift.get('id')
    const shiftIndex = roleShifts.findIndex((shift) => shift.get('id') === shiftId)
    const hasShift = shiftIndex > -1

    shift = hasShift ? roleShifts.get(shiftIndex) : shift
    const shiftExtended = this._extendShift(shift, dateRangeData, rest)

    const newRoleShifts = hasShift ? roleShifts.set(shiftIndex, shiftExtended) : roleShifts.push(shiftExtended)

    return role.merge({ days, kind: 'role', shifts: newRoleShifts })
  }

  _extendShift(shift, dateRangeData, data) {
    let { staff, ...rest } = data
    const { days } = dateRangeData
    const shiftStaff = shift.get('staff') || List()
    const userId = staff.get('userId')
    const staffIndex = shiftStaff?.findIndex((staff) => staff.get('userId') === userId)
    const hasStaff = staffIndex > -1

    staff = hasStaff ? shift.getIn(['staff', staffIndex]) : staff

    const staffExtended = this._extendStaff(staff, dateRangeData, rest)

    const newStaff = hasStaff ? shiftStaff.set(staffIndex, staffExtended) : shiftStaff.push(staffExtended)

    return shift.merge({ days, kind: 'shift', staff: newStaff })
  }

  _extendStaff(staff, dateRangeData, data) {
    const { timeOffRequest, dateRangeTimeOffs } = data
    const { days, isTimeOffsLoaded } = dateRangeData
    const userId = staff.get('userId')
    const timeOffRequests = (staff.get('timeOffRequests') || List()).push(timeOffRequest)

    const cells = days.map((day, dayIndex) => {
      const { dateTime } = day
      const timeOff = dateRangeTimeOffs?.getIn([userId, dateTime])

      return Map({
        dateTime,
        timeOff,
        index: day.index,
        isActive: day.isActive,
        isActiveWeek: day.isActiveWeek,
        isPrevDay: day.isPrevDay,
        isToday: day.isToday,
        isWeekend: day.isWeekend,
        isYesterday: day.isYesterday
      })
    })

    return staff.merge({ cells, timeOffRequests, isTimeOffsLoaded, kind: 'staff' })
  }

  _isDataLoaded() {
    const { unit, appState } = this.props
    const timeOff = appState.get('timeOff')
    const schedules = timeOff.get('schedulesMap').valueSeq() || List([unit.get('schedule')])
    const isSchedulesMapLoaded = timeOff.get('isSchedulesMapLoaded')
    const isStaffMapLoaded = timeOff.getIn(['staff', 'isStaffMapLoaded'])
    const timeOffRequests = timeOff.getIn(['timeOffRequests', 'timeOffRequestsMap']).valueSeq()

    const isTimeOffRequestsLoaded =
      timeOff.getIn(['timeOffRequests', 'pageInfo', 'isLoaded']) || timeOffRequests.size > 0
    const isSchedulesLoaded = schedules.size > 0 || isSchedulesMapLoaded

    return isStaffMapLoaded && isSchedulesLoaded && isTimeOffRequestsLoaded
  }
}
