import { t } from 'i18n'
import { isValidEmail, isValidPhoneNumber } from 'Manager/Staff/CreateEditStaffModal/formDefinitions'

import { IWeScanContactInformation } from 'stores/remoteWorkflowStore/models/wescan/weScanContactInformation'
import { ICreateRemoteWeScanStaffInputFormDefinition } from 'stores/remoteWorkflowStore/models/wescan/createRemoteWeScanStaffInputFormDefinition'

function validateContactInformation(fieldValue: IWeScanContactInformation) {
  const errors: Partial<IWeScanContactInformation> = {}
  const requiredError = t('errors.required_field')

  if (!fieldValue?.firstName?.trim()) {
    errors.firstName = requiredError
  }

  if (!fieldValue?.lastName?.trim()) {
    errors.lastName = requiredError
  }

  if (!fieldValue?.email?.trim()) {
    errors.email = requiredError
  } else if (!isValidEmail(fieldValue?.email)) {
    errors.email = t('auth.invalid_email')
  }

  if (!fieldValue?.phone?.trim()) {
    errors.phone = requiredError
  } else if (!isValidPhoneNumber(fieldValue?.phone)) {
    errors.phone = t('auth.invalid_phone')
  }

  return Object.keys(errors).length ? errors : null
}

function validateAvailabilities(fieldValue: number[]) {
  if (!fieldValue?.length) {
    return {
      availabilities: t('errors.select_slot')
    }
  }

  return null
}

const inputDefinition: ICreateRemoteWeScanStaffInputFormDefinition = {
  properties: {
    equipmentName: {
      type: 'string',
      title: 'Equipment',
      disabled: true
    },
    date: {
      type: 'string',
      title: 'Date',
      disabled: true
    },
    startTime: {
      inputType: 'TimePicker',
      title: 'Start Time',
      required: true
    },
    endTime: {
      inputType: 'TimePicker',
      title: 'End Time',
      required: true,
      validateField: (value: number) => null
    },
    serviceType: {
      inputType: 'InputDropdown',
      label: 'Service Type',
      withLabel: true,
      required: true
    },
    procedures: {
      inputType: 'InputDropdown',
      label: 'Procedures',
      withLabel: true,
      required: true,
      propKey: 'name',
      propValue: 'name'
    },
    skillLevel: {
      inputType: 'InputDropdown',
      label: 'Skill Level',
      withLabel: true,
      required: true,
      propKey: 'value',
      propValue: 'name'
    },
    availabilities: {
      title: 'Slot Availability',
      inputType: 'InputSlotAvailability',
      required: true,
      message: 'Please select Service Type and Procedures to view slot availability.',
      validateField: validateAvailabilities
    },
    requestor: {
      title: 'Requestor Details',
      inputType: 'ContactInformation',
      required: true,
      validateField: validateContactInformation
    },
    radiologist: {
      title: 'Radiologist Details',
      inputType: 'ContactInformation',
      required: true,
      validateField: validateContactInformation
    },
    techAide: {
      title: 'Tech Aide Details',
      inputType: 'ContactInformation',
      required: true,
      validateField: validateContactInformation
    }
  }
}

export default inputDefinition
