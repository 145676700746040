import { Component, Fragment } from 'react'
import CustomDateInput from '@humanics/he-react-common/lib/components/Form/CustomDateInput'
import CustomDropDown from '@humanics/he-react-common/lib/components/Form/CustomDropdown'
import StringInputField from '@humanics/he-react-common/lib/components/Form/StringInputField'
import FormActionBar from './FormActionBar'
import './expertiseForm.scss'

const inputComponentSelector = {
  CustomDateInput,
  CustomDropDown,
  StringInputField
}

export default class CreateEditExpertiseForm extends Component {
  constructor(props) {
    super(props)
    this.formController = new props.FormController(this)
    this.state = this.formController.defaultState
  }

  componentDidMount() {
    this.formController.initForm()
  }

  render() {
    const { onClose, expertiseId, extenderLabel } = this.props
    const { isLoading, isValidForm, form, formExtensions } = this.state
    const { onClickSubmit, addFormExtension, allowFormExtension, handleFormExtensionClosing } = this.formController

    const actionBarProps = {
      onCancel: onClose,
      onSubmit: onClickSubmit,
      submitLabel: expertiseId === 'new' ? 'Add' : 'Save',
      isSubmitLoading: isLoading,
      isSubmitDisabled: !isValidForm
    }

    return (
      <form className="form mb0 hx-expertise-form">
        <div className="p2 pb0 row">
          {this.renderFormFields(form)}
          {extenderLabel &&
            formExtensions.map((formExtension, index) => {
              const formFields = this.renderFormFields(formExtension, index)
              return (
                <Fragment key={`${formExtension.field}_${index}`}>
                  <div className="separator" />
                  <div className="col-12 row align-right mt10">
                    <sh-icon icon="cancel" size="xs" button onClick={() => handleFormExtensionClosing(index)} />
                  </div>
                  {formFields}
                </Fragment>
              )
            })}
          {extenderLabel && (
            <div className="form-extender">
              <div className="separator" />
              <sh-button
                label={`+ ${extenderLabel}`}
                color="tertiary"
                size="auto"
                onClick={(e) => addFormExtension()}
                disabled={!allowFormExtension() ? true : undefined}
              />
            </div>
          )}
        </div>

        <footer className="_footer">
          <FormActionBar {...actionBarProps} />
        </footer>
      </form>
    )
  }

  renderFormFields(form, index) {
    const fieldPropsList = Object.keys(form).map((field) => this.buildFieldProps(field, form, index))
    return fieldPropsList.map((fieldProps) => {
      const InputComponent = inputComponentSelector[fieldProps.definition.inputType]
      return <InputComponent key={fieldProps.field} {...fieldProps} />
    })
  }

  buildFieldProps(field, form, index) {
    const { timeService } = this.props
    const { setFieldValue } = this.formController

    const commonProps = {
      ...form[field],
      value: form[field].value,
      propKey: 'key',
      propValue: 'name'
    }

    if (form[field].definition.type === 'date') {
      return {
        ...commonProps,
        timeService,
        onChange: (e) => {
          setFieldValue(e, field, index)
        }
      }
    }
    return {
      ...commonProps,
      onChange: (e) => {
        if (e.target) {
          setFieldValue(e.target.value, field, index)
        }
      }
    }
  }
}
