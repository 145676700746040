import { Component } from 'react'
import { pick } from 'lodash'
import Details from './Details'
import ShiftDetails from './ShiftDetails'

export default class OpenShiftRequestDetails extends Component {
  render() {
    const shiftDetailsProps = pick(this.props, ['openShift', 'openShiftController', 'timeService', 'unit'])

    const detailsProps = pick(this.props, ['openShift', 'openShiftController', 'openShiftParameters'])

    return (
      <div className="hx-open-shift-request-details-container row" data-section-key="header">
        <div className="col-6">
          <ShiftDetails {...shiftDetailsProps} />
        </div>
        <div className="col-6">
          <Details {...detailsProps} />
        </div>
      </div>
    )
  }
}
