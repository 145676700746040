import { Component } from 'react'
import { curry } from 'i18n'
import { navigate } from 'Navigation'
import ExpertiseCard from './ExpertiseCard'
import { getExpertiseInfo } from './utils'

const t = curry('staff.staffExpertiseCard.')

class Licenses extends Component {
  render = () => {
    const createLicensePath = navigate.from.StaffDetails.to.StaffCreateLicense({}, false)
    const editLicensePath = (licenseId) =>
      navigate.from.StaffDetails.to.StaffEditLicense({ expertiseId: licenseId }, false)

    const licenseCardProps = {
      ...this.props,
      cardTitle: t('licenseCardTitle'),
      expertiseType: 'license',
      getExpertiseTitle: this.getLicenseTitle,
      getExpertiseInfo: (expertise) => getExpertiseInfo(expertise, this.props.timeService),
      createExpertisePath: createLicensePath,
      editExpertisePath: editLicensePath,
      isGrouped: false,
      withExpiryIndicator: true,
      isCollapsible: true
    }
    return <ExpertiseCard {...licenseCardProps} />
  }

  getLicenseTitle = (license) => {
    return license.get('fullName')
  }
}

export default Licenses
