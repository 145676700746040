import moment from 'moment'
import { Map } from 'immutable'
import { iterateThroughDateRange } from 'utils'
import { memoize } from 'lodash'

export default class DateRangeService {
  static buildDateRange({
    unitUrlId,
    unitId,
    usDate,
    dateRangeIndex,
    scheduleId,
    scheduleState,
    startsAt,
    endsAt,
    timeService
  }) {
    // This data structure should not have any module specific options
    // (like mode, componentId). In other case it will leads to issues with
    // updating of these options during navigation between modules, as we
    // can navigate between modules while this data is loading and building.
    // Any module specific options should be inside a scope of that module store.
    const usDateISO = timeService.usDateToMoment(usDate).toISOString()
    const usDateMoment = timeService.timeMoment(usDateISO)
    const from = timeService.timeMoment(startsAt)
    const to = timeService.timeMoment(endsAt)
    const toMinuteBefore = to.clone().subtract(1, 'minutes')

    const { todayUsDate } = timeService

    const firstDayUsDate = timeService.timeMoment(startsAt).format('MM-DD-YYYY')

    const today = timeService.today
    const isPast = timeService.isBefore(toMinuteBefore, today, 'day')
    const isFuture = timeService.isBefore(today, from, 'day')

    const isTodayIncluded = timeService.isBetween(today, from, to, null, '[]')

    const isCurrentYear = timeService.isSame(today, from, 'year') && timeService.isSame(today, to, 'year')
    const endYearIsCurrentYear = timeService.isSame(today, to, 'year')
    const dateFormat = isCurrentYear ? 'MMM D' : 'YYYY MMM D'
    const title = `${from.format(dateFormat)} - ${toMinuteBefore.format(dateFormat)}`
    const titleWithoutYear = `${from.format('MMM D')} - ${toMinuteBefore.format('MMM D')}`
    const endYear = toMinuteBefore.format('YYYY')

    const isActive = timeService.isBetween(usDateMoment, from, toMinuteBefore, null, '[]')
    const duration = to.diff(from, 'weeks')
    const durationText = `${duration} weeks`
    const isReady = true

    let state = scheduleState
    if (scheduleState === 'open') {
      state = 'requests open'
    }
    if (scheduleState === 'published' && (isPast || isTodayIncluded)) {
      state = 'active'
    }
    if (!scheduleState) {
      state = 'inactive'
    }

    const days = this.buildDateRangeDays(from, to, timeService, usDateMoment)

    const usDateSundayMoment = usDateMoment.clone().day(0)
    const usDateSaturdayMoment = usDateMoment.clone().day(6)
    const weekEndsAtMoment = usDateMoment.clone().day(7)
    const weekStartsAt = usDateSundayMoment.toISOString()
    const weekEndsAt = weekEndsAtMoment.toISOString()

    const isTodayInUsDateWeek = timeService.isBetween(today, usDateSundayMoment, usDateSaturdayMoment, null, '[]')
    const usDateMomentISO = usDateMoment.toISOString()
    const usDateWeekSunday = usDateSundayMoment.format('MM-DD-YYYY')

    return Map({
      days,
      unitUrlId,
      unitId,
      usDate,
      todayUsDate,
      scheduleId,
      scheduleState,
      startsAt,
      endsAt,
      firstDayUsDate,
      state,
      isPast,
      isFuture,
      isCurrentYear,
      endYearIsCurrentYear,
      title,
      titleWithoutYear,
      endYear,
      isActive,
      duration,
      durationText,
      isReady,
      isTodayIncluded,
      weekStartsAt,
      weekEndsAt,
      usDateWeekSunday,
      isTodayInUsDateWeek,
      usDateMomentISO,
      index: dateRangeIndex
    })
  }

  static buildDateRangeDays(from, to, timeService, usDateMoment) {
    const days = []
    const dateRangeIterator = iterateThroughDateRange(from, to, timeService)
    dateRangeIterator((dayMoment, index) => {
      const isActive = timeService.isSame(usDateMoment, dayMoment, 'day')
      const isActiveWeek = timeService.isSame(usDateMoment, dayMoment, 'week')
      const day = {
        isActiveWeek,
        isActive,
        isWeekend: timeService.isWeekend(dayMoment),
        isToday: timeService.isToday(dayMoment),
        isPrevDay: timeService.isPrevDay(dayMoment),
        isYesterday: timeService.isYesterday(dayMoment),
        dateTime: dayMoment.toISOString(),
        index,
        date: dayMoment.format('YYYY-MM-DD'),
        usDate: dayMoment.format('MM-DD-YYYY'),
        month: dayMoment.format('MMM'),
        dayOfMonth: dayMoment.format('D'),
        dayOfWeekShort: dayMoment.format('ddd'),
        dayOfWeek: dayMoment.format('dddd'),
        monthAndDay: dayMoment.format('MMM D'),
        weekDayMonthAndDay: dayMoment.format('ddd, MMM DD')
      }

      days.push(day)
    })

    return days
  }

  static buildDateRangeWithDuration(dateRange, timeService, durationInWeeks) {
    const dateRangeIndex = dateRange.get('index')
    const scheduleId = dateRange.get('scheduleId')
    const startsAt = dateRange.get('startsAt')
    const scheduleState = dateRange.get('scheduleState')
    const usDate = dateRange.get('usDate')
    const unitId = dateRange.get('unitId')
    const unitUrlId = dateRange.get('unitUrlId')

    const endsAt = timeService.timeMoment(startsAt).add(durationInWeeks, 'weeks').toISOString()

    return this.buildDateRange({
      unitUrlId,
      unitId,
      usDate,
      dateRangeIndex,
      scheduleId,
      scheduleState,
      startsAt,
      endsAt,
      timeService
    })
  }

  static getDurationsForNewSchedules(unit, timeService, dateRangeIndex, duration) {
    const dateRangeService = new this(unit, timeService, '')
    const firstNewScheduleIndex = dateRangeService.schedules.size
    const limit = dateRangeIndex - dateRangeService.schedules.size

    const durations = dateRangeService
      .getDateRanges(firstNewScheduleIndex, limit)
      .map((dateRange) => dateRange.get('duration'))

    durations.push(duration)
    return durations
  }

  static getFirstDayIndexForCurrentWeek(activeDateRange, timeService) {
    const startDate = activeDateRange?.get('usDateWeekSunday')
    const endDate = activeDateRange?.get('firstDayUsDate')

    if (!startDate || !endDate) {
      return 0
    }

    const startDateMoment = timeService?.usDateToMoment(startDate)
    const endDateMoment = timeService?.usDateToMoment(endDate)

    return startDateMoment.diff(endDateMoment, 'days')
  }

  static getMonthExtendedDateRange = memoize((activeDateRange, timeService) => {
    if (!activeDateRange.get('isReady')) {
      return activeDateRange
    }
    const prevSunday = timeService.timeMoment(activeDateRange.get('startsAt')).startOf('week')
    const nextSunday = timeService.timeMoment(activeDateRange.get('endsAt'))

    if (nextSunday.day() !== 0) {
      nextSunday.startOf('week').add(1, 'week')
    }

    const usDateMoment = timeService.timeMoment(activeDateRange.get('usDateMomentISO'))
    const extendedDays = this.buildDateRangeDays(prevSunday, nextSunday, timeService, usDateMoment)
    return activeDateRange.set('days', extendedDays)
  })

  static getActiveDateRangeIndex(activeDateRange, unit, timeService) {
    const index = activeDateRange.get('index')
    if (index >= 0) {
      return index
    }

    const isSchedulesLoaded = unit.get('isSchedulesLoaded')
    if (!isSchedulesLoaded) {
      return null
    }

    const usDate = activeDateRange.get('usDate')
    const dateRangeService = new DateRangeService(unit, timeService, usDate)
    return dateRangeService.getDateRangeIndex(usDate)
  }

  constructor(unit, timeService, usDate) {
    this.usDate = usDate
    this.timeService = timeService
    this.schedules = unit.get('schedules')
    this.unitSchedulingStartsAt = unit.get('schedulingStartsAt')
    this.unitStaffingPeriod = unit.get('staffingPeriod')
    this.unit = unit
    this.unitId = unit.get('id')
    this.unitUrlId = unit.get('urlId')
    this.scheduleType = unit.get('scheduleType')
  }

  range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => i + startAt)
  }

  getActiveDateRange() {
    const schedule = this.unit.get('schedule')
    return this._buildDateRangeForSchedule(schedule)
  }

  getDateRangeForUsDate(usDate) {
    const index = this.getDateRangeIndex(usDate)
    return this.buildDateRangeForScheduleIndex(index, usDate)
  }

  getDateRange() {
    const index = this.getDateRangeIndex(this.usDate)
    return this.buildDateRangeForScheduleIndex(index)
  }

  getDateRanges(firstDateRangeIndex, limit = 5) {
    if (limit === 0) {
      return []
    }

    return this.range(limit, firstDateRangeIndex).map((index) => this.buildDateRangeForScheduleIndex(index))
  }

  buildDateRangeForScheduleIndex(dateRangeIndex, usDate) {
    const schedule = this.getSchedule(dateRangeIndex, this.timeService)
    return this._buildDateRangeForSchedule(schedule, dateRangeIndex, usDate)
  }

  _buildDateRangeForSchedule(schedule, dateRangeIndex, usDate) {
    const scheduleId = schedule.get('id')
    const startsAt = schedule.get('startsAt')
    const endsAt = schedule.get('endsAt')

    const todayMoment = this.timeService.timeMoment(null)
    const scheduleState = todayMoment.isAfter(endsAt) ? 'inactive' : schedule.get('state')

    return DateRangeService.buildDateRange({
      unitUrlId: this.unitUrlId,
      unitId: this.unitId,
      usDate: usDate || this.usDate,
      dateRangeIndex,
      scheduleId,
      scheduleState,
      startsAt,
      endsAt,
      timeService: this.timeService
    })
  }

  getSchedule(index, timeService) {
    if (!this.schedules) {
      return
    }

    const schedules = this.schedules

    if (0 <= index && index < schedules.size) {
      return schedules.get(index)
    }

    const dummyScheduleIndex = index - schedules.size
    return this.buildDummySchedule(dummyScheduleIndex, timeService)
  }

  getDateRangeIndex(usDate) {
    return this.getScheduleIndexForDate(usDate)
  }

  getScheduleIndexForDate(usDate) {
    const schedules = this.schedules
    const scheduleDate = this.timeService.usDateToMoment(usDate)

    let scheduleIndex = schedules.findKey((schedule) => {
      const startsAt = this.timeService.timeMoment(schedule.get('startsAt'))
      const endsAt = this.timeService.timeMoment(schedule.get('endsAt'))

      return this.timeService.isBetween(scheduleDate, startsAt, endsAt, null, '[)')
    })

    if (scheduleIndex === undefined) {
      const date = scheduleDate.toISOString()
      const dlsOffset = this.timeService.calculateDLSChanging(this.schedulingStartsAt, date)

      let dummyScheduleIndex
      if (this.scheduleType === 'monthly') {
        dummyScheduleIndex = scheduleDate.startOf('month').diff(this.schedulingStartsAt, 'months')
      } else {
        const dayIndex = moment.utc(date).add(dlsOffset, 'minutes').diff(this.schedulingStartsAt, 'hours') / 24
        dummyScheduleIndex = Math.floor(dayIndex / this.unitStaffingPeriod)
      }
      scheduleIndex = dummyScheduleIndex + schedules.size
    }

    return scheduleIndex
  }

  get schedulingStartsAt() {
    const lastScheduleIndex = this.schedules.size - 1
    const lastSchedule = this.schedules.get(lastScheduleIndex)

    if (lastSchedule) {
      return lastSchedule.get('endsAt')
    }

    const staffingPeriod = this.unitStaffingPeriod
    const today = this.timeService.timeMoment(null)
    const schedulingStartsAtMoment = this.timeService.timeMoment(this.unitSchedulingStartsAt)

    // NOTE: pastPeriodsCount should be 0 if schedulingStartsAt is future;
    const pastPeriodsCount = Math.floor(today.diff(schedulingStartsAtMoment, 'days') / staffingPeriod)
    const daysInPastPeriods = Math.max(pastPeriodsCount, 0) * staffingPeriod

    return schedulingStartsAtMoment.add(daysInPastPeriods, 'days').toISOString()
  }

  buildDummySchedule(index, timeService) {
    const scheduleState = 'inactive'

    if (this.scheduleType === 'monthly') {
      const lastOpenedScheduleEndsAt = this.schedules.get(-1)?.get('endsAt') || this.schedulingStartsAt
      const lastScheduleEndsAtMoment = timeService.timeMoment(lastOpenedScheduleEndsAt).add(index, 'month')
      return Map({
        state: scheduleState,
        startsAt: lastScheduleEndsAtMoment.toISOString(),
        endsAt: lastScheduleEndsAtMoment.add(1, 'month').toISOString()
      })
    }
    const schedulingStartsAt = timeService.timeMoment(this.schedulingStartsAt)
    let scheduleStartDate = schedulingStartsAt.clone().add(index * this.unitStaffingPeriod, 'day')

    let scheduleEndDate = scheduleStartDate.clone().add(this.unitStaffingPeriod, 'day')

    const activeDateRange = this.getActiveDateRange()
    const activeDateRangeUsDate = activeDateRange.get('firstDayUsDate')
    const activeDateRangeIndex = this.getScheduleIndexForDate(activeDateRangeUsDate) //didn't use getActiveDateRangeIndex since it was out of sync with activeDateRange object
    const dummyDateRangeIndex = index + this.schedules.size

    if (dummyDateRangeIndex === activeDateRangeIndex) {
      this.unitStaffingPeriodOffset = activeDateRange.get('duration') * 7 - this.unitStaffingPeriod
      scheduleEndDate = scheduleEndDate.clone().add(this.unitStaffingPeriodOffset, 'day')
    } else if (dummyDateRangeIndex > activeDateRangeIndex) {
      scheduleStartDate = scheduleStartDate.clone().add(this.unitStaffingPeriodOffset, 'day')
      scheduleEndDate = scheduleEndDate.clone().add(this.unitStaffingPeriodOffset, 'day')
    }

    const startsAt = scheduleStartDate.toISOString()
    const endsAt = scheduleEndDate.toISOString()

    return Map({ state: scheduleState, startsAt, endsAt })
  }

  buildDummyScheduleFromDate(index, timeService, fromDate) {
    const schedulingStartsAt = timeService.timeMoment(fromDate)

    const scheduleStartDate = schedulingStartsAt.clone().add(index * this.unitStaffingPeriod, 'day')

    const scheduleEndDate = scheduleStartDate.clone().add(this.unitStaffingPeriod, 'day')

    const startsAt = scheduleStartDate.toISOString()
    const endsAt = scheduleEndDate.toISOString()

    const scheduleState = 'inactive'
    return Map({ state: scheduleState, startsAt, endsAt })
  }

  getSchedulesPosition(activeScheduleIndex) {
    const schedulesLengthBeforeActiveSchedule = 1
    const existedSchedulesLength = 5
    const firstExistedScheduleIndex = Math.max(activeScheduleIndex - schedulesLengthBeforeActiveSchedule, 0)
    const lastExistedScheduleIndex = activeScheduleIndex - schedulesLengthBeforeActiveSchedule + existedSchedulesLength

    const schedulesSize = this.schedules.size
    const dummySchedulesSize = activeScheduleIndex + 4 - schedulesSize

    const dummySchedulesStartIndex = Math.max(0, dummySchedulesSize - 5)

    return {
      firstExistedScheduleIndex,
      lastExistedScheduleIndex,
      dummySchedulesStartIndex,
      dummySchedulesSize
    }
  }
}
