import { groupBy } from 'lodash'
import { ComponentController } from '../../../Common/components'
import { Map } from 'immutable'
import { IShiftDayAggregate } from 'stores/dashboardStore/dashboardStore'

//TODO: backend error handling

export default class DataController extends ComponentController {
  createShiftIdToOvertimeMap = (
    unit: any
  ): {
    [key: string]: string
  } => {
    let shiftIdToNameMap: {
      [key: string]: string
    } = {}
    let shiftId: string | undefined
    let shiftName: string | undefined

    const shifts = unit?.get('shifts')
    shifts?.forEach((shift: any) => {
      shiftId = shift?.get('id')
      shiftName = shift?.get('name')
      if (shiftId && shiftName) {
        shiftIdToNameMap[shiftId] = shiftName
      }
    })
    return shiftIdToNameMap
  }

  fetchShiftOvertimeList = (unit: any, shiftOvertimeMap: any): [string[], number[]] => {
    const newShiftNames: string[] = []
    const newShiftOvertimeCounts: number[] = []
    let shiftIdValue: string, shiftOvertimeValue: number
    const shiftIdToNameMap = this.createShiftIdToOvertimeMap(unit)
    shiftOvertimeMap?.forEach((overtimeData: any) => {
      shiftIdValue = overtimeData.get('shiftId')
      shiftOvertimeValue = overtimeData.get('totalShiftOvertime')
      if (shiftIdValue && shiftOvertimeValue > 0) {
        newShiftNames.push(shiftIdToNameMap[shiftIdValue])
        newShiftOvertimeCounts.push(shiftOvertimeValue)
      }
    })
    return [newShiftNames, newShiftOvertimeCounts]
  }

  loadStaffingInfoCardData = async (unitId: string, activeDateRange: Map<string, any>) => {
    const { dashboardStore } = this.props
    const appState = await dashboardStore.loadShiftDayAggregatesByShift(
      activeDateRange.get('startsAt'),
      activeDateRange.get('endsAt'),
      unitId
    )
    const shiftDayAggregatesByShiftId = appState.getIn(['dashboard', 'shiftDayAggregatesByShiftId'])

    let understaffedShiftCount = 0
    let overstaffedShiftCount = 0
    let staffedShiftCount = 0
    const barGraphRequiredStaffData: number[] = []
    const barGraphAssignedStaffData: number[] = []
    const barGraphLabels: string[] = []
    const shiftsById: Map<string, any> = appState.getIn(['generalData', 'shiftsById'])

    shiftDayAggregatesByShiftId.forEach((shiftDayAggregate: IShiftDayAggregate, shiftId: string) => {
      const totalStaffMismatch = shiftDayAggregate.get('totalStaffMismatch')
      const totalStaffRequirement = shiftDayAggregate.get('totalStaffRequirement')
      if (totalStaffMismatch === 0) {
        staffedShiftCount++
      } else if (totalStaffMismatch > 0) {
        understaffedShiftCount++
      } else {
        overstaffedShiftCount++
      }
      barGraphAssignedStaffData.push(Math.max(totalStaffRequirement - totalStaffMismatch, 0)) //TODO: find reason why backend sends staffMismatch > staffRequired, i.e. staffAssigned < 0
      barGraphRequiredStaffData.push(totalStaffRequirement)
      barGraphLabels.push(shiftsById.getIn([shiftId, 'name']))
    })
    const donutGraphData = { understaffedShiftCount, staffedShiftCount, overstaffedShiftCount }
    return {
      donutGraphData,
      barGraphRequiredStaffData,
      barGraphAssignedStaffData,
      barGraphLabels
    }
  }

  loadCardData = async (unitId: string) => {
    const { timeService, staffStore } = this.props
    const today = timeService.timeMoment(null)
    const startDate = today.toISOString()
    const endDate = today.clone().add(1, 'day').toISOString()

    const staffList = await staffStore.loadUnitStaff(unitId, 1, { startDate, endDate }, 9999)
    const staffData = staffList?.getIn(['staff', 'staffList', 'edges'])
    const { fullTime, partTime, perDiem } = groupBy(staffData?.toJS(), 'staffProfile.employmentType')
    return {
      fullTimeStaffCount: fullTime?.length ?? 0,
      partTimeStaffCount: partTime?.length ?? 0,
      perDiemStaffCount: perDiem?.length ?? 0
    }
  }

  loadTopStaffOvertimesCardData = async (unitId: string, activeDateRange: Map<string, any>) => {
    const { dashboardStore } = this.props
    const appState = await dashboardStore.fetchSortedStaffOvertimes(
      unitId,
      activeDateRange.get('startsAt'),
      activeDateRange.get('endsAt')
    )
    return { sortedStaffOvertimes: appState.getIn(['dashboard', 'sortedStaffOvertimes']).toJS() }
  }
}
