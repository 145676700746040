import { ComponentController } from 'Common/components'
import { DefaultDetail, OperationalDetails, UnitResource } from './UnitResourceAdmin'

interface Unit {
  id: string
  name: string
}

class UnitResourceController extends ComponentController {
  get unitResourceStore(): any {
    return this.props.stores.get('unitResource')
  }

  loadData(type: string): void {
    // TODO: Revisit when merging with unit resource - START
    // const { store } = this.props
    // store.loadDataAction({ unitResourceTypes: [type] })
    // this.unitResourceStore.loadDataAction({ unitResourceTypes: [type] })
    this.unitResourceStore.loadDataAction({ resourceTypes: [type] })
    // TODO: Revisit when merging with unit resource - END
  }

  async getUnitResources(): Promise<{} | { unitResourceData: UnitResource[] }> {
    const { appState, store } = this.props
    const isApiInitialized: boolean = store.isApiInitialized(appState)
    if (!isApiInitialized) {
      return {}
    }
    const { search, stateKey } = store.getAdminInfo()
    const { state: { query = {} } = {} } = this.component
    const unitResources: UnitResource[] = search
      ? store.filter(appState, search.field, query)
      : appState.getIn([stateKey, 'list'])

    let unitResourceData: UnitResource[] = unitResources

    let allUnitData: Unit[] = this.props.appState.get('admin.Unit').get('list')

    if (allUnitData.length === 0) {
      const updatedAppState: any = await this.props.stores.get('unit').loadUnits()
      allUnitData = updatedAppState.get('admin.Unit').get('list')
    }

    // TODO: Revisit when merging with unit resource - START
    // unitResourceData = unitResourceData.map((unitResource: UnitResource) => {
    //   const unitData: Unit = allUnitData.find((unit: Unit) => unit.id === unitResource.unitId) as Unit
    //   unitResource.workingDays = this._getWorkingOperatingDays(unitResource.operationalDetails)

    //   unitResource.defaultSettings = this._getDefaultDetailsData(unitResource.defaultDetails)

    //   unitResource.unitName = unitData.name
    //   return unitResource
    // })
    // TODO: Revisit when merging with unit resource - END

    return { unitResourceData }
  }

  _getDefaultDetailsData(defaultDetails: DefaultDetail): string {
    return `Start Time: ${this._convertTo12HourFormat(defaultDetails.startTime)},
   End Time: ${this._convertTo12HourFormat(defaultDetails.endTime)},
   Target Cover: Min ${defaultDetails.targetCover.min}, Max ${defaultDetails.targetCover.max},
   Allowable Gap: Max ${defaultDetails.allowableGap} mins`
  }

  _convertTo12HourFormat(time24: string): string {
    const { timeService } = this.props
    return timeService.timeMoment(time24, 'HH:mm').format('h:mm A')
  }

  _getWorkingOperatingDays(operationalDetails: OperationalDetails): string {
    const weekDaysMap: { [key: string]: string } = {
      sun: 'Sunday',
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday'
    }

    const workingDays: string[] = Object.entries(operationalDetails)
      .filter(([day, details]) => details.isWorking)
      .map(([day]) => weekDaysMap[day])

    return workingDays.join(', ')
  }
}

export default UnitResourceController
