import { Link } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import { paths, navigate } from 'Navigation'
import { t } from 'i18n'
import './AccessBar.scss'
import constants from 'utils/constants'
import { Staff } from 'entityWrappers'
import { CustomerSupport } from 'Common/components'
import { useContext } from 'react'

// @ts-ignore
import { CustomerSupportContext } from '@humanics/he-react-common/lib/components/CustomerSupport'

const { digitalServicesActLink } = constants

const SupportHelp = () => {
  const supportContext: { handleClick: () => Promise<void> } = useContext(CustomerSupportContext)

  return (
    <div className="cursor-pointer" onClick={supportContext.handleClick}>
      <sh-list-item>{t('help_text')}</sh-list-item>
    </div>
  )
}

function AccessBar(props: any) {
  const staff = props.appState.getIn(['authentication', 'facilityUser'])

  const facilityUser = new Staff(staff)
  const { fullName, email, avatarUrl } = facilityUser

  const { label } = props

  const auth = props.appState.get('authentication')

  const urlId = auth.getIn(['facility', 'urlId']) || auth.getIn(['facilities', 0, 'urlId'])
  if (!urlId) {
    return navigate.to.Forbidden({}, false)
  }

  return (
    <div className="access-bar">
      <sh-access-bar label={label} slot="access">
        <sh-user-identifier
          slot="user"
          name={fullName}
          info={email}
          id="username"
          image={avatarUrl && `url(${avatarUrl})`}
        ></sh-user-identifier>
      </sh-access-bar>

      <sh-popover target="username" position="bottom-left">
        <Link to={paths.StaffProfilePage}>
          <sh-list-item>{t('profile')}</sh-list-item>
        </Link>
        <Link to={paths.PrivacyPolicy}>
          <sh-list-item>{t('privacy_policy_text')}</sh-list-item>
        </Link>
        <Link to={paths.TermsOfService}>
          <sh-list-item>{t('terms_of_service_text')}</sh-list-item>
        </Link>
        <Link to={paths.ReadmeOSS}>
          <sh-list-item>{t('readme_oss_text')}</sh-list-item>
        </Link>
        <a href={digitalServicesActLink} target="_blank" rel="noreferrer">
          <sh-list-item>{t('digital_service_act_text')}</sh-list-item>
        </a>
        <CustomerSupport>
          <SupportHelp />
        </CustomerSupport>

        <Link to={paths.Logout}>
          <sh-list-item icon="sign-out" divider="none" label={t('auth.logout')} />
        </Link>
      </sh-popover>
    </div>
  )
}

export default withAppContext(AccessBar)
