import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'

const fields = [
  Field('name', 'Facility Name'),
  Field('country', 'Country'),
  Field('state', 'State'),
  Field('location', 'Location'),
  Field('timeZone', 'Time Zone')
]

function FacilityStore() {
  const actions = fluxStore({ createFacility, loadFacilities })
  const store = AdminStore({
    fields,
    instanceAdmin: true,
    entityName: 'Facility',
    api: {
      index: 'indexIdns'
    },
    header: { title: 'Facilities' },
    search: { field: 'name', placeholder: 'Search for facilities' }
  })

  return {
    ...store,
    ...actions
  }

  async function loadFacilities(state) {
    const api = await store.getApi()
    const { stateKey } = store.getAdminInfo()
    const idns = await api.indexIdns({})
    return state.setIn([stateKey, 'list'], idns)
  }

  function createFacility(state, form) {
    const { idnId, ...facility } = form
    const instance = state.getIn(['authentication', 'instance'])

    return instance.gqlClient.createFacility({ idnId, facility }).then(() => state)
  }
}

export default FacilityStore
