import RemoteWeScanStaffRequestRow from './RemoteWeScanStaffRequestRow'

import { TimeService } from 'services'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'
import RemoteWeScanStaffRequestController from './RemoteWeScanStaffRequestController'

interface IViewRemoteWeScanStaffListProps {
  remoteStaffRequests: IRemoteStaffRequest[]
  timeService: TimeService
  remoteWeScanStaffRequestController: RemoteWeScanStaffRequestController
  handleCancelRequestButtonClick: (remoteStaffRequest: IRemoteStaffRequest) => void
}

export default function RemoteWeScanStaffRequestsList(props: IViewRemoteWeScanStaffListProps) {
  const { remoteStaffRequests, timeService, handleCancelRequestButtonClick, remoteWeScanStaffRequestController } = props

  return (
    <>
      {remoteStaffRequests.map((remoteStaffRequest: IRemoteStaffRequest) => (
        <RemoteWeScanStaffRequestRow
          key={remoteStaffRequest.id}
          remoteStaffRequest={remoteStaffRequest}
          timeService={timeService}
          remoteWeScanStaffRequestController={remoteWeScanStaffRequestController}
          handleCancelRequestButtonClick={handleCancelRequestButtonClick}
        />
      ))}
    </>
  )
}
