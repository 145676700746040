export const EXPERTISE_MISMATCH_ERROR = 'SCH-53'
export const USER_INVALIDATED = 'COM-7'
export const EMAIL_PHONE_NUMBER_ERROR = 'COR-21'
export const EMAIL_ERROR = 'COR-22'
export const PHONE_NUMBER_ERROR = 'COR-23'
export const MFA_REQUIRED_ERROR = 'PER-36'
export const SCHEDULE_NOT_FOUND = 'AUT-6'
export const EMAIL_ERROR_CODES = [EMAIL_PHONE_NUMBER_ERROR, EMAIL_ERROR]
export const PHONE_NUMBER_ERROR_CODES = [EMAIL_PHONE_NUMBER_ERROR, PHONE_NUMBER_ERROR]
export const EMAIL_PHONE_NUMBER_ERROR_CODES = [EMAIL_PHONE_NUMBER_ERROR, EMAIL_ERROR, PHONE_NUMBER_ERROR]
