import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { getDurationLabel } from 'Manager/PatientSlotRecommender/utils'

const fields = [Field('name', 'Name'), Field('createdAt', 'created', { format: 'date-time' })]

function UnitResourceStore() {
  const store = AdminStore({
    fields,
    entityName: 'UnitResource',
    api: {
      // TODO: Revisit when merging with unit resource - START
      // index: 'indexUnitResources'
      index: 'indexShifts',
      read: 'readShift'
      // TODO: Revisit when merging with unit resource - END
    },
    header: { title: 'Unit Resources' },
    // TODO: Revisit when merging with unit resource - START
    // search: { field: 'name', placeholder: 'Search for unit resources' },
    // TODO: Revisit when merging with unit resource - END
    modifyData: (item) => {
      return {
        duration: getDurationLabel(item.length)
      }
    }
  })

  function loadUnitResources() {
    return store.loadDataAction()
  }

  return {
    ...store,
    loadUnitResources,
    hasCrud: (operation, ...args) => {
      if (operation === 'delete') {
        return false
      }
      return store.hasCrud(operation, ...args)
    }
  }
}

export default UnitResourceStore
