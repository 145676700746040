import { useRef, useEffect, MutableRefObject } from 'react'

const useShuiEventHook = (eventName: string, handleChange: (event: any) => void) => {
  const elementRef: MutableRefObject<HTMLElement | null> = useRef(null)

  useEffect(() => {
    const element = elementRef?.current

    if (element) {
      element.removeEventListener(eventName, handleChange)
      element.addEventListener(eventName, handleChange)
    }

    return () => {
      if (element) {
        element.removeEventListener(eventName, handleChange)
      }
    }
  }, [eventName, handleChange])

  return elementRef
}

export default useShuiEventHook
