import { createStaffUnavailabilityRemindersMutation } from '../Mutations'

function StaffUnavailabilityReminders() {
  let gqlClient = null
  const actions = { createStaffUnavailabilityReminders }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  function createStaffUnavailabilityReminders(parameters) {
    return gqlClient.mutate(createStaffUnavailabilityRemindersMutation, parameters)
  }
}

export default StaffUnavailabilityReminders()
