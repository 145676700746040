import { Component } from 'react'
import './PlaceholderInstructionBox.scss'
import DeleteComponentButton from './EditButtons/DeleteComponentButton'
import NavigationBar from './NavigationBar'

export default class PlaceholderInstructionBox extends Component {
  state = {
    isHovering: false
  }
  render() {
    const { component, activeComponent, hasDetailsPage, isFormEditable, isDeleteVisible = true } = this.props
    const { deleteDetailsComponent, onDetailsComponentClicked } = this.props

    return (
      <div
        className="placeholder-link"
        onClick={hasDetailsPage ? onDetailsComponentClicked : null}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        {/* Blue navigation bar on the left */}
        {component.id === activeComponent.id && isFormEditable && <NavigationBar />}

        <div className="dotted-border"> Select on the right </div>

        {/* Button */}
        {isDeleteVisible && this.state.isHovering && hasDetailsPage && isFormEditable && (
          <div className="delete-component-button">
            <DeleteComponentButton deleteDetailsComponent={deleteDetailsComponent} />
          </div>
        )}
      </div>
    )
  }
}
