import { Staff } from 'entityWrappers'
import { navigate } from 'Navigation'
import { CrudCardTable, CrudCard } from 'Manager/components'

export default function DepartmentEligibility({ staff, timeService, units }) {
  if (!units || units.size === 0) {
    return null
  }

  const { transferringUnit, eligibleUnits } = new Staff(staff)

  const hasStaffTransferring = !!transferringUnit

  const hasEligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits']).size > 0
  const staffUnitAssignmentUrl = navigate.from.StaffDetails.to.StaffUnitAssignment({}, false)
  const tableProps = tableTransform(eligibleUnits, units, timeService)
  let cardContents = null
  if (tableProps.columns.length !== 0) {
    cardContents = <CrudCardTable {...tableProps} emptyTableMessage="No history found" />
  }

  const allowCreate = !hasStaffTransferring && hasEligibleUnits
  return (
    <>
      <CrudCard
        title="Primary Department History"
        createItemPath={allowCreate && staffUnitAssignmentUrl}
        createItemTooltip="Add to history"
      >
        {cardContents}
      </CrudCard>
    </>
  )
}

function tableTransform(eligibleUnits, units, timeService) {
  const columns = [
    {
      dataId: 'department',
      width: 2.5
    },
    {
      dataId: 'role',
      width: 2.5
    },
    {
      dataId: 'shift',
      width: 1
    },
    {
      dataId: 'time',
      width: 2
    },
    {
      dataId: 'startDate',
      width: 2
    },
    {
      dataId: 'endDate',
      width: 2
    }
  ]

  const rows = [
    {
      department: 'Department',
      role: 'Role',
      shift: 'Shift',
      time: 'Time',
      startDate: 'Start Date',
      endDate: 'End Date'
    }
  ]

  const groupedEligibleUnits = eligibleUnits.groupBy((eligibleUnit) => eligibleUnit.get('homeUnitId'))
  groupedEligibleUnits.forEach((eligibleUnitGroup, homeUnitId) => {
    const homeUnit = units.find((unit) => unit.get('id') === homeUnitId)
    const roles = homeUnit.get('roles')
    const shiftTypes = homeUnit.get('shiftTypes')

    eligibleUnitGroup.forEach((eligibleUnit, index) => {
      const homeUnitName = eligibleUnit.get('homeUnitName')
      const showHomeUnit = index === 0

      const roleId = eligibleUnit.get('homeUnitRoleId')
      const role = roleId && roles.find((role) => role.get('id') === roleId)
      const roleName = role ? role.get('name') : ''

      const shiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')
      const shiftType = shiftTypeId && shiftTypes.find((shiftType) => shiftType.get('id') === shiftTypeId)
      const shiftTypeInfo = shiftType ? getShiftTimeInfo(shiftType, timeService) : ''
      const shiftTypeTitle = shiftType ? shiftType.get('title') : ''

      const format = 'MMM D, YYYY'
      const startsAtMoment = timeService.timeMoment(eligibleUnit.get('startsAt'))
      const formattedStartsAt = startsAtMoment.format(format)

      const shiftEndDate = eligibleUnit.get('endsAt')

      const endsAtMoment = timeService.timeMoment(shiftEndDate)
      const formattedEndsAt = shiftEndDate ? endsAtMoment.format(format) : '–'

      rows.push({
        department: showHomeUnit && homeUnitName,
        role: roleName,
        shift: shiftTypeTitle,
        time: shiftTypeInfo,
        startDate: formattedStartsAt,
        endDate: formattedEndsAt
      })
    })
  })

  return { columns, rows }
}

function getShiftTimeInfo(shiftType, timeService) {
  const shiftLength = shiftType.get('duration')
  const startTimeMoment = timeService.timeMoment(shiftType.get('startTime'), 'HH:mm')
  const endTimeMoment = startTimeMoment.clone().add(shiftLength, 'minutes')

  const format = 'H:mm'

  const formattedStartTime = startTimeMoment.format(format)
  const formattedEndTime = endTimeMoment.format(format)

  return `${formattedStartTime}-${formattedEndTime}`
}
