import { fromJS, Map } from 'immutable'
import { staffEventsQuery } from '../Queries'
import { GQLDataLoader } from 'utils'
import { createStaffEventsMutation, deleteStaffEventsMutation } from '../Mutations'

function StaffEvents() {
  let gqlClient = null
  let timeService = null
  let updateTimeOffs = null
  let loadDateRangeNotes = null

  const actions = {
    createStaffEvents,
    deleteStaffEvents,
    loadDateRangeTimeOffs,
    loadDateRangesTimeOffs
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, updateTimeOffs, loadDateRangeNotes, facilityTime: timeService } = context)
  }

  async function loadDateRangesTimeOffs(unitId, dateRangesParameters) {
    updateTimeOffs((timeOffsMap) => {
      return dateRangesParameters.reduce((timeOffsMap, dateRangeParameters) => {
        const { dateRange } = dateRangeParameters
        const dateRangeIndex = dateRange.get('index')
        return timeOffsMap.mergeIn([dateRangeIndex], { isLoading: true })
      }, timeOffsMap)
    })

    const promises = dateRangesParameters.map(({ dateRange, userIds }) =>
      _readTimeOffEvents(unitId, dateRange, userIds)
    )

    setTimeout(() => _loadDateRangesNotes(unitId, dateRangesParameters), 400)

    const data = await Promise.all(promises)

    return updateTimeOffs((timeOffsMap) => {
      const newTimeOffsMap = data.reduce((timeOffsMap, timeOffEvents, index) => {
        const { dateRange } = dateRangesParameters[index]
        const dateRangeIndex = dateRange.get('index')
        return timeOffEvents.reduce((memo, timeOffEvent) => {
          const { userId, startsAt } = timeOffEvent
          const dateTime = timeService.timeMoment(startsAt).startOf('day').toISOString()
          const path = [dateRangeIndex, userId, dateTime]
          return memo.setIn(path, fromJS(timeOffEvent))
        }, timeOffsMap)
      }, timeOffsMap)

      return dateRangesParameters.reduce((timeOffsMap, dateRangeParameters) => {
        const { dateRange } = dateRangeParameters
        const dateRangeIndex = dateRange.get('index')
        return timeOffsMap.mergeIn([dateRangeIndex], { isLoading: false })
      }, newTimeOffsMap)
    })
  }

  async function loadDateRangeTimeOffs(unitId, dateRange, userIds) {
    const dateRangeIndex = dateRange.get('index')

    const timeOffEventsPromise = _readTimeOffEvents(unitId, dateRange, userIds)
    setTimeout(() => _loadDateRangesNotes(unitId, [{ dateRange, userIds }]), 400)

    const timeOffEvents = await timeOffEventsPromise

    return updateTimeOffs((timeOffsMap) => {
      timeOffsMap = timeOffsMap.set(dateRangeIndex, Map())
      return timeOffEvents.reduce((memo, timeOffEvent) => {
        const { userId, startsAt } = timeOffEvent
        const dateTime = timeService.timeMoment(startsAt).startOf('day').toISOString()
        const path = [dateRangeIndex, userId, dateTime]
        return memo.setIn(path, fromJS(timeOffEvent))
      }, timeOffsMap)
    })
  }

  function _readTimeOffEvents(unitId, dateRange, userIds) {
    const startDate = dateRange.get('startsAt')
    const endDate = dateRange.get('endsAt')

    const gqlDataLoaded = new GQLDataLoader(gqlClient)
    const parameters = {
      unitId,
      userIds,
      endDate,
      startDate,
      types: ['timeOff']
    }

    return gqlDataLoaded.loadAllItems('staffEvents', staffEventsQuery, parameters)
  }

  async function createStaffEvents(staffEvents, unitEventVariantId, note, options) {
    const parameters = { parameters: { staffEvents, unitEventVariantId } }

    if (note) {
      const { subject, text } = note

      parameters.parameters.note = { subject, text }
    }

    return gqlClient.mutate(createStaffEventsMutation, parameters, options)
  }

  async function deleteStaffEvents(ids, options) {
    const parameters = { parameters: { ids } }
    return gqlClient.mutate(deleteStaffEventsMutation, parameters, options)
  }

  function _loadDateRangesNotes(unitId, dateRanges) {
    return dateRanges.map(({ dateRange, userIds }) => {
      const startDate = dateRange.get('startsAt')
      const endDate = dateRange.get('endsAt')

      const shiftIds = dateRange
        .get('roles')
        .reduce((memo, role) => role.get('shifts').reduce((memo, shift) => memo.concat(shift.get('id')), memo), [])

      return loadDateRangeNotes(unitId, startDate, endDate, shiftIds, userIds)
    })
  }
}

export default StaffEvents()
