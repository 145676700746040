import { PureComponent } from 'react'
import ShiftHeader from './ShiftHeader'
import './Shift.scss'

export default class Shift extends PureComponent {
  render() {
    const { shift, style, timeService } = this.props
    const days = shift.get('days')

    return (
      <div className="hx-shift" style={style}>
        <ShiftHeader shift={shift} days={days} timeService={timeService} />
      </div>
    )
  }
}
