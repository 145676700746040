import CommonCard, { ICommonCardProps } from './CommonCard'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend } from 'chart.js'
import { Bar, Doughnut as Donut } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { merge } from 'lodash'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels, ArcElement)

export interface IGraphCardProps extends ICommonCardProps {
  type: 'bar' | 'donut' | 'donutBar'
  labels: { donut?: string[][]; bar?: string[][] } // 2nd dimension to support multiline labels
  datasets: { donut?: Dataset[]; bar?: Dataset[] }
  graphHeight?: { donut?: number; bar?: number } //TODO: document that this is in pixels
  options: any //TODO: get its type from Chart JS types
  isValid?: boolean //can be used in combination with Common card's noContentLabel to display cause of invalidity
}

const donutPlugins = [
  {
    id: 'donutLegends',
    beforeInit(chart: any) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit

      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)()
        // Change the height
        this.height += 15
      }
    }
  }
]

export interface Dataset {
  label?: string
  data: number[]
  backgroundColor?: Color | Color[]
}

type RGB = `rgb(${number}, ${number}, ${number})`
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`
type HEX = `#${string}`
type Color = RGB | RGBA | HEX

const commonGraphOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      boxPadding: 5
    }
  }
}

export default function GraphCard({
  //graphing props start here
  type,
  labels,
  datasets,
  options,
  graphHeight,
  isValid = true,
  //common card props start here
  ...rest
}: IGraphCardProps) {
  if (!isValid) {
    return <CommonCard {...rest} />
  }

  const combinedOptions = merge(options, commonGraphOptions)

  let chartJSGraphComponent = <></>
  if (type === 'bar') {
    const data = {
      labels: labels.bar,
      datasets: datasets.bar || []
    }
    chartJSGraphComponent = (
      <div style={{ minHeight: `${graphHeight?.bar}px`, height: `${graphHeight?.bar}px` }}>
        <Bar data={data} options={combinedOptions} />
      </div>
    )
  } else if (type === 'donut') {
    const data = {
      labels: labels.donut,
      datasets: datasets.donut || []
    }
    chartJSGraphComponent = (
      <div style={{ minHeight: `${graphHeight?.donut}px` }}>
        <Donut data={data} options={combinedOptions} plugins={donutPlugins} />
      </div>
    )
  } else if (type === 'donutBar') {
    const donutCombinedOptions = merge(options.donut, commonGraphOptions)
    const barCombinedOptions = merge(options.bar, commonGraphOptions)

    const donutData = {
      labels: labels.donut,
      datasets: datasets.donut || []
    }

    const barData = {
      labels: labels.bar,
      datasets: datasets.bar || []
    }

    chartJSGraphComponent = (
      <div className="row" style={{ height: `${graphHeight?.donut}px` }}>
        <div className="col-5">
          <Donut data={donutData} options={donutCombinedOptions} plugins={donutPlugins} />
        </div>
        <div className="col-7" style={{ height: `${graphHeight?.donut}px` }}>
          <div style={{ height: `${graphHeight?.bar}px` }}>
            <Bar data={barData} options={barCombinedOptions} />
          </div>
        </div>
      </div>
    )
  }

  return <CommonCard {...rest}>{chartJSGraphComponent}</CommonCard>
}
