import { useCallback } from 'react'

import useShuiEventHook from './customHooks/shuiEventHook'
import { KEYBOARD_KEYS } from 'Common/constants/KeyboardKeys'
import { ActivityTracker } from './activityTracker'

export interface ITimePickerProps {
  time: string
  setTime: (time: string) => void
  trackActivity?: boolean
}

const TimePicker = ({ time, setTime, trackActivity = false }: ITimePickerProps) => {
  const handleKeyUpEventForTimePicker = (event: KeyboardEvent) => {
    const code = event.code

    if (
      code === KEYBOARD_KEYS.Space ||
      code === KEYBOARD_KEYS.Enter ||
      /^(Key[A-Z]|Digit[0-9]|Numpad[0-9]+)$/.test(code)
    ) {
      handleTimePickerClick()
    }
  }

  const handleTimePickerClick = () => {
    if (trackActivity) {
      ActivityTracker.StartTracking()
    }
  }

  const handleTimeChange = useCallback(
    (h: string, m: string, amPm: string) => {
      if (!h || !m || !amPm) {
        setTime('')
        return
      }

      const minutes = parseInt(m)
      let hours

      if (parseInt(h) === 12) {
        hours = amPm === 'AM' ? 0 : 12
      } else {
        hours = amPm === 'AM' ? parseInt(h) : parseInt(h) + 12
      }

      const newTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
      setTime(newTime)
    },
    [setTime]
  )

  const handleTimeChangeEvent = useCallback(
    (event: any) => {
      const { h, m, amPm } = event.target
      handleTimeChange(h, m, amPm)
    },
    [handleTimeChange]
  )

  const eventName = 'val-changed'
  const timePickerRef = useShuiEventHook(eventName, handleTimeChangeEvent)

  return (
    <sh-timepicker
      no-clear
      label="Time"
      ref={timePickerRef}
      h={time && String(parseInt(time.split(':')[0]) % 12 || 12).padStart(2, '0')}
      m={time && String(time.split(':')[1]).padStart(2, '0')}
      am-pm={time && (parseInt(time.split(':')[0]) < 12 ? 'AM' : 'PM')}
      onClick={handleTimePickerClick}
      onKeyUp={handleKeyUpEventForTimePicker}
      mini
    ></sh-timepicker>
  )
}

export default TimePicker
