import { Component } from 'react'
import CreateEntity from '@humanics/he-react-common/lib/admin/components/CreateEntity'
import { withAppContext } from 'AppContext'

export class FacilityCreate extends Component {
  constructor(props) {
    super(props)
    this.state = { timeZones: [] }
  }
  render() {
    const createProps = this.getCreateProps(this.props.stores.get('state'))
    return <CreateEntity {...this.props} {...createProps} onSubmit={this.onSubmit} />
  }

  getCreateProps = (stateStore) => ({
    createOperationId: 'createFacility',
    createDefinitionName: 'Facility',
    inputDefinitionExtraFields: {
      idnId: {
        label: 'idnId',
        inputType: 'InputDropdownIdnId',
        type: 'string',
        customPlaceHolder: 'Instance URL-idn name',
        required: true,
        withLabel: true
      },
      name: {
        label: 'Name',
        type: 'string',
        required: true
      },
      location: {
        label: 'Location',
        type: 'string',
        required: true
      },
      country: {
        label: 'Country',
        type: 'string',
        onChange: async (e) => {
          if (e && stateStore) {
            const countryCode = e.target.value
            this.getTimeZonesForCountry(countryCode)
            if (!(typeof countryCode === 'object' || !countryCode)) {
              await stateStore.loadDataAction(countryCode)
            }
          }
        },
        required: true
      },
      state: {
        label: 'State',
        type: 'string',
        enum: this.props.appState.getIn(['admin.State', 'list']),
        required: true
      },
      timeZone: {
        label: 'Time zone',
        type: 'string',
        enum: this.state.timeZones,
        required: true,
        definition: {
          withLabel: true
        }
      }
    }
  })

  getTimeZonesForCountry = (countryCode) => {
    const timezones = this.props.appState
      .getIn(['admin.Country', 'list'])
      .filter((country) => country.code === countryCode)
      .flatMap((country) => country.timeZones)
    this.setState({ timeZones: timezones })
  }

  onSubmit = (e, form) => {
    const { store, authStore } = this.props

    return store
      .createFacility(form)
      .then(() => {
        return authStore.authorize()
      })
      .then(() => {
        this.props.history.push('.')
      })
  }
}

export default withAppContext(FacilityCreate)
