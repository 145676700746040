const form = {
  certification: {
    definition: {
      label: 'Credential/Certification',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'certification',
    formItemClasses: 'col-12'
  },
  organization: {
    definition: {
      label: 'Organization',
      required: false,
      type: 'string',
      inputType: 'StringInputField',
      withLabel: true
    },
    field: 'skillType',
    formItemClasses: 'col-12'
  },
  certificationNumber: {
    definition: {
      label: 'Certification Number (if applicable)',
      required: false,
      type: 'string',
      inputType: 'StringInputField',
      withLabel: true
    },
    field: 'assessedCompetency',
    formItemClasses: 'col-6 pr30'
  },
  expirationDate: {
    definition: {
      label: 'Expiration Date',
      required: false,
      type: 'date',
      inputType: 'CustomDateInput',
      withLabel: true,
      flatpickrOptions: {
        static: true
      }
    },
    field: 'expirationDate',
    formItemClasses: 'col-6 pr30'
  }
}

export default form
