import { Component } from 'react'

import { withAppContext } from 'AppContext'

import Toolbar from './Toolbar/Toolbar'
import ScheduleView from './views/ScheduleView'

class StaffDetailedSchedule extends Component {
  render() {
    const { unit, showMismatchModal, getCalendar } = this.props

    if (!unit.get('isReady')) {
      return (
        <div className="hx-staff-detailed-schedule hx-box-shadow bg-white row align-middle">
          <sh-spinner label="Loading" />
        </div>
      )
    }

    return (
      <div className="hx-staff-detailed-schedule hx-box-shadow bg-white">
        <Toolbar />
        <ScheduleView showMismatchModal={showMismatchModal} getCalendar={getCalendar} />
      </div>
    )
  }
}

export default withAppContext(StaffDetailedSchedule)
