import StateController from './StateController'

export default class CrossHatchController extends StateController {
  hideCrosshatch = () => {
    this.component.crossHatchRef.current.hide()
  }

  updateCrossHatchPosition = (e) => {
    this.component.crossHatchRef.current.updatePosition(e)
  }

  updateCrossHatchStyle = () => {
    const grid = document.getElementsByClassName('hx-vgrid')
    const { offsetWidth = 0 } = grid && grid.length > 0 ? grid[0] : {}
    const padding = 39 * 2
    const width = offsetWidth - padding
    const { scrollLeft } = this.state
    const styleHorizontal = { width: `${width}px`, left: `${-scrollLeft}px` }

    this.setState({ styleHorizontal })
  }
}
