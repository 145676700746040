import { useState, useEffect } from 'react'
import ActivitiesGroup from 'Manager/Activities/ActivitiesGroup'
import { CrudCard } from 'Manager/components'
import DateRangeSelector from './DateRangeSelector'

export default function Activities({ activities, unit, timeService, loadStaffActivities }) {
  const [date, setDate] = useState(timeService.today)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      if (!unit.get('isReady')) {
        return
      }
      const startDate = date.clone().utcOffset(0).startOf('month').toISOString()
      const endDate = date.clone().utcOffset(0).endOf('month').toISOString()
      setLoading(true)
      await loadStaffActivities(unit.get('id'), startDate, endDate)
      setLoading(false)
    }
    fetchData()
  }, [loadStaffActivities, unit, date])

  const activityItems = activities
    .entrySeq()
    .map(([key, groupedActivities]) => (
      <ActivitiesGroup
        key={key}
        isUnitLoaded={unit.get('isReady')}
        activities={groupedActivities}
        unit={unit}
        timeService={timeService}
      />
    ))
    .toArray()

  return (
    <CrudCard
      title="Activity"
      emptyCardMessage="No activities found."
      isCollapsible={true}
      isLoading={!unit.get('isReady')}
      isContentLoading={isLoading}
      loadingMessage="Loading activities"
      cardHeader={<DateRangeSelector date={date} setDate={setDate} timeService={timeService} />}
    >
      {activityItems.length > 0 && (
        <div className="row">
          <div className="activities-content">{activityItems}</div>
        </div>
      )}
    </CrudCard>
  )
}
