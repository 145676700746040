import { createContext, useMemo, useReducer, useRef } from 'react'
import { Set } from 'immutable'
import { filterReducer } from './filterReducer'
import { filterOptions } from './utils'

const itemTypeFilterContextDefaultValue = {
  selectedCalendarFilters: Set<string>(),
  addFilter: (_newFilter: string) => {},
  removeFilter: (_filterToRemove: string) => {},
  setFilters: (_newFilters: Set<string>) => {}
}

export const ItemTypeFilterContext = createContext(itemTypeFilterContextDefaultValue)

export function ItemTypeFilterContextProvider({ children }: any) {
  const [selectedCalendarFilters, filterDispatch] = useReducer(filterReducer, Set(Object.keys(filterOptions)))
  const { current: contextValueUpdaters } = useRef({
    addFilter: (newFilter: string) => {
      filterDispatch({ type: 'add', payload: newFilter })
    },
    removeFilter: (filterToRemove: string) => {
      filterDispatch({ type: 'remove', payload: filterToRemove })
    },
    setFilters: (updatedFilters: Set<string>) => {
      filterDispatch({ type: 'set', payload: updatedFilters })
    }
  })

  const contextValue = useMemo(
    () => ({ ...contextValueUpdaters, selectedCalendarFilters }),
    [selectedCalendarFilters, contextValueUpdaters]
  )

  return <ItemTypeFilterContext.Provider value={contextValue}>{children}</ItemTypeFilterContext.Provider>
}
