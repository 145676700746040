import { createExpertiseGroupMutation, deleteExpertiseGroupMutation } from './Mutations'
import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { t } from 'i18n'
import { addWarningBanner } from 'stores'

const fields = [Field('title', 'Title'), Field('type', 'Group type'), Field('description', 'Description')]

function ExpertiseGroupStore() {
  const store = AdminStore({
    fields,
    entityName: 'ExpertiseGroup',
    api: {
      index: 'indexExpertiseGroups'
    },
    header: { title: 'ExpertiseGroups' },
    search: { field: 'title', placeholder: 'Search for expertises' }
  })

  async function createExpertiseGroup(title, type) {
    const gqlClient = store.getGQLClient()
    const parameters = { expertiseGroup: { title, type } }

    return gqlClient.mutate(createExpertiseGroupMutation, parameters)
  }

  async function handleFile(appState, imageFiles) {
    const instanceUri = appState.getIn(['context', 'instanceUri'])
    const facilityId = appState.getIn(['authentication', 'facility', 'id'])

    //upload image to file storage and get URL
    const uploadImageUrl = `${instanceUri}/api/core/expertiseGroups/media`
    let bodyFormData = new FormData()
    bodyFormData.append('file', imageFiles[0])
    const options = {
      body: bodyFormData,
      method: 'POST',
      credentials: 'include',
      headers: {
        Scope: facilityId
      }
    }

    try {
      const response = await fetch(uploadImageUrl, options)
      if (!response.ok) {
        throw new Error()
      }
      const responseBody = await response.json()
      if (!responseBody.url) {
        throw new Error()
      }
      return responseBody.url
    } catch {
      addWarningBanner({ message: t('equipments.banners.equipment.image_upload_error') })
    }
  }

  async function createExpertiseSubgroup(appState, parentId, title, description, imageData, type) {
    const expertiseGroup = {
      parentId,
      title,
      description,
      type
    }
    if (imageData && imageData.length > 0) {
      try {
        const url = await handleFile(appState, imageData)
        expertiseGroup.imageUrl = url
      } catch (_err) {
        console.error('Error uploading equiment image', _err)
      }
    }
    const gqlClient = store.getGQLClient()
    const parameters = { expertiseGroup }

    return gqlClient.mutate(createExpertiseGroupMutation, parameters)
  }

  async function deleteExpertiseGroup(id) {
    const gqlClient = store.getGQLClient()
    const parameters = { id }

    return gqlClient.mutate(deleteExpertiseGroupMutation, parameters)
  }

  return {
    ...store,
    createExpertiseGroup,
    createExpertiseSubgroup,
    deleteExpertiseGroup
  }
}

export default ExpertiseGroupStore
