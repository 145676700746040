import { Component } from 'react'
import { withAppContext } from 'AppContext'
import { navigate } from 'Navigation'

export class Forbidden extends Component {
  render() {
    const { appState } = this.props
    const email = appState.getIn(['authentication', 'profile', 'email'])
    const handleLogOut = (e) => {
      e.preventDefault()
      return navigate.to.Logout()
    }
    return (
      <main className="row">
        <div className="col col-4 push-center relative" style={{ marginTop: '2em' }}>
          <div className="bg-white">
            <div className="p2">
              <span>
                No facility is linked to {email}. &nbsp;Please try{' '}
                <a href="." onClick={handleLogOut}>
                  logging in
                </a>
                &nbsp;again with the correct email.
              </span>
            </div>
            <footer className="p2 pt0">
              <button onClick={handleLogOut} className="button secondary">
                Logout
              </button>
            </footer>
          </div>
        </div>
      </main>
    )
  }
}

export default withAppContext(Forbidden)
