import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { fromJS, List, Map } from 'immutable'
import { activityItemsQuery } from './Queries'

export default function ActivitiesStore() {
  let time = null
  let gqlClient = null
  const actions = fluxStore({ loadActivities })

  const activityTypeGroups = {
    shift: ['shiftAdded', 'shiftRemoved', 'shiftUpdated'],
    paidTimeOff: [
      'timeOffRequestCreated',
      'timeOffRequestApproved',
      'timeOffRequestDenied',
      'paidTimeOffAdded',
      'paidTimeOffRemoved'
    ],
    openShift: ['openShiftPosted', 'openShiftRemoved', 'openShiftApproved', 'openShiftClaimed'],
    shiftSwap: [
      'shiftSwapCreated',
      'shiftSwapCanceled',
      'shiftSwapAccepted',
      'shiftSwapDeclined',
      'shiftSwapApproved',
      'shiftSwapDenied'
    ],
    schedule: ['scheduleOpened', 'scheduleClosed', 'schedulAutoBalanced', 'schedulePublished'],
    unavailability: ['unavailabilityAdded', 'unavailabilityRemoved']
  }

  return { initialize, ...actions }

  function initialize(state, context) {
    ;({ gqlClient, facilityTime: time } = context)
    return state.set('activities', Map({ items: Map({}), pager: Map({}) }))
  }

  function groupActivities(activities) {
    return List(activities)
      .sortBy((activity) => activity.get('createdAt'))
      .reverse()
      .groupBy((activity) => activity.get('createdAtMoment').format('YYYY-MM-DD'))
      .sortBy((_value, key) => key)
      .reverse()
  }

  function mapActivities(activities) {
    const mapper = (activity) => fromJS(activity).set('createdAtMoment', time.timeMoment(activity.createdAt))

    return activities.map(mapper)
  }

  async function loadActivities(state, unitId, page, perPage, endDate, search, userId, startDate, types = []) {
    const limit = perPage
    const offset = (page - 1) * perPage
    endDate = endDate || undefined
    const activityTypes = types.reduce((memo, type) => memo.concat(activityTypeGroups[type] || []), [])
    const parameters = { unitId, limit, offset, startDate, endDate, userId, search, activityTypes }
    const options = {
      operations: {
        activityItems: { includePagination: true }
      }
    }

    const { activityItems } = await gqlClient.query(activityItemsQuery, parameters, options)

    const { edges: activities, pageInfo: pager } = activityItems
    const items = mapActivities(activities)
    const itemsMap = groupActivities(items)

    return state.set('activities', Map({ items: itemsMap, pager }))
  }
}
