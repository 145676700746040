import { PureComponent } from 'react'
import classNames from 'classnames'
import { FilterMenuOption } from 'Manager/components/MultiSelectFilter'
import './IndicatorsSelector.scss'

export default class IndicatorsSelector extends PureComponent {
  state = { isActive: false, isHovered: false }

  render() {
    const { indicators } = this.props
    const { isActive } = this.state

    if (!indicators) {
      return null
    }

    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    const selectorDataItems = [
      {
        id: 'noteOrExplanation',
        label: 'Note or explanation',
        icon: 'large humanics-icons-Note'
      },
      {
        id: 'availabilityAndPreferences',
        label: 'Availability and preferences',
        onChange: this.updateConfigPreferences,
        leaveGapForIcon: true
      },
      {
        id: 'locks',
        label: 'Recurring days',
        icon: 'large icon-reset-indicators',
        indent: true
      },
      {
        id: 'available',
        label: 'Available',
        icon: 'large icon-checkmark',
        indent: true
      },
      {
        id: 'unavailable',
        label: 'Unavailable',
        icon: 'large icon-close',
        indent: true
      },
      {
        id: 'info',
        label: 'Info',
        icon: 'large icon-info',
        indent: true
      }
    ]

    return (
      <aside onBlur={this.hideDropdown} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <span className="bold">
            <sh-text size="title-1">
              {'Indicators '}
              <span>
                <i className={arrowClassName} />
              </span>
            </sh-text>
          </span>
        </button>
        <div className="hx-calendar-highlight-popup p20 bg-white filters-content" onMouseDown={this.onMouseDown}>
          <div style={{ width: '100%' }}>
            {selectorDataItems.map((dataItem) => {
              if (!dataItem) {
                return <hr key="hr" />
              }
              const props = { onChange: () => this.updateConfig(dataItem.id), ...dataItem }
              return <FilterMenuOption {...props} active={indicators.get(dataItem.id)} key={dataItem.id} />
            })}
          </div>
        </div>
      </aside>
    )
  }

  onMouseDown(e) {
    e.preventDefault()
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => ({
      isActive: !prevState.isActive
    }))
  }

  hideDropdown = (e) => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  cancelHide = () => {
    clearTimeout(this.tm)
  }

  updateConfig = (id) => {
    this.cancelHide()
    const { updateIndicator } = this.props
    updateIndicator((indicators) => indicators.set(id, !indicators.get(id)))
  }

  updateConfigPreferences = () => {
    this.cancelHide()
    const { updateIndicator } = this.props
    updateIndicator((indicators) => {
      const isChecked = !indicators.get('availabilityAndPreferences')
      return indicators.merge({
        availabilityAndPreferences: isChecked,
        locks: isChecked,
        available: isChecked,
        unavailable: isChecked,
        info: isChecked
      })
    })
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 500)
  }
}
