import { Component } from 'react'
import { Modal } from 'Common/components'
import EditStaffForm from './EditStaffForm'
import { navigate } from 'Navigation'
import { pick } from 'lodash'

export default class EditStaffModal extends Component {
  hideModal = () => {
    const { isEdit } = this.props
    if (isEdit) {
      return navigate.from.StaffProfileEdit.to.StaffProfilePage()
    }
  }

  render() {
    const { isEdit } = this.props

    const modalProps = pick(
      {
        ...this.props,
        width: 588,
        height: 522,
        visible: true,
        onClose: this.hideModal,
        title: isEdit ? 'edit Profile Information' : 'Add Staff'
      },
      ['title', 'width', 'height', 'visible', 'onClose', 'dismissOnOutsideClick', 'showClose']
    )

    const formProps = pick(
      {
        ...this.props,
        onClose: this.hideModal
      },
      [
        'staff',
        'userId',
        'isEdit',
        'onClose',
        'onSubmit',
        'timeService',
        'staffPosition',
        'managerFullName',
        'currentUnitId',
        'facilityId',
        'accountsUri'
      ]
    )

    return (
      <div>
        <Modal {...modalProps}>
          <EditStaffForm {...formProps} />
        </Modal>
      </div>
    )
  }
}
