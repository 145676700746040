import { List, Map } from 'immutable'
import classNames from 'classnames'
import { PureComponent } from 'react'
import { curry } from 'i18n'
import FilterMenuOption from './FilterMenuOption'
import './MultiSelectFilter.scss'

const t = curry('components.')

export default class ControlledMultiSelectFilter extends PureComponent {
  static defaultProps = { deselectAllAllowed: true }

  constructor(props) {
    super(props)
    const { options = Map(), values = List() } = props
    this.state = { options, values }
  }

  componentDidUpdate(prevProps) {
    const { options, values } = this.props
    const { options: prevOptions, values: prevValues } = prevProps

    const isOptionsChanged = options !== prevOptions
    const isValuesChanged = values !== prevValues

    if (isOptionsChanged || isValuesChanged) {
      this.setState({ options, values })
    }
  }

  render() {
    const { name, minWidth, icons, field, deselectAllAllowed, isActive, onClick } = this.props
    const { options, values } = this.state

    const style = `width-${minWidth}`
    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    const areAllOptionsSelected = this._isAllSelected()

    let textValue = this.getTextValue(values, options)

    return (
      <aside className="hx-filters" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={onClick}
        >
          <div className="option-value">
            <sh-text>{name}: </sh-text>
            <div className={`cmsf selected-value ${style}`}>
              <sh-text size="title-1">
                {textValue}
                <span>
                  <i className={arrowClassName} />
                </span>
              </sh-text>
            </div>
          </div>
        </button>
        <div className="custom-multiselect-filter p20 bg-white filters-content" onMouseDown={(e) => e.preventDefault()}>
          <div style={{ width: '100%' }}>
            <FilterMenuOption
              id={`hx-checkbox[${field}]["all"]`}
              key="all"
              active={areAllOptionsSelected}
              disabled={areAllOptionsSelected && !deselectAllAllowed}
              bordered={true}
              label="All"
              onChange={(e) => this.updateConfig('all', e)}
              icon={icons?.['all']}
            />
            {options.keySeq().map((key) => {
              const checked = values.includes(key)
              return (
                <FilterMenuOption
                  id={`hx-checkbox[${field}][${key}]`}
                  onChange={(e) => this.updateConfig(key, e)}
                  label={options.get(key)}
                  active={checked}
                  disabled={!deselectAllAllowed && this._isLastOneSelected(key)}
                  key={key}
                  icon={icons?.[key]}
                />
              )
            })}
          </div>
        </div>
      </aside>
    )
  }

  getTextValue(values, options) {
    const isAllOptionsSelected = this._isAllSelected()
    let textValue = ''

    switch (true) {
      case isAllOptionsSelected:
        textValue = t('select.all')
        break

      case values.size === 1: {
        const value = values.get(0)
        textValue = options.get(value)
        break
      }
      case values.size === 0:
        textValue = t('select.zero')
        break

      default:
        textValue = t('select.multiple', { size: values.size })
    }

    return textValue
  }

  _getAllOptions() {
    const { options } = this.props
    return options
      .keySeq()
      .filter((option) => option !== 'all')
      .toList()
  }

  _isAllSelected() {
    const { values } = this.state
    return this._getAllOptions().every((option) => values.includes(option))
  }

  _isLastOneSelected(option) {
    const { values } = this.state
    return values.size === 1 && values.includes(option)
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive
    }))
  }

  updateConfig = (option, event) => {
    const { setConfig, deselectAllAllowed } = this.props
    const { values } = this.state
    event.preventDefault()

    if (option === 'all') {
      const allOptions = this._getAllOptions()
      const isAllSelected = this._isAllSelected()
      let newConfig = deselectAllAllowed && isAllSelected ? List() : allOptions
      return setConfig(newConfig)
    }
    const config = values.includes(option) ? values.filter((o) => o !== option) : values.push(option)
    return setConfig(config)
  }

  hideDropdown = () => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 200)
  }
}
