import stripCssComments from 'strip-css-comments'

export default class PrintTemplate {
  constructor(content, css, stylesLinks) {
    this.content = content
    this.css = css
    this.stylesLinks = stylesLinks
  }

  async getHtml() {
    let html

    html = await this._buildTemplate()
    html = this._stripComments(html)
    html = this._closeSelfClosingTags(html)
    html = this._replaceAssetsLocalPaths(html)

    return html
  }

  async _fetchCss(link) {
    const data = await fetch(link)
    return data.text()
  }

  async _buildTemplate() {
    const links = Array.from(this.stylesLinks.values()).map((link) => link.href)

    for (const link of links) {
      const linkCss = await this._fetchCss(link)
      this.css += `\n<style>${linkCss}</style>`
    }

    return `
      <html lang='en'>
        <head>
          ${this.css} 
          <style>@media print { @page { size: US-Letter landscape } }</style>
          <style>body { overflow: visible !important; }</style>
          <style>
            .hx-print-view, #hx-print-view { display: block !important; }
            select.nowrap-style { display: none; }
          </style>
        </head>
        <body>
          <div id="root" class="hx-pdf-template">
            <section class="col-12 _calendarGridWrapper _modeFull _highlight-annotation _highlight-lock _highlight-available _highlight-unavailable" id="hx-calendar">
              <div>
                ${this.content}
              </div>
            </section>
          </div>
        </body>
      </html>
    `
  }

  _stripComments(html) {
    // TODO: remove comments:
    return stripCssComments(html)
  }

  _closeSelfClosingTags(html) {
    // Close img tag:
    html = html.replace(/(<img("[^"]*"|[^/">])*)>/gi, '$1/>')

    // Close hr tag:
    html = html.replace(/(<hr("[^"]*"|[^/">])*)>/gi, '$1/>')
    return html
  }

  _replaceAssetsLocalPaths(html) {
    const { host, protocol, hostname } = document.location

    // Skip replacement for localhost as full path is already present in localhost
    if (hostname?.includes('instance.humanics.local')) {
      return html
    }

    return html.replace(/\/static/g, `${protocol}//${host}/static`)
  }
}
