import { List, Map } from 'immutable'
import classNames from 'classnames'
import { PureComponent, Fragment } from 'react'
import './MultiSelectFilter.scss'

export default class GroupedMultiSelectFilter extends PureComponent {
  static defaultProps = { deselectAllAllowed: true, showHist: true }

  constructor(props) {
    super(props)
    const { options = Map(), values = List() } = props
    this.state = { isActive: false, isHovered: false, options, values }
  }

  componentDidUpdate(prevProps) {
    const { options, values } = this.props
    const { options: prevOptions, values: prevValues } = prevProps

    const isOptionsChanged = options !== prevOptions
    const isValuesChanged = values !== prevValues

    if (isOptionsChanged || isValuesChanged) {
      this.setState({ options, values })
    }
  }

  render() {
    const { name, minWidth, icons, field, deselectAllAllowed, showHist } = this.props
    const { isActive, options, values } = this.state

    const style = `width-${minWidth}`
    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    const isAllOptionsSelected = this._isAllSelected()

    let textValue
    if (showHist) {
      switch (true) {
        case isAllOptionsSelected:
          textValue = 'All'
          break

        case values.size === 1:
          textValue = '1 value'
          break

        case values.size === 0:
          textValue = 'Select value(s)'
          break

        default:
          textValue = `${values.size} values`
      }
    }

    const isAllOptionDisabled = deselectAllAllowed && isAllOptionsSelected

    const Checkbox = ({ option, label, checked, subItem }) => (
      <li
        className={classNames({
          'hx-checkbox p10': true,
          bb1: option === 'all',
          'ph0 mt10': !subItem,
          ml10: !!subItem
        })}
      >
        <input
          type="checkbox"
          id={`hx-checkbox[${field}][${option}]`}
          checked={checked}
          onChange={() => this.updateConfig(option)}
        />
        <label className="regent-gray" htmlFor={`hx-checkbox[${field}][${option}]`}>
          {label}
        </label>
        <i className={icons?.[option]} />
      </li>
    )

    const CheckboxWithDescription = ({ option, label, checked, subItem }) => (
      <li
        className={classNames({
          'hx-checkbox p10': true,
          bb1: option === 'all',
          ph0: !subItem,
          ml10: !!subItem
        })}
      >
        <input
          type="checkbox"
          id={`hx-checkbox[${field}][${option}]`}
          checked={checked}
          onChange={() => this.updateConfig(option)}
        />
        <label className="grouped regent-gray" htmlFor={`hx-checkbox[${field}][${option}]`}>
          <span className="title">{label.get('name')}</span>
          {label.get('description') && <span className="description regent-gray">: {label.get('description')}</span>}
        </label>
        <i className={icons && icons[option]} />
      </li>
    )

    return (
      <aside className="hx-filters" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <div className="option-value">
            <sh-text>{name}: </sh-text>
            <div className={`selected-value ${style}`}>
              <sh-text size="title-1">
                {textValue}
                <span>
                  <i className={arrowClassName} />
                </span>
              </sh-text>
            </div>
          </div>
        </button>
        <div className="custom-multiselect-filter p20 bg-white filters-content" onMouseDown={(e) => e.preventDefault()}>
          <ul>
            <Checkbox
              key="all"
              checked={isAllOptionsSelected}
              disabled={isAllOptionDisabled}
              label="All"
              option="all"
            />
            {options.keySeq().map((key) => {
              const items = options.get(key)
              const checked = items.keySeq().every((item) => values.includes(item))

              const checkboxProps = {
                option: key,
                label: key,
                checked
              }
              return (
                <Fragment key={key}>
                  <Checkbox {...checkboxProps} />
                  {items.keySeq().map((itemKey) => {
                    const checked = values.includes(itemKey)
                    const label = items.get(itemKey)
                    const checkboxProps = {
                      option: itemKey,
                      label: label,
                      checked,
                      subItem: true
                    }
                    if (Map.isMap(label)) {
                      return <CheckboxWithDescription {...checkboxProps} key={itemKey} />
                    } else {
                      return <Checkbox {...checkboxProps} key={itemKey} />
                    }
                  })}
                </Fragment>
              )
            })}
          </ul>
        </div>
      </aside>
    )
  }

  _getAllOptions() {
    const { options } = this.props
    return options
      .keySeq()
      .map((option) => options.get(option).keySeq())
      .flatten(1)
      .toList()
  }

  _isAllSelected() {
    const { values } = this.state
    return this._getAllOptions().every((option) => values.includes(option))
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => ({
      isActive: !prevState.isActive
    }))
  }

  updateConfig = (option) => {
    const { options, setConfig, deselectAllAllowed } = this.props
    const { values } = this.state

    if (option === 'all') {
      const allOptions = this._getAllOptions()
      const isAllSelected = this._isAllSelected()
      const config = deselectAllAllowed && isAllSelected ? List() : allOptions
      return setConfig(config)
    }
    if (options.keySeq().includes(option)) {
      const items = options.get(option)
      const itemValues = items.keySeq()
      const isGroupSelected = itemValues.every((item) => values.includes(item))
      const valuesWithoutGroup = values.filter((o) => !itemValues.includes(o))
      const config = isGroupSelected ? valuesWithoutGroup : List([...valuesWithoutGroup, ...itemValues])
      return setConfig(config)
    }
    const config = values.includes(option) ? values.filter((o) => o !== option) : values.push(option)

    if (!deselectAllAllowed && config.size === 0) {
      return setConfig(values)
    }

    return setConfig(config)
  }

  hideDropdown = (e) => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 300)
  }
}
