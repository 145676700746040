import { PureComponent } from 'react'
import { Link, Switch } from 'react-router-dom'
import { PrivateRoute } from 'auth'
import { withAppContext } from 'AppContext'
import { Admin, Header, Search } from '@humanics/he-react-common/lib/admin/components'
import FacilityUsersAccordion from './FacilityUsersAccordion'
import DataController from './DataController'
import { navigate, paths } from 'Navigation'
import { FacilityUsersCreate, FacilityUsersUpdate } from 'Admin/modules/facilityUsers'

export class FacilityUsersAdmin extends PureComponent {
  constructor(props) {
    super(props)

    this.dataController = new DataController(this)
    this.state = { query: '', apiInitialized: false }
  }

  componentDidMount() {
    ;(async () => {
      await this.dataController.facilityUsersStore.getApi()
      this.dataController.loadData()
      this.setState({ apiInitialized: true })
    })()
  }

  render() {
    const { appState } = this.props
    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }

    const { facilityUsers, facilityUsersStore, adminStores: stores } = this.dataController
    const timeService = appState.getIn(['context', 'facilityTime'])

    return (
      <Admin>
        {this.renderHeader()}
        {this.renderSearch(facilityUsers)}
        {this.renderFacilityUsersAccordion(facilityUsers)}
        <Switch>
          <PrivateRoute
            path={paths.AdminStaffNew}
            exact
            component={FacilityUsersCreate}
            componentProps={{ appState, store: facilityUsersStore, stores }}
            timeService={timeService}
          />
          <PrivateRoute
            path={paths.AdminStaffUpdate}
            exact
            component={FacilityUsersUpdate}
            componentProps={{ appState, store: facilityUsersStore, stores }}
            timeService={timeService}
          />
        </Switch>
      </Admin>
    )
  }

  renderFacilityUsersAccordion(facilityUsers) {
    const { appState } = this.props
    const timeService = appState.getIn(['context', 'facilityTime'])
    const authUserId = appState.getIn(['authentication', 'facilityUser', 'userId'])
    const { facilityUsersStore } = this.dataController

    const facilityUsersAccordionProps = {
      timeService,
      facilityUsers,
      facilityUsersStore,
      navigateTo: this.navigateTo,
      authUserId
    }

    return <FacilityUsersAccordion {...facilityUsersAccordionProps} />
  }

  renderSearch(facilityUsers) {
    const { search, header } = this.dataController.adminInfo

    return (
      <div className="p2 bg-white pb0">
        <Search
          placeholder={search?.placeholder}
          onSearch={(query) => this.setState({ query })}
          onReset={(e) => e?.preventDefault()}
          itemsFound={facilityUsers}
          entityName={header.title}
        />
      </div>
    )
  }

  renderHeader() {
    const { facilityUsersStore, adminInfo } = this.dataController
    const { header } = adminInfo

    const linkTo = navigate.from.AdminStaff.to.AdminStaffNew(null, false)
    const allowCreate = facilityUsersStore.hasCrud('create', 'createFacilityUser')

    const actions = allowCreate ? (
      <Link to={linkTo} className="button secondary">
        {header.createLabel || `Create ${header.label}`}
      </Link>
    ) : null

    return <Header title={header.title} actions={actions} />
  }
}

export default withAppContext(FacilityUsersAdmin)
