import { Component } from 'react'
import { startCase, capitalize } from 'lodash'
import { curry } from 'i18n'
import { navigate } from 'Navigation'
import ExpertiseCard from './ExpertiseCard'
import { getExpiryMessage } from './utils'

const t = curry('staff.staffExpertiseCard.')

class Skills extends Component {
  render = () => {
    const createSkillPath = navigate.from.StaffDetails.to.StaffCreateSkill({}, false)
    const editSkillPath = (expertiseId) => navigate.from.StaffDetails.to.StaffEditSkill({ expertiseId }, false)

    const skillCardProps = {
      ...this.props,
      cardTitle: t('skillCardTitle'),
      expertiseType: 'skill',
      getExpertiseTitle: this.getSkillTitle,
      getExpertiseInfo: this.getSkillInfo,
      createExpertisePath: createSkillPath,
      editExpertisePath: editSkillPath,
      isGrouped: true
    }
    return <ExpertiseCard {...skillCardProps} />
  }

  getSkillTitle = (skill) => {
    const scaleItems = skill.get('scaleItems')
    const staffExpertiseCredential = skill.get('staffExpertiseCredential')
    const scaleIndex = staffExpertiseCredential.get('scaleIndex')

    const assesssedCompetency = capitalize(startCase(scaleItems.get(scaleIndex)))

    return (
      <span>
        {skill.get('name')}: <span className="regent-gray">{assesssedCompetency}</span>
      </span>
    )
  }

  getSkillInfo = (skill) => {
    const staffExpertiseCredential = skill.get('staffExpertiseCredential')
    const reviewedDateTime = staffExpertiseCredential.get('issuedAt')
    const expirationDateTime = staffExpertiseCredential.get('expiresAt')

    const { timeService } = this.props
    const format = 'll' // Feb 2, 2022

    let infoItems = []
    if (reviewedDateTime) {
      const formattedReviewedDate = timeService.timeMoment(reviewedDateTime).format(format)
      infoItems.push(`${t('reviewedLabel')}: ${formattedReviewedDate}`)
    }
    if (expirationDateTime) {
      const formattedExpirationDate = timeService.timeMoment(expirationDateTime).format(format)
      infoItems.push(`${t('expiryLabel')}: ${formattedExpirationDate}`)
    }
    if (infoItems.length === 0) {
      return t('noDetails')
    }

    infoItems = [infoItems.join(' / ')]

    const expiryMessage = getExpiryMessage(skill, timeService)
    if (expiryMessage) {
      infoItems.push(expiryMessage)
    }

    return infoItems
  }
}

export default Skills
