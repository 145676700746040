import { MouseEvent, PropsWithChildren } from 'react'

export interface ICommonCardProps {
  title?: string
  titleIcon?: string
  actionButtonIcon?: string
  onClickActionButton?: (e: MouseEvent) => void
  isActionButtonDisabled?: boolean
  isLoading?: boolean
  loadingText?: string
  loadingSpinnerText?: string
  noContentLabel?: string
  height?: number
}

export default function CommonCard({
  actionButtonIcon,
  children,
  isLoading,
  titleIcon,
  isActionButtonDisabled,
  height,
  title = '',
  onClickActionButton = () => {},
  loadingSpinnerText = 'Loading...',
  noContentLabel = 'Nothing to show here'
}: PropsWithChildren<ICommonCardProps>) {
  let cardContents = <sh-empty-state icon="open" label={noContentLabel} />
  if (isLoading) {
    cardContents = <sh-spinner label={loadingSpinnerText} />
  } else if (children) {
    cardContents = (
      <>
        <sh-icon
          slot="functions"
          button
          size="s"
          icon={actionButtonIcon}
          onClick={onClickActionButton}
          disabled={isActionButtonDisabled}
        ></sh-icon>
        {children}
      </>
    )
  }
  return (
    <div style={{ height: `${height}px` }}>
      <sh-card label={title} icon={titleIcon}>
        {cardContents}
      </sh-card>
    </div>
  )
}
