import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { List, Map } from 'immutable'
import { getAnnouncementQuery, loadAnnouncementsQuery, loadAnnouncementTemplatesQuery } from './Queries'
import {
  createAnnouncementMutation,
  createAnnouncementTemplateMutation,
  deleteAnnouncementMutation,
  deleteAnnouncementTemplateMutation,
  publishAnnouncementMutation,
  updateAnnouncementMutation,
  updateAnnouncementTemplateMutation
} from './Mutations'

function AnnouncementsStore() {
  let gqlClient = null

  const actions = fluxStore({
    setAnnouncementsRetryOptions,
    loadAnnouncements,
    getAnnouncement,
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
    loadAnnouncementTemplates,
    createAnnouncementTemplate,
    updateAnnouncementTemplate,
    deleteAnnouncementTemplate
  })

  return {
    initialize,
    ...actions
  }

  function initialize(state, context) {
    ;({ gqlClient } = context)
    return state
      .set('announcements', Map({ items: Map({}), pager: Map({}) }))
      .set('announcementTemplates', Map({ items: Map({}), pager: Map({}) }))
  }

  /* -------------------- Announcements -------------------- */

  function setAnnouncementsRetryOptions(state, shouldRetry) {
    /* just sets a flag to know if the announcement was loaded from the 'Retry' button
    or from the  'edit' icon */
    return state.setIn(['announcements', 'announcementWasRetried'], shouldRetry)
  }

  async function loadAnnouncements(state, startDate, endDate, append, unitId, limit = 1000, offset = 0, status = null) {
    const params = { startDate, endDate, limit, offset, status, unitId }
    const options = {
      operations: {
        announcements: { includePagination: true }
      }
    }

    const { announcements } = await gqlClient.query(loadAnnouncementsQuery, params, options)
    const { edges: announcementsList = List(), pageInfo: pager } = announcements
    // Lines below are what I used to simulate the failure of an announcement.
    // PLEASE DETETE THEM when we have a straightforward way to make failed announcements purposefully
    // TODO should be covered by unit-tests instead
    if (window.allTheUnsentAnnouncementsWillFail) {
      state = state.setIn(['generalData', 'notifications', 'announcements'], 1)
      announcementsList
        .filter((a) => a.status === 'draft')
        .map((a) => {
          a.status = 'scheduled'
          a.startsAt = '2018-11-16T06:00:00.000Z'
          return a
        })
    }

    if (!append) {
      return state.set('announcements', Map({ items: announcementsList, pager })) // runs on first load
    }
    const previousAnnouncements = state.getIn(['announcements', 'items'])
    return state.set('announcements', Map({ items: previousAnnouncements.concat(announcementsList), pager }))
  }

  async function getAnnouncement(state, id) {
    const params = { id }
    const announcement = await gqlClient.query(getAnnouncementQuery, params)
    return state.setIn(['announcements', 'activeAnnouncementItem'], announcement)
  }

  async function createAnnouncement(state, createAnnouncementInput, sendNow) {
    if (createAnnouncementInput.badgeUrl === '') {
      createAnnouncementInput.badgeUrl = undefined
    }
    const params = { parameters: createAnnouncementInput }

    const { createAnnouncement: newlyCreatedAnnouncement } = await gqlClient.mutate(createAnnouncementMutation, params)
    if (sendNow) {
      await publishAnnouncement(newlyCreatedAnnouncement.id)
    }

    return state.setIn(['announcements', 'lastUpdatedAnnouncementItem'], newlyCreatedAnnouncement)
  }

  function publishAnnouncement(id) {
    return gqlClient.mutate(publishAnnouncementMutation, { id })
  }

  async function updateAnnouncement(state, id, updateAnnouncementInput, sendNow) {
    if (updateAnnouncementInput.badgeUrl === '') {
      updateAnnouncementInput.badgeUrl = undefined
    }
    const params = { id, parameters: updateAnnouncementInput }
    const { updateAnnouncement } = await gqlClient.mutate(updateAnnouncementMutation, params)

    if (sendNow) {
      await publishAnnouncement(id)
    }

    return state.setIn(['announcements', 'lastUpdatedAnnouncementItem'], updateAnnouncement)
  }

  async function deleteAnnouncement(state, id) {
    const params = { id }
    const { deleteAnnouncement } = await gqlClient.mutate(deleteAnnouncementMutation, params)
    return state.setIn(['announcements', 'lastDeletedAnnouncementItem'], deleteAnnouncement)
  }

  /* -------------------- Announcement Templates -------------------- */
  async function loadAnnouncementTemplates(state, limit = 20, offset = 0, search = null) {
    const params = { limit, offset, search }
    const options = {
      operations: {
        announcementTemplates: { includePagination: true }
      }
    }

    const { announcementTemplates } = await gqlClient.query(loadAnnouncementTemplatesQuery, params, options)
    const { edges: announcementTemplatesList, pageInfo: pager } = announcementTemplates
    return state.set('announcementTemplates', Map({ items: announcementTemplatesList, pager }))
  }

  async function createAnnouncementTemplate(state, CreateAnnouncementTemplateInput) {
    if (CreateAnnouncementTemplateInput.badgeUrl === '') {
      CreateAnnouncementTemplateInput.badgeUrl = undefined
    }
    const params = { parameters: CreateAnnouncementTemplateInput }
    const { createAnnouncementTemplate: newlyCreatedAnnouncementTemplate } = await gqlClient.mutate(
      createAnnouncementTemplateMutation,
      params
    )
    return state.setIn(['announcementTemplates', 'lastUpdatedAnnouncementTemplate'], newlyCreatedAnnouncementTemplate)
  }

  async function updateAnnouncementTemplate(state, id, UpdateAnnouncementTemplateInput) {
    const params = { id, parameters: UpdateAnnouncementTemplateInput }
    const { updateAnnouncementTemplate } = await gqlClient.mutate(updateAnnouncementTemplateMutation, params)
    return state.setIn(['announcementTemplates', 'lastUpdatedAnnouncementTemplate'], updateAnnouncementTemplate)
  }

  async function deleteAnnouncementTemplate(state, id) {
    const params = { id }
    const { deleteAnnouncementTemplate } = await gqlClient.mutate(deleteAnnouncementTemplateMutation, params)
    return state.setIn(['announcementTemplates', 'lastDeletedAnnouncementTemplate'], deleteAnnouncementTemplate)
  }
}

export default AnnouncementsStore
