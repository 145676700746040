import { Component } from 'react'
import { Sentry } from 'services'

//TODO: Shift to react-common to share with react-accounts
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    if (this.props.onError) {
      this.props.onError(error, info)
    }
    Sentry.trackError(error)
  }

  render() {
    // TODO:
    if (this.state.hasError) {
      if (this.props.FallBackComponent) {
        return this.props.FallBackComponent
      }
      return null
    }

    return this.props.children
  }
}
