import classNames from 'classnames'
import { startCase } from 'lodash'
import { OpenShiftStaff } from 'entityWrappers'
import Avatar from 'Manager/components/Avatar'
import Staff from './Staff'

export default class ScheduledStaff extends Staff {
  render() {
    const { staff, openShiftController } = this.props
    const { openShift } = openShiftController
    const openShiftStaff = new OpenShiftStaff(openShift, staff)
    const { fullName, userId, hoursThisWeek = 0, employmentType, maximumNumberOfHoursPerWeek } = openShiftStaff

    const isOvertimed = maximumNumberOfHoursPerWeek < hoursThisWeek

    return (
      <div className="bg-alabaster hx-calendar-staff-info-wrapper bt1">
        <Avatar
          userId={userId}
          profile={staff.get('profile')}
          size={32}
          avatarClass="rounded-16"
          initialsClass="bg-periwinklegray rounded-16 hx-staff-initials"
        />{' '}
        <div className="staff-meta">
          {fullName}
          <div className="small regent-gray">
            {startCase(employmentType)}
            <span className="bold">&nbsp;·&nbsp;</span>
            <span className={classNames({ scarlet: isOvertimed })}>{hoursThisWeek}</span>
            <span>&nbsp;/&nbsp;</span>
            <span>{maximumNumberOfHoursPerWeek}hrs</span>
          </div>
        </div>
      </div>
    )
  }
}
