import { ComponentController } from 'Common/components'
import { differenceBy } from 'lodash'

export default class ConstraintsController extends ComponentController {
  get defaultState() {
    return {
      isLoading: true,
      isConstraintsLoading: true,
      isCalendarLoaded: false
    }
  }
  async getConstraints() {
    const { constraintStore, unit } = this.props
    const unitId = unit.get('id')
    const constraints = await constraintStore.readConstraints(unitId)
    const enabledConstraints = this._getEnabledConstraints(constraints)
    const userPreferredConstraints = this._getUserPreferredConstraints(constraints)
    const enabledUserPreferredConstraints = this._getEnabledUserPreferencesConstraints(
      enabledConstraints,
      userPreferredConstraints
    )
    const constraint = this._getConstraintsByUserPreference(enabledConstraints, enabledUserPreferredConstraints)
    const disabledConstraint = this._getDisabledConstraints(constraints)
    const allConstraints = this._getAllConstraints(constraint, disabledConstraint)
    this.setState({
      constraints: allConstraints,
      isLoading: false,
      isCalendarLoaded: false,
      isConstraintsLoading: false,
      isError: false
    })
  }
  _getUserPreferredConstraints(constraints) {
    return constraints.filter((constraint) => constraint.isPreferred)
  }

  async updateConstraint(constraint) {
    const { constraintStore } = this.props
    await constraintStore.updateConstraint(constraint)
    this.getConstraints()
  }

  _getEnabledConstraints(constraints) {
    const enabledConstraints = constraints.filter((constraint) => constraint.enabled)
    return enabledConstraints.map((enabledConstraint) => {
      enabledConstraint.priority = null
      return enabledConstraint
    })
  }

  _getDisabledConstraints(constraints) {
    const disabledConstraints = constraints.filter((constraint) => !constraint.enabled)
    return disabledConstraints.map((disabledConstraint) => {
      return disabledConstraint
    })
  }

  _getAllConstraints(enabledConstraints, disabledConstraints) {
    const sortedEnabledConstraints = enabledConstraints.sort((firstConstraint, secondConstraint) =>
      secondConstraint.type.localeCompare(firstConstraint.type)
    )
    return [...sortedEnabledConstraints, ...disabledConstraints]
  }

  _getEnabledUserPreferencesConstraints(enabledConstraints, userPreferredConstraints) {
    const enabledUserPreferredConstraints = []
    enabledConstraints.forEach((enabledConstraint) => {
      const index = userPreferredConstraints.findIndex(
        (userPreferredConstraint) => userPreferredConstraint.id === enabledConstraint.id
      )
      if (index !== -1) {
        const name = enabledConstraint.name
        enabledUserPreferredConstraints.push({ ...enabledConstraint, name: name, isUserPreference: true })
      }
    })

    return enabledUserPreferredConstraints
  }

  _getConstraintsByUserPreference(enabledConstraints, userPreferredConstraints) {
    if (userPreferredConstraints.length === 0) {
      return enabledConstraints
    }
    let sortedUserPreferredConstraints = userPreferredConstraints
    if (window.REACT_APP_RANKING_ENABLED_FOR_ALGO_SCHEDULE === 'true') {
      sortedUserPreferredConstraints = userPreferredConstraints.sort(
        (firstConstraint, secondConstraint) => firstConstraint.rank - secondConstraint.rank
      )
    }
    const nonPreferredConstraints = differenceBy(enabledConstraints, sortedUserPreferredConstraints, 'name')
    return [...sortedUserPreferredConstraints, ...nonPreferredConstraints]
  }
}
