import { Component } from 'react'
import { List, Map, fromJS } from 'immutable'
import './MismatchWarningModal.scss'
import Modal from '@humanics/he-react-common/lib/components/Modal_new'
import { min, startCase, capitalize } from 'lodash'

export default class MismatchWarningModal extends Component {
  onSubmit = () => {
    const { actionController, expertiseMismatchDetails, unitShiftsMap, onCancel, eventStaffDetails = {} } = this.props
    const { eventShiftId, note } = expertiseMismatchDetails
    const shift = unitShiftsMap.get(eventShiftId)
    const eventVariantId = 'assignment'
    const eventParameters = shift.merge(
      {
        eventShiftId,
        eventVariantId,
        shiftId: eventShiftId,
        addStaffToOnCallShift: eventStaffDetails.addStaffToOnCallShift
      },
      fromJS(eventStaffDetails)
    )
    actionController.createStaffEventsForSelection(eventParameters, note, true)
    onCancel()
  }

  getSelection = () => {
    const { expertiseMismatchDetails } = this.props
    const { selection } = expertiseMismatchDetails
    return selection
  }

  getExpiresAtMoment(expiresAt) {
    const { timeService } = this.props
    return expiresAt ? timeService.timeMoment(expiresAt) : null
  }

  getExpiresAtFormatted(expiresAtMoment) {
    return expiresAtMoment ? expiresAtMoment.format('D MMM YYYY') : ''
  }

  getMappedMismatchedDetails() {
    const { expertises, expertiseMismatchDetails, unitShiftsMap } = this.props
    const { errors, eventShiftId } = expertiseMismatchDetails
    if (!expertises || !errors || !eventShiftId || !unitShiftsMap) {
      return null
    }

    const shift = unitShiftsMap.get(eventShiftId)
    const expertiseRequirements = shift.get('expertiseRequirements')
    return List(errors)
      .filter(
        (item) =>
          expertises.keySeq().includes(item.expertiseId) &&
          expertiseRequirements.find((req) => req.get('expertiseId') === item.expertiseId)
      )
      .map((item) => {
        const { expertiseId, scaleIndex, expiresAt } = item
        const expertise = expertises.get(expertiseId).get(0)
        const type = expertise.get('type')
        const requirement = expertiseRequirements.find((req) => req.get('expertiseId') === expertiseId)
        const expiresAtMoment = this.getExpiresAtMoment(expiresAt)
        const expiresAtFormatted = this.getExpiresAtFormatted(expiresAtMoment)
        let showTooltip = true
        let status = item.status
        let requirements = {
          label: 'Expiry date',
          type: 'expired',
          status: expiresAtFormatted
        }
        if (['equipment', 'skill'].includes(type) && status !== 'Expired') {
          const eligibleScaleIndexes = requirement.get('eligibleScaleIndexes', List()).toArray()
          const eligibleScaleIndex = min(eligibleScaleIndexes)
          const scaleItems = expertise.get('scaleItems')
          const scaleName = scaleItems.get(eligibleScaleIndex ? eligibleScaleIndex : 0)
          status = scaleIndex ? startCase(capitalize(scaleItems.get(scaleIndex))) : item.status
          requirements = {
            label: 'Required level',
            type: 'mismatch',
            status: startCase(capitalize(scaleName))
          }
          showTooltip = !!scaleIndex
        } else {
          showTooltip = !!expiresAt
        }
        return Map({
          name: type === 'equipment' ? expertise.get('fullName') : expertise.get('name'),
          type,
          status,
          requirements,
          showTooltip
        })
      })
      .groupBy((item) => item.get('type'))
  }

  componentDidMount() {
    const { multiselectController } = this.props
    const { staffPath, selectedCells } = this.getSelection()
    multiselectController.selectMultipleCells(staffPath, selectedCells)
  }

  render() {
    const { onCancel } = this.props
    const mappedMismatchDetails = this.getMappedMismatchedDetails()
    if (!mappedMismatchDetails || mappedMismatchDetails.size === 0) {
      return null
    }
    return (
      <div className="hx-warning-modal">
        <Modal type="warning">
          <Modal.Title>This person doesn't match the shift's requirements:</Modal.Title>
          <>
            {mappedMismatchDetails.keySeq().map((type) => (
              <Modal.Content key={type}>
                <div className="group-title regent-gray">{type}</div>
                {mappedMismatchDetails.get(type).map((item, key) => (
                  <div key={`${type}-${key}`} className="group-item">
                    <div className="item-title">{item.get('name')}:</div>{' '}
                    <div className="item-status">{item.get('status')}</div>
                    {item.get('showTooltip') && (
                      <>
                        <div className="info-tooltip">
                          <i id={`${type}-${key}`} className="icon icon-info" />
                          <div className="info-tooltip-text">
                            <div className="regent-gray">{item.get('requirements').label}: </div>
                            <div className={`required-status ${item.get('requirements').type}`}>
                              {item.get('requirements').status}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </Modal.Content>
            ))}
            <Modal.Content>
              <div className="group-item">Are you sure you want to add them?</div>
            </Modal.Content>
            <Modal.Buttons>
              <Modal.OKButton label="Yes" onClick={this.onSubmit} />
              <Modal.CancelButton label="Go Back" onClick={onCancel} />
            </Modal.Buttons>
          </>
        </Modal>
      </div>
    )
  }
}
