import { Component } from 'react'
import TimeOffRequestPending from './TimeOffRequestPending'

export default class TimeOffRequest extends Component {
  render() {
    const { timeOffRequest, rowWidth } = this.props

    if (!rowWidth) {
      return null
    }

    const status = timeOffRequest.get('status')
    const timeOffRequestProps = {
      ...this.props,
      style: this.getStyle()
    }

    return <>{status === 'pending' && <TimeOffRequestPending {...timeOffRequestProps} />}</>
  }

  getStyle = () => {
    const { rowWidth, timeService, timeOffRequest, isTimeOffsLoaded } = this.props
    const dateRangeStartsAt = timeOffRequest.get('dateRangeStartsAt')
    const dateRangeEndsAt = timeOffRequest.get('dateRangeEndsAt')
    const isInOneDateRange = timeOffRequest.get('isInOneDateRange')
    let dateRangeDaysCount = timeOffRequest.get('dateRangeDaysCount')

    const prevSunday = timeService.timeMoment(dateRangeStartsAt).startOf('week')
    const nextSunday = timeService.timeMoment(dateRangeEndsAt)

    if (nextSunday.day() !== 0) {
      nextSunday.startOf('week').add(1, 'week')
    }

    dateRangeDaysCount = nextSunday.diff(prevSunday, 'days')

    const leftOffset = timeService.timeMoment(dateRangeStartsAt).day()

    const borderWidth = 4

    const dates = timeOffRequest.get('datesInDateRange')

    const firstDateMoment = timeService.timeMoment(dates.first()?.get('date'))
    const lastDateMoment = timeService.timeMoment(dates.last()?.get('date'))

    const timeOffRequestDaysCount = dates.size
    const offsetInDays = firstDateMoment.diff(dateRangeStartsAt, 'days') + leftOffset

    const scheduleWeeks = dateRangeDaysCount / 7

    //In case of weekly schedule previousSundayDate is same as dateRangeStartsAt (Schedule start date)
    //In case of monthly schedule we have to consider this date to get proper border correction values
    const previousSundayDate = prevSunday.toISOString()

    const weeksBeforeTimeOffRequest = firstDateMoment.diff(previousSundayDate, 'weeks')
    const weeksBeforeEndTimeOffRequest = lastDateMoment.diff(previousSundayDate, 'weeks')

    const weekendsInTimeOffRequest = weeksBeforeEndTimeOffRequest - weeksBeforeTimeOffRequest
    const { borderLeftStyles = {}, borderRightStyles = {} } = isInOneDateRange
      ? {}
      : this._getStylesForPartialTimeOffRequest(dates)

    const widthBorderCorrection = weekendsInTimeOffRequest * borderWidth
    const offsetBorderCorrection = weeksBeforeTimeOffRequest * borderWidth

    const bordersAmount = scheduleWeeks
    const cellWidth = (rowWidth - bordersAmount * borderWidth) / dateRangeDaysCount
    const width = timeOffRequestDaysCount * cellWidth + widthBorderCorrection
    const offset = offsetInDays * cellWidth + offsetBorderCorrection

    return {
      ...borderLeftStyles,
      ...borderRightStyles,
      opacity: isTimeOffsLoaded ? '1' : '0.2',
      left: `${offset}px`,
      width: `${width}px`
    }
  }

  _getStylesForPartialTimeOffRequest(dates) {
    const { timeOffRequest, timeService } = this.props
    const dateRangeStartsAt = timeOffRequest.get('dateRangeStartsAt')
    const dateRangeEndsAt = timeOffRequest.get('dateRangeEndsAt')

    const firstDateMoment = timeService.timeMoment(dates.first())
    const lastDateMoment = timeService.timeMoment(dates.last())

    const borderLeftStyles = firstDateMoment.isBefore(dateRangeStartsAt)
      ? {
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          borderLeft: 'none'
        }
      : {}

    const endsAtMoment = timeService.timeMoment(dateRangeEndsAt)
    const borderRightStyles = lastDateMoment.isAfter(endsAtMoment.clone().add(-1, 'minute'))
      ? {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          borderRight: 'none'
        }
      : {}

    return { borderLeftStyles, borderRightStyles }
  }
}
