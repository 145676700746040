import { Component } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import history from 'browserHistory'
import IdleTimer from 'react-idle-timer'
import Header from 'Header'
// import Login from 'Login';
import { getState, onChange } from '@humanics/he-react-common/lib/stores/fluxStore'
import { DefaultRedirect, HashSwitch, PrivateRoute, RedirectToGAS } from './auth'
import ErrorBoundary from 'ErrorBoundary'
import typedErrorsHandlers from 'services/ErrorHandler/typedErrorsHandlers'
import Forbidden from './Forbidden'
import NoUnits from './NoUnits'
import StaffAccessBar from './Staff/Common/AccessBar/AccessBar'
import { PrivacyPolicy, ReadmeOSS, TermsOfService } from 'Common'
import { AppContext } from 'AppContext'
import Banner, { OfflineBannerStatus } from 'Banner'
import { Dialog, GlobalError } from 'Modal'
import { paths, navigate } from 'Navigation'
import { AdminRoutes } from './Admin'
import { FirstFacilityRedirect, HomeUnitRedirect, ManagerRoutes } from './Manager'
import { setOfflineMode } from '@humanics/he-react-common/lib/stores/bannersStore'
import { addWarningBanner } from 'stores'
import { List } from 'immutable'
import { t } from 'i18n'
import { isEnabledFeature, isFacilityEnabledFeature } from 'utils'
import { redirectToGASPaths } from 'services/IndexService'
import Mfa from './Mfa'
import './Main.scss'
import StaffLayout from 'Staff/StaffLayout'
import SkillscheckAdministratorLayout from 'SkillscheckAdministrator/SkillscheckAdministratorLayout'

const MILLIS_IN_MIN = 60 * 1000
const REFRESH_OFFSET_IN_MILLIS = 1 * MILLIS_IN_MIN
const MAX_ALLOWED_IDLE_TIME_IN_MILLIS = (Number(window.MAX_ALLOWED_IDLE_TIME_IN_MINS) || 20) * MILLIS_IN_MIN

export default class Main extends Component {
  constructor(props) {
    super(props)
    const appState = getState()
    const { stores, accountsURI } = props
    const timeService = (state) => state.getIn(['context', 'facilityTime'])
    const gqlClient = (state) => state.getIn(['context', 'gqlClient'])
    this.Dialog = Dialog(this, t)
    const context = {
      appState,
      accountsURI,
      activeDateRange: appState.getIn(['generalData', 'activeDateRange']),
      unit: appState.getIn(['generalData', 'unit']),
      Dialog: this.Dialog,
      timeService: timeService(appState),
      gqlClient: gqlClient(appState),
      isEnabledFeature: (feature) => isEnabledFeature(appState, feature),
      isFacilityEnabledFeature: (feature) => isFacilityEnabledFeature(appState, feature),
      ...stores
    }

    this.state = { context }
    this.offChange = onChange((appState) =>
      this.setState((prev) => ({
        isLoading: false,
        context: {
          ...prev.context,
          appState,
          activeDateRange: appState.getIn(['generalData', 'activeDateRange']),
          unit: appState.getIn(['generalData', 'unit']),
          isEnabledFeature: (feature) => isEnabledFeature(appState, feature),
          gqlClient: gqlClient(appState),
          timeService: timeService(appState)
        }
      }))
    )
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.scheduleNextRefreshSessionEvent()
  }

  scheduleNextRefreshSessionEvent = () => {
    const millisecondsLeftForSessionExpiry = this.getMillisecondsLeftForSessionExpiry()
    if (millisecondsLeftForSessionExpiry) {
      const millisecondsAfterWhichToRefresh = Math.max(millisecondsLeftForSessionExpiry - REFRESH_OFFSET_IN_MILLIS, 0)
      setTimeout(this.refreshSession, millisecondsAfterWhichToRefresh)
    }
  }

  refreshSession = async () => {
    try {
      await this.state.context.authStore.extendSession()
      this.scheduleNextRefreshSessionEvent()
    } catch (e) {
      console.error('Unable to refresh cookie.', e)
    }
  }

  getMillisecondsLeftForSessionExpiry = () => {
    const expiresAtMatch = document.cookie.match(/authorizationExpiresAt=(\d+)/)
    let millisecondsLeftForSessionExpiry
    if (expiresAtMatch) {
      const expiresAt = +expiresAtMatch[1] //cookie expires at in milliseconds
      millisecondsLeftForSessionExpiry = expiresAt - new Date().getTime()
    }
    return millisecondsLeftForSessionExpiry
  }

  /**
   * To log out user if they are idle.
   * @param {DomEvent} event Dom Event.
   */
  handleOnIdle = (_event) => {
    addWarningBanner({ message: t('inactivity_message') })
    setTimeout(() => {
      return navigate.to.Logout()
    }, 2000)
  }

  componentWillUnmount() {
    this.offChange()
  }

  componentDidMount() {
    global.addEventListener('offline', (e) => setOfflineMode({ message: t('auth.unable_to_connect_to_internet') }))
    document.title = t('product_name')
  }
  getRoleIds = (auth) => {
    return auth.getIn(['facilityUser', 'roleIds']).toJS() || List()
  }

  AccessBar = () => {
    const auth = this.state.context.appState.get('authentication')
    const isLoggedIn = auth.get('isLoggedIn')

    if (isLoggedIn) {
      const roleIds = this.getRoleIds(auth)
      this.isStaffOnly = roleIds.length === 1 && roleIds[0] === 'staff'
      if (!this.isStaffOnly) {
        return <sh-access-bar label={t('product_name')} slot="access" condensed />
      } else {
        return <StaffAccessBar label={t('product_name')} />
      }
    } else {
      return null
    }
  }

  render() {
    return (
      <AppContext.Provider value={this.state.context}>
        <Router history={history}>
          <>
            <GlobalError t={t} />
            <ErrorBoundary onError={typedErrorsHandlers['ApplicationError']}>
              <Route path={paths.WithAccessBar} component={this.AccessBar} />
              <PrivateRoute path={paths.Admin} component={Header} />
              <Banner />
              <OfflineBannerStatus />
              <this.Dialog />
              <Switch>
                <Route
                  path={paths.Login}
                  render={(props) => <RedirectToGAS {...props} redirectBackToReferrer={true} />}
                />
                {redirectToGASPaths.map((path, key) => (
                  <Route key={key} path={path} component={RedirectToGAS} />
                ))}
                <Route path={paths.Mfa} component={Mfa} />
                <PrivateRoute path={paths.Forbidden} component={Forbidden} requireFacility={false} />
                <PrivateRoute path={paths.NoUnits} component={NoUnits} requireFacility={false} />
                <PrivateRoute path={paths.StaffPage} component={StaffLayout} requireFacility={false} />
                <PrivateRoute path={paths.SkillscheckAdministratorPage} component={SkillscheckAdministratorLayout} />
                <Route path={paths.Admin} component={AdminRoutes} />
                <PrivateRoute path={paths.Root} component={FirstFacilityRedirect} exact />
                <PrivateRoute path={paths.FacilityHome} component={HomeUnitRedirect} exact />
                <PrivateRoute path={paths.FacilityUnitHome} component={DefaultRedirect} exact />
                <PrivateRoute path={paths.CommonManager} component={ManagerRoutes} />
              </Switch>
              <HashSwitch
                hashMap={{
                  [paths.TermsOfService]: TermsOfService,
                  [paths.PrivacyPolicy]: PrivacyPolicy,
                  [paths.ReadmeOSS]: ReadmeOSS
                }}
              />
            </ErrorBoundary>
          </>
        </Router>
        <IdleTimer
          crossTab={{
            emitOnAllTabs: true
          }}
          timeout={MAX_ALLOWED_IDLE_TIME_IN_MILLIS}
          onIdle={this.handleOnIdle}
        />
      </AppContext.Provider>
    )
  }
}
