import { fromJS, List } from 'immutable'
import { ComponentController } from 'Common/components'
import { noteSubjectsForTimeOff } from 'services/NoteSubjects'

export default class NotesController extends ComponentController {
  get defaultState() {
    return {}
  }

  get notesType() {
    const { documentType } = this.state
    return `${documentType}Notes`
  }

  get notes() {
    return this[this.notesType]
  }

  get filledNotes() {
    return this.notes.filter((note) => {
      const subject = note.get('subject')
      const text = note.get('text')
      const isSubjectNotEmpty = subject && subject.length > 0
      const isTextNotEmpty = text && text.length > 0

      return isSubjectNotEmpty || isTextNotEmpty
    })
  }

  get newNote() {
    const newNote = this.filledNotes.findLast((note) => !note.get('id'))

    return newNote ? newNote : null
  }

  get lastNote() {
    const newNote = this.newNote

    if (newNote) {
      return newNote
    }

    const lastNote = this.filledNotes.last()

    return lastNote ? lastNote : null
  }

  get updatedNotes() {
    const filledNotes = this.filledNotes
    const updatedNotes = filledNotes.filter((note) => note.get('id') && note.get('isNoteChanged'))

    return updatedNotes ? updatedNotes : List()
  }

  get isNoteChanged() {
    return this.state.isNoteChanged
  }

  set notes(notes) {
    const { documentType } = this.state
    this[`${documentType}Notes`] = notes
  }

  set isNoteChanged(isNoteChanged) {
    this.setState({ isNoteChanged })
  }

  buildNotesState(notes) {
    return notes ? { notes: fromJS(notes) } : { notes: null }
  }

  setNoteSubject(key, subject) {
    this.notes = this.notes.setIn([key, 'subject'], subject).setIn([key, 'isNoteChanged'], true)

    this.isNoteChanged = true
  }

  setNoteText(key, text) {
    this.notes = this.notes.setIn([key, 'text'], text).setIn([key, 'isNoteChanged'], true)

    this.isNoteChanged = true
  }

  enableEditModeForNote(key) {
    this.notes = this.notes.setIn([key, 'isEditMode'], true)
  }

  toggleNote(key) {
    const isCollapsed = this.notes.getIn([key, 'isCollapsed'])
    this.notes = this.notes.setIn([key, 'isCollapsed'], !isCollapsed)
  }

  getNote(key) {
    const { documentType } = this.state
    const note = this.notes.get(key)
    const id = note.get('id')
    const subject = note.get('subject')
    const text = note.get('text') || ''
    const isEditMode = note.get('isEditMode')
    const isCollapsed = note.get('isCollapsed')
    const isEmpty = !id
    const isCollapsible = false

    const updatedAt = this.getNoteUpdatedAt(key)
    const updatedBy = this.getNoteUpdatedBy(key)
    const lastUpdate = updatedAt && updatedBy ? `${updatedAt} - by ${updatedBy}` : '\u00A0'

    const availableSubjects = noteSubjectsForTimeOff(documentType)

    return {
      id,
      text,
      isEmpty,
      subject,
      lastUpdate,
      isEditMode,
      isCollapsed,
      isCollapsible,
      availableSubjects
    }
  }

  getNoteUpdatedAt(key) {
    const note = this.notes.get(key)
    const updatedAt = note.get('updatedAt')

    if (!updatedAt) {
      return ''
    }

    const { timeService } = this.props
    const updatedAtMoment = timeService.timeMoment(updatedAt)
    const time = updatedAtMoment.format('HH:mm')
    const date = updatedAtMoment.format('MM/DD/YY')

    return `${time} - ${date}`
  }

  getNoteUpdatedBy(key) {
    const note = this.notes.get(key)
    const author = note.get('author')

    if (!author) {
      return ''
    }

    const [firstName, lastName] = author.split(' ')

    return `${firstName[0]}. ${lastName}`
  }
}
