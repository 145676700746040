import { Component, createRef } from 'react'

export default class Details extends Component {
  constructor(props) {
    super(props)
    this.state = { cursor: null }
    this.textarea = createRef()
  }

  componentDidUpdate() {
    const { cursor } = this.state
    if (cursor) {
      this.textarea.current.selectionEnd = cursor
    }
  }

  render() {
    const { openShiftController } = this.props
    const { openShiftParameters, openShift, isLoading } = openShiftController
    const { note, acceptanceCriteria } = openShiftParameters
    const { isPosted } = openShift
    const isDisabled = isPosted || isLoading

    return (
      <section>
        <div className="row pb10">
          <h3 className="semibold">Post Details</h3>
        </div>

        <div className="hx-open-shift-request-details bg-white rounded">
          <div className="details-item">
            <div className="upper tiny black regent-gray">Approval TYPE</div>
            <div className="form-item item-group gap-1">
              <div className="hx-radio">
                <input
                  id="manualApprove"
                  type="radio"
                  name="type"
                  value="manualApprove"
                  checked={acceptanceCriteria === 'manualApprove'}
                  disabled={isDisabled}
                  onChange={openShiftController.onRadioChange('acceptanceCriteria')}
                />
                <label htmlFor="manualApprove" className="trout semibold">
                  Manager Approval
                </label>
              </div>
              <div className="hx-radio">
                <input
                  id="firstToRespond"
                  type="radio"
                  name="type"
                  value="firstToRespond"
                  checked={acceptanceCriteria === 'firstToRespond'}
                  disabled={isDisabled}
                  onChange={openShiftController.onRadioChange('acceptanceCriteria')}
                />
                <label htmlFor="firstToRespond" className="trout semibold">
                  First to Respond
                </label>
              </div>
            </div>
          </div>
          <hr />
          {/* Uncomment after getting final requirements and add appropriate input */}
          {/* <div className="details-item">*/}
          {/*  <div>*/}
          {/*    <span className="upper tiny black regent-gray">incentives (Optional)</span>*/}
          {/*  </div>*/}
          {/*  <div className="form-item item-group gap-1">*/}
          {/*    <div className="hx-checkbox group">*/}
          {/*      <input*/}
          {/*        id="bonusPay"*/}
          {/*        type="checkbox"*/}
          {/*        name="bonusPay"*/}
          {/*         disabled={isDisabled}*/}
          {/*         checked={isBonusPay === true}*/}
          {/*         onChange={openShiftController.onCheckboxChange('isBonusPay')}*/}
          {/*      />*/}
          {/*      <label htmlFor="bonusPay" className="trout semibold">*/}
          {/*        Bonus Pay*/}
          {/*      </label>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="details-item">
            <div>
              <div className="upper tiny black regent-gray">note for invited staff</div>
            </div>
            <div className="form-item item-group gap-1">
              <textarea
                ref={this.textarea}
                placeholder="Optional"
                className="rounded"
                disabled={isDisabled}
                name="note"
                value={note || ''}
                onChange={this.onChangeTextarea}
                maxLength={MAX_NOTE_LENGTH}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }

  onChangeTextarea = (e) => {
    e.persist()
    const { openShiftController } = this.props
    const { selectionEnd: cursor } = e.target
    this.setState({ cursor })
    openShiftController.onInputChange('note')(e)
  }
}

const MAX_NOTE_LENGTH = 1000
