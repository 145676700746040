// @ts-ignore
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { Map, fromJS } from 'immutable'
import { keyBy } from 'lodash'
import { isFunction } from 'utils'

import { shiftsQuery, unitsQuery, publicStaffQuery } from './Queries'

const defaultState = Map({
  shiftsMap: Map({ isLoading: false }),
  unitsMap: Map({ isLoading: false }),
  publicStaffMap: Map({ isLoading: false })
})

export default function StaffGeneralStore() {
  let gqlClient: any = null
  let updateStaffGeneralState: any = null

  const actions = {
    loadPublicStaff,
    loadShifts,
    loadUnits
  }

  return {
    initialize,
    ...actions
  }

  function initialize(state: any, context: any) {
    function _updateStaffGeneralState(state: any, key: string, value: any) {
      if (isFunction(value)) {
        return state.updateIn(['staffGeneralData', key], value)
      }
      return state.setIn(['staffGeneralData', key], value)
    }
    const updaters = fluxStore({
      _updateStaffGeneralState
    })

    const extendedContext = { ...context, ...updaters }

    ;({ gqlClient, _updateStaffGeneralState: updateStaffGeneralState } = extendedContext)
    return state.set('staffGeneralData', defaultState)
  }

  async function loadPublicStaff() {
    updateStaffGeneralState('publicStaffMap', Map({ isLoading: true }))
    const { publicStaffs } = await gqlClient.query(publicStaffQuery)
    return updateStaffGeneralState('publicStaffMap', fromJS({ isLoading: false, ...keyBy(publicStaffs, 'userId') }))
  }

  async function loadShifts() {
    updateStaffGeneralState('shiftsMap', Map({ isLoading: true }))
    const { shifts } = await gqlClient.query(shiftsQuery)
    return updateStaffGeneralState('shiftsMap', fromJS({ isLoading: false, ...keyBy(shifts, 'id') }))
  }

  async function loadUnits() {
    updateStaffGeneralState('unitsMap', Map({ isLoading: true }))
    const { units } = await gqlClient.query(unitsQuery)
    return updateStaffGeneralState('unitsMap', fromJS({ isLoading: false, ...keyBy(units, 'id') }))
  }
}
