import { CalendarItemType, getStaffEventDisplayAttributes } from '../Calendar/utils'
import { Map } from 'immutable'

export interface ICalendarItemIconProps {
    calendarItemType: CalendarItemType,
    size: 'xs' | 's' | 'l' | 'm'
}

export default function CalendarItemIcon({ calendarItemType, size }: ICalendarItemIconProps) {
  const { color, icon, iconColor } = getStaffEventDisplayAttributes(Map({ type: calendarItemType }))
  return (
    <span className="icon-highlight-wrapper" style={{ backgroundColor: color }}>
      <sh-icon icon={icon} size={size} color={iconColor} />
    </span>
  )
}
