import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { allCountries } from 'Admin/services/LocalityService'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'

const fields = [Field('code', 'CODE'), Field('name', 'NAME'), Field('timeZones', 'Time Zones')]

function CountryStore() {
  const actions = fluxStore({ loadDataAction })
  const store = AdminStore({
    fields,
    instanceAdmin: true,
    entityName: 'Country',
    api: {
      index: 'indexCountries'
    },
    header: { title: 'Countries' },
    search: { field: 'name', placeholder: 'Search for countries' }
  })
  return {
    ...store,
    ...actions
  }

  async function loadDataAction(state) {
    const api = await store.getApi()
    let countries
    try {
      countries = await allCountries(state, api)
    } catch (_err) {
      countries = [] //TODO: Improve error handling by displaying banner as well
    }
    const { stateKey } = store.getAdminInfo()
    return state.setIn([stateKey, 'list'], countries)
  }
}

export default CountryStore
