import { Component } from 'react'
import { curry } from 'i18n'
import { navigate } from 'Navigation'
import ExpertiseCard from './ExpertiseCard'
import { getExpertiseInfo } from './utils'

const t = curry('staff.staffExpertiseCard.')

class Certifications extends Component {
  render = () => {
    const createCertificationPath = navigate.from.StaffDetails.to.StaffCreateCertification({}, false)
    const editCertificationPath = (certificationId) =>
      navigate.from.StaffDetails.to.StaffEditCertification({ expertiseId: certificationId }, false)

    const certificationCardProps = {
      ...this.props,
      cardTitle: t('certificationCardTitle'),
      expertiseType: 'certification',
      getExpertiseTitle: this.getCertificationTitle,
      getExpertiseInfo: (expertise) => getExpertiseInfo(expertise, this.props.timeService),
      createExpertisePath: createCertificationPath,
      editExpertisePath: editCertificationPath,
      isGrouped: false,
      withExpiryIndicator: true,
      isCollapsible: true
    }
    return <ExpertiseCard {...certificationCardProps} />
  }

  getCertificationTitle = (certification) => {
    return certification.get('fullName')
  }
}

export default Certifications
