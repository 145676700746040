import {
  CalendarCellsOperation,
  NotesByDateRangeOperation,
  SecondaryStaffCalendarCellsOperation,
  StaffAssignedHoursByDateRangeOperation
} from './Operations'
import {
  updateNotes,
  updateStaffHoursMap,
  updateOtherStaffSection,
  updatePrimaryStaffSections
} from './UpdateCalendarData'
import { calendarSectionsQuery, primaryCalendarSectionsQuery, secondaryCalendarSectionsQuery } from '../Queries'

export default LoadCalendarSections()

function LoadCalendarSections() {
  let gqlClient = null
  let updateCalendar = null

  return { initialize, actions: { loadCalendarSections } }

  function initialize(context) {
    ;({ updateCalendar, gqlClient } = context)
  }

  async function loadCalendarSections(parameters, paths) {
    const { shiftIds } = parameters
    const hasShiftsToLoad = shiftIds.length > 0
    const hasOtherStaff = !!paths.otherStaffPath

    const calendarCellsOperation = CalendarCellsOperation(parameters)
    const secondaryStaffCalendarCellsOperation = SecondaryStaffCalendarCellsOperation(parameters)
    const staffAssignedHoursByDateRangeOperation = StaffAssignedHoursByDateRangeOperation(parameters)
    const notesByDateRangeOperation = NotesByDateRangeOperation(parameters)
    let query

    if (hasShiftsToLoad && hasOtherStaff) {
      query = calendarSectionsQuery
    } else if (hasShiftsToLoad) {
      query = primaryCalendarSectionsQuery
    } else {
      query = secondaryCalendarSectionsQuery
    }

    if (!query) {
      return
    }

    const params = {
      ...calendarCellsOperation.parameters,
      ...secondaryStaffCalendarCellsOperation.parameters,
      ...staffAssignedHoursByDateRangeOperation.parameters,
      ...notesByDateRangeOperation.parameters
    }

    const data = await gqlClient.query(query, params)
    const {
      [notesByDateRangeOperation.name]: notes,
      [calendarCellsOperation.name]: primaryStaffData,
      [staffAssignedHoursByDateRangeOperation.name]: staffHours,
      [secondaryStaffCalendarCellsOperation.name]: otherStaffData
    } = data

    const sectionsData = {
      primaryStaffData,
      otherStaffData,
      staffHours,
      notes
    }
    return updateCalendar(updateCalendarSections(sectionsData, paths, shiftIds))
  }

  function updateCalendarSections(data, paths, shiftIds) {
    return (calendar) => {
      const { primaryStaffData, otherStaffData, staffHours, notes } = data

      calendar = updateStaffHoursMap(calendar, staffHours)
      calendar = updateNotes(calendar, notes)

      if (otherStaffData) {
        calendar = updateOtherStaffSection(calendar, otherStaffData, paths)
      }

      if (primaryStaffData) {
        calendar = updatePrimaryStaffSections(calendar, shiftIds, primaryStaffData, paths)
      }

      return calendar
    }
  }
}
