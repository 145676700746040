import { PureComponent, createRef } from 'react'
import { List } from 'immutable'
import classNames from 'classnames'

export default class CellTooltip extends PureComponent {
  constructor(props) {
    super(props)
    this.cellTooltipRef = createRef()
    this.state = { position: null }
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props
    const { config: prevConfig } = prevProps
    const isConfigChanged = config !== prevConfig

    if (isConfigChanged) {
      this._recalculatePosition()
    }
  }

  render() {
    const { config, timeService, countStaffPTOs, notesMap } = this.props
    const { position } = this.state
    const staffEvent = config.getIn(['cell', 'timeOff'])

    if (!position || !staffEvent) {
      return null
    }

    const { left, top } = position

    const startsAt = staffEvent.get('startsAt')
    const updatedAt = staffEvent.get('updatedAt')

    const staffPath = config.get('staffPath')
    const ptoAmount = countStaffPTOs(staffPath)
    const date = timeService.timeMoment(startsAt).format('ddd, MMM D')
    const isPTO = staffEvent.getIn(['timeOffAttributes', 'paidMinutes']) > 0

    const author = this._getAuthor(staffEvent)
    let granularity

    if (ptoAmount) {
      if (ptoAmount > 1) {
        granularity = 'days'
      } else {
        granularity = 'day'
      }
    } else {
      granularity = 'days'
    }
    const paidDays = `${isPTO ? ptoAmount : 0} PTO ${granularity} requested`
    const updatedAtFormatted = timeService.timeMoment(updatedAt).format('M/D/YYYY HH:mm')
    const lastUpdateDescription = `Updated ${updatedAtFormatted} by ${author}`

    const title = isPTO ? 'PTO' : 'Request Off'

    const offsetTop = 60
    const offsetLeft = -90

    const noteIds = staffEvent.get('noteIds') || List()
    const notesCollection = noteIds.map((noteId) => notesMap.get(noteId)).filter((note) => note)
    const hasNotes = notesCollection.size > 0

    return (
      <div
        className="hx-shift-staff-cell-tooltip round"
        style={{
          position: 'fixed',
          top: top + offsetTop,
          left: left + offsetLeft
        }}
        ref={this.cellTooltipRef}
      >
        <div>
          <div className="p5">
            <div className="row">
              <div className="col-2 text-center">
                <i
                  className={classNames({
                    'large regent-gray': true,
                    'icon-Money-Sign': isPTO,
                    'icon-close': !isPTO
                  })}
                />
              </div>
              <div className="col-10 bold">{title}</div>
            </div>
          </div>
          <div className="p5 bt1">
            <div className="bold regent-gray small text-center p5 ph0">{date}</div>
            <div className="row">
              <div className="col-2 text-center">
                <i className="large regent-gray icon-Money-Sign" />
              </div>
              <div className="col-10 bold">{paidDays}</div>
            </div>
            {hasNotes && this._renderNotes(notesCollection)}
          </div>
          <div className="p10 small regent-gray bt1">{lastUpdateDescription}</div>
        </div>
      </div>
    )
  }

  _recalculatePosition() {
    const targetElement = this.props.config.get('targetElement')
    if (targetElement) {
      const { width, left, top } = targetElement.getBoundingClientRect()
      const position = { left: left + width / 2, top }
      this.setState({ position })
    } else {
      this.setState({ position: null })
    }
  }

  _renderNotes = (notes) => {
    const note = notes.last()

    const subject = note.get('subject') || ''
    const text = note.get('text') || ''

    return (
      <div className="row p5 ph0">
        <div className="col-2" />
        <div className="col-10 small">
          {text}
          {text && subject && <br />}
          {subject}
        </div>
      </div>
    )
  }

  _getAuthor(staffEvent) {
    const { staffManagersMap } = this.props
    const managerId = staffEvent && (staffEvent.get('updatedBy') || staffEvent.get('createdBy'))
    const manager = staffManagersMap?.get(managerId)

    if (!manager) {
      return ''
    }

    const firstName = manager.getIn(['profile', 'firstName'])
    const lastName = manager.getIn(['profile', 'lastName'])

    return `${firstName} ${lastName}`
  }
}
