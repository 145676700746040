import { Map, OrderedMap } from 'immutable'
import StateController from './StateController'
import { findParentElement, cumulativeOffset } from 'utils'

const defaultState = Map({
  staffPath: {},
  cells: OrderedMap(),
  timeOffRequestId: null
})

export default class PopupController extends StateController {
  get defaultState() {
    return {
      popupConfig: Map({
        popupId: null,
        popupProps: {}
      })
    }
  }

  get popupConfig() {
    return this.state.popupConfig
  }

  get popupId() {
    return this.popupConfig.get('popupId')
  }

  get isPopupVisible() {
    return !!this.popupId
  }

  get isTimeOffDetailsVisible() {
    return this.popupId === 'TimeOffDetails'
  }

  get isContextMenuVisible() {
    return this.popupId === 'ContextMenu'
  }

  get isTimeOffRequestDetailsVisible() {
    return this.popupId === 'TimeOffRequestDetails'
  }

  updatePopupOnClick(eventMeta = {}) {
    const { isShiftKeyPressed, isMetaKeyPressed, isCtrlKeyPressed } = eventMeta
    const isMultiselectionKeyPressed = isShiftKeyPressed || isMetaKeyPressed || isCtrlKeyPressed

    if (!isMultiselectionKeyPressed) {
      this.hideTimeOffDetails()
    }
    this.hideContextMenu()
  }

  switchTo = (popupId, callback) => {
    const popupConfig = this.popupConfig.set('popupId', popupId)
    this.setState({ popupConfig }, callback)
  }

  showPopup = (targetElement) => {
    const { popupId } = this
    const isTimeOffDetails = popupId === 'TimeOffDetails'
    const isTimeOffRequestDetails = popupId === 'TimeOffRequestDetails'

    const position = isTimeOffDetails || isTimeOffRequestDetails ? 'bottom' : 'left'
    const width = isTimeOffDetails || isTimeOffRequestDetails ? 380 : 150

    const $staffRow = findParentElement(targetElement, 'classList', '_staff')
    const $cellsContainer = findParentElement(targetElement, 'classList', '_cells')
    const { left: cellsContainerOffsetLeft } = cumulativeOffset($cellsContainer)

    const { left } = cumulativeOffset(targetElement)

    const { offsetHeight: height } = targetElement
    const { offsetHeight: staffRowHeight } = $staffRow
    const top = isTimeOffRequestDetails ? $staffRow.offsetTop + (staffRowHeight - height) / 2 : $staffRow.offsetTop

    const popupProps = {
      position,
      width,
      targetElement,
      container: {
        left: cellsContainerOffsetLeft,
        right: cellsContainerOffsetLeft + $cellsContainer.offsetWidth
      },
      viewPort: {
        top,
        height,
        left: left,
        right: left + targetElement.offsetWidth
      }
    }

    const popupConfig = this.popupConfig.set('popupProps', popupProps).set('popupId', popupId)

    this.setState({ popupConfig })
  }

  hidePopup = (resetSelection = true) => {
    if (!this.isPopupVisible) {
      return
    }

    const popupConfig = this.popupConfig.merge({ popupId: null })
    const selection = resetSelection ? defaultState : this.state.selection

    this.setState({ popupConfig, selection })
  }

  hideTimeOffRequestDetails = () => {
    this.isTimeOffRequestDetailsVisible && this.hidePopup()
  }

  hideTimeOffDetails = () => {
    this.isTimeOffDetailsVisible && this.hidePopup()
  }

  hideContextMenu = () => {
    this.isContextMenuVisible && this.hidePopup()
  }
}
