import { PureComponent, createRef } from 'react'
import { Map } from 'immutable'
import Note from './Note'
import './DayNotes.scss'

export default class DayNotes extends PureComponent {
  constructor(props) {
    super(props)
    this.ref = createRef()
    this.state = { childrenDimensions: Map(), width: 'auto' }
    this.childrenDimensionsMemo = Map()
  }

  componentDidUpdate(prevProps, prevState) {
    const { childrenDimensions } = this.state
    const { childrenDimensions: prevChildrenDimensions } = prevState
    const isChildrenDimensionsChanged = childrenDimensions !== prevChildrenDimensions

    if (isChildrenDimensionsChanged) {
      this.recalculateContainerWidth()
    }
  }

  render() {
    const { width } = this.state
    const { notes, date, timeService } = this.props

    if (!notes) {
      return null
    }

    const today = timeService.timeMoment(null)
    const isCurrentYear = timeService.isSame(today, date, 'year')
    const dateFormat = isCurrentYear ? 'MMM D' : 'YYYY MMM D'

    const dateFormatted = timeService.timeMoment(date).format(dateFormat)
    const dayOfWeek = timeService.timeMoment(date).format('ddd')

    return (
      <div className="day-notes">
        <div>
          <div className="day-notes-title">
            {dateFormatted}
            <span>{dayOfWeek}</span>
          </div>
          <div className="day-notes-content">
            <div ref={this.ref} style={{ width: width }}>
              {notes.map((note, index) => {
                const nextNote = notes.get(index + 1)

                const noteEventId = note.get('staffEventId')
                const nextEventNoteId = nextNote?.get('staffEventId')

                const withDivider = noteEventId !== nextEventNoteId

                return (
                  <Note
                    note={note}
                    key={index}
                    index={index}
                    withDivider={withDivider}
                    timeService={timeService}
                    applyChildDimensions={this.applyChildDimensions}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  applyChildDimensions = (childIndex, { left, right }) => {
    const item = Map({ left, right })
    this.childrenDimensionsMemo = this.childrenDimensionsMemo.set(childIndex, item)
    this.setState({ childrenDimensions: this.childrenDimensionsMemo })
  }

  recalculateContainerWidth() {
    const { childrenDimensions } = this.state
    const firstChild = childrenDimensions.first()
    const lastChild = childrenDimensions.last()
    const firstChildLeft = firstChild.get('left')
    const lastChildRight = lastChild.get('right')
    const width = lastChildRight - firstChildLeft
  }
}
