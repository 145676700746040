import { Map } from 'immutable'
import StateController from './StateController'
import { merge } from 'lodash'
import { getAppropriateStore } from '../layoutUtils'

export default class DataController extends StateController {
  constructor(component) {
    super(component)
    this.loadConfig = Map({ shifts: Map(), timeout: null })
  }

  _setLoadConfig(config, callback) {
    this.loadConfig = this.loadConfig.merge(Map(config))
  }

  _loadShifts = (loadByChunks) => () => {
    const { activeDateRange, calendarType } = this.props
    const shifts = this.loadConfig.get('shifts')
    const shiftsToLoad = shifts
      .valueSeq()
      .filter((shift) => {
        const isLoaded = shift.get('isLoaded') === true
        const isLoading = shift.get('isLoading') === true
        return !isLoaded && !isLoading
      })
      .toArray()

    this.resetLoadConfig()
    const options = { asyncLoading: !loadByChunks, loadByChunks: loadByChunks, shiftsInChunk: 3 }

    const store = getAppropriateStore(calendarType)
    store.loadFlatShifts(shiftsToLoad, activeDateRange, null, options)
  }

  resetLoadConfig = () => {
    const timeout = this.loadConfig.get('timeout')

    if (timeout) {
      clearTimeout(timeout)
    }

    this.loadConfig = Map({ shifts: Map(), timeout: null })
  }

  loadShift = (shiftId, loadByChunks = false) => {
    const { shiftsMap } = this.calendar
    const shift = shiftsMap.get(shiftId)
    const isLoaded = shift.get('isLoaded')
    const isLoading = shift.get('isLoading')

    if (!isLoaded && !isLoading) {
      const timeout = this.loadConfig.get('timeout')
      const shifts = this.loadConfig.get('shifts')

      if (timeout) {
        clearTimeout(timeout)
      }

      const newShifts = shifts.set(shiftId, shift)
      const newTimeout = setTimeout(this._loadShifts(loadByChunks), 50)

      this._setLoadConfig({ timeout: newTimeout, shifts: newShifts })
    }
  }

  loadCalendar = () => {
    const { activeDateRange, unit, calendarType } = this.props
    const store = getAppropriateStore(calendarType)
    return store.loadFlatCalendar(unit, activeDateRange)
  }

  loadCalendarDayView = (date) => {
    const { calendarStore, unit, activeDateRange } = this.props
    return calendarStore.loadCalendarDayView(unit, date, activeDateRange)
  }

  loadOtherStaff = () => {
    const { activeDateRange, unit, calendarType } = this.props
    const store = getAppropriateStore(calendarType)
    return store.loadOtherStaff(unit, activeDateRange)
  }

  loadOtherStaffWithEvents = async (date) => {
    const { calendarStore, activeDateRange, unit } = this.props
    return calendarStore.loadOtherStaffWithEvents(unit, activeDateRange, date)
  }

  preloadShifts = (preloadData, overscanRowCount, height) => {
    const { calendarType } = this.props
    const dimensions = {
      overscanRowCount,
      roleHeight: 31,
      shiftHeight: 28,
      staffRowHeight: 49,
      calendarHeight: height
    }
    const store = getAppropriateStore(calendarType)
    store.preloadFlatShifts(preloadData, dimensions)
  }

  reloadCalendarForSelection = async (selection, shiftPaths, extraInfo) => {
    const { calendar } = this
    const { calendarStore, match, activeDateRange: dateRange } = this.props
    const { mode, date: usDate } = match.params
    const { cellsPaths, staff, shiftDaysPaths } = selection
    let userIds = []
    if (extraInfo?.addStaffToOnCallShift) {
      userIds = [extraInfo.staff.get('id')]
    } else {
      userIds = [staff.get('id')]
    }
    const shifts = shiftPaths.map((shiftPath) => calendar.getShift(shiftPath))
    const loadParameters = { userIds, cellsPaths, shiftDaysPaths }
    const options = { asyncLoading: false }

    await calendarStore.loadFlatShifts(shifts, dateRange, loadParameters, options)
    if (mode === 'day') {
      await this.loadCalendarDayView(usDate)
    }
  }

  reloadDayCalendar = async () => {
    const { match } = this.props
    const { date: usDate } = match.params

    await this.loadCalendarDayView(usDate)
  }

  reloadCalendarForShift = ({ shift, userIds }) => {
    const { calendarStore, activeDateRange } = this.props
    return calendarStore.loadFlatShifts([shift], activeDateRange, { userIds })
  }

  reloadCalendarForRole = (role) => {
    const shifts = role.get('shifts')
    shifts.forEach((shift) => {
      const userIds = shift.get('staff').map((staff) => staff.get('id'))
      this.reloadCalendarForShift({ shift, userIds })
    })
  }

  loadCalendarDay = (date) => {
    const { calendarStore, unit, activeDateRange } = this.props
    const { otherStaffMap } = this.calendar

    return calendarStore.loadCalendarDay(unit, date, activeDateRange, otherStaffMap)
  }

  _getUnitManagerViewPreferencesSettingIndex = () => {
    const settings = this.props.appState.getIn(['authentication', 'facilityUser', 'unitManagerProfile', 'settings'])
    return settings.findIndex((filter) => filter.get('key') === 'viewPreferences')
  }

  getUnitManagerViewPreferences = () => {
    const settings = this.props.appState.getIn(['authentication', 'facilityUser', 'unitManagerProfile', 'settings'])
    const viewPreferencesSettingIndex = this._getUnitManagerViewPreferencesSettingIndex()
    let viewPreferencesString
    if (viewPreferencesSettingIndex !== -1) {
      viewPreferencesString = settings.get(viewPreferencesSettingIndex)?.get('value')
    }
    return viewPreferencesString ? JSON.parse(viewPreferencesString) : { units: {} }
  }

  updateUnitManagerViewPreference = async (newViewPreferences, role, override = false) => {
    const { authStore, appState } = this.props
    const viewPreferences = this.getUnitManagerViewPreferences()
    let updatedViewPreferences = newViewPreferences
    if (!override) {
      updatedViewPreferences = merge(viewPreferences, newViewPreferences)
    }

    const newSetting = Map({
      key: 'viewPreferences',
      value: JSON.stringify(updatedViewPreferences)
    })

    const viewPreferencesSettingIndex = this._getUnitManagerViewPreferencesSettingIndex()
    const settings = appState.getIn(['authentication', 'facilityUser', 'unitManagerProfile', 'settings'])
    const updatedSettings =
      viewPreferencesSettingIndex === -1
        ? settings.push(newSetting)
        : settings.set(viewPreferencesSettingIndex, newSetting)

    const updatedSettingsWithoutTypename = updatedSettings.map((setting) => setting.delete('__typename'))

    await authStore.updateUnitManagerProfileSettings(updatedSettingsWithoutTypename.toJS())
    if (role) {
      this.reloadCalendarForRole(role)
    } else {
      this.loadCalendar()
    }
  }
}
