import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { fromJS, Map } from 'immutable'
import { expertiseQuery, expertiseGroupQuery, shiftSkillMismatchQuery } from './Queries'

export default function ExpertisesStore() {
  let gqlClient = null
  const actions = fluxStore({ loadExpertises, loadShiftSkillMismatch })

  return { initialize, ...actions }

  function initialize(state, context) {
    ;({ gqlClient } = context)
    return state.set('expertises', Map({ expertiseGroups: Map({}), expertiseItems: Map({}) }))
  }

  async function loadExpertises(state) {
    const { expertiseGroups } = await gqlClient.query(expertiseGroupQuery, { limit: 999 })
    const { expertises: expertiseItems } = await gqlClient.query(expertiseQuery, { limit: 999 })
    const expertises = mapExpertises(expertiseGroups, expertiseItems)
    return state.set('expertises', Map(expertises))
  }

  async function loadShiftSkillMismatch(state, { unitId, startDate, endDate }) {
    const { shiftSkillMismatch } = await gqlClient.query(shiftSkillMismatchQuery, {
      unitId,
      startDate,
      endDate
    })
    return state.set('shiftSkillMismatch', Map(shiftSkillMismatch))
  }
}

export function mapExpertises(expertiseGroups, expertises) {
  const expertiseGroupsById = fromJS(expertiseGroups).groupBy((item) => item.get('id'))
  const expertisesById = fromJS(expertises)
    .filter((expertise) => expertiseGroupsById.keySeq().includes(expertise.get('groupId')))
    .map((expertise) => {
      const name = expertise.get('name')
      const groupId = expertise.get('groupId')
      const expertiseGroup = expertiseGroupsById.get(groupId)
      const fullName =
        expertiseGroup && expertiseGroup.size > 0 ? `${expertiseGroup.get(0).get('title')} - ${name}` : name
      return expertise.merge({
        fullName
      })
    })
    .groupBy((item) => item.get('id'))
  return {
    expertiseGroups: expertiseGroupsById,
    expertiseItems: expertisesById
  }
}
