import { t } from 'i18n'
import { useRef } from 'react'
import WarningModal from './WarningModal'
import RemoteWeScanStaffRequestController from '../RemoteWeScanStaffRequestController'

import { TimeService } from 'services'
import { IRemoteStaffRequest } from 'stores/remoteWorkflowStore/models/remoteStaffRequest'

export interface ICancelRemoteWeScanStaffRequestProps {
  remoteStaffRequest: IRemoteStaffRequest
  timeService: TimeService
  onCancelSuccess?: () => void
  onCancelAbort?: () => void
}

export default function CancelRemoteWeScanStaffRequest(props: ICancelRemoteWeScanStaffRequestProps) {
  const { remoteStaffRequest, onCancelSuccess, onCancelAbort, timeService } = props
  const thisRef = useRef({ props })
  const remoteStaffRequestControllerRef = useRef(new RemoteWeScanStaffRequestController(thisRef.current))
  const remoteStaffRequestController = remoteStaffRequestControllerRef.current

  const firstWeScanAppointmentStartsAt = remoteStaffRequest.requestDetails.wescan!.appointments[0].startsAt

  const isPast = timeService.isPastCurrentTime(firstWeScanAppointmentStartsAt)
  const message = remoteStaffRequestController.getCancellationMessage(firstWeScanAppointmentStartsAt)

  const handleYesButtonClick = async () => {
    await remoteStaffRequestController.cancelRemoteStaffRequest(remoteStaffRequest).finally(() => {
      onCancelSuccess?.()
    })
  }

  const handleGoBackButtonClick = () => {
    onCancelAbort?.()
  }

  return (
    <WarningModal
      title={t('equipments.cancel_wescan_request_header')}
      message={message}
      hideYesButton={isPast}
      onProceed={handleYesButtonClick}
      onAbort={handleGoBackButtonClick}
    />
  )
}
