import { PureComponent } from 'react'
import { AutoSizer, List } from 'react-virtualized'
import ViewController from '../../../../../../../Calendar/Grid/StickyList/ViewController'
import StickyHeader from './StickyHeader'

export default class StickyList extends PureComponent {
  constructor(props) {
    super(props)

    this.viewController = new ViewController(this)
    this.state = { ...this.viewController.defaultState }
  }

  render() {
    const { raw, filters, renderRole, renderShift, getRowHeight } = this.props
    const role = this.viewController.getFirstVisibleRole(raw)
    let shift = this.viewController.getFirstVisibleShift(raw, role) // TODO shift should be set 1 row earlier

    const roleIndex = role?.get('roleIndex')
    const shiftRoleIndex = shift?.get('roleIndex')

    shift = roleIndex === shiftRoleIndex && shift

    const stickyHeaderProps = {
      role,
      shift,
      filters,
      renderRole,
      renderShift,
      getRowHeight
    }

    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <div className="hx-grid w100 relative">
            <StickyHeader {...stickyHeaderProps} width={width} />
            <List
              {...this.props}
              width={width}
              style={{ backgroundColor: '#fff', overflowY: 'overlay' }}
              onRowsRendered={this.onRowsRendered(raw)}
            />
          </div>
        )}
      </AutoSizer>
    )
  }

  onRowsRendered =
    (raw) =>
    ({ startIndex }) => {
      this.viewController.onRowsRendered({ startIndex })
    }
}
