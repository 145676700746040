import { t } from 'i18n'
import { List, Map, fromJS } from 'immutable'

interface IYourShift {
  timeService: any,
  staffEventsMap: any,
  dataController: any,
  handleEmptyStateLabel: any,
  handleYourShiftData: any,
  requestShiftSwapData: any
}

const YourShift = ({
  timeService,
  staffEventsMap,
  dataController,
  handleEmptyStateLabel,
  handleYourShiftData,
  requestShiftSwapData
}: IYourShift) => {
  const yourShift = requestShiftSwapData.yourShift

  const handleYourShiftDate = (dateEvent: any) => {
    const dateValue = dateEvent.target.value
    if (dateValue) {
      const date = timeService.formatDate(dateValue, 'MM/DD/YYYY', 'MM-DD-YYYY')
      const selectedDate = timeService.usDateToMoment(date)

      const staffEventsForDate = staffEventsMap.get(selectedDate.toISOString())

      handleYourShiftData(Map({
        date: selectedDate.format('MM/DD/YYYY'),
        selectedShift: Map(),
        staffEventsForSelectedDate: List([])
      }))

      if (staffEventsForDate) {
        handleYourShiftData({ staffEventsForSelectedDate: fromJS(staffEventsForDate) })
        handleEmptyStateLabel(t('staffCalendar.shiftSwap.emptyStateLabels.chooseYourShift'))
      } else {
        handleEmptyStateLabel(t('staffCalendar.shiftSwap.emptyStateLabels.noShiftAvailable'))
        handleYourShiftData({ staffEventsForSelectedDate: List([]) })
      }
    }
  }

  const handleYourShiftSelection = async (staffEvent: any) => {
    const staffEventId = staffEvent.get('id')
    try {
      await dataController.loadShiftDaysAvailableToSwapFor(staffEventId)
    } catch(shiftDaysAvailableToSwapError: any) {
      if (shiftDaysAvailableToSwapError.toString().includes('not scheduled at this time.')) {
        return
      }
    }
    handleEmptyStateLabel(t('staffCalendar.shiftSwap.emptyStateLabels.selectSwappingDate'))
    handleYourShiftData({ selectedShift: fromJS(staffEvent) })
  }

  return (
    <div className="p10 bg-whitesmoke mb10 mt10">
      <div className="strong pb10">{t('staffCalendar.shiftSwap.labels.yourShift')}:</div>
      <div className="row">
        <div className="col-4">
          <sh-datepicker
            onClick={(dateEvent: any) => handleYourShiftDate(dateEvent)}
            label={t('staffCalendar.shiftSwap.labels.selectDate')}
            format="MMDDYYYY"
            past-date-disable
            no-clear
            value={yourShift.get('date')}
          ></sh-datepicker>
        </div>
        <div className="col-8 pl10">
          {yourShift.get('staffEventsForSelectedDate').size > 0 ? (
            <sh-dropdown label={t('staffCalendar.shiftSwap.labels.selectShift')} value={yourShift.getIn(['selectedShift', 'shiftName'])} no-clear>
              {yourShift.get('staffEventsForSelectedDate').map((staffEvent: any) => {
                const unitEventVariantId = staffEvent.get('unitEventVariantId')
                const isMeetingEvent = unitEventVariantId === 'meeting'
                const isAssignment = unitEventVariantId === 'assignment'
                const isOnCall = unitEventVariantId === 'onCall'
                if (isMeetingEvent || (!isAssignment && !isOnCall)) return null

                const shiftName = staffEvent.get('shiftName')
                const staffEventId = staffEvent.get('id')
                return (
                  <sh-menu-item
                    key={staffEventId}
                    onClick={(_: any) => handleYourShiftSelection(staffEvent)}
                    label={shiftName}
                  ></sh-menu-item>
                )
              })}
            </sh-dropdown>
          ) : (
            <sh-dropdown label={t('staffCalendar.shiftSwap.labels.selectShift')} value="" no-clear></sh-dropdown>
          )}
        </div>
      </div>
    </div>
  )
}

export default YourShift
