// import { calendarDayDataQuery } from '../Queries';
// import { keyBy, groupBy } from 'lodash';
// import { fromJS, List } from 'immutable';
// import { FacilityUserShiftsService, ShiftService } from 'services';
// import { readCalendarDayDataParams } from '../CalendarDayView/utils';

export default CalendarDay()

function CalendarDay() {
  let updateCalendar = null
  // let timeService = null;
  // let gqlClient = null;

  const actions = {
    // loadCalendarDay,
    resetCalendarDay
  }
  return { initialize, actions }

  function initialize(context) {
    ;({
      // gqlClient,
      updateCalendar
      // facilityTime: timeService
    } = context)
  }

  function resetCalendarDay(unitId, date) {
    return updateCalendar((calendar) => calendar.removeIn(['daysData']))
  }

  // async function loadCalendarDay(unit, date, dateRange, otherStaffMap) {
  //   const roles = unit.get('roles');
  //   const shifts = unit.get('shifts');
  //   const unitId = unit.get('id');
  //   const usersMap = dateRange.get('facilityUsersMap').merge(otherStaffMap);

  //   const {
  //     notes,
  //     shiftDays,
  //     staffEvents,
  //     openShifts,
  //     shiftOpportunities
  //   } = await _readCalendarDayData(unit, date);

  //   const shiftsByRoleId = shifts.groupBy(shift => shift.get('unitRoleId'));
  //   const shiftDaysMap = keyBy(shiftDays, 'shiftId');
  //   const notesById = keyBy(notes, 'id');
  //   const openShiftsByShiftDayId = keyBy(openShifts, 'shiftDayId');
  //   const staffEventsByShiftDayId = groupBy(staffEvents, 'shiftDayId');
  //   const shiftOpportunitiesByShiftDayId = keyBy(shiftOpportunities, 'shiftDayId');
  //   const data = {
  //     shifts,
  //     usersMap,
  //     notesById,
  //     shiftDaysMap,
  //     shiftsByRoleId,
  //     openShiftsByShiftDayId,
  //     staffEventsByShiftDayId,
  //     shiftOpportunitiesByShiftDayId
  //   };
  //   const dayData = _buildDayData(date, roles, data);

  //   return updateCalendar(calendar => {
  //     const calendarNotes = calendar.get('notes');
  //     const notesReducer = (memo, value) => memo.set(value.get('id'), value);
  //     const newNotes = fromJS(notes).reduce(notesReducer, calendarNotes);

  //     return calendar.setIn(['daysData', unitId, date], dayData).set('notes', newNotes);
  //   });
  // }

  // async function _readCalendarDayData(unit, date) {
  //   const unitId = unit.get('id');
  //   const shifts = unit.get('shifts');
  //   const shiftIds = shifts.reduce((memo, shift) => memo.concat(shift.get('id')), []);
  //   const startDate = date;

  //   const endDate = timeService
  //     .timeMoment(startDate)
  //     .add(1, 'day')
  //     .add(-1, 'minute')
  //     .toISOString();
  //   const parameters = readCalendarDayDataParams(unitId, startDate, endDate, shiftIds);
  //   const {
  //     shiftDays,
  //     openShifts,
  //     staffEvents,
  //     shiftOpportunities,
  //     notesByDateRange: notes
  //   } = await gqlClient.query(calendarDayDataQuery, parameters);

  //   const dateMoment = timeService.timeMoment(date).startOf('day');
  //   const dayShiftDays = shiftDays.filter(({ startsAt }) => dateMoment.isSame(startsAt, 'day'));

  //   return {
  //     notes,
  //     openShifts,
  //     staffEvents,
  //     shiftOpportunities,
  //     shiftDays: dayShiftDays
  //   };
  // }

  // function _buildDayData(date, roles, data) {
  //   const { shiftsByRoleId } = data;
  //   return roles.map((role, roleIndex) => {
  //     const roleId = role.get('id');
  //     const roleShifts = shiftsByRoleId.get(roleId);
  //     const shifts = _buildRoleShifts(date, roleShifts, data);

  //     return role.merge({ shifts, roleIndex });
  //   });
  // }

  // function _buildRoleShifts(date, shifts, data) {
  //   const { shiftDaysMap, openShiftsByShiftDayId, shiftOpportunitiesByShiftDayId } = data;

  //   if (!shifts) return List();

  //   return shifts.map((shift, shiftIndex) => {
  //     const shiftId = shift.get('id');

  //     const shiftDay = shiftDaysMap[shiftId] || _buildShiftDay(date, shift);
  //     const staff = _buildShiftStaff(shiftDay, data);

  //     const { id: shiftDayId } = shiftDay;
  //     const openShift = openShiftsByShiftDayId[shiftDayId];
  //     const shiftOpportunity = shiftOpportunitiesByShiftDayId[shiftDayId];
  //     const shiftDayImmutable = fromJS({
  //       ...shiftDay,
  //       openShift,
  //       shiftOpportunity,
  //       startTime: shift.get('startTime')
  //     });
  //     return shift.merge({ staff, shiftIndex, shiftDay: shiftDayImmutable });
  //   });
  // }

  // function _buildShiftStaff(shiftDay, data) {
  //   const { id: shiftDayId, startsAt: shiftDayStartsAt } = shiftDay;
  //   const { usersMap, notesById, staffEventsByShiftDayId } = data;
  //   const staffEvents = staffEventsByShiftDayId[shiftDayId] || [];

  //   return staffEvents
  //     .filter(event => usersMap.get(event.userId))
  //     .map((event, eventIndex) => {
  //       const { userId, noteIds } = event;
  //       const user = usersMap.get(userId);
  //       const notes = noteIds.map(noteId => notesById[noteId]);
  //       const additionalData = {
  //         event: { ...event, notes },
  //         staffIndex: eventIndex
  //       };

  //       return extendStaff(user, shiftDayStartsAt, additionalData, data);
  //     });
  // }

  // function extendStaff(facilityUser, date, { shifts }, additionalData = {}) {
  //   const facilityProfile = facilityUser.get('facilityProfile');
  //   const type = facilityUser.getIn(['staffProfile', 'employmentTypeLabel']);

  //   const firstName = facilityProfile.get('firstName');
  //   const lastName = facilityProfile.get('lastName');
  //   const fullName = `${firstName} ${lastName}`;
  //   const fullNameRevers = `${lastName}, ${firstName}`;
  //   const fullNameAsSearchable = getFullNameSearcheable(firstName, lastName);

  //   const eligibleUnits = facilityUser.getIn(['staffProfile', 'eligibleUnits']);
  //   const facilityUserShiftsService = new FacilityUserShiftsService(timeService);
  //   const primaryShift = facilityUserShiftsService.getPrimaryShift(eligibleUnits, date, shifts);
  //   const primaryShiftId = primaryShift && primaryShift.get('id');

  //   const data = {
  //     ...additionalData,
  //     type,
  //     firstName,
  //     lastName,
  //     fullName,
  //     primaryShiftId,
  //     fullNameRevers,
  //     fullNameAsSearchable
  //   };

  //   return facilityUser.merge(fromJS(data));
  // }

  // function _buildShiftDay(date, shift) {
  //   const shiftService = new ShiftService(shift, timeService);
  //   const { startsAt, endsAt, length } = shiftService.getShiftPeriodForDate(date);
  //   const staffMismatch = shift.getIn(['requirements', 0, 'number']);

  //   return {
  //     length,
  //     endsAt,
  //     startsAt,
  //     staffMismatch,
  //     staffEvents: [],
  //     shiftId: shift.get('id'),
  //     staffRequirement: shift.getIn(['requirements', 0, 'number']),
  //     startTime: shift.get('startTime'),
  //     unitId: shift.get('unitId'),
  //     isOverstaffed: staffMismatch < 0,
  //     isUnderstaffed: staffMismatch > 0
  //   };
  // }

  // function getFullNameSearcheable(firstName = '', lastName = '') {
  //   return `${firstName.toLowerCase()} ${lastName.toLowerCase()} ${firstName.toLowerCase()}`;
  // }
}
