import { withAppContext } from 'AppContext'

// @ts-ignore
import CustomerSupportBase from '@humanics/he-react-common/lib/components/CustomerSupport'

const CustomerSupport = ({ children, ...props }: any) => {
  const { appState } = props

  const auth = appState?.get('authentication')
  const isLoggedIn = auth?.get('isLoggedIn')
  const profile = auth?.getIn(['facilityUser', 'facilityProfile'])
  const facility = auth?.get('facility')

  const firstName = profile?.get('firstName')
  const lastName = profile?.get('lastName')
  const email = profile?.get('email')
  const phoneNumber = profile?.get('phoneNumber')
  const countryCode = facility?.get('country')

  const data = {
    isLoggedIn,
    firstName,
    lastName,
    email,
    phoneNumber,
    countryCode
  }

  return (
    <CustomerSupportBase
      children={children}
      data={data}
      spinnerStyle={{
        position: 'fixed',
        height: '100vh',
        zIndex: 9999,
        top: '-33px'
      }}
    />
  )
}

export default withAppContext(CustomerSupport)
