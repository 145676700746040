import { MouseEvent, PropsWithChildren, useState } from 'react'
import './Constraint.scss'
import { formatConstraintName } from '../../utils.js'
import { ConstraintType } from '../../constants'
import Silder from '../../Slider/Silder'

export interface IConstraint {
  id: string
  name: string
  type: string
  description: string
  isActive: boolean
  enabled: boolean
  weight: number
}

export interface IConstraintProps {
  constraint: IConstraint
  index: number
  draggableAttributes: {
    [key: string]: any
  }
  onSelectConstraint: (e: MouseEvent, index: number) => void
  showConstraintDetails: (constraint: IConstraint) => void
  swapConstraints: (index: number, method: string) => void
}

function Constraint({
  constraint,
  index,
  draggableAttributes,
  onSelectConstraint,
  showConstraintDetails,
  swapConstraints
}: PropsWithChildren<IConstraintProps>) {
  const [isHovered, setIsHovered] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const { weight, type, isActive, enabled } = constraint

  const isWeightageEnabled = window.REACT_APP_WEIGHTAGE_ENABLED_FOR_ALGO_SCHEDULE === 'true'

  const getTypeIcon = (type: string) => {
    return type === ConstraintType.SOFT ? (
      <sh-icon icon="unlock" size="xs" color="#3DC3B0" class="flex align-middle"></sh-icon>
    ) : (
      <sh-icon icon="lock" size="xs" color="#F19E6A"></sh-icon>
    )
  }
  const getCheckboxAttributes = () => {
    let additionalAttributes
    if (isActive) {
      additionalAttributes = { active: true }
    }
    if (enabled && type === ConstraintType.HARD) {
      additionalAttributes = { active: true, disabled: true }
    }
    if (!enabled) {
      additionalAttributes = { style: { display: 'none' } }
    }
    return additionalAttributes
  }

  const getRowAttributes = () => {
    let additionalAttributes
    if (type === ConstraintType.HARD && enabled) {
      additionalAttributes = { active: true, disabled: true }
    }
    return additionalAttributes
  }

  const rowAttributes = getRowAttributes()
  const checkBoxAttributes = getCheckboxAttributes()
  const constraintNameAttributes = !enabled
    ? {
        style: { color: '#000000', opacity: '0.4' }
      }
    : {}

  return (
    <div
      key={`constraint-wrapper-${constraint.id}`}
      {...draggableAttributes}
      onClick={() => showConstraintDetails(constraint)}
    >
      <sh-table-row
        {...rowAttributes}
        onClick={() => showConstraintDetails(constraint)}
        key={constraint.id}
        onMouseOver={() => {
          setIsHovered(true)
        }}
        onMouseOut={() => {
          setIsHovered(false)
        }}
        id={constraint.id}
      >
        {draggableAttributes.draggable ? (
          <span className="flex align-middle dragItem">
            <sh-icon icon="draggable-vertical-s"></sh-icon>
          </span>
        ) : (
          <></>
        )}
        <sh-table-cell checkbox>
          <sh-checkbox
            label=""
            onClick={(e: MouseEvent) => onSelectConstraint(e, index)}
            {...checkBoxAttributes}
          ></sh-checkbox>
        </sh-table-cell>
        <sh-table-cell columns="12" class="constraint-wrapper-height">
          <sh-text class="constraint-name-wrapper" {...constraintNameAttributes}>
            {formatConstraintName(constraint.name)}
          </sh-text>
        </sh-table-cell>
        <sh-table-cell columns="1" class="constraint-type-icon">
          {enabled && getTypeIcon(constraint.type)}
        </sh-table-cell>
        <sh-table-cell columns="10" style={{ height: 'auto' }}>
          {isWeightageEnabled && type === ConstraintType.SOFT && enabled && (
            <div>
              <Silder disabled={true} weight={weight} type={type} />
            </div>
          )}
        </sh-table-cell>
        <sh-table-cell column="1">
          {isHovered && (
            <span
              className="constraint-type-icon"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <sh-icon icon="information" size="xs" id={constraint.name} color="#6E7B9F"></sh-icon>
            </span>
          )}
        </sh-table-cell>
        {showTooltip && (
          <sh-tooltip
            class="param-information-tooltip"
            label={constraint.description}
            target={constraint.name}
            placement="top-right"
            variation="short"
            visible
          ></sh-tooltip>
        )}

        {draggableAttributes.draggable && (
          <>
            <span className="arrow-button-wrapper" onClick={() => swapConstraints(index, 'up')}>
              <sh-icon icon="arrow-line-up-s" class="arrow-buttons" size="s"></sh-icon>
            </span>
            <span className="arrow-button-wrapper ml10 mr10" onClick={() => swapConstraints(index, 'down')}>
              <sh-icon icon="arrow-line-down-s" class="arrow-buttons" size="s"></sh-icon>
            </span>
          </>
        )}
      </sh-table-row>
    </div>
  )
}

export default Constraint
