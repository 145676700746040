import { Component } from 'react'
import { withAppContext } from 'AppContext'
import { Map, List } from 'immutable'
import StaffProfileHeader from './StaffProfileHeader'
import StaffProfileInfo from './StaffProfileInfo'
import CreateEditStaffModal from '../CreateEditStaffModal'
import DepartmentEligibility from './DepartmentEligibility'
import StaffSchedule from './StaffSchedule'
import { Skills, Certifications, Equipment, Licenses } from './Expertises'
import {
  CreateEditSkillModal,
  CreateEditCertificationModal,
  CreateEditEquipmentModal,
  CreateEditLicenseModal
} from './CreateEditExpertiseModals'
import StaffDetailedSchedule from './StaffDetailedSchedule/StaffDetailedSchedule'
import CreateUpdateRecurringScheduleModal from './CreateEditRecurringScheduleModal'
import { Route } from 'react-router-dom'
import { Staff } from 'entityWrappers'
import { paths } from 'Navigation'
import TransferDepartmentModal from './TransferDepartmentModal'
import { ActionController, DataController } from './Controllers'
import StaffUnitAssignmentModal from './StaffUnitAssignmentModal'
import MismatchWarningModal from '../../Calendar/MismatchWarningModal'
import { Activities } from './Activities'
import './StaffDetails.scss'

export class StaffDetails extends Component {
  constructor(props) {
    super(props)
    this.dataController = new DataController(this)
    this.actionController = new ActionController(this)
    this.state = {
      ...this.dataController.defaultState,
      toggle: false,
      isRequirementsMismatchVisible: false,
      requirementsMismatchProps: {},
      calendar: null
    }
  }

  componentDidMount() {
    this.dataController.loadStaff()
  }

  showMismatchModal = (isRequirementsMismatchVisible, requirementsMismatchProps) => {
    this.setState({ isRequirementsMismatchVisible, requirementsMismatchProps })
  }

  getCalendar = (calendar) => {
    this.setState({ calendar })
  }

  render() {
    const { appState, timeService, Dialog, match } = this.props
    const { isLoading, isRequirementsMismatchVisible, requirementsMismatchProps, calendar } = this.state
    const { staff, units, staffPositions, expertises, expertiseGroups, loadStaffExpertiseForest, loadStaffActivities } =
      this.dataController
    const isStaffLoaded = !isLoading && staff.size > 0

    if (!isStaffLoaded) {
      return null
    }

    const staffExpertiseForest = loadStaffExpertiseForest(staff, expertises, expertiseGroups)
    const unitManager = appState.getIn(['authentication', 'facilityUser'])
    const facilityUser = new Staff(staff)
    const authProfile = appState.getIn(['authentication', 'profile']) || Map()
    const unit = appState.getIn(['generalData', 'unit'])
    const activities = appState.getIn(['activities', 'items'])
    const managerFirstName = authProfile.get('firstName')
    const managerLastName = authProfile.get('lastName')
    const facility = appState.getIn(['generalData', 'facility'])
    const shiftsMap = facility.get('shiftsMap')
    const { fullName, staffComplianceRules, expertiseCredentials, staffRecurringSchedules = List() } = facilityUser
    const managerFullName = facilityUser.managerFullName || `${managerFirstName} ${managerLastName}`
    const { userId } = match.params
    const createEditStaffModalProps = {
      staff,
      units,
      Dialog,
      timeService,
      unitManager,
      staffPositions,
      managerFullName,
      currentUnitId: unit.get('id'),
      isEdit: true,
      title: fullName,
      onSubmit: this.actionController.updateStaff
    }
    const roles = calendar?.get('roles')
    const shifts = roles?.reduce(
      (shiftsMemo, role) => role.get('shifts')?.reduce((memo, shift) => memo.push(shift), shiftsMemo),
      List()
    )

    const createUpdateRecurringScheduleModalProps = {
      units,
      staff,
      timeService,
      staffRecurringSchedules,
      shiftsMap,
      shifts,
      unit,
      onSubmit: this.actionController.updateStaff
    }
    const createEditExpertiseModalProps = {
      staffExpertiseCredentials: expertiseCredentials,
      expertises,
      expertiseGroups,
      timeService,
      onSubmit: this.actionController.updateStaffExpertises
    }

    const expertiseProps = {
      userId,
      timeService,
      staffExpertiseForest,
      staffExpertiseCredentials: expertiseCredentials,
      Dialog,
      updateStaff: this.actionController.updateStaffExpertises
    }
    const equipmentProps = {
      ...expertiseProps,
      expertiseGroups
    }
    const staffProfileInfoProps = { timeService, staff, unit, unitManager }
    const departmentEligibilityProps = { staff, units, timeService }
    const staffScheduleProps = {
      units,
      shifts,
      unit,
      match,
      timeService,
      staffComplianceRules,
      staffRecurringSchedules,
      // time off, sick days sections are not being displayed on <StaffSchedule />
      staffNotes: List(), // staff.get('notes'),
      requestsOff: List(), // staff.get('requestsOff')
      Dialog
    }

    const activityProps = {
      activities,
      unit,
      timeService,
      loadStaffActivities
    }

    return (
      <section className="hx-staff-details">
        <StaffProfileHeader
          timeService={timeService}
          staff={staff}
          Dialog={Dialog}
          isStaffLoaded={isStaffLoaded}
          cancellStaffTransfer={this.actionController.cancellStaffTransfer}
        />
        <div className="hx-staff-container pb30">
          <div className="row">
            <div className="col-3">
              <StaffProfileInfo {...staffProfileInfoProps} />
            </div>
            <div className="col-1"></div>
            <div className="col-8">
              <StaffDetailedSchedule showMismatchModal={this.showMismatchModal} getCalendar={this.getCalendar} />
              <DepartmentEligibility {...departmentEligibilityProps} />
              {/* <div className="hx-staff-container-box-title">
                <h4 className="river-bad">Schedule</h4>
              </div> */}
              <StaffSchedule {...staffScheduleProps} />
              <Equipment {...equipmentProps} />
              <Skills {...expertiseProps} />
              <Certifications {...expertiseProps} />
              <Licenses {...expertiseProps} />
              <Activities {...activityProps} />
            </div>
          </div>
        </div>
        <Route
          exact
          path={paths.StaffEditRecurringSchedule}
          render={(props) =>
            withProps(CreateUpdateRecurringScheduleModal, {
              ...props,
              ...createUpdateRecurringScheduleModalProps
            })
          }
        />
        <Route
          exact
          path={paths.StaffCreateRecurringSchedule}
          render={(props) =>
            withProps(CreateUpdateRecurringScheduleModal, {
              ...props,
              ...createUpdateRecurringScheduleModalProps
            })
          }
        />
        <Route
          exact
          path={[paths.StaffEditSkill, paths.StaffCreateSkill]}
          render={(props) =>
            withProps(CreateEditSkillModal, {
              ...props,
              ...createEditExpertiseModalProps
            })
          }
        />

        <Route
          exact
          path={[paths.StaffEditCertification, paths.StaffCreateCertification]}
          render={(props) =>
            withProps(CreateEditCertificationModal, {
              ...props,
              ...createEditExpertiseModalProps
            })
          }
        />

        <Route
          exact
          path={paths.StaffEditEquipment}
          render={(props) =>
            withProps(CreateEditEquipmentModal, {
              ...props,
              ...createEditExpertiseModalProps
            })
          }
        />

        <Route
          exact
          path={paths.StaffEditLicense}
          render={(props) =>
            withProps(CreateEditLicenseModal, {
              ...props,
              ...createEditExpertiseModalProps
            })
          }
        />

        <Route
          exact
          path={paths.StaffEdit}
          render={(props) =>
            withProps(CreateEditStaffModal, {
              ...props,
              ...createEditStaffModalProps
            })
          }
        />
        <Route exact path={paths.StaffTransferDepartment} component={TransferDepartmentModal} />
        <Route exact path={paths.StaffUnitAssignment} component={StaffUnitAssignmentModal} />
        {isRequirementsMismatchVisible && <MismatchWarningModal {...requirementsMismatchProps} />}
      </section>
    )
  }
}

export default withAppContext(StaffDetails)

function withProps(Component, componentProps, props) {
  const allProps = { ...props, ...componentProps }
  return <Component {...allProps} />
}
