import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { navigate, paths } from 'Navigation'
import RoleCreateUpdate from './RoleCreateUpdate'

export default class RoleCreate extends RoleCreateUpdate {
  render() {
    const { isUnitsLoaded } = this.controller
    if (!isUnitsLoaded) {
      return null
    }

    const { store, appState, match } = this.props
    const url = this.props.location.search

    const { unitId } = queryString.parse(url)
    if (!unitId) {
      return <Redirect to={paths.AdminRoles} />
    }

    const timeService = appState.getIn(['context', 'facilityTime'])

    const onFormSubmit = async (event, form) => {
      const { urlId } = match.params
      try {
        await this.controller.createUnitRole(unitId, form)
        await this.controller.loadUnits()
        return navigate.to.AdminRoles({ urlId })
      } catch {
        return navigate.to.AdminRoles({ urlId })
      }
    }

    let { required, properties } = store.getInputDefinition('OverrideUnitRoleInput')
    required.forEach((element) => {
      properties[element] = { ...properties[element], required: 'true' }
    })
    const unitHasManagementRole = this.controller.unitHasManagementRole(unitId)
    if (unitHasManagementRole) {
      const { isManagement, ...rest } = properties
      properties = rest
    }

    const inputDefinition = { properties }

    return this.renderModal(onFormSubmit, inputDefinition, {}, timeService, 'create', 'Role')
  }

  onSubmit = (e, form) => {
    // This is intentional
  }
}
