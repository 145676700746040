import { PureComponent } from 'react'
import { Switch, Route } from 'react-router-dom'
import { withAppContext } from 'AppContext'
import { paths } from 'Navigation'
import StaffList from './StaffList'
import StaffDetails from './StaffDetails'
import Footer from 'Footer'
import stores from 'stores'

const { expertiseStore } = stores
export class StaffLayout extends PureComponent {
  async componentDidMount() {
    const { staffStore, appState } = this.props
    await this.loadExpertises()
    const isStaffDataLoaded = appState.getIn(['staff', 'isStaffDataLoaded'])
    if (!isStaffDataLoaded) {
      staffStore.loadStaffData()
    }
    expertiseStore.loadExpertises()
  }

  loadExpertises() {
    return expertiseStore.loadExpertises()
  }

  render() {
    return (
      <div className="h100">
        <div className="wrapper-container">
          <Switch>
            <Route exact path={paths.Staff} component={StaffList} />
            <Route exact path={paths.AcceptStaffTransfer} component={StaffList} />
            <Route exact path={paths.StaffNew} component={StaffList} />
            <Route path={paths.StaffDetails} component={StaffDetails} />
          </Switch>
        </div>
        <Footer timeService={this.props.timeService} />
      </div>
    )
  }
}

export default withAppContext(StaffLayout)
