import { PureComponent } from 'react'
import { groupBy, map } from 'lodash'

export default class Months extends PureComponent {
  render() {
    const { days } = this.props
    const totalDaysCount = days.length
    const months = groupBy(days, 'month')

    const Month = (days, month) => {
      const width = (days.length / totalDaysCount) * 100
      const style = { width: `${width}%` }

      return (
        <div key={month} className="small river-bad black bb1 text-left upper" style={style}>
          {month}
        </div>
      )
    }

    return (
      <div data-testid="hx-calendar-header-month-time-off" className="hx-calendar-header-month row w100">
        {map(months, Month)}
      </div>
    )
  }
}
