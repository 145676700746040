import classNames from 'classnames'

export default function PrintModalFooter({ view, date, onClose, printNotes, printStatus, generateSchedulePdf }) {
  const { isPrinting, isGeneratingPdfError, isGeneratingPdf } = printStatus
  const isGenerateButtonHidden = isGeneratingPdfError || isGeneratingPdf || isPrinting
  const isGenerateButtonDisabled = isGeneratingPdf || isPrinting
  // const isPrintingButtonDisabled = isGeneratingPdf || isPrinting;
  // const isPrintButtonHidden = isGeneratingPdf || isPrinting;

  return (
    <footer>
      <button
        className={classNames('button float-right primary submit-button bg-focus ml10', {
          hide: isGenerateButtonHidden
        })}
        disabled={isGenerateButtonDisabled}
        onClick={() => generateSchedulePdf({ printNotes, view, date })}
      >
        Print
      </button>

      {/*<button
        className={classNames('button float-right primary submit-button', {
          hide: isPrintButtonHidden
        })}
        disabled={isPrintingButtonDisabled}
        onClick={() => print({ printNotes })}
      >
        Print
      </button>*/}

      {/*<button
        onClick={downloadSchedulePdf}
        className={classNames('button float-right primary download-button bg-focus', {
          hide: !downloadPdfUrl
        })}
      >
        Download
      </button>*/}
      <span className="float-right p5" />
      <button className="button float-right close-button outline" onClick={onClose}>
        Cancel
      </button>
      <span
        className={classNames('status-error', {
          hide: !isGeneratingPdfError
        })}
      >
        Error happened, please try again.
      </span>
      <span
        className={classNames('status-generating', {
          hide: !isGeneratingPdf
        })}
      >
        Generating a file, please wait...
      </span>
      <span
        className={classNames('status-generating', {
          hide: !isPrinting
        })}
      >
        Prepare for printing, please wait...
      </span>
    </footer>
  )
}
