import { List, Map } from 'immutable'
import classNames from 'classnames'
import { PureComponent } from 'react'
import FilterMenuOption from './FilterMenuOption'
import './MultiSelectFilter.scss'

export default class MultiSelectFilter extends PureComponent {
  static defaultProps = { deselectAllAllowed: true, showHist: true }

  constructor(props) {
    super(props)
    const { options = Map(), values = List() } = props
    this.state = { isActive: false, isHovered: false, options, values }
  }

  componentDidUpdate(prevProps) {
    const { options, values } = this.props
    const { options: prevOptions, values: prevValues } = prevProps

    const isOptionsChanged = options !== prevOptions
    const isValuesChanged = values !== prevValues

    if (isOptionsChanged || isValuesChanged) {
      this.setState({ options, values })
    }
  }

  render() {
    const { name, minWidth, icons, field, deselectAllAllowed, showHist } = this.props
    const { isActive, options, values } = this.state

    const style = `width-${minWidth}`
    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    const areAllOptionsSelected = this._isAllSelected()

    let textValue
    if (showHist) {
      switch (true) {
        case areAllOptionsSelected:
          textValue = 'All'
          break

        case values.size === 1: {
          const value = values.get(0)
          textValue = options.get(value)
          break
        }
        case values.size === 0:
          textValue = '- Select value(s) -'
          break

        default:
          textValue = `${values.size} values`
      }
    }

    return (
      <aside className="hx-filters" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <div className="option-value">
            <sh-text>{name}: </sh-text>
            <div className={`selected-value ${style}`}>
              <sh-text size="title-1">
                {textValue}
                <span>
                  <i className={arrowClassName} />
                </span>
              </sh-text>
            </div>
          </div>
        </button>
        <div className="custom-multiselect-filter p20 bg-white filters-content" onMouseDown={(e) => e.preventDefault()}>
          <div style={{ width: '100%' }}>
            <FilterMenuOption
              key="all"
              id={`hx-checkbox[${field}]["all"]`}
              active={areAllOptionsSelected}
              disabled={areAllOptionsSelected && !deselectAllAllowed}
              bordered={true}
              label="All"
              onChange={(e) => this.updateConfig('all', e)}
              icon={icons?.['all']}
            />
            {options.keySeq().map((key) => {
              const checked = values.includes(key)

              const checkboxProps = {
                key,
                id: `hx-checkbox[${field}][${key}]`,
                option: key,
                label: options.get(key),
                active: checked,
                onChange: (e) => this.updateConfig(key, e),
                disabled: !deselectAllAllowed && this._isLastOneSelected(key),
                icon: icons?.[key]
              }
              return <FilterMenuOption {...checkboxProps} key={key} />
            })}
          </div>
        </div>
      </aside>
    )
  }

  _getAllOptions() {
    const { options } = this.props
    return options
      .keySeq()
      .filter((option) => option !== 'all')
      .toList()
  }

  _isAllSelected() {
    const { values } = this.state
    return this._getAllOptions().every((option) => values.includes(option))
  }

  _isLastOneSelected(option) {
    const { values } = this.state
    return values.size === 1 && values.includes(option)
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive
    }))
  }

  updateConfig = (option) => {
    const { setConfig, deselectAllAllowed } = this.props
    const { values } = this.state

    if (option === 'all') {
      const allOptions = this._getAllOptions()
      const isAllSelected = this._isAllSelected()
      const newConfig = deselectAllAllowed && isAllSelected ? List() : allOptions
      return setConfig(newConfig)
    }

    const config = values.includes(option) ? values.filter((o) => o !== option) : values.push(option)

    if (!deselectAllAllowed && config.size === 0) {
      return setConfig(values)
    }

    return setConfig(config)
  }

  hideDropdown = (e) => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 300)
  }
}
