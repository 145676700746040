import adminStores from '../Admin/stores'
import openShiftStore from './openShiftStore'
import openShiftsStore from './openShiftsStore'
import staffStore from './staffStore'
import shiftSwapsStore from './shiftSwapsStore'
import activitiesStore from './activitiesStore'
import calendarStore from './calendarStore'
import timeOffStore from './timeOffStore'
import generalStore from './generalStore'
import announcementsStore from './announcementsStore'
import expertiseStore from './expertiseStore'
import feedbackStore from '@humanics/he-react-common/lib/stores/feedbackStore'
import dashboardStore from './dashboardStore'
import { algoScheduleStore, constraintStore } from './autoSchedularStore'
import { default as autoScheduleCalendarStore } from './autoSchedularStore/AutoScheduleCalendarStore'
import staffCalendarStore from './staffCalendarStore'
import staffShiftSwapsStore from './staffShiftSwapsStore'
import staffTimeOffRequestsStore from './staffTimeOffRequestsStore'
import staffGeneralStore from './staffGeneralStore'
import remoteWorkflowStore from './remoteWorkflowStore'

import TimeService from 'services/TimeService'

const STORES = {
  calendarStore,
  adminStores,
  openShiftsStore,
  staffStore,
  feedbackStore,
  shiftSwapsStore,
  activitiesStore,
  openShiftStore,
  timeOffStore,
  generalStore,
  announcementsStore,
  expertiseStore,
  constraintStore,
  algoScheduleStore,
  autoScheduleCalendarStore,
  dashboardStore,
  staffCalendarStore,
  staffShiftSwapsStore,
  staffTimeOffRequestsStore,
  staffGeneralStore,
  remoteWorkflowStore
}

export default function Stores() {
  const stores = Object.entries(STORES).reduce((memo, [name, store]) => {
    memo[name] = store()
    return memo
  }, {})

  // as default and handy for unit testing.
  let facilityTime = new TimeService('GMT')

  return {
    initialize,
    timeService: () => facilityTime,
    ...stores
  }

  function initialize(state, context) {
    facilityTime = context.facilityTime

    return Object.entries(stores).reduce((state, [, store]) => store.initialize(state, context), state)
  }
}
