import { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { withAppContext } from 'AppContext'
import { curry } from 'i18n'

const t = curry('staff.')

export class ActionButton extends Component {
  static propTypes = {
    facilityStaff: PropTypes.instanceOf(Map).isRequired,
    inviteUser: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
    revokePermissions: PropTypes.func.isRequired
  }

  onRevokePermissionsClick = (userId) => async (event) => {
    event.preventDefault()
    const { Dialog } = this.props
    const answer = await Dialog.confirm('Are you sure you wish to revoke user permissions?', {
      type: 'error',
      accept: t('confirmRevokePermissions')
    })
    if (answer === true) {
      this.props.revokePermissions({ userId })
    }
  }

  onResendInviteClick = (userId) => async (event) => {
    event.preventDefault()
    const { Dialog } = this.props
    const answer = await Dialog.confirm('Are you sure you wish to resend invitation?', {
      type: 'warning',
      accept: t('confirmStaffInviteResending')
    })
    if (answer === true) {
      this.props.resendInvitation({ userId })
    }
  }

  onInviteClick = (userId) => (event) => {
    event.preventDefault()
    this.props.inviteUser({ parameters: { userId } })
  }

  render() {
    const { facilityStaff, authUserId } = this.props

    const isInvitationSent = facilityStaff.get('isInvitationSent'),
      isInvitationAccepted = facilityStaff.get('isInvitationAccepted'),
      hasPermissions = facilityStaff.get('hasPermissions'),
      userId = facilityStaff.get('userId')

    if (isInvitationAccepted || hasPermissions) {
      const isCurrentUser = authUserId === userId
      return isCurrentUser ? null : (
        <sh-button
          className="_revokePermissions"
          color="secondary"
          onClick={this.onRevokePermissionsClick(userId)}
          size="l"
          label="Revoke Permissions"
        />
      )
    }

    if (isInvitationSent && !isInvitationAccepted) {
      return (
        <sh-button
          color="primary"
          className="_resendInvite"
          onClick={this.onResendInviteClick(userId)}
          size="l"
          label="Resend Invite"
        />
      )
    }

    return (
      <sh-button
        color="primary"
        className="_sendInvite"
        onClick={this.onInviteClick(userId)}
        size="l"
        label="Send Invite"
      />
    )
  }
}

export default withAppContext(ActionButton)
