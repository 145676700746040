import { Component } from 'react'
import SelectFilter from 'Manager/components/SelectFilter'
import SearchInput from 'components/Form/SearchInput'
import { keyBy } from 'utils'
import './ShiftSwapsFilter.scss'

export default class ShiftSwapsFilter extends Component {
  render() {
    const { controller, appState } = this.props
    const { getState, onShiftFilterChange, onRoleFilterChange, onSortFilterChange } = controller
    const { shiftFilter, roleFilter, sortType } = getState()

    const unit = appState.getIn(['generalData', 'unit'])
    const shiftTypes = unit.get('shiftTypes')

    const shiftTypesOptions = shiftTypes.reduce((memo, shiftType) => {
      const title = shiftType.get('title')
      const shiftTypeId = shiftType.get('id')
      return { ...memo, [shiftTypeId]: title }
    }, {})

    const roleIds = appState.getIn(['shiftSwapsState', 'roleIds']) || []
    const unitRoles = unit.get('roles')
    const unitRolesMap = keyBy(unitRoles, 'id')

    const roleOptions = roleIds.reduce(
      (memo, roleId) => ({ ...memo, [roleId]: unitRolesMap.getIn([roleId, 'name']) }),
      {}
    )

    const shiftProps = {
      ...getShiftDefinition(shiftTypesOptions),
      value: shiftFilter,
      setConfig: (option) => onShiftFilterChange(option)
    }

    const roleProps = {
      ...getRoleFilterDefinition(roleOptions),
      value: roleFilter,
      setConfig: (option) => onRoleFilterChange(option)
    }

    const sortTypeProps = {
      ...getSortTypeDefinition(),
      value: sortType,
      setConfig: (option) => onSortFilterChange(option)
    }

    return (
      <div className="row align-middle calendar-toolbar">
        <div className="col-6 row align-middle">
          <span className="river-bad">
            <SelectFilter {...shiftProps} />
          </span>

          <span className="river-bad pr5">
            <SelectFilter {...roleProps} />
          </span>

          <span className="river-bad p15 pv0 bl1">
            <SelectFilter {...sortTypeProps} />
          </span>
        </div>

        <div className="col-5 row align-middle align-center">
          <div className="title">
            <h1>Shift Swaps</h1>
          </div>
        </div>

        <div className="col-1 row align-right bl1">
          <SearchInput
            classNames="bg-alabaster"
            id="search"
            onChange={controller.onSearchChange}
            onReset={controller.onSearchChange}
            iconStyle={{ left: '-2px', top: '1em' }}
          />
        </div>
      </div>
    )
  }
}

const getShiftDefinition = (shiftTypesOptions) => ({
  minWidth: 100,
  name: 'Shift',
  options: {
    all: 'All',
    ...shiftTypesOptions
  }
})

const getRoleFilterDefinition = (roleOptions) => {
  return {
    minWidth: 100,
    name: 'Staff',
    options: {
      all: 'All',
      ...roleOptions
    }
  }
}

const getSortTypeDefinition = () => ({
  minWidth: 100,
  name: 'Sort by',
  options: {
    lastAccepted: 'Last Accepted',
    soonestShift: 'Soonest Shift'
  }
})
