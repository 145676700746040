import { PureComponent } from 'react'
import { PopUp } from 'Manager/components'
import Item from './Item'
import TimeOffsSubmenu from './TimeOffsSubmenu'
import classNames from 'classnames'

// TODO refactor to have context menu component independent from data
export default class CellContextMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isHidden: true, shownSubmenuId: 'changeTimeOff' }
  }

  render() {
    const { isCellContextMenuVisible, scrollTop, popupProps, unit } = this.props
    if (!isCellContextMenuVisible) {
      return null
    }

    const { shownSubmenuId } = this.state

    const isTimeOffsSubmenuShown = shownSubmenuId === 'changeTimeOff'
    const eventVariants = unit.get('eventVariants')
    const timeOffEventVaraints = eventVariants.filter((variant) => variant.get('type') === 'timeOff')
    const timeoffsSubmenuProps = { popupProps, timeOffEventVaraints }

    timeoffsSubmenuProps.onClickHandler = (unitEventVariant, subject) => () =>
      this.changeTimeOff(unitEventVariant, subject)

    return (
      <PopUp {...popupProps} scrollTop={scrollTop} onBlur={this.hide}>
        <div
          data-testid="hx-calendar-cell-context-menu"
          className="context hx-calendar-context-menu"
          tabIndex="1"
          ref={(ref) => {
            this.ref = ref
          }}
        >
          <Item
            title="Change Time Off"
            hasSubmenu={true}
            className={classNames({
              selected: isTimeOffsSubmenuShown
            })}
            onMouseEnter={(e) => this.showSubmenu('changeTimeOff')}
          />

          {isTimeOffsSubmenuShown && <TimeOffsSubmenu {...timeoffsSubmenuProps} />}
        </div>
      </PopUp>
    )
  }

  showSubmenu = (shownSubmenuId) => this.setState({ shownSubmenuId })

  changeTimeOff = async (eventVariant, subject) => {
    const { changeTimeOff, hideContextMenu } = this.props
    const note = { subject, text: '' }

    changeTimeOff(eventVariant, note)
    hideContextMenu()
  }
}
