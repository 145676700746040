import { PureComponent } from 'react'
// import gql from 'graphql-tag';
// import { Subscription, ApolloProvider } from 'react-apollo';

export default class Subscriptions extends PureComponent {
  render() {
    // Here we can define and handle subscriptions for a calendar:
    return null
    // const { gqlClient } = this.props;

    // const unitSubscription = gql`
    //   subscription IndexCalendarCells(
    //     $startDate: String = "2019-02-01T00:00:00.000Z"
    //     $endDate: String = "2019-02-10T00:00:00.000Z"
    //     $shiftIds: [String] = ["5c6aeaa0028d680c0d7067d8"]
    //     $userIds: [String] = ["5c6aea9e028d680c0d70678d"]
    //   ) {
    //     indexCalendarCells(
    //       startDate: $startDate
    //       endDate: $endDate
    //       shiftIds: $shiftIds
    //       userIds: $userIds
    //     ) {
    //       name
    //     }
    //   }
    // `;

    // const unitSubscribers = gql`
    //   subscription IndexCalendarCellsSubscribers(
    //     $startDate: String = "2019-02-01T00:00:00.000Z"
    //     $endDate: String = "2019-02-10T00:00:00.000Z"
    //   ) {
    //     indexCalendarCellsSubscribers(
    //       startDate: $startDate
    //       endDate: $endDate
    //     ) {
    //       userId
    //     }
    //   }
    // `;

    // return (
    //   <ApolloProvider client={gqlClient.client}>
    //     <Subscription
    //       subscription={unitSubscription}
    //       onSubscriptionData={(...data) => {
    //         console.log('unitSubscription: new event created with data', data);
    //       }}
    //     />
    //     {<Subscription
    //    subscription={unitSubscribers}
    //    onSubscriptionData={data => {
    //      console.log('unitSubscribers', data);
    //    }}
    //  >
    //    {(...args) => (
    //      <h4>
    //        Subscribers:&nbsp;
    //        {args[0].data
    //          ? args[0].data.indexCalendarCellsSubscribers
    //              .map(sub => sub.userId)
    //              .join(', ')
    //          : ''}
    //      </h4>
    //    )}
    //  </Subscription>}
    //   </ApolloProvider>
    // );
  }
}
