import { List, Map } from 'immutable'
import TimeOffDefinitionsService from './TimeOffDefinitionsService'

const subjects = {
  StaffPaidTimeOff: TimeOffDefinitionsService.buildPaidTimeOffOptions(),
  StaffUnavailability: TimeOffDefinitionsService.buildUnavailabilityOptions(),
  StaffEvent: {
    Meeting: [
      'Class',
      'Class - ACLS',
      'Class - BLS',
      'Class - BVP',
      'Class - EKG',
      'Class - TTP',
      'Leadership Meeting',
      'Meeting',
      'Office Hours',
      'Orientation'
    ],
    NewStaffEvent: [
      'Call Out',
      'On Call',
      'Extra Pay',
      'Time Off',
      'Partial Time Off',
      'Sick',
      'Other',
      'Cancel'
    ].sort(),
    ExistedStaffEvent: [
      'Call Out',
      'On Call',
      'Extra Pay',
      'Time Off',
      'Partial Time Off',
      'Sick',
      'Other',
      'Cancel'
    ].sort()
  }
}

export const cancelledSubjects = [
  'Call Off',
  'Cancel',
  'Home Sick',
  'Involuntary Flex',
  'No Call No show',
  'Sick',
  'Sick page',
  'Voluntary Flex'
]

export default function noteSubjects(note, documentType, staffEvent, shift) {
  const isStaffEvent = documentType === 'StaffEvent'

  if (isStaffEvent) {
    return noteSubjectsForStaffEvent(note, documentType, staffEvent, shift)
  }

  return noteSubjectsForTimeOff(documentType)
}

export function noteSubjectsForTimeOff(documentType) {
  return List(subjects[documentType]).map((subject) => Map({ subject }))
}

function noteSubjectsForStaffEvent(note, documentType, staffEvent, shift) {
  const { isShiftEvent, isMeetingEvent, staffEventShiftId } = staffEvent
  const isNoteExisted = !!note.get('id')
  const noteSubject = note.get('subject')
  const subjectsForStaffEventTypes = subjects[documentType]
  let subjectsForStaffEventType
  if (isMeetingEvent) {
    subjectsForStaffEventType = subjectsForStaffEventTypes['Meeting']
  }

  if (shift) {
    const shiftId = shift.get('id')
    const isStaffEventExisted = isShiftEvent && shiftId === staffEventShiftId
    const staffEventType = isStaffEventExisted ? 'ExistedStaffEvent' : 'NewStaffEvent'
    subjectsForStaffEventType = subjectsForStaffEventTypes[staffEventType]
  }

  if (subjectsForStaffEventType) {
    let subjectsList = List(subjectsForStaffEventType).map((subject) => Map({ subject }))

    const isNoteSubjectInList = subjectsList.filter((subject) => subject.get('subject') === noteSubject).size > 0

    if (isNoteExisted && !isNoteSubjectInList) {
      subjectsList = subjectsList.unshift(Map({ subject: noteSubject }))
    }

    return subjectsList
  }

  return List()
}
