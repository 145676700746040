import { Component } from 'react'
import AddComponentButton from './EditButtons/AddComponentButton'
import './SubjectPreviewComponent.scss'

export default class SubjectPreviewComponent extends Component {
  state = {
    isHovering: false
  }

  subjectPlaceHolder = () => {
    return this.props.subject ? this.props.subject : '(Subject)'
  }

  subtitlePlaceHolder = () => {
    return this.props.subtitle ? this.props.subtitle : '(Subtitle)'
  }

  render() {
    const { subject, showSubtitle, subtitle, hasDetailsPage, isFormEditable } = this.props
    const { insertDetailsComponent } = this.props

    const extendedPreviewSubjectStyle = {
      // height: '100%',
      paddingLeft: '38px',
      paddingRight: '38px',
      paddingTop: '30px',
      paddingBottom: '30px',
      fontSize: '24px',
      fontWeight: '710',
      color: '#000000',
      position: 'relative',

      boxSizing: 'inherit'
    }

    return (
      <div
        className="extended-preview-subject"
        style={extendedPreviewSubjectStyle}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        {/* Subject div with placeholders */}
        <div>
          <div style={subject ? { opacity: '1.0' } : { opacity: '0.5' }}>{this.subjectPlaceHolder()} </div>
          {showSubtitle && (
            <div style={subtitle ? { opacity: '1.0' } : { opacity: '0.5' }} className="extended-preview-subtitle">
              {this.subtitlePlaceHolder()}
            </div>
          )}
        </div>

        {/* Buttons */}
        {this.state.isHovering && hasDetailsPage && isFormEditable && (
          <div className="add-component-button-below">
            <AddComponentButton insertDetailsComponent={insertDetailsComponent} />
          </div>
        )}
      </div>
    )
  }
}
