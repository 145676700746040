import classNames from 'classnames'

const FilterMenuOption = ({
  id,
  label,
  active,
  disabled,
  icon,
  leaveGapForIcon,
  onChange,
  indent,
  bordered,
  type = 'checkbox'
}) => {
  const iconColumnWidth = 2
  const indentWidth = 1
  let labelColumnWidth = 12
  if (icon || leaveGapForIcon) {
    labelColumnWidth -= iconColumnWidth
  }
  if (indent) {
    labelColumnWidth -= indentWidth
  }

  let core
  switch (type) {
    case 'checkbox':
      core = (
        <sh-checkbox
          id={id}
          label={label}
          checkbox
          active={active ? true : undefined}
          disabled={disabled ? true : undefined}
          onClick={onChange}
        />
      )

      break

    case 'radio':
      core = (
        <sh-radio-button
          id={id}
          label={label}
          checkbox
          active={active ? true : undefined}
          disabled={disabled ? true : undefined}
          onClick={onChange}
        />
      )

      break

    default:
  }

  return (
    <div className={classNames('row align-middle p5 ph0', { bb1: bordered })}>
      {indent && <div className={`col-${indentWidth}`} />}
      <div className={`col-${labelColumnWidth}`}>{core}</div>
      {(icon || leaveGapForIcon) && (
        <div className={`col-${iconColumnWidth} row align-right`}>
          {icon && (
            <span className="rock-blue">
              <i className={icon} />
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default FilterMenuOption
