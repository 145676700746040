import classNames from 'classnames'
import { PureComponent } from 'react'
import './TopNavigationMore.scss'

export default class TopNavigationMore extends PureComponent {
  constructor(props) {
    super(props)
    const { options = {}, values = [] } = props
    this.state = { isActive: false, isHovered: false, options, values }
  }

  render() {
    const { name = 'More', minWidth, Items } = this.props
    const { isActive } = this.state

    const style = `width-${minWidth}`
    const arrowClassName = classNames('icon more', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    return (
      <div
        data-testid="hx-top-navigation-item-more"
        className="hx-top-navigation-item row align-middle _more-items"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="label hide">0</div>
        <div
          className={classNames('row align-middle _more', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <i className="icon-nav-more" />
          <div className="row align-middle">
            {name}
            <div className={`selected-value ${style}`}>
              <i className={arrowClassName} />
            </div>
          </div>
        </div>
        <div className="top-navigation-more pt20 pb20 pl30 pr30 filters-content">{Items}</div>
      </div>
    )
  }

  toggleDropdown = (e) => {
    this.setState((prevState, props) => ({
      isActive: !prevState.isActive
    }))
  }

  hideDropdown = (e) => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 300)
  }
}
