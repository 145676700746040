import { PureComponent } from 'react'
import ModeFull from './ModeFull'
import './Header.scss'

export default class Header extends PureComponent {
  render() {
    const { activeDateRange, timeService } = this.props
    return (
      <section className="hx-calendar-header row bg-white">
        <header className="col-12 bg-alabaster">
          <ModeFull activeDateRange={activeDateRange} timeService={timeService} />
        </header>
      </section>
    )
  }
}
