import { Modal } from 'Common/components'

export default function WarningModal({ onClose }) {
  const properties = {
    visible: true,
    onClose,
    title: 'Action is not allowed'
  }

  return (
    <Modal {...properties}>
      <div className="p10 big">
        This staff member does not have a Primary Shift in this unit. Please right-click to add a secondary shift.
      </div>
      <footer>
        <button className="button secondary borderless float-right" onClick={onClose}>
          Close
        </button>
      </footer>
    </Modal>
  )
}
