const createUpdateStore = ({
  isStaff,
  shouldUpdateStaffProfile,
  staffProfile,
  id,
  updateStaffProfileOperationId,
  isUnitManager,
  shouldUpdateUnitManagerProfile,
  unitManagerProfile,
  updateUnitManagerProfileOperationId,
  store
}) => {
  const promises = []
  if (isStaff && shouldUpdateStaffProfile) {
    promises.push(
      store.update(
        {
          form: { staffProfile, userId: id },
          updateOperationId: updateStaffProfileOperationId
        },
        id
      )
    )
  }
  if (isUnitManager && shouldUpdateUnitManagerProfile) {
    promises.push(
      store.update(
        {
          form: { unitManagerProfile, userId: id },
          updateOperationId: updateUnitManagerProfileOperationId
        },
        id
      )
    )
  }

  return Promise.all(promises)
}
export default createUpdateStore
