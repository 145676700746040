import useShuiEventHook from './customHooks/shuiEventHook'

import { useCallback } from 'react'
import { locations, getLabelForLocation, getLocationFromLabel } from '../utils'
import { KEYBOARD_KEYS } from 'Common/constants/KeyboardKeys'
import { ActivityTracker } from './activityTracker'

export interface ILocationDropdownProps {
  location: string
  setLocation: (location: string) => void
  trackActivity?: boolean
}

const LocationDropdown = ({ location, setLocation, trackActivity = false }: ILocationDropdownProps) => {
  const locationLabel = getLabelForLocation(location)
  const handleLocationChangeEvent = useCallback(
    (event: any) => {
      const selectedLocation = getLocationFromLabel(event?.target?.value)
      setLocation(selectedLocation)
    },
    [setLocation]
  )

  const eventName = 'value-changed'
  const locationDropdownRef = useShuiEventHook(eventName, handleLocationChangeEvent)

  const handleKeyUpEventForDropdown = (event: KeyboardEvent) => {
    if (event.code === KEYBOARD_KEYS.Space || event.code === KEYBOARD_KEYS.Enter) {
      handleDropdownClick()
    }
  }

  const handleDropdownClick = () => {
    if (trackActivity) {
      ActivityTracker.StartTracking()
    }
  }

  return (
    <sh-dropdown
      ref={locationDropdownRef}
      label="Location"
      editable
      value={locationLabel}
      onClick={handleDropdownClick}
      onKeyUp={handleKeyUpEventForDropdown}
    >
      {locations.map(({ label, key }) => (
        <sh-menu-item label={label} key={key}></sh-menu-item>
      ))}
    </sh-dropdown>
  )
}

export default LocationDropdown
