import { PureComponent } from 'react'
import './ActiveDateRange.scss'
import { DateRangeState } from 'Manager/components'
import { withAppContext } from 'AppContext'
import DateRangeSelector from '../DateRangeSelector'
import { Link } from 'react-router-dom'

export class ActiveDateRange extends PureComponent {
  state = { isOpen: false }

  render() {
    const { unit, activeDateRange, showStepper, stepperLeftPath, stepperRightPath } = this.props
    const { isOpen } = this.state
    const setIsOpen = (isOpen) => () => this.setState({ isOpen })

    if (!unit || !unit.get('schedule')) {
      return (
        <div className="hx-calendar-datepicker">
          <div className="giant semibold">
            <div className="loading" />
          </div>
        </div>
      )
    }

    const state = activeDateRange.get('state')
    const title = activeDateRange.get('title')
    const isSchedulesLoaded = unit.get('isSchedulesLoaded')
    const endsAt = activeDateRange.get('endsAt')

    // Can be used for incorporating index in the stepper label
    // const weekIndex = timeService.timeMoment(usDate).week() - timeService.timeMoment(activeDateRange.get('startsAt')).week() + 1

    return (
      <>
        {!isOpen && (
          <div className="hx-calendar-datepicker p10 pv0 mr10 rounded align-middle" onClick={setIsOpen(true)}>
            <span data-testid="date-title" className="dateTitle bold pv0 nowrap">
              {title}
            </span>
            <DateRangeState state={state} endDate={endsAt} />
            <i className="nano icon-Chevron---Down" />
          </div>
        )}
        {isSchedulesLoaded && <DateRangeSelector {...this.props} hideDateRange={setIsOpen(false)} isShown={isOpen} />}
        {showStepper && <InDateRangeStepper leftPath={stepperLeftPath} rightPath={stepperRightPath} label={'Week'} />}
      </>
    )
  }
}

function InDateRangeStepper({ leftPath, rightPath, label }) {
  const leftIcon = <sh-icon icon="arrow-face-left-l" size="s" button />
  const rightIcon = <sh-icon icon="arrow-face-right-l" size="s" button />
  return (
    <div className="row align-middle week-stepper">
      {leftPath ? wrapInLink(leftIcon, leftPath) : leftIcon}
      <span>
        <div className="trout upper black">{label}</div>
      </span>
      {rightPath ? wrapInLink(rightIcon, rightPath) : rightIcon}
    </div>
  )
}

function wrapInLink(component, path) {
  return <Link to={path}>{component}</Link>
}

export default withAppContext(ActiveDateRange)
