import { Component } from 'react'
import { withAppContext } from 'AppContext'
import StaffProfileInfo from 'Manager/Staff/StaffDetails/StaffProfileInfo'
import { Skills, Certifications, Equipment, Licenses } from 'Manager/Staff/StaffDetails/Expertises'
import { Staff } from 'entityWrappers'
import { ActionController, DataController } from 'Manager/Staff/StaffDetails/Controllers'
import './StaffProfile.scss'
import { List } from 'immutable'
import stores from 'stores'
import EditStaffModal from './EditStaffModal'
import { Route } from 'react-router-dom'
import { paths } from 'Navigation'

export class StaffProfile extends Component {
  constructor(props) {
    super(props)
    this.dataController = new DataController(this)
    this.actionController = new ActionController(this)
    this.state = {
      ...this.dataController.defaultState
    }
  }
  getRoleIds = (auth) => {
    return auth.getIn(['facilityUser', 'roleIds']).toJS() || List()
  }

  getUserId = (auth) => {
    return auth.getIn(['facilityUser', 'userId']) || ''
  }

  componentDidMount() {
    const { expertiseStore } = stores
    expertiseStore.loadExpertises()

    const auth = this.props.appState.get('authentication')

    this.userId = this.getUserId(auth)
    const roleIds = this.getRoleIds(auth)

    this.isStaffOnly = roleIds.length === 1 && roleIds[0] === 'staff'

    this.dataController.loadStaff(this.userId, this.isStaffOnly)
  }

  render() {
    const { appState, timeService, Dialog, authStore, history } = this.props
    const { isLoading } = this.state
    const { staff, expertises, expertiseGroups, loadStaffExpertiseForest } = this.dataController
    const isStaffLoaded = !isLoading && staff.size > 0

    if (!isStaffLoaded) {
      return null
    }

    const staffExpertiseForest = loadStaffExpertiseForest(staff, expertises, expertiseGroups)
    const unitManager = appState.getIn(['authentication', 'facilityUser'])
    const facilityUser = new Staff(staff)
    const { expertiseCredentials, managerFullName, eligibleUnits, fullName, staffPosition } = facilityUser
    const [unit] = eligibleUnits
    const { homeUnitName } = unit.toJS()

    const context = appState.getIn(['context'])
    const authorized = context.getIn(['authorized'])
    const facilityId = authorized.facilityScope
    const accountsUri = context.getIn(['gasApiUri'])

    const editStaffModalProps = {
      staff,
      timeService,
      managerFullName: managerFullName,
      currentUnitId: homeUnitName,
      staffPosition,
      isEdit: true,
      title: fullName,
      userId: this.userId,
      facilityId,
      accountsUri,
      onSubmit: async (...args) => {
        await this.actionController.updateStaff(...args)
        await authStore.authorize()
        history.push(paths.StaffProfilePage)
      }
    }
    const expertiseProps = {
      userId: this.userId,
      timeService,
      staffExpertiseForest,
      staffExpertiseCredentials: expertiseCredentials,
      Dialog,
      updateStaff: this.actionController.updateStaffExpertises
    }
    const equipmentProps = {
      ...expertiseProps,
      expertiseGroups
    }
    const staffProfileInfoProps = { timeService, staff, unit, unitManager, isStaffOnly: this.isStaffOnly }

    return (
      <div className="h100">
        <div className="wrapper-container">
          <section className="hx-staff-details">
            <div className="hx-staff-container pb30">
              <div className="row">
                <div className="col-3 staff-profile-col">
                  <StaffProfileInfo {...staffProfileInfoProps} />
                </div>
                <div className="col-1"></div>
                <div className="col-8">
                  <Equipment {...equipmentProps} />
                  <Skills {...expertiseProps} />
                  <Certifications {...expertiseProps} />
                  <Licenses {...expertiseProps} />
                </div>
              </div>
            </div>
            <Route
              exact
              path={paths.StaffProfileEdit}
              render={(props) =>
                withProps(EditStaffModal, {
                  ...props,
                  ...editStaffModalProps
                })
              }
            />
          </section>
        </div>
      </div>
    )
  }
}

export default withAppContext(StaffProfile)

function withProps(Component, componentProps, props) {
  const allProps = { ...props, ...componentProps }
  return <Component {...allProps} />
}
