import { getLabelForStudyCodes, getLabelForLocation, format24HourTimeTo12HourTime } from '../utils'

export interface IPreferenceBarProps {
  preferences: any
  timeService: any
}

const PreferenceBar = ({ preferences, timeService }: IPreferenceBarProps) => {
  const { studyCodes, location, date, time, insuranceAuthorization } = preferences
  const preferredStudyCodesLabel = getLabelForStudyCodes(studyCodes)
  const preferredLocationLabel = getLabelForLocation(location) ?? ''
  const preferredDate = date
  const preferredTime = time ? format24HourTimeTo12HourTime(time, timeService) : null

  return (
    <div className="row g10 mb10">
      <div className="col mt2">
        <sh-text size="body-1" color="secondary">
          Preferences:
        </sh-text>
      </div>
      {preferredLocationLabel && <sh-tag condensed label={`Location: ${preferredLocationLabel}`}></sh-tag>}
      <sh-tag condensed label={`Study code: ${preferredStudyCodesLabel}`} style={{ maxWidth: '100%' }}></sh-tag>
      {preferredDate && <sh-tag condensed label={`Date: ${preferredDate}`}></sh-tag>}
      {preferredTime && <sh-tag condensed label={`Time: ${preferredTime}`}></sh-tag>}
      <sh-tag condensed label={`Insurance authorization: ${insuranceAuthorization ? 'Yes' : 'No'}`}></sh-tag>
    </div>
  )
}

export default PreferenceBar
