const readCalendarDayDataParams = (unitId, startDate, endDate, shiftIds) => {
  return {
    unitId,
    shiftDaysStartDate: startDate,
    shiftDaysEndDate: endDate,
    notesStartDate: startDate,
    notesEndDate: endDate,

    openShiftUnitId: unitId,
    openShiftsStartDate: startDate,
    openShiftsLimit: 999,

    shiftOpportunitiesStartDate: startDate,
    shiftOpportunitiesEndDate: endDate,
    shiftOpportunitiesLimit: shiftIds.length,
    shiftOpportunitiesShiftIds: shiftIds,

    staffEventsLimit: 999,
    staffEventsStartDate: startDate,
    staffEventsEndDate: endDate
  }
}

const staffEventFiltered = (staffEvents, timeService, startDate) => {
  const startPlusOneDayDate = timeService.timeMoment(startDate).add(1, 'day').add(-1, 'minute')
  return staffEvents.filter((staffEvent) => {
    const startsAt = timeService.timeMoment(staffEvent['startsAt'])
    const endsAt = timeService.timeMoment(staffEvent['endsAt'])
    const granularity = null
    const inclusivity = '[]'
    return (
      startsAt.isBetween(startDate, startPlusOneDayDate, granularity, inclusivity) ||
      endsAt.isBetween(startDate, startPlusOneDayDate, granularity, inclusivity)
    )
  })
}

export { readCalendarDayDataParams, staffEventFiltered }
