import { PureComponent } from 'react'
import Mismatch from './Mismatch'
import { OpenShiftEntity } from 'entityWrappers'
import { chunk, getStaffStrategyProperties, mockShiftDays } from 'utils'
import { Map } from 'immutable'
import { isNil } from 'lodash'

export default class Header extends PureComponent {
  componentWillUnmount() {
    const { hideOpenShiftPopup, openShiftPopupProps, shift } = this.props
    const { roleIndex: openShiftRoleIndex, shiftIndex: openShiftShiftIndex } = openShiftPopupProps || {}
    const shiftIndex = shift.get('shiftIndex')
    const roleIndex = shift.get('roleIndex')

    if (
      openShiftShiftIndex &&
      openShiftRoleIndex &&
      shiftIndex === openShiftShiftIndex &&
      roleIndex === openShiftRoleIndex
    ) {
      hideOpenShiftPopup()
    }
  }

  _getExtendedShiftDays(days, shiftDays) {
    if (!days) {
      return shiftDays
    }

    const extendedDays = mockShiftDays(days)
    return extendedDays.concat(shiftDays)
  }

  render() {
    const { shift, activeDateRange, timeService, showScheduledNumbers, mode, filters = Map() } = this.props
    const showMergedShift = filters.get('showMergedShift')

    let shiftDays = shift?.get('shiftDays')
    let leftOffset = 0

    if (mode === 'full') {
      const dateRangeStartsAt = activeDateRange?.get('startsAt')
      leftOffset = dateRangeStartsAt ? timeService.timeMoment(dateRangeStartsAt).day() : 0
      shiftDays = this._getExtendedShiftDays(leftOffset, shiftDays)
    } else if (mode === 'week') {
      shiftDays = this._getShiftDaysForWeekView(activeDateRange, shiftDays, timeService)
    }

    const shiftDaysByWeeks = chunk(shiftDays, 7)
    const weeksCount = shiftDaysByWeeks.size

    const width = 100 / weeksCount
    const style = { width: `${width}%` }
    const state = activeDateRange.get('state')
    const showMismatches = !showScheduledNumbers && state !== 'inactive'

    return (
      <section className="col-10">
        <div className="row text-center bl4">
          {shiftDaysByWeeks.map((shiftDays, weekIndex) => (
            <div key={weekIndex} style={style} className="br4">
              <div className="row-7">
                {showMismatches &&
                  shiftDays.map(
                    showMergedShift ? this.renderMergedMismatch() : this.renderMismatch(shift, weekIndex, leftOffset)
                  )}
                {showScheduledNumbers && shiftDays.map(this.renderScheduledNumber)}
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }

  renderScheduledNumber = (shiftDay, dayIndex) => {
    if (shiftDay.get('isMock')) {
      return <span className="col-1 _headerCell _scheduledNumber border-mystic"></span>
    }
    const staffScheduledNumber = this._getShiftDayScheduledNumber(shiftDay)

    return (
      <div key={dayIndex} className="col-1 _headerCell _scheduledNumber border-mystic">
        <span className="label bold rounded">{staffScheduledNumber}</span>
      </div>
    )
  }

  _getShiftDaysForWeekView(activeDateRange, shiftDays, timeService) {
    if (!activeDateRange.get('isReady')) {
      return Map()
    }

    const weekStartsAtMoment = timeService.timeMoment(activeDateRange.get('weekStartsAt'))
    const weekEndsAtMoment = timeService.timeMoment(activeDateRange.get('weekEndsAt'))
    const shiftDaysForWeek = shiftDays?.filter((shiftDay) => {
      if (!shiftDay.get('startsAt')) {
        return false
      }
      const startsAtMoment = timeService.timeMoment(shiftDay.get('startsAt'))
      return startsAtMoment.isBetween(weekStartsAtMoment, weekEndsAtMoment)
    })

    const firstShiftDay = shiftDaysForWeek?.get(0)?.get('startsAt')
    const leftOffsetForWeek = firstShiftDay ? timeService.timeMoment(firstShiftDay).day() : 0

    return this._getExtendedShiftDays(leftOffsetForWeek, shiftDaysForWeek)
  }

  _getShiftDayScheduledNumber(shiftDay) {
    const staffRequirementCustom = shiftDay.get('staffRequirementCustom')
    let staffRequirement = shiftDay.get('staffRequirement')

    if (!isNil(staffRequirementCustom)) {
      staffRequirement = staffRequirementCustom
    }
    staffRequirement = staffRequirement || 0

    const staffMismatch = shiftDay.get('staffMismatch') || 0
    return staffRequirement - staffMismatch
  }

  _checkStaffMinutesMismatch(staffMismatch, staffMinutesMismatch) {
    return staffMismatch === 0 && staffMinutesMismatch > 0
  }

  renderMismatch(shift, weekIndex, leftOffset) {
    const { showOpenShiftPopup, hideOpenShiftPopup, filters, calendarType, mode, timeService, activeDateRange } =
      this.props
    const strategy = filters.getIn(['displayOptions', 'coverage', 'viewCoverageAs'])

    return (shiftDay, dayIndex) => {
      if (shiftDay.get('isMock')) {
        return <div className={'col-1 _headerCell _staffMismatch border-mystic'}></div>
      }

      const shiftId = shift.get('id')
      const index = weekIndex * 7 + dayIndex
      const shiftDayId = shiftDay.get('id')
      const roleId = shift.get('unitRoleId')
      const openShiftKey = OpenShiftEntity.buildKey(shiftId, shiftDayId)

      const staffRequirementCustom = shiftDay.get('staffRequirementCustom')
      let staffRequirement = shiftDay.get('staffRequirement')

      if (!isNil(staffRequirementCustom)) {
        staffRequirement = staffRequirementCustom
      }
      staffRequirement = staffRequirement || 0

      const staffMismatch = shiftDay.get('staffMismatch') || 0
      const staffMinutesMismatch = shiftDay.get('staffMinutesMismatch') || 0
      const staffScheduled = staffRequirement - staffMismatch

      const targetCover = staffScheduled + staffMismatch
      const isStaffMinutesMismatch = this._checkStaffMinutesMismatch(staffMismatch, staffMinutesMismatch)

      const { count, className } = getStaffStrategyProperties(
        strategy,
        staffScheduled,
        targetCover,
        isStaffMinutesMismatch
      )

      const mismatchParams = {
        roleId,
        shiftId,
        count: Math.abs(count),
        dayIndex: index
      }

      const isWeekMode = mode === 'week'
      let modifiedDayIndex = dayIndex

      if (isWeekMode) {
        const currentDate = timeService?.timeMoment(shiftDay?.get('startsAt'))
        const scheduleStartDate = timeService?.timeMoment(activeDateRange?.get('startsAt'))
        modifiedDayIndex = currentDate.diff(scheduleStartDate, 'days')
      }

      let popupIndex = modifiedDayIndex + weekIndex * 7
      if (!isWeekMode) {
        popupIndex = modifiedDayIndex + weekIndex * 7 - leftOffset
      }

      const eventHandlers =
        calendarType === 'calendar'
          ? {
              onClick: (event) => showOpenShiftPopup(event, popupIndex),
              onMouseLeave: () => hideOpenShiftPopup()
            }
          : {}

      return (
        <div
          key={modifiedDayIndex}
          className={`col-1 _headerCell _staffMismatch border-mystic ${className} ${
            mode === 'week' ? 'weekStaffMismatch' : ''
          }`}
          data-open-shift-key={openShiftKey}
          {...eventHandlers}
        >
          <Mismatch {...mismatchParams} />
        </div>
      )
    }
  }

  renderMergedMismatch() {
    const { filters, mode } = this.props
    const strategy = filters.getIn(['displayOptions', 'coverage', 'viewCoverageAs'])

    return (shiftDay, dayIndex) => {
      if (shiftDay.get('isMock')) {
        return <div className={'col-1 _headerCell _staffMismatch border-mystic'}></div>
      }

      const staffRequirementCustom = shiftDay.get('staffRequirementCustom')
      let staffRequirement = shiftDay.get('staffRequirement')

      if (!isNil(staffRequirementCustom)) {
        staffRequirement = staffRequirementCustom
      }
      staffRequirement = staffRequirement || 0

      const staffMismatch = shiftDay.get('staffMismatch') || 0
      const staffScheduled = staffRequirement - staffMismatch

      const targetCover = staffScheduled + staffMismatch
      const { count, className } = getStaffStrategyProperties(strategy, staffScheduled, targetCover)

      //! We are only showing the merge shift data and we are not allowing user to create open shift for merge shift
      const mismatchParams = {
        count: Math.abs(count)
      }

      return (
        <div
          key={dayIndex}
          className={`col-1 _headerCell _staffMismatch border-mystic ${className} ${
            mode === 'week' ? 'weekStaffMismatch' : ''
          }`}
        >
          <Mismatch {...mismatchParams} />
        </div>
      )
    }
  }
}
