import { getStaffOnTimeOffStyle } from 'Staff/Calendar/utils'
import CalendarItemBar from './CalendarItemBar'

export default function StaffOnTimeOff({ approvedTimeOffRequestsCounter }: any) {
  const { color: barColor, icon } = getStaffOnTimeOffStyle()

  const barTitle = `${approvedTimeOffRequestsCounter} Staff on Time Off`
  if (approvedTimeOffRequestsCounter > 0) {
    return <CalendarItemBar className={'staff-on-time-off'} barColor={barColor} icon={icon} barTitle={barTitle} />
  } else {
    return null
  }
}
