import { Component } from 'react'
import { findDOMNode } from 'react-dom'
import ShiftSwapRow from './ShiftSwapRow'
import stores from 'stores'

const { shiftSwapsStore } = stores

export default class WeekShiftSwaps extends Component {
  state = { isExpanded: true }

  onClickButton = (e, type, id) => {
    const elem = findDOMNode(e.target)
    const bounding = elem.getBoundingClientRect()
    const position = {
      top: bounding.top,
      left: bounding.left
    }

    shiftSwapsStore.setShiftSwapDialog({ type, position, id })
  }

  render() {
    const { weekInfo } = this.props
    const isThisWeek = weekInfo.get('isThisWeek')
    const isNextWeek = weekInfo.get('isNextWeek')
    const { isExpanded } = this.state
    const toggle = () => this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }))
    const shiftSwaps = weekInfo.get('shiftSwaps')
    if (shiftSwaps.size === 0) {
      return null
    }

    return (
      <>
        <tbody className="week">
          <tr className="hx-table-week-range">
            <td colSpan="6">
              {weekInfo.get('weekRange')}

              <span className="friendly-text">
                {isThisWeek && 'This Week'}
                {isNextWeek && 'Next Week'}
              </span>

              <button onClick={toggle} className="float-right">
                {isExpanded && <i className="icon-up float-right" />}
                {!isExpanded && <i className="icon-down float-right" />}
              </button>
            </td>
          </tr>
        </tbody>

        <tbody>
          {isExpanded &&
            shiftSwaps.map((shiftSwap, key) => (
              <ShiftSwapRow shiftSwap={shiftSwap.toJS()} onClickButton={this.onClickButton} key={key} />
            ))}
        </tbody>
      </>
    )
  }
}
