import './Dashboard.scss'
import { Link, withRouter } from 'react-router-dom'
import history from 'browserHistory'
import { navigate } from 'Navigation'
import classNames from 'classnames'
import { withAppContext } from 'AppContext'
import * as dashboardTabs from './DashboardTabs'
import * as dashboardComponents from './DashboardComponents'

function Header(props) {
  const { match } = props

  const activeModule = 'Dashboard'
  const activeComponent = getActiveComponent(match, history.location)
  const todoPath = navigate.from[activeModule].to.ToDo({ mode: 'full' }, false)
  // TODO : Uncomment during the implementation of SkillsCheck Tabs
  const laborProfilePath = navigate.from[activeModule].to.LaborProfile({ mode: 'full' }, false)
  // const skillsCheckPath = navigate.from[activeModule].to.SkillsCheck({ mode: 'full' }, false);

  const toDoClassNames = classNames({
    'hx-dashboard-navigation-item': true,
    active: activeComponent === dashboardComponents.ToDo
  })

  // TODO : Uncomment during the implementation of SkillsCheck Tabs
  // const skillCheckClassNames = classNames({
  //   'hx-dashboard-navigation-item': true,
  //   active: activeComponent === dashboardComponents.SkillsCheck
  // });
  const LaborProfileClassNames = classNames({
    'hx-dashboard-navigation-item': true,
    active: activeComponent === dashboardComponents.LaborProfile
  })

  return (
    <header className="hx-dashboard-header-navigation">
      <nav className="align-middle _dashboard-header-items row">
        <div className={toDoClassNames}>
          <Link to={todoPath} className="align-middle row">
            {dashboardTabs.ToDo}
          </Link>
        </div>
        {/*  TODO : Uncomment during the implementation of SkillsCheck Tabs  */}
        <div className={LaborProfileClassNames}>
          <Link to={laborProfilePath} className="align-middle row">
            {dashboardTabs.LaborProfile}
          </Link>
        </div>
        {/* <div className={skillCheckClassNames}>
          <Link to={skillsCheckPath} className="align-middle row">
            {dashboardTabs.SkillsCheck}
          </Link>
        </div> */}
      </nav>
    </header>
  )
}

export default withAppContext(withRouter(Header))

function getActiveComponent(match, location) {
  if (!match) {
    return null
  }
  const { pathname } = location

  const isToDoComponent = /\/todo/.test(pathname)
  if (isToDoComponent) {
    return dashboardComponents.ToDo
  }

  const isLaborProfileComponent = /\/laborprofile/.test(pathname)
  if (isLaborProfileComponent) {
    return dashboardComponents.LaborProfile
  }

  const isSkillsCheckComponent = /\/skillscheck/.test(pathname)
  if (isSkillsCheckComponent) {
    return dashboardComponents.SkillsCheck
  }
}
