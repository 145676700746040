import { Component } from 'react'
import classNames from 'classnames'
import ImagePreviewComponent from './ImagePreviewComponent'
import TextPreviewComponent from './TextPreviewComponent'
import LinkPreviewComponent from './LinkPreviewComponent'
import SubjectPreviewComponent from './SubjectPreviewComponent'
import PlaceholderInstructionBox from './PlaceholderInstructionBox'
import DeleteDetailsPageButton from './DeleteDetailsPageButton'
import './ExtendedEditPreview.scss'

const PreviewComponents = {
  image: ImagePreviewComponent,
  text: TextPreviewComponent,
  link: LinkPreviewComponent,
  PlaceholderInstructionBox
}

export default class ExtendedEditPreview extends Component {
  state = {
    isHoveringAddDetailsButton: false,
    isHoveringDeleteDetailsButton: false,
    isHoveringDetailsPage: false
  }

  onMouseEnter = (element) => {
    this.setState({ [element]: true })
  }

  onMouseLeave = (element) => {
    this.setState({ [element]: false })
  }

  onClick = () => {
    this.props.onAddDetailsPage()
    this.setState({ isHoveringAddDetailsButton: false })
  }

  render() {
    const { activeComponent, detailsComponents, hasDetailsPage, isFormEditable } = this.props

    const {
      onDeleteDetailsPage,
      insertDetailsComponent,
      updateDetailsComponent,
      onDetailsComponentClicked,
      deleteDetailsComponent
    } = this.props

    const extendedEditPreviewStyle = {
      position: 'relative'
      // paddingLeft: '300px',
      // paddingRight: '300px',
      // paddingTop: '50px'
    }

    const extendedPreviewContentStyle = {
      height: 'auto',
      position: 'relative',
      backgroundColor: '#ffffff',
      zIndex: '1',
      opacity: this.props.hasDetailsPage ? 1 : 0.4
    }

    const isDeleteVisible = detailsComponents.length > 1

    return (
      <div
        className="extended-edit-preview"
        style={extendedEditPreviewStyle}
        onMouseEnter={() => this.onMouseEnter('isHoveringDetailsPage')}
        onMouseLeave={() => this.onMouseLeave('isHoveringDetailsPage')}
      >
        {/* Add or Delete details page buttons */}
        <div>
          {!hasDetailsPage && isFormEditable ? (
            <button
              onClick={this.onClick}
              onMouseEnter={() => this.onMouseEnter('isHoveringAddDetailsButton')}
              onMouseLeave={() => this.onMouseLeave('isHoveringAddDetailsButton')}
              className="add-details-page-button icon-plus"
            />
          ) : (
            this.state.isHoveringDetailsPage &&
            isFormEditable && (
              <DeleteDetailsPageButton
                onDeleteDetailsPage={onDeleteDetailsPage}
                onMouseEnter={() => this.onMouseEnter('isHoveringDeleteDetailsButton')}
                onMouseLeave={() => this.onMouseLeave('isHoveringDeleteDetailsButton')}
              />
            )
          )}
          {!hasDetailsPage
            ? this.state.isHoveringAddDetailsButton &&
              isFormEditable && <p className="add-details-page-hover"> Add Details Page </p>
            : this.state.isHoveringDeleteDetailsButton &&
              isFormEditable && <p className="delete-details-page-hover"> Delete Details Page </p>}
        </div>

        <div
          className={classNames('extended-preview-content', { uneditable: !isFormEditable })}
          style={extendedPreviewContentStyle}
        >
          {/* Subject div */}
          <div className="component-wrapper">
            {hasDetailsPage ? (
              <SubjectPreviewComponent insertDetailsComponentBelow={() => insertDetailsComponent(1)} {...this.props} />
            ) : (
              <div className="extended-preview-subject">(Subject)</div>
            )}
          </div>

          {/* Rendering of components */}
          {detailsComponents.map((component, index) => {
            const Component = PreviewComponents[component.type]
            const content = component.content
            return (
              <div key={component.id} className="component-wrapper">
                <Component
                  isDeleteVisible={isDeleteVisible}
                  activeComponent={activeComponent}
                  detailsComponents={detailsComponents}
                  insertDetailsComponentAbove={() => insertDetailsComponent(index)}
                  insertDetailsComponentBelow={() => insertDetailsComponent(index + 1)}
                  updateDetailsComponent={(component) => updateDetailsComponent(component)}
                  hasDetailsPage={hasDetailsPage}
                  onDetailsComponentClicked={() => {
                    if (isFormEditable) {
                      onDetailsComponentClicked(component)
                    }
                  }}
                  deleteDetailsComponent={() => deleteDetailsComponent(index)}
                  isFormEditable={isFormEditable}
                  {...content}
                  component={component}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
