import { fromJS, Map } from 'immutable'
import { groupBy } from 'lodash'
import { keyBy } from 'utils'
import DateRangeService from 'services/DateRangeService'
import FacilityData from '../FacilityData'
import ActiveDateRange from '../ActiveDateRange'
import { FacilityUserShiftsService } from 'services'
import SchedulesMetadata from '../SchedulesMetadata'
import { generalDataQuery, schedulesQuery } from '../Queries'
import { createSchedulesMutation, updateScheduleMutation } from '../Mutations'

function GeneralData() {
  let timeService = null
  let gqlClient = null
  let getGeneralData = null
  let updateGeneralData = null
  let resetGeneralData = null
  let loadManagers = null
  let loadUnitsShifts = null
  let extendActiveDateRange = null
  let loadSchedulesMetadata = null
  const actions = {
    loadGeneralData,
    createSchedules,
    updateScheduleState,
    loadSchedulesMetadata
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ facilityTime: timeService, gqlClient, resetGeneralData, updateGeneralData, getGeneralData } = context)
    ;({ extendActiveDateRange } = ActiveDateRange)
    ;({ loadManagers, loadUnitsShifts } = FacilityData.actions)
    ;({ loadSchedulesMetadata } = SchedulesMetadata.actions)
  }

  async function loadGeneralData(unitUrlId, usDate, shouldPreloadData = false) {
    usDate = usDate || timeService.timeMoment(null).format('MM-DD-YYYY')
    resetGeneralData()

    let { eligibleUnits, unit } = await _fetchGeneralData({ unitUrlId, usDate })
    const unitShifts = unit.shifts.filter((shift) => !shift.isClass)
    unit = { ...unit, shifts: unitShifts }

    if (shouldPreloadData) {
      await buildPreloadData(unit)
    }

    const unitExtended = extendUnit(unit)
    const { facilityUsers } = unit
    const activeDateRange = _getActiveDateRange(unitExtended, facilityUsers, timeService, usDate)

    const shifts = unitExtended.get('shifts')
    const shiftsById = keyBy(shifts, 'id')

    const generalData = Map({
      shiftsById,
      activeDateRange,
      unit: unitExtended,
      eligibleUnits: fromJS(eligibleUnits)
    })

    setTimeout(() => {
      const scheduleId = activeDateRange.get('scheduleId')

      loadManagers()
      loadUnitsShifts()
      loadUnitSchedules(unitExtended.get('id'))
      if (scheduleId) {
        loadSchedulesMetadata([scheduleId])
      }
    }, 500)

    return updateGeneralData((state) => state.merge(generalData))
  }

  function _fetchGeneralData({ unitUrlId, usDate }) {
    const date = timeService.usDateToMoment(usDate).toISOString()
    const parameters = { unitUrlId, date }

    return gqlClient.query(generalDataQuery, parameters)
  }

  function _getActiveDateRange(unit, facilityUsers, timeService, date) {
    const dateRangeService = new DateRangeService(unit, timeService, date)
    const activeDateRange = dateRangeService.getActiveDateRange()

    return extendActiveDateRange(activeDateRange, facilityUsers)
  }

  function buildPreloadData(unit) {
    const { id: unitId, roles: unitRoles, shifts, facilityUsers, schedule } = unit
    const { startsAt: startDate, endsAt: endDate } = schedule
    const facilityUserIdsByShiftId = FacilityUserShiftsService.userIdsByShiftId(
      startDate,
      endDate,
      unitId,
      facilityUsers,
      shifts,
      timeService
    )

    const shiftsByRoleId = groupBy(shifts, 'unitRoleId')
    const roles = unitRoles.map((role) => ({
      ...role,
      shifts: shiftsByRoleId[role.id] || []
    }))

    const preloadData = fromJS({
      unitId,
      roles,
      endDate,
      startDate,
      facilityUserIdsByShiftId
    })

    return updateGeneralData((state) => state.set('preloadData', preloadData))
  }

  async function loadUnitSchedules(unitId) {
    const { schedules } = await gqlClient.query(schedulesQuery, {
      unitId,
      limit: 999
    })

    return updateGeneralData((state) =>
      state.setIn(['unit', 'schedules'], fromJS(schedules.reverse())).setIn(['unit', 'isSchedulesLoaded'], true)
    )
  }

  async function createSchedules(unitId, schedulesSizesInWeeks) {
    const parameters = {
      unitId,
      schedule: { schedulesSizesInWeeks },
      createEligibleStaffNotifications: true,
      state: 'open'
    }

    const unit = getGeneralData().get('unit')
    const prevSchedule = unit.get('schedule')
    const schedules = unit.get('schedules')

    let { createSchedules: newSchedules } = await gqlClient.mutate(createSchedulesMutation, parameters)

    const todayMoment = timeService.timeMoment(null)
    newSchedules.forEach((schedule, index) => {
      const { state, endsAt } = schedule
      newSchedules[index].state = todayMoment.isAfter(endsAt) ? 'inactive' : state
    })

    newSchedules = fromJS(newSchedules)
    newSchedules = schedules.concat(newSchedules)

    const startsAt = prevSchedule.get('startsAt')
    const endsAt = prevSchedule.get('endsAt')
    const schedule = getScheduleForDateRange(newSchedules, startsAt, endsAt)

    const scheduleId = newSchedules.last().get('id')
    loadSchedulesMetadata([scheduleId])

    await updateGeneralData((state) =>
      state.setIn(['unit', 'schedules'], newSchedules).setIn(['unit', 'schedule'], schedule)
    )

    return refreshActiveDateRange()
  }

  async function updateScheduleState(scheduleId, scheduleState) {
    const parameters = {
      id: scheduleId,
      createEligibleStaffNotifications: true,
      schedule: { state: scheduleState }
    }
    const { updateSchedule: newSchedule } = await gqlClient.mutate(updateScheduleMutation, parameters)

    const schedules = getGeneralData().getIn(['unit', 'schedules'])
    const unitSchedule = getGeneralData().getIn(['unit', 'schedule'])

    const schedulesUpdated = schedules.map((schedule) => {
      if (schedule.get('id') === scheduleId) {
        return fromJS(newSchedule)
      }

      return schedule
    })

    const newUnitSchedule = unitSchedule.get('id') === scheduleId ? fromJS(newSchedule) : unitSchedule

    await updateGeneralData((state) =>
      state.setIn(['unit', 'schedules'], schedulesUpdated).setIn(['unit', 'schedule'], newUnitSchedule)
    )

    return refreshActiveDateRange()
  }

  function extendUnit(unit) {
    return fromJS({
      ...unit,
      isReady: true,
      schedules: [],
      schedulesMetadataMap: {}
    })
  }

  function refreshActiveDateRange() {
    const generalData = getGeneralData()
    const unit = generalData.get('unit')
    const currentDateRange = generalData.get('activeDateRange')

    const date = currentDateRange.get('usDate')

    const facilityUsers = unit.get('facilityUsers').toJS()

    const dateRange = new DateRangeService(unit, timeService, date).getDateRange()

    const dateRangeExtended = extendActiveDateRange(dateRange, facilityUsers)

    return updateGeneralData((state) => state.set('activeDateRange', dateRangeExtended))
  }

  function getScheduleForDateRange(schedules, startsAt, endsAt) {
    return schedules.find((schedule) => {
      const scheduleStartsAt = schedule.get('startsAt')
      const scheduleEndsAt = schedule.get('endsAt')

      return scheduleStartsAt === startsAt && scheduleEndsAt === endsAt
    })
  }
}

export default GeneralData()
