import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { addWarningBanner } from '@humanics/he-react-common/lib/stores/bannersStore'

const fields = [
  Field('userId', 'user id'),
  Field('userProfileFullname', 'name'),
  Field('email', 'email', { format: 'profileEmail' }),
  Field('homeUnitName', 'home unit'),
  Field('updatedAt', 'updated', { format: 'date-time' }),
  Field('hasPermissions', 'has Permissions'),
  Field('status', 'status'),
  Field('invitation', 'invitation')
]

function FacilityUsersStore() {
  const store = AdminStore({
    fields,
    entityName: 'FacilityUserInput',
    api: {
      index: 'indexFacilityUsers',
      update: 'updateFacilityUser'
    },
    header: {
      title: 'Users',
      createLabel: 'Create User',
      updateLabel: 'Update User'
    },
    search: {
      field: 'profile.firstName, profile.lastName, profile.email',
      placeholder: 'Search for facility users'
    },
    modifyData: (item) => ({
      userProfileFullname: `${item.profile.firstName} ${item.profile.lastName}`
    })
  })

  function loadFacilityUsers(parameters) {
    return store.loadDataAction({ ...parameters, limit: 999 })
  }

  async function inviteFacilityUser({ parameters }) {
    const api = await store.getApi()
    return api
      .inviteFacilityUser({ parameters })
      .then(() => store.loadDataAction())
      .catch((e) => {
        addWarningBanner({ message: e.body.message })
      })
  }

  async function revokeFacilityUserInvitations({ userId }) {
    const api = await store.getApi()

    return api.revokeFacilityUserInvitations({ userId }).then(() => store.loadDataAction())
  }

  async function revokePermissions({ userId }) {
    const api = await store.getApi()

    return api
      .revokeFacilityUserPermissions({ userId })
      .then(() => store.loadDataAction())
      .catch((e) => {
        addWarningBanner({ message: e.body.message })
      })
  }

  const options = { reloadDataParameters: { includeAssignments: true } }

  return {
    ...store,
    create: (...args) => store.create(...args, options),
    update: (...args) => store.update(...args, options),
    loadFacilityUsers,
    inviteFacilityUser,
    revokeFacilityUserInvitations,
    revokePermissions
  }
}

export default FacilityUsersStore
