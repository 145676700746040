import { Map } from 'immutable'
import MultiSelectFilter from '../MultiSelectFilter'
import GroupedMultiSelectFilter from '../MultiSelectFilter/GroupedMultiSelectFilter'

const getDefinition = (options) => {
  return {
    minWidth: 100,
    options,
    deselectAllAllowed: true
  }
}

export default function GenericSelector(props) {
  const { options, values, setConfig, fieldName, field, grouped, groupByField, deselectAllAllowed, ...rest } = props
  if (grouped) {
    const mappedOptions = options.reduce((memo, option) => {
      const title = option.get('name')
      const id = option.get('id')
      const item = memo.get(option.get(groupByField), Map())
      return memo.set(option.get(groupByField), item.set(id, title))
    }, Map())

    const multiSelectFilterProps = {
      ...getDefinition(mappedOptions),
      setConfig,
      values,
      name: fieldName,
      field,
      deselectAllAllowed,
      ...rest
    }

    return <GroupedMultiSelectFilter {...multiSelectFilterProps} />
  } else {
    const mappedOptions = options.reduce((memo, option) => {
      const title = option.get('name')
      const id = option.get('id')
      return memo.set(id, title)
    }, Map())

    const multiSelectFilterProps = {
      ...getDefinition(mappedOptions),
      setConfig,
      values,
      name: fieldName,
      field,
      deselectAllAllowed: false,
      ...rest
    }

    return <MultiSelectFilter {...multiSelectFilterProps} />
  }
}
