import { Component } from 'react'
import { Modal } from 'Common/components'
import { navigate } from 'Navigation'
import { Redirect } from 'react-router'
import { Map } from 'immutable'
import { withAppContext } from 'AppContext'
import CustomDateInput from '@humanics/he-react-common/lib/components/Form/CustomDateInput'
import CustomDropdownEnum from 'components/Form/CustomDropdownEnum'
import { Staff } from 'entityWrappers'
import { curry } from 'i18n'
import FormActionBar from '../CreateEditExpertiseModals/FormActionBar'
import './StaffTransferAssignmentModal.scss'

const t = curry('staff.')

const startsAtDefinition = {
  definition: {
    type: 'date',
    dependsOn: false,
    withLabel: true,
    label: 'Start Date',
    flatpickrOptions: {
      static: true
    }
  },
  dateFormatHint: 'YYYY-MM-DD',
  field: 'startsAt'
}

const roleDefinition = {
  definition: {
    type: 'string',
    withLabel: true,
    label: 'Role'
  },
  field: 'groupId'
}

const shiftDefinition = {
  definition: {
    type: 'string',
    withLabel: true,
    label: 'Shift'
  },
  field: 'shifType',
  formItemClasses: 'pr30'
}

class StaffTransferAssignmentModal extends Component {
  constructor(props) {
    super(props)
    this.state = { eligibleUnit: Map(), isProcessing: false, minStartsAt: null }
  }

  hideModal = () => {
    navigate.from.StaffUnitAssignment.to.StaffDetails()
  }

  async componentDidMount() {
    const { match, staffStore, appState } = this.props
    const { userId } = match.params
    const staff = appState.getIn(['staff', 'facilityUser'])
    const isStaffLoaded = staff.size > 0
    const isStaffRelevant = userId === staff.get('userId')

    if (!isStaffLoaded || !isStaffRelevant) {
      await staffStore.resetStaff()
      staffStore.loadStaff(userId)
    }

    if (isStaffLoaded) {
      this._initializeEligibleUnit(staff)
    }
  }

  _initializeEligibleUnit(staff) {
    const { timeService } = this.props
    const eligibleUnit = staff.getIn(['staffProfile', 'eligibleUnits']).last()

    if (!eligibleUnit) {
      return
    }

    const homeUnitId = eligibleUnit.get('homeUnitId')
    const startsAtMoment = timeService.timeMoment(eligibleUnit.get('startsAt'))
    const minStartsAt = startsAtMoment.clone().add(1, 'day').toISOString()

    const today = timeService.timeMoment(null)
    const isTodayAfterMinStartsAt = today.isAfter(minStartsAt)
    const startsAt = isTodayAfterMinStartsAt ? today.toISOString() : startsAtMoment.clone().add(7, 'days').toISOString()

    this.setState({ eligibleUnit: Map({ homeUnitId, startsAt }), minStartsAt })
  }

  componentDidUpdate(prevProps) {
    const { appState } = this.props
    const staff = appState.getIn(['staff', 'facilityUser'])
    const prevStaff = prevProps.appState.getIn(['staff', 'facilityUser'])
    const isStaffJustLoaded = staff.size > 0 && prevStaff.size === 0

    if (isStaffJustLoaded) {
      this._initializeEligibleUnit(staff)
    }
  }

  render() {
    const { appState, timeService } = this.props
    const { eligibleUnit, isProcessing, minStartsAt } = this.state

    const staff = appState.getIn(['staff', 'facilityUser'])
    const isStaffLoaded = staff.size > 0

    if (!isStaffLoaded) {
      return null
    }

    const hasEligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits']).size > 0
    const { transferringUnit } = new Staff(staff)
    if (!hasEligibleUnits || transferringUnit) {
      const path = navigate.from.StaffUnitAssignment.to.StaffDetails({}, false)
      return <Redirect to={path} />
    }

    const homeUnitId = eligibleUnit.get('homeUnitId')
    const units = appState.getIn(['staff', 'units'])
    const unit = units.find((unit) => unit.get('id') === homeUnitId)
    const isUnitReady = !!unit
    if (!isUnitReady) {
      return null
    }

    const modalProps = {
      visible: true,
      onClose: this.hideModal,
      width: 588,
      title: 'Primary Department History',
      modalBoxClasses: 'hx-transfer-modal hx-transfer-unit-assignment-modal'
    }

    roleDefinition.definition.items = {
      enum: unit.get('roles')
    }
    shiftDefinition.definition.items = {
      enum: unit.get('shiftTypes')
    }

    const startsAt = eligibleUnit.get('startsAt')
    const homeUnitRoleId = eligibleUnit.get('homeUnitRoleId')
    const homeUnitShiftTypeId = eligibleUnit.get('homeUnitShiftTypeId')
    const isConfirmDisabled = !homeUnitRoleId || !homeUnitShiftTypeId || !startsAt || isProcessing

    return (
      <Modal {...modalProps} modalBoxClasses={'hx-modal-staff-list hx-accept-staff-transfer-modal'}>
        <div className="hx-staff-transfer-data-container row relative">
          <div className="hx-staff-info-data col-12 pl30 pr30">
            <i className="icon-indicator-dot" />
            <div className="row">
              <div className="col-12">
                <CustomDropdownEnum
                  {...roleDefinition}
                  propKey={'id'}
                  propValue={'name'}
                  onChange={(e) => this.setRoleId(e.currentTarget.value)}
                  value={homeUnitRoleId}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <CustomDropdownEnum
                  {...shiftDefinition}
                  propKey={'id'}
                  propValue={'title'}
                  onChange={(e) => this.setShiftType(e.currentTarget.value)}
                  value={homeUnitShiftTypeId}
                />
              </div>

              <div className="col-6">
                <CustomDateInput
                  {...startsAtDefinition}
                  minDate={minStartsAt}
                  timeService={timeService}
                  onChange={this.onStartsAtChanged}
                  value={startsAt}
                />
              </div>
            </div>
          </div>
          <footer className="col-12 text-right">
            <FormActionBar
              submitLabel="Confirm"
              isSubmitDisabled={isConfirmDisabled}
              onSubmit={this.onSubmit}
              onCancel={this.hideModal}
            />
          </footer>
        </div>
      </Modal>
    )
  }

  onSubmit = async () => {
    const { staffStore, match, Dialog } = this.props
    const { eligibleUnit } = this.state
    const { userId } = match.params

    const isConfirmed = await Dialog.confirm(t('changeStaffEligibilityConfirmation.text'), {
      type: 'warning',
      title: t('changeStaffEligibilityConfirmation.title'),
      accept: t('changeStaffEligibilityConfirmation.accept')
    })

    if (!isConfirmed) {
      return
    }

    const eligibleUnitsItem = {
      startsAt: eligibleUnit.get('startsAt'),
      homeUnitId: eligibleUnit.get('homeUnitId'),
      homeUnitRoleId: eligibleUnit.get('homeUnitRoleId'),
      homeUnitShiftTypeId: eligibleUnit.get('homeUnitShiftTypeId')
    }

    this.setState({ isProcessing: true })
    await staffStore.appendStaffEligibleUnit(userId, eligibleUnitsItem)
    await staffStore.loadStaff(userId)
    this.setState({ isProcessing: false })

    this.hideModal()
  }

  setRoleId = (roleId) => {
    const eligibleUnit = this.state.eligibleUnit.set('homeUnitRoleId', roleId)
    this.setState({ eligibleUnit })
  }

  setShiftType = (shiftTypeId) => {
    const eligibleUnit = this.state.eligibleUnit.set('homeUnitShiftTypeId', shiftTypeId)
    this.setState({ eligibleUnit })
  }

  onStartsAtChanged = (startsAt) => {
    const eligibleUnit = this.state.eligibleUnit.set('startsAt', startsAt)
    this.setState({ eligibleUnit })
  }
}

export default withAppContext(StaffTransferAssignmentModal)
