import { PureComponent } from 'react'
import classNames from 'classnames'
import { List, Map } from 'immutable'
import { PopUp } from 'Manager/components'
import Note from './Note'
import NotesController from './TimeOffNotesController'

export default class TimeOffDetails extends PureComponent {
  constructor(props) {
    super(props)

    this.notesController = new NotesController(this)

    const { defaultState: notesState } = this.notesController

    this.state = {
      ...notesState,
      isUpdating: false,
      isRemoving: false,
      documentType: 'StaffUnavailability'
    }
  }

  componentDidMount() {
    const notesState = this.notesController.buildNotesState()
    this.setState(notesState)
  }

  componentDidUpdate(prevProps) {
    const { selection, notesMap } = this.props
    const { selection: prevSelection } = prevProps
    const isSelectionChanged = selection !== prevSelection

    if (isSelectionChanged) {
      const selectedCell = this._getSelectedCell()
      const selectedCellStaffEvent = selectedCell?.get('staffEvents')?.get(0) || Map()
      const timeOff = selectedCellStaffEvent.get('timeOff')
      const isPTO = timeOff && timeOff.getIn(['timeOffAttributes', 'paidMinutes']) > 0
      const documentType = isPTO ? 'StaffPaidTimeOff' : 'StaffUnavailability'

      const noteIds = timeOff?.get('noteIds') || List()
      const notes = noteIds.map((noteId) => notesMap.get(noteId)).filter((note) => note)
      const notesState = this.notesController.buildNotesState(notes, documentType)

      this.setState({ ...notesState, documentType })
    }
  }

  _getSelectedCell() {
    const { selection, timeOffCalendar } = this.props
    const staffPath = selection.get('staffPath')
    const selectedCells = selection.get('cells')

    if (selectedCells.size > 0) {
      const cells = selectedCells.map((identityHash, cellIndex) => timeOffCalendar.getCell({ ...staffPath, cellIndex }))
      return cells.last()
    }

    return null
  }

  render() {
    const { isTimeOffDetailsVisible, popupProps, scrollTop, selection } = this.props
    const selectedCells = selection.get('cells')
    if (!isTimeOffDetailsVisible || selectedCells.size === 0) {
      return null
    }

    const { isUpdating, isRemoving, documentType } = this.state
    const isProcessing = isUpdating || isRemoving
    const style = { width: 380 }

    const isPTO = documentType === 'StaffPaidTimeOff'
    const isRequestOff = documentType === 'StaffUnavailability'

    const { notesController } = this
    const { notes } = notesController
    const noteProps = { note: notes.last(), notesController }

    return (
      <PopUp {...popupProps} scrollTop={scrollTop} className="popup hx-cell-details add-timeoff">
        <div style={style}>
          <h5 className="text-center">Update Time Off</h5>

          <div className="text-center smaller regent-gray">
            <div className="updated-at" />
          </div>
          <div className="row p20 pb0">
            <div className="col col-4 form-item text-right pr10 row align-middle">
              <label>
                TYPE <span className="req">*</span>
              </label>
            </div>
            <div className="col col-8 form-item">
              <button
                onClick={() => this.setState({ documentType: 'StaffPaidTimeOff' })}
                className={classNames({
                  'button compact': true,
                  'white bg-dodgerblue _active': isPTO,
                  primary: !isPTO
                })}
              >
                PTO
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                onClick={() => this.setState({ documentType: 'StaffUnavailability' })}
                className={classNames({
                  'button compact': true,
                  'white bg-dodgerblue _active': isRequestOff,
                  primary: !isRequestOff
                })}
              >
                RO
              </button>
            </div>
          </div>

          <Note {...noteProps} />

          <footer className="p20">
            <button className="button compact secondary borderless ph0" onClick={this.props.onCancel}>
              Cancel
            </button>

            <>
              <button
                className={classNames('button float-right compact bg-ufo-green _update-button', {
                  loading: isUpdating
                })}
                onClick={this.update}
                disabled={isProcessing}
              >
                Update Time Off
              </button>

              <span className="float-right">&nbsp;</span>

              <button
                className={classNames('button float-right compact primary _delete-button', {
                  loading: isRemoving
                })}
                onClick={this.delete}
                disabled={isProcessing}
              >
                Remove Time Off
              </button>
            </>
          </footer>
        </div>
      </PopUp>
    )
  }

  delete = async () => {
    const { deleteTimeOffs, onCancel } = this.props

    this.setState({ isRemoving: true })
    await deleteTimeOffs()
    this.setState({ isRemoving: false })

    onCancel()
  }

  update = async () => {
    const { changeTimeOff, onCancel } = this.props
    const { note } = this.notesController
    const eventVariant = this.getEventVariant()

    this.setState({ isUpdating: true })
    await changeTimeOff(eventVariant, note)
    this.setState({ isUpdating: false })

    onCancel()
  }

  getEventVariant() {
    const { documentType } = this.state
    const { timeOffEventVaraints } = this.props

    const isPTO = documentType === 'StaffPaidTimeOff'
    const ptoVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'paidTimeOff')
    const roVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'requestOff')
    return isPTO ? ptoVariant : roVariant
  }
}
