import './Footer.scss'
import { t } from 'i18n'

export interface IFooter {
  dataController: any
  userId: String
  timeService: any
  date: any
  shiftSwapRequest: any
}

export default function Footer({ dataController, userId, timeService, date, shiftSwapRequest }: IFooter) {
  const handleAcceptButtonClick = () => {
    async function updateData() {
      const monthStartsAt = timeService.dateTimeToMoment(date).startOf('month').toISOString()
      const id = shiftSwapRequest.get('id')
      const shiftDaysToSwapFor = shiftSwapRequest.get('shiftDaysToSwapFor')
      const shiftDayId = shiftDaysToSwapFor?.first()
      await dataController.acceptShiftSwapRequest(id, userId, shiftDayId.get('id'), monthStartsAt)
    }
    updateData()
  }

  const handleDeclineButtonClick = () => {
    async function updateData() {
      const monthStartsAt = timeService.dateTimeToMoment(date).startOf('month').toISOString()
      const id = shiftSwapRequest.get('id')
      await dataController.declineShiftSwapRequest(id, userId, monthStartsAt)
    }
    updateData()
  }
  const shouldRenderAcceptAndDeclineButton = () => {
    const state = shiftSwapRequest.get('state')
    return state === 'submitted'
  }

  return (
    <div>
      <div className="pendingFooterWrapper">
        <div className="pendingFooterBox">
          {shouldRenderAcceptAndDeclineButton() && (
            <div className="pendingFooterButtons">
              <div className="declineButton" data-testid="decline-button">
                <sh-button
                  label={`${t('staffCalendar.shiftSwap.actionLabels.declineSwap')}`}
                  color="secondary"
                  size="m"
                  onClick={handleDeclineButtonClick}
                />
              </div>
              <div className="acceptButton" data-testid="accept-button">
                <sh-button
                  label={`${t('staffCalendar.shiftSwap.actionLabels.acceptSwap')}`}
                  color="primary"
                  size="m"
                  onClick={handleAcceptButtonClick}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
