import {
  createStaffRecurringScheduleMutation,
  deleteStaffRecurringScheduleMutation,
  updateStaffRecurringScheduleMutation
} from '../Mutations'

function StaffRecurringSchedules() {
  let gqlClient = null
  let updateRecurringSchedule = null

  const actions = {
    createStaffRecurringSchedule,
    deleteStaffRecurringSchedule,
    updateStaffRecurringSchedule
  }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, updateRecurringSchedule } = context)
  }

  async function createStaffRecurringSchedule(parameters) {
    const {
      userId,
      unitId,
      shiftId,
      shiftStartTime,
      shiftDuration,
      startsAt,
      endsAt,
      recurringType,
      weeklyDayIndexes,
      recurEvery,
      subType,
      monthlyOnThe,
      monthlyOnTheDayWeek
    } = parameters
    const staffRecurringSchedule = {
      userId,
      unitId,
      shiftId,
      shiftStartTime,
      shiftDuration,
      startsAt,
      endsAt,
      recurringType,
      weeklyDayIndexes,
      recurEvery,
      subType,
      monthlyOnThe,
      monthlyOnTheDayWeek
    }

    const { createStaffRecurringSchedule: schedule } = await gqlClient.mutate(createStaffRecurringScheduleMutation, {
      staffRecurringSchedule
    })

    return updateRecurringSchedule(schedule)
  }

  async function deleteStaffRecurringSchedule({ id }) {
    await gqlClient.mutate(deleteStaffRecurringScheduleMutation, { id })

    return updateRecurringSchedule({ id }, true)
  }

  async function updateStaffRecurringSchedule(parameters) {
    const { id, endsAt } = parameters
    const staffRecurringSchedule = {
      endsAt
    }
    const props = { id, staffRecurringSchedule }

    const { updateStaffRecurringSchedule: schedule } = await gqlClient.mutate(
      updateStaffRecurringScheduleMutation,
      props
    )

    return updateRecurringSchedule(schedule)
  }
}

export default StaffRecurringSchedules()
