import { List, Map } from 'immutable'
import memoize from 'memoize-one'
import { TimeOffCalendar } from 'entityWrappers'
import StateController from './StateController'
import { TimeOffFilterService } from 'services'

const DATE_RANGE_HEIGHT = 150
const STAFF_HEIGHT = 49
const SHIFT_HEIGHT = 28
const STAFF_DIVIDER_HEIGHT = 2
const ROLE_HEIGHT = 31
const FOOTER_HEIGHT = 79

export default class ViewModelController extends StateController {
  countDateRangeTimeOffs = (dateRangeIndex) => {
    const dateRange = this.timeOffCalendar.getDateRange(dateRangeIndex)
    return TimeOffCalendar.countDateRangeTimeOffs(dateRange)
  }

  countStaffPTOs = (staffPath) => {
    const staff = this.timeOffCalendar.getStaff(staffPath)
    return TimeOffCalendar.countStaffPTOs(staff)
  }

  getViewModel = () => {
    const { filters } = this.props
    const { dateRangesMap } = this.timeOffCalendar
    return this._buildViewModel(dateRangesMap, filters)
  }

  _buildViewModel = memoize((dateRangesMap, filters) => {
    const raw = dateRangesMap.valueSeq().reduce(this._getDateRangesReducer(filters), List())
    return raw.push(Map({ kind: 'footer' }))
  })

  _getDateRangesReducer = (filters) => (result, dateRange) => {
    const { isDateRangeVisible } = this._getFilters(filters)

    if (!isDateRangeVisible(dateRange)) {
      return result
    }
    result = result.push(dateRange.remove('roles'))

    const roles = dateRange.get('roles')
    return roles.reduce(this._getRolesReducer(filters), result)
  }

  _getRolesReducer = (filters) => (result, role) => {
    const { isRoleVisible } = this._getFilters(filters)

    if (!isRoleVisible(role)) {
      return result
    }
    result = result.push(role.remove('shifts'))

    const shifts = role.get('shifts')
    return shifts.reduce(this._getShiftsReducer(filters), result)
  }

  _getShiftsReducer = (filters) => (result, shift) => {
    const { isShiftVisible } = this._getFilters(filters)

    if (!isShiftVisible(shift)) {
      return result
    }

    const shiftStaff = this._getShiftStaff(shift, filters)
    return result.push(shift.remove('staff')).concat(shiftStaff)
  }

  _getShiftStaff = (shift, staffHours, filters) => {
    const { isStaffVisible } = this._getFilters(filters)

    return shift.get('staff').reduce((memoStaff, staff) => {
      if (!isStaffVisible(staff)) {
        return memoStaff
      }

      return memoStaff.push(staff)
    }, List())
  }

  _getFilters = memoize((filters) => {
    const filterService = new TimeOffFilterService(filters)
    const { isDateRangeVisible, isRoleVisible, isShiftVisible, isStaffVisible } = filterService
    return { isDateRangeVisible, isRoleVisible, isShiftVisible, isStaffVisible }
  })

  getRowHeightByIndex =
    (raw) =>
    ({ index }) => {
      const row = raw.get(index)
      return this._getRowHeight(row)
    }

  _getRowHeight = (row) => {
    if (!row) {
      return 0
    }
    if (row.get('hidden')) {
      return 0
    }

    switch (row.get('kind')) {
      case 'footer':
        return FOOTER_HEIGHT
      case 'date-range':
        return DATE_RANGE_HEIGHT
      case 'staff':
        return STAFF_HEIGHT
      case 'shift':
        return SHIFT_HEIGHT
      case 'staff-divider':
        return STAFF_DIVIDER_HEIGHT
      case 'role':
        return ROLE_HEIGHT
      default:
        return 0
    }
  }
}
