import { Map } from 'immutable'
import { OpenShiftsService } from 'services'

export default function buildOpenShiftsMap(openShifts, config, timeService, roles) {
  const { filter, sortBy, sortOrder } = config
  const openShiftsService = new OpenShiftsService(timeService)
  const openShiftsFiltered = openShiftsService.filterOpenShifts(openShifts, filter)
  const todayOpenShifts = openShiftsFiltered.filter((openShift) => openShift.get('isToday'))
  const tomorrowOpenShifts = openShiftsFiltered.filter((openShift) => openShift.get('isTomorrow'))
  const totalOpenShifts = OpenShiftsService.getTotalOpenShifts(openShiftsFiltered)
  const totalNeedApprovalOpenShifts = OpenShiftsService.getTotalNeedApprovalOpenShifts(openShiftsFiltered)
  const totalTodayOpenShifts = OpenShiftsService.getTotalOpenShifts(todayOpenShifts)
  const totalShiftOpportunities = OpenShiftsService.getTotalShiftOpportunities(openShiftsFiltered)
  const acceptanceRate = openShiftsService.getAcceptanceRate(openShifts)

  const restOpenShifts = openShiftsFiltered.filter(
    (openShift) => !openShift.get('isToday') && !openShift.get('isTomorrow')
  )
  const openShiftsByWeeks = openShiftsService.groupOpenShiftsByWeeks(restOpenShifts)
  let rawRoles = {}
  if (roles.size) {
    roles.forEach((role) => {
      rawRoles[role.get('id')] = role.get('name')
    })
  } else {
    rawRoles = roles
  }
  const rawRoleKeys = Object.keys(rawRoles)
  const roleKeys = filter.get('roles') ? filter.get('roles') : rawRoleKeys

  return Map({
    acceptanceRate,
    totalOpenShifts,
    totalNeedApprovalOpenShifts,
    totalTodayOpenShifts,
    totalShiftOpportunities,
    todayOpenShifts,
    tomorrowOpenShifts,
    restOpenShifts: openShiftsService.sortOpenShifts(openShiftsByWeeks, sortOrder, sortBy),
    rawRoles,
    roleKeys,
    rawRoleKeys
  })
}
