import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isEqual } from 'lodash'

export class CustomDayDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { completeListOfItems: [] }
  }

  static defaultProps = {
    propKey: 'id',
    propValue: 'title',
    filter: (option) => true
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    filter: PropTypes.func
  }

  setData = (enumList, defaultValue) => {
    const { onChange, field, value } = this.props
    if (typeof enumList[0] === 'object') {
      this.setState({ completeListOfItems: enumList })
    }

    if (typeof enumList[0] === 'string') {
      const completeListOfItems = enumList.map((key) => ({
        id: key,
        title: getStringFromCapitalize(key)
      }))
      this.setState({ completeListOfItems })
    }

    if (!value) {
      onChange(defaultValue || '', field)
    } else {
      this.setFormData(value)
    }
  }

  loadListOfItems({ stores, storeName }) {
    const { definition, onChange, field, value } = this.props
    const { loadItemsParameters = {} } = definition
    const enumList = definition.items.enum
    const defaultValue = definition.default

    if (!stores || !storeName) {
      if (enumList) {
        this.setData(enumList, defaultValue)
      }
      return
    }

    const store = stores.get(storeName)
    if (enumList && typeof enumList[0] === 'string') {
      store.updateEnumList(enumList)
    }
    const { stateKey } = store.getAdminInfo()
    store.loadDataAction(loadItemsParameters).then((state) => {
      const completeListOfItems = state.getIn([stateKey, 'list'])
      this.setState({ completeListOfItems })
      if (!value) {
        onChange('', field)
      } else {
        this.setFormData(value)
      }
    })
  }

  componentDidMount() {
    this.loadListOfItems(this.props)
  }

  componentDidUpdate(prevProps) {
    const isChangedStoreName = this.props.storeName !== prevProps.storeName
    const isChangedOptionsList = !isEqual(this.props.definition.items.enum, prevProps.definition.items.enum)

    if (isChangedStoreName || isChangedOptionsList) {
      this.loadListOfItems(this.props)
    } else {
      const { value, dependsOnValue, onChange, field } = this.props
      if (dependsOnValue !== prevProps.dependsOnValue && !value.includes(dependsOnValue)) {
        onChange('', field)
      }
    }
  }

  render() {
    let { value, field, error, propKey, propValue, definition, dependentFields, dependsOnValue, formItemClasses } =
      this.props
    // let { completeListOfItems: options } = this.state;

    let options = definition.items.enum || []

    const {
      required,
      label,
      default: defaultValue,
      dependsOn,
      disabled,
      // customPlaceHolder,
      withLabel
    } = definition

    if (dependsOn && !disabled) {
      options = this.filterOptions(options, dependsOnValue, dependentFields)
    }

    // options = options.filter(this.props.filter);
    const isErrorVisible = !!error
    return (
      <div className={classNames('form-item', formItemClasses)} key={field}>
        {withLabel && (
          <label>
            <span className="form-label">{label}</span>
            &nbsp;
            {required && <span className="req">*</span>}
            {isErrorVisible && <span className="error">{error}</span>}
          </label>
        )}
        {!withLabel && isErrorVisible && <span className="error">{error}</span>}
        <select
          className="w100"
          name={label}
          value={value || defaultValue}
          onChange={this.onChange(field)}
          disabled={options.length === 0 || disabled}
        >
          <option value="" disabled>{`--- Select ${label} ---`}</option>
          {options.map((opt) => (
            <Option data={opt} propValue={propValue} propKey={propKey} key={opt[propKey]} />
          ))}
        </select>
      </div>
    )
  }

  onChange = (field) => {
    return (e) => {
      const { onChange } = this.props
      const selectedValue = e.target.value

      onChange(e, field)
      this.setFormData(selectedValue)
    }
  }

  setFormData(selectedValue) {
    const { setFormData, model, propKey } = this.props

    if (model && selectedValue) {
      const { completeListOfItems: items } = this.state
      const item = items.find((item) => item[propKey] === selectedValue)
      setFormData({ [model]: item })
    }
  }

  filterOptions(options, dependsOnValue, dependentFields) {
    return options.filter((option) =>
      dependentFields.some((dependentField) => {
        if (option[dependentField] instanceof Array) {
          return option[dependentField].includes(dependsOnValue)
        } else {
          return option[dependentField] === dependsOnValue
        }
      })
    )
  }
}

export default CustomDayDropdown

function Option({ data, propValue, propKey }) {
  const text = typeof propValue === 'string' ? data[propValue] : propValue(data)
  const key = data[propKey]

  return <option value={key}>{text}</option>
}

const getStringFromCapitalize = (string) => {
  string = string.replace(/([A-Z])/g, ' $1')
  return string.charAt(0).toUpperCase() + string.slice(1)
}
