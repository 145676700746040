import { Map } from 'immutable'

export interface IStaffIdentifierProps {
  publicStaff: Map<string, any>
}

export default function StaffIdentifier({ publicStaff }: IStaffIdentifierProps) {
  const profile = publicStaff.get('profile')
  const firstName = profile.get('firstName')
  const lastName = profile.get('lastName')
  const avatarUrl = profile.get('avatarUrl')

  return (
    <div className="pt5 pb5" data-testid="staff-identifier-component">
      <sh-user-identifier name={`${firstName} ${lastName}`} image={avatarUrl && `url(${avatarUrl})`} />
    </div>
  )
}
