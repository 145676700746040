import { PureComponent } from 'react'
import Staff from './Staff'
import Row from '../Row'
import OpenShift from './OpenShift'
import './Shift.scss'

export default class Shift extends PureComponent {
  render() {
    const { shift, timeService } = this.props
    const shiftName = shift.get('name')
    const shiftDay = shift.get('shiftDay')
    const staff = shift.get('staff')

    const openShiftProps = { shiftDay, timeService }

    return (
      <section className="_shift hx-calendar-shift-container">
        <div className="hx-calendar-shift bg-cadetblue">
          <header className="row _shift-header">
            <aside className="col-2">
              <span className="upper smaller bold">{shiftName}</span>
            </aside>

            <Row shiftDay={shiftDay} timeService={timeService}>
              <OpenShift {...openShiftProps} />
            </Row>
          </header>
        </div>
        {staff.map(this.renderStaff)}
      </section>
    )
  }

  renderStaff = (staff, index) => {
    const { timeService, shift } = this.props
    const shiftDay = shift.get('shiftDay')
    const props = { staff, shiftDay, timeService }

    return <Staff key={index} {...props} />
  }
}
