import { Component } from 'react'
import Summary from './Summary/index'
import './Profile.scss'

export class Profile extends Component {
  render() {
    const { staff, displayNameAs } = this.props
    const fullName =
      !displayNameAs || displayNameAs === 'firstNameLastName' ? staff.get('fullName') : staff.get('fullNameRevers')

    return (
      <>
        <aside className="_staffMeta _staffProfile col-2">
          <div className="bg-alabaster hx-calendar-staff-info-wrapper">
            <div className="staff-meta" style={{ whiteSpace: 'normal' }}>
              {fullName}
              <Summary facilityUser={staff} />
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default Profile
