import { Component } from 'react'
import { Modal } from 'Common/components'
import { navigate } from 'Navigation'
import { Map } from 'immutable'
import { Redirect } from 'react-router'
import { withAppContext } from 'AppContext'
import CustomDateInput from '@humanics/he-react-common/lib/components/Form/CustomDateInput'
import CustomDropdownEnum from 'components/Form/CustomDropdownEnum'
import { Staff } from 'entityWrappers'
import FormActionBar from '../CreateEditExpertiseModals/FormActionBar'
import './TransferDepartmentModal.scss'

const startsAtDefinition = {
  definition: {
    type: 'date',
    dependsOn: false,
    withLabel: false
  },
  dateFormatHint: 'YYYY-MM-DD',
  field: 'startsAt'
}

const departmentDefinition = {
  definition: {
    required: true,
    type: 'string',
    withLabel: false,
    label: 'an option'
  },
  field: 'homeUnitId'
}

class TransferDepartmentModal extends Component {
  constructor(props) {
    super(props)
    this.state = { eligibleUnit: Map(), isProcessing: false }
  }

  hideModal = () => {
    navigate.from.StaffTransferDepartment.to.StaffDetails()
  }

  async componentDidMount() {
    const { match, staffStore, appState } = this.props
    const { userId } = match.params
    const staff = appState.getIn(['staff', 'facilityUser'])
    const isStaffLoaded = staff.size > 0
    const isStaffRelevant = userId === staff.get('userId')

    if (!isStaffLoaded || !isStaffRelevant) {
      await staffStore.resetStaff()
      staffStore.loadStaff(userId)
    }

    if (isStaffLoaded) {
      this._initializeEligibleUnit(staff)
    }
  }

  _initializeEligibleUnit(staff) {
    const { timeService } = this.props
    const eligibleUnit = staff.getIn(['staffProfile', 'eligibleUnits']).last()
    const today = timeService.timeMoment(null)
    const tomorrow = today.clone().add(1, 'day')
    const tomorrowISO = tomorrow.toISOString()

    if (!eligibleUnit) {
      this.setState({ minStartsAt: tomorrowISO })
      return
    }

    const startsAtMoment = timeService.timeMoment(eligibleUnit.get('startsAt'))
    const minEndsAt = startsAtMoment.clone().add(1, 'day').toISOString()

    const isTomorrowAfterMinEndsAt = today.isAfter(minEndsAt)
    const minStartsAt = isTomorrowAfterMinEndsAt ? tomorrowISO : minEndsAt

    const startsAt = isTomorrowAfterMinEndsAt ? tomorrowISO : startsAtMoment.clone().add(7, 'days').toISOString()

    this.setState({ eligibleUnit: Map({ startsAt }), minStartsAt })
  }

  componentDidUpdate(prevProps) {
    const { appState } = this.props
    const staff = appState.getIn(['staff', 'facilityUser'])
    const prevStaff = prevProps.appState.getIn(['staff', 'facilityUser'])
    const isStaffJustLoaded = staff.size > 0 && prevStaff.size === 0

    if (isStaffJustLoaded) {
      this._initializeEligibleUnit(staff)
    }
  }

  render() {
    const { appState, timeService } = this.props
    const { eligibleUnit, isProcessing, minStartsAt } = this.state

    const unit = appState.getIn(['generalData', 'unit'])
    const isUnitReady = unit.get('isReady')
    const staff = appState.getIn(['staff', 'facilityUser'])
    const isStaffLoaded = staff.size > 0

    if (!isUnitReady || !isStaffLoaded) {
      return null
    }

    const canEditEligibleUnits = new Staff(staff).canEditEligibleUnits(timeService)
    if (!canEditEligibleUnits) {
      const path = navigate.from.StaffTransferDepartment.to.StaffDetails({}, false)
      return <Redirect to={path} />
    }

    const modalProps = {
      visible: true,
      onClose: this.hideModal,
      width: 380,
      title: 'TRANSFER DEPARTMENT',
      modalBoxClasses: 'hx-transfer-modal hx-initiate-staff-transfer-modal'
    }

    const eligibleUnits = staff.getIn(['staffProfile', 'eligibleUnits'])
    const units = appState.getIn(['staff', 'units'])
    const staffLastEligibleUnit = eligibleUnits.last()
    const currentUnitId = staffLastEligibleUnit?.get('homeUnitId')
    departmentDefinition.definition.items = {
      enum: units.filter((unit) => unit.get('id') !== currentUnitId)
    }

    const homeUnitId = eligibleUnit.get('homeUnitId')
    const startsAt = eligibleUnit.get('startsAt')
    const isConfirmDisabled = isProcessing || !homeUnitId || !startsAt

    return (
      <div>
        <Modal {...modalProps}>
          <div className="hx-staff-transfer-data-container p20 pb0">
            <div className="hx-staff-info-data w100">
              <div className="row">
                <div className="col-6 text-right pr10">
                  <label>NEW DEPARTMENT</label>
                </div>
                <div className="col-6">
                  <CustomDropdownEnum
                    {...departmentDefinition}
                    propKey={'id'}
                    propValue={'name'}
                    onChange={(event) => this.onUnitChanged(event.currentTarget.value)}
                    value={homeUnitId}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 text-right pr10">
                  <label>START DATE</label>
                </div>
                <div className="col-6">
                  <CustomDateInput
                    {...startsAtDefinition}
                    minDate={minStartsAt}
                    timeService={timeService}
                    onChange={this.onStartsAtChanged}
                    value={startsAt}
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className="_footer">
            <FormActionBar
              submitLabel="Confirm"
              isSubmitDisabled={isConfirmDisabled}
              onSubmit={this.onSubmit}
              onCancel={this.hideModal}
            />
          </footer>
        </Modal>
      </div>
    )
  }

  onSubmit = async () => {
    const { staffStore, match } = this.props
    const { eligibleUnit } = this.state
    const { userId } = match.params
    const eligibleUnitsItem = {
      startsAt: eligibleUnit.get('startsAt'),
      homeUnitId: eligibleUnit.get('homeUnitId')
    }

    this.setState({ isProcessing: true })
    await staffStore.initiateStaffTransfer(userId, eligibleUnitsItem)
    await staffStore.loadStaff(userId)
    this.setState({ isProcessing: false })

    this.hideModal()
  }

  onUnitChanged = (homeUnitId) => {
    const eligibleUnit = this.state.eligibleUnit.set('homeUnitId', homeUnitId)
    this.setState({ eligibleUnit })
  }

  onStartsAtChanged = (startsAt) => {
    const eligibleUnit = this.state.eligibleUnit.set('startsAt', startsAt)
    this.setState({ eligibleUnit })
  }
}

export default withAppContext(TransferDepartmentModal)
