import { List } from 'immutable'
import { default as PendingShiftSwapRequestHeader } from '../Common/PendingShiftSwapRequest/Header'
import { default as PendingShiftSwapRequestFooter } from '../Common/PendingShiftSwapRequest/Footer'
import { default as MyShiftSwapRequestHeader } from '../Common/MyShiftSwapRequest/Header'
import { default as MyShiftSwapRequestFooter } from '../Common/MyShiftSwapRequest/Footer'
import ShiftSwapDetailsCard from '../Common/ShiftSwapDetailsCard/ShiftSwapDetailsCard'
import './ShiftSwapCard.scss'

export interface IShiftSwapCard {
  date: string
  timeService: any
  staffEvents?: List<Map<string, any>>
  publicStaffMap: Map<string, Map<string, any>>
  shiftsMap: Map<string, Map<string, any>>

  dataController: any
  userId: string
  unitsMap: any
  urlId: any
  shiftSwapRequest: any
  initiated: any
}

export default function ShiftSwapCard({
  dataController,
  userId,
  timeService,
  date,
  unitsMap,
  shiftsMap,
  urlId,
  shiftSwapRequest,
  initiated
}: IShiftSwapCard) {
  const formattedDate = timeService.timeMoment(date).format('MM-DD-YYYY')

  const { todayUsDate } = timeService

  const isPastDate = timeService.timeMoment(formattedDate, 'MM-DD-YYYY').isBefore(todayUsDate, 'day')
  return (
    <>
      {initiated ? (
        <div
          className={`shift-swap-card-box initiated ${isPastDate ? 'past-date' : ''}`}
          key={`${shiftSwapRequest.id}-div`}
          data-testid="initiatedShiftSwapRequests-component"
        >
          <MyShiftSwapRequestHeader
            key={`${shiftSwapRequest.id}-header`}
            timeService={timeService}
            usDate={formattedDate}
            shiftSwapRequest={shiftSwapRequest}
          />
          <ShiftSwapDetailsCard
            key={`${shiftSwapRequest.id}-details`}
            timeService={timeService}
            shiftSwapRequest={shiftSwapRequest}
            unitsMap={unitsMap}
            shiftsMap={shiftsMap}
            initiated
          />
          <MyShiftSwapRequestFooter
            key={`${shiftSwapRequest.id}-footer`}
            dataController={dataController}
            userId={userId}
            timeService={timeService}
            usDate={formattedDate}
            shiftSwapRequest={shiftSwapRequest}
            urlId={urlId}
          />
        </div>
      ) : (
        <div
          className={`shift-swap-card-box ${isPastDate ? 'past-date' : ''}`}
          key={`${shiftSwapRequest.id}-div`}
          data-testid="receivededShiftSwapRequests-component"
        >
          <PendingShiftSwapRequestHeader
            key={`${shiftSwapRequest.id}-header`}
            timeService={timeService}
            shiftSwapRequest={shiftSwapRequest}
          />
          <ShiftSwapDetailsCard
            key={`${shiftSwapRequest.id}-details`}
            timeService={timeService}
            shiftSwapRequest={shiftSwapRequest}
            unitsMap={unitsMap}
            shiftsMap={shiftsMap}
            initiated={false}
          />
          <PendingShiftSwapRequestFooter
            key={`${shiftSwapRequest.id}-footer`}
            dataController={dataController}
            userId={userId}
            timeService={timeService}
            date={formattedDate}
            shiftSwapRequest={shiftSwapRequest}
          />
        </div>
      )}
    </>
  )
}
