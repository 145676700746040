import { fromJS, List } from 'immutable'
import { initialState } from '@humanics/he-react-common/lib/stores/fluxStore'
import * as paths from 'Navigation/paths'
import { generatePath } from 'react-router-dom'
import { Roles } from 'auth'

const InstanceAdministrator = 'instanceAdministrator'
const FacilityAdmin = 'facilityAdministrator'
const UnitManager = 'unitManager'

let urlId

const menu = (title, url, roles = [], items = []) => {
  if (urlId && url) {
    url = generatePath(url, { urlId })
  }
  return {
    title,
    url,
    // module,
    roles,
    items,
    hide: true
  }
}
function generateSitemap() {
  return fromJS([
    // menu('Dashboard', '/dashboard'),
    menu('Calendar', '/:urlId', [UnitManager]),
    // menu('Open Shifts', paths.OpenShifts),
    // menu('Shift Swaps', paths.ShiftSwaps),
    menu(
      'Facility',
      null,
      [],
      [
        menu('Licenses', paths.AdminLicenses, [FacilityAdmin]),
        menu('Certifications & Credentials', paths.AdminCertifications, [FacilityAdmin]),
        menu('Skills', paths.AdminSkills, [FacilityAdmin]),
        menu('Equipments (Legacy)', paths.AdminEquipmentsLegacy, [FacilityAdmin]),
        menu('Equipments', paths.AdminEquipments, [FacilityAdmin]),
        menu('SubSpecialty', paths.AdminSubSpecialty, [FacilityAdmin]),
        menu('Units', paths.AdminUnits, [FacilityAdmin]),
        menu('Roles', paths.AdminRoles, [FacilityAdmin]),
        menu('Shift Types', paths.AdminShiftTypes, [FacilityAdmin]),
        menu('Shifts', paths.AdminShifts, [FacilityAdmin]),
        //menu('Census', paths.AdminCensus, [FacilityAdmin]),
        menu('idn', paths.AdminIdns, [InstanceAdministrator]),
        menu('Facilities', paths.AdminFacilities, [InstanceAdministrator])
      ]
    ),
    menu(
      'Staff',
      null,
      [],
      [
        menu('Users', paths.AdminStaff, [FacilityAdmin, UnitManager]),
        menu('Positions', paths.AdminPositions, [FacilityAdmin])
      ]
    )
  ])
}

const sitemap = generateSitemap()
initialState('sitemap', sitemap)

function hideMenuItems(state, isInstanceAdmin = false) {
  const userRoles = getUserRoles(state, isInstanceAdmin)
  const updateMenuItems = (menu) => menu.update('items', updateItems)
  const updateItems = (items) => items.map((item) => item.set('hide', !isAuthorized(item.get('roles'))))
  const isAuthorized = (roles) => roles.some((role) => userRoles.includes(role))
  const updateMenu = (menu) => {
    const items = menu.get('items')
    const roles = menu.get('roles')
    const hide = items.size === 0 ? !isAuthorized(roles) : items.count((item) => !item.get('hide')) === 0
    return menu.set('hide', hide)
  }

  return state.update('sitemap', (sitemap) => sitemap.map(updateMenuItems).map(updateMenu))
}

function getUserRoles(state, isInstanceAdmin) {
  const userRoles = state.getIn(['authentication', 'facilityUser', 'roleIds']) || List()

  if (isInstanceAdmin && !userRoles.includes(Roles.InstanceAdministrator)) {
    return userRoles.push(Roles.InstanceAdministrator)
  }

  return userRoles
}

function resetSitemap(state) {
  return state.set('sitemap', sitemap)
}

function updateFacilityUrlId(state, newFacilityUrlId) {
  urlId = newFacilityUrlId
  return state.set('sitemap', generateSitemap())
}

export { hideMenuItems, resetSitemap, updateFacilityUrlId }
