import { PureComponent, createRef } from 'react'
import Details from './Details'
import { Avatar } from 'Manager/components'
import './Profile.scss'
import { navigate } from 'Navigation'
import { Map } from 'immutable'

export default class Profile extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { isStaffDetailsShown: false, visible: false }
    this.ref = createRef()
  }

  componentDidMount() {
    const { scrollTop, offsetHeight } = this.props
    this.updateVisibility(scrollTop, offsetHeight)
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const { scrollTop, offsetHeight } = nextProps
    const scrollTopChanged = this.props.scrollTop !== scrollTop
    const offsetHeightChanged = this.props.offsetHeight !== offsetHeight
    if (scrollTopChanged || offsetHeightChanged) {
      this.updateVisibility(scrollTop, offsetHeight)
    }
  }

  updateVisibility(top, height) {
    if (height && this.ref.current) {
      const { offsetHeight, offsetTop } = this.ref.current
      if (!offsetHeight) {
        return
      }

      const visible = top <= offsetTop + offsetHeight && top + height >= offsetTop
      this.setState({ visible })
    }
  }

  render() {
    const { facilityUser } = this.props
    const profile = facilityUser.get('profile') || Map()
    const facilityProfile = facilityUser.get('facilityProfile') || Map()
    const facilityProfileWithAvatarUrl = facilityProfile.set('avatarUrl', profile.get('avatarUrl'))

    const firstName = facilityProfile.get('firstName')
    const lastName = facilityProfile.get('lastName')
    const fullName = `${firstName} ${lastName}`
    const userId = facilityUser.get('id')
    const employmentType = facilityUser.getIn(['staffProfile', 'employmentType'])

    const avatarProps = {
      profile: facilityProfileWithAvatarUrl,
      userId
    }

    return (
      <>
        <aside
          ref={this.ref}
          className="_staffMeta _staffProfile col-2"
          onMouseEnter={this.showStaffDetails}
          onMouseLeave={this.hideStaffDetails}
          onDoubleClick={this.navigateToFacilityUser}
        >
          <div className="bg-alabaster hx-calendar-staff-info-wrapper bt1">
            <Avatar {...avatarProps} />
            <div className="staff-meta">
              {fullName}
              <div className="small regent-gray">{getStringFromCapitalize(employmentType)}</div>
            </div>
          </div>
        </aside>
        {this.state.isStaffDetailsShown && <Details facilityUser={facilityUser} />}
      </>
    )
  }

  navigateToFacilityUser = () => {
    const { facilityUser } = this.props
    const userId = facilityUser.get('userId')

    navigate.from.FacilityUnitHome.to.StaffDetails({ userId })
  }

  showStaffDetails = () => this.setState({ isStaffDetailsShown: true })

  hideStaffDetails = () => this.setState({ isStaffDetailsShown: false })
}

const getStringFromCapitalize = (string) => {
  if (!string) {
    return ''
  }
  string = string.replace(/([A-Z])/g, ' $1')
  return string.charAt(0).toUpperCase() + string.slice(1)
}
