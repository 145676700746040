import { SpillProofDiv } from 'Common/components'
import './Shift.scss'

function VShiftTitle({ shift, shiftRange, calendarType }) {
  const shiftDisplayAbbreviation = shift.get('displayAbbreviation')
  const shiftDisplayColor = shift.get('displayColor')
  const isLegendRequired = shiftDisplayAbbreviation
  const additionalCSS = calendarType === 'autoScheduleCalendar' ? { width: '65%', textWrap: 'nowrap' } : {}
  return (
    <aside className="col-2 row align-middle flex-nowrap">
      <span className="upper smaller bold river-bad col-5 pr10">
        <section>
          <SpillProofDiv id={shift.get('id')} tooltipPosition="right" hasCustomTooltip={true}>
            {shift.get('name')}
          </SpillProofDiv>
        </section>
      </span>
      <span className="smaller bold river-bad text-right col-5" style={{ ...additionalCSS }}>
        {shiftRange}
      </span>
      <span className="upper smaller bold river-bad col-2 align-right row align-middle">
        {isLegendRequired && (
          <span
            className="row align-middle align-center legend"
            style={{ color: shiftDisplayColor, background: !shiftDisplayAbbreviation && shiftDisplayColor }}
          >
            {shiftDisplayAbbreviation}
          </span>
        )}
      </span>
    </aside>
  )
}
export default VShiftTitle
