export default function getOptions() {
  return {
    indexAxis: 'y',
    layout: {
      padding: {
        right: 67
      }
    },
    scales: {
      x: {
        ticks: {
          mirror: true
        },
        display: false,
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      y: {
        ticks: {
          mirror: true,
          labelOffset: -20
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    categoryPercentage: 0.5,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end' as const,
        align: 'end' as const
      },
      legend: {
        display: false
      }
    }
  }
}
