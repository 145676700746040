import { isEqual, cloneDeep } from 'lodash'
import { ComponentController } from 'Common/components'
import formDefinitions from './formDefinitionsSchedules'
import constants from './constants'
import { getFieldValueMapping, isAnyRequiredFieldEmpty } from '../utils'

const { recurringTypes, weekDaysFullArray, mapRecurringTypes, weekDaysShort } = constants

class RecurringScheduleFormController extends ComponentController {
  get defaultState() {
    return {
      form: cloneDeep(formDefinitions),
      isLoading: false,
      isValidForm: false,
      shownShiftsSubmenu: false
    }
  }

  initForm() {
    const form = cloneDeep(this.state.form)

    this.#initFieldAccessibilities(form)
    this.#initFieldValues(form)
    this.#initFieldRequirements(form)

    if (this.isFormToEdit()) {
      this.formValuesBeforeEdit = getFieldValueMapping(form)
    }

    const isValidForm = this.isValidForm(form)
    this.setState({ form, isValidForm })
  }

  #initFieldRequirements = (form) => {
    if (this.#isRuleTypeMonthly(form)) {
      //weekly rule type fields not required
      this.#setWeeklyRuleTypeFieldRequirements(form, false)
      //other fields set not required based on weekSelection/daySelection
      if (form.daySelection.value === true) {
        form.weekDaySelection.definition.required = false
        form.weekOnSelection.definition.required = false
      } else if (form.weekSelection.value === true) {
        form.dayOnSelection.definition.required = false
      }
    } else if (this.#isRuleTypeWeekly(form)) {
      //monthly rule type fields not required
      this.#setMonthlyRuleTypeFieldRequirements(form, false, false)
    }
  }

  #setMonthlyRuleTypeFieldRequirements(form, isWeekSelectionRequired, isDaySelectionRequired) {
    form.daySelection.definition.required = isDaySelectionRequired
    form.dayOnSelection.definition.required = isDaySelectionRequired

    form.weekSelection.definition.required = isWeekSelectionRequired
    form.weekOnSelection.definition.required = isWeekSelectionRequired
    form.weekDaySelection.definition.required = isWeekSelectionRequired
  }

  #setWeeklyRuleTypeFieldRequirements(form, isRequired) {
    form.weekSchedule.definition.required = isRequired
  }

  #initFieldAccessibilities = (form) => {
    form.weekOnSelection.definition.disabled = true
    form.weekDaySelection.definition.disabled = true
    if (this.isFormToEdit()) {
      form.ruleType.definition.disabled = true
      form.ruleEvery.definition.disabled = true
      form.shiftTypes.definition.disabled = true
      form.scheduleStartDate.definition.disabled = true
      form.shiftStartTime.definition.disabled = true
      form.shiftEndTime.definition.disabled = true
      form.daySelection.definition.disabled = true
      form.dayOnSelection.definition.disabled = true
      form.weekSchedule.definition.disabled = true
      form.weekSelection.definition.disabled = true
    }
  }

  #initFieldValues = (form) => {
    form.ruleType.value = ''
    form.ruleEvery.value = 1
    form.shiftTypes.value = ''
    form.shiftStartTime.value = ''
    form.shiftEndTime.value = ''
    form.scheduleStartDate.value = ''
    form.scheduleEndDate.value = null
    form.daySelection.value = true
    form.dayOnSelection.value = ''
    form.weekSchedule.value = cloneDeep(weekDaysShort)
    form.weekSelection.value = false
    form.weekOnSelection.value = ''
    form.weekDaySelection.value = ''

    if (this.isFormToEdit()) {
      const { staffRecurringSchedule, timeService } = this.props
      const shiftId = staffRecurringSchedule.get('shiftId')
      const shiftStartTime = staffRecurringSchedule.get('shiftStartTime')
      const shiftDuration = staffRecurringSchedule.get('shiftDuration')
      const startsAt = staffRecurringSchedule.get('startsAt')
      const endsAt = staffRecurringSchedule.get('endsAt')
      const recurringType = staffRecurringSchedule.get('recurringType')
      const weeklyDayIndexes = staffRecurringSchedule.get('weeklyDayIndexes')
      const recurEvery = staffRecurringSchedule.get('recurEvery')
      const subType = staffRecurringSchedule.get('subType')
      const monthlyOnThe = staffRecurringSchedule.get('monthlyOnThe')
      const monthlyOnTheDayWeek = staffRecurringSchedule.get('monthlyOnTheDayWeek')
      const shiftStartTimeMoment = timeService.applyTimeToDate(shiftStartTime, null)

      form.ruleType.value = recurringType === 'weekly' ? '1' : '2'
      form.ruleEvery.value = recurEvery
      form.shiftTypes.value = shiftId
      form.shiftStartTime.value = shiftStartTimeMoment.toISOString()
      form.shiftStartTime.minValue = shiftStartTimeMoment.toISOString()
      form.shiftEndTime.value = shiftStartTimeMoment.add(shiftDuration, 'minutes').toISOString()
      form.shiftEndTime.maxValue = shiftStartTimeMoment.add(shiftDuration, 'minutes').toISOString()

      form.scheduleStartDate.value = startsAt || ''
      form.scheduleEndDate.value = endsAt || null
      form.daySelection.value = subType === 'date' ? true : false
      form.dayOnSelection.value = monthlyOnThe || ''

      const weekDaysShortArray = cloneDeep(weekDaysShort[0])
      weeklyDayIndexes.forEach((item) => {
        let elementUpdate = weekDaysShortArray?.[item]
        elementUpdate.value = true
      })
      form.weekSchedule.value = [weekDaysShortArray]
      form.weekSelection.value = subType === 'day' ? true : false
      form.weekOnSelection.value = monthlyOnThe || ''
      form.weekDaySelection.value = monthlyOnTheDayWeek || ''
    }
  }

  isFormToEdit = () => {
    const { staffRecurringSchedule } = this.props
    return staffRecurringSchedule && staffRecurringSchedule.size > 0
  }

  setFieldValue = (e, field) => {
    const { form } = this.state

    switch (field) {
      case 'scheduleStartDate':
      case 'scheduleEndDate':
        this.#setDate(e, form, field)
        break
      case 'shiftStartTime':
      case 'shiftEndTime':
        this.#setScheduleRangeDate(e, form, field)
        break
      case 'shiftTypes':
      case 'weekSchedule':
        this.#setScheduleDate(e, form, field)
        break
      case 'ruleType':
        this.#setRuleType(e, form)
        break
      case 'ruleEvery':
        this.#setRuleEvery(e, form)
        break
      case 'daySelection':
        this.#setDaySelection(e, form)
        break
      case 'dayOnSelection':
        this.#setDateSelection(e, form, field)
        break
      case 'weekSelection':
        this.#setWeekSelection(e, form)
        break
      case 'weekOnSelection':
        this.#setDateSelection(e, form, field)
        break
      case 'weekDaySelection':
        this.#setDateSelection(e, form, field)
        break
      case 'days':
        form.days.value = e
        break
      default:
    }

    const isValidForm = this.isValidForm(form)
    this.setState({ form, isValidForm })
  }

  #setDate = (e, form, field) => {
    const { timeService } = this.props
    const date = e.substring(0, 10)
    const startOfWeek = timeService.timeMoment(date).startOf('week').format('MM-DD-YYYY')
    form[field].value = timeService.usDateToMoment(startOfWeek).toISOString()
  }

  #setScheduleDate = (e, form, field) => {
    const { timeService, shifts } = this.props
    form[field].value = e
    if (field === 'shiftTypes') {
      const shiftTypesValueArray = shifts?.toJS().filter((item) => item.id === form?.shiftTypes?.value)
      const shiftTypesObject = shiftTypesValueArray && shiftTypesValueArray.length > 0 && shiftTypesValueArray[0]

      const shiftStartTime = shiftTypesObject?.startTime
      const shiftDuration = shiftTypesObject?.length
      if (shiftStartTime) {
        const shiftStartTimeMoment = timeService.applyTimeToDate(shiftStartTime, null)
        form.shiftStartTime.value = shiftStartTimeMoment.toISOString()
        form.shiftStartTime.minValue = form.shiftStartTime.value
        if (shiftDuration) {
          form.shiftEndTime.value = shiftStartTimeMoment.add(shiftDuration, 'minutes').toISOString()
          form.shiftEndTime.maxValue = form.shiftEndTime.value
        }
      }
    }
  }

  #setScheduleRangeDate = (e, form, field) => {
    form[field].value = e
  }

  #setRuleType = (e, form) => {
    form.ruleType.value = e.target ? e.target.value : ''
    if (this.#isRuleTypeMonthly(form)) {
      this.#setMonthlyRuleTypeFieldRequirements(form, form.weekSelection.value, form.daySelection.value)
      this.#setWeeklyRuleTypeFieldRequirements(form, false)
    } else if (this.#isRuleTypeWeekly(form)) {
      this.#setWeeklyRuleTypeFieldRequirements(form, true)
      this.#setMonthlyRuleTypeFieldRequirements(form, false, false)
    }
  }

  #setRuleEvery = (e, form) => {
    form.ruleEvery.value = e
  }

  #setDaySelection = (e, form) => {
    if (form.daySelection.definition.disabled) {
      return
    }

    form.daySelection.value = !!e
    form.weekSelection.value = !e
    form.dayOnSelection.definition.disabled = !e

    form.weekOnSelection.definition.disabled = !!e
    form.weekDaySelection.definition.disabled = !!e

    this.#setMonthlyRuleTypeFieldRequirements(form, form.weekSelection.value, form.daySelection.value)
  }

  #setWeekSelection = (e, form) => {
    if (form.weekSelection.definition.disabled) {
      return
    }

    form.weekSelection.value = !!e
    form.daySelection.value = !e
    form.weekOnSelection.definition.disabled = !e
    form.weekDaySelection.definition.disabled = !e

    form.dayOnSelection.definition.disabled = !!e

    this.#setMonthlyRuleTypeFieldRequirements(form, form.weekSelection.value, form.daySelection.value)
  }

  #setDateSelection = (e, form, field) => {
    form[field].value = e.target ? e.target.value : ''
  }

  areFormValuesUnchanged = (form) => {
    const currentFormValues = getFieldValueMapping(form)
    return isEqual(currentFormValues, this.formValuesBeforeEdit)
  }

  isValidForm = (form) => {
    if (isAnyRequiredFieldEmpty(form)) {
      return false
    }
    if (this.isFormToEdit() && this.areFormValuesUnchanged(form)) {
      return false
    }
    if (this.#isRuleTypeWeekly(form)) {
      const weekScheduleParam = form.weekSchedule.value
      const weekScheduleArray = weekScheduleParam && weekScheduleParam.length > 0 && weekScheduleParam[0]
      const checkResult = weekScheduleArray?.some?.((item) => item.value === true)
      if (!checkResult) {
        return false
      }
    }
    if (this.#isRuleTypeMonthly(form)) {
      if (form.daySelection.value && !(form.dayOnSelection.value > 0)) {
        return false
      }
      if (form.weekSelection.value && !(form.weekOnSelection.value > 0 && !this.isFieldEmpty('weekDaySelection'))) {
        return false
      }
    }

    return true
  }

  #isRuleTypeWeekly = (form) => {
    return form.ruleType.value === '1'
  }

  #isRuleTypeMonthly = (form) => {
    return form.ruleType.value === '2'
  }

  #getMonthlySubType = (form) => {
    if (!this.#isRuleTypeMonthly(form)) {
      return null
    }
    return form.daySelection.value ? 'date' : 'day'
  }

  isFieldEmpty = (fieldKey) => {
    const field = this.state.form[fieldKey].value
    return field === '' || field === undefined || field === null
  }

  serializeForm = () => {
    const { timeService } = this.props
    const {
      shiftTypes,
      shiftStartTime,
      shiftEndTime,
      dayOnSelection,
      scheduleStartDate,
      scheduleEndDate,
      ruleType,
      weekSchedule,
      ruleEvery,
      weekOnSelection,
      weekDaySelection
    } = this.state.form
    const shiftStartTimeHours = timeService.timeMoment(shiftStartTime.value).hours()
    const shiftStartTimeMinutes = timeService.timeMoment(shiftStartTime.value).minutes()
    const shiftEndTimeHours = timeService.timeMoment(shiftEndTime.value).hours()
    const shiftEndTimeMinutes = timeService.timeMoment(shiftEndTime.value).minutes()
    let shiftDuration
    if (shiftStartTimeHours < shiftEndTimeHours) {
      shiftDuration = (shiftEndTimeHours - shiftStartTimeHours) * 60 - shiftStartTimeMinutes + shiftEndTimeMinutes
    } else {
      shiftDuration = (24 - shiftStartTimeHours + shiftEndTimeHours) * 60 - shiftEndTimeMinutes + shiftStartTimeMinutes
    }

    const recurringTypeArray = recurringTypes.filter((item) => item.key === +ruleType.value)
    const recurringTypeItem = recurringTypeArray?.length > 0 && recurringTypeArray[0]
    const recurringType = recurringTypeItem?.name && mapRecurringTypes[recurringTypeItem.name]
    const weekScheduleArray = weekSchedule.value && weekSchedule.value.length > 0 && weekSchedule.value[0]
    const weeklyDayIndexes = weekScheduleArray?.filter((item) => item.value).map((item) => item.index)
    const subType = this.#getMonthlySubType(this.state.form)
    const monthlyOnThe = subType === 'date' ? +dayOnSelection.value : +weekOnSelection.value
    let resultData = {
      shiftId: shiftTypes.value,
      shiftStartTime: timeService.timeMoment(shiftStartTime.value).format('HH:mm'),
      shiftDuration,
      startsAt: scheduleStartDate.value,
      endsAt: scheduleEndDate.value,
      recurringType,
      weeklyDayIndexes,
      recurEvery: +ruleEvery.value,
      subType
    }
    if (recurringType === 'weekly') {
      delete resultData.subType
    }
    if (subType === 'date') {
      resultData.monthlyOnThe = monthlyOnThe
      delete resultData.weeklyDayIndexes
    }
    if (subType === 'day') {
      resultData.monthlyOnThe = monthlyOnThe
      resultData.monthlyOnTheDayWeek = weekDaySelection.value && weekDaysFullArray[+weekDaySelection.value]
      delete resultData.weeklyDayIndexes
    }
    return resultData
  }
}

export default RecurringScheduleFormController
