import { navigate } from 'Navigation'
import './SwappingShiftDetails.scss'
import { withAppContext } from 'AppContext'
import ShiftSwapCard from './ShiftSwapList/ShiftSwapCard/ShiftSwapCard'
import RequestShiftSwap from './RequestShiftSwap/RequestShiftSwap'
import MonthYearHeader from './MonthYearHeader'
import { RefObject, useRef } from 'react'
import DataController from './Controllers/DataController'
import { useLocation } from 'react-router'
import { t } from 'i18n'

const shiftSwapTabOptions = [
  { id: 'shiftSwapList', label: 'Shift Swap List' },
  { id: 'requestShiftSwap', label: 'Request Shift Swap' }
]

function SwappingShiftDetails(props: any) {
  const thisRef = useRef({ props })
  const dataControllerRef: RefObject<DataController> = useRef(new DataController(thisRef.current))
  thisRef.current.props = props

  const auth = props.appState.get('authentication')
  const urlId = auth.getIn(['facility', 'urlId']) || auth.getIn(['facilities', 0, 'urlId'])
  const location = useLocation()
  const {
    usDate: date,
    userId,
    initiatedShiftSwapRequests,
    receivedShiftSwapRequests,
    publicStaffMap,
    appState,
    unitsMap,
    shiftsMap,
    timeService
  } = props
  const pathname = location.pathname
  let componentId = 'shiftSwapList'

  if (pathname.includes('new')) {
    componentId = 'requestShiftSwap'
  }

  const selectedMonth = timeService.getMonth(date, 0, 'MM-YYYY')

  const formattedMonthYear = timeService.getMonthYearForUsDate(date)
  const mergeInitiatedAndReceivedShiftSwaps = () => {
    const initiatedShiftSwapRequestsForSelectedMonth = initiatedShiftSwapRequests.filter((value: any, key: any) => {
      const shiftSwapRequestMonth = timeService.timeMoment(key).format('MM-YYYY')

      return shiftSwapRequestMonth === selectedMonth
    })

    const initiatedShiftSwapRequestsWithSuffix = initiatedShiftSwapRequestsForSelectedMonth.mapKeys(
      (key: string) => `${key}_initiated`
    )

    const receivedShiftSwapRequestsForSelectedMonth = receivedShiftSwapRequests.filter((value: any, key: any) => {
      const shiftSwapRequestMonth = timeService.timeMoment(key).format('MM-YYYY')

      return shiftSwapRequestMonth === selectedMonth
    })

    const receivedShiftSwapRequestsWithSuffix = receivedShiftSwapRequestsForSelectedMonth.mapKeys(
      (key: any) => `${key}_received`
    )

    const mergedShiftSwapRequests = receivedShiftSwapRequestsWithSuffix.merge(initiatedShiftSwapRequestsWithSuffix)

    return mergedShiftSwapRequests.sortBy((value: any, key: any) => key).reverse()
  }

  const shiftData = mergeInitiatedAndReceivedShiftSwaps()

  const handleTabClick = (id: string) => {
    if (id === 'requestShiftSwap') {
      navigate.from.StaffCalendarPage.to.StaffCalendarPageNew()
    } else {
      navigate.from.StaffCalendarPageNew.to.StaffCalendarPage()
    }
  }

  const totalCount = shiftData.reduce((total: number, shiftSwapRequests: any) => {
    return total + shiftSwapRequests.size
  }, 0)

  const staffCalendar = appState.get('staffCalendar')
  const staffShiftSwaps = appState.get('staffShiftSwaps')

  return (
    <div className="shift-swap-container">
      <div className="bg-white pl10">
        <sh-tabs slot="tabs">
          <div className="shift-swap-icon-container">
            <div className="my-shift-icon">
              <sh-icon icon="in-progress" size="s" color="white"></sh-icon>
            </div>
          </div>
          {shiftSwapTabOptions.map(({ id, label }) => (
            <sh-tab-item
              label={label}
              key={id}
              active={componentId === id ? true : undefined}
              onClick={() => handleTabClick(id)}
              badge={id === 'shiftSwapList' ? totalCount : undefined}
            ></sh-tab-item>
          ))}
        </sh-tabs>
      </div>
      {componentId === 'requestShiftSwap' ? (
        <RequestShiftSwap
          timeService={timeService}
          dataController={dataControllerRef.current}
          staffCalendar={staffCalendar}
          staffShiftSwaps={staffShiftSwaps}
          date={date}
          loggedInUserId={userId}
        />
      ) : (
        <>
          <MonthYearHeader formattedMonthYear={formattedMonthYear} />
          <div className="shift-swap-list">
            {shiftData.size ? (
              shiftData.entrySeq().map(([key, shiftSwapRequests]: [string, any[]]) =>
                shiftSwapRequests.map((shiftSwap: any) => (
                  <div key={shiftSwap.get('id')}>
                    <ShiftSwapCard
                      dataController={dataControllerRef.current}
                      userId={userId}
                      timeService={timeService}
                      date={shiftSwap.get('date')}
                      unitsMap={unitsMap}
                      shiftsMap={shiftsMap}
                      publicStaffMap={publicStaffMap}
                      urlId={urlId}
                      shiftSwapRequest={shiftSwap}
                      initiated={key.endsWith('_initiated')}
                    />
                  </div>
                ))
              )
            ) : (
              <div className="h100 flex-column align-middle align-center">
                <div className="my-shift-icon-empty-state">
                  <sh-icon icon="in-progress" size="l" color="white"></sh-icon>
                </div>
                <div className="align-center pt10">
                  <sh-text>{`${t('staffCalendar.shiftSwap.emptyStateLabels.noShiftSwapRequests')}`}</sh-text>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default withAppContext(SwappingShiftDetails)
