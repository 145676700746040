import { RefObject, useRef, useEffect } from 'react'
import DataController from './Calendar/Controllers/DataController'
import { PrivateRoute } from 'auth'
import { navigate, paths } from 'Navigation'
import ErrorBoundary from 'ErrorBoundary'
import typedErrorsHandlers from 'services/ErrorHandler/typedErrorsHandlers'
import Now from './Now'
import StaffProfile from './profile/StaffProfile'
import CalendarLayout from './Calendar'
import { withAppContext } from 'AppContext'
import Footer from 'Footer'
import './StaffLayout.scss'
import { StaffSidebar } from './StaffSidebar'
import { Switch } from 'react-router'

function StaffLayout(props: any) {
  const thisRef = useRef({ props })
  const dataControllerRef: RefObject<DataController> = useRef(new DataController(thisRef.current))
  thisRef.current.props = props

  const { timeService, appState } = props
  const auth = appState.get('authentication')
  const urlId = auth.getIn(['facility', 'urlId']) || auth.getIn(['facilities', 0, 'urlId'])

  useEffect(() => {
    const loadData = async () => {
      await dataControllerRef.current?.loadUnits()
      await dataControllerRef.current?.loadShifts()
      await dataControllerRef.current?.loadPublicStaff()
    }
    loadData()
  }, [])

  if (!urlId) return navigate.to.Forbidden({}, false)

  return (
    <div className="h100 staff-role-layout">
      <div className="row wrapper-container between">
        <div className="left-pane">
          <StaffSidebar timeService={timeService} urlId={urlId} />
        </div>
        <div className="main-pane p10">
          <ErrorBoundary onError={typedErrorsHandlers['ApplicationError']}>
            <Switch>
              <PrivateRoute path={paths.StaffProfilePage} component={StaffProfile} requireFacility={false} />
              <PrivateRoute path={paths.StaffCalendarPage} component={CalendarLayout} requireFacility={false} />
              <PrivateRoute path={paths.StaffNowPage} component={Now} requireFacility={false} />
            </Switch>
          </ErrorBoundary>
        </div>
      </div>
      <Footer timeService={timeService} />
    </div>
  )
}

export default withAppContext(StaffLayout)
