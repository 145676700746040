import { Component } from 'react'
import classNames from 'classnames'
import { middleGlyphMap } from 'Manager/components/CellIconMap'
import './Footer.scss'
import { Map } from 'immutable'

export default class Footer extends Component {
  render() {
    const { timeService, isLegendVisible, authentication: auth, unit, filterShiftTypes, filterRoleIds } = this.props

    const roles = unit.get('roles')
    const shifts = unit.get('shifts') || Map()
    const now = timeService.timeMoment(null)
    const printedDate = now.format('MMM D, YYYY')
    const printedTime = now.format('h:mm A')
    const printedAt = `${printedDate} at ${printedTime}`

    const firstName = auth.getIn(['profile', 'firstName'])
    const lastName = auth.getIn(['profile', 'lastName'])
    const printedBy = `${firstName} ${lastName}`

    const shiftAbbreviationList = []

    for (const shift of shifts) {
      const shiftAbbreviation = shift.get('displayAbbreviation')

      if (shiftAbbreviation) {
        shiftAbbreviationList.push({ shiftAbbreviation, shiftName: shift.get('name') })
      }
    }

    let shiftTypes = ''
    let staffRoles = ''

    switch (true) {
      case filterShiftTypes.size === 2:
        shiftTypes = 'All Shifts'
        break

      case filterShiftTypes.includes('day'):
        shiftTypes = 'Day'
        break

      case filterShiftTypes.includes('night'):
        shiftTypes = 'Night'
        break

      default:
    }

    switch (true) {
      case filterRoleIds.size === roles.size:
        staffRoles = 'All Roles'
        break

      case filterRoleIds.size === 1: {
        const role = roles.find((role) => role.get('id') === filterRoleIds.get(0))
        staffRoles = role?.get('name')
        break
      }
      default:
        staffRoles = `${filterRoleIds.size} roles selected`
    }

    const legendClassName = classNames('legend', { hide: !isLegendVisible })

    return (
      <tfoot>
        <tr>
          <td>
            <div className="bottom-colontitle">
              <div>
                <hr />
              </div>
              <div>
                <div className="print-details">
                  <div>
                    <span className="bold">Printed on {printedAt}</span> by {printedBy}
                  </div>
                  <div>
                    <span>{shiftTypes}</span>
                    <span>{staffRoles}</span>
                  </div>
                </div>
                <div className={legendClassName}>
                  <div>
                    <span className="fullview-cell-icons">{middleGlyphMap['event']}</span> Event
                  </div>
                  <div>
                    <span className="fullview-cell-icons">{middleGlyphMap['faded-circle']}</span> Other Unit
                  </div>
                  <div>
                    <span className="fullview-cell-icons">{middleGlyphMap['unavailability']}</span> Request Off
                  </div>
                  <div>
                    <span className="fullview-cell-icons">{middleGlyphMap['money-sign']}</span> PTO
                  </div>
                  <div>
                    <span className="fullview-cell-icons">{middleGlyphMap['on-call']}</span> On-Call
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="shift-mnemonics">
              {shiftAbbreviationList.length ? (
                <div>
                  <span className="bold">Shifts Mnemonics:</span>
                </div>
              ) : null}
              <div>
                {shiftAbbreviationList.map((shift, idx) => (
                  <span key={idx}>
                    {shift.shiftAbbreviation}: {shift.shiftName}
                  </span>
                ))}
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    )
  }
}
