import { withAppContext } from 'AppContext'
import { Map } from 'immutable'

export function RedirectToGAS(props) {
  const {
    appState = Map(),
    accountsURI,
    location = {},
    referrerLocation = new URL(document.location),
    redirectBackToReferrer = false
  } = props
  const { href, origin } = referrerLocation

  const { pathname } = location.state?.from || {}
  let referrerUri = referrerLocation.origin
  if (redirectBackToReferrer && pathname) {
    referrerLocation.pathname = pathname
    referrerUri = referrerLocation.href
  }

  const redirectLocation = new URL(href.replace(origin, accountsURI))
  redirectLocation.searchParams.set('continue', window.btoa(referrerUri))

  const signOutReason = appState.getIn(['authentication', 'signOutReason', 'code'])
  if (signOutReason) {
    redirectLocation.searchParams.set('signout', signOutReason)
  }

  document.location.href = redirectLocation.href

  return null
}

export default withAppContext(RedirectToGAS)
