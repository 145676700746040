import { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import TopNavigationItems from './TopNavigationItems'
import TopNavigationMore from './TopNavigationMore'
import SelectUnit from '../SelectUnit'
import ProfileDropdown from '../ProfileDropdown'
import { withAppContext } from 'AppContext'
import stores from 'stores'
import './TopNavigation.scss'

export class TopNavigation extends PureComponent {
  render() {
    const { appState, unit, match } = this.props
    const authentication = appState.get('authentication')
    const eligibleUnits = appState.getIn(['generalData', 'eligibleUnits'])
    const homeUnitId = authentication.getIn(['facilityUser', 'homeUnitId'])
    const components = authentication.get('components')
    const timeService = stores.timeService()
    const notifications = appState.getIn(['generalData', 'notifications'])
    const facilityFeatures = authentication.getIn(['facility', 'configuration', 'features'])

    const selectUnitProps = {
      unit,
      timeService,
      homeUnitId,
      eligibleUnits
    }

    const { date, componentId, unitUrlId } = match.params
    const Items =
      facilityFeatures && TopNavigationItems(components, facilityFeatures, componentId, date, notifications, unitUrlId)

    return (
      <div data-testid="hx-top-navigation" className="hx-top-navigation row align-middle">
        {eligibleUnits.size === 0 ? (
          <div className="calendar-select-unit-container row col align-middle" />
        ) : (
          <div className="col">
            <SelectUnit {...selectUnitProps} />
          </div>
        )}

        <nav className="row col align-middle g10 _items">
          {Items}
          <TopNavigationMore Items={Items} />
        </nav>

        <div className="row col align-middle">
          <ProfileDropdown />
        </div>
      </div>
    )
  }
}

export default withAppContext(withRouter(TopNavigation))
