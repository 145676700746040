import Polyglot from 'node-polyglot'
import activities from './activities.i18n.en.json'
import auth from './auth.i18n.en.json'
import calendar from './calendar.i18n.en.json'
import common from './common.i18n.en.json'
import footer from './footer.i18n.en.json'
import announcements from './announcements.i18n.en.json'
import errors from './errors.i18n.en.json'
import openShifts from './open-shifts.i18n.en.json'
import staff from './staff.i18n.en.json'
import shiftSkillMismatch from './shift-skill-mismatch-modal.i18n.en.json'
import expiringList from './expiring-list-modal.i18n.en.json'
import dashboard from './dashboard.i18n.en.json'
import skillscheck from './skillscheck.i18n.en.json'
import equipments from './equipments.i18n.en.json'
import staffCalendar from './staff/calendar.i18n.en.json'

const polyglot = new Polyglot()
export default polyglot
export const extend = polyglot.extend.bind(polyglot)
export const t = polyglot.t.bind(polyglot)
export const curry =
  (prefix) =>
  (key, ...rest) =>
    t(`${prefix}${key}`, ...rest)

extend(auth)
extend(activities)
extend(calendar)
extend(common)
extend(footer)
extend(announcements)
extend(errors)
extend(openShifts)
extend(staff)
extend(shiftSkillMismatch)
extend(expiringList)
extend(dashboard)
extend(skillscheck)
extend(equipments)
extend(staffCalendar)
