import { EditorState, ContentState, convertToRaw } from 'draft-js'

export const ElementSchemas = {
  image: {
    type: 'image',
    content: {
      imageSrc: ''
    }
  },
  text: {
    type: 'text',
    content: {
      contentToSave: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent()))
    }
  },
  link: {
    type: 'link',
    content: {
      linkTitle: '',
      webAddress: ''
    }
  }
}

export function getPrefilledContentToSave(content) {
  return JSON.stringify(convertToRaw(ContentState.createFromText(content)))
}
