import { Component } from 'react'

import LicenseFormController from './LicenseFormController'
import CreateEditExpertiseModal from '../CreateEditExpertiseModal'

class CreateEditLicenseModal extends Component {
  render() {
    const modalProps = {
      ...this.props,
      editPathKey: 'StaffEditLicense',
      createPathKey: 'StaffCreateLicense',
      expertiseType: 'license',
      FormController: LicenseFormController
    }

    return <CreateEditExpertiseModal {...modalProps} />
  }
}

export default CreateEditLicenseModal
