import React, { useEffect, useState } from 'react'
import DonutGraphCard from '../CommonCards/DonutGraphCard'

interface IEmploymentTypeDistributionProps {
  readonly unit: any
  readonly loadCardData: Function
}

interface ICardData {
  fullTimeStaffCount: number
  partTimeStaffCount: number
  perDiemStaffCount: number
}

const EmploymentTypeDistribution: React.FC<IEmploymentTypeDistributionProps> = ({ unit, loadCardData }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [cardData, setCardData] = useState<ICardData>({
    fullTimeStaffCount: 0,
    partTimeStaffCount: 0,
    perDiemStaffCount: 0
  })
  const unitId = unit.get('id')
  const areLoadParamsReady = unit.get('isReady')

  useEffect(() => {
    const loadData = async () => {
      const employmentTypeData = await loadCardData(unitId)

      setCardData(employmentTypeData)
      setIsLoading(false)
    }

    if (areLoadParamsReady) {
      setIsLoading(true)
      loadData()
    }
  }, [loadCardData, unitId, areLoadParamsReady])

  const { fullTimeStaffCount, partTimeStaffCount, perDiemStaffCount } = cardData

  return (
    <DonutGraphCard
      title={'Employment By Type'}
      height={320}
      noContentLabel={'No staff found in this unit'}
      isLoading={isLoading}
      dataset={{
        data: [fullTimeStaffCount, partTimeStaffCount, perDiemStaffCount],
        backgroundColor: ['rgba(229, 161, 115, 1)', 'rgba(131, 38, 245, 1)', 'rgba(103, 192, 176, 1)']
      }}
      labels={['Full Time', 'Part Time', 'Per Diem']}
    />
  )
}

export default EmploymentTypeDistribution
