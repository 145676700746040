import { CreateEntity, DefaultAdmin, UpdateEntity } from '@humanics/he-react-common/lib/admin/components'
import { CreateShift, UpdateShift } from './ShiftsAdmin'
// import { JobsAdmin } from './modules/jobs';
import Services from './modules/dashboard/Services'
import Facilities, { FacilityCreate } from './modules/facilities'
import ShiftTypesAdmin, { ShiftTypeCreate, ShiftTypeUpdate } from './ShiftTypesAdmin'
import Idns, { IdnCreate } from './modules/idns'
import { PrivateRoute, Roles } from 'auth'
import { UnitUpdate, UnitCreate } from './modules/units'
// import UserNotifications from './modules/users/UserNotifications';
import Units from './modules/units/Units'
import { navigate, paths } from 'Navigation'
import RolesAdmin, { RoleCreate, RoleUpdate } from './RolesAdmin'
import EquipmentsLegacyAdmin, { EquipmentLegacyCreate, EquipmentLegacyUpdate } from './EquipmentLegacyAdmin'
import ExpertisesAdmin, { ExpertiseCreate } from './ExpertisesAdmin'
import { CreateUnitResource, UpdateUnitResource } from './UnitResource'
import { Link, withRouter } from 'react-router-dom'
import { Switch } from 'react-router'
import { UnitResourceAdmin } from './UnitResource'

const defaultMap = (store, path, components = {}) => ({
  store,
  path,
  ...components
})

const MAPPINGS = [
  defaultMap('expertise', paths.AdminLicenses, {
    roles: [Roles.FacilityAdmin],
    admin: ExpertisesAdmin,
    create: ExpertiseCreate,
    adminProps: {
      type: 'license',
      title: 'Licenses',
      itemName: 'License',
      titleExpertise: 'license',
      titleExpertiseGroup: 'license group'
    },
    createProps: {
      type: 'license',
      entityExpertise: 'License',
      entityExpertiseGroup: 'LicenseGroup',
      titleExpertise: 'License',
      titleExpertiseGroup: 'Group',
      navigateHome: navigate.to.AdminLicenses
    },
    store: 'expertise'
  }),
  defaultMap('expertise', paths.AdminCertifications, {
    roles: [Roles.FacilityAdmin],
    admin: ExpertisesAdmin,
    create: ExpertiseCreate,
    adminProps: {
      type: 'certification',
      title: 'Certifications & Credentials',
      itemName: 'Certification',
      titleExpertise: 'certification',
      titleExpertiseGroup: 'certification group'
    },
    createProps: {
      type: 'certification',
      entityExpertise: 'Certification',
      entityExpertiseGroup: 'CertificationGroup',
      titleExpertise: 'Certification',
      titleExpertiseGroup: 'Group',
      navigateHome: navigate.to.AdminCertifications
    },
    store: 'expertise'
  }),
  defaultMap('expertise', paths.AdminSkills, {
    roles: [Roles.FacilityAdmin],
    admin: ExpertisesAdmin,
    create: ExpertiseCreate,
    adminProps: {
      type: 'skill',
      title: 'Skills',
      itemName: 'Skill',
      titleExpertise: 'skill',
      titleExpertiseGroup: 'skill group'
    },
    createProps: {
      type: 'skill',
      entityExpertise: 'Skill',
      entityExpertiseGroup: 'SkillGroup',
      titleExpertise: 'Skill',
      titleExpertiseGroup: 'Group',
      navigateHome: navigate.to.AdminSkills
    },
    store: 'expertise'
  }),
  defaultMap('expertiseGroup', paths.AdminEquipmentsLegacy, {
    roles: [Roles.FacilityAdmin],
    admin: EquipmentsLegacyAdmin,
    create: EquipmentLegacyCreate,
    update: EquipmentLegacyUpdate,
    store: 'expertiseGroup'
  }),
  defaultMap('roles', paths.AdminRoles, {
    roles: [Roles.FacilityAdmin],
    admin: RolesAdmin,
    create: RoleCreate,
    update: RoleUpdate,
    store: 'unit',
    updateProps: {
      navigateHome: navigate.to.AdminRoles
    }
  }),
  defaultMap('unitResource', paths.AdminEquipments, {
    roles: [Roles.FacilityAdmin],
    admin: UnitResourceAdmin,
    adminProps: {
      type: 'equipment',
      title: 'Equipments',
      itemName: 'Equipment'
    },
    createProps: {
      type: 'equipment',
      createLabel: 'Create Equipment',
      // TODO: Revisit when merging with unit resource - START
      // createOperationId: 'createUnitResource',
      createOperationId: 'createShift',
      isPaginated: true
      // TODO: Revisit when merging with unit resource - END
    },
    updateProps: {
      type: 'equipment',
      updateLabel: 'Update Equipment',
      // TODO: Revisit when merging with unit resource - START
      // updateOperationId: 'updateUnitResource',
      updateOperationId: 'updateShift',
      isPaginated: true
      // TODO: Revisit when merging with unit resource - END
    },
    // TODO: Revisit when merging with unit resource - START
    // create: CreateUnitResource,
    // update: UpdateUnitResource
    create: CreateShift,
    update: UpdateShift
    // TODO: Revisit when merging with unit resource - END
  }),
  defaultMap('unitResource', paths.AdminSubSpecialty, {
    roles: [Roles.FacilityAdmin],
    admin: UnitResourceAdmin,
    adminProps: {
      type: 'subspeciality',
      title: 'Subspecialties',
      itemName: 'Subspecialty'
    },
    createProps: {
      type: 'subspeciality',
      createLabel: 'Create Subspecialty',
      // TODO: Revisit when merging with unit resource - START
      // createOperationId: 'createUnitResource'
      createOperationId: 'createShift',
      isPaginated: true
      // TODO: Revisit when merging with unit resource - END
    },
    updateProps: {
      type: 'subspeciality',
      updateLabel: 'Update Subspecialty',
      // TODO: Revisit when merging with unit resource - START
      // updateOperationId: 'updateUnitResource'
      updateOperationId: 'updateShift',
      isPaginated: true
      // TODO: Revisit when merging with unit resource - END
    },
    // TODO: Revisit when merging with unit resource - START
    // create: CreateUnitResource,
    // update: UpdateUnitResource
    create: CreateShift,
    update: UpdateShift
    // TODO: Revisit when merging with unit resource - END
  }),
  defaultMap('service', '/admin/dashboard', { admin: Services }),
  defaultMap('shift-types', paths.AdminShiftTypes, {
    roles: [Roles.FacilityAdmin],
    admin: ShiftTypesAdmin,
    create: ShiftTypeCreate,
    update: ShiftTypeUpdate,
    store: 'unit',
    updateProps: {
      navigateHome: navigate.to.AdminShiftTypes
    }
  }),
  defaultMap('idn', paths.AdminIdns, {
    roles: [Roles.InstanceAdministrator],
    admin: Idns,
    create: IdnCreate,
    createProps: {
      routeRequireFacility: false,
      createOperationId: 'createIdn'
    },
    updateProps: {
      navigateHome: navigate.to.AdminIdns
    }
  }),
  defaultMap('facility', paths.AdminFacilities, {
    roles: [Roles.InstanceAdministrator],
    admin: Facilities,
    create: FacilityCreate,
    createProps: {
      routeRequireFacility: false
    },
    updateProps: {
      navigateHome: navigate.to.AdminFacilities
    }
  }),
  // defaultMap('certification', '/admin/system/settings/certifications'),
  // defaultMap(
  //   'minimumStaffingRequirement',
  //   '/admin/system/settings/requirements'
  // ),
  // defaultMap('skill', '/admin/system/settings'),
  // TODO: Revisit when merging with unit resource - START
  // defaultMap('shift', paths.AdminShifts, {
  // roles: [Roles.FacilityAdmin],
  // createProps: {
  //   createOperationId: 'createShift',
  //   isPaginated: true
  // },
  // updateProps: {
  //   updateOperationId: 'updateShift',
  //   isPaginated: true,
  //   navigateHome: navigate.to.AdminShifts
  // },
  // create: CreateShift,
  // update: UpdateShift
  defaultMap('unitResource', paths.AdminShifts, {
    roles: [Roles.FacilityAdmin],
    admin: UnitResourceAdmin,
    adminProps: {
      type: 'shift',
      title: 'Shifts',
      itemName: 'Shift'
    },
    createProps: {
      type: 'shift',
      createLabel: 'Create Shift',
      createOperationId: 'createShift',
      isPaginated: true
    },
    updateProps: {
      type: 'shift',
      updateLabel: 'Update Shift',
      updateOperationId: 'updateShift',
      isPaginated: true
    },
    create: CreateShift,
    update: UpdateShift
    // TODO: Revisit when merging with unit resource - END
  }),
  // defaultMap('census', `${paths.FacilityAdmin}/units/census`),
  defaultMap('unit', paths.AdminUnits, {
    roles: [Roles.FacilityAdmin],
    createProps: {
      createOperationId: 'createUnit',
      isPaginated: true
    },
    updateProps: {
      updateOperationId: 'updateUnit',
      isPaginated: true,
      navigateHome: navigate.to.AdminUnits
    },
    admin: Units,
    create: UnitCreate,
    update: UnitUpdate
  }),
  // TODO: checkout why it's triggering an error
  //       looks like the store is not defined
  //       if it's deprecated then permanently remove.
  // defaultMap('staffProfiles', '/staff/profiles'),
  defaultMap('staffPositions', paths.AdminPositions, {
    roles: [Roles.FacilityAdmin],
    createProps: {
      createOperationId: 'createStaffPosition'
    },
    updateProps: {
      navigateHome: navigate.to.AdminPositions
    }
  }),
  defaultMap('staffCompliance', paths.AdminRules, {
    roles: [Roles.FacilityAdmin],
    createProps: {
      createOperationId: 'createStaffComplianceRule'
    },
    updateProps: {
      updateOperationId: 'updateStaffComplianceRule',
      navigateHome: navigate.to.AdminRules
    }
  }),

  defaultMap('staffPreferences', paths.AdminPreferences, {
    roles: [Roles.FacilityAdmin],
    adminProps: {
      createOperationId: 'createStaffPreferences'
    },
    updateProps: {
      updateOperationId: 'updateStaffPreferences',
      navigateHome: navigate.to.AdminPreferences
    }
  })
  // defaultMap('userNotification', `${paths.FacilityAdmin}/users/notifications`, {
  //   roles: [Roles.FacilityAdmin],
  //   admin: UserNotifications
  // }),
  // defaultMap('job', '/admin/system/jobs', {
  //   roles: [[Roles.FacilityAdmin]],
  //   admin: JobsAdmin
  // })
]

function Mapper(stores, mappings) {
  let routes = mappings.map((route) => ({
    admin: {
      path: route.path,
      component: route.admin || DefaultAdmin,
      store: route.store,
      customProps: { ...route.adminProps, Link, withRouter },
      roles: route.roles
    },
    mutate: [
      {
        path: `${route.path}/new`,
        component: route.create || CreateEntity,
        store: route.store,
        customProps: route.createProps,
        roles: route.roles
      },
      {
        path: `${route.path}/:id`,
        component: route.update || UpdateEntity,
        store: route.store,
        customProps: route.updateProps,
        roles: route.roles
      }
    ]
  }))
  return [].concat(routes)
}

function mapRoutes(stores, appState, timeService) {
  return Mapper(stores, MAPPINGS).map((routes, i) => {
    const route = routes.admin
    const store = stores.get(route.store)
    const { customProps = {} } = route
    const requireFacility =
      typeof customProps.routeRequireFacility === 'undefined' ? true : customProps.routeRequireFacility
    const subRoutes = (
      <Switch>
        {routes.mutate.map((route, i) => {
          let store = stores.get(route.store)
          const { customProps = {} } = route
          const requireFacility =
            typeof customProps.routeRequireFacility === 'undefined' ? true : customProps.routeRequireFacility
          return (
            <PrivateRoute
              key={route.path}
              exact
              path={route.path}
              componentProps={{ appState, store, stores, ...customProps }}
              component={route.component}
              timeService={timeService}
              requireFacility={requireFacility}
              roles={route.roles}
            />
          )
        })}
      </Switch>
    )

    return (
      <PrivateRoute
        key={route.path}
        path={route.path}
        componentProps={{ appState, store, stores, ...customProps, subRoutes }}
        component={route.component}
        timeService={timeService}
        requireFacility={requireFacility}
        roles={route.roles}
      />
    )
  })
}

export default mapRoutes
