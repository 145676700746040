import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [Field('name', 'name'), Field('createdAt', 'created', { format: 'date-time' })]

function StaffPositionsStore() {
  return AdminStore({
    fields,
    entityName: 'StaffPosition',
    api: {
      index: 'indexStaffPositions'
    },
    header: { title: 'Positions', createLabel: 'Create Position' }
  })
}

export default StaffPositionsStore
