import { statusDetails } from "Staff/Calendar/utils"

export const getInitiatorTagDetailsByState = (state: string, acceptedDate: any) => {
  switch (state) {
    case 'submitted':
      return [statusDetails.pendingFromStaff, statusDetails.pendingFromManager]
    case 'accepted':
      return [statusDetails.acceptedByStaff, statusDetails.pendingFromManager]
    case 'confirmed':
      return [statusDetails.acceptedByStaff, statusDetails.approvedByManager]
    case 'denied':
      if (acceptedDate === null) {
        return [statusDetails.deniedByManager]
      } else {
        return [statusDetails.acceptedByStaff, statusDetails.deniedByManager]
      }
    case 'declined':
      return [statusDetails.declinedByStaff]
    case 'canceled':
      return [statusDetails.withdrawnByYou]
    default:
      return null
  }
}

export const getRequesterTagDetailsByState = (state: string, acceptedDate: any) => {
  switch (state) {
    case 'submitted':
      return [statusDetails.pendingFromYou, statusDetails.pendingFromManager]
    case 'accepted':
      return [statusDetails.acceptedByYou, statusDetails.pendingFromManager]
    case 'confirmed':
      return [statusDetails.acceptedByYou, statusDetails.approvedByManager]
    case 'denied':
      if (acceptedDate === null) {
        return [statusDetails.deniedByManager]
      } else {
        return [statusDetails.acceptedByYou, statusDetails.deniedByManager]
      }
    case 'declined':
      return [statusDetails.declinedByYou]
    case 'canceled':
      return [statusDetails.withdrawnByStaff]
    default:
      return null
  }
}
