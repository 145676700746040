import { Component } from 'react'
import MaskedInput from 'react-text-mask'
import classNames from 'classnames'
import './CustomTimeInput.scss'

export class CustomTimeInput extends Component {
  static defaultProps = { step: 15 }

  constructor(props) {
    super(props)
    this.state = { options: [], tempValue: null, style: {} }
  }

  componentDidMount() {
    const options = this._composeOptions(this.props)
    this.setState({ options })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const options = this._composeOptions(nextProps)
    this.setState({ options })
  }

  onClick(e) {
    const { onChange } = this.props

    const $li = e.target
    const optionValue = $li.getAttribute('value')

    this.setState({ tempValue: null })
    onChange(optionValue)
    e.preventDefault()
  }

  onKeyDown(e) {
    const { onChange, value } = this.props
    const { options } = this.state
    const currentPosition = options.map((o) => o.value).indexOf(value)
    let modifier
    switch (e.keyCode) {
      case 38: {
        modifier = -1
        break
      }
      case 40: {
        modifier = 1
        break
      }
      case 27: {
        this.setState({ tempValue: null })
        return
      }
      default: {
        return
      }
    }
    this.setState({ tempValue: null })

    const newOption = options[currentPosition + 1 * modifier]

    const newValue = newOption ? newOption.value : value

    onChange(newValue)
  }

  onChange(e) {
    const input = e.currentTarget
    const { value: time, caretPosition } = this._getInputProperties(input)

    const isTimeFormatCorrect = /^\d{2}:\d{2}$/.test(time)

    if (!isTimeFormatCorrect) {
      e.currentTarget.setSelectionRange(caretPosition, caretPosition)
      return this.setState({ tempValue: time })
    }

    this.setState({ tempValue: null })

    const { onChange, date, minValue, maxValue, timeService } = this.props

    const getDateTime = (time, date) => {
      return date && timeService.applyTimeToDate(time, date)
    }

    const isTimeInValueDate = (time) => minValue && maxValue && time.isBetween(minValue, maxValue, 'seconds', '[]')

    const timeInMinValueDate = getDateTime(time, minValue)
    if (isTimeInValueDate(timeInMinValueDate)) {
      const newValue = timeInMinValueDate.toISOString()
      return onChange(newValue)
    }

    const timeInMaxValueDate = getDateTime(time, maxValue)
    if (isTimeInValueDate(timeInMaxValueDate)) {
      const newValue = timeInMaxValueDate.toISOString()
      return onChange(newValue)
    }

    let timeInDate = getDateTime(time, date)
    if (minValue && maxValue && !timeInDate.isBetween(minValue, maxValue, '[]')) {
      if (timeInDate.isBefore(minValue)) {
        timeInDate = timeService.timeMoment(minValue)
      }

      if (timeInDate.isAfter(maxValue)) {
        timeInDate = timeService.timeMoment(maxValue)
      }
    }

    if (minValue && timeInDate.isBefore(minValue)) {
      timeInDate = timeInMinValueDate.clone().add(1, 'day')
    }

    if (maxValue && timeInDate.isAfter(maxValue)) {
      timeInDate = timeInMaxValueDate.clone().add(-1, 'day')
    }

    const newValue = timeInDate.toISOString()
    return onChange(newValue)
  }

  onBlur() {
    this.setState({ tempValue: null })
    this.setState({ style: { display: 'none' } })
  }

  onFocus(event) {
    event.target.select()
    this.setState({ style: { display: 'block' } })
  }

  render() {
    const { valueShort, propText, propValue, disabled, readOnly } = this.props
    const { options, tempValue } = this.state
    const value = tempValue !== null ? tempValue : valueShort
    const mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]

    return (
      <div data-testid="hx-time-input-container" className="hx-time-input-container w100">
        <MaskedInput
          mask={mask}
          guide={true}
          placeholderChar={'\u2000'}
          keepCharPositions={true}
          disabled={disabled}
          showMask={true}
          readOnly={readOnly}
          onChange={this.onChange.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus.bind(this)}
          value={value}
        />
        <ul onMouseDown={this.onClick.bind(this)} style={{ ...this.state.style }}>
          {options.map((opt, index) => {
            return (
              <li
                value={opt[propValue] || opt.value || opt.id}
                key={index}
                className={classNames({
                  selected: opt.selected,
                  delimiter: opt.delimiter
                })}
              >
                {opt[propText] || opt.name}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  _composeOptions(properties) {
    const { value } = properties
    const availableValues = this._getOptionsValues(properties)
    return availableValues.map(this._buildOption(value))
  }

  _getOptionsValues({ value, step, minValue, maxValue, timeService }) {
    const firstOption = this._getFirstOptionValue(value, step, timeService)
    const isBetweenSteps = this._isBetweenSteps(value, step, timeService)

    const sizeOfList = isBetweenSteps ? 4 : 100
    const defaultList = isBetweenSteps ? [timeService.timeMoment(value || null)] : []

    const isDateInListOfMoments = (dateTime, list) => {
      const dateTimeISO = dateTime.toISOString()
      return list.find((item) => item.toISOString() === dateTimeISO)
    }

    const values = Array(sizeOfList)
      .fill()
      .reduce((memo, a, index) => {
        const offsetInMinutes = index * step
        let optionValue = firstOption.clone().add(offsetInMinutes, 'minutes')

        if (minValue && optionValue.isBefore(minValue)) {
          optionValue = timeService.timeMoment(minValue)
        }

        if (maxValue && optionValue.isAfter(maxValue)) {
          optionValue = timeService.timeMoment(maxValue)
        }

        if (isDateInListOfMoments(optionValue, memo)) {
          return memo
        }

        return memo.concat(optionValue)
      }, defaultList)

    values.sort((a, b) => Number(a) - Number(b))

    return values
  }

  _buildOption = (selectedValue) => {
    return (option, index, options) => {
      const delimiter = !!index && !option.isSame(options[index - 1], 'day')

      const optionValue = option.toISOString()
      const selected = optionValue === selectedValue

      return {
        name: option.format('HH:mm'),
        value: optionValue,
        selected,
        delimiter
      }
    }
  }

  _isBetweenSteps(value, step, timeService) {
    const valueMoment = timeService.timeMoment(value || null)
    const minutes = valueMoment.minutes() || 60
    const minutesToStepModulo = minutes % step

    return minutesToStepModulo !== 0
  }

  _getFirstOptionValue(value, step, timeService) {
    const valueMoment = timeService.timeMoment(value || null)
    const minutes = valueMoment.minutes() || 60

    const minutesToStepModulo = minutes % step

    if (!this._isBetweenSteps(value, step, timeService)) {
      return valueMoment.clone().add(-step * 2, 'minutes')
    }

    return valueMoment
      .clone()
      .add(step - minutesToStepModulo, 'minutes')
      .add(-step * 2, 'minutes')
  }

  _getInputProperties = (input) => {
    let { value, selectionEnd: caretPosition } = input

    const autoFormatHours = (time) => {
      const [hoursString, minutesString] = time.split(':')
      const hours = Number(hoursString)
      if (hours > 23) {
        return `00:${minutesString}`
      }

      return time
    }

    value = autoFormatHours(value.toUpperCase())

    return { value, caretPosition }
  }
}

export default CustomTimeInput
