import { Component } from 'react'
import { EmploymentTypesSelector, GenericSelector, ShiftTypesSelector, SwitchInput } from 'Manager/components'
import { List, Map } from 'immutable'
import { startCase, capitalize, min } from 'lodash'

export default class StaffFilter extends Component {
  state = { search: '' }

  onChangeKeyword = (event) => {
    const { value } = event.target
    this.setState({ search: value }, () => {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => this.props.openShiftController.onSearchChange(value), 350)
    })
  }

  render() {
    const { openShiftController, unitShiftTypes, eligibleUnits: units, expertises } = this.props
    const { search } = this.state
    const expertiseItems = expertises.get('expertiseItems')
    const { staffFilter, updateFilter, openShift } = openShiftController
    const { expertiseRequirements } = openShift
    const mappedRequirements = expertiseRequirements
      .filter((item) => expertiseItems.keySeq().includes(item.get('expertiseId')))
      .map((item) => {
        const id = item.get('expertiseId')
        const expertise = expertiseItems.get(id).get(0)
        const type = expertise.get('type')
        let label = Map()
        switch (type) {
          case 'skill': {
            const eligibleScaleIndexes = item.get('eligibleScaleIndexes', List()).toArray()
            const scaleIndex = min(eligibleScaleIndexes)
            const scaleItems = expertise.get('scaleItems')
            const scaleName = scaleItems[scaleIndex ? scaleIndex : 0]
            label = label.merge({
              name: expertise.get('name'),
              description: startCase(capitalize(scaleName))
            })
            break
          }
          case 'equipment': {
            const eligibleScaleIndexes = item.get('eligibleScaleIndexes', List()).toArray()
            const scaleIndex = min(eligibleScaleIndexes)
            const scaleItems = expertise.get('scaleItems')
            const scaleName = scaleItems[scaleIndex ? scaleIndex : 0]
            label = label.merge({
              name: expertise.get('fullName'),
              description: startCase(capitalize(scaleName))
            })
            break
          }
          default:
            label = label.merge({
              name: expertise.get('name')
            })
        }

        return Map({
          id,
          name: label,
          type: capitalize(type)
        })
      })
    const employmentTypesSelectorProps = {
      showHist: false,
      values: staffFilter.get('employmentTypes'),
      setEmploymentType: (value) => updateFilter('employmentTypes', value)
    }

    const shiftTypesSelectorProps = {
      showHist: false,
      shiftTypes: unitShiftTypes || [],
      values: staffFilter.get('shiftTypes'),
      setShiftType: (value) => updateFilter('shiftTypes', value)
    }

    const expertiseSelectorProps = {
      showHist: false,
      options: mappedRequirements || [],
      values: staffFilter.get('expertises'),
      setConfig: (value) => updateFilter('expertises', value),
      deselectAllAllowed: true,
      grouped: true,
      groupByField: 'type',
      fieldName: 'Shift Requirements',
      field: 'expertises',
      hidden: true
    }

    const unitSelectorProps = {
      showHist: false,
      options: units || List(),
      values: staffFilter.get('units'),
      setConfig: (value) => updateFilter('units', value),
      fieldName: 'Units',
      field: 'unit'
    }
    const selectedUnits = staffFilter.get('units')
    const unitRoles = units
      .filter((unit) => selectedUnits.includes(unit.get('id')))
      .map((item) => item.get('roles'))
      .flatten(1)
    const shiftRoleSelectorProps = {
      showHist: false,
      options: unitRoles,
      values: staffFilter.get('shiftRoles'),
      setConfig: (value) => updateFilter('shiftRoles', value),
      fieldName: 'Shift Roles',
      field: 'shiftRole'
    }
    /* Uncomment after getting final requirements and add appropriate input
    const bestMatchProps = {
      value: staffFilter.get('bestMatch'),
      setConfig: value => updateFilter('bestMatch', value),
      fieldName: 'Best Possible Match',
      field: 'bestMatch',
      switchClasses: 'hx-filters'
    };
    */
    const overtimeProps = {
      value: staffFilter.get('overtime'),
      setConfig: (value) => updateFilter('overtime', value),
      fieldName: 'No Overtime',
      field: 'overtime',
      switchClasses: 'hx-filters'
    }
    /* Uncomment after getting final requirements and add appropriate input
    const bonusPayProps = {
      value: staffFilter.get('bonusPay'),
      setConfig: value => updateFilter('bonusPay', value),
      fieldName: 'Bonus Pay',
      field: 'bonusPay',
      switchClasses: 'hx-filters'
    };
    */
    return (
      <div className="hx-open-shift-staff-filter rounded bg-alabaster">
        <div className="filter-bar">
          <EmploymentTypesSelector {...employmentTypesSelectorProps} />
          <ShiftTypesSelector {...shiftTypesSelectorProps} />
          {!expertiseSelectorProps?.hidden && <GenericSelector {...expertiseSelectorProps} />}
          <GenericSelector {...unitSelectorProps} />
          <GenericSelector {...shiftRoleSelectorProps} />
          <div className="spacer" />
          <SwitchInput {...overtimeProps} />
          {/*<SwitchInput {...bonusPayProps} />*/}
        </div>
        <div className="search-bar">
          <i className="icon-zoom regent-gray" />
          <input
            className="bg-alabaster"
            id="searchStaff"
            placeholder="Search staff"
            value={search}
            onChange={this.onChangeKeyword}
          />
        </div>
      </div>
    )
  }
}
