import { Component } from 'react'

class TableRow extends Component {
  render() {
    const { isHeader } = this.props
    const rowType = isHeader ? 'header' : 'row'
    const rowClassName = `row crud-card-table-${rowType}`

    if (isHeader) {
      return <header className={rowClassName}>{this.renderRowItems(rowType)}</header>
    }
    return <div className={rowClassName}>{this.renderRowItems(rowType)}</div>
  }

  renderRowItems(rowType) {
    const { data, slots, id } = this.props
    let widthPercentage = 100 / slots.length
    return slots.map((slot) => {
      if (slot.width) {
        widthPercentage = (slot.width / 12) * 100
      }
      return (
        <div
          data-testid={`crud-card-table-${rowType}-item`}
          className={`crud-card-table-${rowType}-item`}
          key={`${id}_${slot.dataId}`}
          style={{ width: `${widthPercentage}%` }}
        >
          {this.renderRowItem(data[slot.dataId])}
        </div>
      )
    })
  }

  renderRowItem(data) {
    const { formatRowItem, formatHeaderItem, isHeader } = this.props
    if (isHeader) {
      return formatHeaderItem ? formatHeaderItem(data) : <span>{data}</span>
    }
    if (formatRowItem) {
      return formatRowItem(data)
    }
    return typeof data === 'string' ? <sh-text size="body-1">{data}</sh-text> : data
  }
}

export default TableRow
