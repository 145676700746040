import { PureComponent } from 'react'
import { chunk } from 'lodash'
import Months from '../../../../../../../Calendar/Header/ModeFull/Months'
import Week from './Week'
export default class ModeFull extends PureComponent {
  render() {
    const { activeDateRange } = this.props
    const days = activeDateRange.get('days')
    const weeks = chunk(days, 7)

    return (
      <>
        <div className="row auto bg-white text-center bl4 bb1">
          <Months activeDateRange={activeDateRange} />
          {weeks.map((days, i) => (
            <Week days={days} key={i} />
          ))}
        </div>
      </>
    )
  }
}
