import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { allIdns } from 'Admin/services/IdnsService'
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'

const fields = [
  Field('id', 'ID'),
  Field('name', 'IDN  NAME'),
  Field('location', 'Location'),
  Field('createdAt', 'Created')
]

function IDNStore() {
  const actions = fluxStore({ loadDataAction })
  const store = AdminStore({
    fields,
    instanceAdmin: true,
    entityName: 'Idn',
    api: {
      index: 'indexIdns'
    },
    header: { title: 'IDNs' },
    search: { field: 'name', placeholder: 'Search for idns' }
  })

  return {
    ...store,
    ...actions
  }

  async function loadDataAction(state) {
    const api = await store.getApi()
    let idns
    try {
      idns = await allIdns(state, api)
    } catch (_err) {
      idns = [] //TODO: Improve error handling by displaying banner as well
    }
    const { stateKey } = store.getAdminInfo()
    return state.setIn([stateKey, 'list'], idns)
  }
}

export default IDNStore
