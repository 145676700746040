import { Component } from 'react'
import classNames from 'classnames'
import form from '../formDefinitions'
import './CreateEditDetailLink.scss'
import { TextInput } from '../FormComponents'

export default class CreateEditDetailLink extends Component {
  state = {
    inFocus: false
  }

  toggleFocus = () => {
    this.setState((prevState) => {
      return { inFocus: !prevState.inFocus }
    })
  }

  handleChange = ({ target }) => {
    const { activeComponent: component, updateDetailsComponent } = this.props

    const inputUrl = target.value
    component.content[target.name] = inputUrl
    updateDetailsComponent(component)
    if (target.name === 'webAddress') {
      this.setState({ invalidUrl: !this.isValidUrl(inputUrl) })
    }
  }

  isValidUrl = (url) => {
    try {
      new URL(url)
      return true
    } catch (error) {
      return false
    }
  }

  render() {
    const {
      activeComponent: {
        content: { linkTitle, webAddress }
      }
    } = this.props

    const { invalidUrl, inFocus } = this.state

    return (
      <div className="Form">
        <div className="each-field link-title">
          <TextInput {...form.linkTitle} value={linkTitle} onChange={this.handleChange} />
        </div>
        <div
          className={classNames('each-field web-address', { warning: invalidUrl && !inFocus })}
          onBlur={this.toggleFocus}
          onFocus={this.toggleFocus}
        >
          <TextInput value={webAddress} {...form.webAddress} onChange={this.handleChange} />
          {invalidUrl && !inFocus && <div className="warning-text web-address-warning">Please enter a valid URL.</div>}
        </div>
      </div>
    )
  }
}
