import { cloneDeep } from 'lodash'

const weekDaysShort = [
  [
    {
      index: 0,
      value: false,
      label: 'Su'
    },
    {
      index: 1,
      value: false,
      label: 'Mo'
    },
    {
      index: 2,
      value: false,
      label: 'Tu'
    },
    {
      index: 3,
      value: false,
      label: 'We'
    },
    {
      index: 4,
      value: false,
      label: 'Th'
    },
    {
      index: 5,
      value: false,
      label: 'Fr'
    },
    {
      index: 6,
      value: false,
      label: 'Sa'
    }
  ]
]

const weekDaysShortArray = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const weekDaysFullArray = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const weekDaysFull = [
  {
    index: 0,
    value: false,
    label: 'Sunday'
  },
  {
    index: 1,
    value: false,
    label: 'Monday'
  },
  {
    index: 2,
    value: false,
    label: 'Tuesday'
  },
  {
    index: 3,
    value: false,
    label: 'Wednesday'
  },
  {
    index: 4,
    value: false,
    label: 'Thursday'
  },
  {
    index: 5,
    value: false,
    label: 'Friday'
  },
  {
    index: 6,
    value: false,
    label: 'Saturday'
  }
]

const daysList = [
  {
    key: 1,
    name: '1st'
  },
  {
    key: 2,
    name: '2nd'
  },
  {
    key: 3,
    name: '3rd'
  },
  {
    key: 4,
    name: '4th'
  },
  {
    key: 5,
    name: '5th'
  },
  {
    key: 6,
    name: '6th'
  },
  {
    key: 7,
    name: '7th'
  },
  {
    key: 8,
    name: '8th'
  },
  {
    key: 9,
    name: '9th'
  },
  {
    key: 10,
    name: '10th'
  },
  {
    key: 11,
    name: '11th'
  },
  {
    key: 12,
    name: '12th'
  },
  {
    key: 13,
    name: '13th'
  },
  {
    key: 14,
    name: '14th'
  },
  {
    key: 15,
    name: '15th'
  },
  {
    key: 16,
    name: '16th'
  },
  {
    key: 17,
    name: '17th'
  },
  {
    key: 18,
    name: '18th'
  },
  {
    key: 19,
    name: '19th'
  },
  {
    key: 20,
    name: '20th'
  },
  {
    key: 21,
    name: '21st'
  },
  {
    key: 22,
    name: '22nd'
  },
  {
    key: 23,
    name: '23rd'
  },
  {
    key: 24,
    name: '24th'
  },
  {
    key: 25,
    name: '25th'
  },
  {
    key: 26,
    name: '26th'
  },
  {
    key: 27,
    name: '27th'
  },
  {
    key: 28,
    name: '28th'
  },
  {
    key: 29,
    name: '29th'
  },
  {
    key: 30,
    name: '30th'
  },
  {
    key: 31,
    name: '31st'
  }
]
const weekDaysList = [
  {
    key: 1,
    name: '1st'
  },
  {
    key: 2,
    name: '2nd'
  },
  {
    key: 3,
    name: '3rd'
  },
  {
    key: 4,
    name: '4th'
  }
]

const recurringTypes = [
  {
    key: 1,
    name: 'Week(s)'
  },
  {
    key: 2,
    name: 'Month(s)'
  }
]

const mapRecurringTypes = {
  'Week(s)': 'weekly',
  'Month(s)': 'monthly'
}

const constants = {
  timeStep: 15,

  get weekDaysShort() {
    return cloneDeep(weekDaysShort) //to block object modification by importers
  },

  get weekDaysShortArray() {
    return cloneDeep(weekDaysShortArray)
  },

  get weekDaysFull() {
    return cloneDeep(weekDaysFull)
  },

  get weekDaysFullArray() {
    return cloneDeep(weekDaysFullArray)
  },

  get daysList() {
    return cloneDeep(daysList)
  },

  get weekDaysList() {
    return cloneDeep(weekDaysList)
  },

  get recurringTypes() {
    return cloneDeep(recurringTypes)
  },

  get mapRecurringTypes() {
    return cloneDeep(mapRecurringTypes)
  }
}

export default constants
