import { Component } from 'react'
import { Staff } from 'entityWrappers'
import { navigate } from 'Navigation'
import { curry } from 'i18n'

const t = curry('staff.')

export default class StaffProfileHeader extends Component {
  onTransferDepartment = (e) => {
    navigate.from.StaffDetails.to.StaffTransferDepartment()
  }

  onCancelTransfer = async () => {
    const { cancellStaffTransfer, staff, Dialog } = this.props
    const userId = staff.get('userId')

    const isConfirmed = await Dialog.confirm('Do you really want to cancel this staff transfer?', {
      type: 'error',
      accept: t('confirmStaffTransferCancelling')
    })
    if (isConfirmed) {
      cancellStaffTransfer(userId)
    }
  }

  render() {
    const { staff, timeService } = this.props
    const { transferringUnit } = new Staff(staff)

    const navigateToStaffList = () => {
      navigate.from.StaffDetails.to.Staff()
    }

    const canEditEligibleUnits = new Staff(staff).canEditEligibleUnits(timeService)
    const isTransferDepartmentVisible = !transferringUnit && canEditEligibleUnits
    const isCancellTransferVisible = !!transferringUnit

    return (
      <>
        <header className="hx-staff-profile-header bg-white bb1 row">
          <div className="col col-4">
            <button className="float-left shuttle-gray button-back-to-index" onClick={navigateToStaffList}>
              <i className="icon-left" />
              &nbsp;
              <span className="shuttle-gray">Back to Staff</span>
            </button>
          </div>

          <div className="col col-4 row align-middle align-center">
            <span className="text-center">
              <h1>Staff Profile</h1>
            </span>
          </div>
          {isTransferDepartmentVisible && (
            <div className="col col-4 row align-middle align-right pr10">
              <sh-button color="tertiary" size="auto" onClick={this.onTransferDepartment} label="Transfer Department" />
            </div>
          )}
          {isCancellTransferVisible && (
            <div className="col col-4 row align-middle align-right pr10">
              <sh-button color="tertiary" size="auto" onClick={this.onCancelTransfer} label="Cancel Transfer" />
            </div>
          )}
        </header>
      </>
    )
  }
}
