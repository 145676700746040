import { PureComponent } from 'react'
import classNames from 'classnames'
import { PopUp } from 'Manager/components'
import './OpenShiftPopup.scss'
import { withAppContext } from 'AppContext'
import ShiftDayRequirement from './ShiftDayRequirement'

export class OpenShiftPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { popupProps: {}, isLoading: false }
  }

  render() {
    const { openShiftPopupProps } = this.props
    const { isHovered } = this.state

    if (!openShiftPopupProps) {
      return null
    }

    const className = classNames('hx-open-shift-popup _staffMismatch bg-white', {
      hovered: isHovered
    })

    return (
      <PopUp
        {...openShiftPopupProps}
        className={className}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <ShiftDayRequirement {...this.props} />
      </PopUp>
    )
  }

  isHovered = () => this.state.isHovered

  onMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    const { hideOpenShiftPopup } = this.props
    this.setState({ isHovered: false })
    hideOpenShiftPopup()
  }
}
export default withAppContext(OpenShiftPopup)
