import { ReactNode, useRef, useState } from 'react'
import classNames from 'classnames'
import './SpillProofDiv.scss'

export interface ISpillProofDivProps {
  className?: string
  children: ReactNode | ReactNode[]
  id?: string
  tooltipLabel?: string
  tooltipPosition?: string
  hasCustomTooltip?: boolean
  expandOnHover?: boolean
  onMouseEnter?: Function
  onMouseLeave?: Function
}

export default function SpillProofDiv({
  className,
  children,
  id,
  tooltipLabel,
  tooltipPosition,
  hasCustomTooltip,
  expandOnHover,
  onMouseEnter,
  onMouseLeave
}: ISpillProofDivProps) {
  const [isHovered, setIsHovered] = useState(false)
  let finalLabel = 'Overflowed text'
  if (tooltipLabel) {
    finalLabel = tooltipLabel
  } else if (typeof children === 'string') {
    finalLabel = children
  }

  let showTooltip = false
  let toExpand = false
  const textOutput = useRef<HTMLDivElement>(null)
  if (isHovered && textOutput?.current) {
    const { scrollWidth, offsetWidth } = textOutput.current
    if (scrollWidth && offsetWidth && scrollWidth > offsetWidth) {
      if (expandOnHover) {
        toExpand = true
      } else {
        showTooltip = !!id
      }
    }
  }

  let tooltipClassName = ''
  if (hasCustomTooltip) {
    tooltipClassName = 'shui-tooltip-custom'
  }

  let baseClassName = 'spillproof'
  if (className) {
    baseClassName += ` ${className}`
  }

  return (
    <>
      <div
        id={id}
        ref={textOutput}
        className={classNames(baseClassName, { expanded: toExpand })}
        onMouseEnter={(event) => {
          setIsHovered(true)
          onMouseEnter?.(event)
        }}
        onMouseLeave={(event) => {
          setIsHovered(false)
          onMouseLeave?.(event)
        }}
      >
        {children}
      </div>
      {showTooltip && (
        <sh-tooltip target={id} label={finalLabel} placement={tooltipPosition} class={tooltipClassName} visible />
      )}
    </>
  )
}
