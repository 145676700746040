import { Set } from 'immutable'

export type FilterReducerAction =
  | { type: 'add'; payload: string }
  | { type: 'remove'; payload: string }
  | { type: 'set'; payload: Set<string> }
export function filterReducer(selectedCalendarFilters: Set<string>, action: FilterReducerAction): Set<string> {
  switch (action.type) {
    case 'add':
      return selectedCalendarFilters.add(action.payload)
    case 'remove':
      return selectedCalendarFilters.remove(action.payload)
    case 'set':
      return action.payload
  }
}
