import { useEffect, useState } from 'react'
import { curry } from 'i18n'
const t = curry('calendar.autoSchedule.resetSchedule.')

const ResetPopup = ({ algoScheduleStore, unit }) => {
  const unitId = unit.get('id')
  const scheduleId = unit?.get('schedule')?.get('id')
  const [isResetButtonShown, setIsResetButtonShown] = useState(false)
  const [isPopupShown, setIsPopupShown] = useState(false)
  const [isResetBeingApplied, setIsResetBeingApplied] = useState(false)

  useEffect(() => {
    async function fetchData() {
      if (unitId && scheduleId) {
        const { isAlgoOutputApplied } = await algoScheduleStore.readScheduleSnapshot({ scheduleId, unitId })
        setIsResetButtonShown(isAlgoOutputApplied)
      }
    }
    fetchData()
  }, [scheduleId])

  const onClickHandler = () => {
    setIsPopupShown(true)
  }
  const modelCloseHandler = () => {
    setIsPopupShown(false)
  }

  const resetHandler = async () => {
    setIsResetBeingApplied(true)
    setIsPopupShown(false)
    const { isScheduleReset } = await algoScheduleStore.resetAlgoSchedule({ scheduleId, unitId })
    if (isScheduleReset) {
      setIsResetBeingApplied(false)
      //! Reloading page
      window.location.reload()
    } else {
      setIsResetBeingApplied(false)
    }
  }

  return (
    <>
      {isResetBeingApplied && <sh-spinner label={t('resetBeingApplied')} overlay></sh-spinner>}
      {isResetButtonShown && (
        <>
          <button
            data-testid="reset-algo-schedule"
            id="reset-algo-schedule"
            onClick={onClickHandler}
            className="reset-button p0 opacity8"
          >
            <sh-icon icon="reset" size="m"></sh-icon>
          </button>
          <sh-tooltip label="Reset schedule" target="reset-algo-schedule" placement="bottom" variation="short" />
        </>
      )}

      {isPopupShown && (
        <sh-modal sticky id="size-modal" label={t('title')} visible modal-height="200px" modal-width="400px">
          <div className="pl10"> {t('confirmationMessage')}</div>
          <sh-button color="secondary" slot="footer" label="Cancel" onClick={modelCloseHandler}></sh-button>
          <sh-button slot="footer" label="Ok" onClick={resetHandler}></sh-button>
        </sh-modal>
      )}
    </>
  )
}

export default ResetPopup
