import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { navigate, paths } from 'Navigation'
import ShiftTypeCreateUpdate from './ShiftTypeCreateUpdate'

export default class ShiftTypeUpdate extends ShiftTypeCreateUpdate {
  render() {
    const { isUnitsLoaded } = this.controller
    if (!isUnitsLoaded) {
      return null
    }

    const { store, appState, match } = this.props
    const url = this.props.location.search

    const { unitId } = queryString.parse(url)
    if (!unitId) {
      return <Redirect to={paths.AdminShiftTypes} />
    }

    const { urlId, id } = match.params

    const timeService = appState.getIn(['context', 'facilityTime'])

    const onFormSubmit = async (event, form) => {
      try {
        await this.controller.updateUnitShiftType(unitId, id, form)
        await this.controller.loadUnits()
        return navigate.to.AdminShiftTypes({ urlId })
      } catch {
        return navigate.to.AdminShiftTypes({ urlId })
      }
    }
    const { properties: definition } = store.getInputDefinition('OverrideUnitShiftTypeInput')

    const shiftType = this.controller.getUnitShiftType(unitId, id)

    if (!shiftType) {
      return null
    }

    const inputDefinition = { properties: { title: definition.title } }

    return this.renderModal(onFormSubmit, inputDefinition, shiftType, timeService, 'update', 'ShiftType')
  }

  onSubmit = (e, form) => {
    // This is intentional
  }
}
