import { fromJS } from 'immutable'
import { NotesController } from './Note'

const emptyNote = { text: '', subject: '', isEditMode: true }

export default class TimeOffNotesController extends NotesController {
  get defaultState() {
    return {
      ...super.defaultState,
      StaffPaidTimeOffNotes: fromJS([emptyNote]),
      StaffUnavailabilityNotes: fromJS([emptyNote])
    }
  }

  get StaffPaidTimeOffNotes() {
    return this.state.StaffPaidTimeOffNotes
  }

  get StaffUnavailabilityNotes() {
    return this.state.StaffUnavailabilityNotes
  }

  get note() {
    const { lastNote } = this

    if (!lastNote) {
      return null
    }

    const subject = lastNote.get('subject')
    const text = lastNote.get('text')

    return { subject, text }
  }

  set StaffPaidTimeOffNotes(notes) {
    this.setState({ StaffPaidTimeOffNotes: notes })
  }

  set StaffUnavailabilityNotes(notes) {
    this.setState({ StaffUnavailabilityNotes: notes })
  }

  buildNotesState(notes, documentType) {
    const notesState = notes && notes.size > 0 ? notes : fromJS([emptyNote])
    const isPTO = documentType === 'StaffPaidTimeOff'
    const isUnavailable = documentType === 'StaffUnavailability'

    if (isPTO) {
      return { ...this.defaultState, StaffPaidTimeOffNotes: notesState }
    }

    if (isUnavailable) {
      return { ...this.defaultState, StaffUnavailabilityNotes: notesState }
    }

    return this.defaultState
  }
}
