import { List, Map, Repeat } from 'immutable'
import { FacilityUserShiftsService, ShiftService } from 'services'
import { mockShiftDays } from 'utils'

export default LoadFlatCalendar()

function LoadFlatCalendar() {
  let updateCalendar = null
  let instanceUri = null
  let timeService = null
  let buildStaffWithCells = null

  const actions = { loadFlatCalendar }
  return { initialize, actions }

  function initialize(context) {
    ;({ updateCalendar, instanceUri, facilityTime: timeService, buildStaffWithCells } = context)
  }

  async function loadFlatCalendar(unit, dateRange) {
    const days = dateRange.get('days')
    const unitId = unit.get('id')
    let roles = unit.get('roles')
    let shifts = unit.get('shifts')
    const facilityUsers = dateRange.get('facilityUsers')
    const startDate = dateRange.get('startsAt')
    const endDate = dateRange.get('endsAt')

    const facilityUsersByShiftId = FacilityUserShiftsService.usersByShiftId(
      startDate,
      endDate,
      unitId,
      facilityUsers,
      shifts,
      timeService
    )

    ;({ roles, shifts } = extendWithSecondaryStaff(roles, shifts))

    const facilityUserIdsByShiftId = facilityUsersByShiftId.map((shiftUsers) =>
      shiftUsers.map((user) => user.get('userId'))
    )

    const shiftIdsByFacilityUserId = FacilityUserShiftsService.shiftIdsByUserId(facilityUserIdsByShiftId)

    const shiftsByRoleId = shifts.groupBy((shift) => shift.get('unitRoleId'))
    const flat = initFlatCalendar(roles, shiftsByRoleId, days, facilityUsersByShiftId)

    return updateCalendar({
      ...flat,
      days,
      facilityUserIdsByShiftId,
      shiftIdsByFacilityUserId,
      dateRange,
      daysData: {},
      isOtherStaffLoaded: false,
      otherStaff: List()
    })
  }

  function extendWithSecondaryStaff(roles, shifts) {
    const secondaryStaffRole = Map({
      id: 'secondary-staff',
      isOtherStaff: true,
      name: 'Other Staff'
    })
    roles = roles.push(secondaryStaffRole)

    const secondaryStaffShift = Map({
      id: 'secondary-staff-shift',
      hidden: true,
      isOtherStaff: true,
      unitRoleId: secondaryStaffRole.get('id')
    })
    shifts = shifts.push(secondaryStaffShift)

    return { roles, shifts }
  }

  function initFlatCalendar(roles, shiftsByRoleId, days, facilityUserByShiftId) {
    const shiftDays = mockShiftDays(days.length)
    const cells = List(Repeat(Map(), days.length))

    const rolesExtended = roles.map((role, roleIndex) =>
      mapRole(role, roleIndex, shiftsByRoleId, shiftDays, facilityUserByShiftId, cells)
    )

    return { roles: rolesExtended }
  }

  function mapRole(role, roleIndex, shiftsByRoleId, shiftDays, facilityUserByShiftId, cells) {
    const roleId = role.get('id')
    const roleShifts = shiftsByRoleId.get(roleId) || List()

    return role.merge({
      roleIndex,
      kind: 'role',
      shifts: roleShifts.map((shift, shiftIndex) =>
        mapShift(shift, shiftDays, roleIndex, shiftIndex, facilityUserByShiftId, cells)
      )
    })
  }

  function mapShift(shift, shiftDays, roleIndex, shiftIndex, facilityUserByShiftId, cells) {
    const shiftId = shift.get('id')
    const facilityUsers = facilityUserByShiftId.get(shiftId) || List()
    const staff = buildStaffWithCells(facilityUsers, cells, roleIndex, shiftIndex, shiftId, instanceUri)

    if (!shift.get('hidden')) {
      const shiftService = new ShiftService(shift, timeService)
      const shiftTime = shiftService.getShiftTime()
      shift = shift.set('range', shiftTime)
    }

    return shift.merge({
      primaryStaff: staff,
      staff,
      shiftViewRows: [],
      roleIndex,
      shiftIndex,
      kind: 'shift',
      isLoaded: false,
      shiftDays
    })
  }
}
