export { allIdns }

async function allIdns(appState, api) {
  const instance = appState.getIn(['authentication', 'instance'])
  const idns = await getInstanceIdns(instance, api.indexIdns)

  return Array.prototype.concat.apply([], idns)
}

function getInstanceIdns(instance, indexIdns) {
  const { id, name } = instance
  const mapper = (idn) => ({
    ...idn,
    instanceId: id,
    instanceName: name,
    fullName: `${name} - ${idn.name}`
  })

  return indexIdns().then((instanceIdns) => instanceIdns.map(mapper))
}
