import { PureComponent } from 'react'
import { throttle } from 'lodash'
import { findParentElement } from 'utils'
import './CrossHatch.scss'

export default class CrossHatch extends PureComponent {
  constructor(props) {
    super(props)
    this.throttle = throttle(this.throttleUpdatePosition)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.hideVertical)
    this.$vertical = document.getElementById('cross-hatch-vertical')
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideVertical)
  }

  render() {
    let { styleVertical = {} } = this.props
    styleVertical = { ...styleVertical, display: 'none' }

    return (
      <>
        <div style={styleVertical} id="cross-hatch-vertical" />
      </>
    )
  }

  updatePosition(e) {
    this.throttle(e)
  }

  throttleUpdatePosition(e) {
    let $target

    $target = findParentElement(e.target, 'classList', '_cell')
    const isCellHovered = !!$target

    if (isCellHovered) {
      return this.updateStyles(false, $target)
    }

    $target = findParentElement(e.target, 'classList', '_staffProfile')
    const isStaffProfileHovered = !!$target

    if (isStaffProfileHovered) {
      return this.updateStyles(true, $target)
    }

    $target = findParentElement(e.target, 'classList', '_headerCell')
    const isHeaderCellHovered = !!$target

    if (isHeaderCellHovered) {
      return this.updateStyles(false, $target)
    }

    const isHeaderWeekHovered = e.target.classList.contains('hx-calendar-header-week')

    const isRoleHeaderHovered =
      e.target.nodeName === 'SECTION' && findParentElement(e.target, 'classList', 'hx-calendar-group-container')

    const isShiftHeaderHovered = e.target.nodeName === 'HEADER' && e.target.classList.contains('hx-calendar-shift')

    const isWeekCells =
      findParentElement(e.target, 'classList', '_weekCells') || findParentElement(e.target, 'classList', '_staff')

    const isShiftDayHovered = e.target.classList.contains('_shift')

    const isDividerHovered = e.target.classList.contains('divider') || e.target.classList.contains('staff-divider')

    const isRowHovered = e.target.classList.contains('row')

    if (
      isWeekCells ||
      isShiftHeaderHovered ||
      isShiftDayHovered ||
      isDividerHovered ||
      isRowHovered ||
      isHeaderWeekHovered ||
      isRoleHeaderHovered
    ) {
      return
    }

    return this.hide()
  }

  updateStyles(isVerticalHidden, $target) {
    const { width } = $target.getBoundingClientRect()
    const left = $target.offsetLeft
    this.updateVerticalStyles(left, width, !isVerticalHidden)
  }

  updateVerticalStyles(left = 0, width = 0, display = false) {
    // const $el = document.getElementById('cross-hatch-vertical');
    this.$vertical.style.display = display ? '' : 'none'
    if (display) {
      this.$vertical.style.transform = `translate3d(${left - 1}px, 0, 0)`
      this.$vertical.style.width = `${width + 1}px`
    }
  }

  hide = () => {
    this.updateVerticalStyles()
  }

  hideVertical = () => {
    this.updateVerticalStyles()
  }
}
