import { List } from 'immutable'

export default class DummyShiftDaysBuilder {
  constructor(calendar) {
    this.calendar = calendar
  }

  buildDummyShiftDays(action, selection, data) {
    const { isTimeOff } = data
    const { cells, staffEventShiftDays, staffEventShiftId } = selection

    if (isTimeOff) {
      return List()
    }

    const isDeleteAction = action === 'delete'
    if (isDeleteAction) {
      return this._buildDummyShiftDaysForDeleteAction(cells, staffEventShiftDays)
    }

    const isCreateAction = action === 'create'
    if (isCreateAction) {
      return this._buildDummyShiftDaysForCreateAction(cells, data)
    }

    const isChangeAction = action === 'change'
    if (isChangeAction) {
      return this._buildDummyShiftDaysForChangeAction(cells, staffEventShiftDays, staffEventShiftId, data)
    }

    const isUpdateAction = action === 'update'
    if (isUpdateAction) {
      return List()
    }
  }

  _buildDummyShiftDaysForDeleteAction(cells, staffEventShiftDays) {
    return staffEventShiftDays.map((shiftDay, index) => {
      const isAssignment = cells.getIn([index, 'isAssignment'])
      const isOnCall = cells.getIn([index, 'isOnCall'])
      if (!isAssignment && !isOnCall) {
        return shiftDay
      }

      const requestsCount = (shiftDay.get('requestsCount') || 0) + 1
      const mismatchDelta = 1
      const staffMismatch = shiftDay.get('staffMismatch') + mismatchDelta

      return shiftDay.merge({ staffMismatch, mismatchDelta, requestsCount })
    })
  }

  _buildDummyShiftDaysForCreateAction(cells, { staffEventShiftId }) {
    const shift = this.calendar.findShiftById(staffEventShiftId)
    if (!shift) {
      return List()
    }

    const roleIndex = shift.get('roleIndex')
    const shiftIndex = shift.get('shiftIndex')

    return cells.map((cell, shiftDayIndex) => {
      const shiftDayPath = { roleIndex, shiftIndex, shiftDayIndex }
      const shiftDay = this.calendar.getShiftDay(shiftDayPath)
      if (!shiftDay) {
        return shiftDay
      }

      const requestsCount = (shiftDay.get('requestsCount') || 0) + 1
      const mismatchDelta = -1
      const staffMismatch = shiftDay.get('staffMismatch') + mismatchDelta

      return shiftDay.merge({
        staffMismatch,
        mismatchDelta,
        requestsCount,
        path: shiftDayPath
      })
    })
  }

  _buildDummyShiftDaysForChangeAction(cells, staffEventShiftDays, prevEventShiftId, { staffEventShiftId }) {
    if (prevEventShiftId === staffEventShiftId) {
      return List()
    }

    const newEventShift = this.calendar.findShiftById(staffEventShiftId)
    const newEventRoleIndex = newEventShift?.get('roleIndex')
    const newEventShiftIndex = newEventShift?.get('shiftIndex')
    const newEventShiftPath = newEventShift && {
      roleIndex: newEventRoleIndex,
      shiftIndex: newEventShiftIndex
    }

    const prevEventShift = this.calendar.findShiftById(prevEventShiftId)
    const prevEventRoleIndex = prevEventShift?.get('roleIndex')
    const prevEventShiftIndex = prevEventShift?.get('shiftIndex')
    const prevEventShiftPath = prevEventShift && {
      roleIndex: prevEventRoleIndex,
      shiftIndex: prevEventShiftIndex
    }

    return cells.reduce((memo, cell, shiftDayIndex) => {
      if (newEventShiftPath) {
        const path = { ...newEventShiftPath, shiftDayIndex }
        const newEventShiftDay = this.calendar.getShiftDay(path)
        const mismatchDelta = -1
        const staffMismatch = newEventShiftDay.get('staffMismatch') + mismatchDelta
        const requestsCount = (newEventShiftDay.get('requestsCount') || 0) + 1

        const shiftDay = newEventShiftDay.merge({
          path,
          mismatchDelta,
          requestsCount,
          staffMismatch
        })

        memo = memo.push(shiftDay)
      }

      if (prevEventShift) {
        const path = { ...prevEventShiftPath, shiftDayIndex }
        const prevEventShiftDay = this.calendar.getShiftDay(path)
        const mismatchDelta = 1
        const staffMismatch = prevEventShiftDay.get('staffMismatch') + mismatchDelta
        const requestsCount = (prevEventShiftDay.get('requestsCount') || 0) + 1

        const shiftDay = prevEventShiftDay.merge({
          path,
          mismatchDelta,
          requestsCount,
          staffMismatch
        })

        memo = memo.push(shiftDay)
      }

      return memo
    }, List())
  }
}
