import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import { navigate, paths } from 'Navigation'
import { matchPath } from 'react-router-dom'
import { curry, t as tGlobal } from 'i18n'
import stores, { addSuccessBanner } from 'stores'
import { Prompt } from 'react-router'

const { announcementsStore } = stores

const t = curry('announcements.confirmations.exit.')
const config = {
  empty: {
    title: tGlobal('close'),
    isConfirmationRequired: false
  },
  filled: {
    title: tGlobal('cancel'),
    confirmation: {
      message: t('disclosure'),
      options: {
        title: t('title'),
        accept: t('options.accept'),
        cancel: t('options.cancel'),
        left: t('options.left'),
        type: 'warning'
      }
    },
    isConfirmationRequired: true
  }
}

export class CancelAnnouncementForm extends PureComponent {
  state = {
    canLeave: false,
    isSpinnerShown: false
  }

  handleBlockedNavigation = ({ pathname }) => {
    const { isFormDirty } = this.props
    const { canLeave } = this.state

    if (isFormDirty && !canLeave) {
      this.exitOnClick(pathname)
      return false
    }
    return true
  }

  exitOnClick = async (navigateToPath) => {
    const { Dialog, announcementId, isFormDirty } = this.props
    const { confirmation, isConfirmationRequired } = config[isFormDirty ? 'filled' : 'empty']
    if (!isConfirmationRequired) {
      return navigate.from.FacilityUnitHome.to.Announcements({})
    }
    const response = await Dialog.select(confirmation.message, confirmation.options)

    switch (response) {
      case 'left': // "Cancel" button
        return false
      case true: // "Save" button
        return this.saveAnnouncementDraft(announcementId)
      case false:
      default: {
        // "Discard" button
        let matchParams = null
        if (navigateToPath) {
          matchParams = matchPath(global.location.pathname, paths.Announcements)
        } else {
          navigateToPath = navigate.from.FacilityUnitHome.to.Announcements(null, false)
        }

        return this.setState({ canLeave: true }, navigate.bind(null, navigateToPath, matchParams))
      }
    }
  }

  render() {
    const { isSpinnerShown } = this.state
    const { preventNavigation } = this.props

    return (
      <>
        <Prompt when={preventNavigation} message={this.handleBlockedNavigation} />
        <sh-button
          onClick={() => this.exitOnClick(null)}
          class="cancel-announcement-form"
          color="secondary"
          disabled={isSpinnerShown ? true : undefined}
          label="Exit"
        />
      </>
    )
  }

  saveAnnouncementDraft = async (announcementId) => {
    const {
      subject,
      subtitle,
      content,
      location,
      badgeUrl,
      unitId,
      hasDetailsPage,
      detailsComponents,
      detailsHtml,
      formatDetailsComponents
    } = this.props

    this.setState({ isSpinnerShown: true })
    const parameters = {
      subject,
      subtitle,
      content,
      location,
      badgeUrl,
      status: 'draft',
      detailsComponents: hasDetailsPage ? formatDetailsComponents(detailsComponents) : null,
      detailsHtml: hasDetailsPage ? detailsHtml : null,
      unitIds: [unitId]
    }

    const saveAnnouncement = announcementId
      ? await announcementsStore.updateAnnouncement(announcementId, parameters)
      : await announcementsStore.createAnnouncement(parameters)

    if (!('error' in saveAnnouncement)) {
      addSuccessBanner({ message: tGlobal('announcements.banners.announcement.update_success') })
    }
    this.setState({ isSpinnerShown: false, canLeave: true }, navigate.from.FacilityUnitHome.to.Announcements)
  }
}

export default withAppContext(CancelAnnouncementForm)
