import { PureComponent } from 'react'
import '../ModeFull/Week.scss'

const weekViewDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export default class Week extends PureComponent {
  render() {
    const { width, days } = this.props

    const mapper = (day, index) => {
      const { dayOfMonth } = day
      const weekDay = weekViewDays[index]

      const className = 'col-1 _headerCell white large'
      const style = { alignItems: 'flex-start' }

      return (
        <div key={index} className={className} style={style}>
          <div className="bold">{dayOfMonth}</div>
          <div style={{ marginLeft: '10px' }}>{weekDay}</div>
        </div>
      )
    }

    return (
      <div
        className="bl1"
        style={{
          display: 'inline-block',
          width
        }}
      >
        <div className="row-7">{days.map(mapper)}</div>
      </div>
    )
  }
}
