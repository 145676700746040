import { PureComponent } from 'react'
import classNames from 'classnames'
import { DateRangeState } from 'Manager/components'
import { navigate } from 'Navigation'

export default class DateRange extends PureComponent {
  render() {
    const { dateRange } = this.props
    const { titleWithoutYear, isPast, state, endsAt, firstDayUsDate, isActive, endYear, days } = dateRange.toJS()
    const endYearIsDifferent = !dateRange.get('endYearIsCurrentYear')

    const day = days?.filter((day) => day.isToday)[0]
    const navigationDateRange = day?.usDate ? day.usDate : firstDayUsDate
    return (
      <section
        className={classNames('date-range-container rounded', {
          active: isActive,
          'half-opacity': isPast
        })}
        onClick={async () => {
          await this.navigateToDateRange(navigationDateRange)
        }}
      >
        <div className="text-center year">{endYearIsDifferent && endYear}</div>
        <div className="text-center bold">{titleWithoutYear}</div>
        <div className="text-center">{<DateRangeState state={state} endDate={endsAt} />}</div>
      </section>
    )
  }

  navigateToDateRange = async (date) => {
    const { hideDateRange, shouldAllowScheduleChange } = this.props
    if (await shouldAllowScheduleChange()) {
      hideDateRange()
      navigate.replace({ date })
    }
  }
}
