import { forEach } from 'lodash'

const sideGlyphMap = {
  checkmark: '&#xe900;',
  'checkmark+': '&#xe91f;',
  'timeoff+note': '&#xe901;',
  timeoff: '&#xe902;',
  'star+note': '&#xe903;',
  star: '&#xe904;',
  note: '&#xe905;',
  lock: '&#xe906;',
  gap: '&#xe912;',
  reset: '&#xe913;',
  info: '&#xe914;',
  'info+note': '&#xe915;'
}

const staffGlyphMap = {
  dot: '&#xe910;',
  circle: '&#xe90d;',
  pin: '&#xe91c;',
  checkmark: '&#xe908;',
  'timeoff+note': '&#xe901;',
  timeoff: '&#xe902;',
  'star+note': '&#xe903;',
  star: '&#xe904;',
  note: '&#xe905;',
  lock: '&#xe917;',
  gap: '&#xe91d;',
  'money-sign': '&#xe90a;'
}

const middleGlyphMap = {
  unavailability: '&#xe908;',
  'faded-dot': '&#xe909;',
  'money-sign': '&#xe90a;',
  'faded-crossed-circle': '&#xe90b;',
  'faded-circle': '&#xe90c;',
  event: '&#xe90d;',
  dot: '&#xe90e;',
  'crossed-dot': '&#xe90f;',
  'crossed-circle': '&#xe910;',
  circle: '&#xe911;',
  pin: '&#xe907;',
  'primary-partial': '&#xe916;',
  'secondary-partial': '&#xe917;',
  'on-call': '&#xe918;',

  // Adding Multiple shift Icons
  'multiple-shifts': '&#xe919;',
  'on-call-with-single-shift': '&#xe91A;',
  'on-call-with-multiple-shift': '&#xe91B;',
  'cancelled-multiple-shifts': '&#xe91C;',
  'on-call-with-cancelled-single-shifts': '&#xe91D;',
  'on-call-with-cancelled-multiple-shifts': '&#xe91E;',
  'patient-scan': '&#xe920;'
}

const div = document.createElement('div')
forEach(sideGlyphMap, (value, key) => {
  div.innerHTML = value
  sideGlyphMap[key] = div.textContent
})

forEach(middleGlyphMap, (value, key) => {
  div.innerHTML = value
  middleGlyphMap[key] = div.textContent
})

forEach(staffGlyphMap, (value, key) => {
  div.innerHTML = value
  staffGlyphMap[key] = div.textContent
})
div.remove?.()

export { sideGlyphMap, middleGlyphMap, staffGlyphMap }
