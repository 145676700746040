import 'core-js'
import { Sentry } from 'services'
import { createRoot } from 'react-dom/client'
import './styles/web_v3/web.css'
import './styles/web_v3/index.css'
import './index.scss'
import HumanicsApp from 'HumanicsApp'
import { authenticationStore, GlobalAccountsService, Instance, stores } from 'stores'
import registerServiceWorker from './registerServiceWorker'
import { RedirectToGAS } from 'auth/RedirectToGAS'
import { onError } from '@humanics/he-react-common/lib/stores/fluxStore'
import { handleError } from 'services/ErrorHandler'
import { getFacilityUrlId, getGASRedirect, getGasUri, getInstanceUri } from 'services/IndexService'
import '@shui/core/styles/fonts.css'
import '@shui/core'

onError(handleError)

const { location } = document
const gasRedirect = getGASRedirect(location)
if (gasRedirect) {
  RedirectToGAS(gasRedirect)
} else if (location.pathname.startsWith('/api')) {
  reloadWithoutServiceWorker()
} else {
  runApp()
}

function reloadWithoutServiceWorker() {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then((reg) => reg.unregister()).then(() => location.reload())
  }
}

async function initializeApp() {
  const { pathname } = location
  const gasUri = getGasUri(location)
  const instanceUri = getInstanceUri(location)
  const facilityUrlId = getFacilityUrlId(pathname)

  const gas = new GlobalAccountsService(gasUri)
  await gas.initialize()

  const instance = new Instance(instanceUri, gas.getSessionValidator)

  const authStore = authenticationStore(gas, instance, facilityUrlId)
  await authStore.authorize()

  return { gas, instance, stores: { ...stores, authStore } }
}

async function runApp() {
  const { host } = location
  Sentry.initialize({ environment: host })

  const { gas, stores } = await initializeApp()
  const { uri: accountsURI } = gas
  const appRoot = createRoot(document.getElementById('root'))

  appRoot.render(<HumanicsApp stores={stores} accountsURI={accountsURI} />)
  // .catch(err => <ErrorApp error={err} />)

  registerServiceWorker()
}
