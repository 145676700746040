import Header from './Header'
import Item from './Item'
import { TimeOffDefinitionsService } from 'services'

export default function TimeOffsSubmenu({ popupProps = {}, onClickHandler, timeOffEventVaraints }) {
  const { targetElement = { offsetLeft: 0 }, width } = popupProps
  const maxWidth = window.innerWidth - width
  const left = targetElement.offsetLeft < maxWidth ? width : -1 * width + 1
  const style = { left: `${left}px` }

  const ptoVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'paidTimeOff')
  const roVariant = timeOffEventVaraints.find((variant) => variant.get('id') === 'requestOff')

  const paidTimeOffOptions = TimeOffDefinitionsService.buildPaidTimeOffOptions()
  const unavailabilityOptions = TimeOffDefinitionsService.buildUnavailabilityOptions()

  const ptoHeaderProps = {
    isDisabled: false,
    title: 'Paid Time Off',
    iconClassName: 'icon-Money-Sign',
    iconWrapperClassName: 'rock-blue bg-athensgray',
    onClick: onClickHandler(ptoVariant, '')
  }

  const requestOffHeaderProps = {
    isDisabled: false,
    title: 'Request Off',
    iconClassName: 'icon-close',
    iconWrapperClassName: 'rock-blue bg-athensgray',
    onClick: onClickHandler(roVariant, '')
  }

  return (
    <div data-testid="time-offs-submenu" className="submenu" style={style}>
      <Header {...ptoHeaderProps} />
      {paidTimeOffOptions.map((option, optionIndex) => (
        <Item key={optionIndex} title={option} className="time-off-item" onClick={onClickHandler(ptoVariant, option)} />
      ))}
      <hr />
      <Header {...requestOffHeaderProps} />
      {unavailabilityOptions.map((option, optionIndex) => (
        <Item key={optionIndex} title={option} className="time-off-item" onClick={onClickHandler(roVariant, option)} />
      ))}
    </div>
  )
}
