import { ComponentController } from 'Common/components'
import { Calendar } from 'entityWrappers'
import { getAppropriateStore } from '../layoutUtils'

export default class CellDetailsController extends ComponentController {
  static defaultTabId = 'shiftForm'

  get calendar() {
    const { calendar, calendarType } = this.props
    const store = getAppropriateStore(calendarType)
    return new Calendar(calendar, store)
  }

  get defaultState() {
    return { activeTabId: CellDetailsController.defaultTabId }
  }

  get selectedObjects() {
    const { selection } = this.props
    return this.calendar.getSelectedObjects(selection)
  }

  loadFacilityData() {
    const { facility, generalStore } = this.props
    const isShiftsMapLoaded = facility.get('isShiftsMapLoaded')
    const isManagersMapLoaded = facility.get('isManagersMapLoaded')

    if (!isShiftsMapLoaded) {
      generalStore.loadUnitsShifts()
    }
    if (!isManagersMapLoaded) {
      generalStore.loadManagers()
    }
  }

  isSelectedCellsProcessing() {
    const { selection, cellsPathsInProcessing } = this.props
    const { cellIndexes } = this.selectedObjects

    const staffPath = selection.get('staffPath')
    const cellKeys = this.calendar.getCellKeys(staffPath, cellIndexes)

    return cellKeys.some((cellKey) => cellsPathsInProcessing.contains(cellKey))
  }
}
