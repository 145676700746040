import { createRef, Component } from 'react'
import { Link } from 'react-router-dom'
import './CrudCard.scss'

import { withSeparators } from './utils'

class CrudCard extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, contentHeight: 0 }
    this.cardContentRef = createRef()
    this.collapsedHeight = 100
  }

  componentDidMount() {
    this.setState({ contentHeight: this.cardContentRef.current.clientHeight })
  }

  componentDidUpdate() {
    if (this.cardContentRef.current.clientHeight !== this.state.contentHeight) {
      this.setState({ contentHeight: this.cardContentRef.current.clientHeight })
    }
  }

  render() {
    return (
      <>
        {this.renderCardTitle()}
        {this.renderCard()}
      </>
    )
  }

  renderCardTitle() {
    const { title, createItemPath, isCollapsible } = this.props

    if (!title) {
      return null
    }

    const { isOpen } = this.state
    const status = isOpen ? 'active' : 'inactive'
    const arrowDirection = isOpen ? 'up' : 'down'

    const openCard = () => {
      this.setState((state) => ({ isOpen: !state.isOpen }))
    }

    return (
      <div className={`hx-staff-container-box-title ${status}`}>
        <sh-text size="header-2">
          {title}
          {isCollapsible && this.doesCardRequireCollapsing() && (
            <i className={`icon icon-${arrowDirection}`} onClick={openCard} />
          )}
        </sh-text>

        {createItemPath && this.getCreateItemButton()}
      </div>
    )
  }

  renderCard() {
    let {
      children: cardContents,
      separatorColor,
      isCollapsible,
      title,
      createItemPath,
      isLoading,
      isContentLoading,
      loadingMessage,
      cardHeader
    } = this.props
    const { isOpen, contentHeight } = this.state

    if (isLoading || isContentLoading) {
      cardContents = (
        <div className="empty-card-content">
          <sh-spinner label={loadingMessage || 'Loading'}></sh-spinner>
        </div>
      )
    } else if (!cardContents || cardContents.length === 0) {
      cardContents = (
        <div className="empty-card-content">
          <sh-text size="body-1" color="primary">
            {this.props.emptyCardMessage}
          </sh-text>
        </div>
      )
    }

    let cardHeight = 'auto'
    if (isCollapsible && this.doesCardRequireCollapsing()) {
      if (!isOpen) {
        cardHeight = `${this.collapsedHeight}px`
      } else {
        cardHeight = `${contentHeight}px`
      }
    }

    return (
      <div className="crud-card bg-white regent-gray hx-box-shadow" style={{ height: cardHeight }}>
        <div className="crud-card-contents" ref={this.cardContentRef}>
          {!title && createItemPath && <div className="crud-card-floating-button">{this.getCreateItemButton()}</div>}
          {!isLoading && cardHeader}
          {separatorColor ? withSeparators(cardContents, separatorColor) : cardContents}
          {isCollapsible && this.doesCardRequireCollapsing() && !isOpen && <footer className="collapsed-shadow" />}
        </div>
      </div>
    )
  }

  doesCardRequireCollapsing() {
    return this.state.contentHeight > 2 * this.collapsedHeight
  }

  getCreateItemButton() {
    const {
      createItemPath,
      createItemTooltip,
      createItemIcon,
      isCreateItemDisabled,
      createItemButtonClassNames,
      title
    } = this.props
    const iconWithTooltip = (
      <div className={createItemButtonClassNames}>
        <sh-icon
          id="create-item-btn"
          icon={createItemIcon}
          size="s"
          button
          disabled={isCreateItemDisabled ? true : undefined}
        />
        <sh-tooltip
          label={createItemTooltip}
          target="create-item-btn"
          placement={title ? 'left' : 'top'}
          variation="short"
        />
      </div>
    )

    if (isCreateItemDisabled) {
      return iconWithTooltip
    }
    return <Link to={createItemPath}>{iconWithTooltip}</Link>
  }
}

CrudCard.defaultProps = {
  createItemTooltip: 'Add item',
  emptyCardMessage: 'No items found',
  createItemIcon: 'plus'
}

export default CrudCard
