import { Staff } from 'entityWrappers'
import hasAccessIcon from '../../../../styles/web_v3/check-circle-solid.svg'
import noAccessIcon from '../../../../styles/web_v3/icon-no-access.svg'
import pendingAccessIcon from '../../../../styles/web_v3/icon-pending-access.svg'
import accessEndingIcon from '../../../../styles/web_v3/icon-access-ending.svg'

export default function Status({ staff }) {
  const { isInvitationAccepted, isInvitationSent, hasPermissions, staffProfile } = new Staff(staff)
  const { endsAt } = staffProfile

  const hasAccess = hasPermissions && !endsAt
  const noAccess = !hasPermissions && !isInvitationSent
  const pendingAccess = isInvitationSent && !isInvitationAccepted
  const accessEnding = hasPermissions && !!endsAt

  return (
    <>
      {hasAccess && <img className="hx-status-has-access" alt="has access" src={hasAccessIcon} />}
      {noAccess && <img className="hx-status-no-access" alt="no access" src={noAccessIcon} />}
      {pendingAccess && <img className="hx-status-pending-access" alt="pending access" src={pendingAccessIcon} />}
      {accessEnding && <img className="hx-status-access-ending" alt="access ending" src={accessEndingIcon} />}
    </>
  )
}
