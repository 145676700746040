import { List, Map, Repeat } from 'immutable'
import { FacilityUserShiftsService, ShiftService } from 'services'
import { calendarDayDataQuery } from '../Queries'
import { staffAssignedWeekHoursQuery } from '../../openShiftsStore/Queries'
import { readCalendarDayDataParams, staffEventFiltered } from './utils'

export default CalendarDayView()

function CalendarDayView() {
  let updateCalendar = null
  let instanceUri = null
  let timeService = null
  let buildStaffWithCells = null
  let gqlClient = null

  const actions = { loadCalendarDayView }
  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, updateCalendar, instanceUri, facilityTime: timeService, buildStaffWithCells } = context)
  }

  async function loadCalendarDayView(unit, usDate, dateRange) {
    const hours = 24
    const unitId = unit.get('id')
    let roles = unit.get('roles')
    let shifts = unit.get('shifts')
    const facilityUsers = dateRange.get('facilityUsers')
    const startDate = dateRange.get('startsAt')
    const endDate = dateRange.get('endsAt')
    const dateTime = timeService.timeMoment(usDate).toISOString()

    const { shiftDays, staffEvents, openShifts, staffAssignedWeekHours } = await _readCalendarDayData(
      unit,
      dateTime,
      facilityUsers,
      usDate
    )

    const facilityUsersByShiftId = FacilityUserShiftsService.usersByShiftId(
      startDate,
      endDate,
      unitId,
      facilityUsers,
      shifts,
      timeService
    )
    ;({ roles, shifts } = extendWithSecondaryStaff(roles, shifts))

    const facilityUserIdsByShiftId = facilityUsersByShiftId.map((shiftUsers) =>
      shiftUsers.map((user) => user.get('userId'))
    )

    const shiftsByRoleId = shifts.groupBy((shift) => shift.get('unitRoleId'))
    const flat = initCalendarDayView({
      roles,
      shiftsByRoleId,
      hours,
      facilityUsersByShiftId,
      shiftDays,
      staffEvents,
      dateTime,
      openShifts,
      staffAssignedWeekHours
    })

    return updateCalendar({
      ...flat,
      hours,
      facilityUserIdsByShiftId,
      dateRange,
      daysData: {},
      isOtherStaffLoaded: false,
      otherStaff: List()
    })
  }

  async function _readCalendarDayData(unit, startDate, facilityUsers, usDate) {
    const unitId = unit.get('id')
    const shifts = unit.get('shifts')
    const shiftIds = shifts.reduce((memo, shift) => memo.concat(shift.get('id')), [])
    const endDate = timeService.timeMoment(startDate).add(2, 'day').add(-1, 'minute').toISOString()
    const parameters = readCalendarDayDataParams(unitId, startDate, endDate, shiftIds)
    const {
      shiftDays,
      openShifts,
      staffEvents,
      shiftOpportunities,
      notesByDateRange: notes
    } = await gqlClient.query(calendarDayDataQuery, parameters)
    const parametersHoursWeek = { date: startDate, userIds: facilityUsers.map((u) => u.get('id')) }
    const timeZoneAdjustedStartDate = timeService.timeMoment(startDate.split('T')[0]).toISOString()
    const staffEventsFiltered = staffEventFiltered(staffEvents, timeService, timeZoneAdjustedStartDate)
    const { staffAssignedWeekHours } = await gqlClient.query(staffAssignedWeekHoursQuery, parametersHoursWeek)

    return {
      notes,
      openShifts,
      staffEvents: staffEventsFiltered,
      shiftOpportunities,
      shiftDays,
      staffAssignedWeekHours
    }
  }

  function extendWithSecondaryStaff(roles, shifts) {
    const secondaryStaffRole = Map({
      id: 'secondary-staff',
      isOtherStaff: true,
      name: 'Other Staff'
    })
    roles = roles.push(secondaryStaffRole)

    const secondaryStaffShift = Map({
      id: 'secondary-staff-shift',
      hidden: true,
      isOtherStaff: true,
      unitRoleId: secondaryStaffRole.get('id')
    })
    shifts = shifts.push(secondaryStaffShift)

    return { roles, shifts }
  }

  function initCalendarDayView({
    roles,
    shiftsByRoleId,
    hours,
    facilityUsersByShiftId,
    shiftDays,
    staffEvents,
    date,
    openShifts,
    staffAssignedWeekHours
  }) {
    const cells = List(Repeat(Map(), 24))

    const rolesExtended = roles.map((role, roleIndex) =>
      mapRole({
        role,
        roleIndex,
        shiftsByRoleId,
        shiftDays,
        facilityUsersByShiftId,
        cells,
        staffEvents,
        date,
        openShifts,
        staffAssignedWeekHours
      })
    )

    return { roles: rolesExtended }
  }

  function mapRole({
    role,
    roleIndex,
    shiftsByRoleId,
    shiftDays,
    facilityUsersByShiftId,
    cells,
    staffEvents,
    date,
    openShifts,
    staffAssignedWeekHours
  }) {
    const roleId = role.get('id')
    const roleShifts = shiftsByRoleId.get(roleId) || List()

    return role.merge({
      roleIndex,
      kind: 'role',
      shifts: roleShifts.map((shift, shiftIndex) =>
        mapShift({
          shift,
          shiftDays,
          roleIndex,
          shiftIndex,
          facilityUsersByShiftId,
          cells,
          staffEvents,
          openShifts,
          staffAssignedWeekHours
        })
      )
    })
  }

  function mapShift({
    shift,
    shiftDays,
    roleIndex,
    shiftIndex,
    facilityUsersByShiftId,
    cells,
    staffEvents,
    openShifts,
    staffAssignedWeekHours
  }) {
    const shiftId = shift.get('id')
    const facilityUsers = facilityUsersByShiftId.get(shiftId) || List()
    const staff = buildStaffWithCells(facilityUsers, cells, roleIndex, shiftIndex, shiftId, instanceUri, staffEvents)

    if (!shift.get('hidden')) {
      const shiftService = new ShiftService(shift, timeService)
      const shiftTime = shiftService.getShiftTime()
      shift = shift.set('range', shiftTime)
    }
    const dayShiftDays = shiftDays.filter((item) => item['shiftId'] === shift.get('id'))

    return shift.merge({
      primaryStaff: staff,
      staff,
      roleIndex,
      shiftIndex,
      kind: 'shift',
      isLoaded: false,
      shiftDays,
      shiftDay: dayShiftDays,
      openShifts,
      staffAssignedWeekHours
    })
  }
}
