import { Component } from 'react'
import { startCase, capitalize } from 'lodash'
import { curry } from 'i18n'
import { navigate } from 'Navigation'
import ExpertiseCard from './ExpertiseCard'
import './Equipment.scss'
import { deepRemoveProperty } from './utils'

const t = curry('staff.staffExpertiseCard.')

class Equipment extends Component {
  handleDelete = async (expertiseId, expertiseTitle) => {
    const { updateStaff, userId, Dialog } = this.props

    const isDeleteExpertiseConfirmed = await Dialog.confirm(expertiseTitle, {
      type: 'warning',
      title: t('equipmentDeleteConfirmation.title'),
      accept: t('equipmentDeleteConfirmation.accept')
    })

    if (!isDeleteExpertiseConfirmed) {
      return
    }

    const updatedStaffExpertiseCredentials = this.removeExpertiseFromCredentials(expertiseId)

    try {
      await updateStaff({ userId, expertiseCredentials: updatedStaffExpertiseCredentials })
    } catch (err) {
      navigate.from.StaffDetails.to.StaffDetails(undefined, false)
    }
    navigate.from.StaffDetails.to.StaffDetails(undefined, false)
  }

  removeExpertiseFromCredentials = (expertiseGroupId) => {
    const { staffExpertiseCredentials, expertiseGroups, staffExpertiseForest } = this.props
    const expertiseParentId = expertiseGroups.get(expertiseGroupId).get(0).get('parentId')
    const equipment = staffExpertiseForest[expertiseParentId].expertises.find(
      (item) => item.get('id') === expertiseGroupId
    )
    const skillIds = equipment
      .get('expertises')
      .map((item) => item.get('id'))
      .toJS()
    return staffExpertiseCredentials
      .filterNot((staffExpertiseCredential) => skillIds.includes(staffExpertiseCredential.get('expertiseId')))
      .toJS()
      .map((staffExpertiseCredential) => deepRemoveProperty(staffExpertiseCredential, '__typename'))
  }

  render = () => {
    const createEquipmentPath = navigate.from.StaffDetails.to.StaffCreateEquipment({}, false)
    const editEquipmentPath = (expertiseId) => navigate.from.StaffDetails.to.StaffEditEquipment({ expertiseId }, false)

    const equipmentCardProps = {
      ...this.props,
      cardTitle: t('equipmentCardTitle'),
      expertiseType: 'equipment',
      getExpertiseTitle: this.getEquipmentTitle,
      getExpertiseInfo: this.getEquipmentInfo,
      createExpertisePath: createEquipmentPath,
      editExpertisePath: editEquipmentPath,
      isGrouped: true,
      withImage: true,
      handleDelete: this.handleDelete
    }
    return <ExpertiseCard {...equipmentCardProps} />
  }

  getEquipmentTitle = (equipment) => {
    const name = equipment.get('title')
    const description = equipment.get('description')
    return (
      <>
        <div>{name}</div>
        <div className="equipment-description">
          <sh-text size="body-2">{description}</sh-text>
        </div>
      </>
    )
  }

  getEquipmentInfo = (equipment) => {
    const skills = equipment.get('expertises')

    return skills.map((skill) => {
      const name = skill.get('name')

      const scaleItems = skill.get('scaleItems')
      const staffExpertiseCredential = skill.get('staffExpertiseCredential')
      const scaleIndex = staffExpertiseCredential.get('scaleIndex')
      const competency = scaleIndex !== undefined && capitalize(startCase(scaleItems.get(scaleIndex)))

      const reviewedDateTime = staffExpertiseCredential.get('issuedAt')
      const expirationDateTime = staffExpertiseCredential.get('expiresAt')
      const { timeService } = this.props
      const format = 'll'
      const formattedReviewedDate = reviewedDateTime && timeService.timeMoment(reviewedDateTime).format(format)
      const formattedExpirationDate = expirationDateTime && timeService.timeMoment(expirationDateTime).format(format)

      return (
        <div key={skill.get('id')} className="equipment-skill">
          <span className="name">
            {name}
            {competency && ': '}
          </span>
          {competency}
          {(formattedReviewedDate || formattedExpirationDate) && ' ('}
          {formattedReviewedDate && `Reviewed: ${formattedReviewedDate}`}
          {formattedReviewedDate && formattedExpirationDate && ' / '}
          {formattedExpirationDate && `Expiry: ${formattedExpirationDate}`}
          {(formattedReviewedDate || formattedExpirationDate) && ')'}
        </div>
      )
    })
  }
}

export default Equipment
