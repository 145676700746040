import { Sentry } from 'services'
import TimeService from 'services/TimeService'
import { addErrorMessage } from '@humanics/he-react-common/lib/stores/messages'

export default function Facility(facility, instanceUri, isAdmin) {
  const { id, idnId, name } = facility

  const uri = `${instanceUri}/idns/${idnId}/facilities/${id}`
  let { timeZone } = facility

  if (!timeZone) {
    timeZone = 'America/Los_Angeles'
    const message = `"${name}" facility doesn't have a timeZone. Applied default ${timeZone}`
    addErrorMessage({ message })
  }

  Sentry.setFacilityTimeZone(timeZone)

  const facilityTime = new TimeService(timeZone)

  return {
    ...facility,
    instanceUri,
    uri,
    scope: facility.id,
    isAdmin,
    facilityTime: () => facilityTime
  }
}
