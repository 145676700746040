import classNames from 'classnames'
import { Map } from 'immutable'
import { middleGlyphMap, sideGlyphMap } from 'Manager/components/CellIconMap'
import { TimeOffDefinitionsService } from 'services'

const timeOffAbbreviationsMap = TimeOffDefinitionsService.buildTimeOffAbbreviationsMap()
const timeOffColorsMap = TimeOffDefinitionsService.buildTimeOffColorsMap()
const defaultShiftColor = '#97a4b5'
const eventsIconMap = {
  isCancelled: middleGlyphMap['crossed-dot'],
  isBlockedByAssignment: middleGlyphMap['crossed-dot'],
  isBlockedByAssignmentFromOtherUnit: middleGlyphMap['faded-circle'],
  isAssignment: middleGlyphMap['dot'],
  isOnCall: middleGlyphMap['on-call'],
  isOpportunityPendingToApprove: middleGlyphMap['pin'],
  isPTO: middleGlyphMap['money-sign'],
  isUnavailable: middleGlyphMap['unavailability'],
  isMeeting: middleGlyphMap['event'],
  equipment: middleGlyphMap['patient-scan'],
  subspeciality: {
    icon: `patient-open-tasks`,
    color: 'rgba(151, 164, 181)'
  }
}

const eventsList = Object.keys(eventsIconMap)

function getClassNameForWeeklyView(weeklyViewArgs) {
  const {
    isYesterday,
    cell,
    isPrevDay,
    isError,
    isWeekend,
    isLastSelected,
    isFirstSelected,
    isMiddleSelected,
    isSelected
  } = weeklyViewArgs
  return classNames('col-1 br1 _weekCell weekly-view-event-bar-container cursor-default', {
    _yesterdayCell: isYesterday,
    'half-opacity': isPrevDay,
    'bg-alabaster': !isError && isWeekend,
    'bg-white': !isError && !isWeekend,
    'bg-error': isError,
    'last-selected': isLastSelected,
    'first-selected': isFirstSelected,
    'middle-selected': isMiddleSelected,
    'bg-selected-week-view-shift': isSelected
  })
}

function getClassNameForStaffView(staffViewArgs) {
  const {
    isYesterday,
    isPrevDay,
    isError,
    isWeekend,
    isLastSelected,
    isFirstSelected,
    isMiddleSelected,
    isSelected,
    isCellDisabled,
    activeDateRange,
    isNotCurrentMonth,
    cell,
    isProcessing
  } = staffViewArgs
  if (isProcessing) {
    return 'br1 _cell col-1'
  }

  const hasNotes = cell.get('staffEvents')?.get(0)?.get('hasNotes')
  const isNoTimeOff = cell.get('isNoTimeOff')
  const isRequestedToWork = cell.get('isRequestedToWork')
  const isRequestedDayOff = cell.get('isRequestedDayOff')
  const isMandatoryToWork = cell.get('isMandatoryToWork')

  const isPTO = cell.get('staffEvents')?.size === 1 && cell.get('staffEvents')?.get(0)?.get('isPTO')
  const isUnavailable = cell.get('staffEvents')?.size === 1 && cell.get('staffEvents')?.get(0)?.get('isUnavailable')
  const isOpportunityPendingToApprove = cell.get('isOpportunityPendingToApprove')

  // TODO:
  const activeDateRangeDuration = activeDateRange?.get('duration')
  const shouldApplyHalfOpacity = activeDateRangeDuration === 4 ? isNotCurrentMonth : isPrevDay

  return classNames('col-1 _cell br1 fullview-cell-icons rock-blue', {
    _yesterdayCell: isYesterday,
    'half-opacity': shouldApplyHalfOpacity && !isCellDisabled,
    'bg-alabaster': !isError && isWeekend,
    'bg-white': !isError && !isWeekend,
    'bg-error': isError,
    'bg-selected-shift': isSelected,
    'bg-mystic': !isError && (isCellDisabled || isPTO || isUnavailable),
    'last-selected': isLastSelected,
    'first-selected': isFirstSelected,
    'middle-selected': isMiddleSelected,
    '_cell-annotation': hasNotes,
    '_cell-lock': (isMandatoryToWork || isNoTimeOff) && !isRequestedToWork,
    '_cell-available': isRequestedToWork,
    '_cell-unavailable': isRequestedDayOff,
    '_cell-opportunity-pending-to-approve': isOpportunityPendingToApprove
  })
}

function getClassName(cell, day, isCellDisabled, activeDateRange, isWeeklyView, additionalContent) {
  const isProcessing = cell.get('isProcessing')

  if (isProcessing) {
    return 'br1 _cell col-1'
  }

  // TODO:
  const { isYesterday, isPrevDay, isWeekend, isNotCurrentMonth } = day

  const isError = cell.get('isError')
  const isSelected = cell.get('isSelected')

  const isPreviousSelected = isSelected && cell.get('isPreviousSelected')
  const isNextSelected = isSelected && cell.get('isNextSelected')

  const isFirstSelected = isSelected && !isPreviousSelected && isNextSelected
  const isMiddleSelected = isSelected && isPreviousSelected && isNextSelected
  const isLastSelected = isSelected && isPreviousSelected && !isNextSelected

  const isAvatarCell = cell?.get('isAvatarCell')

  const weeklyViewArgs = {
    cell,
    isYesterday,
    isPrevDay,
    isError,
    isWeekend,
    isLastSelected,
    isFirstSelected,
    isMiddleSelected,
    isSelected
  }

  const staffViewArgs = {
    ...weeklyViewArgs,
    isCellDisabled,
    activeDateRange,
    isNotCurrentMonth,
    cell,
    isProcessing
  }

  if (isWeeklyView && !isAvatarCell) {
    return getClassNameForWeeklyView(weeklyViewArgs)
  } else {
    return getClassNameForStaffView(staffViewArgs)
  }
}

function getLeftColumn(cell, indicators, isPrintView = false) {
  const leftIcons = isPrintView ? null : _getLeftIcons(cell, indicators)
  return <span>{leftIcons}</span>
}

function _getLeftIcons(cell, indicators) {
  const isLocked =
    indicators.get('locks') &&
    !cell.get('isRequestedToWork') &&
    (cell.get('isMandatoryToWork') || cell.get('isTimeOffNotAllowed'))

  if (isLocked) {
    return sideGlyphMap['reset']
  }

  const isRequestedToWork = indicators.get('available') && cell.get('isRequestedToWork')
  const isRequestedToWorkAnyShift = indicators.get('available') && cell.get('isRequestedToWorkAnyShift')
  const isRequestedToWorkMultipleShifts = indicators.get('available') && cell.get('staffAvailableShifts')?.size > 1
  if (isRequestedToWorkAnyShift || isRequestedToWorkMultipleShifts) {
    return sideGlyphMap['checkmark+']
  }
  if (isRequestedToWork) {
    return sideGlyphMap['checkmark']
  }
  return sideGlyphMap['gap']
}

function getMiddleColumn(cell, facilityShiftsMap, isPrintView = false, notes = Map(), unitId) {
  const middleColumnDisplayList = _getMiddleColumnDisplayList(cell, facilityShiftsMap, notes, unitId)

  if (!middleColumnDisplayList.length) {
    return <span></span>
  }

  return (
    <span>
      <div className={`shift-icons children-${middleColumnDisplayList.length}`}>
        {middleColumnDisplayList.map((data) => (
          <div key={data.key} className={isPrintView ? 'print' : ''}>
            {data.component}
          </div>
        ))}
      </div>
    </span>
  )
}

function _getMiddleColumnDisplayList(cell, facilityShiftsMap, notes, unitId) {
  const staffEvents = cell?.get('staffEvents') || Map()
  const firstTwoEvents = staffEvents.slice(0, 2)
  const totalStaffEventsCount = staffEvents.size

  const middleColumnDisplayList = firstTwoEvents
    .map((staffEvent) => _getDisplayData(staffEvent, facilityShiftsMap, notes, unitId))
    .toJS()

  if (totalStaffEventsCount > 2) {
    const displayText = `+${totalStaffEventsCount - 2}`
    const otherProps = { style: { color: defaultShiftColor } }
    const displayData = { key: 'remaining-events-count', component: _getDisplayComponent(displayText, otherProps) }
    middleColumnDisplayList.push(displayData)
  }

  return middleColumnDisplayList
}

function _getDisplayData(staffEvent, facilityShiftsMap, notes, unitId) {
  const isPTO = staffEvent.get('isPTO')
  const isUnavailable = staffEvent.get('isUnavailable')
  const isMeeting = staffEvent.get('isMeeting')
  const isTimeOff = isPTO || isUnavailable

  const shiftIdsForDisplayedUnit = []

  facilityShiftsMap.forEach((shift, shiftId) => {
    if (shift.get('unitId') === unitId) {
      shiftIdsForDisplayedUnit.push(shiftId)
    }
  })

  if (!shiftIdsForDisplayedUnit.includes(staffEvent.get('shiftId')) && !(isTimeOff || isMeeting)) {
    staffEvent = staffEvent.set('isBlockedByAssignmentFromOtherUnit', true)
  }

  const icon = _getIconFromStaffEvent(staffEvent)

  const { abbreviation, color } = _getAbbreviationAndColor(staffEvent, facilityShiftsMap, notes)

  if (abbreviation && !staffEvent.get('isBlockedByAssignmentFromOtherUnit')) {
    return _getDisplayComponentFromStaffEvent(staffEvent, abbreviation, { style: { color } })
  }
  return _getDisplayComponentFromStaffEvent(staffEvent, icon, { class: 'fullview-cell-icons glyph-icon rock-blue' })
}

function _getDisplayComponentFromStaffEvent(staffEvent, displayData, otherProps) {
  return {
    key: staffEvent.get('id'),
    component: _getDisplayComponent(displayData, otherProps)
  }
}

function _getDisplayComponent(displayData, otherProps) {
  const { icon, color } = displayData

  if (icon) {
    return <sh-icon icon={icon} size="s" color={color} />
  }

  return (
    <sh-text size="header-2" {...otherProps}>
      {displayData}
    </sh-text>
  )
}

function _getAbbreviationAndColor(staffEvent, facilityShiftsMap, notes) {
  if (staffEvent.get('isAssignment') || staffEvent.get('isOnCall')) {
    const cellShiftId = staffEvent.get('shiftId')
    const cellShift = facilityShiftsMap?.get(cellShiftId)
    const abbreviation = cellShift?.get('displayAbbreviation')
    const color = (abbreviation && cellShift?.get('displayColor')) || defaultShiftColor
    return { abbreviation, color }
  }

  if (staffEvent.get('isPTO') || staffEvent.get('isUnavailable')) {
    // TODO: revisit
    const noteId = staffEvent.get('noteIds')?.get(0)
    const subject = notes.get(noteId)?.get('subject')
    const abbreviation = timeOffAbbreviationsMap[subject]
    const color = timeOffColorsMap[subject]
    return { abbreviation, color }
  }

  return {}
}

function _getIconFromStaffEvent(staffEvent) {
  // changes for staff view | full view
  for (const event of eventsList) {
    if (event === 'isAssignment' && staffEvent.get(event)) {
      const assignmentType = staffEvent.get('assignmentAttributes')?.get('type')
      const eventType = assignmentType && assignmentType !== 'shift' ? assignmentType : 'isAssignment'

      return eventsIconMap[eventType]
    }
    if (staffEvent.get(event)) {
      return eventsIconMap[event]
    }
  }

  return sideGlyphMap['gap']
}

export { getClassName, getLeftColumn, getMiddleColumn }
