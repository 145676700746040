import { hideMenuItems, resetSitemap, updateFacilityUrlId } from './sitemap'
import authenticationStore from './authenticationStore'

const initStores = (initialize) =>
  authenticationStore({
    hideMenuItems,
    resetSitemap,
    updateFacilityUrlId,
    initialize
  })

export default initStores
