import { PureComponent } from 'react'
import classNames from 'classnames'

export default class MismatchCount extends PureComponent {
  render() {
    const { overstaffCount, understaffCount } = this.props
    const isOverstaffed = overstaffCount < 0
    const isUnderstaffed = understaffCount > 0
    const overstaffCountClassName = classNames('label bold rounded bg-pizazz white', {
      'non-rounded-right': isUnderstaffed
    })
    const undrestaffCountClassName = classNames('label bold rounded error white', {
      'non-rounded-left': isOverstaffed
    })

    return (
      <div className="hx-staffing-totals rounded bg-white flex">
        {isOverstaffed && <span className={overstaffCountClassName}>+{overstaffCount * -1}</span>}
        {isUnderstaffed && <span className={undrestaffCountClassName}>-{understaffCount}</span>}
      </div>
    )
  }
}
