import { loader } from 'graphql.macro'

export const updateFacilityUserFacilityProfileMutation = loader('./UpdateFacilityUserFacilityProfile.graphql')
export const updateMyFacilityUserFacilityProfileMutation = loader('./UpdateMyFacilityUserFacilityProfile.graphql')
export const updateStaffProfileMutation = loader('./UpdateStaffProfile.graphql')
export const updateStaffProfileExpertiseMutation = loader('./UpdateStaffProfileExpertise.graphql')
export const createFacilityUserMutation = loader('./CreateFacilityUser.graphql')
export const revokeFacilityUserInvitationsMutation = loader('./RevokeFacilityUserInvitations.graphql')
export const revokeFacilityUserPermissionsMutation = loader('./RevokeFacilityUserPermissions.graphql')
export const inviteFacilityUserMutation = loader('./InviteFacilityUser.graphql')
export const createStaffComplianceRuleMutation = loader('./CreateStaffComplianceRule.graphql')
export const updateStaffComplianceRuleMutation = loader('./UpdateStaffComplianceRule.graphql')
export const deleteStaffComplianceRuleMutation = loader('./DeleteStaffComplianceRule.graphql')
export const createStaffRecurringScheduleMutation = loader('./CreateStaffRecurringSchedule.graphql')
export const deleteStaffRecurringScheduleMutation = loader('./DeleteStaffRecurringSchedule.graphql')
export const updateStaffRecurringScheduleMutation = loader('./UpdateStaffRecurringSchedule.graphql')
export const popStaffEligibleUnitsItemMutation = loader('./popStaffEligibleUnitsItemMutation.graphql')
export const appendStaffEligibleUnitsItemMutation = loader('./AppendStaffEligibleUnitsItem.graphql')
export const terminateStaffMutation = loader('./TerminateStaff.graphql')
