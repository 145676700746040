import { useState } from 'react'
import { t } from 'i18n'
import './ExpiringListModal.scss'
import momentWithTimeZone from 'moment-timezone'
import StaffName from '../../common/StaffName/StaffName'

function ExpiringListModal({ expertiseMetadata, toggleModalVisibility, timeService }) {
  const [modalTabIndex, setModalTabIndex] = useState(0)
  const modalTabOptions = ['skill', 'license', 'certification', 'equipment']
  const headerName = {
    license: t('dashboard.staffLicenses'),
    skill: t('dashboard.staffSkills'),
    certification: t('dashboard.staffCredentials'),
    equipment: t('dashboard.equipmentSkills')
  }

  const name = {
    skill: t('expiringList.table-header.name.skill'),
    license: t('expiringList.table-header.name.license'),
    certification: t('expiringList.table-header.name.certification'),
    equipment: t('expiringList.table-header.name.equipment')
  }

  const getDate = (date) => momentWithTimeZone.tz(date, timeService?.timeZone?.name).format('MMMM DD, YYYY')
  const activeType = expertiseMetadata[modalTabOptions[modalTabIndex]]

  return (
    <sh-modal
      class="z-201"
      visible
      sticky
      slot="header"
      label={t('expiringList.header.title')}
      id="header-modal"
      modal-height="calc(100% - 10rem)"
      modal-width="70rem"
    >
      <sh-tabs>
        {modalTabOptions.map((expertiseTypeName, index) => (
          <sh-tab-item
            class="tab-item"
            active={modalTabIndex === index ? true : undefined}
            key={index}
            label={`${headerName[expertiseTypeName]} (${expertiseMetadata[expertiseTypeName].length})`}
            onClick={() => setModalTabIndex(index)}
          ></sh-tab-item>
        ))}
      </sh-tabs>
      <div className="modal-body-container">
        {activeType.length ? (
          <sh-table class="no-margin-bottom" readonly>
            <sh-table-row slot="header">
              <sh-table-head columns="6" unsortable>
                <sh-text size="title-1" color="secondary">
                  {t('expiringList.table-header.staffName')}
                </sh-text>
              </sh-table-head>
              <sh-table-head columns="9" unsortable>
                <sh-text size="title-1" color="secondary">
                  {name[modalTabOptions[modalTabIndex]]}
                </sh-text>
              </sh-table-head>
              <sh-table-head columns="5" unsortable>
                <sh-text size="title-1" color="secondary">
                  {t('expiringList.table-header.expiryDate')}
                </sh-text>
              </sh-table-head>
              <sh-table-head columns="4" unsortable>
                <sh-text size="title-1" color="secondary">
                  {t('expiringList.table-header.status')}
                </sh-text>
              </sh-table-head>
            </sh-table-row>
            {activeType.map((metaData, index) => (
              <sh-table-row key={index}>
                <sh-table-cell columns="6">
                  <StaffName metaData={metaData} />
                </sh-table-cell>
                <sh-table-cell columns="9">{metaData['skillName']}</sh-table-cell>
                <sh-table-cell columns="5">{getDate(metaData['expiryDate'])}</sh-table-cell>
                <sh-table-cell columns="4">
                  {metaData['isExpiring'] && (
                    <div className="expiring-warning">
                      <sh-badge type="warning"></sh-badge>
                      <sh-text size="title-1" color="primary" data-testid="expiringSoon">
                        {t('expiringList.expiringSoon')}
                      </sh-text>
                    </div>
                  )}
                  {metaData['isExpired'] && (
                    <div className="expired" data-testid="expired">
                      <sh-badge type="error"></sh-badge> {t('expiringList.expired')}
                    </div>
                  )}
                </sh-table-cell>
              </sh-table-row>
            ))}
          </sh-table>
        ) : (
          <sh-empty-state icon="patient-scheduler" label={t('dashboard.noData')}></sh-empty-state>
        )}
      </div>
      <sh-button slot="footer" label="Close" color="secondary" onClick={toggleModalVisibility}></sh-button>
    </sh-modal>
  )
}

export default ExpiringListModal
