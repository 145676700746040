import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { List, Map } from 'immutable'

const fields = [Field('version', 'version'), Field('name', 'name'), Field('url', 'endpoint')]

function serviceStore() {
  const actions = fluxStore({ loadDataAction })
  const stateKey = 'admin.services'
  let api = null

  return {
    ...actions,
    initialize,
    getAdminInfo,
    hasCrud: () => false,
    getFields: () => fields
  }

  function initialize(state, { facilityApi }) {
    api = facilityApi
    return state.setIn([stateKey, 'list'], List())
  }

  function url() {
    return `${api.uri}/api/health`
  }

  function loadDataAction(state) {
    return fetch(url(), {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    })
      .then((response) => response.json())
      .then((services) => state.setIn([stateKey, 'list'], services))
      .catch((error) => state.set('error', Map(error)))
  }

  function getAdminInfo() {
    return {
      stateKey,
      header: {
        title: 'Services'
      },
      entityDefinition: {}
    }
  }
}

export default serviceStore
