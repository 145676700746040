import { createShiftDayRequirementMutation } from '../Mutations'

function ShiftRequirements() {
  let gqlClient = null
  const actions = { createShiftDayRequirement }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient } = context)
  }

  function createShiftDayRequirement(parameters) {
    const { unitId, shiftId, date, number } = parameters
    const params = { unitId, shiftId, parameters: { date, number } }

    return gqlClient.mutate(createShiftDayRequirementMutation, params)
  }
}

export default ShiftRequirements()
