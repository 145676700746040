import { ComponentController } from 'Common/components'
import stores from 'stores'
import { StaffTimeOffRequestDateInput , StaffTimeOffRequestNoteInput} from '../utils'

const { staffCalendarStore, staffShiftSwapsStore, staffGeneralStore, staffTimeOffRequestsStore }: any = stores
export default class DataController extends ComponentController {
  async loadMyStaffEventsForMonth(date: string) {
    await staffCalendarStore.loadMyStaffEventsForMonth(date)
  }

  async loadMyStaffDaysForMonth(date: string) {
    await staffCalendarStore.loadMyStaffDaysForMonth(date)
  }

  async loadMyShiftSwapRequestsForMonth(date: string, userId: string) {
    await staffShiftSwapsStore.loadMyShiftSwapRequestsForMonth(date, userId)
  }

  async loadMyStaffTimeOffRequestsForMonth(date: string) {
    await staffTimeOffRequestsStore.loadMyStaffTimeOffRequestsForMonth(date)
  }

  async createMyStaffTimeOffRequest(dates: StaffTimeOffRequestDateInput[],note:StaffTimeOffRequestNoteInput, monthStartsAt: string) {
    await staffTimeOffRequestsStore.createMyStaffTimeOffRequest(dates, note, monthStartsAt)
  }

  async loadShifts() {
    const appState = await staffGeneralStore.loadShifts()
    return appState.getIn(['staffGeneralData', 'shiftsMap'])
  }

  async loadUnits() {
    await staffGeneralStore.loadUnits()
  }

  async loadPublicStaff() {
    await staffGeneralStore.loadPublicStaff()
  }
}
