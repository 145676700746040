import { Component } from 'react'
import { Map } from 'immutable'

export default class ImageGraceful extends Component {
  constructor(props) {
    super(props)
    this.state = { errCache: Map() }
  }

  render() {
    const { src, fallbackSrc, fallbackComponent, className, alt, imageContainerClassName } = this.props
    const shouldRenderImg = src && !this.hasError(src)

    if (shouldRenderImg) {
      if (imageContainerClassName) {
        return (
          <div className={imageContainerClassName}>
            <img className={className} onError={() => this.onError(src)} src={src} alt={alt} />
          </div>
        )
      }
      return <img className={className} onError={() => this.onError(src)} src={src} alt={alt} />
    } else if (fallbackSrc) {
      return <img className={className} src={fallbackSrc} alt={alt} />
    }
    return fallbackComponent
  }

  hasError(imgUrl) {
    return this.state.errCache.has(imgUrl)
  }

  onError = (src) => {
    const { fallbackSrc, fallbackComponent } = this.props
    if (!fallbackSrc && !fallbackComponent) {
      return
    }

    this.setState((prevState) => ({ errCache: prevState.errCache.set(src, true) }))
  }
}
