import { PureComponent, createRef } from 'react'
import classNames from 'classnames'
import './Notes.scss'
import { ImmutableSelect } from 'Manager/components'

export default class Notes extends PureComponent {
  constructor(props) {
    super(props)
    this.refSelectText = createRef()
    this.state = { selectTextWidth: 0 }
  }

  componentDidMount() {
    this.updateTextWidth()
  }

  componentDidUpdate() {
    this.updateTextWidth()
  }

  updateTextWidth = () => {
    const selectTextElem = this.refSelectText.current
    if (selectTextElem) {
      const { clientWidth: selectTextWidth } = selectTextElem
      this.setState({ selectTextWidth })
    }
  }

  render() {
    const { notes } = this.props

    if (!notes) {
      return null
    }

    return <>{notes.map((_, key) => this.renderNote(key))}</>
  }

  renderNote = (key) => {
    const { notesController } = this.props
    const { text, subject, isEmpty, lastUpdate, isEditMode, isCollapsed, isCollapsible, availableSubjects } =
      notesController.getNote(key)

    const title = isEmpty ? 'NEW EXPLANATION' : 'EXPLANATION / NOTE'

    const subjectSelectProps = {
      propKey: 'subject',
      propText: 'subject',
      propValue: 'subject',
      value: subject || '',
      disabled: !isEditMode,
      options: availableSubjects,
      onChange: (e) => notesController.setNoteSubject(key, e.currentTarget.value)
    }

    const selectText = subject || 'Select an option...'
    const editButtonClassNames = classNames('button float-right _edit-button', {
      hide: isEditMode
    })

    return (
      <div className="row m0 p10 pl20 pr20 pb0 bb1 bt1 hx-explanation-note" key={key}>
        <div className="col col-10">
          <h5 className="river-bad">{title}</h5>
        </div>
        <div className="col col-2">
          {isCollapsible && (
            <button
              onClick={(e) => notesController.toggleNote(key)}
              data-testid="notes-collapsible"
              className="button float-right _collapse-button"
            >
              <i
                className={classNames('icon bold smaller', {
                  'icon-right': isCollapsed,
                  'icon-down': !isCollapsed
                })}
              />
            </button>
          )}
          <button onClick={(e) => notesController.enableEditModeForNote(key)} className={editButtonClassNames}>
            <i className="icon bold icon-edit dimmed" />
          </button>
        </div>

        {!isCollapsed && (
          <div className="w100">
            <div className="col col-12 form-item">
              <div className="small _selectText" ref={this.refSelectText}>
                {selectText}
              </div>
              {/* <i
                className="icon bold smaller icon-circle regent-gray"
                style={{ left: `calc(${selectTextWidth}px + 10px)` }}
              /> */}
              <ImmutableSelect {...subjectSelectProps}>
                <option value="" disabled>
                  Select an option...
                </option>
              </ImmutableSelect>
              {!isEditMode && <div className="updated-at">{lastUpdate}</div>}
            </div>
            <div className="col col-12 form-item">
              <textarea
                onChange={(e) => notesController.setNoteText(key, e.currentTarget.value)}
                maxLength="250"
                placeholder="Note..."
                value={text}
                disabled={!isEditMode}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
