import { CalendarItemIcon } from 'Staff/Common'
import StatusBadge from 'Staff/Common/StatusBadge'
import "./TimeOffDetails.scss"
import { statusDetails } from 'Staff/Calendar/utils'
import { t } from 'i18n'

export const getTagDetailsByState = (state: string) => {
    switch (state) {
        case 'approved':
            return [statusDetails.approvedByManager]
        case 'denied':
            return [statusDetails.deniedByManager]
        case 'pending':
            return [statusDetails.pendingFromManager]
        default:
            return null
    }
}

interface TimeOffDetailsProps {
    timeOffsData: any[]
    timeService: any
}

export default function TimeOffDetails({ timeOffsData, timeService }: TimeOffDetailsProps) {
    const timeOffIcon = <CalendarItemIcon calendarItemType='timeOff' size='xs' />

    const renderStatusTag = (state: string) => {
        const statusDetails = getTagDetailsByState(state)
        if (statusDetails) {
            return statusDetails.map(({ label, color }, index) => <StatusBadge key={index} label={label} color={color} />)
        }
        return null
    }

    const renderDateDetails = (date: any, dateIndex: number, timeOff: any, timeOffIcon: any, timeService: any) => {
        const startsAt = date.get('startTime')
        const duration = date.get('duration')
        const endsAt = timeService.timeMoment(startsAt, 'HH:mm').add(duration, 'minutes').format('HH:mm')
        const isPartial = date.get('isPartial')
        const time = isPartial ? `${t('staffCalendar.timeOff.partialDay')}  •  ${startsAt + ' - ' + endsAt}` : `${t('staffCalendar.timeOff.fullDay')}`
        const leave_duration = `${time}`
        const timeOffDate = timeService.getDayDateMonthYear(date.get('date'))
        const timeOffType = timeOff.get('note')?.get('subject')
        const leaveDurationAndType = `${leave_duration}  •  ${timeOffType}`
        const unitManagerComment = timeOff.get('responseNote')?.get('text')
        const timeOffRequestState = timeOff.get('status')

        return (
            <div key={`${timeOff.get('id')}-${dateIndex}`} className="bottom-border">
                <div className='row time-off-details-card align-middle'>
                    <div className="icon-width">
                        {timeOffIcon}
                    </div>
                    <div className='text-width'>
                        <div>
                            <sh-text size='title-1'>
                                {timeOffDate}
                            </sh-text>
                        </div>
                        <div>
                            <sh-text size='body-2'>
                                {leaveDurationAndType}
                            </sh-text>
                        </div>
                    </div>
                    <div>
                        {renderStatusTag(timeOffRequestState)}
                    </div>
                </div>
                <div className='comment-wrapper'>
                    <sh-text size='title-1'>
                        {unitManagerComment}
                    </sh-text>
                </div>
            </div>
        )
    }

    return (
        <>
            {timeOffsData.map((timeOff, index) => (
                timeOff.get('dates').map((date: any, dateIndex: number) => renderDateDetails(date, dateIndex, timeOff, timeOffIcon, timeService))
            ))}
        </>
    )
}