import StateController from './StateController'

export default class DataController extends StateController {
  get staff() {
    return this.props.appState.getIn(['staff', 'facilityUser'])
  }

  get staffPositions() {
    return this.props.appState.getIn(['staff', 'staffPositions'])
  }

  get shifts() {
    return this.props.appState.getIn(['staff', 'shifts'])
  }

  get units() {
    return this.props.appState.getIn(['staff', 'units'])
  }

  get groups() {
    return this.props.appState.getIn(['staff', 'groups'])
  }

  get expertises() {
    return this.props.appState.getIn(['expertises', 'expertiseItems'])
  }

  get expertiseGroups() {
    return this.props.appState.getIn(['expertises', 'expertiseGroups'])
  }
}
