import { addWarningBanner } from '@humanics/he-react-common/lib/stores/bannersStore'
import { addErrorMessage } from '@humanics/he-react-common/lib/stores/messages'
import { EMAIL_PHONE_NUMBER_ERROR_CODES, USER_INVALIDATED, SCHEDULE_NOT_FOUND } from '../../utils/GraphQLErrors'

function showGenericError(error, options) {
  const showFeedbackForm = true
  addErrorMessage({ ...error, showFeedbackForm }, options)
}

function showWarningBanner(error, options) {
  addWarningBanner(error, options)
}

const typedErrorsHandlers = {
  ServiceUnavailableError: showGenericError,
  InvalidParametersError: (error, options) => {
    // These errors should be displayed in form:
    if (EMAIL_PHONE_NUMBER_ERROR_CODES.includes(error.code)) {
      return
    }
    showWarningBanner(error, options)
  },
  AccessDeniedError: (error, options) => {
    // facility user was disconnected from the system:
    if (error.code !== USER_INVALIDATED) {
      return
    }
    showWarningBanner(error, options)
  },
  LockedError: showWarningBanner,
  InvalidOutputError: showGenericError,

  AuthenticationError: (error, options) => {
    /* message will be shown on accounts side */
  },
  OperationError: showWarningBanner,
  InvalidRequestError: showGenericError,
  ConflictError: showWarningBanner,
  ApplicationError: showGenericError,
  BadRequestError: (error, options) => {
    if (SCHEDULE_NOT_FOUND.includes(error.code)) {
      return
    }
    showGenericError(error, options)
  }
}

export default typedErrorsHandlers
