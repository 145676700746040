import './DateRangeSelector.scss'
import classNames from 'classnames'

function DateRangeSelector({ date, setDate, timeService }) {
  const isNextMonthInFuture = () => {
    return date.clone().add(1, 'months') > timeService.today
  }
  const isNextYearInFuture = () => {
    return date.clone().add(1, 'years') > timeService.today
  }
  return (
    <div className="date-range-selector row">
      <span className="month-selector row align-middle">
        <i className="nano icon icon-Chevron---Left" onClick={() => setDate(date.clone().subtract(1, 'months'))} />
        <i
          className={classNames('nano icon icon-Chevron---Right', {
            disabled: isNextMonthInFuture()
          })}
          onClick={() => setDate(date.clone().add(1, 'months'))}
        />
        <sh-text size="body-1">{date.format('MMMM')}</sh-text>
      </span>
      <div className="year-selector row align-middle rounded">
        <sh-text size="body-1">{date.year()}</sh-text>
        <div className="buttons">
          <i
            className={classNames('nano icon icon-Chevron---Up', {
              disabled: isNextYearInFuture()
            })}
            onClick={() => setDate(date.clone().add(1, 'years'))}
          />
          <i className="nano icon icon-Chevron---Down" onClick={() => setDate(date.clone().subtract(1, 'years'))} />
        </div>
      </div>
    </div>
  )
}

export default DateRangeSelector
