// @ts-ignore
import fluxStore from '@humanics/he-react-common/lib/stores/fluxStore'
import { Map, fromJS } from 'immutable'
import { isFunction } from 'utils'
import { groupBy, keyBy } from 'lodash'

import { myStaffEventsQuery, myStaffDaysQuery, myStaffEventQuery } from './Queries'

const defaultState = Map({
  staffEventsForMonth: Map({
    isLoading: false,
    staffEventsMap: Map({}),
    monthStartsAt: null
  }),
  staffDaysForMonth: Map({
    isLoading: false,
    staffDaysMap: Map({}),
    monthStartsAt: null
  })
})

export default function StaffCalendarStore() {
  let gqlClient: any = null
  let timeService: any = null
  let updateStaffCalendarState: any = null

  const actions = {
    loadMyStaffEventsForMonth,
    loadMyStaffDaysForMonth,
    getStaffEventIdForShiftDay,
    getConcurrentStaffEventsForStaffEvent
  }

  return {
    initialize,
    ...actions
  }

  function initialize(state: any, context: any) {
    function _updateStaffCalendarState(state: any, key: string, value: any) {
      if (isFunction(value)) {
        return state.updateIn(['staffCalendar', key], value)
      }
      return state.setIn(['staffCalendar', key], value)
    }
    const updaters = fluxStore({
      _updateStaffCalendarState
    })

    const extendedContext = { ...context, ...updaters }

    ;({ gqlClient, facilityTime: timeService, _updateStaffCalendarState: updateStaffCalendarState } = extendedContext)
    return state.set('staffCalendar', defaultState)
  }

  function _getDateKey(startsAt: string): string {
    return timeService.timeMoment(startsAt).startOf('day').toISOString()
  }

  function _calculateStartAndEndDate(monthStartsAt: string) {
    const currentDate = timeService.timeMoment(monthStartsAt)
    const startDateMoment = currentDate.startOf('week')
    const endDateMoment = startDateMoment.clone().add(42, 'days')

    return { startDate: startDateMoment.toISOString(), endDate: endDateMoment.toISOString() }
  }

  async function loadMyStaffDaysForMonth(monthStartsAt: string) {
    const { startDate: sundayBeforeMonthStartsAt, endDate: sevenWeeksAfterStart } =
      _calculateStartAndEndDate(monthStartsAt)
    const parameters: any = {
      startDate: sundayBeforeMonthStartsAt,
      endDate: sevenWeeksAfterStart
    }
    updateStaffCalendarState('staffDaysForMonth', (staffDaysForMonthData: any) => {
      return staffDaysForMonthData.set('isLoading', true)
    })

    const { myStaffDays: staffDays } = await gqlClient.query(myStaffDaysQuery, parameters)

    const staffDaysMap = keyBy(staffDays, (staffDays) => _getDateKey(staffDays.date))

    return updateStaffCalendarState(
      'staffDaysForMonth',
      fromJS({
        isLoading: false,
        staffDaysMap,
        monthStartsAt
      })
    )
  }

  async function loadMyStaffEventsForMonth(monthStartsAt: string) {
    const { startDate: sundayBeforeMonthStartsAt, endDate: sevenWeeksAfterStart } =
      _calculateStartAndEndDate(monthStartsAt)
    const parameters: any = {
      startDate: sundayBeforeMonthStartsAt,
      endDate: sevenWeeksAfterStart
    }

    updateStaffCalendarState('staffEventsForMonth', (staffEventsForMonthData: any) => {
      return staffEventsForMonthData.set('isLoading', true)
    })

    const { myStaffEvents } = await gqlClient.query(myStaffEventsQuery, parameters)
    myStaffEvents.sort((event1: any) => {
      if (event1.type === 'assignment') {
        // assignments first
        return -1
      }
      if (event1.type === 'onCall') {
        // on call next
        return -1
      }
      return 0
    })
    const staffEventsMap = groupBy(myStaffEvents, (myStaffEvents) => _getDateKey(myStaffEvents.startsAt))

    return updateStaffCalendarState(
      'staffEventsForMonth',
      fromJS({
        isLoading: false,
        staffEventsMap,
        monthStartsAt
      })
    )
  }

  async function getStaffEventIdForShiftDay(shiftDayId: string) {
    const { myStaffEvents } = await gqlClient.query(myStaffEventsQuery, { shiftDayId: shiftDayId })
    const [staffEventForShiftDay] = myStaffEvents
    return staffEventForShiftDay?.id
  }

  async function getConcurrentStaffEventsForStaffEvent(staffEventId: string) {
    const { myStaffEvent } = await gqlClient.query(myStaffEventQuery, { id: staffEventId })
    return fromJS(myStaffEvent.concurrentStaffEvents)
  }
}
