import { PureComponent } from 'react'
import { fromJS, Map } from 'immutable'
import { ImmutableSelect, PopUp } from 'Manager/components'
import classNames from 'classnames'
import { uniq } from 'lodash'
import md5 from 'md5'
import { TimeOffDefinitionsService } from 'services'

const defaultState = { note: Map({ subject: '', text: '' }), isCollapsed: true }

export default class TimeOffRequestDetails extends PureComponent {
  state = defaultState

  componentWillReceiveProps(nextProps, nextContext) {
    const timeOffRequestId = nextProps.selection.get('timeOffRequestId')
    const prevTimeOffRequestId = this.props.selection.get('timeOffRequestId')
    const isTimeOffRequestIdChanged = timeOffRequestId !== prevTimeOffRequestId

    if (isTimeOffRequestIdChanged) {
      this.setState({ ...defaultState })
    }
  }

  onDeny = () => {
    const { denyTimeOffRequest } = this.props
    const { note } = this.state
    const subject = note.get('subject')
    const text = note.get('text')

    denyTimeOffRequest({ subject, text })
  }

  onApprove = () => {
    const { approveTimeOffRequest } = this.props
    const { note } = this.state
    const subject = note.get('subject')
    const text = note.get('text')

    approveTimeOffRequest({ subject, text })
  }

  render() {
    const { isTimeOffRequestDetailsVisible, selection } = this.props
    const timeOffRequestId = selection?.get('timeOffRequestId')

    if (!isTimeOffRequestDetailsVisible || !timeOffRequestId) {
      return null
    }

    const { onCancel, isApproving, isDenying, timeService, popupProps, scrollTop } = this.props
    const timeOffRequest = this._getSelectedTimeOffRequst()

    if (!timeOffRequest) {
      return null
    }

    const dates = timeOffRequest.get('dates').map((dateObject) => dateObject.get('date'))
    const firstDate = timeOffRequest.getIn(['dates', 0])?.get('date')
    const lastDate = timeOffRequest.getIn(['dates', dates.size - 1])?.get('date')
    const createdAt = timeOffRequest.get('createdAt')

    const firstDateMoment = timeService.timeMoment(firstDate)
    const lastDateMoment = timeService.timeMoment(lastDate)
    const createdAtMoment = timeService.timeMoment(createdAt)

    const firstDateFormat = firstDateMoment.format('ddd, MMM D')
    const lastDateFormat = lastDateMoment.format('ddd, MMM D')

    const title = dates.size === 1 ? firstDateFormat : `${firstDateFormat} - ${lastDateFormat}`

    const subtitle = `Requested on ${createdAtMoment.format('M/D/YY')} at ${createdAtMoment.format('HH:mm')}`

    const formattedDateObject = timeOffRequest.get('dates').map((dateObject) => {
      const formattedTimeMoment = timeService.timeMoment(dateObject.get('date'))
      const formattedDate = formattedTimeMoment.format('MMMM DD, YYYY')
      let formattedTime
      if (dateObject.get('isPartial')) {
        const formattedStartTime = formattedTimeMoment.add(dateObject.get('startTime'), 'hours').format('LT')
        const formattedEndTime = formattedTimeMoment.add(dateObject.get('duration'), 'minutes').format('LT')
        formattedTime = `${formattedStartTime.toLowerCase()} to ${formattedEndTime.toLowerCase()}`
      } else {
        formattedTime = 'Full time-off'
      }
      return { formattedDate, formattedTime, id: md5(`${timeOffRequestId} ${dateObject}`) }
    })

    const subject = timeOffRequest.getIn(['note', 'subject'])
    const text = timeOffRequest.getIn(['note', 'text'])
    const comment = (
      <span id={`time-off-request-id-${timeOffRequestId}-comment`}>
        {subject}
        <br />
        {text}
      </span>
    )

    const style = { width: 350 }

    return (
      <PopUp
        {...popupProps}
        scrollTop={scrollTop}
        className="popup hx-cell-details"
        onMouseEnter={this.props.onMouseEnterTimeOffRequestDetails}
        onMouseLeave={this.props.onMouseLeaveTimeOffRequestDetails}
      >
        <div style={style}>
          <div
            className="large semibold bb1 p5 row align-middle space-around-align"
            id={`time-off-request-id-${timeOffRequestId}-title`}
          >
            {title}
          </div>
          <div className="tiny gull-gray pt10 row align-middle space-around-align">{subtitle}</div>
          <div className="p20 pb0 bb1 pt10">
            <sh-text size="header-2" class="bb1 gull-gray">
              TIME-OFF REQUESTED
            </sh-text>
            <table>
              {formattedDateObject.map((dateObject) => {
                return (
                  <div className="row bb1" key={dateObject.id}>
                    <span className="col-7 gull-gray">{dateObject.formattedDate}</span>
                    <span className="col-5 gull-gray">{dateObject.formattedTime}</span>
                  </div>
                )
              })}
            </table>
          </div>
          <div className="p20 pb10 pt10">
            <div className="row gull-gray">
              <sh-text size="header-2" class="gull-gray">
                COMMENT
              </sh-text>
            </div>
            <div className="row">
              <div>
                <sh-text>{comment}</sh-text>
              </div>
            </div>
          </div>

          {this._renderNote()}

          <footer className="p20">
            <button
              className="button compact secondary borderless ph0"
              disabled={isApproving || isDenying}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className={classNames({
                'button float-right compact bg-ufo-green': true,
                loading: isApproving
              })}
              disabled={isApproving || isDenying}
              onClick={this.onApprove}
            >
              Approve
            </button>
            <span className="float-right">&nbsp;</span>

            <button
              className={classNames({
                'button float-right compact primary danger': true,
                loading: isDenying
              })}
              disabled={isApproving || isDenying}
              onClick={this.onDeny}
            >
              Deny
            </button>
          </footer>
        </div>
      </PopUp>
    )
  }

  _renderNote() {
    const { isCollapsed, note } = this.state
    const text = note.get('text')
    const subject = note.get('subject')

    const options = noteSubjectOptions.map((o) => ({ subject: o }))
    const _updateNote = (noteParameters) => {
      const newNote = note.merge(noteParameters)
      this.setState({ note: newNote })
    }

    const subjectSelectProps = {
      disabled: false,
      onChange: (e) => _updateNote({ subject: e.currentTarget.value }),
      options: fromJS(options),
      propKey: 'subject',
      propText: 'subject',
      propValue: 'subject',
      value: subject
    }

    return (
      <div
        data-testid="time-off-request-explanation-note"
        className="row bg-porcelain m0 p10 pl20 pr20 pb0 bb1 bt1 hx-explanation-note"
      >
        <div className="col col-10">
          <sh-text size="header-2" class="gull-gray">
            EXPLANATION / NOTE
          </sh-text>
        </div>
        <div className="col col-2">
          <button
            data-testid="time-off-request-explanation-collapsible"
            onClick={() => this.setState({ isCollapsed: !isCollapsed })}
            className="button float-right _collapse-button"
          >
            <i
              className={classNames('icon bold', {
                'icon-plus': isCollapsed,
                'icon-minus': !isCollapsed
              })}
            />
          </button>
        </div>

        {!isCollapsed && (
          <div className="w100">
            <div className="col col-12 form-item">
              <ImmutableSelect {...subjectSelectProps}>
                <option value="" disabled>
                  Select an option...
                </option>
              </ImmutableSelect>
            </div>
            <div className="col col-12 form-item">
              <textarea
                onChange={(e) => _updateNote({ text: e.currentTarget.value })}
                placeholder="Note..."
                value={text}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  _getSelectedTimeOffRequst() {
    const { timeOffCalendar, selection } = this.props
    const staffPath = selection.get('staffPath')
    const timeOffRequestId = selection.get('timeOffRequestId')
    return timeOffCalendar.getStaffTimeOffRequest({ ...staffPath, timeOffRequestId })
  }
}

const paidTimeOffOptions = TimeOffDefinitionsService.buildPaidTimeOffOptions()
const unavailabilityOptions = TimeOffDefinitionsService.buildUnavailabilityOptions()

const noteSubjectOptions = uniq(paidTimeOffOptions.concat(unavailabilityOptions)) //These are note options that SC can use while approving a request. Since a request can contain paid and unpaid days, having note options for both types.
