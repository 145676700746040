import StaffEventDetails from './StaffEventDetails'
import './DayDetails.scss'
import { Map, List, Set } from 'immutable'

export interface IDayDetailsProps {
  usDate: string
  timeService: any
  staffEvents?: List<Map<string, any>>
  publicStaffMap: Map<string, Map<string, any>>
  unitsMap: any
  staffEventTypeFilters?: Set<string>
  shiftsMap: Map<string, Map<string, any>>
}

export default function DayDetails({
  timeService,
  usDate,
  unitsMap,
  staffEvents,
  publicStaffMap,
  staffEventTypeFilters,
  shiftsMap
}: IDayDetailsProps) {
  return (
    <div className="h100 hx-box-shadow bg-white-smoke">
      <div className="row align-middle pl10 header">
        <sh-text size="super-header">{timeService.getDayMonthYearForUsDate(usDate)}</sh-text>
      </div>
      <div className="p10">
        {renderStaffEventsDetails(staffEvents, unitsMap, shiftsMap, timeService, publicStaffMap, staffEventTypeFilters)}
      </div>
    </div>
  )
}

function renderStaffEventsDetails(
  staffEvents: List<Map<string, any>> | undefined | null,
  unitsMap: Map<string, Map<string, any>>,
  shiftsMap: Map<string, Map<string, any>>,
  timeService: any,
  publicStaffMap: Map<string, Map<string, any>>,
  staffEventTypeFilters?: Set<string>
) {
  let filteredStaffEvents = staffEvents
  if (staffEventTypeFilters) {
    filteredStaffEvents = staffEvents
      ?.filter((staffEvent) => staffEventTypeFilters.has(staffEvent?.get('type')))
      .toList()
  }
  if (!filteredStaffEvents || filteredStaffEvents.size === 0) {
    return (
      <div className="pt30 pb30">
        <sh-empty-state icon="calendar" label="No events for the day" />
      </div>
    )
  }
  return filteredStaffEvents
    .map((staffEvent, i) => {
      if (!staffEvent) {
        return null
      }
      const toDisplay = [
        <StaffEventDetails
          key={`event-details-${staffEvent.get('id')}`}
          staffEvent={staffEvent}
          unitsMap={unitsMap}
          timeService={timeService}
          publicStaffMap={publicStaffMap}
          shiftsMap={shiftsMap}
        />
      ]
      if (i !== filteredStaffEvents!.size - 1) {
        toDisplay.push(<sh-divider key={`event-divider-${staffEvent.get('id')}`} spacing="l" />)
      }
      return toDisplay
    })
    .toArray()
}
