import { Map } from 'immutable'
import { map } from 'lodash'
import { RequestError } from 'entityWrappers'
import { ShiftService } from 'services'
import OptimisticActionController from './OptimisticActionController'
import { EXPERTISE_MISMATCH_ERROR } from '../../../utils/GraphQLErrors'
import { getEventStartTime, getStaffEventIndex } from '../CellDetails/cellDetailsUtils'

export default class ActionController extends OptimisticActionController {
  getSelectedEventsShiftId() {
    const { cells } = this.selectedObjects
    const lastCell = cells.last()
    const lastCellStaffEvent = lastCell.get('staffEvents')?.get(0) || Map()

    return lastCellStaffEvent.get('shiftId')
  }

  addTimeOffs = async (eventVariant, note, startsAt, duration, isPartialTimeOff) => {
    if (!eventVariant) {
      return
    }

    const { staff, shiftDays, shift } = this.selectedObjects
    const unitEventVariantId = eventVariant.get('id')
    const selectedUnitId = shift.get('unitId')

    const staffEvents = this._buildTimeOffStaffEvents(
      staff,
      shiftDays,
      selectedUnitId,
      startsAt,
      duration,
      isPartialTimeOff
    )
    const options = { displayError: false }

    const action = this.calendarActionsService.createStaffEvents(staffEvents, unitEventVariantId, note, options, true)

    const typeMetaData = this._getEventTypeMetaData(staffEvents[0], eventVariant)
    this.applyAddTimeOffOptimisticResult(staffEvents, { note, ...typeMetaData })

    return this.executeActions({ actions: [action] })
  }

  changeTimeOffs = async (eventVariant, note, startsAt, duration, isPartialTimeOff) => {
    if (!eventVariant) {
      return
    }

    const { cells, staff, shiftDays, shift } = this.selectedObjects
    const unitEventVariantId = eventVariant.get('id')
    const selectedUnitId = shift.get('unitId')
    const staffEventsToDelete = cells.reduce(
      (memo, cell) =>
        memo.concat({
          staffEventId: cell.get('staffEvents')?.get(getStaffEventIndex(cell, 'timeOff'))?.get('id')
        }),
      []
    )
    const newStaffEvents = this._buildTimeOffStaffEvents(
      staff,
      shiftDays,
      selectedUnitId,
      startsAt,
      duration,
      isPartialTimeOff
    )
    const options = { displayError: false }
    const action = this.calendarActionsService.updateStaffTimeOffs(
      staffEventsToDelete,
      newStaffEvents,
      unitEventVariantId,
      note,
      options
    )

    const typeMetaData = this._getEventTypeMetaData(newStaffEvents[0], eventVariant)
    this.applyChangeTimeOffOptimisticResult(newStaffEvents, { note, ...typeMetaData })

    return this.executeActions({ actions: [action] })
  }

  // NOTE: this can't be used now because when we update a note
  //       the identity hash of related event is not updated:
  // updateTimeOffNotes = note => {
  //   const { cells } = this.selectedObjects;
  //   const noteIds = cells.reduce((memo, cell) => {
  //   const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map();
  //     const noteIds = cellStaffEvent.get('noteIds');

  //     if (!noteIds || noteIds.size === 0) return memo;

  //     const noteId = noteIds.last();
  //     return memo.concat(noteId);
  //   }, []);

  //   if (noteIds.length === 0) return;

  //   const notes = noteIds.map(id => ({ ...note, id }));
  //   const action = this.calendarActionsService.updateNotes(notes);

  //   return this.executeActions([action]);
  // }

  deleteSelectedStaffEvents = (staffEventId) => {
    const { cells } = this.selectedObjects
    let staffEvents
    const extraInfo = {}
    if (!staffEventId || cells.size > 1) {
      staffEvents = cells.reduce((prev, cell) => {
        const cellStaffEvent = cell.get('staffEvents')?.get(0) || Map()
        const isAvatarCell = cell.get('isAvatarCell')
        staffEventId = cellStaffEvent.get('id')
        if (isAvatarCell) {
          const facilityUsersMap = this.props.activeDateRange.get('facilityUsersMap')
          const otherStaffList = this.props.appState.getIn(['calendar', 'otherStaff'])
          const staffId = cell.get('staff')

          extraInfo.addStaffToOnCallShift = true
          extraInfo.staff = facilityUsersMap.get(staffId) || otherStaffList.find((staff) => staff.get('id') === staffId)
        }
        return prev.concat({ staffEventId })
      }, [])
    } else {
      staffEvents = [{ staffEventId }]
    }

    const options = { displayError: false }
    const action = this.calendarActionsService.deleteStaffEvents(staffEvents, options)

    this.applyDeleteOptimisticResult()

    return this.executeActions({ actions: [action], extraInfo })
  }

  changeStaffEventForSelection = async (eventParameters, note) => {
    const eventVariants = this.props.unit.get('eventVariants')
    const eventVariantId = eventParameters.get('eventVariantId')
    const staffEventShiftId = eventParameters.get('shiftId')
    const eventVariant = eventVariants.find((variant) => variant.get('id') === eventVariantId)
    const { cells, staff, days } = this.selectedObjects

    const staffEventsToDelete = cells.reduce((memo, cell) => {
      const staffEventIndex = this.getStaffEventIndex(cell, staffEventShiftId)
      return memo.concat({
        staffEventId: cell.get('staffEvents')?.get(staffEventIndex)?.get('id')
      })
    }, [])

    const newStaffEvents = this._buildStaffEvents(staff, eventParameters, days)

    const options = { displayError: false }
    const action = this.calendarActionsService.changeShiftForStaffEvents(
      staffEventsToDelete,
      newStaffEvents,
      eventVariantId,
      note,
      options
    )

    const typeMetaData = this._getEventTypeMetaData(newStaffEvents[0], eventVariant)
    this.applyChangeShiftOptimisticResult(newStaffEvents, {
      staffEventShiftId,
      note,
      ...typeMetaData
    })

    return this.executeActions({ actions: [action], eventShiftId: staffEventShiftId })
  }

  getStaffEventIndex = (cell, staffEventShiftId) => {
    let staffEventIndex = 0
    if (staffEventShiftId) {
      staffEventIndex = cell
        .get('staffEvents')
        ?.toJS()
        ?.findIndex((e) => e.shiftId === staffEventShiftId)
    }
    return staffEventIndex
  }

  updateEventForSelection = (eventParameters, { type }, newNote, updatedNotes) => {
    const startTime = eventParameters.get('startTime')
    const duration = eventParameters.get('duration')
    const eventShiftId = eventParameters.get('shiftId')

    const { timeService } = this.props
    const { cells, days } = this.selectedObjects

    const staffEvents = days.reduce((memo, day, index) => {
      const cell = cells.get(index)
      const staffEventIndex = this.getStaffEventIndex(cell, eventShiftId)
      const cellStaffEvent = cell.get('staffEvents')?.get(staffEventIndex) || Map()
      const dayStartsAt = day.get('dateTime')
      const staffEventId = cellStaffEvent?.get('id')
      const shiftId = cellStaffEvent?.get('shiftId')
      const shiftService = new ShiftService(Map({ startTime }), timeService)
      const { startsAt } = shiftService.getShiftPeriodForDate(dayStartsAt)
      return memo.concat({ staffEventId, startsAt, duration, shiftId })
    }, [])

    const groupsOfSimilarNotes = map(updatedNotes, (note, index) => {
      const { subject, text } = note

      const ids = cells.reduce((memo, cell) => {
        const staffEventIndex = this.getStaffEventIndex(cell, eventShiftId)
        return memo.concat(cell.get('staffEvents')?.get(staffEventIndex)?.getIn(['noteIds', index]))
      }, [])
      return { ids, text, subject }
    })

    const options = { displayError: false }

    const action = this.calendarActionsService.updateStaffEventsWithNotes(
      staffEvents,
      newNote,
      groupsOfSimilarNotes,
      options
    )

    this.applyUpdateShiftOptimisticResult(staffEvents, {
      newNote,
      updatedNotes,
      eventType: type
    })

    return this.executeActions({ actions: [action] })
  }

  updateEvent = (eventParameters) => {
    const startsAt = eventParameters.get('startsAt')
    const duration = eventParameters.get('duration')
    const staffEventId = eventParameters.get('staffEventId')

    const staffEvent = {
      staffEventId,
      startsAt,
      duration
    }

    const action = this.calendarActionsService.updateStaffEvents([staffEvent])

    return this.executeDayModeActions([action])
  }

  createStaffEventsForSelection = (eventParameters, note, omitExpertiseVerification) => {
    const eventVariants = this.props.unit.get('eventVariants')
    const eventVariantId = eventParameters.get('eventVariantId')
    const eventVariant = eventVariants.find((variant) => variant.get('id') === eventVariantId)
    const addStaffToOnCallShift = eventParameters.get('addStaffToOnCallShift')
    let staff
    if (addStaffToOnCallShift) {
      staff = eventParameters.get('staff')
    } else {
      staff = this.selectedObjects.staff
    }
    const { days } = this.selectedObjects
    const staffEvents = this._buildStaffEvents(staff, eventParameters, days)
    const options = { displayError: false }
    const action = this.calendarActionsService.createStaffEvents(
      staffEvents,
      eventVariantId,
      note,
      options,
      omitExpertiseVerification
    )

    const staffEventShiftId = eventParameters.get('shiftId')
    const typeMetaData = this._getEventTypeMetaData(staffEvents[0], eventVariant)
    this.applyAddStaffEventOptimisticResult(staffEvents, {
      staffEventShiftId,
      note,
      ...typeMetaData,
      addStaffToOnCallShift,
      staff
    })

    return this.executeActions({
      actions: [action],
      eventShiftId: staffEventShiftId,
      note,
      extraInfo: { staff, addStaffToOnCallShift }
    })
  }

  buildShiftsPathsToReload(newEventShiftId) {
    const { shiftPath: selectedShiftPath, shift: selectedShift } = this.selectedObjects
    const selectedShiftId = selectedShift.get('id')
    const originalEventShiftId = this.getSelectedEventsShiftId()

    let newEventShiftPath
    if (newEventShiftId) {
      const isSelectedShift = selectedShiftId === newEventShiftId
      if (!isSelectedShift) {
        newEventShiftPath = this.buildShiftPathToReload(newEventShiftId)
      }
    }

    let originalEventShiftPath
    if (originalEventShiftId) {
      const isSelectedShift = selectedShiftId === originalEventShiftId
      if (!isSelectedShift) {
        originalEventShiftPath = this.buildShiftPathToReload(originalEventShiftId)
      }
    }

    return [selectedShiftPath, newEventShiftPath, originalEventShiftPath].filter(
      (reloadParameters) => !!reloadParameters
    )
  }

  buildShiftPathToReload(shiftId) {
    const shift = this.calendar.findShiftById(shiftId)

    if (shift) {
      const roleIndex = shift.get('roleIndex')
      const shiftIndex = shift.get('shiftIndex')
      return { roleIndex, shiftIndex }
    }

    return null
  }

  static isShiftMismatchedRequirements(error) {
    const { graphQLErrors } = error
    if (!!graphQLErrors && graphQLErrors.length === 1) {
      const error = graphQLErrors[0]
      const { code } = error
      return code === EXPERTISE_MISMATCH_ERROR
    }
    return false
  }

  static getShiftMismatchedRequirementsDetails(error) {
    const { graphQLErrors } = error
    if (!!graphQLErrors && graphQLErrors.length === 1) {
      const error = graphQLErrors[0]
      const { context } = error
      return context
    }
    return []
  }

  handleCreateShiftError = (error, eventShiftId, selection, note) => {
    const context = ActionController.getShiftMismatchedRequirementsDetails(error)
    this.component.setExpertiseMismatchDetails(context, eventShiftId, selection, note)
    this.component.popupController.showRequirementsMismatchPopup()
  }

  executeActions = async ({ actions, eventShiftId, note, extraInfo }) => {
    const selection = this.selectedObjects
    const shiftPaths = this.buildShiftsPathsToReload(eventShiftId)

    let isFailedToFetch = false
    let isUnauthorized = false
    let isShiftMismatchedRequirements = false
    try {
      for (const action of actions) {
        await action
      }
    } catch (error) {
      isUnauthorized = RequestError.isUnathorized(error)
      isFailedToFetch = RequestError.isFailedToFetch(error)
      isShiftMismatchedRequirements = ActionController.isShiftMismatchedRequirements(error)
      if (isShiftMismatchedRequirements) {
        this.handleCreateShiftError(error, eventShiftId, selection, note)
      }
      this.restoreOriginalState(selection)
      if (!isUnauthorized && !isShiftMismatchedRequirements) {
        this.higlightActionError(error, selection)
      }
    }

    if (!isFailedToFetch && !isUnauthorized) {
      await this.reloadCalendarForSelection(selection, shiftPaths, extraInfo)
      if (extraInfo?.staff) {
        const { staff } = extraInfo
        const facilityUserId = staff.get('id')
        const { shiftIdsByFacilityUserId } = this.calendar
        const primaryShiftIds = shiftIdsByFacilityUserId.get(facilityUserId)
        primaryShiftIds?.forEach(async (shiftId) => {
          const shift = this.calendar.findShiftById(shiftId)
          if (shift) {
            await this.reloadCalendarForShift({ shift, userIds: [facilityUserId] })
          }
        })
      }
    }

    this.resetSelectionMetadata(selection)
  }

  executeDayModeActions = async (actions) => {
    let isFailedToFetch = false
    let isUnauthorized = false
    try {
      for (const action of actions) {
        await action
      }
    } catch (error) {
      isUnauthorized = RequestError.isUnathorized(error)
      isFailedToFetch = RequestError.isFailedToFetch(error)
    }

    if (!isFailedToFetch && !isUnauthorized) {
      await this.reloadDayCalendar()
    }
  }

  _buildStaffEvents(staff, eventParameters, days) {
    const { timeService } = this.props
    const userId = staff.get('id')
    const shiftService = new ShiftService(eventParameters, timeService)
    const shiftParameters = shiftService.getShiftParameters()
    const { unitId, shiftId, duration } = shiftParameters

    return days.reduce((memo, day) => {
      const dayStartsAt = day.get('dateTime')
      const { startsAt } = shiftService.getShiftPeriodForDate(dayStartsAt)

      return memo.concat({ unitId, userId, startsAt, duration, shiftId })
    }, [])
  }

  _buildTimeOffStaffEvents(staff, shiftDays, selectedCellUnitId, startsAt, duration, isPartialTimeOff = false) {
    const { timeService } = this.props
    const userId = staff.get('id')
    let startTime = '+00:00'
    if (startsAt) {
      const dateTime = timeService.timeMoment(startsAt).startOf('day').toISOString()
      startTime = getEventStartTime(startsAt, dateTime, timeService)
    }
    const eventParameters = Map({
      duration,
      eventVariantId: 'timeOff',
      length: duration,
      unitId: selectedCellUnitId,
      startTime,
      shiftId: null
    })
    const shiftService = new ShiftService(eventParameters, timeService)

    return shiftDays.reduce((memo, shiftDay) => {
      const shiftDayStartsAt = shiftDay.get('startsAt')
      const unitId = selectedCellUnitId
      const dayDuration = 24 * 60
      const defaultDuration = dayDuration
      const defaultStartsAt = timeService.timeMoment(shiftDayStartsAt).startOf('day').toISOString()
      const { startsAt } = shiftService.getShiftPeriodForDate(shiftDayStartsAt)
      const staffEvent = {
        unitId,
        userId,
        startsAt: startsAt || defaultStartsAt,
        duration: duration || defaultDuration,
        isPartialTimeOff
      }

      return memo.concat(staffEvent)
    }, [])
  }

  _getEventTypeMetaData(staffEvent, eventVariant) {
    const { duration } = staffEvent
    const eventType = eventVariant.get('type')
    const paidTimePercentage = eventVariant.getIn(['timeOffOptions', 'paidTimePercentage'])
    const hasTimeOffOptions = eventVariant.get('timeOffOptions')
    const paidMinutes = hasTimeOffOptions && (duration / 100) * paidTimePercentage
    const timeOffAttributes = { paidMinutes }

    return { eventType, timeOffAttributes }
  }
}
