import { PureComponent } from 'react'
import './StickyHeader.scss'

export default class StickyHeader extends PureComponent {
  render() {
    const { role, width, renderRole, getRowHeight } = this.props

    const roleRowHeight = getRowHeight(role)

    const height = roleRowHeight

    return (
      <div className="hx-grid-header" style={{ height, width }}>
        {role && renderRole(role, { height: roleRowHeight })}
      </div>
    )
  }
}
