import DayDetails from '../DayDetails'
import { Set } from 'immutable'

function ShiftAssignmentsDetails({ timeService, usDate, unitsMap, staffEvents, publicStaffMap, shiftsMap }: any) {
  return (
    <DayDetails
      timeService={timeService}
      usDate={usDate}
      unitsMap={unitsMap}
      staffEvents={staffEvents}
      publicStaffMap={publicStaffMap}
      staffEventTypeFilters={Set(['assignment', 'onCall'])}
      shiftsMap={shiftsMap}
    />
  )
}

export default ShiftAssignmentsDetails
