import { Map } from 'immutable'
import MultiSelectFilter from '../MultiSelectFilter'

const getShiftTypesDefinition = (unitShiftTypes) => {
  return {
    minWidth: 100,
    options: unitShiftTypes,
    deselectAllAllowed: true
  }
}

export default function ShiftTypesSelector(props) {
  const { shiftTypes, values, setShiftType: setConfig, ...rest } = props

  const options = shiftTypes.reduce((memo, shiftType) => {
    const title = shiftType.get('title')
    const shiftTypeId = shiftType.get('id')
    return memo.set(shiftTypeId, title)
  }, Map())

  const multiSelectFilterProps = {
    ...getShiftTypesDefinition(options),
    setConfig,
    values,
    name: 'Shift',
    field: 'shift',
    deselectAllAllowed: false,
    ...rest
  }

  return <MultiSelectFilter {...multiSelectFilterProps} />
}
