import { fromJS } from 'immutable'
import { keyBy } from 'lodash'
import { shiftsQuery, staffManagersQuery } from '../Queries'

function FacilityData() {
  let gqlClient = null
  let updateGeneralData = null
  const actions = { loadManagers, loadUnitsShifts }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, updateGeneralData } = context)
  }

  async function loadManagers() {
    const parameters = {
      limit: 999,
      roleIds: ['unitManager', 'facilityAdministrator']
    }

    const { facilityUsers: staffManagers } = await gqlClient.query(staffManagersQuery, parameters)

    const staffManagersMap = keyBy(staffManagers, 'id')

    return updateGeneralData((generalData) =>
      generalData.mergeIn(['facility'], {
        isManagersMapLoaded: true,
        staffManagersMap: fromJS(staffManagersMap)
      })
    )
  }

  async function loadUnitsShifts(unitIds) {
    const { shifts } = await gqlClient.query(shiftsQuery, {
      unitIds,
      limit: 999
    })
    const unitShifts = shifts.filter((shift) => !shift.isClass)
    const shiftsMap = keyBy(unitShifts, 'id')

    return updateGeneralData((generalData) =>
      generalData.mergeIn(['facility'], {
        isShiftsMapLoaded: true,
        shiftsMap: fromJS(shiftsMap)
      })
    )
  }
}

export default FacilityData()
