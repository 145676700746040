import { PureComponent } from 'react'
import classNames from 'classnames'

export default class Loader extends PureComponent {
  render() {
    const { isLoading } = this.props

    return (
      <section className={classNames('loader', { hide: !isLoading })}>
        <div className="loading" />
      </section>
    )
  }
}
