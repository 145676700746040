export function sortByFullname(staffA, staffB) {
  const fullNameA = `${staffA.getIn(['profile', 'firstName'])} ${staffA.getIn(['profile', 'lastName'])}`.toLowerCase()
  const fullNameB = `${staffB.getIn(['profile', 'firstName'])} ${staffB.getIn(['profile', 'lastName'])}`.toLowerCase()

  let comparison = 0
  if (fullNameA > fullNameB) {
    comparison = 1
  } else if (fullNameB > fullNameA) {
    comparison = -1
  }
  return comparison
}

export function sort(staffA, staffB) {
  const sortedByWeight = calculateSortWeightByEmploymentType(staffA) - calculateSortWeightByEmploymentType(staffB)
  return sortedByWeight || sortByFullname(staffA, staffB)
}

// export function sortFacilityUsersByEvents(facilityUsers, shiftDay) {
//   return facilityUsers.sort((staffA, staffB) => {
//     const sortedByWeight =
//       calculateSortWeightByEvents(staffA, shiftDay) -
//       calculateSortWeightByEvents(staffB, shiftDay);
//     return sortedByWeight || sortByFullname(staffA, staffB);
//   });
// }

export function sortShiftUsersByEligibility(facilityUsers, shiftFacilityUserIds, unitId) {
  return facilityUsers.sort((staffA, staffB) => {
    const sortedByWeight =
      calculateSortWeightByEligibility(staffA, shiftFacilityUserIds, unitId) -
      calculateSortWeightByEligibility(staffB, shiftFacilityUserIds, unitId)
    return sortedByWeight || sortByFullname(staffA, staffB)
  })
}

export function sortShiftUsersByEmploymentType(facilityUsers) {
  return facilityUsers.sort((staffA, staffB) => {
    const sortedByWeight = calculateSortWeightByEmploymentType(staffA) - calculateSortWeightByEmploymentType(staffB)
    return sortedByWeight || sortByFullname(staffA, staffB)
  })
}

// export function calculateSortWeightByEvents(facilityUser, shiftDay) {
//   const userId = facilityUser.get('userId');
//   const staffShiftDay = new StaffShiftDay(userId, shiftDay);
//   const {
//     isPTO,
//     isTimeOff,
//     isScheduled,
//     isClassType,
//     isCancelledType,
//     isPrimaryShiftAssignment
//   } = staffShiftDay.staffEvent;

//   const isSecondary = isScheduled && !isPrimaryShiftAssignment;

//   const isFloat = false; // TODO

//   let sortWeight = 1;

//   if (isPrimaryShiftAssignment) sortWeight = 10;
//   if (isSecondary) sortWeight = 11;
//   if (isClassType) sortWeight = 12;
//   if (isFloat) sortWeight = 13;
//   // divider
//   if (isCancelledType) sortWeight = 14;
//   if (isTimeOff) sortWeight = 15;
//   if (isPTO) sortWeight = 16;

//   return sortWeight;
// }

export function calculateSortWeightByEligibility(facilityUser, shiftFacilityUserIds, unitId) {
  let sortWeight = 0
  if (shiftFacilityUserIds.includes(facilityUser.get('userId'))) {
    sortWeight = 1
  } else {
    sortWeight = 2
  }
  if (facilityUser.get('homeUnitId') !== unitId) {
    sortWeight = 10
  }

  return sortWeight
}

export function calculateSortWeightByEmploymentType(facilityUser) {
  const weightMap = ['fullTime', 'partTime', 'perDiem']

  const userEmploymentType = facilityUser.getIn(['staffProfile', 'employmentType'])

  return weightMap.indexOf(userEmploymentType)
}
