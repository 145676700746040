import { fromJS } from 'immutable'
import { notesByDateRangeQuery, secondaryStaffCalendarCellsQuery, readAlgoScheduleQuery } from '../Queries'
import {
  updateOtherStaffSection,
  updatePrimaryStaffSections,
  updateKpiResultForAutoSchedule
} from './UpdateCalendarData'
import { GQLDataLoader } from 'utils'

export default LoadCalendarCells()

function LoadCalendarCells() {
  let gqlClient = null
  let updateCalendar = null

  return {
    initialize,
    actions: {
      loadPrimaryStaffCells,
      loadOtherStaffCells,
      loadNotes
    }
  }

  function initialize(context) {
    ;({ updateCalendar, gqlClient } = context)
  }

  async function loadOtherStaffCells(parameters, paths) {
    const { userIds, unitId, startDate, endDate } = parameters
    const params = { userIds, unitId, startDate, endDate }
    const loader = new GQLDataLoader(gqlClient)
    try {
      const secondaryStaffCalendarCells = await loader.loadByChunks(
        'secondaryStaffCalendarCells',
        secondaryStaffCalendarCellsQuery,
        params,
        'userIds'
      )

      return updateCalendar((calendar) => updateOtherStaffSection(calendar, secondaryStaffCalendarCells, paths))
    } catch (ex) {
      // if there is an error like network or something
      // it will wait 3secs to update the section.isLoading flag
      // this will make calendar to re-try to load again.
      setTimeout(() => {
        const sectionsPaths = [...paths.shiftsPaths, paths.otherStaffPath]
        updateCalendar(setCalendarSectionsAreLoading(sectionsPaths))
      }, 3000)
    }
  }

  async function loadNotes(userIds, startDate, endDate) {
    const parameters = { userIds, startDate, endDate }
    const loader = new GQLDataLoader(gqlClient)
    const notes = await loader.loadByChunks('notesByDateRange', notesByDateRangeQuery, parameters, 'userIds')

    return updateCalendar((calendar) => {
      const notesReducer = (memo, value) => memo.set(value.get('id'), value)
      return calendar.update('notes', (current) => fromJS(notes).reduce(notesReducer, current))
    })
  }

  async function loadPrimaryStaffCells(parameters, paths) {
    const { userIds, shiftIds, unitId, scheduleId } = parameters
    const readAlgoScheduleParamters = {
      userIds: userIds || [],
      shiftIds,
      unitId,
      scheduleId,
      withCalendarResults: true
    }

    try {
      const {
        algoSchedule: { result, kpi }
      } = await gqlClient.query(readAlgoScheduleQuery, readAlgoScheduleParamters)
      return updateCalendar((autoScheduleCalendar) => {
        const updatedCalendar = updateKpiResultForAutoSchedule(autoScheduleCalendar, kpi)
        return updatePrimaryStaffSections(updatedCalendar, shiftIds, result, paths)
      })
    } catch (ex) {
      setTimeout(() => {
        updateCalendar(setCalendarSectionsAreLoading(paths.shiftsPaths))
      }, 3000)
    }
  }

  function setCalendarSectionsAreLoading(sectionsPaths) {
    const setSectionIsLoading = (calendar, sectionPath) => calendar.setIn([...sectionPath, 'isLoading'], false)

    return (calendar) => sectionsPaths.reduce(setSectionIsLoading, calendar)
  }
}
