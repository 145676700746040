import { overrideUnitShiftTypesMutation, overrideUnitRolesMutation } from './Mutations'
import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [
  Field('name', 'Name'),
  Field('hppdTarget', 'HPPD Target'),
  Field('beds', 'Beds'),
  Field('staffingPeriod', 'Staffing Period'),
  Field('updatedAt', 'Modified')
]

function UnitStore() {
  const store = AdminStore({
    fields,
    entityName: 'Unit',
    api: {
      index: 'indexUnits'
    },
    header: { title: 'Units' },
    search: { field: 'name', placeholder: 'Search for units' }
  })

  function loadUnits() {
    return store.loadDataAction()
  }

  function overrideUnitShiftTypes(unitId, shiftTypes) {
    const gqlClient = store.getGQLClient()
    const parameters = { unitId, shiftTypes }

    return gqlClient.query(overrideUnitShiftTypesMutation, parameters)
  }

  function overrideUnitRoles(unitId, roles) {
    const gqlClient = store.getGQLClient()
    const parameters = { unitId, roles }

    return gqlClient.query(overrideUnitRolesMutation, parameters)
  }

  return {
    ...store,
    hasCrud: (operation, ...args) => {
      if (operation === 'delete') {
        return false
      }
      return store.hasCrud(operation, ...args)
    },
    loadUnits,
    overrideUnitRoles,
    overrideUnitShiftTypes
  }
}

export default UnitStore
