import { Component } from 'react'
import { navigate } from 'Navigation'
import { curry } from 'i18n'
import { CrudCard, CrudCardAccordion, CrudCardItem } from 'Manager/components'
import { deepRemoveProperty, getDaysToExpire } from './utils'

const t = curry('staff.staffExpertiseCard.')

class ExpertiseCard extends Component {
  render = () => {
    const { cardTitle, createExpertisePath, isCollapsible } = this.props

    return (
      <CrudCard
        title={cardTitle}
        createItemTooltip={this.createExpertiseTooltip}
        createItemPath={createExpertisePath}
        emptyCardMessage={this.emptyCardMessage}
        separatorColor="#ECECEC"
        isCollapsible={isCollapsible}
      >
        {this.renderCardChildren()}
      </CrudCard>
    )
  }

  renderCardChildren = () => {
    const { isGrouped, staffExpertiseForest, expertiseType } = this.props
    if (!staffExpertiseForest) {
      return null
    }

    const filteredGroups = Object.values(staffExpertiseForest).filter((group) => group.type === expertiseType)
    let cardContents = null
    if (isGrouped) {
      cardContents = filteredGroups.map((group) => (
        <CrudCardAccordion key={group.id} title={group.title} separatorColor="#F2F4F5">
          {group.expertises.map((expertise) => this.renderCardItem(expertise))}
        </CrudCardAccordion>
      ))
    } else {
      cardContents = filteredGroups.map((group) => group.expertises.map((expertise) => this.renderCardItem(expertise)))
      cardContents = [].concat.apply([], cardContents)
    }
    return cardContents
  }

  renderCardItem = (expertise) => {
    const {
      getExpertiseTitle,
      getExpertiseInfo,
      editExpertisePath,
      withExpiryIndicator,
      withImage,
      handleDelete: externalDeleteHandler,
      timeService
    } = this.props

    const daysToExpire = getDaysToExpire(expertise, timeService)

    return (
      <CrudCardItem
        key={expertise.get('id')}
        id={expertise.get('id')}
        title={getExpertiseTitle(expertise)}
        info={getExpertiseInfo(expertise)}
        deleteItemHandler={externalDeleteHandler || this.handleDelete}
        deleteItemTooltip={this.deleteExpertiseTooltip}
        editItemPath={editExpertisePath(expertise.get('id'))}
        editItemTooltip={this.editExpertiseTooltip}
        icon={withExpiryIndicator && this.getExpiryIconType(expertise)}
        imgUrl={!withExpiryIndicator && withImage && (expertise.get('imageUrl') || '#')}
        imgAltText={expertise.get('name') || expertise.get('title')}
        disabled={withExpiryIndicator && daysToExpire < 0}
      />
    )
  }

  getExpiryIconType = (expertise) => {
    const { timeService } = this.props
    const daysToExpire = getDaysToExpire(expertise, timeService)
    if (daysToExpire < 0) {
      return 'error'
    }
    if (daysToExpire <= 3) {
      return 'warning'
    }
    return 'success'
  }

  handleDelete = async (expertiseId, expertiseTitle) => {
    const { updateStaff, userId, Dialog, expertiseType } = this.props

    const isDeleteExpertiseConfirmed = await Dialog.confirm(expertiseTitle, {
      type: 'warning',
      title: t(`${expertiseType}DeleteConfirmation.title`),
      accept: t(`${expertiseType}DeleteConfirmation.accept`)
    })

    if (!isDeleteExpertiseConfirmed) {
      return
    }

    const updatedStaffExpertiseCredentials = this.removeExpertiseFromCredentials(expertiseId)

    try {
      await updateStaff({ userId, expertiseCredentials: updatedStaffExpertiseCredentials })
    } catch (err) {
      navigate.from.StaffDetails.to.StaffDetails(undefined, false)
    }
    navigate.from.StaffDetails.to.StaffDetails(undefined, false)
  }

  removeExpertiseFromCredentials = (expertiseId) => {
    const { staffExpertiseCredentials } = this.props
    return staffExpertiseCredentials
      .filterNot((staffExpertiseCredential) => staffExpertiseCredential.get('expertiseId') === expertiseId)
      .toJS()
      .map((staffExpertiseCredential) => deepRemoveProperty(staffExpertiseCredential, '__typename'))
  }

  get createExpertiseTooltip() {
    const { expertiseType } = this.props
    return t(`${expertiseType}Add`)
  }

  get editExpertiseTooltip() {
    const { expertiseType } = this.props
    return t(`${expertiseType}Edit`)
  }

  get deleteExpertiseTooltip() {
    const { expertiseType } = this.props
    return t(`${expertiseType}Delete`)
  }

  get emptyCardMessage() {
    const { expertiseType } = this.props
    return t(`${expertiseType}NotFound`)
  }
}

export default ExpertiseCard
