import { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Admin, Header } from '@humanics/he-react-common/lib/admin/components'
import { Grid } from 'Admin/common'
import { allIdns } from 'Admin/services/IdnsService'
import { List } from 'immutable'

export default class Facilities extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { idnId: '', idns: [], fields: null, apiInitialized: false }
  }

  componentDidMount() {
    const { appState, store } = this.props

    ;(async () => {
      const api = await store.getApi()
      Promise.all([store.getFields(), allIdns(appState, api)]).then(([fields, idns]) => {
        this.setState({ apiInitialized: true, fields, idns })
      })
    })()
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState, store } = this.props
    const { idns: prevIdns } = this.state
    const facilities = appState.getIn(['authentication', 'facilities'], List())
    const prevFacilityIds = prevIdns
      .reduce((memo, idn) => {
        const facilityIds = List(idn.facilities.map((facility) => facility.id))
        return memo.concat(facilityIds)
      }, List())
      .sort()
    const facilityIds = facilities.map((facility) => facility.get('id')).sort()
    if (!prevFacilityIds.equals(facilityIds)) {
      ;(async () => {
        const api = await store.getApi()
        const idns = await allIdns(appState, api)
        this.setState({ apiInitialized: true, idns })
      })()
    }
  }

  render() {
    const { store, location, stores, createOperationId, updateOperationId, subRoutes, match } = this.props

    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }

    const { header } = store.getAdminInfo()
    const allowCreate = store.hasCrud('create', createOperationId)
    const allowUpdate = store.hasCrud('update', updateOperationId)
    const linkTo = location.pathname.replace(/\/$/, '') + '/new'
    const { idns } = this.state
    const idn = idns.find((idn) => idn.id === this.state.idnId)
    const { facilities = [] } = idn || {}

    const actions = allowCreate ? (
      <Link to={linkTo} className="button secondary">
        {header.createLabel || `Create ${header.label}`}
      </Link>
    ) : null
    const onRowClick = this.props.onRowClick || ((e) => e.preventDefault())
    return (
      <Admin>
        <Header title={header.title} actions={actions} />
        <Grid
          data={facilities}
          fields={this.state.fields}
          allowUpdate={allowUpdate}
          onRowClick={onRowClick}
          stores={stores}
          currentPath={match.url}
        >
          <div className="col-3">
            <select defaultValue={this.state.idnId} onChange={(e) => this.setState({ idnId: e.target.value })}>
              <option value={''}>Select IDN...</option>
              {idns.map((idn) => (
                <option value={idn.id} key={idn.id}>
                  {idn.fullName}
                </option>
              ))}
            </select>
          </div>
        </Grid>
        {subRoutes}
      </Admin>
    )
  }
}
