import { Component } from 'react'
import './Content.scss'

export default class Content extends Component {
  render() {
    return (
      <tbody>
        <tr>
          <td>
            <div className="print-content">{this.props.children}</div>
          </td>
        </tr>
      </tbody>
    )
  }
}
