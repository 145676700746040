import { Component } from 'react'
import { convertFromRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import EditButtons from './EditButtons/EditButtons'
import './TextPreviewComponent.scss'
import TextIcon from '../../../../styles/web_v3/text.svg'
import NavigationBar from './NavigationBar'

export default class TextPreviewComponent extends Component {
  state = {
    isHovering: false
  }

  render() {
    const { component, activeComponent, hasDetailsPage, isFormEditable, contentToSave } = this.props

    const {
      insertDetailsComponentAbove,
      insertDetailsComponentBelow,
      deleteDetailsComponent,
      onDetailsComponentClicked
    } = this.props

    const currentContent = convertFromRaw(JSON.parse(contentToSave))

    const editorDivStyle = {
      padding: '1.5em 38px 0.5em',
      wordWrap: 'break-word'
    }

    const textIconPlaceholderStyle = {
      width: '374px',
      height: '322px',
      marginLeft: '7%'
    }

    const extendedPreviewTextfieldStyle = {
      position: 'relative',
      background: 'white',
      width: '100%'
    }

    const extendedPreviewTextfieldUnclickedStyle = {
      position: 'relative',
      width: '100%'
    }

    const editorHtmlStyle = {
      margin: '0',
      padding: '0'
    }

    return (
      // adds highlight grey background behind selected component
      <div
        className={
          component.id === activeComponent.id && isFormEditable
            ? 'extended-preview-textfield'
            : 'extended-preview-textfield-unclicked'
        }
        style={
          component.id === activeComponent.id ? extendedPreviewTextfieldStyle : extendedPreviewTextfieldUnclickedStyle
        }
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
      >
        {/* Blue navigation bar on the left */}
        {component.id === activeComponent.id && isFormEditable && <NavigationBar />}

        {/* Text div */}
        <div className="editor-div" style={editorDivStyle} onClick={hasDetailsPage ? onDetailsComponentClicked : null}>
          {currentContent.hasText() ? (
            <div
              style={editorHtmlStyle}
              dangerouslySetInnerHTML={{
                __html: draftToHtml(JSON.parse(contentToSave))
              }}
            />
          ) : (
            <img
              className="text-icon-placeholder"
              style={textIconPlaceholderStyle}
              src={TextIcon}
              alt=""
              onClick={hasDetailsPage ? onDetailsComponentClicked : null}
            />
          )}
        </div>

        {/* Buttons */}
        {this.state.isHovering && hasDetailsPage && isFormEditable && (
          <div>
            <EditButtons
              insertDetailsComponentAbove={insertDetailsComponentAbove}
              insertDetailsComponentBelow={insertDetailsComponentBelow}
              deleteDetailsComponent={deleteDetailsComponent}
            />
          </div>
        )}
      </div>
    )
  }
}
