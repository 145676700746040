import './DeleteComponentButton.scss'
import deleteIcon from '../../../../../styles/web_v3/delete.svg'

export default function DeleteComponentButton({ deleteDetailsComponent }) {
  return (
    <div>
      <button onClick={deleteDetailsComponent}>
        <span class="del-img">
          <img alt="" src={deleteIcon} />
        </span>
      </button>
    </div>
  )
}
