import { PureComponent } from 'react'
import { withAppContext } from 'AppContext'
import DateRangeService from 'services/DateRangeService'
import { isEnabledFeature, isFacilityEnabledFeature, isUsDate } from 'utils'
import stores from 'stores'
import { curry } from 'i18n'
import { addSuccessBanner } from 'stores'
const t = curry('calendar.autoSchedule.')

const { generalStore, algoScheduleStore } = stores

const NOTIFICATION_DELAY = 5 // 5sec
const NOTIFICATION_RERESH_INTERVAL = 60 // 60sec = 1min
const ALGO_POLLING_TIME = 5 //sec

export class ManagerLayout extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { usDate: null, unitUrlId: null }
  }

  static defaultProps = {
    notificationsLoadDelay: NOTIFICATION_DELAY,
    notificationsLoadInterval: NOTIFICATION_RERESH_INTERVAL
  }

  static getDerivedStateFromProps(props, state) {
    const { usDate, unitUrlId } = state
    const { date: nextUsDate, componentId: nextComponentId, unitUrlId: nextUnitUrlId } = props.match.params

    const isUnitIdChanged = unitUrlId !== nextUnitUrlId
    if (isUnitIdChanged) {
      ManagerLayout._loadGeneralData(nextUnitUrlId, nextUsDate, nextComponentId, props)
    }

    const hasDateRange = !!nextUsDate && isUsDate(nextUsDate)
    if (hasDateRange) {
      const { appState } = props
      const activeDateRange = appState.getIn(['generalData', 'activeDateRange'])
      const activeDateRangeUsDate = activeDateRange.get('usDate')

      const isDateChanged = nextUsDate !== usDate
      const shouldUpdateDate = activeDateRangeUsDate !== nextUsDate

      if (isDateChanged && shouldUpdateDate) {
        ManagerLayout._updateActiveDateRange(props)
      }
    }

    return { unitUrlId: nextUnitUrlId, usDate: nextUsDate }
  }

  static _updateActiveDateRange = async (props) => {
    const { appState } = props

    const unit = appState.getIn(['generalData', 'unit'])
    const isSchedulesLoaded = unit.get('isSchedulesLoaded')

    if (isSchedulesLoaded) {
      const { timeService, match } = props
      const { date } = match.params
      const dateRange = new DateRangeService(unit, timeService, date).getDateRange()

      generalStore.setActiveDateRange(dateRange)
    }
  }

  static _loadGeneralData = async (
    unitUrlId,
    date,
    componentId,
    { notificationsLoadDelay, notificationsLoadInterval }
  ) => {
    const shouldPreloadData = componentId === 'calendar'

    date = date && isUsDate(date) ? date : null

    const appState = await generalStore.loadGeneralData(unitUrlId, date, shouldPreloadData)
    const unitId = appState.getIn(['generalData', 'unit', 'id'])
    if (isEnabledFeature(appState, 'notifications')) {
      // will wait {notificationsLoadDelay}secs once general data is loaded to fetch notifications.
      setTimeout(async () => {
        generalStore.refreshNotifications(unitId)
        ManagerLayout._refreshNotifications(unitId, notificationsLoadInterval)
      }, notificationsLoadDelay * 1000)
    }
    if (isFacilityEnabledFeature(appState, 'auto_schedule')) {
      // will wait {notificationsLoadDelay}secs once general data is loaded to fetch Any Running Algo Status.
      setTimeout(async () => {
        const resp = await algoScheduleStore.indexAlgoScheduleState({ unitId })
        const runningSchedules = resp.algoScheduleState.filter((item) => item.status !== 'COMPLETED')
        const completedSchedules = resp.algoScheduleState.filter((item) => item.status === 'COMPLETED')
        if (resp && runningSchedules?.length > 0) {
          ManagerLayout._getAlgoSchedulesStatus(unitId)
        }
        if (completedSchedules?.length > 0) {
          addSuccessBanner({ message: t('autoScheduleCreated') })
        }
      }, notificationsLoadDelay * 1000)
    }
  }

  static _refreshNotifications = (unitId, notificationsLoadInterval = NOTIFICATION_RERESH_INTERVAL) => {
    if (ManagerLayout._notificationsTimeout) {
      clearTimeout(ManagerLayout._notificationsTimeout)
    }
    ManagerLayout._notificationsTimeout = setTimeout(async () => {
      await generalStore.refreshNotifications(unitId)
      ManagerLayout._notificationsTimeout = null
      ManagerLayout._refreshNotifications(unitId, notificationsLoadInterval)
    }, notificationsLoadInterval * 1000)
  }

  static _getAlgoSchedulesStatus = (unitId, algoPollingInterval = ALGO_POLLING_TIME) => {
    if (ManagerLayout._algoSchedulesStatusTimeout) {
      clearTimeout(ManagerLayout._algoSchedulesStatusTimeout)
    }
    ManagerLayout._algoSchedulesStatusTimeout = setTimeout(async () => {
      const resp = await algoScheduleStore.indexAlgoScheduleState({ unitId })
      const runningSchedules = resp.algoScheduleState.filter((item) => item.status !== 'COMPLETED')
      const completedSchedules = resp.algoScheduleState.filter((item) => item.status === 'COMPLETED')
      if (resp && runningSchedules?.length > 0) {
        ManagerLayout._getAlgoSchedulesStatus(unitId, algoPollingInterval)
      } else if (completedSchedules?.length > 0) {
        addSuccessBanner({ message: t('autoScheduleCreated') })
      }
    }, algoPollingInterval * 1000)
  }

  componentDidMount() {
    window.addEventListener('visibilitychange', this.handleVisibilityChange)
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      const { unit } = this.props
      generalStore.refreshNotifications(unit.get('id'))
    }
  }

  render() {
    const { children } = this.props

    return <>{children}</>
  }

  componentWillUnmount() {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange)
  }
}

export default withAppContext(ManagerLayout)
