interface IEmptyStateCard {
  emptyStateLabel: string,
  selectedShift: any
}

const EmptyStateCard = ({ emptyStateLabel, selectedShift }: IEmptyStateCard) => {
  const height = selectedShift.size > 0 ? '38.4em' : '44.8em'
  return (
    <div>
      {emptyStateLabel.length > 0 && (
        <div style={{ height }}>
          <sh-empty-state icon="calendar" label={emptyStateLabel} />
        </div>
      )}
    </div>
  )
}

export default EmptyStateCard
