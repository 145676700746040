import constants from './constants'
const { daysList, weekDaysList, weekDaysFull, weekDaysShort, recurringTypes } = constants

const form = {
  ruleType: {
    definition: {
      label: 'Repeats',
      required: true,
      type: 'string',
      withLabel: false,
      customPlaceHolder: '-- Repeats --',
      items: {
        enum: recurringTypes
      }
    },
    field: 'ruleType',
    formItemClasses: 'col-8 pr10'
  },
  ruleEvery: {
    definition: {
      label: '',
      inputType: 'InputNumber',
      min: 1,
      max: 99,
      order: 'i+-',
      type: 'arrows',
      required: true
    },
    field: 'ruleEvery',
    formItemClasses: 'col-1 pr10',
    inputType: 'InputNumber',
    max: 99
  },
  shiftTypes: {
    definition: {
      label: 'Shift',
      required: true,
      type: 'string',
      withLabel: true,
      customPlaceHolder: 'day',
      items: {
        enum: {}
      }
    },
    field: 'shiftTypes',
    formItemClasses: 'col-12'
  },
  shiftStartTime: {
    definition: {
      label: 'Shift Start Time',
      required: true,
      type: 'date',
      withLabel: true,
      flatpickrOptions: {
        static: true
      }
    },
    field: 'shiftStartTime',
    formItemClasses: 'col-3 pr30'
  },
  shiftEndTime: {
    definition: {
      label: 'Shift End Time',
      required: true,
      type: 'date',
      withLabel: true,
      flatpickrOptions: {
        static: true
      }
    },
    field: 'shiftEndTime',
    formItemClasses: 'col-3 pr30'
  },
  scheduleStartDate: {
    definition: {
      label: 'Schedule Start Date',
      required: true,
      type: 'date',
      withLabel: true,
      flatpickrOptions: {
        static: true
      }
    },
    dateFormatHint: 'YYYY-MM-DD',
    field: 'scheduleStartDate',
    formItemClasses: 'col-6 pr30'
  },
  scheduleEndDate: {
    definition: {
      label: 'Schedule End Date',
      required: false,
      type: 'date',
      withLabel: true,
      flatpickrOptions: {
        static: true
      }
    },
    dateFormatHint: 'YYYY-MM-DD',
    field: 'scheduleEndDate',
    formItemClasses: 'col-6 pr30'
  },
  weekSchedule: {
    definition: {
      required: true,
      type: 'string',
      withLabel: false,
      items: {
        enum: weekDaysShort
      }
    },
    field: 'weekSchedule',
    formItemClasses: 'col-7'
  },
  daySelection: {
    definition: {
      required: true,
      type: 'radio',
      withLabel: false
    },
    field: 'daySelection',
    key: 'daySelection',
    inputClassName: 'col-2 pr5'
  },
  dayOnSelection: {
    definition: {
      required: true,
      type: 'string',
      withLabel: false,
      customPlaceHolder: 'day',
      items: {
        enum: daysList
      }
    },
    field: 'dayOnSelection',
    formItemClasses: 'col-7 pr5'
  },
  weekSelection: {
    definition: {
      required: true,
      type: 'radio',
      withLabel: false
    },
    field: 'weekSelection',
    key: 'weekSelection',
    inputClassName: 'col-2 pr5'
  },
  weekOnSelection: {
    definition: {
      required: true,
      type: 'string',
      withLabel: false,
      customPlaceHolder: 'week',
      items: {
        enum: weekDaysList
      }
    },
    field: 'weekOnSelection',
    formItemClasses: 'col-4 pr5'
  },
  weekDaySelection: {
    definition: {
      required: true,
      type: 'string',
      withLabel: false,
      customPlaceHolder: 'day',
      items: {
        enum: weekDaysFull
      }
    },
    field: 'weekDaySelection',
    formItemClasses: 'col-6'
  }
}

export default form
