import { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Map } from 'immutable'
import { navigate } from 'Navigation'
import { Avatar } from 'Manager/components'
import StaffItemStatus from './StaffItemStatus'
import stores from 'stores'

const PHONE_NUMBER_REGEX = /^(\+)?(\d{0,3})(\d{3})(\d{3})(\d{4})$/

export default class StaffItem extends Component {
  static propTypes = {
    facilityStaff: PropTypes.instanceOf(Map).isRequired,
    namespace: PropTypes.string
  }

  static defaultProps = {
    namespace: ''
  }

  static formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/-/g, '')
    const match = cleanedPhoneNumber.match(PHONE_NUMBER_REGEX)

    if (!match) {
      return phoneNumber
    }

    const domesticNumber = `(${match[3]}) ${match[4]}-${match[5]}`
    let result

    if (match[2]) {
      result = `${match[2] ? (match[1] || '+') + match[2] : ''} ${domesticNumber}`
    } else {
      result = domesticNumber
    }

    return result
  }

  render() {
    const { facilityStaff, namespace, timeService, displayNameAs } = this.props

    const profile = facilityStaff.get('profile') || Map()
    const staffProfile = facilityStaff.get('staffProfile') || Map()
    const facilityProfile = facilityStaff.get('facilityProfile') || Map()
    const facilityProfileWithAvatarUrl = facilityProfile.set('avatarUrl', profile.get('avatarUrl'))

    const fullName =
      !displayNameAs || displayNameAs === 'firstNameLastName'
        ? facilityStaff.get('fullName')
        : facilityStaff.get('fullNameRevers')
    const email = facilityProfile.get('email')
    let phoneNumber = facilityProfile.get('phoneNumber')
    phoneNumber = phoneNumber ? StaffItem.formatPhoneNumber(phoneNumber) : ''
    const userId = facilityStaff.get('userId')
    const position = staffProfile.get('position')
    const date = stores.timeService().todayUsDate
    const mode = 'full'
    const detailsUrl = navigate.from.Staff.to.StaffDetails({ userId, date, mode }, false)

    return (
      <li className={namespace}>
        <Link to={detailsUrl}>
          <aside className="col-4 pr10">
            <Avatar profile={facilityProfileWithAvatarUrl} userId={userId} />
            <div data-testid="staff-item-full-name" className="_fullName">
              {fullName}
              <div className="small regent-gray">{position}</div>
            </div>
          </aside>
          <div className="col col-3 row align-middle pr10">{email}</div>
          <div className="col col-2 row align-middle phone pr10">{phoneNumber}</div>
          <div className="col col-2 row align-middle">{this.props.children}</div>

          <div className="col col-1 row align-middle">
            <StaffItemStatus facilityStaff={facilityStaff} timeService={timeService} />
          </div>
        </Link>
      </li>
    )
  }
}
