import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { pick } from 'lodash'
import { Modal } from 'Common/components'
import PrintModalFooter from './PrintModalFooter'
import { navigate } from 'Navigation'
import './PrintModal.scss'
import classNames from 'classnames'

class PrintModal extends Component {
  constructor(props) {
    super(props)
    this.state = { printNotes: false, mode: 'full', selectedDay: null }
  }

  onClose = () => {
    this.props.resetDefaultGeneratePdfJobState()
    navigate.from.Calendar.to.Calendar()
  }

  render() {
    const { unit, filterShiftTypes, filterRoleIds, activeDateRange, timeService } = this.props
    if (!activeDateRange.get('duration')) {
      return null
    }

    const roles = unit.get('roles')

    const { printNotes, mode, selectedDay } = this.state

    const unitName = unit.get('name')

    let shiftTypes = ''
    let staffRoles = ''

    const hasDayShiftType = filterShiftTypes.includes('DAY')
    const hasNightShiftType = filterShiftTypes.includes('NIGHT')

    switch (true) {
      case hasDayShiftType && hasNightShiftType:
        shiftTypes = 'All'
        break

      case hasDayShiftType:
        shiftTypes = 'Day'
        break

      case hasNightShiftType:
        shiftTypes = 'Night'
        break

      default:
    }

    switch (true) {
      case filterRoleIds.size === roles.size:
        staffRoles = 'All'
        break

      case filterRoleIds.size === 1: {
        const role = roles.find((role) => role.get('id') === filterRoleIds.get(0))
        staffRoles = role?.get('name')
        break
      }
      default:
        staffRoles = `${filterRoleIds.size} roles selected`
    }

    const buttonClassName = 'button compact secondary col-5'

    const fullModeButtonClassName = classNames(`button-tab-left ${buttonClassName}`, {
      'filled bg-dodgerblue _active': mode === 'full'
    })

    //TODO : Uncomment when bringing back pdf option for day view
    // const dayModeButtonClassName = classNames(`button-tab-right ${buttonClassName}`, {
    //   'filled bg-dodgerblue _active': mode === 'day'
    // });

    let selectedRange

    const startsAt = timeService.timeMoment(activeDateRange.get('startsAt'))
    const endsAt = timeService.timeMoment(activeDateRange.get('endsAt')).add(-1, 'minute')

    if (mode === 'full') {
      selectedRange = `${startsAt.format('ddd, MMM D')} - ${endsAt.format('ddd, MMM D')}`
    }

    const today = timeService.today
    const defaultDay = today.isBetween(startsAt, endsAt) ? today : startsAt

    const footerParams = pick(
      {
        ...this,
        ...this.props,
        ...this.state,
        view: mode,
        date: selectedDay || defaultDay.toISOString()
      },
      ['print', 'onClose', 'printNotes', 'printStatus', 'generateSchedulePdf', 'view', 'date']
    )

    return (
      <div>
        <Modal visible={true} title="Print Calendar" onClose={this.onClose} width={440} classes="hx-print-modal">
          <div className="hx-schedule-print">
            <section className="form form-checkboxes p20">
              <div className="row gutters align-middle">
                <div className="col-5 pr20 text-right">
                  <label>
                    <span className="">View</span>
                  </label>
                </div>
                <div className="col-7 form-item">
                  <div className="row">
                    <button
                      onClick={(e) => this._selectPrintMode('full')}
                      className={fullModeButtonClassName}
                      // TODO : Remove the styling when bringing back pdf option for day view
                      style={{ borderRadius: '6px' }}
                    >
                      Full
                    </button>
                    {/* TODO : Uncomment when bringing back pdf option for day view */}
                    {/* <button
                      onClick={e => this._selectPrintMode('day')}
                      className={dayModeButtonClassName}
                    >
                      Day
                    </button> */}
                  </div>
                </div>
                <div className="col-5 pb10 pt10" />
                <div className="col-7 form-item pt10">
                  {mode === 'full' && selectedRange}
                  {/* TODO : Uncomment when bringing back pdf option for day view */}
                  {/* {mode === 'day' && (
                    <CustomDateInput
                      {...dateInputProps}
                      timeService={timeService}
                      onChange={this._selectDayToPrint}
                    />
                  )} */}
                </div>
                <div className="col-12 pt10" />
                <div className="col-5 pr20 text-right">
                  <label>
                    <span className="">Unit</span>
                  </label>
                </div>
                <div className="col-7">{unitName}</div>

                <div className="col-5 pr20 text-right">
                  <label>
                    <span className="">Shift</span>
                  </label>
                </div>
                <div className="col-7">{shiftTypes}</div>

                <div className="col-5 pr20 text-right">
                  <label>
                    <span className="">Staff</span>
                  </label>
                </div>
                <div className="col-7">{staffRoles}</div>

                <div className="col-5 pr20 text-right">
                  <label>
                    <span className="">Include</span>
                  </label>
                </div>
                <div className="col-7">
                  <div className="hx-checkbox">
                    <input
                      type="checkbox"
                      id="include-all-notes"
                      checked={printNotes}
                      onChange={this._togglePrintNotes}
                    />
                    <label htmlFor="include-all-notes">Notes and explanations</label>
                  </div>
                </div>
              </div>
            </section>

            <PrintModalFooter {...footerParams} />
          </div>
        </Modal>
      </div>
    )
  }

  _selectDayToPrint = (selectedDay) => {
    this.setState({ selectedDay })
  }

  _selectPrintMode = (mode) => {
    this.setState({ mode })
  }

  _togglePrintNotes = () => {
    const { printNotes } = this.state
    this.setState({ printNotes: !printNotes })
  }

  _formatDate = (d) => {
    const { timeService } = this.props
    return timeService.dateTimeToMoment(d).startOf('day').calendar(null, {
      sameDay: '[Today], MMM D',
      nextDay: '[Tomorrow], MMM D',
      nextWeek: 'MMM D',
      lastDay: '[Yesterday], MMM D',
      lastWeek: 'MMM D',
      sameElse: 'MMM D'
    })
  }
}

export default withRouter(PrintModal)
