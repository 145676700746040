import { PureComponent } from 'react'
import { Avatar } from 'Manager/components'
import StaffPreferences from 'entityWrappers/StaffPreferences'
import StaffShiftDays from './StaffShiftDays'
import { DateRangeService } from 'services'
import VDetails from './VDetails'
import { navigate } from 'Navigation'
import { formatPhoneNumber } from 'utils'
import { Map } from 'immutable'
import './Staff.scss'

export default function VStaff({
  staff,
  style,
  selection,
  activeDateRange,
  scheduleState,
  onCellEvent,
  showPopup,
  onMouseMoveOverGridCell,
  onMouseLeave,
  cellsPathsInProcessing,
  updateCellTooltip,
  unitUrlId,
  displayNameAs,
  indicators,
  shiftTime,
  mode,
  date,
  otherStaffMap,
  facilityShiftsMap,
  isPrintView,
  notes,
  calendarType,
  timeService
}) {
  const isShiftView = staff.get('isShiftView')
  let profileComponent = (
    <VProfile
      staff={staff}
      unitUrlId={unitUrlId}
      scheduleState={scheduleState}
      displayNameAs={displayNameAs}
      mode={mode}
      date={date}
      calendarType={calendarType}
      activeDateRange={activeDateRange}
      timeService={timeService}
    />
  )

  if (isShiftView) {
    profileComponent = <aside className="_staffMeta _staffProfile col-2 bt1" />
  }
  return (
    <div
      data-test-id="hx-calendar-v-staff"
      className={`_staff hx-calendar-staff row ${isShiftView ? 'on-call-avatar-row' : ''}`}
      data-staff-id={staff.get('id')}
      style={style}
    >
      {profileComponent}
      <StaffShiftDays
        indicators={indicators}
        shiftTime={shiftTime}
        showPopup={showPopup}
        activeDateRange={activeDateRange}
        staff={staff}
        selection={selection}
        onCellEvent={onCellEvent}
        cellsPathsInProcessing={cellsPathsInProcessing}
        onMouseMoveOverGridCell={onMouseMoveOverGridCell}
        onMouseLeave={onMouseLeave}
        updateCellTooltip={updateCellTooltip}
        shiftName={staff.get('shiftName')}
        staffPath={{
          roleIndex: staff.get('roleIndex'),
          shiftIndex: staff.get('shiftIndex'),
          staffIndex: staff.get('staffIndex')
        }}
        otherStaffMap={otherStaffMap}
        facilityShiftsMap={facilityShiftsMap}
        isPrintView={isPrintView}
        notes={notes}
        calendarType={calendarType}
        mode={mode}
        style={style}
        timeService={timeService}
      />
    </div>
  )
}

class VProfile extends PureComponent {
  state = { isStaffDetailsShown: false }

  handleMouseEnter = () => {
    this.timer = setTimeout(
      () =>
        this.setState({
          isStaffDetailsShown: true
        }),
      500
    )
  }

  handleMouseLeave = () => {
    global.clearTimeout(this.timer)
    this.setState({
      isStaffDetailsShown: false
    })
  }

  navigateToFacilityUser = () => {
    const { staff, unitUrlId, date, mode } = this.props
    const userId = staff.get('id')

    navigate.from.Home.to.StaffDetails({ userId, unitUrlId, date, mode })
  }

  getStaffCurrentWeekWorkingHours = (staff, activeDateRange, timeService) => {
    const weekFirstCellIndex = DateRangeService.getFirstDayIndexForCurrentWeek(activeDateRange, timeService)
    const cells = staff?.get('cells')
    let maximumWorkingHours = 0

    if (cells) {
      for (let i = weekFirstCellIndex; i < weekFirstCellIndex + 7; i++) {
        const cell = cells.get(i)
        if (cell) {
          maximumWorkingHours = this.getMaxWorkingHours(cell)
        }
      }
    }
    return maximumWorkingHours / 60
  }

  render() {
    const { staff, scheduleState, displayNameAs, calendarType, activeDateRange, mode, timeService } = this.props
    const userId = staff.get('id')
    const fullName =
      !displayNameAs || displayNameAs === 'firstNameLastName' ? staff.get('fullName') : staff.get('fullNameRevers')
    const staffProfile = staff.get('staffProfile') || Map()
    const profile = staff.get('profile') || Map()
    const phoneNumber = staff.getIn(['profile', 'phoneNumber'])
    const phoneNumber1 = staffProfile.get('phoneNumber1')
    const phoneNumber2 = staffProfile.get('phoneNumber2')
    const phoneNumbers = [...new Set([phoneNumber, phoneNumber1, phoneNumber2])]
      .filter((phone) => phone)
      .map(formatPhoneNumber)

    const preferences = staff.get('preferences')
    const { preferenceNames } = new StaffPreferences(preferences)
    const detailsProps = {
      phoneNumbers,
      preferenceNames,
      hasPreferences: preferences && preferences.size > 0
    }
    const isOpen = scheduleState === 'open'
    const isUnavailabilitySubmitted = staff.get('isUnavailabilitySubmitted')
    const showCheckmark = isOpen && isUnavailabilitySubmitted
    const isWeekMode = mode === 'week'

    // TODO add hrs logic from `src/js/Manager/Calendar/Grid/Role/Shift/Staff/Profile/Summary/Summary.js`
    // Should we do these calculations on backend as well?

    const avatarProps = {
      userId,
      profile,
      visible: staff.get('isShiftLoaded'),
      renderVisibleOnly: true
    }

    const additionalEventHandler = calendarType === 'calendar' ? { onDoubleClick: this.navigateToFacilityUser } : {}
    let currentWeekStaffHours = this.getStaffCurrentWeekWorkingHours(staff, activeDateRange, timeService)
    let expectedWeeklyStaffHours = staff?.get('staffProfile')?.get('maximumNumberOfHoursPerWeek')
    const staffProfileDataComponent = (
      <div className="bg-alabaster hx-calendar-staff-info-wrapper bt1">
        <Avatar {...avatarProps} />
        <div className="staff-meta">
          {fullName}
          {showCheckmark && (
            <div className="small regent-gray float-right">
              <i className="icon-checkmark ufo-green" />
            </div>
          )}
          <div className="small regent-gray">
            {`${staff.get('type')}  ·  `}
            {calendarType === 'calendar' && (
              <span>
                {isWeekMode ? `${currentWeekStaffHours} / ${expectedWeeklyStaffHours}` : staff.get('hrs')} hrs
              </span>
            )}
          </div>
        </div>
      </div>
    )

    const StaffProfileContainer = isWeekMode ? 'div' : 'aside'

    return (
      <>
        <StaffProfileContainer
          className="_staffMeta _staffProfile col-2"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          {...additionalEventHandler}
        >
          {staffProfileDataComponent}
        </StaffProfileContainer>
        {this.state.isStaffDetailsShown && <VDetails {...detailsProps} />}
      </>
    )
  }

  getMaxWorkingHours(cell) {
    let maximumWorkingHours = 0
    const staffEventsArray = cell.getIn(['staffEvents'])

    if (staffEventsArray) {
      staffEventsArray.forEach((staffevent) => {
        if (staffevent.get('isAssignment') !== null) {
          maximumWorkingHours += staffevent.get('duration')
        }
      })
    }
    return maximumWorkingHours
  }
}
