import { fromJS, List, Map } from 'immutable'
import { NotesController } from '../Notes'
import { getStaffEventIndex } from '../cellDetailsUtils'

const emptyNote = { isEditMode: true, isCollapsed: true }

export default class StaffEventNotesController extends NotesController {
  get defaultState() {
    const { emptyStaffEventNotes: StaffEventNotes } = this
    return {
      ...super.defaultState,
      StaffEventNotes
    }
  }

  get emptyStaffEventNotes() {
    const { shiftsMap } = this.props
    const eventNoteTemplates = shiftsMap.set('meeting', {})
    return eventNoteTemplates.map(() => fromJS([emptyNote]))
  }

  get note() {
    const { newNote } = this

    if (!newNote) {
      return null
    }

    const subject = newNote.get('subject')
    const text = newNote.get('text')

    return { subject, text }
  }

  get noteToAdd() {
    return this.note
  }

  get notesToUpdate() {
    const { cell } = this.props
    const { selectedEventTemplateId } = this.state

    const staffEventIndex = getStaffEventIndex(cell, selectedEventTemplateId)
    const cellStaffEvent = cell.get('staffEvents')?.get(staffEventIndex) || Map()
    const noteIds = cellStaffEvent?.get('noteIds') || List()
    const noteIndexesMap = noteIds.reduce((memo, noteId, index) => {
      memo[noteId] = index
      return memo
    }, {})

    return this.updatedNotes.reduce((memo, note) => {
      const id = note.get('id')
      const text = note.get('text')
      const subject = note.get('subject')
      const noteIndex = noteIndexesMap[id]

      memo[noteIndex] = { subject, text, id }
      return memo
    }, {})
  }

  get StaffEventNotes() {
    const { StaffEventNotes } = this.state
    const { isMeetingEvent, shiftId } = this.getEventTemplate()
    const key = isMeetingEvent ? 'meeting' : shiftId
    return StaffEventNotes.get(key)
  }

  set StaffEventNotes(notes) {
    const { StaffEventNotes } = this.state
    const { isMeetingEvent, shiftId } = this.getEventTemplate()
    const key = isMeetingEvent ? 'meeting' : shiftId
    const newStaffEventNotes = StaffEventNotes.set(key, notes)

    this.setState({ StaffEventNotes: newStaffEventNotes })
  }

  buildNotesState(notes) {
    const { cell } = this.props
    const { selectedEventTemplateId } = this.state

    const staffEventIndex = getStaffEventIndex(cell, selectedEventTemplateId)
    const cellStaffEvent = cell.get('staffEvents')?.get(staffEventIndex) || Map()
    const staffEventShiftId = cellStaffEvent.get('shiftId')
    const isMeeting = cellStaffEvent.get('isMeeting')
    const noteKey = isMeeting ? 'meeting' : staffEventShiftId

    notes = notes || []
    notes = notes.concat(emptyNote)
    notes = fromJS(notes)

    const StaffEventNotes = this.emptyStaffEventNotes.set(noteKey, notes)

    return { StaffEventNotes }
  }
}
