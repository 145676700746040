import { curry } from 'i18n'
const t = curry('staff.staffExpertiseCard.')

export function getDaysToExpire(expertise, timeService) {
  const expirationDateTime = expertise.get('staffExpertiseCredential')?.get('expiresAt')
  if (!expirationDateTime) {
    return
  }
  const expirationMoment = timeService.dateTimeToMoment(expirationDateTime)
  return expirationMoment?.startOf('day').diff(timeService.today, 'days')
}

export function getExpiryMessage(expertise, timeService) {
  const daysToExpire = getDaysToExpire(expertise, timeService)

  if (daysToExpire < 0) {
    return (
      <span key={0} style={{ color: 'red' }}>
        {' '}
        / Expired
      </span>
    )
  }
  if (daysToExpire === 0) {
    return (
      <span key={0} style={{ color: '#F2CD35' }}>
        {' '}
        / Expires today
      </span>
    )
  }
  if (daysToExpire <= 3) {
    const daySuffix = daysToExpire === 1 ? 'day' : 'days'
    return (
      <span key={0} style={{ color: '#F2CD35' }}>
        {' '}
        / {`Expires in ${daysToExpire} ${daySuffix}`}
      </span>
    )
  }
  return null
}

export function getExpertiseInfo(expertise, timeService) {
  const staffExpertiseCredential = expertise.get('staffExpertiseCredential')
  const number = staffExpertiseCredential.get('metadata')?.get('number')
  const organization = staffExpertiseCredential.get('issuedBy')
  const expirationDateTime = staffExpertiseCredential.get('expiresAt')

  const format = 'll'
  const formattedExpirationDate = expirationDateTime && timeService.timeMoment(expirationDateTime).format(format)

  const expiryMessage = getExpiryMessage(expertise, timeService)

  let infoItems = []

  if (number) {
    infoItems.push(`${t('numberLabel')}: ${number}`)
  }
  if (organization) {
    infoItems.push(`${t('organizationLabel')}: ${organization}`)
  }
  if (formattedExpirationDate) {
    infoItems.push(`${t('expiryLabel')}: ${formattedExpirationDate}`)
  }
  if (infoItems.length === 0) {
    return t('noDetails')
  }

  infoItems = [infoItems.join(' / ')]
  if (expiryMessage) {
    infoItems.push(expiryMessage)
  }

  return infoItems
}

export function deepRemoveProperty(obj, prop) {
  if (!obj || typeof obj !== 'object') {
    return obj
  }
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (key === prop) {
      return
    }
    newObj[key] = deepRemoveProperty(obj[key], prop)
  })
  return newObj
}
