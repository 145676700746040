function GenerateCalendarPdf() {
  let instanceUri = null
  const actions = { createCalendarPdf }

  return { initialize, actions }

  function initialize(context) {
    ;({ instanceUri } = context)
  }

  async function createCalendarPdf({ scheduleHtml, filename, sendEmail }, facilityId) {
    const createPdfUrl = `${instanceUri}/api/schedule/createPdf`
    const params = { scheduleHtml, filename, sendEmail }
    const options = {
      body: JSON.stringify(params),
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Scope: facilityId
      }
    }

    const pdfGenerateError = new Error('Failed to generate schedule pdf')

    try {
      const response = await fetch(createPdfUrl, options)
      const isResponseSuccess = response.ok
      const contentType = response.headers.get('content-type')
      const isPDF = contentType === 'application/pdf'

      if (!isResponseSuccess && !sendEmail) {
        throw pdfGenerateError
      }
      if (isPDF) {
        return response.blob()
      }
    } catch (error) {
      if (!sendEmail) {
        throw pdfGenerateError
      }
    }
  }
}

export default GenerateCalendarPdf()
