import { fromJS, List, OrderedMap } from 'immutable'
import buildOpenShiftsMap from '../buildOpenShiftsMap'

function OpenShiftsConfig() {
  let timeService
  let updateOpenShiftsStateWith
  const defaultState = {
    openShifts: List(),
    openShiftsMap: OrderedMap({ rawRoles: List() }),
    filter: fromJS({
      status: ['requestPosted', 'needsApproval', 'filled', 'nobodyInvited'],
      shifts: ['day', 'night'],
      roles: []
    }),
    sortOrder: 'asc',
    sortBy: 'shiftStartsAt'
  }
  const actions = { setFilter }

  return { actions, initialize, defaultState, getConfig }

  function initialize(context) {
    ;({ facilityTime: timeService, updateOpenShiftsStateWith } = context)
  }

  function setFilter(filter) {
    return updateOpenShiftsStateWith((openShiftsState) => {
      const { sortBy, sortOrder, roles } = getConfig(openShiftsState)
      const openShifts = openShiftsState.get('openShifts')
      const mapConfig = { filter, sortBy, sortOrder }

      const openShiftsMap = buildOpenShiftsMap(openShifts, mapConfig, timeService, roles)

      return openShiftsState.merge({ filter, openShiftsMap })
    })
  }

  function getConfig(openShiftsState) {
    const filter = openShiftsState.get('filter')
    const sortBy = openShiftsState.get('sortBy')
    const sortOrder = openShiftsState.get('sortOrder')
    const roles = openShiftsState.getIn(['openShiftsMap', 'rawRoles'])

    return { filter, sortBy, sortOrder, roles }
  }
}

export default OpenShiftsConfig()
