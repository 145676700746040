import { ModeFull } from './Header'

export default function VRole({ style, activeDateRange }) {
  return (
    <section className="_group hx-calendar-group-container" style={style}>
      <section className="_modeFull">
        <div className="hx-calendar-group">
          <ModeFull activeDateRange={activeDateRange} />
        </div>
      </section>
    </section>
  )
}
