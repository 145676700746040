import { useEffect, useState } from 'react'
import { withAppContext } from 'AppContext'
import { navigate } from 'Navigation'
import { Map, fromJS } from 'immutable'

async function loadOpenShifts(activeDateRange, unitId, shifts, roles, timeService, openShiftsStore) {
  const startDate = timeService.today.toISOString()
  const facilityUsersMap = activeDateRange.get('facilityUsersMap')

  const unit = fromJS({
    id: unitId,
    shifts: shifts,
    roles: roles
  })

  return openShiftsStore.loadOpenShifts({ unit, startDate, facilityUsersMap })
}

function OpenShiftCard({ activeDateRange, appState, timeService, openShiftsStore }) {
  const [totalOpenShifts, setTotalOpenShifts] = useState(0)
  const [totalNeedApprovalOpenShifts, setTotalNeedApprovalOpenShifts] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const unit = appState.getIn(['generalData', 'unit'])
  const isSchedulesReady = unit.get('isSchedulesLoaded')
  const unitId = unit?.get('id')
  const shifts = unit?.get('shifts')
  const roles = unit?.get('roles')
  const isReady = unit?.get('isReady')

  useEffect(() => {
    if (!unitId || !isReady || !activeDateRange?.get('isReady')) {
      return
    }

    setIsLoading(true)

    if (!isSchedulesReady) {
      return
    }

    openShiftsStore.setFilter(Map())
    loadOpenShifts(activeDateRange, unitId, shifts, roles, timeService, openShiftsStore).then((state) => {
      const openShiftsState = state?.get('openShiftsState')
      const openShiftsMap = openShiftsState?.get('openShiftsMap')

      setTotalOpenShifts(openShiftsMap?.get('totalOpenShifts') ?? 0)
      setTotalNeedApprovalOpenShifts(openShiftsMap?.get('totalNeedApprovalOpenShifts') ?? 0)
      setIsLoading(false)
    })
  }, [activeDateRange, openShiftsStore, timeService, isReady, isSchedulesReady, unitId, shifts, roles])

  return (
    <>
      <div data-testid="todo-common-card-open-shift" className="todo-common-card">
        <div className="hx-card-header">
          <div className="col-2 icon-box">
            <div className="icon-open-shift"></div>
          </div>
          <div className="col-9 card-heading">Open Shifts</div>
          {!isLoading && (
            <div className="col-1 icon-box" onClick={() => navigate.from.Dashboard.to.OpenShifts({})}>
              <sh-icon icon="arrow-line-right-s"></sh-icon>
            </div>
          )}
        </div>
        <div className="hx-card-details">
          {isLoading ? (
            <div style={{ margin: 'auto', padding: '16px' }}>
              <sh-spinner></sh-spinner>
            </div>
          ) : (
            <>
              <div className="col-5 hx-card-detail">
                <div className="h60 countFont">{totalOpenShifts}</div>
                <div className="h20 labelFont">Open Shifts</div>
              </div>
              <div className="col-2 divider">
                <sh-divider vertical></sh-divider>
              </div>
              <div className="col-5 hx-card-detail">
                <div className="h60 hx-card-detail-first-box">
                  <div className="countFont">{totalNeedApprovalOpenShifts}</div>

                  {totalNeedApprovalOpenShifts > 0 && (
                    <div className="icon-box warning-icon">
                      <div className="icon-warning"></div>
                    </div>
                  )}
                </div>
                <div className="h20 labelFont">Approvals Required</div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default withAppContext(OpenShiftCard)
