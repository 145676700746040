import { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Admin, Header } from '@humanics/he-react-common/lib/admin/components'
import { Grid } from 'Admin/common'
import { allIdns } from 'Admin/services/IdnsService'
import { List } from 'immutable'

const field = (name, text, extra) => ({ name, text, ...extra })
const fields = [
  field('instanceName', 'Instance'),
  field('name', 'IDN Name'),
  field('location', 'Location'),
  // field('createdBy', 'Created By'),
  field('createdAt', 'Created At', { format: 'date-time' })
]

export default class Idns extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = { idns: [], fields, apiInitialized: false }
  }

  componentDidMount() {
    const { appState, store } = this.props

    ;(async () => {
      const api = await store.getApi()
      const idns = await allIdns(appState, api)
      this.setState({ apiInitialized: true, idns })
    })()
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState, store } = this.props
    const { idns: prevIdns } = this.state
    const idns = appState.getIn(['authentication', 'idns'], List())
    const prevIdnIds = List(prevIdns.map((idn) => idn.id))
    const idnIds = idns.map((idn) => idn.get('id'))
    if (!prevIdnIds.equals(idnIds)) {
      ;(async () => {
        const api = await store.getApi()
        const newIdns = await allIdns(appState, api)
        this.setState({ idns: newIdns })
      })()
    }
  }

  render() {
    const { store, location, stores, createOperationId, updateOperationId, subRoutes, match } = this.props

    const { apiInitialized } = this.state
    if (!apiInitialized) {
      return null
    }

    const { header } = store.getAdminInfo()
    const allowCreate = store.hasCrud('create', createOperationId)
    const allowUpdate = store.hasCrud('update', updateOperationId)
    const linkTo = location.pathname.replace(/\/$/, '') + '/new'
    const actions = allowCreate ? (
      <Link to={linkTo} className="button secondary">
        {header.createLabel || `Create ${header.label}`}
      </Link>
    ) : null
    const onRowClick = this.props.onRowClick || ((e) => e.preventDefault())

    return (
      <Admin>
        <Header title={header.title} actions={actions} />
        <Grid
          data={this.state.idns}
          fields={this.state.fields}
          allowUpdate={allowUpdate}
          onRowClick={onRowClick}
          stores={stores}
          currentPath={match.url}
        />
        {subRoutes}
      </Admin>
    )
  }
}
