import { List, Map } from 'immutable'
import { uniq } from 'lodash'
import StaffPreferences from './StaffPreferences'
import { StaffEligibilityService } from 'services'

export default class Staff {
  constructor(staff) {
    this.staff = staff
  }

  get userId() {
    return this.staff.get('userId')
  }

  get accountUserId() {
    return this.staff.get('accountUserId')
  }

  get firstName() {
    return this.facilityProfile.get('firstName')
  }

  get lastName() {
    return this.facilityProfile.get('lastName')
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get email() {
    return this.facilityProfile.get('email')
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`.toUpperCase()
  }

  get employmentType() {
    return this.staff.getIn(['staffProfile', 'employmentType'])
  }

  get certifications() {
    return this.staff.getIn(['staffProfile', 'certifications'])
  }

  get maximumNumberOfHoursPerWeek() {
    return this.staff.getIn(['staffProfile', 'maximumNumberOfHoursPerWeek'])
  }

  get avatarUrl() {
    return this.staff.getIn(['profile', 'avatarUrl'])
  }

  get staffPosition() {
    return this.staff.getIn(['staffProfile', 'position'])
  }

  get preferences() {
    return this.staff.getIn(['staffProfile', 'preferences']) || List()
  }

  get extendedPreferences() {
    return this.staff.getIn(['staffProfile', 'extendedPreferences'])
  }

  get hasPreferences() {
    return this.staffPreferences.selectedPreferences.size > 0
  }

  get staffPreferences() {
    return new StaffPreferences(this.preferences)
  }

  get noTimeOffDays() {
    return this.staff.get('noTimeOffDays')
  }

  get phoneNumber() {
    return this.facilityProfile.get('phoneNumber')
  }

  get phoneNumber1() {
    return this.staff.getIn(['staffProfile', 'phoneNumber1'])
  }

  get phoneNumber2() {
    return this.staff.getIn(['staffProfile', 'phoneNumber2'])
  }

  get phoneNumbers() {
    return uniq([this.phoneNumber, this.phoneNumber1, this.phoneNumber2].filter((phone) => phone))
  }

  get isInvitationSent() {
    return this.staff.get('isInvitationSent')
  }

  get isInvitationAccepted() {
    return this.staff.get('isInvitationAccepted')
  }

  get staffComplianceRules() {
    return this.staff.getIn(['staffProfile', 'staffComplianceRules']) || List()
  }

  get staffRecurringSchedules() {
    return this.staff.getIn(['staffProfile', 'staffRecurringSchedules']) || List()
  }

  get hasPermissions() {
    return this.staff.get('hasPermissions')
  }

  get createdAt() {
    return this.staff.get('createdAt')
  }

  get facilityProfile() {
    return this.staff.get('facilityProfile') || Map()
  }

  get eligibleUnits() {
    return this.staff.getIn(['staffProfile', 'eligibleUnits'])
  }

  get lastEligibleUnit() {
    return this.eligibleUnits.last()
  }

  get transferringUnit() {
    return this.eligibleUnits.filter((unit) => !unit.get('homeUnitRoleId') && !unit.get('homeUnitShiftTypeId')).first()
  }

  get staffProfile() {
    return new StaffProfile(this.staff.get('staffProfile'))
  }

  get managerFullName() {
    return this.staff.getIn(['staffProfile', 'managerFullName'])
  }

  get expertiseCredentials() {
    return this.staff.getIn(['staffProfile', 'expertiseCredentials'])
  }

  get staffExpertises() {
    return this.staff.get('staffExpertises')
  }

  get matchPercentage() {
    return this.staff.get('matchPercentage')
  }

  canEditEligibleUnits(timeService) {
    const { lastEligibleUnit } = this

    if (!lastEligibleUnit) {
      return true
    }

    const startsAt = lastEligibleUnit.get('startsAt')
    const endsAt = lastEligibleUnit.get('endsAt')

    const now = timeService.timeMoment(null)
    const alreadyStarted = now.isAfter(startsAt)
    const isNotFinished = !endsAt || now.isBefore(endsAt)

    return alreadyStarted && isNotFinished
  }

  getCurrentEligibleUnit(timeService) {
    return this.getEligibleUnitForDate(null, timeService)
  }

  getEligibleUnitForDate(date, timeService) {
    const staffEligibilityService = new StaffEligibilityService(timeService)
    return staffEligibilityService.getEligibleUnitForDate(this.eligibleUnits, date)
  }

  getHomeUnitIdForDate(date, timeService) {
    const eligibleUnit = this.getEligibleUnitForDate(date, timeService)
    return eligibleUnit?.get('homeUnitId')
  }

  getHomeUnitNameForDate(date, timeService) {
    const eligibleUnit = this.getEligibleUnitForDate(date, timeService)
    return eligibleUnit?.get('homeUnitName')
  }

  getHomeUnitRoleIdForDate(date, timeService) {
    const eligibleUnit = this.getEligibleUnitForDate(date, timeService)
    return eligibleUnit?.get('homeUnitRoleId')
  }
}

class StaffProfile {
  constructor(staffProfile) {
    this.staffProfile = staffProfile
  }

  get startsAt() {
    return this.staffProfile.get('startsAt')
  }

  get endsAt() {
    return this.staffProfile.get('endsAt')
  }
}
