import { ComponentController } from 'Common/components'

export default class StateController extends ComponentController {
  get defaultState() {
    return {
      paddingTop: 0,
      isLoading: false,
      isApproving: false,
      isDenying: false
    }
  }

  get timeOffCalendar() {
    return this.state.timeOffCalendar
  }
}
