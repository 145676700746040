import { Component } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { isEmpty, cloneDeep } from 'lodash'
import { Map } from 'immutable'
import { withAppContext } from 'AppContext'
import { withRouter } from 'react-router'
import { navigate } from 'Navigation'
import Subheader from '../Subheader'
import PreviewAnnouncements from './PreviewAnnouncements'
import CreateAnnouncementForm from './CreateAnnouncementForm'
import ExtendedEditPreview from './ExtendedEditPreview/ExtendedEditPreview'
import { ElementSchemas, getPrefilledContentToSave, image, link, text } from './Details'
import ElementSelectionMenu from './ElementSelectionMenu'
import './ExtendedEditPreview/ExtendedEditPreview.scss'
import './CreateAnnouncementsMaster.scss'
import CancelAnnouncementForm from './CancelAnnouncementForm'
import AdvancedConfigurationCardModal from './AdvancedConfigurationCard/AdvancedConfigurationCardModal'
import { TemplatesHeader } from './Templates'
import { Metadata } from '../Log'
import { detailsHtmlStyle } from './detailsHtmlStyle'
import { t } from 'i18n'

// Used to Render Components dynamically
const ElementComponents = {
  ElementSelectionMenu,
  CreateAnnouncementForm,
  text,
  link,
  image
}

// Used as the key prop for rendering detailsComponents list
let detailsComponentID = 1

class CreateAnnouncementsMaster extends Component {
  constructor(props) {
    const instanceUrl = props.appState.getIn(['context', 'instanceUri'])
    const loudspeakerBadge = process.env.PUBLIC_URL + '/images/loudspeaker-badge.png'
    const loudspeakerBadgeUrl = `${instanceUrl}${loudspeakerBadge}`

    super(props)
    this.state = {
      isLoading: true,
      isFormDirty: false,
      subject: '',
      lastLocation: '',
      subtitle: '',
      showSubtitle: false,
      content: '',
      loadedTemplate: '',
      location: '',
      showLocation: false,
      showModal: false,
      loudspeakerBadgeUrl,
      badgeUrl: loudspeakerBadgeUrl,
      hasDetailsPage: false,
      addAdvancedConfiguration: false,
      detailsComponents: this.setDefaultDetailsComponents(),
      activeComponent: { id: 'defaultForm', type: 'CreateAnnouncementForm', content: {} }
    }
  }

  // Used for loading a Draft announcement: props passed in from Form Loader under Announcements Layout
  componentDidMount = async () => {
    const { appState } = this.props
    const activeAnnouncement = appState.getIn(['announcements', 'activeAnnouncementItem'])

    if (!isEmpty(activeAnnouncement)) {
      const { announcement } = activeAnnouncement
      this.updateMasterState(announcement)
      appState.setIn(['announcements', 'activeAnnouncementItem'], Map({}))
      this.setState({ formInitialised: true })
      const enteredFromRetryButton = appState.getIn(['announcements', 'announcementWasRetried'])
      if (enteredFromRetryButton) {
        const { announcementsStore } = this.props
        this.toggleAddAdvancedConfigurationState()
        announcementsStore.setAnnouncementsRetryOptions(false)
      }
    }
  }

  /* ---------------- Master Form methods ---------------- */

  // Used whenever an event causes the details component to reset (e.g. create new form, delete details page)
  setDefaultDetailsComponents = () => {
    const layout = ['image', 'text']
    const detailsComponents = []
    let elementSchemasClone = cloneDeep(ElementSchemas)
    for (let i = 0; i < layout.length; i++) {
      const componentType = layout[i]
      const currentComponent = elementSchemasClone[componentType]
      currentComponent.id = detailsComponentID++
      detailsComponents.push(currentComponent)
    }

    return detailsComponents
  }

  /**
   * Mainly used for formatting when loading data backend => frontend
   *    - e.g. for loading templates/ drafts
   *
   * 1) Dynamically sets config booleans
   *    - showSubtitle, showLocation, hasDetailsPage
   *
   * 2) Formats detailsComponents.content:
   *    - Removes unused fields from backend (e.g. __typename)
   *    - Adds ID for key prop, parses JSON for Rich Text Editor
   */
  updateMasterState = (states) => {
    if (!states) {
      return
    }

    const { detailsComponents, subtitle, location } = states
    const showSubtitle = !!subtitle
    const showLocation = !!location
    const hasDetailsPage = !isEmpty(detailsComponents)
    const configs = { showSubtitle, showLocation, hasDetailsPage }

    if (hasDetailsPage) {
      for (let i = 0; i < detailsComponents.length; i++) {
        const { __typename, ...currentComponent } = detailsComponents[i]
        currentComponent.id = detailsComponentID++
        if (typeof currentComponent.content === 'string') {
          currentComponent.content = JSON.parse(currentComponent.content)
        }
        detailsComponents[i] = currentComponent
      }
      states.detailsComponents = detailsComponents
    } else {
      states.detailsComponents = this.setDefaultDetailsComponents()
    }
    this.setState({ ...states, ...configs })
  }

  /*
   * Mainly used for formatting when submitting data from frontend => backend
   * Only formats details components because the other fields are handled differently between e.g. Templates & Draft
   */
  formatDetailsComponents = (detailsComponents) => {
    if (!detailsComponents) {
      return null
    }
    return detailsComponents.map(({ id, ...component }) => {
      const { editorState, ...content } = component.content
      return {
        ...component,
        content: JSON.stringify(content)
      }
    })
  }

  // TODO: Improve Checking
  validateFormSubmit = (inputs) => {
    return inputs.every((item) => !!item)
  }

  // Back/Left Arrow Button
  onCancel = (event) => {
    navigate.from.FacilityUnitHome.to.Announcements({})
  }

  // On "Complete" button => Opens Advanced Configurations Options
  onSubmit = (event) => {
    const { subject, content } = this.state
    const isValidForm = this.validateFormSubmit([subject, content])

    if (isValidForm) {
      this.toggleAddAdvancedConfigurationState()
    } else {
      event.preventDefault()
      alert('Please make sure Subject & Content is not empty.')
    }
  }

  toggleAddAdvancedConfigurationState = (wasSuccessfull) => {
    if (wasSuccessfull === true) {
      this.setState(
        {
          subject: '',
          content: ''
        },
        navigate.from.FacilityUnitHome.to.Announcements
      )
    }
    this.setState({
      addAdvancedConfiguration: !this.state.addAdvancedConfiguration
    })
  }

  handleTextChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      isFormDirty: true
    })
  }

  onBadgeClick = (event) => {
    this.setState({
      badgeUrl: event.target.dataset.src,
      isFormDirty: true
    })
  }

  onClickSubtitle = (event) => {
    event.preventDefault()
    this.setState((prevState) => {
      return { showSubtitle: !prevState.showSubtitle }
    })
  }

  onClickLocation = (event) => {
    event.preventDefault()
    this.setState((prevState) => {
      return { showLocation: !prevState.showLocation }
    })
  }

  /* ---------------- Details Components methods ---------------- */

  onAddDetailsPage = (template) => {
    const { detailsComponents, content } = this.state
    if (content) {
      const firstTextEditorContentToSave = getPrefilledContentToSave(content)
      detailsComponents[1].content.contentToSave = firstTextEditorContentToSave
    }

    this.setState({
      hasDetailsPage: true,
      detailsComponents,
      activeComponent: detailsComponents[0],
      isFormDirty: true
    })
  }

  onDeleteDetailsPage = () => {
    this.setState({
      hasDetailsPage: false,
      activeComponent: { id: 'defaultForm', type: 'CreateAnnouncementForm', content: {} },
      detailsComponents: this.setDefaultDetailsComponents(),
      isFormDirty: true
    })
  }

  // Changes active component when user clicks on a details component
  onDetailsComponentClicked = (activeComponent) => {
    if (activeComponent.type === 'PlaceholderInstructionBox') {
      this.setState({
        activeComponent: {
          id: activeComponent.id,
          type: 'ElementSelectionMenu',
          content: {}
        }
      })
    } else {
      this.setState({ activeComponent })
    }
  }

  // On choosing the type of the new details component
  onDetailsComponentSelected = (event) => {
    event.persist()
    const type = event.target.getAttribute('name')
    let elementSchemasClone = cloneDeep(ElementSchemas)
    const component = {
      id: this.state.activeComponent.id,
      type,
      content: elementSchemasClone[type].content
    }

    this.updateDetailsComponent(component)
  }

  // Used to insert a newly created details component (placeholder) at the right location
  insertDetailsComponent = (index) => {
    this.setState((state) => {
      const placeHolder = {
        id: detailsComponentID++,
        type: 'PlaceholderInstructionBox',
        content: {}
      }

      const detailsComponents = state.detailsComponents
      detailsComponents.splice(index, 0, placeHolder)
      return {
        detailsComponents,
        activeComponent: { id: placeHolder.id, type: 'ElementSelectionMenu', content: {} },
        isFormDirty: true
      }
    })
  }

  // Used to replace placeholder components, and update details components content
  updateDetailsComponent = (component) => {
    this.setState((state, props) => {
      const detailsComponents = state.detailsComponents
      for (let i = 0; i < detailsComponents.length; i++) {
        if (detailsComponents[i].id === component.id) {
          detailsComponents[i] = component
          return { activeComponent: component, detailsComponents, isFormDirty: true }
        }
      }
    })
  }

  deleteDetailsComponent = (index) => {
    this.setState(
      (state) => {
        const detailsComponents = state.detailsComponents

        if (detailsComponents.length === 1) {
          const placeHolder = {
            id: detailsComponentID++,
            type: 'PlaceholderInstructionBox',
            content: {}
          }
          detailsComponents.splice(index, 1, placeHolder)
        } else {
          detailsComponents.splice(index, 1)
        }

        return { detailsComponents, isFormDirty: true }
      },
      () => {
        const activeItemIndex = index === this.state.detailsComponents.length ? index - 1 : index
        this.onDetailsComponentClicked(this.state.detailsComponents[activeItemIndex])
      }
    )
  }

  displayModal(location) {
    this.setState({
      showModal: true,
      lastLocation: location
    })
  }

  render() {
    const {
      subject,
      content,
      subtitle,
      location,
      badgeUrl,
      loadedTemplate,
      showSubtitle,
      showLocation,
      hasDetailsPage,
      activeComponent,
      detailsComponents,
      isFormDirty,
      addAdvancedConfiguration,
      formInitialised,
      loudspeakerBadgeUrl,
      status,
      author,
      startsAt,
      endsAt,
      updatedAt,
      createdAt,
      worksAt,
      shiftTypes
    } = this.state

    const canSave = subject.length > 0
    const hasContent = content.length > 0
    const canSubmit = canSave && hasContent
    const preventNavigation = canSave || hasContent // unclear if it should prompt if it only has content but no subject, so I'm asking for any of those
    const { match, editable: isFormEditable, timeService, viewExisting, unit, generalStore } = this.props
    const { announcementId } = match.params
    const unitId = unit.get('id')

    const ComponentRHS = ElementComponents[activeComponent.type]

    // Preview of Announcement Card
    const previewAnnouncementsProps = {
      subject,
      content,
      subtitle,
      location,
      badgeUrl,
      showSubtitle,
      showLocation,
      activeComponent,
      hasDetailsPage,
      isFormEditable,
      onDetailsComponentClicked: this.onDetailsComponentClicked
    }

    // Preview of Details Page
    const extendedEditPreviewProps = {
      subject,
      subtitle,
      showSubtitle,
      hasDetailsPage,
      detailsComponents,
      activeComponent,
      isFormEditable,
      onAddDetailsPage: this.onAddDetailsPage,
      onDeleteDetailsPage: this.onDeleteDetailsPage,
      onDetailsComponentClicked: this.onDetailsComponentClicked,
      insertDetailsComponent: this.insertDetailsComponent,
      updateDetailsComponent: this.updateDetailsComponent,
      deleteDetailsComponent: this.deleteDetailsComponent
    }

    // Right-Hand-Side Editable/Selectable Forms
    const propsRHS = {
      subject,
      subtitle,
      showSubtitle,
      content,
      location,
      showLocation,
      badgeUrl,
      hasDetailsPage,
      activeComponent,
      loudspeakerBadgeUrl,
      handleTextChange: this.handleTextChange,
      onBadgeClick: this.onBadgeClick,
      onClickSubtitle: this.onClickSubtitle,
      onClickLocation: this.onClickLocation,
      onAddDetailsPage: this.onAddDetailsPage,
      onDetailsComponentSelected: this.onDetailsComponentSelected,
      onContentToSaveChange: this.onContentToSaveChange,
      updateDetailsComponent: this.updateDetailsComponent,
      deleteDetailsComponent: this.deleteDetailsComponent,
      instanceUri: this.props.appState.getIn(['context', 'instanceUri']),
      facilityId: this.props.appState.getIn(['authentication', 'facility', 'id'])
    }

    // Abstracted in order to submit and serve static markup previews (e.g. Details Page in Mobile app, Template Preview when Saving new template)
    const previewJSX = <PreviewAnnouncements {...previewAnnouncementsProps} />
    const detailsJSX = <ExtendedEditPreview {...extendedEditPreviewProps} />
    const previewJSXUneditable = <PreviewAnnouncements {...previewAnnouncementsProps} isFormEditable={false} />
    const detailsJSXUneditable = <ExtendedEditPreview {...extendedEditPreviewProps} isFormEditable={false} />

    const detailsHtml = renderToStaticMarkup(detailsJSXUneditable) + renderToStaticMarkup(detailsHtmlStyle)

    const filteredDetailsComponents = this.filterDetailsComponents(detailsComponents)
    const propsToSave = {
      detailsComponents: filteredDetailsComponents,
      hasDetailsPage: !isEmpty(filteredDetailsComponents)
    }
    // For "Exit" Modal
    const exitCreateAnnouncementsProps = {
      subject,
      subtitle,
      content,
      location,
      badgeUrl,
      unitId,
      hasDetailsPage,
      detailsComponents,
      detailsHtml,
      announcementId,
      isFormDirty,
      preventNavigation,
      formatDetailsComponents: this.formatDetailsComponents,
      ...propsToSave
    }

    // For Announcements Templates
    const templatesHeaderProps = {
      isFormDirty,
      loadedTemplate,
      hasDetailsPage,
      canSave,
      loudspeakerBadgeUrl,
      previewJSX: previewJSXUneditable,
      detailsJSX: detailsJSXUneditable,
      payload: {
        subject,
        subtitle,
        content,
        location,
        badgeUrl,
        detailsComponents,
        hasDetailsPage
      },
      formatDetailsComponents: this.formatDetailsComponents,
      updateMasterState: this.updateMasterState,
      setDefaultDetailsComponents: this.setDefaultDetailsComponents
    }

    // For Advanced Configuration Options
    const advancedConfigurationCardModalProps = {
      timeService,
      announcementId,
      payload: {
        subject,
        subtitle,
        content,
        location,
        badgeUrl,
        detailsComponents,
        hasDetailsPage,
        detailsHtml,
        ...propsToSave
      },
      startsAt,
      endsAt,
      unitIds: [unitId],
      formatDetailsComponents: this.formatDetailsComponents,
      visible: this.state.addAdvancedConfiguration,
      toggleAddAdvancedConfigurationState: this.toggleAddAdvancedConfigurationState,
      refreshNotifications: () => generalStore.refreshNotifications(unitId)
    }

    let metadataProps = {
      announcementId,
      status,
      author,
      hasDetailsPage,
      startsAt,
      endsAt,
      updatedAt,
      createdAt,
      worksAt,
      shiftTypes,
      viewExisting
    }

    let cardStyle = {}
    switch (true) {
      case !isFormEditable:
        cardStyle = { backgroundColor: '#f1f1f1' }
        break
      case isFormEditable && activeComponent.type === 'CreateAnnouncementForm':
        cardStyle = { backgroundColor: '#f1f1f1' }
        break
      default:
        cardStyle = { backgroundColor: '#fff' }
    }

    let detailsStyle = {}
    switch (true) {
      case !isFormEditable:
        detailsStyle = { backgroundColor: '#f1f1f1' }
        break
      case isFormEditable && activeComponent.type !== 'CreateAnnouncementForm' && hasDetailsPage:
        detailsStyle = { backgroundColor: '#f1f1f1' }
        break
      default:
        detailsStyle = { backgroundColor: '#fff' }
    }

    return (
      <>
        <div className="master-announcement-container">
          {isFormEditable ? (
            <div className="master-announcements-templates-header">
              <TemplatesHeader {...templatesHeaderProps} />
            </div>
          ) : (
            <Subheader
              canGoBack
              goBackText={t('announcements.common.back', { page: 'Announcements' })}
              headerText="Announcement Details"
              history={this.props.history}
            />
          )}

          <div className="master-announcement-main">
            <div className={isFormEditable ? 'left-hand-side' : 'front-and-center'}>
              <button className="button-close" onClick={this.onCancel}>
                <i className="icon-left" />
                {isFormEditable || 'Back to Announcements'}
              </button>
              <div style={cardStyle} className="preview-announcements">
                {previewJSX}
              </div>
              <div className="extended-preview-announcements" style={detailsStyle}>
                {detailsJSX}
              </div>
            </div>
            {viewExisting && formInitialised && <Metadata {...metadataProps} />}
            {isFormEditable && (
              <div className="right-hand-side">
                <div className="right-hand-side-components">
                  <ComponentRHS {...propsRHS} />
                </div>
                <div className="submit-buttons row">
                  <CancelAnnouncementForm {...exitCreateAnnouncementsProps} />
                  <sh-button
                    class="_submit"
                    disabled={!canSubmit ? true : undefined}
                    color="primary"
                    onClick={(e) => this.onSubmit(e, detailsJSX)}
                    label="Next"
                  />
                  {addAdvancedConfiguration && (
                    <AdvancedConfigurationCardModal {...advancedConfigurationCardModalProps} />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  filterDetailsComponents = (components) =>
    components.filter((c) => {
      switch (c.type) {
        case 'image':
          return c.content.imageSrc
        case 'text': {
          const blocks = JSON.parse(c.content.contentToSave).blocks
          return blocks.some((b) => b.text)
        }
        case 'link':
          return c.content.webAddress
        default:
          return true
      }
    })
}

export default withAppContext(withRouter(CreateAnnouncementsMaster))
