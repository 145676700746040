import { PureComponent } from 'react'
import Months from './Months'
import Week from './Week'
import { chunk } from 'lodash'

export default class ModeFull extends PureComponent {
  render() {
    const { days } = this.props
    const weeks = chunk(days, 7)
    const weekWidth = `${100 / weeks.length}%`

    return (
      <div className="row auto bg-white text-center bl4 bb1">
        <Months days={days} />
        {weeks.map((days, i) => (
          <Week days={days} key={i} width={weekWidth} />
        ))}
      </div>
    )
  }
}
