import { PureComponent } from 'react'
import Shift from './Shift'
import Header from './Header'
import './Role.scss'

export default class Role extends PureComponent {
  render() {
    const { role } = this.props

    const shifts = role.get('shifts')
    const mismatches = shifts.map((shift) => shift.getIn(['shiftDay', 'staffMismatch']))
    const roleName = role.get('name')

    const headerProps = { mismatches, roleName }

    return (
      <section className="_group hx-calendar-group-container">
        <div className="hx-calendar-group bg-cadetblue">
          <Header {...headerProps} />
        </div>
        {shifts.map(this.renderShift)}
      </section>
    )
  }

  renderShift = (shift, index) => {
    const { timeService } = this.props
    const props = { timeService, shift }

    return <Shift key={index} {...props} />
  }
}
