import { Map } from 'immutable'
import { Avatar } from 'Manager/components'

const StaffRow = ({
  staff,
  handleStaffSelection = () => {
    // This is intentional
  },
  removeStaffSelection,
  isStaffSelected
}) => {
  const userId = staff.get('id')
  const profile = staff.get('profile') || Map()
  const fullName = staff.get('fullName')
  const staffHours = staff.get('hrs')
  const staffType = staff.get('type')

  const avatarProps = {
    userId,
    profile,
    visible: staff.get('isShiftLoaded'),
    renderVisibleOnly: true
  }

  return (
    <div
      className={`bg-alabaster hx-calendar-staff-info-wrapper ${!isStaffSelected ? 'bt1' : ''}`}
      onClick={(e) => handleStaffSelection(e, userId)}
      key={userId}
    >
      <Avatar {...avatarProps} />
      <div className="staff-meta">
        {fullName}
        {isStaffSelected && (
          <div className="removeStaff" id="hx-calendar" onClick={removeStaffSelection}>
            <sh-icon icon="cancel" size="xs" button />
          </div>
        )}

        <div className="small regent-gray">
          {`${staffType}  ·  `}
          <span>{staffHours} hrs</span>
        </div>
      </div>
    </div>
  )
}
export default StaffRow
