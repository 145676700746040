import { facilityUsersQuery, staffEventsQuery } from '../Queries'
import { List, Map, Repeat } from 'immutable'
import { getState } from '@humanics/he-react-common/lib/stores/fluxStore'
import { FacilityUserShiftsService } from 'services'
import { GQLDataLoader } from 'utils'
import extendFacilityUser from './extendFacilityUser'
import { staffEventFiltered } from '../CalendarDayView/utils'

export default OtherStaff()

function OtherStaff() {
  let gqlClient = null
  let instanceUri = null
  let timeService = null
  let updateCalendar = null
  let buildStaffWithCells = null

  const actions = { loadOtherStaff, loadOtherStaffWithEvents }
  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, instanceUri, updateCalendar, buildStaffWithCells, facilityTime: timeService } = context)
  }

  function getUpdateCalendar(otherStaff, cells, instanceUri, staffEvents) {
    return updateCalendar((calendar) => {
      const role = calendar.get('roles').last()
      const shift = role.get('shifts').last()
      const roleIndex = role.get('roleIndex')
      const shiftIndex = shift.get('shiftIndex')
      const shiftId = shift.get('id')

      const staffWithCells = buildStaffWithCells(
        otherStaff,
        cells,
        roleIndex,
        shiftIndex,
        shiftId,
        instanceUri,
        staffEvents
      )
      const shiftParameters = {
        isLoaded: false,
        isLoading: false,
        staff: staffWithCells,
        primaryStaff: staffWithCells
      }
      return calendar
        .merge({ otherStaff, isOtherStaffLoaded: true })
        .mergeIn(['roles', roleIndex, 'shifts', shiftIndex], shiftParameters)
    })
  }

  async function loadOtherStaff(unit, dateRange, staffEvents) {
    const parameters = { roleIds: ['staff'] }
    const gqlDataLoaded = new GQLDataLoader(gqlClient)
    const staffCoordinatorEligibleUnits = getState().getIn(['generalData', 'eligibleUnits'])

    const staff = await gqlDataLoaded.loadAllItems('facilityUsers', facilityUsersQuery, parameters)
    const data = { instanceUri }
    const staffExtended = staff.map((staff) => extendFacilityUser(staff, data))
    const otherStaff = _getOtherStaffForUnit(unit, staffExtended, staffCoordinatorEligibleUnits)

    const days = dateRange.get('days')
    const cells = List(Repeat(Map(), days.length))
    return getUpdateCalendar(otherStaff, cells, instanceUri, staffEvents)
  }

  async function loadOtherStaffWithEvents(unit, dateRange, usDate) {
    const parameters = { roleIds: ['staff'] }
    const gqlDataLoaded = new GQLDataLoader(gqlClient)

    const staff = await gqlDataLoaded.loadAllItems('facilityUsers', facilityUsersQuery, parameters)

    const data = { instanceUri }
    const staffExtended = staff.map((staff) => extendFacilityUser(staff, data))
    const otherStaff = _getOtherStaffForUnit(unit, staffExtended)

    const startDate = timeService.timeMoment(usDate).toISOString()
    const endDate = timeService.timeMoment(startDate).add(2, 'day').add(-1, 'minute').toISOString()
    const userIds = staff.map((user) => user.userId)
    const { staffEvents } = await gqlClient.query(staffEventsQuery, {
      userIds,
      startDate,
      endDate,
      limit: 999
    })
    const staffEventsFiltered = staffEventFiltered(staffEvents, timeService, startDate)

    const days = dateRange.get('days')
    const cells = List(Repeat(Map(), days.length))
    return getUpdateCalendar(otherStaff, cells, instanceUri, staffEventsFiltered)
  }

  function _getOtherStaffForUnit(unit, facilityUsers, staffCoordinatorEligibleUnits) {
    const unitId = unit.get('id')
    const startDate = unit.getIn(['schedule', 'startsAt'])
    const endDate = unit.getIn(['schedule', 'endsAt'])
    const shifts = unit.get('shifts')

    return FacilityUserShiftsService.getOtherStaffForUnit(
      unitId,
      startDate,
      endDate,
      facilityUsers,
      shifts,
      timeService,
      staffCoordinatorEligibleUnits
    )
  }
}
