import { isEqual } from 'lodash'
import { ComponentController } from 'Common/components'
import { deepRemoveProperty, getFieldValueMapping, doesDuplicateExist } from '../utils'
import { addWarningBanner } from 'stores'

class ExpertiseFormController extends ComponentController {
  onClickSubmit = async (e) => {
    e.preventDefault()
    const { onClose, onSubmit, userId } = this.props

    let { staffExpertiseCredentials } = this.props

    if (this.isFormToEdit()) {
      staffExpertiseCredentials = this.#deleteOldStaffExpertiseCredentials(staffExpertiseCredentials)
    }
    staffExpertiseCredentials = this.#buildStaffExpertiseCredentialsToSend(staffExpertiseCredentials)
    if (doesDuplicateExist(staffExpertiseCredentials.map((credential) => credential.expertiseId))) {
      addWarningBanner({ message: 'Expertise(s) already exist(s)' })
      onClose()
      return
    }

    this.setState({ isLoading: true })
    try {
      await onSubmit({ userId, expertiseCredentials: staffExpertiseCredentials })
    } catch (err) {
      this.setState({ isLoading: false })
      onClose()
    }
    this.setState({ isLoading: false })
    onClose()
  }

  isFormToEdit = () => {
    return this.props.expertiseId !== 'new'
  }

  areFormValuesUnchanged = (form) => {
    const currentFormValues = getFieldValueMapping(form)
    return isEqual(currentFormValues, this.formValuesBeforeEdit)
  }

  #deleteOldStaffExpertiseCredentials(staffExpertiseCredentials) {
    const newStaffExpertiseCredentials = this.serializeForm()
    const newCredentialsExpertiseIds = newStaffExpertiseCredentials.map((credential) => credential.expertiseId)
    return staffExpertiseCredentials.filter(
      (credential) => !newCredentialsExpertiseIds.includes(credential.get('expertiseId'))
    )
    //TODO: write new function to find array difference
  }

  #buildStaffExpertiseCredentialsToSend(staffExpertiseCredentials) {
    const newStaffExpertiseCredentials = this.serializeForm() //serializeForm to be provided by child class
    return [...newStaffExpertiseCredentials, ...staffExpertiseCredentials.toJS()].map((obj) =>
      deepRemoveProperty(obj, '__typename')
    )
  }
}

export default ExpertiseFormController
