import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'
import { getDurationLabel } from 'Manager/PatientSlotRecommender/utils'

const fields = [
  Field('name', 'Name'),
  Field('unitName', 'Unit'),
  Field('groupName', 'Role'),
  Field('startTime', 'Start Time'),
  Field('duration', 'Duration'),
  Field('updatedAt', 'Modified')
]

function ShiftStore() {
  const store = AdminStore({
    fields,
    entityName: 'Shift',
    api: {
      index: 'indexShifts'
    },
    header: { title: 'Shifts' },
    modifyData: (item) => {
      return {
        duration: getDurationLabel(item.length)
      }
    }
  })

  function loadShifts() {
    return store.loadDataAction()
  }

  async function loadShiftsWithResourceType(resourceTypes) {
    const api = await store.getApi()
    // eslint-disable-next-line no-return-await
    return await api.indexShifts({ resourceTypes })
  }

  async function loadRemoteEquipments(parameters) {
    const api = await store.getApi()
    // eslint-disable-next-line no-return-await
    return await api.indexRemoteEquipments(parameters)
  }

  return {
    ...store,
    loadShifts,
    loadShiftsWithResourceType,
    loadRemoteEquipments,
    hasCrud: (operation, ...args) => {
      if (operation === 'delete') {
        return false
      }
      return store.hasCrud(operation, ...args)
    }
  }
}

export default ShiftStore
