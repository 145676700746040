import { fromJS, List, Map } from 'immutable'
import { avatar } from '../helpers'
import { staffEligibleForUnitQuery, staffTransferForUnitQuery } from '../Queries'
import { GQLDataLoader } from 'utils'

function StaffList() {
  let instanceUri = null
  let gqlClient = null
  let timeService = null
  let updateStaffData = null

  const actions = { loadUnitStaff, resetStaffList, loadTransferringStaff }

  return { initialize, actions }

  function initialize(context) {
    ;({ gqlClient, instanceUri, updateStaffData, facilityTime: timeService } = context)
  }

  async function loadUnitStaff(unitId, page, config, limit = 25) {
    const { search, sortBy, isSortDescending, statuses, startDate } = config
    const { endDate, positions, endsAtBefore } = config

    const offset = limit * (page - 1)
    const parameters = {
      unitId,
      search,
      offset,
      limit,
      startDate,
      endDate,
      sortBy,
      isSortDescending,
      statuses,
      positions,
      endsAtBefore,
      hasOpenTransfer: false
    }
    const options = { operations: { staffEligibleForUnit: { includePagination: true } } }
    const { staffEligibleForUnit } = await gqlClient.query(staffEligibleForUnitQuery, parameters, options)

    const { edges: staff, pageInfo } = staffEligibleForUnit
    const staffExtended = extendStaff(fromJS(staff))

    const staffList = Map({ edges: staffExtended, pageInfo: fromJS(pageInfo) })

    return updateStaffData((staff) => staff.set('staffList', staffList))
  }

  async function loadOutcomingStaff(unitId, config) {
    const { positions, statuses, search, isSortDescending, sortBy } = config

    const startDate = timeService.timeMoment(null).toISOString()
    const endDate = timeService.timeMoment(startDate).add(1, 'day').toISOString()

    const parameters = {
      unitId,
      startDate,
      endDate,
      sortBy,
      positions,
      statuses,
      search,
      isSortDescending,
      offset: 0,
      limit: 999,
      hasOutcomingTransfer: true
    }

    const gqlDataLoader = new GQLDataLoader(gqlClient)
    const outcomingStaff = await gqlDataLoader.loadAllItems(
      'staffEligibleForUnit',
      staffTransferForUnitQuery,
      parameters
    )

    return extendStaff(fromJS(outcomingStaff))
  }

  async function loadIncomingStaff(unitId, config) {
    const { positions, statuses, search, isSortDescending, sortBy } = config
    const startDate = timeService.timeMoment(null).toISOString()
    const endDate = timeService.timeMoment(startDate).add(6, 'month').toISOString()

    const parameters = {
      positions,
      statuses,
      search,
      unitId,
      startDate,
      endDate,
      sortBy,
      isSortDescending,
      offset: 0,
      limit: 999,
      hasOpenTransfer: true
    }

    const gqlDataLoader = new GQLDataLoader(gqlClient)
    const incomingStaff = await gqlDataLoader.loadAllItems(
      'staffEligibleForUnit',
      staffTransferForUnitQuery,
      parameters
    )

    return extendStaff(fromJS(incomingStaff))
  }

  async function loadFutureStaff(unitId, config) {
    const { positions, statuses, search, isSortDescending, sortBy } = config
    const tomorrow = timeService.timeMoment(null).add(1, 'day')
    const startDate = tomorrow.toISOString()
    const endDate = tomorrow.clone().add(1, 'year').toISOString()
    const startsAtAfter = startDate

    const parameters = {
      positions,
      statuses,
      search,
      unitId,
      startDate,
      endDate,
      sortBy,
      startsAtAfter,
      isSortDescending,
      offset: 0,
      limit: 999
    }

    const gqlDataLoader = new GQLDataLoader(gqlClient)
    const incomingStaff = await gqlDataLoader.loadAllItems(
      'staffEligibleForUnit',
      staffEligibleForUnitQuery,
      parameters
    )

    return extendStaff(fromJS(incomingStaff))
  }

  async function loadTransferringStaff(unitId, config) {
    const [incomingStaff, outcomingStaff, futureStaff] = await Promise.all([
      loadIncomingStaff(unitId, config),
      loadOutcomingStaff(unitId, config),
      loadFutureStaff(unitId, config)
    ])

    const transferringStaff = Map({ incomingStaff, outcomingStaff, futureStaff })

    return updateStaffData((staff) => staff.set('transferringStaff', transferringStaff))
  }

  function extendStaff(staff) {
    return staff.map((facilityUser) => {
      const facilityProfile = facilityUser.get('facilityProfile') || Map()

      const firstName = facilityProfile.get('firstName')
      const lastName = facilityProfile.get('lastName')
      const fullName = `${firstName} ${lastName}`
      const fullNameRevers = `${lastName}, ${firstName}`

      return _withAvatar(facilityUser)
        .set('firstName', firstName)
        .set('lastName', lastName)
        .set('fullName', fullName)
        .set('fullNameRevers', fullNameRevers)
    })
  }

  function resetStaffList() {
    const defaultStaffList = Map({
      edges: List(),
      pageInfo: Map({
        offset: 0,
        limit: 25,
        count: 0,
        totalCount: 0
      })
    })

    const defaultTransferringStaff = Map({
      incomingStaff: List(),
      outcomingStaff: List(),
      futureStaff: List()
    })

    return updateStaffData((staff) =>
      staff.set('transferringStaff', defaultTransferringStaff).set('staffList', defaultStaffList)
    )
  }

  function _withAvatar(user) {
    user = user.updateIn(['profile', 'avatarUrl'], (avatarUrl) => avatar(instanceUri, avatarUrl))
    return user.updateIn(['facilityProfile', 'avatarUrl'], (avatarUrl) => avatar(instanceUri, avatarUrl))
  }
}

export default StaffList()
