import { ComponentController } from 'Common/components'
import moment from 'moment-timezone'
import { Staff } from 'entityWrappers'

const currentTimezone = moment.tz.guess()
const currentLocale = moment.locale()

export default class FormController extends ComponentController {
  getFormProperties() {
    const { form } = this.state
    const { isEdit } = this.props
    const { maxStartsAt, minEndsAt } = this.getLimits()
    form.startsAt.definition.disabled = form.startsAt.definition.disableFunc(isEdit)
    form.startsAt.maxDate = maxStartsAt
    form.endsAt.minDate = minEndsAt
    return form
  }

  getLimits() {
    const { timeService } = this.props
    const { form } = this.state

    const startsAt = form.startsAt.value
    const endsAt = form.endsAt.value

    const startsAtMoment = startsAt ? timeService.timeMoment(startsAt) : undefined
    const endsAtMoment = endsAt ? timeService.timeMoment(endsAt) : undefined

    const today = timeService.timeMoment(null).startOf('day')

    const dayBeforeEndsAt = endsAtMoment?.clone().subtract(1, 'day').toISOString()

    const dayAfterStartsAt = startsAtMoment?.clone().add(1, 'day').toISOString()

    const maxStartsAt = dayBeforeEndsAt
    const minEndsAt = today.isAfter(dayAfterStartsAt) ? today.toISOString() : dayAfterStartsAt

    return { maxStartsAt, minEndsAt }
  }

  isAlreadyTerminated = () => {
    const { staff } = this.props
    return staff && !!staff.getIn(['staffProfile', 'endsAt'])
  }

  getPosition = (serializedForm, staffPositions) => {
    return serializedForm.position
      ? staffPositions.find((staffPosition) => staffPosition.get('id') === serializedForm.position).get('name')
      : ''
  }

  serializeForm = () => {
    const { form } = this.state
    const { staffPositions, match } = this.props

    const isAlreadyTerminated = this.isAlreadyTerminated()

    const { userId } = match.params
    let serializedForm = { userId }
    for (const key in form) {
      if (isAlreadyTerminated && key === 'endsAt') {
        continue
      }

      if (form[key].definition.type === 'date') {
        if (form[key].value) {
          serializedForm[key] = form[key].value
        }
      } else {
        serializedForm[key] = form[key].value
      }

      if (key === 'phoneNumber') {
        serializedForm[key] = this._getPhoneNumber(form[key].value)
      }
      // HMN-3405, handle '' on maximumNumberOfHoursPerWeek field, as an empty field should'n update it.
      const doesNotHaveValue = isNaN(parseInt(serializedForm[key]))
      if (key === 'maximumNumberOfHoursPerWeek' && doesNotHaveValue) {
        serializedForm.maximumNumberOfHoursPerWeek = null
      }
    }
    serializedForm.position = this.getPosition(serializedForm, staffPositions)

    serializedForm.locale = currentLocale
    serializedForm.timeZone = currentTimezone

    return serializedForm
  }

  getHomeUnitId(units, homeUnitName) {
    return units.size > 0 ? units.find((p) => p.get('name') === homeUnitName)?.get('id') : ''
  }

  updateForm() {
    const { staff, managerFullName, homeUnitId, timeService, staffPositions, units } = this.props

    const form = this._resetForm(this.state.form)
    const { maxStartsAt, minEndsAt } = this.getLimits()

    const today = timeService.timeMoment(null).startOf('day')
    form.phoneNumber.definition.disabled = false
    form.email.definition.disabled = false
    const facilityUser = new Staff(staff)

    if (staff) {
      const currentEligibleUnit = facilityUser.getCurrentEligibleUnit(timeService)
      const homeUnitName = currentEligibleUnit ? currentEligibleUnit.get('homeUnitName') : ''
      form.homeUnitId.value = this.getHomeUnitId(units, homeUnitName)

      Object.keys(form).forEach((field) => {
        const { path } = form[field]
        if (path) {
          form[field].value = path(staff) || form[field].value
        }
      })
      const hasPermissions = staff.get('hasPermissions')
      const isInvitationSent = staff.get('isInvitationSent')
      const isInvitationAccepted = staff.get('isInvitationAccepted')
      const pendingAccess = isInvitationSent && !isInvitationAccepted

      form.phoneNumber.definition.disabled = pendingAccess || hasPermissions
      form.email.definition.disabled = pendingAccess || hasPermissions
    }

    form.startsAt.value = form.startsAt.value || today.toISOString()
    form.homeUnitId.value = form.homeUnitId.value || homeUnitId
    form.managerFullName.value = form.managerFullName.value || managerFullName
    form.homeUnitId.definition.items = { enum: units }
    form.position.value =
      form.position.value && staffPositions.size > 0
        ? staffPositions.find((p) => p.get('name') === form.position.value).get('id')
        : ''

    form.homeUnitId.definition.items = { enum: units }
    form.position.definition.items = { enum: staffPositions }
    form.startsAt.maxDate = maxStartsAt
    form.endsAt.minDate = minEndsAt
    form.endsAt.definition.disabled = !!form.endsAt.value

    this.setState({ form })
  }

  getFieldValue = (e, field) => {
    const { form } = this.state
    let value

    switch (form[field].definition.type) {
      case 'select':
      case 'number':
      case 'date':
      case 'array':
        value = e
        break
      default:
        value = e.target ? e.target.value : ''
    }

    return value
  }

  setFieldValue = (e, field) => {
    const { timeService } = this.props
    const { form } = this.state

    form[field].value = this.getFieldValue(e, field)
    const startsAt = form.startsAt.value
    const endsAt = form.endsAt.value

    if (field === 'startsAt' && startsAt) {
      const startsAtMoment = timeService.timeMoment(form.startsAt.value)

      if (endsAt && startsAtMoment.isSameOrAfter(endsAt)) {
        form.endsAt.value = startsAtMoment.add(1, 'day').toISOString()
      }
    }

    if (field === 'endsAt' && endsAt) {
      const endsAtMoment = timeService.timeMoment(endsAt)
      if (startsAt && endsAtMoment.isSameOrBefore(startsAt)) {
        form.startsAt.value = endsAtMoment.add(-1, 'day').toISOString()
      }
    }

    delete form[field].inlineError

    this.setState({ form })
  }

  validateField = (field) => {
    const { isEdit } = this.props
    const { form } = this.state
    form[field].validate(form, { showError: true, isEdit })
    if (field === 'email') {
      form[field].value && delete form['phoneNumber'].inlineError
    }
    if (field === 'phoneNumber') {
      form[field].value && delete form['email'].inlineError
    }

    this.setState({ form })
  }

  validateForm = (showError) => {
    const { isEdit } = this.props
    const { form } = this.state
    const fields = Object.keys(form)
    const options = { isEdit, showError }

    return fields.every((field) => form[field].validate(form, options))
  }

  isFormValid = () => {
    return this.validateForm(false)
  }

  _resetForm = (form) => {
    Object.keys(form).forEach((field) => {
      if (form[field].path) {
        delete form[field].value
        delete form[field].inlineError
      }
    })
    return form
  }

  _getPhoneNumber = (str) => {
    str = str || ''
    str = str.replace(/\D/g, '')
    return str ? `+${str}` : ''
  }
}
