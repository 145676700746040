import { Component } from 'react'
import { withAppContext } from 'AppContext'
import form from './formDefinitions'
import './CreateAnnouncementForm.scss'
import { ImageCheckboxInput, TextInput } from './FormComponents'

export class CreateAnnouncementForm extends Component {
  render() {
    const { appState, loudspeakerBadgeUrl } = this.props
    const auth = appState.get('authentication')
    const profile = auth.get('profile')
    const avatarUrl = profile.get('avatarUrl')

    const badges = [loudspeakerBadgeUrl] //array of badges to be used as input options
    if (avatarUrl) {
      badges.unshift(avatarUrl)
    }

    const {
      subject,
      subtitle,
      content,
      location,
      badgeUrl,
      showSubtitle,
      showLocation,
      onBadgeClick,
      onClickSubtitle,
      onClickLocation,
      handleTextChange
    } = this.props

    return (
      <form className="Form" onSubmit={this.handleSubmit}>
        {/*SUBJECT*/}
        <div className="each-field">
          <TextInput {...form.subject} value={subject} onChange={handleTextChange} />
        </div>
        <div className="separator" />

        {/*SUBTITLE*/}
        <div className="each-field">
          <div className="row">
            <label className="form-inline subtitle"> SUBTITLE </label>

            {showSubtitle && <TextInput {...form.subtitle} value={subtitle} onChange={handleTextChange} />}

            <div className={showSubtitle ? 'optional-field-below gray' : 'optional-field-inline gray'}>(optional)</div>

            <button
              className={showSubtitle ? 'icon-minus toggle' : 'icon-plus toggle'}
              type="submit"
              onClick={onClickSubtitle}
            />
          </div>
        </div>
        <div className="separator" />

        {/*CONTENT*/}
        <div data-testid="announcement-form-header-content" className="each-field content">
          <TextInput {...form.content} value={content} onChange={handleTextChange} />
        </div>
        <div className="separator" />

        {/*LOCATION*/}
        <div className="each-field">
          <div className="row">
            <label className="form-inline location"> LOCATION </label>

            {showLocation ? <TextInput {...form.location} value={location} onChange={handleTextChange} /> : null}

            <div className={showLocation ? 'optional-field-below gray' : 'optional-field-inline gray'}>(optional)</div>

            <button
              className={showLocation ? 'icon-minus toggle' : 'icon-plus toggle'}
              type="submit"
              onClick={onClickLocation}
            />
          </div>
        </div>
        <div className="separator" />

        {/*BADGES*/}
        <div className="row each-field">
          <label className="form-inline">BADGE</label>
          <div className="row badges">
            {badges.map((badgeImage, index) => (
              <ImageCheckboxInput
                badgeImage={badgeImage}
                key={index}
                badges={badges}
                badgeUrl={badgeUrl}
                avatarBadge={avatarUrl}
                onBadgeClick={onBadgeClick}
              />
            ))}
          </div>
        </div>
      </form>
    )
  }
}

export default withAppContext(CreateAnnouncementForm)
