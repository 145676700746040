import { PureComponent } from 'react'
import DayNotes from './DayNotes'
import NotesController from './NotesController'
import './NotesTable.scss'

export default class NotesTable extends PureComponent {
  constructor(props) {
    super(props)

    this.notesController = new NotesController(this)
    this.state = { ...this.notesController.defaultState }
  }

  componentWillMount() {
    this.notesController.buildNotesMap()
  }

  componentDidUpdate(prevProps) {
    const { calendar } = this.props
    const { calendar: prevCalendar } = prevProps

    const isCalendarChanged = calendar !== prevCalendar
    if (isCalendarChanged) {
      this.notesController.buildNotesMap()
    }
  }

  render() {
    const { view } = this.props
    const isDayView = view === 'day'
    const isFullView = view === 'full'

    if (isDayView) {
      return this._renderDayNotes()
    }
    if (isFullView) {
      return this._renderAllNotes()
    }

    return null
  }

  _renderDayNotes() {
    const { timeService, date } = this.props
    const { notesMap } = this.state
    const dateNotesKey = timeService.timeMoment(date).format('YYYY-MM-DD')
    const dayNotes = notesMap.get(dateNotesKey)

    return (
      <div className="notes-table">
        <DayNotes notes={dayNotes} date={dateNotesKey} timeService={timeService} />
      </div>
    )
  }

  _renderAllNotes() {
    const { timeService } = this.props
    const { notesMap } = this.state
    const dates = notesMap.keySeq()

    return (
      <div className="notes-table">
        {notesMap.valueSeq().map((dayNotes, index) => {
          const date = dates.get(index)
          return <DayNotes key={index} notes={dayNotes} date={date} timeService={timeService} />
        })}
      </div>
    )
  }
}
