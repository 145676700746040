import { List, Map } from 'immutable'
import buildOpenShiftStaffMap, { filterStaff } from '../buildOpenShiftStaffMap'

function OpenShiftConfig() {
  let timeService
  let updateOpenShiftsStateWithState

  const DEFAULT_STAFF_CONFIG = Map({
    hiddenUserIds: List(),
    selectedUserIds: List()
  })
  const DEFAULT_STAFF_FILTER = Map({
    search: '',
    roleIds: List(),
    shiftTypes: List(),
    expertises: List(),
    units: List(),
    shiftRoles: List(),
    employmentTypes: List(),
    bestMatch: false,
    overtime: false,
    bonusPay: false
  })

  const DEFAULT_ACCEPTANCE_CRITERIA = 'manualApprove'
  const DEFAULT_PARAMETERS = Map({
    isBonusPay: false,
    isCrisisPay: false,
    isImportant: false,
    acceptanceCriteria: DEFAULT_ACCEPTANCE_CRITERIA,
    isAlertEligibleStaff: false,
    note: ''
  })
  const defaultState = {
    openShift: null,
    openShiftParameters: DEFAULT_PARAMETERS,
    openShiftStaffMap: Map(),
    openShiftStaffFilter: DEFAULT_STAFF_FILTER,
    openShiftStaffConfig: DEFAULT_STAFF_CONFIG
  }
  const actions = {
    setStaffConfig,
    setStaffFilter,
    updateOpenShiftParameters
  }

  return { actions, initialize, defaultState }

  function initialize(context) {
    ;({ updateOpenShiftsStateWithState, facilityTime: timeService } = context)
  }

  function updateOpenShiftParameters(parameters) {
    return updateOpenShiftsStateWithState((openShiftsState) => {
      return openShiftsState.mergeIn(['openShiftParameters'], parameters)
    })
  }

  function updateSelectedUserIdsConfig(openShift, config, filter, expertises) {
    let selectedUserIds = config.get('selectedUserIds')
    if (selectedUserIds && selectedUserIds.size > 0) {
      const dateTime = openShift.get('shiftStartsAt')
      const expertiseRequirements = openShift.get('expertiseRequirements')
      const expertiseItems = expertises ? expertises.get('expertiseItems') : List()
      const filteredStaff = filterStaff(
        openShift,
        openShift.get('facilityUsers'),
        expertiseItems,
        expertiseRequirements,
        filter,
        dateTime,
        timeService
      )
      const invitedUserIds = openShift.get('invitedUserIds') || List()
      const filteredUserIds = filteredStaff.map((staff) => staff.get('userId'))

      selectedUserIds = selectedUserIds
        .filter((userId) => filteredUserIds.contains(userId))
        .concat(invitedUserIds)
        .toSet()
        .toList()
      config = config.merge({ selectedUserIds })
    }

    return config
  }

  function setStaffConfig(config) {
    return updateOpenShiftsStateWithState((openShiftsState, appState) => {
      const expertises = appState.get('expertises')
      const filter = openShiftsState.get('openShiftStaffFilter')
      const openShift = openShiftsState.get('openShift')
      const prevConfig = openShiftsState.get('openShiftStaffConfig')
      const newConfig = prevConfig.merge(config)

      const openShiftStaffConfig = updateSelectedUserIdsConfig(openShift, newConfig, filter, expertises)
      const openShiftStaffMap = buildOpenShiftStaffMap(openShift, filter, openShiftStaffConfig, timeService, expertises)

      return openShiftsState.merge({ openShiftStaffMap, openShiftStaffConfig })
    })
  }

  function setStaffFilter(filter, value, refreshStaffList = true) {
    return updateOpenShiftsStateWithState((openShiftsState, appState) => {
      const expertises = appState.get('expertises')
      const prevStaffFilter = openShiftsState.get('openShiftStaffFilter')
      const openShiftStaffFilter = prevStaffFilter.set(filter, value)

      if (!refreshStaffList) {
        return openShiftsState.merge({ openShiftStaffFilter })
      }

      const prevConfig = openShiftsState.get('openShiftStaffConfig')
      const openShift = openShiftsState.get('openShift')
      const openShiftStaffConfig = updateSelectedUserIdsConfig(openShift, prevConfig, openShiftStaffFilter, expertises)
      const openShiftStaffMap = buildOpenShiftStaffMap(
        openShift,
        openShiftStaffFilter,
        openShiftStaffConfig,
        timeService,
        expertises
      )

      return openShiftsState.merge({
        openShiftStaffMap,
        openShiftStaffFilter,
        openShiftStaffConfig
      })
    })
  }
}

export default OpenShiftConfig()
