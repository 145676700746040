import { Component } from 'react'
import { pick } from 'lodash'
import FacilityUserData from './FacilityUserData'

export default class FacilityUsersAccordion extends Component {
  render() {
    const { facilityUsers } = this.props

    return (
      <div className="p2 bg-white">
        {this.renderHeader()}
        <div className="hx-facility-users-list">
          {facilityUsers.map((facilityUser) => this.renderFacilityUser(facilityUser))}
        </div>
      </div>
    )
  }

  renderHeader() {
    return (
      <div className="hx-facility-users-list-header row bb1">
        <div className="upper small bold col-2 p10 pl0 gray muted">Name</div>
        <div className="upper small bold col-2 p10 pl0 gray muted">Email</div>
        <div className="upper small bold col-2 p10 pl0 gray muted">Home Unit</div>
        <div className="upper small bold col-2 p10 pl0 gray muted">Updated</div>
        <div className="upper small bold col-1 p10 pl0 gray muted">Has Permissions</div>
        <div className="upper small bold col-1 p10 pl0 gray muted">Status</div>
        <div className="upper small bold col-2 p10 pl0 gray muted">Invitation</div>
      </div>
    )
  }

  renderFacilityUser(facilityUser) {
    const { userId } = facilityUser
    const key = userId
    const facilityUserDataProps = pick(
      {
        ...this.props,
        facilityUser
      },
      ['groupsMap', 'assignments', 'timeService', 'facilityUser', 'facilityUsersStore', 'authUserId']
    )

    return <FacilityUserData {...facilityUserDataProps} key={key} />
  }
}
