import AdminStore, { Field } from '@humanics/he-react-common/lib/admin/stores/adminStore'

const fields = [Field('credential', 'Credential'), Field('createdAt', 'Created')]

function CertificationStore() {
  return AdminStore({
    fields,
    entityName: 'Certification',
    api: {
      index: 'indexCertifications'
    },
    header: { title: 'Certifications' },
    uniqBy: 'credential'
  })
}

export default CertificationStore
