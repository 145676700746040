import { createRef, Component } from 'react'

import { CalendarLayout, CrossHatch } from './Calendar'

class ScheduleView extends Component {
  constructor() {
    super()
    this.crossHatchRef = createRef()
  }
  render() {
    const { showMismatchModal, getCalendar } = this.props
    return (
      <div className="_mainContainer schedule-view-container">
        <CrossHatch ref={this.crossHatchRef} />
        <CalendarLayout
          crossHatchRef={this.crossHatchRef}
          showMismatchModal={showMismatchModal}
          getCalendar={getCalendar}
        />
      </div>
    )
  }
}

export default ScheduleView
