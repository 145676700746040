import CustomTimeInput from './CustomTimeInput'
import { Component } from 'react'

export class CustomTimeRangeInput extends Component {
  render() {
    const startsAtSelectProps = this.getStartsAtProps()
    const endsAtSelectProps = this.getEndsAtProps()
    const { startsAt, endsAt, fromLabel, toLabel, durationLabel } = this.props
    const duration = this.props.timeService.timeMoment(endsAt).diff(startsAt, 'minutes')
    const displayTime = `${Math.floor(duration / 60)}:${(duration % 60).toString().padStart(2, '0')}`

    return (
      <div className={fromLabel ? 'row' : 'row gutters'}>
        <div className={fromLabel ? 'form-item col-4' : 'item col col-6'}>
          {fromLabel && <div className="label pl10">{fromLabel}</div>}
          <CustomTimeInput {...startsAtSelectProps} />
        </div>
        <div className={toLabel ? 'form-item col-4 pl10' : 'item col col-6'}>
          {toLabel && <div className={'label pl0'}>{toLabel}</div>}
          <CustomTimeInput {...endsAtSelectProps} />
        </div>
        {durationLabel && (
          <>
            <div className="form-item col-1"></div>
            <div className="form-item col-3">
              <div className="label pl0">{durationLabel}</div>
              <input type="text" value={displayTime} disabled />
            </div>
          </>
        )}
      </div>
    )
  }

  getStartsAtProps() {
    const { step, startsAt, endsAt, minStartsAt, timeService, date, readOnly } = this.props
    let { maxStartsAt } = this.props

    const endsAtMoment = endsAt && timeService.timeMoment(endsAt)
    const startsAtMoment = startsAt && timeService.timeMoment(startsAt)
    const minRangeLength = endsAtMoment && (endsAtMoment.minutes() % step || step)

    const limitByEndsAt = endsAtMoment && endsAtMoment.add(-minRangeLength, 'minutes')

    maxStartsAt =
      maxStartsAt && limitByEndsAt && limitByEndsAt.isBefore(maxStartsAt)
        ? endsAtMoment.toISOString()
        : maxStartsAt || (limitByEndsAt && limitByEndsAt.toISOString())

    const valueShort = startsAtMoment && startsAtMoment.format('HH:mm')

    return {
      step,
      date,
      valueShort,
      timeService,
      value: startsAt,
      disabled: !startsAt,
      minValue: minStartsAt,
      maxValue: maxStartsAt,
      onChange: this.onChangeStartsAt,
      readOnly
    }
  }

  getEndsAtProps() {
    const { step, startsAt, endsAt, maxEndsAt, timeService, date, readOnly } = this.props
    let { minEndsAt } = this.props

    const startsAtMoment = startsAt && timeService.timeMoment(startsAt)
    const endsAtMoment = endsAt && timeService.timeMoment(endsAt)
    const minRangeLength = startsAtMoment && (startsAtMoment.minutes() % step || step)

    const limitByStartsAt = startsAtMoment && startsAtMoment.add(minRangeLength, 'minutes')
    minEndsAt =
      minEndsAt && limitByStartsAt && limitByStartsAt.isAfter(minEndsAt)
        ? startsAtMoment.toISOString()
        : minEndsAt || (limitByStartsAt && limitByStartsAt.toISOString())

    const valueShort = endsAtMoment && endsAtMoment.format('HH:mm')

    return {
      step,
      date,
      valueShort,
      timeService,
      value: endsAt,
      disabled: !endsAt,
      minValue: minEndsAt,
      maxValue: maxEndsAt,
      onChange: this.onChangeEndsAt,
      readOnly
    }
  }

  onChangeStartsAt = (utcValue) => {
    const { startsAt, onChangeStartsAt } = this.props
    if (utcValue === startsAt) {
      return
    }

    onChangeStartsAt(utcValue)
  }

  onChangeEndsAt = (utcValue) => {
    const { endsAt, onChangeEndsAt } = this.props

    if (utcValue === endsAt) {
      return
    }

    onChangeEndsAt(utcValue)
  }
}
