import classNames from 'classnames'
import { PureComponent } from 'react'
import { map } from 'lodash'
import { FilterMenuOption } from '../MultiSelectFilter'
import './CustomSelect.scss'

function Option({ name, value, selectedValue, makeSelection, radioName }) {
  return (
    <FilterMenuOption
      id={`hx-radio$[${radioName}][${value}]}`}
      active={selectedValue === value}
      onChange={() => makeSelection(value)}
      label={name}
      type="radio"
    />
  )
}

export default class CustomSelect extends PureComponent {
  state = { isActive: false, isHovered: false }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive
    }))
  }

  hideDropdown = () => {
    this.tm = setTimeout(() => this.setState({ isActive: false }), 100)
  }

  makeSelection = (value) => {
    const { updateConfig } = this.props
    this.hideDropdown()
    return updateConfig(value)
  }

  onMouseEnter = () => {
    if (!this.state.isActive) {
      return
    }
    return this.setState({ isHovered: true })
  }

  onMouseLeave = () => {
    this.setState({ isHovered: false })
    setTimeout(() => !this.state.isHovered && this.hideDropdown(), 500)
  }

  render() {
    const { name, value, options, minWidth, hideName } = this.props
    if (!options) {
      return null
    }

    const { isActive } = this.state
    const style = `width-${minWidth}`
    const radioName = name.replace(/\s/g, '')

    const arrowClassName = classNames('nano icon', {
      'icon-Chevron---Down': !isActive,
      'icon-Chevron---Up': isActive
    })

    return (
      <aside
        className="hx-filters"
        onBlur={this.hideDropdown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        style={{ marginRight: 0 }}
      >
        <button
          className={classNames('hx-calendar-indicators-button', {
            active: isActive
          })}
          onClick={this.toggleDropdown}
        >
          <div className="option-value">
            {hideName && <sh-text>{name}: </sh-text>}
            <div data-testid="selected-option-value" className={`selected-value ml1 ${style}`}>
              <sh-text size="title-1">
                {options[value]}
                <span>
                  <i className={arrowClassName} />
                </span>
              </sh-text>
            </div>
          </div>
        </button>
        <div className="custom-select-filter p20 bg-white filters-content" onMouseDown={(e) => e.preventDefault()}>
          <div style={{ width: '100%' }}>
            {map(options, (name, key) => (
              <Option
                key={key}
                name={name}
                value={key}
                selectedValue={value}
                makeSelection={this.makeSelection}
                radioName={radioName}
              />
            ))}
          </div>
        </div>
      </aside>
    )
  }
}
