import { Map } from 'immutable'
import MultiSelectFilter from '../MultiSelectFilter'

const getRolesDefinition = (roles) => {
  let options = Map({})

  roles.forEach((role) => {
    const id = role.get('id')
    const name = role.get('name')
    options = options.set(id, name)
  })

  return {
    minWidth: 100,
    options,
    deselectAllAllowed: true
  }
}
export default function RolesSelector(props) {
  const { roles, values, setRole: setConfig, ...rest } = props

  const multiSelectFilterProps = {
    ...getRolesDefinition(roles),
    values,
    setConfig,
    name: 'Staff',
    field: 'staff',
    deselectAllAllowed: false,
    ...rest
  }

  return <MultiSelectFilter {...multiSelectFilterProps} />
}
