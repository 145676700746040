import { DateRangeState } from 'Manager/components'

function ScheduleMetadata(props) {
  const { scheduleMetadata, timeService } = props
  const from = timeService.timeMoment(scheduleMetadata.get('startsAt'))
  const to = timeService.timeMoment(scheduleMetadata.get('endsAt'))
  const toMinuteBefore = to.clone().subtract(1, 'minutes')
  const dateFormat = 'MMM D, YYYY'
  const title = `${from.format(dateFormat)} - ${toMinuteBefore.format(dateFormat)}`
  let scheduleState = scheduleMetadata.get('state')
  if (scheduleState === 'open') {
    scheduleState = 'requests open'
  }
  const isRequestsOpenSchedule = scheduleState === 'requests open'
  const completionPercentage =
    isRequestsOpenSchedule &&
    Math.round(
      (scheduleMetadata.get('staffUnavailabilityRequestsSubmittedNumber') * 100) /
        scheduleMetadata.get('staffUnavailabilityRequestsNumber')
    )
  const requestsOpenScheduleProgressBarLabel = `${scheduleMetadata.get('staffUnavailabilityRequestsSubmittedNumber')} /
    ${scheduleMetadata.get('staffUnavailabilityRequestsNumber')} requests submitted`
  return (
    <div style={{ minHeight: '4em' }}>
      <div className="row">
        <div className="col-8 strong">{title}</div>
        <div className="col-4 row align-middle align-right">
          <DateRangeState state={scheduleState} timeService={timeService} endDate={scheduleMetadata.get('endsAt')} />
        </div>
      </div>
      {isRequestsOpenSchedule && (
        <sh-progress value={completionPercentage} info={requestsOpenScheduleProgressBarLabel} />
      )}
    </div>
  )
}

export default ScheduleMetadata
