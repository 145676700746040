import { ModeFull, ModeWeek } from './Header'
import classNames from 'classnames'

export default function VRole({
  role,
  style,
  activeDateRange,
  filters,
  toggleIsCollapsed,
  onChangeViewPreference,
  unitId,
  additionalFilters,
  mode,
  calendarType
}) {
  let isCollapsed = !!filters && filters.getIn(['collapsed', role.get('id')])
  if (additionalFilters) {
    const { hideSecondaryStaff } = additionalFilters
    if (!hideSecondaryStaff) {
      isCollapsed = false
    }
  }
  const roleViewPreference =
    role
      .get('shifts')
      ?.find((shift) => !shift.get('isOnCall'))
      ?.get('viewPreference') || 'staff'

  return (
    <section
      className={classNames('_group hx-calendar-group-container', {
        _collapsed: isCollapsed
      })}
      style={style}
    >
      <div className="hx-calendar-group">
        {mode === 'week' ? (
          <ModeWeek
            isCollapsed={isCollapsed}
            activeDateRange={activeDateRange}
            isShiftDaysLoaded={true}
            roleName={role.get('name')}
            roleId={role.get('id')}
            roleViewPreference={roleViewPreference}
            toggleIsCollapsed={toggleIsCollapsed}
            onChangeViewPreference={async (viewPreferenceSettings) => {
              onChangeViewPreference(viewPreferenceSettings, role)
            }}
            unitId={unitId}
            mode={mode}
            calendarType={calendarType}
          />
        ) : (
          <ModeFull
            isCollapsed={isCollapsed}
            activeDateRange={activeDateRange}
            isShiftDaysLoaded={true}
            roleName={role.get('name')}
            roleId={role.get('id')}
            roleViewPreference={roleViewPreference}
            toggleIsCollapsed={toggleIsCollapsed}
            onChangeViewPreference={async (viewPreferenceSettings) => {
              onChangeViewPreference(viewPreferenceSettings, role)
            }}
            unitId={unitId}
            calendarType={calendarType}
          />
        )}
      </div>
    </section>
  )
}
