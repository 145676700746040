import { toLower } from 'lodash'

export default class TimeOffFilterService {
  constructor(filters) {
    this.filters = filters
  }

  isDateRangeVisible = (dateRange) => !this._isDateRangeEmpty(dateRange)

  isRoleVisible = (role) => {
    const { staffRole } = this.filters

    const isRoleEmpty = this._isRoleEmpty(role)
    if (isRoleEmpty) {
      return false
    }

    if (staffRole === 'all') {
      return true
    }

    return staffRole === role.get('id')
  }

  isShiftVisible = (shift) => {
    const { shiftType } = this.filters

    if (shiftType === 'all') {
      return true
    }

    return shiftType === toLower(shift.get('typeId'))
  }

  isStaffVisible = (staff) => {
    return true
  }

  _isDateRangeEmpty(dateRange) {
    const roles = dateRange.get('roles').filter(this.isRoleVisible)
    return roles.size === 0
  }

  _isRoleEmpty(role) {
    const shifts = role.get('shifts').filter(this.isShiftVisible)
    return shifts.size === 0
  }
}
