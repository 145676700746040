import { useContext } from 'react'
import './StaffEvents.scss'
import { getStaffEventShortTitle, getStaffEventDisplayAttributes } from 'Staff/Calendar/utils'
import { ItemTypeFilterContext } from 'Staff/Calendar/CalendarContext'

function getFilterKeyForStaffEvent(staffEvent: any) {
  let filterType: string = ''
  const staffEventType = staffEvent?.get('type')
  const timeOffAttributes = staffEvent?.get('timeOffAttributes')

  if (staffEventType === 'assignment' || staffEventType === 'onCall') {
    filterType = 'assignment'
  } else if (staffEventType === 'block') {
    filterType = timeOffAttributes?.get('isPartial') ? 'partialTimeOff' : 'timeOff'
  }
  return filterType
}

export function StaffEvents({ staffEvents }: any) {
  const { selectedCalendarFilters } = useContext(ItemTypeFilterContext)
  if (!staffEvents) {
    return null
  }
  let filteredStaffEvents = staffEvents
  if (selectedCalendarFilters) {
    filteredStaffEvents = staffEvents.filter((staffEvent: any) =>
      selectedCalendarFilters.has(getFilterKeyForStaffEvent(staffEvent))
    )
  }

  return (
    <div>
      {filteredStaffEvents.map((staffEvent: any) => {
        const { color: barColor, icon } = getStaffEventDisplayAttributes(staffEvent)
        return (
          <div
            key={staffEvent.get('id')}
            className="event-tag"
            style={{
              backgroundColor: barColor,
              width: '100%',
              marginTop: '2px'
            }}
          >
            <sh-icon icon={icon} size="xs" data-testid={`${staffEvent.get('id')}-sh-icon`} />
            <div className="staff-event-label" style={{ color: '#00000099' }}>
              {getStaffEventShortTitle(staffEvent)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StaffEvents
