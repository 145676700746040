import './EditButtons.scss'
import AddComponentButton from './AddComponentButton'
import DeleteComponentButton from './DeleteComponentButton'

export default function EditButtons({
  insertDetailsComponentAbove,
  insertDetailsComponentBelow,
  deleteDetailsComponent
}) {
  return (
    <div>
      <div className="add-component-button-above">
        <AddComponentButton insertDetailsComponent={insertDetailsComponentAbove} />
      </div>
      <div className="add-component-button-below">
        <AddComponentButton insertDetailsComponent={insertDetailsComponentBelow} />
      </div>
      <div className="delete-component-button">
        <DeleteComponentButton deleteDetailsComponent={deleteDetailsComponent} />
      </div>
    </div>
  )
}
