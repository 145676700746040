import { getCommonFieldsDefinition } from '../../utils'

const formExtension = {
  equipmentSkill: {
    definition: {
      label: 'Equipment Skill',
      required: true,
      type: 'string',
      inputType: 'CustomDropDown',
      withLabel: true
    },
    field: 'equipmentSkill',
    formItemClasses: 'col-6 pr30'
  },
  ...getCommonFieldsDefinition()
}

export default formExtension
