import { List, Map } from 'immutable'
import extendFacilityUser from './extendFacilityUser'
import { staffEligibleForUnitQuery } from '../Queries'

function ActiveDateRange() {
  let instanceUri = null
  let gqlClient = null
  let getGeneralData = null
  let updateGeneralData = null
  const actions = { setActiveDateRange, reloadFacilityUsersInActiveDateRange }
  const helpers = { extendActiveDateRange }

  return { initialize, actions, ...helpers }

  function initialize(context) {
    ;({ gqlClient, instanceUri, getGeneralData, updateGeneralData } = context)
  }

  async function setActiveDateRange(dateRange) {
    const generalData = getGeneralData()
    const schedules = generalData.getIn(['unit', 'schedules'])
    const schedule = getScheduleForDateRange(schedules, dateRange)

    const prevDateRange = generalData.get('activeDateRange')
    const activeDateRangeIndex = prevDateRange.get('index')
    const activeDuration = prevDateRange.get('duration')

    const newDateRangeIndex = dateRange.get('index')
    const newDuration = dateRange.get('duration')

    const isDateRangeIndexChanged = activeDuration !== newDuration
    const isDurationChanged = activeDateRangeIndex !== newDateRangeIndex

    if (isDateRangeIndexChanged || isDurationChanged) {
      dateRange = extendActiveDateRange(dateRange, [])
      dateRange = prevDateRange.merge(dateRange).set('isReady', false)
      updateGeneralData((generalData) => generalData.set('activeDateRange', dateRange))

      const unitId = dateRange.get('unitId')
      const startsAt = dateRange.get('startsAt')
      const endsAt = dateRange.get('endsAt')

      const facilityUsers = await loadFacilityUsers(unitId, startsAt, endsAt)
      dateRange = extendActiveDateRange(dateRange, facilityUsers)
    }

    dateRange = prevDateRange.merge(dateRange)

    return updateGeneralData((generalData) => {
      return generalData.set('activeDateRange', dateRange).setIn(['unit', 'schedule'], schedule)
    })
  }

  async function reloadFacilityUsersInActiveDateRange() {
    const generalDataBeforeReload = getGeneralData()
    let dateRange = generalDataBeforeReload.get('activeDateRange')
    const unitId = dateRange.get('unitId')
    const startsAt = dateRange.get('startsAt')
    const endsAt = dateRange.get('endsAt')

    const facilityUsers = await loadFacilityUsers(unitId, startsAt, endsAt)
    dateRange = extendActiveDateRange(dateRange, facilityUsers)
    return updateGeneralData((generalData) => generalData.set('activeDateRange', dateRange))
  }

  async function loadFacilityUsers(unitId, startDate, endDate) {
    const { staffEligibleForUnit } = await gqlClient.query(staffEligibleForUnitQuery, {
      unitId,
      endDate,
      startDate,
      limit: 999
    })

    return staffEligibleForUnit
  }

  function extendActiveDateRange(activeDateRange, facilityUsers) {
    const { facilityUserIds, facilityUsersMap, facilityUsersExtended } = buildFacilityUsersData(facilityUsers)

    return activeDateRange.merge({
      facilityUserIds,
      facilityUsersMap,
      facilityUsers: facilityUsersExtended,
      staffUnavailabilityRequests: List(),
      isReady: true
    })
  }

  function buildFacilityUsersData(facilityUsers) {
    let facilityUserIds = List()
    let facilityUsersMap = Map()
    const data = { instanceUri }

    const facilityUsersExtended = List(
      facilityUsers.map((facilityUser) => {
        const { userId } = facilityUser
        const facilityUserExtended = extendFacilityUser(facilityUser, data)

        facilityUsersMap = facilityUsersMap.set(userId, facilityUserExtended)
        facilityUserIds = facilityUserIds.push(userId)

        return facilityUserExtended
      })
    )

    return { facilityUserIds, facilityUsersMap, facilityUsersExtended }
  }

  function getScheduleForDateRange(schedules, dateRange) {
    const startsAt = dateRange.get('startsAt')
    const endsAt = dateRange.get('endsAt')

    const schedule = schedules.find((schedule) => {
      const scheduleStartsAt = schedule.get('startsAt')
      const scheduleEndsAt = schedule.get('endsAt')

      return scheduleStartsAt === startsAt && scheduleEndsAt === endsAt
    })

    const scheduleState = 'inactive'
    const state = scheduleState

    if (!schedule) {
      return Map({ startsAt, endsAt, state, scheduleState })
    }

    return schedule
  }
}

export default ActiveDateRange()
