import stores from 'stores'
import { groupBy } from 'lodash'
import { Map } from 'immutable'
import { CalendarSelection } from 'entityWrappers'
import DataController from '../DataController'
import DummyCellsBuilder from './DummyCellsBuilder'
import DummyShiftDaysBuilder from './DummyShiftDaysBuilder'
import DummyStaffHoursBuilder from './DummyStaffHoursBuilder'
import { handleError } from 'services/ErrorHandler'

const { calendarStore } = stores

// Dynamic store passing is not needed since it is not being used in auto schedule calendar
export default class OptimisticActionController extends DataController {
  get defaultState() {
    return { selectionMetaData: Map() }
  }

  get selectionMetaData() {
    return this.state.selectionMetaData
  }

  applyDeleteOptimisticResult() {
    return this._applyActionOptimisticResultForSelection('delete')
  }

  applyAddTimeOffOptimisticResult(staffEvents, data) {
    const action = (params) => this._applyActionOptimisticResultForSelection('create', params)
    return this._applyOptimisticResultForStaffEvents(action, staffEvents, data)
  }

  applyChangeTimeOffOptimisticResult(staffEvents, data) {
    const action = (params) => this._applyActionOptimisticResultForSelection('change', params)
    return this._applyOptimisticResultForStaffEvents(action, staffEvents, data)
  }

  applyAddStaffEventOptimisticResult(staffEvents, data) {
    const action = (params) => this._applyActionOptimisticResultForSelection('create', params)
    return this._applyOptimisticResultForStaffEvents(action, staffEvents, data)
  }

  applyChangeShiftOptimisticResult(staffEvents, data) {
    const action = (params) => this._applyActionOptimisticResultForSelection('change', params)
    return this._applyOptimisticResultForStaffEvents(action, staffEvents, data)
  }

  applyUpdateShiftOptimisticResult(staffEvents, data) {
    const action = (params) => this._applyActionOptimisticResultForSelection('update', params)
    return this._applyOptimisticResultForStaffEvents(action, staffEvents, data)
  }

  resetSelectionMetadata(selection) {
    const selectionIdentityHash = CalendarSelection.buildSelectionIdentityHash(selection)
    const selectionMetaData = this.selectionMetaData.delete(selectionIdentityHash)

    this.setState({ selectionMetaData })
  }

  restoreOriginalState(selection) {
    const { cells, shiftDays, staffHours } = this._buildOriginalState(selection)

    return calendarStore.updateCalendar((calendar) => {
      calendar = this._updateCells(calendar, selection, cells)
      calendar = this._updateShiftDays(calendar, shiftDays)
      calendar = this._updateStaffHoursMap(calendar, selection, { staffHours })

      return calendar
    })
  }

  higlightActionError(error, selection) {
    const { cellsPaths } = selection

    const onHide = () => this._toggleCellsErrorState(cellsPaths, false)
    handleError(error, { onHide })

    this._toggleCellsErrorState(cellsPaths, true)
  }

  _toggleCellsErrorState = (cellsPaths, isError) => {
    return calendarStore.updateCalendar((calendar) =>
      cellsPaths.reduce((calendar, cellPathParams) => {
        const cellPath = calendarStore.getPath(cellPathParams)
        return calendar.mergeIn(cellPath, { isError })
      }, calendar)
    )
  }

  _applyOptimisticResultForStaffEvents(action, staffEvents, data) {
    const { eventType, timeOffAttributes = {}, staffEventShiftId } = data
    const shifts = this.props.unit.get('shifts')
    const staffEventShift = shifts.find((shift) => shift.get('id') === staffEventShiftId)
    const staffEventsByShifts = groupBy(staffEvents, 'shiftId')

    const { paidMinutes } = timeOffAttributes
    const isTimeOff = eventType === 'timeOff'
    const isAssignment = eventType === 'assignment'
    const isOnCall = eventType === 'assignment' && staffEventShift?.get('isOnCall')
    const isMeeting = eventType === 'meeting'

    const isStaffPaidTimeOff = isTimeOff && paidMinutes > 0
    const isRequestOff = isTimeOff && paidMinutes === 0

    return Object.keys(staffEventsByShifts).map((shiftId) => {
      const staffEvents = staffEventsByShifts[shiftId]

      return action({
        ...data,
        staffEvents,
        isMeeting,
        isAssignment,
        isTimeOff,
        isOnCall,
        isRequestOff,
        isStaffPaidTimeOff
      })
    })
  }

  _applyActionOptimisticResultForSelection(action, data) {
    const { selectedObjects } = this
    const shifts = this.props.unit.get('shifts')

    return this._applyActionOptimisticResult(action, selectedObjects, { ...data, shifts })
  }

  _applyActionOptimisticResult(action, selection, data = {}) {
    const optimisticResult = this._buildActionOptimisticResult(action, selection, data)

    this._saveSelectionMetaData(selection, optimisticResult)

    return calendarStore.updateCalendar((calendar) =>
      this._updateCalendarWithOptimisticResult(calendar, selection, optimisticResult)
    )
  }

  _buildOriginalState(selection) {
    const { calendar } = this
    const selectionIdentityHash = CalendarSelection.buildSelectionIdentityHash(selection)
    const selectionMetaData = this.selectionMetaData.get(selectionIdentityHash)

    const staffHoursDelta = selectionMetaData.get('staffHoursDelta')
    const shiftDaysMeta = selectionMetaData.get('shiftDays')
    const cells = selectionMetaData.get('cells')

    const { staff } = selection
    const { staffHoursMap } = calendar
    const userId = staff.get('id')
    const staffHours = parseFloat(staffHoursMap.getIn([userId, 'value'])) - staffHoursDelta

    const shiftDays = shiftDaysMeta.map((shiftDayMeta) => {
      const path = shiftDayMeta.get('path')
      const roleIndex = path.get('roleIndex')
      const shiftIndex = path.get('shiftIndex')
      const shiftDayIndex = path.get('shiftDayIndex')

      const mismatchDelta = shiftDayMeta.get('mismatchDelta')
      const shiftDay = calendar.getShiftDay({
        roleIndex,
        shiftIndex,
        shiftDayIndex
      })
      const staffMismatch = shiftDay.get('staffMismatch') - mismatchDelta

      return shiftDay.merge({ staffMismatch, path })
    })

    return { cells, shiftDays, staffHours }
  }

  _buildActionOptimisticResult(action, selection, data) {
    const { calendar } = this
    const { timeService } = this.props
    const { staffHoursMap } = calendar

    const cellsBuilder = new DummyCellsBuilder(timeService)
    const shiftDaysBuilder = new DummyShiftDaysBuilder(calendar)
    const staffHoursBuilder = new DummyStaffHoursBuilder(staffHoursMap)

    const dummyCells = cellsBuilder.buildDummyCells(action, selection, data)
    const dummyShiftDays = shiftDaysBuilder.buildDummyShiftDays(action, selection, data)
    const dummyStaffHours = staffHoursBuilder.buildDummyStaffHours(action, selection, data)

    return { dummyCells, dummyShiftDays, dummyStaffHours }
  }

  _updateCalendarWithOptimisticResult(calendar, selection, optimisticResult) {
    const { dummyCells, dummyShiftDays, dummyStaffHours } = optimisticResult

    calendar = this._updateCells(calendar, selection, dummyCells)
    calendar = this._updateShiftDays(calendar, dummyShiftDays)
    calendar = this._updateStaffHoursMap(calendar, selection, dummyStaffHours)

    return calendar
  }

  _updateStaffHoursMap(calendar, { staff }, { staffHours, requestsCount }) {
    const userId = staff.get('id')
    return calendar.setIn(['staffHoursMap', userId], Map({ value: staffHours, requestsCount }))
  }

  _updateShiftDays(calendar, shiftDays) {
    return shiftDays
      .filter((shiftDay) => !!shiftDay)
      .reduce((calendar, shiftDay) => {
        const path = shiftDay.get('path')
        const roleIndex = path.get('roleIndex')
        const shiftIndex = path.get('shiftIndex')
        const shiftDayIndex = path.get('shiftDayIndex')
        const shiftDayPath = calendarStore.getPath({
          roleIndex,
          shiftIndex,
          shiftDayIndex
        })

        return calendar.setIn(shiftDayPath, shiftDay)
      }, calendar)
  }

  _updateCells(calendar, { cellsPaths }, cells) {
    return cellsPaths.reduce((calendar, cellPathParams) => {
      const { cellIndex } = cellPathParams
      const cell = cells.get(cellIndex)
      const cellPath = calendarStore.getPath(cellPathParams)

      return calendar.setIn(cellPath, cell)
    }, calendar)
  }

  _saveSelectionMetaData(selection, optimisticResult) {
    const { cells } = selection
    const { dummyShiftDays, dummyStaffHours } = optimisticResult
    const { hoursDelta: staffHoursDelta } = dummyStaffHours

    const selectionIdentityHash = CalendarSelection.buildSelectionIdentityHash(selection)
    const shiftDays = dummyShiftDays
      .filter((shiftDay) => !!shiftDay)
      .map((shiftDay) => {
        const path = shiftDay.get('path')
        const mismatchDelta = shiftDay.get('mismatchDelta') || 0

        return Map({ path, mismatchDelta })
      })

    const selectionMetaData = { cells, staffHoursDelta, shiftDays }
    return this._setSelectionMetaData(selectionIdentityHash, selectionMetaData)
  }

  _setSelectionMetaData = (selectionIdentityHash, metaData) => {
    const newMetaData = this.selectionMetaData.updateIn([selectionIdentityHash], (prevMetaData) =>
      (prevMetaData || Map()).merge(metaData)
    )
    this.setState({ selectionMetaData: newMetaData })
  }
}
