import { PureComponent } from 'react'
import { throttle } from 'lodash'
import { findParentElement } from 'utils'
import './CrossHatch.scss'

export default class CrossHatch extends PureComponent {
  constructor(props) {
    super(props)
    this.throttle = throttle(this.throttleUpdatePosition)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.hideVertical)
    this.$vertical = document.getElementById('cross-hatch-vertical')
    this.$horizontal = document.getElementById('cross-hatch-horizontal')
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideVertical)
  }

  render() {
    let { styleVertical = {}, styleHorizontal = {} } = this.props
    styleVertical = { ...styleVertical, display: 'none' }
    styleHorizontal = { ...styleHorizontal, display: 'none' }

    return (
      <>
        <div style={styleVertical} id="cross-hatch-vertical" />
        <div style={styleHorizontal} id="cross-hatch-horizontal" />
      </>
    )
  }

  updatePosition(e) {
    this.throttle(e)
  }

  throttleUpdatePosition(e) {
    let $target
    // console.log(e.target.parentNode.parentNode);

    let fullTargetElement = findParentElement(e.target, 'classList', '_cell')
    let weekTargetElement = findParentElement(e.target, 'classList', 'weekly-view-event-bar-container')

    $target = fullTargetElement ? fullTargetElement : weekTargetElement

    const isCellHovered = !!$target

    if (isCellHovered) {
      return this.updateStyles(false, false, $target)
    }

    $target = findParentElement(e.target, 'classList', '_staffProfile')
    const isStaffProfileHovered = !!$target

    if (isStaffProfileHovered) {
      return this.updateStyles(true, false, $target)
    }

    $target = findParentElement(e.target, 'classList', '_headerCell')
    const isHeaderCellHovered = !!$target

    if (isHeaderCellHovered) {
      return this.updateStyles(false, true, $target)
    }

    const isHeaderWeekHovered = e.target.classList.contains('hx-calendar-header-week')

    const isRoleHeaderHovered =
      e.target.nodeName === 'SECTION' && findParentElement(e.target, 'classList', 'hx-calendar-group-container')

    const isShiftHeaderHovered = e.target.nodeName === 'HEADER' && e.target.classList.contains('hx-calendar-shift')

    const isWeekCells =
      findParentElement(e.target, 'classList', '_weekCells') || findParentElement(e.target, 'classList', '_staff')

    const isShiftDayHovered = e.target.classList.contains('_shift')

    const isDividerHovered = e.target.classList.contains('divider') || e.target.classList.contains('staff-divider')

    const isRowHovered = e.target.classList.contains('row')

    if (
      isWeekCells ||
      isShiftHeaderHovered ||
      isShiftDayHovered ||
      isDividerHovered ||
      isRowHovered ||
      isHeaderWeekHovered ||
      isRoleHeaderHovered
    ) {
      return
    }

    return this.hide()
  }

  updateStyles(isVerticalHidden, isHorizontalHidden, $target) {
    const { top, left, width } = $target.getBoundingClientRect()

    this.updateVerticalStyles(left, width, !isVerticalHidden)
    this.updateHorizontalStyles(top, !isHorizontalHidden)
  }

  updateVerticalStyles(left = 0, width = 0, display = false) {
    this.$vertical.style.display = display ? '' : 'none'
    if (display) {
      this.$vertical.style.transform = `translate3d(${left - 1}px, 0, 0)`
      this.$vertical.style.width = `${width + 1}px`
      // this.$vertical.style.height = `${$el.parentNode.parentNode.clientHeight}px`;
      // this.$vertical.style.top = `100px`;
    }
  }

  updateHorizontalStyles(top = 0, display = false) {
    const $el = document.getElementById('cross-hatch-horizontal')
    this.$horizontal.style.display = display ? '' : 'none'
    if (display) {
      this.$horizontal.style.transform = `translate3d(0, ${top - 1}px, 0)`
      const left = (document.documentElement.clientWidth - $el?.parentNode?.parentNode?.parentNode?.clientWidth) / 2
      this.$horizontal.style.left = `${left ? left : 0}px`
      this.$horizontal.style.width = `${$el?.parentNode?.clientWidth ? $el.parentNode.clientWidth : 0}px`
    }
  }

  hide = () => {
    this.updateVerticalStyles()
    this.updateHorizontalStyles()
  }

  hideHorizontal = () => {
    this.updateHorizontalStyles()
  }

  hideVertical = () => {
    this.updateVerticalStyles()
  }
}
