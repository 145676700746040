import * as Sentry from '@sentry/browser'
// import { extendEvent } from './Errors';
import buildConfig from './Config'
import { shouldIgnoreEvent } from './IgnoreEventList'

function SentryShell() {
  const isSentryEnabled = !!window.SENTRY_DSN && window.SENTRY_DSN.length > 0 && window.SENTRY_DSN !== '__SENTRY_DSN__'

  const config = buildConfig(isSentryEnabled)

  return {
    ...config,
    initialize,
    trackError,
    trackRequestError
  }

  function initialize(properties) {
    if (!isSentryEnabled) {
      return
    }

    Sentry.init({
      release: window.RELEASE,
      dsn: window.SENTRY_DSN,
      beforeSend(event, hint) {
        const shouldIgnore = shouldIgnoreEvent(event, hint)

        if (shouldIgnore) {
          return null
        }
        return event
        // return extendEvent(event, hint);
      },
      ...properties
    })
  }

  function trackError(error) {
    if (!isSentryEnabled) {
      return
    }
    Sentry.withScope((scope) => {
      scope.setExtra('originalError', error)
      Sentry.captureException(error)
    })
  }

  function trackRequestError(error, request) {
    if (!isSentryEnabled) {
      return
    }

    Sentry.withScope((scope) => {
      scope.setExtra('request', request)
      scope.setExtra('originalError', error)
      Sentry.captureException(error)
    })
  }
}

export default SentryShell()
